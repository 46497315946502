import styled from '@emotion/styled/macro';
import React from 'react';
import { COLORS } from '../../../constants/colors';
import { openPeechProUpgradeModal } from '../../components/layout';
import SVG from '../../components/svg';
import { AppContext } from '../../lib/app-context';
import { sequencesSinceLastChargeState } from '../../state/local';
import { PeechProUpgradeButton } from '../StyledComponents/PeechProUpgradeButton.styled';

const Wrapper = styled.div`
  width: fit-content;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const Counter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 6px;
  min-width: 143px;
  height: 28px;
  background: ${COLORS.peech_neutral_white};
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.peech_gray_500};
  span {
    font-weight: 700;
  }
`;

const StyledPeechProUpgradeButton = styled(PeechProUpgradeButton)`
  justify-content: center;
  height: 28px;
  width: 162px;
  margin-left: 8px;
  font-size: 12px;
  align-items: center;

  svg {
    width: 18px;
    margin-right: 6px;
  }
`;

interface ProjectsCounterBlockProps {}

export default function ProjectsCounterBlock({}: ProjectsCounterBlockProps) {
  const { reachProjectLimit, plan, user } = React.useContext(AppContext);
  const createdProjectsAmount = sequencesSinceLastChargeState.use();
  const projectLimitAmount = React.useMemo(() => plan.maxVideos, [plan.maxVideos]);
  const shouldShowFeature = React.useMemo(
    () => projectLimitAmount !== -1 && projectLimitAmount !== null && projectLimitAmount !== undefined,
    [projectLimitAmount]
  );

  return (
    shouldShowFeature && (
      <Wrapper>
        <Counter>
          <span>
            {createdProjectsAmount}/{projectLimitAmount}
          </span>
          Monthly projects
        </Counter>

        {reachProjectLimit && (
          <StyledPeechProUpgradeButton
            onClick={() => openPeechProUpgradeModal(user, 'Project Library Button', 'PROJECTS_LIMIT_REACHED')}
          >
            <SVG name="diamond" />
            Get more projects
          </StyledPeechProUpgradeButton>
        )}
      </Wrapper>
    )
  );
}
