
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'

export enum AssetUsageUsageType {
	BRIGHT = 1,
	DARK = 2,
	HEADER_FONT_FAMILY = 101,
	BODY_FONT_FAMILY = 102,
};

export interface IAssetUsage extends IBase {
	assetSid?: string | null;
	type?: number | null;
	usageType?: number | null;
	fileNames?: string[] | null;
	regularFileName?: string | null;
	boldFileName?: string | null;
	isDefault?: boolean | null;
}

export interface IAssetUsageProperties extends IBaseProperties {
	assetSid?: boolean;
	type?: boolean;
	usageType?: boolean;
	fileNames?: boolean;
	regularFileName?: boolean;
	boldFileName?: boolean;
	isDefault?: boolean;
}

export interface IAssetUsageFilter extends IBaseFilter {
}

export class AssetUsage<IType extends IAssetUsage = IAssetUsage, ITypeProperties extends IAssetUsageProperties = IAssetUsageProperties> extends Base<IType, ITypeProperties> implements IAssetUsage {
	
	static className = 'AssetUsage';
	static NAME = 'assetUsage';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		assetSid: true,
		type: true,
		usageType: true,
		fileNames: true,
		regularFileName: true,
		boldFileName: true,
		isDefault: true,
	};
	
	/**
	 * @deprecated use AssetUsageUsageType instead
	 */
	static USAGE_TYPE: any = {
		BRIGHT: AssetUsageUsageType.BRIGHT,
		DARK: AssetUsageUsageType.DARK,
		HEADER_FONT_FAMILY: AssetUsageUsageType.HEADER_FONT_FAMILY,
		BODY_FONT_FAMILY: AssetUsageUsageType.BODY_FONT_FAMILY,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get assetSid(): string | null | undefined {
		return this.values.assetSid
	}
			
	set assetSid(value: string | null | undefined) {
		if(this.values.assetSid !== value) {
			this.propertyChanged('assetSid', value);
			this.values.assetSid = value;
		}
	}
			
	get type(): number | null | undefined {
		return this.values.type
	}
			
	get usageType(): number | null | undefined {
		return this.values.usageType
	}
			
	set usageType(value: number | null | undefined) {
		if(this.values.usageType !== value) {
			this.propertyChanged('usageType', value);
			this.values.usageType = value;
		}
	}
			
	get fileNames(): string[] | null | undefined {
		return this.values.fileNames
	}
			
	set fileNames(value: string[] | null | undefined) {
		if(this.values.fileNames !== value) {
			this.propertyChanged('fileNames', value);
			this.values.fileNames = value;
		}
	}
			
	get regularFileName(): string | null | undefined {
		return this.values.regularFileName
	}
			
	set regularFileName(value: string | null | undefined) {
		if(this.values.regularFileName !== value) {
			this.propertyChanged('regularFileName', value);
			this.values.regularFileName = value;
		}
	}
			
	get boldFileName(): string | null | undefined {
		return this.values.boldFileName
	}
			
	set boldFileName(value: string | null | undefined) {
		if(this.values.boldFileName !== value) {
			this.propertyChanged('boldFileName', value);
			this.values.boldFileName = value;
		}
	}
			
	get isDefault(): boolean | null | undefined {
		return this.values.isDefault
	}
			
	set isDefault(value: boolean | null | undefined) {
		if(this.values.isDefault !== value) {
			this.propertyChanged('isDefault', value);
			this.values.isDefault = value;
		}
	}
			
	
	static get<IType extends IAssetUsage = IAssetUsage>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IAssetUsageFilter = IAssetUsageFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IAssetUsageFilter = IAssetUsageFilter, IType extends IAssetUsage = IAssetUsage>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
