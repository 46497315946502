import { navigate } from 'gatsby';
import React from 'react';
import Col from 'react-bootstrap/Col';
import SVG from '../../components/svg';
import '../Library.scss';
import { Container, HeaderContainer, IconContainer } from './UploadNewAssetButton';

interface RedirectToUploadingButtonProps {
  path: string;
}

export default function RedirectToUploadingButton({path}: RedirectToUploadingButtonProps) {
  return (
    <Col
      xs="12"
      md="6"
      lg="4"
      xl="3"
      className="assets-col"
      onClick={() => {
        navigate(path);
      }}
    >
      <Container>
        <HeaderContainer>
          <p>Upload video content</p>
        </HeaderContainer>
        <IconContainer>
          <SVG name="upload-content" viewBox="0 0 94 76" />
        </IconContainer>
      </Container>
    </Col>
  );
}
