import React from 'react'
import styled from 'styled-components';
import { SequenceAccess } from '../client/sequence'
import User from '../client/user'


interface AvatarProps {
    user: User | SequenceAccess;
    style?: any;
}

const StyledDiv = styled.div`
&.initials {
    background-color: var(--peech-avatar-color-${props => props.color});
}
`

function InitialsAvatar({ user, style }: AvatarProps) {

    const avatarColorsCount: number = React.useMemo(() => {
        const bodyStyles = window.getComputedStyle(document.body);
        return parseInt(bodyStyles.getPropertyValue('--peech-avatar-colors-count')); //get
    }, [])

    function getInitials() {
        if (user.firstName) {
            const { firstName, lastName } = user
            return firstName[0].toUpperCase() + lastName[0].toUpperCase()
        }
        if (user.name) {
            const spaceIndex = user.name.indexOf(' ')

            if (spaceIndex > -1) {
                const firstName = user.name.substr(0, spaceIndex)
                const lastName = user.name.substr(spaceIndex + 1)
                return (firstName[0] + lastName[0]).toUpperCase()
            }
            return user.name[0].toUpperCase()
        }
        return user.email
    }

    function avatarColorIndex() {
        return user.email ? user.email.length % avatarColorsCount : 0;
    }

    return <StyledDiv color={avatarColorIndex()} className="guest-thumb initials" style={style}>
        {getInitials()}
    </StyledDiv >
}

export default function Avatar({ user, style }: AvatarProps) {
    const [src, setSrc] = React.useState<string | null>(null)

    React.useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setSrc(user.thumbUrl);
        }
        img.src = user.thumbUrl || '';
    }, [])

    if (src) {
        return (
            <div className="guest-thumb thumb" style={style}>
                <img src={user.thumbUrl} />
            </div >
        )
    }

    return <InitialsAvatar user={user} style={style} />
}