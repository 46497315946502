import React, { useMemo } from 'react';
import { flow, groupBy, maxBy, map, orderBy } from 'lodash/fp';
import classNames from 'classnames';
import JSZip from 'jszip';
import opentype from 'opentype.js';
import { AppContext } from '../../lib/app-context';
import gfonts from '../../lib/gfonts.json';
import Asset, { AssetStatus, AssetType } from '../../client/asset';
import { handleError } from '../../state/error';
import FileUploader from '../../lib/fileUploader';
import { fileUploaderState } from '../../state/fileUploader';
import Preset, { AssetUsage } from '../../client/preset';
import { savePreset as savePresetFunc } from '../../state/local';
import CustomTypeahead from '../CustomTypeahead/CustomTypeahead';

import './Fonts.scss';
import { trackEvent } from '../../utils/analityics.utils';
import useUserPs from '../../hooks/useUserPs';
import GroupedFontsSelect, { FontOption } from './GroupedFontsSelect/GroupedFontsSelect';

const ARCHIVE_EXTENSION = ['zip', 'rar', 'tar', 'gz', 'tar.gz'];
const FONT_EXTENSIONS = ['otf', 'ttf', 'woff', 'woff2'];

const fontList = [
  ...Object.keys(gfonts.defaults).map((font) => ({ text: font })),
  { text: '______________________', disabled: true },
  ...Object.keys(gfonts.rest).map((font) => ({ text: font })),
];

export const WEIGHT_TO_VAR = {
  100: 'Thin',
  200: 'Extra-Light',
  300: 'Light',
  400: 'Regular',
  500: 'Medium',
  600: 'Semi-Bold',
  700: 'Bold',
  800: 'Extra-Bold',
  900: 'Black',
};

export const isItalic = (fontVariant) => fontVariant.at(-1) === 'i';

interface FontsProps {
  fontAsset: any;
  fontFile: any;
  setFontAsset: any;
  setFontFile: any;
  preset: Preset;
  setPreset(preset: Preset): void;
  isNew?: boolean;
}

export default function Fonts({ fontAsset, preset, setPreset, fontFile, setFontFile, isNew }: FontsProps) {
  const { user, setUser, config } = React.useContext(AppContext);
  const [fontUploadError, setFontUploadError] = React.useState(false);
  const fontFamilyInputRef = React.useRef({});
  const formatGoogleFontVariant = (fontVariant) =>
    fontVariant &&
    WEIGHT_TO_VAR[fontVariant.slice(0, 3)] &&
    classNames(WEIGHT_TO_VAR[fontVariant.slice(0, 3)], { ' Italic': isItalic(fontVariant) });
  const variantMapper = (fontVariant) => ({ text: formatGoogleFontVariant(fontVariant), value: fontVariant });
  const formatFontFileName = (name) =>
    formatGoogleFontVariant(name) || name?.split('.')?.slice(0, -1)?.join('') || name;
  const getFontFileNames = (assetUsage) =>
    assetUsage?.fileNames.map((file) => ({ text: file.split('.').slice(0, -1).join(''), value: file }));
  const getGoogleFontFileNames = (fontFamily) =>
    fontFamily && (gfonts.defaults[fontFamily] || gfonts.rest[fontFamily]).map(variantMapper);
  const withUserPs = useUserPs();

  const [presetFonts, setPresetFonts] = React.useState({
    header: {
      name: 'headerFontFamily',
      title: 'Header Font Family',
      fontFamily: preset.headerFontFamily,
      font: preset.getHeaderFontAsset(),
      regularVariant: preset.headerRegularVariant || preset.getHeaderFontAsset()?.regularFileName,
      boldVariant: preset.headerBoldVariant || preset.getHeaderFontAsset()?.boldFileName,
      files: getFontFileNames(preset.getHeaderFontAsset()) || getGoogleFontFileNames(preset.headerFontFamily),
    },
    body: {
      name: 'fontFamily',
      title: 'Body Font Family',
      fontFamily: preset.fontFamily,
      font: preset.getBodyFontAsset(),
      regularVariant: preset.bodyRegularVariant || preset.getBodyFontAsset()?.regularFileName,
      boldVariant: preset.bodyBoldVariant || preset.getBodyFontAsset()?.boldFileName,
      files: getFontFileNames(preset.getBodyFontAsset()) || getGoogleFontFileNames(preset.fontFamily),
    },
  });

  const [presetFontStyling, setPresetFontStyling] = React.useState<string>('');

  const [usersFontsList, setUsersFontsList] = React.useState([]);
  const [isFontsUpdateInProgress, setIsFontsUpdateInProgress] = React.useState(false);
  const [isLoadingUserFonts, setIsLoadingUserFonts] = React.useState(false);

  function getPresetFonts(): string {
    const { header, body } = presetFonts;
    return `.header.font-preview {
                font-family: "User-Font-${header.font?.assetSid}-${header.font?.regularFileName}" ;
            }
            .body.font-preview {
                font-family: "User-Font-${body.font?.assetSid}-${body.font?.regularFileName}";
            }
            `;
  }

  async function savePreset(preset) {
    return await savePresetFunc(preset, { user, setUser }, undefined, false);
  }

  const fetchUsersFonts = async () => {
    setIsLoadingUserFonts(true);
    try {
      Asset.list({
        typeIn: [AssetType.FONT_OTF, AssetType.FONT_TTF, AssetType.FONT_WOFF, AssetType.FONT_WOFF2, AssetType.FONT_ZIP],
        statusEqual: AssetStatus.READY,
      }).then((res) => {
        const assetsWithFileNames = res.filter((asset) => asset?.fileNames?.length);
        const groupedFonts = groupBy('name', assetsWithFileNames);
        const latestUpdateFonts = map((group) => maxBy('insertTime', group), groupedFonts);

        const usersFonts = flow(
          map((el: Asset) => ({
            value: el?.name ?? '',
            label: el?.name?.split('.')[0] ?? '',
            sid: el.sid,
            isCustom: true,
          })),
          orderBy(
            [(el: { value: string; label: string; sid: string; isCustom: boolean }) => el.label.toLowerCase()],
            ['asc']
          )
        )(latestUpdateFonts);

        setUsersFontsList(usersFonts ?? []);
      });
    } catch (error) {
      console.log('getFontsList', error);
    }
    setIsLoadingUserFonts(false);
  };

  React.useEffect(() => {
    if (presetFonts.header.font?.regularFileName) {
      const { regularFileName, assetSid } = presetFonts.header.font;
      loadAssetFont(assetSid, regularFileName);
    }
  }, [presetFonts.header.font?.regularFileName]);

  React.useEffect(() => {
    if (presetFonts.body.font?.regularFileName) {
      const { regularFileName, assetSid } = presetFonts.body.font;
      loadAssetFont(assetSid, regularFileName);
    }
  }, [presetFonts.body.font?.regularFileName]);

  function updateInput(key, value) {
    if (fontFamilyInputRef.current[key]?.state) {
      fontFamilyInputRef.current[key].state.selected = [value];
    }
  }

  function loadAssetFont(assetSid: string, fileName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const fontName = `${assetSid}-${fileName}`;
      let font_file = new FontFace(
        `User-Font-${fontName}`,
        withUserPs(`url(${config.CHUNKS_URL}/t/asset/f/${assetSid}/${fileName}/${user?.cacheVersion || 0}-fontFile)`)
      );
      font_file
        .load()
        .then((payload) => {
          document.fonts.add(payload);
          setPresetFontStyling(getPresetFonts());
          resolve(true);
          console.log('font assetSid loaded successfully - ', fontName);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }

  async function uploadFont(file: File | Blob, asset: Asset, archived: boolean): void {
    return new Promise((resolve, reject) => {
      const fileUploader = new FileUploader({
        file,
        content: asset,
      })
        .on('done', () => {
          fileUploaderState.set(async ({ [asset.sid]: done, ...uploaders }) => {
            resolve();
            return uploaders;
          });
        })
        .on('canceled', () => fileUploaderState.set(({ [asset.sid]: canceled, ...uploaders }) => uploaders))
        .on('error', (title, message) => {
          handleError({ title, message });
          reject();
        });

      fileUploader.uploadAsset(archived ? undefined : file.name.replace(/\s/g, '-'), undefined, archived);
    });
  }

  const applyUsersFont = async (assetSid: string, font: Object) => {
    const fontToUpdate = font.name === 'fontFamily' ? 'body' : 'header';
    const usageType = {
      header: AssetUsage.USAGE_TYPE.HEADER_FONT_FAMILY,
      body: AssetUsage.USAGE_TYPE.BODY_FONT_FAMILY,
    };

    const existingFont = preset.getFontOfType(usageType[fontToUpdate]);
    const loadedAsset = await Asset.get(assetSid);

    if (!loadedAsset) {
      return;
    }

    const fileNames = loadedAsset.fileNames ?? [loadedAsset.name];

    let fontAsset = existingFont;
    if (existingFont) {
      fontAsset.assetSid = assetSid;
      fontAsset.fileNames = [...fileNames];
      preset.assets = [...preset.assets];
    } else {
      fontAsset = preset.addFontAsset(loadedAsset, usageType[fontToUpdate]);
    }

    fontAsset.regularFileName = fileNames?.find((file) => file.toLowerCase().includes('regular')) || fileNames[0];
    fontAsset.boldFileName = fileNames?.find((file) => file.toLowerCase().includes('bold')) || fileNames[0];

    updateInput(`${lowerCaseFirstWord(font.title)}-regular`, {
      text: formatFontFileName(fontAsset.regularFileName),
      value: fontAsset.regularFileName,
    });
    updateInput(`${lowerCaseFirstWord(font.title)}-bold`, {
      text: formatFontFileName(fontAsset.boldFileName),
      value: fontAsset.boldFileName,
    });

    preset[font.name] = null;
    if (font.name === 'headerFontFamily') {
      preset.headerBoldVariant = null;
      preset.headerRegularVariant = null;
    } else {
      preset.bodyBoldVariant = null;
      preset.bodyRegularVariant = null;
    }

    fontFamilyInputRef?.current?.[fontToUpdate]?.clear();
    if (isNew) {
      setPreset(preset);
    } else {
      var newPreset = await savePreset(preset);
    }
    fontAsset = (isNew ? preset : newPreset).getFontOfType(usageType[fontToUpdate]);

    trackEvent('brand-kit-font-select', { type: fontToUpdate, src: 'upload-file', isInWizard: !!isNew });

    setPresetFonts((state) => ({
      ...state,
      [fontToUpdate]: {
        ...state[fontToUpdate],
        font: fontAsset,
        files: getFontFileNames(fontAsset),
        regularVariant: fontAsset?.regularFileName,
        boldVariant: fontAsset?.boldFileName,
      },
    }));

    fetchUsersFonts();
  };

  async function onFontFileInputChange(files: File[], font: Object) {
    if (!files.length) {
      return;
    }
    setIsFontsUpdateInProgress(true);
    // check if 1 of the selected files is an archive
    const archivedFolder = files.find((file) => {
      const fileExtension = file.name.split('.').pop();
      if (ARCHIVE_EXTENSION.includes(fileExtension!)) {
        return file;
      }
    });

    // validate file size limit
    function validateFileSize(file: File | Blob) {
      if (file.size > 1024 * 1024 * 5) {
        let err = new Error('This file is too large, only font files up to 5MB are allowed.');
        handleError({
          title: 'Font Upload',
          message: err.message,
        });
        return;
      }
    }

    function getFontNames(file: File): Promise<{
      fontFamily: string;
      fontName: string;
    }> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const fileName = file.name.replace(/\s/g, '-').split('.')[0];
        reader.onload = function (e) {
          const arrayBuffer = e.target!.result!;
          try {
            const parseFont = opentype.parse(arrayBuffer);
            if (parseFont) {
              // Access font name and weight information
              const fontFamily: string = parseFont?.names?.preferredFamily?.en || parseFont?.names?.fontFamily?.en;
              resolve({
                fontFamily,
                fontName: parseFont?.names?.fullName?.en || fileName,
              });
            } else {
              resolve({
                fontFamily: fileName,
                fontName: fileName,
              });
            }
          } catch (err) {
            console.error('Font could not be parsed.', err);
            resolve({
              fontFamily: fileName,
              fontName: fileName,
            });
          }
        };
        reader.onerror = function (e) {
          console.error('FileReader encountered an error:', e);
          resolve({
            fontFamily: fileName,
            fontName: fileName,
          });
        };
        reader.readAsArrayBuffer(file);
      });
    }

    if (files.length > 0) {
      let asset = new Asset();

      if (!!archivedFolder) {
        try {
          const jsZip = new JSZip();
          const zip = new JSZip();
          const unzip = await jsZip.loadAsync(archivedFolder);
          let promises: Promise<any>[] = [];

          for (const relativePath of Object.keys(unzip.files)) {
            const zipEntry = unzip.file(relativePath);
            const fileExtension = relativePath.split('.').pop();
            if (zipEntry && !zipEntry.dir) {
              if (!relativePath.includes('__MACOSX/') && FONT_EXTENSIONS.includes(fileExtension!)) {
                promises.push(
                  zipEntry.async('uint8array').then(async (fileData) => {
                    if (fileData) {
                      const fileExtension = relativePath.split('.').pop();
                      const file = new File([fileData], relativePath, { type: `font/${fileExtension}` });

                      const names = await getFontNames(file);
                      names.fontName = names.fontName.replace(/\./g, '-').replace(/\s/g, '-');
                      names.fontFamily = names.fontFamily.replace(/\./g, '-');
                      if (Object.keys(zip.files).length === 0) {
                        asset.name = names.fontFamily;
                      }
                      zip.file(`${names.fontName}.${fileExtension}`, file);

                      return true;
                    }
                  })
                );
              }
            }
          }

          await Promise.all(promises);
          const zipped = await zip.generateAsync({ type: 'blob' });
          validateFileSize(zipped);
          asset.type = Asset.TYPE.FONT_ZIP;
          await asset.save();
          try {
            await uploadFont(zipped, asset, true);
          } catch (error) {
            console.error(error);
            setIsFontsUpdateInProgress(false);
            return;
          }
        } catch (error) {
          console.error(error);
          validateFileSize(archivedFolder);
          asset.name = archivedFolder.name.replace(/\s/g, '-');
          asset.type = Asset.TYPE.FONT_ZIP;
          await asset.save();
          await uploadFont(archivedFolder, asset, true);
        }
      } else {
        // zip all files
        const zip = new JSZip();
        const promises = files.map(async (file, index) => {
          const names = await getFontNames(file);
          names.fontName = names.fontName.replace(/\./g, '-').replace(/\s/g, '-');
          names.fontFamily = names.fontFamily.replace(/\./g, '-');
          const fileExtension = file.type.split('/').pop();
          zip.file(`${names.fontName}.${fileExtension}`, file);
          if (index === 0) {
            asset.name = names.fontFamily;
          }
        });

        await Promise.all(promises);
        asset.type = Asset.TYPE.FONT_ZIP;
        const zipped = await zip.generateAsync({ type: 'blob' });
        validateFileSize(zipped);
        await asset.save();
        try {
          await uploadFont(zipped, asset, true);
        } catch (error) {
          console.error(error);
          setIsFontsUpdateInProgress(false);
          return;
        }
      }

      await applyUsersFont(asset.sid, font);
    }

    setIsFontsUpdateInProgress(false);
  }

  async function onFontFamilyChange(font, fonts) {
    if (!fonts.length) {
      return;
    }

    const fontFamily = fonts[0].text;
    const fontToUpdate = font.name === 'fontFamily' ? 'body' : 'header';
    preset[font.name] = fontFamily;

    const fileNames = getGoogleFontFileNames(fonts[0].text);
    const boldFileName = fileNames.find((file) => file.text.toLowerCase().includes('bold')) || fileNames[0];
    const regularFileName = fileNames.find((file) => file.text.toLowerCase().includes('regular')) || fileNames[0];

    preset[`${fontToUpdate}BoldVariant`] = boldFileName.value;
    preset[`${fontToUpdate}RegularVariant`] = regularFileName.value;

    if (isNew) {
      setPreset(preset);
    } else {
      await savePreset(preset);
    }

    updateInput(`${lowerCaseFirstWord(font.title)}-regular`, {
      text: regularFileName.text,
      value: regularFileName.value,
    });
    updateInput(`${lowerCaseFirstWord(font.title)}-bold`, {
      text: boldFileName.text,
      value: boldFileName.value,
    });

    trackEvent('brand-kit-font-select', { type: fontToUpdate, src: 'list', isInWizard: !!isNew });

    setPresetFonts((state) => ({
      ...state,
      [fontToUpdate]: {
        ...state[fontToUpdate],
        fontFamily,
        font: undefined,
        files: fileNames,
        regularVariant: regularFileName.value,
        boldVariant: boldFileName.value,
      },
    }));
  }

  async function onPresetFontChange(font, fonts: [], key: String) {
    if (!fonts.length || !key) {
      return;
    }

    const fontAsset = preset.getFontOfType(font.font?.usageType);
    const fontToUpdate = font.name === 'fontFamily' ? 'body' : 'header';
    const fontKey = key.includes('regular') ? 'RegularVariant' : 'BoldVariant';

    if (presetFonts[fontToUpdate].fontFamily) {
      preset[`${fontToUpdate}${fontKey}`] = fonts[0].value;
    } else {
      font.font[key] = fonts[0].value;
      preset[font.name] = null;
      if (isNew) {
        preset.assets = preset.assets.map((a) => (a.usageType === font.font.usageType ? font.font : a));
      } else {
        preset.assets = preset.assets.map((a) => (a.sid === font.font.sid ? font.font : a));
        fontAsset[key] = fonts[0].value;
      }
    }

    if (isNew) {
      setPreset(preset);
    } else {
      await savePreset(preset);
    }

    trackEvent('brand-kit-font-select-weight', { type: fontToUpdate, weight: fontKey, isInWizard: !!isNew });

    setPresetFonts((state) => ({
      ...state,
      [fontToUpdate]: {
        ...state[fontToUpdate],
        [fontKey]: fonts[0].value,
      },
    }));
  }

  const lowerCaseFirstWord = (sentence) => sentence.split(' ')[0].toLowerCase();

  const FontSelection = (font: Object, fontList: [], defaultValue: string, key: string, refKey: string) => (
    <div className="font-section" style={{ marginTop: '6px' }}>
      <CustomTypeahead
        id={`${refKey}-typeahead`}
        labelKey="text"
        inputProps={{
          className: 'form-control select font-select',
        }}
        onChange={(fonts) => (key ? onPresetFontChange : onFontFamilyChange)(font, fonts, key)}
        options={fontList || []}
        defaultInputValue={defaultValue || undefined} //@TODO or preset fontFamily
        placeholder="Choose a font..."
        refObj={fontFamilyInputRef}
        refKey={refKey}
      />
    </div>
  );

  const fontsOptions = useMemo(() => {
    const googleFontsGroup = fontList.map((item) => !item.disabled && { value: item.text, label: item.text });

    return [
      {
        label: 'My Fonts',
        options: usersFontsList,
      },
      {
        label: 'Google Fonts',
        options: googleFontsGroup,
      },
    ];
  }, [fontList, usersFontsList]);

  React.useEffect(() => {
    fetchUsersFonts();
  }, []);

  const getSelectedFontOption = (font) => {
    if (!font.fontFamily && !font?.font) {
      return null;
    }
    if (font.font) {
      // if(font.font.fileNames?.length) {
      //     return { label: font.font.fileNames?.[0]?.split(".")?.[0] ?? "", value: font.font.fileNames?.[0] ?? "", sid: font.font.assetSid, isCustom: true};
      // }
      if (font.font.assetSid) {
        const option = usersFontsList.find((item) => item?.sid === font?.font?.assetSid);
        if (option) {
          return option;
        }
        return {
          label: font.font.fileNames?.[0]?.split('.')?.[0] ?? '',
          value: font.font.fileNames?.[0] ?? '',
          sid: font.font.assetSid,
          isCustom: true,
        };
      }
    }
    return { label: font.fontFamily, value: font.fontFamily };
  };

  return (
    <>
      <style>{presetFontStyling}</style>
      <div className="fonts">
        {Object.values(presetFonts).map((font) => (
          <div key={font.name} className="font-item">
            <div className="font-content">
              <h4 className="font-title" style={{ marginBottom: isNew ? '20px' : '8px' }}>
                {font.title}
              </h4>

              <div className="font-select-wrapper">
                <GroupedFontsSelect
                  isLoading={isFontsUpdateInProgress || isLoadingUserFonts}
                  fontsOptions={fontsOptions}
                  selectedOption={getSelectedFontOption(font)}
                  onGoogleFontSelect={(selectedFontValue) => {
                    onFontFamilyChange(font, [{ text: selectedFontValue }]);
                  }}
                  onCustomFontSelect={(selectedFont: FontOption) => {
                    console.log({ selectedFont });
                    applyUsersFont(selectedFont.sid, font);
                  }}
                  onFontAdd={(files) => {
                    onFontFileInputChange(files, font);
                  }}
                />
              </div>
            </div>

            {(font.font || font.fontFamily) && (
              <div className="font-preset">
                <div className="font-select-wrapper regular-font">
                  Regular font
                  {FontSelection(
                    font,
                    font.files || getGoogleFontFileNames(font.fontFamily),
                    formatFontFileName(font.regularVariant),
                    'regularFileName',
                    `${lowerCaseFirstWord(font.title)}-regular`
                  )}
                </div>
                <div className="font-select-wrapper bold-font">
                  Bold font
                  {FontSelection(
                    font,
                    font.files || getGoogleFontFileNames(font.fontFamily),
                    formatFontFileName(font.boldVariant),
                    'boldFileName',
                    `${lowerCaseFirstWord(font.title)}-bold`
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
