import { IBaseType } from './base/base';
import * as Base from './base/cuePoint';
import { CuePointStatus, CuePointType } from './base/cuePoint';
import { Word } from './closedCaption';
import { ChapterOrder, ChapterStatus } from './sequence/chapter';

export { CuePointStatus, CuePointType } from './base/cuePoint';
export interface ICuePoint extends Base.ICuePoint {}
export interface ICuePointProperties extends Base.ICuePointProperties {}
export interface ICuePointFilter extends Base.ICuePointFilter {}


export class CuePointTime {
  static fromScene(cuePoint: CuePoint, scene: ChapterOrder, offset: number | undefined, sequenceDurationMS: number): CuePointTime {
    const time = new CuePointTime();
    const constructor = cuePoint.constructor as typeof CuePoint;
    const isClosedCaption = constructor.className === 'ClosedCaption';
    // must have clipForm in ms and floor
    const clipFormMS = Math.floor((scene.clipFrom as number) * 1000);
    const clipToMS = ((scene.clipFrom || 0) + (scene.duration as number)) * 1000;
    let actualStart = cuePoint.startTime as number;
    // when clipForm and isClosedCaption we need to find the first word that
    // start time is >= to clipForm time
    if (clipFormMS > 0 && isClosedCaption) {
      const firstWordStartTimeBiggerThanClipForm = cuePoint?.words?.find((w) => w.startTime >= clipFormMS);
      if (firstWordStartTimeBiggerThanClipForm) {
        actualStart = firstWordStartTimeBiggerThanClipForm.startTime;
      }
    }
    let actualEnd =
      (cuePoint.endTime as number) > 0
        ? cuePoint.endTime as number
        : (scene.duration as number) * 1000 + (cuePoint.endTime as number);

    // when  isClosedCaption we need to find the last word that
    // end time is <= to clipTo time
    if (isClosedCaption) {
      const lastWordStartTimeBeforeClipTo = [...cuePoint?.words].reverse().find((w) => w.startTime < clipToMS);
      if (lastWordStartTimeBeforeClipTo) {
        actualEnd = lastWordStartTimeBeforeClipTo.endTime;
      }
    }
      
    time.sceneSid = scene.sid || scene.cid;
    time.active = true;
    time.offset = offset;
    // when chapter is not ready - like deactivate intro and outro 
    // and cue-point time related to this chapter return false
    if((scene.cuePointType === CuePointType.INTRO || scene.cuePointType === CuePointType.OUTRO)  && scene.status !== ChapterStatus.READY) {
      time.active = false;
      return time;
    }

    if (scene.duration !== scene.srcDuration) {
      if (actualStart > clipToMS || actualStart < clipFormMS ) {
        time.active = false;
        return time;
      }
      if (actualEnd > clipToMS) {
        if (isClosedCaption) {
          actualEnd = clipToMS;
        } 
      }
    }
    if (clipFormMS) {
      if (actualStart < clipFormMS) {
        if (isClosedCaption) {
          actualStart = clipFormMS;
        } 
        else {
          time.active = false;
          return time;
        }
      }
      (time.offset as number) -= clipFormMS;
    }

    time.start = Math.max(actualStart + (time.offset as number), 0);
    time.end = (actualEnd as number) + (time.offset as number);
   
    if (isClosedCaption) {
      cuePoint.words.forEach((word: Word) => {
        word.enabled = word.enabled || {};
        word.enabled[(scene.sid || scene.cid) as string] =
          (word.startTime as number) >= (actualStart as number) && (word.startTime as number) < (actualEnd as number);
      });
    }
    return time;
  }

  static once(start: number, end: number, sceneSid: string) {
    const time = new CuePointTime();

    time.start = start;
    time.end = end;
    time.sceneSid = sceneSid;
    time.offset = 0;
    time.active = true;

    return time;
  }

  sceneSid?: string | null;
  active?: boolean;
  offset?: number;
  start?: number;
  end?: number;
}

interface CuePointTimes {
  [chapterSid: string]: CuePointTime;
}

export default class CuePoint<IType extends ICuePoint = ICuePoint, ITypeProperties extends ICuePointProperties = ICuePointProperties> extends Base.CuePoint<IType, ITypeProperties> implements ICuePoint {
  origin = {} as ICuePoint;

  times?: CuePointTimes;

  disableCuePoint() {
    this.values.status = CuePointStatus.DELETED;
  }

  get activeTimes(): CuePointTime[] {
    return this.times ? Object.values(this.times).filter((t) => t.active) : [];
  }

  get order(): number {
    return Math.min(...(this.activeTimes || []).map((t) => t.start as number));
  }

  get middle(): number {
    return Math.min(...(this.activeTimes || []).map((t) => ((t.start as number) + (t.end as number)) / 2));
  }

  get offset(): number | undefined {
    const constructor = this.constructor as IBaseType<ICuePoint>;
    if (constructor.className === 'ClosedCaption') {
      throw new Error('ClosedCaption may have multiple offsets');
    }
    return this.activeTimes.length && this.activeTimes[0].offset;
  }

  /**
   * @returns {number}
   */
  get middleTime() {
    return ((this.endTime as number) + (this.startTime as number)) / 2;
  }
}
