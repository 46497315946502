import React from 'react';
import { navigate } from 'gatsby';

import Modal from 'react-bootstrap/Modal';

import Button, { ButtonSize, ButtonVariant } from '../../ui-components/Button';
import classNames from 'classnames';
import { loadedPresetsState } from '../../state/local';
import Preset from '../../client/preset';

import './create-error-dialog.scss';
import SVG from '../../components/svg';
import { trackEvent } from '../../utils/analityics.utils';

interface BrandkitMissingModalProps {
  onHide(): void;
  title?: string;
  button?: string;
  brandkitMissingLogo?: boolean;
  projectsLimitReached?: boolean;
  trialEndReached?: boolean;
  tooLongVideo?: boolean;
  upgrade?: boolean;
  trigger?: string;
}

export default function CreateErrorDialog({
  onHide,
  title,
  button,
  brandkitMissingLogo,
  projectsLimitReached,
  trialEndReached,
  tooLongVideo,
  upgrade,
  trigger,
}: BrandkitMissingModalProps) {
  const loadedPresets = loadedPresetsState.use();
  const defaultPreset = Preset.getDefaultPreset(loadedPresets);

  React.useEffect(() => {
    if(trigger){
        trackEvent('Notification', {trigger});
    }
  }, [trigger]);

  function onActionClick() {
    if (projectsLimitReached || trialEndReached || tooLongVideo) {
      navigate('/plans');
    } else if (brandkitMissingLogo) {
      navigate('/brand', { state: { brandkit: defaultPreset?.sid } });
    } else {
      navigate('/brand');
    }
    onHide();
  }

  const modalClassNames = () =>
    classNames('create-error-dialog', {
      wide: brandkitMissingLogo || projectsLimitReached || trialEndReached || upgrade,
      upgrade: upgrade,
    });
  const missingLogoTitle = 'Please upload a main logo to your default brand kit before creating a project';
  const projectLimitTitle =
    'You have reached your limit for creating projects this month, upgrade to continue Peeching your videos';
  const trialEndTitle = 'Your free trial has ended, please choose a plan and continue creating with Peech!';
  const buttonLabel = classNames({
    Upgrade: projectsLimitReached || trialEndReached || tooLongVideo,
    'Go To Brand Kit': brandkitMissingLogo,
    'New Brand Kit': !brandkitMissingLogo && !projectsLimitReached && !trialEndReached && !tooLongVideo,
  });

  return (
    <Modal
      show={true}
      className={modalClassNames()}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onHide}
      centered
      size="lg"
    >
      <Modal.Body>
        <div className="heading">
          {brandkitMissingLogo || projectsLimitReached || trialEndReached || upgrade ? (
            <div className="title">
              {upgrade
                ? title
                : projectsLimitReached
                ? projectLimitTitle
                : trialEndReached
                ? trialEndTitle
                : missingLogoTitle}
            </div>
          ) : (
            <>
              <div className="face-container">
                <SVG className="face-color" name="smiley-face" width="63" height="63" viewBox="0 0 63 63" />
              </div>
              <h3 className="title">{title}</h3>
              <div className="body-text">
                The brand kit will calibrate Peech and help the
                <br />
                automation process to keep your brand guidelines.
                <div className="body-text-bold">Please set up your brand before creating your first video.</div>
              </div>
            </>
          )}
        </div>

        <Button
          id="create-dialog-action"
          className={classNames({ 'bg-rainbow upgrade-btn': projectsLimitReached || trialEndReached || tooLongVideo })}
          label={button || buttonLabel}
          size={ButtonSize.Large}
          variant={ButtonVariant.Primary}
          onClick={onActionClick}
        />
      </Modal.Body>
    </Modal>
  );
}
