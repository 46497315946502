
import * as Base from '../base/preset/assetUsage';

export { AssetUsageUsageType } from '../base/preset/assetUsage';

export interface IAssetUsage extends Base.IAssetUsage {

}

export interface IAssetUsageProperties extends Base.IAssetUsageProperties {

}

export interface IAssetUsageFilter extends Base.IAssetUsageFilter {

}

export default class AssetUsage<IType extends IAssetUsage = IAssetUsage, ITypeProperties extends IAssetUsageProperties = IAssetUsageProperties> extends Base.AssetUsage<IType, ITypeProperties> implements IAssetUsage {
}