
import * as Base from './base/content';

export const ContentStatus = Base.ContentStatus;

export interface IContent extends Base.IContent {

}

export interface IContentProperties extends Base.IContentProperties {

}

export interface IContentFilter extends Base.IContentFilter {

}

export default class Content<IType extends IContent = IContent, ITypeProperties extends IContentProperties = IContentProperties> extends Base.Content<IType, ITypeProperties> implements IContent {
}