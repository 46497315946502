import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from '../../../components/svg';
import { trackEvent } from '../../../utils/analityics.utils';
import { checkAllDeletedState } from '../Editors/EditorContent';

interface SubtitleToolsProps {
    resetSelection(): void;
    handleDeletion(e: MouseEvent): void;
    acceptTextChange(): void;
    onMouseEnter(): void;
    onMouseLeave(): void;
    deleted: boolean;
}
export default function SubtitleTools({ resetSelection, handleDeletion, handleCuts, acceptTextChange, deleted, onMouseEnter, onMouseLeave }: SubtitleToolsProps) {

    const checkAllDeleted = checkAllDeletedState.use();
    
    return <div className="subtitles--tools" data-cy="selected-words-action-btn" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {!deleted && <>

            <div className="tools-action" onClick={(e) => {
                 trackEvent('content-editor-edit', { action: 'cut-that' });
                handleDeletion(e)
            }} data-cy="tools-action">
                <OverlayTrigger placement="bottom-start"
                    overlay={
                        <Tooltip id='cut-excluded-tooltip' className="tooltip-peech-dark">
                            Cut selected content out
                        </Tooltip>}>
                    <SVG name="cut-content" className="cut-excluded" />
                </OverlayTrigger>
            </div>

            <div className="tools-action" onClick={(e) => {
                trackEvent('content-editor-edit', { action: 'cut-all-but-this' });
                handleCuts(e)
            }} data-cy="tools-action-cut-included">
                <OverlayTrigger placement="bottom-start"
                    overlay={
                        <Tooltip id='cut-included-tooltip' className="tooltip-peech-dark">
                            Keep only selected content
                        </Tooltip>}>
                    <SVG name="cut-content" className="cut-included" />
                </OverlayTrigger>
            </div>

        </>}
        {deleted && <>
            <div className="tools-action" onClick={(e) => {
                trackEvent('content-editor-edit', { action: 'uncut' });
                handleDeletion(e);
                checkAllDeletedState.set(false);

                
            }} data-cy="tools-action">
                <SVG name="undo" />
            </div>
        </>}
    </div>
}