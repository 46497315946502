
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'

export interface ILogoEffect extends IBase {
	start?: number | null;
	duration?: number | null;
	x?: number | null;
	y?: number | null;
	type?: string | null;
	enableX?: boolean | null;
	enableY?: boolean | null;
	velocityType?: string | null;
	direction?: string | null;
	opacity?: number | null;
	width?: number | null;
	height?: number | null;
}

export interface ILogoEffectProperties extends IBaseProperties {
	start?: boolean;
	duration?: boolean;
	x?: boolean;
	y?: boolean;
	type?: boolean;
	enableX?: boolean;
	enableY?: boolean;
	velocityType?: boolean;
	direction?: boolean;
	opacity?: boolean;
	width?: boolean;
	height?: boolean;
}

export interface ILogoEffectFilter extends IBaseFilter {
}

export class LogoEffect<IType extends ILogoEffect = ILogoEffect, ITypeProperties extends ILogoEffectProperties = ILogoEffectProperties> extends Base<IType, ITypeProperties> implements ILogoEffect {
	
	static className = 'LogoEffect';
	static NAME = 'logoEffect';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		start: true,
		duration: true,
		x: true,
		y: true,
		type: true,
		enableX: true,
		enableY: true,
		velocityType: true,
		direction: true,
		opacity: true,
		width: true,
		height: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get start(): number | null | undefined {
		return this.values.start
	}
			
	set start(value: number | null | undefined) {
		if(this.values.start !== value) {
			this.propertyChanged('start', value);
			this.values.start = value;
		}
	}
			
	get duration(): number | null | undefined {
		return this.values.duration
	}
			
	set duration(value: number | null | undefined) {
		if(this.values.duration !== value) {
			this.propertyChanged('duration', value);
			this.values.duration = value;
		}
	}
			
	get x(): number | null | undefined {
		return this.values.x
	}
			
	set x(value: number | null | undefined) {
		if(this.values.x !== value) {
			this.propertyChanged('x', value);
			this.values.x = value;
		}
	}
			
	get y(): number | null | undefined {
		return this.values.y
	}
			
	set y(value: number | null | undefined) {
		if(this.values.y !== value) {
			this.propertyChanged('y', value);
			this.values.y = value;
		}
	}
			
	get type(): string | null | undefined {
		return this.values.type
	}
			
	set type(value: string | null | undefined) {
		if(this.values.type !== value) {
			this.propertyChanged('type', value);
			this.values.type = value;
		}
	}
			
	get enableX(): boolean | null | undefined {
		return this.values.enableX
	}
			
	set enableX(value: boolean | null | undefined) {
		if(this.values.enableX !== value) {
			this.propertyChanged('enableX', value);
			this.values.enableX = value;
		}
	}
			
	get enableY(): boolean | null | undefined {
		return this.values.enableY
	}
			
	set enableY(value: boolean | null | undefined) {
		if(this.values.enableY !== value) {
			this.propertyChanged('enableY', value);
			this.values.enableY = value;
		}
	}
			
	get velocityType(): string | null | undefined {
		return this.values.velocityType
	}
			
	set velocityType(value: string | null | undefined) {
		if(this.values.velocityType !== value) {
			this.propertyChanged('velocityType', value);
			this.values.velocityType = value;
		}
	}
			
	get direction(): string | null | undefined {
		return this.values.direction
	}
			
	set direction(value: string | null | undefined) {
		if(this.values.direction !== value) {
			this.propertyChanged('direction', value);
			this.values.direction = value;
		}
	}
			
	get opacity(): number | null | undefined {
		return this.values.opacity
	}
			
	set opacity(value: number | null | undefined) {
		if(this.values.opacity !== value) {
			this.propertyChanged('opacity', value);
			this.values.opacity = value;
		}
	}
			
	get width(): number | null | undefined {
		return this.values.width
	}
			
	set width(value: number | null | undefined) {
		if(this.values.width !== value) {
			this.propertyChanged('width', value);
			this.values.width = value;
		}
	}
			
	get height(): number | null | undefined {
		return this.values.height
	}
			
	set height(value: number | null | undefined) {
		if(this.values.height !== value) {
			this.propertyChanged('height', value);
			this.values.height = value;
		}
	}
			
	
	static get<IType extends ILogoEffect = ILogoEffect>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends ILogoEffectFilter = ILogoEffectFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends ILogoEffectFilter = ILogoEffectFilter, IType extends ILogoEffect = ILogoEffect>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
