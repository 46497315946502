import {downloadAllowance} from "../utils/downloads.utils";
import {trackEvent} from "../utils/analityics.utils";
import {signalPlaybackShouldPause} from "../state/playback";
import {firstDraftState} from "../components/draftModal/DraftModal";
import {DownloadDialogState, DownloadLimitReachedDialogState, WatermarkAlertModalDialogState} from "../state/local";
import {downloadStatus, sequenceState} from "../state/sequence";
import React, {useMemo} from "react";
import {AppContext} from "../lib/app-context";
import {DOWNLOAD_STATUS} from "../state/sequence";
import {FeatureFeatureName} from "../client/base/feature";

export default function useOpenDownloadDialog(): () => Promise<void> {
    const { config, plan } = React.useContext(AppContext);
    const sequence = sequenceState.use();

    const customWatermarkAllowed = useMemo(() => {
        return plan?.planAllowedTierFeatures?.includes(FeatureFeatureName.WITHOUT_PEECH_WATERMARK);
    },[plan?.planAllowedTierFeatures])

    async function openDownloadDialog(){
        const downloadNotStarted = downloadStatus.get().MODE === DOWNLOAD_STATUS.NOT_STARTED;
        const downloadAllowanceResponse = downloadNotStarted ? await downloadAllowance(config, sequence.sid!) : {isDownloadAllowed: true};

        if (!downloadAllowanceResponse.isDownloadAllowed) {
            trackEvent('download-limit-reached-dialog-open');
            DownloadLimitReachedDialogState.set({isUpgradeModalOpen: true, isDownloadAllowed: downloadAllowanceResponse.isDownloadAllowed, data: downloadAllowanceResponse.data});
        } else if (downloadNotStarted && !customWatermarkAllowed) {
            trackEvent('watermark-alert-dialog-open');
            WatermarkAlertModalDialogState.set(true);
        } else {
            trackEvent('download-dialog-open');
            signalPlaybackShouldPause();
            firstDraftState.set(false);
            DownloadDialogState.set(true);
        }
    }

    return (openDownloadDialog);
}
