import styled from "@emotion/styled/macro";
import React from 'react';
import Col from 'react-bootstrap/Col';
import { COLORS } from '../../../constants/colors';
import SVG from '../../components/svg';
import { LottieLoader } from '../../components/common/lottie-loader/lottie-loader.component'
import RequestCustomPAckageButton from '../../components/common/lottie-loader/request-custom-package-button'



const VPItem = styled.div` 
    position: relative;
    height: fit-content;
    width: 92%;
    display: flex;
    flex-direction: column;
    border: 1px solid;
    border-color: ${COLORS.peech_gray_100};
    border-radius: 8px;
    min-width: 180px;
    margin: 10px; 
`

const PackagePreview = styled.div` 
    width: 100%;
    min-width: 180px;
    aspect-ratio: 16 / 9;
    background-color: ${COLORS.peech_primary_1_50};
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    overflow: hidden;
`

const PackageNameWrapper = styled.div` 
    width: 100%;
    height: 30px;
    padding: 5px 10px 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 8px 8px;
   
    span {
        width: 100%;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: ${COLORS.peech_gray_500};
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 17px;
    }

    label {
        height: 19px;
        width: 19px; 
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: solid 1px;
        border-color: ${COLORS.peech_gray_400};
        border-radius: 4px;
        position: relative;
        input {
            height: 10px;
            width: 10px;
            margin: 0;
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
    }
`





interface RequestCustomButtonProps {
    customRequested: boolean;
    setCustomRequested: Function;
}

export default function RequestCustomButton({ customRequested, setCustomRequested }: RequestCustomButtonProps) {


    const onItemClick = () => {
        setCustomRequested(!customRequested)
    }


    return (
        <Col xs="12" sm="6" lg="4" xl="3">
            <VPItem>
                <PackagePreview onClick={onItemClick} >
                    <LottieLoader animationData={RequestCustomPAckageButton} loop={true} width={'100%'} />
                </PackagePreview>
                <PackageNameWrapper >
                    <span >
                        Request custom visual style
                    </span>
                </PackageNameWrapper>
            </VPItem>
        </Col>
    );
}
