import styled from '@emotion/styled/macro';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { entity } from 'simpler-state';
import { FeatureFeatureName } from '../../../client/base/feature';
import CuePoint from '../../../client/cuePoint';
import { AppContext } from '../../../lib/app-context';
import {
  cuePointsState,
  cuePointsVersionState,
  setHighlightedWords
} from '../../../state/cuePoints';
import { HIGHLIGHT_TOOLTIP_TEXT, menuHeaderTooltipTextState } from '../../../state/menu';
import { activeClosedCaptionState, playbackState, resetActiveClosedCaption } from '../../../state/playback';
import { sequenceSave, sequenceState } from '../../../state/sequence';
import {StyledLabel, StyledMenuItem} from '../../../ui-components/Menu/MenuComponents.styled';
import Tabs from '../../../ui-components/Tabs/Tabs';
import { trackEvent } from '../../../utils/analityics.utils';
import { openPeechProUpgradeModal } from '../../layout';
import SVG from '../../svg';
import ContextMenu from '../context-menu';
import CaptionsStyleForm from './CaptionsStyleForm';
import './Subtitles.scss';
import { SentenceComponent } from './components/sentence.component';
import { BaseTitle } from './layout.styled';
import User from '../../../client/user';
import {isInIframe} from "../../../utils/window.utils";
import {RegenerateButton} from "./components/RegenerateButton";
import {allowRegenerateCcState} from "../../../state/local";
import {InfoPopup} from "../../infoPopup";
import {regenerateButtonIsDisabledState} from "../../../client/base/base";

const TABS = {
  SUBTITLE: 0,
  DESIGN: 1,
};

export const shouldResetSelectionState = entity(false);

const DesignAndPositionOption = styled(BaseTitle)`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 13px;
  border-bottom: 1px solid #e6e9ef;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
`;



export default function SubtitlesProperties({ subtitleElements }) {
  const { config } = React.useContext(AppContext);
  const sequence = sequenceState.use();
  const [enabled, setEnabled] = React.useState(sequence.useCaptions);
  const [enabledBurn, setEnabledBurn] = React.useState(sequence.burnCaptions);
  const [isFirstRenderAutoScroll, setIsFirstRenderAutoScroll] = React.useState(true);

  const regenerateButtonIsDisabled = regenerateButtonIsDisabledState.use();
  const allowRegenerateCc = allowRegenerateCcState.use()
  const contextMenuActive = React.useRef(false);

  React.useEffect(() => {
    setEnabled(sequence.useCaptions);
  }, [sequence.useCaptions]);

  async function onEnabledChange() {
    trackEvent('subtitle-show-hide-all', { action: enabled ? 'hide-all-subtitles' : 'show-all-subtitles' });
    sequence.useCaptions = !enabled;
    setEnabled(sequence.useCaptions);
    sequenceSave(sequence);
  }

  function onDownloadSubtitles() {
    const version = new Date().getTime();
    const link = document.createElement("a");
    link.onclick = (e) => e.stopPropagation();
    link.href = `${config.CONTENT_URL}/c/s/s/${sequence.sid}/${version}.srt?ps=${User.ps}`;
    link.target = "_blank";
    link.click();
  }

  function onBurnCaptionsChange() {
    sequence.burnCaptions = !enabledBurn;
    setEnabledBurn(sequence.burnCaptions);
    sequenceSave(sequence);
  }
  function onCaptionsBackgroundEnabled(event) {
    sequence.captionsBackgroundOpacity = event.target.checked ? 0.7 : 0;
    sequenceSave(sequence);
  }

  function onContextMenu(active) {
    contextMenuActive.current = active;
  }

  function onMouseLeave() {
    // TODO find another trigger, this one triggered when I mouseover the menus
    // if(!contextMenuActive.current) {
    // 	contextMenu.hideAll()
    // }
  }

  const subtitleElementsArray = Object.values(subtitleElements || {});
  const cuePointsVersion = cuePointsVersionState.use();
  const virtuoso = React.useRef(null);
  const flattenChaptersCaptions = Object.values(subtitleElementsArray);
  const flattenCaptions = flattenChaptersCaptions.flat();
  const groupCounts = flattenChaptersCaptions.map((c) => c?.length);
  const sidToGroupIndex = (chapterSid) =>
    flattenChaptersCaptions.findIndex((group) => group[0].menuChapter === chapterSid);
  const activeClosedCaption = activeClosedCaptionState.use();
  const playback = playbackState.use();
  const { plan } = useContext(AppContext);

  const scrollToIndex = (index) =>
    virtuoso && virtuoso.current && virtuoso.current.scrollToIndex({
      index,
      align: 'center',
      behavior: 'smooth',
    });

  window.scrollToCC = scrollToIndex; // Used for test, don't delete

  React.useEffect(() => {
    if (activeTab === TABS.DESIGN) {
      return;
    }
    if (activeClosedCaption && activeClosedCaption.chapterSid) {
      const chapterIndex = sidToGroupIndex(activeClosedCaption.chapterSid);
      if (chapterIndex < 0) {
        return;
      }
      const closedCaptionInnerIndex = flattenChaptersCaptions[chapterIndex]?.findIndex(
        (c) => c.sid === activeClosedCaption.closedCaption
      );
      const closedCaptionIndex = new Array(chapterIndex).fill(0).reduce((sum, curr, index) => {
        return (sum += groupCounts[index]);
      }, closedCaptionInnerIndex);

      if (Number.isInteger(closedCaptionInnerIndex) && Number.isInteger(closedCaptionIndex)) {
       setTimeout(() => {
         scrollToIndex(closedCaptionIndex);
         isFirstRenderAutoScroll && setIsFirstRenderAutoScroll(false);
       }, isFirstRenderAutoScroll ? 1000 : 0);
      }

      resetActiveClosedCaption();
    }
  }, [activeClosedCaption?.chapterSid, activeClosedCaption?.closedCaption, isFirstRenderAutoScroll]);

  const isCustomPositionCloseCaption = React.useMemo(() => {
    return flattenChaptersCaptions[0]?.find((closeCaption) => {
      if (closeCaption?.words?.length > 0) {
        const lastWord = closeCaption.words.at(-1);
        if (lastWord?.positionX || lastWord?.positionY) {
          return true;
        }
      }
      return false;
    });
  }, [flattenChaptersCaptions, cuePointsVersion]);

  const itemContent = (index) => {
    const closedCaption = flattenCaptions[index];
    if (!closedCaption || !closedCaption.times[closedCaption.menuChapter]) {
      if (!closedCaption.times[closedCaption.menuChapter]) {
        Sentry.captureException(
          new Error(
            `closedCaption.times[closedCaption.menuChapter] is undefined for sequence ${sequence.sid} for closedCaption ${closedCaption.sid}`
          )
        );
      }

      return null;
    }
    return (
      <SentenceComponent
        closedCaption={closedCaption}
        chapterSid={closedCaption?.menuChapter}
        index={index}
        scrollToIndex={() => {}}
      />
    );
  };

  const [activeTab, setActiveTab] = React.useState(TABS.SUBTITLE);

  const cuePointsData = cuePointsState.use();

  // setHighlightedWords when cue points changed
  React.useEffect(() => {
    let closedCaptionCuePointsWords = [];
    Object.values(cuePointsData).map((cuePoint) => {
      if (cuePoint?.type === CuePoint.TYPE.CLOSED_CAPTION && cuePoint?.words.length) {
        closedCaptionCuePointsWords = closedCaptionCuePointsWords.concat(cuePoint.words);
      }
    });
    setHighlightedWords(closedCaptionCuePointsWords, true);
  }, [cuePointsVersion]);

  const subtitleRef = React.useRef();
  const designRef = React.useRef();

  const subtitleItems = [
    { name: 'Subtitles', value: TABS.SUBTITLE, ref: subtitleRef },
    { name: 'Design', value: TABS.DESIGN, ref: designRef },
  ];

  const menuHeaderTooltipText = menuHeaderTooltipTextState.use();

  const subtitlesActions = [
    {
      svg: 'download',
      className: 'menu-control--custom-icon',
      onClick: onDownloadSubtitles,
      tooltip: 'Download subtitles',
    },
    {
      svg: enabled ? 'show' : 'hide',
      className: 'menu-control--custom-icon',
      onClick: onEnabledChange,
    },
  ];
  
  const regenerateV2CaptionsOnPeechWeb = () => {
    console.log("ccc","send postMessage REGENERATE_CC")
    isInIframe() && window.top?.postMessage('REGENERATE_CC', '*')
  }

  const customizeSubtitlesAllowed = plan?.planAllowedTierFeatures?.includes(FeatureFeatureName.CUSTOMIZE_SUBTITLE);

  return (
    <div className="subtitles parent" onMouseLeave={onMouseLeave}>
      <div style={{ paddingInline: 20 }}>
        <StyledMenuItem 
          label={
            <StyledLabel>
              Subtitles 
              <InfoPopup text={[menuHeaderTooltipText, HIGHLIGHT_TOOLTIP_TEXT]} />
            </StyledLabel>} 
          id="subtitles-menu" 
          actions={subtitlesActions} 
          bordered={false} />
      </div>

      <div className={classNames('tabs-with-tooltip', { disabled: !enabled })}>
        <Tabs tabsItems={subtitleItems} onChange={(selectedTab) => setActiveTab(selectedTab)} />
      </div>
      {activeTab === TABS.SUBTITLE && allowRegenerateCc && 
        <RegenerateButton disabled={regenerateButtonIsDisabled || !enabled} onClick={() => regenerateV2CaptionsOnPeechWeb()}>
          <SVG name="reload" />
          Regenerate from transcription
        </RegenerateButton>}
      {activeTab === TABS.SUBTITLE && (
        <div
          className={classNames('sentences', { disabled: !enabled })}
          data-cy="subtitles-sentences"
          data-captions={flattenCaptions.length}
        >

          {!!groupCounts?.length && (
            <Virtuoso
              ref={virtuoso}
              // groupCounts={groupCounts}
              initialTopMostItemIndex={0}
              // groupContent={groupContent}
              itemContent={itemContent}
              overscan={{ main: 30, reverse: 10 }}
              data-cy="test-sc"
              data={flattenCaptions}
              onScroll={() => {
                playback?.isPlayerPlaying && document.activeElement.blur();
              }}
            />
          )}
        </div>
      )}

      {activeTab === TABS.DESIGN && (
        <React.Fragment>
          <div style={{ paddingInline: 14 }}>
            <DesignAndPositionOption>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Design & Position
                <SVG
                  name="diamond-gradient"
                  width={24}
                  height={24}
                  style={{ opacity: customizeSubtitlesAllowed ? 0 : 1, marginLeft: '8px' }}
                  onClick={(e) => {
                    if (!customizeSubtitlesAllowed) {
                      e.stopPropagation();
                      openPeechProUpgradeModal(
                        user,
                        'Subtitles design and position',
                        FeatureFeatureName.CUSTOMIZE_SUBTITLE
                      );
                    }
                  }}
                />
              </div>
            </DesignAndPositionOption>
          </div>

          <CaptionsStyleForm
            disabled={!enabled}
            featureNotAllowed={!customizeSubtitlesAllowed}
            isCustomPositionCloseCaption={isCustomPositionCloseCaption}
          />
        </React.Fragment>
      )}

      <ContextMenu onContextMenu={onContextMenu} />
    </div>
  );
}
