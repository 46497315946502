import { appConfig } from '../../lib/app-context';
import * as Base from '../base/sequence/logo';
import useUserPs from "../../hooks/useUserPs";


export interface ILogo extends Base.ILogo {

}

export interface ILogoProperties extends Base.ILogoProperties {

}

export interface ILogoFilter extends Base.ILogoFilter {

}


export function fileFromCanvas(canvas: HTMLCanvasElement): Blob {
    const dataUrl = canvas.toDataURL('image/png');
    const blobBin = atob(dataUrl.split(',')[1]);
    const array = [];
    for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/png' });
}

export function numbersToColor([r, g, b]: number[]) {
    return (
        '#' +
        (r % 256).toString(16).padStart(2, '0') +
        (g % 256).toString(16).padStart(2, '0') +
        (b % 256).toString(16).padStart(2, '0')
    )
}

interface IMostFrequent {
    ret: Uint8ClampedArray;
    max: number;
}

export function mostFrequent(array: Uint8ClampedArray[]): Uint8ClampedArray {
    return array
        .map(value => ({ret: value, max: array.filter(v => v === value).length} as IMostFrequent))
        .reduce(({ret, max}: IMostFrequent, {ret: value, max: count}: IMostFrequent) => {
            return (count > max ? {ret: value, max: count} : {ret, max}) as IMostFrequent;
        }, ({ret: new Uint8ClampedArray(), max: 0} as IMostFrequent)).ret;
}

export function findLastIndex(array: Uint8ClampedArray, condition: (i: number) => boolean) {
    var index = array.slice().reverse().findIndex(condition);
    var count = array.length - 1
    return index >= 0 ? count - index : index;
}

export function imgToCanvas(img: HTMLImageElement): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const context = canvas.getContext("2d") as CanvasRenderingContext2D;
    context.drawImage(img, 0, 0, canvas.width, canvas.height);
    return canvas;
}

interface ILogoParsed {
    width: number;
    height: number;
}
export default class Logo<IType extends Base.ILogo= Base.ILogo> extends Base.Logo<IType> implements ILogo {

    static parseLogo(assetSid: string, cacheVersion: number | null = 0): Promise<ILogoParsed> {
        const withUserPs = useUserPs();
        return new Promise((resolve, reject) => {
            if (!assetSid) {
                reject(new Error('No asset found'))
            }
            const img = new Image()
            img.onerror = reject
            img.onload = () => resolve({
                width: img.naturalWidth,
                height: img.naturalHeight,
            })
            img.crossOrigin = "Anonymous";
            img.src = withUserPs(`${appConfig.CHUNKS_URL}/asset/${assetSid}/logo/${cacheVersion}.png`);
        })
    }
}
