
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export enum FolderStructureFolderType {
	ASSET = 1,
	SEQUENCE = 2,
	LOTTIE_PACKAGE = 3,
};

export interface IFolderStructure extends IBase {
	status?: number | null;
	userSid?: string | null;
	type?: number | null;
	ownerType?: number | null;
	parentSid?: string | null;
	systemId?: string | null;
	name?: string | null;
}

export interface IFolderStructureProperties extends IBaseProperties {
	status?: boolean;
	userSid?: boolean;
	type?: boolean;
	ownerType?: boolean;
	parentSid?: boolean;
	systemId?: boolean;
	name?: boolean;
}

export interface IFolderStructureFilter extends IBaseFilter {
	typeEqual?: number;
	parentSidEqual?: string;
	parentSidIsNull?: boolean;
	systemIdEqual?: string;
}

export class FolderStructure<IType extends IFolderStructure = IFolderStructure, ITypeProperties extends IFolderStructureProperties = IFolderStructureProperties> extends Base<IType, ITypeProperties> implements IFolderStructure {
	
	static className = 'FolderStructure';
	static NAME = 'folderStructure';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		userSid: true,
		type: true,
		ownerType: true,
		parentSid: true,
		systemId: true,
		name: true,
	};
	
	/**
	 * @deprecated use FolderStructureFolderType instead
	 */
	static FOLDER_TYPE: any = {
		ASSET: FolderStructureFolderType.ASSET,
		SEQUENCE: FolderStructureFolderType.SEQUENCE,
		LOTTIE_PACKAGE: FolderStructureFolderType.LOTTIE_PACKAGE,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get userSid(): string | null | undefined {
		return this.values.userSid
	}
			
	get type(): number | null | undefined {
		return this.values.type
	}
			
	set type(value: number | null | undefined) {
		if(this.values.type !== value) {
			this.propertyChanged('type', value);
			this.values.type = value;
		}
	}
			
	get ownerType(): number | null | undefined {
		return this.values.ownerType
	}
			
	get parentSid(): string | null | undefined {
		return this.values.parentSid
	}
			
	set parentSid(value: string | null | undefined) {
		if(this.values.parentSid !== value) {
			this.propertyChanged('parentSid', value);
			this.values.parentSid = value;
		}
	}
			
	get systemId(): string | null | undefined {
		return this.values.systemId
	}
			
	set systemId(value: string | null | undefined) {
		if(this.values.systemId !== value) {
			this.propertyChanged('systemId', value);
			this.values.systemId = value;
		}
	}
			
	get name(): string | null | undefined {
		return this.values.name
	}
			
	set name(value: string | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	
	static get<IType extends IFolderStructure = IFolderStructure>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IFolderStructureFilter = IFolderStructureFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IFolderStructureFilter = IFolderStructureFilter, IType extends IFolderStructure = IFolderStructure>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
