import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';
import GraphicColor, { IGraphicColor, IGraphicColorProperties } from '../../client/graphicColor';
import { sequenceState } from '../../state/sequence';
import { Stack } from '../../ui-components/Menu/MenuComponents.styled';
import { Expandable } from './subtitles/Expandable';
import { SequenceColorPicker, SequenceColorPickerValue } from './subtitles/SequenceColorPicker';
import { SCENE_TYPE } from '../introAndOutro/IntroAndOutro';
import { SubtitleColorsObjectType } from './subtitles/CaptionsStyleForm';
import {useClickOutsideEvent} from "../../hooks/useClickOutsideEvent";

const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 6px;
  font-family: 'Open Sans', sans-serif;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  min-width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
`;

const MainRow = styled.div`
  padding-left: 3px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  ${Title} {
    margin: unset;
  }
`;

const ColorsPanel = styled.div`
  width: fit-content;
  height: 100%;
  padding-block: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ColorItem = styled.div<{ backgroundColor?: string | null; isSelected?: boolean }>`
  border: solid 1px;
  border-color: ${COLORS.peech_neutral_black};
  border-radius: 4px;
  width: 15px;
  height: 15px;
  background-color: ${({ backgroundColor }) => backgroundColor || COLORS.peech_neutral_white};
  scale: ${({ isSelected }) => (isSelected ? '1.25' : '1')};
  margin-inline: 2px;
  cursor: pointer;
  transition: scale 0.2s ease-in-out;

  &::after {
    content: '';
    position: absolute;
    background-color: ${(props): string => (!props.backgroundColor ? COLORS.peech_alert_error : 'none')};
    top: -2px;
    bottom: -2px;
    right: 6px;
    width: 1px;
    transform: rotate(45deg);
  }
`;

const SelectedColorProcessingBlock = styled.div`
  width: 100%;
  min-width: 100%;
  height: fit-content;
  padding-block: 4px;
  padding-left: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  ${Title} {
    font-weight: 500;
  }
`;

export interface VisualsMenuColorsComponentProperties {
  colorsObjects: GraphicColor[] | SubtitleColorsObjectType[];
  onColorChange?: (
    value: SequenceColorPickerValue | null,
    colorObj: GraphicColor | SubtitleColorsObjectType | undefined,
    type: string | undefined
  ) => void;
  type?: string;
  shouldScrollIntoView?: boolean;
  withNoneColor?: boolean;
  withStatic?: boolean;
  shouldClosePaletteAfterSelection?: boolean;
}

export default function VisualsMenuColorsComponent({
  colorsObjects,
  onColorChange,
  type,
  shouldScrollIntoView = false,
  withNoneColor = true,
  withStatic = true,
  shouldClosePaletteAfterSelection = false,
}: VisualsMenuColorsComponentProperties) {
  const sequence = sequenceState.use();
  const [selectedColorsObject, setSelectedColorsObject] =
    React.useState<GraphicColor<IGraphicColor, IGraphicColorProperties> | SubtitleColorsObjectType | null>(null);
  const [selectedPaletteObject, setSelectedPaletteObject] = React.useState<{
    selectedColor:  string | number | undefined | null,
    paletteName: string | null | undefined,
    withNoneColor: boolean | undefined,
    withStatic: boolean | undefined,
  }>({
    selectedColor: null,
    paletteName: null,
    withNoneColor: undefined,
    withStatic: undefined,
  });

  const selectedColorBlockRef = React.useRef(null);
  useClickOutsideEvent(selectedColorBlockRef, () => setSelectedColorsObject(null), 'mouseup');

  const colorsObjectsWithBg = React.useMemo(
    () => {
      colorsObjects = (colorsObjects as any).filter((colorsObject: any) => colorsObject !== undefined);
      return colorsObjects.map((colorObject) => {
        if (colorObject?.values?.colorIndex) {
          colorObject.backgroundColor =
            sequence?.colors?.[((colorObject?.values?.colorIndex as number) - 1) as number]?.values?.color;
        } else if (colorObject?.values?.color) {
          colorObject.backgroundColor = colorObject?.values?.color;
        } else {
          colorObject.backgroundColor = null;
        }
        return colorObject;
      })},
    [colorsObjects, sequence?.colors]
  );

  const onColorObjectClick = (colorObject: GraphicColor | SubtitleColorsObjectType) => {
    if (colorObject.disabled) return;
    if (colorObject?.values?.name === selectedColorsObject?.values?.name) {
      setSelectedColorsObject(null);
      return;
    }
    setSelectedColorsObject(colorObject);
    setSelectedPaletteObject({
      selectedColor: colorObject?.values?.color || colorObject?.values?.colorIndex || null,
      paletteName: colorObject?.values?.name,
      withNoneColor: typeof colorObject?.withNoneColor === "boolean" ? colorObject?.withNoneColor : withNoneColor,
      withStatic: typeof colorObject?.withStatic === "boolean" ? colorObject?.withStatic : withStatic
    });
  };

  const handleColorChange = (value: SequenceColorPickerValue | null) => {
    const obj: GraphicColor | SubtitleColorsObjectType | undefined = colorsObjects.find(
      (colorObject) => colorObject?.values?.name === selectedColorsObject?.values.name
    );

    if (!obj) {
      return;
    }

    if (onColorChange) {
      onColorChange(value, obj, type);
      setSelectedColorsObject(obj);
    } else if (selectedColorsObject?.onChangeCallback) {
      selectedColorsObject.onChangeCallback(value);
      setSelectedColorsObject(obj);
    }
    setSelectedPaletteObject({
      selectedColor: value?.value,
      paletteName: selectedPaletteObject.paletteName,
      withNoneColor: typeof selectedColorsObject?.withNoneColor === "boolean" ? selectedColorsObject?.withNoneColor : withNoneColor,
      withStatic: typeof selectedColorsObject?.withStatic === "boolean" ? selectedColorsObject?.withStatic : withStatic
    });
    shouldClosePaletteAfterSelection && setSelectedColorsObject(null);
  };

  React.useEffect(() => {
    if (selectedColorBlockRef.current && shouldScrollIntoView) {
      const timeoutId = setTimeout(() => {
        selectedColorBlockRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 0);

      return () => clearTimeout(timeoutId);
    }
  }, [selectedColorBlockRef.current]);

  return (
    <Container>
      <MainRow>
        <Title>Colors</Title>

        <ColorsPanel>
          {colorsObjectsWithBg.map((colorObject, index) => {
            return colorObject?.disabled ? null : (
              <OverlayTrigger
                key={index}
                placement="top"
                overlay={
                  <Tooltip id={`color-tooltip-${index}`} className="tooltip-peech-light">
                    {colorObject?.values?.name}
                  </Tooltip>
                }
              >
                <ColorItem
                  isSelected={colorObject?.values?.name === selectedColorsObject?.values.name}
                  onClick={() => onColorObjectClick(colorObject)}
                  backgroundColor={colorObject.backgroundColor}
                />
              </OverlayTrigger>
            );
          })}
        </ColorsPanel>
      </MainRow>
        <Expandable isExpanded={!!selectedColorsObject}>
          <SelectedColorProcessingBlock ref={selectedColorBlockRef}>
            <Title>{selectedPaletteObject.paletteName}</Title>

            <Stack spacing={4}>
              <SequenceColorPicker
                key={selectedColorsObject?.values?.color || selectedColorsObject?.values?.colorIndex || 'noneColor'}
                palette={sequence.colors ?? []}
                value={selectedPaletteObject.selectedColor || selectedColorsObject?.values?.color || selectedColorsObject?.values?.colorIndex ||  null}
                onChange={handleColorChange}
                withNoneColor={
                  selectedPaletteObject.withNoneColor
                }
                withStatic={
                  selectedPaletteObject.withStatic
                }
              />
            </Stack>
          </SelectedColorProcessingBlock>
        </Expandable>
    </Container>
  );
}
