import React from 'react';
import classNames from 'classnames';

import { Typeahead } from 'react-bootstrap-typeahead';

import './CustomTypeahead.scss';
import SVG from '../../components/svg';

interface CustomTypeaheadProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    refObj?: React.MutableRefObject<any>;
    refKey?: string;
    hideArrow?: boolean;
    labelKey?: string | Function;
    onChange(): void;
}

export default function CustomTypeahead({ refObj, refKey, hideArrow, labelKey, onChange, ...props }: CustomTypeaheadProps & {}) {
    const customTypeaheadRef = React.useRef()
    const [toggleDropdown, setToggleDropdown] = React.useState(false);
    const [dropup, setDropup] = React.useState(false);

    React.useEffect(() => {
        const calculateDropup = () => {
            const selectMenu = customTypeaheadRef.current;
            if (!selectMenu || !selectMenu.inputNode) {
                return;
            }

            const rect = selectMenu.inputNode.getBoundingClientRect();
            const distanceToBottom = window.innerHeight - rect.bottom;

            if (distanceToBottom < 150) {
                setDropup(true);
            } else {
                setDropup(false);
            }
        };

        calculateDropup();

        const handleResize = () => {
            calculateDropup();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function registerRef(element) {
        customTypeaheadRef.current = element
        if (refObj && refKey) {
            refObj.current[refKey] = element
        } else if (refObj) {
            refObj.current = element
        }
    }

    function filterBy(option, state) {
        if (state.selected.length || state.defaultInputValue === state.text) {
            return true;
        }
        return (option?.[labelKey] || labelKey(option)).toLowerCase().indexOf(state.text.toLowerCase()) > -1;
    }

    const onInputChange = (props) => {
        setToggleDropdown(false);
        onChange(props);
        customTypeaheadRef?.current?.blur();
    }

    const toggleSelect = () => {
        const selectMenu = customTypeaheadRef.current;
        if (!selectMenu) {
            return
        }

        selectMenu.toggleMenu();
        if (!selectMenu.isMenuShown) {
            setToggleDropdown(true);
            selectMenu.focus();
        } else {
            setToggleDropdown(false);
        }
    };

    return (
        <Typeahead
            onBlur={() => setToggleDropdown(false)}
            onFocus={() => setToggleDropdown(true)}
            labelKey={labelKey}
            filterBy={filterBy}
            onChange={onInputChange}
            ref={registerRef}
            dropup={dropup}
            {...props}
        >
            {!hideArrow && <SVG
                id="dropdown-svg"
                name="dropdown"
                onClick={toggleSelect}
                className={toggleDropdown ? "show" : ""}
            />}
        </Typeahead>
    )
}