import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import '../../../css/styles.scss';
import Base from '../../client/base/base';
import Content from '../../client/content';
import Sequence from '../../client/sequence';
import { SequenceClonePicker } from '../../components/SequenceClonePicker';
import { SequenceTranslateSelect } from '../../components/SequenceTranslateSelect';
import SVG from '../../components/svg';
import { AppContext } from '../../lib/app-context';
import { handleError } from '../../state/error';
import { menuState } from '../../state/menu';
import {
  DOWNLOAD_STATUS,
  downloadPendingURL,
  downloadStatus,
  resetDownloadPendingState,
  scenesState,
  sequenceState,
  setScenes,
  tempScenesState,
} from '../../state/sequence';

import Loader from '../../ui-components/Loader';
import { trackEvent } from '../../utils/analityics.utils';
import { getLatestDownloadInfos } from '../../utils/balance.utils';
import { getDownloadLink } from '../../utils/downloads.utils';
import { isInIframe } from '../../utils/window.utils';
import { DownloadGenerating } from './DownloadGenerating';
import { DownloadReady } from './DownloadReady';
import { DurationNotAllowed } from './DurationNotAllowed';
import { isInPreviewState } from '../../state/local';

const HighlightText = styled.span`
  color: ${(props) => props.color};
  font-weight: 700;
`;

const MenuButton = styled.div`
  min-width: 100px;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  display: flex;
  height: 28px;
  padding: 26px 10px 26px 10px;
  background: #fff;
  border: 0.5px solid ${(props) => props.color};
  border-radius: 6px;
  margin: 14px 24px 0px 24px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease-in;
  &:hover {
    border: 0.5px solid ${(props) => props.color};
    background: ${(props) => props.color};
    color: #fff;
    ${HighlightText} {
      color: #fff;
    }
    svg[name='choose-aspect-ratio'] path {
      fill: #fff;
    }
    svg[name='choose-translation-language'] path {
      stroke: #fff;
    }
  }

  svg {
    width: 24px;
    padding: 0;
  }
  svg[name='translate'] {
    margin-top: 10px;
  }
  svg[name='diamond-gradient'] {
    margin-left: 4px !important;
  }

  @media (max-width: 600px) {
    margin: 14px 8px 0px 8px;
  }

  @media (max-width: 375px) {
    margin: 14px 5px 0px 5px;
  }

  @media (max-width: 425px) {
    font-size: 10px;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
`;

const ContentTop = styled.div`
  display: flex;
  min-height: 256.84px;
  flex: 1;
  padding: 14px 0px 14px 0px;
  flex-direction: column;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

const ContentBottom = styled(ContentTop)`
  min-height: unset;
  border-top: 1px solid #e0e0e0;
`;

const Paragraph = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const BackButton = styled.div`
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
  color: #999999;
  margin-top: 10px;
`;

export default function DownloadModal({ onHide, onTrim, aspectRatio }) {
  const { plan, user } = useContext(AppContext);
  const sequence = sequenceState.use();
  const downloadPending = downloadStatus.use();
  const [durationAllowed, setDurationAllowed] = useState(true);
  const [error, setError] = useState(null);
  const downloadUrl = downloadPendingURL.use();
  const [sequenceCloneIsOpen, setSequenceCloneIsOpen] = useState(false);
  const [translateSelectorIsOpen, setTranslateSelectorIsOpen] = useState(false);
  const isInPreview = isInPreviewState.use();

  useEffect(async () => {
    const processingSequenceDownloads = await getLatestDownloadInfos(sequence.sid, 'processing');

    if (downloadPending.MODE !== DOWNLOAD_STATUS.GENERATE && !processingSequenceDownloads.length) {
      // start downloading
      menuState.set(null);
      if (!user || !user.UIFLAGS.NO_UPLOAD_LIMIT) {
        if (plan && plan.maxVideoDuration && sequence.duration / 60 > plan.maxVideoDuration) {
          return setDurationAllowed(false);
        }
      }
      downloadRequest();
    } else if (processingSequenceDownloads.length) {
      // show generating
      downloadStatus.set((state) => ({ ...state, MODE: DOWNLOAD_STATUS.GENERATE }));
    }
  }, []);

  const sendMessage = () => {
    let postMessage = 'DOWNLOAD_MODAL_CLOSED_NOT_STARTED';
    switch (downloadPending.MODE) {
      case DOWNLOAD_STATUS.READY:
        postMessage = 'DOWNLOAD_MODAL_CLOSED_READY';
        break;
      case DOWNLOAD_STATUS.GENERATE:
        postMessage = 'DOWNLOAD_MODAL_CLOSED_IN_PROGRESS';
        break;
      default:
        break;
    }
    isInIframe() && window.top?.postMessage(postMessage, '*');
  };

  useEffect(() => {
    if (isInPreview && (downloadPending.MODE === DOWNLOAD_STATUS.READY || downloadPending.MODE === DOWNLOAD_STATUS.GENERATE)) {
      setTimeout(() => {
      sendMessage();
      }, 1000);
    }
  }, [downloadPending]);

  const onHideModal = () => {
    sendMessage();
    onHide();
  };

  useEffect(() => {
    if (downloadPending.MODE === DOWNLOAD_STATUS.GENERATE) {
      downloadPendingURL.set(null);
    }
  }, [downloadPending.MODE]);

  async function onSequenceResponseReady(sequenceResponse) {
    downloadPendingURL.set(null);

    if (sequenceResponse?.output) {
      sequenceState.set((sequence) => {
        sequence.values.output = new Content(sequenceResponse.output.sid).set(sequenceResponse.output);
        return sequence;
      });
    }

    const url = await getDownloadLink(sequenceResponse?.sid, sequenceResponse?.version);
    downloadPendingURL.set(url);
    downloadStatus.set((state) => ({ ...state, MODE: DOWNLOAD_STATUS.READY }));
  }

  async function downloadRequest() {
    if (error) {
      return;
    }
    downloadStatus.set((state) => ({ ...state, MODE: DOWNLOAD_STATUS.NOT_STARTED }));
    await Base.executeAPIQueue();

    try {
      // need to call preReorderScenes check if need to save new chapter before download
      const res = await sequence.preReorderScenes(tempScenesState.get(), scenesState.get());
      if (res) {
        setScenes(res, true, true);
      }

      const sequenceResponse = await Sequence.generate(sequence.sid, undefined, aspectRatio);
      trackEvent('download-request');
      if (sequenceResponse) {
        onSequenceResponseReady(sequenceResponse);
      } else {
        downloadPendingURL.set(null);
        downloadStatus.set((state) => ({ ...state, MODE: DOWNLOAD_STATUS.GENERATE }));
      }
    } catch (err) {
      handleError({
        title: 'Download',
        message: err.message,
        responseError: err,
      });
    }
  }

  return (
    <Modal
      show={true}
      className={`download ${downloadPending.MODE === DOWNLOAD_STATUS.READY && !error && 'download-ready'}`}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onHideModal}
      centered
      size="lg"
      style={{ paddingRight: 0, paddingLeft: 0 }}
    >
      <Modal.Body>
        {isInPreview ? (
          <div
            style={{
              width: '100%',
              height: '360px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader size="lg" />
          </div>
        ) : (
          <ModalContent>
            <a onClick={onHideModal}>
              <SVG id="btn-close-download-modal" name="close" className="close" />
            </a>
            <ContentTop>
              {(downloadPending.MODE === DOWNLOAD_STATUS.NOT_STARTED ||
                downloadPending.MODE === DOWNLOAD_STATUS.GENERATE) &&
                durationAllowed && <DownloadGenerating />}
              {downloadPending.MODE === DOWNLOAD_STATUS.READY && durationAllowed && downloadUrl && <DownloadReady />}
              {!durationAllowed && <DurationNotAllowed onTrim={onTrim} onHide={onHide} />}
            </ContentTop>
            <ContentBottom>
              <Paragraph>Now, let's generate additional versions from your edited video</Paragraph>

              {!sequenceCloneIsOpen && !translateSelectorIsOpen && (
                <FlexRow>
                  <MenuButton color={'#EA429D'} onClick={() => setSequenceCloneIsOpen(true)}>
                    <SVG name="choose-aspect-ratio" style={{ marginTop: '8px' }} />
                    <div>
                      Different <HighlightText color={'#EA429D'}>Aspect Ratio</HighlightText>
                    </div>
                  </MenuButton>

                  <MenuButton color={'#1F9FFB'} onClick={() => setTranslateSelectorIsOpen(true)}>
                    <SVG name="choose-translation-language" style={{ marginTop: '8px', marginRight: '4px' }} />
                    <div>
                      Different <HighlightText color={'#1F9FFB'}>Language</HighlightText>
                    </div>
                  </MenuButton>
                </FlexRow>
              )}
              {sequenceCloneIsOpen && <SequenceClonePicker aspectRatio={aspectRatio} />}
              {translateSelectorIsOpen && <SequenceTranslateSelect />}

              {(sequenceCloneIsOpen || translateSelectorIsOpen) && (
                <BackButton
                  onClick={() => {
                    setSequenceCloneIsOpen(false);
                    setTranslateSelectorIsOpen(false);
                  }}
                >
                  {'< Back'}
                </BackButton>
              )}
            </ContentBottom>
          </ModalContent>
        )}
      </Modal.Body>
    </Modal>
  );
}
