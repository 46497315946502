
import * as Base from './base/videoType';

export const VideoTypeStatus = Base.VideoTypeStatus;

export interface IVideoType extends Base.IVideoType {

}

export interface IVideoTypeProperties extends Base.IVideoTypeProperties {

}

export interface IVideoTypeFilter extends Base.IVideoTypeFilter {

}

export default class VideoType<IType extends IVideoType = IVideoType, ITypeProperties extends IVideoTypeProperties = IVideoTypeProperties> extends Base.VideoType<IType, ITypeProperties> implements IVideoType {
}