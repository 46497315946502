import { LocationProvider, Router } from '@reach/router';
import * as Sentry from '@sentry/react';
import { navigate } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { entity } from 'simpler-state';
import User from '../../client/user';
import ErrorDialog from '../../dialogs/error/error';
import { AppContext, appConfig } from '../../lib/app-context';
import MyProjects from '../../pages/MyProjects';
import Billing from '../../pages/billing';
import Brand from '../../pages/brand';
import Configure from '../../pages/configure';
import CreateNewProject from '../../pages/create-project';
import Impersonate from '../../pages/impersonate';
import ImpersonateRedirect from '../../pages/impersonate-redirect';
import Library from '../../pages/library';
import Login from '../../pages/login';
import Logout from '../../pages/logout';
import Project from '../../pages/project';
import Roadmap from '../../pages/roadmap';
import YouTubeGallery from '../../pages/trends';
import { handleError } from '../../state/error';
import { footagesProcessing, isInPreviewState, shouldLoginState } from '../../state/local';
import { sequenceState } from '../../state/sequence';
import Button, { ButtonVariant } from '../../ui-components/Button';
import VisualEditorSubheader from '../../ui-components/Header/VisualEditorSubheader';
import Loader from '../../ui-components/Loader';
import SideBarMenu from '../../ui-components/SideBarMenu';
import peechLogo from '../../ui-components/SideBarMenu/peech-logo.json';
import { trackEvent } from '../../utils/analityics.utils';
import { isInIframe } from '../../utils/window.utils';
import LottiePlayer from '../lottie-player';
import CreateNewProjectModal from './CreateNewProjectModal';
import MobileUploadModal from './MobileUploadModal';
import PeechProUpgradeModal from './PeechProUpgradeModal';
import PeechUpgradeFeatureModal from './PeechUpgradeFeatureModal';
import RequestWebinarsDemoModal from './RequestWebinarsDemoModal';
import TranslateSequenceModal from './TranslateSequenceModal';
import ClientConfiguration from './clientConfiguration';

const TABS = {
  PROJECTS: 0,
  CONTENT: 1,
  TRENDS: 2,
  WORKFLOW: 3,
  BRAND: 4,
  ASSETS: 5,
  LEARNINGCENTER: 6,
  WEBINARS: 7,
};
export const sidebarTabState = entity(TABS.PROJECTS);
export const trialNotificationState = entity(TABS.PROJECTS);

const displayPeechProUpgradeModalState = entity(false);
const displayUpgradeFeatureModalState = entity({ open: false, featureId: '' });
const displayTranslateModalState = entity(null);
const displayCreateNewProjectModalState = entity(false);

export const displayUploadMobileModalState = entity(false);

export const openPeechProUpgradeModal = (user, trigger, featureId) => {
  if (user?.UIFLAGS.SMART_LIBRARY) {
    // open pricing page until we create a new upgrade modal for smart library users
    if (appConfig.NEW_UI_URL) {
      window.open(`${appConfig.NEW_UI_URL}/plans`, '_blank');
    }
  } else {
    trackEvent('Upgrade Modal Displayed', { trigger });
    displayUpgradeFeatureModalState.set({ open: true, featureId: featureId ?? '' });
  }
};

export const openTranslateModal = (translateObject) => {
  displayTranslateModalState.set(translateObject);
};

export const openCreateNewProjectModal = (trigger) => {
  trackEvent('Create-new-project Modal Displayed', { trigger });
  displayCreateNewProjectModalState.set(true);
};

const displayWebinarsRequestDemoState = entity(false);
// Need to remove
export const openWebinarsRequestDemoModal = (trigger) => {
  trackEvent('Webinars request demo Modal Displayed', { trigger });
  displayWebinarsRequestDemoState.set(true);
};

const PeechWebRedirect = ({ path, to }) => {
  const { config } = React.useContext(AppContext);

  /** redirect to peech-web **/
  React.useEffect(() => {
    if (
      !window.location.pathname.includes('logout') &&
      !window.location.pathname.includes('impersonate-redirect') &&
      !window.location.search.includes('impersonated') &&
      !window.location.pathname.includes('trends') // display trends page with no user login
    ) {
      const url = new URL(config.NEW_UI_URL);

      if (window.location.pathname.startsWith('/project')) {
        const sequenceSid = window.location.hash.slice(1);

        if (sequenceSid) {
          window.location.assign(url + path.replace('/', '') + `/${sequenceSid}/visuals-editor?firstDraft=true`);
          return;
        }
      }

      if (window.location.search.includes('?footageSid=')) {
        url.pathname = window.location.search.replace('?footageSid=', '/platform/p/');
        window.location.assign(url);
        return;
      }

      if (path === '/plans' && window.location.search.includes('planId=')) {
        // keep search params on redirect to a specific plan
        window.location.assign(url + path.replace('/', '') + window.location.search);
        return;
      }

      if (to) {
        url.pathname = to;
      } else if (path) {
        url.pathname = path;
      }

      window.location.assign(url);
    }
  }, [config]);

  return null;
};

export default function Layout() {
  const { user, getUser, config, isMobileDevice, plan } = React.useContext(AppContext);
  const sequence = sequenceState.use();
  const shouldLogin = shouldLoginState.use();
  const [choosenPlanId, setChoosenPlanId] = React.useState();
  const displayPeechProUpgradeModal = displayPeechProUpgradeModalState.use();
  const displayUpgradeFeatureModal = displayUpgradeFeatureModalState.use();
  const displayUploadMobileModal = displayUploadMobileModalState.use();
  const displayWebinarsRequestDemo = displayWebinarsRequestDemoState.use();
  const displayTranslateModal = displayTranslateModalState.use();
  const displayCreateNewProjectModal = displayCreateNewProjectModalState.use();
  const isFootagesProcessing = footagesProcessing.use();

  /**
   *
   * @param {Error} error
   * @param {{
   * 	componentStack: string
   * }} info
   */
  function boundaryErrorHandler(error, info) {
    console.error('Boundary error', { error, info });
    handleError('Boundary Error', { message: error.message, responseError: error?.response || error });
  }

  const shouldShowSmartLibrary = React.useMemo(() => user?.UIFLAGS.SMART_LIBRARY, [user]);

  const sideBarItems = React.useMemo(() => {
    const list = [
      {
        name: 'library',
        title: 'Content Library',
        value: TABS.CONTENT,
        path: '/library',
        enable: true,
        target: '_self',
        loader: isFootagesProcessing,
      },
      {
        name: 'player-projects',
        title: 'Projects Library',
        value: TABS.PROJECTS,
        path: '/',
        enable: true,
        target: '_self',
        loader: false,
      },
      {
        name: 'trends',
        title: 'Trends',
        value: TABS.TRENDS,
        path: '/trends',
        enable: !shouldShowSmartLibrary,
        target: '_self',
        loader: false,
      },
    ];
    const brandItem = {
      name: 'brand',
      title: 'Brand',
      value: TABS.BRAND,
      path: '/brand',
      enable: true,
      target: '_self',
      loader: false,
    };
    if (shouldShowSmartLibrary) {
      list.push(brandItem);
    } else {
      list.unshift(brandItem);
    }
    const learningCenter = {
      name: 'learning-center',
      title: 'Learning Center',
      value: TABS.LEARNINGCENTER,
      path: 'https://help.peech-ai.com/en/',
      enable: true,
      target: '_blank',
    };
    list.push(learningCenter);
    return list;
  }, [shouldShowSmartLibrary, isFootagesProcessing]);

  React.useEffect(
    () => {
      let tab = TABS.PROJECTS;

      if (location.pathname.replaceAll('/', '') === 'library') {
        tab = TABS.CONTENT;
      } else if (location.pathname.replaceAll('/', '') === 'trends') {
        tab = TABS.TRENDS;
      }
      SwitchTabs(tab);
    },
    [location.pathname],
    []
  );

  function SwitchTabs(tab) {
    if (sidebarTabState !== tab) {
      sidebarTabState.set(tab);
    }
  }

  const onLogin = async (ps, planId) => {
    if (planId) {
      console.log('checking in onLogin 1', { planId });
      setChoosenPlanId(planId);
    }
    await getUser(ps);
  };

  React.useEffect(() => {
    console.log('new UseEffect 1', { user });
    if (user) {
      if (!user.onboardingFinished) {
        console.log('new UseEffect 1', { user });
        if (choosenPlanId) {
          console.log('new UseEffect 2 go to plans', { choosenPlanId });
          navigate('/plans?planId=' + choosenPlanId);
        } else if (
          !location.pathname.startsWith('/plans') &&
          !location.pathname.startsWith('/logout') &&
          !location.pathname.startsWith('/project') &&
          !location.pathname.startsWith('/impersonate-redirect')
        ) {
          console.log('new UseEffect 3 go to onboarding');
          window.location.href = `${config.NEW_UI_URL}/onboarding?authToken=${encodeURIComponent(User.ps)}`;
        }
      } else {
        console.log('new UseEffect 4', { user });
        if (choosenPlanId) {
          console.log('new UseEffect 5', { choosenPlanId });
          navigate('/plans?planId=' + planId);
        } else if (!user.preset) {
          console.log('new UseEffect 6', { preset: user.preset });
          navigate('/brand');
        } else {
          const urlParams = new URLSearchParams(document.location.search);
          const sequenceSid = urlParams.get('sequenceSid');
          console.log('new UseEffect 7', { sequenceSid });
          if (sequenceSid) {
            navigate(`/project#${sequenceSid}`);
          }
        }
      }
    }
  }, [user]);


  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isInPreview = queryParams.has('isInPreview');
    isInPreviewState.set(isInPreview);
  }, [location.search]);

  return (
    <>
      <Sentry.ErrorBoundary onError={boundaryErrorHandler}>
        <div className="ui wrap">
          <Helmet>
            <title>Peech - Automated Talk-Videos Creator</title>
            <meta name="version" content={`${process.env.npm_package_version}-${process.env.VERSION_BUILD_NUMBER}`} />
            <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${config.GTM_ID}');`}</script>
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
            {sequence?.style?.fontFamily ? (
              <link
                rel="stylesheet"
                href={`https://fonts.googleapis.com/css?display=swap&family=${sequence.style.fontFamily}:i,b,400,600,400i,600i`}
              />
            ) : null}
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
            {/* intercom integration htpv2htx */}
            {/* <script>{`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/htpv2htx';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else{if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}}})();`}</script> */}
            <script src="https://cdn.paddle.com/paddle/paddle.js"></script>
            <style>{`body {background-color: #FAFAFA;}`}</style>
            {/* hbspt and calendly */}
            <script charset="utf-8" type="text/javascript" src="https://js.hsforms.net/forms/embed/v2.js"></script>
            <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
            <script src="https://assets.calendly.com/assets/external/forms.js" type="text/javascript"></script>
          </Helmet>
          <LocationProvider>
            <div>
              <PeechProUpgradeModal
                isOpen={displayPeechProUpgradeModal}
                onClose={() => displayPeechProUpgradeModalState.set(false)}
              />
              <PeechUpgradeFeatureModal
                isOpen={displayUpgradeFeatureModal.open}
                onClose={() => displayUpgradeFeatureModalState.set({ open: false, featureId: '' })}
                featureId={displayUpgradeFeatureModal.featureId}
              />
              <TranslateSequenceModal
                isOpen={!!displayTranslateModal}
                onClose={() => displayTranslateModalState.set(null)}
                sequence={displayTranslateModal?.sequence}
                trigger={displayTranslateModal?.trigger}
              />
              <CreateNewProjectModal
                isOpen={!!displayCreateNewProjectModal}
                onClose={() => displayCreateNewProjectModalState.set(null)}
              />

              <RequestWebinarsDemoModal
                isOpen={displayWebinarsRequestDemo}
                onClose={() => displayWebinarsRequestDemoState.set(false)}
              />
              <MobileUploadModal
                isOpen={displayUploadMobileModal}
                onClose={() => displayUploadMobileModalState.set(false)}
              />
              {shouldLogin &&
                (user === null || !User.ps) &&
                (config.REDIRECT_TO_NEW_UI_FOR_RESET_PASSWORD ? <PeechWebRedirect /> : <Login onLogin={onLogin} />)}
              {!isMobileDevice &&
                !!user &&
                !user.UIFLAGS.SMART_LIBRARY &&
                !window.location.pathname.includes('logout') &&
                !window.location.pathname.includes('impersonate-redirect') && (
                  <SideBarMenu menuItems={sideBarItems} onItemChanged={SwitchTabs} />
                )}
              {!(shouldLogin && (user === null || !User.ps)) && 
              window.location.pathname.includes('project') ? <VisualEditorSubheader /> : null}
              {/* no user header for trends page start */}
              {!User.ps && (
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flex: '0 0 auto',
                    height: 72,
                    paddingInline: 36,
                    boxShadow: 'rgb(0 0 0 / 10%) 0px 2px 5px',
                  }}
                >
                  <a href="https://peech-ai.com" style={{ height: 40, translate: '0px -6px' }}>
                    <LottiePlayer animationData={peechLogo} autoplay={true} loop={false} />
                  </a>
                  <Button
                    onClick={() => {
                      const url = new URL(config.NEW_UI_URL);
                      window.location.assign(url);
                    }}
                    style={{ height: 28 }}
                    variant={ButtonVariant.Primary}
                  >
                    sign in
                  </Button>
                </div>
              )}
              {/* no user header for trends page end */}
              <ClientConfiguration />
              {user === undefined ? (
                <Loader style={{ margin: 'auto', marginTop: '40vh' }} />
              ) : (
                <div className="page-view-wrapper ">
                  <Router basepath="/">
                    <>
                      {user?.UIFLAGS.SMART_LIBRARY ? <PeechWebRedirect path="/" /> : <MyProjects path="/" />}

                      {user?.UIFLAGS.SMART_LIBRARY && !isInIframe() ? (
                      <PeechWebRedirect path="/project" />
                      ) : (
                      <Project path="/project" />
                      )}

                      {user?.UIFLAGS.SMART_LIBRARY ? (
                        <PeechWebRedirect path="/library" to="/" />
                      ) : (
                        <Library path="/library" />
                      )}
                      {user?.UIFLAGS.SMART_LIBRARY && !isInIframe() ? (
                        <PeechWebRedirect path="/brand" />
                      ) : (
                        <Brand path="/brand" />
                      )}

                      {!isMobileDevice && (
                        <YouTubeGallery
                          path="/trends"
                          playlistId={config.TRENDS_PLAYLIST_ID}
                          headerText={`${user ? 'Trends' : ''}`}
                          showSignInButton={!user}
                        />
                      )}
                      {user?.UIFLAGS.SMART_LIBRARY && !isInIframe() ? (
                        <PeechWebRedirect path="/billing" />
                      ) : (
                        <Billing path="/billing" />
                      )}
                      <PeechWebRedirect path="/plans" />
                      <Roadmap path="/roadmap" />
                      <CreateNewProject path="/create-project" />
                    </>

                    <Impersonate path="/impersonate" />
                    <ImpersonateRedirect path="/impersonate-redirect" />
                    <Logout path="/logout" />
                    <Configure path="/configure" />
                  </Router>
                </div>
              )}
              {/* {shouldStopReload && <BeforeUnload />} */}
            </div>
          </LocationProvider>
          <ErrorDialog />
        </div>
      </Sentry.ErrorBoundary>
    </>
  );
}
