
export const COLORS = {
    peech_primary_1_50: "#FFE4F3",
    peech_primary_1_300: "#FFB1DB",
    peech_primary_1_400: "#EA75B4",
    peech_primary_1_500: "#EA429D",
    peech_primary_1_900: "#B23278",
    peech_primary_2_50: "#E5F4FF",
    peech_primary_2_300: "#B0DCFB",
    peech_primary_2_400: "#7EC7FB",
    peech_primary_2_500: "#1F9FFB",
    peech_primary_2_900: "#1671B2",
    peech_secondary_50: "#E5EFFF",
    peech_secondary_300: "#97B0D8",
    peech_secondary_400: "#5578AF",
    peech_secondary_500: "#132E59",
    peech_secondary_2_500: "#AE8BFA",
    peech_secondary_900: "#0E2140",
    peech_gray_50: "#FAFAFA",
    peech_gray_100: "#F2F2F2",
    peech_gray_300: "#CCCCCC",
    peech_gray_400: "#999999",
    peech_gray_500: "#666666",
    peech_gray_600: "#828282",
    peech_gray_700: "#737373",
    peech_gray_900: "#333333",
    peech_neutral_white: "#ffffff",
    peech_neutral_black: "#000000",
    $peech_alert_success: "#16B242",
    peech_alert_error: "#FF3D3D",
    peech_alert_warning: "#FFD43D",
    peech_google_red: "#EA4335",
    peech_google_blue: "#4285F4",
    peech_google_yellow: "#FBBC05",
    peech_google_green: "#34A853",
}
