import { useContext } from 'react';
import { AppContext } from '../lib/app-context';
import { openCreateNewProjectModal, openPeechProUpgradeModal } from '../components/layout';
import { navigate } from 'gatsby';
import {
  brandkitMissingLogoState,
  brandkitMissingModalState,
  projectsLimitReachedState,
  trialEndReachedState,
  trialRestState,
} from '../state/local';

export function useTrialEndReached(): boolean {
  const { plan } = useContext(AppContext);
  const trialRest = trialRestState.use();
  return !!(!plan?.recurringPrice && !plan?.overrideRecurringPrice && plan?.freeTrial && trialRest < 1);
}

export function useBrandkitIsMissed(): boolean {
  const { user } = useContext(AppContext);
  return !user?.preset;
}

export function useDefaultLogoMissed(): boolean {
  const { user } = useContext(AppContext);
  return !user?.preset?.getDefaultLogo();
}

export function useCreateProjectClick(trigger: string, onCreate?: () => void): () => void {
  const { user, config, reachProjectLimit } = useContext(AppContext);
  const isNewProjectCreationFlowEnabled = !!user?.UIFLAGS.ENABLE_NEW_CREATION_FLOW;
  const isFastProjectCreationFlowEnabled = !!user?.UIFLAGS.ENABLE_NEW_FAST_CREATION_FLOW;
  const isTrialEndReached = useTrialEndReached();
  const isBrandkitMissed = useBrandkitIsMissed();
  const isDefaultLogoMissed = useDefaultLogoMissed();

  // TODO: here
  const handleClick = (): void => {
    if (isTrialEndReached) {
      return trialEndReachedState.set(true);
    }
    if (reachProjectLimit) {
      return openPeechProUpgradeModal(user, 'create reach project limit', "PROJECTS_LIMIT_REACHED");
    }
    if (isBrandkitMissed) {
      return brandkitMissingModalState.set(true);
    }
    if (isDefaultLogoMissed) {
      return brandkitMissingLogoState.set(true);
    }

    if (onCreate) {
      onCreate();
    } else if (isFastProjectCreationFlowEnabled || isNewProjectCreationFlowEnabled) {
      openCreateNewProjectModal(trigger);
    } else {
      navigate('/project');
    }
  };

  return handleClick;
}
