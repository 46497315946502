
import React from 'react'
import { Player } from 'shaka-player/dist/shaka-player.compiled'
import User from '../client/user';

export { Player };

export const AudioStream = {
    ORIGINAL: 'eng',
    DENOISED: 'ita',
    WITH_MUSIC: 'heb',
    DENOISED_WITH_MUSIC: 'spa',
}

/**
 * @param {object} props 
 * @param {string} props.src
 * @param {number} props.startTime
 * @param {function(Player, {detail: {severity: number}})} props.onDashError
 * @param {function(Player)} props.onDashManifestParsed
 * @param {function(Player)} props.onDashTracksChanged
 * @param {React.RefObject<HTMLVideoElement>} props.innerRef
 * @param {'eng'|'ita'|'heb'|'spa'} props.audioStream see AudioStream
 */
export default function DashPlayer(props) {
    const {
        src,
        startTime,
        innerRef,
        onDashError,
        onDashManifestParsed,
        onDashTracksChanged,
        onTimeUpdate,
        onBuffering,
        audioStream,
        ...rest
    } = props;

    const player = React.useRef(new Player());
    const startPosition = React.useRef(startTime);
    const playerRef = innerRef || React.createRef();

    const configurePlayer = () => player.current.configure({
        streaming: {
            rebufferingGoal: 4,
            bufferingGoal: 10 * 60, // 5 minutes
            bufferBehind: 30,  // 30 sec
            forceHTTPS: true
        },
        manifest: {
            retryParameters: {
              connectionTimeout: 30000
            },
            dash: {
                ignoreMinBufferTime: true
            }
        },
        preferredAudioLanguage: audioStream
    })

    React.useEffect(() => {
        if (!player.current) {
            return;
        }

        configurePlayer()

        player.current.getNetworkingEngine().registerRequestFilter((type, request) => {
            request.headers.Authorization = `Bearer ${User.ps}`;
        });
        return () => {
            player.current && player.current.destroy();
        }
    }, [player.current]);

    React.useEffect(() => {
        if (!player.current || player.current?.ja?.c === audioStream) {
            return;
        }
        configurePlayer()
    }, [audioStream]);

    React.useEffect(() => startPosition.current = startTime, [startTime]);

    React.useEffect(() => player.current?.selectAudioLanguage(audioStream), [audioStream]);

    React.useEffect(() => {
        if (!player.current) {
            return
        }

        onDashError && player.current.addEventListener('error', err => onDashError(player.current, err.detail));
        onDashManifestParsed && player.current.addEventListener('manifestparsed', () => onDashManifestParsed(player.current));
        onDashTracksChanged && player.current.addEventListener('trackschanged', () => onDashTracksChanged(player.current));
        onBuffering && player.current.addEventListener('buffering', (event) => onBuffering(event));

    }, [player.current])

    React.useEffect(() => {
        if (!playerRef.current) {
            return
        }

        player.current.attach(playerRef.current);
    }, [playerRef.current])

    React.useEffect(async () => {
        if (!src) {
            return
        }

        try {
            //test itay
            let udpatedSrc = src.replace('idev', 'dev');
            console.log('replace idev with dev t:' + udpatedSrc);
            await player.current.load(udpatedSrc, startPosition.current)
        }
        catch (err) {
            // console.error('Failed to load player', err)
            // errorState.set({
            //     title: 'Failed to load player',
            //     message: err.message
            // })
            onDashError && onDashError(player.current, err);
            return;
        }
    }, [src])

    function onTimeChange(e) {
        onTimeUpdate && onTimeUpdate(e);
        startPosition.current = e.target.currentTime;
    }

    return <video
        {...rest}
        onTimeUpdate={onTimeChange}
        ref={playerRef}
    />
}
