import { isEmpty } from 'lodash';
import CuePoint from '../client/cuePoint';
import { AssetUsage, IPreset } from '../client/preset';
import TitleCuePoint, { TitleCuePointTitleType } from '../client/titleCuePoint';
import { IUser } from '../client/user';
import { IConfig } from '../lib/app-context';
import { DEFAULT_FONT, loadAssetFont, loadWebFonts } from './font.utils';

export interface FontPrest {
  fontFamily: string;
  variant: string;
  type: 'google' | 'asset';
  fileName?: string;
  assetSid?: string;
}

interface SequenceFontDirectory {
  header: {
    regular: DirectoryFont;
    bold: DirectoryFont;
  };
  body: {
    regular: DirectoryFont;
    bold: DirectoryFont;
  };
  captions: {
    regular: DirectoryFont;
  };
}
interface DirectoryFont {
  fontFamily: string;
  variant: string;
}

const FONT_FALLBACK: DirectoryFont = {
  fontFamily: DEFAULT_FONT,
  variant: '400',
};

export const getFontToGraphicCuePoint = (
  cuePoint: CuePoint,
  sequenceFontDirectory: SequenceFontDirectory,
  lottieFont: { fStyle: string },
  texts: Record<string, string>,
  customRenderProps?: { fWeight: 'normal' | 'bold' }
): DirectoryFont => {
  let returnFont = FONT_FALLBACK;
  const lottieFontStyle = lottieFont?.fStyle?.toLowerCase() || 'regular';
  if (cuePoint.titleType && cuePoint.titleType === TitleCuePointTitleType.SLIDE) {
    if (lottieFontStyle === 'bold') {
      returnFont = sequenceFontDirectory.header.bold || FONT_FALLBACK;
    } else {
      returnFont = sequenceFontDirectory.body.regular || sequenceFontDirectory.header.regular || FONT_FALLBACK;
    }
  } else {
    switch (cuePoint.titleType || cuePoint.type) {
      case TitleCuePoint.TYPE.SPEAKER:
        if (lottieFontStyle === 'bold') {
          returnFont = sequenceFontDirectory.header.bold || FONT_FALLBACK;
        } else {
          returnFont = sequenceFontDirectory.header.regular || FONT_FALLBACK;
        }
        break;
      case TitleCuePoint.TYPE.INTRO:
        // check if has empty text
        const hasEmptyText = Object.values(texts).some((text) => text === '' || text === undefined || text === null);

        if (lottieFontStyle === 'bold' && !hasEmptyText) {
          returnFont = sequenceFontDirectory.header.bold || FONT_FALLBACK;
        } else {
          returnFont = sequenceFontDirectory.header.regular || FONT_FALLBACK;
        }
        break;
      case TitleCuePoint.TYPE.OUTRO:
        if (customRenderProps?.fWeight === 'bold') {
          returnFont = sequenceFontDirectory.header.bold || FONT_FALLBACK;
        } else {
          returnFont = sequenceFontDirectory.header.regular || FONT_FALLBACK;
        }
        break;
      default:
        if (customRenderProps?.fWeight === 'bold') {
          returnFont = sequenceFontDirectory.header.bold || FONT_FALLBACK;
        } else {
          returnFont = sequenceFontDirectory.header.regular || FONT_FALLBACK;
        }
        break;
    }
  }

  return returnFont;
};

export const getImageToAnimation = (imgUrl: string, maxWidth: number, maxHeight: number) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // to avoid CORS if used with Canvas
    img.style.maxWidth = maxWidth.toString();
    img.style.maxHeight = maxHeight.toString();
    img.onload = () => {
      if (img.width / maxWidth > img.height / maxHeight) {
        let ratio = maxWidth / img.width;
        img.width = maxWidth;
        img.height *= ratio;
      } else {
        let ratio = maxHeight / img.height;
        img.height = maxHeight;
        img.width *= ratio;
      }

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = maxWidth;
      canvas.height = maxHeight;
      ctx?.drawImage(img, canvas.width / 2 - img.width / 2, canvas.height / 2 - img.height / 2, img.width, img.height);
      resolve(canvas.toDataURL('image/png'));
    };
    img.onerror = (e) => {
      reject(e);
    };
    img.src = imgUrl;
  });
};

export const getFontFormPrestToPackagePreview = (preset: IPreset) => {
  const brandFont = preset.getHeaderFont() || preset.getBodyFont();
  let fonts: FontPrest[] = [];
  if (brandFont) {
    // google font
    if (brandFont instanceof String || brandFont?.length) {
      fonts = [
        {
          fontFamily: brandFont as string,
          variant: preset.headerBoldVariant as string,
          type: 'google',
        },
        {
          fontFamily: brandFont as string,
          variant: preset.headerRegularVariant as string,
          type: 'google',
        },
      ];
    }
    // asset font
    if (
      brandFont instanceof AssetUsage &&
      brandFont?.boldFileName &&
      brandFont?.regularFileName &&
      brandFont.assetSid
    ) {
      fonts = [
        {
          fontFamily: `User-Font-${brandFont.assetSid}-${brandFont.boldFileName}`.replace('.', '-'),
          variant: '400',
          type: 'asset',
          fileName: brandFont.boldFileName,
          assetSid: brandFont.assetSid,
        },
        {
          fontFamily: `User-Font-${brandFont.assetSid}-${brandFont.regularFileName}`.replace('.', '-'),
          variant: '400',
          type: 'asset',
          fileName: brandFont.regularFileName,
          assetSid: brandFont.assetSid,
        },
      ];
    }
  }

  return fonts;
};

export const getFontFormSequenceFontToPackagePreview = (sequenceFont: any) => {
  let fonts: FontPrest[] = [];
  // header
  if (sequenceFont?.header?.regular && sequenceFont?.header?.bold) {
    fonts = [sequenceFont.header.bold, sequenceFont.header.regular];
  } else if (sequenceFont?.body?.regular && sequenceFont?.body?.bold) {
    fonts = [sequenceFont.body.bold, sequenceFont.body.regular];
  }

  return fonts;
};

export const loadFontsForPackagePreview = async (fonts: FontPrest[], user: IUser, config: IConfig) => {
  const webFonts: [key: string, Value: string[]] = {};
  const assetToLoad = [];
  fonts.forEach((font) => {
    if (font.type === 'google') {
      if (webFonts[font.fontFamily]) {
        webFonts[font.fontFamily].push(font.variant);
      } else {
        webFonts[font.fontFamily] = [font.variant];
      }
    }

    if (font.type === 'asset' && font?.assetSid && font?.fileName) {
      assetToLoad.push(loadAssetFont(font.assetSid, font.fileName, user, config));
    }
  });
  if (!isEmpty(webFonts)) {
    await loadWebFonts(webFonts);
  }
  if (assetToLoad.length) {
    await Promise.all(assetToLoad);
  }
  return fonts;
};

const PLACE_HOLDER_TEXT = {
  'en-US': {},
  'he-IL': {
    'Enter Title': 'הכנס כותרת',
    'Enter Text': 'הכנס טקסט',
    'Text here': 'הכנס טקסט',
  },
};

export const getDefaultTextPlaceHolder = (languageCode: string, text: string): string => {
  let str = text;
  try {
    str = PLACE_HOLDER_TEXT[languageCode][text];
  } catch (error) {}

  return str || text;
};

export const moveSpacesToRight = (str: string): string => {
  let match = str.match(/^\s*/); // Match against spaces at the start of the string
  if (match && match[0].length > 0) {
    return str.slice(match[0].length) + match[0]; // Attach the spaces at the end of the substring
  }
  return str;
};

export const moveSpacesToLeft = (str: string) => {
  let match = str.match(/\s*$/);
  if (match && match[0].length > 0) {
    return match[0] + str.slice(0, str.length - match[0].length);
  }
  return str;
};

const parenthesesRange = /[(){}<>[\]]/;
const swapParentheses = (str: string) => {
  const range = new RegExp(parenthesesRange, 'g');
  return str.replace(range, function (char) {
    switch (char) {
      case '(':
        return ')';
      case ')':
        return '(';
      case '{':
        return '}';
      case '}':
        return '{';
      case '<':
        return '>';
      case '>':
        return '<';
      case '[':
        return ']';
      case ']':
        return '[';
      default:
        return char;
    }
  });
};

enum CharType {
  HEBREW = 'hebrew',
  SPACE = 'space',
  PARENTHESES = 'parentheses',
  OTHER = 'other',
}

type CharChunk = {
  string: string;
  charType: CharType;
};

/**
 * Function to reverse right-to-left characters in a string.
 * It reversibly appends chunks of characters based on their type (Hebrew, space or other) and
 * for chunks of 'other' type characters, moves trailing spaces to the front.
 *
 * @param {string} str - The input string
 * @return {string} - The modified string
 */
export const reverseRTLChar = (str: string, textDirection: 'rtl' | 'ltr'): string => {
  // Return early if there isn't anything to process
  if (!str) return str;

  // Regex pattern for Hebrew characters
  const hebrewRange = /[\u0590-\u05FF\uFB1D-\uFB4F]/;
  // Regex pattern for space characters
  const spaceRange = /\s/;
  const specialRange = parenthesesRange;

  // Initialize the main variables
  let chunks: CharChunk[] = [];
  let previousCharType: CharType | null = null;
  let currentString = '';

  // Loop over every character in the string
  for (let char of str) {
    let charType: CharType;

    // Identify the type of the character
    if (hebrewRange.test(char)) {
      charType = CharType.HEBREW;
    } else if (spaceRange.test(char)) {
      charType = CharType.SPACE;
    } else if (specialRange.test(char)) {
      charType = CharType.PARENTHESES;
    } else {
      charType = CharType.OTHER;
    }

    // If we're processing the first character, assign its type to previousCharType
    if (!previousCharType) {
      previousCharType = charType;
    }

    // If the character type has changed (excluding spaces), start a new chunk
    if (charType !== previousCharType && charType !== CharType.SPACE && charType !== CharType.PARENTHESES) {
      chunks.push({ string: currentString, charType: previousCharType });
      currentString = char;
      previousCharType = charType;
    } else {
      // Group Hebrew characters in reverse order (since they are RTL), and others in insertion order
      if (previousCharType === CharType.HEBREW) {
        currentString = char + currentString;
      } else {
        currentString += char;
      }
    }
  }

  // Push any remaining characters into the chunks
  if (currentString !== '') {
    chunks.push({ string: currentString, charType: previousCharType! });
  }

  // Initialize the final string
  let finalStr = '';

  // Combine all chunks into the final string, moving spaces to front for 'other' type characters
  chunks.forEach((chunk, index) => {
    if (textDirection === 'rtl') {
      let str = chunk.charType === CharType.OTHER ? moveSpacesToLeft(chunk.string) : chunk.string;
      // if PARENTHESES chunk  and next hebrew  parentheses to the opposite direction
      str =
        chunk.charType === CharType.PARENTHESES && chunks[index + 1]?.charType === CharType.HEBREW
          ? swapParentheses(str)
          : str;
      // if hebrew chunk swap parentheses to the opposite direction
      str = chunk.charType === CharType.HEBREW ? swapParentheses(str) : str;
      finalStr = str + finalStr; // RTL add new string to the left
    } else {
      // TODO: currently we don't using this option
      const str = chunk.charType === CharType.HEBREW ? moveSpacesToRight(chunk.string) : chunk.string;
      finalStr = finalStr + str; // LTR add new string to the right
    }
  });

  return finalStr;
};
