import styled from '@emotion/styled/macro';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Preset from '../../client/preset';
import { AppContext } from '../../lib/app-context';
import {
  getLottiesPreviewList,
  getVisPackagesList,
  lottiesLoadingState,
  lottiesPreviewListState,
  packagesLoadingState,
  sortedPackagesSidsState,
  visualPackagesListState,
} from '../../state/visualPackage';
import RequestCustomPackageModal from './RequestCustomPackageModal';
import VisualPackageItem from './VisualPackageItem';
import RequestCustomButton from './RequestCustomButton';
import Loader from '../Loader';
import { getFontFormPrestToPackagePreview, loadFontsForPackagePreview } from '../../utils/lottie-animation.utils';
import useUserPs from '../../hooks/useUserPs';
import { UserRole } from '../../client/base/user';

const Wrapper = styled.div<{ isNew: boolean }>`
  display: flex;
  flex-direction: row;
  height: ${({ isNew }): string => (isNew ? '470px' : 'fit-content')};
  overflow: scroll;

  .modal-dialog {
    min-width: 558px;
    min-height: 506px;
  }
`;

const PackagesContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  height: fit-content;
  padding: 25px 20px 25px 30px;
`;
const LoaderContainer = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface VisualPackagesProps {
  isNew: boolean;
  preset: Preset;
}

export default function VisualPackages({ preset, isNew = false }: VisualPackagesProps) {
  const { config, user } = React.useContext(AppContext);
  const visualPackagesList = visualPackagesListState.use();
  const lottiesPreviewList = lottiesPreviewListState.use();
  const sortedPackagesSids = sortedPackagesSidsState.use();
  const packagesLoading = packagesLoadingState.use();
  const lottiesLoading = lottiesLoadingState.use();
  const [loadingFont, setLoadingFonts] = React.useState(false);

  const fourColumn = useMediaQuery({ query: '(min-width: 1200px)' });
  const threeColumn = useMediaQuery({ query: '(min-width: 992px)' });
  const twoColumn = useMediaQuery({ query: '(min-width: 574px)' });

  const buttonIndex = React.useMemo(() => {
    return fourColumn ? 3 : threeColumn ? 2 : twoColumn ? 1 : 0;
  }, [fourColumn, threeColumn, twoColumn]);

  const [customRequested, setCustomRequested] = React.useState(false);
  const [defaultPackage, setDefaultPackage] = React.useState(undefined);

  React.useEffect(() => {
    getVisPackagesList(user);
    getLottiesPreviewList();
  }, []);

  const visualPackagesListToShow = React.useMemo(() => {
    let arr = [];
    sortedPackagesSids.forEach((sid) => {    
      if(user.role === UserRole.ADMIN || lottiesPreviewList[sid]) {
        arr.push(visualPackagesList[sid]);
      }
    });
    let requestCustomButton = { name: 'Request custom visual style' };
    if (!isNew) {
      if (arr?.length < buttonIndex + 1) {
        arr.push(requestCustomButton);
      } else {
        arr.splice(buttonIndex, 0, requestCustomButton);
      }
    }
    return arr;
  }, [visualPackagesList, sortedPackagesSids, buttonIndex, lottiesPreviewList]);

  const withUserPs = useUserPs();

  const logoAssetUrl = React.useMemo(() => {
    const logoAssetSid = preset?.getDefaultLogo()?.assetSid;
    if (logoAssetSid) {
      return withUserPs(`${config.CHUNKS_URL}/asset/${logoAssetSid}/type/${user?.cacheVersion}.png`);
    }
    return null;
  }, [preset]);

  React.useEffect(() => {
    if (!defaultPackage) {
      if (preset.defaultVisualPackage) {
        setDefaultPackage(preset.defaultVisualPackage);
      } else if (isNew && visualPackagesListToShow.length > 1) {
        preset.defaultVisualPackage = visualPackagesListToShow[0].sid;
        setDefaultPackage(visualPackagesListToShow[0].sid);
      }
    }
  }, [visualPackagesListToShow]);

  async function changeDefaultPackage(sid: string) {
    const oldValue = defaultPackage;
    setDefaultPackage(null);
    if (!isNew) {
      try {
        preset.defaultVisualPackage = sid;
        await preset.save();
        setDefaultPackage(sid);
      } catch (error) {
        console.error('changeDefaultPackage error: ', error);
        setDefaultPackage(oldValue);
      }
    } else {
      preset.defaultVisualPackage = sid;
      setDefaultPackage(sid);
    }
  }

  const fonts = React.useMemo(() => getFontFormPrestToPackagePreview(preset), [preset]);
  React.useEffect(async () => {
    if (fonts.length) {
      setLoadingFonts(true);
      await loadFontsForPackagePreview(fonts, user, config);
    }
    setLoadingFonts(false);
  }, [fonts]);

  return (
    <Wrapper isNew={isNew}>
      <PackagesContainer data-cy="packages-container" customRequested={customRequested}>
        {!(packagesLoading || lottiesLoading || loadingFont) ? (
          visualPackagesListToShow.map((item, index) => {
            if (!item.sid) {
              return (
                <RequestCustomButton
                  key={'customRequested'}
                  customRequested={customRequested}
                  setCustomRequested={setCustomRequested}
                />
              );
            } else {
                return (
                  <VisualPackageItem
                    key={item.sid}
                    visPack={item}
                    isSelected={item.sid === defaultPackage}
                    onItemClick={changeDefaultPackage}
                    isCustom={item.isCustom}
                    lottiePreview={lottiesPreviewList[item.sid]}
                    logoAssetUrl={logoAssetUrl}
                    colors={preset?.colors || []}
                    fonts={fonts}
                    isModal={isNew}
                  />
                );
            }
          })
        ) : (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
      </PackagesContainer>
      {customRequested && <RequestCustomPackageModal onHide={() => setCustomRequested(false)} />}
    </Wrapper>
  );
}
