import React from 'react';
import { SequenceDownloadNotificationType } from '../client/sequence';
import { AppContext } from '../lib/app-context';
import {
  downloadStatus,
  downloadPendingURL,
  downloadNotificationStatus,
  resetDownloadPendingState,
  sequenceState,
} from '../state/sequence';
import Button, { ButtonVariant } from '../ui-components/Button';
import { DOWNLOAD_STATUS } from '../state/sequence';
import { trackEvent } from '../utils/analityics.utils';
import { getDownloadLink } from '../utils/downloads.utils';
import { handleError } from '../state/error';
import { LoaderDiv } from './contentLibrary/libraryStyledComponent';
import Loader from '../ui-components/Loader';

export default function DownloadButton({ id, loveIt, children, onClick, aspectRatio, props }) {
  console.log('1', { aspectRatio });
  const { status, plan, config } = React.useContext(AppContext);
  const sequence = sequenceState.use();
  const downloadNotification = downloadNotificationStatus.use();
  const downloadPending = downloadStatus.use();

  React.useEffect(() => {
    if (!status) return;

    if (status.sid !== sequence.sid) {
      status.sid = sequence.sid;
    }

    status && status.sequence.on('download', onDownloadEvent);

    return () => {
      status && status.sequence.off('download', onDownloadEvent);
    }
  }, [status,sequence.sid]);

  const onDownloadEvent = ({ notificationType, output, sequenceVersion, ack }) => {
    console.log(
      `Download event received (1), notification type: '${notificationType}', sequence version: '${sequenceVersion}', output recieved: ${!!output}`,
      { output }
    );
    if (notificationType === SequenceDownloadNotificationType.DONE) {
      handleDownloadDoneSocketNotification(output, sequenceVersion);
      downloadStatus.set((state) => ({ ...state, MODE: DOWNLOAD_STATUS.READY }));
    }
    if (notificationType === SequenceDownloadNotificationType.FAILED) {
      downloadPendingURL.set(null);
      resetDownloadPendingState();
      handleError({
        title: 'Download',
        message: 'Download failed',
        responseError: 'Download failed',
      });
    }
    ack && ack();
  };

  async function handleDownloadDoneSocketNotification(output, sequenceVersion) {
    trackEvent('download-start');
    let url = null;
    downloadPendingURL.set(null);

    if (sequenceVersion) {
      url = await getDownloadLink(sequence.sid, sequenceVersion);
    } else {
      url = `${config.CONTENT_URL}/c/segments/${sequence.sid}/d/${output.sid}/${output.contentVersion}`;
    }
    downloadPendingURL.set(url);
  }

  return (
    <Button
      id={id}
      data-cy="download-submit"
      onClick={onClick}
      size={loveIt ? 'lg' : 'sm'}
      className="download-submit"
      // className="btn btn-white"
      {...props}
      variant={ButtonVariant.Primary}
    >
      <div className="text">
        {children}
        {downloadPending.MODE === DOWNLOAD_STATUS.GENERATE ? 'Downloading' : 'Download'}
      </div>
      <LoaderDiv style={{ width: 'unset' }} active={downloadPending.MODE === DOWNLOAD_STATUS.GENERATE}>
        <Loader size={'xxs'} />
      </LoaderDiv>
    </Button>
  );
}
