import styled from '@emotion/styled/macro';
import React, { useEffect } from 'react';
import { COLORS } from '../../../../constants/colors';
import ClosedCaption, { Word } from '../../../client/closedCaption';
import { highlightWordListState, keywordListState, setClosedCaption } from '../../../state/cuePoints';
import { scenesState, sequenceState } from '../../../state/sequence';
import { Section, SectionTitle, Stack, SubSectionTitle } from '../../../ui-components/Menu/MenuComponents.styled';
import lottieHighlight from '../../../ui-components/RecomnmendedWord/highlight-button.json';
import { trackEvent } from '../../../utils/analityics.utils';
import LottiePlayer from '../../lottie-player';
import SVG from '../../svg';
import { SequenceColorPicker, SequenceColorPickerValue } from './SequenceColorPicker';
import './Subtitles.scss';

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HighlightLottie = styled(LottiePlayer)`
  opacity: 0;
`;

const HighlightIcon = styled(SVG)`
  height: 24px;
  width: 24px;
`;

const HighlightButton = styled.div<{ unHighlight: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 100%;
  height: 28px;

  background: ${({ disabled, unHighlight }): string => (unHighlight || disabled ? COLORS.peech_gray_100 : 'inherit')};
  border: 1px solid;
  border-color: ${({ disabled, unHighlight }): string =>
    unHighlight || disabled ? COLORS.peech_gray_100 : COLORS.peech_primary_2_500};
  color: ${({ disabled, unHighlight }): string =>
    unHighlight || disabled ? COLORS.peech_gray_500 : COLORS.peech_primary_2_500};
  border-radius: 6px;
  font-weight: ${({ disabled, unHighlight }): string => (unHighlight || disabled ? '600' : '700')};
  cursor: ${({ disabled }): string => (disabled ? 'auto' : 'pointer')};
  opacity: ${({ disabled }): string => (disabled ? '0.7' : '1')};

  ${HighlightLottie} {
    display: none;
  }

  #highlight-icon-stop-1 {
    stopcolor: ${({ disabled, unHighlight }): string =>
      disabled ? COLORS.peech_gray_500 : unHighlight ? COLORS.peech_gray_500 : COLORS.peech_primary_1_500};
  }
  #highlight-icon-stop-2 {
    stopcolor: ${({ disabled, unHighlight }): string =>
      disabled ? COLORS.peech_gray_500 : unHighlight ? COLORS.peech_gray_500 : COLORS.peech_secondary_2_500};
  }
  #highlight-icon-stop-3 {
    stopcolor: ${({ disabled, unHighlight }): string =>
      disabled ? COLORS.peech_gray_500 : unHighlight ? COLORS.peech_gray_500 : COLORS.peech_primary_2_500};
  }

  &:hover {
    background-color: ${({ disabled, unHighlight }): string =>
      disabled ? COLORS.peech_gray_100 : !unHighlight ? COLORS.peech_gray_100 : COLORS.peech_primary_2_50};
    border-color: ${({ disabled, unHighlight }): string =>
      disabled ? COLORS.peech_gray_100 : !unHighlight ? COLORS.peech_gray_100 : COLORS.peech_primary_2_50};

    color: ${({ disabled, unHighlight }): string =>
      disabled ? COLORS.peech_gray_500 : !unHighlight ? COLORS.peech_gray_100 : COLORS.peech_gray_500};

    ${HighlightIcon} {
      display: ${({ disabled, unHighlight }): string => (disabled ? 'inline' : !unHighlight ? 'none' : 'inline')};
      animation: ${({ disabled, unHighlight }): string => (disabled ? 'none' : !unHighlight ? 'normal' : 'none')};
    }

    ${HighlightLottie} {
      display: ${({ disabled, unHighlight }): string => (disabled ? 'none' : !unHighlight ? 'inline' : 'none')};
      height: 24px;
      width: 24px;
      opacity: 1;
      animation: slideRigth 0.25s forwards;
    }
  }

  @keyframes slideRigth {
    from {
      transform: translate(0);
    }
    to {
      transform: translate(40px, 0);
    }
  }
`;

interface HighlightAllComponentProps {
  onColorChange(value: SequenceColorPickerValue | null): void;
  colorValue: string | number | undefined;
  setDisabledHighlightPalette: (val: boolean) => void;
  showPalette?: boolean;
}

export default function HighlightAllComponent({
  onColorChange,
  colorValue,
  setDisabledHighlightPalette,
  showPalette = true,
}: HighlightAllComponentProps) {
  const sequence = sequenceState.use();
  const scenes: any = scenesState.use((scenes) => scenes.map);
  const keywordsList = keywordListState.use();
  const highlightedWordsList = highlightWordListState.use();

  const keywordsWithHighlight: Word[] = React.useMemo(() => {
    return keywordsList.concat(highlightedWordsList);
  }, [highlightedWordsList.length, highlightedWordsList, keywordsList, keywordsList.length]);

  const hasHighLightWord = React.useMemo(() => {
    return !!keywordsWithHighlight.find((w) => w.highlight);
  }, [keywordsWithHighlight]);

  const disabledPalette = React.useMemo(() => {
    return !(hasHighLightWord || !!highlightedWordsList.length);
  }, [hasHighLightWord, highlightedWordsList.length]);

  useEffect(() => {
    setDisabledHighlightPalette(!(hasHighLightWord || !!highlightedWordsList.length));
  }, [hasHighLightWord, highlightedWordsList.length]);

  function highlightAllWords() {
    if (sequence && sequence.sid) {
      for (const item of keywordsWithHighlight) {
        item.highlight = !hasHighLightWord;
        item.origin = item.values.origin || item.word;
        item.changedProperties = [
          ...item.changedProperties,
          'chapterSid',
          'score',
          'flags',
          'excluded',
          'word',
          'startTime',
          'endTime',
          'newLine',
          'highlight',
          'origin',
        ];
      }

      ClosedCaption.updateWords(sequence.sid, keywordsWithHighlight);
      setClosedCaption(keywordsWithHighlight, sequence, scenes, true);
    }
  }

  return (
    <>
      <Section>
        <SubSectionTitle>Highlighted words</SubSectionTitle>
        <Stack spacing={4}>
          <ButtonContainer data-cy="highlight-menu">
            <HighlightButton
              disabled={!keywordsWithHighlight.length}
              unHighlight={hasHighLightWord}
              onClick={() => {
                trackEvent('subtitle-highlight', { action: !hasHighLightWord ? 'highlight-all' : 'unhighlight-all' });
                highlightAllWords();
              }}
            >
              <HighlightLottie animationData={lottieHighlight} autoplay={true} onAnimationComplete={() => {}} />
              <HighlightIcon name="highlight-icon" id="highlight-icon" />
              <span>{hasHighLightWord ? 'Unhightlight all' : 'Highlight all'}</span>
            </HighlightButton>
          </ButtonContainer>
        </Stack>
      </Section>
      {showPalette && <Section>
        <SubSectionTitle>Highlight Color</SubSectionTitle>
        <Stack spacing={4} disabled={disabledPalette}>
          <SequenceColorPicker
            withStatic={false}
            palette={sequence.colors ?? []}
            value={disabledPalette ? undefined : colorValue}
            onChange={onColorChange}
          />
        </Stack>
      </Section>}
    </>
  );
}
