
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'
import GraphicCuePoint, { IGraphicCuePoint, IGraphicCuePointFilter, IGraphicCuePointProperties } from '../graphicCuePoint'
import Image, { IImage } from '../image'
import Video, { IVideo } from '../video'

export enum TitleCuePointStatus {
	PENDING = 0,
	ACTIVE = 1,
	INACTIVE = 2,
	DELETED = 3,
	DOWNLOADING = 4,
	ERRONEOUS = 5,
};

export enum TitleCuePointTitleType {
	IMAGE = 1,
	VIDEO = 2,
	SVG = 3,
	SLIDE = 4,
	CREDIT = 5,
	TEXT = 6,
};

export interface ITitleCuePoint extends IGraphicCuePoint {
	titleType?: TitleCuePointTitleType | null;
	text?: string | null;
	credit?: string | null;
	creditRequired?: boolean | null;
	svg?: string | null;
	url?: string | null;
	videoUrl?: string | null;
	color?: string | null;
	colorIndex?: number | null;
	backgroundColor?: string | null;
	backgroundColorIndex?: number | null;
	fontSize?: number | null;
	fontWeight?: string | null;
	fontItalic?: boolean | null;
}

export interface ITitleCuePointProperties extends IGraphicCuePointProperties {
	titleType?: boolean;
	text?: boolean;
	credit?: boolean;
	creditRequired?: boolean;
	svg?: boolean;
	url?: boolean;
	videoUrl?: boolean;
	color?: boolean;
	colorIndex?: boolean;
	backgroundColor?: boolean;
	backgroundColorIndex?: boolean;
	fontSize?: boolean;
	fontWeight?: boolean;
	fontItalic?: boolean;
}

export interface ITitleCuePointFilter extends IGraphicCuePointFilter {
}

export class TitleCuePoint<IType extends ITitleCuePoint = ITitleCuePoint, ITypeProperties extends ITitleCuePointProperties = ITitleCuePointProperties> extends GraphicCuePoint<IType, ITypeProperties> implements ITitleCuePoint {
	
	static className = 'TitleCuePoint';
	static NAME = 'titleCuePoint';
	static PKS = [
		'sequenceSid',
		'sid',
	];
	static PROPERTIES = {...GraphicCuePoint.PROPERTIES, 
		titleType: true,
		text: true,
		credit: true,
		creditRequired: true,
		svg: true,
		url: true,
		videoUrl: true,
		color: true,
		colorIndex: true,
		backgroundColor: true,
		backgroundColorIndex: true,
		fontSize: true,
		fontWeight: true,
		fontItalic: true,
	};
	
	/**
	 * @deprecated use TitleCuePointStatus instead
	 */
	static STATUS: any = {
		PENDING: TitleCuePointStatus.PENDING,
		ACTIVE: TitleCuePointStatus.ACTIVE,
		INACTIVE: TitleCuePointStatus.INACTIVE,
		DELETED: TitleCuePointStatus.DELETED,
		DOWNLOADING: TitleCuePointStatus.DOWNLOADING,
		ERRONEOUS: TitleCuePointStatus.ERRONEOUS,
	};
	
	/**
	 * @deprecated use TitleCuePointTitleType instead
	 */
	static TITLE_TYPE: any = {
		IMAGE: TitleCuePointTitleType.IMAGE,
		VIDEO: TitleCuePointTitleType.VIDEO,
		SVG: TitleCuePointTitleType.SVG,
		SLIDE: TitleCuePointTitleType.SLIDE,
		CREDIT: TitleCuePointTitleType.CREDIT,
		TEXT: TitleCuePointTitleType.TEXT,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get titleType(): TitleCuePointTitleType | null | undefined {
		return this.values.titleType
	}
			
	set titleType(value: TitleCuePointTitleType | null | undefined) {
		if(this.values.titleType !== value) {
			this.propertyChanged('titleType', value);
			this.values.titleType = value;
		}
	}
			
	get text(): string | null | undefined {
		return this.values.text
	}
			
	set text(value: string | null | undefined) {
		if(this.values.text !== value) {
			this.propertyChanged('text', value);
			this.values.text = value;
		}
	}
			
	get credit(): string | null | undefined {
		return this.values.credit
	}
			
	set credit(value: string | null | undefined) {
		if(this.values.credit !== value) {
			this.propertyChanged('credit', value);
			this.values.credit = value;
		}
	}
			
	get creditRequired(): boolean | null | undefined {
		return this.values.creditRequired
	}
			
	set creditRequired(value: boolean | null | undefined) {
		if(this.values.creditRequired !== value) {
			this.propertyChanged('creditRequired', value);
			this.values.creditRequired = value;
		}
	}
			
	get svg(): string | null | undefined {
		return this.values.svg
	}
			
	set svg(value: string | null | undefined) {
		if(this.values.svg !== value) {
			this.propertyChanged('svg', value);
			this.values.svg = value;
		}
	}
			
	get url(): string | null | undefined {
		return this.values.url
	}
			
	set url(value: string | null | undefined) {
		if(this.values.url !== value) {
			this.propertyChanged('url', value);
			this.values.url = value;
		}
	}
			
	get videoUrl(): string | null | undefined {
		return this.values.videoUrl
	}
			
	set videoUrl(value: string | null | undefined) {
		if(this.values.videoUrl !== value) {
			this.propertyChanged('videoUrl', value);
			this.values.videoUrl = value;
		}
	}
			
	get color(): string | null | undefined {
		return this.values.color
	}
			
	set color(value: string | null | undefined) {
		if(this.values.color !== value) {
			this.propertyChanged('color', value);
			this.values.color = value;
		}
	}
			
	get colorIndex(): number | null | undefined {
		return this.values.colorIndex
	}
			
	set colorIndex(value: number | null | undefined) {
		if(this.values.colorIndex !== value) {
			this.propertyChanged('colorIndex', value);
			this.values.colorIndex = value;
		}
	}
			
	get backgroundColor(): string | null | undefined {
		return this.values.backgroundColor
	}
			
	set backgroundColor(value: string | null | undefined) {
		if(this.values.backgroundColor !== value) {
			this.propertyChanged('backgroundColor', value);
			this.values.backgroundColor = value;
		}
	}
			
	get backgroundColorIndex(): number | null | undefined {
		return this.values.backgroundColorIndex
	}
			
	set backgroundColorIndex(value: number | null | undefined) {
		if(this.values.backgroundColorIndex !== value) {
			this.propertyChanged('backgroundColorIndex', value);
			this.values.backgroundColorIndex = value;
		}
	}
			
	get fontSize(): number | null | undefined {
		return this.values.fontSize
	}
			
	set fontSize(value: number | null | undefined) {
		if(this.values.fontSize !== value) {
			this.propertyChanged('fontSize', value);
			this.values.fontSize = value;
		}
	}
			
	get fontWeight(): string | null | undefined {
		return this.values.fontWeight
	}
			
	set fontWeight(value: string | null | undefined) {
		if(this.values.fontWeight !== value) {
			this.propertyChanged('fontWeight', value);
			this.values.fontWeight = value;
		}
	}
			
	get fontItalic(): boolean | null | undefined {
		return this.values.fontItalic
	}
			
	set fontItalic(value: boolean | null | undefined) {
		if(this.values.fontItalic !== value) {
			this.propertyChanged('fontItalic', value);
			this.values.fontItalic = value;
		}
	}
			
	
	static get<IType extends ITitleCuePoint = ITitleCuePoint>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends ITitleCuePointFilter = ITitleCuePointFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends ITitleCuePointFilter = ITitleCuePointFilter, IType extends ITitleCuePoint = ITitleCuePoint>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
	
	static async icons(sequenceSid: string, text: string, count: number, page: number): Promise<Array<string> | null> {
		const query = `query{
			titleCuePoint {
				icons(sequenceSid: ${sequenceSid !== undefined ? `${JSON.stringify(sequenceSid)}` : 'null'}, text: ${text !== undefined ? `${JSON.stringify(text)}` : 'null'}, count: ${count !== undefined ? `${JSON.stringify(count)}` : 'null'}, page: ${page !== undefined ? `${JSON.stringify(page)}` : 'null'})
			}
		}`;
		var data = await Base.request(query);
		data = data.titleCuePoint || null;
		if(data && data.icons !== undefined && data.icons !== null) {
			return data.icons;
		}
		return null;
	}
	
	static async images(sequenceSid: string, text: string, count: number, page: number, properties: IBaseProperties | null = null): Promise<Array<Image> | null> {
		properties = (properties || Image.PROPERTIES) as IBaseProperties;
		const query = `query{
			titleCuePoint {
				images(sequenceSid: ${sequenceSid !== undefined ? `${JSON.stringify(sequenceSid)}` : 'null'}, text: ${text !== undefined ? `${JSON.stringify(text)}` : 'null'}, count: ${count !== undefined ? `${JSON.stringify(count)}` : 'null'}, page: ${page !== undefined ? `${JSON.stringify(page)}` : 'null'}){${Base.toProperties(properties)}}
			}
		}`;
		var data = await Base.request(query);
		data = data.titleCuePoint || null;
		if(data && data.images !== undefined && data.images !== null) {
			return data.images.map((item: IImage) => new Image(item.sid as string).set(item));
		}
		return null;
	}
	
	static async videos(sequenceSid: string, text: string, count: number, properties: IBaseProperties | null = null): Promise<Array<Video> | null> {
		properties = (properties || Video.PROPERTIES) as IBaseProperties;
		const query = `query{
			titleCuePoint {
				videos(sequenceSid: ${sequenceSid !== undefined ? `${JSON.stringify(sequenceSid)}` : 'null'}, text: ${text !== undefined ? `${JSON.stringify(text)}` : 'null'}, count: ${count !== undefined ? `${JSON.stringify(count)}` : 'null'}){${Base.toProperties(properties)}}
			}
		}`;
		var data = await Base.request(query);
		data = data.titleCuePoint || null;
		if(data && data.videos !== undefined && data.videos !== null) {
			return data.videos.map((item: IVideo) => new Video(item.sid as string).set(item));
		}
		return null;
	}
}
