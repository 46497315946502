import styled from "@emotion/styled/macro";
import React from 'react';
import Col from 'react-bootstrap/Col';
import { COLORS } from '../../../constants/colors';
import Preset from "../../client/preset";
import SVG from '../../components/svg';
import { AppContext } from "../../lib/app-context";
import ImageWithPlaceholder from "../ImageWithPlaceholder/ImageWithPlaceholder";
import useUserPs from "../../hooks/useUserPs";



const BKItem = styled.div` 
    position: relative;
    height: fit-content;
    width: 92%;
    display: flex;
    flex-direction: column;
    border: 1px solid;
    border-color: ${COLORS.peech_gray_100};
    border-radius: 8px;
    min-width: 180px;
    margin: 10px;
            
`

const ThumbnailContainer = styled.div` 
    width: 100%;
    min-width: 180px;
    aspect-ratio: 16 / 9;
    background-color: inherit;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid;
    border-color: ${COLORS.peech_gray_100};


    img {
        width: 50px;
    }
   
`

const BKNameWrapper = styled.div<{ isSelected: boolean }>` 
    width: 100%;
    height: 30px;
    padding: 5px 10px 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 8px 8px;
   
    span {
        width: 85%;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: ${COLORS.peech_gray_500};
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 17px;
    }

    label {
        height: 19px;
        width: 19px; 
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: solid 1px;
        border-color: ${COLORS.peech_gray_400};
        border-radius: 4px;
        position: relative;
        input {
            height: 10px;
            width: 10px;
            margin: 0;
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
        .checkmark {
            position: absolute;
            top: -1px;
            right: -1px;
            display: ${({ isSelected }): string => (isSelected ? 'flex' : 'none')}; 
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            height: 19px;
            width: 19px;
            border-radius: 4px;
            background-color: #EA429D;
            fill: ${COLORS.peech_neutral_white};
        }
    }
`




interface BrandKitItemProps {
    preset: Preset;
    isSelected: boolean;
    onItemClick: Function;
}

export default function BrandKitItem({ preset, isSelected = false, onItemClick }: BrandKitItemProps) {

    const { user, config } = React.useContext(AppContext);
    const presetLogoAsset = preset.getDefaultLogo();
    const withUserPs = useUserPs();

    const onSelect = () => {
        if (!isSelected) {
            onItemClick(preset.sid);
        }
    }

    return (
        <Col xs="12" sm="6" lg="4" xl="3">
            <BKItem>
                <ThumbnailContainer onClick={onSelect}>
                    <img src={withUserPs(`${config.CHUNKS_URL}/asset/${presetLogoAsset?.assetSid}/type/${user?.cacheVersion}.png`)} alt={user?.preset} />
                </ThumbnailContainer>
                <BKNameWrapper isSelected={isSelected}>
                    <span >
                        {preset.name || 'First Brand Kit'}
                    </span>
                    <label onChange={onSelect}>
                        <input type="checkbox" checked={isSelected} />
                        <span className="checkmark">
                            <SVG name="check" viewBox="0 0 36 36" />
                        </span>
                    </label>
                </BKNameWrapper>
            </BKItem>
        </Col>
    );
}
