
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'
import Picture, { IPicture, IPictureFilter, IPictureProperties } from '../picture'

export enum ContentStatus {
	PENDING = 0,
	PROCESSING = 1,
	READY = 2,
	DELETED = 3,
	ERRONEOUS = 5,
};

export interface IContent extends IPicture {
	size?: number | null;
	contentVersion?: number | null;
	duration?: number | null;
	srcDuration?: number | null;
	configurationTime?: number | null;
	generatingTime?: number | null;
	aspectRatio?: string | null;
}

export interface IContentProperties extends IPictureProperties {
	size?: boolean;
	contentVersion?: boolean;
	duration?: boolean;
	srcDuration?: boolean;
	configurationTime?: boolean;
	generatingTime?: boolean;
	aspectRatio?: boolean;
}

export interface IContentFilter extends IPictureFilter {
}

export class Content<IType extends IContent = IContent, ITypeProperties extends IContentProperties = IContentProperties> extends Picture<IType, ITypeProperties> implements IContent {
	
	static className = 'Content';
	static NAME = 'content';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Picture.PROPERTIES, 
		size: true,
		contentVersion: true,
		duration: true,
		srcDuration: true,
		configurationTime: true,
		generatingTime: true,
		aspectRatio: true,
	};
	
	/**
	 * @deprecated use ContentStatus instead
	 */
	static STATUS: any = {
		PENDING: ContentStatus.PENDING,
		PROCESSING: ContentStatus.PROCESSING,
		READY: ContentStatus.READY,
		DELETED: ContentStatus.DELETED,
		ERRONEOUS: ContentStatus.ERRONEOUS,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get size(): number | null | undefined {
		return this.values.size
	}
			
	get contentVersion(): number | null | undefined {
		return this.values.contentVersion
	}
			
	get duration(): number | null | undefined {
		return this.values.duration
	}
			
	set duration(value: number | null | undefined) {
		if(this.values.duration !== value) {
			this.propertyChanged('duration', value);
			this.values.duration = value;
		}
	}
			
	get srcDuration(): number | null | undefined {
		return this.values.srcDuration
	}
			
	get configurationTime(): number | null | undefined {
		return this.values.configurationTime
	}
			
	get generatingTime(): number | null | undefined {
		return this.values.generatingTime
	}
			
	get aspectRatio(): string | null | undefined {
		return this.values.aspectRatio
	}
			
	
	static get<IType extends IContent = IContent>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IContentFilter = IContentFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IContentFilter = IContentFilter, IType extends IContent = IContent>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
