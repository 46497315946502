import { navigate } from "gatsby";
import React, { useCallback } from "react";
import { Chapter } from "../client/sequence";
import { AppContext } from "../lib/app-context";
import { onCloneSequence } from "../ui-components/SequenceCloneOptions/onSequenceClone";
import { AspectRatio } from "../ui-components/SequenceCloneOptions/useSequenceCloneOptions";
import { trackEvent } from "../utils/analityics.utils";

export const useCreateTranslatedSequence = (trigger: string, sequence: any, tempLang: any, setTempLang: any, onClose: ()=>void) => {
    const { config, user, plan } = React.useContext(AppContext);
    const chapter = sequence?.chapters?.find((c) => c.status !== Chapter.STATUS.DELETED);
    const sequenceThumbnail = chapter?.thumbnailUrl(sequence, 200, config.CHUNKS_URL ?? '');
    const onTranslateSuccess = () => {
        if (trigger === 'Project Monitor') {
            navigate(`/`);
        }
    };

    const onTranslateClick = useCallback(() => {
        if (!(tempLang && sequence && sequenceThumbnail && user?.sid)) {
            console.log(trigger, sequence, tempLang, setTempLang, onClose)
            return;
        }

        if(user?.UIFLAGS.SMART_LIBRARY && sequence.footageSid) {
            window.top?.postMessage(`TRANSLATE_${tempLang.languageCode}_${trigger}`, '*');
            return
        }

        trackEvent('Translate Project', {
            trigger: trigger,
            languageCode: tempLang,
        });
        onCloneSequence(
            sequence.aspectRatio as AspectRatio,
            sequenceThumbnail!,
            sequence,
            user.sid!,
            tempLang?.languageCode,
            onTranslateSuccess
    );
        setTempLang(null);
        onClose();
    }, [tempLang, sequence, sequenceThumbnail, user?.sid, onCloneSequence]);

    return onTranslateClick;
}
