
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export enum GraphicColorType {
	DOMINANCY = 0,
	BRIGHTNESS = 1,
};

export interface IGraphicColor extends IBase {
	name?: string | null;
	className?: string | null;
	type?: number | null;
	color?: string | null;
	colorIndex?: number | null;
}

export interface IGraphicColorProperties extends IBaseProperties {
	name?: boolean;
	className?: boolean;
	type?: boolean;
	color?: boolean;
	colorIndex?: boolean;
}

export interface IGraphicColorFilter extends IBaseFilter {
}

export class GraphicColor<IType extends IGraphicColor = IGraphicColor, ITypeProperties extends IGraphicColorProperties = IGraphicColorProperties> extends Base<IType, ITypeProperties> implements IGraphicColor {
	
	static className = 'GraphicColor';
	static NAME = 'graphicColor';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		name: true,
		className: true,
		type: true,
		color: true,
		colorIndex: true,
	};
	
	/**
	 * @deprecated use GraphicColorType instead
	 */
	static TYPE: any = {
		DOMINANCY: GraphicColorType.DOMINANCY,
		BRIGHTNESS: GraphicColorType.BRIGHTNESS,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get name(): string | null | undefined {
		return this.values.name
	}
			
	set name(value: string | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	get className(): string | null | undefined {
		return this.values.className
	}
			
	set className(value: string | null | undefined) {
		if(this.values.className !== value) {
			this.propertyChanged('className', value);
			this.values.className = value;
		}
	}
			
	get type(): number | null | undefined {
		return this.values.type
	}
			
	set type(value: number | null | undefined) {
		if(this.values.type !== value) {
			this.propertyChanged('type', value);
			this.values.type = value;
		}
	}
			
	get color(): string | null | undefined {
		return this.values.color
	}
			
	set color(value: string | null | undefined) {
		if(this.values.color !== value) {
			this.propertyChanged('color', value);
			this.values.color = value;
		}
	}
			
	get colorIndex(): number | null | undefined {
		return this.values.colorIndex
	}
			
	set colorIndex(value: number | null | undefined) {
		if(this.values.colorIndex !== value) {
			this.propertyChanged('colorIndex', value);
			this.values.colorIndex = value;
		}
	}
			
	
	static get<IType extends IGraphicColor = IGraphicColor>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IGraphicColorFilter = IGraphicColorFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IGraphicColorFilter = IGraphicColorFilter, IType extends IGraphicColor = IGraphicColor>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
