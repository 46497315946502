import React from 'react'
import Modal from 'react-bootstrap/Modal'
import ModalTitle from 'react-bootstrap/ModalTitle'
import Alert from 'react-bootstrap/Alert'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/styles.scss'

import Button from '../ui-components/Button'
import { AppContext } from '../lib/app-context';
import User from '../client/user'
import { trackEvent } from '../utils/analityics.utils';
/**
 * 
 * @param {{
 *  onHide: Function,
 * }} props
 * @returns 
 */

export default function PasswordReset({ onHide }) {
    const creds = React.useRef({})
    const { user } = React.useContext(AppContext)
    const [passwordValidation, setPasswordValidation] = React.useState(false)
    const [success, setSuccess] = React.useState()
    const [error, setError] = React.useState()

    async function onRequestResetPassword(e) {
        e.preventDefault()
        setError()
        try {
            
            await User.requestResetPassword(user.email);
            setSuccess('An email with instructions was sent. Please check your email.')
           
            trackEvent('password-reset');
        }
        catch (err) {
            setError(err.message)
        }
    }

    async function onChangePassword(e) {
        e.preventDefault()
        setError()
        try {
            await user.changePassword(creds.current.oldPassword, creds.current.password);
            setSuccess('Your password has been changed successfully.')
           
            trackEvent('password-change');
            
        }
        catch (err) {
            setError(err.message)
        }
    }

    function onValueChange(e) {
        creds.current[e.target.name] = e.target.value
        if (creds.current.password && creds.current.verifyPassword) {
            setPasswordValidation(creds.current.password === creds.current.verifyPassword && creds.current.oldPassword)
        }
    }

    return <Modal
        show={true}
        onHide={onHide}
        className="reset-password"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size='lg'
        style={{ paddingRight: 0, paddingLeft: 0 }}
    >
        <button className='btn btn-close' onClick={onHide}/>
        <ModalTitle style={{ padding: 0 }}>
            <div className="panel-heading">
                <h2 className="panel-title">Change password</h2>
                <h5 className="panel-subtitle">******</h5>
            </div>
        </ModalTitle>
        <Modal.Body className="reset-password-modal-body">
            <div style={{ minHeight: '25px' }}>
                {success && <Alert style={{ margin: '0 40px 0 40px' }} variant="success">{success}</Alert>}
                {error && <Alert style={{ margin: '0 40px 0 40px' }} variant="danger">{error}</Alert>}
            </div>
            <div className="panel panel-primary">
                <Row style={{ justifyContent: 'center' }}>
                    <Col>
                        <div className="form-group">
                            <label htmlFor="old-password">
                                <input
                                    placeholder="Old password"
                                    type="password"
                                    id="old-password"
                                    name="oldPassword"
                                    className="form-control input-sm"
                                    required
                                    autoComplete="current-password"
                                    minLength="8"
                                    maxLength="30"
                                    onChange={onValueChange}
                                />
                            </label>
                        </div>
                        <div className="form-group">
                            <label htmlFor="new-password">
                                <input
                                    placeholder="New password"
                                    type="password"
                                    id="new-password"
                                    name="password"
                                    className="form-control input-sm"
                                    required
                                    autoComplete="current-password"
                                    minLength="8"
                                    maxLength="30"
                                    onChange={onValueChange}
                                />
                            </label>
                        </div>
                        <div className="form-group">
                            <label  htmlFor="verify-password">
                                <input
                                    placeholder="Confirm new password"
                                    type="password"
                                    id="verify-password"
                                    name="verifyPassword"
                                    className="form-control input-sm"
                                    required
                                    autoComplete="current-password"
                                    minLength="8"
                                    maxLength="30"
                                    onChange={onValueChange}
                                />
                            </label>
                        </div>
                        <Button
                            id="change-password-submit"
                            className="btn btn-default  text-white"
                            disabled={!passwordValidation}
                            style={{ marginTop: '46px', padding: 0 }}
                            onClick={onChangePassword}
                            label='Done'
                        >
                         
                        </Button>
                    </Col>
                    <Col>
                    <div style={{ paddingTop: '50px', marginRight: '300px'}}>
                            or
                        </div>
                        <div style={{ fontSize: '14px', paddingTop: '10px' }}>
                            <div>
                                Send an email <br />
                                to reset the password
                            </div>
                            <Button
                                id="reset-password-submit"
                                className="btn btn-default text-white"
                                style={{ marginTop: '28px', padding: 0 }}
                                onClick={onRequestResetPassword}
                                label='Send me an email'
                            >
                              
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Modal.Body>
    </Modal>
}