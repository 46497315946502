import React from 'react'
import classNames from 'classnames'

import VideoType from '../../client/videoType'

import './VideoType.scss'
import SVG from '../../components/svg'

interface VideoTypeProps {
    onClick(videoType: VideoType): void;
    isSelected: boolean;
    videoType: VideoType;
}

export default function VideoTypeItem({ onClick, videoType, isSelected, ...props }: VideoTypeProps) {
    const [animationState, setAnimationState] = React.useState<boolean>(false)

    const IconClassNames = () => classNames('illustration', { 'animate': animationState, 'selected': isSelected })

    function onSelect() {
        setAnimationState(true)
        onClick && onClick(videoType)
    }

    React.useEffect(() => {
        animationState && setTimeout(() => setAnimationState(false), 300)
    }, [animationState])

    return (<div className="videoType" id={`videoType--${videoType.sid}`} data-editrule-sid={videoType.editRulesSid} data-selected-sor={isSelected} {...props} onClick={onSelect}>
        <div className="videoType--icon">
            <SVG name="icon-background" className="illustration-svg illustration-background" viewBox="0 0 156 140" />
            <div className={IconClassNames()} dangerouslySetInnerHTML={{ __html: videoType.icon }} />
        </div>
        <span className="videoType--label">{videoType.name}</span>
        <label className="videoType--radio" htmlFor={`#videoType--${videoType.sid}--checkbox`} >
            <input type="radio" id={`videoType--${videoType.sid}--checkbox`} checked={isSelected} readOnly />
            <span className="checkmark"></span>
        </label>
    </div >)
}

VideoTypeItem.defaultProps = {
    isSelected: false,
    videoType: {
        name: "Customer Testimonial",
        objectType: "VideoType",
        icon: `<svg viewBox="0 0 156 140">
        <path d="M92.1718 103.719C91.8822 103.719 91.5926 103.623 91.303 103.43L80.9732 95.4169H59.0586C56.5485 95.4169 54.5212 93.3895 54.5212 90.8795V73.2127C54.5212 72.6334 55.0039 72.2473 55.4866 72.2473C55.9693 72.2473 56.452 72.73 56.452 73.2127V90.8795C56.452 92.231 57.6105 93.3895 58.9621 93.3895H81.5524L91.496 101.113L91.6891 93.3895H102.115C103.467 93.3895 104.625 92.231 104.625 90.8795V56.8974C104.625 55.5458 103.467 54.3874 102.115 54.3874H59.0586C57.707 54.3874 56.5486 55.5458 56.5486 56.8974V65.7791C56.5486 66.3583 56.0659 66.7445 55.5832 66.7445C55.1005 66.7445 54.6178 66.2618 54.6178 65.7791V56.8974C54.6178 54.3874 56.6451 52.36 59.1551 52.36H102.212C104.722 52.36 106.749 54.3874 106.749 56.8974V90.8795C106.749 93.3895 104.722 95.4169 102.212 95.4169H93.7165L93.5234 102.368C93.5234 102.85 93.2338 103.333 92.7511 103.623C92.6545 103.719 92.4614 103.719 92.1718 103.719Z" />
        <path d="M73.0572 75.6264C73.8569 75.6264 74.5053 74.9781 74.5053 74.1783C74.5053 73.3786 73.8569 72.7302 73.0572 72.7302C72.2574 72.7302 71.6091 73.3786 71.6091 74.1783C71.6091 74.9781 72.2574 75.6264 73.0572 75.6264Z" />
        <path d="M80.7809 75.6259C81.5807 75.6259 82.229 74.9776 82.229 74.1778C82.229 73.3781 81.5807 72.7297 80.7809 72.7297C79.9812 72.7297 79.3328 73.3781 79.3328 74.1778C79.3328 74.9776 79.9812 75.6259 80.7809 75.6259Z" />
        <path d="M88.4076 75.6259C89.2074 75.6259 89.8557 74.9776 89.8557 74.1778C89.8557 73.3781 89.2074 72.7297 88.4076 72.7297C87.6079 72.7297 86.9595 73.3781 86.9595 74.1778C86.9595 74.9776 87.6079 75.6259 88.4076 75.6259Z" />
        <path d="M72.0908 40.3909V66.9394C72.0908 67.3255 72.4769 67.5186 72.7666 67.3255L91.6884 54.0995C91.978 53.9065 91.978 53.5203 91.6884 53.3272L72.7666 40.0047C72.4769 39.8116 72.0908 40.0047 72.0908 40.3909Z" fill="white" />
        <g class="highlight">
            <path d="M72.4774 68.2888C72.2843 68.2888 71.9947 68.1923 71.8016 68.0958C71.3189 67.8061 71.0293 67.3234 71.0293 66.8407V40.2922C71.0293 39.713 71.3189 39.2303 71.8016 39.0372C72.2843 38.7476 72.8635 38.8441 73.2497 39.1337L92.1715 52.3597C92.5577 52.6494 92.7508 53.0355 92.7508 53.5182C92.7508 54.0009 92.5577 54.3871 92.1715 54.6767L73.2497 67.9027C73.0566 68.1923 72.767 68.2888 72.4774 68.2888ZM73.0566 41.4507V65.8753L90.5304 53.7113L73.0566 41.4507Z" />
        </g>
    </svg>`,
        sid: "0lklxsysts",
    }
};

