import React from 'react'
import Card from 'react-bootstrap/Card'
import Tooltip from 'react-bootstrap/Tooltip'
import Dropdown from 'react-bootstrap/Dropdown';
import FormCheck from 'react-bootstrap/FormCheck';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import { scenesState, sequenceState, setScene } from '../state/sequence'
import { cuePointsState, cuePointsVersionState } from '../state/cuePoints'

import SVG from '../components/svg'
import DashPlayer from '../components/dash-player'
import MenuItem from '../ui-components/Menu/MenuItem';

import { readRemoteFile } from 'react-papaparse'

import ClosedCaption, { Word } from '../client/closedCaption'
import { Chapter } from '../client/sequence';

import Content from '../client/content';
import { AppContext } from '../lib/app-context';
import useUserPs from '../hooks/useUserPs';

function timeToObject(time) {
    var obj = {
        millis: Math.round(time) % 1000,
    }
    time = Math.floor(time / 1000)
    obj.seconds = time % 60
    time = Math.floor(time / 60)
    obj.minutes = time % 60
    time = Math.floor(time / 60)
    obj.hours = time % 60
    return obj
}

function formatTime(time) {
    if (time === undefined) {
        return ''
    }
    if (typeof time === 'number') {
        time = timeToObject(time)
    }
    return (
        time &&
        `${time.hours
            .toString()
            .padStart(2, '0')
        }:${time.minutes
            .toString()
            .padStart(2, '0')
        }:${time.seconds
            .toString()
            .padStart(2, '0')
        },${time.millis
            .toString()
            .padStart(3, '0')
        }`
    )
}

function Controls({ player, duration, playing, currentTime, buffers, onSeek, clipFrom }) {
    const slide = React.createRef()
    const seeking = React.useRef(null)

    const calcPercent = time => time && duration ? (time / duration) * 100 : 0;
    const [percent, setPercent] = React.useState(calcPercent(currentTime))

    React.useEffect(() => {
        const stopSeek = () => (seeking.current = null)
        document.addEventListener('mouseup', stopSeek)
        return () => document.removeEventListener('mouseup', stopSeek)
    }, [])

    React.useEffect(() => setPercent(calcPercent(currentTime)), [currentTime])

    function seekTime(time) {
        setPercent(calcPercent(time))
        onSeek && onSeek(time)
    }

    function seek(e, click = false) {
        if (duration === undefined) {
            return
        }

        var { left, width } = seeking.current && !click
            ? seeking.current
            : slide.current.getBoundingClientRect()
        var offset = e.clientX - left
        if (offset <= 0) {
            seekTime(0)
        } else if (offset >= width) {
            seekTime(duration)
        } else {
            seekTime((duration * offset) / width)
        }
    }

    function startSeek(e) {
        if (!slide.current) {
            return
        }
        var rect = slide.current.getBoundingClientRect()
        seeking.current = {
            left: rect.left,
            width: rect.width,
        }
        seek(e)
        document.addEventListener('mousemove', seek)
        document.addEventListener(
            'mouseup',
            () => document.removeEventListener('mousemove', seek),
            { once: true }
        )
    }

    function onPlay() {
        player.current && player.current.play()
    }

    function onPause() {
        player.current && player.current.pause()
    }

    return (
        <div className="controllers">
            <table colSpan="0" rowSpan="0">
                <tbody>
                    <tr>
                        <td className="play">
                            <SVG
                                // viewBox="-6 0 24 24"
                                name="prev"
                                onClick={() => onSeek && onSeek(0)}
                            />
                        </td>
                        <td className="play">
                            <SVG
                                viewBox="6 -2 23 26"
                                name="play"
                                style={{ display: playing ? 'none' : 'block' }}
                                onClick={onPlay}
                            />
                            <SVG
                                viewBox="6 -2 23 26"
                                name="pause"
                                style={{ display: playing ? 'block' : 'none' }}
                                onClick={onPause}
                            />
                        </td>
                        <td
                            ref={slide}
                            onMouseDown={startSeek}
                            onClick={e => seek(e, true)}
                        >
                            <div className="progress-scene">
                                {buffers.map((buffer, i) => (
                                    <div key={`buf-${i}`} className="progress-buffer" style={{
                                        width: (Math.min(100, (buffer.end - clipFrom) / duration * 100) - Math.max(buffer.start - clipFrom, 0)) + '%',
                                        left: Math.max(buffer.start - clipFrom, 0) + '%',
                                    }}></div>
                                ))}
                                <div className="progress-done" style={{ width: percent + '%', }} />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="time-text time">
                <span>
                    {currentTime !== undefined &&
                        formatTime(currentTime * 1000)}
                </span>
                /
                <span>
                    {duration && formatTime(duration * 1000)}
                </span>
            </div>
        </div >
    )
}

/**
 * @param {object} props
            * @param {'left' | 'right'} props.side
            * @param {React.RefObject < HTMLDivElement >} props.trimmer
            * @param {number} props.value
            * @param {number} props.min
            * @param {number} props.max
            * @param {number} props.duration
            * @param {function (number)} props.snap
            * @param {function (number)} props.onChange
            */
function Edge({ side, trimmer, value, min, max, duration, snap, onChange }) {
    const resizing = React.useRef(null)
    const [width, setWidth] = React.useState(side === 'left' ? (value / duration * 100) : (100 - value / duration * 100));
    const [currentValue, setCurrentValue] = React.useState(value);
    const [init, setInit] = React.useState(true);

    const minWidth = side === 'left' ? (min / duration * 100) : (100 - max / duration * 100)
    const maxWidth = side === 'left' ? (max / duration * 100) : (100 - min / duration * 100)

    const step = 0.01;
    function round(x) {
        return parseFloat(snap(Math.round(x / step) * step).toFixed(3));
    }

    /**
     *
     * @param {object} e
            * @param {MouseEvent} e.nativeEvent
            * @param {HTMLDivElement} e.target
            */
    function onResize(e, set = false) {
        if (!resizing.current) {
            return;
        }

        var { left, width } = resizing.current;
        // var {left, width} = resizing.current && !set
        //         ? resizing.current
        //         : trimmer.current.getBoundingClientRect()
        var offset = Math.max(0, Math.min(width, e.clientX - left))
        var w = (side === 'left') ? (offset / width * 100) : ((width - offset) / width * 100);
        setWidth(Math.min(maxWidth, Math.max(minWidth, w)))
        const ratio = offset / width;
        const val = round(Math.min(max, Math.max(min, duration * ratio)))
        setCurrentValue(val)
        setInit(false)
        onChange && onChange(val);
        // if(set && onChange) {
        //     onChange(val);
        // }
    }

    function startDrag(e) {
        e.stopPropagation()

        if (!trimmer.current) {
            return
        }
        var rect = trimmer.current.getBoundingClientRect()
        resizing.current = {
            left: rect.left,
            width: rect.width,
        }
        onResize(e)
        document.addEventListener('mousemove', onResize)
        document.addEventListener(
            'mouseup',
            e => {
                document.removeEventListener('mousemove', onResize);
                onResize(e, true)
                resizing.current = null;
            },
            { once: true }
        )
    }

    return <div
        className={'edge ' + side}
        style={{ width: `calc(${width}% + 4px)` }}
    >
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{formatTime(currentValue * 1000)}</Tooltip>}
        >
            <div className="marker" onMouseDown={startDrag}>
                <div className={'cut ' + (init ? 'marker-init' : '')}>
                    <svg className="line" viewBox="0 0 1 100">
                        <line x1="1" y1="0" x2="1" y2="100" />
                    </svg>
                    <SVG className="icon" viewBox="0 0 36 36" name="cut" />
                </div>
            </div>
        </OverlayTrigger>
    </div>;
}

/**
 * @param {object} props
            * @param {string} props.sid Chapter sid
            * @param {Function} props.onClose
            */
export default function Trim({ sid, onClose, onTrim }) {
    const { config } = React.useContext(AppContext)
    const sequence = sequenceState.use();
    const chapters = scenesState.use(scenes => scenes.filter.chapters)
    const chapter = scenesState.use(scenes => scenes.map[sid])
    const srcChapter = scenesState.use(scenes => scenes.source(chapter))
    const cuePointsData = cuePointsState.use()
    const cuePointsVersion = cuePointsVersionState.use()
    const [clipFrom, setClipFrom] = React.useState(chapter.clipFrom || 0);
    const [clipTo, setClipTo] = React.useState((chapter.clipFrom || 0) + chapter.duration);
    const [playing, setPlaying] = React.useState(0)
    const [currentTime, setCurrentTime] = React.useState(0)
    const [buffers, setBuffers] = React.useState([])
    const [enableSnap, setEnableSnap] = React.useState(true)
    const volumeMap = React.useRef()

    /**
     * @type {React.RefObject < HTMLVideoElement >}
            */
    const player = React.createRef();

    React.useEffect(() => {
        const url = volUrl();
        if (!url) {
            return;
        }
        readRemoteFile(url, {
            header: false, fastMode: true, complete: (results, file) => {
                volumeMap.current = results.data
            }
        })
    }, [])

    React.useEffect(() => {
        if (currentTime < clipFrom && player.current) {
            player.current.currentTime = clipFrom;
        }
    }, [clipFrom])

    React.useEffect(() => {
        if (!words.length) {
            setEnableSnap(false)
        }
    }, [words])

    /**
     * @type {Word[]}
            */
    const words = React.useMemo(() => Object
        .values(cuePointsData)
        .filter(cuePoint => cuePoint &&
            cuePoint.type === ClosedCaption.TYPE.CLOSED_CAPTION &&
            cuePoint.chapterSid === (chapter.linkSid || chapter.sid) &&
            cuePoint.words
        )
        .reduce((arr, closedCaption) => [...arr, ...closedCaption.words], [])
        .sort((a, b) => a.startTime - b.startTime).map(word => {
            word.start = word.startTime / 1000;
            word.end = word.endTime / 1000;
            return word;
        })
        , [chapter, cuePointsVersion]);

    const trimmer = React.createRef()

    const HEIGHT = 50;
    const PREVIEW_WIDTH = 730;
    const duration = chapter.srcDuration;
    const width = HEIGHT * 16 / 9; // ratio
    const length = Math.ceil(PREVIEW_WIDTH / width);
    const interval = duration / length;
    const thumbOffsets = Array.from({ length }, (_, index) => index * interval);

    async function onSave() {
        chapter.clipFrom = clipFrom;
        chapter.duration = clipTo - clipFrom;
        await sequence.updateChapter(chapter.sid, chapter);
        setScene(chapter)
        onClose && onClose()
    }

    function onSaveAsNew() {
        const newChapter = new Chapter();
        newChapter.clipFrom = clipFrom;
        newChapter.duration = clipTo - clipFrom;
        sequence.cloneChapter(chapter.sid, newChapter);
        onClose && onClose()
    }

    function thumbUrl(offset) {
        const timemark = Math.floor(offset * 1000);
        return `${config.CONTENT_URL}/fthumb/${sequence.sid}/c/${srcChapter.sid}/${srcChapter.contentVersion}/preview/thumb-${timemark}-h${HEIGHT}.jpg`;
    }

    function videoUrl() {
        return `${config.PLAYBACK_URL}/src/${sequence.sid}/c/${srcChapter.sid}/${srcChapter.contentVersion}/preview/manifest.mpd`;
    }

    function volUrl() {
        const audio = srcChapter.audio;
        if (!audio || audio.status != Content.STATUS.READY) {
            return null;
        }
        // return `${config.CONTENT_URL}/vol/${sequence.sid}/${sequence.version}/c/${srcChapter.sid}/${audio.contentVersion}/volume_map.csv`;
        return `${config.CONTENT_URL}/vol/${sequence.sid}/a/${srcChapter.sid}-${audio.sid}/${audio.contentVersion}/volume_map.csv`;
    }

    /**
     * @param {HTMLVideoElement} videoPlayer
            */
    async function onTimeUpdate(videoPlayer) {
        setCurrentTime(videoPlayer.currentTime)

        if (videoPlayer.currentTime >= clipTo) {
            await videoPlayer.pause()
            videoPlayer.currentTime = clipTo
        }
    }

    /**
     * @param {HTMLVideoElement} videoPlayer
            */
    function onPlaying(videoPlayer) {
        setPlaying(true);
    }

    /**
     * @param {HTMLVideoElement} videoPlayer
            */
    function onPause(videoPlayer) {
        setPlaying(false);
    }

    /**
     * @param {HTMLVideoElement} videoPlayer
            */
    function onProgress(videoPlayer) {
        let bufs = []
        for (var i = 0; i < videoPlayer.buffered.length; i++) {
            // seconds to percent
            bufs.push({
                start: videoPlayer.buffered.start(i),
                end: videoPlayer.buffered.end(i),
            })
        }
        setBuffers(bufs)
    }

    function onSeek(time) {
        if (player.current) {
            player.current.currentTime = clipFrom + time;
        }
    }

    const MARK_THRESHOLD = 0.1;
    const SNAP_THRESHOLD = 250;
    function snapToVolume(time) {
        if (!time || !volumeMap.current) {
            return time;
        }
        const t = time * 1000;
        const nearestVolPoints = volumeMap.current.filter(vol => vol[0] - t < SNAP_THRESHOLD && vol[0] - t > SNAP_THRESHOLD * -1)
        if (!nearestVolPoints.length) {
            return time;
        }
        const sortedVolPoints = nearestVolPoints.sort(function (a, b) { return a[1] - b[1] })
        return Math.abs(sortedVolPoints[0][0] / 1000);
    }

    function snap(time) {
        if (!time || !enableSnap) {
            return time;
        }
        const t = time * 1000;
        const word = words.reduce((prev, curr) => (Math.abs(curr.startTime - t) < Math.abs(prev.startTime - t) ? curr : prev));
        if (word.manualTiming) {
            return snapToVolume(time);
        }
        const snapped = word.startTime / 1000;
        return Math.abs(time - snapped) > 0.8 ? time : snapped;
    }

    function onSnapEnableChange() {
        setEnableSnap(!enableSnap);
    }

    const withUserPs = useUserPs();


    return <Card className={`trim trim-${sid}`}>
        <Card.Body>
            <button type="button" className="btn-close" onClick={onClose} />
            <div className="body">
                <div className="content">
                    <div className="text">
                        {words.map((word, i) => {
                            const className = (
                                word.end - MARK_THRESHOLD < clipFrom
                                    ? 'deleted from-start'
                                    : word.start + MARK_THRESHOLD > clipTo
                                        ? 'deleted from-end'
                                        : ''
                            );

                            return <React.Fragment key={i}>
                                <span className={className}>{word.word}</span>
                                {word.newLine ? <br /> : <span className={className}> </span>}
                                {/* {word.newLine && <br/>} */}
                            </React.Fragment>
                        })}
                    </div>
                </div>
                <div className="player-preview">
                    <DashPlayer
                        className={`player player-${sid}`}
                        src={videoUrl()}
                        loop={false}
                        controls={false}
                        autoPlay={false}
                        innerRef={player}
                        disablePictureInPicture
                        preload="auto"
                        crossOrigin="anonymous"
                        // controlsList="nodownload nofullscreen noremoteplayback"
                        onTimeUpdate={event => onTimeUpdate(event.target)}
                        onPlaying={event => onPlaying(event.target)}
                        onPause={event => onPause(event.target)}
                        onWaiting={event => onPause(event.target)}
                        onSeeking={event => onPause(event.target)}
                        onProgress={event => onProgress(event.target)}
                    />
                    <Controls
                        player={player}
                        playing={playing}
                        buffers={buffers}
                        currentTime={Math.max(clipFrom, currentTime - clipFrom)}
                        duration={clipTo - clipFrom}
                        onSeek={onSeek}
                        clipFrom={clipFrom}
                    />
                    <div className="trimmer" ref={trimmer}>
                        {thumbOffsets.map((offset, i) => <img key={i} className="thumb" height={HEIGHT} src={thumbUrl(offset)} alt="" />)}
                        <Edge
                            trimmer={trimmer}
                            side="left"
                            value={chapter.clipFrom || 0}
                            min={0}
                            max={clipTo}
                            duration={chapter.srcDuration}
                            onChange={setClipFrom}
                            snap={snap}
                        />
                        <Edge
                            trimmer={trimmer}
                            side="right"
                            value={(chapter.clipFrom || 0) + chapter.duration}
                            min={clipFrom}
                            max={chapter.srcDuration}
                            duration={chapter.srcDuration}
                            onChange={setClipTo}
                            snap={snap}
                        />
                    </div>
                </div>
            </div>
            <div className="menu-control">
                <FormCheck
                    disabled={!words.length}
                    type="switch"
                    id="enable-snap"
                    label="Speech based automatic accuracy"
                    title="Trimming will be aligned to the end of a word"
                    checked={enableSnap}
                    onChange={onSnapEnableChange}
                />
            </div>
            <div className="footer">
                <button id="cancel-trim" onClick={onClose} className="btn bg-white text-dark">
                    <SVG name="cancel" />
                    <span>Cancel</span>
                </button>
                <button id="save-trim-as-new" onClick={onSaveAsNew} className="btn bg-rainbow btn-white">
                    <SVG name="save" />
                    <span>Save as New Scene</span>
                </button>
                <button id="save-trim" onClick={onSave} className="btn bg-rainbow btn-white">
                    <SVG name="save" />
                    <span>Save</span>
                </button>
                <div className="scene-name">
                    {chapters.length > 1
                        ? <Dropdown drop="up">
                            <Dropdown.Toggle variant="" id="dropdown-scenes">Scene {chapter.index}</Dropdown.Toggle>
                            <Dropdown.Menu className="menu-scenes" rootCloseEvent="mousedown" renderOnMount={true}>
                                {chapters.map(c => <MenuItem key={c.sid} onClick={() => onTrim(c.sid)}>
                                    <img src={withUserPs(`${config.CHUNKS_URL}/t/${sequence.sid}/${c.sid}/${c.thumbnailVersion}.png`)} />
                                    Scene {c.index}
                                </MenuItem>)}
                            </Dropdown.Menu>
                        </Dropdown>
                        : `Scene ${chapter.index}`
                    }
                </div>
            </div>
        </Card.Body>
    </Card>
}

