import React from 'react';
import { AppContext } from '../lib/app-context';
import { navigate } from 'gatsby';

export default function CreateNewProject() {
  const { user, config } = React.useContext(AppContext);
  const searchParams = new URLSearchParams(typeof window !== 'undefined' && window.location.search);
  const assetSids = React.useMemo(() => searchParams.getAll('assetSid'), [searchParams]);
  const title = React.useMemo(() => searchParams.get('title'), [searchParams]);
  const isFastProjectCreationFlowEnabled = !!user?.UIFLAGS.ENABLE_NEW_FAST_CREATION_FLOW;

  React.useEffect(() => {
    if (config) {
      if (!title) {
        navigate('/');
      }
      let newParams = '';
      if (assetSids.length) {
        assetSids.forEach((sid) => {
          newParams += `assetSid=${sid}&`;
        });
      }
      newParams += `title=${title}`;
      typeof window !== 'undefined' &&
        window.location.assign(
          `${config.NEW_UI_URL}/${
            isFastProjectCreationFlowEnabled ? 'create-project-fast' : 'create-project'
          }?${newParams}`
        );
    }
  }, [config, title, assetSids]);
  return <div />;
}
