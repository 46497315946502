import React from 'react'
import ChapterElement from '../Elements/ChapterElement'
import DraggableScenesList from '../Draggable/DraggableSceneList'

import { tempScenesState } from '../../../state/sequence'

interface SceneEditorProps {
    draggable: boolean;
    toggleDraggable(): void;
    order: React.MutableRefObject<number[]>;
    hideDeletedWords: boolean;
    shouldUpdate: boolean;
    contentWrapperRef: React.RefObject<HTMLDivElement>;
    experimentalKeepBlankWhenCutWord?: boolean;
}

export default function SceneEditor({ 
    draggable, 
    order, 
    hideDeletedWords, 
    shouldUpdate, 
    contentWrapperRef, 
    scrollVersion,
    experimentalKeepBlankWhenCutWord
}: SceneEditorProps) {
    const chapters = tempScenesState.use(scenes => scenes.filter.array)
    if (!draggable) {
        return (<>
            {chapters.map(chapter => <ChapterElement
                key={chapter.sid || chapter.cid}
                className={`chapter-${chapter.sid || chapter.cid}`}
                chapterSid={chapter.sid || chapter.cid}
                chapters={chapters}
                shouldUpdate={shouldUpdate}
                hideDeletedWords={hideDeletedWords}
                contentWrapperRef={contentWrapperRef}
                scrollVersion={scrollVersion} 
                experimentalKeepBlankWhenCutWord={experimentalKeepBlankWhenCutWord}
                />)}
        </>)
    }

    return <DraggableScenesList order={order} />
}