import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';

import styled from 'styled-components';

import SVG from '../../../components/svg';
import { COLORS } from '../../../../constants/colors';

const IconWrapper = styled.div<{ menuIsOpen: boolean }>`
  width: 20px;
  height: 100%;
  cursor: pointer;
  transform: translateY(${({ menuIsOpen }) => (menuIsOpen ? '2px' : '-1px')})
    rotate(${({ menuIsOpen }) => (menuIsOpen ? '180deg' : '0deg')});
  svg {
    path {
      fill: ${({ menuIsOpen }) => (menuIsOpen ? COLORS.peech_primary_2_500 : COLORS.peech_gray_400)} !important;
    }
  }
`;

export default function StyledDropdownIndicator({ selectProps, ...props }: DropdownIndicatorProps) {
  const { menuIsOpen, setMenuIsOpen } = selectProps;

  return (
    <components.DropdownIndicator {...props}>
      <IconWrapper onClick={() => setMenuIsOpen(!menuIsOpen)} menuIsOpen={menuIsOpen}>
        <SVG name="arrow-down" />
      </IconWrapper>
    </components.DropdownIndicator>
  );
}
