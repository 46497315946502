import React from "react";
import ContentEditable from "react-contenteditable";
import { Rnd } from "react-rnd";
import * as Lottie from "../../../../data-layer/shared/lottie";
import { ZINDEX } from "../../../constants/zIndex.const";
import CuePoint from "../../client/cuePoint";
import { FeatureFeatureName } from "../../client/feature";
import GraphicColor from "../../client/graphicColor";
import GraphicCuePoint from "../../client/graphicCuePoint";
import SpeakerCuePoint from "../../client/speakerCuePoint";
import TitleCuePoint from "../../client/titleCuePoint";
import { lottiesAsset, useAnimation } from "../../hooks/useAnimation";
import { AppContext } from "../../lib/app-context";
import {activeEditCuePointState, isFullFrame, lockInteractionState, onCuePointChange} from "../../state/cuePoints";
import { MENUS, menuState } from "../../state/menu";
import { sequenceState } from "../../state/sequence";
import { trackEvent } from "../../utils/analityics.utils";
import { DEFAULT_FONT } from "../../utils/font.utils";
import { getFontToGraphicCuePoint, reverseRTLChar } from "../../utils/lottie-animation.utils";
import LottiePlayer from "../lottie-player";
import { fontVersionState, sequenceFontDirectoryState } from "../player-style";
import TitleCuePointElement from "./title";
import { calculateLogoData } from '../../utils/calculateLogoData.utils';
import useUserPs from "../../hooks/useUserPs";
import useVisualsMenuColorsChange from "../../hooks/useVisualsMenuColorsChange";

const BRIGHTNESS_THRESHOLD = 200;

const IGNORE_FIT_TYPES = [
  CuePoint.TYPE.LOGO,
  CuePoint.TYPE.CLOSED_CAPTION,
  CuePoint.TYPE.MUSIC,
  CuePoint.TYPE.WATERMARK,
  CuePoint.TYPE.BACKGROUND,
  CuePoint.TYPE.FRAME,
  CuePoint.TYPE.CREDIT,
  CuePoint.TYPE.OUTRO,
  CuePoint.TYPE.INTRO,
];

const CUE_POINT_TYPE = TitleCuePoint.TYPE;
const TITLE_TYPE = TitleCuePoint.TITLE_TYPE;

/**
 * @param {GraphicColor[]} animationColors 
 * @param {GraphicColor[]} cuePointColors 
 * @returns {GraphicColor[]}
 */
export function applyAnimationColorsNames(animationColors, cuePointColors) {
  let ret;
  if (!cuePointColors) {
    ret = animationColors;
  }
  else {
    ret = cuePointColors;
    if (animationColors)
      for (let i = 0; i < cuePointColors.length && i < animationColors.length; i++) {
        let cuePointColor = cuePointColors[i];
        let animationColor = animationColors[i];
        cuePointColor.name = animationColor.name;
      }
  }
  return ret.filter(c => c.name);
}

/**
 * @param {*} animation 
 * @returns {GraphicColor[]}
 */
export function getAnimationProps(animation, overrideColors = null) {
  const colorProperties = [];
  const addProperty = (lottieObject) => {
    if (lottieObject.layers) {
      lottieObject.layers.forEach(addProperty);
    }
    if (lottieObject.shapes) {
      lottieObject.shapes.forEach(addProperty);
    }
    if (lottieObject.getClass("editable") !== "true") {
      return;
    }

    let colorProperty = new GraphicColor();
    colorProperty.name = lottieObject.name;

    if (lottieObject.hasClass("dominant")) {
      colorProperty.type = GraphicColor.TYPE.DOMINANCY;
    } else {
      colorProperty.type = GraphicColor.TYPE.BRIGHTNESS;
    }

    colorProperty.className = lottieObject.classNames.find((className) =>
      className.startsWith(Lottie.Layer.ELEMENT_ID_CLASS_PREFIX)
    );
    if (lottieObject.hasClass("text-field")) {
      if (lottieObject.hasClass("text-name")) {
        colorProperty.name = "Name";
      }
      if (lottieObject.hasClass("text-text")) {
        colorProperty.name = "Text";
      }
      if (lottieObject.hasClass("text-title")) {
        colorProperty.name = "Title";
      }
    }
    const colors = overrideColors || sequenceState.get().colors;
    const dominantColor = colors && colors.length && colors[0];
    if (lottieObject.hasClass("bg")) {
      // If (colorProperties.find(p => p.className === 'bg')) {
      // 	Return;
      // }
      colorProperty.colorIndex = dominantColor?.dominancy;
    } else if (lottieObject.hasClass("text")) {
      // If (colorProperties.find(p => p.className === 'text')) {
      // 	Return;
      // }
      colorProperty.color = dominantColor?.brightness > BRIGHTNESS_THRESHOLD ? "#000000" : "#ffffff";
    } else if (lottieObject.hasClass("black")) {
      colorProperty.color = "#000000";
    } else if (lottieObject.hasClass("white")) {
      colorProperty.color = "#ffffff";
    } else {
      let groupId = lottieObject.classNames.find(className => className.startsWith('-'));
      if (groupId) {
        colorProperty.name = null;
        colorProperty.className = 'element-sid' + groupId;
      }
      else {
        const isNumeric = /^\d+$/;
        let colorIndex = lottieObject.classNames.find(className => isNumeric.test(className));
        if (colorIndex !== undefined) {
          colorProperty.colorIndex = parseInt(colorIndex);
        }
      }
    }
    colorProperties.push(colorProperty);
  };
  animation.layers.forEach(addProperty);
  return colorProperties;
}

/**
 * @param {GraphicColor | Color} color
 */
export function color2string(color, inMenu, overrideColors) {
  if (!color) {
    return null;
  }

  const sequence = sequenceState.get();
  const colors = overrideColors || sequence.colors;

  const colorByIndexFuncMap = {
    [GraphicColor.TYPE.DOMINANCY]: (dominancyIndex) =>
      colors.find((c) => c.dominancy === dominancyIndex)?.color,
    [GraphicColor.TYPE.BRIGHTNESS]: (brightnessIndex) =>
      colors.find((c) => c.brightnessIndex === brightnessIndex)?.color,
  };

  if (color.length) {
    return color;
  }
  if (Number.isInteger(color)) {
    return colorByIndexFuncMap[GraphicColor.TYPE.DOMINANCY](parseInt(color));
  }
  if (color.color) {
    return color.color;
  }
  if (!color.colorIndex && !color.color) {
    return inMenu ? "#fff" : "transparent";
  }
  return colorByIndexFuncMap[color.type || GraphicColor.TYPE.DOMINANCY](parseInt(color.colorIndex));
}

export function calcTransform(style) {
  const transform = [];

  let translateX = 0;
  let translateY = 0;
  if (style.alignment === "center") {
    translateX = -50;
  } else if (style.alignment === "middle") {
    translateY = -50;
  } else if (style.alignment === "both") {
    translateX = -50;
    translateY = -50;
  }

  if (translateX || translateY || style.slideY || style.slideX) {
    transform.push(`translate(${translateX + (style.slideX || 0)}%,${translateY + (style.slideY || 0)}%)`);
  }
  // Typewriter
  if (style.scaleX !== undefined || style.scaleY !== undefined) {
    transform.push(`scale(${style.scaleX || 1}, ${style.scaleY || 1})`);
  } else if (style.scale) {
    transform.push(`scale(${style.scale})`);
  }
  if (transform.length) {
    return transform.join(" ");
  }
  return undefined;
}

/**
 * @param {GraphicCuePoint} cuePoint
 */
function calcBaseStyle(cuePoint) {
  let style = {
    alignment: cuePoint.alignment,
    opacity: cuePoint.opacity,
    display: "inline-flex",
  };

  if (
    cuePoint.type !== CuePoint.TYPE.LOGO &&
    cuePoint.type !== CuePoint.TYPE.WATERMARK &&
    cuePoint.type !== CuePoint.TYPE.BACKGROUND &&
    cuePoint.type !== CuePoint.TYPE.FRAME &&
    cuePoint.type !== CuePoint.TYPE.SPEAKER
  ) {
    return style;
  }

  // Dragable cue-points:
  return Object.assign(style, {
    position: "absolute",
    display: "inline-flex",
    // DefaultDisplay: 'inline-flex',
    top: cuePoint.top !== undefined ? `${cuePoint.top}%` : undefined,
    left: cuePoint.left !== undefined ? `${cuePoint.left}%` : undefined,
    right: cuePoint.right !== undefined ? `${cuePoint.right}%` : undefined,
    bottom: cuePoint.bottom !== undefined ? `${cuePoint.bottom}%` : undefined,
    width: cuePoint.width !== undefined ? `${cuePoint.width}%` : undefined,
    height: cuePoint.height !== undefined ? `${cuePoint.height}%` : undefined,
  });
}

/**
 * @param {{
 *  width: number,
 *  height: number,
 *  cuePoint: GraphicCuePoint,
 *  time: number,
 *  duration: number,
 *  style: CSSProperties
 * }} props
 */
function WatermarkCuePointElement(props) {
  const { style, cuePoint } = props;
  const { plan } = React.useContext(AppContext);
    
  if (plan && plan.planAllowedTierFeatures.includes(FeatureFeatureName.WITHOUT_PEECH_WATERMARK)) {
    return null;
  }

  const localStyle = JSON.parse(JSON.stringify(style));
  localStyle.zIndex = ZINDEX.PEECH_WATERMARK;
  if (localStyle.height) {
    delete localStyle.width;
  }

  const src = "/images/watermark.png";
  return (
    <img
      key={cuePoint.sid}
      className="watermark-element"
      key={cuePoint.sid}
      alt="Watermark"
      src={src}
      style={localStyle}
    />
  );
}

/**
 * @param {{
 *  width: number,
 *  height: number,
 *  cuePoint: GraphicCuePoint,
 *  time: number,
 *  duration: number,
 *  style: CSSProperties
 * }} props
 */
function LogoCuePointElement({ style, cuePoint }) {
  // Const localStyle = JSON.parse(JSON.stringify(style))

  // LocalStyle.zIndex = 15
  // LocalStyle.maxHeight = style.height;
  // LocalStyle.maxWidth = style.width;
  // Delete localStyle.width;
  // Delete localStyle.height;

  // Const [sequence] = useAtom(sequenceAtom);
  const { config } = React.useContext(AppContext);
  const sequence = sequenceState.use();
  const thumbnailVersion = sequenceState.use((sequence) => sequence.logo?.thumbnailVersion);
  const withUserPs = useUserPs();
  const getSrc = () =>
    sequence && sequence.logo && withUserPs(`${config.CHUNKS_URL}/t/${sequence.sid}/${sequence.logo.sid}/${thumbnailVersion}.png`);
  const [src, setSrc] = React.useState(getSrc());

  React.useEffect(() => setSrc(getSrc()), [thumbnailVersion]);

  return (
    <img
      key={cuePoint.sid}
      className="logo-element"
      key={cuePoint.sid}
      alt="Logo"
      src={src}
      style={{
        ...style,
        zIndex: 15,
        maxHeight: style.height,
        maxWidth: style.width,
        height: undefined,
        width: undefined,
      }}
    />
  );
}

/**
 * @param {{
 *  width: number,
 *  height: number,
 *  cuePoint: GraphicCuePoint,
 *  time: number,
 *  duration: number,
 * 	inTimeLine: boolean,
 *  style: CSSProperties
 * }} props
 */
function BackgroundCuePointElement(props) {
  const { config } = React.useContext(AppContext);
  const useGreenScreen = sequenceState.use((sequence) => sequence.useGreenScreen);
  const { style, cuePoint, inTimeLine } = props;
  const retries = React.useRef(0);
  const withUserPs = useUserPs();
  
  const {
    sid,
    sequenceSid,
    chapterSid,
    thumbnailVersion,
    fullSize,
    thumbUrl,
    tmpImageUrl,
    url: fullSizeUrl,
  } = cuePoint;
  const calcUrl = () =>
    tmpImageUrl ||
    thumbUrl ||
    (fullSize
      ? fullSizeUrl
      : withUserPs(`${config.CHUNKS_URL}/tc/${sequenceSid}/BackgroundImage/${chapterSid}-${sid}/${inTimeLine ? 200 : 800
      }/${thumbnailVersion}.png`));
  const [url, setUrl] = React.useState(calcUrl);

  React.useEffect(() => setUrl(calcUrl), [thumbnailVersion]);

  let zIndex = 2;
  if (useGreenScreen === undefined || cuePoint.top > 50 || cuePoint.bottom < 50) {
    zIndex = 6;
  }

  function retry() {
    let tmpUrl = url;
    setUrl(null);
    setTimeout(() => {
      if (retries.current < 3) {
        setUrl(tmpUrl);
        retries.current++;
      }
    }, 500);
  }

  if (!thumbnailVersion || !url) {
    return null;
  }

  // MinWidth: cuePoint.width !== undefined ? `${cuePoint.width}%` : undefined,
  // MinHeight: cuePoint.height !== undefined ? `${cuePoint.height}%` : undefined,
  return (
    <img
      key={cuePoint.sid}
      className="background-element"
      alt="Background"
      src={url}
      style={{ ...style, zIndex }}
      onError={retry}
    />
  );
}

/**
 * @param {{
 * 	inTimeLine: boolean,
 *  cuePoint: TitleCuePoint,
 * 	color: string,
 * 	fontSize: number,
 * 	backgroundColor: string,
 *  disabled: boolean,
 *  onFontSizeChange: Function,
 *  className: string,
 *  innerRef: React.RefObject<HTMLElement> | Function,
 *  style: CSSProperties,
 * 	inMenu: boolean,
 *  time: number,
 * 	animate: boolean,
 * 	src: string,
 * 	animation: Lottie.Animation,
 * 	onKeyUp: Function,
 *  onBlur: Function,
 *  onClick: Function,
 * 	textFields: string[]
 * 	logo: string
 *  loop: boolean,
 *  sequenceFontDirectory
 * }} props
 */
export function LottieCuePointElement({
  cuePoint,
  inMenu,
  inTimeLine,
  inAssetsDialog,
  isDraft,
  textFields,
  logo,
  color,
  backgroundColor,
  onKeyUp,
  onBlur,
  onClick,
  time,
  animate,
  src,
  animation,
  demoColors,
  className,
  style,
  fontSize,
  onTime,
  onFit,
  measured,
  innerSize,
  outerSize,
  innerPos,
  outerPos,
  animationSid,
  loop,
  sequenceFontDirectory,
  textDirection,
  newVisualVersion,
  type
}) {
  const getAnimation = () => animation && animation.clone();
  const [cuePointAnimation, setCuePointAnimation] = React.useState(getAnimation);
  const [animationData, setAnimationData] = React.useState();
  const [animationFontSize, setAnimationFontSize] = React.useState(fontSize);
  const [textRatio, setTextRatio] = React.useState({});
  const { config, user } = React.useContext(AppContext);
  //@TODO handle cached version
  const assetSource = React.useMemo(
    () => cuePoint.assetSid && `${config.CONTENT_URL}/c/t/asset/b/${cuePoint.assetSid}/${user?.cacheVersion || 0}.json`,
    [cuePoint.assetSid]
  );
  const editing = React.useRef(false);
  const activeTime = cuePoint?.activeTimes?.find((t) => t.sceneSid === onTime || onTime === true);

  React.useEffect(() => setCuePointAnimation(getAnimation()), [animation, cuePoint.version]);
  React.useEffect(() => {
    if (!src && !assetSource) {
      return;
    }
    let cancel = false;
    fetch(src || assetSource).then(async (response) => {
      let json;
      if (cancel || !response.ok) {
        return;
      }
      try {
        json = await response.json();
      } catch {
        console.error('failed to parse animation asset', { src, assetSource })
      }
      // !cancel && setCuePointAnimation(new Lottie.Animation(json));
    }).catch(err => console.error('failed to load asset', { src, assetSource, err }));

    return () => (cancel = true);
  }, [src, assetSource]);

  const { inOverlay } = React.useContext(AppContext);
  const isFrame = cuePoint.type === CuePoint.TYPE.FRAME;

  const animationPauseTime = React.useMemo(() => {
    if (!cuePointAnimation || !activeTime || (isFrame && inOverlay)) {
      return null;
    }
    const duration = activeTime.end - activeTime.start + 40; // add 1 frame to cuePoint duration 
    const gap = duration - cuePointAnimation.duration * 1000;
    let lastAnimationEnd = 0;

    if (cuePointAnimation.animationTimes?.length) {
      lastAnimationEnd = Math.max(...cuePointAnimation.animationTimes.map((a) => a.end)) * 1000;

      // check if need to calc animation from the end when value is set to - 
      // only for end animation whe can have - time on outro start time also
      const endAnimation = (cuePointAnimation.animationTimes.length > 1  &&  cuePointAnimation.animationTimes[1].start < 0) ? cuePointAnimation.animationTimes[1] : null; // 

      if (endAnimation) {
        return {
          start: lastAnimationEnd,
          end: duration + endAnimation.start * 1000,
          gap,
        };
      }
    }

    return {
      start: lastAnimationEnd,
      end: duration,
      gap,
    };
  }, [cuePointAnimation, cuePoint, activeTime]);

  /**
   * @returns {{play: number, pause: number}}
   */
  const getPlaybackState = () => {
    if (inMenu || inAssetsDialog) {
      return { pause: 2000 }; //(cuePoint.endTime - cuePoint.startTime) / 2 }
    }
    if (!animationPauseTime) {
      if (!isFrame) {
        return { pause: 0 };
      }
      if (cuePointAnimation && isFrame) {
        return { pause: ((time / 1000) % cuePointAnimation.duration) * 1000 };
      }
      return { pause: null }
    }
    const t =
      time <= animationPauseTime.start
        ? time
        : time < animationPauseTime.end
          ? animationPauseTime.start
          : time - animationPauseTime.gap;

    if (animate) {
      if (loop) {
        return { play: t % cuePointAnimation.duration };
      }
      if (t < animationPauseTime.start || (t >= animationPauseTime.end && !inTimeLine)) {
        return { play: t };
      }
    }
    return { pause: t };
  };

  const [playbackState, setPlaybackState] = React.useState(getPlaybackState);

  React.useEffect(() => setPlaybackState(getPlaybackState),[animate, time, animationPauseTime, cuePointAnimation]);

  const getTexts = () =>
  textFields.reduce(
    (obj, textField) => ({
      ...obj,
      [textField]:
        textDirection === "rtl"
          ? reverseRTLChar(cuePoint[textField], textDirection)
          : cuePoint[textField],
    }),
    {}
  );

  const [texts, setTexts] = React.useState(getTexts);
  React.useEffect(() => setTexts(getTexts), [cuePoint.text, cuePoint.version]);

  React.useEffect(() => (editing.current = false), [cuePoint.version]);

  

  React.useEffect(() => {
    if (cuePointAnimation && cuePoint.fontSize && !inMenu && !inAssetsDialog) {
      const animationAspectRatio = cuePointAnimation.height / cuePointAnimation.width;
      const cuePointAspectRatio = cuePoint.height / cuePoint.width;

      setTextRatio({
        text: animationAspectRatio / cuePointAspectRatio,
      });
      // If(cuePoint.name) {
      // 	TextRatio.name = animationAspectRatio / cuePointAspectRatio;
      // 	TextRatio.title = animationAspectRatio / cuePointAspectRatio;
      // }
    }
  }, [cuePointAnimation, cuePoint.width]);

 const customRenderProps = React.useMemo(()=>  { 
  return {
   fWeight: cuePoint?.fontWeight === "Bold" ? "bold" : "normal",
  }
 },
 [cuePoint?.fontWeight]
 )

  React.useEffect(async () => {
    if (!cuePointAnimation || editing.current) {
      return;
    }

    const layers = cuePointAnimation.layers.filter(
      (layer) => layer.type === Lottie.Layer.Type.TEXT && layer.hasClass("text-field")
    );

    console.log('cuePointAnimation.fonts',cuePointAnimation.fonts);
    if(cuePointAnimation.fonts) {
      cuePointAnimation.fonts.forEach(
        (font) => {
          const overrideFont = getFontToGraphicCuePoint(cuePoint, sequenceFontDirectory, font, texts, customRenderProps);
          console.log('overrideFont', overrideFont, {texts});
          font.fFamily = `${overrideFont?.fontFamily || DEFAULT_FONT}, ${DEFAULT_FONT}`;
          font.fWeight = overrideFont?.variant || '400'
        }
      )
    }

    Object.keys(texts).forEach((field) => {
      const layer = layers.find((layer) => layer.hasClass(`text-${field}`));
      if (layer) {
        layer.text = texts[field];
        let textFrames = layer.textFrames;
        if (textFrames && textFrames.length) {
          setAnimationFontSize(textFrames[0].fontSize);
        }
      }
    });

    const lottieAsset = cuePointAnimation.assets.find((a) => a.hasClass("logo"));
    
    if(lottieAsset && logo) {
      const logoData = await calculateLogoData(logo, cuePointAnimation, cuePoint.logoScale);
      lottieAsset.img = logoData.data.src;
      lottieAsset.data.w = logoData.data.w;
      lottieAsset.data.h = logoData.data.h;
    }

    
    setAnimationData(cuePointAnimation.incClientVersion().toJSON());
  }, [logo, texts, cuePointAnimation, editing.current]);

  function onMouseEnter() {
    setPlaybackState({ play: 0 });
  }

  function onAnimationComplete() {
    setPlaybackState(getPlaybackState);
  }

  function onEdit(e) {
    editing.current = true;
    onKeyUp && onKeyUp({ ...e, animation: cuePointAnimation });
  }

  function onEditDone(e) {
    onBlur && onBlur(e);
  }

  const onColorsChange = useVisualsMenuColorsChange();

 
  const extractedColors = (animation && getAnimationProps(animation)) || [];
  

  // if(user && user.UIFLAGS.RANDOMIZE_GRAPHICS_BACKGROUND_COLOR && !cuePoint.colors && cuePoint.backgroundColorIndex) {
  //   const background = extractedColors.find(c => c.name && c.name.toLowerCase() === 'background');
  //   if (background) {
  //     console.log("apply random colors",{cuePoint, background , colorIndex: cuePoint.backgroundColorIndex});
  //     onColorsChange({ kind: 'palette',value: cuePoint.backgroundColorIndex}, background, "", cuePoint, extractedColors, "");
  //   }
  // }
  const cuePointColors = applyAnimationColorsNames(extractedColors, cuePoint.colors)
  const shouldMeasure = !measured && onFit;
  const isGraphicText = cuePoint instanceof TitleCuePoint && !cuePoint?.titleType;

  // function onEnterFrame (data){
  //   var event = new CustomEvent("overlayEnterFrame", data);
  //   // console.log('overlay enter frame', data, playbackState, time, onFit, measured);
  //   if(document) {
  //     document.dispatchEvent(event);
  //   }
  // } 

  if (!animationData) {
    return null;
  }

  return (
    <LottiePlayer
      textRatio={textRatio}
      data-asset-sid={animationSid}
      color={color}
      fontSize={inMenu || inAssetsDialog ? Math.min(fontSize, animationFontSize) : fontSize}
      contentEditable={false}
      style={style}
      backgroundColor={backgroundColor}
      loop={loop}
      autoPlay={false}
      pauseAt={shouldMeasure ? 2000 : playbackState.pause}
      playFrom={shouldMeasure ? 2000 : playbackState.play}
      className={className}
      animationData={animationData}
      demoWidth={inAssetsDialog && (isFullFrame(cuePoint) ? "100%" : animationData?.w / (animationData?.h / 50))}
      inMenu={inMenu}
      inAssetsDialog={inAssetsDialog}
      isDraft={isDraft}
      onKeyUp={onEdit}
      onBlur={onEditDone}
      onClick={() => onClick && onClick(cuePointAnimation)}
      onMouseEnter={(inMenu || inAssetsDialog) && onMouseEnter}
      onAnimationComplete={(inMenu || inAssetsDialog) && onAnimationComplete}
      // onEnterFrame={onEnterFrame}
      onFit={onFit}
      newVisualVersion={newVisualVersion}
      measured={measured}
      size={innerSize}
      version={cuePoint.version}
      innerSize={innerSize}
      outerSize={outerSize}
      innerPos={innerPos}
      outerPos={outerPos}
      customRenderProps={customRenderProps}
      cuePoint={cuePoint}
      subClasses={((inAssetsDialog || inMenu) && demoColors ? demoColors : cuePointColors)?.map((color) => {
        const colorValue = color2string(color);
        const eSid = color.className.split('-')[2];
        const classNameStroke = `color-stroke-dominant--${eSid}`;
        const classNameFill = `color-fill-dominant--${eSid}`;
        if (!colorValue) {
          return null;
        }
        return [{
          className: `${color.className}.color`,
          style: {
            color: colorValue,
          },
        }, {
          className: `${classNameStroke}`,
          style: {
            color: colorValue,
          },
        },
        {
          className: `${classNameFill}`,
          style: {
            color: colorValue,
          },
        }
      ];
      }).filter(i => i).flat()}
    />
  );
}

/**
 * @param {object} props
 * @param {SpeakerCuePoint} props.cuePoint
 */
export function SpeakerElement(props) {
  const {
    style,
    cuePoint,
    onTime,
    cuePointTime,
    animate,
    time,
    inMenu,
    src,
    autoPlay,
    onClick,
    moveable,
    animationSid,
    inAssetsDialog,
    isDraft,
    measured,
    shouldMeasure,
    skipMeasure,
    innerSize,
    outerSize,
    outerPos,
    onFit,
    reFit,
  } = props;
  
  const sequence = sequenceState.use();
  const sequenceFontDirectory = sequenceFontDirectoryState.use();
  const colors = sequenceState.use((sequence) => sequence.colors);
  const useGreenScreen = sequenceState.use((sequence) => sequence.useGreenScreen);
  const speakerAnimationSid =
    inMenu || inAssetsDialog ? animationSid :  cuePoint.assetSid || sequence.style.speakerAssetSid;
  const speakerAnimation = useAnimation(speakerAnimationSid, sequence.aspectRatio);
  const thumbnailVersion = sequenceState.use((sequence) => sequence.logo?.thumbnailVersion);
  const isCuePointActive = activeEditCuePointState.use() === cuePoint.sid
  const { config } = React.useContext(AppContext);
  const initAnimation = React.useRef(0)
  const withUserPs = useUserPs();
  const getLogoSrc = () =>
    sequence && sequence.logo && withUserPs(`${config.CHUNKS_URL}/t/${sequence.sid}/${sequence.logo.sid}/${thumbnailVersion}.png`);
  const [logoSrc, setLogoSrc] = React.useState(getLogoSrc());

 const lockInteraction = lockInteractionState.use();

  function onChange(cuePoint) {
    onCuePointChange(cuePoint);
    reFit && reFit()
  }

  React.useEffect(() => setLogoSrc(getLogoSrc()), [thumbnailVersion]);
  React.useEffect(() => initAnimation.current ? reFit && reFit() : initAnimation.current++, [speakerAnimationSid])
  // This condition controls the speaker's title visibility
  if (!inMenu && !inAssetsDialog && (!cuePoint.name || !sequence.editRules?.useSpeakers || cuePoint.status === CuePoint.STATUS.INACTIVE)) {
    return null;
  }

  function percentToX(outerPos = 0) {
    if (cuePoint.left === undefined) {
      return (props.width * (100 - cuePoint.right - outerPos - cuePoint.width)) / 100;
    }
    const center = cuePoint.alignment === "center" || cuePoint.alignment === "both";
    return (props.width * (cuePoint.left + (outerPos || 0))) / 100 +
      (center ? ((cuePoint.width || 0) * -0.5 * props.width) / 100 : 0)
    // Return props.width * cuePoint.left / 100 + (cuePoint.width || 0) * (cuePoint.translateX || 0) / 100 * props.width / 100
  }

  function percentToY() {
    if (cuePoint.top === undefined) {
      return (props.height * (100 - cuePoint.bottom - cuePoint.height)) / 100;
    }
    const middle = cuePoint.alignment === "middle" || cuePoint.alignment === "both";
    return (props.height * cuePoint.top) / 100 + (middle ? ((cuePoint.height || 0) * -0.5 * props.height) / 100 : 0);
    // Return props.height * cuePoint.top / 100 + (cuePoint.height || 0) * (cuePoint.translateY || 0) / 100 * props.height / 100
  }

  function xToPercent(x) {
    return parseFloat(((x / props.width) * 100).toFixed(2));
  }

  function yToPercent(y) {
    return parseFloat(((y / props.height) * 100).toFixed(2));
  }

  const dragableStyle = { zIndex: 4, ...(outerPos || {}) };
  if (useGreenScreen === undefined || cuePoint.top > 50 || cuePoint.bottom < 50) {
    dragableStyle.zIndex = 10;
  }
  if (shouldMeasure && !measured) {
    dragableStyle.opacity = 0
  }

  const localStyle = {
    width: "100%",
    height: "fit-content",
    display: "inline-block",
  };

  const classNames = ["speaker-element", "sequence-font"];
  const nameStyle = {
    fontSize: (props.height * 4.5) / 100,
  };
  const titleStyle = {
    fontSize: (props.height * 3) / 100,
  };

  if (cuePoint.colors) {
    for (let color of cuePoint.colors) {
      if (color.colorIndex) {
        classNames.push(`color-${color.className}-${color.colorIndex}`);
      }
    }
  } else {
    if (cuePoint.alignment) {
      const { slideY, slideX, scale, scaleY, scaleX } = style;
      localStyle.transform = calcTransform({ slideY, slideX, scale, scaleY, scaleX });
    }

    if (cuePoint.nameColor) {
      nameStyle.color = cuePoint.nameColor;
    } else if (cuePoint.nameColorIndex !== undefined) {
      var color = colors.find((c) => c.brightnessIndex === cuePoint.nameColorIndex);
      nameStyle.color = color?.color;
    }
    if (cuePoint.nameBackgroundColor) {
      nameStyle.backgroundColor = cuePoint.nameBackgroundColor;
    } else if (cuePoint.nameBackgroundColorIndex !== undefined) {
      var color = colors.find((c) => c.brightnessIndex === cuePoint.nameBackgroundColorIndex);
      nameStyle.backgroundColor = color?.color;
    } else {
      nameStyle.backgroundColor = "white";
    }
    if (!nameStyle.color) {
      nameStyle.color = "black";
    }
    if (!nameStyle.backgroundColor) {
      nameStyle.backgroundColor = "white";
    }

    if (cuePoint.titleColor) {
      titleStyle.color = cuePoint.titleColor;
    } else if (cuePoint.titleColorIndex) {
      var color = colors.find((c) => c.brightnessIndex === cuePoint.titleColorIndex);
      titleStyle.color = color?.color;
    }
    if (cuePoint.titleBackgroundColor) {
      titleStyle.backgroundColor = cuePoint.titleBackgroundColor;
    } else if (cuePoint.titleBackgroundColorIndex) {
      var color = colors.find((c) => c.brightnessIndex === cuePoint.titleBackgroundColorIndex);
      titleStyle.backgroundColor = color?.color;
    }
    if (!titleStyle.color) {
      titleStyle.color = "white";
    }
    if (!titleStyle.backgroundColor) {
      titleStyle.backgroundColor = "black";
    }
  }

  function onKeyPress(e) {
    if (e.which === 13) {
      e.preventDefault();
      e.target.blur();
    }
  }

  async function onNameBlur({ target }) {
    let text = target.innerText.trim();
    if (text.length && text !== cuePoint.name) {
      cuePoint.name = text;
      onCuePointChange(cuePoint);
    } else {
      target.innerText = cuePoint.name;
    }
  }

  async function onTitleBlur({ target }) {
    let text = target.innerText.trim();
    if (text.length && text !== cuePoint.title) {
      cuePoint.title = text;
      onCuePointChange(cuePoint);
    } else {
      target.innerText = cuePoint.title;
    }
  }

  const size = {
    height: style?.height,
    width: outerSize?.width || style?.width,
  };

  const className = classNames.join(" ");
  const shouldResize = !inMenu && !inAssetsDialog;
  const texts = cuePoint.name + cuePoint.title;

  const speakerElement =
    speakerAnimation || src ? (
      <LottieCuePointElement
        animationSid={speakerAnimationSid}
        src={src}
        inMenu={inMenu}
        inAssetsDialog={inAssetsDialog}
        isDraft={isDraft}
        type="speaker"
        time={time}
        animate={animate}
        className={className}
        animation={speakerAnimation}
        cuePoint={cuePoint}
        textFields={["name", "title"]}
        texts={texts}
        logo={logoSrc}
        editable={true}
        autoPlay={autoPlay}
        loop={inAssetsDialog || inMenu}
        measured={measured}
        style={{ width: "auto", height: `${cuePoint.height}%` }}
        onFit={onFit}
        newVisualVersion={!!(sequence.visualPackageVersion && sequence.visualPackageVersion > 1)}
        onClick={onClick}
        onTime={onTime}
        innerSize={innerSize}
        outerSize={outerSize}
        outerPos={outerPos}
        sequenceFontDirectory={sequenceFontDirectory}
        textDirection={sequence.textDirectionByLanguage}
        {...props}
      />
    ) : (
      <div className={className} onClick={() => menuState.set(MENUS.SPEAKERS)} style={{ ...style, ...localStyle }}>
        <ContentEditable
          html={cuePoint.name || ""}
          style={nameStyle}
          className="name"
          onKeyPress={onKeyPress}
          onBlur={onNameBlur}
        />
        {cuePoint.title && (
          <ContentEditable
            html={cuePoint.title || ""}
            style={titleStyle}
            className="title"
            onKeyPress={onKeyPress}
            onBlur={onTitleBlur}
          />
        )}
      </div>
    );

  if (moveable === false) {
    return speakerElement;
  }

  return (
    <Rnd
      onMouseDown={()=> {
          activeEditCuePointState.set(cuePoint.sid)
          menuState.set(MENUS.SPEAKERS);
        }
      }      data-sid={cuePoint.sid}
      data-asset-sid={sequence.style.speakerAssetSid || cuePoint.assetSid}
      className={`
          moveable-element
          ${speakerAnimation ? "" : "speaker-element-wrapper"} 
          ${onTime && "dragable-element"} 
          ${isCuePointActive ? 'cuePointActive' : ''}
        `}
      style={{ ...dragableStyle, visibility: !onTime && cuePointTime === null ? "hidden" : "visible" }}
      bounds="parent"
      enableResizing={!lockInteraction && !isDraft}
      disableDragging={lockInteraction || isDraft}
      lockAspectRatio={true}
      // DragHandleClassName={}
      resizeHandleClasses={{
        bottom: "rnd-handle bottom-handle",
        bottomLeft: "rnd-handle bottom-left-handle",
        bottomRight: "rnd-handle bottom-right-handle",
        left: "rnd-handle left-handle",
        right: "rnd-handle right-handle",
        top: "rnd-handle top-handle",
        topLeft: "rnd-handle top-left-handle",
        topRight: "rnd-handle top-right-handle",
      }}
      position={{
        x: ((sequence.visualPackageVersion && sequence.visualPackageVersion > 1) || skipMeasure) ? percentToX() : percentToX(outerPos), // new way : support legacy
        y: percentToY(),
      }}
      size={size}
      onDragStop={(e, { x, y }) => {
        //Dont drag outside the stage + sometime we get minus values - still not sure why
        if (x > props.x || y > props.y || x < 0 || y < 0) {
          return;
        }
        if (sequence.visualPackageVersion &&  sequence.visualPackageVersion > 1) {
          // new way
            cuePoint.left = xToPercent(x);
        } else {
          // legacy
          cuePoint.left = xToPercent(x) - (outerPos || 0);
        }
        cuePoint.top = yToPercent(y);
        const hasChanges = cuePoint.changedProperties.some(i => i === 'left' || i === 'top');
        if (hasChanges) {
          onChange(cuePoint);
          trackEvent('speaker-edit', {action: 'position' });
        }
        
      }}
      onResizeStop={(e, direction, ref, delta, { x, y }) => {
        const heightRatio = yToPercent(ref.clientHeight) / cuePoint.height
        cuePoint.width = xToPercent(ref.clientWidth);
        cuePoint.height = yToPercent(ref.clientHeight);
        if (sequence.visualPackageVersion &&  sequence.visualPackageVersion > 1) {
          // new way
          cuePoint.left = xToPercent(x);
        } else {
          // legacy
          cuePoint.left = xToPercent(x) - (outerPos || 0) * heightRatio;
        }
        cuePoint.top = yToPercent(y);
        const hasChanges = cuePoint.changedProperties.some(i => i === 'height' || i === 'width' || i === 'left' || i === 'top');
        if (hasChanges) {
          onChange(cuePoint);
          trackEvent('speaker-edit', { action: 'size' });
        }
      }}
    >
      {speakerElement}
    </Rnd>
  );
}

/**
 * @param {{
 *  width: number,
 *  height: number,
 *  cuePoint: GraphicCuePoint,
 *  time: number,
 *  onTime: boolean,
 *  duration: number,
 * }} props
 */
export default function GraphicCuePointElement(props) {
  const { time, onTime, duration, cuePoint, cuePointTime, isDraft } = props;
  if (cuePoint.animations) {
    for (let animation of cuePoint.animations) {
      if (animation.type === "fade-in") {
        animation.values = {
          ...animation.values,
          type: "fade",
          startValue: 0,
          endValue: cuePoint.opacity,
        };
      }
      if (animation.type === "fade-out") {
        animation.values = {
          ...animation.values,
          type: "fade",
          startValue: cuePoint.opacity,
          endValue: 0,
        };
      }
    }
  }

  const { inOverlay } = React.useContext(AppContext);

  const [measurement, setMeasurement] = React.useState({});
  const [measured, setMeasured] = React.useState(false);
  const { innerSize, outerSize, outerPos, innerPos } = measurement;

  function resetSize(pos) {
    setMeasurement(m => (pos ? { ...m, innerSize: undefined, outerSize: undefined } : {}));
    setMeasured(false)
    setStyle(calcBaseStyle(cuePoint))
  }

  const [style, setStyle] = React.useState(calcBaseStyle(cuePoint));
  const fontVersion = fontVersionState.use()

  React.useEffect(() => {
    resetSize();
  }, [cuePoint.version, cuePoint.clientVersion, fontVersion]);

  React.useEffect(() => {
    if (time === null) {
      // If(style.display !== 'none') {
      // 	SetStyle(s => ({...s, display: 'none'}));
      // }
      return;
    }

    setStyle((s) => {
      const animations = {};
      if (cuePoint.animations) {
        for (let animation of cuePoint.animations) {
          var start = animation.start >= 0 ? animation.start : duration + animation.start;
          if (start <= time && time <= start + animation.duration) {
            let progress = (time - start) / animation.duration;
            // TODO velocity function

            switch (animation.type) {
              case "fade":
                var start =
                  animation.startValue !== undefined
                    ? parseFloat(animation.startValue) * cuePoint.opacity
                    : cuePoint.opacity;
                var end =
                  animation.endValue !== undefined
                    ? parseFloat(animation.endValue) * cuePoint.opacity
                    : cuePoint.opacity;
                animations.opacity = start + (end - start) * progress;
                break;

              case "scale":
                if (animation.startX || animation.endX) {
                  var start = animation.startX !== undefined ? animation.startX : 0;
                  var end = animation.endX !== undefined ? animation.endX : 1;
                  animations.scaleX = start + (end - start) * progress;
                }
                if (animation.startY || animation.endY) {
                  var start = animation.startY !== undefined ? animation.startY : 0;
                  var end = animation.endY !== undefined ? animation.endY : 1;
                  animations.scaleY = start + (end - start) * progress;
                }
                if (animation.endX === undefined && animation.endY === undefined) {
                  var start = animation.startValue !== undefined ? parseFloat(animation.startValue) : 1;
                  var end = animation.endValue !== undefined ? parseFloat(animation.endValue) : 1;
                  animations.scale = start + (end - start) * progress;
                }
                break;

              case "slide":
                var start = animation.startValue !== undefined ? parseFloat(animation.startValue) : -250;
                var end = animation.endValue !== undefined ? parseFloat(animation.endValue) : 0;
                if (animation.kind === "up" || animation.kind === "down") {
                  animations.slideY = start + (end - start) * progress;
                }
                break;

              case "background-color":
                var startX = animation.startX !== undefined ? animation.startX : 0;
                var startY = animation.startY !== undefined ? animation.startY : 0;
                var endX = animation.endX !== undefined ? animation.endX : 100;
                var endY = animation.endY !== undefined ? animation.endY : 100;
                var x = startX + (endX - startX) * progress;
                var y = startY + (endY - startY) * progress;
                if (animation.kind === "radial") {
                  animations.background = `radial-gradient(at ${x}% ${y}%, ${animation.startValue} 0%, ${animation.endValue} 100%)`;
                }
                break;

              case "typewriter":
                break;

              default:
                console.log("Unknown Animation", animation);
            }
          }
        }

        animations.transform = calcTransform({ ...s, ...animations });
      }

      return {
        ...s,
        opacity: cuePoint.opacity,
        ...animations,
        // Display: s.defaultDisplay
      };
    });
  }, [time, onTime]);

  function setAnimationSize(inn, out, pos, shouldReset) {
    console.log(`on Fit ${cuePoint?.sid}`, { inn, out, pos, shouldReset })

    if (shouldReset) {
      resetSize(pos);
      return setStyle(calcBaseStyle(cuePoint));
    }
    setMeasurement((state) => ({
      innerSize: inn,
      outerSize: out,
      outerPos: pos?.outerPos || state.outerPos,
      innerPos: pos?.innerPos || state.innerPos
    }));
    setMeasured(true)
  }

  const sequence = sequenceState.use();

  const skipMeasure = inOverlay && (sequence.visualPackageVersion === undefined || sequence.visualPackageVersion < 2);
  const shouldMeasure = !skipMeasure && !IGNORE_FIT_TYPES.includes(cuePoint.type) && (!cuePoint.titleType || cuePoint.titleType === TitleCuePoint.TITLE_TYPE.TEXT );

  const fitProperties = {
    onFit: shouldMeasure && setAnimationSize,
    measured,
    shouldMeasure
  };
  const properties = { ...props, duration, style, onTime, cuePointTime, isDraft };

  if (
    (!cuePoint ||
      !cuePoint.activeTimes.length ||
      cuePoint.status === CuePoint.STATUS.DELETED ||
      (!inOverlay && cuePoint.status === CuePoint.STATUS.INACTIVE) ||
      (!onTime && cuePointTime === null)) &&
    (measured || !shouldMeasure)
  ) {
    return null;
  }

  const CuePointElement = (isMeasure) => {
    const props = isMeasure ?
      { ...properties, ...(shouldMeasure ? fitProperties : {}) } :
      {
        ...properties,
        innerSize,
        outerSize,
        outerPos,
        innerPos,
        reFit: resetSize
      }

    switch (cuePoint.type) {
      // Case CuePoint.TYPE.LOGO:
      // 	Return <LogoCuePointElement {...properties} />

      case CuePoint.TYPE.WATERMARK:
        return <WatermarkCuePointElement {...props} />;

      case CuePoint.TYPE.BACKGROUND:
        return <BackgroundCuePointElement {...props} />;

      case CuePoint.TYPE.SPEAKER:
        return <SpeakerElement {...props} skipMeasure={skipMeasure} />;

      default:
        return <TitleCuePointElement {...props} />;
    }
  }

  return <>
    {(measured || !shouldMeasure) && CuePointElement()}
    {!measured && shouldMeasure && CuePointElement(true)}
  </>
}
