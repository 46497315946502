
import * as Base from './base/subscription';
import { SubscriptionStatus, SubscriptionChargeType } from './base/subscription';

export { SubscriptionStatus, SubscriptionChargeType } from './base/subscription';

export interface ISubscription extends Base.ISubscription {

}

export interface ISubscriptionProperties extends Base.ISubscriptionProperties {
    footageUploadsCount?: boolean;
}

export interface ISubscriptionFilter extends Base.ISubscriptionFilter {

}

export default class Subscription<IType extends ISubscription = ISubscription, ITypeProperties extends ISubscriptionProperties = ISubscriptionProperties> extends Base.Subscription<IType, ITypeProperties> implements ISubscription {
   
}