import React from 'react';
import styled from 'styled-components';
import { LottieLoader } from '../../components/common/lottie-loader/lottie-loader.component';
import downloadVideoLoader from '../../components/common/lottie-loader/download-video.json';
import { AppContext } from '../../lib/app-context';

const Title = styled.div`
  margin: 16px 0px 16px 0px;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  text-wrap: balance;
  display: flex;
  align-items: center;

  @media (max-width: 375px) {
    font-size: 16px;
    line-height: 18px;
    margin-block: 8px;
  }
`;

const Paragraph = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  text-wrap: balance;
`;

export const DownloadGenerating = () => {
  const { isMobileDevice } = React.useContext(AppContext);
  return (
    <>
      <LottieLoader animationData={downloadVideoLoader} loop={true} width={isMobileDevice ? '90px' : '130px'} />
      <Title>
        Your Video Is On It’s Way, 
        feel free to keep working.
      </Title>
      <Paragraph>You'll also receive an email with the download link once it's ready.</Paragraph>
    </>
  );
};
