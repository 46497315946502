import classNames from 'classnames';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CuePoint from '../../client/cuePoint';
import SpeakerCuePoint from '../../client/speakerCuePoint';
import SVG from '../../components/svg';
import { AppContext } from '../../lib/app-context';
import { sequenceState } from '../../state/sequence';
import { reloadSpeakers, speakersLoadedState } from '../../state/speakers';
import Button, { ButtonSize, ButtonVariant } from '../../ui-components/Button';
import Carousel from '../../ui-components/Carousel/Carousel';
import ImageWithPlaceholder from '../../ui-components/ImageWithPlaceholder/ImageWithPlaceholder';
import './SpeakersModal.scss';
import useUserPs from '../../hooks/useUserPs';





interface SpeakerShowcaseProps {
    speaker: SpeakerCuePoint;
    cuePoint: CuePoint;
    
}

function SpeakerShowcase({ cuePoint }: SpeakerShowcaseProps) {
    const sequence = sequenceState.use()
    const { config } = React.useContext(AppContext)
    const withUserPs = useUserPs();
    const src = withUserPs(`${config.CHUNKS_URL}/c/${sequence.sid}/${cuePoint.sid}/${cuePoint.thumbnailVersion}.png`)

    function onKeyPress(e) {
        if (e.which === 13) {
            e.preventDefault()
            return e.target.blur()
        }
    }

    async function onNameChange(e) {
        cuePoint.name = e.target.innerText?.trim();
    }

    async function onTitleChange(e) {
        cuePoint.title = e.target.innerText?.trim();
    }
    
    return <div className="speaker-visual sequence-font speaker-item">
        <div className='face-wrapper'>
            <ImageWithPlaceholder alt={cuePoint.name} className="face" source={src} />
        </div>
        <div className='speaker-item--details'>
            <div className="text">
                <div
                    dangerouslySetInnerHTML={{ __html: cuePoint.name || '' }}
                    contentEditable={true}
                    className="field rbt-input-main"
                    placeholder="Add name here"
                    onKeyPress={onKeyPress}
                    onBlur={onNameChange}
                    onInput={onNameChange}
                />
                <div
                    dangerouslySetInnerHTML={{ __html: cuePoint.title || '' }}
                    contentEditable={true}
                    className="field rbt-input-main"
                    placeholder="Add title here"
                    onKeyPress={onKeyPress}
                    onBlur={onTitleChange}
                    onInput={onTitleChange}
                />
            </div>
        </div>
    </div>
}

interface SpeakersModalProps {
    onDone(): void;
    speakerCuePoints: SpeakerCuePoint[];
    shouldSaveSpeakers: boolean;
    onChange(): void;
}


export default function SpeakersModal({ onDone, speakerCuePoints, shouldSaveSpeakers, onChange }: SpeakersModalProps) {
    const [speakerIndex, setSpeakerIndex] = React.useState(0)
    const { user } = React.useContext(AppContext);
    const speakersLoaded = speakersLoadedState.use();
    const isDisabled: boolean = !speakerCuePoints.find(speakerCP => speakerCP.speakerSid)

    // LOAD SPEAKERS
    React.useEffect(async () => {
        if (speakersLoaded) {
            return;
        }
        reloadSpeakers()
    }, [speakersLoaded, user?.uiFlags])

    const [refreshCheckbox, setRefreshCheckbox] = React.useState(false)

    function onSpeakerCheck(e) {
        e.stopPropagation();
        e.preventDefault();
        if (isDisabled(speakerIndex)) {
            return
        }
        setRefreshCheckbox(!refreshCheckbox)
        speakerCuePoints[speakerIndex].shouldSaveSpeaker = !speakerCuePoints[speakerIndex].shouldSaveSpeaker
    }

    function onActiveSpeakerChange(index, nextIndex) {
        setRefreshCheckbox(!refreshCheckbox)
        setSpeakerIndex(index)
    }


    return (
        <Modal show={true}
            data-cy='create-project-step'
            className="detected-speakers-modal"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            size='lg'>
            <Modal.Header>
                <h3>We have detected speakers in your video</h3>
            </Modal.Header>
            <Modal.Body >

                <Carousel
                    shouldNavigateBackwords={false}
                    height={145}
                    Component={SpeakerShowcase}
                    items={speakerCuePoints}
                    onIndexChange={(i, n) => onActiveSpeakerChange(i, n)}
                />

            </Modal.Body>
            <Modal.Footer>
                <div className='modal-footer-actions'>
                    <a className={classNames("modal-footer-action-wrapper", { "disabled": isDisabled })}
                        onClick={(e) => {
                            e.preventDefault();
                            onChange();
                        }}>
                        <div className={classNames("peech-pink-checkbox", { "selected": shouldSaveSpeakers, "disabled": isDisabled })}>
                            <label className="asset--checkbox" >
                                <input type="checkbox" checked={shouldSaveSpeakers} disabled={isDisabled} />
                                <span className="checkmark">
                                    <SVG name="check" viewBox="0 0 36 36" />
                                </span>
                            </label>
                        </div>
                        Save these speakers for future videos
                    </a>
                </div>
                <Button data-cy='done-button' variant={ButtonVariant.Tertiary} size={ButtonSize.Medium}
                    onClick={onDone} >Done</Button>
            </Modal.Footer>
        </Modal >
    )
}

