import React from 'react'

import './ProgressBar.scss'

interface ProgressBarProps {
    type?: 'linear' | 'circular';
    progress?: number;
}

export default function ProgressBar({ type, progress, ...props }: ProgressBarProps & {}) {

    const typeClass = `peech-progress--${type}`

    const circle = React.useRef<SVGCircleElement>();
    const [dashOffset, setDashOffset] = React.useState(undefined);
    const [dashArray, setDashArray] = React.useState(undefined);

    React.useEffect(() => {
        if (circle.current) {
            const radius = circle.current.r.baseVal.value
            const circumference = radius * 2 * Math.PI
            !dashArray && setDashArray(`${circumference} ${circumference}`)
            const dashOffset = circumference - progress / 100 * circumference
            setDashOffset(dashOffset)
        }
    }, [circle, progress])


    if (type === "linear") {
        return (
            <div className={['peech-progress', typeClass].join(' ')} {...props}>
                <div
                    className="full-linear"
                >
                    <div className={`progress-linear ${progress === 100 ? 'done' : ''}`} style={{ width: progress + '%' }} />
                </div>
                <span className="progress-text">
                    {progress}%
                </span>
            </div>
        )
    }

    return (
        <svg
            className={['peech-progress', typeClass].join(' ')}
            width="120"
            height="120"
            {...props}>
            <circle
                className="full-circle"
                r="52"
                cx="60"
                cy="60" />
            <circle
                className={`progress-circle ${isNaN(dashOffset) ? '' : progress === 100 ? 'done' : 'in-progress'}`}
                ref={circle}
                strokeDashoffset={dashOffset || '326 326'}
                strokeDasharray={dashArray || '326'}
                r="52"
                cx="60"
                cy="60" />
            <text
                className="progress-text"
                x="50%"
                y="50%"
                dy=".3em">
                {progress}%
            </text>
        </svg>
    )
}

ProgressBar.defaultProps = {
    type: 'linear',
    progress: 50,
};