import React from 'react'
import SVG from '../../../components/svg'

interface DraggableDetailsProps {
    thumbnail: string;
}

export default function DraggableDetails({ thumbnail }: DraggableDetailsProps) {

    return (<>
        <div className="draggable-thumbnail">
            <img src={thumbnail} />
        </div>
        <div className="draggable-icon">
            <SVG name="drag" />
        </div>
    </>)
}