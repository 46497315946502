
import * as Base from '../base/asset/contentIdentifier';

export interface IContentIdentifierProperties extends Base.IContentIdentifierProperties {

}

export interface IContentIdentifier extends Base.IContentIdentifier {

}

export interface IContentIdentifierFilter extends Base.IContentIdentifierFilter {

}

export default class ContentIdentifier<IType extends IContentIdentifier = IContentIdentifier, ITypeProperties extends IContentIdentifierProperties = IContentIdentifierProperties> extends Base.ContentIdentifier<IType, ITypeProperties> implements IContentIdentifier {
}