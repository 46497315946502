import React from 'react';
import styled from '@emotion/styled/macro';

import './Subtitles.scss';
import { Color } from '../../../client/sequence';
import { COLORS } from '../../../../constants/colors';

const ColorOption = styled.div<{ isSelected: boolean; color: string; none?: boolean }>`
  width: 15px;
  height: 15px;
  border: 1px solid #000000;
  scale: ${(props) => (props.isSelected ? 1.25 : 1)};
  border-radius: 4px;
  background-color: ${(props) => props.color};
  transition: scale 250ms ease-out;
  cursor: pointer;
    &:hover {
      scale: 1.20;
    }
  &::after {
    content: '';
    position: absolute;
    background-color: ${(props): string => props.none? COLORS.peech_alert_error : 'none'};
    top: -2px;
    bottom: -2px;
    right: 6px;
    width: 1px;
    transform: rotate(45deg);
  }
`;

const STATIC_COLORS = ['#ffffff', '#000000'];

export type SequenceColorPickerValue =
  | {
      kind: 'static';
      value: string;
    }
  | {
      kind: 'palette';
      value: number;
    };

interface Props {
  palette: Color[];
  value?: string | number | null;
  onChange(value: SequenceColorPickerValue | null): void;
  withNoneColor?: boolean;
  withStatic?: boolean;
}

export const SequenceColorPicker = ({ palette, value, onChange, withStatic = true ,withNoneColor = false }: Props) => {
  if(!withStatic) console.log('SequenceColorPicker', {value});

  return (
    <>
      {withStatic && STATIC_COLORS.map((color) => (
        <ColorOption
          key={color}
          color={color}
          isSelected={typeof value === 'string' && value.toLowerCase() === color}
          onClick={() => onChange({ kind: 'static', value: color })}
        />
      ))}

      {palette.map((color) => (
        <ColorOption
          key={color.sid}
          color={color.color!}
          isSelected={color.dominancy === value}
          onClick={() => onChange({ kind: 'palette', value: color.dominancy! })}
        />
      ))}

      {withNoneColor && <ColorOption
        color={STATIC_COLORS[0]}
        isSelected={value === null}
        onClick={() => onChange(null)}
        none={true}
      />}
    </>
  );
};
