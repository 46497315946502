import React, {useRef, useState} from 'react'
import SVG from './svg'
import styled from "styled-components/macro";
import {IconWrapper} from "../ui-components/Menu/MenuComponents.styled";
import {COLORS} from "../../constants/colors";

const Popup = styled.div<{position: { top: number, left: number }}>`
    position: fixed;
    z-index: 999;
    top: ${({ position }) => `${position?.top}px`};
    left: ${({ position }) => `${position?.left}px`};
    background-color: white;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 200px;
    font-size: 10px;
    font-weight: normal;
`;

export const IconContainer = styled(IconWrapper)<{active: boolean}>`
    margin-left: 5px;
    cursor: default;
    & svg path {
            transition: fill 250ms ease-out;
            fill: ${(props) => props.active ? COLORS.peech_primary_2_500 : COLORS.peech_gray_500};
        }
    &:hover {
        & svg path {
            fill: #1F9FFB;
        }
    }
`;

const InfoList = styled.ul`
    margin-bottom: 0px;
    padding-left: 12px;
`

const InfoItem = styled.li`
    margin-bottom: 4px;
`

const Info = styled.p`
    margin-bottom: 4px;
`

interface InfoPopupProps {
  text: string | string[] | null;
}

export const InfoPopup = ({text}: InfoPopupProps) => {
  if (!text || !text.length || text === "") return null;
  const [showPopup, setShowPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const popupRef = useRef(null);

  const displayPopup = (event: any) => {
    const buttonRect = event.target.getBoundingClientRect();
    setPopupPosition({
      top: buttonRect.top + buttonRect.height + 4,
      left: buttonRect.left,
    });
    setShowPopup(true);
  };
  
  return (
    <>
      <IconContainer active={showPopup} onMouseEnter={(e) => displayPopup(e)} onMouseLeave={() => setShowPopup(false)}>
          <SVG name="info-tooltip" viewBox="0 0 14 14" />
      </IconContainer>
      {showPopup && <Popup ref={popupRef} position={popupPosition}>
        {Array.isArray(text) ?         
          <InfoList>
            {text.map((item, index) => (
              <InfoItem key={index}>{item}</InfoItem>
            ))}
          </InfoList> 
          : 
          <Info>{text}</Info>
        }
      </Popup>}
    </>
  )
}
