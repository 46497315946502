import React, { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import styled from '@emotion/styled/macro';
import { COLORS } from '../../../constants/colors';
import SVG from '../svg';
import Sequence, { Chapter } from '../../client/sequence';
import Button, { ButtonSize, ButtonVariant } from '../../ui-components/Button';
import CustomLanguageSelector, {
  LangSelectorColors,
} from '../../ui-components/CustomLanguageSelector/CustomLanguageSelector';
import { AppContext } from '../../lib/app-context';
import { DEFAULT_LANGUAGE_CODE, onCloneSequence } from '../../ui-components/SequenceCloneOptions/onSequenceClone';
import { trackEvent } from '../../utils/analityics.utils';
import { AspectRatio } from '../../ui-components/SequenceCloneOptions/useSequenceCloneOptions';
import { navigate } from 'gatsby';
import {useCreateTranslatedSequence} from '../../hooks/useCreateTranslatedSequence';

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 580px;
  }

  .modal-content {
    display: flex;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
`;

const ModalBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 12%) 8px 4px 8px 0;
  width: 466px;
  height: 281px;
  background-color: ${COLORS.peech_neutral_white};
  border-radius: 10px;
  padding: 16px 30px;
`;

const CloseDiv = styled.div`
  position: absolute;
  top: 10px;
  right: 14px;
  width: 14px;
  cursor: pointer;
  svg {
    fill: ${COLORS.peech_gray_500};
  }
`;

const TitleDiv = styled.div`
  width: 100%;
  margin: 18px 0 23px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  svg {
    width: 30px;
  }
`;

const Title = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  margin-left: 14px;
`;

const InfoDiv = styled.div`
  width: 100%;
  margin-bottom: 23px;
  background-color: ${COLORS.peech_gray_100};
  border-radius: 5px;
  padding: 10px 43px 10px 50px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  position: relative;
`;

const InfoIconDiv = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  svg {
    width: 30px;
    fill: ${COLORS.peech_gray_700};
  }
`;

const LangDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SelectionDiv = styled.div`
  width: 270px;
  height: 30px;
`;

export default function TranslateSequenceModal({
  isOpen,
  onClose,
  sequence,
  trigger,
}: {
  isOpen: boolean;
  onClose(): void;
  sequence: Sequence;
  trigger: string;
}) {
  const { config, user, plan } = React.useContext(AppContext);

  const supportedLanguages = React.useMemo(() => {
    if (!config || !sequence) {
      return;
    }
    const sequenceLangCode = sequence?.languageCode || DEFAULT_LANGUAGE_CODE;
    return config?.TRANSLATE_LANGUAGES?.filter((x) => x.languageCode !== sequenceLangCode);
  }, [config, sequence]);

  const [tempLang, setTempLang] = React.useState(null);
  const onTranslateClick = useCreateTranslatedSequence(trigger, sequence, tempLang, setTempLang, onClose);
  return (
    <StyledModal show={isOpen} onHide={onClose} centered>
      <ModalBody>
        <CloseDiv onClick={onClose}>
          <SVG name="close" viewBox="0 0 22 22" />
        </CloseDiv>
        <TitleDiv>
          <SVG name="translate-color" viewBox="0 0 31 24" />
          <Title>Translate your project to a different language</Title>
        </TitleDiv>
        <InfoDiv>
          <InfoIconDiv>
            <SVG name="information" viewBox="0 0 24 24" />
          </InfoIconDiv>
          What gets translated: <br/>
          Subtitles, Graphic text, Text slides.
          <br />
          <br />A new version with your language will be added to your Projects library as copy.
        </InfoDiv>
        <LangDiv>
          <SelectionDiv>
            <CustomLanguageSelector
              sid={sequence?.sid}
              lang={sequence?.languageCode}
              disabled={false}
              supportedLanguages={supportedLanguages}
              setTempLang={setTempLang}
              placeholder="Choose language"
              selectorColor={LangSelectorColors.GRAY}
              isSearchable={true}
              showFlag={true}
            />
          </SelectionDiv>
          <Button
            variant={ButtonVariant.Primary}
            size={ButtonSize.Medium}
            label="Translate"
            onClick={onTranslateClick}
            disabled={!tempLang}
          />
        </LangDiv>
      </ModalBody>
    </StyledModal>
  );
}
