import React from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import Dropzone from 'react-dropzone';
import Asset from '../../client/asset';
import Preset from '../../client/preset';
import { numbersToColor } from '../../client/sequence/logo';
import Style from '../../client/sequence/style';
import LogoDropArea from '../../components/logo-drop-area';
import StaticDoc from '../../components/StaticDoc/StaticDoc';
import SVG from '../../components/svg';
import { AppContext } from '../../lib/app-context';
import FileUploader from '../../lib/fileUploader';
import gfonts from '../../lib/gfonts.json';
import { handleError } from '../../state/error';
import { sequenceSave, sequenceState } from '../../state/sequence';
import Button from '../../ui-components/Button';
import ColorPalette from '../../ui-components/ColorPalette/ColorPalette';
import { trackEvent } from '../../utils/analityics.utils';
import CustomTypeahead from '../CustomTypeahead/CustomTypeahead';
import Uploader from '../Uploader/Uploader';
import './Branding.scss';






const DEFAULT_FONT = 'Work Sans';

const fontList = [
    ...Object.keys(gfonts.defaults).map(font => ({ text: font })),
    { text: '______________________', disabled: true },
    ...Object.keys(gfonts.rest).map(font => ({ text: font }))
];

interface BrandingProps {
    onLogoSelected(): void;
    onBrandReady?(): void;
    fontAsset: any;
    fontFile: any;
    setFontAsset: any;
    setFontFile: any;

}

export default function Branding({ onLogoSelected, onBrandReady, fontAsset, setFontAsset, fontFile, setFontFile }: BrandingProps) {
    const sequence = sequenceState.use();
    const sequenceColors = sequenceState.use(sequence => sequence.colors);
    const { user, setUser, plan } = React.useContext(AppContext)
    const presetRef = React.useRef(user?.preset || new Preset())
    const fontFamilyInputRef = React.useRef()
    const [fontUploadError, setFontUploadError] = React.useState(false);
    const [showUserFontValidationPopUp, setShowUserFontValidationPopUp] = React.useState(false)
    const [toggleFontSelect, setToggleFontSelect] = React.useState(false)

    async function onFontChange(font) {
        if (font.length) {
            const fontFamily = font[0].text;
            let preset = presetRef.current
            preset.fontFamily = fontFamily
            user.preset = await preset.save();
            setUser(user)
            sequence.style = sequence.style || new Style()
            sequence.style.fontFamily = fontFamily
            sequence.style.fontAssetSid = null
            sequenceSave(sequence)

            trackEvent('user-fontFamily', { fontFamily });
        }

    }
    function onFontFileCanceled() {
        setFontAsset && setFontAsset(null)
        setFontFile && setFontFile(null)
    }


    function getFileFormat(file) {
        let type = file.name.split('.').pop()
        switch (type) {
            case 'ttf':
                type = Asset.TYPE.FONT_TTF;
                break;
            case 'otf':
                type = Asset.TYPE.FONT_OTF;
                break;
            case 'woff':
                type = Asset.TYPE.FONT_WOFF;
                break;
            case 'woff2':
                type = Asset.TYPE.FONT_WOFF2;
                break;
            default:
                let err = new Error('Not supported format')
                setFontUploadError(err)
                handleError({
                    title: 'Font Upload',
                    message: err.message,
                    responseError: err
                })
                return

        }
        return type
    }

    /**
     * 
     * @param {File[]} files 
     * @returns 
     */
    async function onFontFileInputChange(files) {
        if (!files.length) {
            return
        }
        const file = files[0];
        console.log("file-size", file.size)
        if (file.size > 1024 * 1024) {
            let err = new Error('This file is too large, only font files up to 1MB are allowed.')
            handleError({
                title: 'Font Upload',
                message: err.message,
                responseError: err
            })
            return

        }

        let font_file = new FontFace(`Uploaded-Font`, `url(${URL.createObjectURL(file)}`);
        font_file.load().then(payload => {
            // continue
            console.log("finish upload")
        })
            .catch(err => {
                console.error(err)
            });

        let assetType = getFileFormat(file)
        if (assetType) {
            setFontUploadError(false)
            file.assetType = assetType
            setFontFile && setFontFile(file)
            setShowUserFontValidationPopUp(true)
        }
    }

    async function uploadFont(file, asset) {

        const fileUploader = new FileUploader({
            sequence,
            file,
            content: asset,
        })
        await fileUploader.uploadAsset(file.name);
    }


    function mostFrequent(arr) {
        return arr
            .map(value => [value, arr.filter(v => v === value).length])
            .reduce(([ret, max], [value, count]) => count > max ? [value, count] : [ret, max], [0, 0])[0];
    }


    async function onCloseModal(accepted) {
        setShowUserFontValidationPopUp(false)
        const file = fontFile;
        if (accepted && file) {
            let asset = new Asset();
            asset.name = file.name
            asset.type = file.assetType
            await asset.save();
            await uploadFont(file, asset)
            presetRef.current.addFontAsset(asset)
            presetRef.current.fontFamily = null
            setFontAsset && setFontAsset(asset)
            await presetRef.current.save()
            user.preset = presetRef.current
            setUser(user)
            sequence.style.fontAssetSid = asset.sid
            sequence.style.fontFamily = null
            sequenceSave(sequence)
        }
        else {
            onFontFileCanceled()
            presetRef.current.fontFamily = user?.fontFamily || DEFAULT_FONT
        }
    }

    function onColorChange(color, prevColor, idx) {
        sequence.colors[idx].color = color.hex || numbersToColor(Object.values(color.rgb))
    }

    async function onColorChangeComplete() {
        sequenceSave(sequence)
    }

    const toggleSelect = () => {
        const selectMenu = fontFamilyInputRef.current
        selectMenu.toggleMenu();

        if (!selectMenu.isMenuShown) {
            setToggleFontSelect(true)
            selectMenu.focus();
        } else {
            setToggleFontSelect(false)
        }

    }
    const [showTerms, setShowTerms] = React.useState(false)

    return (

        <div className="">
            <Modal
                show={showUserFontValidationPopUp}
                id="new-visual-dialog"
                className="font-modal-feature-spotlight"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => onCloseModal(false)}
            >
                <Modal.Body>
                    <SVG name='alert' />
                    <a onClick={() => onCloseModal(false)}><SVG name="close" className="close" /></a>
                    {/* <p className='font-modal-close' onClick={() => onCloseModal(false)}>x</p> */}
                    <div className='font-wrapper'>
                        <p className='font-modal'>The user font constitutes "User Content" and shall be </p>
                        <p className='font-modal'> subject to our <span className='font-modal-termsofservie' onClick={() => setShowTerms(true)} >Terms of Service</span></p>
                    </div>
                    <Button onClick={() => onCloseModal(true)} variant="primary" label='Accept' />
                </Modal.Body>
            </Modal>
            <StaticDoc setShowTerms={setShowTerms} showTerms={showTerms} />

            <div className='branding-logo'>
                <div className="logo-uploader">
                    <h5 className='branding-title'>Logo</h5>
                    <LogoDropArea onChange={() => { onBrandReady && onBrandReady(); onLogoSelected && onLogoSelected() }} />
                    {sequence.logo?.assetSid && <div className={"logo-section-branding--colors"}>
                        <h5 className='branding-title'>Color</h5>
                        <div className='peech-color-block-branding'>
                            <ColorPalette editable={true} colors={sequenceColors} onChange={onColorChange} onChangeComplete={onColorChangeComplete} />
                        </div>
                    </div>}
                </div>

                <div>
                    <div className={'font-uploader' + (sequence.logo?.assetSid ? '-with-logo' : '')}>
                        <h5 className='branding-title'>Font</h5>
                        <Dropzone
                            onDrop={onFontFileInputChange}
                            maxFiles={1}
                            className="logo-dropzone"
                            disabled={fontAsset}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div className="logo-section-branding--header"
                                    {...getRootProps()}
                                >
                                    <input id="branding-logo-input"
                                        {...getInputProps()} />
                                    <Uploader file={fontFile} fontAsset={fontAsset} error={fontUploadError} onCancel={onFontFileCanceled} />
                                </div>
                            )}
                        </Dropzone>

                    </div>


                    {!fontAsset && <div className="font-selector">
                        <h5 className='branding-title'>{false ? 'Or choose font ' : 'Choose font'}</h5>
                        <div className="font-section">
                            <CustomTypeahead
                                id="basic-typeahead-single-language"
                                labelKey="text"
                                inputProps={{
                                    className: 'form-control select font-select',
                                }}
                                onChange={onFontChange}
                                options={fontList}
                                placeholder="Choose a font..."
                                defaultInputValue={sequence?.style?.fontFamily || DEFAULT_FONT}
                                refObj={fontFamilyInputRef}
                            />
                        </div>
                    </div>}
                </div>
            </div>
        </div>

    )

}

