
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'
import Access, { IAccess, IAccessFilter, IAccessProperties } from '../access'

export interface ISequenceAccess extends IAccess {
	lastViewed?: number | null;
	creator?: boolean | null;
}

export interface ISequenceAccessProperties extends IAccessProperties {
	lastViewed?: boolean;
	creator?: boolean;
}

export interface ISequenceAccessFilter extends IAccessFilter {
}

export class SequenceAccess<IType extends ISequenceAccess = ISequenceAccess, ITypeProperties extends ISequenceAccessProperties = ISequenceAccessProperties> extends Access<IType, ITypeProperties> implements ISequenceAccess {
	
	static className = 'SequenceAccess';
	static NAME = 'sequenceAccess';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Access.PROPERTIES, 
		lastViewed: true,
		creator: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get lastViewed(): number | null | undefined {
		return this.values.lastViewed
	}
			
	get creator(): boolean | null | undefined {
		return this.values.creator
	}
			
	
	static get<IType extends ISequenceAccess = ISequenceAccess>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends ISequenceAccessFilter = ISequenceAccessFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends ISequenceAccessFilter = ISequenceAccessFilter, IType extends ISequenceAccess = ISequenceAccess>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
