
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'

export interface ISequenceGenerationFlow extends IBase {
	startTime?: number | null;
	rootJobId?: string | null;
	rootJobQueueName?: string | null;
	overlayJobId?: string | null;
	overlayJobQueueName?: string | null;
}

export interface ISequenceGenerationFlowProperties extends IBaseProperties {
	startTime?: boolean;
	rootJobId?: boolean;
	rootJobQueueName?: boolean;
	overlayJobId?: boolean;
	overlayJobQueueName?: boolean;
}

export interface ISequenceGenerationFlowFilter extends IBaseFilter {
}

export class SequenceGenerationFlow<IType extends ISequenceGenerationFlow = ISequenceGenerationFlow, ITypeProperties extends ISequenceGenerationFlowProperties = ISequenceGenerationFlowProperties> extends Base<IType, ITypeProperties> implements ISequenceGenerationFlow {
	
	static className = 'SequenceGenerationFlow';
	static NAME = 'sequenceGenerationFlow';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		startTime: true,
		rootJobId: true,
		rootJobQueueName: true,
		overlayJobId: true,
		overlayJobQueueName: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get startTime(): number | null | undefined {
		return this.values.startTime
	}
			
	get rootJobId(): string | null | undefined {
		return this.values.rootJobId
	}
			
	get rootJobQueueName(): string | null | undefined {
		return this.values.rootJobQueueName
	}
			
	get overlayJobId(): string | null | undefined {
		return this.values.overlayJobId
	}
			
	get overlayJobQueueName(): string | null | undefined {
		return this.values.overlayJobQueueName
	}
			
	
	static get<IType extends ISequenceGenerationFlow = ISequenceGenerationFlow>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends ISequenceGenerationFlowFilter = ISequenceGenerationFlowFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends ISequenceGenerationFlowFilter = ISequenceGenerationFlowFilter, IType extends ISequenceGenerationFlow = ISequenceGenerationFlow>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
