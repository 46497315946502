import styled from '@emotion/styled/macro';
import React from 'react';
import { COLORS } from '../../../constants/colors';
import SVG from '../../components/svg';
import CreateErrorDialog from '../../dialogs/CreateErrorDialog';
import Upgrade from '../../dialogs/upgrade';
import { useCreateProjectClick } from '../../hooks/useCreateProjectClick';
import { AppContext } from '../../lib/app-context';
import {
  brandkitMissingLogoState,
  brandkitMissingModalState,
  projectsLimitReachedState,
  sequencesSinceLastChargeState,
  trialEndReachedState,
  trialModalOpenState,
} from '../../state/local';
import { trackEvent } from '../../utils/analityics.utils';


const CreateContainer = styled.div`
  width: 220px;
  height: 52px;
  display: flex;
  align-items: center;
  margin-left: -16px;
  margin-right: 16px;
  padding-left: 16px;
`;

const CreateButton = styled.div`
  background-size: 200% 100%;
  background-size: 200% 100%;
  background-position-x: 0;
  animation: rainbow 2s ease-in forwards;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${COLORS.peech_neutral_white};
  gap: 10px;
  width: 174px;
  height: 46px;
  background-image: linear-gradient(90deg, #ed3f99 0%, #ed3f99 60%, #18a0fb 100%);
  border-radius: 6px;
  border: unset;
  padding: 12px 11px;
  cursor: pointer;
`;

const CreateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
`;

const ItemTitles = styled.div`
  display: inline;
  margin-left: 8px;
  display: flex;
  align-items: center;
  padding-top: 1px;
`;

const Title = styled.div`
  font-size: 16px;
  margin: 0;
  color: ${COLORS.peech_gray_500};
  animation: in 0.2s;
  margin-left: 10px;
`;

const QuickCreate = styled.div<{ disabled: boolean }>`
  width: 172px;
  height: 45px;
  background: ${({ disabled }): string =>
    !disabled
      ? 'linear-gradient(90deg, #EA429D 0%, #EA429D 9.11%, #EA75B4 24.25%, #AE8BFA 54.15%, #1F9FFB 99.42%)'
      : COLORS.peech_gray_300};
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22.4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  color: ${COLORS.peech_gray_50};
  cursor: ${({ disabled }): string => (!disabled ? 'pointer' : 'auto')};
  padding: 6px;

  svg {
    width: 28px;
    height: 20px;
  }
`;

interface CreateProjectProps {
  renderPlace: string;
  onCreate?(): void;
  disabledCreate: boolean;
  overrideLabel?: string;
  trigger?: string;
}

export default function CreateProject({ onCreate, disabledCreate, overrideLabel, trigger = 'Side bar menu', ...props }: CreateProjectProps) {
  const { user, plan, subscription } = React.useContext(AppContext);
  const [animationPlay, setAnimationPlay] = React.useState(false);
  const { renderPlace } = props;

  const brandkitMissingModal = brandkitMissingModalState.use();
  const brandkitMissingLogo = brandkitMissingLogoState.use();
  const projectsLimitReached = projectsLimitReachedState.use();
  const trialEndReached = trialEndReachedState.use();
  const trialModalOpen = trialModalOpenState.use();
  const sequencesSinceLastCharge = sequencesSinceLastChargeState.use();
  const [upgradePlan, setUpgradePlan] = React.useState(false);
  const [enabledCreateButton, setEnabledCreateButton] = React.useState<boolean | undefined>();
  
  const resetCreateErrorDialog = () => {
    brandkitMissingModalState.set(false);
    brandkitMissingLogoState.set(false);
    projectsLimitReachedState.set(false);
    trialEndReachedState.set(false);
  };

  React.useEffect(() => {
    if (disabledCreate) {
      setEnabledCreateButton(false);
    } else {
      setEnabledCreateButton(true);
    }
  }, [disabledCreate]);

  const isButtonActive = user && plan && subscription && !isNaN(sequencesSinceLastCharge);

  const onCreateProjectClick = useCreateProjectClick(trigger, onCreate);

  function navigateToProject() {
    if (isButtonActive) {
      onCreateProjectClick();
    }
  }

  const createIcon = (
    <g>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_23_1278)">
          <path
            d="M13.694 9.67655L5.9471 14.574C5.62457 14.794 5.40003 15.1306 5.3208 15.5128C5.24158 15.8951 5.31386 16.2932 5.5224 16.6232C5.73095 16.9533 6.05948 17.1894 6.43874 17.282C6.81801 17.3745 7.21839 17.3162 7.55551 17.1193L15.3037 12.2231L13.694 9.67655ZM13.9474 11.9174L12.4275 12.878L11.8708 11.9941L13.3907 11.0334L13.9474 11.9174ZM7.03001 16.2892C6.91223 16.3598 6.7718 16.3823 6.63786 16.3521C6.50392 16.3219 6.38673 16.2413 6.31061 16.1271C6.2369 16.0101 6.21257 15.8687 6.24298 15.7338C6.27338 15.599 6.35602 15.4817 6.47279 15.4077L11.0389 12.5211L11.598 13.4057L7.03001 16.2892ZM16.1945 14.7972L17.3294 15.7253L15.9056 16.0787L14.9785 17.2112L14.627 15.7904L13.4925 14.8604L14.9159 14.5089L15.8434 13.3744L16.1945 14.7972ZM10.1656 8.75363L9.03065 7.82597L10.4539 7.47501L11.3815 6.34004L11.7325 7.76326L12.8675 8.69091L11.4442 9.04187L10.5166 10.1768L10.1656 8.75363ZM16.3122 9.80364L15.3195 8.9917L16.5646 8.68425L17.3765 7.69152L17.684 8.9366L18.6767 9.74853L17.4316 10.056L16.6197 11.0487L16.3122 9.80364Z"
            fill="url(#paint0_linear_23_1278)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 5.98276C2 4.88771 2.83376 4 3.86226 4H20.6226C21.6511 4 22.4848 4.88771 22.4848 5.98276V18.8707C22.4848 19.9657 21.6511 20.8534 20.6226 20.8534H3.86226C2.83376 20.8534 2 19.9657 2 18.8707V5.98276ZM20.6226 5.98276H3.86226V18.8707H20.6226V5.98276Z"
          fill="url(#paint1_linear_23_1278)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_23_1278"
            x1="6.2884"
            y1="11.2197"
            x2="17.7591"
            y2="13.8056"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EA429D" />
            <stop offset="0.0911027" stopColor="#EA429D" />
            <stop offset="0.2426" stopColor="#EA75B4" />
            <stop offset="0.541667" stopColor="#AE8BFA" />
            <stop offset="0.9944" stopColor="#1F9FFB" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_23_1278"
            x1="2"
            y1="12.4261"
            x2="22.4797"
            y2="12.4261"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EA429D" />
            <stop offset="0.0911027" stopColor="#EA429D" />
            <stop offset="0.2426" stopColor="#EA75B4" />
            <stop offset="0.541667" stopColor="#AE8BFA" />
            <stop offset="0.9944" stopColor="#1F9FFB" />
          </linearGradient>
          <clipPath id="clip0_23_1278">
            <rect width="11.7993" height="11.7993" transform="translate(7.54468 5.4751) rotate(12.704)" />
          </clipPath>
        </defs>
      </svg>
    </g>
  );

  return (
    <div data-cy="create-button" onMouseEnter={() => setAnimationPlay(true)}>
      {renderPlace === 'projects' ? (
        <CreateButton
          data-cy="create-project-button"
          onClick={(e) => {
            trackEvent('project-create', { trigger: 'create-my-projects' });
            navigateToProject(e);
          }}
        >
          Create new project
        </CreateButton>
      ) : renderPlace === 'sidebar' ? (
        <CreateContainer
          data-cy="create-project-button"
          onClick={(e) => {
            trackEvent('project-create', { trigger: 'create-sidebar-menu' });
            navigateToProject(e);
          }}
        >
          <CreateWrapper>
            <div>{createIcon}</div>
            <ItemTitles>
              <Title>{overrideLabel ? overrideLabel : 'Create Video'}</Title>
            </ItemTitles>
          </CreateWrapper>
        </CreateContainer>
      ) : renderPlace === 'playerModal' ? (
        <QuickCreate
          disabled={!enabledCreateButton}
          data-cy="create-project-button"
          onClick={(e) => {
            if (enabledCreateButton) {
              trackEvent('project-create', { trigger: 'create-content-library' });
              setEnabledCreateButton(false);
              navigateToProject();
            }
          }}
        >
          <SVG name="quick-create" />
          Quick Create
        </QuickCreate>
      ) : null}
      {upgradePlan && <Upgrade title="To create New Project" onHide={() => setUpgradePlan(false)} text={''} />}
      {(brandkitMissingModal || brandkitMissingLogo || projectsLimitReached || trialEndReached || trialModalOpen) && (
        <CreateErrorDialog
          title="You don’t have Brand Kit yet."
          projectsLimitReached={projectsLimitReached}
          brandkitMissingLogo={brandkitMissingLogo}
          trialEndReached={trialEndReached || trialModalOpen}
          onHide={resetCreateErrorDialog}
          button={''}
          upgrade={false}
          trigger="react_project_limit"
        />
      )}
    </div>
  );
}
