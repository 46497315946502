import React from 'react'

import './Loader.scss'

interface LoaderProps {
    size?: 'xl'|'lg'|'md'|'sm'|'xs'|'xxs';
    label?: string;
}

export default function Loader({ size, label, ...props }: LoaderProps & {}) {

    const sizeClass = `peech-loader--${size}`

    return (
        <div
            className={['peech-loader', sizeClass].join(' ')}
            {...props}
        >
            {label && label}
        </div>
    )
}

Loader.defaultProps = {
    size: 'lg',
    children: undefined,
};
