import React from 'react'

export default function Configure(props) {

	React.useEffect(() => {
		if(!document.location.search) {
			return;
		}
		const ps = document.location.search.substr(1);
		document.cookie = `ps=${ps}; Path=/;`
		document.location = '/'
	}, [])

	return (
		<div/>
	)
}
