import classNames from 'classnames';
import React from 'react';
import Loader from '../Loader';

import Preset, { AssetUsage } from '../../client/preset';
import { Logo } from '../../client/sequence';
import { AppContext } from '../../lib/app-context';

import { loadedPresetsState } from '../../state/local';
import { sequenceSave, sequenceState } from '../../state/sequence';

import Select from '../../components/menu/subtitles/select.styled';
import './PresetLogo.scss';
import { incrementCuePointsVersion, resetLogoScaleToDefault } from '../../state/cuePoints';
import { trackEvent } from '../../utils/analityics.utils';
import {CuePointType} from "../../client/base/cuePoint";
import useUserPs from '../../hooks/useUserPs';

interface PresetLogoProps {}

const LOGO_TYPES_TO_TEXT = {
  ['main']: 'Main Logo',
  [AssetUsage.USAGE_TYPE.BRIGHT]: 'Light Logo',
  [AssetUsage.USAGE_TYPE.DARK]: 'Dark Logo',
  ['custom']: 'Custom',
};

export default function PresetLogo({ ...props }: PresetLogoProps & {}) {
  const { usage, cuePoint } = props;
  const { user, config } = React.useContext(AppContext);
  const sequence = sequenceState.use();
  const sequenceLogo = sequenceState.use((sequence) => sequence?.logo);
  const sequenceStyle = sequenceState.use((sequence) => sequence?.style);
  const withUserPs = useUserPs();
  const sequenceLogoUrl =
    usage === 'outro'
      ? sequenceState.use((sequence) =>
          sequence?.style?.outroLogoAssetSid
            ? withUserPs(`${config.CHUNKS_URL}/asset/${sequence?.style?.outroLogoAssetSid}/logo/${user.cacheVersion}.png`)
            : withUserPs(`${config.CHUNKS_URL}/asset/${sequence?.logo?.assetSid}/logo/${user.cacheVersion}.png`)
        )
      : usage === 'intro'
      ? sequenceState.use((sequence) =>
          sequence?.style?.introLogoAssetSid
            ? withUserPs(`${config.CHUNKS_URL}/asset/${sequence?.style?.introLogoAssetSid}/logo/${user.cacheVersion}.png`)
            : withUserPs(`${config.CHUNKS_URL}/asset/${sequence?.logo?.assetSid}/logo/${user.cacheVersion}.png`)
        )
      : sequenceState.use(
          (sequence) => withUserPs(`${config.CHUNKS_URL}/asset/${sequence?.logo?.assetSid}/logo/${user.cacheVersion}.png`)
        );

  const loadedPresets = loadedPresetsState.use();
  const defaultPreset =
    loadedPresets?.find((p) => p.sid === sequence?.presetSid) || user.preset || Preset.getDefaultPreset(loadedPresets);
  const LogoAssets = defaultPreset?.logos();

  const defaultSelected = LogoAssets?.find((asset) => {
    let ident;
    if (usage === 'outro' && sequenceStyle?.outroLogoAssetSid) {
      ident = sequenceStyle.outroLogoAssetSid;
    } else if (usage === 'intro' && sequenceStyle?.introLogoAssetSid) {
      ident = sequenceStyle.introLogoAssetSid;
    } else if (sequenceLogo?.assetSid) {
      ident = sequenceLogo.assetSid;
    }
    if (asset.assetSid === ident) {
      return asset.assetSid;
    }
    return null;
  });

  const [loading, setLoading] = React.useState(false);

  const options = React.useMemo(() => {
    let arr = [];
    (LogoAssets || []).forEach((asset) => {
      const obj = {
        value: asset.assetSid,
        text: LOGO_TYPES_TO_TEXT[asset.usageType || 'main'],
        key: asset.assetSid,
      };
      arr.push(obj);
    });
    return arr;
  }, [LogoAssets, defaultSelected]);

  const selectedOption = React.useMemo(() => {    
    return options.find((x) => x.value === defaultSelected?.assetSid);
  }, [defaultSelected]);

  async function saveLogoOnSequence(logoSid: string, width: number, height: number) {
    if (usage === 'outro') {
      sequence.style.outroLogoAssetSid = logoSid;
    } else if (usage === 'intro') {
      sequence.style.introLogoAssetSid = logoSid;
    } else {
      sequence.logo.assetSid = logoSid;
      sequence.logo.srcWidth = width;
      sequence.logo.srcHeight = height;
    }
    await sequenceSave(sequence);
    // force re render
    incrementCuePointsVersion();
  }

  async function onLogoChange(target) {
    const trackValue = options.find((x) => x.value === target.value).text;
    trackEvent('intro-outro-edit', {
      type: usage,
      action: 'logo changed',
      assetSid: usage === "outro" ? sequence?.style?.outroAssetSid : sequence?.style?.introAssetSid,
      values: trackValue,
    });

    setLoading(true);
    if (usage === 'outro') {
      if (
        !target.value ||
        (sequence.style.outroLogoAssetSid && target.value === sequence.style.outroLogoAssetSid) ||
        (!sequence.style.outroLogoAssetSid && target.value === sequence.logo.assetSid)
      ) {
        return setLoading(false);
      }
      const assetSid = target.value;
      await saveLogoOnSequence(assetSid, null, null);
      await resetLogoScaleToDefault(CuePointType[usage.toUpperCase()]);
      setLoading(false);
    } else if (usage === 'intro') {
      console.log('Intro logo change request');
      if (
        !target.value ||
        (sequence.style.introLogoAssetSid && target.value === sequence.style.introLogoAssetSid) ||
        (!sequence.style.introLogoAssetSid && target.value === sequence.logo.assetSid)
      ) {
        return setLoading(false);
      }
      const assetSid = target.value;
      await saveLogoOnSequence(assetSid, null, null);
      await resetLogoScaleToDefault(CuePointType[usage.toUpperCase()]);
      setLoading(false);
    } else {
      if (!target.value || target.value === sequence.logo.assetSid) {
        return setLoading(false);
      }
      const assetSid = target.value;
      const { width, height } = await Logo.parseLogo(assetSid, user.cacheVersion);
      await saveLogoOnSequence(assetSid, width, height);
      await resetLogoScaleToDefault(CuePointType[usage.toUpperCase()]);
      setLoading(false);
    }
  }

  return (
    <div className={classNames('preset-logo-select', { disabled: loading })}>
      {loading && (
        <Loader size="md" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%)' }} />
      )}
      {/* SELECT */}

      <Select
        value={selectedOption}
        onChange={onLogoChange}
        options={options}
        getOptionValue={(x) => x.key}
        getOptionLabel={(x) => x.text}
        size="medium"
      />

      {/* PREVIEW */}

      <div className="logo-preview-wrapper">
        <img className="logo-preview" src={sequenceLogoUrl} />
        <br />
      </div>
    </div>
  );
}
