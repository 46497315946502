
import * as Base from '../base/sequence/scene';
	
export {default as Content} from '../content';

export const SceneStatus = Base.SceneStatus;

export interface IScene extends Base.IScene {

}

export interface ISceneProperties extends Base.ISceneProperties {

}

export interface ISceneFilter extends Base.ISceneFilter {

}

export default class Scene<IType extends IScene = IScene, ITypeProperties extends ISceneProperties = ISceneProperties> extends Base.Scene<IType, ITypeProperties> implements IScene {
}