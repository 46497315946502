
import * as Base from './base/graphicColor';

export const GraphicColorType = Base.GraphicColorType;

export interface IGraphicColor extends Base.IGraphicColor {

}

export interface IGraphicColorProperties extends Base.IGraphicColorProperties {

}

export interface IGraphicColorFilter extends Base.IGraphicColorFilter {

}

export default class GraphicColor<IType extends IGraphicColor = IGraphicColor, ITypeProperties extends IGraphicColorProperties = IGraphicColorProperties> extends Base.GraphicColor<IType, ITypeProperties> implements IGraphicColor {
}