
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'
import ContentIdentifier, { IContentIdentifier, IContentIdentifierProperties } from '../asset/contentIdentifier'
import AssetIngestFlow, { IAssetIngestFlow, IAssetIngestFlowProperties } from '../asset/assetIngestFlow'

export enum AssetStatus {
	PENDING = 0,
	PROCESSING = 1,
	READY = 2,
	DELETED = 3,
	DELETE_CANDIDATE = 4,
	ERRONEOUS = 5,
};

export enum AssetType {
	LOGO = 1,
	MUSIC = 101,
	MUSIC_SOURCE = 102,
	CUE_POINT_IMAGE = 103,
	SOURCE = 201,
	CUE_POINT_VIDEO = 202,
	HELP = 203,
	SOURCE_INTRO = 204,
	SOURCE_OUTRO = 205,
	CUE_POINT_PRESENTATION = 401,
	LOTTIE_TEXT = 501,
	LOTTIE_SLIDE = 502,
	LOTTIE_SPEAKER = 503,
	LOTTIE_INTRO = 504,
	LOTTIE_OUTRO = 505,
	LOTTIE_TRANSITION = 506,
	LOTTIE_FRAME = 507,
	LOTTIE_IMAGE = 508,
	LOTTIE_VIDEO = 509,
	LOTTIE_ICON = 510,
	LOTTIE_PACKAGE_PREVIEW = 550,
	FONT_OTF = 601,
	FONT_TTF = 602,
	FONT_WOFF = 603,
	FONT_WOFF2 = 604,
	FONT_ZIP = 605,
};

export interface IAsset extends IBase {
	status?: number | null;
	isReadyFormatted_16_9?: boolean | null;
	isReadyFormatted_9_16?: boolean | null;
	isReadyFormatted_1_1?: boolean | null;
	isReadyResized_9_16?: boolean | null;
	isReadyResized_16_9?: boolean | null;
	isReadyResized_1_1?: boolean | null;
	pathFormatted_16_9?: string | null;
	pathFormatted_9_16?: string | null;
	pathFormatted_1_1?: string | null;
	pathResized_9_16?: string | null;
	pathResized_16_9?: string | null;
	pathResized_1_1?: string | null;
	sourcePath?: string | null;
	transcripted?: boolean | null;
	folderSid?: string | null;
	isDefault?: boolean | null;
	type?: AssetType | null;
	footageSid?: string | null;
	insoundzSessionId?: string | null;
	insoundzSucceeded?: boolean | null;
	userSid?: string | null;
	orgSid?: string | null;
	creatorSid?: string | null;
	fileNames?: string[] | null;
	languageCode?: string | null;
	width?: number | null;
	height?: number | null;
	ownerSid?: string | null;
	parentSid?: string | null;
	name?: string | null;
	description?: string | null;
	systemId?: string | null;
	genres?: string[] | null;
	tags?: string[] | null;
	moods?: string[] | null;
	size?: number | null;
	contentIdentifier?: ContentIdentifier | null;
	noWords?: boolean | null;
	errorCause?: string | null;
	errorCode?: number | null;
	aspectRatio?: string | null;
	assetIngestFlow?: AssetIngestFlow | null;
	textContent?: string | null;
	published?: boolean | null;
}

export interface IAssetProperties extends IBaseProperties {
	status?: boolean;
	isReadyFormatted_16_9?: boolean;
	isReadyFormatted_9_16?: boolean;
	isReadyFormatted_1_1?: boolean;
	isReadyResized_9_16?: boolean;
	isReadyResized_16_9?: boolean;
	isReadyResized_1_1?: boolean;
	pathFormatted_16_9?: boolean;
	pathFormatted_9_16?: boolean;
	pathFormatted_1_1?: boolean;
	pathResized_9_16?: boolean;
	pathResized_16_9?: boolean;
	pathResized_1_1?: boolean;
	sourcePath?: boolean;
	transcripted?: boolean;
	folderSid?: boolean;
	isDefault?: boolean;
	type?: boolean;
	footageSid?: boolean;
	insoundzSessionId?: boolean;
	insoundzSucceeded?: boolean;
	userSid?: boolean;
	orgSid?: boolean;
	creatorSid?: boolean;
	fileNames?: boolean;
	languageCode?: boolean;
	width?: boolean;
	height?: boolean;
	ownerSid?: boolean;
	parentSid?: boolean;
	name?: boolean;
	description?: boolean;
	systemId?: boolean;
	genres?: boolean;
	tags?: boolean;
	moods?: boolean;
	size?: boolean;
	contentIdentifier?: IContentIdentifierProperties;
	noWords?: boolean;
	errorCause?: boolean;
	errorCode?: boolean;
	aspectRatio?: boolean;
	assetIngestFlow?: IAssetIngestFlowProperties;
	textContent?: boolean;
	published?: boolean;
}

export interface IAssetFilter extends IBaseFilter {
	folderSidEqual?: string;
	folderSidIsNull?: boolean;
	isDefaultEqual?: boolean;
	typeIn?: number[];
	typeEqual?: number;
	footageSidEqual?: string;
	footageSidIsNull?: boolean;
	insoundzSessionIdEqual?: string;
	userSidEqual?: string;
	ownerSidEqual?: string;
	systemIdEqual?: string;
}

export class Asset<IType extends IAsset = IAsset, ITypeProperties extends IAssetProperties = IAssetProperties> extends Base<IType, ITypeProperties> implements IAsset {
	
	static className = 'Asset';
	static NAME = 'asset';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		isReadyFormatted_16_9: true,
		isReadyFormatted_9_16: true,
		isReadyFormatted_1_1: true,
		isReadyResized_9_16: true,
		isReadyResized_16_9: true,
		isReadyResized_1_1: true,
		pathFormatted_16_9: true,
		pathFormatted_9_16: true,
		pathFormatted_1_1: true,
		pathResized_9_16: true,
		pathResized_16_9: true,
		pathResized_1_1: true,
		sourcePath: true,
		transcripted: true,
		folderSid: true,
		isDefault: true,
		type: true,
		footageSid: true,
		insoundzSessionId: true,
		insoundzSucceeded: true,
		userSid: true,
		orgSid: true,
		creatorSid: true,
		fileNames: true,
		languageCode: true,
		width: true,
		height: true,
		ownerSid: true,
		parentSid: true,
		name: true,
		description: true,
		systemId: true,
		genres: true,
		tags: true,
		moods: true,
		size: true,
		contentIdentifier: ContentIdentifier.PROPERTIES,
		noWords: true,
		errorCause: true,
		errorCode: true,
		aspectRatio: true,
		assetIngestFlow: AssetIngestFlow.PROPERTIES,
		published: true,
	};
	
	/**
	 * @deprecated use AssetStatus instead
	 */
	static STATUS: any = {
		PENDING: AssetStatus.PENDING,
		PROCESSING: AssetStatus.PROCESSING,
		READY: AssetStatus.READY,
		DELETED: AssetStatus.DELETED,
		DELETE_CANDIDATE: AssetStatus.DELETE_CANDIDATE,
		ERRONEOUS: AssetStatus.ERRONEOUS,
	};
	
	/**
	 * @deprecated use AssetType instead
	 */
	static TYPE: any = {
		LOGO: AssetType.LOGO,
		MUSIC: AssetType.MUSIC,
		MUSIC_SOURCE: AssetType.MUSIC_SOURCE,
		CUE_POINT_IMAGE: AssetType.CUE_POINT_IMAGE,
		SOURCE: AssetType.SOURCE,
		CUE_POINT_VIDEO: AssetType.CUE_POINT_VIDEO,
		HELP: AssetType.HELP,
		SOURCE_INTRO: AssetType.SOURCE_INTRO,
		SOURCE_OUTRO: AssetType.SOURCE_OUTRO,
		CUE_POINT_PRESENTATION: AssetType.CUE_POINT_PRESENTATION,
		LOTTIE_TEXT: AssetType.LOTTIE_TEXT,
		LOTTIE_SLIDE: AssetType.LOTTIE_SLIDE,
		LOTTIE_SPEAKER: AssetType.LOTTIE_SPEAKER,
		LOTTIE_INTRO: AssetType.LOTTIE_INTRO,
		LOTTIE_OUTRO: AssetType.LOTTIE_OUTRO,
		LOTTIE_TRANSITION: AssetType.LOTTIE_TRANSITION,
		LOTTIE_FRAME: AssetType.LOTTIE_FRAME,
		LOTTIE_IMAGE: AssetType.LOTTIE_IMAGE,
		LOTTIE_VIDEO: AssetType.LOTTIE_VIDEO,
		LOTTIE_ICON: AssetType.LOTTIE_ICON,
		LOTTIE_PACKAGE_PREVIEW: AssetType.LOTTIE_PACKAGE_PREVIEW,
		FONT_OTF: AssetType.FONT_OTF,
		FONT_TTF: AssetType.FONT_TTF,
		FONT_WOFF: AssetType.FONT_WOFF,
		FONT_WOFF2: AssetType.FONT_WOFF2,
		FONT_ZIP: AssetType.FONT_ZIP,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		if(values.contentIdentifier) {
			if(this.contentIdentifier) {
				this.contentIdentifier.set(values.contentIdentifier, this, 'contentIdentifier');
			}
			else {
				this.values.contentIdentifier = Base.asType<IContentIdentifier>(values.contentIdentifier, ContentIdentifier, this, 'contentIdentifier') as ContentIdentifier;
			}
			delete values.contentIdentifier;
		}
		if(values.assetIngestFlow) {
			if(this.assetIngestFlow) {
				this.assetIngestFlow.set(values.assetIngestFlow, this, 'assetIngestFlow');
			}
			else {
				this.values.assetIngestFlow = Base.asType<IAssetIngestFlow>(values.assetIngestFlow, AssetIngestFlow, this, 'assetIngestFlow') as AssetIngestFlow;
			}
			delete values.assetIngestFlow;
		}
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get isReadyFormatted_16_9(): boolean | null | undefined {
		return this.values.isReadyFormatted_16_9
	}
			
	set isReadyFormatted_16_9(value: boolean | null | undefined) {
		if(this.values.isReadyFormatted_16_9 !== value) {
			this.propertyChanged('isReadyFormatted_16_9', value);
			this.values.isReadyFormatted_16_9 = value;
		}
	}
			
	get isReadyFormatted_9_16(): boolean | null | undefined {
		return this.values.isReadyFormatted_9_16
	}
			
	set isReadyFormatted_9_16(value: boolean | null | undefined) {
		if(this.values.isReadyFormatted_9_16 !== value) {
			this.propertyChanged('isReadyFormatted_9_16', value);
			this.values.isReadyFormatted_9_16 = value;
		}
	}
			
	get isReadyFormatted_1_1(): boolean | null | undefined {
		return this.values.isReadyFormatted_1_1
	}
			
	set isReadyFormatted_1_1(value: boolean | null | undefined) {
		if(this.values.isReadyFormatted_1_1 !== value) {
			this.propertyChanged('isReadyFormatted_1_1', value);
			this.values.isReadyFormatted_1_1 = value;
		}
	}
			
	get isReadyResized_9_16(): boolean | null | undefined {
		return this.values.isReadyResized_9_16
	}
			
	set isReadyResized_9_16(value: boolean | null | undefined) {
		if(this.values.isReadyResized_9_16 !== value) {
			this.propertyChanged('isReadyResized_9_16', value);
			this.values.isReadyResized_9_16 = value;
		}
	}
			
	get isReadyResized_16_9(): boolean | null | undefined {
		return this.values.isReadyResized_16_9
	}
			
	set isReadyResized_16_9(value: boolean | null | undefined) {
		if(this.values.isReadyResized_16_9 !== value) {
			this.propertyChanged('isReadyResized_16_9', value);
			this.values.isReadyResized_16_9 = value;
		}
	}
			
	get isReadyResized_1_1(): boolean | null | undefined {
		return this.values.isReadyResized_1_1
	}
			
	set isReadyResized_1_1(value: boolean | null | undefined) {
		if(this.values.isReadyResized_1_1 !== value) {
			this.propertyChanged('isReadyResized_1_1', value);
			this.values.isReadyResized_1_1 = value;
		}
	}
			
	get pathFormatted_16_9(): string | null | undefined {
		return this.values.pathFormatted_16_9
	}
			
	set pathFormatted_16_9(value: string | null | undefined) {
		if(this.values.pathFormatted_16_9 !== value) {
			this.propertyChanged('pathFormatted_16_9', value);
			this.values.pathFormatted_16_9 = value;
		}
	}
			
	get pathFormatted_9_16(): string | null | undefined {
		return this.values.pathFormatted_9_16
	}
			
	set pathFormatted_9_16(value: string | null | undefined) {
		if(this.values.pathFormatted_9_16 !== value) {
			this.propertyChanged('pathFormatted_9_16', value);
			this.values.pathFormatted_9_16 = value;
		}
	}
			
	get pathFormatted_1_1(): string | null | undefined {
		return this.values.pathFormatted_1_1
	}
			
	set pathFormatted_1_1(value: string | null | undefined) {
		if(this.values.pathFormatted_1_1 !== value) {
			this.propertyChanged('pathFormatted_1_1', value);
			this.values.pathFormatted_1_1 = value;
		}
	}
			
	get pathResized_9_16(): string | null | undefined {
		return this.values.pathResized_9_16
	}
			
	set pathResized_9_16(value: string | null | undefined) {
		if(this.values.pathResized_9_16 !== value) {
			this.propertyChanged('pathResized_9_16', value);
			this.values.pathResized_9_16 = value;
		}
	}
			
	get pathResized_16_9(): string | null | undefined {
		return this.values.pathResized_16_9
	}
			
	set pathResized_16_9(value: string | null | undefined) {
		if(this.values.pathResized_16_9 !== value) {
			this.propertyChanged('pathResized_16_9', value);
			this.values.pathResized_16_9 = value;
		}
	}
			
	get pathResized_1_1(): string | null | undefined {
		return this.values.pathResized_1_1
	}
			
	set pathResized_1_1(value: string | null | undefined) {
		if(this.values.pathResized_1_1 !== value) {
			this.propertyChanged('pathResized_1_1', value);
			this.values.pathResized_1_1 = value;
		}
	}
			
	get sourcePath(): string | null | undefined {
		return this.values.sourcePath
	}
			
	set sourcePath(value: string | null | undefined) {
		if(this.values.sourcePath !== value) {
			this.propertyChanged('sourcePath', value);
			this.values.sourcePath = value;
		}
	}
			
	get transcripted(): boolean | null | undefined {
		return this.values.transcripted
	}
			
	set transcripted(value: boolean | null | undefined) {
		if(this.values.transcripted !== value) {
			this.propertyChanged('transcripted', value);
			this.values.transcripted = value;
		}
	}
			
	get folderSid(): string | null | undefined {
		return this.values.folderSid
	}
			
	set folderSid(value: string | null | undefined) {
		if(this.values.folderSid !== value) {
			this.propertyChanged('folderSid', value);
			this.values.folderSid = value;
		}
	}
			
	get isDefault(): boolean | null | undefined {
		return this.values.isDefault
	}
			
	set isDefault(value: boolean | null | undefined) {
		if(this.values.isDefault !== value) {
			this.propertyChanged('isDefault', value);
			this.values.isDefault = value;
		}
	}
			
	get type(): AssetType | null | undefined {
		return this.values.type
	}
			
	set type(value: AssetType | null | undefined) {
		if(this.values.type !== value) {
			this.propertyChanged('type', value);
			this.values.type = value;
		}
	}
			
	get footageSid(): string | null | undefined {
		return this.values.footageSid
	}
			
	set footageSid(value: string | null | undefined) {
		if(this.values.footageSid !== value) {
			this.propertyChanged('footageSid', value);
			this.values.footageSid = value;
		}
	}
			
	get insoundzSessionId(): string | null | undefined {
		return this.values.insoundzSessionId
	}
			
	set insoundzSessionId(value: string | null | undefined) {
		if(this.values.insoundzSessionId !== value) {
			this.propertyChanged('insoundzSessionId', value);
			this.values.insoundzSessionId = value;
		}
	}
			
	get insoundzSucceeded(): boolean | null | undefined {
		return this.values.insoundzSucceeded
	}
			
	set insoundzSucceeded(value: boolean | null | undefined) {
		if(this.values.insoundzSucceeded !== value) {
			this.propertyChanged('insoundzSucceeded', value);
			this.values.insoundzSucceeded = value;
		}
	}
			
	get userSid(): string | null | undefined {
		return this.values.userSid
	}
			
	set userSid(value: string | null | undefined) {
		if(this.values.userSid !== value) {
			this.propertyChanged('userSid', value);
			this.values.userSid = value;
		}
	}
			
	get orgSid(): string | null | undefined {
		return this.values.orgSid
	}
			
	set orgSid(value: string | null | undefined) {
		if(this.values.orgSid !== value) {
			this.propertyChanged('orgSid', value);
			this.values.orgSid = value;
		}
	}
			
	get creatorSid(): string | null | undefined {
		return this.values.creatorSid
	}
			
	set creatorSid(value: string | null | undefined) {
		if(this.values.creatorSid !== value) {
			this.propertyChanged('creatorSid', value);
			this.values.creatorSid = value;
		}
	}
			
	get fileNames(): string[] | null | undefined {
		return this.values.fileNames
	}
			
	set fileNames(value: string[] | null | undefined) {
		if(this.values.fileNames !== value) {
			this.propertyChanged('fileNames', value);
			this.values.fileNames = value;
		}
	}
			
	get languageCode(): string | null | undefined {
		return this.values.languageCode
	}
			
	set languageCode(value: string | null | undefined) {
		if(this.values.languageCode !== value) {
			this.propertyChanged('languageCode', value);
			this.values.languageCode = value;
		}
	}
			
	get width(): number | null | undefined {
		return this.values.width
	}
			
	set width(value: number | null | undefined) {
		if(this.values.width !== value) {
			this.propertyChanged('width', value);
			this.values.width = value;
		}
	}
			
	get height(): number | null | undefined {
		return this.values.height
	}
			
	set height(value: number | null | undefined) {
		if(this.values.height !== value) {
			this.propertyChanged('height', value);
			this.values.height = value;
		}
	}
			
	get ownerSid(): string | null | undefined {
		return this.values.ownerSid
	}
			
	get parentSid(): string | null | undefined {
		return this.values.parentSid
	}
			
	set parentSid(value: string | null | undefined) {
		if(this.values.parentSid !== value) {
			this.propertyChanged('parentSid', value);
			this.values.parentSid = value;
		}
	}
			
	get name(): string | null | undefined {
		return this.values.name
	}
			
	set name(value: string | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	get description(): string | null | undefined {
		return this.values.description
	}
			
	set description(value: string | null | undefined) {
		if(this.values.description !== value) {
			this.propertyChanged('description', value);
			this.values.description = value;
		}
	}
			
	get systemId(): string | null | undefined {
		return this.values.systemId
	}
			
	set systemId(value: string | null | undefined) {
		if(this.values.systemId !== value) {
			this.propertyChanged('systemId', value);
			this.values.systemId = value;
		}
	}
			
	get genres(): string[] | null | undefined {
		return this.values.genres
	}
			
	set genres(value: string[] | null | undefined) {
		if(this.values.genres !== value) {
			this.propertyChanged('genres', value);
			this.values.genres = value;
		}
	}
			
	get tags(): string[] | null | undefined {
		return this.values.tags
	}
			
	set tags(value: string[] | null | undefined) {
		if(this.values.tags !== value) {
			this.propertyChanged('tags', value);
			this.values.tags = value;
		}
	}
			
	get moods(): string[] | null | undefined {
		return this.values.moods
	}
			
	set moods(value: string[] | null | undefined) {
		if(this.values.moods !== value) {
			this.propertyChanged('moods', value);
			this.values.moods = value;
		}
	}
			
	get size(): number | null | undefined {
		return this.values.size
	}
			
	get contentIdentifier(): ContentIdentifier | null | undefined {
		return this.values.contentIdentifier
	}
			
	get noWords(): boolean | null | undefined {
		return this.values.noWords
	}
			
	get errorCause(): string | null | undefined {
		return this.values.errorCause
	}
			
	get errorCode(): number | null | undefined {
		return this.values.errorCode
	}
			
	get aspectRatio(): string | null | undefined {
		return this.values.aspectRatio
	}
			
	get assetIngestFlow(): AssetIngestFlow | null | undefined {
		return this.values.assetIngestFlow
	}
			
	set textContent(value: string | null | undefined) {
		if(this.values.textContent !== value) {
			this.propertyChanged('textContent', value);
			this.values.textContent = value;
		}
	}
			
	get published(): boolean | null | undefined {
		return this.values.published
	}
			
	
	static get<IType extends IAsset = IAsset>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IAssetFilter = IAssetFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IAssetFilter = IAssetFilter, IType extends IAsset = IAsset>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
	
	async duration(): Promise<number | null> {
		var action = `duration`;
		const query = this.wrap('query', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.duration !== undefined && data.duration !== null) {
			return data.duration;
		}
		return null;
	}
	
	async format(): Promise<boolean | null> {
		var action = `format`;
		const query = this.wrap('query', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.format !== undefined && data.format !== null) {
			return data.format;
		}
		return null;
	}
	
	async unarchive(): Promise<boolean | null> {
		var action = `unarchive`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.unarchive !== undefined && data.unarchive !== null) {
			return data.unarchive;
		}
		return null;
	}
	
	async setOwner(sid: string, type: number): Promise<boolean | null> {
		var action = `setOwner(sid: ${sid !== undefined ? `${JSON.stringify(sid)}` : 'null'}, type: ${type !== undefined ? `${JSON.stringify(type)}` : 'null'})`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.setOwner !== undefined && data.setOwner !== null) {
			return data.setOwner;
		}
		return null;
	}
	
	async publish(): Promise<boolean | null> {
		var action = `publish`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.publish !== undefined && data.publish !== null) {
			return data.publish;
		}
		return null;
	}
	
	async unpublish(): Promise<boolean | null> {
		var action = `unpublish`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.unpublish !== undefined && data.unpublish !== null) {
			return data.unpublish;
		}
		return null;
	}
	
	async addDenoisedAudioContent(denoisedAudioKey: string): Promise<boolean | null> {
		var action = `addDenoisedAudioContent(denoisedAudioKey: ${denoisedAudioKey !== undefined ? `${JSON.stringify(denoisedAudioKey)}` : 'null'})`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.addDenoisedAudioContent !== undefined && data.addDenoisedAudioContent !== null) {
			return data.addDenoisedAudioContent;
		}
		return null;
	}
	
	async approve(): Promise<boolean | null> {
		var action = `approve`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.approve !== undefined && data.approve !== null) {
			return data.approve;
		}
		return null;
	}
}
