
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'
import GraphicCuePoint, { IGraphicCuePoint, IGraphicCuePointFilter, IGraphicCuePointProperties } from '../graphicCuePoint'

export enum FrameCuePointSourceStatus {
	PENDING = 1,
	READY = 2,
	INIT = 3,
};

export interface IFrameCuePoint extends IGraphicCuePoint {
	backgroundColor?: string | null;
	backgroundColorIndex?: number | null;
}

export interface IFrameCuePointProperties extends IGraphicCuePointProperties {
	backgroundColor?: boolean;
	backgroundColorIndex?: boolean;
}

export interface IFrameCuePointFilter extends IGraphicCuePointFilter {
}

export class FrameCuePoint<IType extends IFrameCuePoint = IFrameCuePoint, ITypeProperties extends IFrameCuePointProperties = IFrameCuePointProperties> extends GraphicCuePoint<IType, ITypeProperties> implements IFrameCuePoint {
	
	static className = 'FrameCuePoint';
	static NAME = 'frameCuePoint';
	static PKS = [
		'sequenceSid',
		'sid',
	];
	static PROPERTIES = {...GraphicCuePoint.PROPERTIES, 
		backgroundColor: true,
		backgroundColorIndex: true,
	};
	
	/**
	 * @deprecated use FrameCuePointSourceStatus instead
	 */
	static SOURCE_STATUS: any = {
		PENDING: FrameCuePointSourceStatus.PENDING,
		READY: FrameCuePointSourceStatus.READY,
		INIT: FrameCuePointSourceStatus.INIT,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get backgroundColor(): string | null | undefined {
		return this.values.backgroundColor
	}
			
	set backgroundColor(value: string | null | undefined) {
		if(this.values.backgroundColor !== value) {
			this.propertyChanged('backgroundColor', value);
			this.values.backgroundColor = value;
		}
	}
			
	get backgroundColorIndex(): number | null | undefined {
		return this.values.backgroundColorIndex
	}
			
	set backgroundColorIndex(value: number | null | undefined) {
		if(this.values.backgroundColorIndex !== value) {
			this.propertyChanged('backgroundColorIndex', value);
			this.values.backgroundColorIndex = value;
		}
	}
			
	
	static get<IType extends IFrameCuePoint = IFrameCuePoint>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IFrameCuePointFilter = IFrameCuePointFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IFrameCuePointFilter = IFrameCuePointFilter, IType extends IFrameCuePoint = IFrameCuePoint>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
