import styled from '@emotion/styled/macro';
import React from 'react';
import Button, { ButtonVariant } from '../../ui-components/Button';
import SVG from '../svg';

const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
  height: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 18px;
  .peech-button {
    height: 100%;
    width: 100%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 16px;
      margin-right: 8px;
    }
  }
`;

interface AddElementButtonProps {
  text: string;
  onClick: () => void;
  disabled: boolean;
}

export default function AddElementButton({ text, onClick, disabled=false }: AddElementButtonProps) {
  return (
    <ButtonContainer>
      <Button onClick={onClick} size="lg" variant={ButtonVariant.Primary} disabled={disabled}>
        <SVG name="plus-in-circle" viewBox="0 0 16 16" />
        {text}
      </Button>
    </ButtonContainer>
  );
}
