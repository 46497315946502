import classNames from 'classnames';
import { navigate } from 'gatsby';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Color from '../../client/color';
import Preset from '../../client/preset';
import SVG from '../../components/svg';
import { AppContext } from '../../lib/app-context';
import { handleError } from '../../state/error';
import { savePreset } from '../../state/local';
import { lottiesLoadingState, packagesLoadingState } from '../../state/visualPackage';
import Button, { ButtonSize, ButtonVariant } from '../../ui-components/Button';
import BrandColors from '../../ui-components/Colors';
import Fonts from '../../ui-components/Fonts/Fonts';
import Logos from '../../ui-components/Logos';
import VisualPackages from '../../ui-components/VisualPackages/VisualPackages';
import { trackEvent } from '../../utils/analityics.utils';
import './create-brandkit-dialog.scss';




enum STEPS {
    NAME = 0,
    LOGO = 1,
    COLORS = 2,
    FONTS = 3,
    VISUAL_PACKAGE = 4
}

interface CreateBrandkitProps {
    onHide(): void;
    show: boolean;
}

export default function CreateBrandkit({ onHide, show }: CreateBrandkitProps) {
    const { user, setUser } = React.useContext(AppContext);

    const [preset, setPreset] = React.useState<Preset>(new Preset())
    const [secondaryColors, setSecondaryColors] = React.useState<Color[]>()
    const [advanced, setAdvanced] = React.useState<boolean>(false)
    const [brandkitName, setBrandkitName] = React.useState('')
    const brandkitNameInputRef = React.useRef()
    const [logoSelected, setLogoSelected] = React.useState<boolean>()
    const [step, setStep] = React.useState<STEPS>(STEPS.NAME)
    const packagesLoading = packagesLoadingState.use();
    const lottiesLoading = lottiesLoadingState.use();

    React.useEffect(() => {
        brandkitNameInputRef.current && brandkitNameInputRef.current.focus();
    }, [])

    const onKeyUp = ({ target }) => setBrandkitName(target.value)

    const BrandStep = <div className='brand-wrapper'>
        <div className='brand-title-wrapper'>
            <h4 className='brand-kit-name-title'>Brand Kit name</h4>
        </div>
        <input
            data-cy="brand-kit-name"
            type="text"
            autoComplete="off"
            className="brand-name"
            data-cy="brand-kit-name"
            id="brand-kit-name"
            ref={brandkitNameInputRef}
            required
            placeholder={"Name"}
            onKeyUp={onKeyUp}
            defaultValue={brandkitName}
        />
    </div>


    function setNewPreset(newPreset) {
        setPreset(newPreset)
        setSecondaryColors(newPreset.secondaryColors)
    }

    const stepsDictionary = React.useMemo(() => {
        let arr = [
            {
                name: 'Name', className: 'name', count: 0, isExpandableStep: false, buttonLabel: 'Next',
                component: () => BrandStep
            },
            {
                name: 'Logo', className: 'logo', count: 1, isExpandableStep: true, buttonLabel: 'Next',
                component: () => <Logos preset={preset} setPreset={setPreset} isNew={true} onLogoReady={(flag = true) => setLogoSelected(flag)} />
            },
            {
                name: 'Colors', className: 'colors', count: 2, isExpandableStep: false, buttonLabel: 'Next', advanced_height: true,
                component: () => <BrandColors preset={preset} setPreset={setNewPreset} secondaryColors={secondaryColors} isNew={true} />
            },
            {
                name: 'Fonts', className: 'fonts', count: 3, isExpandableStep: true, buttonLabel: 'Next',
                component: () => <Fonts preset={preset} setPreset={setNewPreset} isNew={true} />
            },
            {
                name: 'Visual style', className: 'visual_package', count: 4, isExpandableStep: false, buttonLabel: 'Save', advanced_width: true,
                component: () => <VisualPackages preset={preset} setPreset={setNewPreset} isNew={true} />
            }

        ]
       
        return arr;

    }, [preset, brandkitName]);

    const modalClassNames = classNames('create-brandkit', { 'advanced_height': advanced || stepsDictionary[step].advanced_height, 'advanced_width': stepsDictionary[step].advanced_width })
    const activeStepClassName = stepsDictionary[step].className;
    const modalBodyClassNames = classNames(`create-brandkit--body ${activeStepClassName}`, {})
    const stepsClassNames = classNames(`create-brandkit--header--steps ${activeStepClassName}`, {})
    const buttonLabel = stepsDictionary[step].buttonLabel;

   

    const Stepper = <div className="stepper">
        <SVG name="dropdown" />
    </div>

    async function saveNewPreset() {
        preset.name = brandkitName
        try {
            trackEvent('brand-kit-save');
            const newPreset = await savePreset(preset, { user, setUser })
            navigate('/brand', { state: { brandkit: newPreset?.sid } })
        } catch (err) {
            console.error(err)
        }
        onHide()
    }

    function onActionClick() {
        setAdvanced(false)
        stepsDictionary.length - 1 === step ? saveNewPreset() : setStep(step => step + 1);
    }

    function changeStep(e) {
        setStep(STEPS[e.target.className.split(' ')[0].toUpperCase()])
    }

    function canProgressToNextStep(activeStep = step) {
        if (activeStep === STEPS.NAME) {
            return brandkitName
        }
        if (activeStep === STEPS.LOGO) {
            return logoSelected
        }
        if (activeStep === STEPS.COLORS) {
            return logoSelected && brandkitName
        }
        if (activeStep === STEPS.FONTS) {
            return logoSelected && brandkitName
        }
        if (activeStep === STEPS.VISUAL_PACKAGE) {
            return logoSelected && brandkitName && !(packagesLoading || lottiesLoading)
        }
    }

    function clearData() {
        setStep(STEPS.NAME)
        setPreset(new Preset())
        setSecondaryColors(null)
        setAdvanced(false)
        setBrandkitName('')
        setLogoSelected(false)
    }

    React.useEffect(() => !show && clearData(), [show])

    function onHideModal() {
        trackEvent('brand-kit-close-wizard');
        removeBrandKit()
        onHide()
    }

    async function removeBrandKit() {
        onHide()
        try {
            await preset.remove()
        }
        catch (err) {
            handleError({
                title: 'Deleting new Brand kit',
                message: err.message,
                responseError: err
            })
        }
    }

    return (
        <Modal
            data-cy="create-brandkit-wizard"
            className={modalClassNames}
            show={show}
            centered
        >
            <Modal.Header className="create-brandkit--header">
                <div className='close-div' onClick={onHideModal} >
                    <SVG name="close" viewBox="0 0 22 22" />
                </div>
                <h2 className="create-brandkit--header--title">Create Brand Kit</h2>
                <h4 className={stepsClassNames}>
                    {stepsDictionary.map((item, index) => {
                        return (<div key={index} style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <span className={classNames(item.className, { 'pass': index === 0 ? true : canProgressToNextStep(item.count) })} onClick={changeStep}>{item.name}</span>
                            {index !== stepsDictionary.length - 1 && Stepper}
                        </div>)
                    })}
                </h4>
            </Modal.Header>
            <Modal.Body className={modalBodyClassNames}>
                {stepsDictionary[step].component()}

                {stepsDictionary[step].isExpandableStep && <a className="expand-button" onClick={() => setAdvanced(!advanced)}>
                    {!advanced && <span>Advanced</span>}
                    <SVG name="dropdown" />
                </a>}

                <div className="button-wrapper">
                    <Button data-cy="brand-kit-wizard-next" className="confirmation-button" variant={ButtonVariant.Primary} size={ButtonSize.Medium} label={buttonLabel} onClick={onActionClick} disabled={!canProgressToNextStep()} />
                </div>

            </Modal.Body>
        </Modal >
    )
}

