
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export interface ITrack extends IBase {
	externalId?: string | null;
	name?: string | null;
	description?: string | null;
	url?: string | null;
	credit?: string | null;
	creditRequired?: boolean | null;
	isSource?: boolean | null;
}

export interface ITrackProperties extends IBaseProperties {
	externalId?: boolean;
	name?: boolean;
	description?: boolean;
	url?: boolean;
	credit?: boolean;
	creditRequired?: boolean;
	isSource?: boolean;
}

export interface ITrackFilter extends IBaseFilter {
}

export class Track<IType extends ITrack = ITrack, ITypeProperties extends ITrackProperties = ITrackProperties> extends Base<IType, ITypeProperties> implements ITrack {
	
	static className = 'Track';
	static NAME = 'track';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		externalId: true,
		name: true,
		description: true,
		url: true,
		credit: true,
		creditRequired: true,
		isSource: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get externalId(): string | null | undefined {
		return this.values.externalId
	}
			
	set externalId(value: string | null | undefined) {
		if(this.values.externalId !== value) {
			this.propertyChanged('externalId', value);
			this.values.externalId = value;
		}
	}
			
	get name(): string | null | undefined {
		return this.values.name
	}
			
	set name(value: string | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	get description(): string | null | undefined {
		return this.values.description
	}
			
	set description(value: string | null | undefined) {
		if(this.values.description !== value) {
			this.propertyChanged('description', value);
			this.values.description = value;
		}
	}
			
	get url(): string | null | undefined {
		return this.values.url
	}
			
	set url(value: string | null | undefined) {
		if(this.values.url !== value) {
			this.propertyChanged('url', value);
			this.values.url = value;
		}
	}
			
	get credit(): string | null | undefined {
		return this.values.credit
	}
			
	set credit(value: string | null | undefined) {
		if(this.values.credit !== value) {
			this.propertyChanged('credit', value);
			this.values.credit = value;
		}
	}
			
	get creditRequired(): boolean | null | undefined {
		return this.values.creditRequired
	}
			
	set creditRequired(value: boolean | null | undefined) {
		if(this.values.creditRequired !== value) {
			this.propertyChanged('creditRequired', value);
			this.values.creditRequired = value;
		}
	}
			
	get isSource(): boolean | null | undefined {
		return this.values.isSource
	}
			
	set isSource(value: boolean | null | undefined) {
		if(this.values.isSource !== value) {
			this.propertyChanged('isSource', value);
			this.values.isSource = value;
		}
	}
			
	
	static get<IType extends ITrack = ITrack>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends ITrackFilter = ITrackFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends ITrackFilter = ITrackFilter, IType extends ITrack = ITrack>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
