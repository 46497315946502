import GraphicColor from '../client/graphicColor';
import { onCuePointChange } from '../state/cuePoints';
import { trackEvent } from '../utils/analityics.utils';

export default function useVisualsMenuColorsChange() {
  function onColorChange(value, colorObj, type, cuePoint, extractedColors, event) {
    if(event) {
      trackEvent(event, {
        type: type,
        action: 'color changed',
        assetSid: cuePoint.assetSid,
      });
    }
  
    const graphicColor: GraphicColor =
      cuePoint?.values?.colors?.find((x: GraphicColor) =>
        x.sid ? x.sid === colorObj.sid : x.name === colorObj.name
      ) ?? colorObj;

    if (value === null) {
      graphicColor.colorIndex = null;
      graphicColor.color = null;
    } else if (value?.kind === 'static') {
      graphicColor.colorIndex = null;
      graphicColor.color = value?.value;
    } else if (value?.kind === 'palette') {
      graphicColor.colorIndex = value?.value;
      graphicColor.color = null;
    }

    if (!cuePoint.colors) {
      cuePoint.colors = extractedColors;
    }

    onCuePointChange(cuePoint);
  }

  return onColorChange;
}
