import React from 'react';
import Dropzone from 'react-dropzone';
import { MenuProps, components } from 'react-select';

import styled from 'styled-components';

import { COLORS } from '../../../../constants/colors';
import SVG from '../../../components/svg';

const StickyButton = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 38px;
  background-color: white;
  border-top: solid 1px;
  border-color: ${COLORS.peech_gray_300};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  cursor: pointer !important;

  svg {
    path {
      transition: fill 0.3s ease-in-out;
    }
  }

  &:hover {
    color: ${COLORS.peech_primary_2_500};
    svg {
      path {
        fill: ${COLORS.peech_primary_2_500};
      }
    }
  }

  transition: color 0.3s ease-in-out;

  input {
    width: 100%;
    height: 100%;
    border: solid red 1px;
  }
`;

const StyledMenu = styled(components.Menu)`
  position: absolute;
  border-radius: 6px;
  border: unset;
  box-shadow: 0px 4px 16px 0px rgba(4, 27, 63, 0.2) !important;
  padding: 5px;
  z-index: 2 !important;

  .select__menu-list {
    max-height: 160px !important;
  }

  .select__option {
    font-size: 12px;
    font-weight: 400;
    transition: background-color ease-in-out 0.2s, color ease-in-out 0.2s;
  }

  .select__option--is-focused {
    background-color: ${COLORS.peech_gray_100} !important;
    color: ${COLORS.peech_gray_900} !important;
  }
` as any as typeof components.Menu;

const IconWrapper = styled.div`
  width: 20px;
  height: 16px;
  transform: rotate(270deg);
  margin-left: 16px;
`;

export default function StyledFontsSelectMenu({ children, selectProps, ...props }: MenuProps) {
  const { onFontAdd, setMenuIsOpen } = selectProps;

  const handleDrop = (files) => {
    onFontAdd(files);
    setMenuIsOpen(false);
  };

  return (
    <StyledMenu {...props}>
      {children}
      <Dropzone
        onDrop={handleDrop}
        maxFiles={10}
        accept="application/x-rar,application/zip,application/x-zip,application/x-zip-compressed,application/octet-stream, .ttf, .otf, .woff, .woff2"
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <StickyButton>
              Add Font
              <IconWrapper>
                <SVG name="arrow-down" />
              </IconWrapper>
              <input id="branding-font-input" {...getInputProps()} />
            </StickyButton>
          </div>
        )}
      </Dropzone>
    </StyledMenu>
  );
}
