import speakerTitle from '../src/components/common/lottie-loader/speaker-title.json';
import subtitles from '../src/components/common/lottie-loader/subtitles.json';
import graphicText from '../src/components/common/lottie-loader/graphic-text.json';
import outro from '../src/components/common/lottie-loader/outro.json';
import intro from '../src/components/common/lottie-loader/intro.json';
import music from '../src/components/common/lottie-loader/music.json';
import frame from '../src/components/common/lottie-loader/frame.json';
import textSlide from '../src/components/common/lottie-loader/text-slide.json';
import User from '../src/client/user';

export const videoEditRulesList = {
  useIntro: {
    elementName: 'Intro',
    disabled: false,
    isSelected: false,
    animationData: intro,
  },
  useOutro: {
    elementName: 'Outro',
    disabled: false,
    isSelected: false,
    animationData: outro,
  },
  useSpeakers: {
    elementName: 'Speaker title',
    disabled: false,
    isSelected: false,
    animationData: speakerTitle,
  },
  useTexts: {
    elementName: 'Graphic text',
    disabled: false,
    isSelected: false,
    animationData: graphicText,
  },
  useSlides: {
    elementName: 'Text slide',
    disabled: false,
    isSelected: false,
    animationData: textSlide,
  },
  useMusic: {
    elementName: 'Music',
    disabled: false,
    isSelected: false,
    animationData: music,
  },
  useHighlight: {
    elementName: 'Subtitle highlights',
    disabled: false,
    isSelected: false,
    animationData: subtitles,
  },
  useFrame: {
    elementName: 'Frame',
    disabled: false,
    isSelected: false,
    animationData: frame,
  },

  // useIcons: {
  //   elementName: 'Animated icons',
  //   disabled: true,
  //   isSelected: false,
  // },
  useVideos: {
    elementName: 'Content-based footage',
    disabled: true,
    isSelected: false,
  },
  useImages: {
    elementName: 'Content-based images',
    disabled: true,
    isSelected: false,
  },

  useTransitions: {
    elementName: 'Transitions',
    disabled: true,
    isSelected: false,
  },
  useBulletframe: {
    elementName: 'Bullet slide',
    disabled: true,
    isSelected: false,
  },
};

export const getVideoEditRulesList = (user: User) => {
  return videoEditRulesList;
};
