import React, { useMemo } from 'react';
import { entity } from 'simpler-state';

import { FeatureFeatureName } from '../../client/base/feature';
import Sequence, { Chapter } from '../../client/sequence';
import { openPeechProUpgradeModal } from '../../components/layout';
import { AppContext } from '../../lib/app-context';
import { trackEvent } from '../../utils/analityics.utils';
import { DropdownFeatureOption } from '../DropdownFeatureOptions/DropDownFeatureOption';
import { onCloneSequence } from './onSequenceClone';

export type AspectRatio = '1:1' | '16:9' | '9:16';

export const ASPECT_RATIOS: AspectRatio[] = ['16:9', '9:16', '1:1'];

export interface PendingClone {
  id: string;
  aspectRatio: AspectRatio;
  thumbnail: string;
  title: string;
}

export const pendingClonesState = entity<PendingClone[]>([]);

interface Params {
  sequence: Sequence;
  trigger: string;
  onCloneSuccess?(sequenceClone: Sequence): void;
}

export const useSequenceCloneOptions = ({ sequence, trigger, onCloneSuccess }: Params) => {
  const { config, user, plan, reachProjectLimit } = React.useContext(AppContext);
  const chapter = sequence?.chapters?.find((c) => c.status !== Chapter.STATUS.DELETED);
  const sequenceThumbnail = chapter?.thumbnailUrl(sequence, 200, config.CHUNKS_URL ?? '');
  const allowDuplicateProject = React.useMemo(() => config?.ALLOW_DUPLICATE_PROJECT === 'true', [config]);

  return useMemo(() => {
    if (!sequence) {
      return [];
    }
    return ASPECT_RATIOS.sort((aspectRatio) => (aspectRatio === sequence.aspectRatio ? -1 : 0)).map((aspectRatio) => {
      const ratioChange = aspectRatio !== sequence.aspectRatio;
      const planFeature = ratioChange
        ? FeatureFeatureName.CLONE_SEQUENCE_ASPECT_RATIO_CHANGE
        : FeatureFeatureName.CLONE_SEQUENCE;
      const featureAllowed = !!plan?.planAllowedTierFeatures?.includes(planFeature);
      const text = !ratioChange ? 'Duplicate' : `Duplicate to  ${aspectRatio}`;

      return {
        ratio: aspectRatio,
        key: `dupe-${aspectRatio}`,
        icon: `dupe`,
        toolTip: `dupe-${aspectRatio}`,
        text: <DropdownFeatureOption allowed={featureAllowed} text={text} />,
        hide: (ratioChange && !user?.UIFLAGS.CLONE_SEQUENCE_ASPECT_RATIO_CHANGE) || !allowDuplicateProject,
        onClick: () => {
          if (reachProjectLimit) {
            openPeechProUpgradeModal(user, 'Duplicate reach project limit', "PROJECTS_LIMIT_REACHED");
            return;
          }

          if (featureAllowed) {
            // send message to peech web app to translate sequence
            if(user?.UIFLAGS.SMART_LIBRARY && sequence.footageSid) {
              window.top?.postMessage(`CLONE_${aspectRatio}_${trigger}`, '*');
              return
            }
            onCloneSequence(aspectRatio, sequenceThumbnail!, sequence, user.sid!, null, onCloneSuccess);
            trackEvent('Duplicate Project', {
              trigger: trigger,
              ratioChange: ratioChange,
              source: sequence.aspectRatio,
              target: aspectRatio,
            });
            return;
          }
          const triggerForModal = ratioChange ? 'Duplicate Ratio' : 'Duplicate';
          const featureId = ratioChange ? FeatureFeatureName.CLONE_SEQUENCE_ASPECT_RATIO_CHANGE : FeatureFeatureName.CLONE_SEQUENCE;

          openPeechProUpgradeModal(user, triggerForModal, featureId);
        },
      };
    });
  }, [sequence, user, plan, reachProjectLimit, onCloneSequence, sequence?.aspectRatio]);
};
