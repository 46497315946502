
import * as Base from './base/userBase';

export const UserBaseStatus = Base.UserBaseStatus;
export const UserBaseType = Base.UserBaseType;

export interface IUserBase extends Base.IUserBase {

}

export interface IUserBaseProperties extends Base.IUserBaseProperties {

}

export interface IUserBaseFilter extends Base.IUserBaseFilter {

}

export default class UserBase<IType extends IUserBase = IUserBase, ITypeProperties extends IUserBaseProperties = IUserBaseProperties> extends Base.UserBase<IType, ITypeProperties> implements IUserBase {
}