
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'

export interface IIncome extends IBase {
	catalogNum?: string | null;
	description?: string | null;
}

export interface IIncomeProperties extends IBaseProperties {
	catalogNum?: boolean;
	description?: boolean;
}

export interface IIncomeFilter extends IBaseFilter {
}

export class Income<IType extends IIncome = IIncome, ITypeProperties extends IIncomeProperties = IIncomeProperties> extends Base<IType, ITypeProperties> implements IIncome {
	
	static className = 'Income';
	static NAME = 'income';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		catalogNum: true,
		description: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get catalogNum(): string | null | undefined {
		return this.values.catalogNum
	}
			
	set catalogNum(value: string | null | undefined) {
		if(this.values.catalogNum !== value) {
			this.propertyChanged('catalogNum', value);
			this.values.catalogNum = value;
		}
	}
			
	get description(): string | null | undefined {
		return this.values.description
	}
			
	set description(value: string | null | undefined) {
		if(this.values.description !== value) {
			this.propertyChanged('description', value);
			this.values.description = value;
		}
	}
			
	
	static get<IType extends IIncome = IIncome>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IIncomeFilter = IIncomeFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IIncomeFilter = IIncomeFilter, IType extends IIncome = IIncome>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
