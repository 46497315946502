import Sequence from '../client/sequence';
import { analytics } from '../components/wrapWithProvider';
// import { mixpanel } from '../lib/app-context';
import { sequenceState } from '../state/sequence';

export const trackEvent = (event: string, props: object = {}) => {
  try {
    const sequence: Sequence = sequenceState.get();
    const sequenceSid = sequence?.sid ?? undefined;
    console.log('track-event', event, { ...props, sequenceSid });
    // mixpanel.track(event, { ...props, sequenceSid }); // should be removed later after full integration
    analytics.track(event, { ...props, sequenceSid }); // track to segment
  } catch (error) {
    console.error(error);
  }
};
