import { animated, useTransition } from '@react-spring/web';
import classNames from 'classnames';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Sequence, { SequenceAccess, SequenceRole } from '../client/sequence';
import User from '../client/user';
import { AppContext } from '../lib/app-context';
import { uniqueSid } from '../state/cuePoints';
import { sequenceLiveState, sequenceState } from '../state/sequence';
import Button from '../ui-components/Button';
import MenuItem from '../ui-components/Menu/MenuItem';
import { trackEvent } from '../utils/analityics.utils';
import { getKeyByValue } from '../utils/objects.utils';
import Avatar from './avatar';
import SVG from './svg';





const ROLE = {
    [Sequence.ROLE.CREATOR]: 'Creator',
    [Sequence.ROLE.OWNER]: 'Owner',
    [Sequence.ROLE.EDITOR]: 'Can edit',
    [Sequence.ROLE.VIEWER]: 'Can view',
}

const EDITORS = [
    Sequence.ROLE.CREATOR,
    Sequence.ROLE.OWNER,
    Sequence.ROLE.EDITOR
]

const CLEAN_UP_TIMEOUT = 3000

const fullName = user => user.firstName + ' ' + user.lastName

const isCreator = user => user?.role === Sequence.ROLE.CREATOR

interface AccessSelectorProps {
    guest?: SequenceAccess;
    setSelectedRole?(number): void;
    selectedRole?: number;
    setAccesses?: (value: SequenceAccess[]) => void;
    accesses?: SequenceAccess[];
    activeSequence?: Sequence;
    isEditAvailable?: boolean;
    isGuestFromOrg?: boolean

}

function AccessSelector({ guest, setSelectedRole, selectedRole, setAccesses, accesses, activeSequence, isEditAvailable, isGuestFromOrg }: AccessSelectorProps) {
    const [role, setRole] = React.useState<number>(guest?.role || selectedRole || Sequence.ROLE.VIEWER)
    const sequence = activeSequence || sequenceState.use()
    const { user } = React.useContext(AppContext);
    const [isSelected, setIsSelected] = React.useState(false);



    async function onSelectRole(e, value) {

        if (guest) {

            if (guest.role === value) {
                return
            }

            const seqAccess = new SequenceAccess(guest.sid)
            seqAccess.role = value
            try {
                await sequence.updateAccess(guest.sid, seqAccess)
                guest.role = value
                setRole(value)
            } catch (err) {
                return console.error(err)
            }

            return trackEvent('share-update', { from: user.sid, to: (guest.email || guest.userSid), role: value });
        }

        setSelectedRole(value)
    }

    async function revokeAccess() {
        const newAccesses = [...accesses]
        const index = newAccesses.indexOf(guest)

        if (index > -1) {
            newAccesses.splice(index, 1);
            try {
                sequence.revokeAccess(guest.sid)
                setAccesses(newAccesses)
                sequence.values.accessesCount = sequence.accessesCount - 1
            }
            catch (err) {
                return console.error(err)
            }

            trackEvent('share-revoke', { from: user.sid, to: (guest.email || guest.userSid) });
        }
    }

    const attrs = {}
    !isEditAvailable && (attrs.className = 'view-only')

    return (
        <Dropdown >
            <Dropdown.Toggle variant="white" size="sm" id="invite-role" {...attrs}>

                <span className={classNames('role-description', { 'selected': isSelected })} >{ROLE[(guest && guest.role) || selectedRole || role]}</span>
                {!isCreator(guest) && isEditAvailable && <SVG name='dropdown' />}
            </Dropdown.Toggle>

            {!isCreator(guest) && isEditAvailable &&
                <Dropdown.Menu className="dropdown-role-menu">
                    <Dropdown.Item onClick={(e) => { setIsSelected(true); onSelectRole(e, Sequence.ROLE.VIEWER) }}>{ROLE[Sequence.ROLE.VIEWER]}</Dropdown.Item>
                    {isGuestFromOrg && <Dropdown.Item onClick={(e) => { setIsSelected(true); onSelectRole(e, Sequence.ROLE.EDITOR) }}>{ROLE[Sequence.ROLE.EDITOR]}</Dropdown.Item>}
                    {guest && <Dropdown.Item onClick={revokeAccess} className="remove-role">Remove</Dropdown.Item>}
                </Dropdown.Menu>}
        </Dropdown >
    )
}

interface GuestProps {
    guest: SequenceAccess;
    orgUsers: User[];
    accesses: SequenceAccess[];
    setAccesses: (value: SequenceAccess[]) => void;
    activeSequence?: Sequence;
}

function Guest({ guest, orgUsers, setAccesses, accesses, activeSequence }: GuestProps) {

    function isGuestFromOrg() {
        return orgUsers.findIndex(user => user.sid === guest.userSid) !== -1
    }

    return (
        <div className="menu-share-guest-row">
            <div className='avatar'>
                <Avatar user={guest} />
            </div>
            <div className="guest-details">
                <span className="guest-name">
                    {guest.name}
                </span>
            </div>
            <span className="guest-access-view">
                <AccessSelector
                    guest={guest}
                    isEditAvailable={true}
                    isGuestFromOrg={isGuestFromOrg()}
                    accesses={accesses}
                    setAccesses={setAccesses}
                    activeSequence={activeSequence} />

            </span>
        </div>
    )
}

interface SuggestionProps {
    user: User;
    onSelectGuestFromSuggestion(user: User): void;
}

function Suggestion({ user, onSelectGuestFromSuggestion }: SuggestionProps) {

    if (!user.name && !user.firstName && !user.email) {
        return null
    }

    return (
        <a onClick={() => onSelectGuestFromSuggestion(user)}>
            <Avatar user={user} />
            <span className="guest-name">{fullName(user)}</span>
        </a>
    )
}

interface InviteInputProps {
    accesses: SequenceAccess[];
    setAccesses: (value: SequenceAccess[]) => void;
    activeSequence?: Sequence;
    orgUsers: User[];
    onKeyUp(): void;
    onSelectGuestFromSuggestion(): void;
    guest: {};
    suggestions: User[];
    inviteInputRef: any;
    selectedRole: number
    setSelectedRole(): void
}

function InviteInput({ selectedRole, setSelectedRole, accesses, setAccesses, suggestions, activeSequence, orgUsers, guest, onKeyUp, onSelectGuestFromSuggestion, inviteInputRef, inviteGuest, valid }: InviteInputProps) {

    const [error, setError] = React.useState<any>(false);
    const [success, setSuccess] = React.useState<boolean>(false);

    function slicedSuggestions() {
        if (suggestions?.length > 4) {
            suggestions.slice(0, 3)
        }
        return suggestions
    }

    const animation = {
        from: { x: -50, opacity: 0, maxHeight: 0 },
        enter: { x: 0, opacity: 1, maxHeight: 50, duration: 1 },
        leave: item => async (next, cancel) => {
            next({ x: -50, opacity: 0 })
            await next({ maxHeight: 0, delay: 120, duration: 40 })
        }
    }

    const errorTransition = useTransition(error, animation)
    // const successTransition = useTransition(success, animation)

    React.useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(false)
            }, CLEAN_UP_TIMEOUT)
        }
    }, [error])

    React.useEffect(() => {
        if (success) {
            setTimeout(() => {
                setSuccess(false)
            }, CLEAN_UP_TIMEOUT)
        }
    }, [success])

    return (
        <>
            <div className="invite-menu-item">
                <div className="input-wrapper">
                    <input
                        type="text"
                        autoComplete="off"
                        id="invite-email"
                        ref={inviteInputRef}
                        required
                        placeholder="Email"
                        onKeyUp={onKeyUp} />
                    {guest.email && guest.email.length && suggestions?.length > 0 &&
                        (<div className="invite-options">
                            {slicedSuggestions().map(user =>
                                <Suggestion
                                    user={user}
                                    key={user.sid}
                                    onSelectGuestFromSuggestion={onSelectGuestFromSuggestion} />
                            )}
                        </div>)}
                    <div className="guest-access">
                        <AccessSelector
                            setSelectedRole={setSelectedRole}
                            selectedRole={selectedRole}
                            isEditAvailable={true}
                            isGuestFromOrg={!!guest.userSid}
                        />
                    </div>
                </div>
                <Button label='Invite' size="md" variant='primary' className="invite-btn" onClick={inviteGuest} disabled={!valid} />
            </div >
            <div style={{ overflow: 'hidden' }}>
                {/* on Success */}
                {
                    // successTransition(
                    //     (styles, success) => success && <animated.div className="message message-success" style={styles}>
                    //         {success}</animated.div>)
                }

                {/* on Error */}
                {
                    errorTransition(
                        (styles, error) => error && <animated.div className="message message-error" style={styles}>
                            {error.message}
                        </animated.div>)
                }
            </div>

        </>
    )
}


interface ShareProps {
    onShareMenuToggle?(isOpen: boolean): void;
    isModal?: boolean;
    activeSequence?: Sequence;
    onHide(): void;

}

export default function Share({ onShareMenuToggle, isModal, activeSequence, onHide }: ShareProps) {

    const sequence = activeSequence || sequenceState.use();
    const { user } = React.useContext(AppContext);
    const [orgUsers, setOrgUsers] = React.useState<User[]>([]);
    const [accesses, setAccesses] = React.useState([])

    const sequenceWatchers = sequenceLiveState.use(state => state.watchers)
    const [suggestions, setSuggestions] = React.useState<User[]>([]);
    const [guest, setGuest] = React.useState<any>({});
    const inviteInputRef = React.useRef(null)
    const [error, setError] = React.useState<any>(false);
    const [success, setSuccess] = React.useState<boolean>(false);
    const [selectedRole, setSelectedRole] = React.useState<number>(Sequence.ROLE.VIEWER);
    const [valid, setValid] = React.useState(false)


    React.useEffect(() => {
        user && user.orgSid && loadOrgUsers()
    }, [user])

    React.useEffect(() => {
        sequence && sequence.sid && getAccesses()
    }, [sequence])

    async function loadOrgUsers() {
        const ou = await User.list()
        setOrgUsers(ou)
    }

    async function getAccesses() {
        const accesses = await sequence.accesses()
        setAccesses(accesses)
    }

    async function inviteGuest() {

        // User not found.
        if (!guest.email && !guest.userSid) {
            return setError({ message: "User not found." })
        }

        const validateIndex = accesses.findIndex((acc: SequenceAccess) => (guest.email && guest.email === acc.email) || guest.userSid && guest.userSid === acc.userSid)

        // not a valid email.
        if (guest.email && !validateEmail(guest.email)) {
            return setError({ message: "Please enter a valid email." })

        }

        // User already exist.
        if (validateIndex !== -1) {
            return setError({ message: "User is already a collaborator." })
        }

        const seqAccess = new SequenceAccess(uniqueSid())

        for (const [key, value] of Object.entries(guest)) {
            if (key !== "display") {
                seqAccess[key] = value.trim()
            }
        }
        seqAccess.role = selectedRole

        // Invite



        trackEvent('project-share', { projectName: sequence.title, role: getKeyByValue(SequenceRole, selectedRole) });

        try {
            const res = await sequence.grantAccess(seqAccess)
            setAccesses((acs: SequenceAccess[]) => [res, ...acs])
            sequence.values.accessesCount = sequence.accessesCount + 1
            setSuccess(`${guest.display || guest.email} has been invited successfully`)
        } catch (err) {
            console.error(err)
            return setError({ message: "Something went wrong." })
        }
    }

    function onSelectGuestFromSuggestion(guest: User) {
        setGuest({ userSid: guest.sid, display: fullName(guest) })
        setSuggestions([])
        inviteInputRef.current && (inviteInputRef.current.value = fullName(guest))
    }
    function onKeyUp({ target }) {
        const value = target.value
        const currentUserSid = user.sid
        setGuest({ email: value })
        if (!accesses?.length) {
            return
        }

        if (!value) {
            return setValid(false)
        }
        setValid(true)

        const filteredUsers = orgUsers.filter(user => user.sid !== currentUserSid &&
            accesses.findIndex(guest => guest.userSid === user.sid && guest.email === user.email) === -1)

        const filteredSuggestions = filteredUsers.filter(user => user && (user.firstName + ' ' + user.lastName || '').includes(value) || (user.email || '').includes(value))

        selectedRole !== Sequence.ROLE.VIEWER && setSelectedRole(Sequence.ROLE.VIEWER)
        setSuggestions(filteredSuggestions)

    }
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    // function slicedSuggestions() {
    //     if (suggestions?.length > 4) {
    //         suggestions.slice(0, 3)
    //     }
    //     return suggestions
    // }

    const animation = {
        from: { x: -50, opacity: 0, maxHeight: 0 },
        enter: { x: 0, opacity: 1, maxHeight: 50, duration: 1 },
        leave: item => async (next, cancel) => {
            next({ x: -50, opacity: 0 })
            await next({ maxHeight: 0, delay: 120, duration: 40 })
        }
    }

    const errorTransition = useTransition(error, animation)
    // const successTransition = useTransition(success, animation)

    React.useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(false)
            }, CLEAN_UP_TIMEOUT)
        }
    }, [error])

    React.useEffect(() => {
        if (success) {
            setTimeout(() => {
                setSuccess(false)
            }, CLEAN_UP_TIMEOUT)
        }
    }, [success])

    function menuItems(activeSequence) {


        return (<>
            <InviteInput accesses={accesses} setAccesses={setAccesses} suggestions={suggestions} orgUsers={orgUsers} activeSequence={activeSequence} guest={guest} onKeyUp={onKeyUp} onSelectGuestFromSuggestion={onSelectGuestFromSuggestion} inviteInputRef={inviteInputRef} selectedRole={selectedRole} setSelectedRole={setSelectedRole} inviteGuest={inviteGuest} valid={valid} />
            <MenuItem bordered={false}>
                {(accesses || []).map(guest =>
                    <Guest
                        key={guest.sid}
                        guest={guest}
                        orgUsers={orgUsers}
                        accesses={accesses}
                        setAccesses={setAccesses}
                        activeSequence={activeSequence}
                    />)}
            </MenuItem>
            {/* <Button label='Invite' size="sm" variant='tertiary' className="invite-btn" onClick={inviteGuest} /> */}
        </>)
    }

    if (isModal) {
        return (
            <div className="menu-share">
                <div className="menu-share-header">
                    <span className='dropdown-menu-title'>Share</span>
                    <a onClick={onHide}><SVG name="close" className="close" /></a>
                </div>
                {menuItems(activeSequence)}
            </div>
        )
    }

    return (menuItems())

}