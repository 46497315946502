export enum TimeType {
  M = 'm',
  S = 's',
  MS = 'ms',
}
export interface TimeObj {
  m: number;
  s: number;
  ms: number;
}
// min can be more than 59 (include hours)
export const convertMsToTime = (milliseconds: number): TimeObj => {
  let s = Math.floor(milliseconds / 1000);
  let m = Math.floor(s / 60);
  let ms = milliseconds - s * 1000;
  s = s % 60;
  return { m, s, ms };
};

export const convertTimeToMs = (time: TimeObj): number => {
  let milliseconds = 0;
  milliseconds += time.m * 60000;
  milliseconds += time.s * 1000;
  milliseconds += time.ms;
  return milliseconds;
};

export const timePad = (value: string | number, digit: number) => String(value).padStart(digit, '0');


export const validateTimeValue = (time: number, minTime: number, maxTime: number): number =>
  Math.min(Math.max(time, minTime), maxTime); // validate the value is < or > from the min max values

export const convertTimestampToDateString = (timestamp) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  return `${year}, ${month} ${day}`;
}