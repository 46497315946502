import classNames from 'classnames';
import React from 'react';
import './Carousel.scss';

interface CarouselProps {
    shouldNavigateBackwords?: boolean;
    onIndexChange?(index: number, nextIndex: number): void;
    Component: React.FC;
    height?: number;
    items: any[];
}

export default function Carousel({ shouldNavigateBackwords, onIndexChange, Component, height, items, setNameInFocus, setTitleInFocus }: CarouselProps & {}) {

    const [activeIndex, setActiveIndex] = React.useState(0);


    function scrollToIndex(index) {
        if (!Number.isInteger(index)) {
            return
        }

        onIndexChange && onIndexChange(activeIndex, index)

        document.getElementById(`peech-carousel--slide${index}`).scrollIntoView();

        setActiveIndex(index);
    }

    const carouselClassNames = classNames("peech-carousel",
        {
            "start": activeIndex === 0 && !shouldNavigateBackwords,
            "end": activeIndex === items.length - 1 && !shouldNavigateBackwords
        })

    const style = {}

    if (height) {
        style.minHeight = height
        style.maxHeight = height
    }

    const onPrevSlideClick = () => {
        const prevIndex = activeIndex > 0 ? activeIndex - 1 : (shouldNavigateBackwords ? items.length - 1 : null);
        scrollToIndex(prevIndex);
    }

    const onNextSlideClick = () => {
        const nextIndex = activeIndex < items.length - 1 ? activeIndex + 1 : (shouldNavigateBackwords ? 0 : null);
        scrollToIndex(nextIndex);
    }

    return (
        <>
            <div>
                <section className={carouselClassNames} aria-label="Gallery" style={{ paddingTop: height || '75%' }}>
                    <ol className="peech-carousel--viewport">
                        {
                            items.map((item, i) => {
                                return <li id={`peech-carousel--slide${i}`}
                                    tabIndex={i}
                                    key={i}
                                    className={classNames("peech-carousel--slide", { "active": i === activeIndex })}>
                                    <div className="peech-carousel--snapper">
                                        <Component cuePoint={item} saveOnBlur={false}
                                            setNameInFocus={setNameInFocus}
                                            setTitleInFocus={setTitleInFocus} />
                                    </div>
                                </li>
                            })
                        }
                    </ol>

                    <aside className="peech-carousel--navigation">
                        <ol className="peech-carousel--navigation-list">
                            {items.map((item, i) => <li className="peech-carousel--navigation-item" key={i}>
                                <a onClick={() => scrollToIndex(i)}
                                    className={classNames("peech-carousel--navigation-button", { "active": activeIndex === i })}>Go to slide {i + 1}</a>
                            </li>)}
                        </ol>
                    </aside>
                </section>
            </div>
            <button className={activeIndex === 0 && !shouldNavigateBackwords ? 'first-slide' : 'go-to-prev-slide'} onClick={onPrevSlideClick}
            />
            <button className={activeIndex === items.length - 1 && !shouldNavigateBackwords ? 'last-slide' : 'go-to-next-slide'} onClick={onNextSlideClick} />
        </>
    )
}