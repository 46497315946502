import classNames from 'classnames';
import React from 'react';
import { Item, Menu, Separator } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import TitleCuePoint from '../../client/titleCuePoint';
import { useAnimation } from '../../hooks/useAnimation';
import { AppContext } from '../../lib/app-context';
import {
  editCuePointState,
  evalClosedCaption,
  incrementCuePointsVersion,
  isHighlightedState,
  setCuePoint,
  setCuePointOnTime,
} from '../../state/cuePoints';
import { handleError } from '../../state/error';
import { MENUS, menuState } from '../../state/menu';
import { seekPlayback } from '../../state/playback';
import { scenesState, sequenceState } from '../../state/sequence';
import { trackEvent } from '../../utils/analityics.utils';
import { decodeHtml, fitSizeToText, fitTextToElement, splitText } from '../cue-points/editableTextItem';
import { getAnimationProps } from '../cue-points/graphic';
import SVG from '../svg';

const PADDING_START = 800;
const PADDING_END = 3000;
const BRIGHTNESS_THRESHOLD = 200;

export function random(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

export default function ContextMenu({ onContextMenu, event, onHide }) {
  const sequence = sequenceState.use();
  const scenes = scenesState.use((scenes) => scenes.map);
  const colors = sequenceState.use((sequence) => sequence.colors);
  const textAnimation = useAnimation(sequence.style?.textAssetSid, sequence.aspectRatio);
  const slideAnimation = useAnimation(sequence.style?.slideAssetSid, sequence.aspectRatio);

  const { user } = React.useContext(AppContext);

  function getChapterLimits(closedCaption) {
    const chapter = scenes[closedCaption.chapterSid];

    return chapter
      ? {
          minStartTime: 0,
          maxStartTime: chapter.srcDuration * 1000,
        }
      : {};
  }
  const isHighlighted = isHighlightedState.use();

  async function highlight({ selectedText, target, closedCaption, chapterSid, inMenu }) {
    const update = selectedText.words;
    update.forEach((word) => {
      word.origin = word.values.origin || word.word;
      word.highlight = !isHighlighted;
    });
    await evalClosedCaption(target, { current: true }, closedCaption, chapterSid, inMenu);
    isHighlightedState.set(!isHighlighted);
  }

  async function createTextCuePoint({ type, selectedText, target, closedCaption, chapterSid, inMenu }) {
    await evalClosedCaption(target, { current: true }, closedCaption, chapterSid, inMenu);
    const { minStartTime, maxStartTime } = getChapterLimits(closedCaption);

    if (!selectedText || !maxStartTime) {
      return;
    }

    const text = selectedText.words.map((w) => w.word).join(' ');
    const firstWord = selectedText.words[0];
    const lastWord = selectedText.words[selectedText.words.length - 1];

    const srcStartTime = Math.max(0, Math.max(minStartTime, Math.round(firstWord.startTime)));
    const srcEndTime =
      maxStartTime < 0 ? Math.round(lastWord.endTime) : Math.min(maxStartTime, Math.round(lastWord.endTime));
    const startTime = Math.max(0, Math.max(minStartTime, srcStartTime - PADDING_START));
    const endTime = maxStartTime < 0 ? srcEndTime + PADDING_END : Math.min(maxStartTime, srcEndTime + PADDING_END);

    const createCuePoint = new TitleCuePoint(closedCaption.sequenceSid);
    createCuePoint.values.status = TitleCuePoint.STATUS.ACTIVE;
    createCuePoint.chapterSid = chapterSid;
    createCuePoint.type = TitleCuePoint.TYPE.TITLE;

    createCuePoint.srcStartTime = srcStartTime;
    createCuePoint.srcEndTime = srcEndTime;
    createCuePoint.startTime = startTime;
    createCuePoint.endTime = endTime;

    const languageCode = sequence.languageCode || 'en-US';
    if (type === 'slide') {
      createCuePoint.titleType = TitleCuePoint.TITLE_TYPE.SLIDE;
      createCuePoint.left = 0;
      createCuePoint.top = 0;
      createCuePoint.width = 100;
      createCuePoint.height = 100;
      createCuePoint.title = '';
      createCuePoint.text = text;
      if (!slideAnimation) {
        createCuePoint.text = splitText(text);
        createCuePoint.fontSize = 18;
        if (document) {
          const element = document.getElementById('main-graphics');
          if (element) {
            const fontSize = fitTextToElement(element, createCuePoint.text, 100);
            createCuePoint.fontSize = parseFloat(((fontSize / element.offsetHeight) * 100).toFixed(2));
          }
        }
        const brightnessIndex = random(2, 4);
        createCuePoint.color = '#ffffff';
        createCuePoint.backgroundColorIndex = colors.find((c) => c.brightnessIndex === brightnessIndex).dominancy;
      } else {
        const colorProperties = getAnimationProps(slideAnimation);
        createCuePoint.colors = colorProperties;
      }

      trackEvent('closed-caption-graphic', { inMenu, type: 'slide' });
    } else {
      const colorIndex = random(1, 5);
      const color = colors.find((c) => c.dominancy === colorIndex);
      if (textAnimation) {
        createCuePoint.text = text;
        createCuePoint.height = 18;
        // await createCuePoint.calcSizeByLottie(textAnimation)
      } else {
        createCuePoint.text = text;
        createCuePoint.fontSize = 8;
        let { width, height } = fitSizeToText(createCuePoint.fontSize, createCuePoint.text);
        createCuePoint.width = width;
        createCuePoint.height = height;
      }
      createCuePoint.left = 5;
      createCuePoint.top = 21;
      createCuePoint.color = color.brightness > BRIGHTNESS_THRESHOLD ? '#000000' : '#ffffff';
      createCuePoint.backgroundColorIndex = colorIndex;
      trackEvent('closed-caption-graphic', { inMenu, type: 'text' });
    }

    try {
      await createCuePoint.save();
      const calced = setCuePoint(createCuePoint, sequence, scenes);
      const activeAnimation = type === 'text' ? textAnimation : slideAnimation;
      const activeAnimationMidTime = activeAnimation?.data && (activeAnimation.data.op / activeAnimation.data.fr) * 500;
      const activeTime = calced.times[calced.chapterSid || calced.sequenceSid];
      setCuePointOnTime(calced, true);
      seekPlayback((activeTime.start + (activeAnimationMidTime || 1000)) / 1000);
      incrementCuePointsVersion();
      menuState.set(MENUS.VISUALS);
    } catch (err) {
      console.error(err);
      handleError({
        title: 'New Visual',
        message: err.message,
        responseError: err,
      });
    }
  }

  async function openAssetsDialog({ type, selectedText, target, closedCaption, chapterSid, inMenu }) {
    await evalClosedCaption(target, { current: true }, closedCaption, chapterSid, inMenu);
    const { minStartTime, maxStartTime } = getChapterLimits(closedCaption);
    if (!selectedText || !maxStartTime) {
      return;
    }

    trackEvent('closed-caption-graphic', { inMenu, type });

    const txt = selectedText.words.map((w) => w.word).join(' ');
    const firstWord = selectedText.words[0];
    const lastWord = selectedText.words[selectedText.words.length - 1];
    const createCuePoint = new TitleCuePoint(sequence.sid, 'tmp');
    createCuePoint.values.status = TitleCuePoint.STATUS.ACTIVE;
    createCuePoint.chapterSid = chapterSid;
    createCuePoint.type = TitleCuePoint.TYPE.TITLE;
    createCuePoint.titleType = TitleCuePoint.TITLE_TYPE.IMAGE;
    createCuePoint.text = decodeHtml(txt[0].toUpperCase() + txt.substr(1));
    createCuePoint.srcStartTime = Math.max(minStartTime, Math.round(firstWord.startTime));
    createCuePoint.srcEndTime = Math.min(maxStartTime, Math.round(lastWord.endTime));
    createCuePoint.startTime = Math.max(minStartTime, createCuePoint.srcStartTime - PADDING_START);
    createCuePoint.endTime = Math.min(maxStartTime, createCuePoint.srcEndTime + PADDING_END);
    createCuePoint.hide = true;

    const chapter = scenes[chapterSid];
    chapter && seekPlayback(chapter.offset - (chapter.clipFrom || 0) + createCuePoint.middleTime / 1000);

    editCuePointState.set({
      cuePoint: createCuePoint,
      defaultType: type,
    });
    setCuePoint(createCuePoint, sequence, scenes);
    setCuePointOnTime(createCuePoint, true);
  }

  return (
    <>
      <Menu
        onHidden={() => {
          onHide && onHide();
          onContextMenu(false);
        }}
        className="visuals-context-menu"
        data-cy="right-click-menu"
        id={`context-menu`}
      >
        <Item className={'visuals-context-menu-header disabled-demo-feature'}>
          <b>Add New Visual</b>
        </Item>
        <Item
          data={{ type: 'highlight' }}
          onClick={({ data, props }) => {
            trackEvent('subtitle-highlight', {
              action: !isHighlighted ? 'highlight' : 'unhighlight',
              trigger: 'subtitle-contex-menu',
            });
            highlight({ ...data, ...props });
          }}
        >
          <SVG name="highlight" title={isHighlighted ? 'Unhighlight' : 'Highlight'} />
          {isHighlighted ? 'Unhighlight' : 'Highlight'} Text
        </Item>
        <Item
          data={{ type: 'text' }}
          onClick={({ data, props }) => {
            trackEvent('visuals-added', { type: 'graphic-text', trigger: 'timecode-context-menu' });
            createTextCuePoint({ ...data, ...props });
          }}
        >
          <SVG name="text" title="Graphic Text" />
          Graphic Text
        </Item>
        <Item
          className="disabled-demo-feature"
          data={{ type: 'icon' }}
          onClick={({ data, props }) => openAssetsDialog({ ...data, ...props })}
        >
          <SVG name="icon" title="Icon" />
          Icon
        </Item>
        <Item
          className="disabled-demo-feature"
          data={{ type: 'image' }}
          onClick={({ data, props }) => openAssetsDialog({ ...data, ...props })}
        >
          <SVG name="image" title="Image" />
          Image
        </Item>
        <Item
          className="disabled-demo-feature"
          data={{ type: 'video' }}
          onClick={({ data, props }) => openAssetsDialog({ ...data, ...props })}
        >
          <SVG name="video" title="Footage" />
          Video
        </Item>
        <Item
          data={{ type: 'slide' }}
          onClick={({ data, props }) => {
            trackEvent('visuals-added', { type: 'text-slide', trigger: 'timecode-context-menu' });
            createTextCuePoint({ ...data, ...props });
          }}
        >
          <SVG name="slide" title="Text Slide" />
          Text Slide
        </Item>
        <Separator />
        <Item
          className={'upload-icon disabled-demo-feature'}
          data={{ type: 'asset' }}
          onClick={({ data, props }) => openAssetsDialog({ ...data, ...props })}
        >
          <SVG name="upload" title="My Uploads" />
          My Uploads
        </Item>
      </Menu>
    </>
  );
}
