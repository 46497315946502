import React from 'react';

import {
  CaptionsFormatType,
  SUBTITLE_FORMAT_PRESETS,
  SubtitleFormatName,
  SubtitleFormatPreset,
  captionFormatsSvgDict,
} from '../../../utils/closeCpation.utils';

import styled from '@emotion/styled';

import { COLORS } from '../../../../constants/colors';
import Button from '../../../ui-components/Button';
import Loader from '../../../ui-components/Loader';
import { AspectRatio } from '../../../ui-components/SequenceCloneOptions/useSequenceCloneOptions';
import SVG from '../../svg';
import { LoadingWrapper } from './AdvancedCaptionsFormatter';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  padding-top: 10px;
`;

const EditFormatButton = styled.div`
  position: absolute;
  top: -2px;
  right: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 22px;
    height: 22px;
  }

  &:hover {
    svg {
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    }
  }

  &:active {
    svg {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }
  }
`;

const EditButton = styled(Button)`
  width: 100% !important;
  height: 30px !important;
  font-size: 14px !important;
`;

const CaptionFormatsContainer = styled.div<{ oneItem?: boolean }>`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: ${({ oneItem }) => (oneItem ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)')};
  gap: 8px;
`;

const CaptionFormatItem = styled.div<{ isSelected?: boolean; cursor?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  padding: 5px;
  border: ${({ isSelected }) => (isSelected ? `1px solid ${COLORS.peech_gray_300}` : 'none')};
  border-radius: 10px;
  box-sizing: border-box;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
  cursor: ${({ cursor }) => cursor || 'auto'};

  span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    line-height: 16px;
    svg {
      transform: rotate(180deg);
      margin-right: 0 !important;
    }
  }

  &:hover {
    box-shadow: ${({ cursor }) => (cursor ? '0px 0px 4px rgba(0, 0, 0, 0.25)' : 'none')};
  }

  transition: box-shadow 0.7s ease-in-out;
`;

const SvgWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 4px;

  svg {
    max-width: 105px !important;
    max-height: 64px !important;
    width: 100%;
    height: auto;
  }
`;

interface CaptionsFormatterProps {
  aspectRatio: AspectRatio;
  currentCaptionsFormatName?: string | null;
  setIsAdvancedOpen: (isAdvancedOpen: boolean) => void;
  rearrangeCaptions: (captionsFormatValues: CaptionsFormatType) => void;
  isCaptionsReformatting: boolean;
  isCaptionsFormatEditing: boolean;
  setIsCaptionsFormatEditing: (isCaptionsFormatEditing: boolean) => void;
}

export default function CaptionsFormatter({
  aspectRatio,
  currentCaptionsFormatName,
  setIsAdvancedOpen,
  rearrangeCaptions,
  isCaptionsReformatting,
  isCaptionsFormatEditing,
  setIsCaptionsFormatEditing,
}: CaptionsFormatterProps) {
  const onCaptionFormatClick = (captionFormat: SubtitleFormatName) => {
    if (captionFormat === SubtitleFormatName.Advanced) {
      setIsAdvancedOpen(true);
    } else {
      rearrangeCaptions({formatName: captionFormat});
      setIsCaptionsFormatEditing(false);
    }
  };

  React.useEffect(() => {
    if (!currentCaptionsFormatName) {
      setIsCaptionsFormatEditing(true);
    }
  }, [currentCaptionsFormatName]);

  return (
    <Container>
      {isCaptionsReformatting ? (
        <LoadingWrapper>
          <Loader size="lg" />
          <span>Generating style</span>
        </LoadingWrapper>
      ) : (
        <>
          <CaptionFormatsContainer oneItem={!isCaptionsFormatEditing}>
            {isCaptionsFormatEditing ? (
              Object.values(SUBTITLE_FORMAT_PRESETS[aspectRatio]).map((item) => (
                <CaptionFormatItem
                  key={item.name}
                  onClick={() => onCaptionFormatClick(item.name)}
                  isSelected={item.name === currentCaptionsFormatName}
                  cursor="pointer"
                >
                  <span>
                    {item.name}
                    {item.name === SubtitleFormatName.Advanced && (
                      <SVG name="arrow-left" viewBox="0 0 24 24" style={{ marginInline: '-4px 8px', width: '16px' }} />
                    )}
                  </span>
                  <SvgWrapper>{item.icon}</SvgWrapper>
                </CaptionFormatItem>
              ))
            ) : (
              <>
                <EditFormatButton onClick={() => setIsCaptionsFormatEditing(true)}>
                  <SVG name="edit" viewBox="0 0 24 24" />
                </EditFormatButton>
                <CaptionFormatItem>
                  <span>{currentCaptionsFormatName}</span>

                  <SvgWrapper>
                    {currentCaptionsFormatName
                      ? SUBTITLE_FORMAT_PRESETS[aspectRatio][currentCaptionsFormatName].icon
                      : null}
                  </SvgWrapper>
                </CaptionFormatItem>
              </>
            )}
          </CaptionFormatsContainer>
        </>
      )}
    </Container>
  );
}
