
import * as Base from './base/plan';
import { PlanStatus } from './base/plan';

export { PlanStatus } from './base/plan';

export interface IPlan extends Base.IPlan {

}

export interface IPlanProperties extends Base.IPlanProperties {

}

export interface IPlanFilter extends Base.IPlanFilter {

}

export default class Plan<IType extends IPlan = IPlan, ITypeProperties extends IPlanProperties = IPlanProperties> extends Base.Plan<IType, ITypeProperties> implements IPlan {

    get planAllowedTierFeatures(): string[] | null | undefined {
		return this.values.allowedTierFeatures
	}

	get planDisallowedTierFeatures(): string[] | null | undefined {
		return this.values.disallowedTierFeatures
	}
}