import styled from '@emotion/styled/macro';
import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { entity } from 'simpler-state';
import Asset from '../../client/asset';
import Base from '../../client/base/base';
import { FeatureFeatureName } from '../../client/base/feature';
import Sequence from '../../client/sequence';
import Shopper from '../../client/shopper';
import User from '../../client/user';
import { EDITORS } from '../../components/dashboard';
import DownloadButton from '../../components/download-button';
import { openPeechProUpgradeModal, openTranslateModal } from '../../components/layout';
import LottiePlayer from '../../components/lottie-player';
import Share from '../../components/share';
import SupportModal from '../../components/support-modal';
import SVG from '../../components/svg';
import Upgrade from '../../dialogs/upgrade';
import useOpenDownloadDialog from '../../hooks/useOpenDownloadDialog';
import { AppContext } from '../../lib/app-context';
import { WIZARDS } from '../../pages/create';
import {
  DownloadLimitReachedDialogState,
  brandkitMissingModalState,
  modifySequence,
  shopperState,
  trialModalOpenState,
  trialRestState,
  wizardState,
  WatermarkAlertModalDialogState,
} from '../../state/local';
import { sequenceSave, sequenceState } from '../../state/sequence';
import { trackEvent } from '../../utils/analityics.utils';
import { onProjectTitleKeyPress } from '../../utils/input.utils';
import { getKeyByValue } from '../../utils/objects.utils';
import { UpgradeRequiredMark } from '../DropdownFeatureOptions/DropDownFeatureOption';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { supportState } from '../HeaderMenu/Menu';
import OptionsMenu from '../OptionsMenu/OptionsMenu';
import { useSequenceCloneOptions } from '../SequenceCloneOptions/useSequenceCloneOptions';
import { PeechProUpgradeButton } from '../StyledComponents/PeechProUpgradeButton.styled';
import './Header.scss';
import loading from './loading.json';
import save from './save.json';
import WatermarkAlertModal from '../../dialogs/WatermarkAlertModal/WatermarkAlertModal';
import { isInIframe } from '../../utils/window.utils';

export const sequencesInitializedState = entity(false);
export const libraryInitializedState = entity(false);

const PROJECT_ROUTE = 'project';
const BRAND_ROUTE = 'brand';
const PLAN_ROUTE = 'plans';
const LEARNING_CENTER_ROUTE = 'learning-center';
const LIBRARY = 'library';
const TRENDS = 'trends';

const ASSET_FONT_TYPES = [Asset.TYPE.FONT_OTF, Asset.TYPE.FONT_TTF, Asset.TYPE.FONT_WOFF, Asset.TYPE.FONT_WOFF2];

const StyledPeechProUpgradeButton = styled(PeechProUpgradeButton)`
  justify-content: space-between;
  height: 28px;
  font-size: 14px;
  line-height: 20px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

const StyledOptionsMenu = styled(OptionsMenu)`
  position: static;
  margin-right: 12px;

  .option--toggle {
    height: unset;
    margin: 0;
  }

  .options-menu {
    inset: 16px auto auto 71px !important;
  }
  svg[name='translate'] {
    margin-top: 10px;
  }
`;

const SequenceCloneOptionsTrigger = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  padding-inline: 8px;
  background: #f2f2f2;
  border-radius: 6px;

  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #666;

  > *:not(:last-child) {
    margin-right: 8px;
  }

  svg {
    padding: 0;
    stroke: none;
  }
  svg[name='translate'] {
    margin-top: 10px;
  }
`;

const SequenceTranslateButton = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  padding: 0 10px 0 10px;
  background: #f2f2f2;
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #666;
  margin-right: 14px;
  cursor: pointer;
  svg {
    width: 24px;
    padding: 0;
    stroke: none;
  }
  svg[name='translate'] {
    margin-top: 10px;
  }
  svg[name='diamond-gradient'] {
    margin-left: 4px !important;
  }
`;

interface HeaderProps {
  user: User;
}

export default function Header({ ...props }: HeaderProps) {
  const { config, user, plan, subscription, reachProjectLimit, isMobileDevice } = React.useContext(AppContext);
  const sequence = sequenceState.use();
  const [showShare, setShowShare] = React.useState<boolean>(false);
  const location = useLocation();
  const wizard = wizardState.use();
  const [trialNotificationOpen, setTrialNotificationOpen] = React.useState(false);
  const trialRest = trialRestState.use();

  const isOnSequencePage = location.pathname.replaceAll('/', '') === PROJECT_ROUTE && location.hash;
  const isOnWizard = location.pathname.replaceAll('/', '') === PROJECT_ROUTE;
  const isPlanPage = location.pathname.replaceAll('/', '').endsWith(PLAN_ROUTE);

  const shouldShowSequenceActions = wizard === WIZARDS.CONTENT_EDITOR || isOnSequencePage;
  const isCreatorOnSequencePage = user && isSequenceCreator() && isOnSequencePage;
  const shouldShowRightHeaderActions = user && isSequenceCreator() && isOnSequencePage;
  const openDownloadDialog = useOpenDownloadDialog();

  const allowDuplicateProject = React.useMemo(
    () => config?.ALLOW_DUPLICATE_PROJECT === 'true' && !location.state?.editContent,
    [config, location.state],
    [config]
  );

  const allowTranslateProject = !!user?.UIFLAGS.TRANSLATE_SEQUENCE;
  const planFeature = FeatureFeatureName.TRANSLATE_SEQUENCE;
  const translateAllowed = !!plan?.planAllowedTierFeatures?.includes(planFeature);

  const onShareMenuToggle = (isOpen, e, metadata) => {
    metadata?.source !== 'select' && setShowShare(!showShare);
    if (isOpen === true) {
      trackEvent('project-show-share-dialog', { trigger: 'share-monitor', isOpen });
    }
  };
  const [fontAsset, setFontAsset] = React.useState();
  const presetFontFamily = user?.preset?.fontFamily;
  const [saveState, setSaveState] = React.useState(Base.saveState.IDLE);
  const brandkitMissingModal = brandkitMissingModalState.use();
  const support = supportState.use();
  const [shopper, setShopper] = React.useState(null);
  const globalShopper = shopperState.use();
  const shouldShowUpgradeFeatureButton = plan?.planDisallowedTierFeatures?.includes(
    FeatureFeatureName.HIDE_UPGRADE_BUTTON
  );
  const downloadLimitReachedDialogState = DownloadLimitReachedDialogState.use();
  const watermarkAlertModalDialogState = WatermarkAlertModalDialogState.use();

  React.useEffect(async () => {
    if (user) {
      const shopperNew = await Shopper.get(null);
      setShopper(shopperNew);
    }
  }, [user]);

  React.useEffect(() => {
    if (globalShopper?.sid) {
      setShopper(globalShopper);
    }
  }, [globalShopper]);

  React.useEffect(() => {
    let remainingDays = null;

    if (
      plan &&
      subscription &&
      subscription.freeTrialRemainingTime !== null &&
      subscription.freeTrialRemainingTime !== undefined
    ) {
      remainingDays = Math.ceil(subscription.freeTrialRemainingTime / (1000 * 60 * 60 * 24));
      trialRestState.set(remainingDays);
    }

    if (remainingDays === null || remainingDays === undefined || remainingDays > 90) {
      // User is on paid plan or remainingDays > 90 (and then we don't show, see https://github.com/peechpro/data-layer/pull/559)
      setTrialNotificationOpen(false);
      trialModalOpenState.set(false);
    } else if (remainingDays) {
      // User has remaining days of free trial
      setTrialNotificationOpen(true);
      trialModalOpenState.set(false);
    } else {
      // Free trial expired
      setTrialNotificationOpen(false);
      trialModalOpenState.set(true);
    }
  }, [plan, subscription]);

  // Set up the interval.
  React.useEffect(() => {
    const id = setInterval(() => {
      setSaveState(Base.saving);
    }, 500);

    return () => clearInterval(id);
  });

  React.useEffect(() => {
    let postMessage = 'SEQUENCE_SAVING_NOT_STARTED';
    switch (saveState) {
      case Base.saveState.LOADING:
        postMessage = 'SEQUENCE_SAVING_LOADING';
        break;
      case Base.saveState.SUCCESS:
        postMessage = 'SEQUENCE_SAVING_SUCCESS';
        break;
      case Base.saveState.FAILURE:
        postMessage = 'SEQUENCE_SAVING_FAILED';
        break;
      default:
        break;
    }

    isInIframe() && window.top?.postMessage(postMessage, '*');
  }, [saveState, sequence]);

  React.useEffect(() => {
    console.log('User PRESET', { preset: user?.preset, brandkitMissingModal });
  }, [user?.preset]);

  React.useEffect(async () => {
    const fontPreset = user?.preset?.assets?.find((asset) => ASSET_FONT_TYPES.includes(asset.type));
    if (fontPreset && fontPreset !== fontAsset && !presetFontFamily) {
      setFontAsset(user?.preset?.assets.find((asset) => ASSET_FONT_TYPES.includes(asset.type)));
    }
  }, [user?.preset]);

  React.useEffect(async () => {
    if (fontAsset && fontAsset.assetSid && !presetFontFamily) {
      let asset = await Asset.get(fontAsset.assetSid);
      // setFontFile(asset)
    }
  }, [fontAsset, presetFontFamily]);

  function isSequenceCreator() {
    return sequence?.role === Sequence.ROLE.CREATOR || sequence?.role === Sequence.ROLE.EDITOR;
  }

  async function onTitleBlur({ target }) {
    var title = target.innerText.trim();
    if (title.length && title !== sequence.title) {
      sequence.title = title;
      await sequenceSave(sequence);
      modifySequence(sequence);
    } else {
      target.innerText = sequence.title;
    }
    target.scrollLeft = 0;
  }

  React.useEffect(() => {}, [location]);

  const contentEditorTabClassNames = classNames({ active: location.state?.editContent });
  const visualsEditorTabClassNames = classNames({ active: !location.state?.editContent });

  function onTabClick(editor) {
    switch (editor) {
      case EDITORS.CONTENT:
        console.log('onTabClick', editor);
        trackEvent('project-monitor-toggle-button', { trigger: getKeyByValue(EDITORS, editor)?.toLowerCase() });
        user?.UIFLAGS.TRIMMER
          ? window.location.assign(`${config.NEW_UI_URL}/project/${sequence.sid}/content-editor`)
          : navigate(undefined, { state: { editContent: editor === EDITORS.CONTENT } });
        break;

      default:
        break;
    }
  }

  const redirectToPlans = () => {
    setTrialNotificationOpen(false);
    navigate('/plans');
  };

  const sequenceCloneOptions = useSequenceCloneOptions({
    sequence,
    trigger: 'visual-editor',
    onCloneSuccess: () => {
      navigate(`/`);
    },
  });

  const onTranslateClick = () => {
    if (reachProjectLimit) {
      openPeechProUpgradeModal(user, 'Translate react project limit', 'PROJECTS_LIMIT_REACHED');
      return;
    }
    if (translateAllowed) {
      openTranslateModal({ sequence, trigger: 'visual-editor' });
      return;
    }
    openPeechProUpgradeModal(user, 'Translate', FeatureFeatureName.TRANSLATE_SEQUENCE);
  };

  return (
    <>
      <div className={classNames('top-header', { 'white-header': isCreatorOnSequencePage || isOnWizard })}>
        <div className="top-header-left">
          {(shouldShowSequenceActions || isOnWizard) && (
            <a
              className="back-to-app"
              onClick={() => {
                if (sequence.footageSid) {
                  navigate(`/?footageSid=${sequence.footageSid}`);
                } else {
                  navigate('/');
                }
              }}
            >
              <SVG name="arrow-left" />
              Home
            </a>
          )}

          <div>
            {(shouldShowSequenceActions || isOnWizard) && shouldShowUpgradeFeatureButton && (
              <StyledPeechProUpgradeButton onClick={() => openPeechProUpgradeModal(user, 'Project Monitor Button')}>
                <SVG name="diamond" />
                Upgrade
              </StyledPeechProUpgradeButton>
            )}
          </div>

          {shouldShowSequenceActions && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="cut-included-tooltip" className="tooltip-peech-dark">
                  {sequence?.title}
                </Tooltip>
              }
            >
              <div
                contentEditable={!user?.UIFLAGS.SMART_LIBRARY}
                className="project-name"
                onBlur={onTitleBlur}
                onKeyPress={onProjectTitleKeyPress}
              >
                {sequence?.title || ''}
              </div>
            </OverlayTrigger>
          )}

          {isCreatorOnSequencePage && (
            <div className="changes-wrapper">
              <OverlayTrigger
                placement="right-end"
                overlay={
                  <Tooltip id="save-tooltip" style={{ fontSize: '12px' }} className="tooltip-peech-light">
                    <span className="triangle"></span>Autosave
                  </Tooltip>
                }
              >
                <div>
                  {saveState == 1 && (
                    <LottiePlayer className="loading-save" animationData={loading} autoplay={true} loop={false} />
                  )}
                  {saveState == 2 && (
                    <LottiePlayer className="done-save" animationData={save} autoplay={true} loop={false} />
                  )}
                </div>
              </OverlayTrigger>
            </div>
          )}
        </div>
        {!isMobileDevice && (
          <div className="top-header-center">
            {isCreatorOnSequencePage && (
              <>
                <a onClick={() => onTabClick(EDITORS.CONTENT)}>
                  <div className={contentEditorTabClassNames} data-cy={'content-editor'}>
                    Content Editor
                  </div>
                </a>{' '}
                |{' '}
                <a onClick={() => onTabClick(EDITORS.VISUALS)}>
                  <div className={visualsEditorTabClassNames} data-cy={'visuals-editor'}>
                    Visuals Editor
                  </div>
                </a>
              </>
            )}
            {trialNotificationOpen && !(isOnWizard || isOnSequencePage || isPlanPage) && (
              <div className="free-trial">
                {trialRest === 1 ? (
                  <div className="free-trial-text" onClick={redirectToPlans}>
                    Your free trial will end &nbsp;<strong>today</strong>, please update your payment method to continue
                    using Peech
                  </div>
                ) : (
                  <div className="free-trial-text" onClick={redirectToPlans}>
                    Your free trial will end in&nbsp;<strong>{trialRest} days</strong>, please update your payment
                    method to continue using Peech
                  </div>
                )}
                <div
                  className="free-trial-btn"
                  onClick={() => {
                    setTrialNotificationOpen(false);
                  }}
                >
                  <SVG id="btn-close-trial-notification" name="close" className="close" />
                </div>
              </div>
            )}
          </div>
        )}

        {!isMobileDevice && (
          <div className="top-header-right">
            {shouldShowRightHeaderActions && allowDuplicateProject ? (
              <StyledOptionsMenu
                options={sequenceCloneOptions}
                trigger={
                  <SequenceCloneOptionsTrigger>
                    <SVG name="dupe" />
                    <div>Duplicate</div>
                    <SVG name="arrow-down" />
                  </SequenceCloneOptionsTrigger>
                }
              />
            ) : null}

            {shouldShowRightHeaderActions && allowTranslateProject && (
              <SequenceTranslateButton onClick={onTranslateClick}>
                <SVG name="translate" />
                <div>Translate</div>
                {!translateAllowed && <UpgradeRequiredMark hide={translateAllowed} name="diamond-gradient" />}
              </SequenceTranslateButton>
            )}

            {shouldShowRightHeaderActions ? (
              <DownloadButton
                onClick={(e) => {
                  trackEvent('project-download', {
                    trigger: location.state?.editContent ? 'download-content-editor' : 'download-visual-editor',
                  });
                  openDownloadDialog(e);
                }}
                id="download-submit"
                data-cy="download-submit"
              />
            ) : null}
          </div>
        )}
      </div>
      {downloadLimitReachedDialogState?.isUpgradeModalOpen && (
        <Upgrade
          onHide={() =>
            DownloadLimitReachedDialogState.set((prevState) => ({ ...prevState, isUpgradeModalOpen: false }))
          }
          title={'You’ve reached the download limit!'}
          info={
            downloadLimitReachedDialogState?.data?.periodMaxDownloads
              ? `${downloadLimitReachedDialogState?.data?.periodMaxDownloads}/${downloadLimitReachedDialogState?.data?.periodMaxDownloads} video downloads a month`
              : ''
          }
          label={'Upgrade Now!'}
          onClickTrackEvent={() => trackEvent('download-limit-reached-cta-click')}
          text={'Upgrade your plan to download this video clip'}
        />
      )}
      {watermarkAlertModalDialogState && <WatermarkAlertModal />}
      <SupportModal show={support} onHide={() => supportState.set(false)} />
    </>
  );
}

Header.defaultProps = {
  size: 'lg',
  children: undefined,
};
