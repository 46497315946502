export interface CropCords {
  topx: number;
  topy: number;
  width: number;
  height: number;
}

export interface CropDataDetail {
  crop: CropCords;
  draw: CropCords;
}

const SVG_FILTER = `
<svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
  <filter id="boxBlur" x="0" y="0" width="100%" height="100%">
    <feGaussianBlur in="SourceGraphic" stdDeviation="50" />
  </filter>
</svg>
`;

const defaultCropData: CropDataDetail = {
  crop: { topx: 0, topy: 0, width: 1, height: 1 },
  draw: { topx: 0, topy: 0, width: 1, height: 1 },
};

export default class VideoToCanvasService {
  private context: CanvasRenderingContext2D;
  private video: HTMLVideoElement;
  private cropData: CropDataDetail;
  private svgFilterDataURL: string;
  private isBlur: boolean = false;
  private bgColor: string = "#000";
  private renderVideo: boolean = true;

  constructor(videoElement: HTMLVideoElement, canvasElement: HTMLCanvasElement) {
    this.context = canvasElement.getContext("2d")!;
    this.video = videoElement;
    this.cropData = defaultCropData;
    this.video.onloadeddata = () => this.render();
    this.video.onplay = () => this.redraw();
    this.video.onseeked = () => this.render();
    this.svgFilterDataURL = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(SVG_FILTER)}`;
  }

  protected clearCroppingData() {
    this.cropData = defaultCropData;
  }

  set croppingData(cropData: CropDataDetail | null) {
    if (!cropData) {
      this.clearCroppingData();
      return;
    }
    this.cropData = cropData;

    this.render();
  }

  set renderVideoLayer(value: boolean) {
    this.renderVideo = value;
    this.render();
  }

  get renderVideoLayer() {
    return this.renderVideo;
  }

  set blur(value: boolean) {
    this.isBlur = value;
    this.render();
  }

  get blur() {
    return this.isBlur;
  }

  set backgroundColor(value: string) {
    this.bgColor = value;
    this.render();
  }

  get backgroundColor() {
    return this.bgColor;
  }

  render() {
    this.redraw(true);
  }

  protected redraw = (force: boolean = false) => {
    if (!force && (this.video.paused || this.video.ended)) {
      return;
    }

    const { videoWidth, videoHeight } = this.video;

    const { width: canvasWidth, height: canvasHeight } = this.context.canvas;
    const { crop, draw } = this.cropData;

    const cropX = videoWidth * crop.topx;
    const cropY = videoHeight * crop.topy;
    const cropW = videoWidth * crop.width;
    const cropH = videoHeight * crop.height;
    const drawX = canvasWidth * draw.topx;
    const drawY = canvasHeight * draw.topy;
    const drawW = canvasWidth * draw.width;
    const drawH = canvasHeight * draw.height;

    // blur layer
    const blurScale = Math.max(canvasWidth / drawW, canvasHeight / drawH);
    const blurDrawW = drawW * blurScale;
    const blurDrawH = drawH * blurScale;
    const blurDrawX = (canvasWidth - blurDrawW) / 2;
    const blurDrawY = (canvasHeight - blurDrawH) / 2;

    // First, clear any existing drawings on the canvas
    this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);
    // Now, let's draw a rectangle covering whole canvas
    this.context.fillStyle = this.isBlur ? "#fff" : this.backgroundColor;
    this.context.fillRect(0, 0, this.context.canvas.width, this.context.canvas.height);

    if (this.isBlur) {
      // Save the current state of the context
      this.context.save();
      this.context.filter = `url(${this.svgFilterDataURL}#boxBlur)`;
      // blur layer
      this.context.drawImage(this.video, cropX, cropY, cropW, cropH, blurDrawX, blurDrawY, blurDrawW, blurDrawH);
      this.context.restore();
    }

    // draw video current frame on canvas
    if (this.renderVideo) {
      this.context.drawImage(this.video, cropX, cropY, cropW, cropH, drawX, drawY, drawW, drawH);
    }

    requestAnimationFrame(() => this.redraw());
  };
}
