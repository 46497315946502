import classNames from 'classnames';
import React from 'react';
import { Col } from 'react-bootstrap';
import SVG from '../../components/svg';
import { useClickOutsideEvent } from '../../hooks/useClickOutsideEvent';
import CreateModal from './CreateModal';
import DeleteConfirmation from '../DeleteConfirmation';
import OptionsMenuPopover from '../OptionsMenu/OptionsMenuPopover';
import './Folder.scss';
import Loader from '../Loader';

interface FolderProps {
  item: Object;
  allItems: Object[];
  isModal: any;
  onFolderClick: Function;
  onDropActions: Function;
  subTitle?: string;
  verifyFolderDeleteSid: any;
  setVerifyFolderDeleteSid: Function;
  deleteFolder: Function;
  verifyFolderRenameSid: any;
  setVerifyFolderRenameSid: Function;
  innerRef: React.MutableRefObject<HTMLInputElement>;
  renameFolder: Function;
  disabled?: Boolean;
  currentFolderSid: string;
  deleteText: string;
  deleteQuestion: string;
  sidDragged: string;
  containerRef: Element;
  scrollingContainerRef?: React.MutableRefObject<HTMLElement>;
  hoveredFolder: string;
  setHoveredFolder: (sid: string) => void;
  showLoader?: boolean;
}

export default function Folder({
  item,
  isModal,
  onFolderClick,
  onDropActions,
  subTitle,
  verifyFolderDeleteSid,
  setVerifyFolderDeleteSid,
  deleteFolder,
  verifyFolderRenameSid,
  setVerifyFolderRenameSid,
  renameFolder,
  innerRef,
  disabled,
  currentFolderSid,
  deleteText,
  deleteQuestion,
  allItems,
  sidDragged,
  scrollingContainerRef,
  hoveredFolder,
  showLoader,
  setHoveredFolder,
}: FolderProps) {
  const [shouldShowOptions, setShouldShowOptions] = React.useState<boolean>();
  const optionMenuRef = React.useRef();
  const folderRef = React.useRef();

  const closeOptionsMenu = () => setShouldShowOptions(false);
  useClickOutsideEvent(optionMenuRef, closeOptionsMenu);

  const [isHoveredrWithItem, setIsHoveredWithItem] = React.useState<string>();
  const folderPic = (sid) =>
    classNames('folder-pic', {
      current: sid === currentFolderSid,
      hover: isHoveredrWithItem && hoveredFolder === item.sid,
    });

  const createFolderActions = (item) => [
    {
      onClick: () => {
        setVerifyFolderDeleteSid(item.sid);
        setShouldShowOptions(false);
      },
      key: 'delete',
      icon: 'delete',
      text: 'Delete',
      toolTip: 'Delete Folder',
    },
    {
      onClick: () => {
        setVerifyFolderRenameSid(item.sid);
        setShouldShowOptions(false);
      },
      key: 'edit',
      icon: 'edit',
      text: 'Rename',
      toolTip: 'Rename Folder',
    },
  ];

  const dragOverHandler = (e) => {
    e.preventDefault();
  };

  const dragEnterHandler = (e, allItems, sidDragged) => {
    e.preventDefault();
    setIsHoveredWithItem(true);
    setHoveredFolder(item.sid);
  };

  const dragLeaveHandler = (e) => {
    e.preventDefault();
    if (folderRef.current) {
      const top = folderRef.current.offsetTop;
      const bottom = top + folderRef.current.offsetHeight;
      const left = folderRef.current.offsetLeft;
      const right = folderRef.current.offsetRight;
      const { offsetTop, offsetHeight, offsetLeft, offsetRight } = e.target;
      const shouldLeave =
        offsetTop <= top || offsetTop + offsetHeight >= bottom || offsetLeft <= left || offsetRight >= right;
      if (shouldLeave) {
        setIsHoveredWithItem(false);
        setHoveredFolder(null);
      }
    }
  };

  const dropHandler = (e) => {
    e.preventDefault();
    onDropActions(item.sid, allItems, sidDragged);
    setIsHoveredWithItem(false);
  };

  return (
    <Col xs="12" md="6" lg={!isModal ? '4' : '6'} xl={!isModal ? '3' : '4'} className="folder-block">
      <div
        className={folderPic(item.sid)}
        onClick={onFolderClick}
        onDragOver={dragOverHandler}
        onDragEnter={dragEnterHandler}
        onDragLeave={dragLeaveHandler}
        onDrop={dropHandler}
        ref={folderRef}
      >
        { showLoader ? <Loader size='xs' />  :
          <SVG name="library" className="library" />
        }
        <div className="folder-name">
          <div className="name">{item.name}</div>
          <div className="subTitle">
            {subTitle || ""}
          </div>
        </div>
        <div className="delete">
          {verifyFolderDeleteSid === item.sid && (
            <DeleteConfirmation
              onHide={() => setVerifyFolderDeleteSid(false)}
              onApproval={() => deleteFolder(item.sid)}
              deleteText={deleteText}
              question={deleteQuestion}
            />
          )}
          {verifyFolderRenameSid === item.sid && (
            <CreateModal
              showModal={!!verifyFolderRenameSid}
              setShowModal={setVerifyFolderRenameSid}
              modalTitle="Enter new folder name"
              innerRef={innerRef}
              addButton="Rename"
              createItem={renameFolder}
              svgName={'library'}
              disabled={disabled}
              defaultValue={item.name}
            />
          )}
          {!isModal && (
            <div
              className="options-button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <OptionsMenuPopover
                options={createFolderActions(item)}
                scrollingContainerRef={scrollingContainerRef}
              />
            </div>
          )}
        </div>
      </div>
    </Col>
  );
}
