
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export interface IColor extends IBase {
	color?: string | null;
	rgb?: number[] | null;
	brightness?: number | null;
	brightnessIndex?: number | null;
	dominancy?: number | null;
	grayness?: number | null;
	percent?: number | null;
}

export interface IColorProperties extends IBaseProperties {
	color?: boolean;
	rgb?: boolean;
	brightness?: boolean;
	brightnessIndex?: boolean;
	dominancy?: boolean;
	grayness?: boolean;
	percent?: boolean;
}

export interface IColorFilter extends IBaseFilter {
}

export class Color<IType extends IColor = IColor, ITypeProperties extends IColorProperties = IColorProperties> extends Base<IType, ITypeProperties> implements IColor {
	
	static className = 'Color';
	static NAME = 'color';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		color: true,
		rgb: true,
		brightness: true,
		brightnessIndex: true,
		dominancy: true,
		grayness: true,
		percent: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get color(): string | null | undefined {
		return this.values.color
	}
			
	set color(value: string | null | undefined) {
		if(this.values.color !== value) {
			this.propertyChanged('color', value);
			this.values.color = value;
		}
	}
			
	get rgb(): number[] | null | undefined {
		return this.values.rgb
	}
			
	get brightness(): number | null | undefined {
		return this.values.brightness
	}
			
	get brightnessIndex(): number | null | undefined {
		return this.values.brightnessIndex
	}
			
	get dominancy(): number | null | undefined {
		return this.values.dominancy
	}
			
	set dominancy(value: number | null | undefined) {
		if(this.values.dominancy !== value) {
			this.propertyChanged('dominancy', value);
			this.values.dominancy = value;
		}
	}
			
	get grayness(): number | null | undefined {
		return this.values.grayness
	}
			
	set grayness(value: number | null | undefined) {
		if(this.values.grayness !== value) {
			this.propertyChanged('grayness', value);
			this.values.grayness = value;
		}
	}
			
	get percent(): number | null | undefined {
		return this.values.percent
	}
			
	set percent(value: number | null | undefined) {
		if(this.values.percent !== value) {
			this.propertyChanged('percent', value);
			this.values.percent = value;
		}
	}
			
	
	static get<IType extends IColor = IColor>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IColorFilter = IColorFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IColorFilter = IColorFilter, IType extends IColor = IColor>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
