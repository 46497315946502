import React from 'react';
import './BrandColors.scss';
import ColorPalette from '../ColorPalette/ColorPalette';
import Color from '../../client/color';

import Preset from '../../client/preset';
import { numbersToColor } from '../../client/sequence/logo';
import { modifyPreset, savePreset } from '../../state/local';
import { handleError } from '../../state/error';
import { uniqueSid } from '../../state/cuePoints';
import { AppContext } from '../../lib/app-context';
import { trackEvent } from '../../utils/analityics.utils';
import { getKeyByValue } from '../../utils/objects.utils';

interface BrandColorsProps {
    preset: Preset
    setPreset: Function
    isNew?: boolean
}

const COLOR_ARRAY = {
    MAIN: 0,
    SECONDARY: 1
}


export default function BrandColors({ preset, setPreset, secondaryColors, isNew }: BrandColorsProps) {
    const { user, setUser } = React.useContext(AppContext)
    
    async function removeColor(colorArrayType) {
        trackEvent('brand-kit-delete-color', { type: getKeyByValue(COLOR_ARRAY, colorArrayType), isInWizard: !!isNew});
        if (isNew) {
            preset.secondaryColors = [...preset.secondaryColors.filter(c => c.color)]
            return setPreset(preset)
        }
        try {
            await savePreset(preset, { user, setUser })
        }
        catch (err) {
            handleError({
                title: 'Deleting color',
                message: err.message,
                responseError: err
            })
        }
    }

    async function onChange(colorArrayType, color, prevColor, index) {
        console.log("onChange", { colorArrayType, color, index });
        if (colorArrayType === COLOR_ARRAY.MAIN) {
            preset.colors[index].color = color.hex || numbersToColor(Object.values(color.rgb))
        } else if (colorArrayType === COLOR_ARRAY.SECONDARY) {
            preset.secondaryColors[index].color = color.hex || numbersToColor(Object.values(color.rgb))
        }
    }

    async function onNewColor(colorArrayType, color, index, secondaryColor) {
        console.log("onNewColor", { colorArrayType, color, index })
        var obj = new Color()
        isNew && (obj.cid = `temp-${uniqueSid()}`)
        obj.dominancy = 1
        obj.color = numbersToColor(Object.values(color.rgb)) || color.hex
        obj.percent = color.percent

        preset.secondaryColors = [...(preset.secondaryColors || []), obj];

        trackEvent('brand-kit-set-color', { type: getKeyByValue(COLOR_ARRAY, colorArrayType), isInWizard: !!isNew});
        if (isNew) {            
            return setPreset(preset)
        }
        const newPreset = await preset.save()
        
        modifyPreset(preset)
       
        return newPreset
    }

    async function onChangeComplete(colorArrayType) {
        trackEvent('brand-kit-change-color', { type: getKeyByValue(COLOR_ARRAY, colorArrayType), isInWizard: !!isNew});
        if (isNew) {
            return setPreset(preset)
        }
        let newPreset;
        try {
            newPreset = await preset.save()
            modifyPreset(newPreset)
        } catch (err) {
            handleError({
                title: 'Oops, color change failed',
                message: err.message,
                responseError: err
            })
        }
       
        return newPreset;
    }

    return <div data-cy="brand-colors" className="brand-colors">
        {!preset.colors?.length ? <h3 className="upload-logo-first"> Please upload logo first.</h3> :
            <div className="colors-palette">
                <div className="peech-color-block-branding">
                    <h4 className="logo-title" >Main Colors Palette</h4>
                    <ColorPalette editable={true}
                        colors={preset.colors || []}
                        onChange={(color, prevColor, index) => onChange(COLOR_ARRAY.MAIN, color, prevColor, index)} onChangeComplete={() =>  onChangeComplete(COLOR_ARRAY.MAIN)} showColorName={true} onNewColor={(color, index) => onNewColor(COLOR_ARRAY.MAIN, color, index, false)} removeColor={() => removeColor(COLOR_ARRAY.MAIN)} canBeDeleted={false} />
                </div>
                <div className="secondary-colors">
                    <h4 className="logo-title" >Secondary Colors Palette</h4>
                    <ColorPalette editable={true} colors={preset.secondaryColors}
                        onChange={(color, prevColor, index) => onChange(COLOR_ARRAY.SECONDARY, color, prevColor, index)} onChangeComplete={() =>  onChangeComplete(COLOR_ARRAY.SECONDARY)} allowNew={true} onNewColor={(color, index) => onNewColor(COLOR_ARRAY.SECONDARY, color, index, true)} showColorName={true} removeColor={() => removeColor(COLOR_ARRAY.SECONDARY)} canBeDeleted={true} />
                </div>

                <div className="wrapper-neutral-colors">
                    <h4 className="logo-title">Neutral Colors Palette</h4>
                    <div className="neutral-colors">
                        <div className="single-color">
                            <div className="peech-color-block -white" />
                            <div className="peech-block-title">#FFFFFF</div>

                        </div>
                        <div className="single-color">
                            <div className="peech-color-block -black" />
                            <div className="peech-block-title">#000000</div>
                        </div>
                    </div>
                </div>
            </div>}
    </div>
}