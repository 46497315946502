import React from 'react';




export function decodeHtml(html) {
	var txt = document.createElement("textarea");
	txt.innerHTML = html.replace(/<br\/?>/g, '\n');
	return txt.value;
}

const LINE_HEIGHT = 0.6;
const CHAR_WIDTH = 1.6;
const WIDTH_FACTOR = 0.62;
const LOTTIE_WIDTH_FACTOR = 1.24;
const LOTTIE_LINE_HEIGHT = 0.65;

/**
 * @param {HTMLElement} element 
 * @param {string} text 
 * @returns {number}
 */
export function fitTextToSize(width, height, text) {
	if (!text) {
		return null;
	}

	var maxFontSize = 300.0
	var minFontSize = 10.0

	var lines = text.split('\n');
	var length = Math.max(...lines.map(l => l.length))

	return Math.max(
		Math.min(
			height / lines.length * LINE_HEIGHT,
			width / length * CHAR_WIDTH,
			maxFontSize
		),
		minFontSize
	)
}

/**
 * @param {HTMLElement} element 
 * @param {string} text 
 * @returns {number}
 */
export function fitTextToElement(element, text, padding = 0) {
	if (!element) {
		return null;
	}

	var width = element.offsetWidth - padding
	var height = element.parentElement.offsetHeight - padding
	return fitTextToSize(width, height, text);
}

/**
 * @param {string} text 
 * @param {number?} maxLineLength
 * @returns {string}
 */
export function splitText(text, maxLineLength = 20) {
	return text
		.trim()
		.split(' ')
		.reduce((lines, word) => {
			const index = lines.length - 1;
			if (lines[index].length + word.length + 1 > maxLineLength) {
				lines.push(word)
			}
			else {
				lines[index] += ' ' + word;
			}
			return lines;
		}, [''])
		.join('\n')
}

/**
 * @param {number} fontSize 
 * @param {string} text 
 * @returns {{width: number, height: number}}
 */
export function fitSizeToText(fontSize, text) {
	var lines = text.split('\n');
	var length = Math.max(...lines.map(l => l.length))
	var longestLine = lines.find(l => l.length === length)
	var spaces = longestLine.split(/[^\s]+/).length - 2
	length -= spaces;


	const element = document.createElement("div")
	element.style.fontSize = fontSize + 'px'
	element.style.width = 'fit-content'
	element.style.visibility = 'hidden'
	element.innerText = longestLine
	const parentElement = document.getElementById("main-graphics");
	parentElement.appendChild(element)
	const width = element.offsetWidth + fontSize
	element.remove()

	return {
		width: width * WIDTH_FACTOR,
		height: fontSize / LINE_HEIGHT * lines.length,
	}
}

/**
 * @param {HTMLElement} element 
 * @param {string} text 
* @returns {{width: number, height: number}}
 */
function fitElementToText(element, text) {
	var fontSize = parseInt(window.getComputedStyle(element).getPropertyValue('font-size'));
	return fitSizeToText(fontSize, text);
}

export default class EditableTextItem extends React.Component {
	constructor(props) {
		super(props);

		/**
		 * @type {React.RefObject<HTMLImageElement>}
		 */
		this.ref = React.createRef();
	}

	onKeyUp(e) {
		const text = e.target.innerText
		const cuePoint = this.props.cuePoint;
		if (text.length && !this.props.shouldNotUpdateText) {
			cuePoint.tmpText = decodeHtml(text);
		} else {
			delete cuePoint.tmpText;
		}

		this.props.onKeyUp && this.props.onKeyUp(e);
	}

	onFocus(e) {
		this.props.onFocus && this.props.onFocus(e);
		this.disableUpdate = true;
	}

	onBlur(e) {
		this.disableUpdate = false;
		this.props.onBlur && this.props.onBlur(e);
		if (this.ref.current?.style.fontSize) {
			this.ref.current.style.removeProperty('fontSize');
		}
	}

	fitText(text) {
		if (!this.ref.current || !text) {
			return
		}

		const fontSize = fitTextToElement(this.ref.current, text);
		this.ref.current.style.fontSize = fontSize + 'px';
		this.props.onFontSizeChange(fontSize);
	}

	fitSize(text) {
		if (!this.ref.current || !text) {
			return
		}

		const { width, height } = fitElementToText(this.ref.current, text);
		this.props.onSizeChange(width, height);
	}

	shouldComponentUpdate(nextProps) {
		if (this.disableUpdate && this.props.html !== nextProps.html) {
			const text = decodeHtml(nextProps.html);
			this.props.onSizeChange && this.fitSize(text);
			this.props.onFontSizeChange && this.fitText(text);
		}
		return !this.disableUpdate;
	}

	componentDidUpdate() {
		if (!this.props.onAlt || !this.ref || !this.ref.current) {
			return;
		}

		const div = this.ref.current;

		const alts = div.getElementsByClassName('alt');
		for (const alt of alts) {
			if (!alt.dataset.listened) {
				alt.dataset.listened = true;
				alt.addEventListener('mouseover', this.props.onAlt);
			}
		}
	}

	onRef(current) {
		if (this.props.innerRef) {
			if (typeof this.props.innerRef === 'function') {
				this.props.innerRef(current)
			}
			else {
				this.props.innerRef.current = current;
			}
		}
		this.ref.current = current;
	}

	render() {
		const {
			cuePoint,
			onKeyUp,
			onFocus,
			onBlur,
			text,
			html,
			innerRef,
			disabled,
			onFontSizeChange,
			onSizeChange,
			shouldNotUpdateText,
			style,
			...rest
		} = this.props;

		return <div
			{...rest}
			ref={current => this.onRef(current)}
			contentEditable={!disabled}
			dangerouslySetInnerHTML={{ __html: html }}
			style={{ ...style, position: 'unset' }}
			onKeyUp={e => this.onKeyUp(e)}
			onFocus={e => this.onFocus(e)}
			onBlur={e => this.onBlur(e)}
			id={`cue-point-${cuePoint.sid}`}
		/>
	}
}
