import styled from "@emotion/styled/macro"
import React, { ReactNode } from 'react'
import { COLORS } from "../../../constants/colors"

export const Explanation = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity;
    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(.55,.1,.41,.83);   
`

const ExItemText = styled.div`
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: ${COLORS.peech_secondary_500};
    width: 101px;
    margin-bottom: 12px;
`

const ExItemIcons = styled.div`
    height: 20px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0px;
    svg {
        margin: 0 4px;
    }

`



interface RatioExplanationProps {
    text: string;
    iconsArray: ReactNode[];
}



export default function RatioExplanation({ text, iconsArray }: RatioExplanationProps) {

    return (
        <Explanation>
            <ExItemText>
                {text}
            </ExItemText>
            <ExItemIcons>
                {iconsArray?.map((item, index) => {
                    return (
                        <span key={index}>
                            {item}
                        </span>)
                })}
            </ExItemIcons>
        </Explanation>
    )
}

