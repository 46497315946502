import { IUser } from '../client/user';
import useUserPs from '../hooks/useUserPs';
import { IConfig } from '../lib/app-context';

export const DEFAULT_FONT = 'Assistant';

export const getAssetFamilyName = (assetSid: string, fileName: string) => {
  return `User-Font-${assetSid}-${fileName}`.replace('.', '-');
};

export const loadAssetFont = (assetSid: string, fileName: string, user: IUser, config: IConfig) => {
  return new Promise((resolve) => {
    const fontName = `User-Font-${assetSid}-${fileName}`.replace('.', '-');
    const withUserPs = useUserPs();
    const url = withUserPs(`${config.CHUNKS_URL}/t/asset/f/${assetSid}/${fileName}/${user?.cacheVersion || 0}-fontFile`);
    const encodeUrl = url.replace(/[ ]/g, "%20");
    console.log('Loading font', { assetSid, fileName, url, encodeUrl });
    let font_file = new FontFace(fontName, `url(${encodeUrl})`);
    font_file
      .load()
      .then((payload) => {
        document.fonts.add(payload);
        resolve(true);
        console.log('font assetSid loaded successfully - ', fontName, payload);
      })
      .catch((err) => {
        console.error(err, { assetSid, fileName, url, encodeUrl });
        resolve(false);
      });
  });
};

export const loadWebFonts = (fontsObj) => {
  return new Promise((resolve) => {
    console.log(
      'loading google fonts',
      Object.keys(fontsObj).map((font) => `${font}:${['b', ...fontsObj[font]].join(',')}`)
    );

    // eslint-disable-next-line no-param-reassign
    import('webfontloader').then((webFont) => {
      const timeout = setTimeout(() => {
        console.error('font failed to load - ', {
          fontsObj,
          string: Object.keys(fontsObj).map((font) => `${font}:${['b', ...fontsObj[font]].join(',')}`),
        });
        resolve();
      }, 4000);
      webFont.typekit = { id: 'psj0mst' };
      webFont.load({
        google: {
          families: [...Object.keys(fontsObj).map((font) => `${font}:${['b', ...fontsObj[font]].join(',')}`)],
        },
        active: function () {
          clearTimeout(timeout);
          console.log('font loaded successfully - ', fontsObj);
          resolve();
        },
      });
    });
  });
};
