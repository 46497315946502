import styled from "@emotion/styled/macro"
import React from 'react'
import { COLORS } from "../../../constants/colors"
import SVG from "../../components/svg"


const StepsContainer = styled.div<{ stepsAmount: number }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: ${({ stepsAmount }): string => (stepsAmount === 3 ? '270px' : stepsAmount === 2 ? '160px' : '0px')};
    height: 20px;
    position: fixed;
    top: calc(73px / 2 - 10px);
    left: ${({ stepsAmount }): string => (stepsAmount === 3 ? 'calc(50vw - 150px)' : stepsAmount === 2 ? 'calc(50vw - 88px)' : '0px')};
    z-index: 2;
`

const Step = styled.div<{ isActive: boolean }>`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: ${({ isActive }): string => (isActive ? '700' : '400')};
    font-size: 14px;
    line-height: 20px;
    color: ${({ isActive }): string => (isActive ? COLORS.peech_secondary_500 : COLORS.peech_gray_400)};
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const Divider = styled.div`
    margin-left: 16px;
    transform: rotate(270deg);
    svg {
        width: 24px;
    }
`


interface CreateProjectStepperProps {
    createFlowStep: number;
    stepsDictionary: Object[];
}



export default function CreateProjectStepper({ createFlowStep, stepsDictionary }: CreateProjectStepperProps) {

    const [steps, setSteps] = React.useState([]);

    React.useEffect(() => {
        let arr = [];
        stepsDictionary.filter(item => {
            if (item.name) return item;
        }).map(item => item.name).forEach(item => {
            let x = arr.find(i => i === item);
            if (!x) arr.push(item);
        });
        setSteps(arr);

    }, [stepsDictionary]);

    const isStepActive = (stepName: string) => {       
        return stepsDictionary[createFlowStep].name === stepName;
    }

    return (
        <StepsContainer stepsAmount={steps?.length}>
            {steps?.map((item, index) => {
                return (
                    <Step key={index} isActive={isStepActive(item)} >
                        <span >{item}</span>
                        {index !== steps.length - 1
                            &&
                            <Divider>
                                <SVG name="dropdown" />
                            </Divider>}
                    </Step>
                )
            })}
        </StepsContainer>
    )
}