
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'

export interface IWord extends IBase {
	chapterSid?: string | null;
	word?: string | null;
	origin?: string | null;
	index?: number | null;
	active?: boolean | null;
	startTime?: number | null;
	endTime?: number | null;
	originStartTime?: number | null;
	originEndTime?: number | null;
	newLine?: boolean | null;
	alt?: boolean | null;
	score?: number | null;
	excluded?: boolean | null;
	manualTiming?: boolean | null;
	highlight?: boolean | null;
	fontSize?: number | null;
	fontWeight?: string | null;
	fontItalic?: boolean | null;
	color?: string | null;
	colorIndex?: number | null;
	flags?: number | null;
	positionX?: number | null;
	positionY?: number | null;
}

export interface IWordProperties extends IBaseProperties {
	chapterSid?: boolean;
	word?: boolean;
	origin?: boolean;
	index?: boolean;
	active?: boolean;
	startTime?: boolean;
	endTime?: boolean;
	originStartTime?: boolean;
	originEndTime?: boolean;
	newLine?: boolean;
	alt?: boolean;
	score?: boolean;
	excluded?: boolean;
	manualTiming?: boolean;
	highlight?: boolean;
	fontSize?: boolean;
	fontWeight?: boolean;
	fontItalic?: boolean;
	color?: boolean;
	colorIndex?: boolean;
	flags?: boolean;
	positionX?: boolean;
	positionY?: boolean;
}

export interface IWordFilter extends IBaseFilter {
}

export class Word<IType extends IWord = IWord, ITypeProperties extends IWordProperties = IWordProperties> extends Base<IType, ITypeProperties> implements IWord {
	
	static className = 'Word';
	static NAME = 'word';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		chapterSid: true,
		word: true,
		startTime: true,
		endTime: true,
		newLine: true,
		alt: true,
		score: true,
		excluded: true,
		manualTiming: true,
		highlight: true,
		fontSize: true,
		fontWeight: true,
		fontItalic: true,
		color: true,
		colorIndex: true,
		flags: true,
		positionX: true,
		positionY: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get chapterSid(): string | null | undefined {
		return this.values.chapterSid
	}
			
	set chapterSid(value: string | null | undefined) {
		if(this.values.chapterSid !== value) {
			this.propertyChanged('chapterSid', value);
			this.values.chapterSid = value;
		}
	}
			
	get word(): string | null | undefined {
		return this.values.word
	}
			
	set word(value: string | null | undefined) {
		if(this.values.word !== value) {
			this.propertyChanged('word', value);
			this.values.word = value;
		}
	}
			
	set origin(value: string | null | undefined) {
		if(this.values.origin !== value) {
			this.propertyChanged('origin', value);
			this.values.origin = value;
		}
	}
			
	set index(value: number | null | undefined) {
		if(this.values.index !== value) {
			this.propertyChanged('index', value);
			this.values.index = value;
		}
	}
			
	set active(value: boolean | null | undefined) {
		if(this.values.active !== value) {
			this.propertyChanged('active', value);
			this.values.active = value;
		}
	}
			
	get startTime(): number | null | undefined {
		return this.values.startTime
	}
			
	set startTime(value: number | null | undefined) {
		if(this.values.startTime !== value) {
			this.propertyChanged('startTime', value);
			this.values.startTime = value;
		}
	}
			
	get endTime(): number | null | undefined {
		return this.values.endTime
	}
			
	set endTime(value: number | null | undefined) {
		if(this.values.endTime !== value) {
			this.propertyChanged('endTime', value);
			this.values.endTime = value;
		}
	}
			
	set originStartTime(value: number | null | undefined) {
		if(this.values.originStartTime !== value) {
			this.propertyChanged('originStartTime', value);
			this.values.originStartTime = value;
		}
	}
			
	set originEndTime(value: number | null | undefined) {
		if(this.values.originEndTime !== value) {
			this.propertyChanged('originEndTime', value);
			this.values.originEndTime = value;
		}
	}
			
	get newLine(): boolean | null | undefined {
		return this.values.newLine
	}
			
	set newLine(value: boolean | null | undefined) {
		if(this.values.newLine !== value) {
			this.propertyChanged('newLine', value);
			this.values.newLine = value;
		}
	}
			
	get alt(): boolean | null | undefined {
		return this.values.alt
	}
			
	set alt(value: boolean | null | undefined) {
		if(this.values.alt !== value) {
			this.propertyChanged('alt', value);
			this.values.alt = value;
		}
	}
			
	get score(): number | null | undefined {
		return this.values.score
	}
			
	set score(value: number | null | undefined) {
		if(this.values.score !== value) {
			this.propertyChanged('score', value);
			this.values.score = value;
		}
	}
			
	get excluded(): boolean | null | undefined {
		return this.values.excluded
	}
			
	set excluded(value: boolean | null | undefined) {
		if(this.values.excluded !== value) {
			this.propertyChanged('excluded', value);
			this.values.excluded = value;
		}
	}
			
	get manualTiming(): boolean | null | undefined {
		return this.values.manualTiming
	}
			
	set manualTiming(value: boolean | null | undefined) {
		if(this.values.manualTiming !== value) {
			this.propertyChanged('manualTiming', value);
			this.values.manualTiming = value;
		}
	}
			
	get highlight(): boolean | null | undefined {
		return this.values.highlight
	}
			
	set highlight(value: boolean | null | undefined) {
		if(this.values.highlight !== value) {
			this.propertyChanged('highlight', value);
			this.values.highlight = value;
		}
	}
			
	get fontSize(): number | null | undefined {
		return this.values.fontSize
	}
			
	set fontSize(value: number | null | undefined) {
		if(this.values.fontSize !== value) {
			this.propertyChanged('fontSize', value);
			this.values.fontSize = value;
		}
	}
			
	get fontWeight(): string | null | undefined {
		return this.values.fontWeight
	}
			
	set fontWeight(value: string | null | undefined) {
		if(this.values.fontWeight !== value) {
			this.propertyChanged('fontWeight', value);
			this.values.fontWeight = value;
		}
	}
			
	get fontItalic(): boolean | null | undefined {
		return this.values.fontItalic
	}
			
	set fontItalic(value: boolean | null | undefined) {
		if(this.values.fontItalic !== value) {
			this.propertyChanged('fontItalic', value);
			this.values.fontItalic = value;
		}
	}
			
	get color(): string | null | undefined {
		return this.values.color
	}
			
	set color(value: string | null | undefined) {
		if(this.values.color !== value) {
			this.propertyChanged('color', value);
			this.values.color = value;
		}
	}
			
	get colorIndex(): number | null | undefined {
		return this.values.colorIndex
	}
			
	set colorIndex(value: number | null | undefined) {
		if(this.values.colorIndex !== value) {
			this.propertyChanged('colorIndex', value);
			this.values.colorIndex = value;
		}
	}
			
	get flags(): number | null | undefined {
		return this.values.flags
	}
			
	set flags(value: number | null | undefined) {
		if(this.values.flags !== value) {
			this.propertyChanged('flags', value);
			this.values.flags = value;
		}
	}
			
	get positionX(): number | null | undefined {
		return this.values.positionX
	}
			
	set positionX(value: number | null | undefined) {
		if(this.values.positionX !== value) {
			this.propertyChanged('positionX', value);
			this.values.positionX = value;
		}
	}
			
	get positionY(): number | null | undefined {
		return this.values.positionY
	}
			
	set positionY(value: number | null | undefined) {
		if(this.values.positionY !== value) {
			this.propertyChanged('positionY', value);
			this.values.positionY = value;
		}
	}
			
	
	static get<IType extends IWord = IWord>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IWordFilter = IWordFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IWordFilter = IWordFilter, IType extends IWord = IWord>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
