import React from 'react';
import Modal from 'react-bootstrap/esm/Modal';

const terms = { __html: require('../../../static/content/terms.html') };
const privacy = { __html: require('../../../static/content/privacy.html') };
type StaticDocProps = {
    setShowTerms(boolean: boolean): void;
    showTerms?: boolean
    setShowPrivacy(boolean: boolean): void;
    showPrivacy?: boolean
};

function StaticDoc({ setShowTerms, showTerms = false, setShowPrivacy, showPrivacy = false }: StaticDocProps) {


    function handleHide() {
        if (showTerms) {
            setShowTerms(false)
        } else {
            setShowPrivacy(false)
        }
    }
    if (showTerms || showPrivacy) {

        return (
            <>
                <Modal
                    show={true}
                    onHide={handleHide}
                    className="terms-dialog"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body className="terms-dialog-body">
                        <Modal.Title>{showTerms ? 'Terms of service' : 'Privacy policy'}</Modal.Title>
                        {showTerms && <div dangerouslySetInnerHTML={terms}></div>}
                        {showPrivacy && <div dangerouslySetInnerHTML={privacy}></div>}
                    </Modal.Body>
                </Modal>
            </>
        )
    }
    return null
}

export default StaticDoc;
