import React from 'react';
import { LabelDiv, Stack, UnderLinedSection } from '../../ui-components/Menu/MenuComponents.styled';
import HorizontalSlider from '../Slider/HorizontalSlider';
import { RangeLabels, ScrollerWrapper } from './IntroAndOutro.styled';
import { isNil } from 'lodash/fp';

interface DurationControllerProperties {
  duration: any;
  onDurationChange: (value: number) => void;
  hide?: boolean;
  disabled: boolean;
  min?: number;
  max?: number;
}

export default function DurationController({
  duration,
  onDurationChange,
  hide = false,
  disabled,
  min,
  max
}: DurationControllerProperties) {
  
  const onChange = (value: number) => {
    onDurationChange(value);
  };

  return (
    <UnderLinedSection hide={hide}>
      <LabelDiv>Duration</LabelDiv>
      <Stack spacing={4}>
        <ScrollerWrapper>
          <HorizontalSlider min={min} max={max} value={duration} onChange={onChange} step={0.5} disabled={disabled}/>
        </ScrollerWrapper>
      </Stack>
      <Stack spacing={4}>
        <RangeLabels>
          {!isNil(min) && <span>{`${min} s`}</span>}
          {!isNil(max) && <span>{`${max} s`}</span>}
        </RangeLabels>
      </Stack>
    </UnderLinedSection>
  );
}
