import _ from 'lodash'
import { entity } from 'simpler-state'
import Preset from '../client/preset'
import Shopper from '../client/shopper'
import { WIZARDS } from '../pages/create'

export const fallBackUrlState = entity(null)
export const shouldLoginState = entity(false)
export const shouldLogoutState = entity(false)

export const loadedSequencesState = entity([])
export const sequencesSinceLastChargeState = entity(0)
export const sequencesTotalCountState = entity(0);
export const sequencesSharedCountState = entity(0);
export const loadedAssetsState = entity([])
export const loadedPresetsState = entity(null)

export const savingModesState = entity({})
export const DownloadDialogState = entity(false)
export const DownloadLimitReachedDialogState = entity({isUpgradeModalOpen: false, isDownloadAllowed: false, data: {}})
export const WatermarkAlertModalDialogState = entity(false)

export const shopperState = entity(new Shopper())


export const brandkitMissingModalState = entity(false);
export const brandkitMissingLogoState = entity(false);
export const projectsLimitReachedState = entity(false);
export const trialEndReachedState = entity(false);
export const trialModalOpenState = entity(false);
export const trialRestState = entity(0);

export const footagesState = entity({});
export const footagesProcessing  = entity(false);

export const isInPreviewState = entity(true);

export const allowRegenerateCcState = entity(true);












export function setSavingState(mode) {
    savingModesState.set(mode)
}

export function prependSequences(...items) {
    loadedSequencesState.set(sequences => _.uniqBy([...items, ...sequences], 'sid'));
}


export function appendSequences(...items) {
    loadedSequencesState.set(sequences => _.uniqBy([...sequences, ...items], 'sid'));
}

export function unloadSequence(sid) {
    loadedSequencesState.set(sequences => sequences.filter(sequence => sequence.sid !== sid))
}

export function updateLoadedSequence(sid, duration, status, languageCode) {
    const sequences = loadedSequencesState.get()
    const sequence = sequences.find(sequence => sequence.sid === sid)
    if (sequence) {
        sequence.values.duration = duration || sequence.duration
        sequence.values.status = status || sequence.status
        sequence.values.languageCode = languageCode || sequence.languageCode
        modifySequence(sequence)
    }

}

export function modifySequence(sequence, shouldAppendNew = true) {
    loadedSequencesState.set(sequences => {
        const sequenceIndex = sequences.findIndex(s => s.sid === sequence.sid)
        if (sequenceIndex >= 0) {
            if (shouldAppendNew) {
                sequences = sequences.filter(s => s.sid !== sequence.sid)
                sequences.splice(sequenceIndex, 0, sequence)
            } else {
                sequences[sequenceIndex] = sequence
            }
        }
        return sequenceIndex >= 0 ? sequences : [sequence, ...sequences || []]
    })
}

export function prependAssets(...items) {
    loadedAssetsState.set(assets => assets.length ? _.uniqBy([...items, ...assets], 'sid') : []);
}

export function appendAssets(...items) {
    loadedAssetsState.set(assets => _.uniqBy([...assets, ...items], 'sid'));
}

export function unshiftAssets(...items) {
    loadedAssetsState.set(assets =>  _.uniqBy([...items, ...assets], 'sid'));
}

export function unloadAsset(sid) {
    loadedAssetsState.set(assets => assets.filter(asset => asset.sid !== sid))
}

export function modifyAsset(asset, shouldAppendNew = true) {
    loadedAssetsState.set(assets => {
        const assetIndex = assets.findIndex(a => a.sid === asset.sid)
        if (assetIndex >= 0) {
            if (shouldAppendNew) {
                assets = assets.filter(a => a.sid !== asset.sid)
                assets.splice(assetIndex, 0, asset)
            } else {
                assets[assetIndex] = asset
            }
        }
        return assetIndex >= 0 ? assets : [asset, ...assets || []]
    })
}


export function isDefaultPreset(preset) {
    return preset.sid === Preset.getDefaultPreset(loadedPresetsState.get())?.sid
}

export function prependPresets(...items) {
    loadedPresetsState.set(presets => presets.length ? [...items, ...presets] : []);
}

export function appendPresets(...items) {
    loadedPresetsState.set(presets => [...presets, ...items]);
}

export function unshiftPresets(...items) {
    loadedPresetsState.set(presets => [...items, ...presets]);
}

export function unloadPreset(sid, { user, setUser }) {
    let isPresetDefault = user?.preset?.sid === sid;
    loadedPresetsState.set(presets => {
        presets = presets.filter(preset => preset.sid !== sid)
        if (isPresetDefault) {
            user.preset = Preset.getDefaultPreset(presets)
            setUser(user)
        }
        return presets
    })
}


export function modifyPreset(preset, shouldAppendNew = true) {
    loadedPresetsState.set(presets => {
        const presetIndex = presets.findIndex(p => p.sid === preset.sid)
        if (presetIndex >= 0) {
            if (shouldAppendNew) {
                presets = presets.filter(p => p.sid !== preset.sid)
                presets.splice(presetIndex, 0, preset)
            } else {
                presets[presetIndex] = preset
            }
        }
        return presetIndex >= 0 ? presets : [preset, ...presets || []]
    })
}

export async function savePreset(preset, { user, setUser }, shouldUpdateUser, shouldAppendNew) {
    const newPreset = await preset.save(Preset.PROPERTIES);
    if (shouldUpdateUser || isDefaultPreset(preset) || !loadedPresetsState.get()?.length) {
        user.preset = newPreset
        setUser(user)
    }
    modifyPreset(newPreset, shouldAppendNew)
    return newPreset
}




export const presetsAmount = () => loadedPresetsState.get().length
export const assetsAmount = () => loadedAssetsState.get().length
export const sequencesAmount = () => loadedSequencesState.get().length


export const wizardState = entity(WIZARDS.CREATE)

export const resetWizardState = () => wizardState.set(WIZARDS.CREATE)

export function setWizard(wizard) {
    if (Object.values(WIZARDS).includes(wizard)) {
        wizardState.set(wizard)
    }
}
