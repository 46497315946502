import React from 'react';
import { Modal } from 'react-bootstrap';
import LottiePlayer from '../../components/lottie-player';
import SVG from '../../components/svg';
import Button from '../Button';
import lottieBrand from '../../../static/lottie/brand-icon.json';
import { useClickOutsideEvent } from '../../hooks/useClickOutsideEvent';

interface CreateModalProps {
  modalTitle: string;
  showModal: Boolean;
  setShowModal: Function;
  innerRef: React.MutableRefObject<HTMLInputElement>;
  addButton: string;
  addLottie: any;
  createItem: Function;
  play: Boolean;
  setPlay?: Function;
  svgName: string;
  disabled: Function;
  defaultValue: string;
}

export default function CreateModal({
  showModal,
  setShowModal,
  modalTitle,
  innerRef,
  addButton,
  addLottie,
  createItem,
  play,
  setPlay,
  svgName,
  disabled,
  defaultValue,
}: CreateModalProps) {
  const [valid, setValid] = React.useState(false);
  const [value, setValue] = React.useState(defaultValue ? defaultValue : '');
  const modalBodyRef = React.useRef<HTMLElement | null>(null);

  const closeCreateModal = () => {
    setShowModal(false);
    setPlay && setPlay(false);
  };

  useClickOutsideEvent(modalBodyRef, closeCreateModal);

  function onChange({ target }) {
    setValue(target.value);
  }

  function onKeyUp({ target }) {
    const value = target.value;
    if (!value || (disabled && disabled(target.value))) {
      setValid(false);
    } else {
      setValid(true);
    }
  }

  React.useEffect(() => {
    if (!showModal) {
      setValue('');
    }
    innerRef && innerRef.current && innerRef.current.focus();
  }, [showModal]);

  return (
    <Modal
      show={showModal}
      centered
      onHide={() => {
        setShowModal(false), setPlay && setPlay(false);
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Modal.Body ref={modalBodyRef}>
        <a className="close-button" onClick={() => setShowModal(false)}>
          <SVG name="close" className="close" />
        </a>
        <div className="brand-modal-content">
          <div className="brand-wrapper">
            <div className="brand-title-wrapper">
              <h3 className="brand-kit-name-title">{modalTitle}</h3>
              {addLottie && (
                <div className="lottie-wrapper">
                  <LottiePlayer className="add-lottie" animationData={addLottie} autoplay={play} loop={false} />
                </div>
              )}
              {svgName && (
                <div className="lottie-wrapper">
                  <SVG name={svgName} className={svgName} />
                </div>
              )}
            </div>
            <input
              type="text"
              autoComplete="off"
              className="brand-name"
              id="brand-kit-name"
              ref={innerRef}
              required
              onKeyUp={onKeyUp}
              placeholder={defaultValue ? defaultValue : ''}
              onChange={onChange}
              value={value}
            />
          </div>
        </div>
        <div className="button-wrapper">
          <Button className="cancel-button" onClick={() => setShowModal(false)} variant="tertiary" label="Cancel" />
          <Button className="add-button" onClick={createItem} variant="primary" label={addButton} disabled={!valid} />
        </div>
      </Modal.Body>
    </Modal>
  );
}
