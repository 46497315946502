import { DEFAULT_FONT } from '../utils/font.utils';
import Asset, { AssetStatus, AssetType } from './asset';
import * as Base from './base/sequence';
import { VideoType } from './base/videoType';
import Color from './color';
import EditRules from './editRules';
import Preset from './preset';
import AssetUsage from './preset/assetUsage';
import Logo from './sequence/logo';
import Style from './sequence/style';
import User from './user';

export { SequenceDownloadNotificationType, SequenceRestoreStatus, SequenceRole, SequenceStatus } from './base/sequence';
export { default as Color } from './color';
export { default as Content } from './content';
export { default as Chapter } from './sequence/chapter';
export { default as Effect } from './sequence/effect';
export { default as Logo } from './sequence/logo';
export { default as LogoEffect } from './sequence/logoEffect';
export { default as Music } from './sequence/music';
export { default as Scene } from './sequence/scene';
export { default as Skip } from './sequence/skip';
export { default as Style } from './sequence/style';
export { default as Text } from './sequence/text';
export { default as SequenceAccess } from './sequenceAccess';

export interface ISequence extends Base.ISequence {}

export interface ISequenceProperties extends Base.ISequenceProperties {}

export interface ISequenceFilter extends Base.ISequenceFilter {}

export default class Sequence<
    IType extends ISequence = ISequence,
    ITypeProperties extends ISequenceProperties = ISequenceProperties
  >
  extends Base.Sequence<IType, ITypeProperties>
  implements ISequence
{
  //getWithCreator
  //minimalList
  extractedThumb?: string;

  get status(): number | null | undefined {
    return this.values.status;
  }

  set status(value: number | null | undefined) {
    if (this.values.status !== value) {
      this.values.status = value;
    }
  }

  get duration(): number | null | undefined {
    return this.values.duration;
  }

  // TODO configure all the places who set duration through values when safer
  set duration(value: number | null | undefined) {
    if (this.values.duration !== value) {
      this.values.duration = value;
    }
  }

  async applyPresetLogo(preset: Preset, user: User): Promise<Sequence> {
    const defaultLogoAssetSid = preset.getDefaultLogo()?.assetSid;
    if (!defaultLogoAssetSid) {
      return this;
    }
    console.log('Default logo', defaultLogoAssetSid);
    try {
      const { width, height } = await Logo.parseLogo(defaultLogoAssetSid, user?.cacheVersion);
      if (width && height) {
        console.log('Logo size', { width, height });
        this.logo = new Logo();
        this.logo.assetSid = defaultLogoAssetSid;
        this.logo.srcWidth = width;
        this.logo.srcHeight = height;
      }
    } catch (err) {
      console.error(err);
    }
    return this;
  }

  applyPresetFont(preset: Preset): Sequence {
    this.style = this.style || new Style();
    const bodyFont = preset.getBodyFont(),
      headerFont = preset.getHeaderFont();

    if (bodyFont) {
      if (bodyFont instanceof String || bodyFont?.length) {
        this.style.fontAssetSid = null;
        this.style.bodyBoldFileName = preset.bodyBoldVariant;
        this.style.bodyRegularFileName = preset.bodyRegularVariant;
        this.style.fontFamily = bodyFont as string;
        this.style.captionsFontFamily = bodyFont as string;
        this.style.captionsRegularFileName = preset.bodyRegularVariant;
      }
      if (bodyFont instanceof AssetUsage && bodyFont?.boldFileName && bodyFont?.regularFileName) {
        this.style.fontFamily = null;
        this.style.bodyBoldFileName = bodyFont.boldFileName;
        this.style.bodyRegularFileName = bodyFont.regularFileName;
        this.style.fontAssetSid = bodyFont.assetSid;
        this.style.captionsFontAssetSid = bodyFont.assetSid;
        this.style.captionsRegularFileName = bodyFont.regularFileName;
      }
    } else {
      // set default font for caption
      this.style.captionsFontAssetSid = null;
      this.style.captionsFontFamily = DEFAULT_FONT;
      this.style.captionsRegularFileName = '400';
    }

    if (headerFont) {
      if (headerFont instanceof String || headerFont?.length) {
        this.style.headerFontAssetSid = null;
        this.style.headerBoldFileName = preset.headerBoldVariant;
        this.style.headerRegularFileName = preset.headerRegularVariant;
        this.style.headerFontFamily = headerFont as string;
      }
      if (headerFont instanceof AssetUsage && headerFont?.boldFileName && headerFont?.regularFileName) {
        this.style.headerFontFamily = null;
        this.style.headerFontAssetSid = headerFont.assetSid;
        this.style.headerBoldFileName = headerFont.boldFileName;
        this.style.headerRegularFileName = headerFont.regularFileName;
      }
    }

    return this;
  }

  applyPresetColors(preset: Preset): Sequence {
    if (preset.colors?.length) {
      this.colors = preset.colors.map((c) => {
        const { color, dominancy, percent } = c;
        const obj = new Color();
        obj.color = color;
        obj.dominancy = dominancy;
        obj.percent = percent;
        return obj;
      });
    }
    return this;
  }

  async applyPresetGraphics(lottiePackageSid: string, defaultLogoSid?: string): Sequence {
    if (lottiePackageSid) {
      try {
        // fetch folder / package asset list
        const lottieAssets = await Asset.list({
          statusIn: [AssetStatus.READY, AssetStatus.PENDING],
          typeIn: [
            AssetType.LOTTIE_SLIDE,
            AssetType.LOTTIE_IMAGE,
            AssetType.LOTTIE_VIDEO,
            AssetType.LOTTIE_ICON,
            AssetType.LOTTIE_SPEAKER,
            AssetType.LOTTIE_OUTRO,
            AssetType.LOTTIE_TEXT,
            AssetType.LOTTIE_FRAME,
            AssetType.LOTTIE_INTRO,
          ],
          folderSidEqual: lottiePackageSid,
        });

        if (!this.style) {
          throw new Error('Style not defined');
        }

        // reset styles before apply assets from package
        this.style.slideAssetSid = null;
        this.style.imageAssetSid = null;
        this.style.videoAssetSid = null;
        this.style.iconAssetSid = null;
        this.style.speakerAssetSid = null;
        this.style.outroAssetSid = null;
        this.style.outroLogoAssetSid = null;
        this.style.textAssetSid = null;
        this.style.frameAssetSid = null;
        this.style.introAssetSid = null;
        this.style.introLogoAssetSid = null;

        lottieAssets.forEach((el) => {
          if (!this.style) {
            throw new Error('Style not defined');
          }
          switch (el.type) {
            case AssetType.LOTTIE_SLIDE:
              this.style.slideAssetSid = el.sid;
              break;
            case AssetType.LOTTIE_IMAGE:
              this.style.imageAssetSid = el.sid;
              break;
            case AssetType.LOTTIE_VIDEO:
              this.style.videoAssetSid = el.sid;
              break;
            case AssetType.LOTTIE_ICON:
              this.style.iconAssetSid = el.sid;
                break;
            case AssetType.LOTTIE_SPEAKER:
              this.style.speakerAssetSid = el.sid;
              break;
            case AssetType.LOTTIE_OUTRO:
              this.style.outroAssetSid = el.sid;
              break;
            case AssetType.LOTTIE_TEXT:
              this.style.textAssetSid = el.sid;
              break;
            case AssetType.LOTTIE_FRAME:
              this.style.frameAssetSid = el.sid;
              break;
            case AssetType.LOTTIE_INTRO:
              this.style.introAssetSid = el.sid;
              break;
            default:
              break;
          }
        });

        if(defaultLogoSid) {
          this.style.outroLogoAssetSid = defaultLogoSid;
          this.style.introLogoAssetSid = defaultLogoSid;
        }
        
        this.style.visualPackageSid = lottiePackageSid;
        // on package change, we need to update the version of the visual package for new position calculation
        this.visualPackageVersion = 2;
        return this;
      } catch (error) {
        console.log('applyPresetGraphics error ', error);
        return this;
      }
    }
    return this;
  }

  async applyPreset(preset: Preset, user: User): Promise<Sequence> {
    this.presetSid = preset.sid;
    console.log('Apply preset logo');
    await this.applyPresetLogo(preset, user);
    console.log('Apply preset font');
    this.applyPresetFont(preset);
    console.log('Apply preset colors');
    this.applyPresetColors(preset);
    console.log('Apply preset graphics');
    await this.applyPresetGraphics(preset.defaultVisualPackage, preset.getDefaultLogo()?.assetSid);
    return this;
  }

  async applyEditRules(videoType: VideoType) {
    this.editRules = new EditRules();

    if (videoType.editRules?.values) {
      for (const key in videoType.editRules.values) {
        try {
          this.editRules[key] = videoType.editRules?.values[key];
        } catch (err) {
          // do nothing, we just want to avoid errors when trying to set fields like sid, version that don't have setter
        }
      }
    }
  }

  /**
   * @param {SceneState} tempScenes
   * @param {scenes} scenes
   * @returns {Sequence}
   */
  async preReorderScenes(tempScenesState, scenesState) {
    const scenes = scenesState.map;
    const chaptersChanges = tempScenesState.array.find(
      (c) =>
        scenes[c.sid]?.index !== c.index ||
        scenes[c.sid]?.duration !== c.duration ||
        scenes[c.sid]?.clipFrom !== c.clipFrom
    );

    if (chaptersChanges) {
      const chapters = tempScenesState.filter.array;
      chapters.forEach((c: ChapterOrder) => c.propertiesEval());
      const chapterOrders = chapters.filter((c) => c.changedProperties.length && (c.duration || c.sid));
      console.log('preReorderScenes has reorder scenes', { chapterOrders });
      const res = await this.reorderScenes(chapterOrders);
      return res;
    } else {
      console.log('preReorderScenes no changes', { chaptersChanges });
      return null;
    }
  }

  get textDirectionByLanguage() {
    if (this.languageCode === 'he-IL') {
      return 'rtl';
    }
    return 'ltr';
  }
}
