import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { Link } from '@reach/router';
import { navigate } from 'gatsby';
import { AppContext } from '../lib/app-context';
import { sequenceState } from '../state/sequence';
import Plan from '../client/plan';

interface UpgradeProps {
  onHide(): void;
  title: String;
  info?: String;
  label?: String;
  text: String;
  onClickTrackEvent?: () => void;
}

export default function Upgrade({ onHide, title, info, text, label, onClickTrackEvent }: UpgradeProps) {
  const { isMobileDevice, plan } = React.useContext(AppContext);
  const sequence = sequenceState.use();
  const [planIdToUpgradeTo, setPlanIdToUpgradeTo] = useState('');

  useEffect(async () => {
    const plans = await Plan.list(null, null, {
      tier: true,
      planId: true,
      provider: true,
      name: true,
      hidden: true,
      status: true,
    });

    const futureUpgradablePaddlePlan = plans
      .filter((planItem) => !planItem.hidden && planItem.status === 1 && planItem.provider === 3 && planItem.tier! > 0)
      .sort((a, b) => a.tier! - b.tier!)
      .find((planItem) => planItem.tier! > plan?.tier!)?.sid;

    setPlanIdToUpgradeTo(futureUpgradablePaddlePlan || '');
  }, []);

  return (
    <Modal
      show={true}
      className="upgrade-modal"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onHide}
      centered
      size="lg"
    >
      <Modal.Body>
        <button
          id="btn-close-upgrade-modal"
          className="btn btn-close"
          onClick={() => {
            window.top?.postMessage('UPGRADE_MODAL_CLOSED', '*');
            onHide();
            if (isMobileDevice) {
              navigate('/');
            }
          }}
        />
        <div className="upgrade-heading">
          <h3 className="upgrade-title">{title}</h3>
          {info && <h6 className="upgrade-info">{info}</h6>}
          <div className={'upgrade-text'}>{text}</div>
        </div>
        <div className="upgrade-actions">
          <Link
            to={`/plans?planId=${planIdToUpgradeTo}&chargeType=1`}
            onClick={() => {
              window.top?.postMessage(`UPGRADE_PLAN_${planIdToUpgradeTo}`, '*');
              onHide();
              onClickTrackEvent && onClickTrackEvent();
            }}
            className="btn btn-white bg-rainbow upgrade-btn"
          >
            {label || 'Upgrade'}
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
}
