import classNames from 'classnames';
import React from 'react';
import { Word } from '../../../client/closedCaption';
import SVG from '../../../components/svg';
import { useClickOutsideEvent } from '../../../hooks/useClickOutsideEvent';
import Button, { ButtonSize, ButtonVariant } from '../../../ui-components/Button';
import { trackEvent } from '../../../utils/analityics.utils';
import { handleCuts } from '../Editors/EditorContent';
import SubtitleTools from '../Generics/SubtitleTools';



interface VisibleWordElementProps {
    selectedWords: Word[];
    activeWords: Word[];
    word: Word;
    handleDeletion(e: MouseEvent): void;
    resetSelection(): void;
    shouldShowBlank: boolean | string;
    shouldShowBlankBefore: boolean;
    className: string;
    onMouseEnter?(word: Word): void;
    onClick?(word: Word): void;
    splitQuietSpace(e: MouseEvent, i: number, chapterSid?: string): void;
    acceptTextChange(newText: string): void;
    index: number;
    chapterSid: string;
    deleted?: boolean;
    isBlurable: React.MutableRefObject<boolean>;
    contentWrapperRef: React.RefObject<HTMLDivElement>;
}

export default function VisibleWordElement({ selectedWords, activeWords, word, handleDeletion, resetSelection, shouldShowBlank, shouldShowBlankBefore, className, onMouseEnter, onClick, splitQuietSpace, acceptTextChange, index, deleted, isBlurable, chapterSid, contentWrapperRef }: VisibleWordElementProps) {
    const editableSpan = React.useRef(null)
    const wordClassNames = classNames('word', { [className]: className?.length })


    React.useEffect(() => {
        if (editableSpan.current) {
            editableSpan.current.focus()
        }
    }, [selectedWords])

    if (!word) {
        return null;
    }

    function onKeyDown(e: KeyboardEvent) {
        if (e.code === "Enter" || e.key === "Enter" || deleted) {
            return e.preventDefault()
        }

        if (e.code === "Space" || e.key === " " || deleted) {
            // to prevent multiple spaces
            // const offset = window.getSelection().getRangeAt(0).startOffset
            // if (!e.target.textContent[offset]?.trim() || !e.target.textContent[offset - 1]?.trim()) {
            //     return e.preventDefault()
            // }
        }
    }

    const selectedCC = React.useRef<HTMLSpanElement | null>(null)
    function onBlur() {
        // isBlurable.current = true
        resetSelection()
    }

    useClickOutsideEvent(selectedCC, onBlur)


    const selectedWord = (w: Word, i: number) => w.word + (i === selectedWords.length - 1 ? '' : ' ')

    return <>
        {
            selectedWords && selectedWords.includes(word) ? word === selectedWords[0] ?
                <span className="editable-subtitles"
                    onBlur={onBlur}
                    ref={selectedCC}
                ><span className="space">{' '}</span>
                    <span
                        data-cy='editable-words'
                        ref={editableSpan}
                        contentEditable={false}
                        suppressContentEditableWarning
                        // TODO use unsafe html
                        onKeyDown={onKeyDown}
                    >
                        {selectedWords.map((w, i) => <span key={i} className="word">{selectedWord(w, i)}</span>)}
                        <span className="subtitles--tools--wrapper">
                            <SubtitleTools
                                acceptTextChange={() => acceptTextChange && acceptTextChange(editableSpan.current.textContent)}
                                resetSelection={resetSelection}
                                handleDeletion={e => handleDeletion(e)}
                                handleCuts={() => {
                                    handleCuts(selectedWords)
                                    resetSelection()
                                }}
                                onMouseEnter={() => isBlurable.current !== undefined && (isBlurable.current = false)}
                                onMouseLeave={() => isBlurable.current !== undefined && (isBlurable.current = true)}
                                deleted={deleted}
                            />
                        </span>
                    </span>
                    <span className="space">{' '}</span>
                </span >
                :
                null :
                <>
                    {shouldShowBlankBefore && <span
                        onMouseEnter={() => onMouseEnter && onMouseEnter(null)}>
                        <Button
                            variant={ButtonVariant.Tertiary}
                            size={ButtonSize.ExtraSmall}
                            className="blank-space-btn"
                            data-cy="blank-space"
                            onClick={e => {
                                trackEvent('content-editor-blank-spaces', { action: 'delete-single-blank'});
                                splitQuietSpace(e, index, chapterSid)
                            }}>
                            <SVG name="delete" />
                            Blank
                        </Button> &nbsp;
                    </span>
                    }
                    <span
                        className={wordClassNames}
                        starttime={word.startTime}
                        data-cy="word-element"
                        onMouseEnter={() => onMouseEnter && onMouseEnter(word)}
                        onClick={() => !isBlurable.current && onClick && onClick(word)}
                    >
                        <span>{word.word}</span>
                        <span>{(activeWords?.at(index + 1) && activeWords.at(index + 1) === selectedWords?.at(0) ? '' : ' ')}</span>
                    </span>
                    {shouldShowBlank && <span
                        onMouseEnter={() => onMouseEnter && onMouseEnter(null)}>
                        <Button
                            variant={ButtonVariant.Tertiary}
                            size={ButtonSize.ExtraSmall}
                            className="blank-space-btn"
                            data-cy="blank-space"
                            onClick={(e) => {
                                trackEvent('content-editor-blank-spaces', { action: 'delete-single-blank' });
                                splitQuietSpace(e, index, shouldShowBlank === chapterSid && chapterSid + '--next')
                            }}>
                            <SVG name="delete" />
                            Blank
                        </Button> &nbsp;
                    </span>
                    }
                </>
        }
    </>

}