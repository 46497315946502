
class ColorIdentifier {

	fullPath;

	constructor(color, parent, className) {
		this.color = color;
		this.parentId = parent.id;
		this.parent = parent;
		this.name = parent.name;
		this.className = className;
	}

}

class LottieBaseObject {
	constructor(data = {}) {
		this.data = data;
	}
}

class LottieObject extends LottieBaseObject {

	static CLASS_TYPE = {
		FILL: 'fill',
		STROKE: 'stroke',
		IMAGE: 'logo',
		TEXT: 'text',
		EDITABLE: 'editable',
	};

	static ELEMENT_ID_CLASS_PREFIX = 'element-sid';

	constructor(data = {}) {
		super(data);

		data._id = data._id || Math.random().toString(36).substring(2);

		this.data._classNames = this.data._classNames || {};
		this.data._baseClassNames = this.data._baseClassNames || !this.data.cl
			? []
			: Array.isArray(this.data.cl)
				? this.data.cl.join(' ').split(' ')
				: [this.data.cl];
	}

	/**
     * @returns {string[]}
     **/
	get classNames() {
		return this.data.cl ? this.data.cl.split(' ') : [];
	}

	/**
     * @param {string} type
     * @param {string} className
     **/
	setClass(type, className) {
		if(type) {
			if(className) {
				this.data._classNames[type] = className;
			}
			else {
				delete this.data._classNames[type];
			}
		}
		const elementIdClass = `${this.constructor.ELEMENT_ID_CLASS_PREFIX}-${this.id}`;
		this.data.cl = [
			this.constructor.name,
			elementIdClass,
			...this.data._baseClassNames,
			...Object.values(this.data._classNames)
		].join(' ');
	}

	/**
     * @param {string} type
     * @returns {string}
     **/
	getClass(type) {
		return this.data._classNames[type];
	}

	/**
     * @param {string} className
     * @returns {boolean}
     **/
	hasClass(className) {
		return this.classNames.includes(className);
	}

	/**
     * @param {ColorIdentifier} color
     * @param {string} className
     **/
	setColorClass(color, className) { }

	/**
     * @returns {string}
     **/
	get id() {
		return this.data._id;
	}

	get type() {
		return this.data.ty;
	}

	/**
     * @returns {number}
     **/
	get index() {
		return this.data.ind;
	}

	/**
     * @returns {number}
     */
	get inPoint() {
		return this.data.ip;
	}

	/**
     * @returns {number}
     */
	get outPoint() {
		return this.data.op;
	}

	/**
     * @returns {number}
     **/
	get parentIndex() {
		return this.data.parent;
	}

	/**
     * @returns {string}
     **/
	get name() {
		return this.data.nm;
	}

	/**
    * @params {string}
    **/
	set name(value) {
		if(value && this.data.nm !== value) {
			this.data.nm = value;
		}
	}


	/**
     * @returns {LottieProperty}
     */
	_get(property) {
		return new LottieProperty(this.data[property]);
	}

	static toRGB(color) {
		const [r, g, b] = color;
		return '#' + [r, g, b]
			.map(c => Math.round(c * 255).toString(16).padStart(2, '0'))
			.join('');
	}

	toJSON() {
		return this.data;
	}
}

class LottieProperty extends LottieBaseObject {

	get values() {
		return this.data.k;
	}
}

export class Shape extends LottieObject {

	static Type = {
		ELLIPSE: 'el',
		FILL: 'fl',
		GRADIENT_FILL: 'gf',
		GRADIENT_STROKE: 'gs',
		GROUP: 'gr',
		MERGE: 'mm',
		OFFSET_PATH: 'op',
		PATH: 'sh',
		RECTANGLE: 'rc',
		REPEATER: 'rp',
		ROUNDED_CORNERS: 'rd',
		STAR: 'sr',
		STROKE: 'st',
		TRIM: 'tm',
		TWIST: 'tw',
		TRANSFORM: 'tr'
	};

	static get(data) {
		if(!Shape.Child[data.ty]) {
			throw new Error('Shape not implemented: ' + data.ty);
			return null;
		}
		return new Shape.Child[data.ty](data);
	}

	/**
     * @returns {ColorIdentifier[]}
     **/
	get colors() {
		return [];
	}
}

export class TrimShape extends Shape {
}

export class TwistShape extends Shape {
}

export class TransformShape extends Shape {
}

export class RectangleShape extends Shape {
}

export class RepeaterShape extends Shape {
}

export class RoundedCornersShape extends Shape {
}

export class EllipseShape extends Shape {
}

export class FillShape extends Shape {

	/**
     * @returns {ColorIdentifier[]}
     **/
	get colors() {
		const color = this._get('c');
		if(!color) {
			return null;
		}
		const colorClass = this.getClass(LottieObject.CLASS_TYPE.FILL);
		const identifier = new ColorIdentifier(LottieObject.toRGB(color.values), this, colorClass);
		return [identifier];
	}

	/**
     * @param {ColorIdentifier} color
     * @param {string} className
     **/
	setColorClass(color, className) {
		if(color.parentId === this.id) {
			this.setClass(LottieObject.CLASS_TYPE.FILL, className ? `color color-fill color-fill-${className}` : undefined);
		}
	}
}

export class MergeShape extends Shape {
}

export class OffsetPathShape extends Shape {
}

export class StarShape extends Shape {
}

export class GradientFillShape extends Shape {
}

export class GradientStrokeShape extends Shape {
}

export class PathShape extends Shape {

	// /**
	//  * @returns {ColorIdentifier[]}
	//  **/
	// Get colors() {
	//     Const color = this._get('c')
	//     If(!color) {
	//         Return null;
	//     }
	//     Const colorClass = this.getClass(LottieObject.CLASS_TYPE.FILL)
	//     Const identifier = new ColorIdentifier(LottieObject.toRGB(color.values), this, colorClass)
	//     Return [identifier];
	// }

	// /**
	//  * @param {ColorIdentifier} color
	//  * @param {string} className
	//  **/
	// SetColorClass(color, className) {
	//     If(color.parentId === this.id) {
	//         This.setClass(LottieObject.CLASS_TYPE.FILL, `color-fill-${className}`)
	//     }
	// }
}

export class GroupShape extends Shape {

	/**
     * @returns {Shape[]}
     */
	get shapes() {
		return this.data.it
			.map(Shape.get)
			.filter(shape => shape);
	}

	/**
     * @returns {ColorIdentifier[]}
     **/
	get colors() {
		const colors = this.shapes
			.map(shape => shape.colors)
			.flat()
			.filter(color => color);

		if(this.name) {
			colors.forEach(color => {
				color.name = color.name || this.name;
				color.fullPath = this.name;
			});
		}
		return colors;
	}

	/**
     * @param {ColorIdentifier} color
     * @param {string} className
     **/
	setColorClass(color, className) {
		this.shapes.forEach(shape => shape.setColorClass(color, className));
	}
}

export class StrokeShape extends Shape {

	/**
     * @returns {ColorIdentifier[]}
     **/
	get colors() {
		const color = this._get('c');
		if(!color) {
			return null;
		}
		const colorClass = this.getClass(LottieObject.CLASS_TYPE.STROKE);
		const identifier = new ColorIdentifier(LottieObject.toRGB(color.values), this, colorClass);
		return [identifier];
	}

	/**
     * @param {ColorIdentifier} color
     * @param {string} className
     **/
	setColorClass(color, className) {
		if(color.parentId === this.id) {
			this.setClass(LottieObject.CLASS_TYPE.STROKE, className ? `color color-stroke color-stroke-${className}` : undefined);
		}
	}
}

Shape.Child = {
	[Shape.Type.ELLIPSE]: EllipseShape,
	[Shape.Type.FILL]: FillShape,
	[Shape.Type.GRADIENT_FILL]: GradientFillShape,
	[Shape.Type.GRADIENT_STROKE]: GradientStrokeShape,
	[Shape.Type.GROUP]: GroupShape,
	[Shape.Type.MERGE]: MergeShape,
	[Shape.Type.OFFSET_PATH]: OffsetPathShape,
	[Shape.Type.PATH]: PathShape,
	[Shape.Type.RECTANGLE]: RectangleShape,
	[Shape.Type.REPEATER]: RepeaterShape,
	[Shape.Type.ROUNDED_CORNERS]: RoundedCornersShape,
	[Shape.Type.STAR]: StarShape,
	[Shape.Type.STROKE]: StrokeShape,
	[Shape.Type.TRIM]: TrimShape,
	[Shape.Type.TWIST]: TwistShape,
	[Shape.Type.TRANSFORM]: TransformShape,
};

export class Transform extends LottieBaseObject {

	/**
     * @returns {number}
     */
	get x() {
		return this.data.px;
	}

	/**
     * @returns {number}
     */
	get y() {
		return this.data.py;
	}

	// "a": {
	//     "title": "Anchor Point",
	//     "description": "Anchor point: a position (relative to its parent) around which transformations are applied (ie: center for rotation / scale)",
	//     "$ref": "#/$defs/animated-properties/multi-dimensional"
	// },
	// "s": {
	//     "title": "Scale",
	//     "description": "Scale factor, `[100, 100]` for no scaling",
	//     "$ref": "#/$defs/animated-properties/multi-dimensional"
	// },
	// "r": {
	//     "title": "Rotation",
	//     "description": "Rotation in degrees, clockwise",
	//     "$ref": "#/$defs/animated-properties/value"
	// },
	// "o": {
	//     "title": "Opacity",
	//     "$ref": "#/$defs/animated-properties/value"
	// },
	// "sk": {
	//     "title": "Skew",
	//     "description": "Skew amount as an angle in degrees",
	//     "$ref": "#/$defs/animated-properties/value"
	// },
	// "sa": {
	//     "title": "Skew Axis",
	//     "description": "Direction along which skew is applied, in degrees (`0` skews along the X axis, `90` along the Y axis)",
	//     "$ref": "#/$defs/animated-properties/value"
	// }
	// "p": {
	//     "title": "Position",
	//     "description": "Position / Translation",
	//     "$ref": "#/$defs/animated-properties/multi-dimensional"
	// }
}

export class Layer extends LottieObject {

	static Type = {
		PRECOMPOSITION: 0,
		SOLID: 1,
		IMAGE: 2,
		GROUP: 3,
		SHAPE: 4,
		TEXT: 5,
		AUDIO: 6,
		VIDEO_PLACEHOLDER: 7,
		IMAGE_SEQUENCE: 8,
		VIDEO: 9,
		IMAGE_PLACEHOLDER: 10,
		GUIDE: 11,
		ADJUSTMENT: 12,
		CAMERA: 13,
		LIGHT: 14
	};

	static get(data) {
		if(!Layer.Child[data.ty]) {
			throw new Error('Layer not implemented: ' + data.ty);
			return null;
		}
		return new Layer.Child[data.ty](data);
	}

	/**
     * @returns {number}
     */
	get ratio() {
		return this.data._ratio;
	}

	/**
     * @param {number} value
     */
	set ratio(value) {
		this.data._ratio = value;
	}

	/**
     * @returns {ColorIdentifier[]}
     */
	get colors() {
		return [];
	}

	/**
     * @returns {boolean}
     */
	get hidden() {
		return this.data.hd;
	}

	/**
     * @returns {number}
     */
	get timeStretch() {
		return this.data.sr;
	}

	/**
     * @returns {Transform}
     */
	get transform() {
		return new Transform(this.data.ks);
	}

	/**
     * @returns {boolean}
     */
	get autoOrient() {
		return this.data.ao ? true : false;
	}

	/**
     * @returns {number}
     */
	get startTime() {
		return this.data.st;
	}

	/**
     * @returns {number}
     */
	get blendMode() {
		return this.data.bm;
	}

	/**
     * @returns {number}
     */
	get matteMode() {
		return this.data.tt;
	}

	/**
     * @returns {number}
     */
	get matteTarget() {
		return this.data.td;
	}

	/**
     * @returns {boolean}
     */
	get hasMask() {
		return this.data.hasMask;
	}

	/**
     * @returns {Mask[]}
     */
	get masksProperties() {
		return this.data.masksProperties.map(maskProperties => new Mask(maskProperties));
	}

	/**
     * @returns {Effect[]}
     */
	get effects() {
		return this.data.ef.map(data => new Effect(data));
	}

	/**
     * `id` attribute used by the SVG renderer
     * @returns {string}
     */
	get elementId() {
		return this.data.ln;
	}
}

export class PrecompositionLayer extends Layer {
}

export class SolidLayer extends Layer {
}

export class ImageLayer extends Layer {
}

export class GroupLayer extends Layer {
}

export class ShapeLayer extends Layer {
	/**
     * @returns {Shape[]}
     */
	get shapes() {
		return this.data.shapes
			.map(Shape.get)
			.filter(shape => shape);
	}

	/**
     * @returns {ColorIdentifier[]}
     **/
	get colors() {
		const colors = this.shapes
			.map(shape => shape.colors)
			.flat()
			.filter(color => color);

		if(this.name) {
			colors.forEach(color => {
				color.name = color.name || this.name;
				color.fullPath = this.name;
			});
		}
		return colors;
	}

	/**
     * @param {ColorIdentifier} color
     * @param {string} className
     **/
	setColorClass(color, className) {
		this.shapes.forEach(shape => shape.setColorClass(color, className));
	}
}

export class TextFrame extends LottieObject {

	/**
     * @returns {ColorIdentifier}
     **/
	get color() {
		const color = this.data.s && this.data.s.fc;
		if(!color) {
			return null;
		}

		return new ColorIdentifier(LottieObject.toRGB(color), this);
	}

	/**
     * @returns {string}
     **/
	get text() {
		return this.data.s && this.data.s.t;
	}

	/**
     * @param {string} value
     **/
	set text(value) {
		this.data.s = this.data.s || {};
		this.data.s.t = value;
	}

	/**
     * @returns {number}
     **/
	get fontSize() {
		return this.data.s && this.data.s.s;
	}

	/**
     * @param {number} value
     **/
	set fontSize(value) {
		this.data.s = this.data.s || {};
		this.data.s.s = value;
	}
}

export class TextAnimatorDataProperty extends LottieBaseObject {
}

export class TextOptions extends LottieBaseObject {
}

export class MaskedPath extends LottieBaseObject {
}

export class TextDocument extends LottieBaseObject {

	/**
     * @returns {string}
     */
	get fontFamily() {
		return this.data.f;
	}

	/**
     * @returns {Color}
     */
	get fontColor() {
		return this.data.fc;
	}

	/**
     * @returns {number}
     */
	get fontSize() {
		return this.data.s;
	}

	/**
     * @returns {number}
     */
	get LineHeight() {
		return this.data.lh;
	}

	/**
     * @returns {number[]}
     */
	get wrapSize() {
		return this.data.sz;
	}

	/**
     * @returns {string}
     */
	get text() {
		return this.data.t;
	}

	/**
     * @returns {number}
     */
	get justify() {
		return this.data.j;
	}
}

export class TextDataKeyframe extends LottieBaseObject {

	/**
     * @returns {number}
     */
	get time() {
		return this.data.t;
	}

	/**
     * @returns {TextDocument}
     */
	get start() {
		return new TextDocument(this.data.s);
	}
}

export class TextData extends LottieBaseObject {
	/**
     * @returns {TextDataKeyframe[]}
     */
	get keyframes() {
		return this.data.k.map(data => new TextDataKeyframe(data));
	}
}

export class TextAnimatorData extends LottieBaseObject {


	/**
     * @returns {TextAnimatorDataProperty[]}
     */
	get properties() {
		return this.data.a.map(data => new TextAnimatorDataProperty(data));
	}

	/**
     * @returns {TextData}
     */
	get textData() {
		return new TextData(this.data.d);
	}

	/**
     * @returns {TextOptions}
     */
	get options() {
		return new TextOptions(this.data.m);
	}

	/**
     * @returns {MaskedPath}
     */
	get maskedPath() {
		return new MaskedPath(this.data.p);
	}
}

export class TextLayer extends Layer {
	/**
     * @returns {TextFrame[]}
     */
	get textFrames() {
		const textFrames = this.data.t && this.data.t.d && this.data.t.d.k;
		return textFrames ? textFrames.map(t => new TextFrame(t)) : [];
	}

	/**
     * @returns {ColorIdentifier[]}
     **/
	get colors() {
		const textFrames = this.textFrames;
		if(!textFrames) {
			return [];
		}
		const colors = textFrames
			.map(textFrame => textFrame.color)
			.filter(color => color);

		const colorClass = this.getClass(LottieObject.CLASS_TYPE.FILL);
		colors.forEach(color => {
			color.className = colorClass;
			if(this.name) {
				colors.forEach(color => {
					color.name = color.name || this.name;
					color.fullPath = this.name;
					color.parent = this;
				});
			}
		});
		return colors;
	}

	/**
     * @param {string} value
     */
	set text(value) {
		this.textFrames.forEach(textFrame => textFrame.text = value);
	}

	/**
     * @returns {TextAnimatorData}
     */
	get animatorData() {
		return new TextAnimatorData(this.data.t);
	}

	/**
     * @param {ColorIdentifier} color
     * @param {string} className
     **/
	setColorClass(color, className) {
		if(this.textFrames.find(textFrame => textFrame.id === color.parentId)) {
			this.setClass(LottieObject.CLASS_TYPE.FILL, className ? `color color-fill color-fill-${className}` : undefined);
		}
	}
}

Layer.Child = {
	[Layer.Type.PRECOMPOSITION]: PrecompositionLayer,
	[Layer.Type.SOLID]: SolidLayer,
	[Layer.Type.IMAGE]: ImageLayer,
	[Layer.Type.GROUP]: GroupLayer,
	[Layer.Type.SHAPE]: ShapeLayer,
	[Layer.Type.TEXT]: TextLayer,
	// [Layer.Type.AUDIO]: 6,
	// [Layer.Type.VIDEO_PLACEHOLDER]: 7,
	// [Layer.Type.IMAGE_SEQUENCE]: 8,
	// [Layer.Type.VIDEO]: 9,
	// [Layer.Type.IMAGE_PLACEHOLDER]: 10,
	// [Layer.Type.GUIDE]: 11,
	// [Layer.Type.ADJUSTMENT]: 12,
	// [Layer.Type.CAMERA]: 13,
	// [Layer.Type.LIGHT]: 14
};

export class Asset extends LottieObject {

	constructor(data) {
		super(data);

		this.data._path = this.data._path || this.data.u;
		this.data._src = this.data._src || this.data.p;
	}

	get width() {
		return this.data.w;
	}

	get height() {
		return this.data.h;
	}

	get src() {
		return (this.data.u || '') + this.data.p;
	}

	set img(img) {
		if(img) {
			this.data.u = '';
			this.data.p = img;
		}
		else {
			this.data.u = this.data._path;
			this.data.p = this.data._src;
		}
	}

}

export class AnimationTime extends LottieBaseObject {

	get start() {
		return this.data.start;
	}

	get end() {
		return this.data.end;
	}
}

export class Animation extends LottieObject {

	addProperty(lottieObject, colorProperties) {
		if(lottieObject.layers) {
			lottieObject.layers.forEach(addProperty);
		}
		if(lottieObject.shapes) {
			lottieObject.shapes.forEach(addProperty);
		}
		if(lottieObject.getClass('editable') !== 'true') {
			return;
		}

		let colorProperty = new GraphicColor();
		colorProperty.name = lottieObject.name;

		if(lottieObject.hasClass('dominant')) {
			colorProperty.type = GraphicColor.TYPE.DOMINANCY;
		}
		else {
			colorProperty.type = GraphicColor.TYPE.BRIGHTNESS;
		}

		colorProperty.className = lottieObject.classNames.find(className => className.startsWith(Lottie.Layer.ELEMENT_ID_CLASS_PREFIX));
		if(lottieObject.hasClass('text-field')) {
			if(lottieObject.hasClass('text-name')) {
				colorProperty.name = 'Name';
			}
			if(lottieObject.hasClass('text-text')) {
				colorProperty.name = 'Text';
			}
			if(lottieObject.hasClass('text-title')) {
				colorProperty.name = 'Title';
			}
		}
		let dominantColor = sequenceState.get().colors[0];
		if(lottieObject.hasClass('bg')) {
			// If (colorProperties.find(p => p.className === 'bg')) {
			// 	Return;
			// }
			colorProperty.colorIndex = dominantColor.dominancy;
		}
		else if(lottieObject.hasClass('text')) {
			// If (colorProperties.find(p => p.className === 'text')) {
			// 	Return;
			// }
			colorProperty.color = dominantColor.brightness > BRIGHTNESS_THRESHOLD ? '#000000' : '#ffffff';
		}
		else {
			const isNumeric = /^\d+$/;
			let colorIndex = lottieObject.classNames.find(className => isNumeric.test(className));
			if(colorIndex !== undefined) {
				colorProperty.colorIndex = colorIndex;
			}
		}
		colorProperties.push(colorProperty);
	}

	getAnimationProps(animation) {
		animation.layers.forEach(l => addProperty(l, colorProperties));
		return colorProperties;
	}

	/**
     * @returns {Animation}
     */
	clone() {
		return new Animation(JSON.parse(JSON.stringify(this)));
	}

	/**
     * @returns {ColorIdentifier[]}
     **/
	get colors() {
		return this.layers
			.map(layer => layer.colors)
			.flat()
			.filter(color => color);
	}

	/**
     * @returns {string}
     */
	get version() {
		return this.data.v;
	}

	/**
     * @returns {number}
     */
	get width() {
		return this.data.w;
	}

	/**
     * @returns {number}
     */
	get height() {
		return this.data.h;
	}

	/**
     * @returns {number}
     */
	get frames() {
		return this.data.op;
	}

	/**
     * @returns {number}
     */
	get frameRate() {
		return this.data.fr;
	}

	/**
     * @returns {number}
     */
	get duration() {
		return this.frames / this.frameRate;
	}

	/**
     * @returns {Layer[]}
     */
	get layers() {
		return this.data.layers.map(Layer.get).filter(layer => layer);
	}

	/**
     * @returns {Asset[]}
     */
	get assets() {
		return this.data.assets.map(data => new Asset(data));
	}

	/**
     * @returns {[]}
     */
	get fonts() {
		return (this.data && this.data.fonts && this.data.fonts.list) ? this.data.fonts.list : [];
	}

	get children() {
		const extractChildren = child => (child && child.shapes && child.shapes.map(extractChildren) && child.shapes.map(extractChildren).flat()) || child;
		return this.layers.map(extractChildren).flat().filter(child => child);
	}

	get textLayers() {
		return this.layers.filter(layer => layer.type === Layer.Type.TEXT);
	}

	/**
     * @returns {AnimationTime[]}
     */
	get animationTimes() {
		if(!this.data._animations) {
			return undefined;
		}
		return this.data._animations.map(data => new AnimationTime(data));
	}

	/**
     * @param {AnimationTime[]} times
     */
	set animationTimes(times) {
		return this.data._animations = times.map(time => time.data);
	}

	/**
     * @param {ColorIdentifier} color
     * @param {string} className
     **/
	setColorClass(color, className) {
		this.layers.forEach(layer => layer.setColorClass(color, className));
	}

	getHeightScaleByAspectRatio(aspectRatio) {
		if(aspectRatio === '1:1' && this.heightScale1on1) {
			return this.heightScale1on1;
		}
		if(aspectRatio === '9:16' && this.heightScale9on16) {
			return this.heightScale9on16;
		}
		return this.heightScale;
	}

	/**
     * @returns {number | undefined}
     */
	get heightScale() {
		return this.data._heightScale;
	}

	/**
     * @param {number} val
     */
	set heightScale(val) {
		return this.data._heightScale = val;
	}

	/**
     * @returns {number | undefined}
     */
	get heightScale1on1() {
		return this.data._heightScale1on1;
	}

	/**
     * @param {number} val
     */
	set heightScale1on1(val) {
		return this.data._heightScale1on1 = val;
	}

	/**
     * @returns {number | undefined}
     */
	get heightScale9on16() {
		return this.data._heightScale9on16;
	}

	/**
     * @param {number} val
     */
	set heightScale9on16(val) {
		return this.data._heightScale9on16 = val;
	}

	get clientVersion() {
		return this.data.clientVersion || 0;
	}

	incClientVersion() {
		this.data.clientVersion = this.clientVersion + 1;
		return this;
	}

}

export class Utility {

	static async updateLotties(srcAnimation, destAnimationInput) {
		let destAnimationData;
    
		try {
			destAnimationData = JSON.parse(destAnimationInput);
		}
		catch(err) {
			console.error(err);
			return err.message;
		}
    
		let destAnimation = new Animation(destAnimationData);
        
		//Set baseclasses & id
		destAnimation.layers.forEach(l => l.setClass());
    
		//Check if default file animation timings & copy animations timing
		if(srcAnimation.animationTimes && srcAnimation.animationTimes.length) {
			destAnimation.animationTimes = srcAnimation.animationTimes.map(element => {
				let start = element.start;
				let end = element.end;
				return new AnimationTime({ start, end });
			});
		}
    
		//Set text in textLayers
		const destTextLayers = destAnimation.textLayers;
		const srcTextLayers = srcAnimation.textLayers;
    
		srcTextLayers.map((srcLayer, i) => {
			let className = srcLayer.getClass(LottieObject.CLASS_TYPE.TEXT);
			destTextLayers[i].setClass(LottieObject.CLASS_TYPE.TEXT, className);

			//Set text
			if(srcLayer.data.t.d.k[0].s.t) {
				destTextLayers[i].data.t.d.k[0].s.t = srcLayer.data.t.d.k[0].s.t;
			}
		});
    
		//Set colors
		const srcColors = srcAnimation.colors;
		const destColors = destAnimation.colors;

		destColors.forEach((colorIdentifier, i) => {
			const {parent} = colorIdentifier;
			const srcColor = srcColors[i];
			const allSrcColorPredicates = srcColor.className && srcColor.className.split(' ');
			const srcColorPredicates = allSrcColorPredicates && allSrcColorPredicates.length > 2 && allSrcColorPredicates.slice(-2);
			const lastSrcColorPredicate = srcColorPredicates && srcColorPredicates.length && srcColorPredicates.at(-1);
			const srcColorValue = srcColorPredicates && `${srcColorPredicates[0]}-${srcColorPredicates[1]} ${srcColorPredicates[0]} ${srcColorPredicates[1]}`;
			let className;
			if(!srcColorValue) {
				className = '';
			}
			else if(lastSrcColorPredicate && lastSrcColorPredicate.startsWith('-')) {
				const cleanSrcColorValue = lastSrcColorPredicate.slice(1);
				const colorGroupIndex = srcColors.findIndex(({parentId}) => parentId === cleanSrcColorValue);
				if(colorGroupIndex !== undefined) {
					const colorGroup = destColors[colorGroupIndex];
					className = srcColorValue.replaceAll(lastSrcColorPredicate, `-${colorGroup.parentId}`);
				}
			}
			else {
				className = srcColorValue;
			}
			parent.name = srcColor.name;
			parent.setClass('editable', (!!srcColor.parent.data._classNames.editable).toString());
			destAnimation.setColorClass(colorIdentifier, className);
		});

		//Set asset properties
		const destAssets = destAnimation.assets;
		const srcAssets = srcAnimation.assets;
    
        
		if(srcAssets.length && destAssets.length) {
			destAssets.forEach((destAsset, index) => {
				// Check is src asset is logo
				if(srcAssets[index] && srcAssets[index].getClass && srcAssets[index].getClass('logo') === 'logo') {
					destAsset.setClass(LottieObject.CLASS_TYPE.IMAGE, 'logo');
					// Clean asset from heavy redundant data
					destAsset.p = 'tmp.png';
					destAsset._src = 'tmp.png';
					delete destAsset._path;
				}
			});
		}
    
		// Font - To clean redundant unused data
		if(destAnimation.fonts && destAnimation.fonts.list) {
			destAnimation.fonts.list.forEach(font => {
				delete font.cache;
			});
		}
		return destAnimation.data;
	}
}

if(typeof window === 'object') {
	window.Lottie = {
		Asset,
		Animation,
		AnimationTime,
		ColorIdentifier,

		Shape,
		FillShape,
		GroupShape,
		RectangleShape,
		StrokeShape,
		TrimShape,
		TransformShape,

		Layer,
		PrecompositionLayer,
		ImageLayer,
		GroupLayer,
		ShapeLayer,
		TextLayer,

		Utility,
	};
}
