import React from 'react';
import { ControlProps, components } from 'react-select';

import styled from 'styled-components';


const StyledControl = styled(components.Control)`
  cursor: pointer;
` as any as typeof components.Control;

export default function StyledFontsSelectControl({ children, selectProps, ...props }: ControlProps) {
  const { menuIsOpen, setMenuIsOpen } = selectProps;

  return (
    <div onClick={() => setMenuIsOpen(!menuIsOpen)}>
      <StyledControl {...props}>{children}</StyledControl>
    </div>
  );
}
