
import * as Base from '../base/sequence/sequenceGenerationFlow';


export interface ISequenceGenerationFlow extends Base.ISequenceGenerationFlow {

}

export interface ISequenceGenerationFlowProperties extends Base.ISequenceGenerationFlowProperties {

}

export interface ISequenceGenerationFlowFilter extends Base.ISequenceGenerationFlowFilter {

}

export default class SequenceGenerationFlow<IType extends ISequenceGenerationFlow = ISequenceGenerationFlow> extends Base.SequenceGenerationFlow<IType> {
}