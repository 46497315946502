import React from 'react'
import User from '../../client/user';
import './Avatar.scss'

interface AvatarProps {
    user: User | undefined;
    style?: any;
    size: 'xss|xs|sm|md|lg';
}

export default function Avatar({ user, style, size, ...props }: AvatarProps & {}) {

    const sizeClass = `peech-avatar--${size}`
    const avatarClassNames = ['peech-avatar', `peech-avatar--${size}`].join(' ')



    const [src, setSrc] = React.useState<string | null>(null)

    React.useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setSrc(user.thumbUrl);
        }
        img.src = user.thumbUrl || '';
    }, [])

    if (src) {
        return (
            <div className={avatarClassNames} style={style}>
                <img src={user.thumbUrl} className={sizeClass} />
            </div >
        )
    }

    return <InitialsAvatar className={avatarClassNames} user={user} style={style} size={size} />
}

Avatar.defaultProps = {
    size: 'md',
    user: {
        name: "Dolev Bitran"
    }
};

interface InitialsAvatarProps extends AvatarProps {
    className: string;
}

function InitialsAvatar({ user, style, className, ...props }: InitialsAvatarProps) {

    function getInitials() {
        if (user.firstName?.length || user.lastName?.length) {
            const { firstName, lastName } = user 
            return (firstName?.[0]?.toUpperCase() || '') + (lastName?.[0]?.toUpperCase() || '')
        }

        const spaceIndex = user.name.indexOf(' ')

        if (spaceIndex > -1) {
            const firstName = user.name.substr(0, spaceIndex)
            const lastName = user.name.substr(spaceIndex + 1)
            return (firstName[0] + lastName[0]).toUpperCase()
        }
        return user.name[0].toUpperCase()
    }

    return <div
        className={[className, 'initials'].join(' ')}
        style={style} >
        {getInitials()}
    </div >
}