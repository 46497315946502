import styled from "@emotion/styled/macro"
import React from 'react'
import Asset from "../../client/asset"
import { sequenceState } from "../../state/sequence"
import Loader from "../../ui-components/Loader"
import Library from '../library'

const LibraryWrapper = styled.div`
    width: 100vw;
    height: 100%;
    padding: 0 38px;
    display: flex;
    flex-direction: column;

    .assets-group-content-wrapper {
        margin: 0px;
        padding-bottom: 0px;
    }
    .assets-group-content {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .assets-group-content-row {
        padding-top: 0px;
    }
   
`

const LoaderContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
interface MaterialsLibraryProps {
    onAssetSelected: Function;
    addSelectedAssets: Function;
    selectedAssets: Asset[];

}

export default function MaterialsLibrary({ onAssetSelected, addSelectedAssets, selectedAssets }: MaterialsLibraryProps) {
    const sequence = sequenceState.use();
    const [libraryLoaded, setLibraryLoaded] = React.useState(false);
    const isSelected = (asset) => selectedAssets.findIndex(a => a.sid === asset.sid) > -1
    const selectedNumber = (asset) => selectedAssets.findIndex(a => a.sid === asset.sid);

    return (

        <LibraryWrapper data-cy='libraryWrapper'>
            {!libraryLoaded && sequence.sid ?
                <Library isModal={true} isSelected={isSelected} onAssetSelected={onAssetSelected} addSelectedAssets={addSelectedAssets} selectedNumber={selectedNumber} setLibraryLoaded={setLibraryLoaded} />

                :
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>

            }
        </LibraryWrapper>


    )
}
