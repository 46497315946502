import { useLocation } from '@reach/router';
import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import PlayerModal from '../components/playerModal/PlayerModal';
import './YouTubeGallery.scss';

import { navigate } from 'gatsby';
import { AppContext } from '../lib/app-context';
import Button, { ButtonVariant } from '../ui-components/Button';

const MAX_CONCURRENT_LOADS = 4;
const LEARNING_CENTER_ROUTE = 'learning-center';

const TABS = {
  TREND: 0,
};

// interface YouTubeThumbnail {
//   url: string;
//   width: number;
//   height: number;
// }

// interface YouTubeThumbnails {
//   default: YouTubeThumbnail;
//   medium: YouTubeThumbnail;
//   high: YouTubeThumbnail;
//   standard: YouTubeThumbnail;
//   maxres: YouTubeThumbnail;
// }

// interface YouTubeResourceId {
//   videoId: string;
// }

// interface YouTubeSnippet {
//   title: string;
//   description: string;
//   channelTitle: string;
//   channelId: string;
//   thumbnails: YouTubeThumbnails;
//   resourceId: YouTubeResourceId;
// }

// interface YouTubeStatistics {
//   viewCount: number;
//   likeCount: number;
//   favoriteCount: number;
//   commentCount: number;
// }

// interface YouTubeItem {
//   id: string;
//   snippet: YouTubeSnippet;
//   statistics: YouTubeStatistics;
// }
// interface YouTubeChannelItem {
//   id: string;
//   snippet: YouTubeSnippet;
// }

async function fetchYouTubePlaylist(GOOGLE_API_KEY, playlistId, pageToken) {
  const base_url = 'https://www.googleapis.com/youtube/v3';
  const requestConfig = {
    method: 'GET',
    mode: 'cors',
  };

  let url;
  if (pageToken) {
    url = `${base_url}/playlistItems?&part=snippet&playlistId=${playlistId}&pageToken=${pageToken}&maxResults=50&key=${GOOGLE_API_KEY}`;
  } else {
    url = `${base_url}/playlistItems?&part=snippet&playlistId=${playlistId}&maxResults=50&key=${GOOGLE_API_KEY}`;
  }

  const result = await fetch(url, requestConfig);
  if (result.status != 200) {
    throw new Error(result.statusText);
  }
  const data = await result.json();

  return fetchYouTubeVideos(
    GOOGLE_API_KEY,
    data.items.map((item) => item.snippet.resourceId.videoId),
    data.items.map((item) => item.snippet.channelId)
  );
}

async function fetchYouTubeVideos(GOOGLE_API_KEY, videoIds, channelIds) {
  const url = `https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2Cstatistics&${videoIds
    .map((id) => `id=${id}`)
    .join('&')}&key=${GOOGLE_API_KEY}`;

  const config = {
    method: 'GET',
    mode: 'cors',
  };

  const result = await fetch(url, config);
  if (result.status != 200) {
    throw new Error(result.statusText);
  }
  const data = await result.json();

  const itemsChannels = data.items.map((item) => item.snippet.channelId);
  const channelslUrl = `https://www.googleapis.com/youtube/v3/channels?&part=snippet&${itemsChannels
    .map((id) => `id=${id}`)
    .join('&')}&key=${GOOGLE_API_KEY}`;
  const channelsresult = await fetch(channelslUrl, config);

  if (channelsresult.status != 200) {
    throw new Error(channelsresult.statusText);
  }
  const channelsdata = await channelsresult.json();
  const thumbsUrls = {};
  channelsdata.items.forEach((channel) => (thumbsUrls[channel.id] = channel.snippet.thumbnails.default.url));

  return [data.items, channelsdata.items, thumbsUrls];
}

// interface YouTubeGalleryItemProps {
//   item: YouTubeItem;
//   channelThumb: string;
//   onClick: React.MouseEventHandler<HTMLDivElement>;
// }

function YouTubeGalleryItem({ item, channelThumb, onClick, showViews }) {
  return (
    <Col xs="12" md="6" lg="4" xl="3" className="projects-col" onClick={onClick} style={{ cursor: 'pointer' }}>
      <div className="trend-wrapper">
        <div className="trend-item">
          <img className="trend-item-img" src={item.snippet.thumbnails.medium.url} />
          <div className="trend-details">
            <div className="trend-details-left">
              <img className="circle" src={channelThumb} />
            </div>
            <div className="trend-details-right">
              <div className="title">
                <span className="main-title">{item.snippet.title}</span>
              </div>
              <div className="details">
                <span className="second-title">{item.snippet.channelTitle}</span>
                {!!showViews && <span className="second-title"> Views: {item.statistics.viewCount}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}

// interface YouTubeGalleryProps {
//   playlistId: string;
// }

const playlists = {};

export default function YouTubeGallery({ playlistId, type, headerText, showSignInButton = false }) {
  const { config, user } = React.useContext(AppContext);
  const location = useLocation();
  const [playlist, setPlaylist] = React.useState();
  const [channelsThumbs, setChannelsThumbs] = React.useState({});
  // const [videoId, setVideoId] = React.useState();
  const isTrends = !type;
  const isLearningCenter = type === 'learning-center';
  const urlParams = new URLSearchParams(location.search);
  const v = urlParams.get('v');

  React.useEffect(() => {
    if (playlist || !playlistId) {
      return;
    }

    fetchYouTubePlaylist(config.GOOGLE_API_KEY, playlistId).then(([items, channels, thumbUrls]) => {
      playlists[playlistId] = items;
      const thumbs = channelsThumbs;
      channels.forEach((channel) => (thumbs[channel.id] = channel.snippet.thumbnails.default.url));
      setChannelsThumbs(thumbUrls);
      setPlaylist(items);
    });
  }, [playlistId]);

  const teamRef = React.useRef();
  const placeHolderRef = React.useRef();

  const trendsItems = [
    { name: 'Trending now', value: TABS.TREND, ref: teamRef },
    { name: '', value: '', ref: placeHolderRef },
  ];

  const videoId = React.useMemo(() => {
    const match = playlist?.find((item) => item.id === v);
    if (match) {
      return match.id;
    }
    return null;
  }, [playlist, v]);

  const onSelectedVideo = React.useCallback((videoId) => {
    if (videoId) {
      const urlParams = new URLSearchParams(location.search);
      urlParams.set('v', videoId);
      navigate(`${location.pathname}?${urlParams.toString()}`);
    }
  }, []);

  const onVideoClose = React.useCallback(() => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.delete('v');
    navigate(`${location.pathname}?${urlParams.toString()}`);
  }, []);

  return (
    <>
      <div className="trends">
        {headerText && <h3 className="learning-center-title">{headerText}</h3>}
        <div className="projects-group">
          <div className="projects-group-content-wrapper">
            <Row className="projects-group-content">
              {playlist &&
                playlist.map((item) => (
                  <YouTubeGalleryItem
                    key={item.id}
                    onClick={() => {
                      onSelectedVideo(item.id);
                    }}
                    item={item}
                    channelThumb={channelsThumbs[item.snippet.channelId]}
                    showViews={!isLearningCenter}
                  />
                ))}
            </Row>
            <PlayerModal show={!!videoId} onHide={onVideoClose} videoId={videoId}>
              {showSignInButton && (
                <Button
                  onClick={() => {
                    const url = new URL(config.NEW_UI_URL);
                    window.location.assign(url);
                  }}
                  style={{ height: 28, marginTop: 10 }}
                  variant={ButtonVariant.Primary}
                >
                  sign in
                </Button>
              )}
            </PlayerModal>
          </div>
        </div>
      </div>
    </>
  );
}
