import { ReactEventHandler, useState } from 'react';
import { PopoverPosition } from 'react-tiny-popover';
import styled from 'styled-components/macro';

import { COLORS } from '../../../constants/colors';
import SVG from '../../components/svg';
import Popover from './Popover';

const DropdownMenu = styled.div`
  border-radius: 6px;
  background-color: ${COLORS.peech_neutral_white};
`;

const DropdownItem = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ disabled }): string => (disabled ? COLORS.peech_gray_300 : COLORS.peech_gray_500)};
  padding: 5.5px 8px 5.5px 6px;
  background-color: ${COLORS.peech_neutral_white};
  svg {
    height: 24px;
    width: 24px;
    margin-top: -3px;
    margin-left: 0;
      path {
        fill: ${({ disabled }): string => (disabled ? COLORS.peech_gray_300 : COLORS.peech_gray_500)};
      }
    
  }
  svg[name="delete"] {
    margin-top: 0px;
  }
  svg[name="translate"] {
    margin-top: 10px;
  }
  &:hover {
    background-color: ${COLORS.peech_gray_100};
  }
  cursor: pointer;
  pointer-events: ${({ disabled }): string => (disabled ? 'none' : 'all')};
`;

const TriggerWrapper = styled.div`
  width: 36px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TriggerButton = styled.div`
  width: 26px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLORS.peech_neutral_white};
  border-radius: 4px;
  svg {
    margin-top: 2px;
  }
  &:hover {
    background-color: ${COLORS.peech_gray_100};
  }
`;

interface OptionsMenuPopoverProps {
  options: Object[];
  onOpen?: () => void;
  onClose?: () => void;
  positions?: PopoverPosition[];
  align?: string;
  scrollingContainerRef?: React.MutableRefObject<HTMLElement>;
}

export default function OptionsMenuPopover({
  onOpen,
  onClose,
  options,
  positions,
  align,
  scrollingContainerRef,
}: OptionsMenuPopoverProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={(e) => {
        e?.stopPropagation();
        onOpen?.();
        setIsOpen(true);
      }}
      onClose={(e) => {
        e?.stopPropagation();
        onClose?.();
        setIsOpen(false)
      }}
      offsetY={5}
      positions={positions || ['bottom', 'left']}
      align={align || 'end'}
      scrollingContainerRef={scrollingContainerRef}
      clickOutsideCapture={true}
      content={
        <DropdownMenu>
          {options.map(
            (option) =>
              !option.hide && (
                <DropdownItem
                  key={option.key}
                  onClick={(e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    option.onClick && option.onClick();
                    setIsOpen(false);
                  }}
                  disabled={option.disabled}
                >
                  <SVG name={option.icon} title={option.toolTip} />
                  {option.text}
                </DropdownItem>
              )
          )}
        </DropdownMenu>
      }
    >
      <TriggerWrapper
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <TriggerButton>
          <SVG className="options" name="options" title="options" />
        </TriggerButton>
      </TriggerWrapper>
    </Popover>
  );
}
