
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'
import Content, { IContent, IContentFilter, IContentProperties } from '../content'

export enum CuePointStatus {
	PENDING = 0,
	ACTIVE = 1,
	INACTIVE = 2,
	DELETED = 3,
	ERRONEOUS = 5,
};

export enum CuePointType {
	LOGO = 1,
	CLOSED_CAPTION = 2,
	LOCATION = 4,
	PERSON = 5,
	ORG = 6,
	ORGANIZATION = 6,
	WORK_OF_ART = 7,
	CONSUMER_GOOD = 8,
	PRODUCT = 8,
	PHONE_NUMBER = 9,
	ADDRESS = 10,
	DATE = 11,
	NUMBER = 12,
	CARDINAL = 12,
	PRICE = 13,
	MONEY = 13,
	TITLE = 14,
	CREDIT = 15,
	SPEAKER = 16,
	NAME = 17,
	BACKGROUND = 18,
	FRAME = 19,
	WATERMARK = 20,
	ORDINAL = 21,
	NORP = 22,
	FAC = 23,
	GPE = 24,
	LOC = 25,
	EVENT = 26,
	LAW = 27,
	LANGUAGE = 28,
	TIME = 29,
	PERCENT = 30,
	QUANTITY = 31,
	INTRO = 32,
	OUTRO = 33,
	TRANSITION = 34,
};

export interface ICuePoint extends IContent {
	sequenceSid?: string | null;
	chapterSid?: string | null;
	type?: CuePointType | null;
	startTime?: number | null;
	endTime?: number | null;
	srcStartTime?: number | null;
	srcEndTime?: number | null;
}

export interface ICuePointProperties extends IContentProperties {
	sequenceSid?: boolean;
	chapterSid?: boolean;
	type?: boolean;
	startTime?: boolean;
	endTime?: boolean;
	srcStartTime?: boolean;
	srcEndTime?: boolean;
}

export interface ICuePointFilter extends IContentFilter {
	sequenceSidEqual?: string;
}

export class CuePoint<IType extends ICuePoint = ICuePoint, ITypeProperties extends ICuePointProperties = ICuePointProperties> extends Content<IType, ITypeProperties> implements ICuePoint {
	
	static className = 'CuePoint';
	static NAME = 'cuePoint';
	static PKS = [
		'sequenceSid',
		'sid',
	];
	static PROPERTIES = {...Content.PROPERTIES, 
		sequenceSid: true,
		chapterSid: true,
		type: true,
		startTime: true,
		endTime: true,
		srcStartTime: true,
		srcEndTime: true,
	};
	
	/**
	 * @deprecated use CuePointStatus instead
	 */
	static STATUS: any = {
		PENDING: CuePointStatus.PENDING,
		ACTIVE: CuePointStatus.ACTIVE,
		INACTIVE: CuePointStatus.INACTIVE,
		DELETED: CuePointStatus.DELETED,
		ERRONEOUS: CuePointStatus.ERRONEOUS,
	};
	
	/**
	 * @deprecated use CuePointType instead
	 */
	static TYPE: any = {
		LOGO: CuePointType.LOGO,
		CLOSED_CAPTION: CuePointType.CLOSED_CAPTION,
		LOCATION: CuePointType.LOCATION,
		PERSON: CuePointType.PERSON,
		ORG: CuePointType.ORG,
		ORGANIZATION: CuePointType.ORGANIZATION,
		WORK_OF_ART: CuePointType.WORK_OF_ART,
		CONSUMER_GOOD: CuePointType.CONSUMER_GOOD,
		PRODUCT: CuePointType.PRODUCT,
		PHONE_NUMBER: CuePointType.PHONE_NUMBER,
		ADDRESS: CuePointType.ADDRESS,
		DATE: CuePointType.DATE,
		NUMBER: CuePointType.NUMBER,
		CARDINAL: CuePointType.CARDINAL,
		PRICE: CuePointType.PRICE,
		MONEY: CuePointType.MONEY,
		TITLE: CuePointType.TITLE,
		CREDIT: CuePointType.CREDIT,
		SPEAKER: CuePointType.SPEAKER,
		NAME: CuePointType.NAME,
		BACKGROUND: CuePointType.BACKGROUND,
		FRAME: CuePointType.FRAME,
		WATERMARK: CuePointType.WATERMARK,
		ORDINAL: CuePointType.ORDINAL,
		NORP: CuePointType.NORP,
		FAC: CuePointType.FAC,
		GPE: CuePointType.GPE,
		LOC: CuePointType.LOC,
		EVENT: CuePointType.EVENT,
		LAW: CuePointType.LAW,
		LANGUAGE: CuePointType.LANGUAGE,
		TIME: CuePointType.TIME,
		PERCENT: CuePointType.PERCENT,
		QUANTITY: CuePointType.QUANTITY,
		INTRO: CuePointType.INTRO,
		OUTRO: CuePointType.OUTRO,
		TRANSITION: CuePointType.TRANSITION,
	};
	
	constructor(sequenceSid: string | undefined = undefined, sid : string | undefined = undefined) {
		super(sid);
		this.set({sequenceSid} as IType)
	}
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get sequenceSid(): string | null | undefined {
		return this.values.sequenceSid
	}
			
	set sequenceSid(value: string | null | undefined) {
		if(this.values.sequenceSid !== value) {
			this.propertyChanged('sequenceSid', value);
			this.values.sequenceSid = value;
		}
	}
			
	get chapterSid(): string | null | undefined {
		return this.values.chapterSid
	}
			
	set chapterSid(value: string | null | undefined) {
		if(this.values.chapterSid !== value) {
			this.propertyChanged('chapterSid', value);
			this.values.chapterSid = value;
		}
	}
			
	get type(): CuePointType | null | undefined {
		return this.values.type
	}
			
	set type(value: CuePointType | null | undefined) {
		if(this.values.type !== value) {
			this.propertyChanged('type', value);
			this.values.type = value;
		}
	}
			
	get startTime(): number | null | undefined {
		return this.values.startTime
	}
			
	set startTime(value: number | null | undefined) {
		if(this.values.startTime !== value) {
			this.propertyChanged('startTime', value);
			this.values.startTime = value;
		}
	}
			
	get endTime(): number | null | undefined {
		return this.values.endTime
	}
			
	set endTime(value: number | null | undefined) {
		if(this.values.endTime !== value) {
			this.propertyChanged('endTime', value);
			this.values.endTime = value;
		}
	}
			
	get srcStartTime(): number | null | undefined {
		return this.values.srcStartTime
	}
			
	set srcStartTime(value: number | null | undefined) {
		if(this.values.srcStartTime !== value) {
			this.propertyChanged('srcStartTime', value);
			this.values.srcStartTime = value;
		}
	}
			
	get srcEndTime(): number | null | undefined {
		return this.values.srcEndTime
	}
			
	set srcEndTime(value: number | null | undefined) {
		if(this.values.srcEndTime !== value) {
			this.propertyChanged('srcEndTime', value);
			this.values.srcEndTime = value;
		}
	}
			
	
	static get<IType extends ICuePoint = ICuePoint>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends ICuePointFilter = ICuePointFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends ICuePointFilter = ICuePointFilter, IType extends ICuePoint = ICuePoint>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
	
	async reoverlay(): Promise<boolean | null> {
		var action = `reoverlay`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.reoverlay !== undefined && data.reoverlay !== null) {
			return data.reoverlay;
		}
		return null;
	}
	
	async activate(): Promise<boolean | null> {
		var action = `activate`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.activate !== undefined && data.activate !== null) {
			return data.activate;
		}
		return null;
	}
	
	async deactivate(): Promise<boolean | null> {
		var action = `deactivate`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.deactivate !== undefined && data.deactivate !== null) {
			return data.deactivate;
		}
		return null;
	}
}
