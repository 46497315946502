import React from 'react';
import classNames from 'classnames';

import './ImageWithPlaceholder.scss';
import { AssetStatus } from '../../client/asset';
import styled from '@emotion/styled';
import { COLORS } from '../../../constants/colors';
import { SequenceStatus } from '../../client/sequence';
import Loader from '../Loader';

const DarkOverlayDiv = styled.div<{ dark: boolean }>`
  position: absolute;
  aspect-ratio: 16/9;
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  color: ${COLORS.peech_neutral_white};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 0 24px 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  border-radius: 8px;
  opacity: ${({ dark }): string => (dark ? '1' : '0')};
  transition: opacity;
  transition-duration: 1500ms;
  transition-timing-function: cubic-bezier(1, 0.12, 1, 0.12);
  pointer-events: none;
  .peech-loader{
    margin-top: 0 !important;
  }
`;

interface ImageWithPlaceholderProps {
  className?: string;
  id?: string;
  style?: object;
  alt?: string;
  source: string;
  placeholderSrc?: string;
  status?: AssetStatus | SequenceStatus;
  darkOverlay?: boolean;
}

const PEECH_THUMB = '/images/peech_thumbnail.png';

const MAX_CONCURRENT_LOADS = 8;

interface IImageQueueItem {
  img: HTMLImageElement;
  src: string;
  canceled?: boolean;
}

/**
 * @type {{
 *  currentlyLoading: number,
 *  queue: IImageQueueItem[]
 * }}
 */
const loadQueue = {
  currentlyLoading: 0,
  queue: [],
};

export default function ImageWithPlaceholder({
  source,
  className,
  placeholderSrc,
  alt,
  status,
  darkOverlay,
  ...rest
}: ImageWithPlaceholderProps) {
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const placeholder = React.useRef<HTMLImageElement>();
  const image = React.useRef<HTMLImageElement>();
  const placeHolderClassNames = classNames('with-placeholder placeholder', {
    [className]: !!className,
    loaded: loaded,
  });
  const imageClassNames = classNames('with-placeholder image', { [className]: !!className, loaded: loaded });

  if (darkOverlay) {
    console.log({ statusChanging: status, darkOverlay, alt });
  }

  function onDone() {
    while (loadQueue.queue.length) {
      let nextItem: IImageQueueItem = loadQueue.queue.shift();
      if (nextItem && nextItem.img.isConnected && !nextItem.canceled) {
        nextItem.img.src = nextItem.src;
        return;
      }
    }

    loadQueue.currentlyLoading--;
  }

  function onLoad() {
    setLoaded(true);
    onDone();
  }

  React.useEffect(() => {
    if (!image.current) {
      return;
    }

    image.current.addEventListener('load', onLoad);
    image.current.addEventListener('error', onDone);

    let queueItem;
    if (loadQueue.currentlyLoading < MAX_CONCURRENT_LOADS) {
      loadQueue.currentlyLoading++;
      image.current.src = source;
    } else {
      queueItem = {
        img: image.current,
        src: source,
      };
      loadQueue.queue.push(queueItem);
    }

    return () => {
      if (queueItem) {
        queueItem.canceled = true;
      }
      image.current?.removeEventListener('load', onLoad);
      image.current?.removeEventListener('error', onDone);
    };
  }, [image.current, status]);

  return (
    <>
      <img
        ref={placeholder}
        src={placeholderSrc || PEECH_THUMB}
        className={placeHolderClassNames}
        {...rest}
        draggable={false}
      />
      <img
        className={imageClassNames}
        {...rest}
        ref={image}
        loading="eager"
        alt={loaded ? alt || '' : ''}
        draggable={false}
      />
      {darkOverlay && (
        <DarkOverlayDiv dark={darkOverlay}>
          {status === AssetStatus.PENDING ? (
            'Uploading...'
          ) : status === AssetStatus.PROCESSING ? (
            'Preparing footage...'
          ) : status === SequenceStatus.TRANSLATING ? (
            <Loader size="sm" />
          ) : null}
        </DarkOverlayDiv>
      )}
    </>
  );
}
