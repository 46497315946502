import styled from "@emotion/styled/macro";

export const PeechProUpgradeButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 52px;
  padding-inline: 16px;
  border: none;
  border-radius: 8px;
  background: linear-gradient(90deg, #ea429d 0%, #ea429d 9.11%, #ea75b4 24.25%, #ae8bfa 54.15%, #1f9ffb 99.42%);
  font-family: 'Open Sans', sans-serif;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #fff;
  white-space: pre;
  overflow: hidden;
  outline: none;
  justify-content: center;
  svg {
    margin-right: 20px;
    width: 24px;
    height: 24px;
    flex: 0 0 auto;
  }
`;