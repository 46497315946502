import { entity } from 'simpler-state'
import { decodeHtml } from '../components/cue-points/editableTextItem'
import { onCuePointChange } from './cuePoints'

export const MENUS = {
	// CONTENT: 'content',
	BRAND: 'brand',
	BRAND_KIT: 'brand-kit',
	INTRO_OUTRO: 'outro',
	CAPTIONS: 'captions',
	VISUALS: 'visuals',
	SPEAKERS: 'speakers',
	AUDIO: 'audio',
	CREDITS: 'credits',
	FRAME: 'frame',
}

export const MENUS_TOOLTIP_TEXT = {
	BRAND: 'Here is your watermark logo, so everyone knows who made this video',
	INTRO_OUTRO: 'Add a branded intro & outro and tell your viewers what they should do next!',
	CAPTIONS: 'Edit your subtitles, press enter to split a caption into 2, or backspace at the beginning to combine 2 subtitles into 1',
	VISUALS: 'You can edit each visuals time, text and colors here',
	SPEAKERS: 'You can edit each speaker titles time, text and colors here',
	AUDIO: 'Choose any song, Peech will make sure it sounds just right in your video',
	FRAME: 'Add a background around your video to give it a branded look',
}

export const HIGHLIGHT_TOOLTIP_TEXT = 'Customize your subtitles style and position for each video while staying on-brand'



export function onMenuTextFieldChange(event, cuePoint, field = 'text') {
	const { target, key, keyCode } = event
	if (keyCode === 13 || key === "Enter") {
		event.preventDefault()
		return event.stopPropagation()
	}
	const text = target.textContent;
	!cuePoint.origin[field] && (cuePoint.origin[field] = cuePoint[field])
	cuePoint[field] = decodeHtml(text).replace(/[\n\r]+/g, '\n')
}

export function onMenuTextFieldBlur({ target }, cuePoint, field = 'text') {
	var text = target?.textContent
	cuePoint[field] = decodeHtml(text).replace(/[\n\r]+/g, '\n')
	onCuePointChange(cuePoint, true)
	delete cuePoint.origin[field]
}

export const menuState = entity(false)
export const googleLoginState = entity(null)
export const menuHeaderTooltipTextState = entity(null);

export const shouldShowMusicLibraryState = entity(true);
export const shouldEnableUploadMusicState = entity(true);
export const shouldDisplayDenoiseState = entity(false);
export const shouldEnableDenoiseState = entity(false);
export const shouldEnableOverrideVolumeState = entity(true);
export const lastSearchedMusicTagState = entity(null);
export const shouldShowLoaderInMenuState = entity(false);


export const shouldShowIntroMenuState = entity(true);
export const introOutroDurationChangeState = entity(false);
