import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { sequenceState } from '../state/sequence';
import { useSequenceCloneOptions } from '../ui-components/SequenceCloneOptions/useSequenceCloneOptions';

type CloneSequenceMenuItem = {
  ratio?: string;
  key?: string;
  icon?: string;
  toolTip?: string;
  text?: Element;
  hide?: boolean;
  onClick?: () => void;
};

const RATIO_OPTIONS = {
  '1:1': 'Square',
  '9:16': 'Vertical',
  '16:9': 'Horizontal',
};

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-inline: 6px;
`;

const Radio = styled.input`
  width: 20px;
  height: 20px;
  border: 1px solid #ea429d;
  margin-right: 4px !important;

  @media (max-width: 375px) {
    margin-right: 0;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SelectorWrapper = styled(FlexRow)`
  width: ${(props) => props.width || '320px'};
  flex-direction: row;
  justify-content: space-around;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  margin-block: 10px;

  @media (max-width: 375px) {
    font-size: 11px;
    width: 280px;
    margin-block: 14px;
    flex-direction: column;
  }
`;

const SpanLabel = styled.span`
  color: #999999;
  font-size: 12px;

  @media (max-width: 375px) {
    margin-bottom: 10px;
  }
`;

const ActionButton = styled.a`
  display: flex;
  color: #fff;
  text-decoration: none;
  height: 28px;
  padding: 18px 10px 18px 10px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  background: #1f9ffb;
  cursor: pointer;
  align-items: center;
  width: 120px;
  justify-content: space-around;
  &:hover {
    color: #fff !important;
    background: #7ec7fb;
  }
  &:active {
    color: #fff !important;
    background: #1671b2;
  }
  svg {
    width: 24px;
    padding: 0;
    fill: #ea429d;
  }
  svg[name='translate'] {
    margin-top: 10px;
  }
  svg[name='diamond-gradient'] {
    margin-left: 4px !important;
  }
`;

export const SequenceClonePicker = ({ aspectRatio }) => {
  const sequence = sequenceState.use();

  const [sequenceCloneData, setSequenceCloneData] = useState(null);

  useEffect(() => {
    setSequenceCloneData(sequenceCloneOptions.find((option) => option.ratio !== aspectRatio));
  }, []);

  const sequenceCloneOptions = useSequenceCloneOptions({
    sequence,
    trigger: 'project-download-modal',
    onCloneSuccess: () => {
      navigate(`/`);
    },
  });

  return (
    <>
      <SelectorWrapper width={'320px'}>
        <SpanLabel>Aspect ratio: </SpanLabel>
        <FlexRow>
          {sequenceCloneOptions.map(
            (option: CloneSequenceMenuItem) =>
              !option.hide &&
              option.ratio !== aspectRatio && (
                <RadioWrapper>
                  <Radio
                    name="ratio"
                    id={option.key}
                    checked={sequenceCloneData?.ratio === option.ratio}
                    type={'radio'}
                    key={option.key}
                    onChange={() => {
                      setSequenceCloneData(option);
                    }}
                  />
                  <label for={option.key}>
                    {' '}
                    {RATIO_OPTIONS[option.ratio]} ({option.ratio})
                  </label>
                </RadioWrapper>
              )
          )}
        </FlexRow>
      </SelectorWrapper>
      <ActionButton
        onClick={() => {
          sequenceCloneData?.onClick();
        }}
      >
        Create!
      </ActionButton>
    </>
  );
};
