
import * as Base from './base/shopper';

export const ShopperStatus = Base.ShopperStatus;

export interface IShopper extends Base.IShopper {

}

export interface IShopperProperties extends Base.IShopperProperties {

}

export interface IShopperFilter extends Base.IShopperFilter {

}

export default class Shopper<IType extends IShopper = IShopper, ITypeProperties extends IShopperProperties = IShopperProperties> extends Base.Shopper<IType, ITypeProperties> implements IShopper {
}