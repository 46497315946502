
import * as Base from './base/feature';

export const FeatureFeatureName = Base.FeatureFeatureName;

export interface IFeature extends Base.IFeature {

}

export interface IFeatureProperties extends Base.IFeatureProperties {

}

export interface IFeatureFilter extends Base.IFeatureFilter {

}

export default class Feature<IType extends IFeature = IFeature> extends Base.Feature<IType> {
}