import {AppContext} from "../../../../lib/app-context";
import {StyleVerticalAlign} from "../../../../client/base/sequence/style";
import React from "react";
import styled from "@emotion/styled";

const TextWrapper = styled.div`
  width: 64%;
  display: flex;
  justify-content: center;
`;

const RandomSubtitlePositionOptionButton = styled.div`
  z-index: 0;
  display: flex;
  width: 100%;
  height: 32px;
  border-radius: 8px;
  background-color: #f2f2f2;
  transition: border-color 250ms ease-out;
  cursor: pointer;
  padding: 0;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  &:hover {
    border: 1px solid #666666;
  }
  &:active {
    background-color: #CCCCCC;
  }
`;

const Icon =
    <svg width="46" height="29" viewBox="0 0 46 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="46" height="29" rx="8" fill="transparent" />
        <g clipPath="url(#clip0_5233_263516)">
            <path
                d="M29.7685 16.0775L28.5902 17.2558L30.5002 19.1667H30.1819C27.3619 19.1667 25.5686 17.89 23.9769 16.2434L23.7269 16.5334C23.4536 16.8501 23.1719 17.1759 22.8786 17.5034C24.7119 19.6262 27.3803 20.8436 30.1852 20.8367H30.5002L28.5894 22.7475L29.7677 23.9258L32.5119 21.1817C33.1625 20.5308 33.1625 19.4758 32.5119 18.825L29.7685 16.0775Z"
                fill="#999999"
            />
            <path
                d="M30.1825 10.8333H30.5L28.59 12.7441L29.7683 13.9224L32.5125 11.1783C33.1631 10.5274 33.1631 9.47244 32.5125 8.82158L29.7683 6.0774L28.59 7.25572L30.5 9.16666H30.1817C26.0509 9.16666 23.7925 11.3917 21.7434 13.7317C19.7192 11.4458 17.2858 9.16666 12.9667 9.16666V10.8333C16.6867 10.8333 18.6942 12.78 20.6409 15C18.6942 17.22 16.6867 19.1667 12.9667 19.1667V20.8333C17.8067 20.8333 20.28 17.97 22.4667 15.445C24.5117 13.0733 26.4475 10.8333 30.1825 10.8333Z"
                fill="#999999"
            />
        </g>
        <defs>
            <clipPath id="clip0_5233_263516">
                <rect width="20" height="20" fill="white" transform="translate(13 5)" />
            </clipPath>
        </defs>
    </svg>


interface Props {
    onChange(value: StyleVerticalAlign): void;
}

export const RandomSubtitlePositionButton = ({ onChange }: Props) => {
    return <>
            <>
              <RandomSubtitlePositionOptionButton
                onClick={() => onChange(StyleVerticalAlign.RANDOM)}>
                {Icon}
                <TextWrapper>Randomize all subtitles</TextWrapper>
         </RandomSubtitlePositionOptionButton>
      </>
    </>
};
