import React from 'react';
import { sequenceState } from '../state/sequence';
import { AppContext } from '../lib/app-context';
import { entity } from 'simpler-state';
import { uniq, isEmpty } from 'lodash/fp';
import { DEFAULT_FONT, getAssetFamilyName } from '../utils/font.utils';
import {
  getCloseCaptionsCaseType,
  getCloseCaptionDefaultPosition,
  getCloseCaptionFontColor,
  getCloseCaptionTransform,
} from '../utils/closeCpation.utils';
import { ZINDEX } from '../../constants/zIndex.const';
import useUserPs from '../hooks/useUserPs';
export const fontVersionState = entity(0);

export const fontLoadedState = entity(false);
export const sequenceFontDirectoryState = entity({
  header: {
    regular: null,
    bold: null,
  },
  body: {
    regular: null,
    bold: null,
  },
  captions: {
    regular: null,
  },
});

/**
 *
 * @param {{
 * isPreview: Boolean,
 * }} props
 * @returns
 */
export default function PlayerStyle({ isPreview, aspectRatio, setFontIsLoaded }) {
  const { user, config } = React.useContext(AppContext);
  const sequenceStyle = sequenceState.use((sequence) => sequence?.style) || {};
  const {
    fontFamily,
    headerFontFamily,
    fontAssetSid,
    bodyBoldFileName,
    bodyRegularFileName,
    headerFontAssetSid,
    headerBoldFileName,
    headerRegularFileName,
    captionsFontAssetSid,
    captionsRegularFileName,
    captionsFontFamily,
    captionsFontColor,
    captionsFontColorIndex,
    captionsVerticalAlign,
    captionsPosition,
    captionsCaseType,
  } = sequenceStyle;

 
  const colors = sequenceState.use((sequence) => sequence?.colors);
  const sequenceAspectRatio = sequenceState.use((sequence) => sequence?.aspectRatio);
  const sequenceTextDirection = sequenceState.use((sequence) => sequence?.textDirectionByLanguage);
  aspectRatio = aspectRatio || sequenceAspectRatio || '16:9';

  const [w, h] = aspectRatio.split(':');
  const ratio = (100 / (w / h)).toFixed(2);

  const highlightColor =
    sequenceState.use((sequence) => {
      const highlightColorIndex = sequence?.style?.highlightColorIndex || 2;
      return (sequence?.colors || []).find((c) => c.dominancy === highlightColorIndex);
    }
    );

  // TODO: rename to sequence styled font
  //   const [loadedFonts, setLoadedFonts] = React.useState(loadedFontsDefault);
  const sequenceFontDirectory = sequenceFontDirectoryState.use();
  const loadedFontsObj = React.useRef({});
  const presetSid = sequenceState.use((sequence) => sequence.presetSid);
  
  const captionStyle = React.useMemo(() => {
    return {
      textTransform: getCloseCaptionsCaseType(captionsCaseType),
      transform: getCloseCaptionTransform(captionsVerticalAlign),
      position: captionsPosition || getCloseCaptionDefaultPosition(sequenceAspectRatio, captionsVerticalAlign),
      fontColor: getCloseCaptionFontColor(captionsFontColorIndex, captionsFontColor, colors),
    };
  }, [captionsFontColor, captionsFontColorIndex, captionsPosition, captionsVerticalAlign, captionsCaseType, presetSid]);

  function getFontStyling(fontType, fontVariant) {
    const font = sequenceFontDirectory[fontType][fontVariant];
    if (font?.fontFamily) {
      return `font-family: "${font.fontFamily || DEFAULT_FONT}", "${DEFAULT_FONT}"; 
            font-weight: ${font.variant?.slice(0, 3) || 400};
            ${font.variant?.at(-1) === 'i' ? 'font-style: italic;' : ''}`;
    } else {
      return `font-family: "${font || DEFAULT_FONT}", "${DEFAULT_FONT}";`;
    }
  }

  function getSequenceFont() {
    return `
        .captions-element {
            ${getFontStyling('captions', 'regular')}
        }
        `;
  }

  const [sequenceFontStyling, setSequenceFontStyling] = React.useState(getSequenceFont());

  const incrementFontVersion = () =>
    fontVersionState.set((version) => {
      version++;
      console.log('font-version', version);
      return version;
    });

  // new set font to be load
  React.useEffect(async () => {
    if (isEmpty(sequenceStyle)) {
      return;
    }
    const fontsToLoad = [];
    const fontAssetsToLoad = {};
    const gfontsToLoad = {};
    const modifiedFontMetadata = { ...sequenceFontDirectory };

    // header font
    const { header, body, captions } = modifiedFontMetadata; // loadedFonts

    if (headerFontAssetSid && headerRegularFileName && headerBoldFileName) {
      // user assets file
      const headerFontAssetFamilyName = getAssetFamilyName(headerFontAssetSid, headerRegularFileName);
      if (header.regular?.fontFamily !== headerFontAssetFamilyName) {
        console.log('prepare font asset header-regular', { headerFontAssetSid, headerRegularFileName });
        const fontAssetFiles = fontAssetsToLoad[headerFontAssetSid] || [];
        fontAssetsToLoad[headerFontAssetSid] = uniq([...fontAssetFiles, headerRegularFileName]);
        modifiedFontMetadata.header.regular = {
          fontFamily: headerFontAssetFamilyName,
          variant: '400',
        };
      }
      const headerBoldAssetFamilyName = getAssetFamilyName(headerFontAssetSid, headerBoldFileName);
      if (header.bold?.fontFamily !== headerBoldAssetFamilyName) {
        console.log('prepare font asset header-bold', { headerFontAssetSid, headerBoldFileName });
        const fontAssetFiles = fontAssetsToLoad[headerFontAssetSid] || [];
        fontAssetsToLoad[headerFontAssetSid] = uniq([...fontAssetFiles, headerBoldFileName]);
        modifiedFontMetadata.header.bold = {
          fontFamily: headerBoldAssetFamilyName,
          variant: '400',
        };
      }
    } else if (
      // user font from google fonts list
      headerFontFamily &&
      (header.regular?.fontFamily !== headerFontFamily ||
        header.regular?.variant !== headerRegularFileName ||
        header.bold?.fontFamily !== headerFontFamily ||
        header.bold?.variant !== headerBoldFileName)
    ) {
      const gFontVariant = gfontsToLoad[headerFontFamily] || [];
      gfontsToLoad[headerFontFamily] = uniq([
        ...gFontVariant,
        headerRegularFileName || '400',
        headerBoldFileName || '700',
      ]);
      modifiedFontMetadata.header.regular = {
        fontFamily: headerFontFamily,
        variant: headerRegularFileName || '400',
      };

      modifiedFontMetadata.header.bold = {
        fontFamily: headerFontFamily,
        variant: headerBoldFileName || '700',
      };
    }

    //body font
    if (fontAssetSid && bodyRegularFileName && bodyBoldFileName) {
      const bodyRegularAssetFamilyName = getAssetFamilyName(fontAssetSid, bodyRegularFileName);
      if (body.regular?.fontFamily !== bodyRegularAssetFamilyName) {
        console.log('prepare font asset body-regular', { fontAssetSid, bodyRegularFileName });
        fontAssetsToLoad[fontAssetSid] = uniq([...(fontAssetsToLoad[fontAssetSid] || []), bodyRegularFileName]);
        modifiedFontMetadata.body.regular = {
          fontFamily: bodyRegularAssetFamilyName,
          variant: '400',
        };
      }
      const bodyBoldAssetFamilyName = getAssetFamilyName(fontAssetSid, bodyBoldFileName);
      if (body.bold?.fontFamily !== bodyBoldAssetFamilyName) {
        console.log('prepare font asset body-bold', { fontAssetSid, bodyBoldFileName });
        fontAssetsToLoad[fontAssetSid] = uniq([...(fontAssetsToLoad[fontAssetSid] || []), bodyBoldFileName]);
        modifiedFontMetadata.body.bold = {
          fontFamily: bodyBoldAssetFamilyName,
          variant: '400',
        };
      }
    } else if (
      fontFamily &&
      (body.regular?.fontFamily !== fontFamily ||
        body.regular?.variant !== bodyRegularFileName ||
        body.bold?.fontFamily !== fontFamily ||
        body.bold?.variant !== bodyBoldFileName)
    ) {
      const gFontVariant = gfontsToLoad[fontFamily] || [];
      gfontsToLoad[fontFamily] = uniq([...gFontVariant, bodyRegularFileName || '400', bodyBoldFileName || '700']);
      modifiedFontMetadata.body.regular = {
        fontFamily,
        variant: bodyRegularFileName || '400',
      };

      modifiedFontMetadata.body.bold = {
        fontFamily,
        variant: bodyBoldFileName || '700',
      };
    }

    // CC font
    if (captionsFontAssetSid && captionsRegularFileName) {
      const captionsRegularAssetFamilyName = getAssetFamilyName(captionsFontAssetSid, captionsRegularFileName);
      if (captions.regular?.fontFamily !== captionsRegularAssetFamilyName) {
        console.log('prepare font captions-regular', { captionsFontAssetSid, captionsRegularFileName });
        const fontAssetFiles = fontAssetsToLoad[captionsFontAssetSid] || [];
        fontAssetsToLoad[captionsFontAssetSid] = uniq([...fontAssetFiles, captionsRegularFileName]);
        modifiedFontMetadata.captions.regular = {
          fontFamily: captionsRegularAssetFamilyName,
          variant: '400',
        };
      }
    } else if (
      captionsFontFamily &&
      (captions.regular?.fontFamily !== captionsFontFamily || captions.regular?.variant !== captionsRegularFileName)
    ) {
      const gFontVariant = gfontsToLoad[captionsFontFamily] || [];
      gfontsToLoad[captionsFontFamily] = uniq([...gFontVariant, captionsRegularFileName]);

      modifiedFontMetadata.captions.regular = {
        fontFamily: captionsFontFamily,
        variant: captionsRegularFileName,
      };
    }

    const gfontsToLoadFiltered = {};
    // check if already font was loaded
    Object.keys(gfontsToLoad).forEach((fontName) => {
      const variantToLoad = gfontsToLoad[fontName].filter((x) => !loadedFontsObj.current[fontName]?.includes(x));
      if (variantToLoad.length) {
        gfontsToLoadFiltered[fontName] = variantToLoad;
      }
    });

    Object.keys(gfontsToLoadFiltered).length && fontsToLoad.push(loadFonts(gfontsToLoadFiltered));
    Object.keys(fontAssetsToLoad).forEach((assetId) => {
      fontAssetsToLoad[assetId].forEach((fileName) => {
        // add if font was not load before
        if (!loadedFontsObj.current[assetId]?.includes(fileName)) {
          fontsToLoad.push(loadAssetFont(assetId, fileName));
        }
      });
    });

    if (fontsToLoad.length || !fontVersion) {
      await Promise.all(fontsToLoad);
      console.log('fonts loaded', { fontVersion, modifiedFontMetadata });
      //   setLoadedFonts(modifiedFontMetadata);
      sequenceFontDirectoryState.set(modifiedFontMetadata);
      fontLoadedState.set(true);
      incrementFontVersion();
      console.log('fonts incr');
    } else {
      // when not need to load font can be that load before we need to set new loadedFont
      //  setLoadedFonts(modifiedFontMetadata);
      sequenceFontDirectoryState.set(modifiedFontMetadata);
      incrementFontVersion();
    }
  }, [
    fontAssetSid,
    headerFontAssetSid,
    bodyRegularFileName,
    bodyBoldFileName,
    headerRegularFileName,
    headerBoldFileName,
    fontFamily,
    headerFontFamily,
    captionsFontAssetSid,
    captionsRegularFileName,
    captionsFontFamily,
    sequenceStyle,
  ]);

  const fontVersion = fontVersionState.use();

  React.useEffect(() => {
    console.log('fontsVersion effect', { fontVersion });
    if (fontVersion) {
      setSequenceFontStyling(getSequenceFont());
      // fontLoadedState.set(true);
    }
  }, [fontVersion]);

  function loadAssetFont(assetSid, fileName) {
    return new Promise((resolve) => {
      const fontName = getAssetFamilyName(assetSid, fileName);
      const withUserPs = useUserPs();
      const url = withUserPs(`${config.CHUNKS_URL}/t/asset/f/${assetSid}/${fileName}/${user?.cacheVersion || 0}-fontFile`);
      const encodeUrl = url.replace(/[ ]/g, "%20");
      console.log('Loading font', { assetSid, fileName, url, encodeUrl });
      let font_file = new FontFace(fontName, `url(${encodeUrl})`);
      font_file
        .load()
        .then((payload) => {
          loadedFontsObj.current[assetSid] = [...(loadedFontsObj.current[assetSid] || []), fileName];
          document.fonts.add(payload);
          resolve();
          console.log('font assetSid loaded successfully - ', fontName);
        })
        .catch((err) => {
          console.error(err, { assetSid, fileName, url, encodeUrl });
          resolve();
        });
    });
  }

  function loadFonts(fontsObj) {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-param-reassign
      import('webfontloader').then((webFont) => {
        const timeout = setTimeout(() => {
          console.error('font failed to load - ', {
            fontsObj,
            string: Object.keys(fontsObj).map((font) => `${font}:${['b', ...fontsObj[font]].join(',')}`),
          });
          resolve();
        }, 4000);
        webFont.typekit = { id: 'psj0mst' };
        webFont.load({
          google: {
            families: [...Object.keys(fontsObj).map((font) => `${font}:${['b', ...fontsObj[font]].join(',')}`)],
          },
          active: function () {
            Object.keys(fontsObj).forEach(
              (fontFamily) =>
                (loadedFontsObj.current[fontFamily] = [
                  ...(loadedFontsObj.current[fontFamily] || []),
                  ...fontsObj[fontFamily],
                ])
            );
            clearTimeout(timeout);
            console.log('font loaded successfully - ', fontsObj);
            resolve();
          },
        });
      });
    });
  }

  // TODO: not sure that we need this here
  const colorClasses = colors
    ? `
        .color-bg-dominant-1 .color-fill-dominant-bg, .color-text-dominant-1 .text-field, .color-bg-1 .color-fill-dominant-bg {
            fill: ${colors.find((c) => c.dominancy === 1)?.color};
            color: ${colors.find((c) => c.dominancy === 1)?.color};
        }
        .color-bg-dominant-2 .color-fill-dominant-bg, .color-text-dominant-2 .text-field, .color-bg-2 .color-fill-dominant-bg {
            fill: ${colors.find((c) => c.dominancy === 2)?.color};
            color: ${colors.find((c) => c.dominancy === 2)?.color};
        }
        .color-bg-dominant-3 .color-fill-dominant-bg, .color-text-dominant-3 .text-field, .color-bg-3 .color-fill-dominant-bg {
            fill: ${colors.find((c) => c.dominancy === 3)?.color};
            color: ${colors.find((c) => c.dominancy === 3)?.color};
        }
        .color-bg-dominant-4 .color-fill-dominant-bg, .color-text-dominant-4 .text-field, .color-bg-4 .color-fill-dominant-bg {
            fill: ${colors.find((c) => c.dominancy === 4)?.color};
            color: ${colors.find((c) => c.dominancy === 4)?.color};
        }
        .color-bg-dominant-5 .color-fill-dominant-bg, .color-text-dominant-5 .text-field, .color-bg-5 .color-fill-dominant-bg {
            fill: ${colors.find((c) => c.dominancy === 5)?.color};
            color: ${colors.find((c) => c.dominancy === 5)?.color};
        }
        .color-bg-1 .color-stroke-dominant-bg, .color-stroke-dominant-1 {
            stroke: ${colors.find((c) => c.dominancy === 1)?.color};
            color: ${colors.find((c) => c.dominancy === 1)?.color};
        }
        .color-bg-2 .color-stroke-dominant-bg, .color-stroke-dominant-2 {
            stroke: ${colors.find((c) => c.dominancy === 2)?.color};
            color: ${colors.find((c) => c.dominancy === 2)?.color};
        }
        .color-bg-3 .color-stroke-dominant-bg, .color-stroke-dominant-3 {
            stroke: ${colors.find((c) => c.dominancy === 3)?.color};
            color: ${colors.find((c) => c.dominancy === 3)?.color};
        }
        .color-bg-4 .color-stroke-dominant-bg, .color-stroke-dominant-4 {
            stroke: ${colors.find((c) => c.dominancy === 4)?.color};
            color: ${colors.find((c) => c.dominancy === 4)?.color};
        }
        .color-bg-5 .color-stroke-dominant-bg, .color-stroke-dominant-5 {
            stroke: ${colors.find((c) => c.dominancy === 5)?.color};
            color: ${colors.find((c) => c.dominancy === 5)?.color};
        }
        .color-bg-dominant-1 .text-element, .color-bg-dominant-1.text-element {
            background-color: ${colors.find((c) => c.dominancy === 1)?.color};
        }
        .color-bg-dominant-2  .text-element, .color-bg-dominant-2.text-element {
            background-color: ${colors.find((c) => c.dominancy === 2)?.color};
        }
        .color-bg-dominant-3  .text-element, .color-bg-dominant-3.text-element {
            background-color: ${colors.find((c) => c.dominancy === 3)?.color};
        }
        .color-bg-dominant-4  .text-element, .color-bg-dominant-4.text-element {
            background-color: ${colors.find((c) => c.dominancy === 4)?.color};
        }
        .color-bg-dominant-5  .text-element, .color-bg-dominant-5.text-element {
            background-color: ${colors.find((c) => c.dominancy === 5)?.color};
        }
        .color-text-dominant-1 .text-element, .color-text-dominant-1.text-element {
            color: ${colors.find((c) => c.dominancy === 1)?.color};
        }
        .color-text-dominant-2  .text-element, .color-text-dominant-2.text-element {
            color: ${colors.find((c) => c.dominancy === 2)?.color};
        }
        .color-text-dominant-3  .text-element, .color-text-dominant-3.text-element {
            color: ${colors.find((c) => c.dominancy === 3)?.color};
        }
        .color-text-dominant-4  .text-element, .color-text-dominant-4.text-element {
            color: ${colors.find((c) => c.dominancy === 4)?.color};
        }
        .color-text-dominant-5  .text-element, .color-text-dominant-5.text-element {
            color: ${colors.find((c) => c.dominancy === 5)?.color};
        }
        .color-text-dominant-1 .color-stroke.text {
            stroke: ${colors.find((c) => c.dominancy === 1)?.color};
            color: ${colors.find((c) => c.dominancy === 1)?.color};
        }
        .color-text-dominant-2  .color-stroke.text {
            stroke: ${colors.find((c) => c.dominancy === 2)?.color};
            color: ${colors.find((c) => c.dominancy === 2)?.color};
        }
        .color-text-dominant-3  .color-stroke.text {
            stroke: ${colors.find((c) => c.dominancy === 3)?.color};
            color: ${colors.find((c) => c.dominancy === 3)?.color};
        }
        .color-text-dominant-4  .color-stroke.text {
            stroke: ${colors.find((c) => c.dominancy === 4)?.color};
            color: ${colors.find((c) => c.dominancy === 4)?.color};
        }
        .color-text-dominant-5  .color-stroke.text {
            stroke: ${colors.find((c) => c.dominancy === 5)?.color};
            color: ${colors.find((c) => c.dominancy === 5)?.color};
        }
        
        .color-fill-brightness-0 {
            fill: ${colors.find((c) => c.brightnessIndex === 0)?.color};
            color: ${colors.find((c) => c.brightnessIndex === 0)?.color};
        }
        .color-fill-brightness-1 {
            fill: ${colors.find((c) => c.brightnessIndex === 1)?.color};
            color: ${colors.find((c) => c.brightnessIndex === 1)?.color};
        }
        .color-fill-brightness-2 {
            fill: ${colors.find((c) => c.brightnessIndex === 2)?.color};
            color: ${colors.find((c) => c.brightnessIndex === 2)?.color};
        }
        .color-fill-brightness-3 {
            fill: ${colors.find((c) => c.brightnessIndex === 3)?.color};
            color: ${colors.find((c) => c.brightnessIndex === 3)?.color};
        }
        .color-fill-brightness-4 {
            fill: ${colors.find((c) => c.brightnessIndex === 4)?.color};
            color: ${colors.find((c) => c.brightnessIndex === 4)?.color};
        }
        .color-stroke-brightness-0 {
            stroke: ${colors.find((c) => c.brightnessIndex === 0)?.color};
            color: ${colors.find((c) => c.brightnessIndex === 0)?.color};
        }
        .color-stroke-brightness-1 {
            stroke: ${colors.find((c) => c.brightnessIndex === 1)?.color};
            color: ${colors.find((c) => c.brightnessIndex === 1)?.color};
        }
        .color-stroke-brightness-2 {
            stroke: ${colors.find((c) => c.brightnessIndex === 2)?.color};
            color: ${colors.find((c) => c.brightnessIndex === 2)?.color};
        }
        .color-stroke-brightness-3 {
            stroke: ${colors.find((c) => c.brightnessIndex === 3)?.color};
            color: ${colors.find((c) => c.brightnessIndex === 3)?.color};
        }
        .color-stroke-brightness-4 {
            stroke: ${colors.find((c) => c.brightnessIndex === 4)?.color};
            color: ${colors.find((c) => c.brightnessIndex === 4)?.color};
        }
    `
    : '';

  const isSquare = aspectRatio.replace(':', ' / ') === '1 / 1';

  // TODO: need to check if need the colorClasses
  return (
    <style>
      {`
           
     ${sequenceFontStyling || getSequenceFont()}

     .embed-responsive {
         aspect-ratio: ${aspectRatio.replace(':', ' / ')};
         width:auto;
     }
     .embed-responsive:before {
         padding-top: ${ratio}%;
     }
     .captions-element {
        left: 50%;
        z-index: ${ZINDEX.CLOSED_CAPTION};
				display: inline;
        color: ${captionStyle.fontColor};
        direction: ${sequenceTextDirection};
        transform: ${captionStyle.transform};
        top: ${captionStyle.position}%;
        text-transform: ${captionStyle.textTransform};
        visibility: hidden;
     }
     .captions-element .highlight {
        font-weight: bold;
        color: ${highlightColor?.color};
    }
    .captions-element .selected {
        background-color: rgba(237, 63, 153, 0.3);
        text-shadow: 0px 2px 8px rgba(237, 63, 153, 1);
    }
    .captions-element .editable-item:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(var(--cc-calculated-width) + var(--cc-calculated-padding-x));
        height: calc(100% + var(--cc-calculated-padding-y));
        transform: translate(-50%, -50%);
        background-color: var(--cc-calculated-bg);
        z-index: -1;
        border-radius: 5px;
    }
     ${colorClasses}
 `}

      {isSquare &&
        isPreview &&
        `
        .editor--preview--monitor-container .screen.preview .monitor{
            max-height: 50vh;
        }
        `}
    </style>
  );
}
