import { isNumber } from 'lodash/fp';
import React from 'react';
import { useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { Rnd } from 'react-rnd';
import { ZINDEX } from '../../../constants/zIndex.const';
import { StyleVerticalAlign } from '../../client/base/sequence/style';
import ClosedCaption from '../../client/closedCaption';
import { useRect } from '../../hooks/useRect';
import { AppContext } from '../../lib/app-context';
import {
  activeEditCuePointState,
  calcCuePointTimes,
  cuePointsOnTimeState,
  cuePointsState,
  evalClosedCaption,
  incrementCuePointsVersion,
  isHighlightedState,
  previousClosedCaption,
  removeClosedCaption,
  saveChanges,
  setCuePoint,
  tempCuePointsVersionState,
  uniqueSid,
} from '../../state/cuePoints';
import { MENUS, menuState } from '../../state/menu';
import { setActiveClosedCaption, signalPlaybackShouldPause } from '../../state/playback';
import { scenesState, sequenceState } from '../../state/sequence';
import { trackEvent } from '../../utils/analityics.utils';
import {
  getCloseCaptionFontSize,
  getCloseCaptionPosition,
  getCloseCaptionStyleFromSequence,
  getCloseCaptionVerticalAlign,
} from '../../utils/closeCpation.utils';
import { firstDraftState } from '../draftModal/DraftModal';
import { shouldResetSelectionState } from '../menu/subtitles';
import SVG from '../svg';
import EditableTextItem, { decodeHtml } from './editableTextItem';

const TEXT_NODE = 3;

/**
 * @param {{
 *  disabled: boolean,
 *  inMenu: boolean,
 *  className: string,
 *  innerRef: React.RefObject<HTMLElement> | Function,
 *  clickEnabled: boolean,
 *  closedCaption: ClosedCaption,
 *  chapterSid: string,
 * 	onContextMenu: Function,
 *  style: CSSProperties,
 *  isPreview: boolean,
 *  onMeasurement: Function
 *  onEditStatusChanged: Function
 *  positionStyle: object
 * }} props
 */

export function ClosedCaptionItem({
  closedCaption,
  chapterSid,
  className,
  style,
  positionStyle,
  disabled,
  selected,
  clickEnabled,
  inMenu,
  onContextMenu,
  isPreview,
  onMeasurement,
  onEditStatusChanged,
}) {
  const sequence = sequenceState.use();
  const scenes = scenesState.use((scenes) => scenes.map);
  const shouldResetSelection = shouldResetSelectionState.use();
  const content = React.useRef();
  const selectAll = React.useRef(false);
  const editing = React.useRef(false);
  const [splitIndex, setSplitIndex] = React.useState(undefined);
  const [innerDimension, setInnerDimension] = React.useState({ width: 0, height: 0 });
  const onTime = cuePointsOnTimeState.useCuePoint(closedCaption);
  const getActiveWords = () =>
    closedCaption.words
      .filter((w) => w.enabled && w.enabled[inMenu ? chapterSid : onTime] && !w.excluded)
      .sort((a, b) => a.startTime - b.startTime);
  const [activeWords, setActiveWords] = React.useState(getActiveWords);
  const tmpText = cuePointsState.use(
    (cuePoints) => cuePoints[closedCaption.sid] && cuePoints[closedCaption.sid].tmpText
  );
  const firstDraftVisible = firstDraftState.use();
  React.useEffect(() => {
    if (!inMenu) {
      setActiveClosedCaption({ chapterSid, closedCaption: closedCaption.sid });
    }
  }, []);

  const calcHtml = () => {
    const wordsSelected = selectedWords && selectedWords.current || [];
    // if (tmpText && !wordsSelected) {
    //   return tmpText;
    // }
    return (
      activeWords
        .reduce(
          ({ html }, w) => {
            const { sid, word, highlight } = w;
            const classNames = [];
            wordsSelected?.includes(w) && classNames.push('selected');
            !isPreview && highlight && classNames.push('highlight');

            const span = `<span 
				id="${sid || uniqueSid()}" 
				${classNames.length ? `class="${classNames.join(' ')}"` : ''}
			>${word}</span>`;

            html += ' ' + span;

            return { html };
          },
          { html: '' }
        )
        .html.trim() || '&nbsp;'
    );
  };

  const [html, setHtml] = React.useState(calcHtml);
  const merge = cuePointsState.use((cuePoints) => cuePoints[closedCaption.sid]?.merge);
  const focus = cuePointsState.use((cuePoints) => cuePoints[closedCaption.sid]?.focus);
  const wordsLength = cuePointsState.use((cuePoints) => cuePoints[closedCaption.sid]?.words?.length);
  const selectedWords = React.useRef([]);
  const getIsHighlighted = () => selectedWords.current.find((w) => w.highlight);
  const isHighlighted = isHighlightedState.use();

  const { user } = React.useContext(AppContext);
  const { show, hideAll } = useContextMenu({ id: `context-menu` });

  React.useEffect(() => {
    if (splitIndex !== undefined && content.current) {
      content.current.blur();
    }
  }, [splitIndex]);

  React.useEffect(() => {
    if (!content.current) {
      return;
    }

    if (focus) {
      if (!selected) {
        content.current.click();
      }
      onEditStatusChanged(true);
      editing.current = true;
      // TODO focus doesn't work
      content.current.focus();
      closedCaption.focus = false;
      setCuePoint(closedCaption, sequence, scenes, false);
    }
  }, [focus, content.current]);

  const previewCuePointsVersion = tempCuePointsVersionState.use();

  React.useEffect(() => setActiveWords(getActiveWords), [wordsLength, closedCaption, onTime]);
  React.useEffect(() => setHtml(calcHtml), [activeWords, tmpText, isHighlighted, previewCuePointsVersion]);

  function mergeNext() {
    const newLines = closedCaption.words.filter((w) => w.newLine);
    newLines.forEach((w) => (w.newLine = false));
    const next = cuePointsState.get()?.[closedCaption.next?.sid];
    if (!next?.times?.[chapterSid]?.active) {
      return;
    }
    closedCaption.words = [...closedCaption.words, ...(next?.words || [])].filter((w) => w.word);
    if (next.next) {
      next.next.prev = closedCaption;
      closedCaption.next = next.next;
    } else {
      delete closedCaption.next;
    }
    closedCaption.endTime = Math.round(closedCaption.words[closedCaption.words.length - 1].endTime);
    saveChanges(closedCaption, newLines, editing, chapterSid, splitIndex, false);
    next.next && setCuePoint(next.next, sequence, scenes);
    removeClosedCaption(next);
    calcCuePointTimes(closedCaption, sequence, scenes);
  }

  React.useEffect(() => {
    if (merge && merge === chapterSid && inMenu) {
      cuePointsState.set((cuePoints) => {
        cuePoints[closedCaption.sid].merge = false;
        return cuePoints;
      });
      mergeNext();
      if (content.current) {
        content.current.click();
      }
    }
  }, [merge]);

  function onKeyPress(e) {
    if (e.which !== 13) {
      return;
    }

    e.preventDefault();
    if (!inMenu) {
      trackEvent('closed-caption-enter', { inMenu });
      return e.target.blur();
    }

    // check if can delete all code below

    // handle closed caption split
    const selection = getTextSelection(e.target);
    if (!selection) {
      return;
    }
    const node = selection.focusNode;
    const selectionText = e.target.textContent;
    var offset = selection.focusOffset;
    if (node !== e.target) {
      var prev = node.nodeType === TEXT_NODE && node.parentNode !== e.target ? node.parentNode : node;
      while (prev.previousSibling) {
        prev = prev.previousSibling;
        offset += prev.textContent.length;
      }
    }
    if (selectionText.length <= offset) {
      return;
    }
    if (
      selectionText.length > offset &&
      !selectionText[offset].match(/[\s\n]/) &&
      !selectionText[offset - 1].match(/[\s\n]/)
    ) {
      trackEvent('closed-caption-enter', { inMenu, cancel: 'not-space' });
      // Not on space
      return;
    }

    const index =
      selectionText
        .substr(0, offset)
        .trim()
        .split(/[\s\r\n]+/).length - 1;

    trackEvent('closed-caption-enter', { inMenu, index });
    setSplitIndex(index);
  }

  async function onKeyDown(e) {
    // esc
    if (e.which === 27) {
      trackEvent('closed-caption-esc', { inMenu });
      return e.target.blur();
    }

    if (!inMenu) {
      selectedWords.current = [];
      return;
    }

  }

  async function onBlur({ target }) {
    // avoid rerender if selected words and context menu
    if (selectedWords.current.length === 0) {
      await evalClosedCaption(target, editing, closedCaption, chapterSid, inMenu, splitIndex, merge);
    }
    editing.current = false;
    onEditStatusChanged(false);
    setSplitIndex(undefined);
  }

  /**
   * @type {Selection}
   */
  function getTextSelection(target) {
    /**
     * @type {Selection}
     */
    var select;
    if (window.getSelection) {
      select = window.getSelection();
    } else if (document.getSelection) {
      select = document.getSelection();
    } else if (document.selection) {
      select = document.selection;
    } else {
      return null;
    }

    if (!select || !select.focusNode || !select.focusNode.parentNode) {
      return null;
    }
    if (
      target &&
      select.focusNode.parentNode !== target.parentNode &&
      select.focusNode.parentNode !== target &&
      select.focusNode.parentNode.parentNode !== target
    ) {
      return null;
    }

    try {
      return select;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  function onRightClick(event) {
    signalPlaybackShouldPause();
    onEditStatusChanged(false);
    editing.current = false;
    if (selectedWords.current.length) {
      trackEvent('closed-caption-context-menu', { inMenu });
      onContextMenu && onContextMenu(true);
      show(event, {
        props: {
          selectedText: {
            words: selectedWords.current,
            text: selectedWords.current.map((w) => w.word).join(' '),
          },

          closedCaption,
          chapterSid,
          inMenu,
          target: content.current,
          editing,
        },
        position: {
          x: event.clientX,
          y: content.current.getBoundingClientRect().y + content.current.getBoundingClientRect().height + 5,
        },
      });
    }
  }

  React.useEffect(() => {
    hideAll();
  }, [selectedWords.current]);

  React.useEffect(() => {
    if (shouldResetSelection) {
      resetSelection();
      shouldResetSelectionState.set(false);
    }
  }, [shouldResetSelection]);

  function onTextHighlighted(event) {
    if (selectAll.current) {
      selectedWords.current = activeWords;
      isHighlightedState.set(getIsHighlighted);
      setHtml(calcHtml(selectedWords.current));
      return;
    }

    const selection = getTextSelection(event.target);
    if (!selection) {
      return;
    }

    const txt = selection.toString().trim();
    if (!txt || !txt.length) {
      selectedWords.current = [];
      return;
    }

    trackEvent('closed-caption-mark', { inMenu });

    // get selected node index in the content
    const extentNodeIndex = [...content.current.children].findIndex((n) => n.id === selection.extentNode.parentNode.id);
    const anchorNodeIndex = [...content.current.children].findIndex((n) => n.id === selection.anchorNode.parentNode.id);
    const startIndex = Math.min(extentNodeIndex, anchorNodeIndex);
    const endIndex = Math.max(extentNodeIndex, anchorNodeIndex);

    selectedWords.current = activeWords.slice(startIndex, endIndex + 1);
    setHtml(calcHtml(selectedWords.current));
    isHighlightedState.set(getIsHighlighted);
  }

  const editableItemOnClick = (e) => {
    if (e.detail === 3) {
      selectAll.current = true;
      return;
    }
    selectAll.current = false;
    setActiveClosedCaption({ chapterSid, closedCaption: closedCaption.sid });
    activeEditCuePointState.set(closedCaption.sid);
    menuState.set(MENUS.CAPTIONS);
    signalPlaybackShouldPause();
    if (!clickEnabled) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
  };

  const [buttonPos, setbuttonPos] = React.useState({});

  function setLocation(e) {
    if (inMenu) {
      return;
    }
    let x = e.clientX - content.current.getBoundingClientRect().x;
    setbuttonPos({
      left: x,
      top: -30,
    });
  }

  function onContextMenuClick(e) {
    onRightClick(e);
  }

  function resetSelection() {
    selectedWords.current = [];
    setHtml(calcHtml);
  }

  function onMouseUp(e) {
    if (inMenu || isPreview || firstDraftVisible) {
      return;
    }
    setLocation(e);
    onTextHighlighted(e);
  }

  const ccRef = React.useRef(null);
  const rectBox = useRect(ccRef);
  React.useEffect(() => {
    if (onMeasurement) {
      onMeasurement(rectBox);
      if(content?.current) {
        const { width, height } = content?.current?.getBoundingClientRect();
        setInnerDimension({ width, height });
      }
    }
  }, [rectBox]);

  React.useEffect(() => {
    if (onEditStatusChanged) {
      onEditStatusChanged(editing.current);
    }
  }, [editing.current]);

  if (!activeWords.length || !closedCaption.words.find((w) => w.word?.length)) {
    return null;
  }

  // need to set filter on the wrapper
  // hook get arrow - keep this as is for now
  const editableWrapperStyle = {
    position: 'relative',
    filter: style?.filter || 'unset',
  };

  // edit content style reset filter that hide the cursor when edit
  const editableStyle = {
    ...style,
    ...{
      filter: 'unset',
      outline: 0,
      "--cc-calculated-width": `${innerDimension.width}px`,
      "--cc-calculated-height": `${innerDimension.height}px`
    },
  };

  return (
    <React.Fragment>
      <div style={positionStyle} ref={ccRef} className={className}>
        <div style={editableWrapperStyle}>
          <EditableTextItem
            innerRef={(current) => (content.current = current)}
            data-cy="closed-captions"
            className="editable-item"
            cuePoint={closedCaption}
            html={html}
            style={editableStyle}
            disabled={disabled}
            onClick={editableItemOnClick}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            onBlur={onBlur}
            shouldNotUpdateText={isPreview}
            onFocus={() => {
              onEditStatusChanged(true);
              editing.current = true;
            }}
            onMouseUp={onMouseUp}
          />
          {!inMenu && !!selectedWords.current?.length && (
            <ContextMenuToggle left={buttonPos.left} top={buttonPos.top} onClick={onContextMenuClick} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

function ContextMenuToggle({ left, top, onClick }) {
  if (!top || !left) {
    return null;
  }

  return (
    <div className="add-context-menu" style={{ position: 'absolute', top, left }}>
      <a onClick={onClick}>
        <SVG name="plus" style={{ height: '31px', width: '31px' }} />
      </a>
    </div>
  );
}

/**
 *
 * @param {object} props
 * @param {ClosedCaption} props.cuePoint
 * @param {number} props.time
 * @param {number} props.height
 * @param {string} props.chapterSid
 * @param {boolean} props.isPreview
 */
export default function ClosedCaptionElement({ cuePoint, time, height, width, chapterSid, isPreview, disabled }) {
  const { user } = React.useContext(AppContext);
  const sequence = sequenceState.use((sequence) => sequence);
  const enabled = sequenceState.use((sequence) => sequence.useCaptions);
  const className = `captions-element`;
  const [dragPosition, setDragPosition] = React.useState({ x: null, y: null });

  const fontSize = getCloseCaptionFontSize(height, sequence);
  const stdDeviation = fontSize * 0.15;
  const styleCss = getCloseCaptionStyleFromSequence(sequence, height);
  const position = getCloseCaptionPosition(sequence);
  const verticalAlign = getCloseCaptionVerticalAlign(sequence);
  const firstDraftVisible = firstDraftState.use();
  const isCuePointActive = activeEditCuePointState.use() === cuePoint.sid;

  const onTime = cuePointsOnTimeState.useCuePoint(cuePoint);

  const RND_PADDING = 12;
  const RND_OFFSET = RND_PADDING * 0.5;

  const allowCustomPosition = React.useMemo(() => !!user?.UIFLAGS.CC_MANUAL_POSITION,[]);

  const onDrag = React.useCallback((evt, data) => {
    const dragData = {
      x: data.lastX + RND_OFFSET,
      y: data.lastY + RND_OFFSET,
    };
    setDragPosition(dragData);
  }, []);

  const onDragStop = React.useCallback(
    (evt, data) => {
      const x = data.lastX + RND_OFFSET;
      const y = data.lastY + RND_OFFSET;
      const xPercentage = (x / width) * 100;
      const yPercentage = (y / height) * 100;
      const lastWord = activeWords.at(-1);
      if (lastWord) {
        lastWord.positionX = Math.round(xPercentage);
        lastWord.positionY = Math.round(yPercentage);
        lastWord.changedProperties = [
          ...lastWord.changedProperties,
          'chapterSid',
          'word',
          'startTime',
          'endTime',
          'newLine',
          'origin',
        ];
        ClosedCaption.updateWords(cuePoint.sequenceSid || cuePoint.linkSid, [lastWord]);
        trackEvent('Manual Subtitle Position');
        incrementCuePointsVersion();
      } else {
        console.log('drag stop no last word', activeWords);
      }
    },
    [height, width, activeWords, onTime]
  );

  const [rectBox, setRectBox] = React.useState({ bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0 });

  const [isEdit, setIsEdit] = React.useState(false);
  const onMeasurement = React.useCallback((rect) => {
    setRectBox(rect);
  }, []);

  const onEditStatusChanged = React.useCallback((value) => {
    setIsEdit(value);
  }, []);

  const disabledCCEditing = React.useMemo(
    () => disabled || isPreview || firstDraftVisible,
    [disabled, isPreview, firstDraftVisible]
  );

  React.useEffect(() => {
    if (height === 0 || width === 0) {
      return;
    }
    let yPos = height * (lastWordPosition?.y / 100);
    let xPos = width * (lastWordPosition?.x / 100);
    if (!isNumber(lastWordPosition?.x) && rectBox.width) {
      xPos = width / 2 - rectBox.width / 2;
    }
    if (!isNumber(lastWordPosition?.y) && rectBox.height) {
      yPos = height * (position / 100);
      // adjust yPos by aliment
      switch (verticalAlign) {
        case StyleVerticalAlign.MIDDLE:
          yPos -= rectBox.height / 2; // from middle
          break;
        case StyleVerticalAlign.BOTTOM:
          yPos -= rectBox.height; // from bottom
          break;

        default:
          // top is default not need to change yPos
          break;
      }
    }

    setDragPosition((pos) => {
      return { ...pos, ...{ y: yPos, x: xPos } };
    });
  }, [position, height, width, rectBox, lastWordPosition, verticalAlign]);

  const renderRnd = React.useMemo(() => {
    const { y, x } = dragPosition;
    const { height, width } = rectBox;
    if (!allowCustomPosition) {
      return false;
    }

    if (disabledCCEditing || isEdit) {
      return false;
    }
    // validate that all needed props are set
    if (
      y === null ||
      isNaN(y) ||
      x === null ||
      isNaN(x) ||
      height === null ||
      isNaN(height) ||
      width === null ||
      isNaN(width)
    ) {
      return false;
    }
    return true;
  }, [rectBox, dragPosition, isEdit, disabledCCEditing]);

  const positionStyle = React.useMemo(() => {
    let y = 'initial';
    let x = 'initial';
    if (dragPosition?.y !== null) {
      y = dragPosition.y;
    }
    if (dragPosition?.x !== null) {
      x = dragPosition.x;
    }
    return {
      top: 0,
      left: 0,
      visibility: dragPosition?.x !== null && dragPosition?.y !== null ? 'unset' : 'hidden',
      transform: `translate(${x}px, ${y}px)`,
    };
  }, [dragPosition]);

  const closeCaptionStyle = React.useMemo(() => {
    return {
      fontSize,
      position: 'fixed',
      ...styleCss,
    };
  }, [fontSize, styleCss]);

  const activeWords = React.useMemo(() => {
    return cuePoint.words
      .filter((w) => w.enabled && w.enabled[onTime] && !w.excluded)
      .sort((a, b) => a.startTime - b.startTime);
  }, [cuePoint.words, onTime]);

  const lastWordPosition = React.useMemo(() => {
    const lastWord = activeWords.at(-1);
    if (lastWord) {
      return {
        y: lastWord.positionY,
        x: lastWord.positionX,
      };
    } else {
      return null;
    }
  }, [activeWords, onTime]);

  if (!enabled || !cuePoint.activeTimes.length) {
    return null;
  }

  if (time === null || time === undefined) {
    return null;
  }

  return (
    <>
      {renderRnd && (
        <Rnd
          onMouseDown={()=> {
              setActiveClosedCaption({ chapterSid, cuePoint: cuePoint.sid });
              activeEditCuePointState.set(cuePoint.sid)
              menuState.set(MENUS.CAPTIONS);
            }
          }
          className={`moveable-element ${isCuePointActive ? 'cuePointActive' : ""} dragable-element rnd`}
          style={{ zIndex: ZINDEX.CLOSED_CAPTION }}
          bounds="parent"
          size={{ width: rectBox.width + RND_PADDING, height: rectBox.height + RND_PADDING }}
          position={{ x: dragPosition.x - RND_OFFSET, y: dragPosition.y - RND_OFFSET }}
          enableResizing={false}
          onDrag={onDrag}
          onDragStop={onDragStop}
          resizeHandleClasses={{
            bottom: 'rnd-handle bottom-handle',
            bottomLeft: 'rnd-handle bottom-left-handle',
            bottomRight: 'rnd-handle bottom-right-handle',
            left: 'rnd-handle left-handle',
            right: 'rnd-handle right-handle',
            top: 'rnd-handle top-handle',
            topLeft: 'rnd-handle top-left-handle',
            topRight: 'rnd-handle top-right-handle',
          }}
        >
          <div />
        </Rnd>
      )}
      <ClosedCaptionItem
        onMouseDown={()=> {
            setActiveClosedCaption({ chapterSid, cuePoint: cuePoint.sid });
            activeEditCuePointState.set(cuePoint.sid);
            menuState.set(MENUS.CAPTIONS);
          }
        }
        onMeasurement={onMeasurement}
        onEditStatusChanged={onEditStatusChanged}
        closedCaption={cuePoint}
        chapterSid={chapterSid}
        isPreview={isPreview}
        className={`${className} ${isCuePointActive ? 'cuePointActive' : ""}`}
        style={closeCaptionStyle}
        positionStyle={positionStyle}
        disabled={disabledCCEditing}
      />
      {/* when is edit remove filter to avoid cursor issue */}
      {!isEdit && (
        <svg style={{ display: 'none' }}>
          <defs>
            <filter id="instagram">
              <feGaussianBlur in="SourceGraphic" stdDeviation={stdDeviation} result="blur" />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -8"
                result="goo"
              />
              <feComposite in="SourceGraphic" in2="goo" operator="atop" />
            </filter>
          </defs>
        </svg>
      )}
    </>
  );
}
