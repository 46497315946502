import React from 'react'
import '../../../css/cue-points.scss'
import CuePoint from '../../client/cuePoint'
import { cuePointsOnTimeState, cuePointsState } from '../../state/cuePoints'
import { sequenceState } from '../../state/sequence'
import ClosedCaptionElement from './closedCaption'
import GraphicCuePointElement from './graphic'





const FPS = 25

/**
 * @param {{
 *  width: number,
 *  height: number,
 *  animate: boolean,
 *  cuePoint: CuePoint,
 *  time: number time in millis of the video stream,
 * 	audioPlayer: React.RefObject<HTMLVideoElement>,
 *  isPreview: boolean,
 * }} props
 */
export default function CuePointElement(props) {
	var { sid, time, animate } = props
	const sequence = sequenceState.use()

	/**
	 * @type {CuePoint}
	 */
	const cuePoint = cuePointsState.use(cuePoints => cuePoints[sid])
	const activeTime = React.useRef(null)
	const duration = React.useRef(cuePoint && ((cuePoint.endTime < 0 ? sequence.duration * 1000 + cuePoint.endTime : cuePoint.endTime) - cuePoint.startTime))

	// pause time since the cue-point started
	const [cuePointTime, setCuePointTime] = React.useState(null)

	// play time since the cue-point started
	const [currentTime, setCurrentTime] = React.useState(null)
	const onTime = cuePointsOnTimeState.useCuePoint(cuePoint)

	var startTime = React.useRef()
	var interval = React.useRef()
	
	function setActiveTime() {
		if (!cuePoint) {
			return;
		}
		activeTime.current = cuePoint && cuePoint.activeTimes.find(t => t.sceneSid ? (t.sceneSid === onTime || onTime === true) : time >= t.start && time <= t.end)
		if (activeTime.current) {
			duration.current = activeTime.current.end - activeTime.current.start;
			setCuePointTime(time - activeTime.current.start);
		} else {
			setCuePointTime(null)
		}
	}

	React.useEffect(() => time !== undefined && setActiveTime(), [time])
	React.useEffect(setActiveTime, [onTime])

	React.useEffect(() => {
		if (animate && onTime) {
			start(cuePointTime)
		} else {
			stop(onTime || time ? cuePointTime : null)
		}
		return () => stop(null)
	}, [cuePointTime, animate, onTime])

	// notify overlayer that the cue-point is rendered
	React.useEffect(() => {
		const drawState = window.drawState;
		if (drawState && drawState.time === time && drawState.cuePoints[sid]) {
			drawState.cuePoints[sid] = false;
			drawState.effects--;
		}
	}, [time])

	function start(t) {
		setCurrentTime(t)
		startTime.current = Date.now() - (t || 0)
		interval.current = setInterval(() => iterate(), 1000 / FPS)
	}

	function iterate() {
		var t = Date.now() - startTime.current

		if (t > duration.current) {
			return stop(duration.current)
		}
		setCurrentTime(t)
	}

	function stop(t) {
		if (interval.current) {
			clearInterval(interval.current)
			interval.current = null
		}
		setCurrentTime(t)
	}


	if (!cuePoint) {
		return null;
	}

	const properties = {
		...props,
		onTime,
		cuePoint,
		cuePointTime,
		duration: duration.current,
		key: cuePoint.sid,
		time: currentTime,
	}

	switch (cuePoint.type) {
		case CuePoint.TYPE.CLOSED_CAPTION:
			return <ClosedCaptionElement {...properties} chapterSid={activeTime.current?.sceneSid} />

		default:
			return <GraphicCuePointElement {...properties} />
	}
}
