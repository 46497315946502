import React from 'react';
import { openStockIcon, uploadMusicIcon } from '../../../static/svg/musicMenuIcons';
import Asset from '../../client/asset';
import FileUploader from '../../lib/fileUploader';
import { handleError } from '../../state/error';
import { shouldEnableUploadMusicState } from '../../state/menu';
import { sequenceState } from '../../state/sequence';
import { trackEvent } from '../../utils/analityics.utils';
import { BreakDiv, ChangeTrackButton, ChangeTrackDiv, MusicTitle } from './MusicMenu.styled';

interface ChangeTrackControllerProperties {
  disabled: boolean;
  setOpenStockLibrary: (val: boolean) => void;
  setUseProgress: Function;
  setShowUpgradeModal: Function;
  onUsersTrackUpload: Function;
  setUploading: Function;
  disableChanges: boolean;
  shouldShowMusicLibrary: boolean;
}

export default function ChangeTrackController({ disabled, setOpenStockLibrary, setShowUpgradeModal, setUploading, setUseProgress, onUsersTrackUpload, disableChanges, shouldShowMusicLibrary}: ChangeTrackControllerProperties) {
  const sequence = sequenceState.use();
  const shouldEnableUploadMusic = shouldEnableUploadMusicState.use();
  const uploadMusicRef = React.useRef<HTMLInputElement>(null);

  function onUploadMusic() {
    if(disableChanges){
        return;
    }
    if (shouldEnableUploadMusic) {
      uploadMusicRef.current?.click();
    } else {
      setShowUpgradeModal(true);
    }
  }

  async function onUploadMusicFileSelected({ target }) {
    if (target.files.length === 0) {
      return;
    }

    const file = target.files[0];
    setUploading(file.name);
    if (!file.type.includes('audio/')) {
      setUploading(null);
      return handleError({
        title: 'You can only upload audio files here.',
        message: '',
      });
    }
    const asset = new Asset();
    asset.type = Asset.TYPE.MUSIC;
    asset.name = file.name;
    asset.isDefault = true;
    await asset.save();
    const fileUploader = new FileUploader({
      sequence,
      file,
      content: asset,
    });
    fileUploader
      .on('progress', (progress) => {
        setUseProgress(Math.min(100, Math.floor(progress)));
      })
      .on('done', async () => {
        if (uploadMusicRef.current) {
          uploadMusicRef.current.value = '';
        }
        await onUsersTrackUpload(asset);
        setUploading(null);
      });
    await fileUploader.uploadAsset();
    trackEvent('track-change', { source: 'userUpload', trackName: file.name });
  }

  return (
    <ChangeTrackDiv>
      <ChangeTrackButton onClick={() => !(disableChanges || !shouldShowMusicLibrary) && setOpenStockLibrary(true)} disabled={disableChanges || !shouldShowMusicLibrary}>
        {openStockIcon}
        <MusicTitle>Choose from library</MusicTitle>
      </ChangeTrackButton>
      <BreakDiv />
      <ChangeTrackButton onClick={onUploadMusic} disabled={disableChanges}>
        {uploadMusicIcon}
        <MusicTitle>Upload music</MusicTitle>
        <input
                ref={uploadMusicRef}
                type="file"
                multiple={false}
                accept="audio/*"
                hidden
                data-cy="file-input"
                onChange={onUploadMusicFileSelected}
              />
      </ChangeTrackButton>
    </ChangeTrackDiv>
  );
}
