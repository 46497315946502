import React from 'react';
import {menuHeaderTooltipTextState, shouldShowIntroMenuState} from '../../state/menu';
import {
  MenuHeaderWrapper, StyledLabel,
  StyledMenuItem,
  TabsWrapper
} from '../../ui-components/Menu/MenuComponents.styled';
import { IntroOutroContainer, MainContainer } from './IntroAndOutro.styled';
import IntroOutroBlock from './IntroOutroBlock';

import { sequenceSave, sequenceState } from '../../state/sequence';

import { entity } from 'simpler-state';
import Tabs from '../../ui-components/Tabs/Tabs';
import { useAnimation } from '../../hooks/useAnimation';
import {InfoPopup} from "../infoPopup";
import {activeEditCuePointState, cuePointsState} from "../../state/cuePoints";
import {CuePointType} from "../../client/cuePoint";

export const sceneAssetsState = entity({
  intro: [],
  outro: [],
});
export const sceneUploadedAssetsState = entity({
  intro: [],
  outro: [],
});

export enum SCENE_TYPE {
  INTRO = 'intro',
  OUTRO = 'outro',
}

interface IntroAndOutroProperties {}

export default function IntroAndOutro({}: IntroAndOutroProperties) {
  const shouldShowIntroMenu = shouldShowIntroMenuState.use();
  const sequence = sequenceState.use();
  const menuHeaderTooltipText = menuHeaderTooltipTextState.use();
  const headerText = React.useMemo(() => (shouldShowIntroMenu ? 'Intro & Outro' : 'Outro'), [shouldShowIntroMenu]);
  const activeEditCuePoint = activeEditCuePointState.use();
  const cuePoints = cuePointsState.use();
  const cuePoint = Object.values(cuePoints).find(cp => cp.sid === activeEditCuePoint);
  const [activeTab, setActiveTab] = React.useState(shouldShowIntroMenu ? SCENE_TYPE.INTRO : SCENE_TYPE.OUTRO);
  const introAssetSid = sequenceState.use((sequence) => sequence?.style?.[`introAssetSid`]);
  const outroAssetSid = sequenceState.use((sequence) => sequence?.style?.[`outroAssetSid`]);

  const introTabRef = React.useRef();
  const outroTabRef = React.useRef();

  const projectItems = shouldShowIntroMenu
    ? [
        { name: `Intro`, value: SCENE_TYPE.INTRO, ref: introTabRef },
        { name: `Outro`, value: SCENE_TYPE.OUTRO, ref: outroTabRef },
      ]
    : [{ name: `Outro`, value: SCENE_TYPE.OUTRO, ref: outroTabRef }];

  React.useEffect(() => {
   if (cuePoint?.type === CuePointType.OUTRO) {
     setActiveTab(SCENE_TYPE.OUTRO)
   } else {
     setActiveTab(SCENE_TYPE.INTRO)
   }
  }, [cuePoint]);

  React.useEffect(() => {
    if (sequence && sequence.logo && sequence.logo.assetSid && sequence.style && !sequence.style.outroLogoAssetSid) {
      setDefaultOutroLogoAssetSid();
    }
  }, [sequence]);

  async function setDefaultOutroLogoAssetSid() {
    if (sequence?.style && sequence?.logo) {
      sequence.style.outroLogoAssetSid = sequence.logo.assetSid;
      await sequenceSave(sequence);
    }
  }

 const introAnimation = useAnimation(introAssetSid || '', sequence?.aspectRatio || '16:9');
 const outroAnimation = useAnimation(outroAssetSid || '', sequence?.aspectRatio || '16:9');

  return (
    <MainContainer>
      <MenuHeaderWrapper>
        {/* <UseIntroOutroDiv> */}
        <StyledMenuItem 
          label={
            <StyledLabel style={{ display: "flex", 
              fontWeight: 600,
              fontSize: "14px",
              alignItems: "center",
            }} >
              {headerText}
              <InfoPopup text={menuHeaderTooltipText} />
            </StyledLabel>
        } 
          id="intro-outro-menu" 
          actions={[]} 
          bordered={false} 
        />
        {/* </UseIntroOutroDiv> */}
        <TabsWrapper>
          <Tabs tabsItems={projectItems} onChange={(selectedTab) => setActiveTab(selectedTab)} forceActiveTabIndex={projectItems.findIndex(item => item.value === activeTab)} />
        </TabsWrapper>
      </MenuHeaderWrapper>

      <IntroOutroContainer>
        <IntroOutroBlock
          type={activeTab}
          label={activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
          textDirection={sequence.textDirectionByLanguage}
          sceneAnimation={activeTab === SCENE_TYPE.INTRO ? introAnimation : outroAnimation}
        />
      </IntroOutroContainer>
    </MainContainer>
  );
}
