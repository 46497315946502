import styled from "@emotion/styled/macro"
import React from 'react'
import { COLORS } from "../../constants/colors"
import SVG from "../components/svg"

export const CheckBoxBlock = styled.div<{isChecked}>`
    z-index: 1;
    height: 20px;
    width: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: solid 1px;
    border-color: ${({isChecked}): string => (isChecked? COLORS.peech_primary_1_500 : COLORS.peech_neutral_white)};
    border-radius: 3px;
    background-color: ${({isChecked}): string => (isChecked? COLORS.peech_primary_1_500 : COLORS.peech_gray_100)};
    cursor: pointer;
    svg {
        fill: ${COLORS.peech_neutral_white};
        stroke: ${COLORS.peech_neutral_white};
        path {
            stroke-width: 3px;
        }
    }

`


interface PeechCheckboxProps {
    isChecked: boolean;
    onClick: Function;
}



export default function PeechCheckbox({ isChecked, onClick}: PeechCheckboxProps){

  return (
      <CheckBoxBlock isChecked={isChecked}>
          {isChecked && <SVG name="check" viewBox="0 0 36 36" />}
      </CheckBoxBlock>
  );

    

};