import styled from "@emotion/styled/macro";
import React from 'react';
import { COLORS } from '../../../constants/colors';
import SVG from '../../components/svg';

const ButtonContainer = styled.div`
    width: 120px;
    height: 60px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 6px 0;

    @media (max-height: 700px) {
        height: 50px;
        width: 100px;
    }

`

const IconContainer = styled.div<{ isSelected: boolean }>`
    margin-left: 4px;
    svg {
        height: 24px;
        width: 33px;
        margin-bottom: 8px;
        fill: none;
        stroke: ${({ isSelected }): string => (isSelected ? COLORS.peech_primary_2_500 : COLORS.peech_gray_400)};
    }

    @media (max-height: 700px) {
        svg {
            height: 18px;
            width: 26px;
            margin-bottom: 4px;
        }
  }
`

const NameContainer = styled.div<{ isSelected: boolean }>`
    padding: 0 5px 0 5px;
    font-size: 14px;
    height: 21px;
    color: ${({ isSelected }): string => (isSelected ? COLORS.peech_primary_2_500 : COLORS.peech_gray_400)};
    font-weight: ${({ isSelected }): string => (isSelected ? '600' : '400')};

    @media (max-height: 700px) {
        font-size: 10px;
  }
`


interface VisualGuidelineButtonProps {
    onClick: Function;
    iconName: string;
    name: string;
    isSelected: boolean;
}

export default function VisualGuidelineButton({ onClick, iconName, name, isSelected }: VisualGuidelineButtonProps) {

    return (
        <ButtonContainer onClick={onClick}>
            <IconContainer isSelected={isSelected}>
                <SVG name={`${iconName}-menu`} />
            </IconContainer>
            <NameContainer isSelected={isSelected}>
                {name}
            </NameContainer>
        </ButtonContainer>
    );
}
