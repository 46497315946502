import React from 'react';

import styled from '@emotion/styled/macro';

import { COLORS } from '../../../constants/colors';

import { LottieLoader } from '../../components/common/lottie-loader/lottie-loader.component';
import RequestCustomPackageModalJson from '../../components/common/lottie-loader/request-custom-package-modal.json';
import SVG from '../../components/svg';
import CalendlyContactForm from './CalendlyContactForm';

const ModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalBody = styled.div`
  position: absolute;
  width: 800px;
  max-width: 90%;
  height: 560px;
  z-index: 38;
  background: #ffffff;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 50px 26px 30px 8px;
`;

const InfoContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const FormContainrer = styled.div`
  width: 50%;
  height: 100%;
  border: solid 1px;
  border-color: ${COLORS.peech_gray_300};
  border-radius: 10px;
  padding: 10px;
`;

const CloseDiv = styled.div`
  position: absolute;
  top: 17px;
  right: 18px;
  height: 18px;
  width: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: ${COLORS.peech_gray_600};
  }
`;
const PicDiv = styled.div`
  height: 194px;
  margin-bottom: 22px;
  text-align: center;
  display: flex;
  justify-content: center;
`;

const HeaderDiv = styled.div`
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: ${COLORS.peech_gray_900};
  margin-block: 14px;
  white-space: pre-wrap;
  @media only screen and (min-width: 600px) {
    font-size: 32px;
  line-height: 38px;
  }
`;
const SubHeaderDiv = styled.div`
  width: 100%;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${COLORS.peech_gray_900};
  margin-bottom: 38px;
  white-space: pre-wrap;
  @media only screen and (min-width: 600px) {
    font-size: 16px;
  line-height: 24px;
  }
`;

const noPictureIcon = () => {
  return (
    <svg width="87" height="86" viewBox="0 0 87 86" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g style={{ mixBlendMode: 'multiply' }}>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.8008 15.9997H2V84.4046H70.4049V73.6038" fill="#F5F6F8" />
        <path
          d="M12.8008 15.9997H2V84.4046H70.4049V73.6038"
          stroke="#E6E9EF"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8008 1.59863H84.806V73.6038H12.8008V1.59863Z"
          fill="#F5F6F8"
          stroke="#E6E9EF"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.8054 59.2028L65.0046 30.4007L50.6036 52.0023L41.6029 41.2015L30.8021 59.2028"
          fill="#F5F6F8"
        />
        <path
          d="M75.8054 59.2028L65.0046 30.4007L50.6036 52.0023L41.6029 41.2015L30.8021 59.2028"
          stroke="#E6E9EF"
          stroke-width="3"
          stroke-linejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.2025 26.8004C39.1851 26.8004 41.6029 24.3826 41.6029 21.4001C41.6029 18.4175 39.1851 15.9997 36.2025 15.9997C33.22 15.9997 30.8021 18.4175 30.8021 21.4001C30.8021 24.3826 33.22 26.8004 36.2025 26.8004Z"
          fill="#F5F6F8"
          stroke="#E6E9EF"
          stroke-width="3"
          stroke-linejoin="round"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M84.806 58.7985H12.8008H84.806Z" fill="#F5F6F8" />
        <path d="M84.806 58.7985H12.8008" stroke="#E6E9EF" stroke-width="3" stroke-linejoin="round" />
      </g>
    </svg>
  );
};

interface RequestCustomPackageModalProps {
  onHide: Function;
}

export default function RequestCustomPackageModal({ onHide }: RequestCustomPackageModalProps) {
  const headerTexgt = 'Level up your\n visual style!';
  const subHeaderText = 'Our design team will apply the\n visual style to your account\n within 7 days.';
  return (
    <ModalWrapper>
      <ModalBody>
        <CloseDiv
          onClick={(e) => {
            onHide();
          }}
        >
          <SVG name="close" viewBox="0 0 22 22" />
        </CloseDiv>
        <InfoContainer>
          <PicDiv>
            <LottieLoader animationData={RequestCustomPackageModalJson} loop={true} width='90%'/>
          </PicDiv>
          <HeaderDiv>{headerTexgt!}</HeaderDiv>
          <SubHeaderDiv>{subHeaderText}</SubHeaderDiv>
        </InfoContainer>
        <FormContainrer>
            <CalendlyContactForm onSubmit={() => onHide()}/>
        </FormContainrer>
      </ModalBody>
    </ModalWrapper>
  );
}
