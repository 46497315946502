
import * as Base from '../base/closedCaption/word';


export interface IWord extends Base.IWord {

}

export interface IWordProperties extends Base.IWordProperties {

}

export interface IWordFilter extends Base.IWordFilter {

}

export interface IWordEnabled {
	[chapterSid: string]: boolean;
}

export interface IWord extends Base.IWord {
	enabled?: IWordEnabled;
}

export default class Word<IType extends IWord = IWord, ITypeProperties extends IWordProperties = IWordProperties> extends Base.Word<IType, ITypeProperties> implements IWord {

	static PROPERTIES: any = {
		word: true,
		startTime: true,
		endTime: true,
		newLine: true,
		highlight: true,
		flags: true,
        chapterSid:true,
        positionX: true,
        positionY: true
	};

    get enabled(): IWordEnabled | undefined {
        return this.values.enabled
    }

    set enabled(value: IWordEnabled | undefined) {
        if (this.values.enabled !== value) {
            this.values.enabled = value;
        }
    }

    get sourceStartTime(): number {
        return this.values.originStartTime as number
    }

    get sourceEndTime(): number {
        return this.values.originEndTime as number
    }

}