
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'

export interface ISkip extends IBase {
	startTime?: number | null;
	endTime?: number | null;
}

export interface ISkipProperties extends IBaseProperties {
	startTime?: boolean;
	endTime?: boolean;
}

export interface ISkipFilter extends IBaseFilter {
}

export class Skip<IType extends ISkip = ISkip, ITypeProperties extends ISkipProperties = ISkipProperties> extends Base<IType, ITypeProperties> implements ISkip {
	
	static className = 'Skip';
	static NAME = 'skip';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		startTime: true,
		endTime: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get startTime(): number | null | undefined {
		return this.values.startTime
	}
			
	set startTime(value: number | null | undefined) {
		if(this.values.startTime !== value) {
			this.propertyChanged('startTime', value);
			this.values.startTime = value;
		}
	}
			
	get endTime(): number | null | undefined {
		return this.values.endTime
	}
			
	set endTime(value: number | null | undefined) {
		if(this.values.endTime !== value) {
			this.propertyChanged('endTime', value);
			this.values.endTime = value;
		}
	}
			
	
	static get<IType extends ISkip = ISkip>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends ISkipFilter = ISkipFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends ISkipFilter = ISkipFilter, IType extends ISkip = ISkip>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
