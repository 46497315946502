import {appConfig} from "../lib/app-context";
import User from "../client/user";
import {Chapter} from "../client/sequence";
import {AssetCropDetails, CropDataItem} from "../client/sequence/crop.type";

export const getSequenceCropDetails = async (externalSequenceSid: string, outputAspectRatio: string): Promise<any> => {
  const API_v2 = appConfig.API_V2_URL!;
  const response = await fetch(API_v2, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${User.ps}`
    },
    body: JSON.stringify({
      query: `query getCropDetails($externalSequenceSid: String!, $outputAspectRatio: String!) {
      cropDetails(filter: { externalSequenceSid: { eq: $externalSequenceSid } outputAspectRatio: {eq: $outputAspectRatio } }) {
        nodes {
          externalAssetSid
          originalAspectRatio
          outputWidth
          outputHeight
          brandColorIndex
          color
          blur
          cropData {
            start
            end
            crop {
              width
              height
              topx
              topy
            }
            draw {
              width
              height
              topx
              topy
            }
          }
        }
      }
    }`,
      variables: {
        externalSequenceSid,
        outputAspectRatio
      },
    }),
  });

  if (response.ok) {
    const body = await response.json();
    return body.data.cropDetails.nodes;
  } else {
    throw new Error('Network response was not ok.');
  }
}

export const arrangeCropData = (chapters: Chapter[], sequenceAssetsCropDetails: AssetCropDetails[], videoDuration: number) => {
  const result: Required<CropDataItem>[] = [];
  
  if (!chapters?.length || !sequenceAssetsCropDetails?.length || !Array.isArray(sequenceAssetsCropDetails)) {
    return result;
  }

  for (const assetCropDetails of sequenceAssetsCropDetails) {
    const cropData = assetCropDetails.cropData;

    if (!Array.isArray(cropData) || !cropData?.length) {
      continue;
    }
    
    for (const crop of cropData) {
      for (const chapter of chapters) {
        if (assetCropDetails.externalAssetSid !== chapter.assetSid) {
          continue;
        }
        // Calculate start and end for chapter and crop based on offset, duration and clipFrom
        const chapterStart = chapter.offset!;
        const chapterEnd = chapter.offset! + chapter.duration!;
        const cropStart = crop.start + chapter.offset! - (chapter.clipFrom ?? 0);
        const cropEnd = crop.end + chapter.offset! - (chapter.clipFrom ?? 0)

        // Check if the crop data intersects with the chapter
        if (cropStart < chapterEnd && cropEnd > chapterStart) {
          // Split the crop data if it intersects with the chapter
          const intersectStart = Math.max(cropStart, chapterStart);
          const intersectEnd = Math.min(cropEnd, chapterEnd);
          
          result.push({ ...crop, start: intersectStart, end: intersectEnd });
        }
      }
    }
  }
  
  
  // fill missing crop data (for anything that is not covered by crop data at its first or last edges)
  const isGapAtTheStart = result[0].start > 0;
  const isGapAtTheEnd = result[result.length - 1].end < videoDuration;
  
  if (isGapAtTheStart || isGapAtTheEnd) {
    const initialCropDataItem: CropDataItem = {
      crop: {topx:0, topy:0, width:1, height:1},
      draw: {topx:0, topy:0, width:1, height:1}
    }

    isGapAtTheStart && result.unshift({
      start: 0,
      end: result[0].start,
      ...initialCropDataItem
    });

    isGapAtTheEnd && result.push({
      start: result[result.length - 1].end,
      end: videoDuration,
      ...initialCropDataItem
    });
  }

  return result.sort((a, b) => a.start - b.start);
}


