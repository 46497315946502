import React from 'react'
import ChapterElement from '../Elements/ChapterElement'

import { tempScenesState } from '../../../state/sequence'

import { useDrag } from '@use-gesture/react'
import { useSprings, animated, config } from '@react-spring/web'
import { ChapterOrder } from '../../../client/sequence/chapter'

const AnimatedChapterElement = animated(ChapterElement)

const BLOCK_HEIGHT_WITH_SPACE = 116

const clamp = (pos, low, high) => {
    const mid = Math.max(pos, low);
    return Math.min(mid, high);
};

const swap = (arr, from, to) => {
    const copy = [...arr];
    const [index] = copy.splice(from, 1);
    copy.splice(to, 0, index);
    return copy;
};

// Returns fitting styles for dragged/idle items

const fn =
    (order: number[], active = false, originalIndex = 0, curIndex = 0, y = 0) =>
        (index: number) => {
            return active && index === originalIndex
                ? { y: curIndex * BLOCK_HEIGHT_WITH_SPACE + y, scale: 1.1, zIndex: 1, shadow: 15, immediate: (key: string) => key === 'zIndex', config: (key: string) => (key === 'y' ? config.stiff : config.default), }
                : { y: order.indexOf(index) * BLOCK_HEIGHT_WITH_SPACE, scale: 1, zIndex: 0, shadow: 1, immediate: false, }
        }

interface DraggableScenesListProps {
    order: React.MutableRefObject<number[]>;
}

export default function DraggableScenesList({ order }: DraggableScenesListProps) {

    const chapters = tempScenesState.use(scenes => scenes.filter.durableArray)
    const [springs, setSprings] = useSprings(chapters.length, fn(order?.current)) // Create springs, each corresponds to an item, controlling its transform, scale, etc.
    const bind = useDrag(({ args: [originalIndex], active, movement: [, y] }) => {
        const curIndex = order.current.indexOf(originalIndex)
        const curRow = clamp(Math.round((curIndex * 100 + y) / 100), 0, chapters.length - 1)
        const newOrder = swap(order.current, curIndex, curRow)
        setSprings.start(fn(newOrder, active, originalIndex, curIndex, y)) // Feed springs new style data, they'll animate the view without causing a single render
        if (!active) order.current = newOrder
    })

    return (
        <div className="content" data-cy="draggable-container" style={{ height: chapters.length * 127 }}>
            {springs.map(({ zIndex, shadow, y, scale }, i) => (
                <AnimatedChapterElement
                    {...bind(i)}
                    key={chapters[i].sid || chapters[i].cid}
                    chapter={chapters[i]}
                    className={`chapter-${chapters[i].sid || chapters[i].cid || chapters[i].linkSid} draggable`}
                    chapterSid={chapters[i].sid || chapters[i].cid}
                    chapters={chapters}
                    draggable={true}
                    style={{
                        zIndex,
                        // boxShadow: shadow.to(s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
                        y,
                        scale,
                    }}
                />
            ))}
        </div>
    )

}