
import React, { KeyboardEvent, SyntheticEvent } from 'react';
import lottie, { AnimationItem } from 'lottie-web';
import styled from "@emotion/styled/macro";




export const LoaderContainer = styled.div<{ width: string }>`
  width:  ${({ width }): string => width};
`;

interface LottieLoaderProps {
    animationData: any;
    loop?: boolean;
    autoplay?: boolean;
    width?: string;
}


export const LottieLoader: React.FC<LottieLoaderProps> = ({ animationData, loop, autoplay = true, width = '250px' }) => {

    const container = React.useRef<HTMLDivElement>();
    const animation = React.useRef<AnimationItem>();

    React.useEffect(() => {
        if (!container.current) {
            return;
        }


        const anim = JSON.parse(JSON.stringify(animationData))

        animation.current = lottie.loadAnimation({
            loop,
            autoplay,
            animationData: anim,
            container: container.current,
            renderer: 'svg',
        });
        // animation.current.playSegments([30, 90]) ;
        return () =>
            animation.current.destroy();
    }, [animationData.clientVersion, animationData, loop, autoplay, container.current])

    return <LoaderContainer ref={container} width={width}/>
}