import styled from "@emotion/styled/macro";
import React from 'react';
import { COLORS } from '../../../constants/colors';
import SVG from '../../components/svg';
import { AppContext } from "../../lib/app-context";
import {resetCuePointToDefault, resetLogoScaleToDefault} from "../../state/cuePoints";
import { loadedPresetsState } from "../../state/local";
import { sequenceState, sequenceSave } from "../../state/sequence";
import Button from '../Button';
import Loader from "../Loader";
import BrandKitItem from './BrandKitItem';
import Base from "../../client/base/base";
import {CuePointType} from "../../client/cuePoint";

const ModalWrapper = styled.div` 
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    z-index: 30;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalBody = styled.div` 
    position: absolute;
    min-width: 800px;
    width: 70%;
    min-height: 500px;
    height: 80%;
    z-index: 38;
    background: #FFFFFF;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px 20px 20px 30px;

`
const CloseDiv = styled.div` 
    position: absolute;
    top: 17px;
    right: 18px;
    height: 18px;
    width: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        fill: ${COLORS.peech_gray_600};
    }

`

const PackagesContainer = styled.div`
    position: relative;
    width: 100%;
    max-height: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start; 
    margin: 0;
    height: fit-content;
    overflow-y: scroll;
    
   
`


const LoaderContainer = styled.div` 
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ButtonContainer = styled.div`
    width: 100%;
    min-height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 22px;
    margin-top: 4px;
`
const LoaderWrapper = styled.div`
    width: 134px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${COLORS.peech_primary_2_500};
    border-radius: 6px;
    text-align: center;
`



interface BrandKitsModalProps {
    onHide: Function;
    sequenceSaveLoading: boolean;
    setSequenceSaveLoading: Function;
}

export default function BrandKitsModal({ onHide, sequenceSaveLoading, setSequenceSaveLoading }: BrandKitsModalProps) {
    const { user } = React.useContext(AppContext);
    const sequence = sequenceState.use();
    const loadedPresets = loadedPresetsState.use();
    const [selectedPresetSid, setSelectedPresetSid] = React.useState();

    React.useEffect(() => {
        sequence && setSelectedPresetSid(sequence?.presetSid);
    }, [sequence])

    async function onBrandKitApply() {
        if (sequence.presetSid !== selectedPresetSid) {
            setSequenceSaveLoading(true);
            await Base.executeAPIQueue();
            const preset = loadedPresets?.find(el => el.sid === selectedPresetSid);
            const newSequence = await sequence.applyPreset(preset, user);
            await sequenceSave(newSequence);
            await resetCuePointToDefault();
            await resetLogoScaleToDefault(CuePointType.INTRO, CuePointType.OUTRO);
            setSequenceSaveLoading(false);
        }
        onHide();
    }

    return (
        <ModalWrapper onClick={onHide} >
            <ModalBody onClick={e => e.stopPropagation()}>
                <CloseDiv onClick={onHide}>
                    <SVG name="close"
                        viewBox="0 0 22 22"
                    />
                </CloseDiv>
                <PackagesContainer >
                    {loadedPresets && loadedPresets.length ? loadedPresets.map((item) => {
                        return <BrandKitItem key={item.sid} preset={item} isSelected={item.sid === selectedPresetSid} onItemClick={() => setSelectedPresetSid(item.sid)} />
                    })
                        :
                        <LoaderContainer>
                            <Loader />
                        </LoaderContainer>
                    }
                </PackagesContainer>
                <ButtonContainer>
                    {loadedPresets && loadedPresets.length ?
                        sequenceSaveLoading ?
                            <LoaderWrapper>
                                <Loader size='xs' />
                            </LoaderWrapper>
                            :
                            <Button variant='primary' label='Apply' onClick={onBrandKitApply} />
                        :
                        null}
                </ButtonContainer>
            </ModalBody>
        </ModalWrapper>
    );

}