import { useLocation } from '@reach/router';
import classNames from 'classnames';
import React from 'react';
import { Alert } from 'react-bootstrap';
import GoogleLogin, { GoogleLoginResponse } from 'react-google-login';
import User from '../client/user';
import StaticDoc from '../components/StaticDoc/StaticDoc';
import SVG from '../components/svg';
import { AppContext } from '../lib/app-context';
import { googleLoginState } from '../state/menu';
import Button from '../ui-components/Button';
import { trackEvent } from '../utils/analityics.utils';
import "./login.scss";


const HEADER_LOGO = '/images/peech_header_logo.png'


const MODES = {
    SIGNIN: 1,
    SIGNIN_EMAIL: 2,
    SIGNUP: 3,
    SIGNUP_DONE: 4,
    CONFIGURE_PWD: 5,
    FORGOT_PWD: 6,
    FORGOT_PWD_DONE: 7,
};

const PLANS_ROUTE = 'plans'

/**
 *
 * @param {object} props
 * @param {Function} props.onLogin
 * @param {string} props.errMessage
 */
export default function Login({ onLogin, errMessage }) {
    const { config, testimonialRandomIndex } = React.useContext(AppContext)
    const location = useLocation();
    const creds = React.useRef({})
    const [error, setError] = React.useState(errMessage)
    const [mode, setMode] = React.useState(MODES.SIGNUP)
    const [passwordValidation, setPasswordValidation] = React.useState(true)
    const [isOpenLoginModal, setIsOpenLoginModal] = React.useState(true);
    const [explInformation, setExplInformation] = React.useState(null);
    const [planId, setPlanId] = React.useState(null);
    const [userName, setUserName] = React.useState();

    function changeMode(m) {
        if (m !== MODES.CONFIGURE_PWD) {
            document.cookie = `token=; path=/`;
        }

        setError(null);
        setMode(m);
    }

    React.useEffect(() => {
        var decodedCookie = decodeURIComponent(document.cookie);
        var cookies = decodedCookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();

            if (cookie.startsWith('plan=')) {
                let planSid = cookie.substr(5);
                console.log('checking', {cookie, planSid});
                if (planSid.length > 3) {
                    setPlanId(planSid);
                }
            }
            if (cookie.startsWith('token=')) {
                var token = cookie.substr(6);
                if (token.length) {
                    creds.current.token = token;
                    changeMode(MODES.CONFIGURE_PWD);
                }
            }

        }

    }, []);

    React.useEffect(() => {
        if (config && !explInformation) {
            getExplInformation();
        }
    }, [config])

    function getExplInformation() {
        let splashScreenTestimonials = config.SPLASH_SCREEN_TESTIMONIALS[testimonialRandomIndex];
        let defObj = {
            adminAvatar: splashScreenTestimonials.fileData,
            adminName: splashScreenTestimonials.name,
            adminTitle: splashScreenTestimonials.title,
            adminCompany: splashScreenTestimonials.company,
            adminPhrase: splashScreenTestimonials.text,
            teamsMembers: config.SPLASH_SCREEN_LOGOS
        }
        setExplInformation(defObj);
    }

    async function onSubmit(e) {
        e.preventDefault()

        var ps;
        try {
            switch (mode) {
                case MODES.FORGOT_PWD:
                    await User.requestResetPassword(creds.current.email);

                    trackEvent('reset-password', { email: creds.current.email });
                    changeMode(MODES.FORGOT_PWD_DONE)
                    break;

                case MODES.SIGNIN_EMAIL:
                    ps = await User.login(creds.current.email, creds.current.password)

                    googleLoginState.set(false);
                    if (ps) {
                        onLogin(ps, planId)
                    }
                    break;

                case MODES.SIGNUP:
                    var user = new User();
                    user.email = creds.current.email;
                    await user.save();

                    changeMode(MODES.SIGNUP_DONE)
                    break;

                case MODES.CONFIGURE_PWD:
                    ps = await User.setPassword(creds.current.email, creds.current.password, creds.current.token);
                    trackEvent('set-password', { email: creds.current.email });
                    document.cookie = `token=; path=/`;
                    googleLoginState.set(false);

                    User.ps = ps;
                    const userRes = await User.get(null);
                    userRes.firstName = userName.firstName;
                    userRes.lastName = userName.lastName;

                    await userRes.save();

                    onLogin(ps, planId)

                    break;
            }
        }
        catch (err) {
            setError(err.message)
        }
    }

    const validateInput = (event) => {
        event.target.value = event.target.value.replace(/[^A-Za-z0-9\ ]/gi, '');
    }

    function changeFullName(e) {
        let arr = e.target.value.trim().split(' ');
        let obj = {
            lastName: ' ',
            firstName: ' ',
        }
        if (arr.length === 1) {
            obj.firstName = arr[0];
        } else if (arr.length > 1) {
            obj.lastName = arr.pop();
            obj.firstName = arr.join(' ');
        }
        setUserName(obj);

    }

    function onValueChange(e) {
        creds.current[e.target.name] = e.target.value

        if (creds.current.password && creds.current.verifyPassword) {
            setPasswordValidation(creds.current.password === creds.current.verifyPassword)
        }
    }

    /**
     * @param {GoogleLoginResponse} response
     */
    async function onGoogleLoginSuccess(response) {
        const ps = await User.verifyLogin(
            response.getAuthResponse().id_token,
            'google'
        )

        googleLoginState.set(true);
        onLogin(ps, planId)
    }

    function onGoogleLoginFailure(response) {
        if (response.details) {
            setError(response.details)
        }
        console.error('Google login failed', response)
    }

    function title() {
        switch (mode) {
            case MODES.FORGOT_PWD:
            case MODES.FORGOT_PWD_DONE:
                return 'Forgot My Password';

            case MODES.CONFIGURE_PWD:
                return "We're almost there";

            case MODES.SIGNIN_EMAIL:
                return 'Welcome to Peech';

            default:
                return 'Create your account';
        }
    }

    function titleResponsive() {
        switch (mode) {
            case MODES.FORGOT_PWD:
            case MODES.FORGOT_PWD_DONE:
                return 'Forgot My Password';

            case MODES.CONFIGURE_PWD:
                return "We're almost there";

            case MODES.SIGNIN_EMAIL:
                return 'Log in to your account';

            default:
                return 'Create your account';
        }
    }

    function subtitleText() {
        switch (mode) {
            case MODES.FORGOT_PWD:
                return 'Reset your password';

            case MODES.FORGOT_PWD_DONE:
                return 'Reset password link sent to your email.';

            case MODES.CONFIGURE_PWD:
                return "Confirm your password to help us secure your privacy.";

            case MODES.SIGNIN:
                return "Sign up to start creating new videos";

            case MODES.SIGNIN_EMAIL:
                return "Let’s log in to start creating new videos";

            case MODES.SIGNUP:
                return "Start your free 14-day trial"

            case MODES.SIGNUP_DONE:
                return "Sign up to start increasing your video presence";
        }
    }

    function subtitleResponsiveText() {
        switch (mode) {
            case MODES.FORGOT_PWD:
                return 'Reset your password';

            case MODES.FORGOT_PWD_DONE:
                return 'Reset password link sent to your email.';

            case MODES.CONFIGURE_PWD:
                return "Make you sure no one is looking.\n Now, confirm your password to help us secure your privacy.";

            case MODES.SIGNIN:
                return "Sign up to start creating new videos";

            case MODES.SIGNIN_EMAIL:
                return "Let’s log in to start creating new videos";

            case MODES.SIGNUP:
                return "Start your free 14-day trial"

            case MODES.SIGNUP_DONE:
                return "Sign up to start increasing your video presence";
        }
    }

    const [showPrivacy, setShowPrivacy] = React.useState(false)
    const [showTerms, setShowTerms] = React.useState(false)

    const signup_done = '/images/pointing-finger.png'


    return (
        <div className='login-page-container'>
            <div className='fill-form-side' style={{ width: mode === MODES.SIGNUP_DONE || mode === MODES.FORGOT_PWD || mode === MODES.CONFIGURE_PWD || mode === MODES.FORGOT_PWD_DONE ? '100%' : '34%' }}>
                <div className='fill-form-side-container'>
                    <div className="fill-form-side-header">
                        <img src={HEADER_LOGO} />
                    </div>
                    <div className="login-form">
                        <div className="text-title" textSmall={titleResponsive()} textBig={title()} style={{ fontSize: mode === MODES.FORGOT_PWD ? "28px" : "30px" }} />

                        {mode !== MODES.FORGOT_PWD && <div className="text-subtitle" textSmall={subtitleResponsiveText()} textBig={subtitleText()} style={{ whiteSpace: 'pre-line' }} />}

                        {config.GOOGLE_CLIENT_ID &&
                            (mode === MODES.SIGNIN || mode === MODES.SIGNUP || mode === MODES.SIGNIN_EMAIL) && (
                                <React.Fragment>
                                    <div>
                                        <GoogleLogin
                                            clientId={config.GOOGLE_CLIENT_ID}
                                            onSuccess={onGoogleLoginSuccess}
                                            onFailure={onGoogleLoginFailure}
                                            isSignedIn={true}
                                            render={({ disabled, onClick }) => (
                                                <button
                                                    style={{ marginTop: "54px" }}
                                                    onClick={onClick}
                                                    disabled={disabled}
                                                    id="login-google"
                                                    className="btn-login google-btn"
                                                >
                                                    <div>
                                                        <SVG name="google-icon" width="21" height="21" viewBox="0 0 21 21">{{ marginRight: mode === MODES.SIGNUP ? '25px' : '0' }}</SVG>
                                                    </div>
                                                    <span className='google-span'>{mode === MODES.SIGNIN || mode === MODES.SIGNUP ? 'Sign up' : 'Continue'} with Google</span>
                                                </button>
                                            )}
                                        // cookiePolicy={'single_host_origin'}
                                        >Continue with Google</GoogleLogin>
                                    </div>
                                </React.Fragment>
                            )}
                        <form onSubmit={onSubmit}>
                            {mode === MODES.CONFIGURE_PWD
                                &&
                                (<div className="form-group">
                                    <label className="focus-border-rainbow" htmlFor="full-name"
                                        style={{ marginTop: '40px' }}
                                    >
                                        <input
                                            placeholder={'Full Name'}
                                            type="name"
                                            id="full-name"
                                            data-cy="full-name-input"
                                            name="full-name"
                                            className="form-control input-sm"
                                            required
                                            onInput={validateInput}
                                            onChange={changeFullName}
                                            style={{ backgroundColor: '#E8F0FE' }}
                                        />
                                    </label>
                                </div>)
                            }

                            {
                                (mode === MODES.SIGNIN_EMAIL ||
                                    mode === MODES.SIGNUP ||
                                    mode === MODES.FORGOT_PWD ||
                                    mode === MODES.CONFIGURE_PWD
                                ) &&
                                (
                                    <div className="form-group">

                                        {(mode === MODES.SIGNUP || mode === MODES.SIGNIN_EMAIL) ?
                                            config.GOOGLE_CLIENT_ID ?
                                                <h5
                                                    className="email-login-hint"
                                                >
                                                    Or with mail
                                                </h5>
                                                :
                                                <div style={{ height: '32px' }} />
                                            :
                                            null

                                        }
                                        <label className="focus-border-rainbow" htmlFor="login-email"
                                            style={{ marginTop: mode === MODES.CONFIGURE_PWD || mode === MODES.FORGOT_PWD ? '16px' : '3px' }}
                                        >
                                            <input
                                                placeholder={(mode === MODES.CONFIGURE_PWD ? "Re-enter your email" : "Email")}
                                                type="email"
                                                id="login-email"
                                                data-cy="login-email-input"
                                                name="email"
                                                className="form-control input-sm"
                                                required
                                                autoComplete="email"
                                                onChange={onValueChange}
                                            />
                                        </label>
                                    </div>
                                )}
                            {(mode == MODES.SIGNIN) && (
                                <button className="btn-login btn-login-email"
                                    id="btn-email"
                                    data-cy="signup-email-btn"
                                    onClick={() => (setMode(MODES.SIGNUP))}>
                                    <SVG name="email" width="24" height="18" viewBox="0 0 24 18"></SVG>
                                    <span className='email-span'>
                                        Sign up with email
                                    </span>
                                </button>
                            )}
                            {(mode === MODES.SIGNIN_EMAIL || mode === MODES.CONFIGURE_PWD) && (
                                <div className="form-group">
                                    <label className="focus-border-rainbow" htmlFor="login-password" >
                                        <input
                                            placeholder="Password"
                                            type="password"
                                            id="login-password"
                                            data-cy="login-password-input"
                                            name="password"
                                            className="form-control input-sm"
                                            required
                                            autoComplete="current-password"
                                            minLength="8"
                                            maxLength="30"
                                            onChange={onValueChange}
                                        />
                                    </label>
                                </div>
                            )}
                            {error && !(mode === MODES.CONFIGURE_PWD) &&
                                <div className='signup-alert' data-cy="signup-alert">
                                    <Alert
                                        className={classNames('alert', { '--google': error.startsWith("Not a valid origin") })}
                                        variant="light"
                                    >
                                        <SVG name="error" width="9" height="9" viewBox="0 0 9 9"></SVG>{error}</Alert>
                                </div>}
                            {mode === MODES.CONFIGURE_PWD && (
                                <>
                                    <div className="form-group">
                                        <label className="focus-border-rainbow" htmlFor="verify-password" style={{ marginTop: '16px' }}>
                                            <input
                                                placeholder="Verify Password"
                                                type="password"
                                                id="verify-password"
                                                data-cy="login-password-verify-input"
                                                name="verifyPassword"
                                                className="form-control input-sm"
                                                required
                                                autoComplete="current-password"
                                                minLength="8"
                                                maxLength="30"
                                                onChange={onValueChange}
                                                style={{ backgroundColor: '#E8F0FE' }}
                                            />
                                        </label>
                                    </div>
                                    {(!passwordValidation || error) &&
                                        <div className="password-alert">
                                            <Alert
                                                className='alert'
                                                variant="light"
                                            >
                                                <SVG name="error" width="9" height="9" viewBox="0 0 9 9"></SVG>{error ? error : 'Passwords are not the same'}</Alert>
                                        </div>
                                    }

                                </>
                            )}
                            {(mode === MODES.SIGNIN_EMAIL) && (

                                <>
                                    {!error && <div className='form-group-login-remind'
                                        style={{
                                            // margin: '0 8%',
                                            display: 'flex',
                                            flexDirection: 'row-reverse',
                                            justifyContent: 'space-between',

                                        }}>
                                        <div
                                            className='form-group-login-remind-link'
                                            style={{
                                                padding: '4px',
                                                textDecoration: 'none'
                                            }}
                                            id="login-remind"
                                            className="btn btn-link"
                                            onClick={() => changeMode(MODES.FORGOT_PWD)}
                                        ><a>Forgot Password?</a>
                                        </div>
                                    </div>}
                                    <div className='login-submit'>
                                        <Button
                                            data-cy="login-submit"
                                            type="submit"
                                            id="login-submit"
                                            variant="primary"
                                            style={{ marginTop: error ? '40px' : '0' }}
                                        >
                                            Log in
                                        </Button>
                                    </div>
                                    <div className="text bottom-action">
                                        Don't have an account yet?
                                        <button
                                            style={{ padding: '5px' }}
                                            id="login-signup"
                                            className="btn btn-link bottom-action"
                                            onClick={() => changeMode(MODES.SIGNUP)}
                                        >Sign up</button>
                                    </div>
                                </>
                            )}
                            {mode === MODES.SIGNUP && (
                                <div className="signup-submit" >
                                    <Button
                                        type="submit"
                                        id="signup-submit"
                                        variant="primary"
                                        data-cy="signup-email-submit"
                                    >
                                        Sign up
                                    </Button>
                                </div>
                            )}
                            {(mode === MODES.SIGNUP || mode === MODES.SIGNIN) && (
                                <div className="text bottom-action"
                                // style={{ marginTop: mode === MODES.SIGNIN ? '20px' : '10px' }}
                                >
                                    Already have an account?
                                    <button
                                        data-cy="login-account"
                                        style={{ padding: '5px' }}
                                        id="signup-login"
                                        className="btn btn-link bottom-action"
                                        onClick={() => changeMode(MODES.SIGNIN_EMAIL)}
                                    >Log in</button>
                                </div>
                            )}
                            {mode === MODES.FORGOT_PWD && (
                                <React.Fragment>
                                    <div
                                        style={{ marginTop: '30px' }}
                                    >
                                        <Button
                                            type="submit"
                                            id="remind-submit"
                                            variant="primary"
                                            style={{
                                                width: '314px',
                                                height: '39px',
                                            }}
                                        >
                                            Reset Password
                                        </Button>

                                    </div>
                                    <div className="text bottom-action">
                                        Back to
                                        <button
                                            style={{ padding: '5px' }}
                                            id="remind-login"
                                            className="btn btn-link bottom-action"
                                            onClick={() => changeMode(MODES.SIGNIN_EMAIL)}
                                        >Log In</button>
                                    </div>
                                </React.Fragment>
                            )}
                            {mode === MODES.CONFIGURE_PWD && (
                                <div className="configure-pass">
                                    <Button
                                        type="submit"
                                        id="configure-submit"
                                        data-cy="configure-submit"
                                        variant='primary'
                                        style={{
                                            width: '97px', height: '36px',
                                            marginTop: '12px',
                                        }}
                                    //disabled={passwordValidation !== true}
                                    >
                                        Next
                                    </Button>
                                </div>
                            )}
                            {mode === MODES.SIGNUP_DONE && (
                                <div className='configure-mail'>
                                    <SVG className="contact" name="contact" width="162" height="50" viewBox="0 0 162 50"></SVG>
                                    <div className="sign-up-done">
                                        <h6 id='email-sent'>An email was sent to you to activate your account!</h6>
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>


                    <div className="terms">
                        <div className='terms-row-1'>
                            By creating an account, I declare that I have read and accepted
                        </div>
                        <div className='terms-row-2'>
                            <span className='terms-span'>
                                Peech-Ai’s
                            </span>
                            <span className='terms-span link' onClick={() => setShowTerms(true)}>
                                Terms of Service
                            </span>
                            <span className='terms-span'>
                                and
                            </span>
                            <span className='terms-span link' onClick={() => setShowPrivacy(true)}>
                                Privacy Policy
                            </span>

                        </div>

                        <StaticDoc setShowTerms={setShowTerms} setShowPrivacy={setShowPrivacy} showPrivacy={showPrivacy} showTerms={showTerms} />
                    </div>

                </div>
            </div>



            <div className='explanation-side' style={{ width: mode === MODES.SIGNUP_DONE || mode === MODES.FORGOT_PWD || mode === MODES.CONFIGURE_PWD || mode === MODES.FORGOT_PWD_DONE ? '0%' : '66%' }}>
                <div className='explanation-side-container'>
                    <div className='admin-avatar'>
                        <div className='img-wrapper'>
                            <img alt="AdminAvatar" src={explInformation && explInformation.adminAvatar ? explInformation.adminAvatar : ""} />
                        </div>
                    </div>
                    <div className='admin-name'>
                        {explInformation && explInformation.adminName ? explInformation.adminName : ''}
                        {explInformation && explInformation.adminName && explInformation.adminTitle ? ", " : ""}
                        {explInformation && explInformation.adminTitle ? explInformation.adminTitle : ''}
                        <br />
                        {explInformation && explInformation.adminCompany ? explInformation.adminCompany : ''}
                    </div>
                    <div className='admin-phrase'
                        style={{ whiteSpace: "pre-line" }}
                    >
                        {explInformation && explInformation.adminPhrase ? "\"" + explInformation.adminPhrase + "\"" : ''}
                    </div>
                    <div className='join-text'>
                        Join the content teams that already use Peech:
                    </div>
                    <div className='teams-logos'>
                        {explInformation && explInformation.teamsMembers && explInformation.teamsMembers.length && explInformation.teamsMembers.map((item, index) => {
                            return <img key={index} alt={item.logoName} src={item.logoImage} />
                        })}

                    </div>

                </div>



            </div>

        </div>
    )
}
