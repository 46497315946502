import React from 'react';
import { musicIcon } from '../../../static/svg/musicMenuIcons';
import { playbackIsLockedState } from '../../state/playback';
import { musicBackgroundInProcess, sequenceState } from '../../state/sequence';
import Loader from '../../ui-components/Loader';
import {
  AudioTrackDiv, LoaderContainer,
  MusicTitle,
  MusicTrack
} from './MusicMenu.styled';

interface AudioTrackControllerProperties {
  uploading: boolean | null;
  volumeLoading: boolean;
  useProgress: Number;
}

export default function AudioTrackController({
  uploading,
  volumeLoading,
  useProgress,
}: AudioTrackControllerProperties) {
  const sequence = sequenceState.use();
  const lockPlayer = playbackIsLockedState.use();
  const processingMusic = musicBackgroundInProcess.use();

  return (
    <AudioTrackDiv>
      <MusicTrack>
        {musicIcon}
        <MusicTitle>{uploading ? uploading : sequence?.music?.name}</MusicTitle>
        <LoaderContainer>
          {(uploading || volumeLoading || lockPlayer || processingMusic) && <Loader size="sm" />}
          {useProgress > 0 && useProgress < 100 && <span>{useProgress}%</span>}
        </LoaderContainer>
      </MusicTrack>
    </AudioTrackDiv>
  );
}
