import styled from '@emotion/styled/macro';
import React from 'react';

import SVG from '../../components/svg';

export const Option = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const UpgradeRequiredMark = styled(SVG)<{ hide: boolean }>`
  margin-left: 18px !important;
  opacity: ${(props) => (props.hide ? 0 : 1)};
`;

interface Props {
  text: string;
  allowed: boolean;
}

export const DropdownFeatureOption = ({ text, allowed }: Props) => (
  <Option>
    {text}
    <UpgradeRequiredMark hide={allowed} name="diamond-gradient" />
  </Option>
);
