
import * as Base from '../base/sequence/chapter';
import { ChapterStatus } from '../base/sequence/chapter';
import Sequence from '../sequence';
	
export {default as Content} from '../content';

export { ChapterStatus } from '../base/sequence/chapter';

export interface IChapter extends Base.IChapter {
}

export interface IChapterProperties extends Base.IChapterProperties {

}

export interface IChapterFilter extends Base.IChapterFilter {

}


export default class Chapter<IType extends Base.IChapter = Base.IChapter> extends Base.Chapter<IType> implements IChapter {
    thumb?: string;
    
    get status(): ChapterStatus | null | undefined {
        return this.values.status
    }

    set status(value: ChapterStatus | null | undefined) {
        this.values.status = value
    }

    // TODO add content-identifier to chapter and add the cache content sid and thumb-version
    thumbnailUrl(sequence: Sequence, size: number, baseUrl: string, aspectRatio = '16:9') {
        const ar = aspectRatio.replace(':','-');
        if(this.assetSid) {
            return `${baseUrl}/asset/${this.assetSid}/Content/${size}/${ar}/${this.thumbnailVersion || 1}.png`;
        }
    
        return `${baseUrl}/tc/${sequence.sid}/Chapter/${this.sid}/${size}/16-9/${this.thumbnailVersion}.png`;
    }
}

export class ChapterOrder extends Chapter {
    /**
     * @param {string} value
     */
    set sid(value) {
        if (this.values.sid !== value) {
            this.propertyChanged('sid', value);
            this.values.sid = value;
        }
    }

    /**
    * @returns {boolean} value
    */
    get resized() {
        return this.values.resized
    }

    /**
    * @param {boolean} value
    */
    set resized(value) {
        this.values.resized = value;
    }

    /**
    * @returns {boolean} value
    */
    get transcripted() {
        return this.values.transcripted
    }

    /**
    * @param {boolean} value
    */
    set transcripted(value) {
        this.values.transcripted = value;
    }

    /**
    * @returns {boolean} value
    */
    get keyFramed() {
        return this.values.keyFramed
    }

    /**
    * @param {boolean} value
    */
    set keyFramed(value) {
        this.values.keyFramed = value;
    }

    /**
    * @returns {string} value
    */
    get sid() {
        return this.values.sid
    }

    /**
     * @param {string} value
     */
    set linkSid(value) {
        if (this.values.linkSid !== value) {
            this.propertyChanged('linkSid', value);
            this.values.linkSid = value;
        }
    }

    get linkSid(): string | null | undefined {
        return this.values.linkSid
    }

    get index(): number | null | undefined {
        return this.values.index
    }

    set index(value: number | null | undefined) {
        if (this.values.index !== value) {
            this.propertyChanged('index', value);
            this.values.index = value ? Math.floor(value) : value;
        }
    }

    get duration(): number | null | undefined {
        return this.values.duration
    }

    set duration(value: number | null | undefined) {
        if (this.values.duration !== value) {
            this.propertyChanged('duration', value);
            this.values.duration = value ? parseFloat(String(value)) : value;
        }
    }

    set sourceDuration(value: number | null | undefined) {
        this.values.sourceDuration = value;
    }

    get sourceDuration(): number | null | undefined {
        return this.values.sourceDuration || this.values.srcDuration
    }

    /**
    * @description add values to changedProperties before sent to scenesReorder
    */
    propertiesEval() {
        const properties = ['sid', 'linkSid', 'title', 'clipFrom', 'index']

        properties.forEach(key =>
            this.values[key] && !this.changedProperties.includes(key) && this.propertyChanged(key)
        )
    }

}