import React, { useCallback } from 'react';
import { navigate } from 'gatsby';
import { Modal } from 'react-bootstrap';
import styled from '@emotion/styled/macro';

import upgradeBannerImage from '../../../static/images/upgrade-modal-bg.jpg';

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 580px;
  }

  .modal-content {
    display: flex;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
`;

const PeechProUpgradeModalBody = styled.div`
  display: flex;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 12%) 8px 4px 8px 0;
`;

const LeftPanel = styled.div`
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  padding: 30px 32px;
  font-family: 'Open Sans', sans-serif;
  color: #323338;
  background-color: #fff;
`;

const RightPanel = styled.div`
  flex: 0 0 50%;
  background-image: url('${upgradeBannerImage}');
  background-color: #443964;
  background-size: contain;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
`;

const Features = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
`;

const FeaturesTitle = styled.div`
  font-size: 15px;
  line-height: 25px;
  font-weight: 700;
`;

const FeaturesUl = styled.ul`
  display: flex;
  flex-direction: column;
`;

const FeaturesLi = styled.li`
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
`;

const Note = styled.div`
  font-size: 12px;
  line-height: 30px;
  font-weight: 400;
  margin-top: 42px;
`;

const StartTrialButton = styled.button`
  height: 30px;
  margin-top: 12px;
  border: none;
  border-radius: 6px;
  background: linear-gradient(90deg, #ea429d 0%, #ea429d 9.11%, #ea75b4 24.25%, #ae8bfa 54.15%, #1f9ffb 99.42%);
  color: #fff;
`;

export default function PeechProUpgradeModal({ isOpen, onClose }: { isOpen: boolean; onClose(): void }) {
  const onStartTrialButtonClick = useCallback(() => {
    onClose();
    navigate('/plans');
  }, []);

  return (
    <StyledModal show={isOpen} onHide={onClose} centered>
      <PeechProUpgradeModalBody>
        <LeftPanel>
          <Title>
            <div>Try Peech Pro</div>
          </Title>

          <Features>
            <FeaturesTitle>Here is what you’ll get:</FeaturesTitle>

            <FeaturesUl>
              <FeaturesLi>More videos every month</FeaturesLi>
              <FeaturesLi>Extended upload duration</FeaturesLi>
              <FeaturesLi>Access to premium music</FeaturesLi>
              <FeaturesLi>Remove Peech watermark</FeaturesLi>
            </FeaturesUl>
          </Features>

          <Note>* You can cancel anytime.</Note>
          <StartTrialButton onClick={onStartTrialButtonClick}>Get Started</StartTrialButton>
        </LeftPanel>

        <RightPanel />
      </PeechProUpgradeModalBody>
    </StyledModal>
  );
}
