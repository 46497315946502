
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export enum UserBaseStatus {
	PENDING = 1,
	READY = 2,
	DELETED = 3,
	BLOCKED = 4,
};

export enum UserBaseType {
	USER = 1,
	TEAM = 2,
	ORG = 3,
};

export interface IUserBase extends IBase {
	status?: number | null;
	uiFlags?: number | null;
}

export interface IUserBaseProperties extends IBaseProperties {
	status?: boolean;
	uiFlags?: boolean;
}

export interface IUserBaseFilter extends IBaseFilter {
}

export class UserBase<IType extends IUserBase = IUserBase, ITypeProperties extends IUserBaseProperties = IUserBaseProperties> extends Base<IType, ITypeProperties> implements IUserBase {
	
	static className = 'UserBase';
	static NAME = 'userBase';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		uiFlags: true,
	};
	
	/**
	 * @deprecated use UserBaseStatus instead
	 */
	static STATUS: any = {
		PENDING: UserBaseStatus.PENDING,
		READY: UserBaseStatus.READY,
		DELETED: UserBaseStatus.DELETED,
		BLOCKED: UserBaseStatus.BLOCKED,
	};
	
	/**
	 * @deprecated use UserBaseType instead
	 */
	static TYPE: any = {
		USER: UserBaseType.USER,
		TEAM: UserBaseType.TEAM,
		ORG: UserBaseType.ORG,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get uiFlags(): number | null | undefined {
		return this.values.uiFlags
	}
			
	set uiFlags(value: number | null | undefined) {
		if(this.values.uiFlags !== value) {
			this.propertyChanged('uiFlags', value);
			this.values.uiFlags = value;
		}
	}
			
	
	static get<IType extends IUserBase = IUserBase>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IUserBaseFilter = IUserBaseFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IUserBaseFilter = IUserBaseFilter, IType extends IUserBase = IUserBase>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
