import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../lib/app-context';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  min-height: 100px;
  min-width: 100px;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

export default function Roadmap() {
  const { config } = React.useContext(AppContext);

  return (
    <>
      <Container>
        <iframe src="https://portal.productboard.com/bjuoy25qrsgszogb6bxcyr1d?hide_header=1" frameborder="0"></iframe>
      </Container>
    </>
  );
}
