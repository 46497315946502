import React from 'react';
import classNames from 'classnames';
import SVG from '../../components/svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function MenuItem({ label, id, bordered, className, children, actions, ...rest }) {
  const actionItem = (action, index) => (
    <a key={index} onClick={action.onClick} className={action.className || ''}>
      <OverlayTrigger
        placement="bottom"
        overlay={
          action.tooltip ? (
            <Tooltip id="action-item-tooltip" className="tooltip-peech-light">
              {action.tooltip}
            </Tooltip>
          ) : (
            <span />
          )
        }
      >
        <SVG name={action.svg} />
      </OverlayTrigger>
    </a>
  );

  return (
    <div className={classNames('menu-item', { 'menu-item--bordered': bordered }, className)} {...rest}>
      {label && id ? (
        <div className="menu-item-header">
          <label className="menu-item-label" htmlFor={id}>
            {label}
          </label>
          {!!actions?.length && <div className="menu-item-actions">{actions.map((action, index) => actionItem(action, index))}</div>}
        </div>
      ) : null}
      <div className="menu-item-content">{children}</div>
    </div>
  );
}

MenuItem.defaultProps = {
  bordered: true,
};
