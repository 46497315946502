import React from 'react';
import { appConfig, AppContext, mixpanel } from '../../lib/app-context';
import { AnalyticsBrowser } from '@segment/analytics-next';
import _ from "lodash";

import User, { IUser } from '../../client/user';
import Plan from '../../client/plan';
import LogRocket from 'logrocket';
import {
  appendAssets,
  appendSequences,
  footagesProcessing,
  footagesState,
  loadedAssetsState,
  loadedPresetsState,
  loadedSequencesState,
  modifyAsset,
  prependSequences,
  sequencesSharedCountState,
  sequencesSinceLastChargeState,
  sequencesTotalCountState,
  shouldLoginState,
  unloadSequence,
  updateLoadedSequence,
} from '../../state/local';

import Preset from '../../client/preset';
import Sequence from '../../client/sequence';

import Overlay from '../../pages/overlay';
import Layout from '../layout';
import Status from '../../lib/status';
import pkg from '../../../package.json';
import Subscription from '../../client/subscription';

import { libraryInitializedState, sequencesInitializedState } from '../../ui-components/Header/Header';
import Asset, { AssetStatus, AssetType } from '../../client/asset';

import * as Sentry from '@sentry/react';
import { handleError } from '../../state/error';
import { sequenceState } from '../../state/sequence';
import { incrementPlaybackVersion } from '../../state/playback';
import VideoType, { IVideoType } from '../../client/videoType';

export const PAGE_SIZE = 60;
// TODO: get writeKey from config file
export let analytics;

const App = ({ element }) => {
  const [config, setConfig] = React.useState();

  async function getConfig() {
    if (config) {
      return;
    }

    const version = process.env.npm_package_version;
    let builNumber = process.env.VERSION_BUILD_NUMBER;
    if (!builNumber) {
      builNumber = pkg.build;
    }
    const response = await fetch(`/config/${version}-${builNumber}/config.json`);
    Object.assign(appConfig, await response.json());
    // for local dev we need to set the API_V2_URL differently we set it on development.js 
    if(!appConfig.API_V2_URL) {
      appConfig.API_V2_URL = appConfig?.API_URL!.replace("graphql", "v2/graphql");
    }
    setConfig(appConfig);
  }

  React.useEffect(getConfig, []);

  React.useEffect(() => {
    if (config && document.location.pathname !== '/overlay/') {
      console.log('Init AnalyticsBrowser', config.SEGMENT_TOKEN);
      analytics = AnalyticsBrowser.load({ writeKey: config.SEGMENT_TOKEN });
    }
    return () => {};
  }, [config]);

  if (!config) {
    return null;
  }

  if (document.location.pathname === '/overlay/') {
    return (
      <AppContext.Provider value={{ config, inOverlay: true }}>
        <Overlay />
      </AppContext.Provider>
    );
  }

  return <AppWrapper element={element} config={config} />;
};

const AppWrapper = ({ element, config }) => {
  const [user, setUser] = React.useState<IUser>();
  const [plan, setPlan] = React.useState();
  const [subscription, setSubscription] = React.useState();
  const [status, setStatus] = React.useState();
  const [init, setInit] = React.useState();
  const [testimonialRandomIndex, setTestimonialRandomIndex] = React.useState(0);
  const [defaultVideoType, setDefaultVideoType] = React.useState<VideoType<IVideoType>>();
  const [isIOS, setIsIOS] = React.useState(false);
  const [isMobileDevice, setIsMobileDevice] = React.useState(false);
  const openSequence = sequenceState.use();
  const sequences = loadedSequencesState.use();
  const [reachProjectLimit, setReachProjectLimit] = React.useState(false);
  const numOfSequenceInPaymentCycle = sequencesSinceLastChargeState.use();

  const getSequencesCountByTime = async (lastActivationDate: number) => {
    const sequencesCount =
      lastActivationDate &&
      (await Sequence.count({
        insertTimeGreaterThan: lastActivationDate,
        statusIn: [Sequence.STATUS.ACTIVE],
      }));

    sequencesSinceLastChargeState.set(sequencesCount);
  };

  const getSequencesTotalCount = async () => {
    const sequencesCount = await Sequence.count({
      statusIn: [Sequence.STATUS.ACTIVE],
    });

    sequencesTotalCountState.set(sequencesCount);
  };

  const getSequencesSharedCount = async () => {
    const sequencesCount = await Sequence.count({
      statusIn: [Sequence.STATUS.ACTIVE],
      roleNotEqual: 1,
    });

    sequencesSharedCountState.set(sequencesCount);
  };

  React.useEffect(() => {
    if (subscription && !user?.UIFLAGS.SMART_LIBRARY) {
      const { lastActivationDate } = subscription;
      getSequencesCountByTime(lastActivationDate);
    }
  }, [sequences.length, subscription, openSequence.status]);

  React.useEffect(() => {
    if (user?.sid && !user?.UIFLAGS.SMART_LIBRARY) {
      getSequencesTotalCount();
      getSequencesSharedCount();
    }
  }, [user?.sid, sequences.length, openSequence.status]);

  React.useEffect(() => {
    if (plan?.maxVideos === undefined || numOfSequenceInPaymentCycle < plan.maxVideos) {
      setReachProjectLimit(false);
    } else {
      setReachProjectLimit(true);
    }
  }, [numOfSequenceInPaymentCycle, plan]);

  React.useEffect(() => {
    window.mobileCheck = (function () {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      //check if we are on ipad
      if (navigator.platform.indexOf('iPad') != -1) check = false;
      if (navigator.userAgent.match(/iPad/i)) check = false;
      setIsMobileDevice(check);
      setIsIOS(/iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream);
      return check;
    })();
  }, []);

  const isInitialized = Sentry.getCurrentHub().getClient();

  React.useEffect(() => {
    if (isInitialized) {
      Sentry.setUser(user ? { email: user.email, id: user.sid } : null);
    }
  }, [isInitialized, user]);

  async function getUser(ps) {
    if (ps) {
      User.ps = ps;
    } else if (!User.ps) {
      return setUser(null);
    }

    try {
      /**
       * @type {User}
       */
      const user = await User.get(null);
      if (!user) {
        return;
      }
      LogRocket.identify(user.sid, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        environment: ENV_NAME,
      });
      mixpanel.identify(user.sid);
      mixpanel.people?.set({
        Environment: ENV_NAME,
        $distinct_id: user.sid,
        $email: user.email,
        $first_name: user.firstName,
        $last_name: user.lastName,
        $created: new Date(user.insertTime).toISOString(),
      });
      const { sid, email, firstName, lastName, insertTime } = user;
      analytics.identify(sid, {
        environment: ENV_NAME,
        firstName,
        lastName,
        email,
        created: insertTime,
      });

      const stat = new Status(undefined, user.sid, user.orgSid);
      // Get user access on share project.
      // Fix the invite notifications

      // stat.user.on("access", (props) => console.log("Status access works", { ...props }))

      stat.user
        .on('access', ({ ack }) => {
          // TODO add the new project to shared projects list
          ack && ack();
        })
        .on('asset', ({ itemId, ack, asset, ...props }) => {
          console.log('status asset', { itemId, ...props });
          if (asset?.type === AssetType.SOURCE) {
            const update = new Asset().set(asset);
            modifyAsset(update);
          }
          // TODO add the new asset to the assets list
          ack && ack();
        })
        .on('watch', ({ ack }) => {
          ack && ack();
        })
        .on('unwatch', ({ ack }) => {
          ack && ack();
        })
        .on('sequence', ({ sequence, ack, ...props }) => {
          const { sid, duration, status, languageCode } = sequence;
          const currentSequence = sequenceState.get();
          const triggerPlayback =
            currentSequence && currentSequence.sid === sid && currentSequence.duration !== duration;
          console.log('triggerPlayback', {
            sid,
            triggerPlayback,
            duration,
            currentSequenceDuration: currentSequence?.duration,
            sequence
          });
          updateLoadedSequence(sid, duration, status, languageCode);
          if (triggerPlayback) {
            incrementPlaybackVersion();
          }
          ack && ack();
        })
        .on('sequenceDeleted', ({ itemId, ack }) => {
          unloadSequence(itemId);
          ack && ack();
        })
        .on('error', ({ message, ack, code }) => {
          handleError({
            title: 'Server Error',
            statusCode: code,
            message,
          });
          ack && ack();
        });

        // add socket listen to footages 
        // stat.footages.on(({data}) => {
        //   console.log("footages socket event", data);
        //   footagesState.set((prevState:{[key: string]: any }) => ({ ...prevState, [data._id]: data }));
        // }
        // );

      setStatus(stat);
      shouldLoginState.set(false);
      // TODO implement google analytics
      try {
        var presets = await Preset.list();
      } catch (err) {
        console.error(err);
      }
      loadedPresetsState.set(presets || []);
      if (presets?.length) {
        user.preset = Preset.getDefaultPreset(presets);
      }
      setUser(user);
    } catch (err) {
      console.error(err);
      setUser(null);
    }
  }

  function getMixpanel() {
    if (mixpanel) {
      mixpanel.init(config.MIXPANEL_TOKEN || 'NULL');
      if (!config.MIXPANEL_TOKEN) {
        mixpanel.disable();
      }
    }
    setInit(true);
  }

  // function setSegmentIO() {
  // 	const analytics = AnalyticsBrowser.load({ writeKey: '<YOUR_WRITE_KEY>' })

  // }

  function identifyPlan() {
    if (init && plan && analytics) {
      const { sid, name } = plan;
      analytics.identify({
        PlanSid: sid,
        Plan: name,
      });
    }
  }

  function configureMixpanel() {
    if (init && plan && mixpanel) {
      mixpanel.people?.set({
        'Plan Sid': plan.sid,
        Plan: plan.name,
      });
    }
  }

  async function getPlan() {
    if (!user) {
      return;
    }

    const p = await Plan.get(null, {
      sid: true,
      status: true,
      name: true,
      recurringPrice: true,
      overrideRecurringPrice: true,
      annualRecurringPrice: true,
      annualOverrideRecurringPrice: true,
      description: true,
      freeTrial: true,
      maxVideoDuration: true,
      maxVideos: true,
      maxUsers: true,
      maxStorage: true,
      brandsCount: true,
      updateTime: true,
      tier: true,
      allowedTierFeatures: true,
      disallowedTierFeatures: true,
      features: true,
      showShutterStockBadge: true,
      maxFootageUploads: true,
    });
    setPlan(p);
  }

  async function loadLibrary() {
    // if smart library is enabled, we don't need to load sequences
    // TODO: change to not fetch at all from the app provider 
    if(user?.UIFLAGS.SMART_LIBRARY){
      return 
    }
    if (loadedAssetsState.get().length) {
      return;
    }
    try {
      var items = await Asset.list(
        {
          typeEqual: AssetType.SOURCE,
          statusIn: [AssetStatus.PROCESSING, AssetStatus.READY],
        },
        {
          pageSize: PAGE_SIZE,
          pageIndex: 1,
        },
        {
          ...Asset.PROPERTIES,
          duration: true,
        }
      );
    } catch (err) {
      console.error(err);
    }

    libraryInitializedState.set(true);
    if (items?.length) {
      appendAssets(...items);
    }
  }

  async function loadSeqeunces() {
    // if smart library is enabled, we don't need to load sequences
    // TODO: change to not featch at all from the app provider 
    if(user?.UIFLAGS.SMART_LIBRARY){
      return 
    }

    if (loadedSequencesState.get().length) {
      return;
    }
    try {
      var items = await Sequence.list(
        { userSidEqual: user.sid, statusEqual: 1, roleEqual: 1 },
        {
          pageSize: PAGE_SIZE,
          pageIndex: 0,
        },
        {
          sid: true,
          insertTime: true,
          status: true,
          chapters: {
            sid: true,
            status: true,
            contentVersion: true,
            thumbnailVersion: true,
            cuePointType: true,
          },
          duration: true,
          title: true,
          role: true,
          accessesCount: true,
          accesses: {
            userSid: true,
            role: true,
            email: true,
            name: true,
            thumbUrl: true,
            creator: true,
          },
          aspectRatio: true,
          languageCode: true,
          folderSid: true,
        }
      );
    } catch (err) {
      console.error(err);
    }

    try {
      var sharedWithMe = await Sequence.list(
        { userSidEqual: user.sid, statusEqual: 1, roleNotEqual: 1 },
        {
          pageSize: PAGE_SIZE,
          pageIndex: 0,
        },
        {
          sid: true,
          insertTime: true,
          status: true,
          chapters: {
            sid: true,
            status: true,
            contentVersion: true,
            thumbnailVersion: true,
            cuePointType: true,
          },
          duration: true,
          title: true,
          role: true,
          folderSid: true,
          accessesCount: true,
          accesses: {
            userSid: true,
            role: true,
            email: true,
            name: true,
            thumbUrl: true,
            creator: true,
          },
          aspectRatio: true,
          languageCode: true,
        }
      );
    } catch (err) {
      console.error(err);
    }

    sequencesInitializedState.set(true);
    if (items?.length || sharedWithMe?.length) {
      appendSequences(...items, ...sharedWithMe);
    }
    loadLibrary();
  }

  // async function loadFootages () {
  //  const footages =  await getFootages();
  //  footagesState.set(_.keyBy(footages,"_id"));
  // }

  async function loadDefaultVideoType() {
    if (defaultVideoType) {
      return;
    }

    try {
      const types = await VideoType.list({ statusEqual: VideoType.STATUS.IS_DEFAULT });
      setDefaultVideoType(types[0]);
    } catch (err) {
      handleError({
        title: 'Fetching video types failed',
        message: err.message,
        responseError: err,
      });
    }
  }

  function loadUserData() {
    if (!user) {
      sequencesSinceLastChargeState.set(null);
      return;
    }
    getPlan();
    loadSeqeunces();
    // loadFootages();
    loadDefaultVideoType();
  }

  async function getSubscription() {
    const sub = await Subscription.get(null, {
      ...Subscription.PROPERTIES,
      footageUploadsCount: true,
    });
    if (sub) {
      setSubscription(sub);
      const { lastActivationDate } = sub;
      // @TODO add filter statusEqual with relevant status
      if(lastActivationDate && !user?.UIFLAGS.SMART_LIBRARY) {
        getSequencesCountByTime(lastActivationDate);
      }

    }
    return sub;
  }

  React.useEffect(() => {
    getUser();
    if (config?.SPLASH_SCREEN_TESTIMONIALS) {
      let min = 0;
      let max = config.SPLASH_SCREEN_TESTIMONIALS.length - 1;
      let random = Math.floor(Math.random() * (max - min + 1) + min);
      setTestimonialRandomIndex(random);
    }
  }, [config]);

  React.useEffect(loadUserData, [user]);
  React.useEffect(getMixpanel, [mixpanel]);
  React.useEffect(configureMixpanel, [init, plan, mixpanel]);
  React.useEffect(identifyPlan, [init, plan, mixpanel]);

  React.useEffect(() => {
    plan && getSubscription();
  }, [plan]);


  const footages = footagesState.use();

  React.useEffect(()=> {
    console.log("footages state changed", footages);
    // @ts-ignore
    const isFootagesProcessing = _.some(footages, ({status}) => status === "creatingAssets" ||  status === "ingestingFootage");
    console.log("footages isCreatingAssets", isFootagesProcessing);
    footagesProcessing.set(isFootagesProcessing);
  },[footages])

  if (!init) {
    return null;
  }

  return (
    <AppContext.Provider
      value={{
        config,
        user,
        setUser,
        getUser,
        mixpanel,
        plan,
        setPlan,
        status,
        setStatus,
        subscription,
        setSubscription,
        testimonialRandomIndex,
        defaultVideoType,
        isMobileDevice,
        isIOS,
        reachProjectLimit,
      }}
    >
      <Layout />
    </AppContext.Provider>
  );
};

export default ({ element }) => {
  return <App element={element} />;
};
