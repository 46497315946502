import styled from '@emotion/styled/macro';
import React from 'react';
import { convertMsToTime, convertTimeToMs, TimeObj, timePad, TimeType } from '../../../utils/time-utils';

export enum TimeFormat {
  MMSSmmm = 'MM:SS:mmm',
  MMSS = 'MM:SS',
}

interface Props {
  time: number;
  minTime: number;
  maxTime: number;
  onTimeChange?: (time: number) => void;
  format?: TimeFormat;
  disabled?: boolean;
}

const Container = styled.div<{ focused: boolean }>`
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input {
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  color: #666666;
  border: 1px solid ${({ focused }): string => (focused ? '#1f9ffb' : 'transparent')};
  padding: 0 2px;
  border-radius: 4px;
  line-height: 12px;
  font-size: 12px;
  letter-spacing:0px;
`;

const Input = styled.input<{ width: number }>`
  border: none;
  width: ${({ width }) => width}px;
  letter-spacing:1px;
  padding: 0px;
  outline: 0px solid transparent;
  text-align: center;
  &:focus {
    cursor: none;
  }
  &:invalid {
    background-color: rgba(237, 63, 153, 0.4);
  }
`;

export const TimeCodeComponent: React.FC<Props> = ({
  time,
  minTime,
  maxTime,
  onTimeChange,
  format = TimeFormat.MMSSmmm,
  disabled = false,
}) => {
  const mInputRef = React.useRef<HTMLInputElement>(null);
  const sInputRef = React.useRef<HTMLInputElement>(null);
  const msInputRef = React.useRef<HTMLInputElement>(null);
  const [timeState, setTimeState] = React.useState<TimeObj>(convertMsToTime(time));
  const minTimeObj = React.useMemo(() => convertMsToTime(minTime), [minTime]);
  const maxTimeObj = React.useMemo(() => convertMsToTime(maxTime), [maxTime]);
  const [isInputFocused, setIsInputFocused] = React.useState(false);

  React.useEffect(() => {
    setTimeState(convertMsToTime(time));
  }, [time]);

  // store current time in state
  const setTimeByTimeType = (timeType: TimeType, value: number) => {
    const newTime = { ...timeState };
    newTime[timeType] = Number(value);
    setTimeState(newTime);
  };

  // format value for max digit and save in state
  const formatInput = React.useCallback(
    (input: HTMLInputElement, digit: number, timeType: TimeType) => {
      let { value } = input;
      if (value.length > digit) {
        value = value.slice(1, digit + 1);
      }
      setTimeByTimeType(timeType, Number(value));
      input.value = timePad(value, digit);
    },
    [setTimeByTimeType]
  );

  // validate times and update inputs
  const validateValue = () => {
    const validTime = { ...timeState };
    if (mInputRef?.current) {
      if (timeState.m > maxTimeObj.m) {
        console.log('Time-code-max-minutes', 'user-input:', validTime.m, 'max-value:', maxTimeObj.m);
        validTime.m = maxTimeObj.m;
      }

      if (timeState.m < minTimeObj.m) {
        console.log('Time-code-min-minutes', 'user-input:', validTime.m, 'min-value:', minTimeObj.m);
        validTime.m = minTimeObj.m;
      }

      mInputRef.current.value = timePad(validTime.m, 2);
    }

    if (sInputRef?.current) {
      if (timeState.s > sMax) {
        console.log('Time-code-max-seconds', 'user-input:', validTime.s, 'max-value:', sMax);
        validTime.s = sMax;
        // sInputRef.current.value = timePad(sMax, 2);
      }

      if (timeState.s < sMin) {
        console.log('Time-code-min-seconds', 'user-input:', validTime.s, 'min-value:', sMin);
        validTime.s = sMin;
      }

      sInputRef.current.value = timePad(validTime.s, 2);
    }

    if (msInputRef?.current) {
      if (timeState.ms > msMax) {
        console.log('Time-code-max-Milliseconds', 'user-input:', validTime.ms, 'max-value:', msMax);
        validTime.ms = msMax;
      }

      if (timeState.ms < msMin) {
        console.log('Time-code-min-Milliseconds', 'user-input:', validTime.ms, 'min-value:', msMin);
        validTime.ms = msMin;
      }

      msInputRef.current.value = timePad(validTime.ms, 3);
    }

    setTimeState(validTime);
    const timeInMS = convertTimeToMs(validTime);
    if (timeInMS !== time) {
      onTimeChange && onTimeChange(convertTimeToMs(validTime));
    }
  };

  // sec min base on the min value
  const sMin = React.useMemo(() => {
    return timeState.m > minTimeObj.m ? 0 : minTimeObj.s;
  }, [timeState, minTimeObj]);

  // sec max base on the min value
  const sMax = React.useMemo(() => {
    return timeState.m < maxTimeObj.m ? 59 : maxTimeObj.s;
  }, [timeState, maxTimeObj]);

  // ms min time base on sec value
  const msMin = React.useMemo(() => {
    return timeState.m > minTimeObj.m || timeState.s > minTimeObj.s ? 0 : minTimeObj.ms;
  }, [timeState, minTimeObj]);

  // ms max time base on sec value
  const msMax = React.useMemo(() => {
    return timeState.m < maxTimeObj.m || timeState.s < maxTimeObj.s ? 999 : maxTimeObj.ms;
  }, [timeState, maxTimeObj]);

  return (
    <Container focused={isInputFocused}>
      {/*min input */}
      <Input
        ref={mInputRef}
        type="number"
        data-cy='minutes-input'
        width={16}
        disabled={disabled}
        min={minTimeObj.m}
        max={maxTimeObj.m}
        value={timePad(timeState.m, 2)}
        step="1"
        onInput={(e) => mInputRef?.current && formatInput(mInputRef.current, 2, TimeType.M)}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => {
          setIsInputFocused(false);
          validateValue();
        }}
        onWheel={(e) => {
          e.stopPropagation();
        }}
      />
      :{/*sec input */}
      <Input
        ref={sInputRef}
        type="number"
        data-cy='seconds-input'
        disabled={disabled}
        width={16}
        min={sMin}
        max={sMax}
        value={timePad(timeState.s, 2)}
        step="1"
        onInput={(e) => sInputRef?.current && formatInput(sInputRef.current, 2, TimeType.S)}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => {
          setIsInputFocused(false);
          validateValue();
        }}
        onWheel={(e) => {
          e.stopPropagation();
        }}
      />
      {format === TimeFormat.MMSSmmm && (
        <>
          .{/*ms input */}
          <Input
            ref={msInputRef}
            type="number"
            data-cy='milliseconds-input'
            disabled={disabled}
            width={25}
            min={msMin}
            max={msMax}
            value={timePad(timeState.ms, 3)}
            step="1"
            onInput={(e) => msInputRef?.current && formatInput(msInputRef.current, 3, TimeType.MS)}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => {
              setIsInputFocused(false);
              validateValue();
            }}
            onWheel={(e) => {
              e.stopPropagation();
            }}
          />
        </>
      )}
    </Container>
  );
};
