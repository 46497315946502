
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'

export interface IContentIdentifier extends IBase {
	thumbnailVersion?: number | null;
	key?: string | null;
	aspectRatio?: string | null;
}

export interface IContentIdentifierProperties extends IBaseProperties {
	thumbnailVersion?: boolean;
	key?: boolean;
	aspectRatio?: boolean;
}

export interface IContentIdentifierFilter extends IBaseFilter {
}

export class ContentIdentifier<IType extends IContentIdentifier = IContentIdentifier, ITypeProperties extends IContentIdentifierProperties = IContentIdentifierProperties> extends Base<IType, ITypeProperties> implements IContentIdentifier {
	
	static className = 'ContentIdentifier';
	static NAME = 'contentIdentifier';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		thumbnailVersion: true,
		key: true,
		aspectRatio: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get thumbnailVersion(): number | null | undefined {
		return this.values.thumbnailVersion
	}
			
	get key(): string | null | undefined {
		return this.values.key
	}
			
	get aspectRatio(): string | null | undefined {
		return this.values.aspectRatio
	}
			
	
	static get<IType extends IContentIdentifier = IContentIdentifier>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IContentIdentifierFilter = IContentIdentifierFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IContentIdentifierFilter = IContentIdentifierFilter, IType extends IContentIdentifier = IContentIdentifier>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
