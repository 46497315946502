import React from 'react';
import CuePoint from '../../client/cuePoint';
import {
    BoldButton, Limit,
    OptionsBlock, SectionTitle, Stack
} from '../../ui-components/Menu/MenuComponents.styled';
import SVG from '../svg';
import { SCENE_TYPE } from './IntroAndOutro';
import {
    IntroOutroInput
} from './IntroAndOutro.styled';
import { INPUT_TYPE} from './IntroOutroBlock';

interface InputsControllerProperties {
  cuePoint: CuePoint;
  type: SCENE_TYPE;
  inputType: INPUT_TYPE;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>, field: string) => void;
  onInputBlur: (e: React.ChangeEvent<HTMLInputElement>, field: string) => void;
  onKeyPress: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef: React.LegacyRef<HTMLInputElement> | undefined;
  getMaxLength?: (inputType: INPUT_TYPE) => number;
  inputsLimit?: number | null;
  usedSymbolsCount?: number;
  onFontWeightChange?: () => void;
  isBold?: boolean;
  textDirection: string;
}

export default function InputsController({
  cuePoint,
  type,
  inputType,
  onInputChange,
  onInputBlur,
  onKeyPress,
  inputRef,
  getMaxLength,
  inputsLimit,
  usedSymbolsCount,
  onFontWeightChange,
  isBold,
  textDirection
}: InputsControllerProperties) {

  React.useEffect(() => {
    inputRef.current.style.maxHeight = "30px";
    inputRef.current.style.maxHeight = Math.min(5, Math.floor(inputRef.current.scrollHeight / 20)) * 20 + 12 + 'px';
  }, [inputRef?.current?.scrollHeight])

  return (
    <>
      <SectionTitle style={{ fontSize: '12px' }}>
        {type === SCENE_TYPE.OUTRO ? 'Call to action' : inputType === INPUT_TYPE.TITLE ? 'Title' : 'Subtitle'}
        <OptionsBlock>
          {inputsLimit && usedSymbolsCount !== undefined && inputType === INPUT_TYPE.TITLE && (
            <Limit>
              {usedSymbolsCount} / {inputsLimit}
            </Limit>
          )}
          {type === SCENE_TYPE.OUTRO && <BoldButton onClick={() => onFontWeightChange && onFontWeightChange()} isSelected={!!isBold}>
            <SVG name="bold" />
          </BoldButton>}
        </OptionsBlock>
      </SectionTitle>
      <Stack spacing={4}>
        <IntroOutroInput
          data-cy="wizard-title-input"
          type={'text'}
          placeholder="Add text here"
          id={type + `-title`}
          defaultValue={type === SCENE_TYPE.INTRO && inputType === INPUT_TYPE.TITLE ? cuePoint?.title || '' : cuePoint?.text || ''}
          onChange={(e) => {
            let field = 'text';
            if (type === SCENE_TYPE.INTRO && inputType === INPUT_TYPE.TITLE) {
              field = 'title';
            }
            onInputChange(e, field);
          }}
          onBlur={(e) => {
            let field = 'text';
            if (type === SCENE_TYPE.INTRO && inputType === INPUT_TYPE.TITLE) {
              field = 'title';
            }
            onInputBlur(e, field);
          }}
          onInput={(e) => {
            e.currentTarget.style.maxHeight = "30px";
            e.currentTarget.style.maxHeight = Math.min(5, Math.floor(e.currentTarget.scrollHeight / 20)) * 20 + 12 + 'px';
          }}
          onKeyDown={onKeyPress}
          ref={inputRef}
          maxLength={getMaxLength && getMaxLength(inputType)}
          dir={textDirection}
          autoComplete='off'
          rows={4}
        />
      </Stack>
    </>
  );
}
