
import React from 'react';
import User from '../client/user';
import Plan from '../client/plan';
import Status from './status';
import mixPanel, { Mixpanel } from 'mixpanel-browser';
import Subscription from '../client/subscription';
import VideoType from '../client/videoType';
import Sequence from '../client/sequence';


interface IMixpanelData {
    sequenceSid?: string;
}

mixPanel.trackSequence = (event, data: IMixpanelData = {}) => {
    if (mixPanel.sequenceSid) {
        data.sequenceSid = mixPanel.sequenceSid;
    }
    mixPanel.track(event, data);
}


export const mixpanel = mixPanel

export interface ISplashScreenTestimonial {
    company: string,
    name: string,
    title: string,
    filename: string,
    text: string
}

export interface ISplashScreenImage {
    logoImage: string,
    logoName: string
}

export interface ISupportedLanguage {
    languageCode: string,
    status: string
}

export interface IConfig {
    API_URL?: string;
    API_V2_URL?: string;
    CHUNKS_URL?: string;
    CONTENT_URL?: string;
    PLAYBACK_URL?: string;
    ENV_NAME?: string;
    GTM_ID?: string;

    MIXPANEL_TOKEN?: string;
    GOOGLE_CLIENT_ID?: string;
    LOGROCKET_APP_ID?: string;
    SENTRY_TOKEN?: string;
    SENTRY_TRACES_SAMPLE_RATE?: number;
    BLUE_SNAP_SCRIPT_URL?: string;
    PADDLE_VENDOR_ID?: number;
    PADDLE_ENV?: string;

    GOOGLE_API_KEY?: string;
    TRENDS_PLAYLIST_ID?: string;
    LEARNING_CENTER_PLAYLIST_ID?: string;
    SPLASH_SCREEN_LOGOS?: ISplashScreenImage[];
    SPLASH_SCREEN_TESTIMONIALS?: ISplashScreenTestimonial[];
    SUPPORTED_LANGUAGES?: ISupportedLanguage[];
    TRANSLATE_LANGUAGES?: ISupportedLanguage[];
    NEW_UI_URL?: string;
    SHOULD_SKIP_SOR_ON_CREATE?: string;
    RELEASE_VERSION?: string;
    MONITOR_HIDE_PLAY: string;
    ROADMAP: string;
    ALLOW_DUPLICATE_PROJECT: string;
    PROCESS_MUSIC_IN_BACKGROUND: string;
    LIMIT_DOWNLOADS: string;
}

export const appConfig: IConfig = {}

interface IAppContext {
    config: IConfig;
    user: User;
    setUser: (user: User) => void;
    getUser: () => User;
    plan: Plan;
    setPlan: (plan: Plan) => void;
    status: Status;
    mixpanel: Mixpanel;
    inOverlay: boolean;
    subscription: Subscription;
    setSubscription: (subscription: Subscription) => void;
    defaultVideoType: VideoType | undefined;
    reachProjectLimit: boolean;
    isMobileDevice: boolean;
    isIOS: boolean;
}

export const AppContext = React.createContext<IAppContext>({
    config: appConfig,
    mixpanel: mixPanel,
})
