
import * as Base from './base/video';


export interface IVideo extends Base.IVideo {

}

export interface IVideoProperties extends Base.IVideoProperties {

}

export interface IVideoFilter extends Base.IVideoFilter {

}

export default class Video<IType extends IVideo = IVideo, ITypeProperties extends IVideoProperties = IVideoProperties> extends Base.Video<IType, ITypeProperties> implements IVideo {
}