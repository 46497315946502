import styled from '@emotion/styled/macro';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Asset from '../client/asset';
import ClosedCaption, { Word } from '../client/closedCaption';
import Content, { ContentStatus } from '../client/content';
import CuePoint from '../client/cuePoint';
import Sequence, { Chapter, Music, Scene } from '../client/sequence';
import BeforeUnload from '../components/beforeUnload';
import Dashboard from '../components/dashboard';
import CreateErrorDialog from '../dialogs/CreateErrorDialog';
import { AppContext } from '../lib/app-context';
import Status from '../lib/status';
import {
  cuePointsState,
  highlightWordListState,
  keywordListState, loadCuePoints,
  newInstanceHandler,
  removeCuePoint,
  setClosedCaption,
  setCuePoint,
  setNonSequelWords,
} from '../state/cuePoints';
import { handleError } from '../state/error';
import {
  allowRegenerateCcState,
  loadedPresetsState, projectsLimitReachedState, sequencesSinceLastChargeState,
} from '../state/local';
import { lastSearchedMusicTagState } from '../state/menu';
import { currentTimeState, incrementPlaybackVersion, playbackIsLockedState, resetCurrentTime } from '../state/playback';
import {
  DOWNLOAD_STATUS,
  addWatcher,
  downloadNotificationStatus,
  downloadStatus,
  getSequence,
  musicBackgroundInProcess,
  removeScene,
  removeWatcher,
  resetSequenceLiveState,
  scenesState,
  sequenceState,
  setScene,
  setScenes,
  setTempSceneFormated,
  setTempSceneKeyFramed,
  setTempSceneTranscripted,
} from '../state/sequence';
import Loader from '../ui-components/Loader';
import { pendingClonesState } from '../ui-components/SequenceCloneOptions/useSequenceCloneOptions';
import { getLatestDownloadInfos } from '../utils/balance.utils';
import ProjectWizard from './create-new/ProjectWizard';
import queryString from "query-string";
import {regenerateButtonIsDisabledState} from "../client/base/base";

const LoaderContainer = styled.div`
  z-index: 999;
  position: absolute;
  top: 73px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  background-color: #ffffff33;
`;

const ASSET_STATUS = {
  PENDING: 0,
  READY: 2,
};

export default function Project() {
  const { setStatus, status, user, plan, config } = React.useContext(AppContext);
  const scenes = scenesState.use((scenes) => scenes.map);
  const shouldStopReload = !!scenesState.use((scenes) => scenes.filter.totalArray.length);
  const sequence = sequenceState.use();
  const sequenceSid = sequenceState.use((sequence) => sequence?.sid);
  const location = useLocation();
  const [openSequenceSid, setOpenSequenceSid] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const presets = loadedPresetsState.use();
  const pendingClones = pendingClonesState.use();
  const projectsLimitReached = projectsLimitReachedState.use();
  const retryCount = React.useRef(-1);
  const queryParams = queryString.parse(location.search);
  const autoSyncCC = queryParams?.autoSyncCC === 'true';
  const allowRegenerateCC = queryParams?.allowRegenerateCC === 'true';
  const newCreateFlow = React.useMemo(() => {
    return (
      user?.UIFLAGS.SMART_LIBRARY ||
      user?.UIFLAGS.ENABLE_NEW_CREATION_FLOW ||
      user?.UIFLAGS.ENABLE_NEW_FAST_CREATION_FLOW
    );
  }, [
    user?.UIFLAGS.SMART_LIBRARY,
    user?.UIFLAGS.ENABLE_NEW_CREATION_FLOW,
    user?.UIFLAGS.ENABLE_NEW_FAST_CREATION_FLOW,
  ]);

  useEffect(() => {
    // disable/enable regenerate captions button based on autoSyncCC queryParam status
    regenerateButtonIsDisabledState.set(autoSyncCC);
  }, [autoSyncCC]);

  useEffect(() => {
    // hide/show regenerate captions button based on allowRegenerateCC queryParam status
    allowRegenerateCcState.set(allowRegenerateCC);
  }, [allowRegenerateCC]);

  React.useEffect(() => {
    // after regenerate v2 captions in peech-web
    // listen for UPDATE_CAPTIONS -> call v1 -> set regenerated captions
      const onMessage = async (event) => {
          // on CAPTIONS_REGENERATED postMessage:
          // fetch updated captions from v1
          // disable regenerate button
          try {
            if (event.data === "CAPTIONS_REGENERATED" && allowRegenerateCC) {
              let scenes = scenesState.get();
              let sequence = sequenceState.get();
              if (!sequence || !sequence.sid) {
                return;
              }
              console.log("[CC] [reactor]", "onMessage: CAPTIONS_REGENERATED. get fresh captions");
              regenerateButtonIsDisabledState.set(true);
              await cuePointsState.init();
              await loadCuePoints(sequence, scenes.map, null, null);
              sequence.style.captionFormatting = null;
              sequenceState.set((sequence) => {
                sequence.values.style.captionFormatting = null;
                return sequence;
              });
            }
          } catch (e) {
            handleError({
              statusCode: 4,
              responseError: e,
            });
          }
        } 
        ;
        window.addEventListener("message", onMessage);
      
      return () => {
        window.removeEventListener("message", onMessage);
      };
    
  }, []);
  
  // check if need to used the new create flow - if yes and the hash is empty - redirect to home page
  React.useEffect(() => {
    if (!location.hash && newCreateFlow) {
      navigate('/', { replace: true });
    }
  }, [location.hash]);

  React.useEffect(() => {
    if (
      sequence?.scenes?.find((s) => s.status === Chapter.STATUS.READY) &&
      !sequence.scenes.find((s) => !s.fitted && s.status !== Scene.STATUS.DELETED)
    ) {
      downloadNotificationStatus.set(Sequence.DOWNLOAD_NOTIFICATION_TYPE.ENABLED);
    }
  }, [sequence]);

  React.useEffect(() => {
    return () => {
      sequenceCleanUp();
    };
  }, []);

  // check if music ready if not set the state in musicBackgroundInProcess
  React.useEffect(() => {
    console.log('music', sequence?.audio);
    if (config?.PROCESS_MUSIC_IN_BACKGROUND === 'true' && sequence?.audio && sequence?.music.enabled) {
      if (sequence?.audio?.status !== ContentStatus.READY) {
        console.log('music - set in Background progress');
        musicBackgroundInProcess.set(true);
      }
    }
  }, [sequence?.audio, config]);

  React.useEffect(async () => {
    if (sequenceSid) {
      const downloadInfo = await getLatestDownloadInfos(sequenceSid, 'processing');
      downloadInfo.length && downloadStatus.set((state) => ({ ...state, MODE: DOWNLOAD_STATUS.GENERATE }));
      console.log('downloadInfo', downloadInfo);
    }
  }, [sequenceSid]);

  const sequenceSidX = location.hash.substr(1);
  React.useEffect(() => {
    if (!sequenceSidX) {
      sequenceCleanUp();
    }

    setOpenSequenceSid(sequenceSidX ?? null);

    return () => {
      if (sequenceSidX) {
        sequenceCleanUp();
      }
    };
  }, [sequenceSidX]);

  function sequenceCleanUp() {
    resetSequenceLiveState();
    setScenes({}, true);

    if (status) {
      status.sid = null;
    }

    cuePointsState.set({});
    keywordListState.set([]);
    highlightWordListState.set([]);
    lastSearchedMusicTagState.set(null);
    resetCurrentTime();
  }

  function onClosedCaptionStatus(words) {
    setClosedCaption(
      words.map((w) => new Word().set(w)),
      sequence,
      scenes,
      true
    );
  }

  function onCuePointStatus(cuePoint) {
    if (
      cuePoint.status === CuePoint.STATUS.DELETED &&
      cuePoint.type !== CuePoint.TYPE.OUTRO &&
      cuePoint.type !== CuePoint.TYPE.INTRO
    ) {
      removeCuePoint(cuePoint.sid);
    } else {
      const cuePoints = cuePointsState.get();

      /**
       * @type {CuePoint}
       */
      let obj = cuePoints[cuePoint.sid];
      if (obj === null) {
        return;
      }
      //We should add here only cuepoints type that will require new manifest from the server
      //The cleanup will be once player is ready to play
      if (
        (cuePoint.type == CuePoint.TYPE.OUTRO || cuePoint.type == CuePoint.TYPE.INTRO) &&
        cuePoint.status != obj?.status
      ) {
        playbackIsLockedState.set(true);
      }
      if (!obj) {
        obj = newInstanceHandler(cuePoint);
      }
      if (obj) {
        obj.set(cuePoint, undefined, undefined, true);
        setCuePoint(obj, sequence, scenes);
      }
    }
  }

  React.useEffect(() => {
    if (!sequence.sid) {
      return;
    }

    let stat;
    if (status) {
      if (status.sid !== sequence.sid) {
        status.sid = sequence.sid;
      }
      stat = status;
    } else {
      stat = new Status(sequence.sid, user.sid, user.orgSid);
      setStatus(stat);
    }

    stat.sequence
      .on('backgroundJob', ({ backgroundJobType, statusType, subStatus, ack }) => {
        // handle music backgroundJob
        if (backgroundJobType === 'music' && config?.PROCESS_MUSIC_IN_BACKGROUND === 'true') {
          if (subStatus === 'started') {
            // need to reload video mpd
            incrementPlaybackVersion();
            musicBackgroundInProcess.set(true);
          }
          if (subStatus === 'ended') {
            // need to reload video mpd
            incrementPlaybackVersion();
            musicBackgroundInProcess.set(false);
          }
        }
        ack && ack();
      })
      .on('audio', ({ progress, ack }) => {
        if (progress === 100) {
          sequenceState.set((sequence) => {
            sequence.values.audioGenerated = true;
            return sequence;
          });
        }
        ack && ack();
      })
      .on('duration', ({ duration, ack }) => {
        sequenceState.set((sequence) => {
          sequence.duration = duration;
          return sequence;
        });
        ack && ack();
      })
      .on('download', ({ notificationType, output, ack }) => {
        switch (notificationType) {
          case Sequence.DOWNLOAD_NOTIFICATION_TYPE.DISABLED:
            downloadNotificationStatus.set(Sequence.DOWNLOAD_NOTIFICATION_TYPE.DISABLED);
            break;
        }

        if (output) {
          if (output) {
            sequenceState.set((sequence) => {
              sequence.values.output = new Content(output.sid).set(output);
              return sequence;
            });
          }
        }

        ack && ack();
      })
      .on('access', ({ access, ack }) => {
        // TODO
        ack && ack();
      })
      .on('watch', ({ userSid, ack }) => {
        addWatcher(userSid);
        // TODO
        ack && ack();
      })
      .on('unwatch', ({ userSid, ack }) => {
        removeWatcher(userSid);
        // TODO
        ack && ack();
      })
      .on('sequence', ({ sequence: seq, ack }) => {
        ack && ack();
      })
      .on('error', ({ message, ack, code }) => {
        handleError({
          title: 'Server Error',
          statusCode: code,
          message,
        });
        ack && ack();
      })
      .on('format', ({ aspectRatio, altAspectRatios, ack }) => {
        aspectRatio &&
          sequenceState.set((sequence) => {
            sequence.values.aspectRatio = aspectRatio;
            sequence.values.altAspectRatios = altAspectRatios;
            return sequence;
          });
        ack && ack();
      })
      .on('closedCaption', ({ words, ack, nonSequel }) => {
        if (nonSequel) {
          setNonSequelWords(
            words.map((w) => new Word().set(w)),
            sequence,
            scenes,
            true
          );
        } else {
          onClosedCaptionStatus(words);
        }

        ack && ack();
      })
      .on('cuePoint', ({ cuePoint, ack }) => {
        onCuePointStatus(cuePoint);
        ack && ack();
      })
      .on('music', ({ music, ack }) => {
        console.log('Status music', { music });
        sequenceState.set((sequence) => {
          sequence.values.music = new Music(music.sid).set(music);
          return sequence;
        });
        ack && ack();
      })
      .on('logo', ({ logo, ack }) => {
        sequence.sid &&
          sequenceState.set((sequence) => {
            sequence.logo.set(logo);
            return sequence;
          });
        ack && ack();
      })
      .on('background', ({ sceneType, image, ack }) => {
        if (!sequence.sid) {
          return;
        }
        sequenceState.set((sequence) => {
          sequence[sceneType].background.set({ image }, sequence[sceneType]);
          return sequence;
        });
        scenesState.set((scenes) => {
          const scene = scenes[sceneType];
          scenes.map[scene.sid] = scene.set({ image }, scene);
          return scenes;
        });
        ack && ack();
      })
      .on('scene', ({ version, duration, scene: update, ack }) => {
        console.log('Status ' + update.objectType, { version, duration, scene: update });
        let scene = scenes[update.sid];
        if (update.status === Chapter.STATUS.DELETED) {
          removeScene(update.sid);
          // TODO: not remove cue-point related to chapter from client state
          // removeChapterCuePoints(update.sid)
        } else {
          if (!scene) {
            scene = new Chapter(update.sid);
          }
          const shouldSetTemp = !scene?.srcDuration;
          setScene(scene.set(update), shouldSetTemp);
          if (scene && !scene.linkSid) {
            if (!scene.resized && update.resized) {
              setTempSceneFormated(update.sid);
            }
            if (!scene.transcripted && update.transcripted) {
              setTempSceneTranscripted(update.sid);
            }
            if (!scene.keyFramed && update.keyFramed) {
              setTempSceneKeyFramed(update.sid);
            }
          }
        }
        sequenceState.set((sequence) => {
          if (duration && duration !== sequence.duration) {
            sequence.duration = duration;
            incrementPlaybackVersion();
            currentTimeState.get() > duration && currentTimeState.set(0);
          }
          return sequence;
        });
        ack && ack();
      });
  }, [sequenceSid, config]);

  async function loadUserAssets() {
    if (!user || user.logoAssets) {
      return;
    }
    try {
      const assets = await Asset.list(
        {
          statusEqual: ASSET_STATUS.READY,
          typeIn: [Asset.TYPE.LOGO, Asset.TYPE.MUSIC],
        },
        {
          pageSize: 20,
        }
      );

      user.logoAssets = assets.filter((asset) => asset.type === Asset.TYPE.LOGO);
      user.audioAssets = assets.filter((asset) => asset.type === Asset.TYPE.MUSIC);

      setLoading(false);
    } catch (err) {
      console.error(err);
      retryCount.current++;
      if (retryCount < 2) {
        return setTimeout(loadUserAssets, 100);
      }
      handleError({
        statusCode: 9,
        responseError: err,
      });
    }
  }

  React.useEffect(() => {
    if (!user) {
      return;
    }
    // fallBackUrlState.set('/project/')
    loadUserAssets();
    if (location.hash) {
      const sequenceSid = location.hash.substr(1);
      if (sequence && sequence.sid === sequenceSid) {
        return;
      }
      getSequence(sequenceSid, user.cacheVersion);
    }
    return () => {
      // fallBackUrlState.set(null)
    };
  }, [user, location.hash]);

  const sequencesSinceLastCharge = sequencesSinceLastChargeState.use();

  if (!user) {
    return null;
  }

  if (
    !openSequenceSid &&
    (!presets?.length ||
      !user?.preset?.getDefaultLogo() ||
      isNaN(sequencesSinceLastCharge) ||
      (plan?.maxVideos <= sequencesSinceLastCharge && !sequenceSid))
  ) {
    console.log('project.js return null in project render', {
      openSequenceSid,
      presetLength: presets?.length,
      defaultLogo: user?.preset?.getDefaultLogo(),
      sequencesSinceLastCharge,
      plan,
      sequenceSid,
    });
    return null;
  }

  if (openSequenceSid) {
    return (
      <>
        <Dashboard />

        {!!pendingClones.length && (
          <LoaderContainer>
            <Loader size="lg" />
          </LoaderContainer>
        )}
        {projectsLimitReached && (
          <CreateErrorDialog
            projectsLimitReached
            onHide={() => projectsLimitReachedState.set(false)}
            trigger="react_project_limit"
          />
        )}
      </>
    );
  }

  if (user?.logoAssets || !loading) {
    console.log('project.js render NewProjectWizard');
    return (
      <>
        <Helmet>
          <style>{'body {background-color: #F2F2F2;}'}</style>
        </Helmet>
        {shouldStopReload && <BeforeUnload />}
        {!location.hash && <ProjectWizard />}
      </>
    );
  }
  console.log('project.js render loader');
  return <Loader size="lg" style={{ margin: 'auto', marginTop: '40vh' }} />;
}
