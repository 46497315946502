import React from 'react';
import classNames from 'classnames';
import './stepper.scss';

export enum StepperDirection {
    Horizontal = 'horizontal',
    Vertical = 'vertical',
}

interface StepperProps {
    numSteps: number;
    currentStep: number;
    direction: StepperDirection;
}

export default function Stepper({ numSteps, currentStep, direction, ...props }: StepperProps & {}) {
    const classPrefix = 'peech-stepper'
    const directionClass = `${classPrefix}--${direction}`
    const wrapperClasses = classNames(classPrefix, directionClass)
    const stepKey = (stepNum: number) => `step-${stepNum}`
    const stepClasses = (stepNum: number) => classNames('step', { 'current': stepNum === currentStep })
    return (
        <div className={wrapperClasses} {...props}>
            {Array.from({ length: numSteps }, (_, i) => <div className={stepClasses(i + 1)} data-cy={`step-${i + 1}`} key={stepKey(i)}>{i + 1}</div>)}
        </div>
    )
}

Stepper.defaultProps = {
    numSteps: 5,
    currentStep: 4,
    direction: 'horizontal',
};
