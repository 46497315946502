import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import React from 'react';
import pluralize from "pluralize";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Dropzone from 'react-dropzone';
import useResizeObserver from 'use-resize-observer';
import Asset, { AssetStatus } from '../client/asset';
import { FeatureFeatureName } from '../client/base/feature';
import FolderStructure from '../client/folderStructure';
import Sequence from '../client/sequence';
import BeforeUnload from '../components/beforeUnload';
import { LoaderDiv } from '../components/contentLibrary/libraryStyledComponent';
import {
  displayUploadMobileModalState,
  openPeechProUpgradeModal,
} from '../components/layout';
import { Link } from '../components/layout/MobileUploadModal';
import PlayerModal from '../components/playerModal/PlayerModal';
import SVG from '../components/svg';
import { PAGE_SIZE } from '../components/wrapWithProvider';
import { AppContext } from '../lib/app-context';
import FileUploader from '../lib/fileUploader';
import { dragToFolderEndHandler, dragToFolderStartHandler, onDragHandler, onDropActions } from '../lib/utils';
import { handleError } from '../state/error';
import { fileUploaderState } from '../state/fileUploader';
import { appendAssets, assetsAmount, footagesState, loadedAssetsState, modifyAsset } from '../state/local';
import DeleteConfirmation from '../ui-components/DeleteConfirmation';
import { DropdownFeatureOption } from '../ui-components/DropdownFeatureOptions/DropDownFeatureOption';
import CreateModal from '../ui-components/Folder/CreateModal';
import Folder from '../ui-components/Folder/Folder';
import { libraryInitializedState } from '../ui-components/Header/Header';
import ImageWithPlaceholder from '../ui-components/ImageWithPlaceholder/ImageWithPlaceholder';
import Loader from '../ui-components/Loader';
import OptionsMenuPopover from '../ui-components/OptionsMenu/OptionsMenuPopover';
import SceneUploader from '../ui-components/SceneUploader';
import Tabs from '../ui-components/Tabs/Tabs';
import { trackEvent } from '../utils/analityics.utils';
import './Library.scss';
import RedirectToUploadingButton from './library/RedirectToUploadingButton';
import UploadNewAssetButton from './library/UploadNewAssetButton';
import _ from "lodash";

const ASSET_STATUS = {
  PENDING: 0,
  READY: 2,
};
const CONTENTS = {
  TEAM: 0,
};

function ContentFileUpload({
  title,
  setAssets,
  onHide,
  filesInUpload,
  onAssetRemove,
  onLangChange,
  supportedLanguages = [],
  onAssetFileInputChange,
  showUploadNotification,
  setShowUploadNotification,
}) {
  const { user, plan } = React.useContext(AppContext);

  return (
    <div className="content-file-upload">
      <div className="content-triangle-wrapper">
        <span className="triangle"></span>
      </div>
      <div>
        <Dropzone
          onDrop={onAssetFileInputChange}
          className="logo-dropzone"
          style={{ cursor: 'pointer' }}
          accept="video/*"
        >
          {({ getRootProps, getInputProps }) => (
            <div
              className="logo-section-branding--header"
              {...getRootProps({
                onClick: (event) => filesInUpload && filesInUpload.length && event.stopPropagation(),
              })}
            >
              <input
                id="branding-logo-input"
                {...getInputProps({
                  onClick: (event) => filesInUpload && filesInUpload.length && event.stopPropagation(),
                })}
              />
              {!(filesInUpload && filesInUpload.length) ? (
                <>
                  <div className="content-continer">
                    <h3 className="first-title">{title}</h3>
                    <span className="sub-title">Drag and drop your files here</span>
                  </div>
                  <SVG
                    name="upload-content"
                    className="upload-content-icon"
                    viewBox="0 0 94 76"
                    width="94px"
                    height="76px"
                  ></SVG>
                </>
              ) : (
                <div className="upload-assets-indication">
                  {showUploadNotification && (
                    <div className="upload-notification">
                      <div className="notification-close" onClick={() => setShowUploadNotification(false)}>
                        <SVG name="close" viewBox="0 0 24 24" />
                      </div>
                      <div className="notification-title">For best results</div>
                      <div className="notification-text">
                        <div className="notification-icon-wrapper">
                          <SVG name="language-notification" width="28px" viewBox="0 0 23 23" />
                        </div>
                        <p>
                          <strong>
                            Make sure the video’s <ins>language</ins> is correct
                          </strong>{' '}
                          <br />
                          Select the language that is spoken in the video
                        </p>
                      </div>
                    </div>
                  )}
                  {filesInUpload.map((file, i) => (
                    <SceneUploader
                      size="sm"
                      key={file.content.sid}
                      sid={file.content.sid}
                      name={file.content.name}
                      fileSize={file.size}
                      onRemove={onAssetRemove}
                      onLangChange={onLangChange}
                      lang={file.content.languageCode}
                      hideProgress={filesInUpload}
                      duration={file.duration}
                      supportedLanguages={supportedLanguages}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
        </Dropzone>
      </div>
    </div>
  );
}

function ContentFileUploadModal({
  title,
  setAssets,
  show,
  onHide,
  filesInUpload,
  onAssetRemove,
  onLangChange,
  supportedLanguages,
  onAssetFileInputChange,
  showUploadNotification,
  setShowUploadNotification,
}) {
  return (
    <>
      <div className="content-file-upload-wrapper" />
      <div className={classNames('content-file-upload', { selected: setAssets })} onClick={(e) => e.stopPropagation()}>
        <ContentFileUpload
          title={title}
          setAssets={setAssets}
          onHide={onHide}
          filesInUpload={filesInUpload}
          onAssetRemove={onAssetRemove}
          onLangChange={onLangChange}
          supportedLanguages={supportedLanguages}
          onAssetFileInputChange={onAssetFileInputChange}
          showUploadNotification={showUploadNotification}
          setShowUploadNotification={setShowUploadNotification}
        />
      </div>
    </>
  );
}

function AssetItem({
  isModal,
  assetItem,
  onRemove,
  onSelect,
  isSelected,
  onAssetSelected,
  draggable,
  onDragStart,
  onDragEnd,
  selectedNumber,
  setUploadFile,
  scrollingContainerRef,
}) {
  const { user, plan } = React.useContext(AppContext);
  const [verifyDelete, setVerifyDelete] = React.useState(false);
  const onItemClick = (e) => e.defaultPrevented || (onAssetSelected ? onAssetSelected(e, asset) : onClick());
  const videoPlayer = React.useRef();
  const [asset, setAsset] = React.useState(null);
  const [darkOverlay, setDarkOverlay] = React.useState();
  const stopRef = React.useRef(true);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  React.useEffect(() => {
    setAsset(assetItem);
    if (assetItem?.values?.status === AssetStatus.READY) {
      setDarkOverlay(false);
    } else {
      setDarkOverlay(true);
    }
  }, [assetItem, assetItem?.values?.status]);

  async function deleteAsset() {
    try {
      await new Asset(asset.sid).remove();
      onRemove && onRemove(asset.sid);
    } catch (err) {
      handleError({
        title: 'Deleting Asset',
        message: err.message,
        responseError: err,
      });
    }
  }

  const downloadTranscriptionAllowed = React.useMemo(() => {
    return !!plan?.planAllowedTierFeatures?.includes(FeatureFeatureName.DOWNLOAD_TRANSCRIPTION);
  }, [plan]);

  const getTranscription = React.useCallback(() => {
    if (!downloadTranscriptionAllowed) {
      openPeechProUpgradeModal(user, 'Download transcription requested', FeatureFeatureName.DOWNLOAD_TRANSCRIPTION);
      return;
    }
    const url = asset.getTranscriptionUrl();
    const link = document.createElement('a');
    link.onclick = (e) => e.stopPropagation();
    link.href = url;
    link.target = '_blank';
    link.click();
    trackEvent('asset-download', { type: 'transcription', trigger: 'context-menu' });
  }, [downloadTranscriptionAllowed, asset]);

  const actions = React.useMemo(
    () => [
      {
        onClick: () => setVerifyDelete(true),
        key: 'delete',
        icon: 'delete',
        text: 'Delete',
        toolTip: 'Delete Project',
      },
      {
        onClick: () => getTranscription(),
        key: 'download',
        icon: 'download',
        text: <DropdownFeatureOption allowed={downloadTranscriptionAllowed} text="Download Transcription" />,
        toolTip: 'Download Transcription',
        disabled: asset?.status !== Asset.STATUS.READY,
      },
    ],
    [asset, downloadTranscriptionAllowed, user.UIFLAGS.CREATE_HIGHLIGHT]
  );

  function onClick() {
    if (onSelect && asset.status === Asset.STATUS.READY) {
      onSelect(asset);
    }
  }

  const onMouseEnter = React.useCallback(() => {
    if (videoPlayer?.current) {
      videoPlayer.current.currentTime = 3; // seek to start 3 sec like thumb
      videoPlayer.current.play(); // play video
    }
  }, [videoPlayer?.current]);

  const onMouseLeave = React.useCallback(() => {
    if (videoPlayer?.current) {
      // avoid error when not loaded yet and call pause - TODO: set it as a player override pause method
      if (videoPlayer.current.readyState !== 4) {
        videoPlayer.current.addEventListener(
          'canplay',
          () => {
            videoPlayer.current || videoPlayer.current.pause();
          },
          { once: true }
        );
      } else {
        videoPlayer.current.pause();
      }
    }
  }, [videoPlayer?.current]);

  if (
    asset?.status === AssetStatus.DELETED ||
    asset?.status === AssetStatus.DELETE_CANDIDATE ||
    (isModal && asset?.status !== AssetStatus.READY)
  ) {
    return null;
  }

  return (
    <Col
      xs="12"
      md="6"
      lg="4"
      xl="3"
      className="assets-col"
      onClick={(e) => {
        setUploadFile && setUploadFile(false);
      }}
    >
      {verifyDelete && <DeleteConfirmation onHide={() => setVerifyDelete(false)} onApproval={deleteAsset} />}

      <div className={classNames('plug-pic', { [asset?.sid]: true })}>
        <img src={asset?.getThumbnailUrl({ width: 200, aspectRatio: '16:9' })} draggable={false} />
      </div>

      <div
        className={`assets-item assets-item-${asset?.status}`}
        onClick={(e) => {
          if (!isPopoverOpen) {
            setUploadFile && setUploadFile(false);
            onItemClick(e);
          }
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        draggable={draggable}
        onDrag={(e) => onDragHandler(e, draggable, stopRef, scrollingContainerRef)}
        onDragStart={onDragStart}
        onDragEnd={(e) => {
          stopRef.current = true;
          onDragEnd(e);
        }}
      >
        <div className="assets-item-label">
          <div className="assets-item-label-wrapper">
            <SVG name="player-projects" className="player-projects" />
            <div className="dragged-asset-name">{asset?.name}</div>
          </div>
        </div>

        <div className="assets-item-thumb">
          <Loader size="md" />
          {!isModal && (
            <div
              className="options-button"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <OptionsMenuPopover
                options={actions}
                scrollingContainerRef={scrollingContainerRef}
                onOpen={() => {
                  setIsPopoverOpen(true);
                }}
                onClose={() => {
                  setIsPopoverOpen(false);
                }}
              />
            </div>
          )}
          <ImageWithPlaceholder
            source={asset?.getThumbnailUrl({ width: 200, aspectRatio: '16:9' })}
            alt={asset?.name}
            className="asset-item-img"
            status={asset?.values?.status}
            darkOverlay={darkOverlay}
          />

          {/* <MinDashPlayer ref={videoPlayer} 
            loop  
            muted 
            src={asset.previewUrl} 
            preload="auto"
            startTime={3}
            style={{
              width:'100%', 
              height:'100%', 
              aspectRatio: '16/9',
              position : 'absolute'
            }} /> */}

          <div className="contents-item-actions"></div>
          {isModal && (
            <div className={classNames('assset-selection', { selected: selectedNumber(asset) >= 0 })}>
              <div className={selectedNumber(asset) >= 0 ? 'asset--checkbox-selected' : 'asset--checkbox'}>
                <span>{selectedNumber(asset) >= 0 ? selectedNumber(asset) + 1 : null}</span>
              </div>
            </div>
          )}
        </div>
        <div className="assets-item-header">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="cut-included-tooltip" className="tooltip-peech-dark">
                {asset?.name}
              </Tooltip>
            }
          >
            <div className="assets-item-title">{asset?.name}</div>
          </OverlayTrigger>
        </div>
      </div>
    </Col>
  );
}

export default function Library({
  isModal,
  isSelected,
  onAssetSelected,
  addSelectedAssets,
  selectedNumber,
  setLibraryLoaded,
}) {
  const { user, plan, config } = React.useContext(AppContext);
  const enabledFoldersFeature = React.useMemo(
    () => !!user?.UIFLAGS.ENABLE_FOLDERS_FEATURE,
    [user?.UIFLAGS.ENABLE_FOLDERS_FEATURE]
  );
  const retryCount = React.useRef(-1);
  const setAssets = console.log;
  const assets = loadedAssetsState.use();
  const footages = footagesState.use();
  const [uploadFile, setUploadFile] = React.useState(false);
  const [previewAsset, setPreviewAsset] = React.useState(undefined);
  const [activeTab, setActiveTab] = React.useState(CONTENTS.TEAM);
  const [activePos, setActivePos] = React.useState({});
  const teamButtonRef = React.useRef();
  const tabsWrapperRef = React.useRef();
  const projectsRef = React.useRef();
  const parentElement = React.useRef(null);
  const fetching = React.useRef(false);
  const listenerAdded = React.useRef(false);
  const page = React.useRef(2);
  const [searchText, setSearchText] = React.useState('');
  const [foldersList, setFoldersList] = React.useState([]);
  const [isFetching, setIsFetching] = React.useState(!libraryInitializedState.get());
  const [currentFolderSid, setCurrentFolderSid] = React.useState('');
  const [assetSidDragged, setAssetSidDragged] = React.useState(null);
  const [showAddFolderModal, setShowAddFolderModal] = React.useState(false);
  const folderInputRef = React.useRef(null);
  const folderInputRefRename = React.useRef(null);
  const [verifyFolderDeleteSid, setVerifyFolderDeleteSid] = React.useState(false);
  const [verifyFolderRenameSid, setVerifyFolderRenameSid] = React.useState(false);
  const [isOptionsMenuOpened, setIsOPtionsMenuOpened] = React.useState({});
  const [draggedClassName, setDraggedClassName] = React.useState();
  const libraryInitialized = libraryInitializedState.use();

  const fileUploader = fileUploaderState.use();
  const [filesInUpload, setFilesInUpload] = React.useState([]);
  const [filesJustUploaded, setFilesJustUploaded] = React.useState([]);

  const deleteText = 'By deleting this folder all of the raw files will be placed\n in the main content library tab';
  const deleteQuestion = 'Are you sure you want to delete this folder?';
  const [hideUploadProgress, setHideUploadProgress] = React.useState(true);
  const shouldStopReload = !!fileUploaderState.use((files) => Object.values(files).length);
  const [removedItems, setRemovedItems] = React.useState([]);
  const [isDragActive, setIsDragActive] = React.useState(false);
  const [showUploadNotification, setShowUploadNotification] = React.useState(true);

  const disableUpload = React.useMemo(() => user?.UIFLAGS.SMART_LIBRARY, [user?.UIFLAGS.SMART_LIBRARY]);

  const scrollingContainerRef = React.useRef();
  const [hoveredFolder, setHoveredFolder] = React.useState(null);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const folderSidParam = urlParams.get('folder');

  const isNewProjectCreationFlowEnabled = !!user?.UIFLAGS.ENABLE_NEW_CREATION_FLOW;
  const isFastProjectCreationFlowEnabled = !!user?.UIFLAGS.ENABLE_NEW_FAST_CREATION_FLOW;

  // on select folder
  const onSelectFolder = (folderSid) => {
    const selectedFolder = foldersList.find((folder) => folder.sid === folderSid);
    const { pathname, search } = document.location;
    const urlParams = new URLSearchParams(search);
    if (selectedFolder) {
      urlParams.set('folder', folderSid);
      navigate(`${pathname}?${urlParams.toString()}`);
    } else {
      urlParams.delete('folder');
      navigate(`${pathname}?${urlParams.toString()}`);
    }
  };

  // open upload modal when set in url ?upload=true
  React.useEffect(() => {
    const urlParams = new URLSearchParams(document.location.search);
    const uploadParam = urlParams.has('upload');
    const createParam = urlParams.has('create');

    if (uploadParam) {
      setUploadFile(true);
      console.log('show upload modal');
    } else if (createParam && assets.length) {
      // create project with asset
      // get first ready asset
      const readyAsset = assets.find((asset) => asset.status === AssetStatus.READY);
      console.log('create project', readyAsset, {
        skipContentStep: user?.UIFLAGS.SKIP_CONTENT_EDIT_EXAMPLE_ASSET,
        assetsLength: assets.length,
      });
      // navigate to create project wizard
      if (readyAsset) {
        navigate('/project', {
          state: {
            asset: new Asset().set(readyAsset.values),
            skipContentStep: !!user?.UIFLAGS.SKIP_CONTENT_EDIT_EXAMPLE_ASSET,
          },
          replace: true,
        });
      } else {
        navigate('/library');
        setUploadFile(true);
      }
    }
  }, [assets, foldersList]);

  // set folder select state from folder query param
  React.useEffect(() => {
    if (folderSidParam && foldersList.find((folder) => folder.sid === folderSidParam)) {
      setCurrentFolderSid(folderSidParam);
    } else {
      setCurrentFolderSid('');
    }
  }, [folderSidParam, foldersList]);

  React.useEffect(() => {
    //we are updating the state when we have PreviewAsset
    // console.log('statusAsset assets length in Library', assets.length);
    if (previewAsset) {
      const assetChanged = assets.find((el) => el.sid === previewAsset.sid);
      assetChanged && setPreviewAsset(assetChanged);
    }
  }, [assets]);

  // close mobile upload modal when start uploading file - asset length changed
  React.useEffect(() => {
    displayUploadMobileModalState.set(false);
  }, [assets?.length]);

  React.useEffect(() => {
    setFilesInUpload(Object.values(fileUploader)?.filter((u) => u?.content instanceof Asset))?.filter((u) => {
      const isRemoved = removedItems.find((sid) => u.content.sid === sid);
      if (!isRemoved) {
        return u;
      }
    });
  }, [fileUploader]);

  React.useEffect(() => {
    let inProgress = [...filesInUpload];
    let finished = [...filesJustUploaded];
    let newsInProgress = [];
    inProgress.forEach((el) => {
      let isNewOne = !finished.find((x) => x.content.sid === el.content.sid);
      isNewOne && newsInProgress.push(el);
    });
    let arr = finished.concat(newsInProgress);
    setFilesJustUploaded(arr);
  }, [filesInUpload]);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    !assets.length && fetchMore();
  }, [user]);

  React.useEffect(() => {
    if (enabledFoldersFeature && user && (!foldersList?.length || !currentFolderSid)) {
      getFoldersList();
    }
  }, [user, currentFolderSid, footages, assets.length]);

  const getFoldersList = () => {
    FolderStructure.list({
      typeEqual: FolderStructure.FOLDER_TYPE.ASSET,
    }).then((res) => {
      setFoldersList(res);
    });
  };

  React.useEffect(() => {
    if (libraryInitialized) {
      setIsFetching(false);
      setLibraryLoaded && setLibraryLoaded(false);
    } else {
      setLibraryLoaded && setLibraryLoaded(true);
    }
  }, [libraryInitialized]);

  React.useEffect(() => {
    if (listenerAdded.current) {
      return;
    }
    if (projectsRef.current) {
      parentElement.current = projectsRef.current.parentNode;
      function handleScroll() {
        const { scrollTop, offsetHeight } = parentElement.current;
        if (offsetHeight + scrollTop >= projectsRef.current.clientHeight - 1) {
          fetchMore();
        }
      }
      if (parentElement.current) {
        parentElement.current.addEventListener('scroll', handleScroll);
        listenerAdded.current = true;
        return () => {
          parentElement.current.removeEventListener('scroll', handleScroll);
          listenerAdded.current = false;
        };
      }
    }
  }, [projectsRef.current, assets.length]);

  const headerSizeRef = useResizeObserver();
  const foldersSizeRef = useResizeObserver();

  const filesContainerHeight = React.useMemo(() => {
    const foldersVar = !currentFolderSid && foldersSizeRef?.height ? foldersSizeRef?.height + 40 : -20;
    return currentFolderSid
      ? `calc(100vh - 56px - ${headerSizeRef.height}px - ${foldersVar}px - 10px)`
      : `calc(100vh - 56px - ${headerSizeRef.height}px - ${foldersVar}px)`;
  }, [headerSizeRef?.height, foldersSizeRef?.height]);

  async function fetchMore() {
    if (fetching.current || !page.current || !libraryInitialized) {
      return;
    }
    fetching.current = true;
    setIsFetching(true);
    const assetsLoadedCount = assetsAmount();
    try {
      const items = await Asset.list(
        {
          typeIn: [Asset.TYPE.SOURCE],
          statusIn: [AssetStatus.READY, AssetStatus.PROCESSING],
        },
        {
          pageSize: assetsLoadedCount || PAGE_SIZE,
          pageIndex: assetsLoadedCount ? 2 : page.current,
        }
      );
      setIsFetching(false);
      if (items?.length) {
        appendAssets(...items);
      }
      if (items.length < PAGE_SIZE) {
        page.current = null;
      } else {
        page.current++;
      }
      fetching.current = false;
    } catch (err) {
      console.error(err);
      retryCount.current++;
      if (retryCount < 2) {
        return setTimeout(() => fetchMore());
      }
      handleError({
        statusCode: 9,
        responseError: err,
      });
    }
  }

  React.useEffect(() => {
    if (!teamButtonRef.current || !tabsWrapperRef.current) {
      return;
    }
    const { left, right } = tabsWrapperRef.current.getBoundingClientRect();
    let activeLeft, activeRight;
    activeLeft = teamButtonRef.current.getBoundingClientRect().left;
    activeRight = teamButtonRef.current.getBoundingClientRect().right;
    setActivePos({
      left: activeLeft - left,
      right: right - activeRight,
    });
  }, [activeTab, teamButtonRef.current, tabsWrapperRef.current]);

  async function onAssetFileInputChange(files) {
    setShowUploadNotification(true);
    setIsDragActive(false);
    files.forEach(async (file) => {
      const asset = new Asset();
      asset.name = file.name;
      asset.type = Asset.TYPE.SOURCE;
      await asset.save();

      const maxDuration = user?.UIFLAGS.NO_UPLOAD_LIMIT ? null : plan?.maxVideoDuration * 60;
      const uploader = new FileUploader({
        file,
        maxDuration,
        content: asset,
      });
      fileUploaderState.set((uploaders) => ({ ...uploaders, [asset.sid]: uploader }));

      uploader
        .on('done', () => fileUploaderState.set(({ [asset.sid]: done, ...uploaders }) => uploaders))
        .on('canceled', () => {
          fileUploaderState.set(({ [asset.sid]: canceled, ...uploaders }) => uploaders);
        })
        .on('tooLongNotification', async () => {
          let fileName = '';
          if (typeof uploader.file.name === 'string') {
            if (uploader.file.name.length <= 14) {
              fileName = `"${uploader.file.name}" `;
            } else {
              fileName = `"${uploader.file.name.substr(0, 14)}..." `;
            }
          }

          await onAssetRemove(asset.sid);
          fileUploaderState.set(({ [asset.sid]: error, ...uploaders }) => uploaders);
          openPeechProUpgradeModal(user, 'Too long video uploaded', "TOO_LONG_VIDEO")
        })
        .on('error', async (title, message) => {
          handleError({ title, message });
          await onAssetRemove(asset.sid);
          fileUploaderState.set(({ [asset.sid]: error, ...uploaders }) => uploaders);
        })
        .on('thumbnail', async ({ src, thumbnail }) => {
          if (!asset.contentIdentifier) {
            return;
          }
          try {
            uploader.uploadThumbnail(asset.contentIdentifier.key, thumbnail);
            asset.thumbnail = src;
          } catch (err) {
            console.error(err);
            handleError({
              title: 'Content thumbnail upload failed',
              message: err.message,
              responseError: err,
            });
          }
        })
        .on('parsed', (metadata) => {
          console.log(metadata);
        })
        .uploadAsset(null, asset.sid);
    });
  }

  async function onAssetRemove(sid) {
    trackEvent('asset-cancel');
    try {
      // push sid to array with removed assets, so when useEffect works before this function finishes, removed asset doesn't return to filesJustUploaded
      setRemovedItems((prev) => {
        let arr = [...prev];
        arr.push(sid);
        return arr;
      });
      //cleaning localState
      setFilesJustUploaded((prev) => {
        let arr = [...prev].filter((el) => el.content.sid !== sid);
        return arr;
      });
      setFilesInUpload((prev) => {
        let arr = [...prev].filter((el) => el.content.sid !== sid);
        return arr;
      });
      // cleaning globalState
      fileUploader[sid]?.cancel();
      // removing from uploading
      await new Asset(sid).remove();
      let assetsCopy = [...assets.filter((x) => x.sid !== sid)];
      loadedAssetsState.set(assetsCopy);
    } catch (err) {
      handleError({
        title: 'Deleting Asset',
        message: err.message,
        responseError: err,
      });
    }
  }

  function onSelect(asset) {
    setPreviewAsset(asset);
  }
  const teamRef = React.useRef();
  const placeHolderRef = React.useRef();

  const contentItems = [
    { name: 'Team uploads', value: CONTENTS.TEAM, ref: teamRef },
    {
      name: '',
      value: '',
      ref: placeHolderRef,
    },
  ];

  const getFootageClipsData =  (sid) => {
    const footage = _.find(footages, ({assetsFolderSid})  => {
      return assetsFolderSid === sid;
    });
    if(footage && footage.clips) {
      const totalClips = footage.clips.length;
      const readyClips = _.filter(footage.clips, {status: "ready"}).length;
      const hasPendingClip = !!_.find(footage.clips, {status: "pending"});
      return {
        totalClips,
        readyClips,
        hasPendingClip,
        info: totalClips === readyClips ? `${readyClips} ${pluralize('videos', readyClips)}` :  `${readyClips} of ${totalClips} ${pluralize('videos', totalClips)}`,
      }
    }
    return null;
  };


  const currentFolderFootageData = React.useMemo(() => {
    return getFootageClipsData(currentFolderSid) 
  },[currentFolderSid, footages])

  if (!libraryInitialized) {
    return (
      <div className="init-loader">
        <Loader style={{ display: 'block' }} />
      </div>
    );
  }

  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  async function createNewFolder(parentSid) {
    setShowAddFolderModal(false);
    const name = folderInputRef.current.value;
    const folder = new FolderStructure();
    folder.name = name;
    folder.type = FolderStructure.FOLDER_TYPE.ASSET;
    try {
      const newFolder = await folder.save();
      setFoldersList((state) => [newFolder, ...(state || [])]);
    } catch (err) {
      console.error('error ', err);
    }
    folder.save();
  }

  const isNewFolderNameNotUnique = (str) => {
    return !!foldersList.find((folder) => folder.name?.toUpperCase().trim() === str?.toUpperCase().trim());
  };

  async function deleteFolder(sid) {
    let folderForDeleting;
    await FolderStructure.get(sid).then((res) => (folderForDeleting = res));
    if (folderForDeleting) {
      try {
        await folderForDeleting.remove();
        setFoldersList((state) => state.filter((folder) => folder.sid !== sid));
        loadedAssetsState.set((assets) => {
          assets.forEach((asset) => asset.folderSid === sid && (asset.folderSid = null));
          return assets;
        });
      } catch (err) {
        console.error(err);
      }
    }
    setVerifyFolderDeleteSid(false);
    onSelectFolder(null);
  }

  const getVideosAmount = (sid) => {
    let amount = 0;
    if (assets && assets.length) {
      amount = assets.filter((asset) => asset.folderSid === sid).length;
    }
    return `${amount} ${pluralize('videos', amount)}`;
  };

 

  async function renameFolder() {
    const name = folderInputRefRename.current.value;
    try {
      let folderForRenaming = await FolderStructure.get(verifyFolderRenameSid);
      folderForRenaming.name = name;
      await folderForRenaming.save();
      getFoldersList();
    } catch (err) {
      console.error(err);
    }
    setVerifyFolderRenameSid(false);
    onSelectFolder(null);
    folderInputRefRename.current = null;
  }

  const onFileUploadClick = () => {
    if ((uploadFile && !filesInUpload?.length) || (!uploadFile && !filesInUpload?.length)) {
      setFilesJustUploaded([]);
    }
    setUploadFile(!uploadFile);
    setHideUploadProgress(true);
  };

  async function onLangChange(val, sid) {
    let filesJustUploadedCopy = [...filesJustUploaded];
    filesJustUploadedCopy.map((el) => {
      if (el.content.sid === sid) {
        el.content.languageCode = val;
      }
    });
    setFilesJustUploaded(filesJustUploadedCopy);

    let asset = assets.find((x) => x.sid === sid);
    if (!asset) {
      return null;
    } else {
      let oldLangValue = asset.languageCode;
      try {
        asset.languageCode = val;
        await asset.save().then((res) => {
          console.log('saved asset', { res });
          modifyAsset(res);
          return res.languageCode;
        });
      } catch (error) {
        console.log('asset languageCode saving error: ', error);
        asset.languageCode = oldLangValue;
        return asset.languageCode;
      }
    }
  }

  const onUpload = (files) => {
    onAssetFileInputChange(files);
    setUploadFile(true);
  };

  if (assets === undefined) {
    return null;
  }

  if (!assets.length) {
    return (
      <div>
        <ContentFileUpload
          title={'No videos yet?'}
          setAssets={setAssets}
          onHide={() => {
            setUploadFile(false);
          }}
          onLangChange={onLangChange}
          filesInUpload={filesJustUploaded}
          onAssetRemove={onAssetRemove}
          supportedLanguages={config?.SUPPORTED_LANGUAGES || []}
          onAssetFileInputChange={onAssetFileInputChange}
        />
      </div>
    );
  }

  return (
    <>
      {shouldStopReload && <BeforeUnload />}
      <div className="contents" style={{ height: '100%' }} onClick={() => uploadFile && setUploadFile(false)}>
        {/*  Header */}
        {!isModal && (
          <div ref={headerSizeRef.ref} className={classNames('assets-group-header', { overlay: uploadFile })}>
            <div className="tabs-group">
              {enabledFoldersFeature && (
                <div
                  className="page-title"
                  onClick={() => onSelectFolder(null)}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => onDropActions(null, assets, assetSidDragged)}
                  style={{ fontSize: isModal ? '16px' : '24px', width: '' }}
                >
                  Content Library
                </div>
              )}
              {!enabledFoldersFeature ? (
                <Tabs
                  tabsItems={contentItems}
                  // onChange={(selectedTab) => setActiveTab(selectedTab)}
                />
              ) : !currentFolderSid ? (
                <Tabs
                  tabsItems={contentItems}
                  // onChange={(selectedTab) => setActiveTab(selectedTab)}
                />
              ) : (
                <>
                <div className="page-subtitle" style={{ paddingBottom: '2px' }}>
                  <div className="page-subtitle-arrow">{'>'}</div>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="page-subtitle-text-tooltip" className="tooltip-peech-light">
                          {foldersList?.find((f) => f.sid === currentFolderSid)?.name || ''}
                        </Tooltip>
                      }
                    >
                    
                      
                        <div className="page-subtitle-text">
                          {foldersList?.find((f) => f.sid === currentFolderSid)?.name || ''}
                        </div>
                      
                      
                    </OverlayTrigger>
                </div>
                </>
              )}
            </div>
            
              <div className="actions-group">
                {
                  currentFolderFootageData && 
                  <div>
                    {currentFolderFootageData.info}
                  </div>
                }
                { !disableUpload && !isModal && (
                  <>
                    {user.UIFLAGS.MOBILE_UPLOAD ? (
                      <Link onClick={() => displayUploadMobileModalState.set(true)}>Upload from mobile</Link>
                    ) : null}
                    <a onClick={onFileUploadClick}>
                      <div className="add-video-wrapper">
                        {uploadFile || (filesJustUploaded?.length && !hideUploadProgress) ? (
                          <ContentFileUploadModal
                            title={'Upload video content'}
                            setAssets={setAssets}
                            onHide={() => {
                              setUploadFile(false);
                            }}
                            onLangChange={onLangChange}
                            filesInUpload={filesJustUploaded}
                            onAssetRemove={onAssetRemove}
                            supportedLanguages={config?.SUPPORTED_LANGUAGES || []}
                            onAssetFileInputChange={onAssetFileInputChange}
                            showUploadNotification={showUploadNotification}
                            setShowUploadNotification={setShowUploadNotification}
                          />
                        ) : null}

                        <div className="add-video-button-wrapper">
                          {!filesInUpload.length ? (
                            <SVG name="add-video" className="add-video" />
                          ) : (
                            <Loader size="xs" />
                          )}
                          <span>Upload</span>
                        </div>
                      </div>
                    </a>
                  </>
                )}
              </div>

            {enabledFoldersFeature && (
              <CreateModal
                showModal={showAddFolderModal}
                setShowModal={setShowAddFolderModal}
                modalTitle="New folder name"
                innerRef={folderInputRef}
                addButton="Add new folder"
                createItem={createNewFolder}
                svgName={'library'}
                disabled={isNewFolderNameNotUnique}
              />
            )}
          </div>
        )}

        {isModal ? (
          <div
            className="assets-group-content-wrapper"
            style={{
              height: '100%',
              minHeight: 'unset',
            }}
          >
            <Row className="assets-group-content" ref={projectsRef}>
              <RedirectToUploadingButton path={user.UIFLAGS.SMART_LIBRARY ? '/webinars' : '/library?upload=true'} />
              {assets.map((asset) => (
                <AssetItem
                  key={asset.sid}
                  assetItem={asset}
                  onRemove={onAssetRemove}
                  onSelect={onSelect}
                  isModal={isModal}
                  isSelected={isSelected && isSelected(asset)}
                  onAssetSelected={onAssetSelected}
                  selectedNumber={selectedNumber}
                />
              ))}
            </Row>
            <LoaderDiv active={isFetching}>
              <Loader />
            </LoaderDiv>
          </div>
        ) : (
          <Dropzone
            onDragLeave={() => setIsDragActive(false)}
            onDragEnter={() => setIsDragActive(true)}
            onDrop={(files) => onUpload(files)}
            accept="video/*"
            disabled={disableUpload}
          >
            {({ getRootProps, getInputProps }) => {
              const { onClick, onMouseDown, ...rootProps } = getRootProps();

              return (
                <div {...rootProps}>
                  <input
                    id="branding-logo-input"
                    {...getInputProps({
                      onClick: (event) => event.stopPropagation(),
                    })}
                  />
                  {
                    <div
                      className="assets-group-content-wrapper"
                      ref={scrollingContainerRef}
                      style={{
                        height: filesContainerHeight,
                      }}
                      onClick={(e) => {
                        setUploadFile(false);
                      }}
                    >
                      {/* Folders */}

                      {enabledFoldersFeature ? (
                        <Row className="assets-group-content" ref={projectsRef}>
                          {!currentFolderSid && (
                            <div className="part-title">
                              Folders
                              <div className="search-container">
                                {enabledFoldersFeature && (
                                  <div className="search-wrapper">
                                    <SVG name="search" className="search" />
                                    <input
                                      type="text"
                                      id="search-input"
                                      name="search-input"
                                      placeholder="Search here"
                                      onChange={onSearchTextChange}
                                    />
                                  </div>
                                )}

                                <div
                                  className="add-folder-wrapper"
                                  onClick={() => {
                                    setShowAddFolderModal(true);
                                  }}
                                >
                                  <SVG name="add-folder" className="add-folder" />
                                </div>
                              </div>
                            </div>
                          )}
                          {!currentFolderSid &&
                            foldersList.map((item, index) => {
                              const footageClipsData = getFootageClipsData(item.sid);
                              const folderSubTitle = footageClipsData ? footageClipsData.info : getVideosAmount(item.sid);
                                return (
                                  <Folder
                                    key={item.sid}
                                    showLoader={footageClipsData && footageClipsData.hasPendingClip}
                                    item={item}
                                    isModal={isModal}
                                    onFolderClick={() => onSelectFolder(item.sid)}
                                    onDropActions={onDropActions}
                                    subTitle={folderSubTitle}
                                    verifyFolderDeleteSid={verifyFolderDeleteSid}
                                    setVerifyFolderDeleteSid={setVerifyFolderDeleteSid}
                                    deleteFolder={deleteFolder}
                                    verifyFolderRenameSid={verifyFolderRenameSid}
                                    setVerifyFolderRenameSid={setVerifyFolderRenameSid}
                                    renameFolder={renameFolder}
                                    innerRef={folderInputRefRename}
                                    disabled={isNewFolderNameNotUnique}
                                    currentFolderSid={currentFolderSid}
                                    isOptionsMenuOpened={isOptionsMenuOpened}
                                    setIsOPtionsMenuOpened={setIsOPtionsMenuOpened}
                                    deleteText={deleteText}
                                    deleteQuestion={deleteQuestion}
                                    allItems={assets}
                                    sidDragged={assetSidDragged}
                                    scrollingContainerRef={scrollingContainerRef}
                                    hoveredFolder={hoveredFolder}
                                    setHoveredFolder={setHoveredFolder}
                                  />
                                );
                              })}

                          {/* Files */}

                          {enabledFoldersFeature && assets.length && !currentFolderSid && !isModal && (
                            <div className="part-title">Files</div>
                          )}

                          {!currentFolderSid && !disableUpload && (
                            <UploadNewAssetButton
                              onUpload={onUpload}
                              setUploadFile={setUploadFile}
                              isDragActive={isDragActive}
                              setIsDragActive={setIsDragActive}
                            />
                          )}
                          {assets
                            .filter((asset) => {
                              if ((!currentFolderSid && !asset.folderSid) || asset.folderSid === currentFolderSid) {
                                return asset;
                              }
                            })
                            .filter((asset) =>
                              !searchText ? asset : asset.name?.toUpperCase().includes(searchText?.toUpperCase().trim())
                            )
                            .map((asset) => (
                              <AssetItem
                                key={asset.sid}
                                assetItem={asset}
                                onRemove={onAssetRemove}
                                onSelect={onSelect}
                                isModal={isModal}
                                isSelected={isSelected && isSelected(asset)}
                                onAssetSelected={onAssetSelected}
                                draggable={true}
                                onDragStart={(e) =>
                                  dragToFolderStartHandler(e, asset.sid, setDraggedClassName, setAssetSidDragged)
                                }
                                onDragEnd={(e) =>
                                  dragToFolderEndHandler(
                                    e,
                                    asset.sid,
                                    draggedClassName,
                                    setAssetSidDragged,
                                    setDraggedClassName
                                  )
                                }
                                setUploadFile={setUploadFile}
                                scrollingContainerRef={scrollingContainerRef}
                              />
                            ))}
                        </Row>
                      ) : (
                        <Row className="assets-group-content" ref={projectsRef}>
                          {!disableUpload && (
                            <UploadNewAssetButton
                              onUpload={onUpload}
                              setUploadFile={setUploadFile}
                              isDragActive={isDragActive}
                              setIsDragActive={setIsDragActive}
                            />
                          )}
                          {assets.map((asset) => (
                            <AssetItem
                              key={asset.sid}
                              assetItem={asset}
                              onRemove={onAssetRemove}
                              onSelect={onSelect}
                              isModal={isModal}
                              isSelected={isSelected && isSelected(asset)}
                              onAssetSelected={onAssetSelected}
                              selectedNumber={selectedNumber}
                              setUploadFile={setUploadFile}
                              scrollingContainerRef={scrollingContainerRef}
                            />
                          ))}
                        </Row>
                      )}
                      <LoaderDiv active={isFetching}>
                        <Loader />
                      </LoaderDiv>

                      <PlayerModal
                        status={previewAsset?.status}
                        title={previewAsset?.name}
                        show={!!previewAsset}
                        previewAsset={previewAsset}
                        setPreviewAsset={setPreviewAsset}
                        playbackUrl={previewAsset?.previewUrl}
                        thumbnail={previewAsset?.getThumbnailUrl({
                          width: 200,
                          aspectRatio: previewAsset?.aspectRatio || '16:9',
                        })}
                        onHide={() => setPreviewAsset(null)}
                        aspectRatio={previewAsset?.aspectRatio || '16:9'}
                        withCreateButton={true}
                        onCreate={() => {
                          if (isFastProjectCreationFlowEnabled || isNewProjectCreationFlowEnabled) {
                            const encodedTitle = encodeURIComponent(previewAsset.values.name);
                            navigate(`/create-project?assetSid=${previewAsset.values.sid}&title=${encodedTitle}`);
                          } else {
                            navigate('/project', {
                              state: {
                                asset: new Asset().set(previewAsset.values),
                              },
                            });
                          }
                        }}
                        supportedLanguages={config?.SUPPORTED_LANGUAGES || []}
                        onLangChange={onLangChange}
                      />
                    </div>
                  }
                </div>
              );
            }}
          </Dropzone>
        )}
      </div>
    </>
  );
}
