import React from 'react';
import './PlugMobile.scss';

const HEADER_LOGO = '/images/peech_header_logo.png';

interface PlugMobileProps {}

export default function PlugMobile({}: PlugMobileProps) {
  const SvgPlugMob = (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_135_10929)">
        <g clipPath="url(#clip1_135_10929)">
          <g className="color-1">
            <path d="M78.5461 102.022L22.9528 92.2196C19.8528 91.673 17.7213 88.982 18.2081 86.2213L24.7731 48.2944L25.6348 43.4077L92.6017 55.2158L88.2456 79.9206C88.1404 80.5172 87.5683 80.9196 86.9742 80.8148C86.3801 80.7101 85.9802 80.1363 86.0854 79.5397L90.0589 57.0044L27.4125 45.9581L26.9343 48.6699L20.3683 86.6022C20.0919 88.1697 21.4235 89.7131 23.3353 90.0502L78.9286 99.8528C80.8404 100.19 82.6196 99.195 82.896 97.6276L84.6394 87.7402C84.7446 87.1436 85.3167 86.7412 85.9108 86.8459C86.5048 86.9507 86.9048 87.5245 86.7996 88.1211L85.0562 98.0085C84.5694 100.769 81.6514 102.57 78.5461 102.022Z" />
            <path d="M75.9096 74.083L34.3253 66.7506L35.8555 58.0728L77.4398 65.4052L75.9096 74.083ZM36.8681 64.9621L74.1319 71.5327L74.897 67.1937L37.6332 60.6231L36.8681 64.9621Z" />
            <path d="M73.9018 85.4728L32.3175 78.1403L33.8477 69.4625L75.432 76.7949L73.9018 85.4728ZM34.8603 76.3518L72.1241 82.9224L72.8892 78.5835L35.6253 72.0128L34.8603 76.3518Z" />
          </g>
          <g className="color-2">
            <mask
              id="mask0_135_10929"
              style={{ maskType: 'alpha' }}
              maskUnits="userSpaceOnUse"
              x="53"
              y="2"
              width="76"
              height="60"
            >
              <rect x="67.0762" y="2.32373" width="71" height="27" transform="rotate(30.3084 67.0762 2.32373)" />
            </mask>
          </g>

          <g className="color-3">
            <g mask="url(#mask0_135_10929)">
              <path d="M65.7886 29.2143C65.5916 29.0991 65.4011 28.8824 65.3582 28.5941L63.1609 18.5159C63.0686 18.0407 63.3668 17.5305 63.8426 17.44C64.3184 17.3496 64.8307 17.649 64.9229 18.1242L67.0973 28.2416C67.1896 28.7168 66.8914 29.227 66.4156 29.3175C66.2285 29.3661 65.9856 29.3294 65.7886 29.2143Z" />
              <path d="M105.073 52.178C104.876 52.0628 104.725 51.8692 104.675 51.6823C104.521 51.2233 104.779 50.6901 105.278 50.5604L115.161 47.491C115.62 47.3382 116.156 47.5984 116.287 48.0966C116.442 48.5556 116.183 49.0889 115.684 49.2186L105.801 52.288C105.513 52.3298 105.27 52.2932 105.073 52.178Z" />
              <path d="M89.8375 32.4776C89.4041 32.2242 89.2559 31.6637 89.5083 31.232L94.5978 22.2539C94.8502 21.8222 95.4113 21.6763 95.8447 21.9296C96.2781 22.183 96.4263 22.7435 96.1739 23.1752L91.0844 32.1533C90.832 32.585 90.2709 32.7309 89.8375 32.4776Z" />
            </g>
            <path d="M79.3055 54.8288C78.922 54.7612 78.5857 54.4894 78.4554 54.0917L74.9984 43.6957C74.805 43.1191 75.1151 42.4971 75.6896 42.302C76.2632 42.1124 76.8832 42.4174 77.0767 42.994L80.5336 53.3901C80.7271 53.9666 80.417 54.5886 79.8424 54.7837C79.6641 54.8473 79.4783 54.8593 79.3055 54.8288Z" />
            <path d="M60.9422 51.591C60.5587 51.5234 60.2224 51.2516 60.0921 50.8539L56.6352 40.4579C56.4417 39.8813 56.7518 39.2593 57.3264 39.0643C57.8999 38.8746 58.5199 39.1796 58.7134 39.7562L62.1703 50.1523C62.3638 50.7288 62.0537 51.3508 61.4791 51.5459C61.3009 51.6095 61.115 51.6215 60.9422 51.591Z" />
            <path d="M42.5809 48.3535C42.1974 48.2859 41.8611 48.0141 41.7308 47.6164L38.2738 37.2203C38.0804 36.6438 38.3905 36.0218 38.965 35.8267C39.5386 35.637 40.1586 35.9421 40.3521 36.5187L43.809 46.9147C44.0025 47.4913 43.6924 48.1133 43.1178 48.3083C42.9395 48.372 42.7537 48.3839 42.5809 48.3535Z" />
          </g>

          <g className="color-1">
            <path d="M92.2185 57.3855L25.2516 45.5774L26.6555 37.6155C27.0945 35.1261 29.4656 33.4582 31.9444 33.8953L89.9302 44.1198C92.4091 44.5569 94.0668 46.9351 93.6278 49.4245L92.2239 57.3865L92.2185 57.3855ZM27.7944 43.7889L90.4408 54.8351L91.4622 49.0427C91.6898 47.7519 90.8276 46.5149 89.5423 46.2883L31.5619 36.0648C30.2766 35.8381 29.0433 36.7056 28.8157 37.9964L27.7944 43.7889Z" />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_135_10929">
          <rect width="120" height="120" fill="white" />
        </clipPath>
        <clipPath id="clip1_135_10929">
          <rect width="120" height="123" fill="white" transform="translate(0 -20)" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <div className="container" data-cy="plug-mobile">
      <div className="content">
        <div className="svg-wrapper">{SvgPlugMob}</div>

        <div className="text-title">This page is not yet supported on mobile</div>
      </div>
    </div>
  );
}
