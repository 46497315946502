import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

const SliderWithTooltip = createSliderWithTooltip(Slider);

const SliderContainer = styled.div<{ disabled: boolean }>`
  width: 100%;
  margin-top: 12px;
  align-items: center;
  display: flex;
  height: 22px;
  position: relative;
`;

const StyledSlider = styled(SliderWithTooltip)`

  .rc-slider-rail {
    height: 7px;
    background-color: ${COLORS.peech_gray_100};
    border-radius: 5px;
  }

  .rc-slider-track {
    background-color: ${COLORS.peech_primary_2_500};
    border-radius: 5px;
    height: 7px;
  }

  .rc-slider-handle {
    display: ${(props) => (props.disabled ? 'none' : 'block')};
    z-index: 1;
    width: 24px;
    height: 24px;
    margin-top: -9px;
    border-radius: 50%;
    background-color: ${COLORS.peech_neutral_white};
    box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12);
    border: none;
    opacity: 1;

    &:focus {
      outline: none !important;
      box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12) !important;
    }
    &:focus::-moz-focus-inner {
      border: 0;
    }
  }

  .rc-slider-tooltip {
    min-width: 30px;
    min-height: 20px;
    width: fit-content;
    background-color: transparent;
  }

  .rc-slider-tooltip-inner {
    color: ${COLORS.peech_gray_400};
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12), 0px 6px 13px rgba(0, 0, 0, 0.12);
    background-color: ${COLORS.peech_neutral_white};
  }

  .rc-slider-tooltip-arrow {
    border-top-color: ${COLORS.peech_neutral_white} !important;
  }

  &.rc-slider-disabled {
    background-color: transparent !important;
  }

`;

interface HorizontalSliderProps {
  min?: number;
  max?: number;
  value?: number;
  step?: number;
  onChange?: (value: number) => void;
  format?: (value: number) => string;
  height?: number;
  disabled?: boolean;
  enableTooltip?: boolean;
  triggerOnChangeImmediately?: boolean;
}

export default function HorizontalSlider({
  min = 0,
  max = 100,
  value = 0,
  step = 1,
  onChange,
  format,
  height = 20,
  disabled = false,
  enableTooltip = true,
  triggerOnChangeImmediately = false
}: HorizontalSliderProps) {
  const formatter = format || ((value) => value);
  const [sliderValue, setSliderValue] = React.useState(0);
  const [shouldShowTooltip, setShouldShowTooltip] = React.useState(false);

  React.useEffect(() => {
    setSliderValue(value);
  }, [value]);

  const handleSliderChange = React.useCallback(
      (newValue: number) => {
        triggerOnChangeImmediately ? onChange?.(newValue) : setSliderValue(newValue);
    },
    [setSliderValue, onChange, triggerOnChangeImmediately]
  );

  const handleSliderAfterChange = React.useCallback(() => {
    !triggerOnChangeImmediately && onChange?.(sliderValue);
    setShouldShowTooltip(false);
  }, [onChange, sliderValue, setShouldShowTooltip, triggerOnChangeImmediately]);

  return (
    <SliderContainer style={{ height: `${height}px` }} disabled={disabled}>
      <StyledSlider
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        onFocus={() => enableTooltip && setShouldShowTooltip(true)}
        onChange={handleSliderChange}
        onAfterChange={handleSliderAfterChange}
        disabled={disabled}
        tipFormatter={formatter}
        tipProps={{
          visible: shouldShowTooltip,
          placement: enableTooltip ? "top" : "none"
        }}
      />
    </SliderContainer>
  );
}
