import styled from '@emotion/styled/macro';
import React, { KeyboardEvent } from 'react';

interface Props {
  onDone: (target: HTMLDivElement, splitIndex: number, merge: boolean) => void;
  allowMerge: boolean;
  allowSplit: boolean;
}

const ContentEditableStyled = styled.div`
  outline: 0px solid transparent;
  &::selection {
    background-color: rgba(237, 63, 153, 0.4);
  }
  direction: ${(props) => (props.dir ? props.dir : 'ltr')};
`;

export const EditableCloseCaptionComponent: React.FC<Props> = ({ children, onDone, allowMerge, allowSplit, dir }) => {
  const contentEditableEl = React.useRef<HTMLDivElement>(null);
  const [splitIndex, setSplitIndex] = React.useState<number>(NaN);
  const [originChildren, setOriginChildren] = React.useState(children);

  const onBlur = () => {
    if (contentEditableEl?.current) {
      // check for changes before call onDone
      if (isNaN(splitIndex) && originChildren === contentEditableEl?.current.innerText) {
        return;
      }
      onDone(contentEditableEl?.current, splitIndex, false);
      setSplitIndex(NaN);
    }
  };

  React.useEffect(() => {
    setOriginChildren(children);
  }, [children]);

  const onKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'Escape':
        contentEditableEl?.current?.blur();
        break;
      case 'Enter':
        e.preventDefault();
        if(!allowSplit) return;
        let selection = window.getSelection();
        if (selection && selection.focusNode) {
          const { focusOffset, focusNode, type } = selection;
          const selectionText = focusNode.textContent;
          if (type === 'Caret' && focusOffset && selectionText) {
            if (
              selectionText.length > focusOffset &&
              !selectionText[focusOffset].match(/[\s\n]/) &&
              !selectionText[focusOffset - 1].match(/[\s\n]/)
            ) {
              console.log('closed-caption-enter', { cancel: 'not-space' });
              // Not on space
              return;
            }
            const focusOffsetIndex =
              selectionText
                .substring(0, focusOffset)
                .trim()
                .split(/[\s\r\n]+/).length - 1;
            setSplitIndex(focusOffsetIndex);
            setTimeout(() => contentEditableEl?.current?.blur(), 0);
          }
        }
        break;

      case 'Backspace':
        selection = window.getSelection();
        if (
          allowMerge &&
          selection &&
          selection.focusNode &&
          selection.focusOffset === 0 &&
          selection.focusNode.textContent
        ) {
          console.log('time to merge closedCaption');
          if (contentEditableEl?.current) {
            onDone(contentEditableEl?.current, NaN, true);
          }
        }
        break;
      default:
        break;
    }
  };
 
  return (
    <ContentEditableStyled
      ref={contentEditableEl}
      contentEditable
      suppressContentEditableWarning
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      dir={dir}
    >
      {children}
    </ContentEditableStyled>
  );
};
