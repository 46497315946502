import React, {useCallback, useEffect, useRef, useState} from 'react';
import HorizontalSlider from '../../Slider/HorizontalSlider';
import styled from "@emotion/styled";

interface SliderWithInputProps {
    value: any;
    onValueChange: (value: number) => void;
    hide?: boolean;
    disabled?: boolean;
    minLabel?: string;
    maxLabel?: string;
    min?: number;
    max?: number;
    step?: number;
    reverse?: boolean;
    enableSliderTooltip?: boolean;
    triggerOnChangeImmediately?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //  align-items: baseline;
`;

export const SliderInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  // width: 100%;
//  align-items: baseline;
`;

export const RangeLabels = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
  span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
`;

export const ScrollerWrapper = styled.div<{ $marginTop?: string | undefined; }>`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: flex-start;
  justify-content: center;
  overflow: visible;
  flex: 1;
  margin-right: 12px;
  margin-top: ${props => props.$marginTop || '30px'};
`;

const Input = styled.input<{ error?: boolean; }>`
  margin-left: 10px;
  width: 45px;
  height: 27px;
  top: 2px;
  left: 195px;
  border-radius: 8px;
  background: #F5F5F5;
  text-align: center;
  border: ${props => props.error ? "1px red solid" : "none"};
  caret-color: black;
  font-size: 12px;
  &:hover {
    border: 1px black solid;
    outline-offset: 1px;

  }
  :focus-visible {
    outline-style: solid !important;
    outline-width: 2px !important;
    outline-offset: -2px !important;
    outline-color: ${props => props.error ? "red" : "#1e9ffb"} !important;
  }
  &::selection {
    background: lightskyblue;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
  }
`;

export default function SliderWithInput({
                value,
                onValueChange,
                disabled = false,
                minLabel,
                maxLabel,
                min = 0,
                max = 100,
                step = 1,
                reverse = false,
                enableSliderTooltip = false,
                triggerOnChangeImmediately = true,
}: SliderWithInputProps) {

    const [displayValue, setDisplayValue] = useState(reverse ? String(max - value) : String(value));
    const inputRef = useRef<HTMLInputElement | null>(null);
    const timerRef = useRef<number | null>(null);

    useEffect(() => {
      let newValue = reverse ? max - value : value;
      newValue = sanitizeValue(String(newValue));
      setDisplayValue(newValue);
    }, [max, min, value]);

    const sanitizeValue = useCallback((dirtyValue: string) => {
        // this returns closest value within range.
        let value = Number(dirtyValue);
        value = Math.min(max, Math.max(min, value));
        return String(value);
    },[max, min, value])

    const onInputChange = useCallback((inputValue: string) => {
      // typing any numeric value allowed, even if it's invalid.
      setDisplayValue(inputValue);
      // clear the previous timer
      if (timerRef.current) {
          clearTimeout(timerRef.current);
      }
      // set a new timer (debounce the input change).
      // check if the value is valid. if so, update the value.
      // if not, do nothing. onInputBlur will sanitize and update the value.
      timerRef.current = setTimeout(() => {
          if (sanitizeValue(inputValue) === inputValue) {
              onValueChange(reverse ? max - Number(inputValue) : Number(inputValue));
          }
      }, 400);
    },[value, onValueChange]);

    const onSliderChange = useCallback((sliderValue: number) => {
        onInputChange(String(sliderValue));
    },[value, onInputChange]);

    const onInputBlur = useCallback((inputValue: string) => {
        // prevent the input from being stuck on an invalid value.
            inputValue = sanitizeValue(inputValue);
            setDisplayValue(inputValue);
            onValueChange(reverse ? max - Number(inputValue) : Number(inputValue));
    },[max, value, onValueChange]);

    // TODO: add check if we want to validate only integers
    const onInputKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
      const intRx = /\d/; // only allow digits
      if (
				(e.key.length > 1) ||
        e.ctrlKey ||
				( (e.key === "-") && (!e.currentTarget.value.length) ) ||
				intRx.test(e.key)
			) return;
			e.preventDefault();
    }
    ,[]);

    return (
        <Wrapper>
            <SliderInputWrapper>
                <ScrollerWrapper $marginTop={'-12px'}>
                    <HorizontalSlider
                        value={Number(displayValue)}
                        min={min}
                        max={max}
                        step={step}
                        enableTooltip={enableSliderTooltip}
                        onChange={onSliderChange}
                        triggerOnChangeImmediately={triggerOnChangeImmediately}
                        disabled={disabled}
                    />
                    {(minLabel || maxLabel) && <RangeLabels>
                        {minLabel && <span>{minLabel}</span>}
                        {maxLabel && <span>{maxLabel}</span>}
                    </RangeLabels>}
                </ScrollerWrapper>
                <Input
                    ref={inputRef}
                    value={displayValue}
                    defaultValue={displayValue}
                    error={displayValue !== sanitizeValue(displayValue)}
                    disabled={disabled}
                    min={min}
                    max={max}
                    step={step}
                    type="number"
                    onChange={(e) => onInputChange(e.target.value)}
                    onBlur={(e) => onInputBlur(e.target.value)}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      inputRef?.current?.select();
                    }}
                    onKeyDown={onInputKeyDown}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                        inputRef?.current?.blur();
                      }
                    }}
                />
            </SliderInputWrapper>

        </Wrapper>
    );
}
