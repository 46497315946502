import React from 'react';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import LottiePlayer from '../../components/lottie-player';
import SVG from '../../components/svg';
import { trackEvent } from '../../utils/analityics.utils';
import Button from '../Button';
import './modals.scss';


interface ApproveModalProps {
    approveText: string
    showModal: Boolean
    setShowModal: Function
    // innerRef: React.MutableRefObject<HTMLInputElement>
    approve: Function
    setTemplates: Function
    isNew: boolean
}


export default function ApproveModal({ showModal, setShowModal, approveText,
    approve, setTemplates, isNew }: ApproveModalProps) {

    return (
        <Modal
            show={showModal}
            centered
            onHide={() => { setShowModal(false) }}
            dialogClassName="approve-modal"
        >
            <ModalBody as="wrapper">
                <div>
                    <SVG name="alert" className="alertSmall" />
                </div>
                <div className='popup-text-wrapper'>
                    <div className='popup-text'>
                        <p style={{whiteSpace: "pre-line"}}>
                            {approveText}
                        </p>
                    </div>
                </div>
                <Button className='approve-button--no' onClick={() => {
                     trackEvent('brand-kit-regenerate-colors', {type: false, isInWizard: !!isNew});
                    setShowModal(false)
                    setTemplates(false)
                }} variant="gray" size="sm" label={"No"} />
                <Button className='approve-button--yes' onClick={() => {
                     trackEvent('brand-kit-regenerate-colors', {type: true, isInWizard: !!isNew});
                    approve()
                    setTemplates(false)
                }
                } variant="primary" size="sm" label={"Yes"} />

            </ModalBody>

        </Modal>

    )
}

