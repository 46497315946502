import { useLocation } from "@reach/router";
import classNames from 'classnames';
import { diffChars } from 'diff';
import React from 'react';
import ClosedCaption, { Word } from '../../../client/closedCaption';
import CuePoint from '../../../client/cuePoint';
import { Chapter } from '../../../client/sequence';
import { ChapterOrder } from '../../../client/sequence/chapter';
import { decodeHtml } from '../../../components/cue-points/editableTextItem';
import { calcPreviewCuePointsTimes, cuePointsState, incrementCuePointsVersion, uniqueSid } from '../../../state/cuePoints';
import { currentTimeState } from '../../../state/playback';
import { getNextTempChapter, scenesState, sequenceState, tempScenesState } from '../../../state/sequence';
import { trackEvent } from "../../../utils/analityics.utils";
import { BLANK_REPLACMENT_BEGIN_TIME_MS, BLANK_REPLACMENT_END_TIME_MS, BLANK_THRESHOLD, handleCuts } from '../Editors/EditorContent';
import WordElement from '../Elements/WordElement';

const PROJECT_ROUTE = 'project'

interface ChapterClosedCaptionsProps {
    closedCaption: any;
    chapters: Chapter[] | ChapterOrder[];
    selectedWords: Word[];
    resetSelection(): void;
    HighlightSentence(word: Word): void;
    highlightedWord: Word;
    className: string;
    chapterSid: string;
    isBlurable: React.MutableRefObject<boolean>;
    contentWrapperRef: React.RefObject<HTMLDivElement>;
    experimentalKeepBlankWhenCutWord? : boolean;

}
export default function ChapterClosedCaptions({ 
    chapters, 
    closedCaption, 
    selectedWords, 
    resetSelection, 
    HighlightSentence, 
    highlightedWord, 
    chapterSid, 
    isBlurable, 
    contentWrapperRef, 
    scrollVersion,
    experimentalKeepBlankWhenCutWord
}: ChapterClosedCaptionsProps) {
    const sequence = sequenceState.use();
    const tempScenes = tempScenesState.use(scenes => scenes.map)
    const chapter = chapters?.find(c => (c.sid || c.cid) === chapterSid)
    const getActiveWords = (chapter: Chapter): Word[] => closedCaption?.words.filter(w => chapter
        && (!chapter.clipFrom || w.startTime / 1000 >= chapter.clipFrom)
        && w.startTime / 1000 <= (chapter.clipFrom ? chapter.clipFrom + chapter.duration : chapter.duration)
    ).sort((a, b) => a.startTime - b.startTime);

    const [activeWords, setActiveWords] = React.useState<Word[]>(getActiveWords(chapter))
    const editableSpan = React.useRef<HTMLSpanElement>(null)
    const currentTime = currentTimeState.use()

    const wordsLength = closedCaption?.words?.length
    const nextChapter = getNextTempChapter(chapter)
    const wordsToRemove = [], wordsToUpdate = []

    const location = useLocation()
    const isOnSequencePage = (location.pathname.replaceAll('/', '') === PROJECT_ROUTE && location.hash)

    React.useEffect(() => setActiveWords(getActiveWords(chapter)), [closedCaption, wordsLength, chapter?.clipFrom, chapter?.duration, chapter])


    function diffChanges(newText) {
        console.log(newText)
        console.log(activeWords)

        if (!selectedWords.length) {
            const word = new Word();
            word.origin = 'none';
            word.word = newText;
            word.startTime = Math.round(closedCaption.startTime);
            word.endTime = Math.round(closedCaption.endTime);
            word.chapterSid = closedCaption.chapterSid;
            word.newLine = true;
            word.manualTiming = true;
            return [word]
        }

        const text = selectedWords.map(w => w.word).join(' ');
        const diffs = diffChars(text, newText);
        const saveWords = selectedWords;
        saveWords.forEach((w, i) => {
            delete w.added;
            delete w.removed;
            w.offset = (i ? (saveWords[i - 1].offset + saveWords[i - 1].word.length + 1) : 0)
        });

        var offset = 0;
        for (var d of diffs) {
            if (d.removed) {
                var removedWords = d.value.split(/\s/);
                let index = saveWords.findIndex(w => w.offset > offset) - 1
                if (index === -2) {
                    index = saveWords.length - 1;
                }
                let localOffset = offset;
                for (var i = 0; i < removedWords.length; i++) {
                    var removedWord = removedWords[i];
                    let word = saveWords[index]

                    if (i && index) {
                        word.mergePrev = saveWords[index - 1];
                    }
                    index++;

                    if (!removedWord) {
                        continue;
                    }
                    let charIndex = localOffset + i - word.offset + (word.added || 0) - (word.removed || 0);
                    wordsToRemove.push({ word: word.word, startTime: word.startTime, chapterSid: word.chapterSid });
                    word.origin = word.values.origin || word.word
                    word.word = word.word.substr(0, charIndex) + word.word.substr(charIndex + removedWord.length)
                    localOffset += removedWord.length;
                    word.removed = (word.removed || 0) + removedWord.length;
                    word.highlight = false

                }
                offset += d.count;
            }
            else if (d.added) {
                let index = saveWords.findIndex(w => w.offset > offset) - 1
                if (index === -2) {
                    index = saveWords.length - 1;
                }
                let word = saveWords[index]
                let charIndex = offset - word.offset + (word.added || 0) - (word.removed || 0);
                let prevWord = word.word
                word.origin = word.values.origin || word.word
                word.word = word.word.substr(0, charIndex) + d.value + word.word.substr(charIndex)
                word.added = (word.added || 0) + d.count;
                word.highlight && wordsToUpdate.push({ word: word.word, origin: prevWord, chapterSid, chapterSid: word.chapterSid, startTime: word.startTime })

            }
            else {
                offset += d.count;
            }
        }

        console.log('DIFF', { text, newText, saveWords, Changes: text !== newText })
        return text === newText ? [] : saveWords;
    }

    async function acceptTextChange(text: string) {
        const newText = decodeHtml(text).replace(/[\s\r\n]+/g, ' ').trim()
        var empty = false;
        var saveWords;

        if (newText) {
            saveWords = diffChanges(newText);
        }
        else {
            empty = true;
            selectedWords.forEach(w => {
                w.origin = w.word;
                w.word = '';
            });

            saveWords = selectedWords;
        }

        var wordsToSplit = saveWords.filter(w => w.word.match(/\s/))
        if (wordsToSplit.length) {
            wordsToSplit.forEach(w => {
                var newLine = w.newLine
                var startTime = w.startTime
                var words = w.word.split(/\s+/)
                var splitTime = Math.floor(
                    (w.endTime - startTime) / words.length
                )
                w.endTime = Math.round(startTime + splitTime)
                w.word = words.shift().trim()
                if (words.length) {
                    w.newLine = false
                } else if (newLine) {
                    w.newLine = true
                }
                while (words.length) {
                    var word = new Word().set(JSON.parse(JSON.stringify(w)))
                    startTime += splitTime
                    word.origin = null
                    word.manualTiming = true
                    word.startTime = Math.round(startTime)
                    word.endTime = Math.round(startTime + splitTime)
                    word.word = words.shift().trim()
                    if (words.length) {
                        w.newLine = false
                    } else if (newLine) {
                        word.newLine = true
                    }
                    saveWords.push(word)
                }
            })
        }

        saveWords.sort((a, b) => a.startTime - b.startTime)

        const changedWords = saveWords.filter(w => w.changedProperties.length);
        trackEvent('closed-caption-change-content-editor', { changedWords: changedWords.length });
        resetSelection()
        await saveChanges(changedWords, true);
        calcPreviewCuePointsTimes(sequence, tempScenes)
    }

    function markAsDeleted(e: MouseEvent) {
        const indexOfFirst = activeWords.indexOf(selectedWords[0])
        const indexOfLast = activeWords.indexOf(selectedWords.at(-1))
        const tempChapterSid = `temp-${uniqueSid()}`
        const nextChapterSid = nextChapter && (nextChapter.sid || nextChapter.cid)

        if (selectedWords.length === activeWords.length) {
            // Case: delete all chapter
            console.log('mark chapter as deleted')
            if (isOnSequencePage) {
                // if current chapter has linkSid we need to remove it from tempScenesState 
                // when all words cut out, also needs to remove in reorder scenes
                if (chapter?.linkSid) {
                    console.log('delete virtual chapter from temp array')
                    tempScenesState.set(scenes => {
                        scenes.map[chapterSid].index = null;
                        scenes.map[chapterSid].duration = null;
                        scenes.map[chapterSid].clipFrom = null;
                        scenes._clearCache()
                        return scenes
                    })
                } else {
                    tempScenesState.set(scenes => {
                        scenes.map[chapterSid].duration = 0.001
                        scenes.map[chapterSid].clipFrom = null
                        scenes._clearCache()
                        return scenes
                    })
                   
                }
            }
            else if (nextChapter) {
                tempScenesState.set(scenes => {
                    Object.keys(scenes.scenes).map(chapterSid => {
                        if (scenes.map[chapterSid].index > nextChapter.index) {
                            scenes.map[chapterSid].index -= 1
                        }
                    })
                    const { clipFrom, clipTo, duration } = nextChapter
                    scenes.map[chapterSid].clipFrom = clipFrom
                    scenes.map[chapterSid].clipTo = clipTo
                    scenes.map[chapterSid].duration = duration
                    scenes.map[nextChapterSid].index = null
                    scenes.map[nextChapterSid].duration = null
                    scenes._clearCache()
                    return scenes
                })
            } else {
                if (chapter?.linkSid) {
                    console.log('delete virtual chapter from temp array')
                    tempScenesState.set(scenes => {
                        Object.keys(scenes.scenes).map(chapterSid => {
                            if (scenes.map[chapterSid].index > chapter.index) {
                                scenes.map[chapterSid].index -= 1
                            }
                        })
                        scenes.map[chapterSid].index = null;
                        scenes.map[chapterSid].duration = null;
                        scenes.map[chapterSid].clipFrom = null;
                        scenes._clearCache()
                        return scenes
                    })

                } else {
                    tempScenesState.set(scenes => {
                        Object.keys(scenes.scenes).map(chapterSid => {
                            if (scenes.map[chapterSid].index > chapter.index) {
                                scenes.map[chapterSid].index -= 1
                            }
                        })
                        scenes.map[chapterSid].duration = 0.001
                        scenes.map[chapterSid].clipFrom = null
                        scenes._clearCache()
                        return scenes
                    })
                   
                }
            }
            return resetSelection()
        }

        if (selectedWords[0] === activeWords[0] && (selectedWords[0].startTime === 0 || !experimentalKeepBlankWhenCutWord )) {
            //Case: delete from start
            console.log('mark chapter words as deleted from start', selectedWords[0].startTime)
            const clipFrom = (selectedWords.at(-1).endTime + activeWords[indexOfLast + 1].startTime - 2) / 2000
            tempScenesState.set(scenes => {
                scenes.map[chapterSid].sourceDuration = (chapter.sourceDuration || chapter.duration)
                scenes.map[chapterSid].duration = (chapter.duration + (chapter.clipFrom || 0)) - clipFrom
                scenes.map[chapterSid].clipFrom = clipFrom
                scenes._clearCache()
                return scenes
            })
            return resetSelection()
        }
        if (selectedWords.at(-1) === activeWords.at(-1)) {
            // Case: delete from end
            console.log('mark chapter words as deleted from end')
            const clipTo = (selectedWords[0].startTime + activeWords[indexOfFirst - 1].endTime - 2) / 2000
            tempScenesState.set(scenes => {
                scenes.map[chapterSid].duration = clipTo - (chapter.clipFrom || 0)
                scenes.map[chapterSid].clipTo = clipTo
                scenes._clearCache()
                return scenes
            })
            return resetSelection()
        }

        // Case : delete middle of the chapter
        console.log('mark chapter words as deleted in between')
        tempScenesState.set(scenes => {
            const clipFrom = (selectedWords.at(-1).endTime + activeWords[indexOfLast + 1].startTime - 2) / 2000;
            let clipTo;
            // keep blank at the begging of the video 
            if(experimentalKeepBlankWhenCutWord && indexOfFirst === 0) {
                console.log('keep blank at the begging of the video')
                clipTo = (selectedWords[0].startTime - 2) / 1000;
            } else{
               clipTo = (selectedWords[0].startTime + activeWords[indexOfFirst - 1].endTime - 2) / 2000;
            }

            Object.keys(scenes.scenes).map(chapterSid => {
                if (scenes.map[chapterSid]?.index > chapter.index) {
                    scenes.map[chapterSid].index += 1
                }
            })

            const chapterClipTo = chapter.clipTo || chapter.duration + (chapter.clipFrom || 0)
            scenes.map[tempChapterSid] = new ChapterOrder()
            scenes.map[tempChapterSid].clipFrom = clipFrom
            scenes.map[tempChapterSid].linkSid = chapter?.linkSid || chapterSid
            scenes.map[tempChapterSid].index = chapter.index + 1
            scenes.map[tempChapterSid].clipTo = chapterClipTo
            scenes.map[tempChapterSid].duration = chapterClipTo - clipFrom
            scenes.map[tempChapterSid].sourceDuration = chapter?.srcDuration || chapter?.sourceDuration
            scenes.map[tempChapterSid].cid = tempChapterSid

            scenes.map[chapterSid].clipTo = clipTo
            scenes.map[chapterSid].duration = clipTo - (chapter.clipFrom || 0)
            scenes._clearCache()
            return scenes
        })

        resetSelection()
    }

    async function saveChanges(words, removeAlts) {
        if (!words.length) {
            return
        }
        const alts = (closedCaption.alts || []).filter(alt => removeAlts || words.find(w => w.startTime === alt.startTime || w.endTime === alt.endTime))
        alts.forEach(alt => {
            alt.origin = alt.word
            alt.word = ''
        })
        words.forEach(w => {
            delete w.alts;
        })

        /**
         * @type {Word[]}
         */
        const update = [...words, ...alts].filter(w => w.word !== undefined)

        if (!update.length) {
            return;
        }
        update.forEach(
            w =>
            (w.changedProperties = [
                ...w.changedProperties,
                'startTime',
                'endTime',
                'chapterSid',
                'score',
                'word',
                'excluded',
                'newLine',
                'origin'
            ])
        )
        console.log('Save words', update)

        cuePointsState.set(cuePoints => {
            const cuePoint = Object.values(cuePoints)
                .find(cp => cp.type === CuePoint.TYPE.CLOSED_CAPTION && cp.words.includes(selectedWords[0]))
            const firstClosedCaption = Object.values(cuePoints)
                .find(cp => cp.type === CuePoint.TYPE.CLOSED_CAPTION)
            for (const word of words) {
                (cuePoint || firstClosedCaption).words.find(w => w === word) || ((cuePoint || firstClosedCaption).words.push(word))
            }
            incrementCuePointsVersion()
            return cuePoints
        })

        await ClosedCaption.updateWords(sequence.sid, update)
        closedCaption.alts = (closedCaption.alts || []).filter(alt => !alts.find(a => a === alt));

        closedCaption.words.forEach(w => {
            delete w.values.origin;
            w.changedProperties = [];
        });
        closedCaption.words.sort((a, b) => a.startTime - b.startTime);
        closedCaption.tmpText = null;
    }

    function splitQuietSpace(event: MouseEvent, i: number, activeChapterSid?: string) {
        console.log("splitQuietSpace", i, activeChapterSid)

        const tempChapterSid = `temp-${uniqueSid()}`

        event.stopPropagation()
        tempScenesState.set(scenes => {
            if (activeChapterSid) {
                const shouldCutNext = activeChapterSid.includes('--next')
                const chapterSid = activeChapterSid.replace('--next', '')
                const activeChapter = scenes.map[chapterSid]
                const clipFrom = (scenes.map[chapterSid].clipFrom || 0)
                if (activeChapter) {
                    const durationGap = shouldCutNext ?
                        clipFrom + chapter?.duration - activeWords[i].endTime / 1000 :
                        activeWords[i].startTime / 1000 - clipFrom
                    scenes.map[chapterSid].duration -= durationGap
                    scenes.map[chapterSid].clipFrom = shouldCutNext ?
                        scenes.map[chapterSid].clipFrom :
                        activeWords[i].startTime / 1000
                    scenes._clearCache()
                }
                return scenes
            }
            const clipTo = activeWords[i].endTime / 1000 + BLANK_REPLACMENT_BEGIN_TIME_MS / 1000
            const clipFrom = activeWords[i + 1].startTime / 1000 - BLANK_REPLACMENT_END_TIME_MS / 1000
            const chapterClipTo = chapter.clipTo || chapter.duration + (chapter.clipFrom || 0)

            Object.keys(scenes.scenes).map(chapterSid => {
                if (scenes.map[chapterSid]?.index > chapter.index) {
                    scenes.map[chapterSid].index += 1
                }
            })

            scenes.map[chapterSid].clipTo = clipTo
            scenes.map[chapterSid].duration = clipTo - (chapter.clipFrom || 0)

            scenes.map[tempChapterSid] = new ChapterOrder()
            scenes.map[tempChapterSid].clipFrom = clipFrom
            scenes.map[tempChapterSid].linkSid = chapter?.linkSid || chapterSid
            scenes.map[tempChapterSid].index = chapter.index + 1
            scenes.map[tempChapterSid].clipTo = chapterClipTo
            scenes.map[tempChapterSid].duration = chapterClipTo - clipFrom
            scenes.map[tempChapterSid].sourceDuration = chapter?.srcDuration || chapter?.sourceDuration
            scenes.map[tempChapterSid].cid = tempChapterSid
            scenes._clearCache()
            return scenes
        })


    }

    React.useEffect(() => {
        if (editableSpan.current) {
            editableSpan.current.focus()
        }
    }, [selectedWords])

    const offset = (tempScenes.array || []).filter(c => c && c.duration && c.index < chapter.index).reduce((total, curr) => total += curr.duration, -(chapter?.clipFrom || 0)) * 1000

    const wordClassNames = (word: Word) => classNames({ 'hovered': highlightedWord === word, 'on-time': chapter && (currentTime * 1000 >= offset + word.startTime && currentTime * 1000 <= offset + word.endTime) })

    const shouldShowBlankBefore = (i: number) => !activeWords[i - 1] && activeWords[i].startTime - (chapter.clipFrom || 0) * 1000 > BLANK_THRESHOLD
    const shouldShowBlank = (i: number) => (activeWords[i + 1] && activeWords[i + 1].startTime - activeWords[i].endTime > BLANK_THRESHOLD) ||
        (i === activeWords.length - 1 && ((chapter.clipFrom || 0) + chapter.duration) * 1000 - activeWords[i].endTime > BLANK_THRESHOLD) && chapterSid

    // needed for build time
    if (!activeWords) {
        return null;
    }

    return <React.Fragment>
        {activeWords.map((word, i) => <WordElement
            key={i}
            className={wordClassNames(word)}
            selectedWords={selectedWords}
            word={word}
            activeWords={activeWords}
            handleDeletion={markAsDeleted}
            handleCuts={handleCuts}
            resetSelection={resetSelection}
            onMouseEnter={HighlightSentence}
            acceptTextChange={acceptTextChange}
            splitQuietSpace={splitQuietSpace}
            index={i}
            deleted={false}
            isBlurable={isBlurable}
            shouldShowBlank={shouldShowBlank(i)}
            shouldShowBlankBefore={shouldShowBlankBefore(i)}
            chapterSid={chapterSid}
            contentWrapperRef={contentWrapperRef}
            scrollVersion={scrollVersion}
        />)}
    </React.Fragment >
}