
import * as Base from './base/sequenceAccess';


export interface ISequenceAccess extends Base.ISequenceAccess {

}

export interface ISequenceAccessProperties extends Base.ISequenceAccessProperties {

}

export interface ISequenceAccessFilter extends Base.ISequenceAccessFilter {

}

export default class SequenceAccess<IType extends ISequenceAccess = ISequenceAccess, ITypeProperties extends ISequenceAccessProperties = ISequenceAccessProperties> extends Base.SequenceAccess<IType, ITypeProperties> implements ISequenceAccess {
}