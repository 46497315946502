
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'
import InvoiceList, { IInvoiceList } from '../invoiceList'
import Plan, { IPlan } from '../plan'
import PlanChange, { IPlanChange, IPlanChangeProperties } from '../subscription/planChange'

export enum SubscriptionStatus {
	PENDING = 1,
	ACTIVE = 1,
	INACTIVE = 2,
	DELETED = 3,
};

export enum SubscriptionChargeType {
	MONTHLY = 1,
	ANNUAL = 2,
};

export interface ISubscription extends IBase {
	status?: number | null;
	subscriptionId?: number | null;
	userSid?: string | null;
	orgSid?: string | null;
	nextChargeTime?: number | null;
	planSid?: string | null;
	recurringPrice?: number | null;
	chargeType?: SubscriptionChargeType | null;
	couponSid?: string | null;
	taxId?: string | null;
	billingAddress?: string | null;
	billingEmail?: string | null;
	billingName?: string | null;
	monthlyPeriodStart?: number | null;
	lastActivationDate?: number | null;
	isCanceled?: boolean | null;
	freeTrialRemainingTime?: number | null;
}

export interface ISubscriptionProperties extends IBaseProperties {
	status?: boolean;
	subscriptionId?: boolean;
	userSid?: boolean;
	orgSid?: boolean;
	nextChargeTime?: boolean;
	planSid?: boolean;
	recurringPrice?: boolean;
	chargeType?: boolean;
	couponSid?: boolean;
	taxId?: boolean;
	billingAddress?: boolean;
	billingEmail?: boolean;
	billingName?: boolean;
	monthlyPeriodStart?: boolean;
	lastActivationDate?: boolean;
	isCanceled?: boolean;
	freeTrialRemainingTime?: boolean;
}

export interface ISubscriptionFilter extends IBaseFilter {
	userSidEqual?: string;
}

export class Subscription<IType extends ISubscription = ISubscription, ITypeProperties extends ISubscriptionProperties = ISubscriptionProperties> extends Base<IType, ITypeProperties> implements ISubscription {
	
	static className = 'Subscription';
	static NAME = 'subscription';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		subscriptionId: true,
		userSid: true,
		orgSid: true,
		nextChargeTime: true,
		planSid: true,
		recurringPrice: true,
		chargeType: true,
		couponSid: true,
		taxId: true,
		billingAddress: true,
		billingEmail: true,
		billingName: true,
		monthlyPeriodStart: true,
		lastActivationDate: true,
		isCanceled: true,
		freeTrialRemainingTime: true,
	};
	
	/**
	 * @deprecated use SubscriptionStatus instead
	 */
	static STATUS: any = {
		PENDING: SubscriptionStatus.PENDING,
		ACTIVE: SubscriptionStatus.ACTIVE,
		INACTIVE: SubscriptionStatus.INACTIVE,
		DELETED: SubscriptionStatus.DELETED,
	};
	
	/**
	 * @deprecated use SubscriptionChargeType instead
	 */
	static CHARGE_TYPE: any = {
		MONTHLY: SubscriptionChargeType.MONTHLY,
		ANNUAL: SubscriptionChargeType.ANNUAL,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get subscriptionId(): number | null | undefined {
		return this.values.subscriptionId
	}
			
	get userSid(): string | null | undefined {
		return this.values.userSid
	}
			
	set userSid(value: string | null | undefined) {
		if(this.values.userSid !== value) {
			this.propertyChanged('userSid', value);
			this.values.userSid = value;
		}
	}
			
	get orgSid(): string | null | undefined {
		return this.values.orgSid
	}
			
	set orgSid(value: string | null | undefined) {
		if(this.values.orgSid !== value) {
			this.propertyChanged('orgSid', value);
			this.values.orgSid = value;
		}
	}
			
	get nextChargeTime(): number | null | undefined {
		return this.values.nextChargeTime
	}
			
	get planSid(): string | null | undefined {
		return this.values.planSid
	}
			
	get recurringPrice(): number | null | undefined {
		return this.values.recurringPrice
	}
			
	get chargeType(): SubscriptionChargeType | null | undefined {
		return this.values.chargeType
	}
			
	set chargeType(value: SubscriptionChargeType | null | undefined) {
		if(this.values.chargeType !== value) {
			this.propertyChanged('chargeType', value);
			this.values.chargeType = value;
		}
	}
			
	get couponSid(): string | null | undefined {
		return this.values.couponSid
	}
			
	get taxId(): string | null | undefined {
		return this.values.taxId
	}
			
	set taxId(value: string | null | undefined) {
		if(this.values.taxId !== value) {
			this.propertyChanged('taxId', value);
			this.values.taxId = value;
		}
	}
			
	get billingAddress(): string | null | undefined {
		return this.values.billingAddress
	}
			
	set billingAddress(value: string | null | undefined) {
		if(this.values.billingAddress !== value) {
			this.propertyChanged('billingAddress', value);
			this.values.billingAddress = value;
		}
	}
			
	get billingEmail(): string | null | undefined {
		return this.values.billingEmail
	}
			
	set billingEmail(value: string | null | undefined) {
		if(this.values.billingEmail !== value) {
			this.propertyChanged('billingEmail', value);
			this.values.billingEmail = value;
		}
	}
			
	get billingName(): string | null | undefined {
		return this.values.billingName
	}
			
	set billingName(value: string | null | undefined) {
		if(this.values.billingName !== value) {
			this.propertyChanged('billingName', value);
			this.values.billingName = value;
		}
	}
			
	get monthlyPeriodStart(): number | null | undefined {
		return this.values.monthlyPeriodStart
	}
			
	get lastActivationDate(): number | null | undefined {
		return this.values.lastActivationDate
	}
			
	get isCanceled(): boolean | null | undefined {
		return this.values.isCanceled
	}
			
	get freeTrialRemainingTime(): number | null | undefined {
		return this.values.freeTrialRemainingTime
	}
			
	
	static get<IType extends ISubscription = ISubscription>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends ISubscriptionFilter = ISubscriptionFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends ISubscriptionFilter = ISubscriptionFilter, IType extends ISubscription = ISubscription>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
	
	static async getHostedPaymentFieldToken(): Promise<string | null> {
		const query = `query{
			subscription {
				getHostedPaymentFieldToken
			}
		}`;
		var data = await Base.request(query);
		data = data.subscription || null;
		if(data && data.getHostedPaymentFieldToken !== undefined && data.getHostedPaymentFieldToken !== null) {
			return data.getHostedPaymentFieldToken;
		}
		return null;
	}
	
	static async invoices(page: number, pageSize: number, properties: IBaseProperties | null = null): Promise<InvoiceList | null> {
		properties = (properties || InvoiceList.PROPERTIES) as IBaseProperties;
		const query = `query{
			subscription {
				invoices(page: ${page !== undefined ? `${JSON.stringify(page)}` : 'null'}, pageSize: ${pageSize !== undefined ? `${JSON.stringify(pageSize)}` : 'null'}){${Base.toProperties(properties)}}
			}
		}`;
		var data = await Base.request(query);
		data = data.subscription || null;
		if(data && data.invoices !== undefined && data.invoices !== null) {
			var ret = new InvoiceList(data.invoices.sid);
			return ret.set(data.invoices) as InvoiceList;
		}
		return null;
	}
	
	async plan(properties: ITypeProperties | null = null): Promise<Plan | null> {
		properties = (properties || Plan.PROPERTIES) as ITypeProperties;
		var action = `plan{${Base.toProperties(properties)}}`;
		const query = this.wrap('query', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.plan !== undefined && data.plan !== null) {
			var ret = new Plan(data.plan.sid);
			return ret.set(data.plan) as Plan;
		}
		return null;
	}
	
	async footageUploadsCount(): Promise<number | null> {
		var action = `footageUploadsCount`;
		const query = this.wrap('query', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.footageUploadsCount !== undefined && data.footageUploadsCount !== null) {
			return data.footageUploadsCount;
		}
		return null;
	}
	
	async cancel(): Promise<boolean | null> {
		var action = `cancel`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.cancel !== undefined && data.cancel !== null) {
			return data.cancel;
		}
		return null;
	}
}
