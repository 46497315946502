import React from 'react'
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';

import '../../css/styles.scss'
// import '../../css/support.scss'

/**
 * 
 * @param {{
 *  onHide: Function,
 * }} props
 * @returns 
 */

export default function SupportModal({show, onHide }) {



    return <Modal
        show={show}
        onHide={onHide}
        className="support"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ paddingRight: 0, paddingLeft: 0 }}
    >
        <ModalBody className="support-modal-body">
            <button className='btn btn-close' onClick={onHide} />
            <div className="panel panel-primary">
                <div className="panel-heading" style={{ paddingBottom: '20px' ,justifyContent: "center"}}>
                    <h2 style={{ margin: '40px 8% 0 8%' }}>We are here to help</h2>
                </div>
                <div>
                    <iframe className="support-iframe" src="https://share.hsforms.com/1hfImOfd7QbaZhSgsX6ixVQbuyiu"></iframe>
                </div>
            </div>
        </ModalBody>
    </Modal>
}