
import * as Base from './base/color';


export interface IColor extends Base.IColor {

}

export interface IColorProperties extends Base.IColorProperties {

}

export interface IColorFilter extends Base.IColorFilter {

}

export default class Color<IType extends IColor = IColor, ITypeProperties extends IColorProperties = IColorProperties> extends Base.Color<IType, ITypeProperties> implements IColor {
    cid?: string;
}