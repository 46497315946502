import React from 'react';

import styled from '@emotion/styled';
import { COLORS } from '../../../../constants/colors';

import { sequenceState } from '../../../state/sequence';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button, { ButtonVariant } from '../../../ui-components/Button';
import Loader from '../../../ui-components/Loader';
import { AspectRatio } from '../../../ui-components/SequenceCloneOptions/useSequenceCloneOptions';
import {
  CaptionsBreakType,
  CaptionsFormatType,
  SUBTITLE_FORMAT_PRESETS,
  SubtitleFormatName,
  SubtitleFormatPreset,
} from '../../../utils/closeCpation.utils';
import SVG from '../../svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  padding-top: 14px;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;

  svg {
    width: 20px;
    height: 20px;
    margin-top: 5px;
    margin-left: 5px;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;

  label {
    display: flex;
    align-items: center;
  }

  input {
    margin-right: 10px;
    accent-color: ${COLORS.peech_primary_1_500};
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`;

const NumberInput = styled.input`
  width: 30px !important;
  height: 20px !important;
  padding-inline: 2px important;
  text-align: center;
  font-size: 11px;
  margin: 0;
  border: 1px solid ${COLORS.peech_gray_300};
  border-radius: 5px;
  -moz-appearance: textfield;
  appearance: none;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const PunctuationDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;

  input {
    margin-right: 10px;
    margin-bottom: 4px;
    accent-color: ${COLORS.peech_primary_1_500};
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    padding-top: 10px;
  }
`;

const RearrangeButton = styled(Button)`
  width: 100% !important;
  height: 30px !important;
  font-size: 14px !important;
`;

interface AdvancedCaptionsFormatterProps {
  aspectRatio: AspectRatio;
  rearrangeCaptions: (captionsFormatValues: CaptionsFormatType) => void;
  isCaptionsReformatting: boolean;
}

export default function AdvancedCaptionsFormatter({
  aspectRatio,
  rearrangeCaptions,
  isCaptionsReformatting,
}: AdvancedCaptionsFormatterProps) {
  const sequence = sequenceState.use();
  const advancedDefaultValues = SUBTITLE_FORMAT_PRESETS[aspectRatio][SubtitleFormatName.Advanced].values;

  if (!advancedDefaultValues) return null;

  const [captionSettings, setCaptionSettings] = React.useState({
    subtitleDurationSeconds: advancedDefaultValues[CaptionsBreakType.Time].default / 1000,
    subtitleDurationWords: advancedDefaultValues[CaptionsBreakType.Words].default,
    breakType: SUBTITLE_FORMAT_PRESETS[aspectRatio][SubtitleFormatName.Advanced].breakType,
    shouldBreakByPunctuationMark:
      SUBTITLE_FORMAT_PRESETS[aspectRatio][SubtitleFormatName.Advanced].shouldBreakByPunctuationMark,
  });

  React.useEffect(() => {
    try {
      const savedData = JSON.parse(sessionStorage.getItem(`advancedCaptionsFormat_${sequence.sid}`) || '');
      if (savedData) {
        setCaptionSettings(savedData);
      }
    } catch (error) {
      console.error('Error parsing JSON data:', error);
    }
  }, [sequence]);

  const handleRearrange = () => {
    if (!captionSettings.breakType) return;
    const value =
      captionSettings.breakType === CaptionsBreakType.Time
        ? captionSettings.subtitleDurationSeconds * 1000
        : captionSettings.subtitleDurationWords;

    rearrangeCaptions({
      formatName: SubtitleFormatName.Advanced,
      formatPreset: {
        breakType: captionSettings.breakType,
        value,
        shouldBreakByPunctuationMark: captionSettings.shouldBreakByPunctuationMark,
      },
    });

    sessionStorage.setItem(`advancedCaptionsFormat_${sequence.sid}`, JSON.stringify(captionSettings));
  };

  const handleMouseEnter = (e) => {
    e.target.blur(); // Remove focus on hover
  };

  return (
    <Container>
      {isCaptionsReformatting ? (
        <LoadingWrapper>
          <Loader size="lg" />
          <span>Generating style</span>
        </LoadingWrapper>
      ) : (
        <>
          <RadioGroup>
            <input
              type="radio"
              name="subtitleDuration"
              checked={captionSettings.breakType === CaptionsBreakType.Time}
              onChange={() =>
                setCaptionSettings((prevSettings) => ({
                  ...prevSettings,
                  breakType: CaptionsBreakType.Time,
                }))
              }
            />
            <label>
              <NumberInput
                type="number"
                value={captionSettings.subtitleDurationSeconds}
                onChange={(e) => {
                  const min = advancedDefaultValues?.[CaptionsBreakType.Time].min / 1000;
                  const max = advancedDefaultValues[CaptionsBreakType.Time].max / 1000;
                  const value = parseFloat(e.target.value);
                  if (value > max || value < min) {
                    e.preventDefault();
                  } else {
                    setCaptionSettings((prevSettings) => ({
                      ...prevSettings,
                      subtitleDurationSeconds: value,
                    }));
                  }
                }}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setCaptionSettings((prevSettings) => ({
                      ...prevSettings,
                      subtitleDurationSeconds: advancedDefaultValues[CaptionsBreakType.Time].default / 1000,
                    }));
                  }
                }}
                step="0.05"
                min={advancedDefaultValues?.[CaptionsBreakType.Time].min / 1000}
                max={advancedDefaultValues[CaptionsBreakType.Time].max / 1000}
                onMouseEnter={handleMouseEnter}
              />
              Max Seconds (Per Subtitle)
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="max-seconds-tooltip" className="tooltip-peech-light" style={{ maxWidth: '240px' }}>
                    Each subtitle is displayed for a certain amount of time with varying amount of words
                  </Tooltip>
                }
              >
                <SVG name="info-tooltip" viewBox="0 0 22 22" />
              </OverlayTrigger>
            </label>
          </RadioGroup>
          <RadioGroup>
            <input
              type="radio"
              name="subtitleDuration"
              checked={captionSettings.breakType === CaptionsBreakType.Words}
              onChange={() =>
                setCaptionSettings((prevSettings) => ({
                  ...prevSettings,
                  breakType: CaptionsBreakType.Words,
                }))
              }
            />
            <label>
              <NumberInput
                type="number"
                value={captionSettings.subtitleDurationWords}
                onChange={(e) => {
                  const min = advancedDefaultValues[CaptionsBreakType.Words].min;
                  const max = advancedDefaultValues[CaptionsBreakType.Words].max;
                  const value = parseInt(e.target.value);
                  if (value > max || value < min) {
                    e.preventDefault();
                  } else {
                    setCaptionSettings((prevSettings) => ({
                      ...prevSettings,
                      subtitleDurationWords: value,
                    }));
                  }
                }}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setCaptionSettings((prevSettings) => ({
                      ...prevSettings,
                      subtitleDurationWords: advancedDefaultValues[CaptionsBreakType.Words].default,
                    }));
                  }
                }}
                min={advancedDefaultValues[CaptionsBreakType.Words].min}
                max={advancedDefaultValues[CaptionsBreakType.Words].max}
                onMouseEnter={handleMouseEnter}
              />
              Max Words (Per Subtitle)
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="max-words-tooltip" className="tooltip-peech-light" style={{ maxWidth: '240px' }}>
                    Each subtitle is displayed for a certain amount of words for a varying duration of time
                  </Tooltip>
                }
              >
                <SVG name="info-tooltip" viewBox="0 0 22 22" />
              </OverlayTrigger>
            </label>
          </RadioGroup>
          <PunctuationDiv>
            <input
              type="checkbox"
              id="breakLineCheckbox"
              checked={captionSettings.shouldBreakByPunctuationMark}
              onChange={() =>
                setCaptionSettings((prevSettings) => ({
                  ...prevSettings,
                  shouldBreakByPunctuationMark: !prevSettings.shouldBreakByPunctuationMark,
                }))
              }
            />
            <label htmlFor="breakLineCheckbox">
              Break line on Punctuation marks
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="punctuation-tooltip" className="tooltip-peech-light" style={{ maxWidth: '240px' }}>
                    Punctuation marks will split subtitles. (Question mark, Comma, Exclamation mark, Full stop)
                  </Tooltip>
                }
              >
                <SVG name="info-tooltip" viewBox="0 0 22 22" />
              </OverlayTrigger>
            </label>
          </PunctuationDiv>
          <RearrangeButton onClick={() => handleRearrange()} size="lg" variant={ButtonVariant.Primary} disabled={false}>
            Rearrange
          </RearrangeButton>
        </>
      )}
    </Container>
  );
}
