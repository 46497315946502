import { navigate } from 'gatsby';
import React from 'react';
import { Card, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CANCEL_ICON from '../../static/svg/cancelPlan.svg';
import { CardOther, CardPro, CardTeams } from '../../static/svg/plan-icons';
import { Invoice } from '../client/invoiceList';
import Plan from '../client/plan';
import Shopper from '../client/shopper';
import Subscription from '../client/subscription';
import User from '../client/user';
import PaymentForm from '../components/bluesnap-payment-form';
import SVG from '../components/svg';
import { AppContext } from '../lib/app-context';
import { sequencesSinceLastChargeState, trialRestState } from '../state/local';
import Button from '../ui-components/Button';
import Loader from '../ui-components/Loader';
import Tabs from '../ui-components/Tabs/Tabs';
import { trackEvent } from '../utils/analityics.utils';
import calculatePlanPrices from '../utils/calculatePlanPrices';
import ShutterstockLogo from './plans/ShutterstockLogo';
import { updatePaymentMethod } from '../lib/paddle';
import moment from 'moment';
import { PlugLoading } from '../ui-components/PlugLoading/PlugLoading';
import {isInIframe} from "../utils/window.utils";
import useUserPs from '../hooks/useUserPs';
import { getBalance } from '../utils/balance.utils';
import { set } from 'lodash';

export const CARD_TYPE = {
  AMEX: 'american-express',
  CHINA_UNION_PAY: 'union-pay',
  DINERS: 'diners',
  JCB: 'jcb',
  MASTERCARD: 'mastercard',
  MASTER: 'mastercard',
  VISA: 'visa',
  ELO: 'elo',
  HIPERCARD: 'hipercard',
  NARANJA: 'naranja',
  CENCOSUD: 'cencosud',
  CABAL: 'cabal',
  ARGENCARD: 'argencard',
};

const PlanIcon = ({ cardPlanIcon }) => {
  if (cardPlanIcon === 'card-pro') return CardPro();
  if (cardPlanIcon === 'card-teams') return CardTeams();
  else return CardOther();
};

/**
 *
 * @param {{
 *  invoice: Invoice
 * }} props
 */
function InvoiceRow({ invoice }) {
  const { config } = React.useContext(AppContext);
  const withUserPs = useUserPs();
  return (
    <>
      <Row id={`invoice-${invoice.id}`} className="invoice">
        <Col className="invoice-date">{new Date(invoice.creationDate * 1000).toLocaleDateString()}</Col>
        <Col className="invoice-plan">{invoice.income && invoice.income.length && invoice.income[0].description}</Col>
        <Col className="invoice-amount">${invoice.amount}</Col>
        <Col className="invoice-download">
          <a
            data-number={invoice.number}
            className="invoice-link"
            target="_blank"
            href={withUserPs(`${config.CHUNKS_URL}/i/${invoice.id}/${invoice.number}.pdf`)}
          >
            <img src="/images/pdf.png" alt="Download PDF" title="Download PDF" />
          </a>
        </Col>
      </Row>
      <hr />
    </>
  );
}

/**
 *
 * @param {{
 *  user: User
 * }} props
 */
function BillingHistory({ user }) {
  const [items, setItems] = React.useState([]);

  React.useEffect(async () => {
    if (!user) {
      return;
    }

    const invoices = await Subscription.invoices(1, 100);
    setItems(invoices?.items);
  }, [user]);

  return (
    <div className="invoices">
      <Row>
        {/* <Col as="h4">#</Col> */}
        <Col as="h4">Date</Col>
        <Col as="h4">Description</Col>
        <Col as="h4">Total</Col>
        <Col as="h4">Reciept</Col>
      </Row>
      <hr />
      {items && items.map((item) => <InvoiceRow key={item.id} invoice={item} />)}
    </div>
  );
}

function DeleteConfirmation({ onHide, cancelPlan }) {
  return (
    <Modal show={true} className="plan-cancel" onHide={onHide} centered>
      <Modal.Body>
        <img className="cancel-icon" src={CANCEL_ICON} />
        <p className="plan-cancel-title">We’re sorry to see you’re going…</p>
        <p className="plan-subtitle">By cancelling, you will lose access to all of the projects you have</p>
        <div className="plan-buttons">
          <Button className="keep-button" variant="primary" onClick={onHide}>
            Keep Peeching!
          </Button>
          <Button className="cancel-button" variant="tertiary" id="project-delete-button" onClick={cancelPlan}>
            Cancel subscription
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Overview({ user, subscription, orgUsersCount }) {
  const { plan, setPlan } = React.useContext(AppContext);
  const [verifyDelete, setVerifyDelete] = React.useState(false);
  const [freePlan, setFreePlan] = React.useState();
  const [maximumMembers, setMaximumMembers] = React.useState(1);
  const [maximumVideos, setMaximumVideos] = React.useState(1);
  const [billingDate, setBillingDate] = React.useState(null);
  const [costPerMonth, setCostPerMonth] = React.useState(null);

  const sequencesSinceLastCharge = sequencesSinceLastChargeState.use();
  const trialRest = trialRestState.use();

  React.useEffect(() => {
    if (plan) {
      setMaximumMembers(plan.maxUsers ? plan.maxUsers : 1);
      setMaximumVideos(!plan.maxVideos ? 1 : plan.maxVideos >= 1000 ? 'unlimited' : plan.maxVideos);
    } else {
      setMaximumMembers(1);
      setMaximumVideos(1);
    }
  }, [plan]);

  React.useEffect(() => {
    if (plan && subscription) {
      let calculatedPrices = calculatePlanPrices(plan, subscription.chargeType);
      setCostPerMonth(calculatedPrices.costPerMonth);
    }
  }, [subscription, plan]);

  React.useEffect(async () => {
    const defaultPlan = await Plan.list({ statusEqual: Plan.STATUS.DEFAULT }, null, {
      sid: true,
      version: true,
      insertTime: true,
      updateTime: true,
      status: true,
      name: true,
      recurringPrice: true,
      overrideRecurringPrice: true,
      annualRecurringPrice: true,
      annualOverrideRecurringPrice: true,
      description: true,
      freeTrial: true,
      maxVideoDuration: true,
      maxVideos: true,
      maxUsers: true,
      maxStorage: true,
      brandsCount: true,
      icon: true,
      provider: true,
      planId: true,
      annualPlanId: true,
      showShutterStockBadge: true,
    });
    setFreePlan(defaultPlan[0]);
  }, []);

  React.useEffect(async () => {
    if (!user) {
      return;
    }
  }, [user]);

  async function cancelPlan() {
    try {
      const result = await subscription.cancel();
      setVerifyDelete(false);
      result && setBillingDate(null);
    } catch (err) {
      console.error(err);
    }
  }

  React.useEffect(() => {
    if (subscription) {
      if (subscription.isCanceled || !subscription.nextChargeTime) {
        setBillingDate(null);
      } else {
        const d = new Date(subscription.nextChargeTime);
        const _billingDate = d
          .toLocaleDateString('es-PA', {
            month: '2-digit',
            day: '2-digit',
            year: '2-digit',
          })
          .replaceAll('/', '.');
        setBillingDate(_billingDate);
      }
    }
  }, [subscription, trialRest]);

  React.useEffect(() => {
    user?.UIFLAGS.SMART_LIBRARY && window.Intercom && window.Intercom('shutdown');
  }, [window.Intercom]);


const [balance, setBalance] = React.useState(null);
React.useEffect(() => {
  // Define async function
  const fetchBalance = async () => {
    let balance = await getBalance();
    setBalance(balance);
  };

  // Call async function
  fetchBalance();
}, []);

  const uploadUsage = React.useMemo(() => {
    const footageUploadsLimit = plan?.maxFootageUploads || 0; 
    const footageUploadsCount = subscription &&  subscription.values && subscription.values.footageUploadsCount || 0;
    if(footageUploadsLimit === 0) return '∞';

    return `${footageUploadsCount || 0} / ${footageUploadsLimit}`
  }
  , [subscription, plan]);

  const downloadUsage = React.useMemo(() => {
    const {periodMaxDownloads, periodLeftDownloads} = balance || {};
    const downloadCount = periodMaxDownloads - periodLeftDownloads;
    if (periodMaxDownloads === 0 || periodMaxDownloads === null) return '∞';
    return `${downloadCount} / ${periodMaxDownloads}`;
  }
  , [balance]);

 

  return (
    <div className="overview-borde" style={{ opacity: subscription && plan ? 1 : 0 }}>
      <Loader style={{ display: subscription && plan ? 'none' : 'block' }} />
      <div className="left-side">
        <h4 className="overview-title">Plan Details</h4>

        <div className="plans-details">
          <div className="details-wrapper">
            <div className="current-plan">
              <span className="plans-title">Current plan</span>

              <h3 className="plans-name">{plan?.name}</h3>
            </div>

            <span className="plan-separation"></span>

            <div className="current-plan">
              <span className="plans-title">Next billing</span>
              <h3 className="plans-billing">{billingDate ? moment(billingDate).format('MMM D, YYYY') : '-'}</h3>
            </div>
          </div>

          <div className="upgrade-button-wrapper">
            <button
              data-cy="upgrade-plan-btn"
              className="btn bg-rainbow  btn-white"
              type="button"
              id="submit-purchase"
              onClick={() => {
                isInIframe() ? window.top.postMessage('OLD_APP_IFRAME_UPGRADE_BUTTON_CLICK', '*') : navigate('/plans');
              }}
              style={{
                borderRadius: '6px',
                width: '150px',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span className="upgrade-now">Upgrade now</span>
            </button>
          </div>
        </div>

        <h4 className="overview-title">Usage</h4>

        <div className="plans-details">
          <div className="details-wrapper">
            <div className="current-plan">
              <span className="plans-title">Uploads</span>
              <h3 className="plans-billing">
                {uploadUsage}
              </h3>
            </div>

            <span className="plan-separation" style={{ marginTop: '27px' }}></span>
            <div className="current-plan">
              <span className="plans-title">Downloads</span>
              <h3 className="plans-billing">
                { balance && downloadUsage}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="right-side">
        <h4 className="overview-title">My Plan</h4>
        <div data-cy="my-plan-card" className="my-plan-card">
          {plan?.showShutterStockBadge && (
            <div className="Shutterstock-wrapper">
              <div className="Shutterstock-logo">
                <ShutterstockLogo />
              </div>
            </div>
          )}
          <div className="plan-icon" style={{ marginBottom: 'unset' }}>
            <PlanIcon cardPlanIcon={plan?.icon} />
          </div>
          <h3 className="plan-type">{plan?.name}</h3>
          <span className="border" />
          <div className="plan-price">
            {/* ====================== TODO: change calculation ==================== */}

            {/* <h2 className='price'>{`$${plan?.overrideRecurringPrice || plan?.recurringPrice}`} <span className='per-subscription'>per month</span></h2>
             */}
            <h2
              data-cy={costPerMonth !== null && costPerMonth !== undefined ? 'choosen-plan-price' : ''}
              className="price"
            >
              ${costPerMonth !== null && costPerMonth !== undefined && Math.round(costPerMonth).toLocaleString()}
              <span className="per-subscription">per month</span>
            </h2>
          </div>

          <ul className="package-list">
            {plan?.values?.features?.map((featureName) => (
                <li className="package-item" key={featureName}>
               
                    <SVG name="mark-v" className="item-icon" width="12" viewBox="0 0 12 9"/>
                    <span>{featureName.charAt(0) === '*' ? featureName.slice(1) : featureName}</span>
                 
                </li>
            ))}
          </ul>
        </div>
        {verifyDelete && <DeleteConfirmation onHide={() => setVerifyDelete(false)} cancelPlan={cancelPlan} />}
        {plan?.status !== Plan.STATUS.DEFAULT && billingDate && (
          <div className="cancel-plane-button">
            <Button variant="ghost" className="cancel-btn" onClick={() => setVerifyDelete(true)}>
              Cancel Plan
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

function BillingPlanItem({ label, value }) {
  return (
    <Row className="billing-plan-item">
      <Col lg="3" className="label">
        {label}
      </Col>
      <Col lg="9">{value}</Col>
    </Row>
  );
}

/**
 *
 * @param {{
 *  user: User
 * }} props
 */
function BillingPlan({ user }) {
  /**
   * @type {Plan[]}
   */
  const [plan, setPlan] = React.useState();
  const [subscription, setSubscription] = React.useState();

  React.useEffect(async () => {
    if (!user) {
      return;
    }

    setSubscription(await Subscription.get(null, {
      ...Subscription.PROPERTIES,
      footageUploadsCount: true,
    }));

    setPlan(
      await Plan.get(null, {
        sid: true,
        name: true,
        recurringPrice: true,
        overrideRecurringPrice: true,
        annualRecurringPrice: true,
        annualOverrideRecurringPrice: true,
        description: true,
        freeTrial: true,
        maxVideoDuration: true,
        maxVideos: true,
        maxUsers: true,
        maxStorage: true,
        brandsCount: true,
        provider: true,
        planId: true,
        annualPlanId: true,
        showShutterStockBadge: true,
      })
    );
  }, [user]);

  if (!plan) {
    return <div />;
  }
  const price = subscription && subscription.recurringPrice;
  // const price = plan.overrideRecurringPrice
  // ? plan.overrideRecurringPrice
  // : plan.recurringPrice
  // ? plan.recurringPrice
  // : '0.00';

  return (
    <div className="billing-plan">
      <h2>Your Plan</h2>
      <h3>{plan.name}</h3>
      <hr />
      {subscription && !!subscription.recurringPrice && (
        <>
          <div>${subscription.recurringPrice} per month</div>
          <hr />
        </>
      )}
      <BillingPlanItem label="Number of videos" value="Unlimited" />
      {/* <BillingPlanItem label="Export' length" value={plan.maxVideoDuration} /> */}
      <BillingPlanItem label="Subtitles" value="Yes" />
      <BillingPlanItem label="Custom watermark" value="Yes" />
      <BillingPlanItem label="Stock Footage" value="Yes" />
      <BillingPlanItem
        label="Brands"
        value={
          plan.brandsCount > 1 ? plan.brandsCount + ' Brands' : plan.brandsCount === 1 ? '1 Brand' : 'Unlimited brands'
        }
      />
      <BillingPlanItem
        label="Cloud storage"
        value={plan.maxStorage ? `${plan.maxStorage} GB cloud storage` : 'Unlimited'}
      />
    </div>
  );
}

/**
 *
 * @param {{
 *  user: User
 * }} props
 */
function PaymentMethod({ user, subscription, shopper, shopperLoading }) {
  /**
   * @type {Shopper[]}
   */

  const [update, setUpdate] = React.useState(false);

  React.useEffect(async () => {
    if (!user) {
      return;
    }
  }, [user]);

  async function onChangeSuccess(subscription) {
    trackEvent('payment-method-change');
    setUpdate(false);
    if (subscription) {
      subscription.save();
    }
  }

  if (!shopper) {
    if (!shopperLoading) {
      return (
        <div className="payment-method">
          <h4>No billing method defined</h4>
        </div>
      );
    }
    return (
      <div className="loading-container">
        <PlugLoading>
          <div />
        </PlugLoading>
      </div>
    );
  }

  const ccImage = CARD_TYPE[shopper.ccType]
    ? require(`../../static/images/creditcards/${CARD_TYPE[shopper.ccType]}.png`)
    : undefined;

  const onUpdate = async () => {
    if (shopper.updateUrl) {
      await updatePaymentMethod(shopper.updateUrl);
      return;
    }
    setUpdate(!update);
  };

  // https://developers.bluesnap.com/docs/credit-card-codes
  return (
    <div className="payment-method" style={{ width: update ? 'fit-content' : '308px' }}>
      <Loader style={{ display: shopper ? 'none' : 'block' }} />

      <h4 className="payment-card-title">Payment method</h4>
      <hr />
      <h4 className="payment-card-title">
        Next Payment <span>{moment(subscription.nextChargeTime).format('MMM D, YYYY')}</span>
      </h4>
      <hr />
      <div className='cardInfo'>
        {shopper.ccType in CARD_TYPE && ccImage && (
          <div className="imgDiv">
            <img className="card" src={ccImage.default} />
          </div>
        )}
        <div className="info">
          <div>
            Card ending with <span id="card-last-digit">{shopper.lastDigits}</span>
          </div>
          <div className="expiry">Expires {shopper.exp}</div>
        </div>
      </div>
      <hr />
      <div className="change-payment-method" style={{ height: update ? 'auto' : '0px' }}>
        <PaymentForm
          // title="Payment method"
          buttonText="Save"
          shopper={shopper}
          onSuccess={onChangeSuccess}
        />
      </div>
      <button id="btn-change" className="btn btn-link" onClick={onUpdate}>
        {update ? 'Go back' : 'Change'}
      </button>
    </div>
  );
}

export default function Billing() {
  const { user, plan, subscription, setSubscription } = React.useContext(AppContext);
  const [userPlan, setUserPlan] = React.useState();
  const [orgUsersCount, setOrgUsersCount] = React.useState();
  const [shopperLoading, setShopperLoading] = React.useState(false);
  const [shopper, setShopper] = React.useState();

  React.useEffect(async () => {
    if (!user) {
      return;
    }
    const sub = await Subscription.get(null, {
      ...Subscription.PROPERTIES,
      footageUploadsCount: true,
    });
    setSubscription(sub);

    const p = await Plan.get(null, {
      sid: true,
      status: true,
      name: true,
      recurringPrice: true,
      overrideRecurringPrice: true,
      annualRecurringPrice: true,
      annualOverrideRecurringPrice: true,
      description: true,
      freeTrial: true,
      maxVideoDuration: true,
      maxVideos: true,
      maxUsers: true,
      maxStorage: true,
      brandsCount: true,
      updateTime: true,
      provider: true,
      planId: true,
      annualPlanId: true,
      showShutterStockBadge: true,
    });
    setUserPlan(p);

    try {
      setShopperLoading(true);
      setShopper(await Shopper.get(null));
      setShopperLoading(false);
    } catch (error) {
      console.log('get shopper error');
      setShopperLoading(false);
    }
  }, [user]);

  if (!user) {
    return null;
  }
  const TABS = {
    OVERVIEW: 0,
    INVOICES: 1,
    PAYMENT: 2,
  };
  const teamRef = React.useRef();
  const invoicesrRef = React.useRef();
  const PaymentRef = React.useRef();

  const [activeTab, setActiveTab] = React.useState(TABS.OVERVIEW);
  const billingItems = [
    { name: 'Overview', value: TABS.OVERVIEW, ref: teamRef },
    // { name: 'Invoices', value: TABS.INVOICES, ref: invoicesrRef },
    { name: 'Payment Method', value: TABS.PAYMENT, ref: PaymentRef },
  ];

  React.useEffect(() => {
    user && user.orgSid && loadOrgUsers();
  }, [user]);

  async function loadOrgUsers() {
    const orgsUserList = await User.count({ orgSidEqual: user.orgSid });
    setOrgUsersCount(orgsUserList);
  }
  return (
    <>
      <div className="billing">
        <div className="content-wrapper">
          <Tabs tabsItems={billingItems} triple={true} onChange={(selectedTab) => setActiveTab(selectedTab)} />
          {activeTab === TABS.OVERVIEW && (
            <Card className="tab-content">
              <Card.Body>
                <Overview user={user} subscription={subscription} orgUsersCount={orgUsersCount} />
              </Card.Body>
            </Card>
          )}
          {/* {activeTab === TABS.INVOICES && (
            <Card className="tab-content">
              <Card.Body>
                <h4 className="invoices-title">Invoices</h4>
                <BillingHistory user={user} />
              </Card.Body>
            </Card>
          )} */}
          {activeTab === TABS.PAYMENT && (
            <Card className="tab-content">
              <Card.Body>
                <h4 className="payment-method-title">Payment method</h4>
                <PaymentMethod
                  user={user}
                  subscription={subscription}
                  shopper={shopper}
                  shopperLoading={shopperLoading}
                />
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}
