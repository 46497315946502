
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export enum PictureStatus {
	PENDING = 0,
	PROCESSING = 1,
	READY = 2,
	DELETED = 3,
	ERRONEOUS = 5,
};

export interface IPicture extends IBase {
	status?: number | null;
	key?: string | null;
	thumbnailVersion?: number | null;
	assetSid?: string | null;
	linkSid?: string | null;
	thumbnail?: string | null;
}

export interface IPictureProperties extends IBaseProperties {
	status?: boolean;
	key?: boolean;
	thumbnailVersion?: boolean;
	assetSid?: boolean;
	linkSid?: boolean;
	thumbnail?: boolean;
}

export interface IPictureFilter extends IBaseFilter {
}

export class Picture<IType extends IPicture = IPicture, ITypeProperties extends IPictureProperties = IPictureProperties> extends Base<IType, ITypeProperties> implements IPicture {
	
	static className = 'Picture';
	static NAME = 'picture';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		key: true,
		thumbnailVersion: true,
		assetSid: true,
		linkSid: true,
	};
	
	/**
	 * @deprecated use PictureStatus instead
	 */
	static STATUS: any = {
		PENDING: PictureStatus.PENDING,
		PROCESSING: PictureStatus.PROCESSING,
		READY: PictureStatus.READY,
		DELETED: PictureStatus.DELETED,
		ERRONEOUS: PictureStatus.ERRONEOUS,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get key(): string | null | undefined {
		return this.values.key
	}
			
	get thumbnailVersion(): number | null | undefined {
		return this.values.thumbnailVersion
	}
			
	get assetSid(): string | null | undefined {
		return this.values.assetSid
	}
			
	set assetSid(value: string | null | undefined) {
		if(this.values.assetSid !== value) {
			this.propertyChanged('assetSid', value);
			this.values.assetSid = value;
		}
	}
			
	get linkSid(): string | null | undefined {
		return this.values.linkSid
	}
			
	set thumbnail(value: string | null | undefined) {
		if(this.values.thumbnail !== value) {
			this.propertyChanged('thumbnail', value);
			this.values.thumbnail = value;
		}
	}
			
	
	static get<IType extends IPicture = IPicture>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IPictureFilter = IPictureFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IPictureFilter = IPictureFilter, IType extends IPicture = IPicture>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
