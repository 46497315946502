import React, { PropsWithChildren } from 'react';
import useResizeObserver from 'use-resize-observer';
import styled from '@emotion/styled/macro';

const Container = styled.div<{ isExpanded: boolean; height?: number }>`
  position: relative;
  height: ${(props) => (props.isExpanded ? props.height : 0)}px;
  width: 100%;
  overflow: hidden;
  transition: height 0.5s cubic-bezier(0.55, 0.1, 0.41, 0.83);
`;

const ContentHeightMeasurer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

export const Expandable = ({ children, isExpanded }: PropsWithChildren<{ isExpanded: boolean }>) => {
  const { ref, height } = useResizeObserver();

  return (
    <Container height={height} isExpanded={isExpanded}>
      <ContentHeightMeasurer ref={ref}>{children}</ContentHeightMeasurer>
    </Container>
  );
};
