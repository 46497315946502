import React, { KeyboardEvent, MutableRefObject, SyntheticEvent } from 'react';
import styled from 'styled-components';
import lottie, { AnimationItem } from 'lottie-web';
// import lottie from '../../../data-layer/shared/lottie_svg';
import { Layer } from '../../../data-layer/shared/lottie.mjs';
import { fontVersionState } from './player-style';
import { AppContext } from '../lib/app-context';
import GraphicCuePoint from '../client/graphicCuePoint';

const BORDER_LAYER_SELECTOR = 'svg > g';

interface LottiePlayerSubClassProps {
  className: string;
  style: React.CSSProperties;
}

interface LottiePlayerCommonProps {
  contentEditable?: boolean;
  style?: React.CSSProperties;

  /**
   * Text color
   */
  color?: string;

  className?: string;

  fontSize?: number;

  textRatio?: Map<string, number>;

  /**
   * Background color
   */
  backgroundColor?: string;

  onKeyUp?: (event: KeyUpEvent) => void;

  onBlur?: (event: SyntheticEvent<HTMLDivElement>) => void;
  onClick?: (event: SyntheticEvent<HTMLDivElement>) => void;

  subClasses?: LottiePlayerSubClassProps[];
}

interface LottiePlayerProps extends LottiePlayerCommonProps {
  animationData: any;
  loop?: boolean;
  autoplay?: boolean;
  inMenu?: boolean;
  inAssetsDialog?: boolean;
  demoWidth?: number;
  cuePoint?: GraphicCuePoint;
  /**
   * Pause time in millis
   */
  pauseAt?: number;

  /**
   * Start time in millis, ignored if pauseAt is defined
   */
  playFrom?: number;

  onAnimationComplete: () => void;
  onEnterFrame?: () => void;

  onMouseEnter?: (event: MouseEvent) => void;
  animationRef?: MutableRefObject<AnimationItem | undefined>;
  newVisualVersion?: boolean; // use to need to use new takeInnerPosition
}

interface KeyUpEvent {
  target: HTMLElement;
}

interface InnerLottiePlayerProps extends LottiePlayerCommonProps {
  innerRef: Function;
}

function Player(props: InnerLottiePlayerProps) {
  const { innerRef, onKeyUp, onBlur, onClick, backgroundColor, className, subClasses, textRatio, fontSize, ...rest } =
    props;
  const myRef = React.useRef<HTMLDivElement>();

  function setRef(element) {
    myRef.current = element;
    innerRef && innerRef(element);
  }

  function getSelection(): Selection | null {
    let selection;
    if (window.getSelection) {
      selection = window.getSelection();
    } else if (document.getSelection) {
      selection = document.getSelection();
    } else {
      return null;
    }

    if (!selection.focusNode) {
      return null;
    }

    return selection;
  }

  function getElementInfo(): KeyUpEvent {
    const selection = getSelection();

    if (!selection) {
      return null;
    }

    let target: HTMLElement = selection.focusNode.parentElement;
    const body = document.documentElement;
    while (target && !target.isEqualNode(body)) {
      if (target.classList.contains('text-field')) {
        if (target.textContent.trim().length) {
          return { target };
        } else {
          return null;
        }
      }
      target = target.parentElement;
    }
    return null;
  }

  function onDivKeyUp(e: KeyboardEvent<HTMLDivElement>) {
    if (onKeyUp) {
      const elementInfo = getElementInfo();
      if (elementInfo) {
        onKeyUp(elementInfo);
      } else {
        onBlur(e);
      }
    }
  }

  function onKeyDown(e: KeyboardEvent<HTMLDivElement>) {
    // on Escape / Enter / Tab
    if (e.which === 27 || e.which === 13 || e.which === 9) {
      e.preventDefault();
      e.target.blur();
      return false;
    }

    // on Backspace
    if (e.key === 'Backspace' || e.key === 'Delete') {
      const selection = getSelection();

      if (!selection) {
        return false;
      }

      const range = selection.getRangeAt(0);
      const { startOffset, endOffset } = range;
      const textLength = e.target.textContent.length;

      if (startOffset === 0 || endOffset === 0 || startOffset === 1) {
        if (startOffset === 1 && endOffset === 1 && textLength > 2) {
          // Deleting first letter
          return true;
        } else if (endOffset === startOffset || (Math.abs(startOffset - endOffset) === 1 && textLength < 3)) {
          // Deleting the only letter
          return e.preventDefault();
        } else if (endOffset >= textLength - 1) {
          // CTRL + A (All selected)
          return e.preventDefault();
        }
        // Index 0 is included in selection fix
        const { baseNode } = selection;
        range.setStart(baseNode, 0);
      }
    }
    return true;
  }

  return (
    <div
      ref={setRef}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onClick={onClick}
      onInput={onDivKeyUp}
      className={`${className || ''} lottie-container`}
      {...rest}
    />
  );
}

const Container = styled(Player)`
  & .text-text {
    ${(props) => (props.color ? `fill: ${props.color};` : '')}
    ${(props) => (props.color ? `color: ${props.color};` : '')}
		/* ${(props) => (props.fontSize ? `font-size: ${props.fontSize}px;` : '')} */
  }
  & .color-stroke.text,
  & .color-stroke.div {
    ${(props) => (props.color ? `stroke: ${props.color};` : '')}
  }
  & .color-fill-dominant-bg {
    ${(props) => (props.backgroundColor ? `fill: ${props.backgroundColor};` : '')}
  }
  /* ${(props) =>
    props.textRatio &&
    Object.keys(props.textRatio).map(
      (key) => `
        & .text-${key} text, & .text-${key} div {
            transform: scaleY(${props.textRatio[key]});
        }
    `
    )} */
  ${(props) =>
    props.subClasses?.map(
      (subClass) => `
        & .${subClass.className}.color-fill {
            fill: ${subClass.style?.color};
            color: ${subClass.style?.color};
        }
        & .${subClass.className}-fill {
            fill: ${subClass.style?.color};
            color: ${subClass.style?.color};
        }
        & .${subClass.className}.color-stroke {
            stroke: ${subClass.style?.color};
            color: ${subClass.style?.color};
        }
        & .${subClass.className}-stroke {
            stroke: ${subClass.style?.color};
            color: ${subClass.style?.color};
        }
        &.${subClass.className} {
            transform: ${subClass.style?.transform};
        }
    `
    )}
`;

export default function LottiePlayer(props: LottiePlayerProps) {
  const {
    animationData,
    customRenderProps,
    loop,
    autoplay,
    pauseAt,
    playFrom,
    onAnimationComplete,
    onEnterFrame,
    style,
    demoWidth,
    inMenu,
    inAssetsDialog,
    isDraft,
    cuePoint,
    measured,
    onFit,
    newVisualVersion,
    size,
    version,
    innerPos,
    innerSize,
    outerSize,
    outerPos,
    animationRef,
    ...rest
  } = props;

  const { inOverlay } = React.useContext(AppContext);
  const container = React.useRef<HTMLDivElement>();
  const animation = React.useRef<AnimationItem>();
  const fontVersion = fontVersionState.use();

  const monitorClass = isDraft ? '.monitor.draft' : '.main-monitor';
  const [shouldMeasure, setShouldMeasure] = React.useState<boolean>(false);
  const [outOfRange, setOutOfRange] = React.useState<boolean>(false);
  const [innerSizeM, setInnerSizeM] = React.useState<any>(false);
  const [outerSizeM, setOuterSizeM] = React.useState<any>(false);
  const [innerPosM, setInnerPosM] = React.useState<any>(false);
  const [outerPosM, setOuterPosM] = React.useState<any>(false);


  React.useEffect(() => {
    if (!container.current) {
      return;
    }

    if (inOverlay && animation.current) {
      return;
    }

    if (inAssetsDialog) {
      const extractChildren = (child) =>
        (child && child.shapes && child.shapes.map(extractChildren) && child.shapes.map(extractChildren).flat()) ||
        child;
      animationData.layers
        .map(extractChildren)
        .flat()
        .filter((layer) => layer?.ty === Layer.Type.TEXT)
        .forEach((tl) => {
          tl.t.d.k[0].s.t = tl.nm;
        });
    }

    // TODO: need to verify if needs this with new package
    // support old json file change singleShape to false
    animationData.layers
      .filter((layer) => layer?.ty === Layer.Type.TEXT)
      .forEach((tl) => {
        tl.singleShape = false;
      });

    const anim = JSON.parse(JSON.stringify(animationData));

    anim.customRenderProps = customRenderProps;
    const measurement = {};
    const animationStartTime =
      !!animationData.animations?.length &&
      animationData.animations?.find((a) => a.start === 0)?.end * animationData.fr;
    if (!measured && onFit) {
      setShouldMeasure(true);
      measurement.initialSegment = [
        animationStartTime || animationData.op / 2,
        animationStartTime || animationData.op / 2,
      ];
    }

    animation.current = lottie.loadAnimation({
      loop,
      autoplay,
      animationData: anim,
      container: container.current,
      renderer: 'svg',
      rendererSettings: {
        preserveAspectRatio: 'none',
        ...measurement,
      },
    });

    if (animationRef) {
      animationRef.current = animation.current;
    }

    pauseAt && animation.current.goToAndStop(pauseAt);

    onAnimationComplete && animation.current.addEventListener('complete', onAnimationComplete);
    onEnterFrame && animation.current.addEventListener('enterFrame', onEnterFrame);

    return () => !inOverlay && animation.current.destroy();
  }, [
    animationData.clientVersion,
    animationData,
    customRenderProps,
    version,
    loop,
    autoplay,
    container.current,
    measured,
    fontVersion,
  ]);

  React.useEffect(() => {
    if (!animation.current) {
      return;
    }

    if (pauseAt !== undefined && pauseAt !== null) {
      /**
       * @type {number} Duration in seconds
       */
      const duration = animation.current.getDuration();
      if (duration < pauseAt / 1000) {
        console.log('outOfRange', {
          outOfRange: true,
          pauseAt: pauseAt / 1000,
          animationDuration: duration,
        });

        return setOutOfRange(true);
      }
      setOutOfRange(false);
      setTimeout(() => {
        animation.current!.goToAndStop(pauseAt);
      }, 1);
    } else if (playFrom !== undefined) {
      animation.current.goToAndPlay(playFrom);
    }
    if (outOfRange) {
      setOutOfRange(false);
    }
  }, [pauseAt, playFrom, animation.current]);

  React.useEffect(() => {
    shouldMeasure && onFit && takeInnerSize();
  }, [shouldMeasure]);

  function takeInnerSize() {
    if (container.current && onFit) {
      const borderLayer = container.current?.querySelector(BORDER_LAYER_SELECTOR);
      if (borderLayer && container.current?.parentElement) {
        const parentElement = container.current.parentElement;
        const parentWidth = parentElement.getBoundingClientRect().width,
          parentHeight = parentElement.getBoundingClientRect().height;

        const canvas = document.querySelector(`${monitorClass} #main-graphics`);
        if (!canvas) {
          return;
        }
        const canvasWidth = canvas.getBoundingClientRect().width,
          canvasHeight = canvas.getBoundingClientRect().height;

        const { width, height } = borderLayer?.getBoundingClientRect();

        if (!height) {
          return;
        }

        const requestedHeight = (parentHeight / height) * 100;
        const requestedWidth = (canvasWidth / width) * 100;

        const size = {
          width: 'max-content',
          height: requestedHeight + '%',
        };
        setInnerSizeM(size);
        setShouldMeasure(false);
      }
    }
  }

  function takeOuterSize() {
    const borderLayer = container.current?.querySelector(BORDER_LAYER_SELECTOR);
    if (borderLayer && onFit) {
      const { width, height } = borderLayer?.getBoundingClientRect();
      const canvas = document.querySelector(`${monitorClass} #main-graphics`);
      const canvasWidth = canvas.getBoundingClientRect().width,
        canvasHeight = canvas.getBoundingClientRect().height;

      const requestedWidth = (width / canvasWidth) * 100;
      const requestedHeight = (height / canvasHeight) * 100;
      if (!requestedWidth || !requestedHeight) {
        return;
      }
      setOuterSizeM({ width: requestedWidth + '%', height: requestedHeight + '%' });
    }
  }

  function takeOuterPosition() {
    const borderLayer = container.current?.querySelector(BORDER_LAYER_SELECTOR);
    const canvas = document.querySelector(`${monitorClass} #main-graphics`);
    if (borderLayer && container.current && onFit && canvas) {
      const parentElement = container.current;
      const parentLeft = parentElement.getBoundingClientRect().left;
      const canvasWidth = canvas.getBoundingClientRect().width;
      const { left, top } = borderLayer?.getBoundingClientRect();

      if (!top || !left) {
        return;
      }

      const requestedLeft = ((left - parentLeft) / canvasWidth) * 100;  

      return requestedLeft;
    }
  }

  function takeInnerPosition() {
    // represent the inner text / graphic in the svg layer
    const childElement = container.current?.querySelector(BORDER_LAYER_SELECTOR);
    if (childElement && container.current && onFit) {
      // represent the outer svg layer
      const parentElement = container.current;
      const { left: parentLeft, width: parentWidth } = parentElement.getBoundingClientRect();
      const { left: childLeft } = childElement?.getBoundingClientRect();
      // actual position of the inner text / graphic related to the outer svg layer
      const actualLeft = childLeft - parentLeft;
      const transformPercentage = (actualLeft / parentWidth) * 100;

      if (!childLeft) {
        return;
      }
     
      return { transform: `translateX(calc(-${transformPercentage}%))` };
    }
  }

  function legacyTakeInnerPosition() {
      const borderLayer = container.current?.querySelector(BORDER_LAYER_SELECTOR);
      if (borderLayer && container.current && onFit) {
        const parentElement = container.current;
        const parentLeft = parentElement.getBoundingClientRect().left;
        const containerWidth = parentElement.getBoundingClientRect().width;
  
        const { left, top } = borderLayer?.getBoundingClientRect();
  
        if (!top || !left) {
          return;
        }
  
        
        const requestedLeft = -(((left - parentLeft) / containerWidth) * 100);
  
        return { transform: `translateX(${requestedLeft}%)` };
      }
    }

  function takePosition() {
    setTimeout(() => {
      // condition to legacyTakeInnerPosition
      const innerPos = newVisualVersion ? takeInnerPosition() : legacyTakeInnerPosition() ;
      const outer = takeOuterPosition();
      if (!inOverlay  && !(innerSizeM && outerSizeM)) {
        return;
      }
      setInnerPosM(innerPos);
      setOuterPosM(outer);
      onFit && onFit(innerSizeM, outerSizeM, { innerPos, outerPos: outerPosM || outerPos || outer });
    }, 10);
  }

  React.useEffect(() => {
    if (innerSizeM && container.current && onFit) {
      let timeout = setTimeout(takeOuterSize, 20);
      return () => clearTimeout(timeout);
    }
  }, [container.current, innerSizeM]);

  React.useEffect(takePosition, [outerSizeM]);

  return (
    <Container
      innerRef={(element) => (container.current = element)}
      style={{
        display: outOfRange && !inAssetsDialog && !inMenu ? 'none' : 'block',
        width: inAssetsDialog ? demoWidth : style?.width,
        ...(inAssetsDialog || inMenu ? {} : { width: inOverlay ? 'max-content' : (innerSize || size)?.width }),
        ...(innerPosM || innerPos || {}),
      }}
      {...rest}
    />
  );

  // return <div
  //     ref={element => container.current = element}
  //     {...rest}
  // />
}
