import React from 'react'
import Branding from '../../ui-components/Branding/Branding';
interface WizardBrandProps {
	onBrandReady?(): void;
}

export default function WizardBrand({ onBrandReady }: WizardBrandProps) {
	const [fontFile, setFontFile] = React.useState({ name: '', size: '' })
    const [fontAsset, setFontAsset] = React.useState()

	return (
		<div className="wizard-brand">
			<Branding onBrandReady={onBrandReady} fontFile={fontFile} setFontFile={setFontFile} fontAsset={fontAsset} setFontAsset={setFontAsset}/>
		</div>
	)
}