import styled from '@emotion/styled'
import { navigate, useLocation } from '@reach/router'
import React from 'react'
import { COLORS } from '../../../constants/colors'
import ClosedCaption from '../../client/closedCaption'
import Chapter from '../../client/sequence/chapter'
import SpeakerCuePoint from '../../client/speakerCuePoint'
import faceRecognitionLoader from '../../components/common/lottie-loader/face-recognition-loader.json'
import firstDraftLoader from '../../components/common/lottie-loader/first-draft-loader.json'
import { LottieLoader } from '../../components/common/lottie-loader/lottie-loader.component.tsx'
import SpeakersModal from '../../dialogs/SpeakersModal'
import { cuePointsState, getCloseCaption } from '../../state/cuePoints'
import { scenesState, sequenceState } from '../../state/sequence'
import { saveSpeaker } from '../../state/speakers'
import Loader from '../../ui-components/Loader'
import OnboardingWizard from '../../ui-components/OnboardingWizard'
import { trackEvent } from '../../utils/analityics.utils'
import EditorContent from './Editors/EditorContent'
import EditorPreview from './Editors/EditorPreview'
import './style.scss'





const LoaderContainer = styled.div`
	width: 100vw;
	height: calc(100vh - 56px);
	z-index: 40;
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: ${COLORS.peech_gray_50};
	display: flex;
	justify-content: center;
	align-items: center;
`

const PROJECT_ROUTE = 'project'

interface ContentEditorProps {
	skipContentStep?: boolean;
	setSkipContentStep?: Function;
	hideTrimButton?: boolean;
}

export default function ContentEditor({ skipContentStep, setSkipContentStep, hideTrimButton }: ContentEditorProps) {
	const [done, setDone] = React.useState<boolean>(false)
	const sequence = sequenceState.use()
	const audioGenerated = sequenceState.use(sequence => sequence.audioGenerated)
	const scenes = scenesState.use(scenes => scenes.map)
	const useSpeakers = sequenceState.use(sequence => sequence?.editRules?.useSpeakers);
	const [speakers, setSpeakers] = React.useState<SpeakerCuePoint[]>([])
	const notDeletedChapters = scenesState.use(scenes => scenes.filter.notDeletedChapters)
	const contentEnabledChapters = scenesState.use(scenes => scenes.filter.contentChapters)
	const unPlayableChapter = !!(notDeletedChapters || []).find(c => c.status !== Chapter.STATUS.READY && (!c?.resized || !c?.transcripted))
	const unTrimmableChapter = !!(notDeletedChapters || []).find(c => c.status !== Chapter.STATUS.READY && (!c?.keyFramed || !c?.transcripted || !c.fitted || !c.formated))
	const faceDetected = !(contentEnabledChapters || []).find(c => !c.faceDetected)
	const location = useLocation()
	const isOnSequencePage = (location.pathname.replaceAll('/', '') === PROJECT_ROUTE && location.hash)

	const [loaderFinished, setLoaderFinished] = React.useState(!unPlayableChapter && !unTrimmableChapter)
	const [SpeakersModalReady, setSpeakersModalReady] = React.useState<boolean>(false)
	const [shouldSaveSpeakers, setShouldSaveSpeakers] = React.useState<boolean>(false)
	const [waitingForFaceDetection, setWaitingForFaceDetection] = React.useState<boolean>(false)
	const [fetchCloseCaption, setFetchCloseCaption] = React.useState<boolean>(false)

	

	const cuePointsData = cuePointsState.use()
	const speakerCuePoints = Object.values(cuePointsData).filter(
		cuePoint => cuePoint && cuePoint instanceof SpeakerCuePoint
	).sort((a, b) => a.insertTime - b.insertTime);
	const closedCaptionsCount = Object.values(cuePointsData).filter(
		cuePoint => cuePoint && cuePoint instanceof ClosedCaption).length
	const speakerCuePointsCount = speakers.length;

	const readyChaptersCount = scenesState.use(scenes => scenes.filter.readyChapters.filter(chapter => !chapter.cuePointType).length)
	const totalChaptersCount = scenesState.use(scenes => scenes.filter.chapters.filter(chapter => !chapter.cuePointType).length)
	const [showLoaderBeforeSkip, setShowLoaderBeforeSkip] = React.useState(false);

	React.useEffect(() => {
		if (skipContentStep) {
			setShowLoaderBeforeSkip(true);
		}
	}, [skipContentStep])

	React.useEffect(() => {
		setLoaderFinished(!unTrimmableChapter);
	}, [unTrimmableChapter]);

	React.useEffect(continueToProject, [readyChaptersCount, done, totalChaptersCount, audioGenerated]);

	React.useEffect(() => {
		faceDetected && !isOnSequencePage && onFaceDetected()
	}, [faceDetected]);

	function onFaceDetected() {
		if (!speakerCuePointsCount) {
			setDone(true)
		}
		setSpeakersModalReady(true)
		setWaitingForFaceDetection(false)
	}
	// filter out speakers that already includes all details
	React.useEffect(() => setSpeakers(speakerCuePoints.filter(cuePoint => !cuePoint.name)), [speakerCuePoints.length]);

	function continueToProject() {
		readyChaptersCount === totalChaptersCount && done && (!sequence?.music?.enabled || sequence?.audioGenerated) && navigate(`/project?new=true#${sequence.sid}`)
	}

	function onContentDone() {
		if (SpeakersModalReady) {
			// remove speaker sid if user didnt choose to save
			!shouldSaveSpeakers && speakers.forEach((cp: SpeakerCuePoint) => cp.speakerSid = null)
			// remove empty speaker cuePoints and save the rest
			speakers.forEach((cp: SpeakerCuePoint) => {
				if (!cp.name?.trim()?.length) {
					cp.remove()
				} else {
					saveSpeaker(cp, sequence, scenes)
				}
			})

			setDone(true)
			trackEvent('speaker-detection-done');
		} else if (!useSpeakers) {
			setDone(true)
		} else {
			setSkipContentStep(false);
			setShowLoaderBeforeSkip(false);
			setWaitingForFaceDetection(true)
		}

	}

	// when loader done and closedCaptionsCount is 0 try fetch cc
	React.useEffect(async () => {
		if (loaderFinished) {
			await sequence.validateInputs();
		}
		if (loaderFinished && !closedCaptionsCount) {
			getCloseCaption(sequence, scenes).then(() => {
				console.log('fetch closed caption when empty to verify that we get closed caption');
				setFetchCloseCaption(true)
			});
		}
	}, [loaderFinished, closedCaptionsCount])

	const shouldShowSpeakersModal = SpeakersModalReady && faceDetected && !done
	const shouldShowFaceRecognitionLoader = waitingForFaceDetection;
	const shouldShowFirstDraftLoader = (done && (readyChaptersCount !== totalChaptersCount || (sequence?.music?.enabled && !sequence?.audioGenerated)))

	const projectLoadingDiv = <LoaderContainer>
		<LottieLoader animationData={faceRecognitionLoader} loop={true} width={'250px'} />
	</LoaderContainer>

	const firstDraftLoaderDiv = <LoaderContainer>
		<LottieLoader animationData={firstDraftLoader} loop={true} width={'250px'} />
	</LoaderContainer>

	const speakerModalDiv = <LoaderContainer>
		<SpeakersModal speakerCuePoints={speakers} onChange={() => setShouldSaveSpeakers(!shouldSaveSpeakers)} shouldSaveSpeakers={shouldSaveSpeakers} onDone={onContentDone} />
	</LoaderContainer>

	if (shouldShowFaceRecognitionLoader
	) {
		return projectLoadingDiv;
	}

	if (shouldShowFirstDraftLoader
	) {
		return firstDraftLoaderDiv;
	}

	if (shouldShowSpeakersModal) {
		return speakerModalDiv;
	}

	if (loaderFinished && !closedCaptionsCount) {
		return <LoaderContainer>
			<Loader />
		</LoaderContainer>
	}

	const EditorContentProps = <EditorContent unPlayableChapter={unPlayableChapter} unTrimmableChapter={unTrimmableChapter} loaderFinished={loaderFinished} hideTrimButton={hideTrimButton} />

	return (
		<>
			{loaderFinished ? null : <Loader />}
			{showLoaderBeforeSkip ?
				projectLoadingDiv
				:
				null}
			<div className="content-editor" style={{ display: loaderFinished ? 'block' : 'none' }}>
				<OnboardingWizard PreviewComponent={EditorContentProps}>
					<EditorPreview unTrimmableChapter={unTrimmableChapter} visible={loaderFinished} onContentDone={onContentDone} skipContentStep={skipContentStep}/>
				</OnboardingWizard>
			</div>

		</>
	)
}
