import {Link} from "gatsby";
import React from "react";
import {
    scenesState,
    sequenceState,
} from '../../state/sequence'
export const DurationNotAllowed = ({onTrim, onHide}) => {
    const sequence = sequenceState.use()
    const chapters = scenesState.use(scenes => scenes.filter.chapters)
    return (
        <>
            <div className="download-heading download-unallowed">
                <h2 className="download-title">Wow!</h2>
                <div>
                    Seems like your video has extended <br /> the allowed length of your plan.<br />
                    You can <a className="trim-text-link" onClick={() => { onHide(); chapters[0] ? onTrim(chapters[0].sid) : null }}>trim</a> the
                    video to two minutes <br />or <Link className="upgrade-text-link" to={`/plans/#${sequence.sid}`}>
                    upgrade your plan</Link>!
                </div>
            </div>
            <div className="download-unallowed-actions">
                <button onClick={() => { onHide(); chapters[0] ? onTrim(chapters[0].sid) : null }} id="trim-btn-download"
                        className="btn btn-white bg-rainbow upgrade trim-btn">
                    Trim
                </button>
                <Link to={`/plans/#${sequence.sid}`} className="btn btn-link download-unallowed-link"
                >Upgrade</Link>
            </div>
        </>
    )
}
