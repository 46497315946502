
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'

export interface IText extends IBase {
	start?: number | null;
	duration?: number | null;
	x?: number | null;
	y?: number | null;
	type?: string | null;
	enableX?: boolean | null;
	enableY?: boolean | null;
	velocityType?: string | null;
	direction?: string | null;
	opacity?: number | null;
	text?: string | null;
	color?: string | null;
	startColor?: string | null;
	align?: string | null;
	fontSize?: number | null;
	fontWeight?: string | null;
	fontItalic?: boolean | null;
	lineHeight?: number | null;
	shadowBlur?: number | null;
	shadowOpacity?: number | null;
	shadowColor?: string | null;
	shadowOffsetX?: number | null;
	shadowOffsetY?: number | null;
}

export interface ITextProperties extends IBaseProperties {
	start?: boolean;
	duration?: boolean;
	x?: boolean;
	y?: boolean;
	type?: boolean;
	enableX?: boolean;
	enableY?: boolean;
	velocityType?: boolean;
	direction?: boolean;
	opacity?: boolean;
	text?: boolean;
	color?: boolean;
	startColor?: boolean;
	align?: boolean;
	fontSize?: boolean;
	fontWeight?: boolean;
	fontItalic?: boolean;
	lineHeight?: boolean;
	shadowBlur?: boolean;
	shadowOpacity?: boolean;
	shadowColor?: boolean;
	shadowOffsetX?: boolean;
	shadowOffsetY?: boolean;
}

export interface ITextFilter extends IBaseFilter {
}

export class Text<IType extends IText = IText, ITypeProperties extends ITextProperties = ITextProperties> extends Base<IType, ITypeProperties> implements IText {
	
	static className = 'Text';
	static NAME = 'text';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		start: true,
		duration: true,
		x: true,
		y: true,
		type: true,
		enableX: true,
		enableY: true,
		velocityType: true,
		direction: true,
		opacity: true,
		text: true,
		color: true,
		startColor: true,
		align: true,
		fontSize: true,
		fontWeight: true,
		fontItalic: true,
		lineHeight: true,
		shadowBlur: true,
		shadowOpacity: true,
		shadowColor: true,
		shadowOffsetX: true,
		shadowOffsetY: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get start(): number | null | undefined {
		return this.values.start
	}
			
	set start(value: number | null | undefined) {
		if(this.values.start !== value) {
			this.propertyChanged('start', value);
			this.values.start = value;
		}
	}
			
	get duration(): number | null | undefined {
		return this.values.duration
	}
			
	set duration(value: number | null | undefined) {
		if(this.values.duration !== value) {
			this.propertyChanged('duration', value);
			this.values.duration = value;
		}
	}
			
	get x(): number | null | undefined {
		return this.values.x
	}
			
	set x(value: number | null | undefined) {
		if(this.values.x !== value) {
			this.propertyChanged('x', value);
			this.values.x = value;
		}
	}
			
	get y(): number | null | undefined {
		return this.values.y
	}
			
	set y(value: number | null | undefined) {
		if(this.values.y !== value) {
			this.propertyChanged('y', value);
			this.values.y = value;
		}
	}
			
	get type(): string | null | undefined {
		return this.values.type
	}
			
	set type(value: string | null | undefined) {
		if(this.values.type !== value) {
			this.propertyChanged('type', value);
			this.values.type = value;
		}
	}
			
	get enableX(): boolean | null | undefined {
		return this.values.enableX
	}
			
	set enableX(value: boolean | null | undefined) {
		if(this.values.enableX !== value) {
			this.propertyChanged('enableX', value);
			this.values.enableX = value;
		}
	}
			
	get enableY(): boolean | null | undefined {
		return this.values.enableY
	}
			
	set enableY(value: boolean | null | undefined) {
		if(this.values.enableY !== value) {
			this.propertyChanged('enableY', value);
			this.values.enableY = value;
		}
	}
			
	get velocityType(): string | null | undefined {
		return this.values.velocityType
	}
			
	set velocityType(value: string | null | undefined) {
		if(this.values.velocityType !== value) {
			this.propertyChanged('velocityType', value);
			this.values.velocityType = value;
		}
	}
			
	get direction(): string | null | undefined {
		return this.values.direction
	}
			
	set direction(value: string | null | undefined) {
		if(this.values.direction !== value) {
			this.propertyChanged('direction', value);
			this.values.direction = value;
		}
	}
			
	get opacity(): number | null | undefined {
		return this.values.opacity
	}
			
	set opacity(value: number | null | undefined) {
		if(this.values.opacity !== value) {
			this.propertyChanged('opacity', value);
			this.values.opacity = value;
		}
	}
			
	get text(): string | null | undefined {
		return this.values.text
	}
			
	set text(value: string | null | undefined) {
		if(this.values.text !== value) {
			this.propertyChanged('text', value);
			this.values.text = value;
		}
	}
			
	get color(): string | null | undefined {
		return this.values.color
	}
			
	set color(value: string | null | undefined) {
		if(this.values.color !== value) {
			this.propertyChanged('color', value);
			this.values.color = value;
		}
	}
			
	get startColor(): string | null | undefined {
		return this.values.startColor
	}
			
	set startColor(value: string | null | undefined) {
		if(this.values.startColor !== value) {
			this.propertyChanged('startColor', value);
			this.values.startColor = value;
		}
	}
			
	get align(): string | null | undefined {
		return this.values.align
	}
			
	set align(value: string | null | undefined) {
		if(this.values.align !== value) {
			this.propertyChanged('align', value);
			this.values.align = value;
		}
	}
			
	get fontSize(): number | null | undefined {
		return this.values.fontSize
	}
			
	set fontSize(value: number | null | undefined) {
		if(this.values.fontSize !== value) {
			this.propertyChanged('fontSize', value);
			this.values.fontSize = value;
		}
	}
			
	get fontWeight(): string | null | undefined {
		return this.values.fontWeight
	}
			
	set fontWeight(value: string | null | undefined) {
		if(this.values.fontWeight !== value) {
			this.propertyChanged('fontWeight', value);
			this.values.fontWeight = value;
		}
	}
			
	get fontItalic(): boolean | null | undefined {
		return this.values.fontItalic
	}
			
	set fontItalic(value: boolean | null | undefined) {
		if(this.values.fontItalic !== value) {
			this.propertyChanged('fontItalic', value);
			this.values.fontItalic = value;
		}
	}
			
	get lineHeight(): number | null | undefined {
		return this.values.lineHeight
	}
			
	set lineHeight(value: number | null | undefined) {
		if(this.values.lineHeight !== value) {
			this.propertyChanged('lineHeight', value);
			this.values.lineHeight = value;
		}
	}
			
	get shadowBlur(): number | null | undefined {
		return this.values.shadowBlur
	}
			
	set shadowBlur(value: number | null | undefined) {
		if(this.values.shadowBlur !== value) {
			this.propertyChanged('shadowBlur', value);
			this.values.shadowBlur = value;
		}
	}
			
	get shadowOpacity(): number | null | undefined {
		return this.values.shadowOpacity
	}
			
	set shadowOpacity(value: number | null | undefined) {
		if(this.values.shadowOpacity !== value) {
			this.propertyChanged('shadowOpacity', value);
			this.values.shadowOpacity = value;
		}
	}
			
	get shadowColor(): string | null | undefined {
		return this.values.shadowColor
	}
			
	set shadowColor(value: string | null | undefined) {
		if(this.values.shadowColor !== value) {
			this.propertyChanged('shadowColor', value);
			this.values.shadowColor = value;
		}
	}
			
	get shadowOffsetX(): number | null | undefined {
		return this.values.shadowOffsetX
	}
			
	set shadowOffsetX(value: number | null | undefined) {
		if(this.values.shadowOffsetX !== value) {
			this.propertyChanged('shadowOffsetX', value);
			this.values.shadowOffsetX = value;
		}
	}
			
	get shadowOffsetY(): number | null | undefined {
		return this.values.shadowOffsetY
	}
			
	set shadowOffsetY(value: number | null | undefined) {
		if(this.values.shadowOffsetY !== value) {
			this.propertyChanged('shadowOffsetY', value);
			this.values.shadowOffsetY = value;
		}
	}
			
	
	static get<IType extends IText = IText>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends ITextFilter = ITextFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends ITextFilter = ITextFilter, IType extends IText = IText>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
