
import Base, {IBase, IBaseFilter, IBaseProperties, Pager} from '../base'
import { IIncome, Income, IIncomeProperties } from './income';
import { IPayment, Payment, IPaymentProperties } from './payment';

export interface IInvoice extends IBase {
	id?: string | null;
	creationDate?: number | null;
	number?: number | null;
	amount?: number | null;
	payment?: Payment[] | null;
	income?: Income[] | null;
}

export interface IInvoiceProperties extends IBaseProperties {
	id?: boolean;
	creationDate?: boolean;
	number?: boolean;
	amount?: boolean;
	payment?: IPaymentProperties;
	income?: IIncomeProperties;
}

export interface IInvoiceFilter extends IBaseFilter {
}

export class Invoice<IType extends IInvoice = IInvoice, ITypeProperties extends IInvoiceProperties = IInvoiceProperties> extends Base<IType, ITypeProperties> implements IInvoice {
	
	static className = 'Invoice';
	static NAME = 'invoice';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		id: true,
		creationDate: true,
		number: true,
		amount: true,
		payment: Payment.PROPERTIES,
		income: Income.PROPERTIES,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		if(values.payment && values.payment.length) {
			values.payment = values.payment.map(item => Base.asType<IPayment>(item, Payment, this, 'payment') as Payment);
		}
		if(values.income && values.income.length) {
			values.income = values.income.map(item => Base.asType<IIncome>(item, Income, this, 'income') as Income);
		}
		return super.set(values, parent, name, ignoreModified);
	}
	
	get id(): string | null | undefined {
		return this.values.id
	}
			
	set id(value: string | null | undefined) {
		if(this.values.id !== value) {
			this.propertyChanged('id', value);
			this.values.id = value;
		}
	}
			
	get creationDate(): number | null | undefined {
		return this.values.creationDate
	}
			
	set creationDate(value: number | null | undefined) {
		if(this.values.creationDate !== value) {
			this.propertyChanged('creationDate', value);
			this.values.creationDate = value;
		}
	}
			
	get number(): number | null | undefined {
		return this.values.number
	}
			
	set number(value: number | null | undefined) {
		if(this.values.number !== value) {
			this.propertyChanged('number', value);
			this.values.number = value;
		}
	}
			
	get amount(): number | null | undefined {
		return this.values.amount
	}
			
	set amount(value: number | null | undefined) {
		if(this.values.amount !== value) {
			this.propertyChanged('amount', value);
			this.values.amount = value;
		}
	}
			
	get payment(): Payment[] | null | undefined {
		return this.values.payment
	}
			
	set payment(value: Payment[] | null | undefined) {
		if(this.values.payment !== value) {
			this.propertyChanged('payment', value);
			this.values.payment = value;
		}
	}
			
	get income(): Income[] | null | undefined {
		return this.values.income
	}
			
	set income(value: Income[] | null | undefined) {
		if(this.values.income !== value) {
			this.propertyChanged('income', value);
			this.values.income = value;
		}
	}
			
	
	static get<IType extends IInvoice = IInvoice>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IInvoiceFilter = IInvoiceFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IInvoiceFilter = IInvoiceFilter, IType extends IInvoice = IInvoice>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
