

export function calcMinTime(offset: number, minTime?: number) {
    if (isNaN(minTime)) {
        return offset * 1000;
    }
    return Math.round(minTime / 1000) * 1000;
}

export function calcMaxTime(offset: number, duration: number, maxTime?: number) {
    if (isNaN(maxTime)) {
        return (offset + duration) * 1000;
    }
    return Math.round(maxTime / 1000) * 1000;
}

export function calculateDuplicateCuePointPlacement(currentCuePointTop: number, currentCuePointHeight: number) {
    let newCuePointTop;

    const videoBottom = 100;
    const videoTop = 0;
    const newCuePointTopIfBelowCurrentCuePoint = currentCuePointTop + currentCuePointHeight;
    const newCuePointTopIfAboveCurrentCuePoint = currentCuePointTop - currentCuePointHeight;
    const newCuePointBottomIfBelowCurrentCuePoint = newCuePointTopIfBelowCurrentCuePoint + currentCuePointHeight;

    // default new cuePoint top: below current
    newCuePointTop = newCuePointTopIfBelowCurrentCuePoint;

    // if new cuePoint doesn't fit below current - place it above
    if (newCuePointBottomIfBelowCurrentCuePoint > videoBottom) {
        newCuePointTop = newCuePointTopIfAboveCurrentCuePoint
    }

    // if new cuePoint will not fit above or below current: offset top by 5
    if (newCuePointTopIfAboveCurrentCuePoint < videoTop && newCuePointBottomIfBelowCurrentCuePoint > videoBottom) {
        newCuePointTop = currentCuePointTop + 5
    }

    return newCuePointTop
}
