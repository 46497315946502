import FileUploader from '../lib/fileUploader'

import {entity} from 'simpler-state'

/**
 * @type {Map<string, FileUploader>}
 */
const emptyMap = {};

export const fileUploaderState = entity(emptyMap)
