import React from 'react';
import { AppContext } from '../lib/app-context';
import Fonts from '../ui-components/Fonts/Fonts';
import LogosAndColors from '../ui-components/LogosAndColors';
import Tabs from '../ui-components/Tabs/Tabs';
import VisualPackages from '../ui-components/VisualPackages/VisualPackages';

const TABS = {
  LOGOS: 0,
  FONTS: 1,
  VISUAL_PACKAGE: 2,
};

export default function BrandkitPage({ preset, setPreset }) {
  const [activeTab, setActiveTab] = React.useState(TABS.LOGOS);
  const { user } = React.useContext(AppContext);
  const logosRef = React.useRef();
  const fontsRef = React.useRef();
  const visualPackagesRef = React.useRef();

  const brandItems = [
    { name: 'Logos & Colors', value: TABS.LOGOS, ref: logosRef },
    { name: 'Fonts', value: TABS.FONTS, ref: fontsRef },
  ];

  brandItems.push({ name: 'Visual Style', value: TABS.VISUAL_PACKAGE, ref: visualPackagesRef });

  return (
    <div className="brand-kit-page">
      <Tabs tabsItems={brandItems} onChange={(selectedTab) => setActiveTab(selectedTab)} triple={true} />
      <div className="brand-view-wrapper">
        {activeTab === TABS.LOGOS && <LogosAndColors preset={preset} setPreset={setPreset} />}
        {activeTab === TABS.FONTS && <Fonts preset={preset} setPreset={setPreset} />}
        {activeTab === TABS.VISUAL_PACKAGE && <VisualPackages preset={preset} setPreset={setPreset} />}
      </div>
    </div>
  );
}
