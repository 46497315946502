import React from 'react';
import Select, { components } from 'react-select';
import Flag from 'react-world-flags';
import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';
import { AppContext } from '../../lib/app-context';

export const FlagIcon = styled(Flag)<{ minimized?: boolean }>`
  width: ${(props) => (props.minimized ? '18px' : '24px')};
  height: ${(props) => (props.minimized ? '14px' : '16px')};
  margin-right: 10px;
`;

interface LangObjectProps {
  languageCode: string;
  label: string;
  status: 'COMING_SOON' | 'ACTIVE';
}

export enum LangSelectorColors {
  BLUE = 0,
  WHITE = 1,
  GRAY = 2,
}
interface CustomLanguageSelectorProps {
  sid: string;
  onLangChange?(val: any, sid: string): void;
  disabled: boolean;
  lang: string;
  setTempLang?(): void;
  supportedLanguages: LangObjectProps[];
  minimized?: boolean;
  selectorColor?: LangSelectorColors;
  placeholder?: string;
  isSearchable?: boolean;
  showFlag?: boolean | undefined;
}

export default function CustomLanguageSelector({
  sid,
  lang,
  onLangChange,
  disabled,
  supportedLanguages,
  setTempLang,
  minimized = false,
  selectorColor = LangSelectorColors.BLUE,
  placeholder = 'Language',
  isSearchable = false,
  showFlag = false,
}: CustomLanguageSelectorProps) {
  const [currentLang, setCurrentLang] = React.useState();
  const { isMobileDevice } = React.useContext(AppContext);

  React.useEffect(() => {
    setCurrentLang(supportedLanguages?.find((el) => el.languageCode === lang));
  }, [lang]);

  const { Option } = components;

  async function changeCurrentLang(val: Object) {
    setCurrentLang(val);
    if (val.languageCode === lang) {
      setTempLang && setTempLang(null);
    } else {
      setTempLang && setTempLang(val);
      if (onLangChange) {
        await onLangChange(val.languageCode, sid).then((newCode) => {
          let changedVal = supportedLanguages?.find((el) => el.languageCode === newCode);
          setCurrentLang(changedVal);
        });
      }
    }
  }

  const CustomOption = (props: any) => (
    <Option {...props}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
        {showFlag && <FlagIcon code={props.data.languageCode.split('-')[1]} minimized={minimized} />}
        {props.data.label}
      </div>
      {props.data.status === 'COMING_SOON' ? <span>Coming Soon</span> : null}
    </Option>
  );

  const CustomSingleValue = (props: any) => (
    <components.SingleValue {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {showFlag && <FlagIcon code={props.data.languageCode.split('-')[1]} minimized={minimized} />}
        {props.children}
      </div>
    </components.SingleValue>
  );

  return (
    <Select
      value={currentLang}
      placeholder={placeholder}
      className="lang-input"
      classNamePrefix="select"
      isDisabled={disabled}
      isLoading={false}
      isClearable={false}
      isRtl={false}
      isSearchable={isSearchable}
      name="lang-selector"
      options={supportedLanguages}
      isOptionDisabled={(option) => option.status !== 'ACTIVE'}
      getOptionValue={(option) => option.languageCode}
      onChange={changeCurrentLang}
      components={{
        IndicatorSeparator: () => null,
        Option: CustomOption,
        SingleValue: CustomSingleValue,
      }}
      menuPortalTarget={document.body}
      styles={{
        menu: (base) => ({
          ...base,
          height: isMobileDevice ? '92px' : 'fit-content',
          overflow: 'scroll',
        }),

        option: (base) => ({
          ...base,
          cursor: 'pointer',
          height: '100%',
          zIndex: '2',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: minimized ? '12px' : '14px',
          span: {
            width: '64px',
            height: '10px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '6px',
            lineHeight: '14px',
            backgroundColor: COLORS.peech_gray_100,
            color: COLORS.peech_gray_400,
            borderRadius: '2px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }),
        control: (base) => ({
          ...base,
          cursor: 'pointer',
          height: minimized ? '28px' : '36px',
          backgroundColor: 'inherit',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '19px',
          textAlign: 'start',
          boxShadow: 'none',
          color: COLORS.peech_gray_500,
          minHeight: minimized ? '28px' : '36px',
          borderColor:
            selectorColor === LangSelectorColors.WHITE
              ? COLORS.peech_neutral_white
              : selectorColor === LangSelectorColors.GRAY
              ? COLORS.peech_gray_300
              : COLORS.peech_primary_2_500,
          '&:hover': {
            borderColor:
              selectorColor === LangSelectorColors.WHITE
                ? COLORS.peech_neutral_white
                : selectorColor === LangSelectorColors.GRAY
                ? COLORS.peech_gray_300
                : COLORS.peech_primary_2_500,
          },
        }),
        singleValue: (provided) => ({
          ...provided,
          color:
            selectorColor === LangSelectorColors.WHITE
              ? COLORS.peech_neutral_white
              : selectorColor === LangSelectorColors.GRAY
              ? COLORS.peech_gray_500
              : COLORS.peech_primary_2_500,
          paddingBottom: minimized ? '3px' : '0px',
        }),

        valueContainer: (provided) => ({
          ...provided,
          height: minimized ? '28px' : '36px',
          padding: '0 6px',
        }),

        input: (provided, state) => ({
          ...provided,
          margin: '0px',
        }),

        indicatorsContainer: (provided) => ({
          ...provided,
          height: minimized ? '28px' : '36px',
        }),

        dropdownIndicator: (base, state) => ({
          ...base,
          transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
          color:
            selectorColor === LangSelectorColors.WHITE
              ? COLORS.peech_neutral_white
              : selectorColor === LangSelectorColors.GRAY
              ? COLORS.peech_gray_300
              : COLORS.peech_primary_2_500,
          '&:hover': {
            color:
              selectorColor === LangSelectorColors.WHITE
                ? COLORS.peech_neutral_white
                : selectorColor === LangSelectorColors.GRAY
                ? COLORS.peech_gray_300
                : COLORS.peech_primary_2_500,
          },
          svg: {
            height: '17px',
          },
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
    />
  );
}
