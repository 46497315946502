
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'
import GraphicCuePoint, { IGraphicCuePoint, IGraphicCuePointFilter, IGraphicCuePointProperties } from '../graphicCuePoint'
import Speaker, { ISpeaker, ISpeakerProperties } from '.././speaker'

export enum SpeakerCuePointSourceStatus {
	PENDING = 1,
	READY = 2,
	INIT = 3,
};

export interface ISpeakerCuePoint extends IGraphicCuePoint {
	name?: string | null;
	nameColor?: string | null;
	titleColor?: string | null;
	nameColorIndex?: number | null;
	titleColorIndex?: number | null;
	nameBackgroundColor?: string | null;
	titleBackgroundColor?: string | null;
	nameBackgroundColorIndex?: number | null;
	titleBackgroundColorIndex?: number | null;
	index?: number | null;
	speakerSid?: string | null;
	speaker?: Speaker | null;
}

export interface ISpeakerCuePointProperties extends IGraphicCuePointProperties {
	name?: boolean;
	nameColor?: boolean;
	titleColor?: boolean;
	nameColorIndex?: boolean;
	titleColorIndex?: boolean;
	nameBackgroundColor?: boolean;
	titleBackgroundColor?: boolean;
	nameBackgroundColorIndex?: boolean;
	titleBackgroundColorIndex?: boolean;
	index?: boolean;
	speakerSid?: boolean;
	speaker?: ISpeakerProperties;
}

export interface ISpeakerCuePointFilter extends IGraphicCuePointFilter {
}

export class SpeakerCuePoint<IType extends ISpeakerCuePoint = ISpeakerCuePoint, ITypeProperties extends ISpeakerCuePointProperties = ISpeakerCuePointProperties> extends GraphicCuePoint<IType, ITypeProperties> implements ISpeakerCuePoint {
	
	static className = 'SpeakerCuePoint';
	static NAME = 'speakerCuePoint';
	static PKS = [
		'sequenceSid',
		'sid',
	];
	static PROPERTIES = {...GraphicCuePoint.PROPERTIES, 
		name: true,
		nameColor: true,
		titleColor: true,
		nameColorIndex: true,
		titleColorIndex: true,
		nameBackgroundColor: true,
		titleBackgroundColor: true,
		nameBackgroundColorIndex: true,
		titleBackgroundColorIndex: true,
		index: true,
		speakerSid: true,
		speaker: Speaker.PROPERTIES,
	};
	
	/**
	 * @deprecated use SpeakerCuePointSourceStatus instead
	 */
	static SOURCE_STATUS: any = {
		PENDING: SpeakerCuePointSourceStatus.PENDING,
		READY: SpeakerCuePointSourceStatus.READY,
		INIT: SpeakerCuePointSourceStatus.INIT,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		if(values.speaker) {
			if(this.speaker) {
				this.speaker.set(values.speaker, this, 'speaker');
			}
			else {
				this.values.speaker = Base.asType<ISpeaker>(values.speaker, Speaker, this, 'speaker') as Speaker;
			}
			delete values.speaker;
		}
		return super.set(values, parent, name, ignoreModified);
	}
	
	get name(): string | null | undefined {
		return this.values.name
	}
			
	set name(value: string | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	get nameColor(): string | null | undefined {
		return this.values.nameColor
	}
			
	set nameColor(value: string | null | undefined) {
		if(this.values.nameColor !== value) {
			this.propertyChanged('nameColor', value);
			this.values.nameColor = value;
		}
	}
			
	get titleColor(): string | null | undefined {
		return this.values.titleColor
	}
			
	set titleColor(value: string | null | undefined) {
		if(this.values.titleColor !== value) {
			this.propertyChanged('titleColor', value);
			this.values.titleColor = value;
		}
	}
			
	get nameColorIndex(): number | null | undefined {
		return this.values.nameColorIndex
	}
			
	set nameColorIndex(value: number | null | undefined) {
		if(this.values.nameColorIndex !== value) {
			this.propertyChanged('nameColorIndex', value);
			this.values.nameColorIndex = value;
		}
	}
			
	get titleColorIndex(): number | null | undefined {
		return this.values.titleColorIndex
	}
			
	set titleColorIndex(value: number | null | undefined) {
		if(this.values.titleColorIndex !== value) {
			this.propertyChanged('titleColorIndex', value);
			this.values.titleColorIndex = value;
		}
	}
			
	get nameBackgroundColor(): string | null | undefined {
		return this.values.nameBackgroundColor
	}
			
	set nameBackgroundColor(value: string | null | undefined) {
		if(this.values.nameBackgroundColor !== value) {
			this.propertyChanged('nameBackgroundColor', value);
			this.values.nameBackgroundColor = value;
		}
	}
			
	get titleBackgroundColor(): string | null | undefined {
		return this.values.titleBackgroundColor
	}
			
	set titleBackgroundColor(value: string | null | undefined) {
		if(this.values.titleBackgroundColor !== value) {
			this.propertyChanged('titleBackgroundColor', value);
			this.values.titleBackgroundColor = value;
		}
	}
			
	get nameBackgroundColorIndex(): number | null | undefined {
		return this.values.nameBackgroundColorIndex
	}
			
	set nameBackgroundColorIndex(value: number | null | undefined) {
		if(this.values.nameBackgroundColorIndex !== value) {
			this.propertyChanged('nameBackgroundColorIndex', value);
			this.values.nameBackgroundColorIndex = value;
		}
	}
			
	get titleBackgroundColorIndex(): number | null | undefined {
		return this.values.titleBackgroundColorIndex
	}
			
	set titleBackgroundColorIndex(value: number | null | undefined) {
		if(this.values.titleBackgroundColorIndex !== value) {
			this.propertyChanged('titleBackgroundColorIndex', value);
			this.values.titleBackgroundColorIndex = value;
		}
	}
			
	get index(): number | null | undefined {
		return this.values.index
	}
			
	set index(value: number | null | undefined) {
		if(this.values.index !== value) {
			this.propertyChanged('index', value);
			this.values.index = value;
		}
	}
			
	get speakerSid(): string | null | undefined {
		return this.values.speakerSid
	}
			
	set speakerSid(value: string | null | undefined) {
		if(this.values.speakerSid !== value) {
			this.propertyChanged('speakerSid', value);
			this.values.speakerSid = value;
		}
	}
			
	get speaker(): Speaker | null | undefined {
		return this.values.speaker
	}
			
	
	static get<IType extends ISpeakerCuePoint = ISpeakerCuePoint>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends ISpeakerCuePointFilter = ISpeakerCuePointFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends ISpeakerCuePointFilter = ISpeakerCuePointFilter, IType extends ISpeakerCuePoint = ISpeakerCuePoint>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
