import styled from '@emotion/styled/macro';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Dropzone from 'react-dropzone';
import { COLORS } from '../../../constants/colors';
import SVG from '../../components/svg';
import '../Library.scss';

export const Container = styled.div<{ isDragActive?: boolean }>`
  position: relative;
  padding-block: 14px 10px;
  width: 100%;
  min-width: 200px;
  aspect-ratio: 16/9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: ${({ isDragActive }): string => (isDragActive ? '2px dotted' : '1px solid')};
  border-color: ${COLORS.peech_gray_100};
  border-color: ${({ isDragActive }): string => (isDragActive ? COLORS.peech_gray_300 : COLORS.peech_gray_100)};
  border-radius: 10px;
  background-color: ${COLORS.peech_neutral_white};
  cursor: pointer;

  svg {
    animation: ${({ isDragActive }): string => (isDragActive ? 'resize 0.3s 0.5s forwards' : 'normal')};
    .highlight {
      animation: ${({ isDragActive }): string => (isDragActive ? 'slide 0.5s forwards' : 'normal')};
    }

    .highlight-color {
      fill: ${COLORS.peech_primary_2_500};
      stroke: ${COLORS.peech_gray_100};
    }
  }

  &:hover {
    svg {
      animation: resize 0.3s 0.5s forwards;
      .highlight {
        animation: slide 0.5s forwards;
      }
    }
  }

  .logo-section-branding--header {
    height: 100%;
    justify-content: space-around;
    padding: 5% 5px;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  min-width: 200px;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  p {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }
  span {
    font-size: 12px;
    color: ${COLORS.peech_gray_500};
  }

  @media (max-width: 1600px) {
    p {
      font-size: 16px;
    }
    span {
      font-size: 10px;
    }
  }
`;

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  svg {
    width: 26%;
    fill: ${COLORS.peech_gray_50};
    stroke: ${COLORS.peech_gray_300};
  }

  @media (max-width: 1600px) {
    svg {
      width: 20%;
    }
  }
`;

interface UploadNewAssetButtonButtonProps {
  onUpload: Function;
  setUploadFile: (val: boolean) => void;
  isDragActive: boolean;
  setIsDragActive: (val: boolean) => void;
}

export default function UploadNewAssetButton({
  onUpload,
  setUploadFile,
  isDragActive,
  setIsDragActive,
}: UploadNewAssetButtonButtonProps) {
  return (
    <Col
      xs="12"
      md="6"
      lg="4"
      xl="3"
      className="assets-col"
      onClick={(e) => {
        setUploadFile && setUploadFile(false);
      }}
    >
      <Container isDragActive={isDragActive}>
        <Dropzone
          onDragLeave={() => setIsDragActive(false)}
          onDragEnter={() => setIsDragActive(true)}
          onDrop={(files, reject, evt) => {
            evt.stopPropagation();
            onUpload(files);
          }}
          accept="video/*"
        >
          {({ getRootProps, getInputProps }) => (
            <div className="logo-section-branding--header" {...getRootProps()}>
              <input id="branding-logo-input" {...getInputProps()} />
              <>
                <HeaderContainer>
                  <p>Upload video content</p>
                  <span>Drag and drop your files here</span>
                </HeaderContainer>
                <IconContainer>
                  <SVG name="upload-content" viewBox="0 0 94 76" />
                </IconContainer>
              </>
            </div>
          )}
        </Dropzone>
      </Container>
    </Col>
  );
}
