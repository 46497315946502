import { useTransition } from '@react-spring/web'
import classNames from 'classnames'
import React from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import { GoogleLoginResponse, useGoogleLogin } from 'react-google-login'
import SVG from '../../components/svg'
import { AppContext } from '../../lib/app-context'
import { removeChapterCuePoints } from '../../state/cuePoints'
import { handleError } from '../../state/error'
import { removeScene, scenesState, sequenceState } from '../../state/sequence'
import Button from '../../ui-components/Button'
import SceneUploader from '../../ui-components/SceneUploader'
import { trackEvent } from '../../utils/analityics.utils'
import Library from '../library'






const MAX_FILES_ALLOWED = 5;

interface WizardUploadProps {
    onChapterFileSelected(file: File): void;
    transition(): void;
    uploading: boolean;
    setUploading(boolean): void;

}

export default function WizardUpload({ onChapterFileSelected, uploading, setUploading, addAsset }: WizardUploadProps) {
    const sequence = sequenceState.use()
    const chapters = scenesState.use(scenes => scenes.filter.chapters)
    const { config } = React.useContext(AppContext)
    const [libraryUpload, setLibraryUpload] = React.useState(false);
    const [selectedAssets, setSelectedAssets] = React.useState<string[]>([])

    const { user } = React.useContext(AppContext)

    const [googleLoginToken, setGoogleLoginToken] = React.useState(false);
    const { signIn } = config.GOOGLE_CLIENT_ID ? useGoogleLogin({
        isSignedIn: true,
        clientId: config.GOOGLE_CLIENT_ID,
        onSuccess: onGoogleLoginSuccess,
        onAutoLoadFinished: onGoogleLoginLoaded,
        scope: ['https://www.googleapis.com/auth/drive.file'],
    }) : {};

    const isSelected = (asset) => selectedAssets.findIndex(a => a.sid === asset.sid) > -1

    function onAssetSelected(e, asset) {
        e.preventDefault()
        const assetIndex = selectedAssets.findIndex(a => a.sid === asset.sid)
        if (assetIndex > -1) {
            const newArray = selectedAssets.filter(a => a.sid !== asset.sid)
            setSelectedAssets(newArray)
        } else {
            setSelectedAssets([...selectedAssets, asset])

        }
    }
    async function addSelectedAssets() {
        setLibraryUpload(false)
        for (const asset of selectedAssets) {
            await addAsset(asset)
        }
        setSelectedAssets([])
    }

    async function onChapterFileInputChange(files) {
        if (!files.length) {
            return
        }
        if (files.length > MAX_FILES_ALLOWED) {
            return handleError({
                title: 'Failed to upload files',
                message: `Cannot upload more than ${MAX_FILES_ALLOWED} files`,
            })
        }
        for (const file of files) {
            await onChapterFileSelected(file)
        }

        console.log('Uploading...')
        setUploading(true)
    }

    function onChapterRemove(sid: string) {
        removeChapterCuePoints(sid)
        sequence.deleteChapter(sid);
        removeScene(sid, true);
        trackEvent('scene-delete');

    }

    /**
     * @param {boolean} successLogin
            */
    function onGoogleLoginLoaded(successLogin) {
        console.log('onGoogleLoginLoaded', successLogin)
        if (document.location.hash === '#google') {
            successLogin || signIn();
        }
    }

    /**
     * @param {GoogleLoginResponse} response
            */
    function onGoogleLoginSuccess(response) {
        console.log('onGoogleLoginSuccess', response)
        if (document.location.hash === '#google') {
            setGoogleLoginToken(response.accessToken)
        }
    }

    const rightTransition = useTransition(uploading, {
        config: { duration: 400 },
        from: { x: -100, y: 500, opacity: 0.1 },
        enter: { x: 0, y: 0, opacity: 1 }
    })
    const leftTransition = useTransition(uploading, {
        config: { duration: 400 },
        from: { x: 100, y: 500, opacity: 0.1 },
        enter: { x: 0, y: 0, opacity: 1 }
    })

    return (

        <div className="wizard-upload--wrapper"
            onClick={e => e.stopPropagation()}>
            {uploading && !!chapters.length && <div className='wizard-upload-actions'>
                {<a>
                    <div className='action' onClick={() => setLibraryUpload(true)}>
                        <SVG name="create-library" />
                    </div>
                </a>}
            </div>}
            <div className='wizard-upload'>
                <div className={classNames('wizard-upload--header-wrapper', { "uploading": uploading && chapters.length })}   >
                    <h2 className={classNames("wizard-upload-title-init", { 'uploading': chapters.length })}>
                        Ready
                        <span>
                            to create a new video?
                        </span>
                    </h2>
                    <div className="upload-wrapper">

                        <div className="wizard-upload--content" data-cy="open-lib" onClick={(e) => {
                            trackEvent('create-wizard-select-footage', { src: 'library' });
                            setLibraryUpload(true)
                        }} >

                            <SVG name='arrow' viewBox="0 0 127 165" className='arrow' fill='none' />

                            <div className="upload-icon illustration-wrapper">
                                <SVG name="icon-background" className="illustration-svg" id="illustration-background" viewBox="0 0 156 140" />
                                <SVG name="library-upload-illustration" className="illustration" viewBox="0 0 156 140" />
                            </div>

                            <div className="wizard-upload--description--content">
                                <span className='regular' style={{ marginBottom: "29px" }} ><span className='bold'>Select your videos </span><br /> from your library </span>
                            </div>
                        </div>

                    </div>

                </div>

                <Modal
                    className='wizard-upload-modal'
                    show={libraryUpload}
                    onHide={() => setLibraryUpload(false)}
                >
                    <ModalBody className='upload-modal-body'>
                        <div className='content-title'>Content Library</div>
                        <a className="close" onClick={() => setLibraryUpload(false)}>
                            <SVG name="close" viewBox='0 0 24 24' />
                        </a>
                        <Library isModal={true} isSelected={isSelected} onAssetSelected={onAssetSelected} addSelectedAssets={addSelectedAssets} />
                        <div className='add-button-wrapper'>
                            <Button label='Add' size="md" variant='primary' disabled={!selectedAssets?.length} data-cy="lib-add-btn" onClick={addSelectedAssets} />
                        </div>
                    </ModalBody>
                </Modal>

                {
                    uploading && <div className="upload-progress">
                        {chapters.map(chapter => <SceneUploader
                            key={chapter.sid}
                            sid={chapter.sid}
                            assetSid={chapter.assetSid}
                            thumb={chapter.thumb}
                            name={chapter.name}
                            fileSize={chapter.size}
                            duration={chapter.duration}
                            onRemove={onChapterRemove}
                            hideProgress={true}
                        />)}
                    </div>
                }
            </div>
        </div>
    )
}
