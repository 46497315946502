
import * as Base from './base/track';


export interface ITrack extends Base.ITrack {

}

export interface ITrackProperties extends Base.ITrackProperties {

}

export interface ITrackFilter extends Base.ITrackFilter {

}

export default class Track<IType extends ITrack = ITrack, ITypeProperties extends ITrackProperties = ITrackProperties> extends Base.Track<IType, ITypeProperties> implements ITrack {
}