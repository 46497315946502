import React from 'react';


function returnFalse(e) {
    e.returnValue = 'Your changes won\'t be saved, are you sure ?';
    return false;
}

interface BeforeUnloadProps {
}

export function BeforeUnload({ ...props }: BeforeUnloadProps & {}) {

    const onMount = () => window.addEventListener('beforeunload', returnFalse)
    const onUnMount = () => window.removeEventListener('beforeunload', returnFalse)

    React.useEffect(() => {
        onMount()
        return () => {
            onUnMount()
        }
    }, [])

    return null
}