
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export enum SpeakerStatus {
	ACTIVE = 1,
	NAMED = 2,
	DELETED = 3,
};

export interface ISpeaker extends IBase {
	status?: number | null;
	userSid?: string | null;
	orgSid?: string | null;
	name?: string | null;
	title?: string | null;
}

export interface ISpeakerProperties extends IBaseProperties {
	status?: boolean;
	userSid?: boolean;
	orgSid?: boolean;
	name?: boolean;
	title?: boolean;
}

export interface ISpeakerFilter extends IBaseFilter {
}

export class Speaker<IType extends ISpeaker = ISpeaker, ITypeProperties extends ISpeakerProperties = ISpeakerProperties> extends Base<IType, ITypeProperties> implements ISpeaker {
	
	static className = 'Speaker';
	static NAME = 'speaker';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		userSid: true,
		orgSid: true,
		name: true,
		title: true,
	};
	
	/**
	 * @deprecated use SpeakerStatus instead
	 */
	static STATUS: any = {
		ACTIVE: SpeakerStatus.ACTIVE,
		NAMED: SpeakerStatus.NAMED,
		DELETED: SpeakerStatus.DELETED,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get userSid(): string | null | undefined {
		return this.values.userSid
	}
			
	set userSid(value: string | null | undefined) {
		if(this.values.userSid !== value) {
			this.propertyChanged('userSid', value);
			this.values.userSid = value;
		}
	}
			
	get orgSid(): string | null | undefined {
		return this.values.orgSid
	}
			
	set orgSid(value: string | null | undefined) {
		if(this.values.orgSid !== value) {
			this.propertyChanged('orgSid', value);
			this.values.orgSid = value;
		}
	}
			
	get name(): string | null | undefined {
		return this.values.name
	}
			
	set name(value: string | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	get title(): string | null | undefined {
		return this.values.title
	}
			
	set title(value: string | null | undefined) {
		if(this.values.title !== value) {
			this.propertyChanged('title', value);
			this.values.title = value;
		}
	}
			
	
	static get<IType extends ISpeaker = ISpeaker>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends ISpeakerFilter = ISpeakerFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends ISpeakerFilter = ISpeakerFilter, IType extends ISpeaker = ISpeaker>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
