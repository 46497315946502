
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'
import Subscription from '../subscription'

export enum PlanStatus {
	ACTIVE = 1,
	INACTIVE = 2,
	DELETED = 3,
	DEFAULT = 4,
};

export enum PlanProvider {
	FREE_TRIAL = 1,
	CUSTOM = 2,
	PADDLE = 3,
	BLUESNAP = 4,
};

export enum PlanPlanType {
	NONE = 1,
	INDIVIDUALS = 2,
	BUSINESS = 3,
	ENTERPRISE = 4,
};

export interface IPlan extends IBase {
	status?: number | null;
	name?: string | null;
	internalName?: string | null;
	planType?: PlanPlanType | null;
	icon?: string | null;
	provider?: PlanProvider | null;
	recurringPrice?: number | null;
	overrideRecurringPrice?: number | null;
	annualRecurringPrice?: number | null;
	annualOverrideRecurringPrice?: number | null;
	salesOnly?: boolean | null;
	hidden?: boolean | null;
	description?: string | null;
	features?: string[] | null;
	gracePeriod?: number | null;
	freeTrial?: number | null;
	chargeOnPlanSwitch?: boolean | null;
	planId?: number | null;
	annualPlanId?: number | null;
	maxVideoDuration?: number | null;
	maxVideos?: number | null;
	maxFootageUploads?: number | null;
	maxFootageUploadMinutesDuration?: number | null;
	maxUsers?: number | null;
	maxStorage?: number | null;
	brandsCount?: number | null;
	buttonColor?: string | null;
	annualDiscountPrice?: number | null;
	annualDiscountPercentage?: number | null;
	promptTag?: string | null;
	showShutterStockBadge?: boolean | null;
	buttonAditionalColor?: string | null;
	tier?: number | null;
	subHeader?: string | null;
	pricePerSeatMonthly?: number | null;
	pricePerSeatAnnually?: number | null;
	periodMaxDownloads?: number | null;
}

export interface IPlanProperties extends IBaseProperties {
	status?: boolean;
	name?: boolean;
	internalName?: boolean;
	planType?: boolean;
	icon?: boolean;
	provider?: boolean;
	recurringPrice?: boolean;
	overrideRecurringPrice?: boolean;
	annualRecurringPrice?: boolean;
	annualOverrideRecurringPrice?: boolean;
	salesOnly?: boolean;
	hidden?: boolean;
	description?: boolean;
	features?: boolean;
	gracePeriod?: boolean;
	freeTrial?: boolean;
	chargeOnPlanSwitch?: boolean;
	planId?: boolean;
	annualPlanId?: boolean;
	maxVideoDuration?: boolean;
	maxVideos?: boolean;
	maxFootageUploads?: boolean;
	maxFootageUploadMinutesDuration?: boolean;
	maxUsers?: boolean;
	maxStorage?: boolean;
	brandsCount?: boolean;
	buttonColor?: boolean;
	annualDiscountPrice?: boolean;
	annualDiscountPercentage?: boolean;
	promptTag?: boolean;
	showShutterStockBadge?: boolean;
	buttonAditionalColor?: boolean;
	tier?: boolean;
	subHeader?: boolean;
	pricePerSeatMonthly?: boolean;
	pricePerSeatAnnually?: boolean;
	periodMaxDownloads?: boolean;
}

export interface IPlanFilter extends IBaseFilter {
	planTypeEqual?: string;
	hiddenEqual?: boolean;
}

export class Plan<IType extends IPlan = IPlan, ITypeProperties extends IPlanProperties = IPlanProperties> extends Base<IType, ITypeProperties> implements IPlan {
	
	static className = 'Plan';
	static NAME = 'plan';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		name: true,
		internalName: true,
		planType: true,
		icon: true,
		provider: true,
		recurringPrice: true,
		overrideRecurringPrice: true,
		annualRecurringPrice: true,
		annualOverrideRecurringPrice: true,
		salesOnly: true,
		hidden: true,
		description: true,
		features: true,
		gracePeriod: true,
		freeTrial: true,
		chargeOnPlanSwitch: true,
		planId: true,
		annualPlanId: true,
		maxVideoDuration: true,
		maxVideos: true,
		maxFootageUploads: true,
		maxFootageUploadMinutesDuration: true,
		maxUsers: true,
		maxStorage: true,
		brandsCount: true,
		buttonColor: true,
		annualDiscountPrice: true,
		annualDiscountPercentage: true,
		promptTag: true,
		showShutterStockBadge: true,
		buttonAditionalColor: true,
		tier: true,
		subHeader: true,
		pricePerSeatMonthly: true,
		pricePerSeatAnnually: true,
		periodMaxDownloads: true,
	};
	
	/**
	 * @deprecated use PlanStatus instead
	 */
	static STATUS: any = {
		ACTIVE: PlanStatus.ACTIVE,
		INACTIVE: PlanStatus.INACTIVE,
		DELETED: PlanStatus.DELETED,
		DEFAULT: PlanStatus.DEFAULT,
	};
	
	/**
	 * @deprecated use PlanProvider instead
	 */
	static PROVIDER: any = {
		FREE_TRIAL: PlanProvider.FREE_TRIAL,
		CUSTOM: PlanProvider.CUSTOM,
		PADDLE: PlanProvider.PADDLE,
		BLUESNAP: PlanProvider.BLUESNAP,
	};
	
	/**
	 * @deprecated use PlanPlanType instead
	 */
	static PLAN_TYPE: any = {
		NONE: PlanPlanType.NONE,
		INDIVIDUALS: PlanPlanType.INDIVIDUALS,
		BUSINESS: PlanPlanType.BUSINESS,
		ENTERPRISE: PlanPlanType.ENTERPRISE,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get name(): string | null | undefined {
		return this.values.name
	}
			
	set name(value: string | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	get internalName(): string | null | undefined {
		return this.values.internalName
	}
			
	set internalName(value: string | null | undefined) {
		if(this.values.internalName !== value) {
			this.propertyChanged('internalName', value);
			this.values.internalName = value;
		}
	}
			
	get planType(): PlanPlanType | null | undefined {
		return this.values.planType
	}
			
	set planType(value: PlanPlanType | null | undefined) {
		if(this.values.planType !== value) {
			this.propertyChanged('planType', value);
			this.values.planType = value;
		}
	}
			
	get icon(): string | null | undefined {
		return this.values.icon
	}
			
	set icon(value: string | null | undefined) {
		if(this.values.icon !== value) {
			this.propertyChanged('icon', value);
			this.values.icon = value;
		}
	}
			
	get provider(): PlanProvider | null | undefined {
		return this.values.provider
	}
			
	set provider(value: PlanProvider | null | undefined) {
		if(this.values.provider !== value) {
			this.propertyChanged('provider', value);
			this.values.provider = value;
		}
	}
			
	get recurringPrice(): number | null | undefined {
		return this.values.recurringPrice
	}
			
	set recurringPrice(value: number | null | undefined) {
		if(this.values.recurringPrice !== value) {
			this.propertyChanged('recurringPrice', value);
			this.values.recurringPrice = value;
		}
	}
			
	get overrideRecurringPrice(): number | null | undefined {
		return this.values.overrideRecurringPrice
	}
			
	set overrideRecurringPrice(value: number | null | undefined) {
		if(this.values.overrideRecurringPrice !== value) {
			this.propertyChanged('overrideRecurringPrice', value);
			this.values.overrideRecurringPrice = value;
		}
	}
			
	get annualRecurringPrice(): number | null | undefined {
		return this.values.annualRecurringPrice
	}
			
	set annualRecurringPrice(value: number | null | undefined) {
		if(this.values.annualRecurringPrice !== value) {
			this.propertyChanged('annualRecurringPrice', value);
			this.values.annualRecurringPrice = value;
		}
	}
			
	get annualOverrideRecurringPrice(): number | null | undefined {
		return this.values.annualOverrideRecurringPrice
	}
			
	set annualOverrideRecurringPrice(value: number | null | undefined) {
		if(this.values.annualOverrideRecurringPrice !== value) {
			this.propertyChanged('annualOverrideRecurringPrice', value);
			this.values.annualOverrideRecurringPrice = value;
		}
	}
			
	get salesOnly(): boolean | null | undefined {
		return this.values.salesOnly
	}
			
	set salesOnly(value: boolean | null | undefined) {
		if(this.values.salesOnly !== value) {
			this.propertyChanged('salesOnly', value);
			this.values.salesOnly = value;
		}
	}
			
	get hidden(): boolean | null | undefined {
		return this.values.hidden
	}
			
	set hidden(value: boolean | null | undefined) {
		if(this.values.hidden !== value) {
			this.propertyChanged('hidden', value);
			this.values.hidden = value;
		}
	}
			
	get description(): string | null | undefined {
		return this.values.description
	}
			
	set description(value: string | null | undefined) {
		if(this.values.description !== value) {
			this.propertyChanged('description', value);
			this.values.description = value;
		}
	}
			
	get features(): string[] | null | undefined {
		return this.values.features
	}
			
	set features(value: string[] | null | undefined) {
		if(this.values.features !== value) {
			this.propertyChanged('features', value);
			this.values.features = value;
		}
	}
			
	get gracePeriod(): number | null | undefined {
		return this.values.gracePeriod
	}
			
	set gracePeriod(value: number | null | undefined) {
		if(this.values.gracePeriod !== value) {
			this.propertyChanged('gracePeriod', value);
			this.values.gracePeriod = value;
		}
	}
			
	get freeTrial(): number | null | undefined {
		return this.values.freeTrial
	}
			
	set freeTrial(value: number | null | undefined) {
		if(this.values.freeTrial !== value) {
			this.propertyChanged('freeTrial', value);
			this.values.freeTrial = value;
		}
	}
			
	get chargeOnPlanSwitch(): boolean | null | undefined {
		return this.values.chargeOnPlanSwitch
	}
			
	set chargeOnPlanSwitch(value: boolean | null | undefined) {
		if(this.values.chargeOnPlanSwitch !== value) {
			this.propertyChanged('chargeOnPlanSwitch', value);
			this.values.chargeOnPlanSwitch = value;
		}
	}
			
	get planId(): number | null | undefined {
		return this.values.planId
	}
			
	set planId(value: number | null | undefined) {
		if(this.values.planId !== value) {
			this.propertyChanged('planId', value);
			this.values.planId = value;
		}
	}
			
	get annualPlanId(): number | null | undefined {
		return this.values.annualPlanId
	}
			
	set annualPlanId(value: number | null | undefined) {
		if(this.values.annualPlanId !== value) {
			this.propertyChanged('annualPlanId', value);
			this.values.annualPlanId = value;
		}
	}
			
	get maxVideoDuration(): number | null | undefined {
		return this.values.maxVideoDuration
	}
			
	set maxVideoDuration(value: number | null | undefined) {
		if(this.values.maxVideoDuration !== value) {
			this.propertyChanged('maxVideoDuration', value);
			this.values.maxVideoDuration = value;
		}
	}
			
	get maxVideos(): number | null | undefined {
		return this.values.maxVideos
	}
			
	set maxVideos(value: number | null | undefined) {
		if(this.values.maxVideos !== value) {
			this.propertyChanged('maxVideos', value);
			this.values.maxVideos = value;
		}
	}
			
	get maxFootageUploads(): number | null | undefined {
		return this.values.maxFootageUploads
	}
			
	set maxFootageUploads(value: number | null | undefined) {
		if(this.values.maxFootageUploads !== value) {
			this.propertyChanged('maxFootageUploads', value);
			this.values.maxFootageUploads = value;
		}
	}
			
	get maxFootageUploadMinutesDuration(): number | null | undefined {
		return this.values.maxFootageUploadMinutesDuration
	}
			
	set maxFootageUploadMinutesDuration(value: number | null | undefined) {
		if(this.values.maxFootageUploadMinutesDuration !== value) {
			this.propertyChanged('maxFootageUploadMinutesDuration', value);
			this.values.maxFootageUploadMinutesDuration = value;
		}
	}
			
	get maxUsers(): number | null | undefined {
		return this.values.maxUsers
	}
			
	set maxUsers(value: number | null | undefined) {
		if(this.values.maxUsers !== value) {
			this.propertyChanged('maxUsers', value);
			this.values.maxUsers = value;
		}
	}
			
	get maxStorage(): number | null | undefined {
		return this.values.maxStorage
	}
			
	set maxStorage(value: number | null | undefined) {
		if(this.values.maxStorage !== value) {
			this.propertyChanged('maxStorage', value);
			this.values.maxStorage = value;
		}
	}
			
	get brandsCount(): number | null | undefined {
		return this.values.brandsCount
	}
			
	set brandsCount(value: number | null | undefined) {
		if(this.values.brandsCount !== value) {
			this.propertyChanged('brandsCount', value);
			this.values.brandsCount = value;
		}
	}
			
	get buttonColor(): string | null | undefined {
		return this.values.buttonColor
	}
			
	set buttonColor(value: string | null | undefined) {
		if(this.values.buttonColor !== value) {
			this.propertyChanged('buttonColor', value);
			this.values.buttonColor = value;
		}
	}
			
	get annualDiscountPrice(): number | null | undefined {
		return this.values.annualDiscountPrice
	}
			
	set annualDiscountPrice(value: number | null | undefined) {
		if(this.values.annualDiscountPrice !== value) {
			this.propertyChanged('annualDiscountPrice', value);
			this.values.annualDiscountPrice = value;
		}
	}
			
	get annualDiscountPercentage(): number | null | undefined {
		return this.values.annualDiscountPercentage
	}
			
	set annualDiscountPercentage(value: number | null | undefined) {
		if(this.values.annualDiscountPercentage !== value) {
			this.propertyChanged('annualDiscountPercentage', value);
			this.values.annualDiscountPercentage = value;
		}
	}
			
	get promptTag(): string | null | undefined {
		return this.values.promptTag
	}
			
	set promptTag(value: string | null | undefined) {
		if(this.values.promptTag !== value) {
			this.propertyChanged('promptTag', value);
			this.values.promptTag = value;
		}
	}
			
	get showShutterStockBadge(): boolean | null | undefined {
		return this.values.showShutterStockBadge
	}
			
	set showShutterStockBadge(value: boolean | null | undefined) {
		if(this.values.showShutterStockBadge !== value) {
			this.propertyChanged('showShutterStockBadge', value);
			this.values.showShutterStockBadge = value;
		}
	}
			
	get buttonAditionalColor(): string | null | undefined {
		return this.values.buttonAditionalColor
	}
			
	set buttonAditionalColor(value: string | null | undefined) {
		if(this.values.buttonAditionalColor !== value) {
			this.propertyChanged('buttonAditionalColor', value);
			this.values.buttonAditionalColor = value;
		}
	}
			
	get tier(): number | null | undefined {
		return this.values.tier
	}
			
	set tier(value: number | null | undefined) {
		if(this.values.tier !== value) {
			this.propertyChanged('tier', value);
			this.values.tier = value;
		}
	}
			
	get subHeader(): string | null | undefined {
		return this.values.subHeader
	}
			
	set subHeader(value: string | null | undefined) {
		if(this.values.subHeader !== value) {
			this.propertyChanged('subHeader', value);
			this.values.subHeader = value;
		}
	}
			
	get pricePerSeatMonthly(): number | null | undefined {
		return this.values.pricePerSeatMonthly
	}
			
	set pricePerSeatMonthly(value: number | null | undefined) {
		if(this.values.pricePerSeatMonthly !== value) {
			this.propertyChanged('pricePerSeatMonthly', value);
			this.values.pricePerSeatMonthly = value;
		}
	}
			
	get pricePerSeatAnnually(): number | null | undefined {
		return this.values.pricePerSeatAnnually
	}
			
	set pricePerSeatAnnually(value: number | null | undefined) {
		if(this.values.pricePerSeatAnnually !== value) {
			this.propertyChanged('pricePerSeatAnnually', value);
			this.values.pricePerSeatAnnually = value;
		}
	}
			
	get periodMaxDownloads(): number | null | undefined {
		return this.values.periodMaxDownloads
	}
			
	set periodMaxDownloads(value: number | null | undefined) {
		if(this.values.periodMaxDownloads !== value) {
			this.propertyChanged('periodMaxDownloads', value);
			this.values.periodMaxDownloads = value;
		}
	}
			
	
	static get<IType extends IPlan = IPlan>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IPlanFilter = IPlanFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IPlanFilter = IPlanFilter, IType extends IPlan = IPlan>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
	
	async allowedTierFeatures(): Promise<Array<string> | null> {
		var action = `allowedTierFeatures`;
		const query = this.wrap('query', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.allowedTierFeatures !== undefined && data.allowedTierFeatures !== null) {
			return data.allowedTierFeatures;
		}
		return null;
	}
	
	async disallowedTierFeatures(): Promise<Array<string> | null> {
		var action = `disallowedTierFeatures`;
		const query = this.wrap('query', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.disallowedTierFeatures !== undefined && data.disallowedTierFeatures !== null) {
			return data.disallowedTierFeatures;
		}
		return null;
	}
	
	async enable(): Promise<boolean | null> {
		var action = `enable`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.enable !== undefined && data.enable !== null) {
			return data.enable;
		}
		return null;
	}
	
	async disable(): Promise<boolean | null> {
		var action = `disable`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.disable !== undefined && data.disable !== null) {
			return data.disable;
		}
		return null;
	}
	
	async setAsDefault(): Promise<boolean | null> {
		var action = `setAsDefault`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.setAsDefault !== undefined && data.setAsDefault !== null) {
			return data.setAsDefault;
		}
		return null;
	}
	
	async select(subscriptionDetailts: Subscription, properties: ITypeProperties | null = null): Promise<Subscription | null> {
		properties = (properties || Subscription.PROPERTIES) as ITypeProperties;
		var action = `select(subscriptionDetailts: ${subscriptionDetailts !== undefined ? `{${subscriptionDetailts.changedValues()}}` : 'null'}){${Base.toProperties(properties)}}`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.select !== undefined && data.select !== null) {
			var ret = new Subscription(data.select.sid);
			return ret.set(data.select) as Subscription;
		}
		return null;
	}
}
