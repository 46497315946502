
import * as Base from './base/access';


export interface IAccess extends Base.IAccess {

}

export interface IAccessProperties extends Base.IAccessProperties {

}

export interface IAccessFilter extends Base.IAccessFilter {

}

export default class Access<IType extends IAccess = IAccess, ITypeProperties extends IAccessProperties = IAccessProperties> extends Base.Access<IType, ITypeProperties> implements IAccess {
}