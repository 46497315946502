import React from 'react';
import SVG from '../../components/svg';

import classNames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import './OptionsMenu.scss';

export interface OptionsMenuAction {
  key: string;
  icon: string;
  text: string;
  toolTip: string;
  disabled?: boolean;
  hide?: boolean;
  onClick(): void;
}

interface OptionsMenu {
  options: OptionsMenuAction[];
  onToggle?: (isOpen: boolean) => void;
}

const defaultTrigger = <SVG className="options" name="options" title="options" />;

export default function OptionsMenu({
  options,
  innerRef,
  menuRef,
  open,
  className,
  trigger = defaultTrigger,
  onToggle,
}: OptionsMenu) {
  return (
    <>
      <Dropdown
        className={classNames('options-menu', className)}
        ref={innerRef}
        show={open}
        onToggle={(isOpen) => onToggle && onToggle(isOpen)}
        drop="down"
        alignRight={true}
      >
        <Dropdown.Toggle className="option--toggle" variant="white">
          {trigger}
        </Dropdown.Toggle>

        <Dropdown.Menu className="options-menu" renderOnMount={true} ref={menuRef}>
          <div className="options-menu--navigation">
            {options.map(
              (option) =>
                !option.hide && (
                  <DropdownItem
                    key={option.key}
                    className={classNames({ disabled: option.disabled, hide: option.hide })}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      option.onClick && option.onClick();
                    }}
                  >
                    <SVG name={option.icon} title={option.toolTip} />
                    {option.text}
                  </DropdownItem>
                )
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
