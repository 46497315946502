
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'
import Payment, { IPayment, IPaymentProperties } from '../invoiceList/payment'
import Income, { IIncome, IIncomeProperties } from '../invoiceList/income'
import Invoice, { IInvoice, IInvoiceProperties } from '../invoiceList/invoice'

export interface IInvoiceList extends IBase {
	total?: number | null;
	pages?: number | null;
	page?: number | null;
	pageSize?: number | null;
	items?: Invoice[] | null;
}

export interface IInvoiceListProperties extends IBaseProperties {
	total?: boolean;
	pages?: boolean;
	page?: boolean;
	pageSize?: boolean;
	items?: IInvoiceProperties;
}

export interface IInvoiceListFilter extends IBaseFilter {
}

export class InvoiceList<IType extends IInvoiceList = IInvoiceList, ITypeProperties extends IInvoiceListProperties = IInvoiceListProperties> extends Base<IType, ITypeProperties> implements IInvoiceList {
	
	static className = 'InvoiceList';
	static NAME = 'invoiceList';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		total: true,
		pages: true,
		page: true,
		pageSize: true,
		items: Invoice.PROPERTIES,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		if(values.items && values.items.length) {
			values.items = values.items.map(item => Base.asType<IInvoice>(item, Invoice, this, 'items') as Invoice);
		}
		return super.set(values, parent, name, ignoreModified);
	}
	
	get total(): number | null | undefined {
		return this.values.total
	}
			
	set total(value: number | null | undefined) {
		if(this.values.total !== value) {
			this.propertyChanged('total', value);
			this.values.total = value;
		}
	}
			
	get pages(): number | null | undefined {
		return this.values.pages
	}
			
	set pages(value: number | null | undefined) {
		if(this.values.pages !== value) {
			this.propertyChanged('pages', value);
			this.values.pages = value;
		}
	}
			
	get page(): number | null | undefined {
		return this.values.page
	}
			
	set page(value: number | null | undefined) {
		if(this.values.page !== value) {
			this.propertyChanged('page', value);
			this.values.page = value;
		}
	}
			
	get pageSize(): number | null | undefined {
		return this.values.pageSize
	}
			
	set pageSize(value: number | null | undefined) {
		if(this.values.pageSize !== value) {
			this.propertyChanged('pageSize', value);
			this.values.pageSize = value;
		}
	}
			
	get items(): Invoice[] | null | undefined {
		return this.values.items
	}
			
	set items(value: Invoice[] | null | undefined) {
		if(this.values.items !== value) {
			this.propertyChanged('items', value);
			this.values.items = value;
		}
	}
			
	
	static get<IType extends IInvoiceList = IInvoiceList>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IInvoiceListFilter = IInvoiceListFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IInvoiceListFilter = IInvoiceListFilter, IType extends IInvoiceList = IInvoiceList>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
