import { useLocation } from '@reach/router';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import LogRocket from 'logrocket';
import React from 'react';
import Base from '../../client/base/base';
import Sequence from '../../client/sequence';
import User from '../../client/user';
import { AppContext } from '../../lib/app-context';
import { cuePointsState } from '../../state/cuePoints';
import { handleError } from '../../state/error';
import { fileUploaderState } from '../../state/fileUploader';
import { shouldLoginState, shouldLogoutState } from '../../state/local';
import { playbackState } from '../../state/playback';
import { sequenceState } from '../../state/sequence';
import { trackEvent } from '../../utils/analityics.utils';

const LOGIN_OPTIONAL_ROUTES = ['plans', 'impersonate', 'configure' ,'overlay'];

export default function ClientConfiguration() {
  const { user, getUser, setUser, config } = React.useContext(AppContext);

  const location = useLocation();
  const shouldLogin = shouldLoginState.use();
  const shouldLogout = shouldLogoutState.use();
  const sequence = sequenceState.use();
  const playback = playbackState.use();
  const fileUploader = fileUploaderState.use();
  const cuePoints = Object.values(cuePointsState.use());
  const sentryInitialized = Sentry.getCurrentHub().getClient();
  const [dumpState, setDumpState] = React.useState(false);

  React.useEffect(() => {
    if (dumpState) {
      console.group('State');
      LogRocket.getSessionURL((sessionUrl) => {
        console.log('LR Session URL: ', sessionUrl);
        window['_lrsu'] = sessionUrl;
      });
      console.log('User:');
      console.table({ ...user?.values, resetToken: null, resetUrl: null });
      console.log('Sequence:');
      console.table(sequence.values);
      console.log('Playback:');
      console.table(playback);
      console.log('CuePoints:');
      console.table(cuePoints.filter((cp) => cp).map((cp) => cp.values));
      console.groupEnd();
      setDumpState(false);
    }
  }, [dumpState]);

  React.useEffect(() => {
    const intercomBoot = { api_base: 'https://api-iam.eu.intercom.io', app_id: 'm7k431u7' };
    if (user) {
      intercomBoot.email = user.email;
      intercomBoot.created_at = parseInt(user.insertTime / 1000);
      intercomBoot.name = `${user.firstName} ${user.lastName}`;
      intercomBoot.user_id = user.sid;
      intercomBoot.hide_default_launcher = true;
      Base.addErrorHandler((err) => {
        const { code, message, request, response } = err;
        handleError({
          statusCode: !isNaN(code) ? code : response?.status,
          message,
        });
        throw err;
      });
    } else {
      Base.addErrorHandler(null);
    }
    window.Intercom && window.Intercom('boot', intercomBoot);
  }, [user]);

  React.useEffect(() => {
    const isOptionalLogin = LOGIN_OPTIONAL_ROUTES.find((route) => route === location.pathname.replaceAll('/', ''));
    if (isOptionalLogin) {
      shouldLoginState.set(false);
    } else if (!shouldLogin) {
      shouldLoginState.set(true);
    }

    trackEvent(`page ${location.pathname.replace(/\//g, '-').replace(/-$/, '')}`);

    window.Intercom &&
      window.Intercom('update', {
        hide_default_launcher: true,
      });
  }, [location.pathname]);

  React.useEffect(() => {
    if (config.LOGROCKET_APP_ID) {
      LogRocket.init(config.LOGROCKET_APP_ID, {
        shouldParseXHRBlob: true,
        mergeIframes: true,
        parentDomain: '*',
      });
    }

    if (config.SENTRY_TOKEN) {
      Sentry.init({
        dsn: config.SENTRY_TOKEN,
        integrations: [new BrowserTracing()],
        tracesSampleRate: config.SENTRY_TRACES_SAMPLE_RATE || 1.0,
        environment: config.ENV_NAME,
      });
      if (config.LOGROCKET_APP_ID) {
        LogRocket.getSessionURL((sessionURL) => {
          Sentry.configureScope((scope) => {
            scope.setExtra('sessionURL', sessionURL);
          });
        });
      }
    }

    // ReactDOM.render(<App />, document.getElementById("root"));

    // // Can also use with React Concurrent Mode
    // // ReactDOM.createRoot(document.getElementById('root')).render(<App />);

    window['_pdbg'] = () => setDumpState(true);
  }, []);

  React.useEffect(() => {
    Sequence.unsetHeader('X-SequenceSid');
    if (sequence?.sid) {
      Sequence.setHeader('X-SequenceSid', sequence.sid);
    }
  }, [sequence]);

  React.useEffect(() => {
    if (sentryInitialized) {
      if (sequence) {
        Sentry.setTag('sequenceSid', sequence.sid);
      } else {
        Sentry.setTag('sequenceSid', undefined);
      }
    }
  }, [sequence, sentryInitialized]);

  React.useEffect(() => {
    if (!shouldLogin || user) {
      return;
    }

    if (User.ps && user === null) {
      getUser();
    } else {
      shouldLoginState.set(true);
    }
  }, [shouldLogin]);

  React.useEffect(() => {
    if (shouldLogout && user) {
      User.ps = null;
      setUser(null);
      shouldLogoutState.set(false);
    }
  }, [shouldLogout, user]);

  return <></>;
}
