import classNames from 'classnames';
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import Color from '../../client/color';
import SVG from '../../components/svg';
import { useClickOutsideEvent } from '../../hooks/useClickOutsideEvent';
import { trackEvent } from '../../utils/analityics.utils';
import './ColorItem.scss';




interface ColorItemProps {
    color: Color;
    idx: number;
    editable: boolean;
    inMenu: boolean;
    onChange(selectedColor: any, color: Color, idx: number): void;
    onChangeComplete(): void;
    onNewColor(color: any): void;
    onClick(color: Color): void;
    removeColor(): void;
    newColor: boolean;
    showColorName: boolean;
    canBeDeleted: boolean;
}

export default function ColorItem({ color, idx, inMenu, onChange, onNewColor, editable, newColor, onChangeComplete, onClick, showColorName, removeColor, canBeDeleted }: ColorItemProps) {

    const [openPicker, setOpenPicker] = useState(false);
    const [colorIdx, setColorIdx] = useState(null);
    const [currentColor, setCurrentColor] = useState(typeof color === 'string' ? color : color?.color);
    const lastChosenColor = React.useRef()
    const colorPickerRef = React.useRef()

    const onClickHandler = (idx, presetSecondaryColors) => {
        if (onClick) {
            onClick(color)
        } else {
            setOpenPicker(true) // open picker
            setColorIdx(idx)
        }
    }

    const handleClosePalette = async () => {
        console.log('onClickOutside', { lastChosenColor, newColor, currentColor })
        setOpenPicker(false)
        setColorIdx(null)
        if (lastChosenColor.current) {
            if (newColor) {
                await onNewColor(lastChosenColor.current)
                setCurrentColor('#cccccc')
                lastChosenColor.current = null
            } else {
                onChangeComplete && onChangeComplete()
            }
        }
    }

    const handleChangeComplete = async (selectedColor) => {
        setCurrentColor(selectedColor.hex)
        trackEvent('user-branding-color-change');

    }

    const handleColorChange = async (selectedColor) => {
        lastChosenColor.current = selectedColor
        setCurrentColor(selectedColor.hex)
        !newColor && onChange(selectedColor, color, idx)
    }

    const isBright = (color) => {
        if (!color) {
            return false;
        }
        const rgb = {
            r: parseInt(color.substr(1, 2), 16),
            g: parseInt(color.substr(3, 2), 16),
            b: parseInt(color.substr(5, 2), 16),
        };

        const conversion = { r: 0.299, g: 0.587, b: 0.114 };
        return parseInt(rgb.r * conversion.r + rgb.g * conversion.g + rgb.b * conversion.b) > 200;
    }

    function onColorRemove() {
        console.log("onColorRemove colorColor ", color);
        color.color = null
        removeColor && removeColor();
        // setCurrentColor(null);
    }

    useClickOutsideEvent(colorPickerRef, handleClosePalette)


    const colorBlockClassNames = classNames('peech-color-block', { 'bright': isBright(currentColor), 'editable': editable, 'active': colorIdx === idx })

    if (!currentColor && !newColor) {
        return null
    }

    return <div className="color-item">
        {editable && !newColor
            && canBeDeleted
            && <div className='remove-wrapper' onClick={onColorRemove}>
                <SVG className="remove-color" name="close-white" style={{ height: "12px", width: "12px" }} />
            </div>}

        <div key={idx} className={colorBlockClassNames} onClick={() => onClickHandler(idx, currentColor)} style={{ backgroundColor: currentColor ?? '#cccccc' }}>
            {editable && !newColor && <a onClick={() => onClickHandler(idx, currentColor)}><SVG name="edit-temp" /></a>}
            {newColor && <SVG className="plus" name="plus-icon" />}
            {colorIdx === idx && < div className='active-color'></div>}
        </div>
        <div>
            {openPicker && !inMenu && <div className='color-palette-container' ref={colorPickerRef}>
                <ChromePicker className='color-palette item-color-picker' placement="bottom" disableAlpha color={currentColor || '#cccccc'} onChange={handleColorChange} onChangeComplete={handleChangeComplete} />
                <SVG name='close' className='close' onClick={handleClosePalette} />
            </div>}
            {showColorName && <div onClick={() => navigator?.clipboard?.writeText(currentColor || "")} style={{ fontSize: "14px", textAlign: "center", textTransform:"uppercase" }}>{(currentColor || (currentColor.length && currentColor) || color?.color || '').toString()}</div>}
        </div>
    </div >
}