
import * as Base from './base/speaker';

export const SpeakerStatus = Base.SpeakerStatus;

export interface ISpeaker extends Base.ISpeaker {

}

export interface ISpeakerProperties extends Base.ISpeakerProperties {

}

export interface ISpeakerFilter extends Base.ISpeakerFilter {

}

export default class Speaker<IType extends ISpeaker = ISpeaker, ITypeProperties extends ISpeakerProperties = ISpeakerProperties> extends Base.Speaker<IType, ITypeProperties> implements ISpeaker {
}