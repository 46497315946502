import React from 'react';
import { navigate } from 'gatsby';
import { AppContext } from '../lib/app-context';

export default function ImpersonateRedirect(props) {
  const { user, getUser } = React.useContext(AppContext);
  const hasUser = !!user;

  React.useEffect(() => {
    if (!document.location.search) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const encodedPs = searchParams.get('authToken');

    if (encodedPs) {
      const ps = decodeURIComponent(encodedPs);
      document.cookie = `ps=${ps}; Path=/;`;
      getUser(ps);
    } else {
      // TODO: show an error?
      window.history.back();
    }
  }, []);

  React.useEffect(() => {
    if (!hasUser) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const encodedRedirectUrl = searchParams.get('redirectUrl');

    navigate(decodeURIComponent(encodedRedirectUrl) ?? '/', { replace: true });
  }, [hasUser]);

  return null;
}
