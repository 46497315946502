import { animated, useTransition } from '@react-spring/web'
import classNames from 'classnames'
import React from 'react'
import SVG from '../../components/svg'
import { AppContext } from '../../lib/app-context'
import { formatBytes } from '../../lib/utils'
import { fileUploaderState } from '../../state/fileUploader'
import { loadedAssetsState } from '../../state/local'
import { sequenceState } from '../../state/sequence'
import CustomLanguageSelector, { LangSelectorColors } from '../CustomLanguageSelector/CustomLanguageSelector'
import ProgressBar from '../ProgressBar'
import './SceneUploader.scss'
import NumberedRemoveButton from '../NumberedRemoveButton'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import useUserPs from '../../hooks/useUserPs'


interface SceneUploaderProps {
    error?: any;
    image?: any;
    className?: string;
    sid: string;
    size?: string;
    duration?: number;
    fileSize?: number;
    handledProgress?: number;
    name?: string;
    thumbnailVersion?: number;
    onRemove(sid: string): void;
    thumb?: string;
    onLangChange?(sid: string): void;
}

function timeToObject(time: number) {
    const obj = {}
    obj.seconds = Math.floor(time % 60)
    obj.minutes = Math.floor(time / 60)
    return obj
}

function formatTime(time: number) {
    if (time === undefined || time === null || time === NaN) {
        return '00:00:00'
    }
    if (typeof time === 'number') {
        var timeObj = timeToObject(time)
    } else (
        console.error({ time })
    )
    return (
        timeObj &&
        `${(timeObj.minutes)
            .toString()
            .padStart(2, '0')}:${(timeObj.seconds)
                .toString()
                .padStart(2, '0')}`
    )
}

export default function SceneUploader({ className, size, sid, assetSid, thumb, image, onRemove, handledProgress, name, duration, fileSize, thumbnailVersion, lang, onLangChange, supportedLanguages = [], hideProgress = false, index, ...props }: SceneUploaderProps) {
    const sequence = sequenceState.use()
    const fileUploader = fileUploaderState.use(uploaders => uploaders[assetSid || sid])
    const [progress, setProgress] = React.useState(0)
    const [error, setError] = React.useState(null)
    const [isDone, setIsDone] = React.useState(thumbnailVersion)
    const loadedAssets = loadedAssetsState.use();
    const { config } = React.useContext(AppContext)
    const withUserPs = useUserPs();
    const thumbnailUrl = () => (thumb || (assetSid && loadedAssets.find(asset => asset.sid === assetSid)?.getThumbnailUrl({ width: 200, aspectRatio: '16:9' })) || fileUploader?.thumb || withUserPs(`${config.CHUNKS_URL}/t/${sequence.sid}/${sid}/${thumbnailVersion}.png`));
    const [thumbnail, setThumbnail] = React.useState(thumbnailUrl());

    React.useEffect(() => {
        if (!fileUploader) {
            return;
        }

        const onProgress = progress => progress !== undefined && setProgress(Math.max(0, Math.min(100, Math.round(progress))))
        const onThumbnail = async ({ src }) => setThumbnail(thumbnailUrl() || src)
        const onDone = () => setIsDone(true)
        const onError = (title, message) => setError(message)

        fileUploader
            .on('progress', onProgress)
            .on('thumbnail', onThumbnail)
            .on('error', onError)
            .on('done', onDone)
        return () => fileUploader.off('progress', onProgress).off('error', onError).off('done', onDone)
    }, [fileUploader]);

    React.useEffect(() => {
        thumbnailUrl() && !fileUploader && setIsDone(true)
    }, [thumbnailVersion]);


    const sceneUploaderClassNames = (className: string | undefined) => classNames('peech-scene-uploader',
        { [size]: size, 'uploading': fileUploader, error, 'success': image, className });

    const sceneUploaderWrapperClassNames = () => classNames("scene-uploader-wrapper", { "with-lang": onLangChange });

    const style = {}
    if (thumbnail) {
        style.backgroundImage = `url(${thumbnail})`
    }

    const chapterTransition = useTransition(null, {
        from: { x: -100, opacity: 0.1 },
        enter: { x: 0, opacity: 1 },
        leave: { x: -100, opacity: 0.1 },
    })
    const infoTransition = useTransition(null, {
        from: { x: 400, opacity: 0.1 },
        enter: { x: 0, opacity: 1 },
        leave: { x: 400, opacity: 0.1 },
    })



    return (

        chapterTransition(transition => <animated.div style={transition} className={'animatedDiv'}>
            <div className={sceneUploaderWrapperClassNames(className)} >
                <div className={sceneUploaderClassNames(className)} {...props}>

                    <div className="peech-scene-uploader-thumb" style={style} >

                        {size === "lg" ?
                            <NumberedRemoveButton number={index + 1} onClick={(e) => onRemove(sid)} />
                            :
                            null
                        }
                    </div>

                    {error && <SVG name="warning" />}

                    {infoTransition(transition => <animated.div className="peech-scene-uploader-info" style={transition}>

                        {(fileUploader
                            || !hideProgress
                            || (Number.isInteger(handledProgress) && handledProgress <= 100)) && <>
                                <OverlayTrigger placement="top"
                                    overlay={
                                        <Tooltip id='cut-included-tooltip' className="tooltip-peech-dark">
                                            {name}
                                        </Tooltip>}>
                                    <div className="scene-name">{name}</div>

                                </OverlayTrigger>


                                <ProgressBar type="linear" progress={handledProgress || progress} />
                            </>}

                        {!fileUploader
                            && hideProgress
                            && ((isDone && !Number.isInteger(handledProgress)) || (handledProgress === 100)) && <>

                                <OverlayTrigger placement="top"
                                    overlay={
                                        <Tooltip id='cut-included-tooltip' className="tooltip-peech-dark">
                                            {name}
                                        </Tooltip>}>
                                    <div className="scene-name">{name}</div>

                                </OverlayTrigger>

                                <span className="scene-property">Duration: {formatTime(duration)}</span>
                                {fileSize && <span className="scene-property">Size: {formatBytes(handledProgress ? sequence?.output?.size : fileSize)}</span>}
                            </>}

                        {error && <>
                            <span className="upload-failed">{handledProgress ? 'Download' : 'Upload'} failed</span>
                            <a href='#'><span className="upload-failed-action">Retry</span></a>
                        </>}

                    </animated.div>)}
                    <div className='peech-scene-uploader-feedback-icon' >
                        {!fileUploader && ((isDone && !Number.isInteger(handledProgress)) || handledProgress === 100) && size !== "lg" && <SVG name="success" />}
                        {fileUploader && size === "sm" && !isDone && <SVG name="close" onClick={() => onRemove(sid)} />}
                    </div>
                </div >
                {onLangChange ?
                    <div className='lang-change-wrapper'>
                        <div className='lang-title'>
                            Video’s language
                        </div>
                        <div className='lang-input-wrapper'>
                            <CustomLanguageSelector sid={sid} lang={lang} onLangChange={onLangChange} disabled={false} supportedLanguages={supportedLanguages} selectorColor={LangSelectorColors.BLUE} />
                        </div>
                    </div>
                    :
                    null}
            </div>
        </animated.div >)
    )
}
SceneUploader.defaultProps = {
    size: "lg"
};