import React from 'react'
import { Typeahead } from 'react-bootstrap-typeahead';

import SVG from '../../components/svg'

import { sequenceState } from '../../state/sequence'
import CustomTypeahead from '../../ui-components/CustomTypeahead/CustomTypeahead';

const languages = [
	{ text: 'Arabic (United Arab Emirates)', value: 'ar-AE', alt: ['ar'] },
	{ text: 'Arabic (Bahrain)', value: 'ar-BH' },
	{ text: 'Arabic (Algeria)', value: 'ar-DZ' },
	{ text: 'Arabic (Egypt)', value: 'ar-EG' },
	{ text: 'Arabic (Israel)', value: 'ar-IL' },
	{ text: 'Arabic (Iraq)', value: 'ar-IQ' },
	{ text: 'Arabic (Jordan)', value: 'ar-JO' },
	{ text: 'Arabic (Kuwait)', value: 'ar-KW' },
	{ text: 'Arabic (Lebanon)', value: 'ar-LB' },
	{ text: 'Arabic (Morocco)', value: 'ar-MA' },
	{ text: 'Arabic (Oman)', value: 'ar-OM' },
	{ text: 'Arabic (State of Palestine)', value: 'ar-PS' },
	{ text: 'Arabic (Qatar)', value: 'ar-QA' },
	{ text: 'Arabic (Saudi Arabia)', value: 'ar-SA' },
	{ text: 'Arabic (Tunisia)', value: 'ar-TN' },
	{ text: 'German (Germany)', value: 'de-DE' },
	{ text: 'English (Australia)', value: 'en-AU' },
	{ text: 'English (Canada)', value: 'en-CA' },
	{ text: 'English (United Kingdom)', value: 'en-GB' },
	{ text: 'English (Ghana)', value: 'en-GH' },
	{ text: 'English (Ireland)', value: 'en-IE' },
	{ text: 'English (India)', value: 'en-IN' },
	{ text: 'English (Kenya)', value: 'en-KE' },
	{ text: 'English (Nigeria)', value: 'en-NG' },
	{ text: 'English (New Zealand)', value: 'en-NZ' },
	{ text: 'English (Philippines)', value: 'en-PH' },
	{ text: 'English (Singapore)', value: 'en-SG' },
	{ text: 'English (Tanzania)', value: 'en-TZ' },
	{ text: 'English (United States)', value: 'en-US' },
	{ text: 'English (South Africa)', value: 'en-ZA' },
	{ text: 'Spanish (Argentina)', value: 'es-AR' },
	{ text: 'Spanish (Bolivia)', value: 'es-BO' },
	{ text: 'Spanish (Chile)', value: 'es-CL' },
	{ text: 'Spanish (Colombia)', value: 'es-CO' },
	{ text: 'Spanish (Costa Rica)', value: 'es-CR' },
	{ text: 'Spanish (Dominican Republic)', value: 'es-DO' },
	{ text: 'Spanish (Ecuador)', value: 'es-EC' },
	{ text: 'Spanish (Spain)', value: 'es-ES' },
	{ text: 'Spanish (Guatemala)', value: 'es-GT' },
	{ text: 'Spanish (Honduras)', value: 'es-HN' },
	{ text: 'Spanish (Mexico)', value: 'es-MX' },
	{ text: 'Spanish (Nicaragua)', value: 'es-NI' },
	{ text: 'Spanish (Panama)', value: 'es-PA' },
	{ text: 'Spanish (Peru)', value: 'es-PE' },
	{ text: 'Spanish (Puerto Rico)', value: 'es-PR' },
	{ text: 'Spanish (Paraguay)', value: 'es-PY' },
	{ text: 'Spanish (El Salvador)', value: 'es-SV' },
	{ text: 'Spanish (United States)', value: 'es-US' },
	{ text: 'Spanish (Uruguay)', value: 'es-UY' },
	{ text: 'Spanish (Venezuela)', value: 'es-VE' },
	{ text: 'French (Canada)', value: 'fr-CA' },
	{ text: 'French (France)', value: 'fr-FR' },
	{ text: 'Italian (Italy)', value: 'it-IT' },
	{ text: 'Japanese (Japan)', value: 'ja-JP' },
	{ text: 'Korean (South Korea)', value: 'ko-KR' },
	{ text: 'Dutch (Netherlands)', value: 'nl-NL' },
	{ text: 'Dutch (Belgium)', value: 'nl-BE' },
	{ text: 'Polish (Poland)', value: 'pl-PL' },
	{ text: 'Portuguese (Brazil)', value: 'pt-BR' },
	{ text: 'Russian (Russia)', value: 'ru-RU' },
	{ text: 'Thai (Thailand)', value: 'th-TH' },
	{ text: 'Turkish (Turkey)', value: 'tr-TR' },
	{ text: 'Chinese, Mandarin (Simplified, China)', value: 'zh', alt: ['zh-CN'] },
	{ text: 'Chinese, Cantonese (Traditional, Hong Kong)', value: 'yue-Hant-HK' },
	{ text: 'Chinese, Mandarin (Traditional, Taiwan)', value: 'zh-TW' },
	{ text: 'Bulgarian (Bulgaria)', value: 'bg-BG' },
	{ text: 'Catalan (Spain)', value: 'ca-ES' },
	{ text: 'Czech (Czech Republic)', value: 'cs-CZ' },
	{ text: 'Danish (Denmark)', value: 'da-DK' },
	{ text: 'Greek (Greece)', value: 'el-GR' },
	{ text: 'Finnish (Finland)', value: 'fi-FI' },
	{ text: 'Hebrew (Israel)', value: 'he-IL', alt: ['iw'] },
	{ text: 'Hindi (India)', value: 'hi-IN' },
	{ text: 'Croatian (Croatia)', value: 'hr-HR' },
	{ text: 'Hungarian (Hungary)', value: 'hu-HU' },
	{ text: 'Indonesian (Indonesia)', value: 'id-ID' },
	{ text: 'Lithuanian (Lithuania)', value: 'lt-LT' },
	{ text: 'Latvian (Latvia)', value: 'lv-LV' },
	{ text: 'Norwegian Bokmål (Norway)', value: 'nb-NO' },
	{ text: 'Portuguese (Portugal)', value: 'pt-PT' },
	{ text: 'Romanian (Romania)', value: 'ro-RO' },
	{ text: 'Slovak (Slovakia)', value: 'sk-SK' },
	{ text: 'Slovenian (Slovenia)', value: 'sl-SI' },
	{ text: 'Serbian (Serbia)', value: 'sr-RS' },
	{ text: 'Swedish (Sweden)', value: 'sv-SE' },
	{ text: 'Ukrainian (Ukraine)', value: 'uk-UA' },
	{ text: 'Afrikaans (South Africa)', value: 'af-ZA' },
	{ text: 'Amharic (Ethiopia)', value: 'am-ET' },
	{ text: 'Azerbaijani (Azerbaijan)', value: 'az-AZ' },
	{ text: 'Bengali (Bangladesh)', value: 'bn-BD' },
	{ text: 'Bengali (India)', value: 'bn-IN' },
	{ text: 'Estonian (Estonia)', value: 'et-EE' },
	{ text: 'Basque (Spain)', value: 'eu-ES' },
	{ text: 'Persian (Iran)', value: 'fa-IR' },
	{ text: 'Filipino (Philippines)', value: 'fil-PH' },
	{ text: 'Galician (Spain)', value: 'gl-ES' },
	{ text: 'Gujarati (India)', value: 'gu-IN' },
	{ text: 'Armenian (Armenia)', value: 'hy-AM' },
	{ text: 'Icelandic (Iceland)', value: 'is-IS' },
	{ text: 'Javanese (Indonesia)', value: 'jv-ID' },
	{ text: 'Georgian (Georgia)', value: 'ka-GE' },
	{ text: 'Khmer (Cambodia)', value: 'km-KH' },
	{ text: 'Kannada (India)', value: 'kn-IN' },
	{ text: 'Lao (Laos)', value: 'lo-LA' },
	{ text: 'Macedonian (North Macedonia)', value: 'mk-MK' },
	{ text: 'Malayalam (India)', value: 'ml-IN' },
	{ text: 'Mongolian (Mongolia)', value: 'mn-MN' },
	{ text: 'Marathi (India)', value: 'mr-IN' },
	{ text: 'Malay (Malaysia)', value: 'ms-MY' },
	{ text: 'Burmese (Myanmar)', value: 'my-MM' },
	{ text: 'Nepali (Nepal)', value: 'ne-NP' },
	{ text: 'Punjabi (Gurmukhi, India)', value: 'pa-guru-IN' },
	{ text: 'Sinhala (Sri Lanka)', value: 'si-LK' },
	{ text: 'Albanian (Albania)', value: 'sq-AL' },
	{ text: 'Sundanese (Indonesia)', value: 'su-ID' },
	{ text: 'Swahili (Kenya)', value: 'sw-KE' },
	{ text: 'Tamil (India)', value: 'ta-IN' },
	{ text: 'Tamil (Sri Lanka)', value: 'ta-LK' },
	{ text: 'Tamil (Malaysia)', value: 'ta-MY' },
	{ text: 'Tamil (Singapore)', value: 'ta-SG' },
	{ text: 'Telugu (India)', value: 'te-IN' },
	{ text: 'Urdu (India)', value: 'ur-IN' },
	{ text: 'Urdu (Pakistan)', value: 'ur-PK' },
	{ text: 'Uzbek (Uzbekistan)', value: 'uz-UZ' },
	{ text: 'Zulu (South Africa)', value: 'zu-ZA' },

];

interface WizardTitleProps {
	onTitleChange(title: string): void;
	onLanguageChange(value: string): void;
	shouldShowLanguage: boolean;
}

export default function WizardTitle({ onTitleChange, onLanguageChange, shouldShowLanguage = false }: WizardTitleProps) {
	const languageInputRef = React.useRef()
	const sequenceTitle = sequenceState.use(sequence => sequence.title);

	const [language, setLanguage] = React.useState({ text: 'English (United States)', value: 'en-US' })
	const timeout = React.useRef(null)

	const titleInputRef = React.useRef()

	React.useEffect(() => {
		titleInputRef.current && titleInputRef.current.focus();
	}, [titleInputRef.current])

	function onTitleKeyUp(e: any) {
		const title = e.target.value;
		timeout.current && clearTimeout(timeout.current)
		if (title) {
			timeout.current = setTimeout(() => {
				onTitleBlur(e)
			}, 1000)
		}
		else {
			timeout.current = null;
		}
		onTitleChange(title)
	}

	function onTitleKeyPress(e) {
		if (e.which === 13) {
			e.preventDefault()
			e.target.blur()
			document.getElementById('next-button').click()
		}
	}

	function onTitleBlur({ target }) {
		timeout.current && clearTimeout(timeout.current)
		timeout.current = null;

		var title = target.value.trim()
		if (title.length) {
			onTitleChange(title)
		}
	}

	function onLangChange(lang) {
		if (lang.length) {
			setActiveLang(lang[0])
			onLanguageChange(lang[0].value)
		}
	}

	function setActiveLang(lang) {
		setLanguage(lang);
	}

	return (
		<div className="wizard-title">

			<div className="title-section">
				<div className="title-icon">
					<SVG name="icon-background" className="illustration-svg illustration-background" viewBox="0 0 156 140" />
					<SVG name="name-illustration" className="illustration" viewBox="0 0 156 140" />
				</div>
				<h3 className='wizard-title-description'>You know, so you can easily find it later.</h3>
				<input
					type="text"
					id="wizard-title-input"
					data-cy="wizard-title-input"
					ref={titleInputRef}
					className="form-control wizard-input"
					placeholder="Give your video a title"
					autoComplete="off"
					onKeyPress={onTitleKeyPress}
					onKeyUp={onTitleKeyUp}
					onBlur={onTitleBlur}
					defaultValue={sequenceTitle}
				/>
			</div>

			{shouldShowLanguage &&
				<>

					<span className="divider">And</span>

					<div className="language-section">
						<div className="language-icon">
							<SVG name="icon-background" className="illustration-svg illustration-background" viewBox="0 0 156 140" />
							<SVG name="language-illustration" className="illustration" viewBox="0 0 156 140" />
						</div>
						<h3 className='wizard-title-description'>Is this the right language of your video? </h3>
						<CustomTypeahead
							disabled={true}
							className="disabled-feature"
							id="basic-typeahead-single-language"
							labelKey="text"
							inputProps={{
								className: 'form-control input-sm select',
							}}
							onChange={onLangChange}
							options={languages}
							placeholder="Type a language..."
							onInputChange={text => setLanguage({ ...language, text })}
							selected={[language]}
							refObj={languageInputRef}
						/>
					</div>
				</>
			}

		</div >
	)
}