
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'
import CuePoint, { ICuePoint, ICuePointFilter, ICuePointProperties } from '../cuePoint'
import GraphicColor, { IGraphicColor, IGraphicColorProperties } from '.././graphicColor'
import GraphicAsset, { IGraphicAsset, IGraphicAssetProperties } from '.././graphicAsset'
import GraphicAnimation, { IGraphicAnimation, IGraphicAnimationProperties } from '.././graphicAnimation'

export enum GraphicCuePointSourceStatus {
	PENDING = 1,
	READY = 2,
	INIT = 3,
};

export interface IGraphicCuePoint extends ICuePoint {
	title?: string | null;
	opacity?: number | null;
	width?: number | null;
	height?: number | null;
	top?: number | null;
	bottom?: number | null;
	left?: number | null;
	right?: number | null;
	zIndex?: number | null;
	logoScale?: number | null;
	grayscale?: number | null;
	alignment?: string | null;
	animations?: GraphicAnimation[] | null;
	externalId?: string | null;
	colors?: GraphicColor[] | null;
	assets?: GraphicAsset[] | null;
}

export interface IGraphicCuePointProperties extends ICuePointProperties {
	title?: boolean;
	opacity?: boolean;
	width?: boolean;
	height?: boolean;
	top?: boolean;
	bottom?: boolean;
	left?: boolean;
	right?: boolean;
	zIndex?: boolean;
	logoScale?: boolean;
	grayscale?: boolean;
	alignment?: boolean;
	animations?: IGraphicAnimationProperties;
	externalId?: boolean;
	colors?: IGraphicColorProperties;
	assets?: IGraphicAssetProperties;
}

export interface IGraphicCuePointFilter extends ICuePointFilter {
}

export class GraphicCuePoint<IType extends IGraphicCuePoint = IGraphicCuePoint, ITypeProperties extends IGraphicCuePointProperties = IGraphicCuePointProperties> extends CuePoint<IType, ITypeProperties> implements IGraphicCuePoint {
	
	static className = 'GraphicCuePoint';
	static NAME = 'graphicCuePoint';
	static PKS = [
		'sequenceSid',
		'sid',
	];
	static PROPERTIES = {...CuePoint.PROPERTIES, 
		title: true,
		opacity: true,
		width: true,
		height: true,
		top: true,
		bottom: true,
		left: true,
		right: true,
		zIndex: true,
		logoScale: true,
		grayscale: true,
		alignment: true,
		animations: GraphicAnimation.PROPERTIES,
		externalId: true,
		colors: GraphicColor.PROPERTIES,
		assets: GraphicAsset.PROPERTIES,
	};
	
	/**
	 * @deprecated use GraphicCuePointSourceStatus instead
	 */
	static SOURCE_STATUS: any = {
		PENDING: GraphicCuePointSourceStatus.PENDING,
		READY: GraphicCuePointSourceStatus.READY,
		INIT: GraphicCuePointSourceStatus.INIT,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		if(values.animations && values.animations.length) {
			values.animations = values.animations.map(item => Base.asType<IGraphicAnimation>(item, GraphicAnimation, this, 'animations') as GraphicAnimation);
		}
		if(values.colors && values.colors.length) {
			values.colors = values.colors.map(item => Base.asType<IGraphicColor>(item, GraphicColor, this, 'colors') as GraphicColor);
		}
		if(values.assets && values.assets.length) {
			values.assets = values.assets.map(item => Base.asType<IGraphicAsset>(item, GraphicAsset, this, 'assets') as GraphicAsset);
		}
		return super.set(values, parent, name, ignoreModified);
	}
	
	get title(): string | null | undefined {
		return this.values.title
	}
			
	set title(value: string | null | undefined) {
		if(this.values.title !== value) {
			this.propertyChanged('title', value);
			this.values.title = value;
		}
	}
			
	get opacity(): number | null | undefined {
		return this.values.opacity
	}
			
	set opacity(value: number | null | undefined) {
		if(this.values.opacity !== value) {
			this.propertyChanged('opacity', value);
			this.values.opacity = value;
		}
	}
			
	get width(): number | null | undefined {
		return this.values.width
	}
			
	set width(value: number | null | undefined) {
		if(this.values.width !== value) {
			this.propertyChanged('width', value);
			this.values.width = value;
		}
	}
			
	get height(): number | null | undefined {
		return this.values.height
	}
			
	set height(value: number | null | undefined) {
		if(this.values.height !== value) {
			this.propertyChanged('height', value);
			this.values.height = value;
		}
	}
			
	get top(): number | null | undefined {
		return this.values.top
	}
			
	set top(value: number | null | undefined) {
		if(this.values.top !== value) {
			this.propertyChanged('top', value);
			this.values.top = value;
		}
	}
			
	get bottom(): number | null | undefined {
		return this.values.bottom
	}
			
	set bottom(value: number | null | undefined) {
		if(this.values.bottom !== value) {
			this.propertyChanged('bottom', value);
			this.values.bottom = value;
		}
	}
			
	get left(): number | null | undefined {
		return this.values.left
	}
			
	set left(value: number | null | undefined) {
		if(this.values.left !== value) {
			this.propertyChanged('left', value);
			this.values.left = value;
		}
	}
			
	get right(): number | null | undefined {
		return this.values.right
	}
			
	set right(value: number | null | undefined) {
		if(this.values.right !== value) {
			this.propertyChanged('right', value);
			this.values.right = value;
		}
	}
			
	get zIndex(): number | null | undefined {
		return this.values.zIndex
	}
			
	set zIndex(value: number | null | undefined) {
		if(this.values.zIndex !== value) {
			this.propertyChanged('zIndex', value);
			this.values.zIndex = value;
		}
	}
			
	get logoScale(): number | null | undefined {
		return this.values.logoScale
	}
			
	set logoScale(value: number | null | undefined) {
		if(this.values.logoScale !== value) {
			this.propertyChanged('logoScale', value);
			this.values.logoScale = value;
		}
	}
			
	get grayscale(): number | null | undefined {
		return this.values.grayscale
	}
			
	set grayscale(value: number | null | undefined) {
		if(this.values.grayscale !== value) {
			this.propertyChanged('grayscale', value);
			this.values.grayscale = value;
		}
	}
			
	get alignment(): string | null | undefined {
		return this.values.alignment
	}
			
	set alignment(value: string | null | undefined) {
		if(this.values.alignment !== value) {
			this.propertyChanged('alignment', value);
			this.values.alignment = value;
		}
	}
			
	get animations(): GraphicAnimation[] | null | undefined {
		return this.values.animations
	}
			
	set animations(value: GraphicAnimation[] | null | undefined) {
		if(this.values.animations !== value) {
			this.propertyChanged('animations', value);
			this.values.animations = value;
		}
	}
			
	get externalId(): string | null | undefined {
		return this.values.externalId
	}
			
	set externalId(value: string | null | undefined) {
		if(this.values.externalId !== value) {
			this.propertyChanged('externalId', value);
			this.values.externalId = value;
		}
	}
			
	get colors(): GraphicColor[] | null | undefined {
		return this.values.colors
	}
			
	set colors(value: GraphicColor[] | null | undefined) {
		if(this.values.colors !== value) {
			this.propertyChanged('colors', value);
			this.values.colors = value;
		}
	}
			
	get assets(): GraphicAsset[] | null | undefined {
		return this.values.assets
	}
			
	set assets(value: GraphicAsset[] | null | undefined) {
		if(this.values.assets !== value) {
			this.propertyChanged('assets', value);
			this.values.assets = value;
		}
	}
			
	
	static get<IType extends IGraphicCuePoint = IGraphicCuePoint>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IGraphicCuePointFilter = IGraphicCuePointFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IGraphicCuePointFilter = IGraphicCuePointFilter, IType extends IGraphicCuePoint = IGraphicCuePoint>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
