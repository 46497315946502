import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { navigate } from 'gatsby';
import { AppContext } from '../../lib/app-context';
import { sequenceState } from '../../state/sequence';
import styled from '@emotion/styled';
import { trackEvent } from '../../utils/analityics.utils';
import { DownloadDialogState, WatermarkAlertModalDialogState } from '../../state/local';
import { signalPlaybackShouldPause } from '../../state/playback';
import { firstDraftState } from '../../components/draftModal/DraftModal';
import Plan from '../../client/plan';

const FlexBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
`;

const RainbowButton = styled.a`
  cursor: pointer;
  font-family: Open Sans;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 30px;
  padding: 10px 38px;
  text-decoration: none;
  border: 0;
  color: white;
  fill: white;
  stroke: white;
  background-image: linear-gradient(90deg, #ed3f99 0%, #ed3f99 60%, #18a0fb 100%);
  background-size: 200% 100%;
  background-position-x: 0;
  animation: rainbow 2s ease-in forwards;
  transition: all 0.3s ease-in-out;
  :hover {
    color: white;
    fill: white;
    background-image: linear-gradient(90deg, #ed3f99 0%, #ed3f99 60%, #18a0fb 100%);
    background-size: 1300% 1400%;
  }
`;

const WhiteButton = styled.a`
  cursor: pointer;
  text-decoration: none;
  font-family: Open Sans;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px 10px;
  color: black;
  transition: all 0.6s ease-in;
  :hover {
    color: black;
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 40px;
`;

const Text = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
`;

const Title = styled.div`
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  padding: 30px 0px 14px 0px;
`;

export default function WatermarkAlerModal() {
  const { plan } = React.useContext(AppContext);
  const watermarkAlertModalDialogState = WatermarkAlertModalDialogState.use();
  const [planIdToUpgradeTo, setPlanIdToUpgradeTo] = useState('');

  useEffect(async () => {
    const plans = await Plan.list(null, null, {
      tier: true,
      planId: true,
      provider: true,
      name: true,
      hidden: true,
      status: true,
    });

    const futureUpgradablePaddlePlan = plans
      .filter((planItem) => !planItem.hidden && planItem.status === 1 && planItem.provider === 3 && planItem.tier! > 0)
      .sort((a, b) => a.tier! - b.tier!)
      .find((planItem) => planItem.tier! > plan?.tier!)?.sid;

    setPlanIdToUpgradeTo(futureUpgradablePaddlePlan || '');
  }, []);

  return (
    <Modal
      show={watermarkAlertModalDialogState}
      className="upgrade-modal"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => WatermarkAlertModalDialogState.set(false)}
      centered
      size="lg"
    >
      <Modal.Body>
        <Wrapper>
          <button
            id="btn-close-upgrade-modal"
            className="btn btn-close"
            onClick={() => {
              WatermarkAlertModalDialogState.set(false);
              window.top?.postMessage('WATERMARK_MODAL_CLOSED', '*');
            }}
          />
          <Title>Your video will include a Peech watermark</Title>
          <Text>To remove the Peech watermark, upgrade to a premium plan</Text>
        </Wrapper>

        <FlexBox>
          <WhiteButton
            onClick={() => {
              signalPlaybackShouldPause();
              firstDraftState.set(false);
              trackEvent('download-with-watermark-alert-download-click');
              WatermarkAlertModalDialogState.set(false);
              trackEvent('download-dialog-open');
              DownloadDialogState.set(true);
            }}
          >
            Download with watermark
          </WhiteButton>
          <RainbowButton
            href={`/plans?planId=${planIdToUpgradeTo}&chargeType=1`}
            onClick={() => {
              trackEvent('download-with-watermark-alert-upgrade-click');
              window.top?.postMessage(`UPGRADE_PLAN_${planIdToUpgradeTo}`, '*');
            }}
          >
            Upgrade to remove watermark
          </RainbowButton>
        </FlexBox>
      </Modal.Body>
    </Modal>
  );
}
