
import * as Base from './base/speakerCuePoint';
	
export {default as Speaker} from '././speaker';

export const SpeakerCuePointSourceStatus = Base.SpeakerCuePointSourceStatus;

export interface ISpeakerCuePoint extends Base.ISpeakerCuePoint {

}

export interface ISpeakerCuePointProperties extends Base.ISpeakerCuePointProperties {

}

export interface ISpeakerCuePointFilter extends Base.ISpeakerCuePointFilter {

}

export default class SpeakerCuePoint<IType extends ISpeakerCuePoint = ISpeakerCuePoint, ITypeProperties extends ISpeakerCuePointProperties = ISpeakerCuePointProperties> extends Base.SpeakerCuePoint<IType, ITypeProperties> implements ISpeakerCuePoint {
    shouldSaveSpeaker?: boolean;
}