module.exports = `<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0in;
	text-align:justify;
	line-height:115%;
	unicode-bidi:embed;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;}
.MsoPapDefault
	{text-align:justify;
	line-height:115%;
	unicode-bidi:embed;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:56.7pt 70.9pt 56.7pt 70.9pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
.privacy ol
	{margin-bottom:0in;}
.privacy ul
	{margin-bottom:0in;}
-->
</style>
</head>
<body>
<div class="privacy WordSection1">

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><b><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>We at Peech
(&quot;<b>Peech</b>&quot;, &quot;<b>us</b>&quot;, &quot;<b>we</b>&quot;, or
&quot;<b>our</b>&quot;) recognize and respect the importance of maintaining the
privacy of visitors of our website (&quot;<b>Site</b>&quot;) and our customers.
This Privacy Notice describes the types of information we collect from you when
you visit our Site and/or use our Services (as defined in the Terms). This
Privacy Notice also explains how we collect, process, transfer, store and
disclose the information collected. If not otherwise defined herein,
capitalized terms have the meaning given to them in the Terms of Service,
available at&nbsp;https://app.peech-ai.com/ (&quot;<b>Terms</b>&quot;). &quot;<b>You</b>&quot;
means any user of the Site and/or Services. </span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&quot;<b>Personal
Data</b>&quot; means any information that refers, is related to, or is
associated with an identified or identifiable individual or as otherwise may be
defined by applicable law. This Privacy Notice details which Personal Data is
collected by us in connection with provision of the Services.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><b><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><b><u><span style='font-family:"Calibri",sans-serif'>Personal
Data We Collect </span></u></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><b><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>We collect
information from you when you choose to use our Site and/or Services. In order
to use our Site and/or receive related Services, you will be required to
register and provide us with certain Personal Data, such as your name and email
address.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>We also
collect Personal Data when you make use of the Site, request information from
us, complete online forms, or contact us for any other reason. If you use the
Services by logging in through a third-party login service, we may also receive
Personal Data about you as provided by such third-party login service, such as
your Google account. Please check such third-party’s policies in order to
understand what information we receive. Examples of the Personal Data that we
collect from you when you engage in any of the activities detailed above, may
include your name and e-mail address. Such Personal Data may be collected by us
through the Site. </span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>We also
collect any materials including images and/or pictures and/or photos and/or
documents you may upload to the Site. Please note that when using the Services for
the purpose of creating an Edited Video we may, subject to your consent,
collect facial recognition data contained in the User Content you provide us
for such purpose; such data may be deemed as biometric data under applicable
laws. </span></p>

<br/>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>In addition,
when you use the Site, certain information may be automatically gathered about
your computer, including non-Personal Data, such as your operating system, and
Personal Data such as your IP address, device ID, as well as your use, browsing
history and/or any information regarding your viewing and purchase history on
our Site. </span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'> It is your
voluntary decision whether to provide us with any such Personal Data, but if
you refuse to provide such information we may not be able to register you to
the Site and/or provide you with the Services. </span></p>

<br/>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><b><u><span style='font-family:"Calibri",sans-serif'>How We
Use Your Personal Data</span></u></b></p>

<br/>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><u><span style='font-family:"Calibri",sans-serif'>General</span></u></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>We and any
of our trusted third-party subcontractors and service providers use the
Personal Data we collect from and about you for any of the following purposes:
(1) to provide you with the Site and/or Services; (2) to respond to your
inquiries or requests, contact and communicate with you; (3) to develop new
products or services and conduct analyses to improve our current content,
products, and Services;  (4) to review the usage and operations of our Site and
Services; (5) to use your data in an aggregated, non-specific format for
analytical purposes (as detailed below); (6) to prevent fraud, protect the
security of our Site and Services, and address any problems with the Site
and/or Services and (7) to provide customer support. </span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><u><span style='font-family:"Calibri",sans-serif'>Statistical
Information and Analytics</span></u><span style='font-family:"Calibri",sans-serif'>
</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>We and/or
our service providers use analytics tools, including &quot;Google
Analytics&quot; to collect and analyze information about the use of the Site
and/or Services, such as how often users visit the Site, what pages they visit
when they do so, and what other sites and mobile applications they used prior
to visiting the Site. By analyzing the information we receive, we may compile
statistical information across a variety of platforms and users, which helps us
improve our Site and Services, understand trends and customer needs and
consider new products and services, and tailor existing products and services
to customer desires. The information we collect is anonymous and aggregated and
we will not link it to any Personal Data. We may share such anonymous
information with our partners, without restriction, on commercial terms that we
can determine in our sole discretion. You can find more information about how
Google collects information and how you can control such use at </span><a
href="https://policies.google.com/technologies/partner-sites.%20%5bNetanella"><span
style='font-family:"Calibri",sans-serif;color:blue'>https://policies.google.com/technologies/partner-sites.
</span></a><span style='font-family:"Calibri",sans-serif'> </span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><a name="_gjdgxs"></a><b><u><span style='font-family:"Calibri",sans-serif'>Sharing
the Personal Data We Collect</span></u></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><b><span style='font-family:"Calibri",sans-serif'> </span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>We share
your information, including Personal Data, as follows:</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><u><span style='font-family:"Calibri",sans-serif'>Business
Partners, Service Providers, Affiliates, and Subcontractors</span></u></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>We disclose
information, including Personal Data we collect from and/or about you, to our
trusted service providers, business partners, affiliates, subcontractors, who
use such information to: (1) help us provide you with the Site and/or Services;
and (2) aid in their understanding of how users are using our Site and/or
Services. </span></p>

<br/>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>By accepting
this Privacy Notice, you acknowledge that we may use subcontractors and service
providers who are located in countries other than your own and may send them
information we receive (including Personal Data). All of our subcontractors and
service providers will be subject to non-disclosure and non-use obligations. </span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><u><span style='font-family:"Calibri",sans-serif'>Business
Transfers</span></u></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>We may
transfer our databases containing your Personal Data if we sell our business or
part of it, including in cases of liquidation. Your Personal Data may be
disclosed as part of, or during negotiations of, any merger, sale of company
assets or acquisition (including in cases of liquidation) in such case, your
Personal Data shall continue being subject to the provisions of this Privacy
Notice.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><u><span style='font-family:"Calibri",sans-serif'>Law
Enforcement Related Disclosure</span></u></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>We will
fully cooperate with any law enforcement authorities or court order requesting
or directing us to disclose the identity, behavior or (digital) content and
information of or related to an individual, including in the event of any user
suspected to have engaged in illegal or infringing behavior. We may share your
Personal Data with third parties: (i) if we believe in good faith that
disclosure is appropriate to protect our or a third party's rights, property or
safety (including the enforcement of the Terms and this Privacy Notice); (ii)
when required by law, regulation subpoena, court order or other law enforcement
related issues, agencies and/or authorities; or (iii) as is necessary to comply
with any legal and/or regulatory obligation.</span></p>

<br/>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><u><span style='font-family:"Calibri",sans-serif'>Legal
Uses</span></u></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>We may use
your Personal Data as required or permitted by any applicable law, for example,
to comply with audit and other legal requirements.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>                                                                                                                      </span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><u><span style='font-family:"Calibri",sans-serif'>Other
Uses or Transfer of Your Information</span></u></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>If you use
our Site and/or Services with or through a third-party service, site and/or
mobile application, we may receive information (including Personal Data) about
you from those third parties. Please note that when you use third-parties
outside of our Site and/or Services, their own terms and privacy policies will
govern your use of those services.</span></p>

<br/>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><b><u><span style='font-family:"Calibri",sans-serif'>Security
</span></u></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><b><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>We make
efforts to follow generally accepted industry standards to protect the Personal
Data submitted to and collected by us, both during transmission and once we
receive it. However, no method of transmission over the Internet or method of
electronic storage is 100% secure. Therefore, while we strive to use
commercially acceptable means to protect your Personal Data, we cannot
guarantee its absolute security. As the security of information depends in part
on the security of the computer you use to communicate with us and the security
you use to protect user IDs and passwords, please take appropriate measures to
protect this information.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><b><u><span style='font-family:"Calibri",sans-serif'>Your
Rights</span></u></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>Subject to
applicable law, you may request to see any Personal Data you have provided to
us and request that such Personal Data be corrected or updated by emailing us
at info@peech-ai.com.</span></p>

<br/>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><b><u><span style='font-family:"Calibri",sans-serif'>Data
Retention</span></u></b><span style='font-family:"Calibri",sans-serif'> </span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>We retain
information as necessary for the purposes set forth above. We may delete
information from our systems, without notice to you, once we deem it is no
longer necessary for the purposes set forth above. </span></p>

<br/>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed;border:none'><a name=30j0zll></a><a name="_1fob9te"></a><b><u><span
style='font-family:"Calibri",sans-serif;color:black'>Cookies and Similar
Technologies</span></u></b><span style='font-family:"Calibri",sans-serif;
color:black'>. We use cookies and similar technologies for a number of reasons,
including to help personalize your experience. When visiting this Site, you
shall be notified of the use of and placement of cookies and other similar
technologies on your device as specified herein.</span></p>

<br/>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed;border:none'><a name="_3znysh7"></a><u><span
style='font-family:"Calibri",sans-serif;color:black'>What are Cookies?</span></u><span
style='font-family:"Calibri",sans-serif;color:black'> A cookie is a small piece
of text that is sent to a user's browser or device. The browser provides this
piece of text to the device of the originating user when this user returns.</span></p>

<br/>

<p class=MsoNormal style='margin-left:.5in;text-align:justify;text-indent:-.25in;
line-height:normal;direction:ltr;unicode-bidi:embed;border:none'><span
style='font-family:"Noto Sans Symbols";color:black'>−<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-family:"Calibri",sans-serif;
color:black'>A &quot;session cookie&quot; is temporary and will remain on your
device until you leave the Site.</span></p>

<p class=MsoNormal style='margin-left:.5in;text-align:justify;text-indent:-.25in;
line-height:normal;direction:ltr;unicode-bidi:embed;border:none'><span
style='font-family:"Noto Sans Symbols";color:black'>−<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-family:"Calibri",sans-serif;
color:black'>A &quot;persistent&quot; cookie may be used to help save your
settings and customizations across visits. It will remain on your device until
you delete it. </span></p>

<p class=MsoNormal style='margin-left:.5in;text-align:justify;text-indent:-.25in;
line-height:normal;direction:ltr;unicode-bidi:embed;border:none'><span
style='font-family:"Noto Sans Symbols";color:black'>−<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-family:"Calibri",sans-serif;
color:black'>First-party cookies are placed by us, while third-party cookies
may be placed by a third party. We use both first- and third-party cookies.</span></p>

<p class=MsoNormal style='margin-left:.5in;text-align:justify;text-indent:-.25in;
line-height:normal;direction:ltr;unicode-bidi:embed;border:none'><span
style='font-family:"Noto Sans Symbols";color:black'>−<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=LTR></span><span style='font-family:"Calibri",sans-serif;
color:black'>We may use the terms &quot;cookies&quot; to refer to all
technologies that we may use to store data in your browser or device or that
collect information or help us identify you in the manner described above, such
as web beacons or &quot;pixel tags&quot;.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed;border:none'><a name="_2et92p0"></a><u><span
style='font-family:"Calibri",sans-serif;color:black'>How We Use Cookies</span></u><span
style='font-family:"Calibri",sans-serif;color:black'>. We use cookies and
similar technologies for a number of reasons, as specified below.</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>The specific
names and types of the cookies, web beacons, and other similar technologies we
use may change from time to time. However, the cookies we use generally fall
into one of the following categories:</span></p>

<table class=a border=1 cellspacing=0 cellpadding=0 width=619 style='border-collapse:
 collapse;border:none'>
 <tr>
  <td width=95 valign=top style='width:71.0pt;border:solid black 1.0pt;
  background:#D9D9D9;padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='text-align:justify;line-height:normal;direction:
  ltr;unicode-bidi:embed'><b><span style='font-family:"Calibri",sans-serif'>Type
  of Cookie<a name="_tyjcwt"></a></span></b></p>
  </td>
  <td width=524 valign=top style='width:393.3pt;border:solid black 1.0pt;
  border-left:none;background:#D9D9D9;padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='text-align:justify;line-height:normal;direction:
  ltr;unicode-bidi:embed'><b><span style='font-family:"Calibri",sans-serif;
  color:black'>Why We Use These Cookies</span></b></p>
  </td>
 </tr>
 <tr>
  <td width=95 valign=top style='width:71.0pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='text-align:justify;line-height:normal;direction:
  ltr;unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>Necessary</span></p>
  </td>
  <td width=524 valign=top style='width:393.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='text-align:justify;line-height:normal;direction:
  ltr;unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>These
  cookies are necessary in order to allow the Site to work correctly. They
  enable you to access the Site, move around, and access different services,
  features, and tools. Examples include remembering previous actions (e.g.
  entered text) when&nbsp;navigating back to a page in the same session. These
  cookies cannot be disabled.</span></p>
  </td>
 </tr>
 <tr>
  <td width=95 valign=top style='width:71.0pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='text-align:justify;line-height:normal;direction:
  ltr;unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>Functionality</span></p>
  </td>
  <td width=524 valign=top style='width:393.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='text-align:justify;line-height:normal;direction:
  ltr;unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>These
  cookies remember your settings and preferences and the choices you make (such
  as language or regional preferences) in order to help us personalize your
  experience and offer you enhanced functionality and content.</span></p>
  </td>
 </tr>
 <tr>
  <td width=95 valign=top style='width:71.0pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='text-align:justify;line-height:normal;direction:
  ltr;unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>Security</span></p>
  </td>
  <td width=524 valign=top style='width:393.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='text-align:justify;line-height:normal;direction:
  ltr;unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>These
  cookies can help us identify and prevent security risks. They may be used to
  store your session information to prevent others from changing your password
  without your login information.</span></p>
  </td>
 </tr>
 <tr>
  <td width=95 valign=top style='width:71.0pt;border:solid black 1.0pt;
  border-top:none;padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='text-align:justify;line-height:normal;direction:
  ltr;unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>Analytics</span></p>
  </td>
  <td width=524 valign=top style='width:393.3pt;border-top:none;border-left:
  none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
  padding:0in 5.75pt 0in 5.75pt'>
  <p class=MsoNormal style='text-align:justify;line-height:normal;direction:
  ltr;unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>These
  cookies collect information regarding your activity on our Site to help us
  learn more about which features are popular with our users and how our Site
  can be improved. </span></p>
  </td>
 </tr>
</table>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><a name="_3dy6vkm"></a><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<br/>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed;border:none'><a name="_1t3h5sf"></a><u><span
style='font-family:"Calibri",sans-serif;color:black'>How to Adjust Your
Preferences</span></u></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed;border:none'><span style='font-family:"Calibri",sans-serif;
color:black'>Most Web browsers are initially configured to accept cookies, but
you can change this setting so your browser either refuses all cookies or
informs you when a cookie is being sent. In addition, you are free to delete
any existing cookies at any time. Please note that some features of the
Services may not function properly when cookies are disabled or removed. For
example, if you delete cookies that store your account information or
preferences, you will be required to input these each time you visit. </span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><a name="_4d34og8"></a><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><b><u><span style='font-family:"Calibri",sans-serif'>Third-Party
Applications and Services</span></u></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>All use of
third-party applications or services is at your own risk and subject to such
third party's privacy policies. </span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'> </span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed;border:none'><a name=2s8eyo1></a><b><u><span
style='font-family:"Calibri",sans-serif;color:black'>Communications</span></u></b><span
style='font-family:"Calibri",sans-serif;color:black'>. We reserve the right to
send you service-related communications, including service announcements and
administrative messages, without offering you the opportunity to opt out of
receiving them. Should you not wish to receive such communications, you may cancel
your account. </span></p>

<br/>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><b><u><span style='font-family:"Calibri",sans-serif'>Children</span></u></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>We do not
knowingly collect Personal Data from or market our Services to children under
the age of 18. </span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'> </span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed;border:none'><a name=17dp8vu></a><b><u><span
style='font-family:"Calibri",sans-serif;color:black'>Changes to the Privacy
Notice</span></u></b></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed;border:none'><span style='font-family:"Calibri",sans-serif;
color:black'>We may update this Privacy Notice from time to time to keep it up
to date with legal requirements and the way we operate our business, and we
will place any updates on this webpage. Please come back to this page every now
and then to make sure you are familiar with the latest version. If we make
material changes to this Privacy Notice, we will seek to inform you by notice
on our Site or per email. </span></p>

<p class=MsoNormal style='margin-right:5.3pt;text-align:justify;line-height:
normal;background:white;direction:ltr;unicode-bidi:embed'><span
style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><b><u><span style='font-family:"Calibri",sans-serif'>Comments
and Questions</span></u></b></p>

<p class=MsoNormal align=left style='text-align:left;line-height:normal;
direction:ltr;unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><span style='font-family:"Calibri",sans-serif'>If you have
any comments or questions about this privacy notice, please contact us at
info@peech-ai.com<span style='color:black'>. </span></span></p>


<br/>

<p class=MsoNormal style='text-align:justify;line-height:normal;direction:ltr;
unicode-bidi:embed'><a name="_3rdcrjn"></a><i><span style='font-family:"Calibri",sans-serif'>Last
updated: February, 2021</span></i></p>

</div>
</body>
</html>`;