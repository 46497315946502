import Base from "../../client/base/base";
import Sequence from "../../client/sequence";
import { prependSequences } from "../../state/local";
import { AspectRatio, PendingClone, pendingClonesState } from "./useSequenceCloneOptions";


export const DEFAULT_LANGUAGE_CODE = 'en-US';

export const onCloneSequence = async (aspectRatio: AspectRatio, sequenceThumbnail: string, sequence: Sequence, userSid: string, newLanguage?: string | null, onSuccess?: () => void,) => {
      const pendingClone: PendingClone = {
        id: Math.random().toString(32),
        aspectRatio,
        thumbnail: sequenceThumbnail!,
        title: `Copy of ${sequence.title}`,
      };

      pendingClonesState.set((clones) => [pendingClone, ...clones]);

      try {
        onSuccess?.();
        await Base.executeAPIQueue();
        const language = newLanguage? newLanguage : sequence?.languageCode? sequence.languageCode : DEFAULT_LANGUAGE_CODE;
        const sequenceClone = await Sequence.clone(sequence.sid!, userSid, aspectRatio, language);
        prependSequences(sequenceClone);
      } catch {}

      pendingClonesState.set((clones) => clones.filter(({ id }) => id !== pendingClone.id));
    }
  ;