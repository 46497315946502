import React from 'react';

import { cuePointsState, cuePointsVersionState, isActive, previewActiveCuePointsState } from '../state/cuePoints';
import CuePointElement from './cue-points/element';
import { sequenceState } from '../state/sequence';
import { fontLoadedState } from './player-style';
import useResizeObserver from 'use-resize-observer';

/**
 * @param {object} props
 * @param {number} props.time in millis
 * @param {boolean} props.animate
 * @param {boolean} props.isPreview
 * @param {boolean} props.isDraft
 * @param {boolean} props.visible;
 */
export default function Renderer(props) {
  const { time, scene, animate, isPreview, isDraft, visible, ...rest } = props;
  const fontLoaded = fontLoadedState.use();
  const cuePointsData = cuePointsState.use();
  const cuePoints = Object.values(cuePointsData);
  const cuePointsVersion = cuePointsVersionState.use();
  const [renderCuePoints, setRenderCuePoints] = React.useState([]);
  const container = React.createRef();
  const previewCuePoints = previewActiveCuePointsState.use();
  const aspectRatio = sequenceState.use((sequence) => sequence?.aspectRatio || '16:9');
  const [w, h] = aspectRatio.split(':');
  const ratio = w / h;
  var currentTime = time;
  if (scene && currentTime !== undefined) {
    currentTime += scene.offset * 1000;
  }

  React.useEffect(() => {
    const renderCuePoints = isPreview
      ? previewCuePoints
      : scene
      ? cuePoints.filter((c) => c?.chapterSid === scene.sid)
      : cuePoints;
    setRenderCuePoints(renderCuePoints.filter((c) => isActive(c)));
  }, [scene, cuePointsVersion, previewCuePoints]);

	const { ref, height, width } = useResizeObserver();

	if (!fontLoaded) {
		return null
	}

	return (
		<div
			className="graphics embed-responsive"
			ref={ref}
			{...rest}
		>
			<div className="logo-bounds" />
			{renderCuePoints
				.filter(c => c)
				.map((c, index) => <CuePointElement
					key={c?.sid || index}
					sid={c?.sid}
					width={width || 0}
					height={height|| 0}
					time={currentTime}
					animate={animate}
					isPreview={isPreview}
					isDraft={isDraft}
				/>)}
		</div>
	)
}
