import React, { useMemo } from 'react';
import Asset, { AssetStatus } from '../../client/asset';
import CuePoint, { CuePointType } from '../../client/cuePoint';
import GraphicColor from '../../client/graphicColor';
import { Scene } from '../../client/sequence';
import TitleCuePoint from '../../client/titleCuePoint';
import { reloadPage } from '../../lib/ui-errors';
import { cuePointsState, isActive, onCuePointChange, saveCuePoint } from '../../state/cuePoints';
import { handleError } from '../../state/error';
import { introOutroDurationChangeState } from '../../state/menu';
import { seekPlayback, signalPlaybackShouldPause } from '../../state/playback';
import { activateScene, deactivateScene, scenesState, sequenceSave, sequenceState } from '../../state/sequence';
import {
  BlockHeader,
  HeaderActionsDiv,
  Section,
  SectionWrapper,
  ShowHideDiv,
  Stack,
  SubSection,
  TimeContainerWrapper,
  UnderLinedSection,
} from '../../ui-components/Menu/MenuComponents.styled';
import PresetLogo from '../../ui-components/PresetLogo/PresetLogo';
import { trackEvent } from '../../utils/analityics.utils';
import { calcMaxTime, calcMinTime } from '../../utils/cuePoint.utils';
import { TimeCodeComponent, TimeFormat } from '../common/time-code/time-code.component';
import { TimeContainer } from '../common/time-code/time-code.style';
import { decodeHtml } from '../cue-points/editableTextItem';
import { applyAnimationColorsNames, getAnimationProps } from '../cue-points/graphic';
import { TextCuePointElement } from '../cue-points/title';
import { SequenceColorPickerValue } from '../menu/subtitles/SequenceColorPicker';
import SVG from '../svg';
import DurationController from './DurationController';
import InputsController from './InputsController';
import { SCENE_TYPE, sceneAssetsState, sceneUploadedAssetsState } from './IntroAndOutro';
import { BlockWrapper, LogoContainer, LottieContainer } from './IntroAndOutro.styled';
import { calculateLogoData } from '../../utils/calculateLogoData.utils';
import { AppContext } from '../../lib/app-context';
import SliderWithInput from '../menu/subtitles/SliderWithInput';
import styled from '@emotion/styled';
import useUserPs from '../../hooks/useUserPs';
import VisualsMenuColorsComponent from '../menu/VisualsMenuColorsComponent';
import useVisualsMenuColorsChange from '../../hooks/useVisualsMenuColorsChange';

const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 6px;
  font-family: 'Open Sans', sans-serif;
`;

export enum INPUT_TYPE {
  TITLE = 0,
  SUBTITLE = 1,
  CALL_TO_ACTION = 2,
}

async function getSceneAssets(type: string) {
  const sceneAssets = await Asset.list(
    {
      typeEqual: Asset.TYPE[`LOTTIE_${type.toUpperCase()}`],
      statusIn: [AssetStatus.READY, AssetStatus.PENDING],
    },
    {
      pageIndex: 0,
      pageSize: 500,
    }
  );
  sceneAssetsState.set((assets) => ({ ...assets, [type]: sceneAssets }));
  return sceneAssets;
}

async function getSceneUploadedAssets(type: string) {
  const sceneUploadedAssets = await Asset.list(
    {
      typeEqual: Asset.TYPE[`SOURCE_${type.toUpperCase()}`],
      statusEqual: AssetStatus.READY,
    },
    {
      pageIndex: 0,
      pageSize: 500,
    }
  );
  sceneUploadedAssets.length && sceneUploadedAssetsState.set((assets) => ({ ...assets, [type]: sceneUploadedAssets }));
  return sceneUploadedAssets;
}

const MAX_DURATION = 8;

interface IntroOutroBlockProperties {
  type: string;
  label: string;
  showHeaderName?: boolean;
  textDirection?: string;
  sceneAnimation: any;
}

export default function IntroOutroBlock({
  type,
  label,
  showHeaderName = true,
  textDirection = 'ltr',
  sceneAnimation,
}: IntroOutroBlockProperties) {
  const enableIntroOutroDurationChange = introOutroDurationChangeState.use();
  const enableIntroOutroLogoScaleChange = true;
  const sequence = sequenceState.use();
  const assetSid = sequenceState.use((sequence) => sequence?.style?.[`${type}AssetSid`]);
  const sceneAssets = sceneAssetsState.use<Asset[]>((assets) => assets[type]);
  const sceneUploadedAssets = sceneUploadedAssetsState.use<Asset[]>((assets) => assets[type]);
  const newSceneCuePoint = () => {
    const cp = new TitleCuePoint(sequence.sid);
    cp.width = 100;
    cp.height = 100;
    cp.top = 0;
    cp.left = 0;
    cp.type = TitleCuePoint.TYPE[type.toUpperCase()];
    cp.startTime = 0;
    cp.endTime = 0;
    return cp;
  };
  const cuePoints = cuePointsState.use();
  const cuePoint =
    Object.values(cuePoints).find((cp) => cp?.type === CuePoint.TYPE[type.toUpperCase()]) || newSceneCuePoint();
  const scenes = scenesState.use((scenes) => scenes.map);
  const scene = scenesState.use((scenes) => {
    const arr = scenes.filter.totalArray
      ?.filter(
        (scene) => scene.cuePointType === CuePoint.TYPE[type.toUpperCase()] && !scene.cuePointSid && scene.assetSid
      )
      ?.sort((a, b) => b.insertTime - a.insertTime);
    return arr.find((scene) => scene.status !== Scene.STATUS.DELETED) || arr?.[0];
  });
  const activeTime = cuePoint?.times?.[cuePoint.chapterSid];
  const offset = scenesState.use((scenes) => scenes.map[activeTime?.sceneSid]?.offset || 0);
  const duration = scenesState.use((scenes) => scenes.map[activeTime?.sceneSid]?.duration || sequence.duration);
  const isSceneActived = React.useMemo(() => scene && scene.assetSid && scene.status !== Scene.STATUS.DELETED, [scene]);
  const isEnabled = React.useMemo(() => {
    return (isSceneActived && !scene.disabled) || isActive(cuePoint);
  }, [isSceneActived, scene, cuePoint.values.status]);
  const titleInputRef = React.useRef<HTMLDivElement>();
  const subtitleInputRef = React.useRef<HTMLDivElement>();
  const callToActionInputRef = React.useRef<HTMLDivElement>();
  const [bold, setBold] = React.useState(
    cuePoint?.fontWeight === undefined ? sequence?.style?.fontWeight === 'Bold' : cuePoint?.fontWeight === 'Bold'
  );
  const inputsLimit = React.useMemo(() => (type === SCENE_TYPE.INTRO ? 135 : null), [type]);
  const [usedSymbolsCount, setUsedSymbolsCount] = React.useState();
  const [showHideButtonEnabled, setShowHideButtonEnabled] = React.useState(true);
  const [shouldDisableDurationChange, setShouldDisableDurationChange] = React.useState(false);

  const [maxLogoScale, setMaxLogoScale] = React.useState(1);
  const thumbnailVersion = sequenceState.use((sequence) => sequence.logo?.thumbnailVersion);

  const sequenceLogoAsssetSidMemo = useMemo(() => {
    if (sequence?.style?.introLogoAssetSid && cuePoint.type === CuePointType.INTRO) {
      return sequence.style.introLogoAssetSid;
    } else if (sequence?.style?.outroLogoAssetSid && cuePoint.type === CuePointType.OUTRO) {
      return sequence.style.outroLogoAssetSid;
    } else {
      return sequence.logo?.assetSid;
    }
  }, [sequence.logo?.assetSid, sequence.style?.outroLogoAssetSid, sequence.style?.introLogoAssetSid, cuePoint.type]);

  const { config } = React.useContext(AppContext);

  const onColorsChange = useVisualsMenuColorsChange();

  React.useEffect(() => {
    if (!inputsLimit) {
      return;
    }
    const titleLength = titleInputRef?.current?.value?.length || 0;
    const subtitleLength = subtitleInputRef?.current?.value?.length || 0;
    const commonLength = titleLength + subtitleLength;
    setUsedSymbolsCount(commonLength);
  }, [titleInputRef?.current?.value, subtitleInputRef?.current?.value]);

  React.useEffect(async () => {
    if (!sceneAssets.length) {
      try {
        await getSceneAssets(type);
      } catch (err) {
        console.error(err);
      }
    }

    if (!sceneUploadedAssets.length) {
      try {
        getSceneUploadedAssets(type);
      } catch (err) {
        console.error(err);
      }
    }
  }, []);

  const onKeyPress = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.which === 13 || e.which === 27) {
      e.preventDefault();
      e.target.blur();
    }
  };

  async function onInputChange(e: React.ChangeEvent<HTMLInputElement>, field: string) {
    const text = e?.target?.value || '';

    if (inputsLimit) {
      let secondInputLength = 0;
      if (field === 'title') {
        secondInputLength = subtitleInputRef?.current?.value?.length || 0;
      } else if (field === 'text') {
        secondInputLength = titleInputRef?.current?.value?.length || 0;
      }
      const commonLength = text.length + secondInputLength;
      setUsedSymbolsCount(commonLength);
    }
    cuePoint[field] = decodeHtml(text).replace(/[\n\r]+/g, '\n');
  }

  async function onInputBlur(e: React.ChangeEvent<HTMLInputElement>, field: string) {
    console.log({ e, field });
    const trackAction =
      type === SCENE_TYPE.INTRO && field === 'title'
        ? 'title changed'
        : SCENE_TYPE.INTRO && field === 'text'
        ? 'subtitle chenged'
        : 'call to action changed';

    trackEvent('intro-outro-edit', {
      type: type,
      action: trackAction,
      assetSid: assetSid,
    });
    var val = e.target.value || '';
    cuePoint[field] = val;
    onCuePointChange(cuePoint, true);
  }

  const getMaxLength = (inputType: INPUT_TYPE) => {
    if (inputsLimit) {
      let res;
      if (inputType === INPUT_TYPE.TITLE) {
        res = subtitleInputRef?.current?.value?.length || 0;
      } else if (inputType === INPUT_TYPE.SUBTITLE) {
        res = titleInputRef?.current?.value?.length || 0;
      }
      return inputsLimit - res;
    }
  };

  async function onFontWeightChange() {
    const isBold = !bold;
    setBold(isBold);
    cuePoint.fontWeight = isBold ? 'Bold' : 'Regular';
    onCuePointChange(cuePoint, true);
  }

  const getScene = () =>
    scenesState
      .get()
      .filter.totalArray?.filter(
        (scene) => scene.cuePointType === CuePoint.TYPE[type.toUpperCase()] && !scene.cuePointSid && scene.assetSid
      )
      ?.sort((a, b) => b.insertTime - a.insertTime)
      .find((scene) => scene.status !== Scene.STATUS.DELETED);

  async function onDisable() {
    const scene = getScene();
    try {
      if (cuePoint.sid && isActive(cuePoint)) {
        await cuePoint.deactivate();
      } else if (scene && scene.sid) {
        const sceneChapters = scenes.array.filter(
          (scene) => scene.cuePointType === CuePoint.TYPE[type.toUpperCase()] && scene.status !== Scene.STATUS.DELETED
        );
        sceneChapters.forEach(async (scene) => {
          deactivateScene(scene, sequence);
        });
      }
    } catch (err) {
      handleError({
        title: `We failed to hide this ${type}`,
        message: 'Please refresh the page and try again',
        actionText: 'Refresh',
        action: reloadPage,
      });
    }
  }

  async function onEnable() {
    const shouldSaveSequence =
      type === SCENE_TYPE.OUTRO ? !sequence.style.outroAssetSid : !sequence.style.introAssetSid;
    try {
      if (scene && scene.sid && isSceneActived) {
        activateScene(scene, sequence);
      } else if (cuePoint.sid) {
        await cuePoint.activate();
      } else {
        if (type === SCENE_TYPE.OUTRO) {
          sequence.style.outroAssetSid = sequence.style.outroAssetSid || sceneAssets?.[0]?.sid;
        } else if (type === SCENE_TYPE.INTRO) {
          sequence.style.introAssetSid = sequence.style.introAssetSid || sceneAssets?.[0]?.sid;
        }
        shouldSaveSequence && (await sequenceSave(sequence));
        await cuePoint.save();
      }
    } catch (err) {
      handleError({
        title: `We failed to show this ${type}`,
        message: 'Please refresh the page and try again',
        actionText: 'Refresh',
        action: reloadPage,
      });
    }
  }

  async function onEnabledChange() {
    trackEvent('intro-outro-edit', { type: type, action: !isEnabled ? 'enabled' : 'disabled', assetSid: assetSid });
    setShowHideButtonEnabled(false);
    if (isEnabled) {
      await onDisable();
    } else {
      await onEnable();
    }
    setShowHideButtonEnabled(true);
  }

  const extractedColors = (sceneAnimation && getAnimationProps(sceneAnimation)) || [];
  const cuePointColors = applyAnimationColorsNames(extractedColors, cuePoint.colors);
  const animationTimes = sceneAnimation?.animationTimes;

  const minDuration = React.useMemo(() => {
    if (!animationTimes || !animationTimes.length) {
      return 0;
    }
    const inStart = animationTimes[0]?.data.start ?? 0;
    const inEnd = animationTimes[0]?.data.end ?? 0;
    const outStart = animationTimes[1]?.data.start ?? 0;
    if (type === SCENE_TYPE.INTRO) {
      return Math.ceil((Math.abs(inStart) + Math.abs(inEnd) + Math.abs(outStart)) * 2) / 2;
    } else if (type === SCENE_TYPE.OUTRO) {
      return Math.ceil((Math.abs(inStart) + Math.abs(inEnd)) * 2) / 2;
    }
  }, [type, animationTimes]);

  const onDurationChange = async (value: number) => {
    trackEvent('intro-outro-edit', {
      type: type,
      action: 'duration changed',
      assetSid: assetSid,
      values: { duration: value, minimumReached: value === minDuration, maximumReached: value === MAX_DURATION },
    });
    cuePoint.duration = value * 1000;
    try {
      setShouldDisableDurationChange(true);
      await saveCuePoint(cuePoint);
      setShouldDisableDurationChange(false);
    } catch (error) {
      setShouldDisableDurationChange(false);
    }
  };

  async function onBlockClick() {
    signalPlaybackShouldPause();
    if (activeTime?.start !== undefined) {
      let halfDuration = (activeTime.end - activeTime.start) / 2;
      const seekTimeToSet = activeTime.start + halfDuration;
      await seekPlayback(Math.ceil(seekTimeToSet) / 1000);
    }
  }

  const withUserPs = useUserPs();

  const logoSrc = useMemo(() => {
    if (sequenceLogoAsssetSidMemo) {
      return withUserPs(`${config.CHUNKS_URL}/asset/${sequenceLogoAsssetSidMemo}/logo/${thumbnailVersion}.png`);
    }
  }, [config.CHUNKS_URL, sequenceLogoAsssetSidMemo, thumbnailVersion]);

  const minLogoScale = 0.5;

  const onLogoScaleChange = async (value: number) => {
    value /= 100;
    trackEvent('intro-outro-edit', {
      type: type,
      action: 'logo scaled',
      assetSid: assetSid,
      value: { scaleValue: value },
      isMaxScaled: value === maxLogoScale,
    });
    cuePoint.logoScale = value;
    await saveCuePoint(cuePoint);
  };

  React.useEffect(async () => {
    await onBlockClick();
  }, [type]);

  React.useEffect(async () => {
    const logoData = await calculateLogoData(logoSrc, sceneAnimation, cuePoint.logoScale);
    logoData?.data?.maxScale && setMaxLogoScale(logoData?.data?.maxScale);
  }, [logoSrc, sceneAnimation]);

  const sceneCuePointItem = (
    <div className={`scene-animation-preview ratio-${sequence?.aspectRatio?.replace(':', '-')}`}>
      <TextCuePointElement
        inMenu={true}
        autoPlay={false}
        loop={true}
        animationSid={assetSid}
        cuePoint={cuePoint}
        moveable={false}
      />
    </div>
  );

  return (
    <BlockWrapper>
      <BlockHeader onClick={onBlockClick}>
        <Title>{showHeaderName ? label : ''}</Title>
        <HeaderActionsDiv>
          <TimeContainerWrapper hide={enableIntroOutroDurationChange}>
            {activeTime && (
              <TimeContainer data-cy="time-container">
                <TimeCodeComponent
                  time={Math.round(activeTime?.start)}
                  minTime={calcMinTime(offset)}
                  maxTime={activeTime?.end - 500 || 0}
                  format={TimeFormat.MMSS}
                  disabled={true}
                />
                <span>|</span>
                <TimeCodeComponent
                  time={Math.round(activeTime?.end)}
                  minTime={activeTime?.start + 500 || 0}
                  maxTime={calcMaxTime(offset, duration)}
                  format={TimeFormat.MMSS}
                  disabled={true}
                />
              </TimeContainer>
            )}
          </TimeContainerWrapper>
          <ShowHideDiv onClick={onEnabledChange} disabled={!showHideButtonEnabled}>
            {isEnabled ? <SVG name="show" viewBox="0 0 24 24" /> : <SVG name="hide" viewBox="0 0 24 24" />}
          </ShowHideDiv>
        </HeaderActionsDiv>
      </BlockHeader>

      <SectionWrapper disabled={!isEnabled}>
        <UnderLinedSection>
          <Stack spacing={4}>
            <LottieContainer>{sceneCuePointItem}</LottieContainer>
          </Stack>
        </UnderLinedSection>

        <DurationController
          duration={cuePoint.duration / 1000}
          onDurationChange={onDurationChange}
          hide={!enableIntroOutroDurationChange}
          disabled={shouldDisableDurationChange}
          min={minDuration}
          max={MAX_DURATION}
        />

        <UnderLinedSection>
          <Title>Logo</Title>
          <Stack spacing={4}>
            <LogoContainer>
              <PresetLogo usage={type} cuePoint={cuePoint} />
              <Title>Size</Title>
              <SliderWithInput
                value={Math.round((cuePoint.logoScale || 1) * 100)}
                onValueChange={onLogoScaleChange}
                hide={!enableIntroOutroLogoScaleChange}
                disabled={maxLogoScale <= minLogoScale}
                min={minLogoScale * 100}
                max={maxLogoScale * 100}
                step={1}
              />
            </LogoContainer>
          </Stack>
        </UnderLinedSection>

        {type === SCENE_TYPE.INTRO && (
          <>
            <Section>
              <InputsController
                cuePoint={cuePoint}
                type={type}
                inputType={INPUT_TYPE.TITLE}
                onInputChange={onInputChange}
                onInputBlur={onInputBlur}
                onKeyPress={onKeyPress}
                inputRef={titleInputRef}
                getMaxLength={getMaxLength}
                inputsLimit={inputsLimit}
                usedSymbolsCount={usedSymbolsCount}
                textDirection={textDirection}
              />
            </Section>

            <Section>
              <InputsController
                cuePoint={cuePoint}
                type={type}
                inputType={INPUT_TYPE.SUBTITLE}
                onInputChange={onInputChange}
                onInputBlur={onInputBlur}
                onKeyPress={onKeyPress}
                inputRef={subtitleInputRef}
                getMaxLength={getMaxLength}
                inputsLimit={inputsLimit}
                usedSymbolsCount={usedSymbolsCount}
                textDirection={textDirection}
              />
            </Section>
          </>
        )}

        {type === SCENE_TYPE.OUTRO && (
          <Section>
            <InputsController
              cuePoint={cuePoint}
              type={type}
              inputType={INPUT_TYPE.CALL_TO_ACTION}
              onInputChange={onInputChange}
              onInputBlur={onInputBlur}
              onKeyPress={onKeyPress}
              inputRef={callToActionInputRef}
              onFontWeightChange={onFontWeightChange}
              isBold={bold}
              textDirection={textDirection}
            />
          </Section>
        )}

        {/* COLORS */}
        {assetSid && isActive(cuePoint) && !!cuePointColors?.length && (
          <VisualsMenuColorsComponent
            colorsObjects={cuePointColors}
            onColorChange={(value, colorObj, type) =>
              onColorsChange(value, colorObj, type, cuePoint, extractedColors, 'intro-outro-edit')
            }
            type={type}
            shouldScrollIntoView={true}
            key={type}
          />
        )}
      </SectionWrapper>
    </BlockWrapper>
  );
}
