import {appConfig, IConfig} from '../lib/app-context';
import User from '../client/user';
import Sequence from "../client/sequence";
import {getBalance, getLatestDownloadInfos} from "./balance.utils";

type DownloadAllowanceData = {
    isDownloadAllowed: boolean;
    data?: any;
}

export const getDownloadLink = async (sequenceSid: string, sequenceVersion: number): Promise<string> => {
        return `${appConfig.CONTENT_URL}/v2/downloads/get-download-url/${sequenceSid}/${sequenceVersion}?authorization=Bearer ${User.ps}`;
}

export const downloadAllowance = async (config: IConfig, sequenceSid: string): Promise<DownloadAllowanceData> => {
    try {
        if (!config.LIMIT_DOWNLOADS) {
            return {isDownloadAllowed: true}
        }

        // check if sequence is already downloaded and if so, allow download
        // get latest download info for sequence
        const downloadInfo = await getLatestDownloadInfos(sequenceSid, 'done');
        if (downloadInfo?.length > 0) {
            return {isDownloadAllowed: true}
        }

        let balance = await getBalance();
        if (balance?.periodLeftDownloads || balance?.periodLeftDownloads === null) {
            return {isDownloadAllowed: true, data: balance};
        }

        return {isDownloadAllowed: false, data: balance};

    } catch (err) {
        return {isDownloadAllowed: false};
    }
}
