import { entity } from 'simpler-state'
import Speaker from '../client/speaker';
import { saveCuePoint } from './cuePoints';

/**
 * @type {SpeakerCuePoint[]}
 */
export const speakersState = entity([])
export const speakersLoadedState = entity(false)


export async function reloadSpeakers() {
    const speakers = await Speaker.list({
        statusEqual: Speaker.STATUS.NAMED
    });
    if (speakers.length) {
        speakersState.set(speakers);
    }
}

export async function saveSpeaker(cuePoint, sequence, scenes) {
    const res = await saveCuePoint(cuePoint, sequence, scenes, false, { speaker: Speaker.PROPERTIES })
    const speaker = res?.values?.speaker
    if (speaker && speaker.sid) {
        speakersState.set(speakers => {
            const speakerIndex = speakers.findIndex(s => speaker.sid === s.sid)
            if (speakerIndex >= 0) {
                speakers[speakerIndex] = speaker
            } else {
                speakers.push(speaker)
            }
            return speakers
        })
    }
}