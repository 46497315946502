import { useLocation } from "@reach/router"
import * as Sentry from "@sentry/react"
import { navigate } from 'gatsby'
import React from 'react'
import Asset from '../../client/asset'
import EditRules from '../../client/editRules'
import Sequence, { Chapter } from '../../client/sequence'
import VideoType from '../../client/videoType'
import { AppContext } from '../../lib/app-context'
import FileUploader from '../../lib/fileUploader'
import { handleError } from '../../state/error'
import { fileUploaderState } from '../../state/fileUploader'
import { prependSequences, resetWizardState, setWizard, wizardState } from '../../state/local'
import { resetSequenceLiveState, scenesState, sequenceSave, sequenceState, setScene } from '../../state/sequence'
import OnboardingWizard from '../../ui-components/OnboardingWizard'
import { trackEvent } from '../../utils/analityics.utils'
import { DEFAULT_FONT } from "../../utils/font.utils"
import ContentEditor from '../content-editor'
import './style.scss'
import WizardBrand from './WizardBrand'
import WizardSOR from './WizardSOR'
import WizardTitle from './WizardTitle'
import WizardUpload from './WizardUpload'







export const WIZARDS = {
	CREATE: 1,
	CONTENT_EDITOR: 2
};

export const MODES = {
	UPLOAD: 1,
	TITLE: 2
};

interface NewProjectWizardProps {
	numSteps: number;
}

export default function NewProjectWizard({ numSteps }: NewProjectWizardProps) {
	const [mode, setCurrentMode] = React.useState<number>(MODES.UPLOAD)
	const { user, plan, status, defaultVideoType } = React.useContext(AppContext)
	const sequence = sequenceState.use();
	const chapters = scenesState.use(scenes => scenes.filter.chapters)
	const [sorReady, setSorReady] = React.useState(false)
	const [activeSor, setActiveSor] = React.useState(null);
	const [titleReady, setTitleReady] = React.useState<boolean>(false)
	const [brandReady, setBrandReady] = React.useState(false)
	const [videoTypes, setVideoTypes] = React.useState(NewProjectWizard.videoTypesSor);
	const [logoThumbnail, setLogoThumbnail] = React.useState(false);
	const [uploading, setUploading] = React.useState(false);
	const [shouldShowLanguage, setShouldShowLanguage] = React.useState(false);


	const wizard = wizardState.use()
	const location = useLocation()

	const retryCount = React.useRef(-1)

	React.useEffect(() => {
		console.log({ activeSor, videoTypes });

	}, [activeSor])

	React.useEffect(() => {
		resetSequenceLiveState()
		resetWizardState()
		return () => resetWizardState()
	}, [])

	if (!user) {
		return null;
	}

	React.useEffect(() => {
		const intercomBoot = {
			api_base: "https://api-iam.eu.intercom.io", 
			app_id: "m7k431u7",
			hide_default_launcher: true
	}

		if (!user) {
			return;
		}
		if (user?.preset?.assets?.find(a => a.type === Asset.TYPE.LOGO) || user.logoAssets?.length) {
			setLogoThumbnail(true);
		}
		// fallBackUrlState.set('/project/')

		window.Intercom && window.Intercom('shutdown')

		return () => {
			// fallBackUrlState.set(null)
			window.Intercom && window.Intercom('boot', intercomBoot);
		}
	}, [user, user?.preset])

	React.useEffect(() => {
		if ((user?.preset?.fontFamily || user?.preset?.hasFontAsset()) && logoThumbnail) {
			setBrandReady(true);
		}
	}, [user?.preset, logoThumbnail])

	// React.useEffect(loadVideoTypes, []);

	// works when create project from Quick create button on assetPreview
	React.useEffect(() => {
		console.log("Location state asset: ", location?.state?.asset);
		if (location?.state?.asset) {
			setShouldShowLanguage(false);
			setCurrentMode(MODES.TITLE)
			try {
				addAsset(location.state.asset);
			} catch (err) {
				console.log("addAsset error", err);
			}
			navigate('/project', { state: {}, replace: true })
		}
	}, [location?.state?.asset?.sid])

	// async function loadVideoTypes() {

	// 	if (location.hash || NewProjectWizard.videoTypesSor.length) {
	// 		return
	// 	}

	// 	try {
	// 		window.editRules = EditRules;
	// 		const types = await VideoType.list({ statusEqual: VideoType.STATUS.IS_DEFAULT })
	// 		NewProjectWizard.videoTypesSor = types.sort((a, b) => a.order - b.order)
	// 		setVideoTypes(NewProjectWizard.videoTypesSor)
	// 	}
	// 	catch (err) {
	// 		console.error(err)
	// 		retryCount.current++
	// 		if (retryCount < 2) {
	// 			return setTimeout(loadVideoTypes, 100)
	// 		}
	// 		handleError({
	// 			title: 'Fetching video types failed',
	// 			message: err.message,
	// 			responseError: err
	// 		})
	// 	}
	// }

	// function verifySequenceFonts() {
	// 	if (!sequence.style.fontFamily && !sequence.style.bodyRegularFileName) {
	// 		sequence.style.fontFamily = DEFAULT_FONT
	// 	}
	// 	if (!sequence.style.headerFontFamily && !sequence.style.headerRegularFileName) {
	// 		sequence.style.fontFamily = DEFAULT_FONT
	// 	}
	// 	if (!sequence.style.captionsFontFamily && !sequence.style.captionsRegularFileName) {
	// 		sequence.style.captionsFontFamily = DEFAULT_FONT
	// 	}
	// }

	async function setSequenceAssets() {
		if (user.preset) {
			// @TODO enable once presetSid available on sequence
			sequence.presetSid = user.preset.sid
			console.log('Set sequence assets')
			await sequence.applyPreset(user.preset, user)
			console.log('Sequence assets as set')
			// verifySequenceFonts()
			sequenceState.set(sequence)
		}
		return sequence
	}

	async function saveNewSequence() {
		console.log('Create new sequence ', { videoTypes })
		await setSequenceAssets()
		sequence.editRules = new EditRules();
		try {
			const newSequence = await sequenceSave(sequence)
			prependSequences(newSequence)
		} catch (err) {
			console.error(err)
		}

		trackEvent('new-project');

		Sequence.setHeader('X-SequenceSid', sequence.sid)
		if (status && status.sid !== sequence.sid) {
			status.sid = sequence.sid;
		}

		prependSequences(sequence)
	}


	async function addAsset(asset) {
		if (!sequence.sid) {
			await saveNewSequence();
		}
		const choosenAsset = new Asset().set(asset.values)
		let chapter = new Chapter()
		chapter.assetSid = choosenAsset.sid
		chapter.name = choosenAsset.name

		try {
			chapter = await sequence.addChapter(chapter)

			setUploading(true)
			setScene(chapter, true)
		}
		catch (err) {
			return handleError({
				statusCode: 11,
				responseError: err
			});
		}
	}

	async function onChapterFileSelected(file: File, callback: Function) {
		if (!sequence.sid) {
			await saveNewSequence();
		}
		const asset = new Asset();
		asset.name = file.name
		asset.type = Asset.TYPE.SOURCE
		await asset.save();

		const maxDuration = user?.UIFLAGS.NO_UPLOAD_LIMIT ? null : plan?.maxVideoDuration * 60;
		const uploader = new FileUploader({
			file,
			maxDuration,
			content: asset
		})
		fileUploaderState.set(uploaders => ({ ...uploaders, [asset.sid]: uploader }))

		uploader
			.on('done', () => fileUploaderState.set(({ [asset.sid]: done, ...uploaders }) => uploaders))
			.on('canceled', () => fileUploaderState.set(({ [asset.sid]: canceled, ...uploaders }) => uploaders))
			.on('error', (title, message) => handleError({ title, message }))
			.on('thumbnail', async ({ src, thumbnail }) => {
				if (!asset.contentIdentifier) {
					return;
				}
				try {
					uploader.uploadThumbnail(asset.contentIdentifier.key, thumbnail, asset.sid)
					asset.thumbnail = src
				}
				catch (err) {
					console.error(err)
					handleError({
						title: 'Content thumbnail upload failed',
						message: err.message,
						responseError: err
					})
				}

			})
			.on('parsed', metadata => {
				console.log(metadata)
			})
			.uploadAsset(null, asset.sid)
		let chapter = new Chapter()
		chapter.assetSid = asset.sid
		try {
			chapter = await sequence.addChapter(chapter)
			chapter.thumb === asset.thumbnail
		}
		catch (err) {
			return handleError({
				statusCode: 11,
				responseError: err
			});
		}

		chapter.values.size = file.size
		setScene(chapter, true)
	}



	function onLanguageChange(value: string) {
		sequence.languageCode = value;
	}

	async function onTitleChange(title: string) {

		if (!title) {
			sequence.title = null
			setTitleReady(false);
		} else if (sequence.title !== title) {
			sequence.title = title
			console.log({ 'sequence in onTitleChange': sequence });

			sequenceState.set(sequence)
			return setTitleReady(true);
		}
	}

	function onEditRulesChange(videoType: VideoType, skip) {
		console.log({ videoType });

		sequence.editRulesSid = videoType?.editRulesSid || null;
		setActiveSor(videoType.sid)
		setSorReady(true)

		if (skip) {
			trackEvent('sor-skip');
		} else {
			trackEvent('sor-select', {
				editRulesSid: videoType?.editRulesSid || 'none',
				videoTypeSid: videoType?.sid || 'none',
				videoTypeName: videoType?.name,
			});
		}
	}

	async function onDone() {
		if (!sequence.title) {
			console.log('title step click done sequence.title is null');
			Sentry.captureException(new Error('title step click done sequence.title is null'));
			return;
		}
		if (!sequence.changedProperties.includes('title')) {
			Sentry.captureException(new Error('title property removed from changedProperties'));
			sequence.changedProperties.push('title');
		}
		sequenceSave(sequence)
		setWizard(WIZARDS.CONTENT_EDITOR)
	}

	function canProgressToNextStep() {
		if (mode === MODES.UPLOAD) {
			return chapters.length > 0
		}
		if (mode === MODES.SOR) {
			// if coming from library with create button, maybe should wait for chapters ?
			return sorReady && chapters?.length
		}
		if (mode === MODES.TITLE) {
			return sequence.title?.length && chapters?.length
		}
		if (mode === MODES.BRAND) {
			return brandReady
		}
	}
	if (wizard === WIZARDS.CREATE) {
		return (
			<div className="create">
				<OnboardingWizard
					numSteps={numSteps}
					currentStep={mode}
					canProgressToNextStep={canProgressToNextStep()}
					toNextStep={() => setCurrentMode(mode => mode + 1)}
					toPrevStep={() => setCurrentMode(mode => mode - 1)}
					onDone={onDone}
				>
					{mode === MODES.UPLOAD && <WizardUpload onChapterFileSelected={onChapterFileSelected} addAsset={addAsset} uploading={uploading} setUploading={setUploading} />}
					{mode === MODES.SOR && <WizardSOR videoTypes={videoTypes} activeSor={activeSor} onEditRulesChange={onEditRulesChange} />}
					{mode === MODES.TITLE && <WizardTitle onTitleChange={onTitleChange} onLanguageChange={onLanguageChange} shouldShowLanguage={shouldShowLanguage} />}
					{mode === MODES.BRAND && <WizardBrand onBrandReady={() => setBrandReady(true)} />}
				</OnboardingWizard>
			</div>
		)
	}
	if (wizard === WIZARDS.CONTENT_EDITOR) {
		return (
			<ContentEditor />
		)
	}

}

NewProjectWizard.videoTypesSor = []
