import { useLocation } from "@reach/router";
import React from 'react';
import CuePoint from '../../../client/cuePoint';
import { ChapterOrder } from '../../../client/sequence/chapter';
import MainPlayer from '../../../components/main-player';
import PlayerStyle from '../../../components/player-style';
import { AppContext } from '../../../lib/app-context';
import { calcPreviewCuePointsTimes, cuePointsState } from '../../../state/cuePoints';
import { incrementPlaybackVersion, playbackState, reloadPlayer, resetActiveClosedCaption, resetSeekAndPlayUntil } from '../../../state/playback';
import { clearTempCache, removeTempScene, scenesState, sequenceState, setScenes, tempScenesState } from '../../../state/sequence';
import Button, { ButtonSize, ButtonVariant } from '../../../ui-components/Button';
import { trackEvent } from '../../../utils/analityics.utils';



interface EditorPreviewProps {
    unTrimmableChapter: boolean;
    visible: boolean;
    onContentDone(): void;
    skipContentStep?: boolean;
}

const PROJECT_ROUTE = 'project'

export default function EditorPreview({ onContentDone, unTrimmableChapter, visible, skipContentStep }: EditorPreviewProps) {
    const { config, isIOS } = React.useContext(AppContext);
    const sequence = sequenceState.use()
    const scenes = scenesState.use(scenes => scenes.map)
    const chapters = tempScenesState.use(scenes => scenes.filter.array)
    const durableChapters = tempScenesState.use(scenes => scenes.filter.durableArray)
    const tempScenes = tempScenesState.use(scenes => scenes.map)
    const formatedChaptersLength = scenesState.use(scenes => scenes.filter.previewReadyChapters.length)
    const manifestVersion = playbackState.use(playback => playback.manifestVersion);
    const [playbackUrl, setPlaybackUrl] = React.useState();
    const localChaptersChanges = tempScenesState.use(tempScenes => tempScenes.cacheVersion);
    const isSaving = React.useRef<boolean>(false)


    const location = useLocation()
    const isOnSequencePage = (location.pathname.replaceAll('/', '') === PROJECT_ROUTE && location.hash)

    const getPlaybackUrl = () => {
        if (!formatedChaptersLength) {
            return undefined;
        }
        const baseUrl = config.PLAYBACK_URL || location.protocol + '//' + location.host;
        const cutScenes = durableChapters.map(c => `${c.linkSid || c.sid},${c.clipFrom || 0},${c.duration}`).join(';');

        const osType = isIOS ? 'hls' : 'dash';
        const manifestType = isIOS ? 'master.m3u8' : 'manifest.mpd';

        return `${baseUrl}/${osType}/${sequence.sid}/${manifestVersion}/chapters/${cutScenes}/${manifestType}`;
    }

    React.useEffect(() => {
        setPlaybackUrl(getPlaybackUrl)
    }, [localChaptersChanges, formatedChaptersLength])

    React.useEffect(() => {
        if (!formatedChaptersLength) {
            return console.log('No ready chapter')
        }
        setPlaybackUrl(getPlaybackUrl)
    }, [manifestVersion])

    const cuePointsData = cuePointsState.use()
    const closedCaptionCuePoints = Object.values(cuePointsData).filter(cuePoint =>
        cuePoint &&
        cuePoint.type === CuePoint.TYPE.CLOSED_CAPTION &&
        cuePoint.words &&
        cuePoint.words.length
    )

    React.useEffect(() => {
        calcPreviewCuePointsTimes(sequenceState.get(), tempScenesState.get().map)
        if (playbackUrl && closedCaptionCuePoints.length && formatedChaptersLength && !unTrimmableChapter && skipContentStep) {
            onDone();
        }
    }, [playbackUrl, closedCaptionCuePoints.length, formatedChaptersLength, unTrimmableChapter, skipContentStep])

    async function saveNewOrder() {
        if (isSaving.current) {
            return
        }
        clearTempCache()
        const chapters = tempScenesState.get().filter.array
        chapters.forEach((c: ChapterOrder) => c.propertiesEval())
        const chapterOrders = chapters.filter(c => c.changedProperties.length && (c.duration || c.sid))
        try {
            isSaving.current = true
            const res = await sequence.reorderScenes(chapterOrders)
            console.log('playback change request', { chapterOrders, response: res })
            chapterOrders.filter(c => !c.duration).forEach(c =>
                removeTempScene(c.sid || c.cid))
            setScenes(res, true, isOnSequencePage)
            reloadPlayer(res.version, 0.001)
        } catch (err) {
            console.error(err)
        }
        isSaving.current = false
    }

    function resetPlaybackState() {
        resetSeekAndPlayUntil()
        resetActiveClosedCaption();
        resetSeekAndPlayUntil();
    }

    async function onDone() {
        if (!sequence.title) {
            return;
        }
        const scenes = scenesState.get().map
        const chaptersChanges = tempScenesState.get().filter.array.find(c =>
            scenes[c.sid || c.cid]?.index !== c.index ||
            scenes[c.sid || c.cid]?.duration !== c.duration ||
            scenes[c.sid || c.cid]?.clipFrom !== c.clipFrom)
        if (chaptersChanges) {
            await saveNewOrder()
        }
        else if (!isOnSequencePage) {
            try {
                await sequence.reorderScenes()
            } catch (err) {
                console.error(err)
            }
        }
        resetPlaybackState()
        onContentDone()
        trackEvent('create-content-complete', {skipContentEditor: skipContentStep});
    }

    React.useEffect(() => {
        return async () => {
            if (isOnSequencePage) {

                const temp = tempScenesState.get();
                const current = scenesState.get();
                const res = await sequence.preReorderScenes(temp, current);
                if (res) {
                    setScenes(res, true, isOnSequencePage);
                    clearTempCache();
                }
                resetPlaybackState();
            }
        };
    }, []);

    return (
        <div className="editor monitor-preview">
            <PlayerStyle isPreview={true} />
            <div className="editor--container">
                <div className="editor--preview">
                    <div className="editor--preview--monitor-container">
                        <div className="screen dashboard preview content">
                            <MainPlayer
                                playbackUrl={playbackUrl}
                                onError={() => setPlaybackUrl(null)}
                                onRetry={() => incrementPlaybackVersion()}
                                isPreview={true}
                                visible={visible}
                                unTrimmableChapter={unTrimmableChapter}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='controls-container'>
                {!isOnSequencePage && <Button variant={ButtonVariant.Primary} data-cy="wizard-next-btn" size={ButtonSize.Large} label='Continue' disabled={unTrimmableChapter} onClick={onDone} />}
            </div>
        </div>
    )
}