import io from 'socket.io-client'
import EventEmitter from 'wolfy87-eventemitter'

import User from '../client/user'
import LogRocket from 'logrocket';
import { appConfig } from './app-context';


/**
 * @typedef {object} Emitter
 * @property {Function} on
 * @property {Function} off
 * }}
 */

export default class Status {
	constructor(sid, userSid, orgSid) {
		this.emitters = {};
		this.handlers = {};
		this.sid = sid
		this.userSid = userSid
		this.orgSid = orgSid
		this.socket = io(appConfig.STATUS_URL, {
			cors: {
				origin: appConfig.STATUS_URL,
				methods: ['GET', 'POST'],
			},
			auth: {
				ps: User.ps
			},
			// rejectUnauthorized: false
		})
			.onAny((type, ...args) => {
				console.log(`Status ${type}`, ...args)
			})
			.on('connect', () => {
				console.log("Status connect socket v1");
				this.userSid ? this.socket.emit('user', this.userSid) : null
				this.sid ? this.socket.emit('sequence', this.sid) : null
			})
			.on('connect_timeout', () => {
				console.log('Status.socket.connect_timeout')
			})
			.on('connect_error', err => {
				console.log('Status.socket.connect_error', err)
			})
			.on('reconnect', () => {
				console.log('Status.socket.reconnect', this.sid)
				if (this.sid) {
					this.socket.emit('sequence', this.sid)
				}
				if (this.userSid) {
					this.socket.emit('user', this.userSid)
				}
			})
		
		// socket v2 
		// this.socketV2 = io(appConfig.STATUS_URL, {
		// 	cors: {
		// 		origin: appConfig.STATUS_URL,
		// 		methods: ['GET', 'POST'],
		// 	},
		// 	auth: {
		// 		ps: User.ps
		// 	},
		// 	path: "/v2/socket.io"
		// 	// rejectUnauthorized: false
		// })
		// 	.onAny((type, ...args) => {
		// 		console.log(`Status V2 ${type}`, ...args)
		// 	})
		// 	.on('connect', () => {
		// 		console.log("Status connect socket v2");
				
		// 		this.socketV2.emit("registerClient", {
		// 			userSid: this.userSid,
		// 			orgSid: this.orgSid,
		// 		});
				
				
		// 	})
		// 	.on('connect_timeout', () => {
		// 		console.log('Status.socket.connect_timeout')
		// 	})
		// 	.on('connect_error', err => {
		// 		console.log('Status.socket.connect_error', err)
		// 	})
		// 	.on('reconnect', () => {
		// 		console.log('Status.socket.reconnect')
		// 		this.socketV2.emit("registerClient", {
		// 			userSid: this.userSid,
		// 			orgSid: this.orgSid,
		// 		});
				
		// 	})
				
	}

	set userSid(value) {
		this._userSid = value;
		this.emitters.user = new EventEmitter();
		if (this.socket) {
			this.socket.emit('user', value)
		}
		this.handlers.user = {};
	}

	get userSid() {
		return this._userSid;
	}

	set orgSid(value) {
		this._orgSid = value;
		this.handlers.org = {};
	}

	get orgSid() {
		return this._orgSid;
	}

	set sid(value) {
		if (value) {
			LogRocket.track('Project load', {
				sid: value
			})
		}
		else if (this._sid) {
			LogRocket.track('Project unload', {
				sid: this._sid
			})
		}
		this._sid = value;
		this.emitters.sequence = new EventEmitter();
		if (this.socket) {
			this.socket.emit('sequence', value)
		}
		this.handlers.sequence = {};
	}

	get sid() {
		return this._sid;
	}

	_ack(status) {
		const {type, sid, itemId, statusType: objectType} = status;
		this.socket.emit('ack', {
			type,
			itemId,
			objectType,
			sid,
		})
	}

	wrap(status) {
		if(status.ack) {
			status.ack = () => this._ack(status)
		}
		return status;
	}

	/**
	 * @returns {Emitter}
	 */
	get sequence() {
		const emitter = {
			on: (type, handler) => {
				this.emitters.sequence.on(type, handler)
				this._on('sequence', type, this.sid);
				return emitter;
			},
			off: (type, handler) => {
				this.emitters.sequence.off(type, handler)
				return emitter;
			}
		}
		return emitter;
	}

	/**
	 * @returns {Emitter}
	 */
		// get footages() {
		// 	const emitter = {
		// 		on: (handler) => {
		// 			this.socketV2.on("footages", handler);
		// 			return emitter;
		// 		},
		// 		off: (type, handler) => {
		// 			this.socketV2.off("footages", handler);
		// 			return emitter;
		// 		}
		// 	}
		// 	return emitter;
		// }
	

	/**
	 * @returns {Emitter}
	 */
	get user() {
		const emitter = {
			on: (type, handler) => {
				this.emitters.user.on(type, handler)
				this._on('user', type, this.userSid);
				this._on('org', type, this.orgSid);
				return emitter;
			},
			off: (type, handler) => {
				this.emitters.user.off(type, handler)
				return emitter;
			}
		}
		return emitter;
	}

	_on(objectType, type, sid) {
		if (!this.handlers[objectType][type]) {
			this.handlers[objectType][type] = status => {
				if(status.sid === sid && status.statusType === objectType) {
					const emitterType = objectType === 'org' ? 'user' : objectType;
					this.emitters[emitterType].emit(type, status)
				}
			};
			this.socket.on(type, event => {
				if (event.sid === sid && this.handlers[objectType][type]) {
					this.handlers[objectType][type](this.wrap(event))
				}
			});
		}
		return this
	}
}
