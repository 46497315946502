import styled from "@emotion/styled/macro";

export const RegenerateButton = styled.div<{disabled?: boolean}>`
  z-index: 0;
  display: flex;
  color: #666666;
  margin: 0px 14px 15px 14px;
  height: 32px;
  border-radius: 8px;
  background-color: ${({ disabled }) => disabled ? "#F2F2F2": "#FFFFFF"};
  transition: border-color 250ms ease-out;
  cursor: ${({ disabled }) => disabled ? "not-allowed": "pointer"};
  opacity: ${({ disabled }) => disabled ? "0.5": "1"};;
  border: ${({ disabled }) => disabled ? "none" : "1px solid #666666"};
  pointer-events:${({ disabled })=> disabled ? 'none' : null };
  padding: 0;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: Open Sans;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;

    &:hover {
        background-color: #F2F2F2;
    }
    &:active {
        background-color: #CCCCCC;
    }
  & svg {
      height: 20px;
      margin-top: 6px;
      margin-right: 2px;
  }
`;
