// Folders drag N drop handlers

export function dragToFolderStartHandler (e, sid, setDraggedClassName, setItemSidDragged){
    setDraggedClassName(e.target.className);
    e.target.className = e.target.className + ' dragged';
    let plug = Array.from(document.getElementsByClassName('plug-pic')).find(x => x.className.includes(sid));
    const { layerY } = e.nativeEvent
    const label = e.target.getElementsByClassName('assets-item-label')[0]
    label && (label.style.transform = `translateY(${Math.min(label.offsetHeight - 70, Math.max(0, layerY - 24))}px)`)
    setTimeout(() => {
      plug.style.zIndex = '3000';
      plug.style.visibility = 'visible'
    }, 10)
    setItemSidDragged(sid);
}

export function dragToFolderEndHandler (e, sid, draggedClassName, setItemSidDragged, setDraggedClassName) {
    e.target.className = draggedClassName;
    let plug = Array.from(document.getElementsByClassName('plug-pic')).find(x => x.className.includes(sid));
    plug.style.zIndex = '1000';
    plug.style.visibility = 'hidden'
    setItemSidDragged(null);
    setDraggedClassName(null);
}

export function onDropActions (folderSid, arr, sidDragged) {
  let itemFound = arr.find(x => x.sid === sidDragged);
  itemFound && (itemFound.folderSid = folderSid);
  itemFound && itemFound.save();
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  );
}

export const scroll = function (step, stopRef, scrollingContainerRef) {
  scrollingContainerRef.current.scrollBy({top: step});
  if (!stopRef.current) {
    setTimeout(function () {
      scroll(step, stopRef, scrollingContainerRef);
    }, 20);
  }
};

export const onDragHandler = (e, draggable, stopRef, scrollingContainerRef) => {
  if (!draggable) {
    return;
  }
  stopRef.current = true;

  if (e.clientY < 150) {
    stopRef.current  = false;
    scroll(-1, stopRef, scrollingContainerRef);
  }

  if (e.clientY > scrollingContainerRef.current.clientHeight - 150) {
    stopRef.current = false;
    scroll(1, stopRef, scrollingContainerRef);
  }
};