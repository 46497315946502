import React from 'react'

export default function CreditsProperties({credits}) {
	return (
		<div className="credit">
			<div className="title" data-testid="credits-title">
				Credits
			</div>
			{credits.map(item => <div key={item.sid} className="item">{item.credit}</div>)}
		</div>
	)
}
