import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';
import { FeatureFeatureName } from '../../client/base/feature';
import { openPeechProUpgradeModal } from '../../components/layout';
import SVG from '../../components/svg';
import { AppContext } from '../../lib/app-context';

const Container = styled.div<{ isBig: boolean }>`
  position: relative;
  width: calc(100% + 20px);
  height: 73px;
  padding-left: 20px;
  background-color: ${({ isBig }): string => (isBig ? COLORS.peech_gray_100 : COLORS.peech_neutral_white)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  white-space: pre;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  transition: background-color 250ms ease-out;
`;

const DiamondContiner = styled.div<{ isBig: boolean }>`
  border-radius: 8px;
  background-color: ${({ isBig }): string => (isBig ? COLORS.peech_gray_100 : COLORS.peech_neutral_white)};
  width: 30px;
  height: 30px;
  width: 60px;
  height: 52px;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  svg[name='diamond-gradient'] {
    margin-top: -12px;
    margin-left: -10px;
    overflow: visible;
  }
  &:hover {
    background-color: ${COLORS.peech_gray_100};
  }
  transition: background-color 250ms ease-out;
`;

const TextContainer = styled.div<{ isBig: boolean }>`
  height: 100%;
  /* margin-left: 16px; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  flex-wrap: nowrap;
  overflow: hidden;

  font-weight: 500;
  font-size: 12px;
  color: ${COLORS.peech_gray_600};
  visibility: ${({ isBig }): string => (isBig ? 'visible' : 'hidden')};

  &:hover {
    color: ${COLORS.peech_primary_2_400};
  }
  transition: visibility 250ms ease-out, color 250ms ease-out;
`;

interface RoadMapButtonProperties {
  isBig: boolean;
}

export default function RoadMapButton({ isBig }: RoadMapButtonProperties) {
  const { plan, user } = React.useContext(AppContext);
  const allowedRoadmap = React.useMemo(
    () => !!plan?.planAllowedTierFeatures?.includes(FeatureFeatureName.ROADMAP),
    [plan]
  );

  const onButtonClick = () => {
    if (allowedRoadmap) {
      navigate('/roadmap');
    } else {
      openPeechProUpgradeModal(user, 'Roadmap Button', FeatureFeatureName.ROADMAP);
    }
  };

  return (
    <Container onClick={onButtonClick} isBig={isBig}>
      <DiamondContiner isBig={isBig}>
        {allowedRoadmap ? (
          <SVG name="finger-up-gradient" viewBox="0 0 24 24" />
        ) : (
          <SVG name="diamond-gradient" viewBox="0 0 18 18" />
        )}
      </DiamondContiner>
      <TextContainer isBig={isBig}>
        Your insights matter!
        <br />
        Vote for the next features
      </TextContainer>
    </Container>
  );
}
