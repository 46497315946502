module.exports = `
<html xmlns:v="urn:schemas-microsoft-com:vml"
xmlns:o="urn:schemas-microsoft-com:office:office"
xmlns:w="urn:schemas-microsoft-com:office:word"
xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
xmlns="http://www.w3.org/TR/REC-html40">

<head>
<meta http-equiv=Content-Type content="text/html; charset=utf-8">
<meta name=ProgId content=Word.Document>
<meta name=Generator content="Microsoft Word 15">
<meta name=Originator content="Microsoft Word 15">
<link rel=File-List href="Terms%20of%20Service%20(.01.11).fld/filelist.xml">
<link rel=themeData href="Terms%20of%20Service%20(.01.11).fld/themedata.thmx">
<link rel=colorSchemeMapping
href="Terms%20of%20Service%20(.01.11).fld/colorschememapping.xml">
<!--[if gte mso 9]><xml>
 <w:WordDocument>
  <w:SpellingState>Clean</w:SpellingState>
  <w:GrammarState>Clean</w:GrammarState>
  <w:TrackMoves>false</w:TrackMoves>
  <w:TrackFormatting/>
  <w:PunctuationKerning/>
  <w:ValidateAgainstSchemas/>
  <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
  <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
  <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
  <w:DoNotPromoteQF/>
  <w:LidThemeOther>EN-US</w:LidThemeOther>
  <w:LidThemeAsian>X-NONE</w:LidThemeAsian>
  <w:LidThemeComplexScript>HE</w:LidThemeComplexScript>
  <w:Compatibility>
   <w:BreakWrappedTables/>
   <w:SnapToGridInCell/>
   <w:WrapTextWithPunct/>
   <w:UseAsianBreakRules/>
   <w:DontGrowAutofit/>
   <w:SplitPgBreakAndParaMark/>
   <w:EnableOpenTypeKerning/>
   <w:DontFlipMirrorIndents/>
   <w:OverrideTableStyleHps/>
  </w:Compatibility>
  <w:DoNotOptimizeForBrowser/>
  <m:mathPr>
   <m:mathFont m:val="Cambria Math"/>
   <m:brkBin m:val="before"/>
   <m:brkBinSub m:val="&#45;-"/>
   <m:smallFrac m:val="off"/>
   <m:dispDef/>
   <m:lMargin m:val="0"/>
   <m:rMargin m:val="0"/>
   <m:defJc m:val="centerGroup"/>
   <m:wrapIndent m:val="1440"/>
   <m:intLim m:val="subSup"/>
   <m:naryLim m:val="undOvr"/>
  </m:mathPr></w:WordDocument>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="false"
  DefSemiHidden="false" DefQFormat="false" DefPriority="99"
  LatentStyleCount="376">
  <w:LsdException Locked="false" Priority="0" QFormat="true" Name="Normal"/>
  <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 1"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 2"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 3"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 4"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 5"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 6"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 7"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 8"/>
  <w:LsdException Locked="false" Priority="9" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="heading 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index 9"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 1"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 2"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 3"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 4"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 5"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 6"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 7"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 8"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" Name="toc 9"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="header"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footer"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="index heading"/>
  <w:LsdException Locked="false" Priority="35" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="caption"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of figures"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="envelope return"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="footnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="line number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="page number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote reference"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="endnote text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="table of authorities"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="macro"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="toa heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Bullet 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Number 5"/>
  <w:LsdException Locked="false" Priority="10" QFormat="true" Name="Title"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Closing"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Signature"/>
  <w:LsdException Locked="false" Priority="1" SemiHidden="true"
   UnhideWhenUsed="true" Name="Default Paragraph Font"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="List Continue 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Message Header"/>
  <w:LsdException Locked="false" Priority="11" QFormat="true" Name="Subtitle"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Salutation"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Date"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text First Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Note Heading"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Body Text Indent 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Block Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="FollowedHyperlink"/>
  <w:LsdException Locked="false" Priority="22" QFormat="true" Name="Strong"/>
  <w:LsdException Locked="false" Priority="20" QFormat="true" Name="Emphasis"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Document Map"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Plain Text"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="E-mail Signature"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Top of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Bottom of Form"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal (Web)"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Acronym"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Address"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Cite"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Code"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Definition"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Keyboard"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Preformatted"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Sample"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Typewriter"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="HTML Variable"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Normal Table"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="annotation subject"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="No List"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Outline List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Simple 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Classic 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Colorful 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Columns 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Grid 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 4"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 5"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 7"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table List 8"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table 3D effects 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Contemporary"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Elegant"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Professional"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Subtle 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 1"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 2"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Web 3"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Balloon Text"/>
  <w:LsdException Locked="false" Priority="39" Name="Table Grid"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Table Theme"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Placeholder Text"/>
  <w:LsdException Locked="false" Priority="1" QFormat="true" Name="No Spacing"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 1"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 1"/>
  <w:LsdException Locked="false" SemiHidden="true" Name="Revision"/>
  <w:LsdException Locked="false" Priority="34" QFormat="true"
   Name="List Paragraph"/>
  <w:LsdException Locked="false" Priority="29" QFormat="true" Name="Quote"/>
  <w:LsdException Locked="false" Priority="30" QFormat="true"
   Name="Intense Quote"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 1"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 1"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 1"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 1"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 1"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 2"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 2"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 2"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 2"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 2"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 2"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 3"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 3"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 3"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 3"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 3"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 3"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 4"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 4"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 4"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 4"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 4"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 4"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 5"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 5"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 5"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 5"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 5"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 5"/>
  <w:LsdException Locked="false" Priority="60" Name="Light Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="61" Name="Light List Accent 6"/>
  <w:LsdException Locked="false" Priority="62" Name="Light Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="63" Name="Medium Shading 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="64" Name="Medium Shading 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="65" Name="Medium List 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="66" Name="Medium List 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="67" Name="Medium Grid 1 Accent 6"/>
  <w:LsdException Locked="false" Priority="68" Name="Medium Grid 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="69" Name="Medium Grid 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="70" Name="Dark List Accent 6"/>
  <w:LsdException Locked="false" Priority="71" Name="Colorful Shading Accent 6"/>
  <w:LsdException Locked="false" Priority="72" Name="Colorful List Accent 6"/>
  <w:LsdException Locked="false" Priority="73" Name="Colorful Grid Accent 6"/>
  <w:LsdException Locked="false" Priority="19" QFormat="true"
   Name="Subtle Emphasis"/>
  <w:LsdException Locked="false" Priority="21" QFormat="true"
   Name="Intense Emphasis"/>
  <w:LsdException Locked="false" Priority="31" QFormat="true"
   Name="Subtle Reference"/>
  <w:LsdException Locked="false" Priority="32" QFormat="true"
   Name="Intense Reference"/>
  <w:LsdException Locked="false" Priority="33" QFormat="true" Name="Book Title"/>
  <w:LsdException Locked="false" Priority="37" SemiHidden="true"
   UnhideWhenUsed="true" Name="Bibliography"/>
  <w:LsdException Locked="false" Priority="39" SemiHidden="true"
   UnhideWhenUsed="true" QFormat="true" Name="TOC Heading"/>
  <w:LsdException Locked="false" Priority="41" Name="Plain Table 1"/>
  <w:LsdException Locked="false" Priority="42" Name="Plain Table 2"/>
  <w:LsdException Locked="false" Priority="43" Name="Plain Table 3"/>
  <w:LsdException Locked="false" Priority="44" Name="Plain Table 4"/>
  <w:LsdException Locked="false" Priority="45" Name="Plain Table 5"/>
  <w:LsdException Locked="false" Priority="40" Name="Grid Table Light"/>
  <w:LsdException Locked="false" Priority="46" Name="Grid Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="Grid Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="Grid Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="Grid Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="Grid Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="Grid Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="Grid Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="Grid Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="Grid Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="Grid Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="46" Name="List Table 1 Light"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark"/>
  <w:LsdException Locked="false" Priority="51" Name="List Table 6 Colorful"/>
  <w:LsdException Locked="false" Priority="52" Name="List Table 7 Colorful"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 1"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 1"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 1"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 1"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 1"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 1"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 2"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 2"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 2"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 2"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 2"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 2"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 3"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 3"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 3"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 3"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 3"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 3"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 4"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 4"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 4"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 4"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 4"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 4"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 5"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 5"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 5"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 5"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 5"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 5"/>
  <w:LsdException Locked="false" Priority="46"
   Name="List Table 1 Light Accent 6"/>
  <w:LsdException Locked="false" Priority="47" Name="List Table 2 Accent 6"/>
  <w:LsdException Locked="false" Priority="48" Name="List Table 3 Accent 6"/>
  <w:LsdException Locked="false" Priority="49" Name="List Table 4 Accent 6"/>
  <w:LsdException Locked="false" Priority="50" Name="List Table 5 Dark Accent 6"/>
  <w:LsdException Locked="false" Priority="51"
   Name="List Table 6 Colorful Accent 6"/>
  <w:LsdException Locked="false" Priority="52"
   Name="List Table 7 Colorful Accent 6"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Hyperlink"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Hashtag"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Unresolved Mention"/>
  <w:LsdException Locked="false" SemiHidden="true" UnhideWhenUsed="true"
   Name="Smart Link"/>
 </w:LatentStyles>
</xml><![endif]-->
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;
	mso-font-charset:0;
	mso-generic-font-family:roman;
	mso-font-pitch:variable;
	mso-font-signature:3 0 0 0 1 0;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;
	mso-font-charset:0;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:-536859905 -1073732485 9 0 511 0;}
@font-face
	{font-family:Georgia;
	panose-1:2 4 5 2 5 4 5 2 3 3;
	mso-font-charset:0;
	mso-generic-font-family:roman;
	mso-font-pitch:variable;
	mso-font-signature:647 0 0 0 159 0;}
@font-face
	{font-family:"Segoe UI";
	panose-1:2 11 5 2 4 2 4 2 2 3;
	mso-font-charset:0;
	mso-generic-font-family:swiss;
	mso-font-pitch:variable;
	mso-font-signature:-469750017 -1073683329 9 0 511 0;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:"";
	margin:0cm;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-ansi-language:EN-US;}
h1
	{mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:24.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	mso-outline-level:1;
	font-size:24.0pt;
	font-family:"Times New Roman",serif;
	mso-font-kerning:0pt;
	mso-ansi-language:EN-US;
	mso-bidi-font-weight:normal;}
h2
	{mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:0cm;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	mso-outline-level:2;
	font-size:18.0pt;
	font-family:"Times New Roman",serif;
	mso-ansi-language:EN-US;
	mso-bidi-font-weight:normal;}
h3
	{mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:14.0pt;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:0cm;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	mso-outline-level:3;
	font-size:14.0pt;
	font-family:"Times New Roman",serif;
	mso-ansi-language:EN-US;
	mso-bidi-font-weight:normal;}
h4
	{mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:2.0pt;
	margin-left:0cm;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	mso-outline-level:4;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	mso-ansi-language:EN-US;
	mso-bidi-font-weight:normal;}
h5
	{mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:11.0pt;
	margin-right:0cm;
	margin-bottom:2.0pt;
	margin-left:0cm;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	mso-outline-level:5;
	font-size:11.0pt;
	font-family:"Times New Roman",serif;
	mso-ansi-language:EN-US;
	mso-bidi-font-weight:normal;}
h6
	{mso-style-noshow:yes;
	mso-style-priority:9;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:10.0pt;
	margin-right:0cm;
	margin-bottom:2.0pt;
	margin-left:0cm;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	mso-outline-level:6;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	mso-ansi-language:EN-US;
	mso-bidi-font-weight:normal;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-link:"Comment Text Char";
	margin:0cm;
	mso-pagination:widow-orphan;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-ansi-language:EN-US;}
span.MsoCommentReference
	{mso-style-noshow:yes;
	mso-style-priority:99;
	mso-ansi-font-size:8.0pt;
	mso-bidi-font-size:8.0pt;}
p.MsoTitle, li.MsoTitle, div.MsoTitle
	{mso-style-priority:10;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:24.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	font-size:36.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-ansi-language:EN-US;
	font-weight:bold;
	mso-bidi-font-weight:normal;}
p.MsoSubtitle, li.MsoSubtitle, div.MsoSubtitle
	{mso-style-priority:11;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-next:Normal;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:0cm;
	mso-pagination:widow-orphan lines-together;
	page-break-after:avoid;
	font-size:24.0pt;
	font-family:"Georgia",serif;
	mso-fareast-font-family:Georgia;
	mso-bidi-font-family:Georgia;
	color:#666666;
	mso-ansi-language:EN-US;
	font-style:italic;
	mso-bidi-font-style:normal;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-link:"Balloon Text Char";
	margin:0cm;
	mso-pagination:widow-orphan;
	font-size:9.0pt;
	font-family:"Segoe UI",sans-serif;
	mso-fareast-font-family:"Times New Roman";
	mso-ansi-language:EN-US;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	mso-add-space:auto;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-ansi-language:EN-US;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	mso-add-space:auto;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-ansi-language:EN-US;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	mso-add-space:auto;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-ansi-language:EN-US;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-type:export-only;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	mso-add-space:auto;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	mso-fareast-font-family:"Times New Roman";
	mso-ansi-language:EN-US;}
span.CommentTextChar
	{mso-style-name:"Comment Text Char";
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Comment Text";
	mso-ansi-font-size:10.0pt;
	mso-bidi-font-size:10.0pt;}
span.BalloonTextChar
	{mso-style-name:"Balloon Text Char";
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-unhide:no;
	mso-style-locked:yes;
	mso-style-link:"Balloon Text";
	mso-ansi-font-size:9.0pt;
	mso-bidi-font-size:9.0pt;
	font-family:"Segoe UI",sans-serif;
	mso-ascii-font-family:"Segoe UI";
	mso-hansi-font-family:"Segoe UI";
	mso-bidi-font-family:"Segoe UI";}
span.SpellE
	{mso-style-name:"";
	mso-spl-e:yes;}
span.GramE
	{mso-style-name:"";
	mso-gram-e:yes;}
.MsoChpDefault
	{mso-style-type:export-only;
	mso-default-props:yes;
	mso-ansi-language:EN-US;}
 /* Page Definitions */
 @page
	{mso-footnote-separator:url("Terms%20of%20Service%20\(.01.11\).fld/header.html") fs;
	mso-footnote-continuation-separator:url("Terms%20of%20Service%20\(.01.11\).fld/header.html") fcs;
	mso-endnote-separator:url("Terms%20of%20Service%20\(.01.11\).fld/header.html") es;
	mso-endnote-continuation-separator:url("Terms%20of%20Service%20\(.01.11\).fld/header.html") ecs;}
@page WordSection1
	{size:612.0pt 792.0pt;
	margin:72.0pt 90.0pt 57.6pt 90.0pt;
	mso-header-margin:36.0pt;
	mso-footer-margin:39.6pt;
	mso-page-numbers:1;
	mso-title-page:yes;
	mso-even-footer:url("Terms%20of%20Service%20\(.01.11\).fld/header.html") ef1;
	mso-footer:url("Terms%20of%20Service%20\(.01.11\).fld/header.html") f1;
	mso-paper-source:0;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 @list l0
	{mso-list-id:313950183;
	mso-list-template-ids:227968172;}
@list l0:level1
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:54.0pt;
	text-indent:-18.0pt;
	mso-ascii-font-family:Calibri;
	mso-fareast-font-family:Calibri;
	mso-hansi-font-family:Calibri;
	mso-bidi-font-family:Calibri;}
@list l0:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:90.0pt;
	text-indent:-18.0pt;}
@list l0:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:126.0pt;
	text-indent:-9.0pt;}
@list l0:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-18.0pt;}
@list l0:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:198.0pt;
	text-indent:-18.0pt;}
@list l0:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:234.0pt;
	text-indent:-9.0pt;}
@list l0:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:270.0pt;
	text-indent:-18.0pt;}
@list l0:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:306.0pt;
	text-indent:-18.0pt;}
@list l0:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:342.0pt;
	text-indent:-9.0pt;}
@list l1
	{mso-list-id:777025914;
	mso-list-template-ids:1330574272;}
@list l1:level1
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:18.0pt;
	text-indent:-18.0pt;
	mso-ansi-font-weight:normal;}
@list l1:level2
	{mso-level-text:"%1\.%2\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:39.6pt;
	text-indent:-21.6pt;
	mso-ansi-font-weight:normal;
	mso-ansi-font-style:normal;}
@list l1:level3
	{mso-level-text:"%1\.%2\.%3\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:61.2pt;
	text-indent:-25.2pt;}
@list l1:level4
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:86.4pt;
	text-indent:-32.35pt;}
@list l1:level5
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:111.6pt;
	text-indent:-39.6pt;}
@list l1:level6
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:136.8pt;
	text-indent:-46.75pt;}
@list l1:level7
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:162.0pt;
	text-indent:-54.0pt;}
@list l1:level8
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:187.2pt;
	text-indent:-61.2pt;}
@list l1:level9
	{mso-level-text:"%1\.%2\.%3\.%4\.%5\.%6\.%7\.%8\.%9\.";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:216.0pt;
	text-indent:-72.0pt;}
@list l2
	{mso-list-id:1260220232;
	mso-list-type:hybrid;
	mso-list-template-ids:-608503350 -884322114 67698713 67698715 67698703 67698713 67698715 67698703 67698713 67698715;}
@list l2:level1
	{mso-level-number-format:roman-lower;
	mso-level-text:"\(%1\)";
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:97.2pt;
	text-indent:-36.0pt;}
@list l2:level2
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:115.2pt;
	text-indent:-18.0pt;}
@list l2:level3
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:151.2pt;
	text-indent:-9.0pt;}
@list l2:level4
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:187.2pt;
	text-indent:-18.0pt;}
@list l2:level5
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:223.2pt;
	text-indent:-18.0pt;}
@list l2:level6
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:259.2pt;
	text-indent:-9.0pt;}
@list l2:level7
	{mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:295.2pt;
	text-indent:-18.0pt;}
@list l2:level8
	{mso-level-number-format:alpha-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:left;
	margin-left:331.2pt;
	text-indent:-18.0pt;}
@list l2:level9
	{mso-level-number-format:roman-lower;
	mso-level-tab-stop:none;
	mso-level-number-position:right;
	margin-left:367.2pt;
	text-indent:-9.0pt;}
ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>
<!--[if gte mso 10]>
<style>
 /* Style Definitions */
 table.MsoNormalTable
	{mso-style-name:"Table Normal";
	mso-tstyle-rowband-size:0;
	mso-tstyle-colband-size:0;
	mso-style-noshow:yes;
	mso-style-priority:99;
	mso-style-parent:"";
	mso-padding-alt:0cm 5.4pt 0cm 5.4pt;
	mso-para-margin:0cm;
	mso-pagination:widow-orphan;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	mso-ansi-language:EN-US;}
</style>
<![endif]--><!--[if gte mso 9]><xml>
 <o:shapedefaults v:ext="edit" spidmax="1026"/>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <o:shapelayout v:ext="edit">
  <o:idmap v:ext="edit" data="1"/>
 </o:shapelayout></xml><![endif]-->
</head>

<body lang=en-IL style='tab-interval:36.0pt;word-wrap:break-word'>

<div class=WordSection1>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none'><a
name="_gjdgxs"></a><b style='mso-bidi-font-weight:normal'><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'>Terms of Service<o:p></o:p></span></b></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
class=SpellE><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri'>Peech</span></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'> Technology Ltd. (&quot;<span class=SpellE><b style='mso-bidi-font-weight:
normal'>Peech</b></span>&quot;, &quot;<b style='mso-bidi-font-weight:normal'>us</b>&quot;,
&quot;<b style='mso-bidi-font-weight:normal'>our</b>&quot;, or &quot;<b
style='mso-bidi-font-weight:normal'>we</b>&quot;), a company incorporated in
the State of Israel with Company No. 516254000, has developed a proprietary
algorithm which analyzes the verbal content of video footage and automatically
produces an edited video based on such content (the &quot;<b style='mso-bidi-font-weight:
normal'>Edited</b> <b style='mso-bidi-font-weight:normal'>Video</b>&quot;, and
the &quot;<b style='mso-bidi-font-weight:normal'>Services</b>&quot;,
respectively), available to you through this website (&quot;<b
style='mso-bidi-font-weight:normal'>Site</b>&quot;). It is hereby clarified
that the terms “Services” shall include any services provided by <span
class=SpellE>Peech</span> in creating the Edited Video(s) through the Platform
or otherwise. These Terms of Service (&quot;<b style='mso-bidi-font-weight:
normal'>Terms</b>&quot;) govern your access and use of the Site and Services
available thereon. Our Privacy Notice, available at [app.peech-ai.com] (&quot;<b
style='mso-bidi-font-weight:normal'>Privacy Notice</b>&quot;) governs our
collection, processing and transfer of any Personal Data (as such term is
defined in the Privacy Notice). &quot;<b style='mso-bidi-font-weight:normal'>You</b>&quot;
means an individual using the Site or Services.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'>Please read these Terms carefully. By clicking on the button marked
&quot;I agree&quot; you signify your assent to these Terms. Changes may be made
to these Terms from time to time, following which, your continued use of the
Site and/or Services shall constitute your acceptance of such change. If you do
not agree to any of these Terms, please do not click the button marked &quot;I
agree&quot; and do not use the Site or Services.<span style='color:black'><o:p></o:p></span></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>If you are registering on behalf of any entity or company
(&quot;<b style='mso-bidi-font-weight:normal'>Company</b>&quot;), you represent
that you are authorized to <span class=GramE>enter into</span>, and bind the
Company to these Terms and register for the Services. You are solely
responsible for ensuring that these Terms <span class=GramE>are in compliance
with</span> all laws, rules, and regulations applicable to you and the Company
and the right to access the Services is revoked where these Terms or use of the
Services is prohibited. <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>In the event of a conflict between these Terms and a
statement of work and/or customer agreement we have executed with you or the Company
on whose behalf you are using the Site and/or Services, separately and prior to
entering into these Terms (&quot;<b>Customer Agreement</b>&quot;), the
provisions of such Customer Agreement, as applicable, shall prevail.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>Use of Site and Services</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:
11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;
color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>Subject to these Terms, <span class=SpellE>Peech</span>
allows you to access and use the Site and Services on a non-exclusive basis. <span
class=SpellE>Peech</span> may, at its sole discretion and at any time, modify
or discontinue providing the Site, Services or any part thereof without notice
and shall not be liable to you or any third party for any modification or
discontinuance of the Site and/or Services. <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>1.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>Use of and access to the Site and Services is void where
prohibited by law. You represent and warrant that (a) any and all registration
information you submit is truthful and accurate; (b) you will maintain the
accuracy of such information; (c) you are 18 years of age or older, and have
the ability to form a binding contract; (d) your use of the Site and Services
does not violate any applicable law, regulation, or obligation you may have to
a third party; and (e) you shall comply with applicable laws, regulations,
guidelines, and these Terms throughout your use of the Site and/or Services. <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><i
style='mso-bidi-font-style:normal'><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><span
style='mso-list:Ignore'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span></i><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>Account Registration</span></b><i
style='mso-bidi-font-style:normal'><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><o:p></o:p></span></i></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>2.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>In order to use the Services, you will need to have a
registered account. [You can register by logging in through your Facebook,
Gmail or other third-party login account (&quot;<b style='mso-bidi-font-weight:
normal'>Login Account</b>&quot;) as may be permitted by <span class=SpellE>Peech</span>
from time to time. By registering through a Login <span class=GramE>Account</span>
you represent and warrant that such Login Account is yours and that you have
full rights to provide us with the information in such Login Account.</span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;background:lime;mso-highlight:lime'> </span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>To complete the registration process, you must provide all
(additional) registration information as requested by us. We may indicate that
the provision of some information is optional, but your agreement to provide
such information may assist us in providing you with improved Services. <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none;tab-stops:
263.05pt'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri'><span style='mso-tab-count:1'>                                                                                        </span><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>2.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span class=SpellE><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>Peech</span></span><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'>
offers different plans to suit your needs. Upon registration you can indicate
your choice of plan. Different plans offer different features and scopes, all
as listed on the Site </span><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri'>or as
separately provided to you<span style='color:black'>. If you wish to change the
plan for which you are registered, such change will be effective as of the
subsequent month</span>.<span style='color:black'><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-left:39.6pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>2.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span class=SpellE><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>Peech</span></span><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'>
may refuse to open an account for any individual or entity at its sole
discretion, all subject to applicable law.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none;tab-stops:
131.1pt'><b style='mso-bidi-font-weight:normal'><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>2.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>You agree to notify us immediately of any unauthorized use
of your account or password. You are fully and solely responsible for the
security of your computer system and/or mobile device and all activity on your
account, even if such activities were not committed by you. To the fullest
extent permitted by applicable law, <span class=SpellE>Peech</span> will not be
liable for any losses or damage arising from unauthorized use of your account
or password, and you agree to indemnify and hold <span class=SpellE>Peech</span>
harmless for any unauthorized, improper or illegal use of your account and any
charges and taxes incurred, unless you have notified us via e-mail at [</span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'>info@peech-ai.com<span style='color:black'>] that your account has
been compromised and have requested that we block access to it, which we will
do as soon as reasonably practicable. We do not police for and cannot guarantee
that we will learn of or prevent, any inappropriate use of the Site and
Services.<o:p></o:p></span></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>Termination of Account</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>3.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>You acknowledge and agree that <span class=SpellE>Peech</span>
may suspend or terminate your account at any time by providing three (3) days
prior notice to you. Additionally, upon the occurrence of any of the following
events, <span class=SpellE>Peech</span> may suspend or terminate your account
immediately: (i) expiration or idleness of your account, (ii) violation of the
letter or spirit of these Terms, (iii) fraudulent, harassing or abusive
behavior, (iv) behavior that is illegal or harmful to other users, third
parties, or the business interests of <span class=SpellE>Peech</span>, or (v)
failure to make payment in accordance with the terms specified herein, or use
of your chargeback rights with your credit card company or denial or dispute of
any preapproval obtained by <span class=SpellE>Peech</span> from your credit
card company. If your account is terminated, you may not rejoin <span
class=SpellE>Peech</span> again without express permission. Upon termination of
your account, you shall not have any further access to any Content that may be
available through your account. <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>3.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>We reserve the right to investigate suspected violations
of these Terms or illegal and inappropriate behavior through the Site and/or
Services. We will fully cooperate with any law enforcement authorities or court
order requesting or directing us to disclose the identity, behavior or Content
of anyone believed to have violated these Terms or to have engaged in illegal
behavior in connection with the Site and/or Services. <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>3.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>You may request termination of your <span class=SpellE>Peech</span>
account at any time and for any reason by sending an email to [</span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'>info@peech-ai.com<span style='color:black'>], and such termination
will be effective at the end of the following calendar month. Following such
time, <span class=SpellE>Peech</span> shall close your account as soon as
reasonably practicable. Any suspension or termination of your account shall not
affect your obligations under these Terms (including but not limited to
ownership, indemnification, any representations and warranties made by you,
limitation of liability, and payment obligations), which by their sense and
context are intended to survive such suspension or termination. <o:p></o:p></span></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>Fees and Payment </span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>4.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>You agree to pay <span class=SpellE>Peech</span> the fees
as specified on the Site in accordance with the plan for which you have
registered or pursuant to the applicable Customer Agreement. Unless otherwise
specified in the plan for which you have subscribed or the Customer Agreement,
payments are to be made on a monthly basis, in advance of the applicable month.
It is clarified that you will not be eligible for a refund in respect of any
month paid in advance. You will be asked to provide customary billing
information such as name, billing address and credit card information either to
<span class=SpellE>Peech</span> or its third-party payment processor(s). You
hereby authorize the collection of such amounts by charging the credit card
provided, either directly by <span class=SpellE>Peech</span> or indirectly, via
a third-party online payment processor or by one of the payment methods
described in the Services. If you are directed to a third-party payment
processor, you may be subject to terms and conditions governing use of that
third party's service and that third party's Personal Data collection
practices. Please review such terms and conditions and privacy policy before
using such services. <o:p></o:p></span></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>4.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>Where applicable, taxes may also be charged. Except as
expressly provided in these Terms, fees are non-refundable.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>4.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>Please note that <span class=SpellE>Peech</span> may impose
or deduct foreign currency processing costs on or from any payments or payouts
by <span class=SpellE>Peech</span> in currencies other than U.S. dollars or New
Israeli Shekel. When converting currency, prices may be rounded up to the
nearest whole number. <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>Content</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>5.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>Certain types of content may be made available through the
Site and/or Services. &quot;<b style='mso-bidi-font-weight:normal'>Content</b>&quot;
as used in these Terms means, collectively, all content on or made available
through the Site and/or Services, including the Edited Videos and any images,
photos, pictures, videos, music t</span><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri'>racks<span
style='color:black'> and any modifications or derivatives of the foregoing. <span
class=SpellE>Peech</span> allows you to upload certain Content including but
not limited to images and/or pictures and/or videos on or through the Site
and/or Services, referred to herein as &quot;<b style='mso-bidi-font-weight:
normal'>User Content</b>&quot;.<span style='mso-spacerun:yes'>  </span><o:p></o:p></span></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>5.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>Content comes from a variety of sources. You understand
that <span class=SpellE>Peech</span> is not responsible for the accuracy,
usefulness, safety, appropriateness, or infringement of any intellectual
property rights of or relating to the Content (including but not limited to the
User Content). You hereby waive any legal or equitable rights or remedies you
have or may have against us with respect thereto.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>User Content Restrictions</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none'><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>6.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span class=SpellE><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>Peech</span></span><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'>
has no obligation to accept, display, or maintain any User Content. Moreover, <span
class=SpellE>Peech</span> reserves the right to remove and permanently delete
any User Content uploaded by you, without notice and for any reason. You are
and shall <span class=GramE>remain at all times</span> fully and solely
responsible for any User Content that you upload to the Site and/or Services.
You represent and warrant that any User Content that you upload (i) complies
with applicable law; (ii) does not infringe or violate any third-party
intellectual property rights, privacy or publicity rights, or moral or other
rights; and (iii) that you have all necessary rights, licenses, consents, and
authorities to submit such User Content. <o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none'><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>6.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>Without limiting the foregoing, you agree that you will
not transmit, submit or upload any User Content or act in any way that: (1)
restricts or inhibits use of the Site and/or Services; (2) violates the legal
rights of others, including defaming, abuse, stalking or threatening users or
individuals; (3) infringes (or results in the infringement of) the intellectual
property, moral, publicity, privacy, or other rights of any third party; (4) is
(or you reasonably believe or should reasonably believe to be) in furtherance
of any illegal, counterfeiting, fraudulent, pirating, unauthorized, or violent
activity, or that involves (or you reasonably believe or should reasonably
believe to involve) any stolen, illegal, counterfeit, fraudulent, pirated, or
unauthorized material; (5) does not comply with all applicable laws, rules and
regulations; (6) imposes an unreasonably or disproportionately large load on
our infrastructure; or (7) posts, stores, transmits, offers, or solicits
anything that contains the following, or that you know contains links to the
following or to locations that in turn contain links to the following: (a)
material that we determine to be offensive (including material promoting or
glorifying hate, violence, bigotry, or any entity (past or present) principally
dedicated to such causes or items associated with such an entity), (b) material
that is racially or ethnically insensitive, material that is defamatory,
harassing or threatening, (c) pornography or obscene material, (d) any virus,
worm, trojan horse, or other harmful or disruptive component or (e) anything
that encourages conduct that would be considered a criminal offense, give rise
to civil liability, violate any law or regulation or is otherwise inappropriate
or offensive.<b style='mso-bidi-font-weight:normal'><i style='mso-bidi-font-style:
normal'> </i></b><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>Use Restrictions</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:
11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;
color:black'><span style='mso-spacerun:yes'> </span></span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:39.6pt;text-align:justify;text-indent:
-21.6pt;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>7.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>You may not do or attempt to do or facilitate a third
party in doing any of the following: (1) decipher, decompile, disassemble, or
reverse-engineer any of the software and/or code, if and as applicable, used to
provide the Site or Services without our prior written authorization, including
framing or mirroring any part of the Site or Services; (2) circumvent, disable,
or otherwise interfere with security-related features of the Site and/or
Services or features that prevent or restrict use or copying of any Content;
(3) use the Site or Services or Content thereon in connection with any
commercial endeavors in any manner, except for the purposes specifically set
forth in these Terms; (4) use any robot, spider, site search or retrieval
application, or any other manual or automatic device or process to retrieve,
index, data-mine, or in any way reproduce or circumvent the navigational
structure or presentation of the Site or Services; (5) use or access another
user’s account or password without permission; (6) use the Site or Services or
Content thereon in any manner not permitted by these Terms or applicable law.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:39.6pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:39.6pt;text-align:justify;text-indent:
-21.6pt;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>7.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><u><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>Shutterstock Restrictions</span></u><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>. You may have access to certain Content through the Site
and/or Services licensed from Shutterstock Inc. (“<b>Shutterstock</b>” and &quot;<b>Shutterstock
Content</b>&quot;, respectively). Without derogating from any restrictions in
these Terms, you agree you shall not and shall not allow any third party to do
any of the following with respect to the Shutterstock Content.<o:p></o:p></span></p>

<p class=MsoListParagraph><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:61.2pt;text-align:justify;text-indent:
-25.2pt;mso-pagination:none;mso-list:l1 level3 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>7.2.1.</span></span><![endif]><span
dir=LTR></span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>You shall not use any Shutterstock
Content:<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>except solely as incorporated into an Edited Video or
otherwise download any Shutterstock Content in its original, unaltered form
outside the Site and/or Services;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>together with pornographic, defamatory, or unlawful
content or in such a manner that it infringes upon any third party’s trademark
or intellectual property rights;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>portraying any person depicted therein (a &quot;<b>Model</b>&quot;)
in a way that a reasonable person would find offensive, including but not
limited to depicting a Model: a) in connection with pornography, &quot;adult
videos&quot;, adult entertainment venues, escort services, dating services, or
the like; b) in connection with the advertisement or promotion of tobacco
products; c) as endorsing a political party, candidate, or elected official, or
political opinion; d) as suffering from, or medicating for, a physical or
mental ailment; or e) engaging in immoral or criminal activities;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>as a trademark, service mark, or logo.<o:p></o:p></span></p>

<p class=MsoListParagraph><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:61.2pt;text-align:justify;text-indent:
-25.2pt;mso-pagination:none;mso-list:l1 level3 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>7.2.2.</span></span><![endif]><span
dir=LTR></span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>You shall not use any Shutterstock
Content that is music:<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>as a musical theme in connection with any Edited Video;<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>in an audio-only capacity in which music is the primary
content with any Edited Video;<o:p></o:p></span></p>

<p class=MsoListParagraph><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>in an international television advertising campaign by or
on behalf of an entity with annual revenues of more than One Billion Dollars
($1,000,000,000);<o:p></o:p></span></p>

<p class=MsoListParagraph><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>remixed or otherwise altered, except that you may engage
in basic editing (e.g., setting start/stop points, determining fade-in/fade-out
points, etc.);<o:p></o:p></span></p>

<p class=MsoListParagraph><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>e.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>in a downloadable form available through the internet or
otherwise including making it available via FTP, IRC, peer-to-peer file sharing
services or the like.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpFirst style='text-align:justify'><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoListParagraphCxSpLast style='margin-left:97.2pt;mso-add-space:auto;
text-align:justify;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><u><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><o:p><span
 style='text-decoration:none'>&nbsp;</span></o:p></span></u></p>

<p class=MsoNormal style='margin-left:61.2pt;text-align:justify;text-indent:
-25.2pt;mso-pagination:none;mso-list:l1 level3 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>7.2.3.</span></span><![endif]><span
dir=LTR></span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>In connection with the access to
or use of any Shutterstock Content, you shall not:<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:54.0pt;text-align:justify;border:none;
mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><u><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p><span style='text-decoration:none'>&nbsp;</span></o:p></span></u></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>interfere with or disrupt Shutterstock services or servers
or networks connected to Shutterstock services, or disobey any requirements,
procedures, policies or regulations of networks connected to Shutterstock
services; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>place, nor cause, enable or permit a third party to place,
advertisements or other materials that overlay, obscure or misattribute the Shutterstock
Content; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>use Shutterstock Content and/or services for any illegal
or unauthorized purpose; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>circumvent or modify any API-related keys or other
security mechanisms employed by Shutterstock or such API, including without
limitation any authentication technologies; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>e.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>enable any other person to personally identify any user of
the Site and Services except with the user's express consent; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>f.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>falsify, modify or delete any author attributions, legal
or other proper notices or proprietary designations or labels of the origin or
source of software or other material (including Shutterstock Content);<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>g.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>provide content with, in or in connection with the Site
and/or Services that falsely expresses or implies that such content is
sponsored or endorsed by Shutterstock; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>h.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>Upload, post, email or transmit or otherwise make
available with, in or in connection with the Site and/or Services, any content
that infringes any patent, trademark, copyright, trade secret or other
proprietary right of any party, unless you (or the user posting the content)
are the owner of the content or have the permission of the owner to post such
content; <o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:86.4pt;text-align:justify;text-indent:
-32.35pt;mso-pagination:none;mso-list:l1 level4 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>i.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>transmit any viruses, malware, worms, defects, Trojan
horses, or any items of a destructive nature.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:39.6pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><span style='mso-spacerun:yes'> 
</span><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none'><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>Intellectual Property</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><a name="_30j0zll"></a><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>8.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span class=SpellE><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>Peech</span></span><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'>
or its licensors, as the case may be, have all right, title and interest in the
Site, Services, and any Content thereon, including its overall appearance,
text, graphics, graphics design, videos, demos, interfaces, and underlying
source files, and all worldwide intellectual property rights, the trademarks,
service marks, and logos contained therein, whether registered or unregistered.
Except as expressly permitted herein, you may not copy, further develop,
reproduce, republish, modify, alter download, post, broadcast, transmit or
otherwise use the Content of the Site or Services for any purpose. You will not
remove, alter or conceal any copyright, trademark, service mark or other
proprietary rights notices incorporated in the Site and/or Services, if any. All
trademarks are trademarks or registered trademarks of their respective owners.
Nothing in these&nbsp;Terms or the Site should be construed as granting you any
right to use any trademark, service mark, logo, or trade name of <span
class=SpellE>Peech</span> or any third party. If you provide <span
class=SpellE>Peech</span> with any feedback (&quot;<b style='mso-bidi-font-weight:
normal'>Feedback</b>&quot;) regarding any Content on the Site and/or Services,
including with respect to any Edited Video, <span class=SpellE>Peech</span> may
use all such Feedback without restriction and shall not be subject to any
non-disclosure or non-use obligations in respect of such Feedback.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><a name="_1fob9te"></a><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>8.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>You have all right, title and interest in the User Content
you submit. By submitting or posting any User Content, you grant <span
class=SpellE>Peech</span> and its successors and assignees a worldwide,
non-exclusive, royalty-free, sub-licensable and transferable license under any
of your intellectual property, moral or privacy rights to use, copy, transmit,
modify, prepare derivative works of, alter, and/or decompile such User Content
on, through or in connection with the Site and/or Services, including to create
the Edited Videos and including for the improvement of the Site and/or Services
and the development of new services, all for internal use only, save for the
Edited Videos and subject to Section 15 herein.</span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'> <span style='color:black'><o:p></o:p></span></span></p>

<p class=MsoNormal style='margin-left:36.0pt;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><span lang=EN-US style='font-size:11.0pt;font-family:
"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><a name="_3znysh7"></a><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>8.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>Notwithstanding Section 8.1 and 8.2, and subject to the
terms hereof, including the payment in full of any fees due to <span
class=SpellE>Peech</span>, as applicable, <span class=SpellE>Peech</span>
assigns to you all rights, title and interest in and to any Edited Video(s),
created by <span class=SpellE>Peech</span> for you as a result of the Services;
provided however that where such assignment would preclude <span class=SpellE>Peech</span>
from providing its Services to any current or future client (the &quot;<b
style='mso-bidi-font-weight:normal'>Excluded</b> <b style='mso-bidi-font-weight:
normal'>Materials</b>), then <span class=SpellE>Peech</span> shall retain all
such intellectual property rights in the Excluded Materials and it shall
automatically grant back to you a non-exclusive, perpetual, irrevocable,
worldwide royalty-free license to use the Edited Video(s) worldwide and for any
purpose. Each Edited Video may be subject to additional third-party terms, and
you hereby agree that your use of any Edited Video(s) will comply with such
terms. <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><a name="_2et92p0"></a><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>Copyright</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>9.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>The policy of <span class=SpellE>Peech</span> is not to
infringe upon or violate the intellectual property rights or other rights of
any third party, and <span class=SpellE>Peech</span> will refuse to use and
remove any User Content in connection with the Site and/or Services that
infringes the rights of any third party. Under the Digital Millennium Copyright
Act of 1998 (the &quot;<b style='mso-bidi-font-weight:normal'>DMCA</b>&quot;), <span
class=SpellE>Peech</span> will remove any Content (including, without
limitation, any User Content) if properly notified that such material infringes
third party rights, and may do so at its sole discretion, without prior notice
to users at any time.&nbsp;The policy of <span class=SpellE>Peech</span> is to
terminate the accounts of repeat infringers in appropriate circumstances.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>9.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>You are in the best position to judge whether your User
Content is in violation of intellectual property or personal rights of any
third-party.&nbsp;You accept full responsibility for avoiding infringement of
the intellectual property or personal rights of others in connection with User
Content</span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:#333333;background:white;mso-highlight:
white'>.</span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><a name="_tyjcwt"></a><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>9.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>If you believe that something appearing on the Site and/or
Services infringes your copyright, you may send us a notice requesting that it
be removed, or that access to it blocked. If you believe that such a notice has
been wrongly filed against you, the&nbsp;DMCA&nbsp;allows you send us a
counter-notice. Notices and counter-notices must meet the DMCA’s requirements.
We suggest that you consult with your legal advisor before filing a notice or
counter-notice. Be aware that there can be substantial penalties for false claims.
Send notices and counter-notices to us by contacting us at [</span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'>info@peech-ai.com<span style='color:black'>].<o:p></o:p></span></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><a name="_3dy6vkm"></a><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>Disclaimers and Disclaimer of
Warranty</span></b><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><a name="_1t3h5sf"></a><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>10.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>All information and Content posted on the Site is for
informational purposes only and <span class=SpellE>Peech</span> provides no
guarantees with respect thereto. Your use of the Site and/or Services is at
your sole discretion and risk. The Site, Services, Content thereon, and/or any
Edited Video are provided on an AS IS and AS AVAILABLE basis without warranties
of any kind. We do not represent or warrant that Site, Services and/or Edited
Video will be of good quality or useful for your needs. <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>10.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS,
IMPLIED OR STATUTORY, RELATING TO THE SITE AND/OR SERVICES AND/OR ANY EDITED
VIDEO(S) OR ANY CONTENT THEREON, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF
TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF
PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE. WE DISCLAIM ANY
WARRANTIES, EXPRESS OR IMPLIED, (I) REGARDING THE SECURITY, ACCURACY,
RELIABILITY, TIMELINESS AND PERFORMANCE OF THE SITE AND/OR SERVICES AND/OR ANY
EDITED VIDEO(S); (II) THAT THE SITE, SERVICES OR ANY EDITED VIDEO(S)WILL BE
ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED; (III) REGARDING THE ACCURACY,
QUALITY, CURRENCY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION PROVIDED ON
THE SITE AND/OR SERVICES AND/OR ANY EDITED VIDEO(S). <o:p></o:p></span></p>

<p class=MsoNormal align=center style='text-align:center;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>10.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>No advice or information, whether oral or written,
obtained by you from us, shall create any warranty that is not expressly stated
in these Terms. If you choose to rely on such information, you do so solely at
your own risk. Some states or jurisdictions do not allow the exclusion of
certain warranties. Accordingly, some of the above exclusions may not apply to
you. Check your local laws for any restrictions or limitations regarding the
exclusion of implied warranties.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><a
name="_4d34og8"></a><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>10.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>You acknowledge and agree that <span class=SpellE>Peech</span>
is not a data retention service. You therefore must create backups of your
data, and <span class=SpellE>Peech</span> shall have no responsibility or
liability in respect of any loss of, damage to, or corruption of any such data.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;mso-pagination:
none;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:
yes'><b style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:
11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;
color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>Beta version</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>. Please note that the Site and Services are currently
being provided in their beta versions, the features of which have not been
fully implemented or refined. As with any beta version, the services currently
constitute a work in progress and as such, there may be unresolved issues. The
Site and Services have not been subject to full quality assurance procedures
and may contain defects. Unless you are comfortable using beta software and
understand the implications thereof, please do not to use this beta version of
the Site and/or Services.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>Limitation of Liability</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><b
style='mso-bidi-font-weight:normal'><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><a name="_2s8eyo1"></a><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>12.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>Without derogating from any of the foregoing, we assume no
responsibility for any error, omission, interruption, deletion, defect, delay
in operation or transmission, communications line failure, theft or destruction
or unauthorized access to, or alteration of, the Site or any Content or
Services, including Edited Videos. We are not responsible for any problems or
technical malfunction or failure of any telephone network or lines, computer
online systems or equipment, servers or providers, software, failure due to
technical problems or traffic congestion on the Internet or on the Site or
Services. Under no circumstances shall we be responsible for any loss or
damage, including personal injury or death and any injury or damage to any
person's property, including mobile device or computer, resulting from use of
the Site, Services, from any Content, including without limitation any Edited
Video(s), or from the conduct of any users of the Site or Services, whether
online or offline. In addition, we assume no responsibility for any incorrect
data, including Personal Data provided by you or on your behalf and you hereby
represent and warrant that you are solely responsible for any and all data
provided to <span class=SpellE>Peech</span>, including any incorrect data and
you shall assume any and all liability for any consequences of provision of
such incorrect data to us. <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:49.65pt;text-align:justify;text-indent:
-1.0cm;mso-pagination:none;mso-list:l1 level2 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><a name="_17dp8vu"></a><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>12.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span></span><![endif]><span dir=LTR></span><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>IN NO EVENT SHALL PEECH, OR ANY OF ITS OFFICERS,
DIRECTORS, EMPLOYEES, ASSIGNEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY
FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION INDIRECT, INCIDENTAL,
SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION
WITH YOUR USE OF THE SITE AND/OR SERVICES AND/OR ANY EDITED VIDEO(S), INCLUDING
BUT NOT LIMITED TO THE QUALITY, ACCURACY, OR UTILITY OF THE INFORMATION
PROVIDED AS PART OF OR THROUGH THE SITE AND/OR SERVICES, WHETHER THE DAMAGES
ARE FORESEEABLE AND WHETHER OR NOT PEECH HAS BEEN ADVISED OF THE POSSIBILITY OF
SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST
EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION AND IN NO EVENT SHALL
OUR CUMULATIVE LIABILITY TO YOU EXCEED THE AMOUNT YOU HAVE PAID US IN THE THREE
MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH THE APPLICABLE CLAIM OR CAUSE OF
ACTION AROSE, IF APPLICABLE. IF YOU HAVE NOT MADE ANY PAYMENTS TO PEECH FOR THE
USE OF THE SITE AND/OR SERVICES, THEN PEECH SHALL NOT HAVE ANY LIABILITY TOWARD
YOU.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>Indemnification. </span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>You agree to indemnify, defend, and hold harmless <span
class=SpellE>Peech</span> and its employees, directors, officers,
subcontractors and agents, against any and all claims, damages, or costs,
losses, liabilities or expenses (including reasonable court costs and
attorneys’ fees) that arise directly or indirectly from: (a) breach of
these&nbsp;Terms&nbsp;by you or anyone using your account and/or computer
and/or mobile device, password (whether authorized or unauthorized); (b) any
claim, loss or damage experienced from your use or attempted use of (or
inability to use) Site or Services; (c) your violation of any law or regulation
or any of your obligations, representations, or warranties hereunder including
but not limited to breach of any privacy and/or data protection laws and
regulations to which you are subject; (d) your infringement of any right of any
third party; and (e) any other matter for which you are responsible hereunder
or under applicable law. <o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><a name="_3rdcrjn"></a><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>Third-Party Content. </span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>The Site may provide you with third-party links
(including, without limitation, advertisements) to websites, applications, and
services. We make no promises regarding any content, goods or services provided
by such third parties and all use of third-party websites and applications is
at your own risk. Additionally, we do not accept responsibility for any
payments processed or submitted through third-party websites and applications
or for the privacy policies of such third parties. We do not endorse any
products offered by third parties and we urge our users to exercise caution in
using third-party websites or applications.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>15.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>Publicity</span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>. <span class=SpellE>Peech</span> may refer to you as a
customer of <span class=SpellE>Peech</span>, including by displaying your name
and logo on <span class=SpellE>Peech's</span> Site and/or other marketing
materials.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-left:36.0pt;border:none;mso-padding-alt:31.0pt 31.0pt 31.0pt 31.0pt;
mso-border-shadow:yes'><b style='mso-bidi-font-weight:normal'><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;mso-pagination:none;mso-list:l1 level1 lfo1;border:none;mso-padding-alt:
31.0pt 31.0pt 31.0pt 31.0pt;mso-border-shadow:yes'><![if !supportLists]><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><span style='mso-list:Ignore'>16.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></span><![endif]><span dir=LTR></span><b style='mso-bidi-font-weight:
normal'><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-fareast-font-family:Calibri;color:black'>Miscellaneous. </span></b><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'>These&nbsp;Terms&nbsp;shall be governed solely by the laws
of the State of Israel, and without regard to the United Nations Convention on
the International Sales of Goods and the competent courts in the State of
Israel shall have exclusive jurisdiction to hear any disputes arising
hereunder. <span class=GramE>In the event that</span> any provision of
these&nbsp;Terms&nbsp;is held to be unenforceable, such provision shall be
replaced with an enforceable provision which most closely achieves the effect
of the original provision, and the remaining terms of these Terms&nbsp;shall
remain in full force and effect. Nothing in these Terms creates any agency,
employment, joint venture, or partnership relationship between you and <span
class=SpellE>Peech</span> or enables you to act on behalf of <span
class=SpellE>Peech</span>. Except as may be expressly stated in
these&nbsp;Terms, these&nbsp;Terms&nbsp;constitute the entire agreement between
us and you pertaining to the subject matter hereof, and any and all other
agreements existing between us and you relating thereto are hereby canceled. We
may assign and/or transfer our rights and obligations hereunder to any third
party without prior notice. You shall not assign and/or transfer any of your
rights or obligations hereunder, and any assignment in violation of the
foregoing shall be void. No waiver of any breach or default hereunder shall be
deemed to be a waiver of any preceding or subsequent breach or default. If we
are required to provide notice to you hereunder, we may provide such notice to
the contact details you provided upon registration.<o:p></o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri;color:black'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='text-align:justify;mso-pagination:none'><i
style='mso-bidi-font-style:normal'><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-fareast-font-family:Calibri'>Last updated:
<span class=GramE>October,</span> 2021</span></i><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-fareast-font-family:
Calibri'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-pagination:none'><span lang=EN-US
style='font-size:11.0pt'><o:p>&nbsp;</o:p></span></p>

</div>

</body>

</html>
`;