
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'
import { Content, IContent, IContentProperties } from '../content';
import { Detection, IDetection, IDetectionProperties } from '../detection';

export enum SceneStatus {
	PENDING = 0,
	PROCESSING = 1,
	READY = 2,
	DELETED = 3,
	ERRONEOUS = 5,
};

export interface IScene extends IBase {
	status?: number | null;
	key?: string | null;
	thumbnailVersion?: number | null;
	assetSid?: string | null;
	linkSid?: string | null;
	thumbnail?: string | null;
	size?: number | null;
	contentVersion?: number | null;
	duration?: number | null;
	srcDuration?: number | null;
	configurationTime?: number | null;
	generatingTime?: number | null;
	aspectRatio?: string | null;
	name?: string | null;
	title?: string | null;
	cid?: string | null;
	index?: number | null;
	keyFramed?: boolean | null;
	formated?: boolean | null;
	transcripted?: boolean | null;
	highlighted?: boolean | null;
	detections?: Detection[] | null;
	faceDetected?: boolean | null;
	noiseReducted?: boolean | null;
	noiseReductedSuccessfully?: boolean | null;
	resized?: boolean | null;
	fitted?: boolean | null;
	verified?: boolean | null;
	masked?: boolean | null;
	resize?: Content | null;
	fit?: Content | null;
	mask?: Content | null;
	maskPreview?: Content | null;
	audio?: Content | null;
	offset?: number | null;
	externalVendor?: string | null;
	externalId?: string | null;
	mute?: boolean | null;
	disabled?: boolean | null;
	externalToken?: string | null;
}

export interface ISceneProperties extends IBaseProperties {
	status?: boolean;
	key?: boolean;
	thumbnailVersion?: boolean;
	assetSid?: boolean;
	linkSid?: boolean;
	thumbnail?: boolean;
	size?: boolean;
	contentVersion?: boolean;
	duration?: boolean;
	srcDuration?: boolean;
	configurationTime?: boolean;
	generatingTime?: boolean;
	aspectRatio?: boolean;
	name?: boolean;
	title?: boolean;
	cid?: boolean;
	index?: boolean;
	keyFramed?: boolean;
	formated?: boolean;
	transcripted?: boolean;
	highlighted?: boolean;
	detections?: IDetectionProperties;
	faceDetected?: boolean;
	noiseReducted?: boolean;
	noiseReductedSuccessfully?: boolean;
	resized?: boolean;
	fitted?: boolean;
	verified?: boolean;
	masked?: boolean;
	resize?: IContentProperties;
	fit?: IContentProperties;
	mask?: IContentProperties;
	maskPreview?: IContentProperties;
	audio?: IContentProperties;
	offset?: boolean;
	externalVendor?: boolean;
	externalId?: boolean;
	mute?: boolean;
	disabled?: boolean;
	externalToken?: boolean;
}

export interface ISceneFilter extends IBaseFilter {
}

export class Scene<IType extends IScene = IScene, ITypeProperties extends ISceneProperties = ISceneProperties> extends Base<IType, ITypeProperties> implements IScene {
	
	static className = 'Scene';
	static NAME = 'scene';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		key: true,
		thumbnailVersion: true,
		assetSid: true,
		linkSid: true,
		size: true,
		contentVersion: true,
		duration: true,
		srcDuration: true,
		configurationTime: true,
		generatingTime: true,
		aspectRatio: true,
		name: true,
		title: true,
		cid: true,
		index: true,
		keyFramed: true,
		formated: true,
		transcripted: true,
		highlighted: true,
		detections: Detection.PROPERTIES,
		faceDetected: true,
		noiseReducted: true,
		noiseReductedSuccessfully: true,
		resized: true,
		fitted: true,
		verified: true,
		masked: true,
		resize: Content.PROPERTIES,
		fit: Content.PROPERTIES,
		mask: Content.PROPERTIES,
		maskPreview: Content.PROPERTIES,
		audio: Content.PROPERTIES,
		offset: true,
		externalVendor: true,
		externalId: true,
		mute: true,
		disabled: true,
	};
	
	/**
	 * @deprecated use SceneStatus instead
	 */
	static STATUS: any = {
		PENDING: SceneStatus.PENDING,
		PROCESSING: SceneStatus.PROCESSING,
		READY: SceneStatus.READY,
		DELETED: SceneStatus.DELETED,
		ERRONEOUS: SceneStatus.ERRONEOUS,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		if(values.detections && values.detections.length) {
			values.detections = values.detections.map(item => Base.asType<IDetection>(item, Detection, this, 'detections') as Detection);
		}
		if(values.resize) {
			if(this.resize) {
				this.resize.set(values.resize, this, 'resize');
			}
			else {
				this.values.resize = Base.asType<IContent>(values.resize, Content, this, 'resize') as Content;
			}
			delete values.resize;
		}
		if(values.fit) {
			if(this.fit) {
				this.fit.set(values.fit, this, 'fit');
			}
			else {
				this.values.fit = Base.asType<IContent>(values.fit, Content, this, 'fit') as Content;
			}
			delete values.fit;
		}
		if(values.mask) {
			if(this.mask) {
				this.mask.set(values.mask, this, 'mask');
			}
			else {
				this.values.mask = Base.asType<IContent>(values.mask, Content, this, 'mask') as Content;
			}
			delete values.mask;
		}
		if(values.maskPreview) {
			if(this.maskPreview) {
				this.maskPreview.set(values.maskPreview, this, 'maskPreview');
			}
			else {
				this.values.maskPreview = Base.asType<IContent>(values.maskPreview, Content, this, 'maskPreview') as Content;
			}
			delete values.maskPreview;
		}
		if(values.audio) {
			if(this.audio) {
				this.audio.set(values.audio, this, 'audio');
			}
			else {
				this.values.audio = Base.asType<IContent>(values.audio, Content, this, 'audio') as Content;
			}
			delete values.audio;
		}
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get key(): string | null | undefined {
		return this.values.key
	}
			
	get thumbnailVersion(): number | null | undefined {
		return this.values.thumbnailVersion
	}
			
	get assetSid(): string | null | undefined {
		return this.values.assetSid
	}
			
	set assetSid(value: string | null | undefined) {
		if(this.values.assetSid !== value) {
			this.propertyChanged('assetSid', value);
			this.values.assetSid = value;
		}
	}
			
	get linkSid(): string | null | undefined {
		return this.values.linkSid
	}
			
	set thumbnail(value: string | null | undefined) {
		if(this.values.thumbnail !== value) {
			this.propertyChanged('thumbnail', value);
			this.values.thumbnail = value;
		}
	}
			
	get size(): number | null | undefined {
		return this.values.size
	}
			
	get contentVersion(): number | null | undefined {
		return this.values.contentVersion
	}
			
	get duration(): number | null | undefined {
		return this.values.duration
	}
			
	set duration(value: number | null | undefined) {
		if(this.values.duration !== value) {
			this.propertyChanged('duration', value);
			this.values.duration = value;
		}
	}
			
	get srcDuration(): number | null | undefined {
		return this.values.srcDuration
	}
			
	get configurationTime(): number | null | undefined {
		return this.values.configurationTime
	}
			
	get generatingTime(): number | null | undefined {
		return this.values.generatingTime
	}
			
	get aspectRatio(): string | null | undefined {
		return this.values.aspectRatio
	}
			
	set aspectRatio(value: string | null | undefined) {
		if(this.values.aspectRatio !== value) {
			this.propertyChanged('aspectRatio', value);
			this.values.aspectRatio = value;
		}
	}
			
	get name(): string | null | undefined {
		return this.values.name
	}
			
	set name(value: string | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	get title(): string | null | undefined {
		return this.values.title
	}
			
	set title(value: string | null | undefined) {
		if(this.values.title !== value) {
			this.propertyChanged('title', value);
			this.values.title = value;
		}
	}
			
	get cid(): string | null | undefined {
		return this.values.cid
	}
			
	set cid(value: string | null | undefined) {
		if(this.values.cid !== value) {
			this.propertyChanged('cid', value);
			this.values.cid = value;
		}
	}
			
	get index(): number | null | undefined {
		return this.values.index
	}
			
	set index(value: number | null | undefined) {
		if(this.values.index !== value) {
			this.propertyChanged('index', value);
			this.values.index = value;
		}
	}
			
	get keyFramed(): boolean | null | undefined {
		return this.values.keyFramed
	}
			
	get formated(): boolean | null | undefined {
		return this.values.formated
	}
			
	get transcripted(): boolean | null | undefined {
		return this.values.transcripted
	}
			
	get highlighted(): boolean | null | undefined {
		return this.values.highlighted
	}
			
	get detections(): Detection[] | null | undefined {
		return this.values.detections
	}
			
	get faceDetected(): boolean | null | undefined {
		return this.values.faceDetected
	}
			
	get noiseReducted(): boolean | null | undefined {
		return this.values.noiseReducted
	}
			
	get noiseReductedSuccessfully(): boolean | null | undefined {
		return this.values.noiseReductedSuccessfully
	}
			
	get resized(): boolean | null | undefined {
		return this.values.resized
	}
			
	get fitted(): boolean | null | undefined {
		return this.values.fitted
	}
			
	get verified(): boolean | null | undefined {
		return this.values.verified
	}
			
	get masked(): boolean | null | undefined {
		return this.values.masked
	}
			
	get resize(): Content | null | undefined {
		return this.values.resize
	}
			
	get fit(): Content | null | undefined {
		return this.values.fit
	}
			
	get mask(): Content | null | undefined {
		return this.values.mask
	}
			
	get maskPreview(): Content | null | undefined {
		return this.values.maskPreview
	}
			
	get audio(): Content | null | undefined {
		return this.values.audio
	}
			
	get offset(): number | null | undefined {
		return this.values.offset
	}
			
	set offset(value: number | null | undefined) {
		if(this.values.offset !== value) {
			this.propertyChanged('offset', value);
			this.values.offset = value;
		}
	}
			
	get externalVendor(): string | null | undefined {
		return this.values.externalVendor
	}
			
	set externalVendor(value: string | null | undefined) {
		if(this.values.externalVendor !== value) {
			this.propertyChanged('externalVendor', value);
			this.values.externalVendor = value;
		}
	}
			
	get externalId(): string | null | undefined {
		return this.values.externalId
	}
			
	set externalId(value: string | null | undefined) {
		if(this.values.externalId !== value) {
			this.propertyChanged('externalId', value);
			this.values.externalId = value;
		}
	}
			
	get mute(): boolean | null | undefined {
		return this.values.mute
	}
			
	set mute(value: boolean | null | undefined) {
		if(this.values.mute !== value) {
			this.propertyChanged('mute', value);
			this.values.mute = value;
		}
	}
			
	get disabled(): boolean | null | undefined {
		return this.values.disabled
	}
			
	set disabled(value: boolean | null | undefined) {
		if(this.values.disabled !== value) {
			this.propertyChanged('disabled', value);
			this.values.disabled = value;
		}
	}
			
	set externalToken(value: string | null | undefined) {
		if(this.values.externalToken !== value) {
			this.propertyChanged('externalToken', value);
			this.values.externalToken = value;
		}
	}
			
	
	static get<IType extends IScene = IScene>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends ISceneFilter = ISceneFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends ISceneFilter = ISceneFilter, IType extends IScene = IScene>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
