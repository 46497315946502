import { appConfig } from '../lib/app-context';
import User from '../client/user';
export const getBalance = async (): Promise<any> => {
    const API_v2 = appConfig.API_V2_URL!;
    const response = await fetch(API_v2, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${User.ps}`
        },
        body: JSON.stringify({
            query: `
                query getBalance {
                    getDownloadsBalance {    
                        periodMaxDownloads
                        periodLeftDownloads
                        periodStartTime
                        periodEndTime  
                    }
                }
            `,
        }),
    });

    if (response.ok) {
        const body = await response.json();
        return body.data.getDownloadsBalance;
    } else {
        throw new Error('Network response was not ok.');
    }
}

type DownloadStatus = 'done' | 'processing';

export const getLatestDownloadInfos = async (externalSequenceId: string, status: DownloadStatus = 'done'): Promise<any> => {
    const API_v2 = appConfig.API_V2_URL!;
    const response = await fetch(API_v2, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${User.ps}`
        },
        body: JSON.stringify({
            query: `
                query downloadInfos($externalSequenceId: String!, $status: DownloadStatus!){
                    downloadInfos(
                      filter: {externalSequenceId: {eq: $externalSequenceId}, status:{eq: $status}}
                    ){
                      nodes {
                          id
                          externalSequenceId
                          externalSequenceTitle
                      }
                  }
                }`,
            variables: {
                externalSequenceId,
                status
            },
        }),
    });

    if (response.ok) {
        const body = await response.json();
        return body.data?.downloadInfos?.nodes;
    } else {
        throw new Error('Network response was not ok.');
    }
}

