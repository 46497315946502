import React from 'react'
import { Helmet } from 'react-helmet'
import { animationAssets, sequenceState, setScenes } from '../state/sequence'

import { cuePointsState, cuePointsVersionState, resetCuePointOnTime, setCuePoint, setCuePointOnTime } from '../state/cuePoints'
import Renderer from '../components/renderer'

import Sequence from '../client/sequence'
import ClosedCaption from '../client/closedCaption'
import TitleCuePoint from '../client/titleCuePoint'
import SpeakerCuePoint from '../client/speakerCuePoint'
import GraphicCuePoint from '../client/graphicCuePoint'

import '../../css/overlay.css';

import PlayerStyle, { fontLoadedState } from '../components/player-style'
import { getLottieJsonUrl } from '../hooks/useAnimation';
import { appConfig } from '../lib/app-context';
import Asset from '../client/asset'

const CuePointTypes = {
    ClosedCaption,
    TitleCuePoint,
    SpeakerCuePoint,
    GraphicCuePoint,
};

async function fetchAnimation(assetSid, variant = '16:9') {
    const jsonURL = getLottieJsonUrl(appConfig.CONTENT_URL, assetSid, variant, Date.now())
    // console.log('overlay - start fetch json', JSON.stringify({jsonURL}));
	const response = await fetch(jsonURL);
    // console.log('overlay - end fetch json', JSON.stringify({ok: response.ok}));
	if (response.ok) {
		const json = await response.json();
        // console.log('overlay - json', JSON.stringify({json}));
		const animation = new Lottie.Animation(json);
		const animationId = `${assetSid}-${variant}`;
		animationAssets.set(animations => ({ ...animations, [animationId]: animation }))
	}
}

export default function Overlay() {
    const [time, setTime] = React.useState();
    // const [enterFirstFrame, setEnterFirstFrame] = React.useState(false);
    const sequence = sequenceState.use();
    const fontLoaded = fontLoadedState.use();
    const hasCuePoints = cuePointsState.use(cuePoints => Object.keys(cuePoints).length)
    const initCuePoint = cuePoint => {
      
        if (!cuePoint.objectType) {
            throw new Error('Missing object type ' + JSON.stringify(cuePoint))
        }
        if (!CuePointTypes[cuePoint.objectType]) {
            throw new Error('Missing constructor for type ' + cuePoint.objectType)
        }
        const cp = new CuePointTypes[cuePoint.objectType]().set(cuePoint);
        cp.fullSize = true;
        if (cuePoint.titleType === TitleCuePoint.TITLE_TYPE.VIDEO && cuePoint.thumb) {
            cp.thumb = cuePoint.thumb
        }

        const cuePointClass = setCuePoint(cp, window.sequence, window.scenes, false, true)
        const activeTime = cuePointClass.activeTimes.find(t => t.active)
        setCuePointOnTime(cuePoint, cuePoint.type === ClosedCaption.TYPE.CLOSED_CAPTION ? activeTime?.sceneSid : true);
    }

    async function onReaderLoad(event) {
        let obj = JSON.parse(event.target.result);
        if (obj.jsonPayload) {
            obj = obj.jsonPayload;
        }
        let { sequence, cuePoint, actualTime, drawType, durationOfSequence: duration, width, height, start, end } = obj;
        if (!actualTime) {
            if (!start || !end) {
                alert('actualTime not defined in log');
            }
            actualTime = (start + end) / 2
        }
        if (duration) {
            sequence.duration = duration;
        }
        if (width) {
            sequence.width = width;
        }
        if (height) {
            sequence.height = height;
        }

        window.actualTime = actualTime;
        window.drawType = drawType;
        window.cuePoint = cuePoint;

        if (drawType === 'drawTime') {
            await init(sequence, cuePoint);
            // if (window.drawTime instanceof Function) {
            //     await drawTime(actualTime);
            // }
        }
        else if (drawType === 'draw') {
            await init(sequence);
            // if(window.draw instanceof Function) {
            //     await draw(actualTime, [cuePoint]);
            // }
        }
    }


    // const onLottieEnterFrame = React.useCallback(() => {
    //     // window.drawState = {
    //     //   time: window.___time,
    //     // };
    //     console.log('overlayEnterFrame', window.drawState);
    //     setEnterFirstFrame(true);
    // }, []);

    // React.useEffect(() => {
    //     console.log('font loaded and enter first frame',{fontLoaded, enterFirstFrame});
    //     if(fontLoaded && enterFirstFrame) {
            
    //         if (window.actualTime && window.drawTime) {
    //             window.drawTime(window.actualTime);
    //         }
    //     }
    // },[fontLoaded, enterFirstFrame]);


    // React.useEffect(() => {
    //     if (document) {
    //       document.addEventListener('overlayEnterFrame', onLottieEnterFrame);
    //     }
    //     return () => {
    //       document.removeEventListener('overlayEnterFrame', onLottieEnterFrame);
    //     };
    //   }, [onLottieEnterFrame]);

    function onFileChange(event) {
        let reader = new FileReader();
        reader.onload = onReaderLoad;
        reader.readAsText(event.target.files[0]);
    }

    async function setSequence(sequence, cuePoint) {
        window.sequence = new Sequence().set(sequence);
        if(!window.sequence.duration) {
            window.sequence.duration =  7200 // 2 hours
        }
        window.scenes = setScenes(window.sequence)
        sequenceState.set(window.sequence)

        if(cuePoint && cuePoint.type !== ClosedCaption.TYPE.CLOSED_CAPTION) {
            const { 
                    textAssetSid, 
                    slideAssetSid, 
                    speakerAssetSid, 
                    frameAssetSid, 
                    outroAssetSid,
                    introAssetSid
                } = window.sequence.style;
        const { aspectRatio } = window.sequence;
        const promiseArray = [];
        if (cuePoint.assetSid) {
            promiseArray.push(fetchAnimation(cuePoint.assetSid, aspectRatio));
        }
        textAssetSid &&  promiseArray.push(fetchAnimation(textAssetSid, aspectRatio));
        slideAssetSid &&  promiseArray.push(fetchAnimation(slideAssetSid, aspectRatio));
        speakerAssetSid &&  promiseArray.push(fetchAnimation(speakerAssetSid, aspectRatio));
        frameAssetSid &&  promiseArray.push(fetchAnimation(frameAssetSid, aspectRatio));
        outroAssetSid &&  promiseArray.push(fetchAnimation(outroAssetSid, aspectRatio));
        introAssetSid &&  promiseArray.push(fetchAnimation(introAssetSid, aspectRatio));
        // await Promise.all(
        //     promiseArray
        // )
        }

        if (cuePoint) {
            window.cuePointSid = cuePoint.sid;
            initCuePoint(cuePoint);
            cuePointsVersionState.set(version => version + 1)
        }
    }

    function parseQuery(queryString) {
        var query = {};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    React.useEffect(async () => {
        window.init = (sequence, cuePoint) => setSequence(sequence, cuePoint);

        window.onclick = () => {
            const input = document.createElement('input')
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'application/json');
            input.addEventListener('change', onFileChange, { once: true });
            input.click();
        }

        if (document.location.search.length) {
            const {
                sequenceSid,
                time,
                graphicCuePointSid,
                titleCuePointSid,
                speakerCuePointSid,
            } = parseQuery(`${document, location.search}`);

            if (sequenceSid) {
                const sequence = await Sequence.get(sequenceSid);
                let cuePoint, cuePoints;
                if (graphicCuePointSid) {
                    cuePoints = await GraphicCuePoint.list({ sequenceSidEqual: sequenceSid, sidEqual: graphicCuePointSid });
                }
                if (titleCuePointSid) {
                    
                    cuePoints = await TitleCuePoint.list({ sequenceSidEqual: sequenceSid, sidEqual: titleCuePointSid });
                }
                if (speakerCuePointSid) {
                    cuePoints = await SpeakerCuePoint.list({ sequenceSidEqual: sequenceSid, sidEqual: speakerCuePointSid });
                }
                if (cuePoints?.length) {
                    cuePoint = cuePoints.shift()
                }
                await setSequence(sequence.toJSON(), cuePoint?.toJSON());
                if (time) {
                   
                    if (cuePoint) {
                        window.drawTime(time)
                    }
                    else {
                        
                        cuePoints = [
                            ... await GraphicCuePoint.list({ sequenceSidEqual: sequenceSid }),
                            ... await TitleCuePoint.list({ sequenceSidEqual: sequenceSid }),
                            // ... await SpeakerCuePoint.list({ sequenceSidEqual: sequenceSid, sidEqual: speakerCuePointSid }),
                        ]
                            .map(cp => cp.toJSON())
                        window.draw(time, cuePoints)
                    }
                }
            }
        }
    }, []);


    React.useEffect(async () => {

        // if(!fontLoaded) {
        //     console.log('font not loaded');
        //     return;
        // }

        console.log('font loaded added draw function');

        window.draw = (time, cuePoints) => {
            window.drawState = {
                time,
                cuePoints: cuePoints.reduce((map, c) => ({ ...map, [c.sid]: true }), {}),
                effects: cuePoints.length
            }
            resetCuePointOnTime()
            cuePoints.forEach(initCuePoint)
            cuePointsVersionState.set(version => version + 1)
            setTime(time);
        }

        window.drawTime = (time, src) => {
            cuePointsState.set(cuePoints => {
                if (cuePoints[window.cuePointSid]) {
                    cuePoints[window.cuePointSid].thumb = src;
                }
                return cuePoints
            })
            window.drawState = {
                time,
                cuePoints: { [window.cuePointSid]: true },
                effects: 1
            }
            setTime(time);
        };

        if (window.drawType === 'drawTime') {
            await window.drawTime(window.actualTime);
        } else if (window.drawType === 'draw') {
            await window.draw(window.actualTime, [window.cuePoint]);
        }

    }, [fontLoaded]);
    
    return (
        <div className="overlay wrap">
            <Helmet>
                {sequence?.style?.fontFamily && <link
                    rel="stylesheet"
                    href={`https://fonts.googleapis.com/css?display=swap&family=${sequence.style.fontFamily}:i,b,400,600,400i,600i`}
                />}
                <style>{`
                    body {
                        background-color: unset;
                    }
                `}</style>
            </Helmet>
            <PlayerStyle />
            {hasCuePoints && <Renderer time={time} style={{
                width: sequence && sequence.width,
                height: sequence && sequence.height,
            }} />}
        </div>
    )
}
