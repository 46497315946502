import Color from 'color';
import { get, isNil } from 'lodash';

import { StyleCaptionsCaseType, StyleStyleType, StyleVerticalAlign } from '../client/base/sequence/style';
import ClosedCaption, { Word } from '../client/closedCaption';
import { IColor } from '../client/color';
import CuePoint, { ICuePoint, ICuePointProperties } from '../client/cuePoint';
import Sequence, { Style } from '../client/sequence';
import { cuePointsState, removeCuePoint, setClosedCaption } from '../state/cuePoints';
import { scenesState } from '../state/sequence';
import { AspectRatio } from '../ui-components/SequenceCloneOptions/useSequenceCloneOptions';
import {
  advancedSvg,
  oneLineSvg,
  singleWordSvg,
  twoLineSvg,
} from '../components/menu/subtitles/components/captionFormatsSvg';

const DEFAULT_POSITION = {
  [`9:16-${Style.VERTICAL_ALIGN.TOP}`]: 10,
  [`9:16-${Style.VERTICAL_ALIGN.MIDDLE}`]: 50,
  [`9:16-${Style.VERTICAL_ALIGN.BOTTOM}`]: 86,
  [`1:1-${Style.VERTICAL_ALIGN.TOP}`]: 10,
  [`1:1-${Style.VERTICAL_ALIGN.MIDDLE}`]: 50,
  [`1:1-${Style.VERTICAL_ALIGN.BOTTOM}`]: 84,
  [`16:9-${Style.VERTICAL_ALIGN.TOP}`]: 10,
  [`16:9-${Style.VERTICAL_ALIGN.MIDDLE}`]: 50,
  [`16:9-${Style.VERTICAL_ALIGN.BOTTOM}`]: 84,
};

const DEFAULT_COLORS = {
  FONT: '#FFFFFF',
  STYLE: '#000000',
};

export const DEFAULT_FONT_SIZE = 4.8;

const getColor = (
  brandColorIndex: number | null | undefined,
  colorString: string | null | undefined,
  brandColors: IColor[]
): string | null => {
  let retColor: string | null = null;

  if (!isNil(brandColorIndex)) {
    const brandColor = brandColors?.find((c) => c.dominancy === brandColorIndex)?.color;

    if (brandColor) {
      retColor = brandColor;
    }
  } else if (colorString) {
    retColor = Color(colorString).hex();
  }

  return retColor;
};

export const getCloseCaptionDefaultPosition = (ratio: string, verticalAlign: StyleVerticalAlign): number => {
  const position = DEFAULT_POSITION[`${ratio}-${verticalAlign}`];
  return position || 84;
};

export const getCloseCaptionVerticalAlignmentByPosition = (position: number): number => {
  if (Number(position) <= 34) position = StyleVerticalAlign.TOP;
  if (Number(position) > 34 && Number(position) < 66) position = StyleVerticalAlign.MIDDLE;
  if (Number(position) >= 66) position = StyleVerticalAlign.BOTTOM;
  return position;
};

export const getCloseCaptionFontColor = (
  captionsFontColorIndex: number | null,
  captionsFontColor: string,
  brandColors: IColor[]
): string => {
  // font color
  const fontColor = getColor(captionsFontColorIndex, captionsFontColor, brandColors);
  return fontColor || DEFAULT_COLORS.FONT;
};

export const getCloseCaptionStyleColor = (
  captionsStyleColorIndex: number | null | undefined,
  captionsStyleColor: string | null | undefined,
  brandColors: IColor[]
): string => {
  const styleColor = getColor(captionsStyleColorIndex, captionsStyleColor, brandColors);
  return styleColor || DEFAULT_COLORS.STYLE;
};

const ALIGNMENT_DEFAULTS: Record<AspectRatio, StyleVerticalAlign> = {
  ['16:9']: StyleVerticalAlign.BOTTOM,
  ['9:16']: StyleVerticalAlign.TOP,
  ['1:1']: StyleVerticalAlign.BOTTOM,
};

const STYLE_TYPE_DEFAULTS: Record<AspectRatio, StyleStyleType> = {
  ['16:9']: StyleStyleType.TRANSPARENT_BOX,
  ['9:16']: StyleStyleType.STROKE,
  ['1:1']: StyleStyleType.STROKE,
};

export const getDefaultCaptionsStyle = (sequence: Sequence) => {
  const ratio = sequence.aspectRatio as AspectRatio;

  return {
    captionsVerticalAlign: ALIGNMENT_DEFAULTS[ratio],
    captionsPosition: getCloseCaptionDefaultPosition(ratio, ALIGNMENT_DEFAULTS[ratio]),
    captionsFontColor: DEFAULT_COLORS.FONT,
    captionsStyleType: STYLE_TYPE_DEFAULTS[ratio],
    captionsStyleColor: DEFAULT_COLORS.STYLE,
  };
};

export const getStyleObject = (
  captionsStyleType: StyleStyleType | null | undefined,
  styleColor: string,
  opacity: number,
  playerHeight: number,
  sequence: Sequence
): Object => {
  const fontSize = getCloseCaptionFontSize(playerHeight, sequence);
  const padding = getCloseCaptionPadding(fontSize);

  switch (captionsStyleType) {
    case StyleStyleType.STROKE:
      const textStrokeWidth = getCloseCaptionStrokeWidth(fontSize);
      const textStroke = generateOutline(textStrokeWidth, styleColor, 'bottom-right', 0, 0);
      return {
        backgroundColor: 'transparent',
        textShadow: textStroke,
        lineHeight: '110%',
      };

    case StyleStyleType.SHADOW:
      let strokeSize = getCloseCaptionStrokeWidth(fontSize) * 0.3;
      let textShadowWidth = getCloseCaptionStrokeWidth(fontSize) * 0.9;
      const stepSize = 0.1;
      const stepNumber = textShadowWidth / stepSize;
      let textShadow = generateOutline(strokeSize, styleColor, 'bottom-right', stepSize, stepNumber);
      return {
        backgroundColor: 'transparent',
        textShadow: textShadow,
        lineHeight: '110%',
      };

    case StyleStyleType.SHADOW_STROKE:
      strokeSize = getCloseCaptionStrokeWidth(fontSize) * 1.2;
      textShadowWidth = getCloseCaptionStrokeWidth(fontSize) * 2;
      const textStrokeShadow = generateOutline(strokeSize, styleColor, 'bottom-right', 0, 0);
      textShadow =
        generateOutlineByDegree(textShadowWidth, styleColor, 320, 360) +
        ', ' +
        generateOutlineByDegree(textShadowWidth * 1.3, styleColor, 345, 115);
      return {
        backgroundColor: 'transparent',
        textShadow: textStrokeShadow + ', ' + textShadow,
        lineHeight: '110%',
        letterSpacing: '1.5px',
      };

    case StyleStyleType.ROUND_BOX:
      return {
        backgroundColor: styleColor,
        filter: `url('#instagram')`,
        boxDecorationBreak: 'clone',
        WebkitBoxDecorationBreak: 'clone',
        display: 'inline',
        padding: `0px ${padding}px`,
        lineHeight: `130%`,
      };

    case StyleStyleType.BOX:
      return {
        backgroundColor: styleColor,
        boxDecorationBreak: 'clone',
        WebkitBoxDecorationBreak: 'clone',
        display: 'inline',
        padding: `0px ${padding}px`,
        lineHeight: `120%`,
      };

    case StyleStyleType.TRANSPARENT:
      return {
        backgroundColor: 'transparent',
        lineHeight: '100%',
      };

    case StyleStyleType.FULL_BOX:
      return {
        backgroundColor: 'transparent',
        padding: `0px ${padding}px`,
        lineHeight: '110%',
        '--cc-calculated-padding-x': `3000px`,
        '--cc-calculated-padding-y': `${padding * 0.3 * 2}px`,
        '--cc-calculated-bg': Color(styleColor).alpha(0.5).rgb().string(),
      };

    case StyleStyleType.TRANSPARENT_BOX:
    default:
      const colorRgba = Color(styleColor).alpha(opacity).rgb().string();

      return {
        backgroundColor: 'transparent',
        display: 'inline',
        padding: 0,
        lineHeight: '100%',
        '--cc-calculated-padding-x': `${padding * 2}px`,
        '--cc-calculated-padding-y': `${padding * 0.3 * 2}px`,
        '--cc-calculated-bg': colorRgba,
      };
  }
};

export const getCloseCaptionFontSize = (height: number, sequence: Sequence) => {
  const fontSize = sequence?.style?.captionsFontSize ? sequence?.style?.captionsFontSize / 10 : DEFAULT_FONT_SIZE;
  return (height * fontSize) / 100;
};
export const getCloseCaptionStrokeWidth = (fontSize: number) => fontSize * 0.1;
export const getCloseCaptionPadding = (fontSize: number) => fontSize * 0.4;

export const generateOutline = (
  strokeSize: number,
  strokeColor: string,
  shadowDirection: 'bottom-right' | 'bottom-left',
  shadowThickness: number,
  shadowSteps: number
): string => {
  let textShadow = '';
  for (var angle = 0; angle < 2 * Math.PI; angle += 1 / strokeSize) {
    const x = Math.cos(angle) * strokeSize;
    const y = Math.sin(angle) * strokeSize;
    if (textShadow !== '') {
      textShadow += ', ';
    }
    textShadow += `${strokeColor} ${x}px ${y}px `;
  }

  // Add multiple drop shadows
  const shadowOffsetY = shadowThickness;
  let shadowOffsetX = shadowDirection === 'bottom-right' ? shadowThickness : -shadowThickness;
  for (let i = 1; i <= shadowSteps; i++) {
    textShadow += `, ${strokeColor} ${i * shadowOffsetX}px ${i * shadowOffsetY}px`;
  }

  return textShadow;
};

export const generateOutlineByDegree = (
  strokeSize: number,
  strokeColor: string,
  startDegree: number, // Starting angle in degrees
  endDegree: number // Ending angle in degrees
): string => {
  let textShadow = '';

  // Convert degrees to radians
  let startAngle = degreesToRadians(startDegree);
  let endAngle = degreesToRadians(endDegree);

  // Ensure angles are normalized between 0 and 2*PI
  startAngle = normalizeAngle(startAngle);
  endAngle = normalizeAngle(endAngle);

  const increment = 1 / strokeSize;
  for (let angle = 0; angle < 2 * Math.PI; angle += increment) {
    if (angleInRange(angle, startAngle, endAngle)) {
      const x = Math.cos(angle) * strokeSize;
      const y = Math.sin(angle) * strokeSize;
      textShadow += `${textShadow ? ', ' : ''}${strokeColor} ${x}px ${y}px `;
    }
  }

  return textShadow;
};

function degreesToRadians(degrees: number) {
  return degrees * (Math.PI / 180);
}

function normalizeAngle(angle: number) {
  return angle % (2 * Math.PI);
}

function angleInRange(angle: number, startAngle: number, endAngle: number) {
  if (endAngle > startAngle) {
    return angle >= startAngle && angle <= endAngle;
  } else {
    // Handle wrap-around case
    return angle >= startAngle || angle <= endAngle;
  }
}

export const getCloseCaptionStyleFromSequence = (sequence: Sequence, height: number): Object => {
  if (sequence.style) {
    const { colors, captionsBackgroundOpacity } = sequence;
    const { captionsStyleType, captionsStyleColorIndex, captionsStyleColor } = sequence.style;
    const styleColor = getCloseCaptionStyleColor(captionsStyleColorIndex, captionsStyleColor, colors || []);
    const opacity = captionsBackgroundOpacity || 0.7;
    const styleCss = getStyleObject(captionsStyleType, styleColor, opacity, height, sequence);
    return styleCss;
  }

  return {};
};

export const getCloseCaptionPosition = (sequence: Sequence): number => {
  if (sequence.style) {
    const { sequenceAspectRatio } = sequence;
    const { captionsVerticalAlign, captionsPosition } = sequence.style;
    return captionsPosition || captionsPosition === 0
      ? captionsPosition
      : getCloseCaptionDefaultPosition(sequenceAspectRatio, captionsVerticalAlign || StyleVerticalAlign.BOTTOM);
  }

  return 0;
};

export const getCloseCaptionVerticalAlign = (sequence: Sequence): number => {
  if (sequence.style) {
    const { captionsVerticalAlign } = sequence.style;
    return captionsVerticalAlign || StyleVerticalAlign.BOTTOM;
  }
  return StyleVerticalAlign.BOTTOM;
};

export const getCloseCaptionTransform = (verticalAlign: StyleVerticalAlign): string => {
  switch (verticalAlign) {
    case StyleVerticalAlign.TOP:
      return 'translate(-50%)';
    case StyleVerticalAlign.MIDDLE:
      return 'translate(-50%, -50%)';
    default:
      return 'translate(-50%, -100%)';
  }
};

export const getCloseCaptionsCaseType = (captionsCaseType: StyleCaptionsCaseType): string => {
  switch (captionsCaseType) {
    case StyleCaptionsCaseType.UPPERCASE:
      return 'uppercase';
    case StyleCaptionsCaseType.LOWERCASE:
      return 'lowercase';
    default:
      return 'none';
  }
};

//=============================================================================

export const checkWordPunctuationMark = (wordValue: string): boolean => {
  const punctuationMarks = /[.,?!:;]$/;
  return punctuationMarks.test(wordValue);
};

export enum CaptionsBreakType {
  Words = 'words',
  Characters = 'characters',
  Time = 'time',
}

const formatByWords = (words: Word[], value: number, shouldBreakByPunctuationMark: boolean) => {
  let counter = 0;

  return words.map((word) => {
    word.newLine = false;
    counter += 1;

    if ((shouldBreakByPunctuationMark && checkWordPunctuationMark(word.word as string)) || counter === value) {
      word.newLine = true;
      counter = 0;
    }

    return word;
  });
};

const formatByCharacters = (words: Word[], value: number, shouldBreakByPunctuationMark: boolean) => {
  let counter = 0;

  return words.map((word, index) => {
    word.newLine = false;
    const wordLength = word?.word?.length ?? 0;
    const nextWordLength = (words[index + 1]?.word?.length ?? 0) + 1;

    counter += wordLength + 1;

    if (
      counter >= value ||
      counter + nextWordLength > value ||
      (shouldBreakByPunctuationMark && checkWordPunctuationMark(word.word as string))
    ) {
      word.newLine = true;
      counter = 0;
    }

    return word;
  });
};

const formatByTime = (words: Word[], value: number, shouldBreakByPunctuationMark: boolean) => {
  let counter = 0;

  return words.map((word, index) => {
    word.newLine = false;
    const wordDuration = (word?.endTime ?? 0) - (word?.startTime ?? 0);
    const nextWordDuration = (words[index + 1]?.endTime ?? 0) - (words[index + 1]?.startTime ?? 0);

    counter += wordDuration;

    if (
      counter >= value ||
      counter + nextWordDuration > value ||
      (shouldBreakByPunctuationMark && checkWordPunctuationMark(word.word as string))
    ) {
      word.newLine = true;
      counter = 0;
    }
    return word;
  });
};

export type SubtitleFormatPreset = {
  breakType: CaptionsBreakType;
  value?: number;
  values?: Record<CaptionsBreakType.Words | CaptionsBreakType.Time, { min: number; max: number; default: number }>;
  shouldBreakByPunctuationMark: boolean;
  name: SubtitleFormatName;
  icon: JSX.Element;
};

export const formatWordsBySubtitleFormat = ({
  words,
  formatPresetData,
}: {
  words: Word[];
  formatPresetData: SubtitleFormatPreset;
}) => {
  const { breakType, value, shouldBreakByPunctuationMark } = formatPresetData;
  if (!words?.length) {
    return [];
  }

  switch (breakType) {
    case CaptionsBreakType.Words:
      return formatByWords(words, value as number, shouldBreakByPunctuationMark);
    case CaptionsBreakType.Characters:
      return formatByCharacters(words, value as number, shouldBreakByPunctuationMark);
    case CaptionsBreakType.Time:
      return formatByTime(words, value as number, shouldBreakByPunctuationMark);
    default:
      return words;
  }
};

export enum SubtitleFormatName {
  OneLine = 'one line',
  TwoLines = 'two lines',
  SingleWord = 'single word',
  Advanced = 'advanced',
}

export type CaptionsFormatType = {
  formatName: SubtitleFormatName;
  formatPreset?: SubtitleFormatPreset;
};

export const SUBTITLE_FORMAT_PRESETS: Record<AspectRatio, Record<SubtitleFormatName, SubtitleFormatPreset>> = {
  ['16:9']: {
    [SubtitleFormatName.OneLine]: {
      breakType: CaptionsBreakType.Characters,
      value: 50,
      shouldBreakByPunctuationMark: true,
      name: SubtitleFormatName.OneLine,
      icon: oneLineSvg,
    },
    [SubtitleFormatName.TwoLines]: {
      breakType: CaptionsBreakType.Characters,
      value: 90,
      shouldBreakByPunctuationMark: true,
      name: SubtitleFormatName.TwoLines,
      icon: twoLineSvg,
    },
    [SubtitleFormatName.SingleWord]: {
      breakType: CaptionsBreakType.Words,
      value: 1,
      shouldBreakByPunctuationMark: true,
      name: SubtitleFormatName.SingleWord,
      icon: singleWordSvg,
    },
    [SubtitleFormatName.Advanced]: {
      breakType: CaptionsBreakType.Words,
      values: {
        [CaptionsBreakType.Time]: {
          min: 1000,
          max: 20000,
          default: 5000,
        },
        [CaptionsBreakType.Words]: {
          min: 1,
          max: 100,
          default: 6,
        },
      },
      shouldBreakByPunctuationMark: true,
      name: SubtitleFormatName.Advanced,
      icon: advancedSvg,
    },
  },
  ['9:16']: {
    [SubtitleFormatName.OneLine]: {
      breakType: CaptionsBreakType.Characters,
      value: 16,
      shouldBreakByPunctuationMark: true,
      name: SubtitleFormatName.OneLine,
      icon: oneLineSvg,
    },
    [SubtitleFormatName.TwoLines]: {
      breakType: CaptionsBreakType.Characters,
      value: 32,
      shouldBreakByPunctuationMark: true,
      name: SubtitleFormatName.TwoLines,
      icon: twoLineSvg,
    },
    [SubtitleFormatName.SingleWord]: {
      breakType: CaptionsBreakType.Words,
      value: 1,
      shouldBreakByPunctuationMark: true,
      name: SubtitleFormatName.SingleWord,
      icon: singleWordSvg,
    },
    [SubtitleFormatName.Advanced]: {
      breakType: CaptionsBreakType.Words,
      values: {
        [CaptionsBreakType.Time]: {
          min: 1000,
          max: 20000,
          default: 5000,
        },
        [CaptionsBreakType.Words]: {
          min: 1,
          max: 100,
          default: 4,
        },
      },
      shouldBreakByPunctuationMark: true,
      name: SubtitleFormatName.Advanced,
      icon: advancedSvg,
    },
  },
  ['1:1']: {
    [SubtitleFormatName.OneLine]: {
      breakType: CaptionsBreakType.Characters,
      value: 26,
      shouldBreakByPunctuationMark: true,
      name: SubtitleFormatName.OneLine,
      icon: oneLineSvg,
    },
    [SubtitleFormatName.TwoLines]: {
      breakType: CaptionsBreakType.Characters,
      value: 48,
      shouldBreakByPunctuationMark: true,
      name: SubtitleFormatName.TwoLines,
      icon: twoLineSvg,
    },
    [SubtitleFormatName.SingleWord]: {
      breakType: CaptionsBreakType.Words,
      value: 1,
      shouldBreakByPunctuationMark: true,
      name: SubtitleFormatName.SingleWord,
      icon: singleWordSvg,
    },
    [SubtitleFormatName.Advanced]: {
      breakType: CaptionsBreakType.Words,
      values: {
        [CaptionsBreakType.Time]: {
          min: 1000,
          max: 20000,
          default: 5000,
        },
        [CaptionsBreakType.Words]: {
          min: 1,
          max: 100,
          default: 4,
        },
      },
      shouldBreakByPunctuationMark: true,
      name: SubtitleFormatName.Advanced,
      icon: advancedSvg,
    },
  },
};

export const getSubtitleFormatPreset = (
  ratio: AspectRatio,
  name: SubtitleFormatName
): SubtitleFormatPreset | undefined => {
  return SUBTITLE_FORMAT_PRESETS[ratio][name];
};

export const getSubtitleFormatPresets = (
  ratio: AspectRatio
): Partial<Record<SubtitleFormatName, SubtitleFormatPreset>> => {
  return SUBTITLE_FORMAT_PRESETS[ratio];
};

export const getSubtitleFormatPresetName = (ratio: AspectRatio, formatPresetData: SubtitleFormatPreset): string => {
  const presetNames = Object.keys(SUBTITLE_FORMAT_PRESETS[ratio]);
  const presetName = presetNames.find((name) => {
    const preset = SUBTITLE_FORMAT_PRESETS[ratio as AspectRatio][name as SubtitleFormatName];
    return preset?.breakType === formatPresetData.breakType && preset.value === formatPresetData.value;
  });

  return presetName || 'advanced';
};

export const divideArray = (array: any[], chunkSize: number) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

export const getWordsArray = (cuePointsData: Map<string, CuePoint<ICuePoint, ICuePointProperties>>) => {
  const closedCaptions = Object.values(cuePointsData).filter(
    (cuePoint) => cuePoint && cuePoint instanceof ClosedCaption
  );
  const wordsList: Word[] = [];
  closedCaptions.map((closeCaption) => closeCaption.words.forEach((word: Word) => wordsList.push(word)));
  return wordsList;
};

export const removeAllClosedCaptions = (cuePointsData: Map<string, CuePoint<ICuePoint, ICuePointProperties>>) => {
  const closedCaptions = Object.values(cuePointsData).filter(
    (cuePoint) => cuePoint && cuePoint instanceof ClosedCaption
  );

  closedCaptions.forEach((closeCaption) => {
    removeCuePoint(closeCaption.sid);
  });
};

export const saveClosedCaptionsChanges = (
  sequence: Sequence,
  captionFormat: SubtitleFormatName,
  cacheVersion?: number | null,
  formatPresetData?: SubtitleFormatPreset
) => {
  const cuePointsData = cuePointsState.get();

  const formatPresetDataToSave = formatPresetData
    ? formatPresetData
    : getSubtitleFormatPreset(sequence.aspectRatio as AspectRatio, captionFormat);

  const wordsArray = getWordsArray(cuePointsData);
  const formattedWords = formatWordsBySubtitleFormat({words: wordsArray, formatPresetData: formatPresetDataToSave as SubtitleFormatPreset}); // prettier-ignore

  const chunksArray = divideArray(formattedWords, 200);
  async function updateChunks() {
    for (const chunk of chunksArray) {
      chunk.map((word) => {
        word.changedProperties = [
          ...word.changedProperties,
          'chapterSid',
          'flags',
          'score',
          'excluded',
          'word',
          'startTime',
          'endTime',
          'newLine',
          'highlight',
          'origin',
        ];
      });

      await ClosedCaption.updateWords(
        sequence.sid as string,
        chunk.map((w) => w)
      );
    }
  }

  updateChunks();

  const scenes = scenesState.get().map;
  removeAllClosedCaptions(cuePointsData);

  setClosedCaption(formattedWords, sequence, scenes, true);
};

export const deleteActiveWords = async (activeWords: Word[], sequenceSid: string) => {
  // delete all active words before the new time
  activeWords.forEach((word, index) => {
    word.origin = word.word;
    word.word = '';
    word.changedProperties = [
      ...word.changedProperties,
      'startTime',
      'endTime',
      'chapterSid',
      'score',
      'word',
      'flag',
      'excluded',
      'newLine',
      'origin',
    ];
  });

  try {
    await ClosedCaption.updateWords(sequenceSid, activeWords);
  } catch (error) {
    console.error(error);
  }
};
