import { useEffect, MutableRefObject } from 'react';

export const useClickOutsideEvent = <T extends HTMLElement>(ref: MutableRefObject<T | null>, callback: () => void, eventName: any = 'mousedown') => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener(eventName, handleClickOutside);

    return () => {
      document.removeEventListener(eventName, handleClickOutside);
    };
  }, [ref, callback]);
};
