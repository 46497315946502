
import * as Base from './base/frameCuePoint';

export const FrameCuePointSourceStatus = Base.FrameCuePointSourceStatus;

export interface IFrameCuePoint extends Base.IFrameCuePoint {

}

export interface IFrameCuePointProperties extends Base.IFrameCuePointProperties {

}

export interface IFrameCuePointFilter extends Base.IFrameCuePointFilter {

}

export default class FrameCuePoint<IType extends IFrameCuePoint = IFrameCuePoint, ITypeProperties extends IFrameCuePointProperties = IFrameCuePointProperties> extends Base.FrameCuePoint<IType, ITypeProperties> implements IFrameCuePoint {
}