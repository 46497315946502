import { appConfig } from './app-context';

let paddleInstance = null

const getPaddle = () => {
	if (paddleInstance) {
		return paddleInstance
	}
	if (typeof window === "undefined") {
		return null
	}
	window.Paddle.Environment.set(appConfig.PADDLE_ENV)
	window.Paddle.Setup({
		vendor: appConfig.PADDLE_VENDOR_ID,
	})
	paddleInstance = window.Paddle
	return window.Paddle
}

export function createSubscription({
	product,
	userSid,
	planSid,
	chargeType,
	successCallback
}: {
	product: number,
	userSid: string,
	planSid: string,
	chargeType: string,
	successCallback: () => void;

}) {
	const paddle = getPaddle()
	if (!paddle) {
		console.error('Paddle not found')
		return
	}
	paddle.Checkout.open({
		product,
		passthrough: `{"userSid": "${userSid}", "planSid": "${planSid}", "chargeType": "${chargeType}", "env": "${appConfig.ENV_NAME}"}`,
		successCallback
	})
}

export function updatePaymentMethod(updateUrl: string) {
	const paddle = getPaddle()
	if (!paddle) {
		console.error('Paddle not found')
		return
	}
	paddle.Checkout.open({
		override: updateUrl,
		passthrough: `{"env": "${appConfig.ENV_NAME}"}`
	})
}
