
import { appConfig } from '../lib/app-context';
import * as Base from './base/asset';
import {AssetType, AssetStatus} from './base/asset';
	
export {default as ContentIdentifier} from './asset/contentIdentifier';

export { AssetType } from './base/asset';
export { AssetStatus } from './base/asset';

export interface IAsset extends Base.IAsset {

}

export interface IAssetProperties extends Base.IAssetProperties {

}

export interface IAssetFilter extends Base.IAssetFilter {

}

interface IThumbnailUrl {
    width?: number;
    aspectRatio?: string;
}

export default class Asset<IType extends IAsset = IAsset, ITypeProperties extends IAssetProperties = IAssetProperties> extends Base.Asset<IType, ITypeProperties> implements IAsset {
    thumbnail?: string;

    static FONT_ASSET_TYPES = [
        AssetType.FONT_OTF,
        AssetType.FONT_TTF,
        AssetType.FONT_WOFF,
        AssetType.FONT_WOFF2,
        AssetType.FONT_ZIP,
    ]

    static isFont(type: Base.AssetType) {
        return Asset.FONT_ASSET_TYPES.includes(type)
    }

    get previewUrl() {
        return `${appConfig.PLAYBACK_URL}/asset/${this.sid}/Content/resized/${this.version}/manifest.mpd`
    }

    getThumbnailUrl(options: IThumbnailUrl = {}) {
        const {width, aspectRatio} = options;
        if(this.thumbnail) {
            return this.thumbnail;
        }
        
        let contentIdentifier = this.contentIdentifier
        let thumbnailVersion = contentIdentifier?.thumbnailVersion || 0
        if(width && aspectRatio){
            return `${appConfig.CHUNKS_URL}/asset/${this.sid}/Content/${width}/${aspectRatio.replace(':', '-')}/${thumbnailVersion}.png`
           
        }
        if(width){
            return `${appConfig.CHUNKS_URL}/asset/${this.sid}/Content/${width}/${thumbnailVersion}.png`
        }

        return `${appConfig.CHUNKS_URL}/asset/${this.sid}/Content/${thumbnailVersion}.png`
    }

    getTranscriptionUrl() {
        return `${appConfig.CHUNKS_URL}/a/${this.sid}/${this.version}.txt`
    }
}