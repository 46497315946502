import { useMemo } from 'react';
import classNames from 'classnames';

import Preset from '../../../client/preset';
// @ts-ignore
import gfonts from '../../../lib/gfonts.json';
import { uniqueSid } from '../../../state/cuePoints';
import { loadedPresetsState } from '../../../state/local';
import { isItalic, WEIGHT_TO_VAR } from '../../../ui-components/Fonts/Fonts';
import Sequence from '../../../client/sequence';

const formatFontFileName = (name?: string) => name?.split('.')?.slice(0, -1)?.join('') || name;

const formatGoogleFontVariant = (fontVariant: string) =>
  classNames(WEIGHT_TO_VAR[Number(fontVariant.slice(0, 3)) as keyof typeof WEIGHT_TO_VAR], {
    Italic: isItalic(fontVariant),
  });

const getGoogleFontFileNames = (fontFamily?: string | null) => {
  const variants: string[] | undefined = gfonts.defaults[fontFamily] || gfonts.rest[fontFamily];

  return variants?.map(formatGoogleFontVariant) ?? [];
};

const variantToWeight = (fontVariant: string) => {
  const isItalicVariant = fontVariant.includes('Italic');

  const fontVar = Object.entries(WEIGHT_TO_VAR).find((variant) => {
    const variantName = isItalicVariant ? fontVariant.slice(0, -7) : fontVariant;
    return variant[1] === variantName;
  })?.[0];

  return isItalicVariant ? fontVar + 'i' : fontVar;
};

export interface FontOption {
  key: string;
  text: string;
  value: string;
  fontFamily?: string;
  isDefault?: boolean;
  assetSid?: string;
}

export const useFontOptions = (sequence: Sequence) => {
  const sequencePreset = loadedPresetsState.use(
    ((presets?: Preset[]) =>
      presets?.find((p) => p.sid === sequence.presetSid) || Preset.getDefaultPreset(presets)) as any
  ) as Preset;
  const bodyAsset = sequencePreset?.getBodyFontAsset();
  const headerAsset = sequencePreset?.getHeaderFontAsset();
  const fontFamily = sequencePreset?.fontFamily;
  const headerFontFamily = sequencePreset?.headerFontFamily;

  return useMemo<FontOption[]>(
    () => [
      {
        key: uniqueSid(),
        text: 'Assistant',
        value: 'Assistant',
        isDefault: true,
      },

      ...(bodyAsset?.fileNames ?? []).map((file) => ({
        key: uniqueSid(),
        text: formatFontFileName(file)!,
        value: file,
        assetSid: bodyAsset.assetSid!,
      })),

      ...(headerAsset?.fileNames ?? []).map((file) => ({
        key: uniqueSid(),
        text: formatFontFileName(file)!,
        value: file,
        assetSid: headerAsset.assetSid!,
      })),

      ...(getGoogleFontFileNames(fontFamily) ?? []).map((variant) => ({
        key: uniqueSid(),
        text: `${fontFamily} ${variant}`,
        value: variantToWeight(variant)!,
        fontFamily: fontFamily ?? undefined,
      })),

      ...(headerFontFamily && headerFontFamily !== fontFamily ? getGoogleFontFileNames(headerFontFamily) : []).map(
        (variant: string) => ({
          key: uniqueSid(),
          text: `${headerFontFamily} ${variant}`,
          value: variantToWeight(variant)!,
          fontFamily: headerFontFamily ?? undefined,
        })
      ),
    ],
    [sequencePreset]
  );
};
