import React from 'react';
import Stepper, { StepperDirection } from '../Stepper'
import Button, { ButtonSize, ButtonVariant } from '../Button';

import './onboardingWizard.scss';
import { MODES } from '../../pages/create';

const NUM_STEPS = 4


interface OnboardingWizardProps {
    numSteps: number;
    currentStep: number;
    direction: 'horizontal|vertical';
    canProgressToNextStep: boolean;
    toNextStep(): void;
    toPrevStep(): void;
    onDone(): void;
    children: React.ReactNode;
    PreviewComponent?: JSX.Element;//React.ReactNode;
}

export default function OnboardingWizard({ PreviewComponent, numSteps, currentStep, direction, canProgressToNextStep, toNextStep, toPrevStep, onDone, children, ...props }: OnboardingWizardProps & {}) {
    function stepText() {
        if (currentStep === MODES.UPLOAD) {
            return <h1>Just follow these <span className='highlight'>{numSteps}</span> steps and we'll take care of the rest</h1>
        }
        if (currentStep === MODES.SOR) {
            return <h1>Which of the following best describes your <span className='highlight'>needs</span>?</h1>
        }
        if (currentStep === MODES.TITLE) {
            return <h1>But what<br /> will I<span className='highlight'> name</span> <br /> my project?</h1>

        }
        if (currentStep === MODES.BRAND) {
            return <h1>Don't<br /> forget to<br /> <span className='highlight'>brand</span> your videos! </h1>
        }
    }

    return (
        <div className='wizard-container'>
            {
                PreviewComponent ? PreviewComponent :
                    <div className='description'>
                        <div className='description-body'>
                            <div className='description-title'>
                                To Start
                            </div>
                            {stepText()}

                        </div>
                        <div className='progress-indicator-container'>
                            <Stepper numSteps={numSteps} currentStep={currentStep} direction={StepperDirection.Horizontal} />
                        </div>
                    </div>
            }
            <div className='wizard-step'>
                {children}
                {!PreviewComponent && <div className='controls-container'>
                    {currentStep !== MODES.UPLOAD && <Button variant={ButtonVariant.Ghost} size={ButtonSize.Large} label='Back' disabled={currentStep < MODES.SOR} onClick={toPrevStep} />}
                    <Button variant={ButtonVariant.Primary} id='next-button' data-cy="wizard-next-btn" size={ButtonSize.Large} label='Next' disabled={!canProgressToNextStep} onClick={currentStep >= numSteps ? onDone : toNextStep} />
                </div>}
            </div>
        </div >
    )
}

OnboardingWizard.defaultProps = {
    numSteps: NUM_STEPS,
};
