import classNames from 'classnames';
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { errorState } from '../../state/error'
import { fallBackUrlState } from '../../state/local';
import Button, { ButtonVariant, ButtonSize } from '../../ui-components/Button';

import './error.scss';

export default function ErrorDialog() {
	const { title, message, actionText, action } = errorState.use() || {};
	const shouldShowAction = actionText && action
	const fallbackUrl = fallBackUrlState.use()

	function close() {
		errorState.set({});
	}

	function errorCheck() {
		if (shouldShowAction) {
			return
		}
		fallbackUrl ? location.href = fallbackUrl : close()
	}

	const errorModalClassNames = classNames('error-dialog', { 'with-action': action })

	const errorIcon = <svg
		width="63"
		height="63"
		viewBox="0 0 63 63"
		fill="none"
		name="error-icon"
		xmlns="http://www.w3.org/2000/svg">
		<circle cx="31.5" cy="31.5" r="29" stroke="#E44258" strokeWidth="5" />
		<path d="M34.616 20H29.5547L30.0053 36.3627H34.1653L34.616 20ZM31.9813 39.24C30.2133 39.24 29 40.4187 29 42.1173C29 43.816 30.2133 44.9947 31.9813 44.9947C33.784 44.9947 34.9627 43.816 34.9627 42.1173C34.9627 40.4187 33.784 39.24 31.9813 39.24Z" fill="#E44258" />
	</svg>

	return (
		<Modal
			className={errorModalClassNames}
			show={(message || title) !== undefined}
			onHide={errorCheck}
			backdrop={shouldShowAction ? 'static' : true}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body className="error-message">
				{!shouldShowAction && <button type="button" className="btn-close" onClick={errorCheck} />}
				{errorIcon}
				<h3>{title}</h3>
				<p>{message}</p>
				{shouldShowAction && <Button variant={ButtonVariant.Primary} size={ButtonSize.Large} onClick={action}>{actionText}</Button>}
			</Modal.Body>
		</Modal>
	)
}