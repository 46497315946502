import styled from '@emotion/styled/macro';
import React from 'react';
import { COLORS } from '../../../constants/colors';
import Asset from '../../client/asset';
import Button from '../../ui-components/Button/Button';
import CustomLanguageSelector, { LangSelectorColors } from '../../ui-components/CustomLanguageSelector/CustomLanguageSelector';
import Loader from '../../ui-components/Loader';
import { convertTimestampToDateString } from '../../utils/time-utils';
import SVG from '../svg';
import './playerModal.scss';

const PlugBlured = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 90%;
  width: 100%;
  z-index: 20;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  filter: blur(2.5px);
`;

const AssetInfoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 30;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  a {
    position: absolute;
    top: 14px;
    right: 14px;
    width: 15px;
    height: 15px;
    svg {
      fill: ${COLORS.peech_neutral_white};
    }
  }
  a:hover {
    cursor: pointer;
  }
`;

const MetaDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const AvatarWrapper = styled.div`
  width: 66px;
  height: 66px;
  border: solid yellow 3px;
  margin: 22px 22px 0px 22px;
`;

const MetaDataInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 22px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  color: ${COLORS.peech_neutral_white};
  margin-left: 22px;

  span {
    font-weight: 400;
  }
`;

const LangWrapper = styled.div<{direction: string}>`
  display: flex;
  flex-direction: ${({ direction }): string => (direction)};
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 22px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: ${COLORS.peech_neutral_white};
  
  span {
    width: 128px;
    height: 27px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
  }

  .peech-button--primary {
    width: 136px;
    height: 28px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 12px;
  }
`;

const SelectorWrapper = styled.div`
  height: 30px;
  margin-right: 22px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;

  .lang-input {
    width: 173px;
    height: 27px;
    padding: 0;
  }
`;

const LoadWrapper = styled.div`
  height: 26px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1px 0 12px 14px;
`;

interface AssetInfoPlugProps {
  supportedLanguages: Object[];
  onLangChange(): string;
  setShowInfo(): void;
  previewAsset: Asset;
  setPreviewAsset(): void;
  statusNotReady: boolean;
}

export default function AssetInfoPlug({
  supportedLanguages,
  onLangChange,
  setShowInfo,
  previewAsset,
  setPreviewAsset,
  statusNotReady,
}: AssetInfoPlugProps) {
  const [tempLang, setTempLang] = React.useState(null);
  const insertDate = React.useMemo(() => {
    if (previewAsset) {        
      return convertTimestampToDateString(previewAsset.insertTime);
    } else {
      return null;
    }
  }, [previewAsset]);

  async function applyChanges() {
    try {
      await onLangChange(tempLang.languageCode, previewAsset.sid);
      setTempLang(null);
    } catch (error) {
      console.log('In AssetInfoPlug - applyChanges error: ', error);
    }
  }

  return (
    <>
      <PlugBlured />
      <AssetInfoContainer>
        <a onClick={() => setShowInfo(false)}>
          <SVG name="close" viewBox="0 0 24 24" />
        </a>
        <MetaDataWrapper>
          <MetaDataInfo>
            <div>
              Date: <span>{insertDate}</span>
            </div>
          </MetaDataInfo>
        </MetaDataWrapper>

        <LangWrapper direction={previewAsset?.aspectRatio === "9:16" ? "column" : "row"}>
          <span>Video's language</span>

          <SelectorWrapper>
            {previewAsset && (
              <CustomLanguageSelector
                sid={previewAsset.sid}
                lang={previewAsset.languageCode}
                disabled={statusNotReady}
                supportedLanguages={supportedLanguages}
                setTempLang={setTempLang}
                minimized={true}
                selectorColor={LangSelectorColors.WHITE}
              />
            )}
             {statusNotReady ? (
            <LoadWrapper variant="primary">
              <Loader size="xs" />
            </LoadWrapper>
          ) : null}


          </SelectorWrapper>

          {!statusNotReady && tempLang ? (
            <Button variant="primary" onClick={applyChanges}>
              Apply Changes
            </Button>
          ) : null}
        </LangWrapper>
      </AssetInfoContainer>
    </>
  );
}
