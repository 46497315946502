import styled from "@emotion/styled/macro";
import classNames from 'classnames';
import { Link, navigate } from 'gatsby';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useGoogleLogout } from 'react-google-login';
import { entity } from 'simpler-state';
import { COLORS } from '../../../constants/colors';
import { FeatureFeatureName } from '../../client/base/feature';
import User from '../../client/user';
import { openPeechProUpgradeModal } from '../../components/layout';
import SVG from "../../components/svg";
import ResetPassword from '../../dialogs/password-reset';
import { AppContext } from '../../lib/app-context';
import { googleLoginState } from '../../state/menu';
import { trackEvent } from '../../utils/analityics.utils';
import Button from '../Button';
import { PeechProUpgradeButton } from '../StyledComponents/PeechProUpgradeButton.styled';
import './Menu.scss';

const StyledPeechProUpgradeButton = styled(PeechProUpgradeButton)`
  justify-content: center;
  height: 42px;
  width: calc(100% - 40px);
  margin-inline: 20px;
`

export const supportState = entity(false)

interface MenuProps {
    controlled: boolean;
    show: boolean;
    openMenu: boolean;
    onClose(): void;
}

const VersionTag = styled.span`
    position: absolute;
    right: 10px;
    bottom: 50px;
    font-size: 8px;
    pointer-events: none;
    color: ${COLORS.peech_gray_400};
`
export default function Menu({ controlled, show, onClose, openMenu }: MenuProps) {
    const { user, plan, config } = React.useContext(AppContext);
    const googleLogin = googleLoginState.use();
    const [resetPassword, setResetPassword] = React.useState(false);
    const shouldShowUpgradeFeatureButton = plan?.planDisallowedTierFeatures?.includes(FeatureFeatureName.HIDE_UPGRADE_BUTTON);

    function onLogout() {
        trackEvent('logout');
        User.ps = null;
        googleLogin && googleLoginState.set(false)
        window.location.assign(`${config.NEW_UI_URL}/logout?redirected`);
    }

    function onClickSupport() {
        trackEvent('support-open');
        supportState.set(true);
    }

    function onClickResetPassword() {
        trackEvent('password-change-open');
        setResetPassword(true);
    }


    return (
        <>
            <Dropdown data-cy="peech-menu" className="peech-menu" onToggle={isOpen => !isOpen && onClose && onClose()}>
                <Dropdown.Toggle
                    data-cy="peech-menu-toggle"
                    id="peech-menu-toggle"
                    className="peech-menu--toggle"
                    variant="white"
                    style={{ zIndex: 1001, width: 0, height: 0 }} />

                <Dropdown.Menu className={classNames("peech-menu--nav", { "open": openMenu })} renderOnMount={true} show={controlled && show}>
                    {user && (
                        <div className="peech-menu--user-section">
                            {shouldShowUpgradeFeatureButton ? (
                                <StyledPeechProUpgradeButton onClick={() => openPeechProUpgradeModal(user, "User Menu Button")}>
                                    <SVG name="diamond"/>
                                    Upgrade
                                </StyledPeechProUpgradeButton>
                            ): (
                                <Dropdown.Item className="upgrade">
                                    <Button data-cy="open-plans-btn" id="upgrade-btn-user" label="Get more from Peech" variant='primary' size="sm" onClick={() => navigate("/plans")} />
                                </Dropdown.Item>
                            )}
                        </div>
                    )}

                    <div className="peech-menu--navigation">
                        {user && <>
                             <Dropdown.Item id="billing-link" as={Link} to="/billing">
                                Billing
                            </Dropdown.Item>
                        </>}

                        <Dropdown.Item className="support-link" id="support-link" onClick={onClickSupport} >
                            Contact Support
                        </Dropdown.Item>

                        {user && <Logout onLogout={onLogout} />}
                        <VersionTag>{config.RELEASE_VERSION}</VersionTag>
                    </div>
                </Dropdown.Menu>
            </Dropdown >

            {resetPassword && <ResetPassword onHide={() => setResetPassword(false)} />}
        </>
    )
}

Menu.defaultProps = {
};

function Logout({ onLogout }) {

    const { config } = React.useContext(AppContext)
    const googleLogin = googleLoginState.use();

    React.useEffect(() => {
        if (!config.GOOGLE_CLIENT_ID) {
            googleLoginState.set(false);
        } else {
            loadYoutubeApi();
        }
    }, [])

    function loadYoutubeApi() {
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/client.js";
        script.onload = () => {
            gapi.load('auth2', () => {
                gapi.auth2.init({ client_id: config.GOOGLE_CLIENT_ID })
                    .then(() => {
                        if (!googleLogin && gapi.auth2.getAuthInstance()) {
                            googleLoginState.set(true);
                        } else {
                            googleLoginState.set(false);
                        }
                    },
                        (err) => console.error(err));
            });
        };

        document.body.appendChild(script);
    }

    const { signOut } = config.GOOGLE_CLIENT_ID ? useGoogleLogout({
        clientId: config.GOOGLE_CLIENT_ID,
        onLogoutSuccess: onLogout,
    }) : {}


    return (
        <Dropdown.Item
            id={`logout-${googleLogin ? 'google' : 'email'}`}
            className="menu-icon logout-btn"
            onClick={googleLogin ? signOut : onLogout}
        >
            Log out
        </Dropdown.Item>
    );
}
