
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export enum FeatureFeatureName {
	CLONE_SEQUENCE_ASPECT_RATIO_CHANGE = 'CLONE_SEQUENCE_ASPECT_RATIO_CHANGE',
	CLONE_SEQUENCE = 'CLONE_SEQUENCE',
	CUSTOMIZE_SUBTITLE = 'CUSTOMIZE_SUBTITLE',
	WITHOUT_PEECH_WATERMARK = 'WITHOUT_PEECH_WATERMARK',
	MUSIC_LIBRARY = 'MUSIC_LIBRARY',
	DOWNLOAD_TRANSCRIPTION = 'DOWNLOAD_TRANSCRIPTION',
	ROADMAP = 'ROADMAP',
	TRANSLATE_SEQUENCE = 'TRANSLATE_SEQUENCE',
	HIDE_PRE_CREATE_POPUP = 'HIDE_PRE_CREATE_POPUP',
	HIDE_UPGRADE_BUTTON = 'HIDE_UPGRADE_BUTTON',
	UNLOCK_FOOTAGE_EXPIRED = 'UNLOCK_FOOTAGE_EXPIRED',
	YOUTUBE_UPLOAD = 'YOUTUBE_UPLOAD',
};

export interface IFeature extends IBase {
	status?: number | null;
	name?: FeatureFeatureName | null;
	description?: string | null;
	tier?: number | null;
}

export interface IFeatureProperties extends IBaseProperties {
	status?: boolean;
	name?: boolean;
	description?: boolean;
	tier?: boolean;
}

export interface IFeatureFilter extends IBaseFilter {
}

export class Feature<IType extends IFeature = IFeature, ITypeProperties extends IFeatureProperties = IFeatureProperties> extends Base<IType, ITypeProperties> implements IFeature {
	
	static className = 'Feature';
	static NAME = 'feature';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		name: true,
		description: true,
		tier: true,
	};
	
	/**
	 * @deprecated use FeatureFeatureName instead
	 */
	static FEATURE_NAME: any = {
		CLONE_SEQUENCE_ASPECT_RATIO_CHANGE: FeatureFeatureName.CLONE_SEQUENCE_ASPECT_RATIO_CHANGE,
		CLONE_SEQUENCE: FeatureFeatureName.CLONE_SEQUENCE,
		CUSTOMIZE_SUBTITLE: FeatureFeatureName.CUSTOMIZE_SUBTITLE,
		WITHOUT_PEECH_WATERMARK: FeatureFeatureName.WITHOUT_PEECH_WATERMARK,
		MUSIC_LIBRARY: FeatureFeatureName.MUSIC_LIBRARY,
		DOWNLOAD_TRANSCRIPTION: FeatureFeatureName.DOWNLOAD_TRANSCRIPTION,
		ROADMAP: FeatureFeatureName.ROADMAP,
		TRANSLATE_SEQUENCE: FeatureFeatureName.TRANSLATE_SEQUENCE,
		HIDE_PRE_CREATE_POPUP: FeatureFeatureName.HIDE_PRE_CREATE_POPUP,
		HIDE_UPGRADE_BUTTON: FeatureFeatureName.HIDE_UPGRADE_BUTTON,
		UNLOCK_FOOTAGE_EXPIRED: FeatureFeatureName.UNLOCK_FOOTAGE_EXPIRED,
		YOUTUBE_UPLOAD: FeatureFeatureName.YOUTUBE_UPLOAD,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get name(): FeatureFeatureName | null | undefined {
		return this.values.name
	}
			
	set name(value: FeatureFeatureName | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	get description(): string | null | undefined {
		return this.values.description
	}
			
	set description(value: string | null | undefined) {
		if(this.values.description !== value) {
			this.propertyChanged('description', value);
			this.values.description = value;
		}
	}
			
	get tier(): number | null | undefined {
		return this.values.tier
	}
			
	set tier(value: number | null | undefined) {
		if(this.values.tier !== value) {
			this.propertyChanged('tier', value);
			this.values.tier = value;
		}
	}
			
	
	static get<IType extends IFeature = IFeature>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IFeatureFilter = IFeatureFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IFeatureFilter = IFeatureFilter, IType extends IFeature = IFeature>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
