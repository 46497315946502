import styled from '@emotion/styled/macro';
import { useLocation } from '@reach/router';
import className from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { COLORS } from '../../../constants/colors';
import { FeatureFeatureName } from '../../client/base/feature';
import { openPeechProUpgradeModal } from '../../components/layout';
import LottiePlayer from '../../components/lottie-player';
import SVG from '../../components/svg';
import { AppContext } from '../../lib/app-context';
import Avatar from '../Avatar';
import CreateProject from '../CreateProject';
import Menu from '../HeaderMenu/Menu';
import { PeechProUpgradeButton } from '../StyledComponents/PeechProUpgradeButton.styled';
import RoadMapButton from './RoadMapButton';
import './SideBarMenu.scss';
import peechLogo from './peech-logo.json';
import Loader from '../Loader';
import { isInIframe } from '../../utils/window.utils';

const Separator = styled.div`
  width: 100%;
  margin-block: 5px;
  border-bottom: 2px solid ${COLORS.peech_gray_100};
  margin: 22px 0;
`;

const StyledPeechProUpgradeButton = styled(PeechProUpgradeButton)<{ hideText?: boolean }>`
  svg {
    margin-right: 0;
  }
  span {
    margin-left: ${({ hideText }): string => (hideText ? '0px' : '20px')};
    visibility: ${({ hideText }): string => (hideText ? 'hidden' : 'visible')};
    width: ${({ hideText }): string => (hideText ? '0px' : '70px')};
    transition: visibility 250ms ease-out, width 250ms ease-out, margin-left 450ms ease-out;
  }
`;

type SideBarItem = {
  name: string;
  title: string;
  value: number;
  path: string;
  comingSoon: boolean;
  enable: boolean;
  target: string;
  loader: boolean;
};

interface SideBarMenuProps {
  menuItems: SideBarItem[];
  onItemChanged: any;
}

export default function SideBarMenu({ menuItems, onItemChanged }: SideBarMenuProps) {
  const location = useLocation();
  const [billingPage, setBillingPage] = React.useState(false);
  const { user, plan, config } = React.useContext(AppContext);
  const [openMenu, setOpenMenu] = React.useState(true);
  const [openUserMenu, setOpenUserMenu] = React.useState(false);
  const shouldShowRoadmapBunner = React.useMemo(() => config?.ROADMAP === 'true', [config]);
  const shouldShowSmartLibrary = user?.UIFLAGS.SMART_LIBRARY;
  const shouldShowUpgradeFeatureButton = plan?.planDisallowedTierFeatures?.includes(
    FeatureFeatureName.HIDE_UPGRADE_BUTTON
  );

  const getPathName = () => location.pathname.replaceAll('/', '');

  React.useEffect(() => {
    if (location.pathname.replaceAll('/', '') === 'billing') {
      setBillingPage(true);
    } else {
      setBillingPage(false);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    console.log('reverse lottie');
    // lottieRef.current.setDirection(-1);
  }, [!openMenu]);

  const hideSideBar = React.useMemo(
    () =>
      isInIframe() ||
      location.pathname.replaceAll('/', '') === 'project' ||
      location.pathname.replaceAll('/', '') === 'plans',
    [location.pathname]
  );

  if (hideSideBar) {
    return null;
  }

  return (
    <div className={className('sidebar-wapper', { open: openMenu })}>
      <div className="sidebar-menu">
        <a
          className={className('arrow-button', { open: openMenu })}
          style={{
            position: 'absolute',
            right: '0',
            transform: 'translateX(50%)',
            cursor: 'pointer',
            marginTop: '30px',
            zIndex: '22',
          }}
          onClick={() => setOpenMenu(!openMenu)}
        >
          <SVG className="open-arrow" name="open-arrow" />
        </a>
        <div className="sidebar-top-part">
          <div className="sidebar-menu-container">
            <div className="animation-wrapper">
              <LottiePlayer className="peech-logo" animationData={peechLogo} autoplay={openMenu} loop={false} />
            </div>

            <div className="sidebar-items">
              {!!shouldShowSmartLibrary && (
                <Link
                  to={'/webinars'}
                  state={{ fromSidebar: true }}
                  key={'webinar-library'}
                  className={className('item sidebar-menu-element icon')}
                  style={{ marginBottom: 0 }}
                >
                  <div className="item-content" style={{ left: '18px' }}>
                    <div>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_6871_297952)">
                          <path
                            d="M17.7915 7.21214C17.3472 5.30142 16.2162 3.62058 14.6137 2.48912C13.0111 1.35766 11.0487 0.854452 9.09948 1.07515C7.15022 1.29585 5.35 2.22507 4.041 3.68618C2.732 5.14729 2.00549 7.03843 1.99955 9.00014C1.99808 10.2786 2.30692 11.5383 2.89955 12.6711C1.82067 13.2493 0.965945 14.1714 0.471164 15.2909C-0.0236166 16.4105 -0.13007 17.6633 0.168717 18.8503C0.467503 20.0373 1.15439 21.0904 2.12025 21.8422C3.08611 22.5941 4.27553 23.0017 5.49955 23.0001H11.9995V21.0001H5.49955C4.64797 21.0027 3.82483 20.6939 3.18506 20.1318C2.5453 19.5698 2.13303 18.7933 2.02586 17.9485C1.91869 17.1037 2.12401 16.2488 2.60318 15.5448C3.08235 14.8409 3.80231 14.3363 4.62754 14.1261L6.06455 13.7551L5.18155 12.5621C4.41308 11.5337 3.99839 10.284 3.99955 9.00014C4.01095 7.48612 4.59433 6.03239 5.63269 4.93049C6.67104 3.82859 8.08759 3.15998 9.59826 3.05877C11.1089 2.95755 12.602 3.4312 13.7781 4.38473C14.9542 5.33827 15.7263 6.70118 15.9395 8.20014L16.0395 8.95814L16.7965 9.05814C18.1325 9.23324 19.3705 9.85366 20.3103 10.8192C21.2502 11.7846 21.837 13.0388 21.9761 14.379C22.1152 15.7192 21.7984 17.0672 21.0768 18.2051C20.3552 19.343 19.2711 20.2044 17.9995 20.6501V22.7371C19.7339 22.2911 21.2681 21.2753 22.3559 19.8528C23.4437 18.4303 24.022 16.6835 23.998 14.8929C23.974 13.1023 23.3491 11.3716 22.2236 9.97874C21.0981 8.58587 19.5372 7.61154 17.7915 7.21214Z"
                            fill="url(#paint0_linear_6871_297952)"
                          />
                          <path
                            d="M18.293 17.7073L19.707 16.2933L16.414 13.0003C16.0389 12.6253 15.5303 12.4147 15 12.4147C14.4697 12.4147 13.9611 12.6253 13.586 13.0003L10.293 16.2933L11.707 17.7073L14 15.4143V24.0003H16V15.4143L18.293 17.7073Z"
                            fill="url(#paint1_linear_6871_297952)"
                          />
                        </g>
                        <defs>
                          <linearGradient
                            id="paint0_linear_6871_297952"
                            x1="0.00263596"
                            y1="12.0115"
                            x2="23.9927"
                            y2="12.0115"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#EA429D" />
                            <stop offset="0.0911027" stop-color="#EA429D" />
                            <stop offset="0.2426" stop-color="#EA75B4" />
                            <stop offset="0.541667" stop-color="#AE8BFA" />
                            <stop offset="0.9944" stop-color="#1F9FFB" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_6871_297952"
                            x1="10.293"
                            y1="18.2071"
                            x2="19.7046"
                            y2="18.2071"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#EA429D" />
                            <stop offset="0.0911027" stop-color="#EA429D" />
                            <stop offset="0.2426" stop-color="#EA75B4" />
                            <stop offset="0.541667" stop-color="#AE8BFA" />
                            <stop offset="0.9944" stop-color="#1F9FFB" />
                          </linearGradient>
                          <clipPath id="clip0_6871_297952">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="item-titles">
                      <div className={className('title')}>Upload</div>
                    </div>
                  </div>
                </Link>
              )}
              <div className="item sidebar-menu-element icon" style={{ marginBottom: 0 }}>
                <div className="item-content" style={{ left: '18px' }}>
                  {plan ? (
                    <CreateProject
                      renderPlace="sidebar"
                      overrideLabel={shouldShowSmartLibrary ? 'Create Project' : undefined}
                    />
                  ) : null}
                </div>
              </div>
              <Separator />

              {menuItems.map((item) => {
                if (item.enable) {
                  return (
                    <Link
                      to={item.path}
                      target={item.target}
                      state={{ fromSidebar: true }}
                      onClick={() => onItemChanged(item.value)}
                      key={item.name}
                      className={className('item sidebar-menu-element icon', {
                        selected: item.path.replaceAll('/', '') === getPathName() && !item.comingSoon && !billingPage,
                        'coming-soon': item.comingSoon === true,
                      })}
                    >
                      <div className="item-content" style={{ left: item.name === 'learning-center' ? '16px' : '18px' }}>
                        <div>
                          {item.loader ?  <Loader size='xs'/> :   <SVG name={item.name} />}
                        </div>
                        <div className="item-titles">
                          <div className={className('title', { 'coming-soon': item.comingSoon === true })}>
                            {item.title}
                          </div>
                          <div className={className('soon-title', { 'coming-soon': item.comingSoon === true })}>
                            Coming Soon
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                }
              })}

              {shouldShowUpgradeFeatureButton && (
                <div className="item sidebar-menu-element icon" style={{ padding: 0 }}>
                  <StyledPeechProUpgradeButton
                    onClick={() => openPeechProUpgradeModal(user, 'Main Menu Button')}
                    hideText={!openMenu}
                  >
                    <SVG name="diamond" />
                    <span>Upgrade</span>
                  </StyledPeechProUpgradeButton>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="user-side">
          {!shouldShowSmartLibrary && shouldShowRoadmapBunner && (
            <div className="item sidebar-menu-element icon full-width" style={{ padding: 0, left: '0px' }}>
              <RoadMapButton isBig={openMenu} />
            </div>
          )}
          {openUserMenu && (
            <Menu controlled={true} show={true} onClose={() => setOpenUserMenu(false)} openMenu={openMenu} />
          )}

          <div
            className={className('user-wrapper', { open: openMenu })}
            onClick={() => setOpenUserMenu(!openUserMenu)}
            data-cy="user-menu"
          >
            <div className="user-content">
              <Avatar user={user} size="xs" />
              <div className={className('user-details')}>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    !openUserMenu ? (
                      <Tooltip id="cut-included-tooltip" className="tooltip-peech-dark">
                        {`${user.firstName} ${user.lastName}`}
                      </Tooltip>
                    ) : (
                      <span />
                    )
                  }
                >
                  <span className="user-name">{`${user.firstName} ${user.lastName}`}</span>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="right"
                  overlay={
                    !openUserMenu ? (
                      <Tooltip id="cut-included-tooltip" className="tooltip-peech-dark">
                        {`${user.email}`}
                      </Tooltip>
                    ) : (
                      <span />
                    )
                  }
                >
                  <span className="user-email">{`${user.email}`}</span>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
