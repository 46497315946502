import React, { useCallback } from 'react';
import { navigate } from 'gatsby';
import { Modal } from 'react-bootstrap';
import styled from '@emotion/styled/macro';

import requestDemoImage from '../../../static/images/webinars-demo-modal.png';

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 580px;
  }

  .modal-content {
    display: flex;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
`;

const ModalBody = styled.div`
  display: flex;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 12%) 8px 4px 8px 0;
`;

const LeftPanel = styled.div`
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  padding: 30px 32px;
  font-family: 'Open Sans', sans-serif;
  color: #323338;
  background-color: #fff;
`;

const RightPanel = styled.div`
  flex: 0 0 50%;
  background-image: url('${requestDemoImage}');
  background-color: #443964;
  background-size: contain;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
`;

const SecondTitle = styled(Title)`
    font-size: 15px;
    margin-top: 20px;
`;

const Features = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
`;

const RequestDempButton = styled.button`
  height: 30px;
  margin-block: 20px;
  border: none;
  border-radius: 6px;
  background: linear-gradient(90deg, #ea429d 0%, #ea429d 9.11%, #ea75b4 24.25%, #ae8bfa 54.15%, #1f9ffb 99.42%);
  color: #fff;
`;

export default function RequestWebinarsDemoModal({ isOpen, onClose }: { isOpen: boolean; onClose(): void }) {
  const onRequestDemoClick = useCallback(() => {
    onClose();
    navigate('https://www.peech-ai.com/requestademo');
  }, []);

  return (
    <StyledModal show={isOpen} onHide={onClose} centered>
      <ModalBody>
        <LeftPanel>
          <Title>
            <div>It seems like we're</div>
            <div>dealing with a real video</div>
            <div>expert :)</div>
          </Title>

          <SecondTitle>
            <div>For long-form videos you </div>
            <div>should try:</div>
          </SecondTitle>

          <Features>
            <SecondTitle>Peech for webinars</SecondTitle>
            Perfect for long-form videos (up to 90 minutes) and includes an AI feature that generate unlimited video
            versions with Peech's Automated Workflow.
          </Features>

          <RequestDempButton onClick={onRequestDemoClick}>Request for a Demo</RequestDempButton>
        </LeftPanel>

        <RightPanel />
      </ModalBody>
    </StyledModal>
  );
}
