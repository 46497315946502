import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { entity } from 'simpler-state';
import { IChapter } from '../../../client/base/sequence/chapter';
import { Word } from '../../../client/closedCaption';
import CuePoint from '../../../client/cuePoint';
import { ChapterOrder } from '../../../client/sequence/chapter';
import SVG from '../../../components/svg';
import { AppContext } from '../../../lib/app-context';
import { cuePointsState, cuePointsVersionState, uniqueSid } from '../../../state/cuePoints';
import { clearTempCache, scenesState, sequenceState, setTempScenes, tempScenesState } from '../../../state/sequence';
import Button, { ButtonSize, ButtonVariant } from '../../../ui-components/Button';
import { trackEvent } from '../../../utils/analityics.utils';
import SceneEditor from './SceneEditor';

const WORD_THRESHOLD = 10;
export const BLANK_THRESHOLD = 1500;
export const BLANK_REPLACMENT_BEGIN_TIME_MS = 500;
export const BLANK_REPLACMENT_END_TIME_MS = 250;

interface EditorContentProps {
  unTrimmableChapter: boolean;
  unPlayableChapter: boolean;
  hideTrimButton?: boolean;
}

const createScrollStopListener = (element, callback, timeout) => {
  let removed = false;
  let handle = null;
  const onScroll = () => {
    if (handle) {
      clearTimeout(handle);
    }
    handle = setTimeout(callback, timeout || 200); // default 200 ms
  };
  element.addEventListener('scroll', onScroll);
  return () => {
    if (removed) {
      return;
    }
    removed = true;
    if (handle) {
      clearTimeout(handle);
    }
    element.removeEventListener('scroll', onScroll);
  };
};

export function handleCuts(words: Word[]) {
  if (!words?.length) {
    return;
  }
  const chapterSid: string | undefined = words[0].chapterSid;
  // 1. delete the whole linked chapters and set source chapter to the accepted duration/clipFrom
  tempScenesState.set((scenes) => {
    const filteredChapters: ChapterOrder[] = scenes.filter.durableArray;
    const virtualChapters: ChapterOrder[] = filteredChapters
      .filter((c) => c.linkSid === chapterSid)
      .sort((a, b) => a.index - b.index);
    const sourceChapter: ChapterOrder | undefined = scenes.map[chapterSid];

    // generate a new chapter with source SID
    const main = new ChapterOrder();
    const clipFrom = words[0].startTime / 1000;
    const clipTo = (words.at(-1).endTime - 1) / 1000;
    const duration = clipTo - clipFrom;
    console.log('selectedWords', { words, clipFrom, clipTo, duration });

    main.sid = chapterSid;
    // set sourceChapter to only include the selected words
    main.clipFrom = clipFrom;
    main.duration = duration;
    main.index = sourceChapter?.index && sourceChapter.duration ? sourceChapter.index : virtualChapters[0].index;

    // remove all virual chapters
    virtualChapters.forEach((c) => {
      c.index = null;
      c.duration = null;
    });
    scenes.map[chapterSid] = main;
    // index the scenes array
    Object.values(scenes)
      .filter((c) => c.index && c.duration)
      .forEach((c, index) => (c.index = index + 1));

    return scenes;
  });
  clearTempCache();

  // 2. split the active chapter in the specific point
  // as of now it will use markAsDeleted from ChapterClosedCaptions
}

export const checkAllDeletedState = entity(false);

export default function EditorContent({
  unTrimmableChapter,
  unPlayableChapter,
  loaderFinished,
  hideTrimButton,
}: EditorContentProps) {
  const [draggable, setDraggable] = React.useState<boolean>(false);
  const [hideDeletedWords, setHideDeletedWords] = React.useState<boolean>(false);
  const chaptersCount = tempScenesState.use((scenes) => scenes.filter.array.length);
  const filteredChapters = tempScenesState.use((scenes) => scenes.filter.durableArray);
  const tempScenesMap = tempScenesState.use((scenes) => scenes.map);
  const scenes = scenesState.use((scenes) => scenes.map);
  const cuePointsData = cuePointsState.use();
  const { user, config } = React.useContext(AppContext);
  const checkAllDeleted = checkAllDeletedState.use();
  const sequence = sequenceState.use();
  const order = React.useRef<any>();

  function getNewOrder(orderArr) {
    return Object.entries(orderArr).reduce((acc, [key, value]) => ((acc[value] = key), acc), {});
  }

  function toggleDraggable() {
    if (!draggable) {
      if (unPlayableChapter) {
        return;
      }
      order.current = filteredChapters.map((_, index) => index);
    } else if (filteredChapters[0]) {
      const firstChapterIndex = filteredChapters[0].index;
      const newOrder = getNewOrder(order.current);
      tempScenesState.set((scenes) => {
        filteredChapters.forEach((chapter, index) => {
          const chapterNewIndex = parseInt(firstChapterIndex) + parseInt(newOrder[index]);
          chapter.index = chapterNewIndex;
        });
        scenes._clearCache();
        return scenes;
      });
    }
    setDraggable(!draggable);
  }

  function toggleHideDeletedWords() {
    if (unPlayableChapter) {
      return;
    }
    setHideDeletedWords(!hideDeletedWords);
  }

  const contentWrapperRef = React.useRef<HTMLDivElement>();

  const [scrollVersion, setScrollVersion] = React.useState(0);

  const incrementScrollVersion = () => setScrollVersion((ver) => ver + 1);

  React.useEffect(() => (setTimeout(incrementScrollVersion, 50), null), [chaptersCount, loaderFinished]);
  React.useEffect(() => {
    let destroyListener;
    if (contentWrapperRef.current) {
      destroyListener = createScrollStopListener(contentWrapperRef.current, incrementScrollVersion, 150);
    }
    return destroyListener;
  }, [contentWrapperRef.current]);

  const cuePointsVersion = cuePointsVersionState.use();
  const closedCaptionCuePoints = Object.values(cuePointsData).filter(
    (cuePoint) => cuePoint?.type === CuePoint.TYPE.CLOSED_CAPTION && cuePoint?.words.length
  );

  const captions = React.useMemo(() => closedCaptionCuePoints.sort((a, b) => a.order - b.order), [cuePointsVersion]);

  const chapterWords = (chapter: ChapterOrder) =>
    captions.reduce((chapterWords, closedCaption) => {
      closedCaption.chapterSid === (chapter.linkSid || chapter.sid) &&
        closedCaption.status !== CuePoint.STATUS.DELETED &&
        chapterWords.push(closedCaption.words);
      return chapterWords;
    }, []);

  const isChapterActiveWord = (chapter: ChapterOrder, word: Word) =>
    (!chapter.clipFrom || word.startTime / 1000 >= chapter.clipFrom) &&
    word.startTime / 1000 <= (chapter.clipFrom || 0) + chapter.duration;

  const activeChapterWords = (chapter: ChapterOrder, chapterWords: Word[]) =>
    chapterWords
      .flat()
      .filter((w) => chapter && w.chapterSid === (chapter.linkSid || chapter.sid) && isChapterActiveWord(chapter, w))
      .sort((a, b) => a.startTime - b.startTime);

  const getChapterBlanks = (chapter: ChapterOrder, activeWords: Word[]) =>
    activeWords.reduce((blanks, word, index, words) => {
      // const spaceBefore = index === 0 && (chapter.clipFrom || 0)

      const spaceAfter = words[index + 1] && words[index + 1].startTime - word.endTime > BLANK_THRESHOLD;

      if (spaceAfter) {
        blanks.push({ startTime: word.endTime, endTime: words[index + 1].startTime });
      }
      return blanks;
    }, []);

  function blankAll(check = false) {
    const tempChapterSid = () => `temp-${uniqueSid()}`;
    let blankFound = false;

    const chaptersWords = {};
    const activeChaptersWords = {};
    const chapterBlanks = {};
    const blankedOrder: (ChapterOrder | IChapter)[] = [];

    filteredChapters.forEach((chapter: ChapterOrder, index: number) => {
      if (check && blankFound === true) {
        return;
      }
      chaptersWords[chapter.linkSid || chapter.sid] =
        chaptersWords[chapter.linkSid || chapter.sid] || chapterWords(chapter);
      activeChaptersWords[chapter.sid || chapter.cid] = activeChapterWords(
        chapter,
        chaptersWords[chapter.linkSid || chapter.sid]
      );
      chapterBlanks[chapter.sid || chapter.cid] = getChapterBlanks(
        chapter,
        activeChaptersWords[chapter.sid || chapter.cid]
      );

      const blanks = chapterBlanks[chapter.sid || chapter.cid];
      const sourceDuration: number = scenes[chapter.linkSid || chapter.sid].srcDuration;
      const activeChapterOrder = new ChapterOrder().set(chapter.values);
      activeChapterOrder.index = blankedOrder.length + 1;
      let clipFrom = chapter.clipFrom || 0;
      let clipTo = clipFrom + chapter.duration;

      if (!activeChaptersWords[chapter.sid || chapter.cid]) {
        // push chapter as is with new index
        // and move to next iteration
        return blankedOrder.push(activeChapterOrder);
      }

      // Blank at the beggining of a chapter
      if (activeChaptersWords[chapter.sid || chapter.cid]?.[0]?.startTime - clipFrom * 1000 > BLANK_THRESHOLD) {
        if (check) {
          return (blankFound = true);
        }
        clipFrom = activeChaptersWords[chapter.sid || chapter.cid][0].startTime / 1000;
      }

      // Blank at the end of a chapter

      if (clipTo * 1000 - activeChaptersWords[chapter.sid || chapter.cid]?.at?.(-1)?.endTime > BLANK_THRESHOLD) {
        if (check) {
          return (blankFound = true);
        }
        clipTo = activeChaptersWords[chapter.sid || chapter.cid]?.at?.(-1)?.endTime / 1000;
      }

      activeChapterOrder.clipFrom = clipFrom;
      activeChapterOrder.duration = clipTo - clipFrom - 0.001;

      if (blanks?.length) {
        if (check) {
          return (blankFound = true);
        } else {
          const duration = blanks[0].startTime / 1000 - clipFrom;
          activeChapterOrder.duration = duration + BLANK_REPLACMENT_BEGIN_TIME_MS / 1000;
          blanks.forEach((blank, index) => {
            const newChapter = new ChapterOrder();
            newChapter.cid = tempChapterSid();
            newChapter.linkSid = chapter.linkSid || chapter.sid;
            newChapter.index = activeChapterOrder.index + index + 1;
            newChapter.clipFrom = (blank.endTime - BLANK_REPLACMENT_END_TIME_MS) / 1000;
            newChapter.sourceDuration = sourceDuration;
            newChapter.duration = blanks[index + 1]
              ? (blanks[index + 1].startTime - blank.endTime + BLANK_REPLACMENT_BEGIN_TIME_MS) / 1000
              : clipTo - newChapter.clipFrom;
            blankedOrder.push(newChapter);
          });
        }
      }
      blankedOrder.push(activeChapterOrder);
    });

    if (!check) {
      setTempScenes({ scenes: blankedOrder });
    }

    return blankFound;
  }

  function revertBlanks(check = false) {
    let restoringAvailable = false;
    const chaptersWords = {};
    const chapterMap = filteredChapters.reduce((chapterMap, chapter, index) => {
      chapterMap[chapter.linkSid || chapter.sid] = chapterMap[chapter.linkSid || chapter.sid] || [];
      chapterMap[chapter.linkSid || chapter.sid].push(chapter);
      return chapterMap;
    }, []);

    const retChaptersArray: ChapterOrder[] = [];
    const pushReturnedChapter = (chapter: ChapterOrder, val, c) => {
      !retChaptersArray.includes(chapter) && retChaptersArray.push(chapter);
    };

    Object.keys(chapterMap).forEach(
      (chapterSid) =>
        (chaptersWords[chapterSid] = chapterWords(tempScenesMap[chapterSid])
          .flat()
          .sort((a, b) => a.startTime - b.startTime))
    );

    const firstChapterWordIndex = (chapter: ChapterOrder) =>
      (chaptersWords[chapter.linkSid || chapter.sid] || []).findIndex(
        (w: Word) =>
          w.endTime / 1000 > (chapter.clipFrom || 0) &&
          w.startTime / 1000 < (chapter.clipFrom || 0) + (chapter.duration as number) &&
          (w.endTime as number) - (chapter.clipFrom || 0) * 1000 > WORD_THRESHOLD
      );

    const lastChapterWordIndex = (chapter: ChapterOrder) =>
      chaptersWords[chapter.linkSid || chapter.sid]?.length -
      1 -
      [...(chaptersWords[chapter.linkSid || chapter.sid] || [])]
        .reverse()
        .findIndex(
          (w: Word) =>
            ((chapter.clipFrom || 0) + (chapter.duration as number)) * 1000 - (w.startTime as number) >
              WORD_THRESHOLD &&
            (w.endTime as number) > (chapter.clipFrom || 0) * 1000 &&
            (w.startTime as number) / 1000 <= (chapter.clipFrom || 0) + (chapter.duration as number)
        );

    Object.values(chapterMap).forEach((chapters: ChapterOrder[], index: number) => {
      chapters
        .sort((a: ChapterOrder, b: ChapterOrder) => a.index - b.index)
        .forEach((chapter: ChapterOrder, index: number) => {
          if (check && restoringAvailable) {
            return;
          }

          const firstWordIndex = firstChapterWordIndex(chapter);
          const lastWordIndex = lastChapterWordIndex(chapter);

          const prevWord = [...chaptersWords[chapter.linkSid || chapter.sid].slice(0, firstWordIndex + 1)]
            .reverse()
            .find((w) => w.startTime < chaptersWords[chapter.linkSid || chapter.sid][firstWordIndex]?.startTime);
          const prevChapter = chapters[index - 1];

          const nextWord = chaptersWords[chapter.linkSid || chapter.sid]
            .slice(lastWordIndex, -1)
            .find((w) => w.startTime > chaptersWords[chapter.linkSid || chapter.sid][lastWordIndex]?.startTime);
          const nextChapter = chapters[index + 1];

          const retPrevChapter: ChapterOrder = retChaptersArray.at(-1);
          const retChapter: ChapterOrder = new ChapterOrder();
          const sourceDuration: number = scenes[chapter.linkSid || chapter.sid].srcDuration;
          retChapter.sid = chapter.sid;
          retChapter.linkSid = chapter.linkSid;
          retChapter.cid = chapter.cid;
          retChapter.sourceDuration = sourceDuration;
          retChapter.index = (retPrevChapter?.index || 0) + 1;
          retChapter.clipFrom = chapter.clipFrom;
          retChapter.duration = chapter.duration;

          let connected = false;
          if (check) {
            return (
              detectRestorebaleBlanks(prevWord, prevChapter, nextWord, nextChapter, chapter) &&
              (restoringAvailable = true)
            );
          }

          // Prev
          if (prevChapter) {
            if (prevWord) {
              if (isChapterActiveWord(prevChapter, prevWord)) {
                // if word is included in prevChapter - connect
                const endTime = (chapter.clipFrom || 0) + chapter.duration;
                const duration = endTime - (retPrevChapter.clipFrom || 0);
                retPrevChapter.duration = duration;
                connected = true;
              } else {
                // else expand to word endTime
                retChapter.clipFrom = prevWord.endTime / 1000;
                retChapter.duration = chapter.clipFrom - retChapter.clipFrom + chapter.duration;
                pushReturnedChapter(retChapter, 1, chapter);
              }
            } else {
              // connect to the prev chapter
              retPrevChapter.duration = (chapter.clipFrom || 0) + chapter.duration - (retPrevChapter.clipFrom || 0);
              connected = true;
            }
          } else if (chapter.clipFrom) {
            if (prevWord) {
              // if prev word - expand untill prevWord
              retChapter.clipFrom = prevWord.endTime / 1000;
              retChapter.duration = chapter.clipFrom - retChapter.clipFrom + chapter.duration;
            } else {
              // else expand to beginning of the video
              retChapter.clipFrom = undefined;
              retChapter.duration = chapter.duration + (chapter.clipFrom || 0);
            }
            pushReturnedChapter(retChapter, 2, chapter);
          }

          // Next
          const activeChapter = connected ? retPrevChapter : retChapter;
          const clipFrom = activeChapter.clipFrom || 0;
          if (nextChapter) {
            if (
              nextWord &&
              nextWord.startTime / 1000 <= clipFrom + nextChapter.duration &&
              !isChapterActiveWord(nextChapter, nextWord)
            ) {
              // if word is included in nextChapter - connection will happen on the next chapter iteration
              // else expand to word startTime - 1
              activeChapter.duration = nextWord.startTime / 1000 - clipFrom - 0.001;
            }
          } else if (chapter.duration !== sourceDuration) {
            if (nextWord) {
              // if next word - expand till next word
              activeChapter.duration = nextWord.startTime / 1000 - clipFrom - 0.001;
            } else {
              // else expand till the end of the video
              activeChapter.duration = sourceDuration - clipFrom;
            }
          }

          pushReturnedChapter(activeChapter, 3, chapter);
        });
    });

    if (!check) {
      tempScenesMap.array.forEach((chapter) => {
        const modifiedChapter = retChaptersArray.find((c) => c.sid === chapter.sid);
        if (!modifiedChapter) {
          chapter.duration = null;
          chapter.index = null;
          retChaptersArray.push(chapter);
        }
      });
      setTempScenes({ scenes: retChaptersArray });
    }

    return restoringAvailable;
  }

  function detectRestorebaleBlanks(
    prevWord: Word | undefined,
    prevChapter: ChapterOrder | undefined,
    nextWord: Word | undefined,
    nextChapter: ChapterOrder | undefined,
    chapter: ChapterOrder | undefined
  ) {
    const clipFrom = (chapter.clipFrom || 0) * 1000;
    const clipTo = clipFrom + chapter.duration * 1000;

    // previous word exist and in previous chapter and can be connected
    if (prevWord && prevChapter && isChapterActiveWord(prevChapter, prevWord)) {
      return true;
    }
    // pervious word exist and there is threshold between prev and clipFrom
    if (prevWord && prevWord.endTime - clipTo > BLANK_THRESHOLD) {
      return true;
    }
    // previous word and previous chapter both not found and clipFrom > blank threshold
    if (!prevWord && !prevChapter && clipFrom > BLANK_THRESHOLD) {
      return true;
    }

    // next word exist but is not active in next chapter / next chapter not found and the gap between clipTo and next word > threshold
    if (
      ((nextWord && !nextChapter) || (nextWord && nextChapter && !isChapterActiveWord(nextChapter, nextWord))) &&
      nextWord.startTime - clipTo * 1000 > BLANK_THRESHOLD
    ) {
      return true;
    }
    // next chapter and next word both are not found, and the gap between clipFrom + duration to source duration > threshold
    if (
      !nextChapter &&
      !nextWord &&
      (chapter.srcDuration || chapter.sourceDuration) * 1000 - clipTo > BLANK_THRESHOLD
    ) {
      return true;
    }
  }

  const experimentalKeepBlankWhenCutWord = user?.UIFLAGS.EXPERIMENTAL_KEEP_BLANK_WHEN_CUT_WORD;

  const localChaptersChanges = tempScenesState.use((tempScenes) => tempScenes.cacheVersion);
  const blankAvailable = React.useMemo(() => blankAll(true), [localChaptersChanges, captions, checkAllDeleted]);
  const blankRestoreAvailable = React.useMemo(
    () => revertBlanks(true),
    [localChaptersChanges, captions, blankAvailable]
  );

  React.useEffect(() => {
    const tempCheck = filteredChapters.find((item) => item.duration && item.duration > 0.001);
    checkAllDeletedState.set(!tempCheck);
  }, [filteredChapters]);

  return (
    <div className="editor">
      <div className="editor--container">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: 30 }}>
          {checkAllDeleted ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="revert-all-tooltip" className="tooltip-peech-light">
                  Undo
                </Tooltip>
              }
            >
              <div
                className="revert-all"
                onClick={() => {
                  trackEvent('content-editor-blank-spaces', { action: 'restore-all' });
                  revertBlanks();
                }}
                disabled={!blankRestoreAvailable}
              >
                <SVG name="undo" />
              </div>
            </OverlayTrigger>
          ) : blankRestoreAvailable ? (
            <Button
              id="blank-all-btn"
              variant={ButtonVariant.Tertiary}
              size={ButtonSize.Small}
              onClick={() => {
                trackEvent('content-editor-blank-spaces', { action: 'restore-all' });
                revertBlanks();
              }}
              disabled={!blankRestoreAvailable}
            >
              <SVG name="delete" />
              <span>Revert Blanks</span>
            </Button>
          ) : blankAvailable || blankRestoreAvailable ? (
            <Button
              id="blank-all-btn"
              variant={ButtonVariant.Tertiary}
              size={ButtonSize.Small}
              onClick={() => {
                trackEvent('content-editor-blank-spaces', { action: 'blank-all' });
                blankAll();
              }}
              disabled={!blankAvailable}
            >
              <SVG name="delete" />
              <span>All Blanks</span>
            </Button>
          ) : (
            <div />
          )}

          {!!user?.UIFLAGS.TRIMMER && !hideTrimButton && (
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingInline: 2,
                transform: 'translateY(-40px)',
                fontWeight: 400,
                color: '#999',
                borderColor: 'currentcolor',
              }}
              onClick={async () => {
                const temp = tempScenesState.get();
                const current = scenesState.get();
                await sequence.preReorderScenes(temp, current);
                window.location.assign(`${config.NEW_UI_URL}/project/${sequence.sid}/content-editor`);
              }}
              variant={ButtonVariant.Tertiary}
              size={ButtonSize.Small}
            >
              <SVG name="scissors" width={24} height={24} />
              <span>Trimmer</span>
            </Button>
          )}
        </div>

        <div className="editor--content">
          <div className="content--wrapper" ref={contentWrapperRef}>
            <SceneEditor
              draggable={draggable}
              order={order}
              toggleDraggable={toggleDraggable}
              hideDeletedWords={hideDeletedWords}
              shouldUpdate={true}
              contentWrapperRef={contentWrapperRef}
              scrollVersion={scrollVersion}
              experimentalKeepBlankWhenCutWord={experimentalKeepBlankWhenCutWord}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
