import React from 'react';
import { Item, Menu, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import SpeakerCuePoint from '../../../client/speakerCuePoint';
import TitleCuePoint from '../../../client/titleCuePoint';
import { useAnimation } from '../../../hooks/useAnimation';
import { AppContext } from '../../../lib/app-context';
import { incrementCuePointsVersion, setCuePoint, setCuePointOnTime } from '../../../state/cuePoints';
import { handleError } from '../../../state/error';
import { MENUS, menuState } from '../../../state/menu';
import { currentTimeState, seekPlayback } from '../../../state/playback';
import { scenesState, sequenceState } from '../../../state/sequence';
import { trackEvent } from '../../../utils/analityics.utils';
import { getDefaultTextPlaceHolder } from '../../../utils/lottie-animation.utils';
import { fitSizeToText } from '../../cue-points/editableTextItem';
import { getAnimationProps } from '../../cue-points/graphic';
import SVG from '../../svg';
import AddElementButton from '../AddElementButton';
import { random } from '../context-menu';
import './MonitorMenu.scss';

const BRIGHTNESS_THRESHOLD = 200;
const DEFAULT_SPEAKER_DURATION = 6000;
const DEFAULT_GRAPHIC_TEXT_DURATION = 6000;
const CORNERS = {
  TOP_RIGHT: {
    top: 7,
    right: 5,
  },
  BOTTOM_LEFT: {
    bottom: 21,
    left: 5,
  },
  TOP_LEFT: {
    top: 21,
    left: 5,
  },
  BOTTOM_RIGHT: {
    right: 5,
    bottom: 21,
  },
};
const TRIGGERS = {
    MONITOR_MENU: 'monitor-menu',
    VISUALS_MENU: 'visuals-menu',
    SPEAKERS_MENU: 'speakers-menu'
}

export default function MonitorMenuToggle({ trigger }) {
  const { show, hideAll } = useContextMenu({ id: trigger });
  const monitorMenuToggleRef = React.useRef<HTMLDivElement>();
  const visualsMenuToggleRef = React.useRef<HTMLDivElement>();
  const [disabledAddButton, setDisabledAddButton] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState(false);

  function onClickMunuMonitor(event) {
    trackEvent('monitor-menu-button-click');
    show(event, {
      props: {},
      position: {
        x: monitorMenuToggleRef.current.getBoundingClientRect().x + monitorMenuToggleRef.current.offsetWidth * 0.8 - 38,
        y: monitorMenuToggleRef.current.getBoundingClientRect().y + monitorMenuToggleRef.current.offsetHeight,
      },
    });
  }

  function onClickMenuVisuals(event) {
    trackEvent('visuals-menu-add-button-click');
    show(event, {
      props: {},
      position: {
        x:
          visualsMenuToggleRef.current.getBoundingClientRect().x + visualsMenuToggleRef.current.offsetWidth * 0.8 - 175,
        y: visualsMenuToggleRef.current.getBoundingClientRect().y + visualsMenuToggleRef.current.offsetHeight,
      },
    });
  }

  function onClickMenuSpeakers(event) {
    trackEvent('visuals-menu-add-button-click');
    show(event, {
      props: {},
    });
    setButtonClicked(true);
  }

  return trigger === TRIGGERS.MONITOR_MENU ? (
    <div ref={monitorMenuToggleRef} className="add-context-menu" style={{ position: 'absolute', right: -1, top: -50 }}>
      <button onClick={onClickMunuMonitor} disabled={disabledAddButton} className={'add-button'}>
        <SVG name={disabledAddButton ? 'plus-disabled' : 'plus'} style={{ height: '36px', width: '36px' }} />
      </button>
      <MonitorMenu setDisabledAddButton={setDisabledAddButton} trigger={trigger} />
    </div>
  ) : trigger === TRIGGERS.VISUALS_MENU ? (
    <div ref={visualsMenuToggleRef} className="add-context-menu">
      <AddElementButton text="Add visual element" onClick={onClickMenuVisuals} disabled={disabledAddButton} />
      <MonitorMenu setDisabledAddButton={setDisabledAddButton} trigger={trigger} />
    </div>
  ) : trigger === TRIGGERS.SPEAKERS_MENU ? (
    <div ref={visualsMenuToggleRef} className="add-context-menu">
      <AddElementButton text="Add speaker title" onClick={onClickMenuSpeakers} disabled={disabledAddButton} />
      <MonitorMenu
        setDisabledAddButton={setDisabledAddButton}
        trigger={trigger}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
      />
    </div>
  ) : null;
}

export function MonitorMenu({
  setDisabledAddButton = () => {},
  trigger,
  buttonClicked = false,
  setButtonClicked = (val: boolean) => {},
}) {
  const { user, config } = React.useContext(AppContext);
  const sequence = sequenceState.use();
  const colors = sequenceState.use((sequence) => sequence.colors);
  const scenes = scenesState.use((scenes) => scenes.map);
  const textAnimation = useAnimation(sequence.style?.textAssetSid, sequence.aspectRatio);
  const speakerAnimation = useAnimation(sequence.style?.speakerAssetSid, sequence.aspectRatio);
  const slideAnimation = useAnimation(sequence.style?.slideAssetSid, sequence.aspectRatio);
  const currentTime = currentTimeState.use();

  React.useEffect(() => {
    if (trigger === TRIGGERS.SPEAKERS_MENU && buttonClicked) {
      createSpeakerTitle();
      setButtonClicked(false);
    }
  }, [trigger, buttonClicked]);

  function getChapterLimits(chapterSid) {
    const chapter = scenes[chapterSid],
      clipFrom = chapter?.clipFrom || 0,
      clipTo = clipFrom + chapter?.duration;

    return chapter
      ? {
          minStartTime: 0,
          maxStartTime: Math.floor(chapter.srcDuration * 1000),
          maxEndTime: Math.floor(clipTo * 1000),
        }
      : {};
  }

  const playingChapterSid = (): string =>
    scenes.array
      .filter((scene) => scene.offset <= currentTime)
      .sort((a, b) => b.offset - a.offset)
      .shift().sid;
  
  const getCuePointTime = (): number => {
    const chapter = scenes.array
      .filter((scene) => scene.offset <= currentTime)
      .sort((a, b) => b.offset - a.offset)
      .shift();
    return currentTime - (chapter.offset - (chapter.clipFrom || 0));
  };

  async function createSpeakerTitle() {
    const chapterSid = playingChapterSid();
    const chapter = scenes[chapterSid];

    if (!speakerAnimation || !chapter) {
      return;
    }

    setDisabledAddButton(true);


    const startTime = Math.floor(getCuePointTime() * 1000);
    const createCuePoint = new SpeakerCuePoint(sequence.sid);
    createCuePoint.values.status = SpeakerCuePoint.STATUS.ACTIVE;
    createCuePoint.type = SpeakerCuePoint.TYPE.SPEAKER;
    createCuePoint.srcStartTime = startTime;
    createCuePoint.srcEndTime = startTime + DEFAULT_SPEAKER_DURATION;
    createCuePoint.startTime = startTime;
    createCuePoint.endTime = startTime + DEFAULT_SPEAKER_DURATION;

    createCuePoint.left = CORNERS.BOTTOM_LEFT.left;
    createCuePoint.bottom = CORNERS.BOTTOM_LEFT.bottom;

    createCuePoint.height = 18;

    const colorProperties = getAnimationProps(speakerAnimation);
    createCuePoint.colors = colorProperties;

    try {
      menuState.set(MENUS.SPEAKERS);
      await createCuePoint.save();
      trackEvent('speaker-added', { trigger: 'context-menu' });
      const calced = setCuePoint(createCuePoint, sequence, scenes);
      const activeAnimationMidTime =
        speakerAnimation?.data && (speakerAnimation.data.op / speakerAnimation.data.fr) * 500;
      const activeTime = calced.times["_"];
      setCuePointOnTime(calced, true);
      seekPlayback((activeTime.start + (activeAnimationMidTime || 1000)) / 1000).then(() => {
        setDisabledAddButton(false);
      });
    } catch (err) {
      console.error(err);
      handleError({
        title: 'New Speaker Title',
        message: err.message,
        responseError: err,
      });
      setDisabledAddButton(false);
    }
  }

  async function createGraphicText({ type }) {
    const chapterSid = playingChapterSid();
    const chapter = scenes[chapterSid];

    if ((type === 'slide' && !slideAnimation) || (type === 'text' && !textAnimation) || !chapter) {
      return;
    }
    setDisabledAddButton(true);

    const { maxEndTime } = getChapterLimits(chapterSid);

    const startTime = Math.max(0, Math.floor(getCuePointTime() * 1000));
    const endTime =
      maxEndTime < 0
        ? startTime + DEFAULT_GRAPHIC_TEXT_DURATION
        : Math.min(maxEndTime, startTime + DEFAULT_GRAPHIC_TEXT_DURATION);

    const createCuePoint = new TitleCuePoint(sequence.sid);
    createCuePoint.values.status = TitleCuePoint.STATUS.ACTIVE;
    createCuePoint.chapterSid = chapterSid;
    createCuePoint.type = TitleCuePoint.TYPE.TITLE;

    createCuePoint.srcStartTime = startTime;
    createCuePoint.srcEndTime = endTime;
    createCuePoint.startTime = startTime;
    createCuePoint.endTime = endTime;

    createCuePoint.left = CORNERS.TOP_LEFT.left;
    createCuePoint.top = CORNERS.TOP_LEFT.top;

    const ratio = textAnimation.height / textAnimation.width;
    createCuePoint.height = 18;
    // createCuePoint.width = parseInt(createCuePoint.height / ratio);

    const colorIndex = random(1, 3);
    const color = colors.find((c) => c.dominancy === colorIndex);
    const languageCode = sequence.languageCode || 'en-US';
    var potentialErrMessage = 'New Text ';
    if (type === 'slide') {
      potentialErrMessage = potentialErrMessage + 'Slide';
      createCuePoint.titleType = TitleCuePoint.TITLE_TYPE.SLIDE;
      createCuePoint.left = 0;
      createCuePoint.top = 0;
      createCuePoint.width = 100;
      createCuePoint.height = 100;
      createCuePoint.title = getDefaultTextPlaceHolder(languageCode, 'Enter Title');
      createCuePoint.text = getDefaultTextPlaceHolder(languageCode, 'Enter Text');
      const colorProperties = getAnimationProps(slideAnimation);
      createCuePoint.colors = colorProperties;
    } else {
      potentialErrMessage = potentialErrMessage + 'Graphic';
      createCuePoint.text = getDefaultTextPlaceHolder(languageCode, 'Text here');
      if (textAnimation) {
        createCuePoint.height = 18;
        // await createCuePoint.calcSizeByLottie(textAnimation)
      } else {
        createCuePoint.fontSize = 8;
        let { width, height } = fitSizeToText(createCuePoint.fontSize, createCuePoint.text);
        createCuePoint.width = width;
        createCuePoint.height = height;
      }
      createCuePoint.left = 5;
      createCuePoint.top = 21;
      createCuePoint.color = color.brightness > BRIGHTNESS_THRESHOLD ? '#000000' : '#ffffff';
      createCuePoint.backgroundColorIndex = colorIndex;
    }

    try {
      await createCuePoint.save();
      const calced = setCuePoint(createCuePoint, sequence, scenes);
      const activeAnimation = type === 'text' ? textAnimation : slideAnimation;
      const activeAnimationMidTime = activeAnimation?.data && (activeAnimation.data.op / activeAnimation.data.fr) * 500;
      const activeTime = calced.times[calced.chapterSid || calced.sequenceSid];
      setCuePointOnTime(calced, true);
      seekPlayback((activeTime.start + (activeAnimationMidTime || 1000)) / 1000).then(() => {
        setDisabledAddButton(false);
      });
      menuState.set(MENUS.VISUALS);
      incrementCuePointsVersion()
    } catch (err) {
      console.error(err);
      handleError({
        title: potentialErrMessage,
        message: err.message,
        responseError: err,
      });
      setDisabledAddButton(false);
    }
  }


  return (
    <>
      {(trigger === TRIGGERS.MONITOR_MENU || trigger === TRIGGERS.VISUALS_MENU) && (
        <Menu className="monitor-actions-menu" data-cy="monitor-menu" id={trigger}>
          {trigger === TRIGGERS.MONITOR_MENU && (
            <Item
              data={{ type: 'speaker' }}
              onClick={({ data, props }) => createSpeakerTitle({ ...data, ...props })}
            >
              <SVG name="speakers-menu" title="Speaker Title" />
              <span className="speaker-title">Speaker Title</span>
            </Item>
          )}
          <Item
            data={{ type: 'text' }}
            onClick={({ data, props }) => {
              trackEvent('visuals-added', { type: 'graphic-text', trigger: 'subtitle-contex-menu' });

              createGraphicText({ ...data, ...props });
            }}
          >
            <SVG name="visuals-menu" title="Graphic Text" />
            <span className="speaker-title">Graphic Text</span>
          </Item>
          <Item
            data={{ type: 'slide' }}
            onClick={({ data, props }) => {
              trackEvent('visuals-added', { type: 'text-slide', trigger: 'subtitle-contex-menu' });

              createGraphicText({ ...data, ...props });
            }}
          >
            <SVG name="slide" title="Graphic Text" />
            <span className="speaker-title">Text Slide</span>
          </Item>
        </Menu>
      )}
    </>
  );
}
