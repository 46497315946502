import React from 'react'
import SVG from '../../../components/svg'

import { Word } from '../../../client/closedCaption'
import VisibleWordElement from './VisibleWordElement';
import { tempScenesState } from '../../../state/sequence';
import Button, { ButtonSize, ButtonVariant } from '../../../ui-components/Button';
import { ChapterOrder } from '../../../client/sequence/chapter';
interface WordElementProps {
    selectedWords: Word[];
    activeWords: Word[];
    word: Word;
    handleDeletion(e: MouseEvent): void;
    resetSelection(): void;
    shouldShowBlank: boolean;
    shouldShowBlankBefore: boolean;
    className: string;
    onMouseEnter?(word: Word): void;
    onClick?(word: Word): void;
    splitQuietSpace(e: MouseEvent, i: number, chapterSid?: string): void;
    acceptTextChange(newText: string): void;
    index: number;
    chapterSid: string;
    deleted?: boolean;
    isBlurable: React.MutableRefObject<boolean>;
    contentWrapperRef: React.RefObject<HTMLDivElement>;
}

export default function WordElement({ selectedWords, activeWords, word, handleDeletion, resetSelection, shouldShowBlank, shouldShowBlankBefore, className, onMouseEnter, onClick, splitQuietSpace, acceptTextChange, index, deleted, isBlurable, contentWrapperRef, scrollVersion, ...rest }: WordElementProps) {

    // needed for build time

    const tempScenes = tempScenesState.use(scenes => scenes.map)
    const positionRef = React.useRef<HTMLSpanElement>()


    const [inViewPort, setInViewPort] = React.useState<boolean>()

    React.useEffect(() => {
        setInViewPort(checkViewPort)
        // if (true) { // debug message
        //     console.log({ inViewPort, wordBottom: wordBottom(), wordTop: wordTop(), viewPortTop: viewPortTop(), viewPortBottom: viewPortBottom(), viewPortHeight: viewPortHeight() })
        // }
    }, [scrollVersion])


    const viewPortHeight = () => contentWrapperRef?.current?.getBoundingClientRect().height
    const viewPortTop = () => contentWrapperRef?.current?.getBoundingClientRect().top
    const viewPortBottom = () => contentWrapperRef?.current?.getBoundingClientRect().bottom

    const wordTop = () => positionRef.current?.getBoundingClientRect().top
    const wordBottom = () => positionRef.current?.getBoundingClientRect().bottom

    const checkViewPort = () => wordBottom() - viewPortTop() > -viewPortHeight() && viewPortBottom() - wordTop() > -viewPortHeight()
    const checkForOnTimeScroll = () => wordBottom() > viewPortBottom() || viewPortTop() > wordTop()

    // onTime sync
    React.useEffect(() => className && className.includes('on-time') && checkForOnTimeScroll() && positionRef.current?.scrollIntoView(), [className])

    if (!word) {
        return null;
    }

    const attrs = {}
    !inViewPort && (attrs['data-cy'] = "word-element")
    word.flags && (attrs['data-keyword'] = "true")

    return <span ref={positionRef} starttime={word.startTime} {...attrs}>
        {!inViewPort && shouldShowBlank && <span>
            <Button
                className="blank-space-btn"
                variant={ButtonVariant.Tertiary}
                size={ButtonSize.ExtraSmall}>
                <SVG name="delete" />
                Blank
            </Button> &nbsp;
        </span>}
        {!inViewPort ? word.word + (activeWords?.at(index + 1) && activeWords.at(index + 1) === selectedWords?.at(0) ? '' : ' ') : <VisibleWordElement
            className={className}
            selectedWords={selectedWords}
            word={word}
            activeWords={activeWords}
            handleDeletion={handleDeletion}
            resetSelection={resetSelection}
            onMouseEnter={onMouseEnter}
            acceptTextChange={acceptTextChange}
            splitQuietSpace={splitQuietSpace}
            index={index}
            deleted={deleted}
            isBlurable={isBlurable}
            shouldShowBlank={shouldShowBlank}
            shouldShowBlankBefore={shouldShowBlankBefore}
            contentWrapperRef={contentWrapperRef}
            scrollVersion={scrollVersion}
            {...rest}
        />}
        {!inViewPort && shouldShowBlank && <span>
            <Button
                className="blank-space-btn"
                variant={ButtonVariant.Tertiary}
                size={ButtonSize.ExtraSmall}>
                <SVG name="delete" />
                Blank
            </Button> &nbsp;
        </span>}
    </span>

}