import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { appConfig } from '../../lib/app-context';
import { User } from './user';
import {isInIframe} from "../../utils/window.utils";
import { entity } from 'simpler-state'

export const regenerateButtonIsDisabledState = entity(false);

declare global {
  interface Window {
    _ps: string;
  }
}

class APIClientError extends Error {}

export interface IBaseProperties {
  [key: string]: any;
  sid?: boolean;
  version?: boolean;
  insertTime?: boolean;
  updateTime?: boolean;
}

export interface IResponseData {
  [key: string]: any;
}

export interface IAnyBase {
  [key: string]: any;
}

export interface IBase extends IAnyBase {
  clientVersion?: number;
  objectType?: string;
  sid?: string | null;
  version?: number;
  insertTime?: number;
  updateTime?: number;

  wrap?: (type: string, action: string) => string;
  unwrap?: (data: IResponseData) => IBase | IResponseData | null;
  valuesCahnged?: () => boolean;
  changedValues?: () => string;
}

class IUnion {
  [key: string]: IBaseProperties;
}

class Union {
  constructor(values: IUnion) {
    Object.assign(this, values);
  }
  toProperties(): string {
    return `${Object.entries(this)
      .map(([key, value]) => {
        return `... on ${key}{ ${Base.toProperties(value)} }`;
      })
      .join(' ')}`;
  }
}

export interface IBaseType<IType extends IBase> {
  new (sid?: string): Base<IType>;
  className: string;
}

export interface Pager {
  [key: string]: number | undefined;
  pageIndex?: number;
  pageSize?: number;
}

export interface IBaseFilter {
  [key: string]: any;
  statusEqual?: number;
  statusNotEqual?: number;
  statusIn?: number[];
  statusNotIn?: number[];
  insertTimeGreaterThan?: number;
  insertTimeLessThan?: number;
  updateTimeGreaterThan?: number;
  updateTimeLessThan?: number;
}

type FSaveCuePoint = (
  cuePoint: IBase,
  sequence: IBase | null,
  scenes: IBase[] | null,
  incrementVersion: boolean,
  properties: IBaseProperties | null,
  shouldUpdateLocally: boolean
) => Promise<IBase>;

interface IAPIQueueItem {
  timeOut?: any;
  cuePoint: IBase;
  saveFunc: FSaveCuePoint;
}

interface IAPIQueue {
  [key: string]: IAPIQueueItem;
}

type ErrorHandler = (err: Error) => void;

export enum RequestErrorCode {
  HTTP_STATUS_CODE = -1,
  SERVER_NOT_REACHABLE = -2,
}

class RequestError extends Error {
  code: number;
  response: AxiosResponse;

  constructor(message: string, code: number, response: AxiosResponse) {
    super(message);

    this.code = code;
    this.response = response;
  }
}

const API_DEBOUNCE_TIMEOUT = 5000;
const _ = undefined;

export default class Base<IType extends IBase, ITypeProperties extends IBaseProperties = IBaseProperties>
  implements IBase
{
  [key: string]: any;
  static className = 'Base';
  static Union: typeof Union = Union;

  ['constructor']?: typeof Base;
  static NAME: string;
  static PKS: string[];

  static _endpoint: string;
  static _debug: boolean;

  static errHandler: ErrorHandler | null;
  static requestHeaders: AxiosRequestHeaders = {};
  static saveState = {
    IDLE: 0,
    LOADING: 1,
    SUCCESS: 2,
    FAILURE: 3,
  };
  static saving = Base.saveState.IDLE;

  static APIQueue: IAPIQueue = {};

  static trackChanges(cuePoint: IBase, modifiedProperties: string[], saveFunc: FSaveCuePoint) {
    Base.saving = Base.saveState.LOADING;
    const sid: string = cuePoint.sid as string;
    const cuePointSlot = this.APIQueue[sid];
    if (cuePointSlot) {
      clearTimeout(cuePointSlot.timeOut);
    } else {
      this.APIQueue[sid] = {
        cuePoint,
        saveFunc,
      };
    }
    this.APIQueue[sid].timeOut = setTimeout(() => {
      this.APIQueue[sid].saveFunc(cuePoint, null, null, false, null, false);

      delete this.APIQueue[sid];
    }, API_DEBOUNCE_TIMEOUT);
  }

  static holdAPIQueue() {
    Object.values(this.APIQueue).map((pendingRequest) => {
      clearTimeout(pendingRequest?.timeOut);
    });
  }

  static removeFromAPIQueue(cuePointSid: string) {
    clearTimeout(this.APIQueue?.[cuePointSid]?.timeOut);
    delete this.APIQueue[cuePointSid];
  }

  static executeAPIQueue(): Promise<any> {
    return Promise.all(
      Object.values(this.APIQueue).map((pendingRequest) => {
        clearTimeout(pendingRequest?.timeOut);
        const sid: string = pendingRequest?.cuePoint?.sid as string;
        const returnPromise = this.APIQueue[sid]?.saveFunc(pendingRequest?.cuePoint, null, null, false, null, false);
        delete this.APIQueue[sid];
        return returnPromise;
      })
    );
  }

  static async request(query: string) {
    const isMutation = query.startsWith('mutation');
    if (isMutation) {
      Base.saving = Base.saveState.LOADING;
      try {
        // after editing captions: switch off autoSync 
        if (query.includes('updateWords')) {
          isInIframe() && window.top?.postMessage('UPDATE_WORDS', '*');
          // enable regenerate button so user can revert to transcript words
          regenerateButtonIsDisabledState.set(false);
        }
      } catch (err) {
        console.log('an error occurred while trying to send "UPDATE_WORDS" postMessage')
      }
    }

    if (Base.debug) {
      console.log('Query:', query);
    }

    try {
      const response = await axios.post(
        this.endpoint,
        { query },
        {
          withCredentials: false,
          headers: this.headers,
        }
      );

      if (response.status !== 200) {
        const err = new RequestError(response.statusText, response.status, response);
        throw err;
      }
      if (response.data.errors?.length) {
        const { message, code } = response.data.errors[0];
        throw new RequestError(message, code, response);
      }
      if (isMutation) {
        Base.saving = Base.saveState.SUCCESS;
      }
      return response.data.data;
    } catch (err: any) {
      if (isMutation) {
        Base.saving = Base.saveState.FAILURE;
      }
      if (this.errHandler && err.code) {
        return this.errHandler(err);
      }
      if (err.response) {
        console.error({
          query,
          response: err.response.data,
          requestId: err.response.headers?.['x-requestid'],
        });

        const status = err.response.status;
        if (status === 404) {
          throw new RequestError(
            'Server not accessible, please try again in few seconds',
            RequestErrorCode.SERVER_NOT_REACHABLE,
            err.response
          );
        }
        if (status === 401) {
          throw new RequestError(err.response.message, status, err.response);
        }
      } else {
        console.error(err);
      }
      throw err;
    }
  }

  static addErrorHandler(handler: ErrorHandler | null) {
    this.errHandler = handler;
  }

  static get endpoint(): string {
    if (!this._endpoint) {
      this._endpoint = appConfig.API_URL as string;
    }
    return this._endpoint;
  }

  static set endpoint(value: string) {
    this._endpoint = value;
  }

  static get ps(): string | null {
    if (typeof window === "undefined") {
      return null
    }
    if (window._ps) {
      return window._ps;
    }
    if (document) {
      var decodedCookie = decodeURIComponent(document.cookie);
      var cookies = decodedCookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.startsWith('ps=')) {
          window._ps = cookie.substr(3);
          return window._ps;
        }
      }
    }
    return null;
  }

  static set ps(value: string | null) {
    window._ps = value as string;
    if (document) {
      if (value) {
        document.cookie = `ps=${value}; path=/`;
      } else {
        document.cookie = `ps=; path=/`;
      }
    }
  }

  static setAuthorizationHeader() {
    if (this.ps) {
      this.setHeader('Authorization', `Bearer ${this.ps}`);
    }
  }

  static setHeader(name: string, value: string) {
    this.requestHeaders[name] = value;
  }

  static unsetHeader(name: string) {
    delete this.requestHeaders[name];
  }

  static get headers(): AxiosRequestHeaders {
    const ps = this.ps;
    if (ps) {
      return {
        ...this.requestHeaders,
        Authorization: `Bearer ${ps}`,
        'Content-Type': 'application/json',
      };
    }
    return this.requestHeaders;
  }

  /**
   * @returns {boolean}
   */
  static get debug() {
    return this._debug;
  }
  /**
   * @param {boolean} value
   */
  static set debug(value) {
    this._debug = value;
  }

  clientVersion: number = 0;
  values: IType = {} as IType;
  originalValues: IAnyBase = {};
  changedProperties: Array<string> = [];
  _name?: string;
  _parent?: IBase;

  constructor(sid: string | undefined = undefined) {
    this._sid = sid;
  }

  static toProperties(properties: IBaseProperties): string {
    return Object.entries(properties)
      .map(([key, value]) => {
        if (value === true) {
          return key;
        } else if (value instanceof Union) {
          return `${key}{${value.toProperties()}}`;
        } else {
          return `${key}{${Base.toProperties(value as IBaseProperties)}}`;
        }
      })
      .join(' ');
  }

  toJSON() {
    return JSON.parse(JSON.stringify(this.values));
  }

  set(values: IType, parent?: IBase | null, name?: string, ignoreModified: boolean = false): IType {
    this.clientVersion++;
    this._name = name || this._name;

    if (values instanceof Base) {
      const err = new Error('Base.set invalid values');
      console.error(err, { stack: err.stack, values, parent, name, ignoreModified });
      values = values.values;
    }

    if (parent) {
      parent.clientVersion = (parent.clientVersion || 0) + 1;
      this._parent = parent;
    }
    for (var key in values) {
      if (values[key] !== null && (!ignoreModified || !this.changedProperties.includes(key))) {
        this.values[key] = values[key];
      }
    }
    this.changedProperties = [];

    return this as unknown as IType;
  }

  static asType<IType extends IBase>(
    values: IType,
    type: IBaseType<IType> | IBaseType<IType>[],
    parent: IBase,
    name: string
  ) {
    if (Array.isArray(type)) {
      type = type.find((t) => t.className === values.objectType) as IBaseType<IType>;
    }
    var ret = new type();
    return ret.set(values, parent, name);
  }

  wrap(type: string, action: string) {
    if (this._parent?.wrap) {
      return this._parent.wrap(
        type,
        `
                ${this._name} {
                    ${action}
                }`
      );
    } else {
      let constructor = this.constructor as typeof Base;
      return `${type} {
                ${this._name ? this._name : constructor.NAME}(${this.pks()}){
                    ${action}
                }
            }`;
    }
  }

  unwrap(data: IResponseData): IType | IResponseData | null {
    if (this._parent?.unwrap) {
      data = this._parent.unwrap(data) as IResponseData;
    }
    if (!data) {
      return null;
    }
    if (data[this._name as string]) {
      return data[this._name as string];
    }
    let constructor = this.constructor as typeof Base;
    if (data[constructor.NAME]) {
      return data[constructor.NAME];
    }
    return null;
  }

  propertyChanged(property: string, value?: any) {
    if (!(property in this.originalValues)) {
      this.originalValues[property] = this.values[property];
    }
    if (this.values[property] !== value && !this.changedProperties.includes(property)) {
      this.changedProperties.push(property);
    }
  }

  static PROPERTIES = {
    objectType: true,
    sid: true,
    version: true,
    insertTime: true,
    updateTime: true,
  };

  get sid(): string | null | undefined {
    return this.values.sid;
  }

  set _sid(value: string | null | undefined) {
    this.values.sid = value;
  }

  get version(): number | undefined {
    return this.values.version;
  }

  get insertTime(): number | undefined {
    return this.values.insertTime;
  }

  get updateTime(): number | undefined {
    return this.values.updateTime;
  }

  pks(omitSid?: boolean): string {
    let constructor = this.constructor as typeof Base;
    return constructor.PKS.filter((pk: string) => this[pk] !== undefined && (!omitSid || pk !== 'sid'))
      .map((pk) => `${pk}: "${this[pk]}"`)
      .join(', ');
  }

  static newInstanceHandler: ((data: any) => IBase) | null = null;
  static getNew(data: any) {
    return (this.newInstanceHandler && this.newInstanceHandler(data)) || new this(data.sid);
  }

  static async get<IType extends IBase>(
    sid: string | null = null,
    properties: IBaseProperties | null = null
  ): Promise<IType | null> {
    properties = properties || this.PROPERTIES;
    const query = `query {
            ${this.NAME}(sid: ${JSON.stringify(sid)}) {${Base.toProperties(properties)}}
        }`;
    var data = await Base.request(query);
    if (!data[this.NAME]) {
      return null;
    }
    var ret = this.getNew(data);
    return ret.set(data[this.NAME], null, this.NAME) as IType;
  }

  static async count<IFilter extends IBaseFilter>(filter: IFilter | null = null): Promise<number> {
    let filterStr = 'null';
    if (filter) {
      filterStr = `{${Object.keys(filter).map((k) => `${k}: ${JSON.stringify(filter[k])}`)}}`;
    }
    const query = `query {
            ${this.NAME}{
                count(filter: ${filterStr}) 
            }
        }`;
    var data = await Base.request(query);
    return data[this.NAME].count;
  }

  static async list<IFilter extends IBaseFilter, IType extends IBase>(
    filter: IFilter | null,
    pager: Pager | null,
    properties: IBaseProperties | null = null
  ): Promise<IType[]> {
    properties = properties || this.PROPERTIES;
    let filterStr = 'null';
    let pagerStr = 'null';
    if (filter) {
      filterStr = `{${Object.keys(filter).map((k) => `${k}: ${JSON.stringify(filter[k])}`)}}`;
    }
    if (pager) {
      pagerStr = `{${Object.keys(pager).map((k) => `${k}: ${JSON.stringify(pager[k])}`)}}`;
    }
    const query = `query {
            ${this.NAME}{
                list(filter: ${filterStr}, pager: ${pagerStr}) {
					sid ${Base.toProperties(properties)}
				}
            }
        }`;
    var data = await Base.request(query);
    if (!data[this.NAME] || !data[this.NAME].list) {
      return [];
    }
    return data[this.NAME].list.map((item: any) => {
      var ret = this.getNew(item);
      return ret.set(item, null, this.NAME);
    });
  }

  async remove(): Promise<boolean | null> {
    let constructor = this.constructor as typeof Base;
    const name = constructor.NAME;
    const pks = constructor.PKS.length > 1 ? `(${this.pks(true)})` : '';
    const query = `mutation {
            ${name}${pks} {
                remove (sid: "${this.sid}")
            }
        }`;
    const data = await Base.request(query);
    if (!data[name]) {
      return null;
    }
    return data[name];
  }

  valuesCahnged(): boolean {
    if (this.changedProperties.length) {
      return true;
    }
    for (let property in this.values) {
      if (this.values[property] !== undefined) {
        if (this.values[property as string] instanceof Base && (this.values[property] as IBase).valuesCahnged?.()) {
          return true;
        }
        if (Array.isArray(this.values[property])) {
          for (var item of <any>this.values[property]) {
            if (item instanceof Base && item.valuesCahnged()) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  changedValues(): string {
    appConfig.ENV_NAME !== 'default' && console.log('changedValues', this);
    const values = [];
    for (let property in this.values) {
      if (this.values[property] !== undefined) {
        if (this.values[property as string] instanceof Base && (this.values[property] as IBase).valuesCahnged?.()) {
          values.push(`${property}: {${(this.values[property] as IBase).changedValues?.()}}`);
        } else if (Array.isArray(this.values[property])) {
          const arr: Array<IBase | any> = <any>this.values[property];
          const isEmptied = !arr.length && this.changedProperties.includes(property);
          const changed =
            isEmptied ||
            arr.filter((p) => (p instanceof Base ? p.valuesCahnged() : this.changedProperties.includes(property) && p))
              .length;
          if (changed) {
            appConfig.ENV_NAME !== 'default' &&
              console.log('changedValues mapping', { value: this.values[property], key: property, this: this });
            values.push(`${property}: [${arr.map((p) => (p instanceof Base ? `{${p.changedValues()}}` : `"${p}"`))}]`);
          }
        } else if (this.changedProperties.includes(property)) {
          values.push(`${property}: ${JSON.stringify(this.values[property])} `);
        }
      }
    }
    return values.join(' ');
  }

  async add(properties: ITypeProperties | null = null): Promise<IType | null> {
    let constructor = this.constructor as typeof Base;
    properties = (properties || constructor.PROPERTIES) as ITypeProperties;
    const name = constructor.NAME;
    const pks = constructor.PKS.length > 1 ? `(${this.pks(true)})` : '';
    const values = this.changedValues();
    const query = `mutation {
            ${name}${pks} {
							add(
								values: { ${values} }
                ) {sid ${Base.toProperties(properties as ITypeProperties)} }
					}
				} `;
    var data = await Base.request(query);
    if (!data[name] || !data[name].add) {
      return null;
    }
    return this.set(data[name].add, null, name);
  }

  async update(properties: ITypeProperties | null = null): Promise<IType | null> {
    let constructor = this.constructor as typeof Base;
    properties = (properties || constructor.PROPERTIES) as ITypeProperties;
    const name = constructor.NAME;
    const pks = constructor.PKS.length > 1 ? `(${this.pks(true)})` : '';
    const values = this.changedValues();
    const query = `mutation {
            ${name}${pks} {
                update (
                    sid: "${this.sid}",
                    values: { ${values} }
                ) {${Base.toProperties(properties as ITypeProperties)}}
            }
        }`;

    const data = await Base.request(query);
    if (!data[name] || !data[name].update) {
      return null;
    }

    this.originalValues = {};
    return this.set(data[name].update, null, name);
  }

  save(properties: ITypeProperties | null = null): Promise<IType | null> {
    if (this.sid) {
      if (!this.valuesCahnged()) {
        return Promise.resolve(this as unknown as IType);
      }
      
      try {
        return this.update(properties);
      } catch (err) {
        let constructor = this.constructor as typeof Base;
        this.set(this.originalValues as IType, null, constructor.NAME);
        this.originalValues = {};
        throw err;
      }
    } else {
      return this.add(properties);
    }
  }
}
