import classNames from 'classnames';
import React from 'react';

import { AppContext } from '../../../lib/app-context';
import { applyAnimationColorsNames, color2string, getAnimationProps } from '../../cue-points/graphic';

import {
	calcCuePointTimes,
	cuePointsState,
	editCuePointState,
	isActive,
	onCuePointChange,
	setCuePoint
} from '../../../state/cuePoints';
import { handleError } from '../../../state/error';
import { scenesState, sequenceSave, sequenceState } from '../../../state/sequence';
import { reloadSpeakers, saveSpeaker, speakersLoadedState, speakersState } from '../../../state/speakers';

import GraphicColor from '../../../client/graphicColor';
import Speaker from '../../../client/speaker';
import SpeakerCuePoint from '../../../client/speakerCuePoint';

import ImageWithPlaceholder from '../../../ui-components/ImageWithPlaceholder/ImageWithPlaceholder';
import Menu from '../../../ui-components/Menu/Menu';
import CuePointPropertiesItem from '../cue-point';

import { useAnimation } from '../../../hooks/useAnimation';
import { trackEvent } from '../../../utils/analityics.utils';
import { validateTimeValue } from '../../../utils/time-utils';
import { TimeFormat } from '../../common/time-code/time-code.component';
import { MenuItemColorPicker } from '../cuePoint-palette';
import MonitorMenu from '../monitor-menu';
import './Speakers.scss';
import useUserPs from '../../../hooks/useUserPs';
import VisualsMenuColorsComponent from '../VisualsMenuColorsComponent';
import useVisualsMenuColorsChange from '../../../hooks/useVisualsMenuColorsChange';
import { visualPackageReplaceState } from '../../../dialogs/assets';
import { AssetType } from '../../../client/asset';

const COLOR_HEIGHT = 65;
const DEFAULT_SPEAKER_THUMB = '/images/empty-avatar.png';

export function findSequenceColor(color) {
  const sequence = sequenceState.get();
  const sequenceColors = sequence.colors;

  if (!color || color.type === undefined || color.colorIndex === undefined || !sequenceColors?.length) {
    return null;
  }

  const colorByIndexFuncMap = {
    [GraphicColor.TYPE.DOMINANCY]: (dominancyIndex) => sequenceColors.find((c) => c.dominancy === dominancyIndex),
    [GraphicColor.TYPE.BRIGHTNESS]: (brightnessIndex) =>
      sequenceColors.find((c) => c.brightnessIndex === brightnessIndex),
  };
  return colorByIndexFuncMap[color.type](parseInt(color.colorIndex));
}

/**
 * @param {object} props
 * @param {SpeakerCuePoint} props.cuePoint
 */
function SpeakerVisual({ cuePoint }) {
  /**
   * @type {Speaker[]}
   */
  const speakers = speakersState.use();
  const visualPackageReplaceStateValue = visualPackageReplaceState.use();
  const scenes = scenesState.use((scenes) => scenes.map);
  const sequence = sequenceState.use();
  const useSpeakers = sequenceState.use((sequence) => sequence.editRules?.useSpeakers);
  const speakerAnimation = useAnimation(cuePoint?.assetSid || sequence?.style?.speakerAssetSid);
  const { config } = React.useContext(AppContext);
  const autoCompleteRef = React.useRef();
  const activeTime = cuePoint.chapterSid
    ? cuePoint.times[cuePoint.chapterSid]
    : cuePoint.activeTimes?.length && cuePoint.activeTimes[0];
  const [showColorPicker, setShowColorPicker] = React.useState(false);

  var color;
  const nameStyle = {};
  if (cuePoint.nameColor) {
    nameStyle.color = cuePoint.nameColor;
  } else if (cuePoint.nameColorIndex !== undefined) {
    color = sequence.colors.find((c) => c.brightnessIndex === cuePoint.nameColorIndex);
    nameStyle.color = color?.color;
  }
  if (cuePoint.nameBackgroundColor) {
    nameStyle.backgroundColor = cuePoint.nameBackgroundColor;
  } else if (cuePoint.nameBackgroundColorIndex !== undefined) {
    color = sequence.colors.find((c) => c.brightnessIndex === cuePoint.nameBackgroundColorIndex);
    nameStyle.backgroundColor = color?.color;
  }
  if (!nameStyle.color) {
    nameStyle.color = 'black';
  }
  if (!nameStyle.backgroundColor) {
    nameStyle.backgroundColor = 'white';
  }

  const titleStyle = {};
  if (cuePoint.titleColor) {
    titleStyle.color = cuePoint.titleColor;
  } else if (cuePoint.titleColorIndex) {
    color = sequence.colors.find((c) => c.brightnessIndex === cuePoint.titleColorIndex);
    titleStyle.color = color?.color;
  }
  if (cuePoint.titleBackgroundColor) {
    titleStyle.backgroundColor = cuePoint.titleBackgroundColor;
  } else if (cuePoint.titleBackgroundColorIndex) {
    color = sequence.colors.find((c) => c.brightnessIndex === cuePoint.titleBackgroundColorIndex);
    titleStyle.backgroundColor = color.color;
  }
  if (!titleStyle.color) {
    titleStyle.color = 'white';
  }
  if (!titleStyle.backgroundColor) {
    titleStyle.backgroundColor = 'black';
  }

  function onKeyPress(e) {
    if (e.which === 13) {
      e.preventDefault();
      return e.target.blur();
    }
  }

  async function onNameBlur(e) {
    if (cuePoint.name !== e.target.innerText) {
      cuePoint.name = e.target.innerText;
      await saveSpeaker(cuePoint, sequence, scenes);
      trackEvent('speaker-edit', { action: 'name' });
    }
  }

  async function onTitleBlur({ target }) {
    var text = target.innerText?.trim();
    if (text !== cuePoint.title) {
      cuePoint.title = text;
      await saveSpeaker(cuePoint, sequence, scenes);
      trackEvent('speaker-edit', { action: 'title' });
    } else {
      target.innerText = cuePoint.title || '';
    }
  }

  function resetEditingState() {
    setShowColorPicker(false);
  }

  async function onHide() {
    trackEvent('speaker-show-hide', { action: 'hide' });
    try {
      await cuePoint.deactivate();
      resetEditingState();
    } catch (err) {
      handleError({
        title: 'Hide speaker title',
        message: err.message,
        responseError: err,
      });
    }
  }

  async function onShow() {
    trackEvent('speaker-show-hide', { action: 'show' });
    try {
      await cuePoint.activate();
    } catch (err) {
      handleError({
        title: 'Hide speaker title',
        message: err.message,
        responseError: err,
      });
    }
  }

  const onVisibilityToggle = () => (isActive(cuePoint) ? onHide() : onShow());

  async function onStartChange(time, minTime, maxTime) {
    cuePoint.startTime = Math.ceil(validateTimeValue(time, minTime, maxTime) - cuePoint.offset);
    try {
      trackEvent('speaker-edit', { action: 'time' });
      await cuePoint.save();
      const calced = calcCuePointTimes(cuePoint, sequence, scenes);
      setCuePoint(calced, sequence, scenes);
    } catch (err) {
      handleError({
        statusCode: 3,
        responseError: err,
      });
    }
  }

  async function onEndChange(time, minTime, maxTime) {
    cuePoint.endTime = Math.floor(validateTimeValue(time, minTime, maxTime) - cuePoint.offset);
    try {
      trackEvent('speaker-edit', { action: 'time' });
      await cuePoint.save();
      const calced = calcCuePointTimes(cuePoint, sequence, scenes);
      setCuePoint(calced, sequence, scenes);
    } catch (err) {
      handleError({
        statusCode: 3,
        responseError: err,
      });
    }
  }

  const isReplaceable = React.useMemo(() => {
    return visualPackageReplaceStateValue[AssetType.LOTTIE_SPEAKER];
  }, [visualPackageReplaceStateValue, cuePoint.titleType]);

  function onReplace() {
    const { cuePoint: activeCuePoint } = editCuePointState.get();
    if (activeCuePoint?.sid === cuePoint?.sid) {
      editCuePointState.set({});
      return;
    }
    editCuePointState.set({ cuePoint, defaultType: 'speaker' });
  }

  const withUserPs = useUserPs();

  const src = withUserPs(`${config.CHUNKS_URL}/c/${sequence.sid}/${cuePoint.sid}/${cuePoint.thumbnailVersion}.png`);

  function setRef(el) {
    autoCompleteRef.current = el;
  }

  const extractedColors = (speakerAnimation && getAnimationProps(speakerAnimation)) || [];
  const cuePointColors = applyAnimationColorsNames(extractedColors, cuePoint.colors);
  const titleColor = cuePointColors?.find((color) => color.name === 'Title'),
    nameColor = cuePointColors?.find((color) => color.name === 'Name'),
    restColors = cuePointColors?.filter((color) => color.name !== 'Name' && color.name !== 'Title');

  const colorPickerMaxHeight = showColorPicker ? cuePointColors?.length * COLOR_HEIGHT : 0;

  const activeAnimation = speakerAnimation;
  const activeAnimationMidTime = activeAnimation?.data && (activeAnimation.data.op / activeAnimation.data.fr) * 500;
  const onColorsChange = useVisualsMenuColorsChange();

  React.useEffect(() => {
    if (!useSpeakers) {
      setShowColorPicker(false);
    }
  }, [useSpeakers]);

   return (
    <CuePointPropertiesItem
      type="speaker"
      cuePoint={cuePoint}
      onStartChange={onStartChange}
      onEndChange={onEndChange}
      seekCalculator={(animation) => activeTime.start + (activeAnimationMidTime || 1000)}
      bordered={true}
      enabled={useSpeakers}
      // onDoubleClick={useSpeakers ? onReplace : undefined}
      onReplace={isReplaceable && onReplace}
      visibilityToggle={true}
      onVisibilityToggle={onVisibilityToggle}
      timerToggle={true}
      timeFormat={TimeFormat.MMSSmmm}
      data-cy="visuals-menu-item"
      className="speaker"
    >
      <div className="speaker-visual sequence-font">
        <div className="face">
          <ImageWithPlaceholder alt={cuePoint.name} source={src} placeholderSrc={DEFAULT_SPEAKER_THUMB} />
        </div>
        <div className={`speaker-visual--fields-wrapper ${sequence.textDirectionByLanguage}`}>
          <div className="text">
            {/* NAME INPUT */}
            <div className="text-name">
              <div className="field title-input-wrapper">
                <div
                  dangerouslySetInnerHTML={{ __html: cuePoint.name || '' }}
                  contentEditable={true}
                  className="title-input"
                  placeholder="Add name here"
                  onKeyPress={onKeyPress}
                  onBlur={onNameBlur}
                  data-cy="visuals-name-input"
                />
              </div>
            </div>
            {/* TITLE INPUT */}
            <div className="text-title">
              <div className="field title-input-wrapper">
                <div
                  dangerouslySetInnerHTML={{ __html: cuePoint.title || '' }}
                  contentEditable={true}
                  className="title-input"
                  placeholder="Add title here"
                  onKeyPress={onKeyPress}
                  onBlur={onTitleBlur}
                  data-cy="visuals-title-input"
                />
              </div>
            </div>
          </div>
        </div>
        {/* COLORS */}
      </div>
      <div style={{ marginTop: '10px' }}>
        {!![nameColor , titleColor, ...(restColors || [])].filter((el) => el)?.length && (
          <VisualsMenuColorsComponent
            colorsObjects={[nameColor, titleColor, ...(restColors || [])]}
            onColorChange={(value, colorObj, type) =>
              onColorsChange(value, colorObj, type, cuePoint, extractedColors, 'speaker-edit')
            }
            type='speaker'
          />
        )}
      </div>
    </CuePointPropertiesItem>
  );
}

export default function SpeakersProperties() {
  const sequence = sequenceState.use();
  const [enabled, setEnabled] = React.useState(sequence.editRules?.useSpeakers);

  const cuePointsData = cuePointsState.use();
  const cuePoints = Object.values(cuePointsData)
    .filter((cuePoint) => cuePoint && cuePoint instanceof SpeakerCuePoint)
    .sort((a, b) => a.order - b.order);
  const speakerCuePoints = React.useMemo(() => cuePoints, [cuePoints.length]);

  const speakersLoaded = speakersLoadedState.use();

  const { user } = React.useContext(AppContext);

  React.useEffect(async () => {
    if (speakersLoaded) {
      return;
    }
    reloadSpeakers();
  }, [speakersLoaded]);

  React.useEffect(() => {
    return () => editCuePointState.set({});
  }, []);

  async function onEnabledChange() {
    trackEvent('speaker-show-hide-all', { action: enabled ? 'hide-all-speakers' : 'show-all-speakers' });
    sequence.editRules.useSpeakers = !enabled;
    setEnabled(sequence.editRules?.useSpeakers);
    await sequenceSave(sequence);
  }

	const actions = [];


  return (
    <Menu
      type="speakers"
      header="Speaker Titles"
      className="speakers"
      enabled={enabled}
      onChange={onEnabledChange}
      customIcon={{ show: 'show', hide: 'hide' }}
      actions={actions}
    >
      <div style={{ position: 'relative', width: '100%' }}>
        <MonitorMenu trigger="speakers-menu" />
      </div>

      {speakerCuePoints.map((cuePoint) => {
        return <SpeakerVisual key={cuePoint.sid} cuePoint={cuePoint} />;
      })}
    </Menu>
  );
}
