import styled from '@emotion/styled/macro';

export const BaseTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #000;
`;

export const RoundedRectangleOption = styled.div<{ isSelected: boolean }>`
  display: flex;
  padding: 6px;
  width: 46px;
  height: 32px;
  border: 1px solid ${(props) => (props.isSelected ? '#666666' : 'transparent')};
  border-radius: 8px;
  background-color: #f2f2f2;
  transition: border-color 250ms ease-out;
  cursor: pointer;
`;
