
import * as Base from '../base/sequence/music';

export const MusicSourceStatus = Base.MusicSourceStatus;

export interface IMusic extends Base.IMusic {

}

export interface IMusicProperties extends Base.IMusicProperties {

}

export interface IMusicFilter extends Base.IMusicFilter {

}

export default class Music<IType extends IMusic = IMusic, ITypeProperties extends IMusicProperties = IMusicProperties> extends Base.Music<IType, ITypeProperties> implements IMusic {
}