import classNames from 'classnames';
import React from 'react'
import './Tabs.scss'


type TabsItems = {
    name: string;
    value: number;
    ref: any;
}

interface TabsProps {
    tabsItems: TabsItems[];
    onChange: Function;
    triple?: boolean;
    forceActiveTabIndex?: number;
}

export default function Tabs({ tabsItems, onChange, triple, forceActiveTabIndex = -1 }: TabsProps) {

    const tabsWrapperRef = React.useRef();

    const [activeTab, setActiveTab] = React.useState(tabsItems[0].value);
    const [activePos, setActivePos] = React.useState({});

    React.useEffect(() => {
        onChange && onChange(activeTab)
    }, [activeTab])

    React.useEffect(() => {
        if (forceActiveTabIndex > -1 && forceActiveTabIndex < tabsItems.length) {
            setActiveTab(tabsItems[forceActiveTabIndex].value);
        }
    }, [forceActiveTabIndex, tabsItems])

    React.useEffect(() => {
        if (!tabsItems[0].ref.current || !tabsItems[1]?.ref?.current || !tabsWrapperRef.current) {
            return
        }
        const { left, right } = tabsWrapperRef.current.getBoundingClientRect()
        let activeLeft, activeRight

        if (activeTab === tabsItems[0].value) {
            const { left, right } = tabsItems[0].ref.current.getBoundingClientRect()
            activeLeft = left
            activeRight = right
        } else if (activeTab === tabsItems[1]?.value) {
            const { left, right } = tabsItems[1]?.ref?.current.getBoundingClientRect()
            activeLeft = left
            activeRight = right
        } else if (activeTab === tabsItems[2]?.value) {
            const { left, right } = tabsItems[2]?.ref?.current.getBoundingClientRect()
            activeLeft = left
            activeRight = right

        }
        setActivePos({
            left: activeLeft - left,
            right: right - activeRight

        })

    }, [activeTab, tabsItems[1]?.ref?.current])



    return (
        <div className="tabs-group-header">
            <div className={classNames("tabs-group-tabs", { 'triple': triple })} ref={tabsWrapperRef}>
                {tabsItems.map(item =>
                    <button
                        key={item.name}
                        className={activeTab === item.value ? 'active' : ''}
                        onClick={() => onChange && setActiveTab(item.value)}
                        ref={item.ref}
                        data-cy={item.name.replace(" ", "-").toLocaleLowerCase()}
                    >
                        {item.name}
                    </button>
                )}
                <div className='active-indicator' style={{ left: activePos?.left, right: activePos?.right }} />
            </div>
        </div>







    )
}
