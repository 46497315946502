import React, { useContext } from 'react';
import { AppContext } from '../lib/app-context';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { sequenceState } from '../state/sequence';
import { openPeechProUpgradeModal } from './layout';
import { FeatureFeatureName } from '../client/base/feature';
import CustomLanguageSelector, {
  LangSelectorColors,
} from '../ui-components/CustomLanguageSelector/CustomLanguageSelector';
import { DEFAULT_LANGUAGE_CODE } from '../ui-components/SequenceCloneOptions/onSequenceClone';
import { useCreateTranslatedSequence } from '../hooks/useCreateTranslatedSequence';

const ActionButton = styled.a`
  display: flex;
  color: #fff;
  text-decoration: none;
  height: 28px;
  padding: 18px 10px 18px 10px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  background: #1f9ffb;
  cursor: pointer;
  align-items: center;
  width: 120px;
  justify-content: space-around;
  margin-top: 8px;
  &:hover {
    color: #fff !important;
    background: #7ec7fb;
  }
  &:active {
    color: #fff !important;
    background: #1671b2;
  }
  svg {
    width: 24px;
    padding: 0;
    fill: #ea429d;
  }
  svg[name='translate'] {
    margin-top: 10px;
  }
  svg[name='diamond-gradient'] {
    margin-left: 4px !important;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SelectorWrapper = styled(FlexRow)`
  width: 100%;
  justify-content: space-around;
  flex-direction: row;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

const SpanLabel = styled.span`
  color: #999999;
  font-size: 12px;
  margin-right: 12px;
  width: 100px;

  @media (max-width: 425px) {
    margin-bottom: 10px;
  }
`;

const SelectionDiv = styled.div`
  width: 240px;
  height: 30px;

  @media (max-width: 425px) {
    width: 240px;
  }
`;

export const SequenceTranslateSelect = () => {
  const [tempLang, setTempLang] = React.useState(null);
  const { plan, status, user, reachProjectLimit, config } = useContext(AppContext);
  const sequence = sequenceState.use();
  const planFeature = FeatureFeatureName.TRANSLATE_SEQUENCE;
  const translateAllowed = !!plan?.planAllowedTierFeatures?.includes(planFeature);

  const supportedLanguages = React.useMemo(() => {
    if (!config || !sequence) {
      return;
    }
    const sequenceLangCode = sequence?.languageCode || DEFAULT_LANGUAGE_CODE;
    return config?.TRANSLATE_LANGUAGES?.filter((x) => x.languageCode !== sequenceLangCode);
  }, [config, sequence]);

  const onTranslateCreateClick = useCreateTranslatedSequence(
    'project-download-modal',
    sequence,
    tempLang,
    setTempLang,
    () => navigate('/')
  );

  const onTranslateClick = () => {
    if (reachProjectLimit) {
      openPeechProUpgradeModal(user, 'Translate react project limit', 'PROJECTS_LIMIT_REACHED');
      return;
    }
    if (translateAllowed) {
      onTranslateCreateClick();
      return;
    }
    openPeechProUpgradeModal(user, 'Translate', FeatureFeatureName.TRANSLATE_SEQUENCE);
  };
  return (
    <>
      <FlexRow>
        <SelectorWrapper>
          <SpanLabel>Translate to: </SpanLabel>
          <SelectionDiv>
            <CustomLanguageSelector
              sid={sequence?.sid}
              lang={sequence?.languageCode}
              disabled={false}
              supportedLanguages={supportedLanguages}
              setTempLang={setTempLang}
              placeholder="Choose language"
              selectorColor={LangSelectorColors.GRAY}
              isSearchable={true}
              showFlag={true}
            />
          </SelectionDiv>
        </SelectorWrapper>
      </FlexRow>
      <ActionButton onClick={() => onTranslateClick()}>Create!</ActionButton>
    </>
  );
};
