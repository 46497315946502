
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'

export enum FontAssetUsageUsageType {
	BRIGHT = 1,
	DARK = 2,
	HEADER_FONT_FAMILY = 101,
	BODY_FONT_FAMILY = 102,
};

export interface IFontAssetUsage extends IBase {
	assetSid?: string | null;
	type?: number | null;
	usageType?: number | null;
	fileNames?: string[] | null;
	regularFileName?: string | null;
	boldFileName?: string | null;
	isDefault?: boolean | null;
	italic?: string | null;
	italicBold?: string | null;
	normal?: string | null;
	bold?: string | null;
}

export interface IFontAssetUsageProperties extends IBaseProperties {
	assetSid?: boolean;
	type?: boolean;
	usageType?: boolean;
	fileNames?: boolean;
	regularFileName?: boolean;
	boldFileName?: boolean;
	isDefault?: boolean;
	italic?: boolean;
	italicBold?: boolean;
	normal?: boolean;
	bold?: boolean;
}

export interface IFontAssetUsageFilter extends IBaseFilter {
}

export class FontAssetUsage<IType extends IFontAssetUsage = IFontAssetUsage, ITypeProperties extends IFontAssetUsageProperties = IFontAssetUsageProperties> extends Base<IType, ITypeProperties> implements IFontAssetUsage {
	
	static className = 'FontAssetUsage';
	static NAME = 'fontAssetUsage';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		assetSid: true,
		type: true,
		usageType: true,
		fileNames: true,
		regularFileName: true,
		boldFileName: true,
		isDefault: true,
		italic: true,
		italicBold: true,
		normal: true,
		bold: true,
	};
	
	/**
	 * @deprecated use FontAssetUsageUsageType instead
	 */
	static USAGE_TYPE: any = {
		BRIGHT: FontAssetUsageUsageType.BRIGHT,
		DARK: FontAssetUsageUsageType.DARK,
		HEADER_FONT_FAMILY: FontAssetUsageUsageType.HEADER_FONT_FAMILY,
		BODY_FONT_FAMILY: FontAssetUsageUsageType.BODY_FONT_FAMILY,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get assetSid(): string | null | undefined {
		return this.values.assetSid
	}
			
	set assetSid(value: string | null | undefined) {
		if(this.values.assetSid !== value) {
			this.propertyChanged('assetSid', value);
			this.values.assetSid = value;
		}
	}
			
	get type(): number | null | undefined {
		return this.values.type
	}
			
	get usageType(): number | null | undefined {
		return this.values.usageType
	}
			
	set usageType(value: number | null | undefined) {
		if(this.values.usageType !== value) {
			this.propertyChanged('usageType', value);
			this.values.usageType = value;
		}
	}
			
	get fileNames(): string[] | null | undefined {
		return this.values.fileNames
	}
			
	set fileNames(value: string[] | null | undefined) {
		if(this.values.fileNames !== value) {
			this.propertyChanged('fileNames', value);
			this.values.fileNames = value;
		}
	}
			
	get regularFileName(): string | null | undefined {
		return this.values.regularFileName
	}
			
	set regularFileName(value: string | null | undefined) {
		if(this.values.regularFileName !== value) {
			this.propertyChanged('regularFileName', value);
			this.values.regularFileName = value;
		}
	}
			
	get boldFileName(): string | null | undefined {
		return this.values.boldFileName
	}
			
	set boldFileName(value: string | null | undefined) {
		if(this.values.boldFileName !== value) {
			this.propertyChanged('boldFileName', value);
			this.values.boldFileName = value;
		}
	}
			
	get isDefault(): boolean | null | undefined {
		return this.values.isDefault
	}
			
	set isDefault(value: boolean | null | undefined) {
		if(this.values.isDefault !== value) {
			this.propertyChanged('isDefault', value);
			this.values.isDefault = value;
		}
	}
			
	get italic(): string | null | undefined {
		return this.values.italic
	}
			
	set italic(value: string | null | undefined) {
		if(this.values.italic !== value) {
			this.propertyChanged('italic', value);
			this.values.italic = value;
		}
	}
			
	get italicBold(): string | null | undefined {
		return this.values.italicBold
	}
			
	set italicBold(value: string | null | undefined) {
		if(this.values.italicBold !== value) {
			this.propertyChanged('italicBold', value);
			this.values.italicBold = value;
		}
	}
			
	get normal(): string | null | undefined {
		return this.values.normal
	}
			
	set normal(value: string | null | undefined) {
		if(this.values.normal !== value) {
			this.propertyChanged('normal', value);
			this.values.normal = value;
		}
	}
			
	get bold(): string | null | undefined {
		return this.values.bold
	}
			
	set bold(value: string | null | undefined) {
		if(this.values.bold !== value) {
			this.propertyChanged('bold', value);
			this.values.bold = value;
		}
	}
			
	
	static get<IType extends IFontAssetUsage = IFontAssetUsage>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IFontAssetUsageFilter = IFontAssetUsageFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IFontAssetUsageFilter = IFontAssetUsageFilter, IType extends IFontAssetUsage = IFontAssetUsage>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
