import React from 'react'

import { Helmet } from 'react-helmet'
import { cuePointsState, cuePointsVersionState, mediaState, setLoadedMedia } from '../../state/cuePoints';

import CuePoint from '../../client/cuePoint'
import TitleCuePoint from '../../client/titleCuePoint';
import { AppContext } from '../../lib/app-context';
import useUserPs from '../../hooks/useUserPs';

export default function Preloader() {
    const { config } = React.useContext(AppContext);
    const cuePointsData = cuePointsState.use();
    const cuePoints = Object.values(cuePointsData);
    const cuePointsVersion = cuePointsVersionState.use()
    const media = mediaState.use();
    const withUserPs = useUserPs();

    React.useEffect(() => {
        cuePoints
            .filter(cuePoint => (cuePoint.titleType === TitleCuePoint.TITLE_TYPE.VIDEO))
            .forEach(cuePoint => {
                const key = `${cuePoint.sid}_${cuePoint.contentVersion}`
                if ((media as any)[key] === undefined) {
                    // //////////////////////
                    // TODO: Do this block correct?
                    // const src = cuePoint.videoUrl || `${config.CHUNKS_URL}/cc/${cuePoint.sequenceSid}/TitleCuePoint/${cuePoint.sid}/800/${cuePoint.contentVersion}.mp4`;
                    // setLoadedMedia(null, key)
                    // setLoadedMedia(src, key)
                     // //////////////////////
                }
            })
    }, [cuePointsVersion])

    return <>
        <Helmet>
            {
                cuePoints
                    .filter(cuePoint => !!cuePoint)
                    .map(cuePoint => {
                        const { type, assetSid, chapterSid, sequenceSid, sid, thumbnailVersion, fullSize, fullSizeUrl, tmpImageUrl, thumbUrl, videoUrl } = cuePoint
                        switch (type) {
                            case CuePoint.TYPE.BACKGROUND:
                                const url = tmpImageUrl || thumbUrl || (fullSize
                                    ? fullSizeUrl
                                    : withUserPs(`${config.CHUNKS_URL}/tc/${sequenceSid}/BackgroundImage/${chapterSid}-${sid}/${800}/${thumbnailVersion}.png`)
                                );
                                return <link key={cuePoint.sid} rel="preload" as="image" href={url} />
                            case CuePoint.TYPE.TITLE:
                                const src = withUserPs(`${config.CHUNKS_URL}/cc/${sequenceSid}/TitleCuePoint/${sid}/${800}/${thumbnailVersion}.png`)
                                return <link key={`menu-${cuePoint.sid}`} rel="preload" as="image" href={src} />
                            default:
                                if (cuePoint.assetSid) { // TODO: Is this block required?
                                    const src = withUserPs(`${config.CHUNKS_URL}/t/asset/b/${assetSid}/${sid}.png`);
                                    return <link key={cuePoint.sid} rel="preload" as="image" href={src} />
                                }
                                // Do nothing with this cue point type.
                        }
                    })
            }
            {
                cuePoints
                    .filter(cuePoint => !!cuePoint)
                    .map(cuePoint => {
                        const { sequenceSid, sid, thumbnailVersion, type } = cuePoint
                        switch (type) {
                            case CuePoint.TYPE.TITLE:
                                const src = withUserPs(`${config.CHUNKS_URL}/cc/${sequenceSid}/TitleCuePoint/${sid}/${90}/${thumbnailVersion}.png`)
                                return <link key={`preview-${cuePoint.sid}`} rel="preload" as="image" href={src} />
                            default:
                                // Do nothing with this cue point type.
                        }
                    })
            }
        </Helmet>
    </>
}