import React from 'react';
import moment from 'moment';

import { scenesState, sequenceState, tempScenesState } from '../state/sequence';
import {
  playbackIsLockedState,
  playbackState,
  seekPlayback,
  currentTimeState,
  buffersState,
  resetPlayUntil,
  resetSeekAndPlayUntil,
  setAudioPlayerSource,
  clearPlaybackShouldPause,
  resetActiveClosedCaption,
  setIsPlayerPlaying,
} from '../state/playback';

import Renderer from './renderer';

import SVG from './svg';
import DashPlayer, { AudioStream, Player } from './dash-player';
import ControlsBar from './controls-bar';
import MonitorMenu from './menu/monitor-menu';

import {
  cuePointsState,
  cuePointsVersionState,
  editCuePointState,
  resetCuePointOnTime,
  recalcCuePointsTimes,
  setCuePointOnTime,
  isTemp,
  previewActiveCuePointsState,
  tempCuePointsVersionState, activeEditCuePointState,
} from '../state/cuePoints';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import Loader from '../ui-components/Loader';
import { fileUploaderState } from '../state/fileUploader';

import '../../css/components/main-player.scss';
import classNames from 'classnames';

import CuePoint, {CuePointType} from '../client/cuePoint';
import { firstDraftState } from './draftModal/DraftModal';

import { AppContext } from '../lib/app-context';
import Sequence from '../client/sequence';
import { entity } from 'simpler-state';
import { trackEvent } from '../utils/analityics.utils';
import { ZINDEX } from '../../constants/zIndex.const';
import useUserPs from '../hooks/useUserPs';
import VideoToCanvasService from "../utils/VideoToCanvasService";
import {getSequenceCropDetails, arrangeCropData} from "../utils/crop.utils";
import { set } from 'lodash';
import {pick} from "lodash/fp";
import queryString from "query-string";
import {isInIframe} from "../utils/window.utils";

export const mainPlayerStyleState = entity({
  zIndex: ZINDEX.VIDEO,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export function resetMainPlayerStyle() {
  mainPlayerStyleState.set({
    zIndex: ZINDEX.VIDEO,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  });
}

function calcPercent(time, duration) {
  return time && duration ? (time / duration) * 100 : 0;
}

function timeToObject(time) {
  var obj = {
    millis: Math.round(time) % 1000,
  };
  time = Math.floor(time / 1000);
  obj.seconds = time % 60;
  time = Math.floor(time / 60);
  obj.minutes = time % 60;
  time = Math.floor(time / 60);
  obj.hours = time % 60;
  return obj;
}

export function formatTime(time) {
  if (time === undefined) {
    return '';
  }
  if (typeof time === 'number') {
    time = timeToObject(time);
  }
  return (
    time &&
    `${time.hours.toString().padStart(2, '0')}:${time.minutes.toString().padStart(2, '0')}:${time.seconds
      .toString()
      .padStart(2, '0')}`
  );
}

function Controls({ player, duration, playing, playUntil, isPreview, disabled, isDraft }) {
  const buffers = buffersState.use();
  const currentTime = currentTimeState.use();
  const slide = React.createRef();
  const seeking = React.useRef(null);
  const notReadyScenes = scenesState.use((scenes) => scenes.filter.notReadyChapters).filter((c) => !c.resized);
  const tempScenes = tempScenesState.use((scenes) => scenes.filter.durableArray);
  const readyChapters = tempScenes.filter((c) => c.duration && (c.resized || c.linkSid));
  const playableScenes = scenesState.use((scenes) => scenes.filter.playable);
  const queryParams = typeof window !== `undefined` && queryString.parse(location.search);
  const [playFromState, setPlayFromState] = React.useState({
    offset: queryParams?.playbackOffset,
    chapterSid: queryParams?.playbackChapter,
  });
  
  const scenes = !isPreview
    ? playableScenes
    : !notReadyScenes.length
    ? tempScenes
    : readyChapters.length
    ? readyChapters
    : [tempScenes[0]];

  const [percent, setPercent] = React.useState(calcPercent(currentTime, duration));
  const tooltip = React.useRef();
  const [tooltipTime, setTooltipTime] = React.useState(calcPercent(currentTime));
  const sequence = sequenceState.use();

  // seek to the current time from query params
  React.useEffect(() => {
    const chapter = playableScenes.find((chapter) => chapter.values.sid === playFromState?.chapterSid);
    if (chapter?.values?.offset > -1 && playFromState?.offset > -1) {
        const time = Number(chapter.values.offset) + Number(playFromState.offset);
        seekTime(time);
        setPlayFromState(undefined);
    }
  }, [playableScenes, playFromState]);

  // when currentTime changes: update peech-web playFrom
  React.useEffect(() => {
    if (isDraft) return;
    const chapter = playableScenes.find((chapter) => currentTime > chapter?.values?.offset && currentTime < chapter?.values?.offset + chapter?.values?.duration);
    if (chapter?.values?.offset > -1 && chapter?.values?.sid) {
      isInIframe() && window.parent.postMessage({eventName: 'reactorCurrentTimeChanged', offset: currentTime - chapter.values.offset, chapterSid: chapter.values.sid }, '*');
    }
  }, [currentTime]);

  React.useEffect(() => {
    const stopSeek = () => (seeking.current = null);
    document.addEventListener('mouseup', stopSeek);
    return () => document.removeEventListener('mouseup', stopSeek);
  }, []);

  React.useEffect(() => {
    if (player) duration = player.duration;
  }, [scenes, scenes.length]);

  React.useEffect(() => {
    setPercent(calcPercent(currentTime, duration));

    if (!playUntil || currentTime <= playUntil) {
      return;
    }
    player.current && player.current.pause();
    resetPlayUntil();
  }, [currentTime, duration]);

  function seekTime(time) {
    setPercent(calcPercent(time, duration));
    seekPlayback(time);
    currentTimeState.set(time);
  }

  function seek(e, click = false) {
    if (duration === undefined) {
      return;
    }

    var { left, width } = seeking.current && !click ? seeking.current : slide.current.getBoundingClientRect();
    var offset = e.clientX - left;
    if (offset <= 0) {
      seekTime(0);
    } else if (offset >= width) {
      seekTime(duration);
    } else {
      seekTime((duration * offset) / width);
    }
  }

  function updateTooltip({ clientX }) {
    if (!tooltip.current || !slide.current) {
      return;
    }
    const { left, width } = slide.current.getBoundingClientRect();
    const offset = clientX - left;

    if (tooltip.current?.parentElement?.parentElement) {
      tooltip.current.parentElement.parentElement.style.left = offset + 'px';
    }
    setTooltipTime((offset <= 0 ? 0 : offset >= width ? duration : (duration * offset) / width) * 1000);
  }

  function startSeek(e) {
    if (!slide.current) {
      return;
    }
    var rect = slide.current.getBoundingClientRect();
    seeking.current = {
      left: rect.left,
      width: rect.width,
    };
    seek(e);
    document.addEventListener('mousemove', seek);
    document.addEventListener('mouseup', () => document.removeEventListener('mousemove', seek), { once: true });
  }

  function onPlay() {
    if (disabled) {
      return;
    }
    resetSeekAndPlayUntil();
    activeEditCuePointState.set(null);
    player.current && player.current.play();
    trackEvent('content-editor-preview-video', { action: 'play' });
  }

  function onPause() {
    player.current && player.current.pause();
  }

  return (
    <div className="controllers" id="video-controllers" data-cy="video-controllers">
      <table colSpan="0" rowSpan="0">
        <tbody>
          <tr>
            <td className="play">
              <SVG data-cy="play-video" name="play" style={{ display: playing ? 'none' : 'block' }} onClick={onPlay} />
              <SVG
                data-cy="pause-video"
                name="pause"
                style={{ display: playing ? 'block' : 'none' }}
                onClick={onPause}
              />
            </td>
            <td ref={slide} onMouseDown={startSeek} onMouseMove={updateTooltip} className="progress-seeker">
              <OverlayTrigger
                placement={'top-start'}
                overlay={
                  <Tooltip className="controllers-tooltip" arrowProps={{ show: true }}>
                    <div ref={tooltip}>{formatTime(tooltipTime)}</div>
                  </Tooltip>
                }
              >
                <div className="progress">
                  <ControlsBar totalDuration={duration} buffers={buffers} done={percent} />
                </div>
              </OverlayTrigger>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="time-text time">
        <span>{currentTime !== undefined && formatTime(currentTime * 1000)}</span>/
        <span>{duration && formatTime(duration * 1000)}</span>
      </div>
    </div>
  );
}

/**
 *
 * @param {object} props
 * @param {string|boolean} props.playbackUrl
 * @param {Function} [props.onError]
 * @param {Function} [props.onRetry]
 * @param {boolean} [props.isPreview]
 * @param {boolean} [props.unTrimmableChapter]
 * @param {Function} [props.onCanPlay]
 * @param {Function} [props.onDownload]
 * @param {string} props.contentThumbnail
 * @returns
 */

export default function MainPlayer({
  playbackUrl,
  onError,
  onRetry,
  disabled,
  isPreview,
  isDraft,
  unTrimmableChapter,
  onCanPlay,
  enableKeyboardControls,
  visible,
  contentThumbnail,
  sequenceCreator,
  showInfo,
  showPlayButton = true,
}) {
  const sequence = sequenceState.use();
  const scenes = scenesState.use((scenes) => scenes.map);
  const scenesVersion = scenesState.use((scenes) => scenes.cacheVersion);
  const chapters = scenesState.use((scenes) => scenes.filter.chapters);
  const cuePointsVersion = cuePointsVersionState.use();
  const { cuePoint } = editCuePointState.use();
  const { user, config } = React.useContext(AppContext);
  const useMusic = sequenceState.use((sequence) => sequence.music?.enabled);
  const useDenoised = sequenceState.use((sequence) => sequence.useDenoised);
  const [audioStream, setAudioStream] = React.useState(AudioStream.ORIGINAL);
  const [shouldRetry, setShouldRetry] = React.useState(false);
  const lockPlayer = playbackIsLockedState.use();
  const [canvasHW, setCanvasHW] = React.useState({W: 0, H: 0});
  const [sequenceCropDetails, setSequenceCropDetails] = React.useState(null);
  
  React.useEffect(() => {
    const useStream =
      useMusic && useDenoised
        ? AudioStream.DENOISED_WITH_MUSIC
        : useMusic
        ? AudioStream.WITH_MUSIC
        : useDenoised
        ? AudioStream.DENOISED
        : AudioStream.ORIGINAL;

    console.log('Stream changed', { useStream, useDenoised, useMusic });
    setAudioStream(useStream);
  }, [useMusic, useDenoised]);

  const previewCuePoints = previewActiveCuePointsState.use();

  const chaptersChanges =
    tempScenesState.use((tempScenes) =>
      tempScenes.filter.trimmableArray.find(
        (c) =>
          scenes[c.sid]?.index !== c.index ||
          scenes[c.sid]?.duration !== c.duration ||
          scenes[c.sid]?.clipFrom !== c.clipFrom
      )
    ) && !unTrimmableChapter;

  /**
   * @type {React.RefObject<HTMLVideoElement>}
   */
  const playerVideo = React.createRef();

  React.useEffect(() => {
    if (showInfo) {
      playerVideo.current && playerVideo.current.pause();
    }
  }, [showInfo]);

  const [isPlayerLoaded, setIsPlayerLoaded] = React.useState(false);
  const [shouldShowLoader, setShouldShowLoader] = React.useState(false);
  const [pausedDelayed, setPausedDelayed] = React.useState(true);

  const pausedDelayTimer = React.createRef(-1);
  /**
   * @type {React.RefObject<HTMLCanvasElement>}
   */
  const playerBackground = React.useRef();
  const video2Canvas = React.useRef(null);

  const [seeking, setSeeking] = React.useState(false);
  const [duration, setDuration] = React.useState();
  const [pauseTime, setPauseTime] = React.useState(0);
  const [currentTime, setCurrentTime] = React.useState(0);

  const {
    seek,
    playUntil,
    startTime,
    start,
    version: playbackVersion,
    shouldPause,
    isPlayerPlaying,
  } = playbackState.use();

  const timeout = React.useRef(null);

  const playbackInterval = React.useRef(null);

  function endInterval() {
    if (playbackInterval.current) {
      clearInterval(playbackInterval.current);
      playbackInterval.current = null;
    }
  }

  function startInterval(videoPlayer) {
    if (playbackInterval.current) {
      endInterval();
    }
    playbackInterval.current = setInterval(() => {
      videoPlayer.currentTime && currentTimeState.set(videoPlayer.currentTime);
    }, 100);
  }

  React.useEffect(() => {
    if (playbackUrl && timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }

    return () => {
      resetActiveClosedCaption();
      resetCuePointOnTime();
      resetMainPlayerStyle();
    };
  }, []);

  React.useEffect(() => {
    if (shouldRetry) {
      timeout.current = setTimeout(onRetry, 5000);
      setShouldRetry(false);
    }
  }, [shouldRetry]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  React.useEffect(() => {
    if (seek === null || seek === undefined || isNaN(seek)) {
      return;
    }

    const videoPlayer = playerVideo.current;

    if (!videoPlayer) {
      return;
    }

    //Called new paramater newSeek because seek is state varaible which is not mutatable
    let newSeek = seek;

    //Set seek one milisecond less if the seek is to the end of the video
    if (seek >= videoPlayer.duration) {
      newSeek = videoPlayer.duration - 0.001;
    }
    videoPlayer.currentTime = newSeek;

    start && videoPlayer.paused && videoPlayer.play();
  }, [playbackVersion]);

  React.useEffect(() => {
    setShouldShowLoader(true);
    setIsPlayerPlaying(false);
  }, [playbackUrl]);

  React.useEffect(() => {
    if (shouldPause) {
      console.log('Playback pause was requested - pausing playback if still playing');
      playerVideo.current && !playerVideo.current.paused && playerVideo.current.pause();
      clearPlaybackShouldPause();
    }
  }, [shouldPause]);

  const cues = React.useRef({});
  const metadataTrack = React.useRef();
  const cropDataTrack = React.useRef();

  /**
   *
   * @param {CuePoint[]} cuePoints
   * @returns
   */
  function addCuePoints(cuePoints) {
    if (!metadataTrack.current) {
      return;
    }
    resetCuePointOnTime();
    var track = metadataTrack.current;
    // Clear all textTrack list cuePoints
    // TODO: consider to forEach track.cues
    Object.values(cues.current).forEach((currentCuePoint) => {
      currentCuePoint.forEach((cue) => {
        try {
          track.removeCue(cue);
        } catch (err) {}
      });
    });
    cuePoints
      .filter((cuePoint) => cuePoint)
      .forEach((cuePoint) => {
        if (cuePoint.status === CuePoint.STATUS.DELETED) {
          return;
        }
        try {
          cues.current[cuePoint.sid] = cuePoint.activeTimes
            .filter((time) => !isNaN(time.start) && !isNaN(time.end))
            .map((time) => {
              const cue = new VTTCue(time.start / 1000, time.end / 1000, cuePoint.sid);

              cue.onenter = (e) => {
                setCuePointOnTime(cuePoint, time.sceneSid || true);
              };
              cue.onexit = (e) => {
                setCuePointOnTime(cuePoint, time.sceneSid || true, true);
              };

              track.addCue(cue);
              return cue;
            });
        } catch (err) {
          console.error({ cuePoint, err });
        }
      });
    window.track = track;
  }
  
  const setCropDataCuePoints = (cropData) => {
    if (!cropDataTrack.current) {
      return;
    }
    // Clear all textTrack list cuePoints if exist
    cropDataTrack.current?.cues?.length && Object.values(cropDataTrack.current.cues).forEach((cropCue) => {
      cropDataTrack.current.removeCue(cropCue);
    });

    // set cropData cuePoints to vtt track
    cropData.forEach((crop) => {
      const cropCue = new VTTCue(crop.start, crop.end, crop.start + crop.end);
      cropCue.onenter = (e) => {
        console.log('cropDataCue', crop);
        // when each cue enters we assign video2Canvas.current.croppingData = crop to set the cue's crop data to display on canvas
        video2Canvas.current.clearCroppingData();
        video2Canvas.current.croppingData = crop;
      };
      cropDataTrack.current.addCue(cropCue);
    });
  }
  
  React.useEffect(async () => {
    if (sequence?.useFootageForCrop && sequence?.sid && sequence?.aspectRatio) {
      const sequenceCropDetailsRes = await getSequenceCropDetails(sequence.sid, sequence.aspectRatio);
      setSequenceCropDetails(sequenceCropDetailsRes);
    }
  }, [sequence.sid, sequence.aspectRatio]);
  

  const [playbackVersionState, setPlaybackVersionState] = React.useState(0);

  React.useEffect( () => {
    if (sequence?.useFootageForCrop && duration && sequenceCropDetails?.length) {
      if (playerVideo.current && playerBackground.current) {
        // initialize video2Canvas service
        video2Canvas.current = new VideoToCanvasService(playerVideo.current, playerBackground.current);
      }

      if (sequence.sid && sequence?.aspectRatio && chapters?.length) {
        const filteredChapters = chapters?.filter((chapter) => chapter.cuePointType !== CuePointType.OUTRO && chapter.cuePointType !== CuePointType.INTRO);
        // re-calculate cropData cuePoints
        const arrangedCropData = arrangeCropData(filteredChapters, sequenceCropDetails, duration);
        if (video2Canvas.current && arrangedCropData && cropDataTrack.current) {
          // set cropData cuePoints to vtt track & canvas
          setCropDataCuePoints(arrangedCropData);
        }
      }
    }
  }, [duration, sequenceCropDetails, playbackVersionState]);
  
  React.useEffect(() => {
    if (!video2Canvas.current || !sequenceCropDetails?.length) return
    const cropStyle = pick(["color", "brandColorIndex", "blur"], sequenceCropDetails[0]);
    const brandColorIndex = cropStyle?.brandColorIndex;
    const sequenceBrandColors = sequence?.values?.colors;
    const bgColor = brandColorIndex > -1 && sequenceBrandColors?.[brandColorIndex]?.values?.color || cropStyle?.color || "#000" 
    video2Canvas.current.backgroundColor = bgColor;
    video2Canvas.current.blur = !!cropStyle?.blur;
  }, [sequenceCropDetails, video2Canvas.current, sequence?.values?.colors]);

  const tempCuePointsVersion = tempCuePointsVersionState.use();
  const formatedChaptersLength = tempScenesState.use((scenes) => scenes.filter.formatedScenes.length);

  React.useEffect(
    () => isPreview && addCuePoints(previewCuePoints),
    [metadataTrack.current, tempCuePointsVersion, previewCuePoints, formatedChaptersLength]
  );
  React.useEffect(() => !isPreview && addCuePoints(Object.values(cuePointsState.get())), [cuePointsVersion]);
  React.useEffect(() => !isPreview && recalcCuePointsTimes(sequence, scenes), [sequence, scenesVersion]);

  /**
   * @type {RefObject<HTMLVideoElement}
   */
  const overlay = React.useRef();

  async function onDashError(player, err) {
    console.error('DASH Player Error', err);
    onError && onError();
    timeout.current && clearTimeout(timeout.current);
    setShouldRetry(true);
  }

  /**
   * @param {Player} player
   */
  function onDashManifestParsed(player) {
    console.log('DASH Player Loaded');
    playbackIsLockedState.set(false);
    var videoPlayer = player.getMediaElement();
    metadataTrack.current = videoPlayer.addTextTrack('metadata', 'v-' + sequence.version);
    cropDataTrack.current = videoPlayer.addTextTrack('metadata', 'cropData v-' + sequence.version);

    !isPreview && addCuePoints(Object.values(cuePointsState.get()));
    // isPreview && addCuePoints(previewCuePoints)
  }

  /**
   * @param {Player} player
   */
  function onDashTracksChanged(player) {
    isPreview && !Object.values(cues.current || {}).length && addCuePoints(previewCuePoints);
    !isPreview && recalcCuePointsTimes(sequence, scenes);
    //     var variants = dash.getVariantTracks();
    //     var denoised = variants.find(v => v.label === 'denoised');
    //     if(denoised) {
    //         enableNoiseReductionDialog();
    //     }
    setPlaybackVersionState((v) => v + 1);
  }

  function onProgress(videoPlayer) {
    let buffers = [];
    for (var i = 0; i < videoPlayer.buffered.length; i++) {
      // seconds to percent
      const start = videoPlayer.buffered.start(i);
      const end = videoPlayer.buffered.end(i);
      buffers.push({
        start: (start / videoPlayer.duration) * 100,
        end: (end / videoPlayer.duration) * 100,
      });
    }

    buffersState.set(buffers);
  }

  async function onTimeUpdate(videoPlayer) {
    if (videoPlayer.paused) {
      setPauseTime(videoPlayer.currentTime * 1000);
    }
    videoPlayer.currentTime && setCurrentTime(videoPlayer.currentTime);
    // currentTimeState.set(videoPlayer.currentTime)
    //     setPlaybackPosition();
  }

  async function onPlay(videoPlayer) {
    setIsPlayerPlaying(true);
    startInterval(videoPlayer);
  }

  async function onPause(videoPlayer) {
    setIsPlayerPlaying(false);
    setPauseTime(videoPlayer.currentTime * 1000);
    endInterval();
  }

  function onSeeking(videoPlayer) {
    setSeeking(true);
  }

  function onSeeked(videoPlayer) {
    setSeeking(false);
  }

  async function onWaiting(videoPlayer) {
    setSeeking(true);
    setPauseTime(videoPlayer.currentTime * 1000);
  }

  async function onPlaying() {
    setSeeking(false);
    setPauseTime(undefined);
  }

  React.useEffect(() => {
    if (enableKeyboardControls) {
      return document.addEventListener('keyup', (e) => {
        e.preventDefault();
        if (playerVideo.current && e.code === 'Space' && !disabled) {
          playerVideo.current.paused ? playerVideo.current.play() : playerVideo.current.pause();
        }
        if (playerVideo.current && e.code === 'ArrowLeft' && !disabled) {
          playerVideo.current.currentTime -= 5;
        }
        if (playerVideo.current && e.code === 'ArrowRight' && !disabled) {
          playerVideo.current.currentTime += 5;
        }
      });
    }
  }, [playerVideo]);

  React.useEffect(() => {
    if (disabled && pauseTime === undefined) {
      playerVideo.current && playerVideo.current.pause();
      onPause(playerVideo);
    }
  }, [disabled]);

  const chapter = scenesState.use((scenes) => scenes.chapters[0]);

  const thumbnailChapter = scenesState.use((scenes) =>
    scenes.chapters.find((ch) => ch.cuePointType !== 32 && ch.cuePointType !== 33)
  );

  const fileUploader = fileUploaderState.use((uploaders) => uploaders[chapter?.sid]);
  const thumbnailVersion = thumbnailChapter?.thumbnailVersion;
  const withUserPs = useUserPs();
  const thumbnailUrl = () =>
    sequence.extractedThumb ||
    fileUploader?.thumb ||
    withUserPs(thumbnailChapter?.thumbnailUrl(sequence, 800, config.CHUNKS_URL, sequence?.aspectRatio));
  const [thumbnail, setThumbnail] = React.useState(sequence.extractedThumb || fileUploader?.thumb || thumbnailUrl());

  React.useEffect(() => setThumbnail(thumbnailUrl()), [thumbnailVersion]);

  async function onPlayClick() {
    trackEvent('content-editor-preview-video', { action: 'play' });
    if (playerVideo.current) {
      try {
        await playerVideo.current.play();
      } catch (err) {
        console.error(err);
      }
    }
  }

  async function onPauseRequired() {
    if (!isPreview || !chaptersChanges || !playerVideo.current || playerVideo.current.paused) {
      return;
    }
    try {
      await playerVideo.current.pause();
    } catch (err) {
      console.error(err);
    }
  }

  // classNames

  const monitorClassNames = () =>
    classNames('monitor embed-responsive', {
      playing: isPlayerLoaded && pauseTime === undefined,
      paused: pausedDelayed,
      'not-started': !isPlayerLoaded || pauseTime < 100 || !currentTime || currentTime <= 0.1,
      started: currentTime > 0.1 || pauseTime > 100,
      loading: !isPlayerLoaded || shouldShowLoader || lockPlayer,
      loaded: !seeking && isPlayerLoaded,
      draft: isDraft,
      'main-monitor': !isDraft,
    });

  React.useEffect(() => {
    if (pausedDelayTimer.current > 0) {
      clearTimeout(pausedDelayTimer.current);
      pausedDelayTimer.current = -1;
    }
    pausedDelayTimer.current = setTimeout(() => {
      if (isPlayerLoaded && pauseTime > 100) {
        setPausedDelayed(true);
      } else {
        setPausedDelayed(false);
      }
    }, 1500);
  }, [isPlayerLoaded, pauseTime]);

  function checkPlayerLoaded() {
    if (isPlayerLoaded) {
      // setIsPlayerLoaded(false)
    }
    setTimeout(() => {
      setIsPlayerLoaded(true);
    }, 100);
  }

  const firstDraft = firstDraftState.use();
  const style = mainPlayerStyleState.use();

  const EDITOR_ROLES = [Sequence.ROLE.CREATOR, Sequence.ROLE.EDITOR, Sequence.ROLE.OWNER];

  const shouldShowMonitorMenu = !isPreview && !firstDraft && !contentThumbnail && EDITOR_ROLES.includes(sequence.role);

  const getCanvasHW = () => {
    if (playerVideo.current) {
      const videoAspectRatio = playerVideo.current?.videoWidth / playerVideo.current?.videoHeight;
      const viewportAspectRatio = sequence.width / sequence.height; // output ratio
      let canvasW;
      let canvasH ;
      if (videoAspectRatio > viewportAspectRatio) {
        canvasH = playerVideo.current.videoHeight;
        canvasW = canvasH * viewportAspectRatio;
      } else {
        canvasW = playerVideo.current.videoWidth;
        canvasH = canvasW / viewportAspectRatio;
      }
      return { W: canvasW, H: canvasH };
    }
  };
  
  return (
    <React.Fragment>
      <div className="monitor-container">
        <div
          className={monitorClassNames()}
          style={{ pointerEvents: isTemp(cuePoint) ? 'none' : 'auto' }}
          ref={(element) => (overlay.current = element)}
          onClick={(e) => {
            if (playerVideo.current && pauseTime === undefined) {
              if (playerVideo.current.paused && e.target === overlay.current) {
                playerVideo.current.play();
              } else {
                playerVideo.current.pause();
              }
            }
          }}
        >
          {shouldShowMonitorMenu && <MonitorMenu trigger="monitor-menu" />}
          {playbackUrl ? (
            <React.Fragment>
              <canvas onClick={() => activeEditCuePointState.set(null)} width={canvasHW.W} height={canvasHW.H} style={{...style, display: sequence?.useFootageForCrop ? 'block' : 'none'}} ref={(element) => (playerBackground.current = element)} />
               <DashPlayer
                loop={false}
                controls={false}
                autoPlay={false}
                disablePictureInPicture
                preload="auto"
                className="main"
                crossOrigin="anonymous"
                controlsList="nodownload nofullscreen noremoteplayback"
                style={{...style, display: sequence?.useFootageForCrop ? 'none' : 'block'}}
                onTimeUpdate={(event) => onTimeUpdate(event.target)}
                onPlay={(event) => onPlay(event.target)}
                onPlaying={(event) => onPlaying(event.target)}
                onPause={(event) => onPause(event.target)}
                onWaiting={(event) => onWaiting(event.target)}
                onSeeking={(event) => onSeeking(event.target)}
                onSeeked={(event) => onSeeked(event.target)}
                onProgress={(event) => onProgress(event.target)}
                onLoadedData={(event) => {
                  setDuration(event.target.duration);
                }}
                onLoadedMetadata={(event) => {
                  const canvasHW = getCanvasHW();
                  setCanvasHW(canvasHW);
                }}
                innerRef={playerVideo}
                onDashError={onDashError}
                onDashTracksChanged={onDashTracksChanged}
                onDashManifestParsed={onDashManifestParsed}
                onBuffering={(event) => {
                  setShouldShowLoader(event.buffering);
                }}
                src={playbackUrl}
                startTime={startTime}
                onCanPlay={() => {
                  checkPlayerLoaded();
                  onCanPlay && onCanPlay();
                }}
                audioStream={audioStream}
              />
              {(contentThumbnail || thumbnail) && (
                <img className="monitor-placeholder" src={contentThumbnail || thumbnail} />
              )}
              {showPlayButton && <div className="monitor-overlay" />}
              {showPlayButton && (
                <div className="monitor-play" onClick={onPlayClick}>
                  <SVG name="play" />
                </div>
              )}
              <div className="monitor-loading" data-cy="monitor-loading">
                <Loader />
              </div>
              <Renderer
                id="main-graphics"
                data-cy="main-graphics"
                time={pauseTime}
                animate={pauseTime === undefined}
                isPreview={isPreview}
                isDraft={isDraft}
                visible={visible}
              />
            </React.Fragment>
          ) : (
            <>
              {thumbnail && <img className="monitor-placeholder" src={thumbnail} />}
              <div className="monitor-overlay" />
              {showPlayButton && (
                <div className="monitor-play" onClick={onPlayClick}>
                  <SVG name="play" />
                </div>
              )}
              <div className="monitor-loading">
                <Loader />
              </div>
            </>
          )}
          <div className="sequence-preview">
            <div>
              <div className="sequence-preview-title">{sequence.title}</div>
              <div className="sequence-preview-subtitle">
                Last updated on: {moment(sequence.updateTime).format('D MMMM, YYYY')}
              </div>
            </div>
            {sequenceCreator && (
              <div className="sequence-preview-credit">
                Created by:&nbsp;<span className="credit-name">{sequenceCreator.name}</span>
                {sequenceCreator.thumbUrl && <img src={sequenceCreator.thumbUrl} className="credit-thumb" />}
              </div>
            )}
          </div>
          <div
            className="player-tools"
            style={{ position: 'absolute', left: 0, right: 0, bottom: -5, transform: 'translateY(100%)' }}
          >
            {sequence.useDenoised ? (
              <div className="low-res-banner">
                <div className="pink-ellipse" />
                Studio Sound Enhancer | Low resolution preview
              </div>
            ) : (
              <div className="low-res-banner">Low resolution preview</div>
            )}
            {(playbackUrl !== undefined || isPreview) && (
              <Controls
                player={playerVideo}
                playing={isPlayerPlaying}
                duration={duration}
                playUntil={playUntil}
                isPreview={isPreview}
                isDraft={isDraft}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
