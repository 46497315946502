import styled from "@emotion/styled/macro";

export const LoaderDiv = styled.div<{ active }>`
    display: ${({ active }): string => (active ? 'flex' : 'none')};
    position: relative;
    width: 99%;
    justify-content: center; 
   align-items: flex-start;
   margin-left: 8px;

   .Loader {
    display: ${({ active }): string => (active ? 'block' : 'none')};
   }
`