
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'
import EditRules, { IEditRules, IEditRulesProperties } from '.././editRules'
import Color, { IColor, IColorProperties } from '.././color'
import AssetUsage, { IAssetUsage, IAssetUsageProperties } from '../preset/assetUsage'

export enum PresetStatus {
	ACTIVE = 1,
	IS_DEFAULT = 2,
	DELETED = 3,
};

export interface IPreset extends IBase {
	status?: number | null;
	editRules?: EditRules | null;
	assets?: AssetUsage[] | null;
	userSid?: string | null;
	name?: string | null;
	colors?: Color[] | null;
	secondaryColors?: Color[] | null;
	fontFamily?: string | null;
	headerFontFamily?: string | null;
	headerBoldVariant?: string | null;
	headerRegularVariant?: string | null;
	bodyBoldVariant?: string | null;
	bodyRegularVariant?: string | null;
	defaultVisualPackage?: string | null;
	defaultVideoType?: string | null;
	exampleAsset?: boolean | null;
	defaultWatermarkSize?: number | null;
	defaultFrameSize?: number | null;
	captionsStyling?: string | null;
}

export interface IPresetProperties extends IBaseProperties {
	status?: boolean;
	editRules?: IEditRulesProperties;
	assets?: IAssetUsageProperties;
	userSid?: boolean;
	name?: boolean;
	colors?: IColorProperties;
	secondaryColors?: IColorProperties;
	fontFamily?: boolean;
	headerFontFamily?: boolean;
	headerBoldVariant?: boolean;
	headerRegularVariant?: boolean;
	bodyBoldVariant?: boolean;
	bodyRegularVariant?: boolean;
	defaultVisualPackage?: boolean;
	defaultVideoType?: boolean;
	exampleAsset?: boolean;
	defaultWatermarkSize?: boolean;
	defaultFrameSize?: boolean;
	captionsStyling?: boolean;
}

export interface IPresetFilter extends IBaseFilter {
	userSidEqual?: string;
}

export class Preset<IType extends IPreset = IPreset, ITypeProperties extends IPresetProperties = IPresetProperties> extends Base<IType, ITypeProperties> implements IPreset {
	
	static className = 'Preset';
	static NAME = 'preset';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		editRules: EditRules.PROPERTIES,
		assets: AssetUsage.PROPERTIES,
		userSid: true,
		name: true,
		colors: Color.PROPERTIES,
		secondaryColors: Color.PROPERTIES,
		fontFamily: true,
		headerFontFamily: true,
		headerBoldVariant: true,
		headerRegularVariant: true,
		bodyBoldVariant: true,
		bodyRegularVariant: true,
		defaultVisualPackage: true,
		defaultVideoType: true,
		exampleAsset: true,
		defaultWatermarkSize: true,
		defaultFrameSize: true,
		captionsStyling: true,
	};
	
	/**
	 * @deprecated use PresetStatus instead
	 */
	static STATUS: any = {
		ACTIVE: PresetStatus.ACTIVE,
		IS_DEFAULT: PresetStatus.IS_DEFAULT,
		DELETED: PresetStatus.DELETED,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		if(values.editRules) {
			if(this.editRules) {
				this.editRules.set(values.editRules, this, 'editRules');
			}
			else {
				this.values.editRules = Base.asType<IEditRules>(values.editRules, EditRules, this, 'editRules') as EditRules;
			}
			delete values.editRules;
		}
		if(values.assets && values.assets.length) {
			values.assets = values.assets.map(item => Base.asType<IAssetUsage>(item, AssetUsage, this, 'assets') as AssetUsage);
		}
		if(values.colors && values.colors.length) {
			values.colors = values.colors.map(item => Base.asType<IColor>(item, Color, this, 'colors') as Color);
		}
		if(values.secondaryColors && values.secondaryColors.length) {
			values.secondaryColors = values.secondaryColors.map(item => Base.asType<IColor>(item, Color, this, 'secondaryColors') as Color);
		}
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get editRules(): EditRules | null | undefined {
		return this.values.editRules
	}
			
	set editRules(value: EditRules | null | undefined) {
		if(this.values.editRules !== value) {
			this.propertyChanged('editRules', value);
			this.values.editRules = value;
		}
	}
			
	get assets(): AssetUsage[] | null | undefined {
		return this.values.assets
	}
			
	set assets(value: AssetUsage[] | null | undefined) {
		if(this.values.assets !== value) {
			this.propertyChanged('assets', value);
			this.values.assets = value;
		}
	}
			
	get userSid(): string | null | undefined {
		return this.values.userSid
	}
			
	set userSid(value: string | null | undefined) {
		if(this.values.userSid !== value) {
			this.propertyChanged('userSid', value);
			this.values.userSid = value;
		}
	}
			
	get name(): string | null | undefined {
		return this.values.name
	}
			
	set name(value: string | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	get colors(): Color[] | null | undefined {
		return this.values.colors
	}
			
	set colors(value: Color[] | null | undefined) {
		if(this.values.colors !== value) {
			this.propertyChanged('colors', value);
			this.values.colors = value;
		}
	}
			
	get secondaryColors(): Color[] | null | undefined {
		return this.values.secondaryColors
	}
			
	set secondaryColors(value: Color[] | null | undefined) {
		if(this.values.secondaryColors !== value) {
			this.propertyChanged('secondaryColors', value);
			this.values.secondaryColors = value;
		}
	}
			
	get fontFamily(): string | null | undefined {
		return this.values.fontFamily
	}
			
	set fontFamily(value: string | null | undefined) {
		if(this.values.fontFamily !== value) {
			this.propertyChanged('fontFamily', value);
			this.values.fontFamily = value;
		}
	}
			
	get headerFontFamily(): string | null | undefined {
		return this.values.headerFontFamily
	}
			
	set headerFontFamily(value: string | null | undefined) {
		if(this.values.headerFontFamily !== value) {
			this.propertyChanged('headerFontFamily', value);
			this.values.headerFontFamily = value;
		}
	}
			
	get headerBoldVariant(): string | null | undefined {
		return this.values.headerBoldVariant
	}
			
	set headerBoldVariant(value: string | null | undefined) {
		if(this.values.headerBoldVariant !== value) {
			this.propertyChanged('headerBoldVariant', value);
			this.values.headerBoldVariant = value;
		}
	}
			
	get headerRegularVariant(): string | null | undefined {
		return this.values.headerRegularVariant
	}
			
	set headerRegularVariant(value: string | null | undefined) {
		if(this.values.headerRegularVariant !== value) {
			this.propertyChanged('headerRegularVariant', value);
			this.values.headerRegularVariant = value;
		}
	}
			
	get bodyBoldVariant(): string | null | undefined {
		return this.values.bodyBoldVariant
	}
			
	set bodyBoldVariant(value: string | null | undefined) {
		if(this.values.bodyBoldVariant !== value) {
			this.propertyChanged('bodyBoldVariant', value);
			this.values.bodyBoldVariant = value;
		}
	}
			
	get bodyRegularVariant(): string | null | undefined {
		return this.values.bodyRegularVariant
	}
			
	set bodyRegularVariant(value: string | null | undefined) {
		if(this.values.bodyRegularVariant !== value) {
			this.propertyChanged('bodyRegularVariant', value);
			this.values.bodyRegularVariant = value;
		}
	}
			
	get defaultVisualPackage(): string | null | undefined {
		return this.values.defaultVisualPackage
	}
			
	set defaultVisualPackage(value: string | null | undefined) {
		if(this.values.defaultVisualPackage !== value) {
			this.propertyChanged('defaultVisualPackage', value);
			this.values.defaultVisualPackage = value;
		}
	}
			
	get defaultVideoType(): string | null | undefined {
		return this.values.defaultVideoType
	}
			
	set defaultVideoType(value: string | null | undefined) {
		if(this.values.defaultVideoType !== value) {
			this.propertyChanged('defaultVideoType', value);
			this.values.defaultVideoType = value;
		}
	}
			
	get exampleAsset(): boolean | null | undefined {
		return this.values.exampleAsset
	}
			
	set exampleAsset(value: boolean | null | undefined) {
		if(this.values.exampleAsset !== value) {
			this.propertyChanged('exampleAsset', value);
			this.values.exampleAsset = value;
		}
	}
			
	get defaultWatermarkSize(): number | null | undefined {
		return this.values.defaultWatermarkSize
	}
			
	set defaultWatermarkSize(value: number | null | undefined) {
		if(this.values.defaultWatermarkSize !== value) {
			this.propertyChanged('defaultWatermarkSize', value);
			this.values.defaultWatermarkSize = value;
		}
	}
			
	get defaultFrameSize(): number | null | undefined {
		return this.values.defaultFrameSize
	}
			
	set defaultFrameSize(value: number | null | undefined) {
		if(this.values.defaultFrameSize !== value) {
			this.propertyChanged('defaultFrameSize', value);
			this.values.defaultFrameSize = value;
		}
	}
			
	get captionsStyling(): string | null | undefined {
		return this.values.captionsStyling
	}
			
	set captionsStyling(value: string | null | undefined) {
		if(this.values.captionsStyling !== value) {
			this.propertyChanged('captionsStyling', value);
			this.values.captionsStyling = value;
		}
	}
			
	
	static get<IType extends IPreset = IPreset>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IPresetFilter = IPresetFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IPresetFilter = IPresetFilter, IType extends IPreset = IPreset>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
	
	async setDefault(properties: ITypeProperties | null = null): Promise<Preset | null> {
		properties = (properties || Preset.PROPERTIES) as ITypeProperties;
		var action = `setDefault{${Base.toProperties(properties)}}`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.setDefault !== undefined && data.setDefault !== null) {
			this.set(data.setDefault);
			return this;
		}
		return null;
	}
}
