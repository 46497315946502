import styled from '@emotion/styled/macro';
import React from 'react';

import './Subtitles.scss';

import { StyleCaptionsCaseType } from '../../../client/base/sequence/style';
import { RoundedRectangleOption } from './layout.styled';

const CaseOption = styled(RoundedRectangleOption)<{ case: StyleCaptionsCaseType }>`
padding: 5px;
display: flex;
align-items: center;
justify-content: space-between;
margin-right: 5px !important;
width: fit-content;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 20px;
  span {
    font-weight: 700;
    margin-right: 3px;
    text-transform: ${(props) => {
      switch (props.case) {
        case StyleCaptionsCaseType.DEFAULT:
          return 'capitalize';

        case StyleCaptionsCaseType.UPPERCASE:
          return 'uppercase';

        case StyleCaptionsCaseType.LOWERCASE:
          return 'lowercase';
        default:
          return 'none';
      }
    }};
  }
`;

const CASE_TYPES = [
    StyleCaptionsCaseType.DEFAULT,
    StyleCaptionsCaseType.UPPERCASE,
    StyleCaptionsCaseType.LOWERCASE,

]; // prettier-ignore

interface Props {
  value?: StyleCaptionsCaseType;
  onChange(value: StyleCaptionsCaseType): void;
}

export const CaptionsCasePicker = ({ value, onChange }: Props) => (
  <>
    {CASE_TYPES.map((CaseType) => {
      const subtitleText =
        StyleCaptionsCaseType[CaseType].charAt(0).toUpperCase() + StyleCaptionsCaseType[CaseType].slice(1).toLowerCase();

      return (
        <CaseOption
          key={CaseType}
          case={CaseType}
          isSelected={CaseType === value}
          onClick={() => {
            if (CaseType !== value) {
              onChange(CaseType);
            }
          }}
        >
          <span>aa</span>
          {subtitleText}
        </CaseOption>
      );
    })}
  </>
);
