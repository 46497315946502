import Compressor from 'compressorjs';
import React from 'react';
import Dropzone from 'react-dropzone';
import Asset from '../client/asset';
import Color from '../client/color';
import Preset, { AssetUsage } from '../client/preset';
import { fileFromCanvas } from '../client/sequence/logo';
import SVG from '../components/svg';
import { AppContext } from '../lib/app-context';
import FileUploader from '../lib/fileUploader';
import { handleError } from '../state/error';
import { savePreset } from '../state/local';
import Uploader from '../ui-components/Uploader/Uploader';
import { trackEvent } from '../utils/analityics.utils';
import Loader from './Loader';
import ApproveModal from './Modals/ApproveModal';




interface LogoProps {
    onLogoSelected(): void;
    onBrandReady?(): void;
}

function numbersToColor([r, g, b]) {
    return (
        '#' +
        (r % 256).toString(16).padStart(2, '0') +
        (g % 256).toString(16).padStart(2, '0') +
        (b % 256).toString(16).padStart(2, '0')
    )
}

function mostFrequent(arr) {
    return arr
        .map(value => [value, arr.filter(v => v === value).length])
        .reduce(([ret, max], [value, count]) => count > max ? [value, count] : [ret, max], [0, 0])[0];
}

function findLastIndex(array, condition) {
    var index = array.slice().reverse().findIndex(condition);
    var count = array.length - 1
    return index >= 0 ? count - index : index;
}

function imgToCanvas(img) {
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    var context = canvas.getContext("2d");
    context.drawImage(img, 0, 0, canvas.width, canvas.height);
    return canvas;
}


/**
 * @param {{
 * 	onChange: Function
 * 	openUploader: boolean
 * 	onAutoOpen: Function
 * }} props
 */
//export default function LogoDropArea({ source, preset, onChange, openUploader, onAutoOpen, customUploadFunction }) {
export default function Logo({ logoName, logoTitle, preset, setPreset, usageType, assetUsage, assetUsageType, onBrandReady, onLogoSelected, source, type, selectedLogo, setSelectedLogo, isNew, onLogoReady, defaultLogoChangeSaving, setDefaultLogoChangeSaving }: LogoProps) {
    const { user, setUser, config } = React.useContext(AppContext)

    const [logoThumbnail, setLogoThumbnail] = React.useState();
    const dropzoneRef = React.useRef();
    const [uploaderError, setUploaderError] = React.useState(false);
    const [logoFile, setLogoFile] = React.useState(null)

    const [logoAsset, setLogoAsset] = React.useState(preset.getLogoOfType(assetUsageType))
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [tempImage, setTempImage] = React.useState(null);
    const approveText = "Do you want to regenerate the brand colors\n in this brand kit?"

    React.useEffect(() => {
        setLogoThumbnail();
    }, [usageType, preset]);

    async function onLogoChange(img) {
        if (!img || !img.naturalWidth) {
            return false
        }
        var colors = window.getPalette(img, 5)
        var colorsHash = colors
            .map(color => numbersToColor(color.rgb))
            .join(':')
        var savedColors = preset.colors
            ? preset.colors.map(color => color.color).join(':')
            : ''
        if (savedColors !== colorsHash && usageType === "main") {
            preset.colors = colors.map((color, index) => {
                var obj = new Color()
                obj.dominancy = index + 1
                obj.color = numbersToColor(color.rgb)
                obj.percent = color.percent
                return obj
            })
        }
        return preset;
    }

    async function uploadLogo(img, filename) {
        var canvas = imgToCanvas(img);
        var context = canvas.getContext("2d");
        var data = [
            context.getImageData(2, 2, 1, 1),
            context.getImageData(2, canvas.height - 2, 1, 1),
            context.getImageData(canvas.width - 2, canvas.height - 2, 1, 1),
            context.getImageData(canvas.width - 2, 2, 1, 1),
        ]
            .map(imgData => imgData.data);

        var transparent = data.findIndex(corner => corner[3] < 255) >= 0;
        if (!transparent) {
            var bg = Array.from(mostFrequent(data));
        }

        var imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        var logoImageData = imageData.data;

        // crop
        if (true) {
            // build array of opacity cells
            var opacity = logoImageData.filter((_, i) => (i + 1) % 4 === 0);
            // start with worse data
            var w = canvas.width, h = canvas.height, l = w, t = h, r = 0, b = 0;
            for (let i = 0; i < h; i++) {
                var chunk = opacity.slice(i * w, (i + 1) * w);
                var firstNonTransparentIndex = chunk.findIndex(d => d);
                if (firstNonTransparentIndex < 0) {
                    continue; // all line is transparent
                }
                var lastNonTransparentIndex = findLastIndex(chunk, d => d);
                if (lastNonTransparentIndex < 0) {
                    lastNonTransparentIndex = w;
                }
                l = Math.min(firstNonTransparentIndex, l);
                r = Math.max(lastNonTransparentIndex + 1, r);
                t = Math.min(i, t);
                b = Math.max(i + 1, b);
            }
            if (l > 0 || t > 0 || r < w || b < h) {
                imageData = context.getImageData(l, t, r - l, b - t);
                logoImageData = imageData.data;
                context.clearRect(0, 0, canvas.width, canvas.height);
                canvas.width = r - l;
                canvas.height = b - t;
                context.putImageData(imageData, 0, 0);
            }
        }
        const src = canvas.toDataURL();
        setLogoThumbnail(src)
        !selectedLogo && setSelectedLogo(type)

        var asset = new Asset();
        asset.type = Asset.TYPE.LOGO;
        asset.name = filename;
        await asset.save();

        const fileUploader = new FileUploader({
            file: fileFromCanvas(canvas),
            content: asset,
            sliceSize: 1024 * 1024 * 20 // 20 MB
        })
        await fileUploader.uploadAsset();

        const assetUsage = preset.addLogoAsset(asset, AssetUsage.USAGE_TYPE[usageType?.toUpperCase()])
        const newPreset = await preset.save(Preset.PROPERTIES);
        setLogoAsset(assetUsage)

        const shouldUpdatePreset = logoName !== "main-logo" || !preset?.colors?.length

        if (isNew) {
            setPreset(preset);
            setSelectedFileUploading(false);
            onLogoReady && onLogoReady(true);
        } else {
            savePreset(preset, { user, setUser }, undefined, shouldUpdatePreset).then(res => {
                setSelectedFileUploading(false);
                onLogoReady && onLogoReady(true);
            })
        }
    }

    async function onLogoFileSelected(file) {
        onLogoReady && onLogoReady(false);

        return new Promise((resolve, reject) => {
            new Compressor(file, {
                strict: false,
                maxWidth: 1500,
                maxHeight: 1000,
                checkOrientation: true,
                convertSize: Infinity,
                success: compressed => {
                    const img = new Image()
                    img.onload = () => {
                        setTempImage(img);
                        uploadLogo(img, file.name);
                        resolve(img)
                    }
                    img.src = window.URL.createObjectURL(compressed);
                },
                error: err => {
                    setUploaderError(err)
                    handleError({
                        title: 'Logo Upload',
                        message: err.message,
                        responseError: err
                    })
                    setSelectedFileUploading(false);
                    reject()
                },
            })
        })

    }

    const [selectedFileUploading, setSelectedFileUploading] = React.useState(false);

    async function onLogoFileInputChange(files) {
        if (!files.length) {
            return
        }
        const file = files[0];
        
        trackEvent('brand-kit-upload-logo', {
            type: usageType === 'bright' ? 'light' : usageType, isInWizard: !!isNew, file: {
                size: file.size,
                type: file.type
            }
        });


        setSelectedFileUploading(true);
        const tempImg = await onLogoFileSelected(file);
        setLogoFile(file);

        if (logoName === "main-logo") {
            if (preset && preset.colors && preset.colors.length) {
                setDialogOpen(true);
            } else {
                await onLogoChange(tempImg);
            }
        }
    }

    function onDefaultChange(e) {
        if (!assetUsage && !logoAsset) {
            e.preventDefault()
            return e.stopPropagation()
        }

        const defaultLogo = preset.logos().find(a => a.isDefault)
        const newDefault = preset.logos().find(a => a.usageType === (logoAsset || assetUsage).usageType)

        if (!newDefault) {
            return preset
        }
        if (defaultLogo) {
            defaultLogo.isDefault = false
        }
        newDefault.isDefault = true
        setSelectedLogo(type)
       
        if(isNew){
            setPreset(preset)
        } else {
            setDefaultLogoChangeSaving(true);
            savePreset(preset, { user, setUser }).then(() => setDefaultLogoChangeSaving(false))
        }
    }

    const isChecked = type === selectedLogo

    const regenerateBrandColors = () => {
        setDialogOpen(false);
        setSelectedFileUploading(false);
        onLogoChange(tempImage);
        savePreset(preset, { user, setUser });
    }

    return (
        <div key={logoName} >
            <ApproveModal showModal={dialogOpen
                && !selectedFileUploading
            } setShowModal={setDialogOpen} approveText={approveText} approve={regenerateBrandColors} setTemplates={setTempImage} isNew={!!isNew} />
            <div className='logo-content'>
                <h4 className='logo-title'>{logoTitle}</h4>
                <div className='logoDrop-wrapper'>

                    <Dropzone
                        onDrop={onLogoFileInputChange}
                        accept="image/*"
                        maxFiles={1}
                        data-cy="logo-drop-area"
                        className="logo-dropzone"
                        style={{ cursor: "pointer" }}
                        ref={dropzoneRef}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div
                                {...getRootProps()}
                                className="drop-area drop-area-logo logo"
                                style={logoThumbnail ? { border: 'unset' } : {}}
                            >
                                <input {...getInputProps()} className="logo-file" data-cy="logo-drop-area" />

                                <div className='uploader-wrapper'>
                                    {selectedFileUploading ? <Loader size={'sm'} data-cy='logo-uploading-loader' /> : <Uploader preview={logoThumbnail || source} file={logoFile} error={uploaderError} preset={preset} />}
                                    <br />
                                </div>
                            </div>
                        )}
                    </Dropzone>

                    <div className="default-selection" >
                        <label className="asset--checkbox" onChange={onDefaultChange} >
                            <input type="checkbox" checked={isChecked} disabled={defaultLogoChangeSaving || selectedFileUploading || !assetUsage && !logoAsset}
                                readOnly
                                // data-cy={!defaultLogoChangeSaving ? 'logo-checkbox' : ''} 
                               data-cy={!(defaultLogoChangeSaving || selectedFileUploading || !assetUsage && !logoAsset) ? 'logo-checkbox' : ''} 
                                />
                            <span className="checkmark">
                                <SVG name="check" viewBox="0 0 36 36" />
                            </span>
                        </label>
                        <div className='set-brandkit-default'>Set as default</div>
                    </div>

                </div>
            </div>
        </div>
    )
}
