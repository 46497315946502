import React from 'react';
import className from 'classnames';
import Menu from '../../ui-components/Menu/Menu';
import MenuItem from '../../ui-components/Menu/MenuItem';

import { sequenceState, sequenceSave, scenesState } from '../../state/sequence';
import { cuePointsState, saveCuePoint } from '../../state/cuePoints';
import { AppContext } from '../../lib/app-context';

import TitleCuePoint from '../../client/titleCuePoint';
import PresetLogo from '../../ui-components/PresetLogo/PresetLogo';
import { analytics } from '../wrapWithProvider';
import { trackEvent } from '../../utils/analityics.utils';
import { openPeechProUpgradeModal } from '../layout';
import { FeatureFeatureName } from '../../client/base/feature';
import SliderWithInput from "./subtitles/SliderWithInput";
import styled from "@emotion/styled";
import {mapValueWithinRange} from "../../utils/mapValueWithinRange.utils";

const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 6px;
  font-family: 'Open Sans',sans-serif;
`;

const SliderWthInputWrapper = styled.div`
  margin-left: 6px;
`;

export default function BrandProperties() {
  const scenes = scenesState.use(scenes => scenes.map);
  const sequence = sequenceState.use();
  const intro = scenesState.use(scenes => scenes.filter.intro);
  const cuePoints = cuePointsState.use();
  const { user, plan } = React.useContext(AppContext);


  // const [showWatermarkText, setShowWatermarkText] = React.useState(false)

  // React.useEffect(() => seekPlayback((sequence.intro?.duration || 0) + 2), [])
  /**
   * @type {TitleCuePoint}
   */
  const logo = Object.values(cuePoints).find(c => c?.type === TitleCuePoint.TYPE.LOGO);
  const [enabled, setEnabled] = React.useState(logo?.opacity ?? 1);
  const [position, setPosition] = React.useState(getLogoPosition);
  const [size, setSize] = React.useState(logo?.width);
  const [positionColor, setPositionColor] = React.useState(false);

  React.useEffect(() => setPosition(getLogoPosition), [logo?.version]);
  React.useEffect(() => setSize(logo?.width), [logo?.width]);

  if (!sequence) return

  function aspectRatioToFloat(aspectRatio) {
    if(aspectRatio.indexOf(':') > 0) {
      const [w, h] = aspectRatio.split(':').map((num) => parseInt(num));
      return w / h;
    }
    return parseFloat(aspectRatio);
  }
  const floatAspectRatio = aspectRatioToFloat(sequence.aspectRatio);
  const expectedHDiff = 3 * floatAspectRatio;
  const expectedWDiff = expectedHDiff / floatAspectRatio;
  function isWithinBounds(a, b) {
    return Math.abs(a - b) <= 0.01
  }

  function getLogoPosition() {
    if (!logo) {
      return '';
    }
    if (isWithinBounds(logo.top, expectedHDiff) && isWithinBounds(logo.left, expectedWDiff)) {
      return '0';
    }
    if (isWithinBounds(logo.top, expectedHDiff) && isWithinBounds(logo.right, expectedWDiff)) {
      return '1';
    }
    if (isWithinBounds(logo.bottom, expectedHDiff) && isWithinBounds(logo.right, expectedWDiff)) {
      return '2';
    }
    if (isWithinBounds(logo.bottom, expectedHDiff) && isWithinBounds(logo.left, expectedWDiff)) {
      return '3';
    }
  }

  async function onToggleActivation() {
    await onOpacityChange(enabled ? 0 : 1)
    trackEvent('brand-logo-enable', { enabled});
  }

  async function onSizeChange(percent) {
    percent = mapValueWithinRange(percent, 0, 100, 5, 95);
    sequence.logo.size = percent;
    await sequenceSave(sequence);
    analytics.track('brand-logo-size', { percent });
  }

  async function onOpacityChange(opacity) {
    opacity /= 100;
    if (enabled !== opacity > 0) {
      setEnabled(opacity > 0)
    }
    sequence.logo.opacity = opacity;
    await sequenceSave(sequence);
    analytics.track('brand-logo-opacity', { opacity });
  }

  function onChangeLogoPosition(event) {
    const positionTypes = [
      { top: expectedHDiff, left: expectedWDiff }, // top-left
      { top: expectedHDiff, right: expectedWDiff }, // top-right
      { bottom: expectedHDiff, right: expectedWDiff }, // bottom-right
      { bottom: expectedHDiff, left: expectedWDiff }, // bottom-left
    ];
    setPositionColor(true)

    if (!positionTypes[event.target.value]) {
      return;
    }

    Object.assign(logo, {
      top: null,
      bottom: null,
      left: null,
      right: null,
    }, positionTypes[event.target.value]);
    saveCuePoint(logo, sequence, scenes);
  }

  return (
    <Menu type="brand" header="Logo" enabled={enabled} onChange={onToggleActivation}>
      <MenuItem bordered={false} id="watermark-logo">
        <Title>Select Logo</Title>
        <PresetLogo usage='watermark'/>
      </MenuItem>
      {logo && <> <MenuItem id="branding-position">
        <Title>Position</Title>
        <select
          value={position}
          id="branding-position"
          className={className("menu-select", { "position-selected": positionColor })}
          onChange={onChangeLogoPosition}
        >
          <option value="">Custom</option>
          <option value="0">Top Left</option>
          <option value="1">Top Right</option>
          <option value="2">Bottom Right</option>
          <option value="3">Bottom Left</option>
        </select>
      </MenuItem>
      <MenuItem id="branding-opacity">
        <Title>Opacity</Title>
        <SliderWthInputWrapper>
        <SliderWithInput
          onValueChange={onOpacityChange}
          value={Math.round(logo?.opacity * 100)}
          min={0}
          max={100}
          step={1}
        />
        </SliderWthInputWrapper>
      </MenuItem>
      <MenuItem id="branding-size">
        <Title>Size</Title>
        <SliderWthInputWrapper>
        <SliderWithInput
          onValueChange={onSizeChange}
          value={Math.round(mapValueWithinRange(sequence.logo.size, 5, 95, 0, 100))}
          min={1}
          max={100}
          step={1}
        />
        </SliderWthInputWrapper>
      </MenuItem>
      </>}

      {
        plan?.planDisallowedTierFeatures?.includes(FeatureFeatureName.WITHOUT_PEECH_WATERMARK) && <MenuItem style={{ padding: '0' }}>
          {/* peech watermarks */}

          <div className="remove-watermark-text" onClick={() => openPeechProUpgradeModal(user, "remove peech watermark", FeatureFeatureName.WITHOUT_PEECH_WATERMARK)}>
            Upgrade to remove <br />peech watermark
          </div>
        </MenuItem>
      }
    </Menu >
  );
}
