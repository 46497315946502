
import * as Base from './base/editRules';


export interface IEditRules extends Base.IEditRules {

}

export interface IEditRulesProperties extends Base.IEditRulesProperties {

}

export interface IEditRulesFilter extends Base.IEditRulesFilter {

}

export default class EditRules<IType extends IEditRules = IEditRules, ITypeProperties extends IEditRulesProperties = IEditRulesProperties> extends Base.EditRules<IType, ITypeProperties> implements IEditRules {
}