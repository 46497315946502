
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export enum GraphicAssetType {
	LOGO = 1,
	SPEAKER = 2,
	IMAGE = 3,
	VIDEO = 4,
};

export interface IGraphicAsset extends IBase {
	name?: string | null;
	className?: string | null;
	type?: number | null;
	assetSid?: string | null;
}

export interface IGraphicAssetProperties extends IBaseProperties {
	name?: boolean;
	className?: boolean;
	type?: boolean;
	assetSid?: boolean;
}

export interface IGraphicAssetFilter extends IBaseFilter {
}

export class GraphicAsset<IType extends IGraphicAsset = IGraphicAsset, ITypeProperties extends IGraphicAssetProperties = IGraphicAssetProperties> extends Base<IType, ITypeProperties> implements IGraphicAsset {
	
	static className = 'GraphicAsset';
	static NAME = 'graphicAsset';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		name: true,
		className: true,
		type: true,
		assetSid: true,
	};
	
	/**
	 * @deprecated use GraphicAssetType instead
	 */
	static TYPE: any = {
		LOGO: GraphicAssetType.LOGO,
		SPEAKER: GraphicAssetType.SPEAKER,
		IMAGE: GraphicAssetType.IMAGE,
		VIDEO: GraphicAssetType.VIDEO,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get name(): string | null | undefined {
		return this.values.name
	}
			
	set name(value: string | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	get className(): string | null | undefined {
		return this.values.className
	}
			
	set className(value: string | null | undefined) {
		if(this.values.className !== value) {
			this.propertyChanged('className', value);
			this.values.className = value;
		}
	}
			
	get type(): number | null | undefined {
		return this.values.type
	}
			
	set type(value: number | null | undefined) {
		if(this.values.type !== value) {
			this.propertyChanged('type', value);
			this.values.type = value;
		}
	}
			
	get assetSid(): string | null | undefined {
		return this.values.assetSid
	}
			
	set assetSid(value: string | null | undefined) {
		if(this.values.assetSid !== value) {
			this.propertyChanged('assetSid', value);
			this.values.assetSid = value;
		}
	}
			
	
	static get<IType extends IGraphicAsset = IGraphicAsset>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IGraphicAssetFilter = IGraphicAssetFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IGraphicAssetFilter = IGraphicAssetFilter, IType extends IGraphicAsset = IGraphicAsset>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
