import styled from '@emotion/styled/macro';
import React, { SyntheticEvent } from 'react';
import { Modal } from 'react-bootstrap';
import { COLORS } from '../../../constants/colors';
import Button, { ButtonSize, ButtonVariant } from '../../ui-components/Button';
import SVG from '../svg';
import { onProjectTitleKeyPress } from '../../utils/input.utils';
import { navigate } from 'gatsby';

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 371px;
    max-height: 236px;
  }

  .modal-content {
    display: flex;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
`;

const ModalBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 12%) 8px 4px 8px 0;
  width: 371px;
  height: 236px;
  background-color: ${COLORS.peech_neutral_white};
  border-radius: 10px;
  padding: 40px 15px 20px 15px;
`;

const CloseDiv = styled.div`
  position: absolute;
  top: 10px;
  right: 14px;
  width: 14px;
  cursor: pointer;
  svg {
    fill: ${COLORS.peech_gray_500};
  }
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: ${COLORS.peech_gray_900};
  margin-bottom: 10px;
`;

const InfoDiv = styled(Title)`
  color: ${COLORS.peech_neutral_black};
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`;

const StyledInput = styled.input`
  width: 100%;
  max-width: 100%;
  margin-block: 22px;
  height: 37px;
  border: 0.5px solid;
  border-color: ${COLORS.peech_gray_400};
  border-radius: 3px;
  background-color: ${COLORS.peech_neutral_white};
  padding: 10px 12px 10px 10px;
  color: ${COLORS.peech_gray_900};
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;

  &:focus {
    outline: none !important;
    border: 1px solid ${COLORS.peech_gray_700};
    box-shadow: 0 0 1px ${COLORS.peech_gray_700};
  }
`;

export default function CreateNewProjectModal({ isOpen, onClose }: { isOpen: boolean; onClose(): void }) {
  const titleInput = React.useRef();
  const [title, setTitle] = React.useState('');

  const onCreateClick = () => {
    const encodedTitle = encodeURIComponent(title);
    navigate(`/create-project?title=${encodedTitle}`)
    onClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  return (
    <StyledModal show={isOpen} onHide={onClose} centered>
      <ModalBody>
        <CloseDiv onClick={onClose}>
          <SVG name="close" viewBox="0 0 22 22" />
        </CloseDiv>
        <Title>Create a new video</Title>
        <InfoDiv>Give your video a title so you can find it later</InfoDiv>

        <StyledInput
          ref={titleInput}
          type={'text'}
          placeholder="Your video title"
          id={'project-title'}
          onChange={handleChange}
          onKeyDown={onProjectTitleKeyPress}
          maxLength={60}
          autoComplete='off'
        />

        <Button
          variant={ButtonVariant.Primary}
          size={ButtonSize.Large}
          label="Create"
          onClick={onCreateClick}
          disabled={!title}
        />
      </ModalBody>
    </StyledModal>
  );
}
