import React, { useEffect, useRef, useState } from 'react';
import { Popover as BasePopover, PopoverProps as BasePopoverProps } from 'react-tiny-popover';
import styled, { createGlobalStyle, keyframes } from 'styled-components/macro';
import { COLORS } from '../../../constants/colors';

const scaleUp = keyframes`
  from {
    opacity: 0;
    scale: 0.75;
  }

  to {
    opacity: 1;
    scale: 1;
  }
`;

const PopupGlobalStyle = createGlobalStyle`
  .react-tiny-popover-container {
    width: unset !important;
    padding: unset;
    border: unset;
    border-radius: unset !important;
    box-shadow: 0 0 2px ${COLORS.peech_gray_500};
    background-color: transparent;
    border-radius: 8px !important;
    z-index: 30;
    overflow: hidden !important;
    
    > * {
      animation: ${scaleUp} 200ms cubic-bezier(0.57, -0.01, 0, 0.77) forwards;
    }
  }
`;

const Trigger = styled.div`
  display: flex;
  cursor: pointer;
`;

interface PopoverProps extends Omit<BasePopoverProps, 'onClickOutside'> {
  onOpen?(): void;
  onClose?(): void;
  scrollingContainerRef?: React.MutableRefObject<HTMLElement>;
}

export default function Popover({ isOpen, onOpen, onClose, children, scrollingContainerRef, ...props }: PopoverProps) {
  const [isElementVisible, setIsElementVisible] = useState(true); // isTriggerVisible
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!scrollingContainerRef?.current) {
      return;
    }

    const scrollingContainer = scrollingContainerRef.current;
    const element = elementRef.current!;

    function handleScroll() {
      const elementRect = element.getBoundingClientRect();
      const containerRect = scrollingContainer.getBoundingClientRect();

      if (elementRect.top >= containerRect.top && elementRect.bottom <= containerRect.bottom) {
        setIsElementVisible(true);
      } else {
        setIsElementVisible(false);
      }
    }

    scrollingContainer?.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      scrollingContainer?.removeEventListener('scroll', handleScroll);
    };
  }, [scrollingContainerRef?.current?.getBoundingClientRect()]);

  useEffect(() => {
    if (isOpen && !isElementVisible) {
      onClose?.();
    }
  }, [isOpen, isElementVisible]);

  return (
    <>
      <PopupGlobalStyle />

      <div ref={elementRef}>
        <BasePopover isOpen={isOpen} onClickOutside={onClose} {...props}>
          <Trigger onClick={isOpen ? onClose : onOpen}>{children}</Trigger>
        </BasePopover>
      </div>
    </>
  );
}
