import { useTransition } from '@react-spring/web';
import { entity } from 'simpler-state';
import Compressor from 'compressorjs';
import React from 'react';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Asset, { AssetStatus, AssetType } from '../client/asset';
import Base from '../client/base/base';
import SpeakerCuePoint from '../client/speakerCuePoint';
import TitleCuePoint from '../client/titleCuePoint';
import { SpeakerElement } from '../components/cue-points/graphic';
import { TextCuePointElement } from '../components/cue-points/title';
import SVG from '../components/svg';
import { AppContext } from '../lib/app-context';
import FileUploader from '../lib/fileUploader';
import {
  activeEditCuePointState,
  cuePointsState,
  editCuePointState,
  isFullFrame as isFullFrameCP,
  resetCuePoint,
  resetCuePointTempValues,
  saveCuePoint,
  setLoadedMedia,
  tempMediaState,
  tempToLoadedMedia,
} from '../state/cuePoints';
import { handleError } from '../state/error';
import { menuState } from '../state/menu';
import { scenesState, sequenceSave, sequenceState } from '../state/sequence';
import Loader from '../ui-components/Loader';
import Tabs from '../ui-components/Tabs/Tabs';
import { trackEvent } from '../utils/analityics.utils';
import useUserPs from '../hooks/useUserPs';
import { CuePoint } from '../client/base/cuePoint';

const ASSET_STATUS = {
  PENDING: 0,
  READY: 2,
};

const SPEAKER_TABS = {
  GENERIC: 0,
  CUSTOM: 1,
};

/**
 *
 * @param {{
 *  nextPage: Function,
 *  viewPageSize: number,
 *  text: string,
 *  reload: any,
 *  children: Function,
 * }} props
 */
function Galery({
  nextPage,
  viewPageSize,
  text,
  reload,
  children,
  className,
  tabs,
  tabFetchMore,
  carouselTab,
  filter,
  tabPage,
}) {
  const [items, setItems] = React.useState([]);
  const [waiting, setWaiting] = React.useState(true);
  const [activeIndex, setActiveIndex] = React.useState();
  const fetchMore = React.useRef(true);
  const requestPageIndex = React.useRef(0);
  const pageRef = React.useRef(0);
  const parentElement = React.useRef(0);
  const sequence = sequenceState.use();

  viewPageSize = (window.innerWidth - 92) / 120;

  const shouldFetchMore = tabs ? tabFetchMore.current[carouselTab] : fetchMore.current;
  const setShouldFetchMore = (val) => {
    tabs && (tabFetchMore.current[carouselTab] = val);
    fetchMore.current = val;
  };
  const requestPage = () => (tabs ? tabPage.current[carouselTab] : requestPageIndex.current);
  const incrementRequestPage = () => (tabs ? tabPage.current[carouselTab]++ : requestPageIndex.current++);

  async function loadNextPage() {
    if (!fetchMore.current) {
      return;
    }
    const newItems = await nextPage(requestPage());
    setWaiting(false);
    if (newItems.length) {
      setItems((arr) => arr.concat(newItems));
      incrementRequestPage();
    } else {
      setShouldFetchMore(false);
    }
  }

  function onPageChange(pageIndex) {
    setActiveIndex(pageIndex);
    if (fetchMore.current) {
      // TODO add loader while already
      // setWaiting(true)
    }
    loadNextPage();
  }

  React.useEffect(async () => {
    if (text === undefined) {
      return;
    }
    // setItems([])
    if (tabs) {
      fetchMore.current = tabFetchMore.current[carouselTab];
    } else {
      setWaiting(true);
      setShouldFetchMore(true);
      requestPageIndex.current = 0;
      await loadNextPage();
      setActiveIndex(0);
    }
  }, [text, reload]);

  React.useEffect(async () => {
    if (Number.isInteger(carouselTab)) {
      setWaiting(true);
      setShouldFetchMore(true);
      await loadNextPage();
    }
  }, [carouselTab]);

  var length = Math.ceil(items.length / viewPageSize);
  var pages = Array.from({ length }, (_, pageIndex) =>
    items.slice(pageIndex * viewPageSize, (pageIndex + 1) * viewPageSize)
  );

  React.useEffect(() => {
    if (!pageRef.current) {
      return;
    }
    parentElement.current = pageRef.current.parentNode;
    const horizontalScrolling = (evt) => {
      evt.preventDefault();
      parentElement.current.scrollLeft += evt.deltaY;
    };

    parentElement.current?.addEventListener('wheel', horizontalScrolling);

    return () => parentElement.current?.removeEventListener('wheel', horizontalScrolling);
  }, [pageRef.current, carouselTab]);

  React.useEffect(() => {
    if (pageRef.current) {
      parentElement.current = pageRef.current.parentNode;
      function handleScroll() {
        const { offsetWidth } = pageRef.current;
        const { scrollLeft, clientWidth } = parentElement.current;
        if (clientWidth + scrollLeft >= offsetWidth) {
          onPageChange(activeIndex + 1);
        }
      }
      if (parentElement.current) {
        parentElement.current.addEventListener('scroll', handleScroll);
        return () => parentElement.current?.removeEventListener('scroll', handleScroll);
      }
    }
  }, [pageRef.current, carouselTab]);

  if (waiting) {
    return (
      <div className="loader-wrapper" style={{ width: '100%', height: '110px', display: 'flex' }}>
        <Loader size="md" style={{ margin: 'auto', display: 'inline-flex' }} />
      </div>
    );
  }

  return (
    <Carousel
      className={className}
      controls
      indicators={false}
      interval={null}
      onSelect={onPageChange}
      prevLabel=""
      nextLabel=""
      controls={false}
      nextIcon={
        <div className="carousel-control">
          <SVG name="right" />
        </div>
      }
      prevIcon={
        <div className="carousel-control">
          <SVG name="left" />
        </div>
      }
    >
      <Carousel.Item style={{ display: 'flex' }}>
        <div
          className="page"
          data-cy="carousel-page"
          style={{ justifyContent: 'flex-start', display: 'inline-flex', width: 'unset', flexGrow: 1 }}
          ref={pageRef}
        >
          {(filter ? filter(items) : items).map((item, index) => (
            <div className="asset" data-cy="asset-image" data-sid={item.sid} data-name={item.name} key={index}>
              {children(item)}
            </div>
          ))}
        </div>
        {/* TODO add loader inside the carousel / not instead */}
        {/* {waiting &&
                <div style={{ height: '100%', minWidth: '100px', margin: 'auto' }}>
                    <Loader size="md" style={{ margin: "auto" }} />
                </div>
            } */}
      </Carousel.Item>
    </Carousel>
  );
}
Galery.defaultProps = {
  viewPageSize: 10,
};

export const visualPackageReplaceState = entity({
  [AssetType.LOTTIE_SLIDE]: 0,
  [AssetType.LOTTIE_TEXT]: 0,
  [AssetType.LOTTIE_SPEAKER]: 0,
});

export default function Assets() {
  const scenes = scenesState.use((scenes) => scenes.map);
  const sequence = sequenceState.use();
  const lottiePackageSid = sequenceState.use((seq)=> seq.style.visualPackageSid);
  const cuePoints = cuePointsState.use();
  const { user, plan, config } = React.useContext(AppContext);
  const activeEditCuePointId = activeEditCuePointState.use();
  const activeEditCuePoint = cuePoints[activeEditCuePointId] || null;
  const { cuePoint, defaultType, enableTypes, upload } = editCuePointState.use();
  const [search, setSearch] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [newAsset, setNewAsset] = React.useState();
  const [selectedTab, setSelectedTab] = React.useState(defaultType);
  const loadedTempMedia = tempMediaState.use();
  const requestPageSize = 30;
  const transitions = useTransition(cuePoint, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    // delay: 200,
  });
  const text = search || cuePoint?.text;
  const [carouselTab, setCarouselTab] = React.useState(SPEAKER_TABS.GENERIC);


// fetch current visual package assets to validate if there is more than one asset by type
React.useEffect(async () => {
  const lottieAssets = await Asset.list({
    statusIn: [AssetStatus.READY, AssetStatus.PENDING],
    typeIn: [
      AssetType.LOTTIE_SLIDE,
      // AssetType.LOTTIE_IMAGE,
      // AssetType.LOTTIE_VIDEO,
      // AssetType.LOTTIE_ICON,
      AssetType.LOTTIE_SPEAKER,
      // AssetType.LOTTIE_OUTRO,
      AssetType.LOTTIE_TEXT,
      // AssetType.LOTTIE_FRAME,
      // AssetType.LOTTIE_INTRO,
    ],
    folderSidEqual: lottiePackageSid,
  });
  // check if there is more than one asset by type
  const assetsByType = lottieAssets.reduce((acc, asset) => {
    
    if (!acc.hasOwnProperty(asset.type)) {
      acc[asset.type] = 0;
    } else {
      acc[asset.type]++;
    }
    return acc;
  }, {});
  visualPackageReplaceState.set(assetsByType);
}, [lottiePackageSid]);


  React.useEffect(() => {
    if (cuePoint) {
      search && setSearch(null);
      setInputValue(cuePoint.text);
      window.Intercom && window.Intercom('update', { hide_default_launcher: true });
    } else {
      window.Intercom && window.Intercom('update', { hide_default_launcher: true });
      setCarouselTab(SPEAKER_TABS.GENERIC);
      requestPage.current = { [SPEAKER_TABS.GENERIC]: 0, [SPEAKER_TABS.CUSTOM]: 0 };
    }
    if (upload) {
      onUpload();
      editCuePointState.set((upload, ...state) => ({ ...state }));
    }
  }, [cuePoint]);

  // handle active cuePoint type and choose if to display asset picker
  React.useEffect(() => {
    if (!activeEditCuePointId || !activeEditCuePoint || !cuePoint) {
      editCuePointState.set({});
      return;
    }
    const currentType = defaultType;
    let newType = '';
    switch (activeEditCuePoint.type) {
      case CuePoint.TYPE.CLOSED_CAPTION:
        newType = '';
        break;
      case CuePoint.TYPE.SPEAKER:
        if (visualPackageReplaceState[AssetType.LOTTIE_SPEAKER] > 0) {
          newType = 'speaker';
        }
        break;
      default:
        if (activeEditCuePoint.titleType === TitleCuePoint.TITLE_TYPE.TEXT && visualPackageReplaceState[AssetType.LOTTIE_TEXT] > 0) {
          newType = 'text';
        } else if (activeEditCuePoint.titleType === TitleCuePoint.TITLE_TYPE.SLIDE && visualPackageReplaceState[AssetType.LOTTIE_SLIDE] > 0) {
          newType = 'slide';
        }
        break;
    }
    if (newType === '') {
      editCuePointState.set({});
      return;
    }

    if (newType !== currentType || cuePoint.sid !== activeEditCuePoint.sid) {
      editCuePointState.set({});
      setTimeout(() => {
        editCuePointState.set({ cuePoint: activeEditCuePoint, defaultType: newType });
      }, 1);
    }
  }, [activeEditCuePointId, activeEditCuePoint]);

  React.useEffect(() => setSelectedTab(defaultType), [defaultType, cuePoint]);

  function calcRatio() {
    if (sequence.aspectRatio) {
      const [x, y] = sequence.aspectRatio.split(':');
      return y / x;
    }
    return 9 / 16;
  }

  function calcHeight(width) {
    return width * calcRatio();
  }

  function calcSize(w, h) {
    var { width, height } = cuePoint;
    if (h < calcHeight(w)) {
      width = (sequence.height / sequence.width / (h / w)) * width;
    } else {
      height = (sequence.height / sequence.width / (h / w)) * height;
    }
    return { width, height };
  }

  function onKeyPress(e) {
    if (e.which === 13) {
      e.preventDefault();
      e.target.blur();
    }
  }

  function onTextChange(e) {
    const value = e.target.value;
    setSearch(value);
    trackEvent('assets-search', { value });
  }

  function cancel() {
    trackEvent('assets-cancel');
    if (cuePoint.sid === 'tmp') {
      cuePointsState.set(({ tmp, ...rest }) => rest);
    } else {
      cuePointsState.set((cuePoints) => {
        resetCuePointTempValues(cuePoint);
        return { ...cuePoints, [cuePoint.sid]: cuePoint };
      });
    }
    close();
  }

  function close() {
    editCuePointState.set({});
  }

  function onImageOver(url) {
    trackEvent('assets-image-hover');
    cuePointsState.set((cuePoints) => {
      resetCuePointTempValues(cuePoint);
      cuePoint.tmpImageUrl = url;
      return { ...cuePoints, [cuePoint.sid]: cuePoint };
    });
  }

  function onImageSelected({
    url,
    thumbUrl,
    credit,
    creditRequired,
    externalId,
    description,
    width,
    height,
    isSource,
  }) {
    trackEvent('assets-image-select');
    resetCuePoint(cuePoint);
    cuePoint.url = url;
    cuePoint.tmpImageUrl = url;
    cuePoint.credit = credit;
    cuePoint.creditRequired = creditRequired;
    if (!isSource) {
      cuePoint.externalId = externalId;
    }
    if (!cuePoint.width && !cuePoint.height) {
      if (height / sequence.height >= width / sequence.width) {
        cuePoint.width = 100;
        cuePoint.height = null;
      } else {
        cuePoint.width = null;
        cuePoint.height = 100;
      }
    } else if (cuePoint.width && cuePoint.height) {
      cuePoint.width = null;
    }
    saveCuePoint(cuePoint, sequence, scenes, false);
    close();
  }

  function onVideoOver({ url, width, height }) {
    trackEvent('assets-video-hover');

    if (loadedTempMedia[url] === undefined) {
      setLoadedMedia(null, url, true);
      setLoadedMedia(url, url, true);
    }

    var { width, height } = calcSize(width, height);
    cuePointsState.set((cuePoints) => {
      resetCuePointTempValues(cuePoint);
      cuePoint.tmpWidth = width;
      cuePoint.tmpHeight = height;
      cuePoint.tmpVideoUrl = url;
      return { ...cuePoints, [cuePoint.sid]: cuePoint };
    });
  }

  async function onVideoSelected({ url, thumbUrl, credit, creditRequired, width, height, externalId, isSource }) {
    trackEvent('assets-video-select');
    var { width, height } = calcSize(width, height);
    resetCuePoint(cuePoint);
    cuePoint.url = thumbUrl;
    cuePoint.videoUrl = url;
    cuePoint.tmpVideoUrl = url;
    cuePoint.credit = credit;
    cuePoint.creditRequired = creditRequired;
    if (!isSource) {
      cuePoint.externalId = externalId;
    }
    if ((!cuePoint.width && !cuePoint.height) || cuePoint.width > 100 || cuePoint.height > 100) {
      if (height / sequence.height >= width / sequence.width) {
        cuePoint.width = 100;
        cuePoint.height = null;
      } else {
        cuePoint.width = null;
        cuePoint.height = 100;
      }
      cuePoint.top = 0;
      cuePoint.left = 0;
    } else if (cuePoint.height) {
      cuePoint.width = null;
    } else if (cuePoint.width && cuePoint.height) {
      if (height / sequence.height >= width / sequence.width) {
        cuePoint.height = null;
      } else {
        cuePoint.width = null;
      }
    }

    await saveCuePoint(cuePoint, sequence, scenes);
    tempToLoadedMedia(cuePoint.sid, loadedTempMedia[cuePoint.tmpVideoUrl]);
    close();
  }

  function onIconOver(svg) {
    trackEvent('assets-icon-hover');
    cuePointsState.set((cuePoints) => {
      resetCuePointTempValues(cuePoint);
      cuePoint.tmpSvg = svg;
      return { ...cuePoints, [cuePoint.sid]: cuePoint };
    });
  }

  function onIconSelected(svg) {
    trackEvent('assets-icon-select');
    resetCuePoint(cuePoint);
    cuePoint.svg = svg;
    saveCuePoint(cuePoint, sequence, scenes);
    close();
  }

  const selected = menuState.use();

  /**
   * @param {Asset} asset
   */
  async function onAssetSelected(asset, { img, animation } = {}, tabType) {
    trackEvent(`${selected}-edit`, { type: tabType, action: 'design', assetSid: asset.sid });
    console.log('onAssetSelected', asset, img, animation, tabType);
    await Base.executeAPIQueue(); // execute all pending requests

    const getVisualSequenceAssetSidByTabType = (tabType) => {
      switch (tabType) {
        case 'speaker':
          return sequence.style.speakerAssetSid;
        case 'text-slide':
          return sequence.style.slideAssetSid;
        case 'graphic-text':
          return sequence.style.textAssetSid;
      }
    };

    if (img) {
      if (!cuePoint.width && !cuePoint.height) {
        if (img.naturalHeight / sequence.height >= img.naturalWidth / sequence.width) {
          cuePoint.width = 100;
          cuePoint.height = null;
        } else {
          cuePoint.width = null;
          cuePoint.height = 100;
        }
      } else if (cuePoint.width && cuePoint.height) {
        cuePoint.width = null;
      }
    } else if (animation) {
      if (getVisualSequenceAssetSidByTabType(tabType) === asset.sid) {
        cuePoint.assetSid = null;
      } else {
        cuePoint.assetSid = asset.sid; // set asset sid
      }
      cuePoint.colors = null; // reset colors
      cuePoint.save();
    }
    // close();
  }

  function onImageUpload(file, set) {
    return new Compressor(file, {
      strict: false,
      maxWidth: sequence.width,
      maxHeight: sequence.height,
      checkOrientation: true,
      convertSize: Infinity,
      success: async (compressed) => {
        var asset = new Asset();
        asset.type = Asset.TYPE.CUE_POINT_IMAGE;
        asset.name = compressed.name;

        await asset.save();

        const fileUploader = new FileUploader({
          sequence,
          file: compressed,
          content: asset,
        });
        await fileUploader.uploadAsset();
        set && setNewAsset(asset);
      },
      error: (err) => {
        console.error(err);
        handleError({
          title: 'Image Upload Compression',
          message: err.message,
          responseError: err,
        });
      },
    });
  }

  async function onVideoUpload(file, set) {
    const asset = new Asset();
    asset.type = Asset.TYPE.CUE_POINT_IMAGE;
    asset.name = file.name;

    await asset.save();

    const fileUploader = new FileUploader({
      sequence,
      file,
      content: asset,
      minDuration: 1,
      maxDuration: 7,
    });
    await fileUploader.uploadAsset();
    set && setNewAsset(asset);
  }

  function onUploadFileSelected({ target }) {
    trackEvent('assets-upload-files', { count: target.files.length });
    for (var i = 0; i < target.files.length; i++) {
      let file = target.files[i];
      if (file.type.startsWith('image/')) {
        onImageUpload(file, i === 0);
      } else if (file.type.startsWith('video/')) {
        onVideoUpload(file, i === 0);
      } else {
        handleError({
          title: 'Visual element upload',
          message: `File type ${file.type} is not supported`,
        });
      }
    }
  }

  function onUpload() {
    trackEvent('assets-upload-click');
    var input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', true);
    input.setAttribute('accept', 'image/*, video/*');
    input.addEventListener('change', onUploadFileSelected, { once: true });
    input.click();
  }

  function onTabSelect(tab) {
    trackEvent('assets-tab', { tab });
    setSelectedTab(tab);
  }

  function calcColor(color, colorIndex) {
    return color ? color : colorIndex ? undefined : null;
  }

  let classes = ['sequence-font'];
  if (cuePoint?.titleType === TitleCuePoint.TITLE_TYPE.SLIDE) {
    classes.push('slide-element');
  }
  if (cuePoint?.fontItalic) {
    classes.push('font-italic');
  }
  if (cuePoint?.fontWeight === undefined) {
    classes.push('font-' + sequence?.style?.fontWeight?.toLowerCase());
  } else {
    classes.push('font-' + cuePoint.fontWeight.toLowerCase());
  }

  if (cuePoint?.backgroundColorIndex) {
    classes.push('color-bg-dominant-' + cuePoint.backgroundColorIndex);
  }
  if (cuePoint?.colorIndex) {
    classes.push('color-text-dominant-' + cuePoint.colorIndex);
  }

  const fetchMore = React.useRef({ [SPEAKER_TABS.GENERIC]: true, [SPEAKER_TABS.CUSTOM]: true });
  const requestPage = React.useRef({ [SPEAKER_TABS.GENERIC]: 0, [SPEAKER_TABS.CUSTOM]: 0 });
  const orgFilter = (items) =>
    items.filter((item) =>
      carouselTab === SPEAKER_TABS.GENERIC
        ? item.ownerSid == 0 || !item.ownerSid || item.ownerSid === user.sid
        : item.ownerSid === user.orgSid
    );
  const filterOwnerSid = carouselTab === SPEAKER_TABS.GENERIC ? null : user.orgSid;

  const GenerictRef = React.useRef();
  const CustomRef = React.useRef();

  const LottiesTabs = [
    { name: 'Generic', value: SPEAKER_TABS.GENERIC, ref: GenerictRef },
    { name: 'Custom', value: SPEAKER_TABS.CUSTOM, ref: CustomRef },
  ];

  const withUserPs = useUserPs();

  function LottieGallery({ typeEqual, callback }) {
    return (
      <>
        {/* {transitions((styles, item) => item && <animated.div style={styles} className="backdrop"></animated.div>)} */}
        {cuePoint && (
          <Card className="bottom-dialog assets">
            <Card.Body style={{ paddingTop: user.orgSid ? '10px' : '45px' }}>
              <button className="btn-close" style={{ right: 30, top: 10, fontSize: 22 }} onClick={cancel} />
              {/* spacer div*/}
              <div style={{ height: 20 }}></div>
              {/* {user.orgSid && <Tabs tabsItems={LottiesTabs} secondTabName="Custom" onChange={tab => setCarouselTab(tab)} />} */}
              <Galery
                className="speaker"
                text={null}
                reload={newAsset}
                nextPage={(requestPageIndex) =>
                  Asset.list(
                    {
                      statusIn: [AssetStatus.READY, AssetStatus.PENDING],
                      typeIn: [typeEqual],
                      folderSidEqual: sequence.style.visualPackageSid,
                    },
                    {
                      pageIndex: requestPageIndex + 1,
                      pageSize: requestPageSize,
                    }
                  )
                }
                tabs={true}
                tabFetchMore={fetchMore}
                carouselTab={carouselTab}
                tabPage={requestPage}
              >
                {callback}
              </Galery>
            </Card.Body>
          </Card>
        )}
      </>
    );
  }

  if (selectedTab === 'speaker') {
    return LottieGallery({
      typeEqual: AssetType.LOTTIE_SPEAKER,
      callback: (asset) => {
        const src = `${config.CONTENT_URL}/c/t/asset/f/${asset.sid}/${sequence.aspectRatio}/${asset.contentIdentifier.thumbnailVersion}.json`;
        let isSelected = false;
        if (cuePoint.assetSid) {
          isSelected = cuePoint.assetSid === asset.sid;
        } else {
          isSelected = sequence.style.speakerAssetSid === asset.sid;
        }

        return (
          <div
            style={{ color: isSelected ? '#1F9FFB' : 'unset' }}
            onClick={(animation) => onAssetSelected(asset, { animation }, 'speaker')}
          >
            {asset.name}
            {/* <SpeakerElement
                        src={src}
                        autoPlay={true}
                        loop={true}
                        cuePoint={cuePoint}
                        inAssetsDialog={true}
                        moveable={false}
                        animationSid={asset.sid}
                        onClick={animation => onAssetSelected(asset, { animation }, 'speaker')}
                    /> */}
          </div>
        );
      },
    });
  }

  if (selectedTab === 'text') {
    return LottieGallery({
      typeEqual: AssetType.LOTTIE_TEXT,
      callback: (asset) => {
        const src = `${config.CONTENT_URL}/c/t/asset/f/${asset.sid}/${sequence.aspectRatio}/${asset.contentIdentifier.thumbnailVersion}.json`;
        let isSelected = false;
        if (cuePoint.assetSid) {
          isSelected = cuePoint.assetSid === asset.sid;
        } else {
          isSelected = sequence.style.textAssetSid === asset.sid;
        }

        return (
          <div
            style={{ color: isSelected ? '#1F9FFB' : 'unset' }}
            onClick={(animation) => onAssetSelected(asset, { animation }, 'graphic-text')}
          >
            {asset.name}
            {/* <TextCuePointElement
                        src={src}
                        inAssetsDialog={true}
                        autoPlay={true}
                        loop={true}
                        cuePoint={cuePoint}
                        moveable={false}
                        animationSid={asset.sid}
                        onClick={animation => onAssetSelected(asset, { animation }, 'graphic-text')}
                    /> */}
          </div>
        );
      },
    });
  }

  if (selectedTab === 'slide') {
    return LottieGallery({
      typeEqual: AssetType.LOTTIE_SLIDE,
      callback: (asset) => {
        const src = `${config.CONTENT_URL}/c/t/asset/f/${asset.sid}/${sequence.aspectRatio}/${asset.contentIdentifier.thumbnailVersion}.json`;

        let isSelected = false;
        if (cuePoint.assetSid) {
          isSelected = cuePoint.assetSid === asset.sid;
        } else {
          isSelected = sequence.style.slideAssetSid === asset.sid;
        }

        return (
          <div
            style={{ color: isSelected ? '#1F9FFB' : 'unset' }}
            onClick={(animation) => onAssetSelected(asset, { animation }, 'text-slide')}
          >
            {asset.name}
            {/* <TextCuePointElement
                        src={src}
                        inAssetsDialog={true}
                        autoPlay={true}
                        loop={true}
                        cuePoint={cuePoint}
                        moveable={false}
                        animationSid={asset.sid}
                        onClick={animation => onAssetSelected(asset, { animation }, 'text-slide')}
                    /> */}
          </div>
        );
      },
    });
  }

  if (selectedTab === 'intro') {
    return LottieGallery({
      typeEqual: AssetType.LOTTIE_INTRO,
      callback: (asset) => {
        const src = `${config.CONTENT_URL}/c/t/asset/b/${asset.sid}/${asset.contentIdentifier.thumbnailVersion}.json`;
        return (
          <div>
            <TextCuePointElement
              src={src}
              inAssetsDialog={true}
              autoPlay={true}
              loop={true}
              cuePoint={cuePoint}
              moveable={false}
              animationSid={asset.sid}
              onClick={(animation) => onAssetSelected(asset, { animation }, 'intro')}
            />
          </div>
        );
      },
    });
  }

  if (selectedTab === 'outro') {
    return LottieGallery({
      typeEqual: AssetType.LOTTIE_OUTRO,
      callback: (asset) => {
        const src = `${config.CONTENT_URL}/c/t/asset/b/${asset.sid}/${asset.contentIdentifier.thumbnailVersion}.json`;
        return (
          <div>
            <TextCuePointElement
              src={src}
              inAssetsDialog={true}
              autoPlay={true}
              loop={true}
              cuePoint={cuePoint}
              moveable={false}
              animationSid={asset.sid}
              onClick={(animation) => onAssetSelected(asset, { animation }, 'outro')}
            />
          </div>
        );
      },
    });
  }

  if (selectedTab === 'transition') {
    return LottieGallery({
      typeEqual: Asset.TYPE.LOTTIE_TRANSITION,
      callback: (asset) => {
        const src = `${config.CONTENT_URL}/c/t/asset/b/${asset.sid}/${asset.contentIdentifier.thumbnailVersion}.json`;
        return (
          <div>
            <TextCuePointElement
              src={src}
              inAssetsDialog={true}
              autoPlay={true}
              loop={true}
              cuePoint={cuePoint}
              moveable={false}
              animationSid={asset.sid}
              onClick={(animation) => onAssetSelected(asset, { animation }, 'transition')}
            />
          </div>
        );
      },
    });
  }

  return (
    <>
      {/* {transitions((styles, item) => item && <animated.div style={styles} className="backdrop"></animated.div>)} */}
      {cuePoint && (
        <Card className="bottom-dialog assets">
          <Card.Body data-cy="assets-dialog-body">
            <button className="btn-close" onClick={cancel} />
            <Tab.Container mountOnEnter defaultActiveKey={defaultType}>
              <Nav variant="tabs" fill onSelect={onTabSelect}>
                <Nav.Item>
                  <Nav.Link eventKey="text" disabled={enableTypes && !enableTypes.includes('text')}>
                    <SVG name="text" title="Text" /> Text
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="image" disabled={enableTypes && !enableTypes.includes('image')}>
                    <SVG name="image" title="Image" />
                    Image
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="video" disabled={enableTypes && !enableTypes.includes('video')}>
                    <SVG name="video" title="Video" />
                    Video
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="icon" disabled={enableTypes && !enableTypes.includes('icon')}>
                    <SVG name="icon" title="Icon" /> Icon
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="my-uploads"
                    eventKey="asset"
                    disabled={enableTypes && !enableTypes.includes('asset')}
                  >
                    <SVG name="upload" title="My Uploads" />
                    My Uploads
                  </Nav.Link>
                </Nav.Item>
                {selectedTab === 'asset' ? (
                  <button className="btn upload-btn" onClick={onUpload}>
                    <SVG name="upload" />
                    <span>Upload</span>
                  </button>
                ) : (
                  <label className="search">
                    <SVG name="search" />
                    <input
                      value={inputValue}
                      placeholder="Search for images, icons, footage etc."
                      onKeyPress={onKeyPress}
                      onBlur={onTextChange}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    <span>POWERED BY SHUTTERSTOCK</span>
                  </label>
                )}
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="image">
                  <Galery
                    className="image"
                    text={text}
                    nextPage={(requestPageIndex) =>
                      TitleCuePoint.images(sequence.sid, text, requestPageSize, requestPageIndex)
                    }
                  >
                    {(img) => {
                      const loader = new Image();
                      loader.src = img.url;

                      return (
                        <img
                          src={img.thumbUrl}
                          alt={img.description}
                          onMouseOver={() => onImageOver(img.url)}
                          onClick={() => onImageSelected(img)}
                        />
                      );
                    }}
                  </Galery>
                </Tab.Pane>
                <Tab.Pane eventKey="video">
                  <Galery
                    className="video"
                    text={text}
                    nextPage={(requestPageIndex) =>
                      TitleCuePoint.videos(sequence.sid, text, requestPageSize, requestPageIndex)
                    }
                  >
                    {/* {video => <video 
                                controls={false}
                                crossOrigin="anonymous"
                                loop
                                preload="auto"
                                src={video.url}
                                poster={video.thumbUrl}
                                muted
                                autoPlay={false}
                                onMouseOver={e => {
                                    e.target.play();
                                    onVideoOver(video.url)
                                }}
                                onMouseOut={e => e.target.pause()}
                                onClick={e => e.target.paused ? e.target.play() : e.target.pause()}
                            />} */}
                    {(video) => (
                      <img
                        src={video.thumbUrl}
                        alt={video.description}
                        onMouseOver={() => onVideoOver(video)}
                        onClick={() => onVideoSelected(video)}
                      />
                    )}
                  </Galery>
                </Tab.Pane>
                <Tab.Pane eventKey="icon">
                  <Galery
                    className="icon"
                    text={text}
                    nextPage={(requestPageIndex) =>
                      TitleCuePoint.icons(sequence.sid, text, requestPageSize, requestPageIndex)
                    }
                  >
                    {/* {svg => <img
                                src={`data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svg)))}`}
                                alt="Icon"
                                onMouseOver={() => onIconOver(svg)}
                                onClick={() => onIconSelected(svg)}
                            />} */}
                    {(svg) => (
                      <div
                        dangerouslySetInnerHTML={{ __html: svg }}
                        title="Icon"
                        onMouseOver={() => onIconOver(svg)}
                        onClick={() => onIconSelected(svg)}
                      />
                    )}
                  </Galery>
                </Tab.Pane>
                <Tab.Pane eventKey="asset">
                  <Galery
                    className="asset"
                    text={text}
                    reload={newAsset}
                    nextPage={(requestPageIndex) =>
                      Asset.list(
                        {
                          statusIn: [AssetStatus.READY, AssetStatus.PENDING],
                          typeIn: [
                            Asset.TYPE.CUE_POINT_IMAGE,
                            Asset.TYPE.CUE_POINT_VIDEO,
                            // Asset.TYPE.CUE_POINT_PRESENTATION,
                          ],
                        },
                        {
                          pageIndex: requestPageIndex + 1,
                          pageSize: requestPageSize,
                        }
                      )
                    }
                  >
                    {(asset) => {
                      // TODO onVideoOver for video assets
                      const src = withUserPs(`${config.CHUNKS_URL}/t/asset/b/${asset.sid}/${asset.name}`);
                      return (
                        <img
                          src={src}
                          alt={asset.name}
                          onMouseOver={() => onImageOver(src)}
                          onClick={({ target }) => onAssetSelected(asset, { img: target })}
                        />
                      );
                    }}
                  </Galery>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      )}
    </>
  );
}
