import * as Base from './base/user';
import Preset from './preset';

export const UserRole = Base.UserRole;

export interface IUser extends Base.IUser {

}

export interface IUserProperties extends Base.IUserProperties {

}

export interface IUserFilter extends Base.IUserFilter {

}

export default class User<IType extends IUser = IUser, ITypeProperties extends IUserProperties = IUserProperties> extends Base.User<IType, ITypeProperties> implements IUser {
  preset?: Preset;

  get UIFLAGS() {
    const uiFlags = this.uiFlags as number;
    return {
      FEEDBACK_SENT: uiFlags & (1 << 0),
      TRIMMER: uiFlags & (1 << 1),
      SMART_LIBRARY: uiFlags & (1 << 2),
      MOBILE_UPLOAD: uiFlags & (1 << 3),
      TRANSLATE_SEQUENCE: uiFlags & (1 << 4),
      CC_MANUAL_POSITION: uiFlags & (1 << 5),
      NO_UPLOAD_LIMIT: uiFlags & (1 << 6),
      EXPERIMENTAL_KEEP_BLANK_WHEN_CUT_WORD: uiFlags & (1 << 7),
      DENOISER_ENABLED: uiFlags & (1 << 8),
      FRAME_PARAMS: uiFlags & (1 << 9),
      ENABLE_NEW_CREATION_FLOW: uiFlags & (1 << 10),
      ALLOW_10_ASSETS_IN_PROJECT: uiFlags & (1 << 11),
      INTRO_OUTRO_DURATION_CHANGE: uiFlags & (1 << 12),
      ___UNUSED_FLAG_13: uiFlags & (1 << 13),
      ENABLE_NEW_FAST_CREATION_FLOW: uiFlags & (1 << 14),
      ___UNUSED_FLAG_15: uiFlags & (1 << 15),
      RANDOMIZE_GRAPHICS_BACKGROUND_COLOR: uiFlags & (1 << 16),
      DONT_SEND_FOOTAGE_EMAIL: uiFlags & (1 << 17),
      SHOW_ADDITIONAL_CC_STYLE: uiFlags & (1 << 18),
      SPLIT_TOPIC_BY_TIME: uiFlags & (1 << 19),
      USE_NEW_MODEL_GPT_4O: uiFlags & (1 << 20),
      TRANSCRIPT_WITH_SUMIT: uiFlags & (1 << 21),
      SKIP_CONTENT_EDIT_EXAMPLE_ASSET: uiFlags & (1 << 22),
      CLONE_SEQUENCE_ASPECT_RATIO_CHANGE: uiFlags & (1 << 23),
      GET_CONTENT_BY_SPECIFIC_PARTS: uiFlags & (1 << 24),
      ADD_BLACK_GRADIENT: uiFlags & (1 << 25),
      CHECK_VISUAL_CONFLICTS_ONLY_WITH_FIRST_SPEAKER: uiFlags & (1 << 26),
      ENABLE_FOLDERS_FEATURE: uiFlags & (1 << 27),
      ENABLE_CUSTOM_SNIPPETS: uiFlags & (1 << 28),
      PROCESS_MUSIC_IN_BACKGROUND: uiFlags & (1 << 29),
      DONT_CREATE_HIGHLIGHTS: uiFlags & (1 << 30),
      CLIP_FOOTAGE_BY_TIMESTAMP: uiFlags & (1 << 31),
    };
  }
}