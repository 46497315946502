
import * as Base from './base/graphicCuePoint';
	
export {default as GraphicColor} from './graphicColor';
	
export {default as GraphicAsset} from './graphicAsset';
	
export {default as GraphicAnimation} from './graphicAnimation';

export const GraphicCuePointSourceStatus = Base.GraphicCuePointSourceStatus;

export interface IGraphicCuePoint extends Base.IGraphicCuePoint {

}

export interface IGraphicCuePointProperties extends Base.IGraphicCuePointProperties {

}

export interface IGraphicCuePointFilter extends Base.IGraphicCuePointFilter {

}

export default class GraphicCuePoint<IType extends IGraphicCuePoint = IGraphicCuePoint, ITypeProperties extends IGraphicCuePointProperties = IGraphicCuePointProperties> extends Base.GraphicCuePoint<IType, ITypeProperties> implements IGraphicCuePoint {
}