
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export enum ShopperStatus {
	ACTIVE = 1,
	DELETED = 3,
};

export interface IShopper extends IBase {
	status?: number | null;
	shopperId?: number | null;
	userSid?: string | null;
	orgSid?: string | null;
	ccType?: string | null;
	exp?: string | null;
	lastDigits?: string | null;
	zip?: string | null;
	name?: string | null;
	fraudSessionId?: string | null;
	updateUrl?: string | null;
	token?: string | null;
}

export interface IShopperProperties extends IBaseProperties {
	status?: boolean;
	shopperId?: boolean;
	userSid?: boolean;
	orgSid?: boolean;
	ccType?: boolean;
	exp?: boolean;
	lastDigits?: boolean;
	zip?: boolean;
	name?: boolean;
	fraudSessionId?: boolean;
	updateUrl?: boolean;
	token?: boolean;
}

export interface IShopperFilter extends IBaseFilter {
	userSidEqual?: string;
}

export class Shopper<IType extends IShopper = IShopper, ITypeProperties extends IShopperProperties = IShopperProperties> extends Base<IType, ITypeProperties> implements IShopper {
	
	static className = 'Shopper';
	static NAME = 'shopper';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		shopperId: true,
		userSid: true,
		orgSid: true,
		ccType: true,
		exp: true,
		lastDigits: true,
		zip: true,
		name: true,
		fraudSessionId: true,
		updateUrl: true,
	};
	
	/**
	 * @deprecated use ShopperStatus instead
	 */
	static STATUS: any = {
		ACTIVE: ShopperStatus.ACTIVE,
		DELETED: ShopperStatus.DELETED,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get shopperId(): number | null | undefined {
		return this.values.shopperId
	}
			
	get userSid(): string | null | undefined {
		return this.values.userSid
	}
			
	set userSid(value: string | null | undefined) {
		if(this.values.userSid !== value) {
			this.propertyChanged('userSid', value);
			this.values.userSid = value;
		}
	}
			
	get orgSid(): string | null | undefined {
		return this.values.orgSid
	}
			
	set orgSid(value: string | null | undefined) {
		if(this.values.orgSid !== value) {
			this.propertyChanged('orgSid', value);
			this.values.orgSid = value;
		}
	}
			
	get ccType(): string | null | undefined {
		return this.values.ccType
	}
			
	set ccType(value: string | null | undefined) {
		if(this.values.ccType !== value) {
			this.propertyChanged('ccType', value);
			this.values.ccType = value;
		}
	}
			
	get exp(): string | null | undefined {
		return this.values.exp
	}
			
	set exp(value: string | null | undefined) {
		if(this.values.exp !== value) {
			this.propertyChanged('exp', value);
			this.values.exp = value;
		}
	}
			
	get lastDigits(): string | null | undefined {
		return this.values.lastDigits
	}
			
	set lastDigits(value: string | null | undefined) {
		if(this.values.lastDigits !== value) {
			this.propertyChanged('lastDigits', value);
			this.values.lastDigits = value;
		}
	}
			
	get zip(): string | null | undefined {
		return this.values.zip
	}
			
	set zip(value: string | null | undefined) {
		if(this.values.zip !== value) {
			this.propertyChanged('zip', value);
			this.values.zip = value;
		}
	}
			
	get name(): string | null | undefined {
		return this.values.name
	}
			
	set name(value: string | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	get fraudSessionId(): string | null | undefined {
		return this.values.fraudSessionId
	}
			
	set fraudSessionId(value: string | null | undefined) {
		if(this.values.fraudSessionId !== value) {
			this.propertyChanged('fraudSessionId', value);
			this.values.fraudSessionId = value;
		}
	}
			
	get updateUrl(): string | null | undefined {
		return this.values.updateUrl
	}
			
	set updateUrl(value: string | null | undefined) {
		if(this.values.updateUrl !== value) {
			this.propertyChanged('updateUrl', value);
			this.values.updateUrl = value;
		}
	}
			
	set token(value: string | null | undefined) {
		if(this.values.token !== value) {
			this.propertyChanged('token', value);
			this.values.token = value;
		}
	}
			
	
	static get<IType extends IShopper = IShopper>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IShopperFilter = IShopperFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IShopperFilter = IShopperFilter, IType extends IShopper = IShopper>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
