
import * as Base from './base/graphicAsset';

export const GraphicAssetType = Base.GraphicAssetType;

export interface IGraphicAsset extends Base.IGraphicAsset {

}

export interface IGraphicAssetProperties extends Base.IGraphicAssetProperties {

}

export interface IGraphicAssetFilter extends Base.IGraphicAssetFilter {

}

export default class GraphicAsset<IType extends IGraphicAsset = IGraphicAsset, ITypeProperties extends IGraphicAssetProperties = IGraphicAssetProperties> extends Base.GraphicAsset<IType, ITypeProperties> implements IGraphicAsset {
}