import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const PlugLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: ${COLORS.peech_neutral_white};
  background: ${COLORS.peech_gray_100};
  z-index: 3;
  @keyframes taadaa {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
  div {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: ${COLORS.peech_neutral_white};
    animation: taadaa 2s infinite;
  }
`;
