import styled from '@emotion/styled';
import { COLORS } from '../../../constants/colors';

import {
  FakeTooltip,
  MenuHeaderWrapper,
  StyledMenuItem,
} from '../../ui-components/Menu/MenuComponents.styled';

export const MainContainer = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  position: relative;
  font-family: 'Open Sans';
  color: ${COLORS.peech_neutral_black};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
    ${MenuHeaderWrapper} {
        .menu-item {
            padding: 8px 10px 15px 10px!important
        }
        &:after {
      left: 5px;
      right: 5px;
    }
  }

  ${FakeTooltip} {
    width: 94%;
  }

  ${StyledMenuItem} {
    width: 95%;
    margin-bottom: 18px;
  }
`;

export const UseIntroOutroDiv = styled.div`
  position: relative;
  width: 100%;
  padding: 10px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  border: unset;
`;

export const IntroOutroContainer = styled.div`
  width: 106%;
  height: calc(100% - 122px);
  padding-inline: 26px 10px;
  overflow-y: scroll;
  /* direction: rtl; */

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${COLORS.peech_gray_300};
    width: 2px;
  }
`;

export const BlockWrapper = styled.div`
  width: 96%;
  min-height: 30px;
  height: fit-content;
`;

export const LottieContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 116px;
  height: fit-content;
  border-radius: 6px;
`;

export const IntroOutroInput = styled.textarea`
  margin-top: 5px;
  width: 99%;
  max-height: 30px;
  border: 0.5px solid;
  border-color: ${COLORS.peech_gray_400};
  border-radius: 3px;
  background-color: ${COLORS.peech_neutral_white};
  color: ${COLORS.peech_gray_900};
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 5px;
  resize: none;
  &:hover {
    background-color: ${COLORS.peech_gray_100};
  }
  &:focus {
    outline: none !important;
    border: solid 1px;
    border-color: ${COLORS.peech_primary_2_500};
  }
  transition: background-color 250ms ease-out;
`;

export const IntroOutroContentEditable = styled.div`
  margin-top: 5px;
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border: 0.5px solid;
  border-color: ${COLORS.peech_gray_300};
  border-radius: 3px;
  background-color: ${COLORS.peech_neutral_white};
  padding: 5px;
  color: ${COLORS.peech_gray_900};
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
`;

export const LogoContainer = styled.div`
  width: 100%;
`;

export const RangeLabels = styled.div<{ $spanRightLeftMargin?: string | undefined; }>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: 7px;
  span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: ${props => props.$spanRightLeftMargin || 'unset'};
    margin-right: ${props => props.$spanRightLeftMargin || 'unset'};
  }
`;

export const ScrollerWrapper = styled.div<{ $marginTop?: string | undefined; }>`
  display: flex;
  justify-content: center;
  padding-inline: 6px;
  overflow: visible;
  flex: 1;
  margin-top: ${props => props.$marginTop || '30px'};
`;
