import classNames from 'classnames';
import React from 'react';
import './button.scss';

export enum ButtonVariant {
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
    Ghost = 'ghost',
    gray = 'gray'
}

export enum ButtonSize {
    Large = 'lg',
    Medium = 'md',
    Small = 'sm',
    ExtraSmall = 'xs',
}

interface ButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    variant: ButtonVariant;
    size: ButtonSize;
    children?: any;
    label?: string;
}

// export default function Button({ type, size, label, children, ...props }: ButtonProps & {}) {
//     const typeClass = `peech-button--${type}`
//     const sizeClass = `peech-button--${size}`



export default function Button({ className, variant, size, children, label, ...props }: ButtonProps & {}) {
    const buttonClassNames = classNames(`peech-button peech-button--${variant} peech-button--${size}`, { [className]: !!className })

    return (
        <button
            type="button"
            className={buttonClassNames}
            {...props}
        >
            {children && children}{label && label}
        </button>
    )
}

Button.defaultProps = {
    type: 'primary',
    size: 'lg',
    onClick: undefined,
};
