import { useLocation } from '@reach/router';
import classNames from 'classnames';
import React from 'react';
import { Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import SVG from '../components/svg';
import { AppContext } from '../lib/app-context';

import Preset from '../client/preset';
import Button from '../ui-components/Button';
import ImageWithPlaceholder from '../ui-components/ImageWithPlaceholder/ImageWithPlaceholder';

import ContentEditable from 'react-contenteditable';
import { handleError } from '../state/error';
import BrandkitPage from './brandKitPage';

import { loadedPresetsState, unloadPreset } from '../state/local';
import CustomTypeahead from '../ui-components/CustomTypeahead/CustomTypeahead';

import { navigate } from 'gatsby';
import CreateBrandkit from '../dialogs/CreateBrandkit/create-brandkit-dialog';
import Loader from '../ui-components/Loader';

import OptionsMenuPopover from '../ui-components/OptionsMenu/OptionsMenuPopover';
import { trackEvent } from '../utils/analityics.utils';
import './brand.scss';
import User from '../client/user';
import useUserPs from '../hooks/useUserPs';
import {isInIframe} from "../utils/window.utils";

function DeleteConfirmation({ preset, onHide, onRemove }) {
  async function removeBrandKit() {
    onHide();
    try {
      await preset.remove();
      onRemove && onRemove(preset.sid);
    } catch (err) {
      handleError({
        title: 'Deleting Brand kit',
        message: err.message,
        responseError: err,
      });
    }
  }
  
  return (
    <Modal show={true} className="brandkit-delete" id="delete-confirmation-dialog" onHide={onHide} centered>
      <Modal.Body>
        <Modal.Title>Delete Confirmation</Modal.Title>
        <p>
          Once deleting a preset, you will no longer have access to
          <br />
          use that preset anymore.
        </p>
        <p>
          <b>Are you sure you want to delete this preset?</b>
        </p>
        <Button className="btn btn-secondary" variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button className="btn btn-danger" id="asset-delete-button" variant="danger" onClick={removeBrandKit}>
          Delete
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export function BrandKit({ preset, setBrandkitPage, scrollingContainerRef, allowDelete = true }) {
  const [verifyDelete, setVerifyDelete] = React.useState(false);
  const presetLogoAsset = preset.getDefaultLogo();
  const { config, user, setUser } = React.useContext(AppContext);
  const titleField = React.useRef();
  const isDefaultChecked = preset.status === Preset.STATUS.IS_DEFAULT;

  function onTitleKeyPress(e) {
    if (e.which === 13) {
      e.preventDefault();
      e.target.blur();
    }
  }

  function onTitleBlur({ target }) {
    var title = target.innerText.trim();
    if (title.length && title !== preset.name) {
      preset.name = title;
      preset.save();
    } else {
      target.innerText = preset.name;
    }
  }

  function onRemove(sid) {
    unloadPreset(sid, { user, setUser });
    trackEvent('brand-kit-delete', { brandSid: sid });
  }

  const actions = [
    {
      onClick: () => setVerifyDelete(true),
      key: 'delete',
      icon: 'delete',
      text: 'Delete',
      toolTip: 'Delete brand preset',
      disabled: !allowDelete || preset.status === Preset.STATUS.IS_DEFAULT,
    },
    {
      onClick: () => {
        // send 
        isInIframe() && window.top?.postMessage({eventName: 'OPEN_RENAME_BRAND_PRESET_MODAL', brandPresetName: preset?.name, presetSid: preset?.sid}, '*')
      },
      key: 'rename',
      icon: 'edit',
      text: 'Rename',
      toolTip: 'Rename brand preset',
      disabled: false,
    },
  ];

  if (preset.status === Preset.STATUS.DELETED) {
    return null;
  }

  React.useEffect(() => {
    user?.UIFLAGS.SMART_LIBRARY && window.Intercom && window.Intercom('shutdown');
  }, [window.Intercom]);

  React.useEffect(() => {
    // get all presets (brand preset) after rename event
    const onMessage = async (event) => {
      if (event.data === "RENAME_BRAND_PRESET_SUBMITTED") {
        try {
          var presets = await Preset.list();
        } catch (err) {
          console.error(err);
        }
        loadedPresetsState.set(presets || []);
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);

  const withUserPs = useUserPs();

  return (
    <>
      <Col xs="12" sm="6" lg="4" xl="3" xxl="2" className="projects-col" style={{ display: 'flex' }}>
        {verifyDelete && (
          <DeleteConfirmation onHide={() => setVerifyDelete(false)} preset={preset} onRemove={onRemove} />
        )}
        <div className="brandKit-item" onClick={() => setBrandkitPage(preset)}>
          <div className="image-wrapper">
            <div className="brandKit-item-thumb" data-cy="projects-item-brandKit" data-preset-sid={preset.sid}>
              {/* Options Menu*/}
              <div
                className="options-button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <OptionsMenuPopover options={actions} scrollingContainerRef={scrollingContainerRef}/>
              </div>
              {/* Thumbnail */}
              <ImageWithPlaceholder
                source={withUserPs(`${config.CHUNKS_URL}/asset/${presetLogoAsset?.assetSid}/type/${user?.cacheVersion}.png?ps=${User.ps}`)}
                alt={user?.preset}
                className="brandKit-item-img"
              />
            </div>
          </div>
          <div className="brandKit-detalis">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="cut-included-tooltip" className="tooltip-peech-dark">
                  {preset.name || 'First Brand Kit'}
                </Tooltip>
              }
            >
              <ContentEditable
                className="brandkit-name"
                html={preset.name || 'First Brand Kit'}
                disabled={true}
                onKeyPress={onTitleKeyPress}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onBlur={onTitleBlur}
                innerRef={titleField}
              />
            </OverlayTrigger>

            <div
              className="default-selection"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <label className="asset--checkbox" onChange={(e) => preset.onDefaultChange(e, { user, setUser })}>
                <input type="checkbox" checked={isDefaultChecked} readOnly />
                <span className="checkmark">
                  <SVG name="check" viewBox="0 0 36 36" />
                </span>
              </label>
              <div className="set-brandkit-default">Set as default</div>
            </div>
          </div>
          <div className="brandKit-item-header"></div>
        </div>
      </Col>
    </>
  );
}

export default function Brand() {
  const { user, setUser } = React.useContext(AppContext);
  const [showBrandKitModal, setShowBrandKitModal] = React.useState(false);
  const brandRef = React.useRef();
  const presets = loadedPresetsState.use();
  const [brandkitPage, setBrandkitPage] = React.useState(false);
  const preset = loadedPresetsState.use((presets) => presets?.find((p) => p.sid === brandkitPage?.sid));
  const [play, setPlay] = React.useState(false);
  const presetInputRef = React.useRef();
  const location = useLocation();

  const isDefaultChecked = preset?.status === Preset.STATUS.IS_DEFAULT;

  const scrollingContainerRef = React.useRef();

  async function onPresetChange(preset) {
    if (preset.length) {
      setBrandkitPage(preset[0]);
    }
  }

  React.useEffect(() => {
    if (location?.state?.fromSidebar) {
      setBrandkitPage(false);
      console.log('checking in brand 1');
      navigate('/brand', { state: { fromSidebar: false } });
    }
  }, [location?.state?.fromSidebar]);

  React.useEffect(() => {
    if (location?.state?.brandkit) {
      setBrandkitPage(presets?.find((p) => p.sid === location?.state?.brandkit));
      window.history.replaceState({}, document.title);
    }
  }, [location?.state?.brandkit]);

  const BrandKitItem = () => (
    <div className="brand-content">
      <BrandkitPage preset={preset} setPreset={setBrandkitPage} />
    </div>
  );

  const MyBrands = () => {
    if (!presets?.length) {
      return (
        <div data-cy="brand-placerholder" className="brand-plaecholder">
          <SVG className="face-color" name="smiley-face" width="63" height="63" viewBox="0 0 63 63" />
          <h3 className="placeholder-title">FIRST THINGS FIRST, SET UP YOUR BRAND KIT!</h3>
          <h4 className="placeholder-sub-title">The brand kit will calibrate Peech and help the automation</h4>
          <h4 className="placeholder-sub-title">process to keep your brand guidelines.</h4>
          <Button
            data-cy="new-brand-kit"
            className="brand-button"
            variant="primary"
            onClick={() => {
              trackEvent('brand-kit-new', { trigger: 'new-brand-kit-big' });
              setShowBrandKitModal(true);
            }}
          >
            New Brand Kit
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <div className="brands-group">
            <div className="brands-group-content-wrapper" ref={scrollingContainerRef}>
              <Row className={classNames('brands-group-content', { 'not-empty': user?.preset })} ref={brandRef}>
                {presets.map((preset) => (
                  <BrandKit
                    key={preset.sid}
                    user={user}
                    preset={preset}
                    setBrandkitPage={setBrandkitPage}
                    onRemove={() => unloadPreset(preset)}
                    scrollingContainerRef={scrollingContainerRef}
                    allowDelete={presets?.length > 1}
                  />
                ))}
              </Row>
            </div>
          </div>
        </div>
      );
    }
  };

  if (presets === null) {
    return (
      <div className="init-loader">
        <Loader style={{ display: 'block' }} />
      </div>
    );
  }

  if (brandkitPage) {
    return (
      <div className="brand">
        <div className="brand-header">
          <div className="header-leftSide">
            <h3 className="brand-title">Brand</h3>
            <CustomTypeahead
              id="select-preset"
              labelKey={(option) => option.name || 'First Brand Kit'}
              inputProps={{
                className: 'form-control select font-select',
              }}
              onChange={onPresetChange}
              options={presets || []}
              placeholder="Choose a Brand Kit..."
              selected={[preset]}
              refObj={presetInputRef}
            />

            <div className="default-selection">
              <label className="asset--checkbox" onChange={(e) => preset.onDefaultChange(e, { user, setUser })}>
                <input type="checkbox" checked={isDefaultChecked} />
                <span className="checkmark">
                  <SVG name="check" viewBox="0 0 36 36" />
                </span>
              </label>
              <div className="set-brandkit-default">Set as default brand kit</div>
            </div>
          </div>

          <div className="header-rightSide">
            <Button
              data-cy="preset-new-brand-kit"
              className="brand-button"
              variant="primary"
              onClick={() => {
                trackEvent('brand-kit-new', { trigger: 'new-brand-kit-small-within-brand-kit' });
                setShowBrandKitModal(true);
              }}
            >
              New Brand Kit
            </Button>
          </div>
        </div>
        <BrandKitItem />
        <CreateBrandkit show={showBrandKitModal} onHide={() => setShowBrandKitModal(false)} />
      </div>
    );
  }

  return (
    <div className="brand">
      <div className="brand-header">
        <div className="header-leftSide">
          <h3 className="brand-title"></h3>
        </div>
        <div className="header-rightSide">
          <Button
            data-cy="header-new-brand-kit"
            className="brand-button"
            variant="primary"
            onClick={() => {
              trackEvent('brand-kit-new', { trigger: 'new-brand-kit-small' });
              setShowBrandKitModal(true), setTimeout(() => setPlay(true), 300);
            }}
          >
            New Brand Kit
          </Button>
        </div>
      </div>
      <MyBrands />
      <CreateBrandkit show={showBrandKitModal} onHide={() => setShowBrandKitModal(false)} />
    </div>
  );
}
