import React from 'react'

/**
 * 
 * @param {Object} props.scene
 * @param {Array} props.buffers
 * @param {number} props.percent
 */

export default function ControlsBar({ totalDuration, done, buffers }) {
    const percent = (totalDuration || 100) / 100
    
    return (
        <>
            <div className="progress-scene" data-cy="chapter-controls" style={{ width: 100 + '%' }}>
                {buffers.map((buffer, i) => (
                    <div key={`buf-${i}`} className="progress-buffer" style={{
                        width: (buffer.end - buffer.start) / percent * 100,
                        left: (buffer.start / percent * 100) + '%' //((buffer.start - scene.begins) / scene.percent * 100) + '%'
                    }}></div>
                ))}
                <div className="progress-done" style={{
                    width: done + '%',
                    // backgroundSize: 100 + '%',
                    // backgroundPosition: 0 + '%'
                }}></div>
            </div>
        </>
    )
}

