import styled from '@emotion/styled';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { COLORS } from '../../../constants/colors';
import { AppContext } from '../../lib/app-context';
import {activeEditCuePointState, cuePointsState} from '../../state/cuePoints';
import {
  MENUS,
  menuState,
  MENUS_TOOLTIP_TEXT,
  menuHeaderTooltipTextState,
  shouldDisplayDenoiseState,
  shouldEnableDenoiseState,
  shouldShowIntroMenuState,
  introOutroDurationChangeState,
} from '../../state/menu';
import { sequenceState } from '../../state/sequence';
import {
  getLottiesPreviewList,
  getVisPackagesList,
  lottiesPreviewListState,
  sortedPackagesSidsState,
  visualPackagesListState,
} from '../../state/visualPackage';
import BrandKitsModal from '../../ui-components/VisualGuideline/BrandKitsModal';
import VisualGuidelineButton from '../../ui-components/VisualGuideline/VisualGuidelineButton';
import VisualPackagesModal from '../../ui-components/VisualGuideline/VisualPackagesModal';
import { trackEvent } from '../../utils/analityics.utils';
import { getKeyByValue } from '../../utils/objects.utils';
import ProjectAudio from '../audioNew/projectAudio';
import SVG from '../svg';
import BrandProperties from './brand';
import CreditsProperties from './credits';
import FrameProperties from './frame';
import SpeakersProperties from './speakers';
import SubtitlesProperties from './subtitles';
import VisualsProperties from './visuals';
import IntroAndOutro from '../../components/introAndOutro/IntroAndOutro';
import { UserRole } from '../../client/base/user';

const DashboardMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* float: left; */
  cursor: pointer;
  position: relative;
  background-color: ${COLORS.peech_neutral_white};
  margin-right: 5px;
  padding: 1px;
  height: calc(100vh - 56px);
`;

const TopMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 10px;
  margin-bottom: 8px;
  flex-wrap: nowrap;
`;

const BottomMenuItems = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  padding: 7px 0 8px 0;
  flex-wrap: nowrap;
`;

function uniqueSid() {
  return Math.random().toString(36).substring(2);
}

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.name
 * @param {string} props.iconViewBox
 * @param {boolean} props.disabled
 */
export function MenuItem({
  title,
  className,
  name,
  children,
  shouldRenderChildren,
  iconViewBox,
  disabled,
  onSelected,
  onClick,
  resetSelection,
}) {
  const selected = menuState.use();
  const menuHeaderTooltipText = menuHeaderTooltipTextState.use();
  const menuRef = React.useRef();
  const { user } = useContext(AppContext);

  const helpId = uniqueSid();

  React.useEffect(() => {
    if (selected === name) {
      const { top } = menuRef.current.getBoundingClientRect();
      onSelected(top);
    }
    menuHeaderTooltipTextState.set(getToolTipText(selected));
  }, [selected]);

  function onSelect() {
    trackEvent(`menu-${name}`);

    menuState.set((selected) => (selected === name || resetSelection ? null : name));
    onClick && onClick();
  }

  const menuItemClassNames = classNames('item sidebar-menu-element', {
    'disabled-demo-feature': disabled,
    selected: name === selected,
    [className]: className,
  });

  return (
    <div id={`menu-${name}`} onClick={onSelect} ref={menuRef} className={menuItemClassNames}>
      {/* <OverlayTrigger key={`menu-${name}-tooltip`} placement="right-start"
        overlay={
          <Tooltip id={`menu-${name}-tooltip`} className="menu-tooltip">
            <div>
              {children}   
            </div>
          </Tooltip>}>
        <SVG
          name="help"
          viewBox="0 0 20 20"
          data-tip=""
          data-for={helpId}
          className="help"
        />
      </OverlayTrigger> */}
      {shouldRenderChildren && children}
      <div className="icon">
        <SVG
          className="menu-icon"
          //  viewBox={iconViewBox || '0 0 33 33'}
          name={`${name}-menu`}
        />
        <div className={classNames('title', { selected: name === selected })}>{title}</div>
      </div>
    </div>
  );
}

const getToolTipText = (value) => {
  return MENUS_TOOLTIP_TEXT[getKeyByValue(MENUS, value)];
};

function MenuProperties({ credits, subtitleElements }) {
  const selected = menuState.use();

  switch (selected) {
    // case MENUS.CONTENT:
    // 	return <ContentProperties />

    case MENUS.CAPTIONS:
      return <SubtitlesProperties subtitleElements={subtitleElements} />;

    case MENUS.VISUALS:
      return <VisualsProperties />;

    case MENUS.SPEAKERS:
      return <SpeakersProperties />;

    case MENUS.INTRO_OUTRO:
      return <IntroAndOutro />;

    case MENUS.FRAME:
      return <FrameProperties />;

    case MENUS.BRAND:
      return <BrandProperties />;

    case MENUS.AUDIO:
      return <ProjectAudio />;

    case MENUS.CREDITS:
      return <CreditsProperties credits={credits} />;

    default:
      return null;
  }
}

export default function Menu({ subtitleElements }) {
  const selected = menuState.use();
  const sequence = sequenceState.use();
  const cuePoints = cuePointsState.use();
  const [opening, setOpening] = React.useState(true);
  const properties = React.createRef();
  const { plan, user } = React.useContext(AppContext);
  const [selectedOffset, setSelectedOffset] = React.useState(100);
  const [visGuidlineModalOpen, setVisGuidlineModalOpen] = React.useState(false);
  const [brandKitGuidlineModalOpen, setBrandKitGuidelineModalOpen] = React.useState(false);
  const [sequenceSaveLoading, setSequenceSaveLoading] = React.useState(false);
  const visualPackagesList = visualPackagesListState.use();
  const sortedPackagesSids = sortedPackagesSidsState.use();
  const shouldShowIntro = shouldShowIntroMenuState.use();
  const lottiesPreviewList = lottiesPreviewListState.use();
  const activeEditCuePoint = activeEditCuePointState.use()

  const inTtoOutroButtonText = React.useMemo(() => {
    return shouldShowIntro ? 'Intro & Outro' : 'Outro';
  }, [shouldShowIntro]);

  React.useEffect(() => {
    const element = document.querySelectorAll('.cuePointActive:not(.moveable-element)');
    if (element?.[0]) {
      element[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest'});
    }
  }, [activeEditCuePoint, opening])

  React.useEffect(() => {
    if (user) {
      getVisPackagesList(user);
      getLottiesPreviewList();
    }
    if (user.UIFLAGS.DENOISER_ENABLED) {
      shouldDisplayDenoiseState.set(true);
    }
    if(user.UIFLAGS.INTRO_OUTRO_DURATION_CHANGE) {
      introOutroDurationChangeState.set(true)
    }
    if (sequence.noiseReducted) {
      shouldEnableDenoiseState.set(true);
    }
  }, [user, sequence]);

  const visualPackagesListToShow = React.useMemo(() => {
    let arr = [];
    sortedPackagesSids.forEach((sid) => {
      if(user.role === UserRole.ADMIN || lottiesPreviewList[sid]) {
        arr.push(visualPackagesList[sid]);
      }
    });
    return arr;
  }, [visualPackagesList, sortedPackagesSids, lottiesPreviewList]);

  React.useEffect(() => {
    selected && setOpening(true);
  }, [selected]);

  function onAnimationEnd() {
    console.log('onAnimationEnd');
    setOpening(false);
  }

  if (!sequence) {
    return null;
  }

  const credits = [sequence.music, ...Object.values(cuePoints).filter((cuePoint) => cuePoint?.credit)].filter(
    (item) => item?.credit && (item.creditRequired || !plan)
  );

  return (
    <>
      <DashboardMenu className="menu" id="monitor-menu" data-cy="dashboard-menu">
       
        <TopMenuItems>
          <MenuItem title="Subtitles" name={MENUS.CAPTIONS} onSelected={setSelectedOffset}>
            We encourage you to review the subtitles. <br />
            Also 85% of videos on social media <br />
            are watched without sound, <br />
            so your subtitles are important.
          </MenuItem>
          <MenuItem title="Visuals" name={MENUS.VISUALS} onSelected={setSelectedOffset}>
            These are all the visual elements <br />
            automatically placed in your video, <br />
            if you don't want a specific element <br />
            just delete it :)
          </MenuItem>
          <MenuItem title={<span>Speaker Titles</span>} name={MENUS.SPEAKERS} onSelected={setSelectedOffset}>
            Add the speaker's name and title <br />
            and we'll remember it for your next video!
          </MenuItem>
          <MenuItem title="Logo" name={MENUS.BRAND} onSelected={setSelectedOffset}>
            Choose your brand's watermarked logo,
            <br />
            position and size.
            <br />
            So no one forgets who made this video!
          </MenuItem>

          <MenuItem title={inTtoOutroButtonText} name={MENUS.INTRO_OUTRO} onSelected={setSelectedOffset}>
            This is the last scene of your video. <br />
            Make sure to use it to tell people <br />
            what to do after watching your video.
          </MenuItem>

          <MenuItem title="Background" name={MENUS.FRAME} onSelected={setSelectedOffset}>
            This is the a background frame. <br />
            Use it to wrap your video with <br />
            highly branded frame.
          </MenuItem>

          <MenuItem title="Music" name={MENUS.AUDIO} onSelected={setSelectedOffset}>
            Change the music in your video, <br />
            reduce background noise <br />
            and anything else <br />
            related to the audio in your video.
          </MenuItem>
          {credits.length ? (
            <MenuItem title="Credits" name={MENUS.CREDITS} onSelected={setSelectedOffset}>
              Giving credit to the creators of the music <br />
              and stock images/footage in your video <br />
              is important so we automatically <br />
              add a credit frame at the end of your video <br />
              to give credit to the creators.
            </MenuItem>
          ) : null}
        </TopMenuItems>

        <BottomMenuItems>
          <VisualGuidelineButton
            onClick={() => {
              activeEditCuePointState.set(null);
              setBrandKitGuidelineModalOpen(true);
            }}
            iconName="brand-kit"
            name="Brand Kit"
            isSelected={brandKitGuidlineModalOpen}
          />

          <VisualGuidelineButton
            onClick={() => {
              activeEditCuePointState.set(null);
              trackEvent(`visualStyle-show`, {trigger: 'menu', type:'modal'});
              setVisGuidlineModalOpen(true)
            }}
            iconName="visual-package"
            name="Visual style"
            isSelected={visGuidlineModalOpen}
          />
        </BottomMenuItems>
      </DashboardMenu>
      <div className="properties-wrapper">
        <div
          ref={properties}
          className={`properties-${selected ? selected : 'close '} properties ${opening ? 'opening' : 'open'}`}
          onAnimationEnd={onAnimationEnd}
          data-cy="properties-menu"
        >
          <MenuProperties
            credits={credits}
            subtitleElements={subtitleElements}
            setVisGuidlineModalOpen={setVisGuidlineModalOpen}
          />
        </div>
      </div>
      {visGuidlineModalOpen && (
        <VisualPackagesModal
          onHide={() => setVisGuidlineModalOpen(false)}
          sequenceSaveLoading={sequenceSaveLoading}
          setSequenceSaveLoading={setSequenceSaveLoading}
          visualPackagesListToShow={visualPackagesListToShow}
        />
      )}
      {brandKitGuidlineModalOpen && (
        <BrandKitsModal
          onHide={() => setBrandKitGuidelineModalOpen(false)}
          sequenceSaveLoading={sequenceSaveLoading}
          setSequenceSaveLoading={setSequenceSaveLoading}
        />
      )}
    </>
  );
}
