import { Music } from '../client/sequence';
import { incrementPlaybackVersion } from '../state/playback';
import { sequenceSave } from '../state/sequence';

export const DEFAULT_VOLUME = {
  volume: 0.12,
  noContentVolume: 0.8,
};

export default async function applySelectedMusic(track, sequence) {
  if (sequence?.music && (sequence?.music.assetSid && sequence?.music.assetSid === track.assetSid) || (sequence?.music.externalId && sequence?.music.externalId === track.externalId)) {
        return;
  }

  const music = sequence.music || new Music();
  music.enabled = true;
  music.url = track.url;
  music.name = track.name;
  music.overrideVolume = false;

  if (track.externalId) {
    music.assetSid = null;
    music.externalId = track.externalId;
    music.credit = track.credit;
    music.creditRequired = track.creditRequired;
  } else {
    music.assetSid = track.sid;
    music.externalId = null;
    music.credit = null;
    music.creditRequired = null;
  }
  sequence.music = music;
  await sequenceSave(sequence).then(incrementPlaybackVersion);
}
