import classNames from 'classnames';
import React from 'react';
import { Modal, ModalBody, OverlayTrigger, Tooltip } from 'react-bootstrap';
import YouTube from 'react-youtube';
import { Asset } from '../../client/base/asset';
import PlayerStyle from '../../components/player-style';
import SVG from '../../components/svg';
import { signalPlaybackShouldPause } from '../../state/playback';
import CreateProject from '../../ui-components/CreateProject';
import { firstDraftState } from '../draftModal/DraftModal';
import MainPlayer from '../main-player';
import AssetInfoPlug from './AssetInfoPlug';
import './playerModal.scss';
import { isInIframe } from '../../utils/window.utils';

interface PlayerModalProps {
  playbackUrl: string;
  aspectRatio: string;
  show: boolean;
  title?: string;
  hide?: boolean | Function;
  onHide?: Function;
  children?: JSX.Element;
  thumbnail?: string;
  videoId?: string;
  onCreate?(): void;
  supportedLanguages?: Object[];
  onLangChange?(): string;
  previewAsset?: Asset;
  setPreviewAsset(): void;
  status?: Number;
  headerText?: string;
  headerTag?: string;
}

function PlayerModal({
  playbackUrl,
  aspectRatio,
  title,
  show,
  hide,
  onHide,
  thumbnail,
  children,
  videoId,
  onCreate,
  supportedLanguages = [],
  onLangChange,
  previewAsset,
  setPreviewAsset,
  status,
  headerText = '',
  headerTag = '',
}: PlayerModalProps) {
  const [animation, setAnimation] = React.useState('');
  const showFirstDraft = firstDraftState.use();
  const animateOut = 'animate-out';
  const [showInfo, setShowInfo] = React.useState(false);

  const statusNotReady = React.useMemo(() => {
    if (status !== undefined && status !== 2) {
      return true;
    } else {
      return false;
    }
  }, [status]);

  React.useEffect(() => {
    if (!hide) {
      return;
    }
    if (typeof hide === 'function') {
      return animateModal(hide);
    }
    animateModal();
  }, [hide]);

  const animateModal = (cb?: Function) => {
    signalPlaybackShouldPause();
    setAnimation(animateOut);
    setTimeout(() => {
      cb && cb();
    }, 400);
    setTimeout(() => {
      showFirstDraft && firstDraftState.set(false);
      onHide && onHide();
      setShowInfo(false);
    }, 500);
  };

  return (
    <Modal
      className={classNames(`player-modal`, {
        square: aspectRatio === '1:1',
        vertical: aspectRatio === '9:16',
        animation: false,
      })}
      centered
      show={show}
      onHide={() => {
        isInIframe() && window.top?.postMessage('DRAFT_MODAL_CLOSED', '*');
        animateModal();
      }}
    >
      <ModalBody className="player-modal-body">
        <div className="player-model-header">
          <span className="content-title">
            {headerTag && <div className="info-tag">{headerTag}</div>}
            <span
              className={classNames(`header-text`, { square: aspectRatio === '1:1', vertical: aspectRatio === '9:16' })}
            >
              {headerText}
            </span>
          </span>

          <a
            onClick={() => {
              isInIframe() && window.top?.postMessage('DRAFT_MODAL_CLOSED', '*');
              animateModal();
            }}
          >
            <SVG name="close" viewBox="0 0 24 24" />
          </a>
        </div>
        <div className="player-model-content">
          {showInfo && (
            <AssetInfoPlug
              supportedLanguages={supportedLanguages}
              onLangChange={onLangChange}
              setShowInfo={setShowInfo}
              previewAsset={previewAsset}
              setPreviewAsset={setPreviewAsset}
              statusNotReady={statusNotReady}
            />
          )}
          {!videoId && <PlayerStyle aspectRatio={aspectRatio} isPreview={true} />}
          {!videoId && (
            <MainPlayer
              playbackUrl={playbackUrl}
              contentThumbnail={thumbnail}
              isDraft={true}
              showInfo={showInfo}
              onCanPlay={() => {
                isInIframe() && window.top?.postMessage('DRAFT_MODAL_READY', '*');
              }}
            />
          )}
          {videoId && (
            <YouTube videoId={videoId} opts={{ height: '100%', width: '100%', playerVars: { autoplay: 1 } }} />
          )}
          {children}
        </div>

        {onCreate && (
          <div className="player-model-footer">
            <CreateProject
              renderPlace="playerModal"
              onCreate={onCreate}
              trigger="Player-modal"
              disabledCreate={statusNotReady}
            />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}

export default PlayerModal;
