
import * as Base from './base/image';


export interface IImage extends Base.IImage {

}

export interface IImageProperties extends Base.IImageProperties {

}

export interface IImageFilter extends Base.IImageFilter {

}

export default class Image<IType extends IImage = IImage, ITypeProperties extends IImageProperties = IImageProperties> extends Base.Image<IType, ITypeProperties> implements IImage {
}