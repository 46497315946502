import React from 'react'
import { navigate } from 'gatsby'
import { shouldLogoutState } from '../state/local'

export default function Configure(props) {

	React.useEffect(() => {
		if (!document.location.search) {
			return;
		}
		const urlParams = new URLSearchParams(document.location.search);
		const sequenceSid = urlParams.get('sequenceSid');

		const token = document.location.search.replace(/^[?]([^=&]+).*$/g, '$1')
		shouldLogoutState.set(true)
		document.cookie = `token=${token}; Path=/;`

		// document.location = `/plans?configure=true${sequenceSid ? '&sequenceSid=' + sequenceSid : ''}`
		document.location = `/`
	}, [])


	return (
		<div />
	)
}
