import styled from '@emotion/styled/macro';
import React from 'react';
import { COLORS } from '../../../constants/colors';
import { EditRules } from '../../client/base/editRules';
import { VideoType } from '../../client/base/videoType';
import { LottieLoader } from '../../components/common/lottie-loader/lottie-loader.component';
import Loader from '../../ui-components/Loader';
import PeechCheckbox, { CheckBoxBlock } from '../../ui-components/PeechCheckbox';

const Wrapper = styled.div`
  height: 460px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TitleBlock = styled.div`
  width: 236px;
  height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: ${COLORS.peech_gray_400};
`;

const TypesBlock = styled.div`
  height: 100%;
  width: fit-content;
  min-width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  ${TitleBlock} {
    margin-left: 15px;
  }
`;
const ElementsBlock = styled(TypesBlock)`
  width: 800px;
  max-width: 800px;
`;

const ElementsContainer = styled.div`
  background-color: ${COLORS.peech_neutral_white};
  width: fit-content;
  height: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 20px 45px;
`;
const ButtonsContainer = styled.div`
  background-color: ${COLORS.peech_gray_50};
  width: 252px;
  height: 100%;
  direction: rtl;
  overflow-y: scroll;
  overflow-x: visible;
`;
const PinkBorder = styled.div`
  height: 100%;
  width: 4px;
  border-radius: 12px 0px 0px 12px;
`;

const ButtonName = styled.div`
  height: 100%;
  width: 236px;
  padding: 12px 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: ${COLORS.peech_neutral_black};
  cursor: pointer;
`;

const TypeButton = styled.div<{ isActive: boolean }>`
  height: 50px;
  width: 236px;
  margin-bottom: 6px;
  direction: ltr;
  display: flex;
  flex-direction: row;

  ${ButtonName} {
    font-weight: ${({ isActive }): string => (isActive ? '700' : '400')};
    background-color: ${({ isActive }): string => (isActive ? COLORS.peech_neutral_white : 'inherit')};
  }

  ${PinkBorder} {
    background-color: ${({ isActive }): string => (isActive ? COLORS.peech_primary_1_500 : 'inherit')};
  }
`;

const ElementNameDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  opacity: 1;
`;

const LottieWrapper = styled(ElementNameDiv)`
  opacity: 1;
`;

const VisualElemenmt = styled.div<{ disabled: boolean; isChecked: boolean; showAnyway: boolean }>`
  border: solid 1px;
  border-color: ${COLORS.peech_gray_100};
  background-color: ${({ disabled, isChecked }): string =>
    disabled || !isChecked ? 'inherit' : COLORS.peech_gray_100};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  width: 157px;
  height: 107px;
  border-radius: 8px;
  position: relative;
  cursor: ${({ disabled }): string => (disabled ? 'unset' : 'pointer')};
  color: ${({ disabled }): string => (disabled ? COLORS.peech_gray_300 : COLORS.peech_neutral_black)};

  ${CheckBoxBlock} {
    position: absolute;
    top: 7px;
    left: 7px;
  }
  &:hover {
    ${ElementNameDiv} {
      opacity: ${({ disabled, showAnyway }): string => (disabled || showAnyway ? '1' : '0')};
    }
  }
`;

interface CalibrationProps {
  videoTypes: VideoType[];
  selectedType: VideoType;
  setSelectedType: Function;
  currentEditRules: EditRules;
  sequenceIsSaving: boolean;
}

export default function Calibration({ videoTypes, selectedType, setSelectedType, currentEditRules, sequenceIsSaving }: CalibrationProps) {
  const [hoveredElement, setHoveredElement] = React.useState(null);

  const buttonsContainerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (!selectedType || !videoTypes) {
      return;
    }
    const index = videoTypes.findIndex((vt) => vt.values.sid === selectedType.values.sid);
    const button = buttonsContainerRef.current?.childNodes.item(index) as HTMLElement;
    button?.scrollIntoView({ behavior: 'smooth' });
  }, [selectedType]);

  const changeElementSelected = (item: Object) => {
    let selectedTypeCopy = { ...selectedType };
    let element = currentEditRules[item.name];
    if (element) {
      selectedTypeCopy.editRules = {
        ...selectedTypeCopy.editRules,
        values: {
          ...selectedTypeCopy.editRules.values,
          [item.name]: !element.isSelected,
        },
      };
      setSelectedType(selectedTypeCopy);
      setHoveredElement(null);
    }
  };

  return (
    <Wrapper>
      <TypesBlock>
        <TitleBlock>Video type</TitleBlock>
        <ButtonsContainer ref={buttonsContainerRef}>
          {selectedType &&
            videoTypes.map((item, index) => {
              return (
                <TypeButton
                  key={index}
                  onClick={() => {
                    !sequenceIsSaving && setSelectedType(item);
                  }}
                  isActive={item.values.name === selectedType.values.name}
                >
                  <PinkBorder />
                  <ButtonName>{item.values.name}</ButtonName>
                </TypeButton>
              );
            })}
        </ButtonsContainer>
      </TypesBlock>
      <ElementsBlock>
        <TitleBlock>Visual elements</TitleBlock>
        <ElementsContainer>
          {currentEditRules &&
            Object.values(currentEditRules).map((item, index) => {
              return (
                <VisualElemenmt
                  data-cy="visual-element"
                  key={index}
                  disabled={item.disabled}
                  isChecked={item.isSelected}
                  showAnyway={!hoveredElement}
                  onClick={() => !item.disabled && !sequenceIsSaving && changeElementSelected(item)}
                  onMouseEnter={(e) => {
                    e.preventDefault();
                    setHoveredElement(item);
                  }}
                  onMouseLeave={(e) => {
                    e.preventDefault();
                    setHoveredElement(null);
                  }}
                >
                  <ElementNameDiv>{item.elementName}</ElementNameDiv>
                  {hoveredElement &&
                  item.elementName === hoveredElement.elementName &&
                  !item.disabled &&
                  item.animationData ? (
                    <LottieWrapper>
                      <LottieLoader animationData={item.animationData} loop={false} width={'155px'} />
                    </LottieWrapper>
                  ) : null}
                  {!item.disabled && (
                    <PeechCheckbox
                      isChecked={item.isSelected}
                      onClick={() => !item.disabled && changeElementSelected(item.elementName)}
                    />
                  )}
                </VisualElemenmt>
              );
            })}
        </ElementsContainer>
      </ElementsBlock>
    </Wrapper>
  );
}
