import React from 'react'
import classNames from 'classnames';

import Color from '../../client/color';
import { ColorItem } from '../Colors';

import './ColorPalette.scss'

interface ColorPaletteProps {
    editable: boolean;
    className?: string;
    onChange(color: Color): void;
    onChangeComplete(): void;
    onNewColor(color: Color, index: number): void;
    onColorClick(color: Color): void;
    colors: Color[];
    inMenu: boolean;
    allowNew?: boolean
    showColorName?: boolean
    removeColor?(): void;
    canBeDeleted: boolean;
}

export default function ColorPalette({ editable, onChange, onChangeComplete, onNewColor, onColorClick, inMenu, className, colors, allowNew, showColorName, removeColor, canBeDeleted, ...props }: ColorPaletteProps & {}) {

    return (
        <div className={classNames('peech-color-palette', { [className]: className })}>
            <div className='color-blocks'>
                {(colors || []).map((color, idx) => <ColorItem color={color}
                    key={color.sid || color.cid}
                    idx={idx}
                    onChange={(selectedColor, color) => onChange && onChange(selectedColor, color, idx)} onChangeComplete={onChangeComplete} showColorName={showColorName} onClick={onColorClick} editable={editable} onNewColor={onNewColor} removeColor={removeColor} canBeDeleted={canBeDeleted} />)}
                {allowNew && (colors?.length < 5 || !colors?.length) && <ColorItem idx="new" newColor={true} onChange={onChange} onNewColor={onNewColor} showColorName={false} onClick={onColorClick} editable={editable} removeColor={removeColor} />}
            </div>
        </div >
    )
}

// ColorPalette.defaultProps = {
//     colors: [
//         "#EA429D",
//         "#B23278",
//         "#7EC7FB",
//         "#1F9FFB",
//         "#1671B2"
//     ],
// };