import User from '../client/user';

export default function useUserPs() {
  const withUserPs = (url: string) => {
    if (typeof window === 'undefined') return url;
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set('ps', User.ps || '');
      return urlObj.toString();
    } catch (error) {
      return url;
    }
  };

  return withUserPs;
}
