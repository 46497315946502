import EventEmitter from 'wolfy87-eventemitter';
// import { Detector } from 'react-detect-offline';

/* eslint-disable */
import Sequence from '../client/sequence';

import axios from 'axios';

import Asset from '../client/asset';
import { appConfig } from './app-context';

const MAX_CONCURRENT_UPLOADS = 6;
const SLICE_SIZE = 1024 * 1024;
const SOURCE_ASSETS_TYPE = [Asset.TYPE.SOURCE, Asset.TYPE.SOURCE_INTRO, Asset.TYPE.SOURCE_OUTRO];

function slice(file, start, end) {
  var slice = file.mozSlice ? file.mozSlice : file.webkitSlice ? file.webkitSlice : file.slice ? file.slice : () => {};

  return slice.bind(file)(start, end);
}

class FileUploader extends EventEmitter {
  static fileUploaders = {};
  static activeUploads = 0;

  /**
   * @type {FormData[]}
   */
  formDataQueue;

  static detectOffline() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;

    window.onoffline = () => {
      console.log('Network down');
      this.pauseAll();
    };
    window.ononline = () => {
      console.log('Network resumed');
      this.resumeAll();
    };
  }

  static resumeAll() {
    Object.values(FileUploader.fileUploaders).forEach((fileUploader) => fileUploader.resume());
  }

  static pauseAll() {
    Object.values(FileUploader.fileUploaders).forEach((fileUploader) => fileUploader.pause());
  }

  static cancelAll() {
    Object.values(FileUploader.fileUploaders).forEach((fileUploader) => fileUploader.cancel());
  }

  /**
   * @param {object} params
   * @param {File} params.file
   * @param {Sequence.Scene} params.content
   * @param {Sequence} params.sequence
   * @param {number} params.minDuration in seconds
   * @param {number} params.maxDuration in seconds
   */
  constructor({ file, content, sequence, minDuration, maxDuration, sliceSize }) {
    super();
    FileUploader.detectOffline();

    this.content = content;
    this.sequence = sequence;
    this.sliceSize = sliceSize || SLICE_SIZE;

    if (file) {
      this.file = file;
      this.minDuration = minDuration || 3;
      this.maxDuration = maxDuration === undefined ? 30 * 60 : maxDuration;
      this.size = file.size;
      this.percent = (100 / this.size) * this.sliceSize;
      this.done = 0;
      this.start = 0;
      this.uploadUrl = appConfig.INGEST_URL + '/upload';
    }

    FileUploader.fileUploaders[content.sid] = this;
  }

  /**
   * @param {'error'|'parsed'|'done'|'canceled'|'progress'|'thumbnail'|'thumbnailProgress'|'sessionExpired'} type
   * @param {Function} callback
   * @returns {FileUploader}
   */
  on(type, callback) {
    return super.on(type, callback);
  }

  off(type, handler) {
    return super.off(type, handler);
  }

  parse() {
    var video = document.createElement('video');
    video.preload = 'metadata';
    video.muted = true;
    video.playsInline = true;
    return new Promise((resolve, reject) => {
      video.addEventListener(
        'error',
        (err) => {
          console.error('Unable to parse format', err);
          resolve();
          // this.emit(
          // 	'error',
          // 	'Footage Format',
          // 	`Unable to parse format.`
          // )
          // reject();
        },
        { once: true }
      );

      video.addEventListener(
        'loadedmetadata',
        async () => {
          video.currentTime = 3;
          try {
            await video.play();
            await video.pause();
            let fileName = '';
            if (typeof this.file.name === 'string') {
              if (this.file.name.length <= 14) {
                fileName = `"${this.file.name}" `;
              } else {
                fileName = `"${this.file.name.substr(0, 14)}..." `;
              }
            }

            if (this.maxDuration && video.duration > this.maxDuration) {
              this.emit('tooLongNotification');
              return reject();
            }

            if (this.minDuration && video.duration < this.minDuration) {
              this.emit(
                'error',
                'Oops, your video is too short',
                `Your account is limited to ${Math.floor(this.minDuration / 60) || Math.floor(this.minDuration)} 
							${Math.floor(this.minDuration / 60) > 0 ? 'minutes' : 'seconds'} for each file.`
              );
              return reject();
            }
            this.duration = video.duration;

            if (video.videoWidth && video.videoHeight) {
              this.video = video;
              this.emit('parsed', {
                videoWidth: video.videoWidth,
                videoHeight: video.videoHeight,
                duration: video.duration,
              });
            }
          } catch (err) {
            this.emit('error', 'Video Parse', err.message);
          }
          resolve();
        },
        { once: true }
      );

      video.src = URL.createObjectURL(this.file);
    });
  }

  smallThumbnail() {
    if (!this.video) {
      return;
    }
    var canvas = document.createElement('canvas');
    canvas.width = this.video.videoWidth;
    canvas.height = this.video.videoHeight;
    var context = canvas.getContext('2d');
    context.drawImage(this.video, 0, 0, canvas.width, canvas.height);
    this.thumb = canvas.toDataURL('image/png');
    this.emit('thumbnail', {
      src: this.thumb,
      thumbnail: this.thumb.split(';base64,')[1],
    });
  }

  imageThumbnail() {
    return new Promise((resolve) => {
      var img = new Image();
      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        var context = canvas.getContext('2d');
        context.drawImage(img, 0, 0, canvas.width, canvas.height);
        this.thumb = canvas.toDataURL('image/png');
        this.emit('thumbnail', {
          src: this.thumb,
          thumbnail: this.thumb.split(';base64,')[1],
        });
        this.emit('done', this);
        resolve();
      };
      img.src = URL.createObjectURL(this.file);
    });
  }

  /**
   * @param {string} fileName set it for a file under the asset, omit it when the asset is the file
   * @returns {Promise}
   */
  async uploadAsset(fileName, sid, archived) {
    if (SOURCE_ASSETS_TYPE.includes(this.content.type)) {
      try {
        await this.parse();
      } catch (error) {}
    }
    if (this.size > this.sliceSize) {
      this.uploadUrl = appConfig.INGEST_URL + '/asset';
      return this.startUpload(fileName, archived);
    }

    await this.smallThumbnail();

    const url = appConfig.INGEST_URL + '/asset';
    const formData = new FormData();
    formData.append('buffer', this.file);
    formData.append('sid', sid || this.content.sid);

    if (SOURCE_ASSETS_TYPE.includes(this.content.type)) {
      formData.append('size', this.size);
    }

    if (archived) {
      formData.append('archived', true);
      formData.append('size', this.size);
    }
    if (fileName) {
      formData.append('fileName', fileName);
      formData.append('size', 1);
    }

    return axios
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${Sequence.ps}`,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          console.error(response.statusText, {
            response: response.data,
            requestId: response.headers['x-requestid'],
          });
          throw new Error(response.statusText);
        }
        console.log('Uploaded', this.file.name);
        this.emit('done', this);
      })
      .catch((err) => {
        console.error(err);
        this.emit('error', 'Upload Error', err.message);
      });
  }

  uploadThumbnail(key, thumbnail, assetSid) {
    if (thumbnail.length > 1000000) {
      const promises = thumbnail.match(/.{1,1000000}/g).map((chunk, index, all) => {
        const count = all.length;
        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('key', key);
        formData.append('index', index);
        formData.append('count', count);

        if (SOURCE_ASSETS_TYPE.includes(this.content.type)) {
          formData.append('assetSid', assetSid);
        } else {
          formData.append('sid', this.content.sid);
          formData.append('sequenceSid', this.sequence.sid);
        }

        return axios
          .post(appConfig.INGEST_URL + '/thumb', formData, {
            headers: {
              Authorization: `Bearer ${Sequence.ps}`,
            },
          })
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(response.statusText);
            }
            this.emit('thumbnailProgress', (index / count) * 100);
          });
      });
      return Promise.all(promises)
        .then(() => this.emit('thumbnailProgress', 100))
        .catch((err) => console.error(err));
    }

    const formData = new FormData();
    formData.append('buffer', new Blob([thumbnail], { type: 'text/plain' }));
    formData.append('key', key);
    if (SOURCE_ASSETS_TYPE.includes(this.content.type)) {
      formData.append('assetSid', assetSid);
    } else {
      formData.append('sid', this.content.sid);
      formData.append('sequenceSid', this.sequence.sid);
    }

    return axios
      .post(appConfig.INGEST_URL + '/thumb', formData, {
        headers: {
          Authorization: `Bearer ${Sequence.ps}`,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        this.emit('thumbnailProgress', 100);
      })
      .catch((err) => console.error(err));
  }

  /**
   *
   * @param {FormData} formData
   * @param {number} retries
   * @returns
   */
  uploadChunk(formData, retries) {
    if (this.paused) {
      if (formData) {
        this.formDataQueue.unshift(formData);
      }
      return false;
    }
    retries = retries || 1;
    if (retries > 10) {
      console.log('Chunk upload failed', { retries });
      return false;
    }
    if (!formData && !this.formDataQueue.length) {
      if (FileUploader.fileUploaders[this.content.sid]) {
        delete FileUploader.fileUploaders[this.content.sid];
      }
      return false;
    }
    FileUploader.activeUploads++;
    formData = formData || this.formDataQueue.shift();

    this.counter++;
    return axios
      .post(this.uploadUrl, formData, {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${Sequence.ps}`,
        },
      })
      .then((response) => {
        FileUploader.activeUploads--;
        if (response.status === 200) {
          this.counter--;
          setTimeout(() => this.resume(), 10);
          this.done += this.percent;
          if (!this.counter && !this.formDataQueue.length) {
            if (!this.canceled) {
              console.log('Uploaded', this.file.name);
              this.emit('done', this);
            }
          }
          return this.emit('progress', this.done, this);
        }
        if (response.status == 401) {
          if (!this.inLogin) {
            this.inLogin = true;
            this.emit('sessionExpired');
          }
          return this.formDataQueue.unshift(formData);
        }

        const position = formData.get('start');
        console.error(response.statusText, {
          retries,
          position,
          size: this.size,
          response: response.data,
          requestId: response.headers['x-requestid'],
        });
        setTimeout(() => this.uploadChunk(formData, retries + 1), 100);
      })
      .catch((err) => {
        FileUploader.activeUploads--;
        const position = formData.get('start');
        console.error(err.message, {
          retries,
          position,
          size: this.size,
        });
        setTimeout(() => this.uploadChunk(formData, retries + 1), 1000 * retries * retries);
      });
  }

  addSlice(synch, fileName, archived) {
    if (this.start >= this.size) {
      return false;
    }

    var end = this.start + this.sliceSize;
    if (this.size - end < 0) {
      end = this.size;
    }

    var formData = new FormData();

    var data = slice(this.file, this.start, end);
    formData.append('buffer', data);
    formData.append('sid', this.content.sid);
    formData.append('contentVersion', this.content.contentVersion || 0);
    formData.append('type', this.content.constructor.className);
    formData.append('start', this.start);
    formData.append('size', this.size);
    if (fileName) {
      formData.append('fileName', fileName);
    }
    if (archived) {
      formData.append('archived', true);
    }
    this.sequence && formData.append('sequenceSid', this.sequence.sid);
    if (end == this.size) {
      formData.append('last', 1);
    }

    if (synch) {
      this.uploadChunk(formData);
    } else {
      this.formDataQueue.push(formData);
    }
    this.start = end;
    return true;
  }

  async startUpload(fileName, archived) {
    this.counter = 0;
    this.smallThumbnail();
    this.formDataQueue = [];

    var moreLeft;
    do {
      moreLeft = this.addSlice(true, fileName, archived);
    } while (moreLeft && FileUploader.activeUploads < MAX_CONCURRENT_UPLOADS);

    while (moreLeft) {
      moreLeft = this.addSlice(false, fileName, archived);
    }
  }

  /**
   * @deprecated
   */
  async upload() {
    try {
      if (this.content?.image) {
        return this.imageThumbnail();
      }
      await this.parse();
      this.startUpload();
    } catch (err) {}
  }

  pause() {
    this.paused = true;
  }

  cancel() {
    this.canceled = true;
    this.emit('canceled');
    delete FileUploader.fileUploaders[this.content.sid];
    if (this.formDataQueue) {
      this.formDataQueue = [];
    }
  }

  resume() {
    this.paused = false;
    if (this.formDataQueue) {
      do {
        if (!this.uploadChunk()) {
          break;
        }
      } while (FileUploader.activeUploads < MAX_CONCURRENT_UPLOADS);
    }
  }
}

export default FileUploader;
