
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'
import SequenceAccess, { ISequenceAccess } from '../sequenceAccess'
import Track, { ITrack } from '../track'
import EditRules, { IEditRules, IEditRulesProperties } from '.././editRules'
import Color, { IColor, IColorProperties } from '.././color'
import Content, { IContent, IContentProperties } from '../content'
import Logo, { ILogo, ILogoProperties } from '../sequence/logo'
import Effect, { IEffect, IEffectProperties } from '../sequence/effect'
import Text, { IText, ITextProperties } from '../sequence/text'
import LogoEffect, { ILogoEffect, ILogoEffectProperties } from '../sequence/logoEffect'
import Scene, { IScene, ISceneProperties } from '../sequence/scene'
import Music, { IMusic, IMusicProperties } from '../sequence/music'
import Skip, { ISkip, ISkipProperties } from '../sequence/skip'
import Chapter, { IChapter, IChapterProperties } from '../sequence/chapter'
import Style, { IStyle, IStyleProperties } from '../sequence/style'
import BackgroundJob, { IBackgroundJob, IBackgroundJobProperties } from '../sequence/backgroundJob'
import SequenceGenerationFlow, { ISequenceGenerationFlow, ISequenceGenerationFlowProperties } from '../sequence/sequenceGenerationFlow'

export enum SequenceStatus {
	PENDING = 0,
	ACTIVE = 1,
	DELETED = 3,
	PROCESSING = 4,
	ERRONEOUS = 5,
	INPUTS_VALIDATED = 6,
	TRANSLATING = 7,
};

export enum SequenceRole {
	CREATOR = 1,
	EDITOR = 2,
	REVIEWER = 3,
	VIEWER = 4,
	OWNER = 5,
};

export enum SequenceDownloadNotificationType {
	DISABLED = 1,
	ENABLED = 2,
	DONE = 3,
	CANCELED = 4,
	FAILED = 5,
};

export enum SequenceRestoreStatus {
	PENDING = 1,
	RESTORABLE = 2,
	RESTORING = 3,
	RESTORED = 4,
	CLEANING = 5,
};

export enum SequenceGeneratedType {
	MANUAL = 'MANUAL',
	SMART_LIBRARY = 'SMART_LIBRARY',
	SINGLE_HIGHLIGHT = 'SINGLE_HIGHLIGHT',
	SMART_LIBRARY_SEQUENCE = 'SMART_LIBRARY_SEQUENCE',
	CUSTOM_SNIPPETS = 'CUSTOM_SNIPPETS',
	SMART_LIBRARY_SPLIT_BY_TIME = 'SMART_LIBRARY_SPLIT_BY_TIME',
	SMART_LIBRARY_SPLIT_BY_USER_TIME_STAMPS = 'SMART_LIBRARY_SPLIT_BY_USER_TIME_STAMPS',
};

export interface ISequence extends IBase {
	status?: number | null;
	editRules?: EditRules | null;
	logo?: Logo | null;
	chapters?: Chapter[] | null;
	colors?: Color[] | null;
	transcripted?: number | null;
	highlighted?: number | null;
	validated?: number | null;
	masked?: number | null;
	presetSid?: string | null;
	userSid?: string | null;
	width?: number | null;
	height?: number | null;
	aspectRatio?: string | null;
	altAspectRatios?: string[] | null;
	languageCode?: string | null;
	style?: Style | null;
	title?: string | null;
	music?: Music | null;
	audio?: Content | null;
	video?: Content | null;
	mask?: Content | null;
	output?: Content | null;
	useGreenScreen?: boolean | null;
	useDenoised?: boolean | null;
	useCaptions?: boolean | null;
	captionsBackgroundOpacity?: number | null;
	useTitleCuePoints?: boolean | null;
	useFootageForCrop?: boolean | null;
	useV2ClosedCaptions?: boolean | null;
	burnCaptions?: boolean | null;
	editRulesSid?: string | null;
	folderSid?: string | null;
	generatedType?: string | null;
	footageSid?: string | null;
	manuallyCreatedFirstBoard?: string | null;
	errorCause?: string | null;
	errorCode?: number | null;
	backgroundJobs?: BackgroundJob[] | null;
	sequenceGenerationFlow?: SequenceGenerationFlow | null;
	updateTimeStamp?: number | null;
	visualPackageVersion?: number | null;
	duration?: number | null;
	scenes?: Scene[] | null;
	role?: number | null;
	accessesCount?: number | null;
	audioGenerated?: boolean | null;
	noiseReducted?: boolean | null;
}

export interface ISequenceProperties extends IBaseProperties {
	status?: boolean;
	editRules?: IEditRulesProperties;
	logo?: ILogoProperties;
	chapters?: IChapterProperties;
	colors?: IColorProperties;
	transcripted?: boolean;
	highlighted?: boolean;
	validated?: boolean;
	masked?: boolean;
	presetSid?: boolean;
	userSid?: boolean;
	width?: boolean;
	height?: boolean;
	aspectRatio?: boolean;
	altAspectRatios?: boolean;
	languageCode?: boolean;
	style?: IStyleProperties;
	title?: boolean;
	music?: IMusicProperties;
	audio?: IContentProperties;
	video?: IContentProperties;
	mask?: IContentProperties;
	output?: IContentProperties;
	useGreenScreen?: boolean;
	useDenoised?: boolean;
	useCaptions?: boolean;
	captionsBackgroundOpacity?: boolean;
	useTitleCuePoints?: boolean;
	useFootageForCrop?: boolean;
	useV2ClosedCaptions?: boolean;
	burnCaptions?: boolean;
	editRulesSid?: boolean;
	folderSid?: boolean;
	generatedType?: boolean;
	footageSid?: boolean;
	manuallyCreatedFirstBoard?: boolean;
	errorCause?: boolean;
	errorCode?: boolean;
	backgroundJobs?: IBackgroundJobProperties;
	sequenceGenerationFlow?: ISequenceGenerationFlowProperties;
	updateTimeStamp?: boolean;
	visualPackageVersion?: boolean;
	duration?: boolean;
	scenes?: ISceneProperties;
	role?: boolean;
	accessesCount?: boolean;
	audioGenerated?: boolean;
	noiseReducted?: boolean;
}

export interface ISequenceFilter extends IBaseFilter {
	userSidEqual?: string;
	languageCodeEqual?: string;
	folderSidEqual?: string;
	folderSidIsNull?: boolean;
	generatedTypeEqual?: string;
	footageSidEqual?: string;
	roleIn?: number[];
	roleEqual?: number;
	roleNotIn?: number[];
	roleNotEqual?: number;
}

export class Sequence<IType extends ISequence = ISequence, ITypeProperties extends ISequenceProperties = ISequenceProperties> extends Base<IType, ITypeProperties> implements ISequence {
	
	static className = 'Sequence';
	static NAME = 'sequence';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		editRules: EditRules.PROPERTIES,
		logo: Logo.PROPERTIES,
		chapters: Chapter.PROPERTIES,
		colors: Color.PROPERTIES,
		transcripted: true,
		highlighted: true,
		validated: true,
		masked: true,
		presetSid: true,
		userSid: true,
		width: true,
		height: true,
		aspectRatio: true,
		altAspectRatios: true,
		languageCode: true,
		style: Style.PROPERTIES,
		title: true,
		music: Music.PROPERTIES,
		audio: Content.PROPERTIES,
		video: Content.PROPERTIES,
		mask: Content.PROPERTIES,
		output: Content.PROPERTIES,
		useGreenScreen: true,
		useDenoised: true,
		useCaptions: true,
		captionsBackgroundOpacity: true,
		useTitleCuePoints: true,
		useFootageForCrop: true,
		useV2ClosedCaptions: true,
		burnCaptions: true,
		editRulesSid: true,
		folderSid: true,
		generatedType: true,
		footageSid: true,
		manuallyCreatedFirstBoard: true,
		errorCause: true,
		errorCode: true,
		backgroundJobs: BackgroundJob.PROPERTIES,
		sequenceGenerationFlow: SequenceGenerationFlow.PROPERTIES,
		updateTimeStamp: true,
		visualPackageVersion: true,
		duration: true,
		scenes: new Base.Union({
			Chapter: Chapter.PROPERTIES as IChapterProperties,
		}),
		role: true,
		accessesCount: true,
		audioGenerated: true,
		noiseReducted: true,
	};
	
	/**
	 * @deprecated use SequenceStatus instead
	 */
	static STATUS: any = {
		PENDING: SequenceStatus.PENDING,
		ACTIVE: SequenceStatus.ACTIVE,
		DELETED: SequenceStatus.DELETED,
		PROCESSING: SequenceStatus.PROCESSING,
		ERRONEOUS: SequenceStatus.ERRONEOUS,
		INPUTS_VALIDATED: SequenceStatus.INPUTS_VALIDATED,
		TRANSLATING: SequenceStatus.TRANSLATING,
	};
	
	/**
	 * @deprecated use SequenceRole instead
	 */
	static ROLE: any = {
		CREATOR: SequenceRole.CREATOR,
		EDITOR: SequenceRole.EDITOR,
		REVIEWER: SequenceRole.REVIEWER,
		VIEWER: SequenceRole.VIEWER,
		OWNER: SequenceRole.OWNER,
	};
	
	/**
	 * @deprecated use SequenceDownloadNotificationType instead
	 */
	static DOWNLOAD_NOTIFICATION_TYPE: any = {
		DISABLED: SequenceDownloadNotificationType.DISABLED,
		ENABLED: SequenceDownloadNotificationType.ENABLED,
		DONE: SequenceDownloadNotificationType.DONE,
		CANCELED: SequenceDownloadNotificationType.CANCELED,
		FAILED: SequenceDownloadNotificationType.FAILED,
	};
	
	/**
	 * @deprecated use SequenceRestoreStatus instead
	 */
	static RESTORE_STATUS: any = {
		PENDING: SequenceRestoreStatus.PENDING,
		RESTORABLE: SequenceRestoreStatus.RESTORABLE,
		RESTORING: SequenceRestoreStatus.RESTORING,
		RESTORED: SequenceRestoreStatus.RESTORED,
		CLEANING: SequenceRestoreStatus.CLEANING,
	};
	
	/**
	 * @deprecated use SequenceGeneratedType instead
	 */
	static GENERATED_TYPE: any = {
		MANUAL: SequenceGeneratedType.MANUAL,
		SMART_LIBRARY: SequenceGeneratedType.SMART_LIBRARY,
		SINGLE_HIGHLIGHT: SequenceGeneratedType.SINGLE_HIGHLIGHT,
		SMART_LIBRARY_SEQUENCE: SequenceGeneratedType.SMART_LIBRARY_SEQUENCE,
		CUSTOM_SNIPPETS: SequenceGeneratedType.CUSTOM_SNIPPETS,
		SMART_LIBRARY_SPLIT_BY_TIME: SequenceGeneratedType.SMART_LIBRARY_SPLIT_BY_TIME,
		SMART_LIBRARY_SPLIT_BY_USER_TIME_STAMPS: SequenceGeneratedType.SMART_LIBRARY_SPLIT_BY_USER_TIME_STAMPS,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		if(values.editRules) {
			if(this.editRules) {
				this.editRules.set(values.editRules, this, 'editRules');
			}
			else {
				this.values.editRules = Base.asType<IEditRules>(values.editRules, EditRules, this, 'editRules') as EditRules;
			}
			delete values.editRules;
		}
		if(values.logo) {
			if(this.logo) {
				this.logo.set(values.logo, this, 'logo');
			}
			else {
				this.values.logo = Base.asType<ILogo>(values.logo, Logo, this, 'logo') as Logo;
			}
			delete values.logo;
		}
		if(values.chapters && values.chapters.length) {
			values.chapters = values.chapters.map(item => Base.asType<IChapter>(item, Chapter, this, 'chapters') as Chapter);
		}
		if(values.colors && values.colors.length) {
			values.colors = values.colors.map(item => Base.asType<IColor>(item, Color, this, 'colors') as Color);
		}
		if(values.style) {
			if(this.style) {
				this.style.set(values.style, this, 'style');
			}
			else {
				this.values.style = Base.asType<IStyle>(values.style, Style, this, 'style') as Style;
			}
			delete values.style;
		}
		if(values.music) {
			if(this.music) {
				this.music.set(values.music, this, 'music');
			}
			else {
				this.values.music = Base.asType<IMusic>(values.music, Music, this, 'music') as Music;
			}
			delete values.music;
		}
		if(values.audio) {
			if(this.audio) {
				this.audio.set(values.audio, this, 'audio');
			}
			else {
				this.values.audio = Base.asType<IContent>(values.audio, Content, this, 'audio') as Content;
			}
			delete values.audio;
		}
		if(values.video) {
			if(this.video) {
				this.video.set(values.video, this, 'video');
			}
			else {
				this.values.video = Base.asType<IContent>(values.video, Content, this, 'video') as Content;
			}
			delete values.video;
		}
		if(values.mask) {
			if(this.mask) {
				this.mask.set(values.mask, this, 'mask');
			}
			else {
				this.values.mask = Base.asType<IContent>(values.mask, Content, this, 'mask') as Content;
			}
			delete values.mask;
		}
		if(values.output) {
			if(this.output) {
				this.output.set(values.output, this, 'output');
			}
			else {
				this.values.output = Base.asType<IContent>(values.output, Content, this, 'output') as Content;
			}
			delete values.output;
		}
		if(values.backgroundJobs && values.backgroundJobs.length) {
			values.backgroundJobs = values.backgroundJobs.map(item => Base.asType<IBackgroundJob>(item, BackgroundJob, this, 'backgroundJobs') as BackgroundJob);
		}
		if(values.sequenceGenerationFlow) {
			if(this.sequenceGenerationFlow) {
				this.sequenceGenerationFlow.set(values.sequenceGenerationFlow, this, 'sequenceGenerationFlow');
			}
			else {
				this.values.sequenceGenerationFlow = Base.asType<ISequenceGenerationFlow>(values.sequenceGenerationFlow, SequenceGenerationFlow, this, 'sequenceGenerationFlow') as SequenceGenerationFlow;
			}
			delete values.sequenceGenerationFlow;
		}
		if(values.scenes && values.scenes.length) {
			values.scenes = values.scenes.map(item => Base.asType<IScene>(item, [Chapter], this, 'scenes') as Scene);
		}
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get editRules(): EditRules | null | undefined {
		return this.values.editRules
	}
			
	set editRules(value: EditRules | null | undefined) {
		if(this.values.editRules !== value) {
			this.propertyChanged('editRules', value);
			this.values.editRules = value;
		}
	}
			
	get logo(): Logo | null | undefined {
		return this.values.logo
	}
			
	set logo(value: Logo | null | undefined) {
		if(this.values.logo !== value) {
			this.propertyChanged('logo', value);
			this.values.logo = value;
		}
	}
			
	get chapters(): Chapter[] | null | undefined {
		return this.values.chapters
	}
			
	get colors(): Color[] | null | undefined {
		return this.values.colors
	}
			
	set colors(value: Color[] | null | undefined) {
		if(this.values.colors !== value) {
			this.propertyChanged('colors', value);
			this.values.colors = value;
		}
	}
			
	get transcripted(): number | null | undefined {
		return this.values.transcripted
	}
			
	get highlighted(): number | null | undefined {
		return this.values.highlighted
	}
			
	get validated(): number | null | undefined {
		return this.values.validated
	}
			
	get masked(): number | null | undefined {
		return this.values.masked
	}
			
	get presetSid(): string | null | undefined {
		return this.values.presetSid
	}
			
	set presetSid(value: string | null | undefined) {
		if(this.values.presetSid !== value) {
			this.propertyChanged('presetSid', value);
			this.values.presetSid = value;
		}
	}
			
	get userSid(): string | null | undefined {
		return this.values.userSid
	}
			
	get width(): number | null | undefined {
		return this.values.width
	}
			
	set width(value: number | null | undefined) {
		if(this.values.width !== value) {
			this.propertyChanged('width', value);
			this.values.width = value;
		}
	}
			
	get height(): number | null | undefined {
		return this.values.height
	}
			
	set height(value: number | null | undefined) {
		if(this.values.height !== value) {
			this.propertyChanged('height', value);
			this.values.height = value;
		}
	}
			
	get aspectRatio(): string | null | undefined {
		return this.values.aspectRatio
	}
			
	set aspectRatio(value: string | null | undefined) {
		if(this.values.aspectRatio !== value) {
			this.propertyChanged('aspectRatio', value);
			this.values.aspectRatio = value;
		}
	}
			
	get altAspectRatios(): string[] | null | undefined {
		return this.values.altAspectRatios
	}
			
	set altAspectRatios(value: string[] | null | undefined) {
		if(this.values.altAspectRatios !== value) {
			this.propertyChanged('altAspectRatios', value);
			this.values.altAspectRatios = value;
		}
	}
			
	get languageCode(): string | null | undefined {
		return this.values.languageCode
	}
			
	set languageCode(value: string | null | undefined) {
		if(this.values.languageCode !== value) {
			this.propertyChanged('languageCode', value);
			this.values.languageCode = value;
		}
	}
			
	get style(): Style | null | undefined {
		return this.values.style
	}
			
	set style(value: Style | null | undefined) {
		if(this.values.style !== value) {
			this.propertyChanged('style', value);
			this.values.style = value;
		}
	}
			
	get title(): string | null | undefined {
		return this.values.title
	}
			
	set title(value: string | null | undefined) {
		if(this.values.title !== value) {
			this.propertyChanged('title', value);
			this.values.title = value;
		}
	}
			
	get music(): Music | null | undefined {
		return this.values.music
	}
			
	set music(value: Music | null | undefined) {
		if(this.values.music !== value) {
			this.propertyChanged('music', value);
			this.values.music = value;
		}
	}
			
	get audio(): Content | null | undefined {
		return this.values.audio
	}
			
	get video(): Content | null | undefined {
		return this.values.video
	}
			
	get mask(): Content | null | undefined {
		return this.values.mask
	}
			
	get output(): Content | null | undefined {
		return this.values.output
	}
			
	get useGreenScreen(): boolean | null | undefined {
		return this.values.useGreenScreen
	}
			
	set useGreenScreen(value: boolean | null | undefined) {
		if(this.values.useGreenScreen !== value) {
			this.propertyChanged('useGreenScreen', value);
			this.values.useGreenScreen = value;
		}
	}
			
	get useDenoised(): boolean | null | undefined {
		return this.values.useDenoised
	}
			
	set useDenoised(value: boolean | null | undefined) {
		if(this.values.useDenoised !== value) {
			this.propertyChanged('useDenoised', value);
			this.values.useDenoised = value;
		}
	}
			
	get useCaptions(): boolean | null | undefined {
		return this.values.useCaptions
	}
			
	set useCaptions(value: boolean | null | undefined) {
		if(this.values.useCaptions !== value) {
			this.propertyChanged('useCaptions', value);
			this.values.useCaptions = value;
		}
	}
			
	get captionsBackgroundOpacity(): number | null | undefined {
		return this.values.captionsBackgroundOpacity
	}
			
	set captionsBackgroundOpacity(value: number | null | undefined) {
		if(this.values.captionsBackgroundOpacity !== value) {
			this.propertyChanged('captionsBackgroundOpacity', value);
			this.values.captionsBackgroundOpacity = value;
		}
	}
			
	get useTitleCuePoints(): boolean | null | undefined {
		return this.values.useTitleCuePoints
	}
			
	set useTitleCuePoints(value: boolean | null | undefined) {
		if(this.values.useTitleCuePoints !== value) {
			this.propertyChanged('useTitleCuePoints', value);
			this.values.useTitleCuePoints = value;
		}
	}
			
	get useFootageForCrop(): boolean | null | undefined {
		return this.values.useFootageForCrop
	}
			
	get useV2ClosedCaptions(): boolean | null | undefined {
		return this.values.useV2ClosedCaptions
	}
			
	get burnCaptions(): boolean | null | undefined {
		return this.values.burnCaptions
	}
			
	set burnCaptions(value: boolean | null | undefined) {
		if(this.values.burnCaptions !== value) {
			this.propertyChanged('burnCaptions', value);
			this.values.burnCaptions = value;
		}
	}
			
	get editRulesSid(): string | null | undefined {
		return this.values.editRulesSid
	}
			
	set editRulesSid(value: string | null | undefined) {
		if(this.values.editRulesSid !== value) {
			this.propertyChanged('editRulesSid', value);
			this.values.editRulesSid = value;
		}
	}
			
	get folderSid(): string | null | undefined {
		return this.values.folderSid
	}
			
	set folderSid(value: string | null | undefined) {
		if(this.values.folderSid !== value) {
			this.propertyChanged('folderSid', value);
			this.values.folderSid = value;
		}
	}
			
	get generatedType(): string | null | undefined {
		return this.values.generatedType
	}
			
	set generatedType(value: string | null | undefined) {
		if(this.values.generatedType !== value) {
			this.propertyChanged('generatedType', value);
			this.values.generatedType = value;
		}
	}
			
	get footageSid(): string | null | undefined {
		return this.values.footageSid
	}
			
	set footageSid(value: string | null | undefined) {
		if(this.values.footageSid !== value) {
			this.propertyChanged('footageSid', value);
			this.values.footageSid = value;
		}
	}
			
	get manuallyCreatedFirstBoard(): string | null | undefined {
		return this.values.manuallyCreatedFirstBoard
	}
			
	set manuallyCreatedFirstBoard(value: string | null | undefined) {
		if(this.values.manuallyCreatedFirstBoard !== value) {
			this.propertyChanged('manuallyCreatedFirstBoard', value);
			this.values.manuallyCreatedFirstBoard = value;
		}
	}
			
	get errorCause(): string | null | undefined {
		return this.values.errorCause
	}
			
	get errorCode(): number | null | undefined {
		return this.values.errorCode
	}
			
	get backgroundJobs(): BackgroundJob[] | null | undefined {
		return this.values.backgroundJobs
	}
			
	get sequenceGenerationFlow(): SequenceGenerationFlow | null | undefined {
		return this.values.sequenceGenerationFlow
	}
			
	get updateTimeStamp(): number | null | undefined {
		return this.values.updateTimeStamp
	}
			
	set updateTimeStamp(value: number | null | undefined) {
		if(this.values.updateTimeStamp !== value) {
			this.propertyChanged('updateTimeStamp', value);
			this.values.updateTimeStamp = value;
		}
	}
			
	get visualPackageVersion(): number | null | undefined {
		return this.values.visualPackageVersion
	}
			
	set visualPackageVersion(value: number | null | undefined) {
		if(this.values.visualPackageVersion !== value) {
			this.propertyChanged('visualPackageVersion', value);
			this.values.visualPackageVersion = value;
		}
	}
			
	get duration(): number | null | undefined {
		return this.values.duration
	}
			
	get scenes(): Scene[] | null | undefined {
		return this.values.scenes
	}
			
	get role(): number | null | undefined {
		return this.values.role
	}
			
	get accessesCount(): number | null | undefined {
		return this.values.accessesCount
	}
			
	get audioGenerated(): boolean | null | undefined {
		return this.values.audioGenerated
	}
			
	get noiseReducted(): boolean | null | undefined {
		return this.values.noiseReducted
	}
			
	
	static get<IType extends ISequence = ISequence>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends ISequenceFilter = ISequenceFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends ISequenceFilter = ISequenceFilter, IType extends ISequence = ISequence>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
	
	async chapter(sid: string, properties: ITypeProperties | null = null): Promise<Chapter | null> {
		properties = (properties || Chapter.PROPERTIES) as ITypeProperties;
		var action = `chapter(sid: ${sid !== undefined ? `${JSON.stringify(sid)}` : 'null'}){${Base.toProperties(properties)}}`;
		const query = this.wrap('query', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.chapter !== undefined && data.chapter !== null) {
			var ret = new Chapter(data.chapter.sid);
			return ret.set(data.chapter) as Chapter;
		}
		return null;
	}
	
	async access(sid: string, properties: ITypeProperties | null = null): Promise<SequenceAccess | null> {
		properties = (properties || SequenceAccess.PROPERTIES) as ITypeProperties;
		var action = `access(sid: ${sid !== undefined ? `${JSON.stringify(sid)}` : 'null'}){${Base.toProperties(properties)}}`;
		const query = this.wrap('query', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.access !== undefined && data.access !== null) {
			var ret = new SequenceAccess(data.access.sid);
			return ret.set(data.access) as SequenceAccess;
		}
		return null;
	}
	
	async accesses(properties: ITypeProperties | null = null): Promise<Array<SequenceAccess> | null> {
		properties = (properties || SequenceAccess.PROPERTIES) as ITypeProperties;
		var action = `accesses{${Base.toProperties(properties)}}`;
		const query = this.wrap('query', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.accesses !== undefined && data.accesses !== null) {
			return data.accesses.map((item: ISequenceAccess) => new SequenceAccess(item.sid as string).set(item));
		}
		return null;
	}
	
	async creator(properties: ITypeProperties | null = null): Promise<SequenceAccess | null> {
		properties = (properties || SequenceAccess.PROPERTIES) as ITypeProperties;
		var action = `creator{${Base.toProperties(properties)}}`;
		const query = this.wrap('query', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.creator !== undefined && data.creator !== null) {
			var ret = new SequenceAccess(data.creator.sid);
			return ret.set(data.creator) as SequenceAccess;
		}
		return null;
	}
	
	async restore(force: boolean): Promise<boolean | null> {
		var action = `restore(force: ${force !== undefined ? `${JSON.stringify(force)}` : 'null'})`;
		const query = this.wrap('query', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.restore !== undefined && data.restore !== null) {
			return data.restore;
		}
		return null;
	}
	
	async transcript(force: boolean): Promise<boolean | null> {
		var action = `transcript(force: ${force !== undefined ? `${JSON.stringify(force)}` : 'null'})`;
		const query = this.wrap('query', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.transcript !== undefined && data.transcript !== null) {
			return data.transcript;
		}
		return null;
	}
	
	static async musics(sequenceSid: string, text: string, count: number, properties: IBaseProperties | null = null): Promise<Array<Track> | null> {
		properties = (properties || Track.PROPERTIES) as IBaseProperties;
		const query = `query{
			sequence {
				musics(sequenceSid: ${sequenceSid !== undefined ? `${JSON.stringify(sequenceSid)}` : 'null'}, text: ${text !== undefined ? `${JSON.stringify(text)}` : 'null'}, count: ${count !== undefined ? `${JSON.stringify(count)}` : 'null'}){${Base.toProperties(properties)}}
			}
		}`;
		var data = await Base.request(query);
		data = data.sequence || null;
		if(data && data.musics !== undefined && data.musics !== null) {
			return data.musics.map((item: ITrack) => new Track(item.sid as string).set(item));
		}
		return null;
	}
	
	async generateAudio(): Promise<boolean | null> {
		var action = `generateAudio`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.generateAudio !== undefined && data.generateAudio !== null) {
			return data.generateAudio;
		}
		return null;
	}
	
	async chapterActions(sid: string): Promise<Chapter | null> {
		var action = `chapterActions(sid: ${sid !== undefined ? `${JSON.stringify(sid)}` : 'null'})`;
		var ret = new Chapter(sid);
		return ret.set({} as IChapter, this, action) as Chapter;
	}
	
	async accessActions(sid: string): Promise<SequenceAccess | null> {
		var action = `accessActions(sid: ${sid !== undefined ? `${JSON.stringify(sid)}` : 'null'})`;
		var ret = new SequenceAccess(sid);
		return ret.set({} as ISequenceAccess, this, action) as SequenceAccess;
	}
	
	async grantAccess(access: SequenceAccess, properties: ITypeProperties | null = null): Promise<SequenceAccess | null> {
		properties = (properties || SequenceAccess.PROPERTIES) as ITypeProperties;
		var action = `grantAccess(access: ${access !== undefined ? `{${access.changedValues()}}` : 'null'}){${Base.toProperties(properties)}}`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.grantAccess !== undefined && data.grantAccess !== null) {
			var ret = new SequenceAccess(data.grantAccess.sid);
			return ret.set(data.grantAccess) as SequenceAccess;
		}
		return null;
	}
	
	async updateAccess(sid: string, access: SequenceAccess, properties: ITypeProperties | null = null): Promise<SequenceAccess | null> {
		properties = (properties || SequenceAccess.PROPERTIES) as ITypeProperties;
		var action = `updateAccess(sid: ${sid !== undefined ? `${JSON.stringify(sid)}` : 'null'}, access: ${access !== undefined ? `{${access.changedValues()}}` : 'null'}){${Base.toProperties(properties)}}`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.updateAccess !== undefined && data.updateAccess !== null) {
			var ret = new SequenceAccess(data.updateAccess.sid);
			return ret.set(data.updateAccess) as SequenceAccess;
		}
		return null;
	}
	
	async revokeAccess(sid: string): Promise<boolean | null> {
		var action = `revokeAccess(sid: ${sid !== undefined ? `${JSON.stringify(sid)}` : 'null'})`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.revokeAccess !== undefined && data.revokeAccess !== null) {
			return data.revokeAccess;
		}
		return null;
	}
	
	async deleteChapter(sid: string): Promise<boolean | null> {
		var action = `deleteChapter(sid: ${sid !== undefined ? `${JSON.stringify(sid)}` : 'null'})`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.deleteChapter !== undefined && data.deleteChapter !== null) {
			return data.deleteChapter;
		}
		return null;
	}
	
	async validateInputs(properties: ITypeProperties | null = null): Promise<Sequence | null> {
		properties = (properties || Sequence.PROPERTIES) as ITypeProperties;
		var action = `validateInputs{${Base.toProperties(properties)}}`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.validateInputs !== undefined && data.validateInputs !== null) {
			this.set(data.validateInputs);
			return this;
		}
		return null;
	}
	
	async reorderScenes(scenes: Array<Chapter>, properties: ITypeProperties | null = null): Promise<Sequence | null> {
		properties = (properties || Sequence.PROPERTIES) as ITypeProperties;
		var action = `reorderScenes(scenes: ${scenes !== undefined ? `[${scenes.map(v => `{${v.changedValues()}}`).join(', ')}]` : 'null'}){${Base.toProperties(properties)}}`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.reorderScenes !== undefined && data.reorderScenes !== null) {
			this.set(data.reorderScenes);
			return this;
		}
		return null;
	}
	
	async updateChapter(sid: string, chapter: Chapter, properties: ITypeProperties | null = null): Promise<Chapter | null> {
		properties = (properties || Chapter.PROPERTIES) as ITypeProperties;
		var action = `updateChapter(sid: ${sid !== undefined ? `${JSON.stringify(sid)}` : 'null'}, chapter: ${chapter !== undefined ? `{${chapter.changedValues()}}` : 'null'}){${Base.toProperties(properties)}}`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.updateChapter !== undefined && data.updateChapter !== null) {
			var ret = new Chapter(data.updateChapter.sid);
			return ret.set(data.updateChapter) as Chapter;
		}
		return null;
	}
	
	async cloneChapter(sid: string, chapter: Chapter, properties: ITypeProperties | null = null): Promise<Chapter | null> {
		properties = (properties || Chapter.PROPERTIES) as ITypeProperties;
		var action = `cloneChapter(sid: ${sid !== undefined ? `${JSON.stringify(sid)}` : 'null'}, chapter: ${chapter !== undefined ? `{${chapter.changedValues()}}` : 'null'}){${Base.toProperties(properties)}}`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.cloneChapter !== undefined && data.cloneChapter !== null) {
			var ret = new Chapter(data.cloneChapter.sid);
			return ret.set(data.cloneChapter) as Chapter;
		}
		return null;
	}
	
	async addChapter(chapter: Chapter, properties: ITypeProperties | null = null): Promise<Chapter | null> {
		properties = (properties || Chapter.PROPERTIES) as ITypeProperties;
		var action = `addChapter(chapter: ${chapter !== undefined ? `{${chapter.changedValues()}}` : 'null'}){${Base.toProperties(properties)}}`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.addChapter !== undefined && data.addChapter !== null) {
			var ret = new Chapter(data.addChapter.sid);
			return ret.set(data.addChapter) as Chapter;
		}
		return null;
	}
	
	static async clone(sid: string, userSid: string, aspectRatio: string, languageCode: string, footageSid: string, properties: IBaseProperties | null = null): Promise<Sequence | null> {
		properties = (properties || Sequence.PROPERTIES) as IBaseProperties;
		const query = `mutation{
			sequence {
				clone(sid: ${sid !== undefined ? `${JSON.stringify(sid)}` : 'null'}, userSid: ${userSid !== undefined ? `${JSON.stringify(userSid)}` : 'null'}, aspectRatio: ${aspectRatio !== undefined ? `${JSON.stringify(aspectRatio)}` : 'null'}, languageCode: ${languageCode !== undefined ? `${JSON.stringify(languageCode)}` : 'null'}, footageSid: ${footageSid !== undefined ? `${JSON.stringify(footageSid)}` : 'null'}){${Base.toProperties(properties)}}
			}
		}`;
		var data = await Base.request(query);
		data = data.sequence || null;
		if(data && data.clone !== undefined && data.clone !== null) {
			var ret = new Sequence(data.clone.sid);
			return ret.set(data.clone) as Sequence;
		}
		return null;
	}
	
	static async createFromTemplate(userSid: string, aspectRatio: string, presetSid: string, properties: IBaseProperties | null = null): Promise<Sequence | null> {
		properties = (properties || Sequence.PROPERTIES) as IBaseProperties;
		const query = `mutation{
			sequence {
				createFromTemplate(userSid: ${userSid !== undefined ? `${JSON.stringify(userSid)}` : 'null'}, aspectRatio: ${aspectRatio !== undefined ? `${JSON.stringify(aspectRatio)}` : 'null'}, presetSid: ${presetSid !== undefined ? `${JSON.stringify(presetSid)}` : 'null'}){${Base.toProperties(properties)}}
			}
		}`;
		var data = await Base.request(query);
		data = data.sequence || null;
		if(data && data.createFromTemplate !== undefined && data.createFromTemplate !== null) {
			var ret = new Sequence(data.createFromTemplate.sid);
			return ret.set(data.createFromTemplate) as Sequence;
		}
		return null;
	}
	
	static async createHighlight(assetSids: Array<string>, userSid: string, type: string): Promise<boolean | null> {
		const query = `mutation{
			sequence {
				createHighlight(assetSids: ${assetSids !== undefined ? `${JSON.stringify(assetSids)}` : 'null'}, userSid: ${userSid !== undefined ? `${JSON.stringify(userSid)}` : 'null'}, type: ${type !== undefined ? `${JSON.stringify(type)}` : 'null'})
			}
		}`;
		var data = await Base.request(query);
		data = data.sequence || null;
		if(data && data.createHighlight !== undefined && data.createHighlight !== null) {
			return data.createHighlight;
		}
		return null;
	}
	
	static async updatePreset(userSid: string, sequenceSid: string, presetSid: string, properties: IBaseProperties | null = null): Promise<Sequence | null> {
		properties = (properties || Sequence.PROPERTIES) as IBaseProperties;
		const query = `mutation{
			sequence {
				updatePreset(userSid: ${userSid !== undefined ? `${JSON.stringify(userSid)}` : 'null'}, sequenceSid: ${sequenceSid !== undefined ? `${JSON.stringify(sequenceSid)}` : 'null'}, presetSid: ${presetSid !== undefined ? `${JSON.stringify(presetSid)}` : 'null'}){${Base.toProperties(properties)}}
			}
		}`;
		var data = await Base.request(query);
		data = data.sequence || null;
		if(data && data.updatePreset !== undefined && data.updatePreset !== null) {
			var ret = new Sequence(data.updatePreset.sid);
			return ret.set(data.updatePreset) as Sequence;
		}
		return null;
	}
	
	static async translate(sequenceSid: string, languageCode: string): Promise<string | null> {
		const query = `mutation{
			sequence {
				translate(sequenceSid: ${sequenceSid !== undefined ? `${JSON.stringify(sequenceSid)}` : 'null'}, languageCode: ${languageCode !== undefined ? `${JSON.stringify(languageCode)}` : 'null'})
			}
		}`;
		var data = await Base.request(query);
		data = data.sequence || null;
		if(data && data.translate !== undefined && data.translate !== null) {
			return data.translate;
		}
		return null;
	}
	
	async cancelGenerate(): Promise<boolean | null> {
		var action = `cancelGenerate`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.cancelGenerate !== undefined && data.cancelGenerate !== null) {
			return data.cancelGenerate;
		}
		return null;
	}
	
	static async generate(sid: string, force: boolean, aspectRatio: string, properties: IBaseProperties | null = null): Promise<Sequence | null> {
		properties = (properties || Sequence.PROPERTIES) as IBaseProperties;
		const query = `mutation{
			sequence {
				generate(sid: ${sid !== undefined ? `${JSON.stringify(sid)}` : 'null'}, force: ${force !== undefined ? `${JSON.stringify(force)}` : 'null'}, aspectRatio: ${aspectRatio !== undefined ? `${JSON.stringify(aspectRatio)}` : 'null'}){${Base.toProperties(properties)}}
			}
		}`;
		var data = await Base.request(query);
		data = data.sequence || null;
		if(data && data.generate !== undefined && data.generate !== null) {
			var ret = new Sequence(data.generate.sid);
			return ret.set(data.generate) as Sequence;
		}
		return null;
	}
}
