
export const chargeTypes = {
    MONTHLY: 1,
    ANNUALY: 2
}

export default function calculatePlanPrices (plan, choosenChargeType) {

    let calculatedPrices = {
        costPerYear: 0,
        costPerMonth: 0,
        saveAmount: 0
    }

    if (choosenChargeType === chargeTypes.MONTHLY) {
        if (plan.overrideRecurringPrice) {
            calculatedPrices.costPerYear = plan.overrideRecurringPrice * 12;
            calculatedPrices.costPerMonth = plan.overrideRecurringPrice;
            calculatedPrices.saveAmount = 0;
        } else if (plan.recurringPrice) {
            calculatedPrices.costPerYear = plan.recurringPrice * 12;
            calculatedPrices.costPerMonth = plan.recurringPrice;
            calculatedPrices.saveAmount = 0;
        }
    } else if (choosenChargeType === chargeTypes.ANNUALY) {
        if (plan.annualOverrideRecurringPrice) {
            calculatedPrices.costPerYear = plan.annualOverrideRecurringPrice;
            calculatedPrices.costPerMonth = plan.annualOverrideRecurringPrice / 12;
            calculatedPrices.saveAmount = plan.overrideRecurringPrice ? plan.overrideRecurringPrice * 12 - plan.annualOverrideRecurringPrice : plan.recurringPrice ? plan.recurringPrice * 12 - plan.annualOverrideRecurringPrice : 0;
        } else if (plan.annualRecurringPrice) {
            calculatedPrices.costPerYear = plan.annualRecurringPrice;
            calculatedPrices.costPerMonth = plan.annualRecurringPrice / 12;
            calculatedPrices.saveAmount = plan.overrideRecurringPrice ? plan.overrideRecurringPrice * 12 - plan.annualRecurringPrice : plan.recurringPrice ? plan.recurringPrice * 12 - plan.annualRecurringPrice : 0;
        }

    }

    return calculatedPrices;


}