import { useLocation } from '@reach/router';
import React from 'react';

import styled from '@emotion/styled';

import { AppContext } from '../../lib/app-context';

import { trackEvent } from '../../utils/analityics.utils';
import { isInIframe } from '../../utils/window.utils';

import { COLORS } from '../../../constants/colors';
import { FeatureFeatureName } from '../../client/feature';
import DownloadButton from '../../components/download-button';
import { openPeechProUpgradeModal, openTranslateModal } from '../../components/layout';
import Share from '../../components/share';
import SVG from '../../components/svg';
import useOpenDownloadDialog from '../../hooks/useOpenDownloadDialog';
import { sequenceState } from '../../state/sequence';
import { UpgradeRequiredMark } from '../DropdownFeatureOptions/DropDownFeatureOption';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import OptionsMenu from '../OptionsMenu/OptionsMenu';
import { useSequenceCloneOptions } from '../SequenceCloneOptions/useSequenceCloneOptions';
import Base from '../../client/base/base';
import { DownloadLimitReachedDialogState, WatermarkAlertModalDialogState, isInPreviewState } from '../../state/local';
import Upgrade from '../../dialogs/upgrade';
import SupportModal from '../../components/support-modal';
import WatermarkAlertModal from '../../dialogs/WatermarkAlertModal/WatermarkAlertModal';
import { supportState } from '../HeaderMenu/Menu';
import { entity } from 'simpler-state';

const Container = styled.div<{ hide?: boolean }>`
  width: 100vw;
  height: 44px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  margin-block: 6px;
  padding-inline: 24px;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  justify-content: flex-end;
  align-items: center;
  background-color: ${COLORS.peech_gray_50};
`;
const StyledOptionsMenu = styled(OptionsMenu)`
  position: static;
  margin-right: 12px;

  .option--toggle {
    height: unset;
    margin: 0;
  }

  .options-menu {
    inset: 16px auto auto 71px !important;
  }
  svg[name='translate'] {
    margin-top: 10px;
  }
`;

const SequenceCloneOptionsTrigger = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  height: 28px;
  padding-inline: 8px;
  background: ${COLORS.peech_gray_50};
  border-radius: 6px;

  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  color: #666;

  > *:not(:last-child) {
    margin-right: 8px;
  }

  svg {
    padding: 0;
    stroke: none;
    * {
      transition: fill 350ms ease-out;
    }
  }
  svg[name='translate'] {
    margin-top: 10px;
  }

  &:hover {
    color: ${COLORS.peech_primary_2_500};
    svg {
      * {
        fill: ${COLORS.peech_primary_2_500};
      }
    }
  }

  transition: color 350ms ease-out;
`;

const SequenceTranslateButton = styled.div`
  display: flex;
  align-items: center;
  height: 28px;
  padding: 0 10px 0 10px;
  background: ${COLORS.peech_gray_50};
  border-radius: 6px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  color: #666;
  margin-right: 14px;
  cursor: pointer;

  svg {
    width: 24px;
    padding: 0;
    stroke: none;
    path {
      transition: stroke 350ms ease-out;
    }
  }
  svg[name='translate'] {
    margin-top: 10px;
    path {
    }
  }
  svg[name='diamond-gradient'] {
    margin-left: 4px !important;
  }

  &:hover {
    color: ${COLORS.peech_primary_2_500};
    svg {
      path {
        stroke: ${COLORS.peech_primary_2_500};
      }
    }
  }

  transition: color 350ms ease-out;
`;

export default function VisualEditorSubheader({}) {
  const { config, user, plan, subscription, reachProjectLimit, isMobileDevice } = React.useContext(AppContext);
  const location = useLocation();
  const sequence = sequenceState.use();
  const openDownloadDialog = useOpenDownloadDialog();
  const allowTranslateProject = !!user?.UIFLAGS.TRANSLATE_SEQUENCE;
  const planFeature = FeatureFeatureName.TRANSLATE_SEQUENCE;
  const translateAllowed = !!plan?.planAllowedTierFeatures?.includes(planFeature);
  const [showShare, setShowShare] = React.useState<boolean>(false);
  const [saveState, setSaveState] = React.useState(Base.saveState.IDLE);
  const downloadLimitReachedDialogState = DownloadLimitReachedDialogState.use();
  const watermarkAlertModalDialogState = WatermarkAlertModalDialogState.use();
  const support = supportState.use();
  const shouldShowEditor = !isInPreviewState.use();

  const allowDuplicateProject = React.useMemo(
    () => config?.ALLOW_DUPLICATE_PROJECT === 'true' && !location.state?.editContent,
    [config, location.state]
  );

  const sequenceCloneOptions = useSequenceCloneOptions({
    sequence,
    trigger: 'visual-editor',
    onCloneSuccess: () => {
      isInIframe() && window.top?.postMessage('NAVIGATE_TO_PLATFORM', '*');
    },
  });

  const onTranslateClick = () => {
    if (reachProjectLimit) {
      openPeechProUpgradeModal(user, 'Translate react project limit', 'PROJECTS_LIMIT_REACHED');
      return;
    }
    if (translateAllowed) {
      openTranslateModal({ sequence, trigger: 'visual-editor' });
      return;
    }
    openPeechProUpgradeModal(user, 'Translate', FeatureFeatureName.TRANSLATE_SEQUENCE);
  };

  const onShareMenuToggle = (isOpen, e, metadata) => {
    metadata?.source !== 'select' && setShowShare(!showShare);
    if (isOpen === true) {
      trackEvent('project-show-share-dialog', { trigger: 'share-monitor', isOpen });
    }
  };

  // Set up the interval.
  React.useEffect(() => {
    const id = setInterval(() => {
      setSaveState(Base.saving);
    }, 500);

    return () => clearInterval(id);
  });

  React.useEffect(() => {
    let postMessage = 'SEQUENCE_SAVING_NOT_STARTED';
    switch (saveState) {
      case Base.saveState.LOADING:
        postMessage = 'SEQUENCE_SAVING_LOADING';
        break;
      case Base.saveState.SUCCESS:
        postMessage = 'SEQUENCE_SAVING_SUCCESS';
        break;
      case Base.saveState.FAILURE:
        postMessage = 'SEQUENCE_SAVING_FAILED';
        break;
      default:
        break;
    }

    isInIframe() && window.top?.postMessage(postMessage, '*');
  }, [saveState, sequence]);

  return (
    <Container hide={!shouldShowEditor}>
      {allowTranslateProject && (
        <SequenceTranslateButton onClick={onTranslateClick}>
          <SVG name="translate" />
          <div>Translate</div>
          {!translateAllowed && <UpgradeRequiredMark hide={translateAllowed} name="diamond-gradient" />}
        </SequenceTranslateButton>
      )}
      <div style={{ position: 'relative' }}>
        {allowDuplicateProject ? (
          <StyledOptionsMenu
            options={sequenceCloneOptions}
            trigger={
              <SequenceCloneOptionsTrigger>
                <SVG name="dupe" />
                <div>Duplicate</div>
              </SequenceCloneOptionsTrigger>
            }
          />
        ) : null}
      </div>

      <DownloadButton
        onClick={(e) => {
          trackEvent('project-download', {
            trigger: location.state?.editContent ? 'download-content-editor' : 'download-visual-editor',
          });
          openDownloadDialog(e);
        }}
        id="download-submit"
        data-cy="download-submit"
      />
      {downloadLimitReachedDialogState?.isUpgradeModalOpen && (
        <Upgrade
          onHide={() =>
            DownloadLimitReachedDialogState.set((prevState) => ({ ...prevState, isUpgradeModalOpen: false }))
          }
          title={'You’ve reached the download limit!'}
          info={
            downloadLimitReachedDialogState?.data?.periodMaxDownloads
              ? `${downloadLimitReachedDialogState?.data?.periodMaxDownloads}/${downloadLimitReachedDialogState?.data?.periodMaxDownloads} video downloads a month`
              : ''
          }
          label={'Upgrade Now!'}
          onClickTrackEvent={() => trackEvent('download-limit-reached-cta-click')}
          text={'Upgrade your plan to download this video clip'}
        />
      )}
      {watermarkAlertModalDialogState && <WatermarkAlertModal />}
      <SupportModal show={support} onHide={() => supportState.set(false)} />
    </Container>
  );
}
