
import React from 'react';
import { AppContext } from '../../lib/app-context';
import Preset, { AssetUsage } from '../../client/preset';
import Asset from '../../client/asset';

import Logo from '../logo';
import './logos.scss';
import User from '../../client/user';

const USAGE_TYPE_TO_STRING = {
    main: 'main',
    [AssetUsage.USAGE_TYPE.BRIGHT]: 'bright',
    [AssetUsage.USAGE_TYPE.DARK]: 'dark',
}

interface LogoProps {
    preset: Preset
    setPreset: Function;
    isNew?: boolean;
    onLogoReady?(): void;
}

export default function Logos({ preset, setPreset, isNew, onLogoReady }: LogoProps) {
    const { user } = React.useContext(AppContext)
    const getDefaultSelectedUsageType = () => preset?.getDefaultLogo() ? USAGE_TYPE_TO_STRING[preset?.getDefaultLogo().usageType || 'main'] : null
    const [selectedLogo, setSelectedLogo] = React.useState(getDefaultSelectedUsageType())
    const PresetLogos = [
        { name: 'main-logo', title: 'Main Logo', logo: 'main', assetUsageType: undefined, thumbnail: preset?.getLogoUrl(undefined, user.cacheVersion), assetSid: preset?.getLogoOfType()?.assetSid },
        { name: 'light-logo', title: 'Light Logo', logo: 'bright', assetUsageType: AssetUsage.USAGE_TYPE.BRIGHT, thumbnail: preset?.getLogoUrl(AssetUsage.USAGE_TYPE.BRIGHT, user.cacheVersion), assetSid: preset?.getLogoOfType(AssetUsage.USAGE_TYPE.BRIGHT)?.assetSid },
        { name: 'dark-logo', title: 'Dark Logo', logo: 'dark', assetUsageType: AssetUsage.USAGE_TYPE.DARK, thumbnail: preset?.getLogoUrl(AssetUsage.USAGE_TYPE.DARK, user.cacheVersion), assetSid: preset?.getLogoOfType(AssetUsage.USAGE_TYPE.DARK)?.assetSid },
    ]

    const [defaultLogoChangeSaving, setDefaultLogoChangeSaving] = React.useState(false);

    if (!preset) {
        return null
    }
    
    return <div className='logos-inner'>
        {PresetLogos.map((logo, i) =>
            <Logo
                key={i}
                logoName={logo.name}
                logoTitle={logo.title}
                preset={preset}
                setPreset={setPreset}
                usageType={logo.logo}
                assetUsageType={logo.assetUsageType}
                assetUsage={preset.assets?.find(asset => asset.type === Asset.TYPE.LOGO && asset.usageType === AssetUsage.USAGE_TYPE[logo.logo.toUpperCase()])}
                source={logo.thumbnail ? `${logo.thumbnail}?ps=${User.ps}` : null}
                type={logo.logo}
                selectedLogo={selectedLogo}
                setSelectedLogo={setSelectedLogo}
                isNew={isNew}
                onLogoReady={logo.logo === 'main' && onLogoReady}
                defaultLogoChangeSaving={defaultLogoChangeSaving}
                setDefaultLogoChangeSaving={setDefaultLogoChangeSaving}
            />
        )}
    </div>
}