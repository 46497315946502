
import React from "react";
import { pauseIcon, playIcon } from '../../../static/svg/musicMenuIcons';
import { CheckBlock, Checked } from "../../pages/create-new/MaterialsChaptersAndRatio";
import { sequenceState } from '../../state/sequence';
import { BluredDiv, EmptyTrackList, LoaderContainer, MusicItem, MusicLibraryListContainer, MusicTitle } from "./MusicMenu.styled";

const PLAGS_AMOUNT = 10;


interface MusicLibraryListProperties {
    libraryLoading: boolean;
    library: Object[]; 
    onLibraryTrackSelected: Function;
    currentPlayingTrack: Object;
    playingTrackUrl: string;
    setCurrentPlayingTrack: Function;
}


export default function MusicLibraryList({ libraryLoading, library, currentPlayingTrack, playingTrackUrl, setCurrentPlayingTrack, onLibraryTrackSelected }: MusicLibraryListProperties) {

    const sequence = sequenceState.use();
    const plags = React.useMemo(() => {
        let count = 0;
        let arr = [];
        while(count < PLAGS_AMOUNT){
            arr.push(count);
            count ++;
        }
        return arr;
    }, []);

    
    const playPauseTrackPreview = React.useCallback((e, track) => {
        e.preventDefault();
        e.stopPropagation();
        if (track?.externalId === currentPlayingTrack?.externalId) {
            setCurrentPlayingTrack(null);
        } else {
            setCurrentPlayingTrack(track);
        }
    }, [currentPlayingTrack]);

   
    return (<>
        {libraryLoading ?

            <MusicLibraryListContainer>
                {plags.map((item, index) => {
                    return <MusicItem key={index}>
                        <BluredDiv />
                        <CheckBlock />
                        <MusicTitle >.........</MusicTitle>
                        <LoaderContainer>
                            {pauseIcon}
                        </LoaderContainer>
                    </MusicItem>
                })}
            </MusicLibraryListContainer>
            :
            <MusicLibraryListContainer>
                {library?.length ? library.map((item, index) => {
                    return <MusicItem key={index} isCurrent={sequence?.music?.externalId === item.externalId} isPlaying={currentPlayingTrack?.externalId === item.externalId } onClick={() => onLibraryTrackSelected(item)}>
                        <CheckBlock>
                            <Checked />
                        </CheckBlock>
                        <MusicTitle >
                            {item.name}
                        </MusicTitle>
                        <LoaderContainer onClick={(e) => playPauseTrackPreview(e, item)}>
                            {currentPlayingTrack?.externalId === item.externalId ? pauseIcon : playIcon}
                        </LoaderContainer>
                        <audio src={item?.url} preload="auto" muted />

                    </MusicItem>
                })
                    :
                    <EmptyTrackList>
                        No results were found, try searching for something else
                    </EmptyTrackList>}

                <audio
                    autoPlay
                    src={playingTrackUrl}
                    onEnded={(): void => {
                        setCurrentPlayingTrack(null);
                    }}
                    onPause={(): void => {
                        setCurrentPlayingTrack(null);
                    }}
                    preload="auto"
                />
            </MusicLibraryListContainer>}
    </>
    );

}