
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'
import UserBase, { IUserBase, IUserBaseFilter, IUserBaseProperties } from '../userBase'

export enum UserRole {
	ADMIN = 1,
};

export interface IUser extends IUserBase {
	orgSid?: string | null;
	email?: string | null;
	resetToken?: string | null;
	firstName?: string | null;
	lastName?: string | null;
	role?: UserRole | null;
	thumbUrl?: string | null;
	teamTypeSid?: string | null;
	orgName?: string | null;
	orgUrl?: string | null;
	orgIndustry?: string | null;
	orgNumOfEmpolyees?: string | null;
	fontFamily?: string | null;
	roleSid?: string | null;
	userRole?: string | null;
	userPurpose?: string | null;
	onboardingFinished?: boolean | null;
	department?: string | null;
	signupCampaign?: string | null;
	languageCode?: string | null;
	resetUrl?: string | null;
	signupCompletionUrl?: string | null;
	cacheVersion?: number | null;
	defaultPlanToAssign?: number | null;
}

export interface IUserProperties extends IUserBaseProperties {
	orgSid?: boolean;
	email?: boolean;
	resetToken?: boolean;
	firstName?: boolean;
	lastName?: boolean;
	role?: boolean;
	thumbUrl?: boolean;
	teamTypeSid?: boolean;
	orgName?: boolean;
	orgUrl?: boolean;
	orgIndustry?: boolean;
	orgNumOfEmpolyees?: boolean;
	fontFamily?: boolean;
	roleSid?: boolean;
	userRole?: boolean;
	userPurpose?: boolean;
	onboardingFinished?: boolean;
	department?: boolean;
	signupCampaign?: boolean;
	languageCode?: boolean;
	resetUrl?: boolean;
	signupCompletionUrl?: boolean;
	cacheVersion?: boolean;
	defaultPlanToAssign?: boolean;
}

export interface IUserFilter extends IUserBaseFilter {
	orgSidEqual?: string;
	emailEqual?: string;
	emailLike?: string;
}

export class User<IType extends IUser = IUser, ITypeProperties extends IUserProperties = IUserProperties> extends UserBase<IType, ITypeProperties> implements IUser {
	
	static className = 'User';
	static NAME = 'user';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...UserBase.PROPERTIES, 
		orgSid: true,
		email: true,
		resetToken: true,
		firstName: true,
		lastName: true,
		role: true,
		thumbUrl: true,
		teamTypeSid: true,
		orgName: true,
		orgUrl: true,
		orgIndustry: true,
		orgNumOfEmpolyees: true,
		fontFamily: true,
		roleSid: true,
		userRole: true,
		userPurpose: true,
		onboardingFinished: true,
		department: true,
		signupCampaign: true,
		languageCode: true,
		resetUrl: true,
		signupCompletionUrl: true,
		cacheVersion: true,
		defaultPlanToAssign: true,
	};
	
	/**
	 * @deprecated use UserRole instead
	 */
	static ROLE: any = {
		ADMIN: UserRole.ADMIN,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get orgSid(): string | null | undefined {
		return this.values.orgSid
	}
			
	set orgSid(value: string | null | undefined) {
		if(this.values.orgSid !== value) {
			this.propertyChanged('orgSid', value);
			this.values.orgSid = value;
		}
	}
			
	get email(): string | null | undefined {
		return this.values.email
	}
			
	set email(value: string | null | undefined) {
		if(this.values.email !== value) {
			this.propertyChanged('email', value);
			this.values.email = value;
		}
	}
			
	get resetToken(): string | null | undefined {
		return this.values.resetToken
	}
			
	get firstName(): string | null | undefined {
		return this.values.firstName
	}
			
	set firstName(value: string | null | undefined) {
		if(this.values.firstName !== value) {
			this.propertyChanged('firstName', value);
			this.values.firstName = value;
		}
	}
			
	get lastName(): string | null | undefined {
		return this.values.lastName
	}
			
	set lastName(value: string | null | undefined) {
		if(this.values.lastName !== value) {
			this.propertyChanged('lastName', value);
			this.values.lastName = value;
		}
	}
			
	get role(): UserRole | null | undefined {
		return this.values.role
	}
			
	set role(value: UserRole | null | undefined) {
		if(this.values.role !== value) {
			this.propertyChanged('role', value);
			this.values.role = value;
		}
	}
			
	get thumbUrl(): string | null | undefined {
		return this.values.thumbUrl
	}
			
	get teamTypeSid(): string | null | undefined {
		return this.values.teamTypeSid
	}
			
	set teamTypeSid(value: string | null | undefined) {
		if(this.values.teamTypeSid !== value) {
			this.propertyChanged('teamTypeSid', value);
			this.values.teamTypeSid = value;
		}
	}
			
	get orgName(): string | null | undefined {
		return this.values.orgName
	}
			
	set orgName(value: string | null | undefined) {
		if(this.values.orgName !== value) {
			this.propertyChanged('orgName', value);
			this.values.orgName = value;
		}
	}
			
	get orgUrl(): string | null | undefined {
		return this.values.orgUrl
	}
			
	set orgUrl(value: string | null | undefined) {
		if(this.values.orgUrl !== value) {
			this.propertyChanged('orgUrl', value);
			this.values.orgUrl = value;
		}
	}
			
	get orgIndustry(): string | null | undefined {
		return this.values.orgIndustry
	}
			
	set orgIndustry(value: string | null | undefined) {
		if(this.values.orgIndustry !== value) {
			this.propertyChanged('orgIndustry', value);
			this.values.orgIndustry = value;
		}
	}
			
	get orgNumOfEmpolyees(): string | null | undefined {
		return this.values.orgNumOfEmpolyees
	}
			
	set orgNumOfEmpolyees(value: string | null | undefined) {
		if(this.values.orgNumOfEmpolyees !== value) {
			this.propertyChanged('orgNumOfEmpolyees', value);
			this.values.orgNumOfEmpolyees = value;
		}
	}
			
	get fontFamily(): string | null | undefined {
		return this.values.fontFamily
	}
			
	set fontFamily(value: string | null | undefined) {
		if(this.values.fontFamily !== value) {
			this.propertyChanged('fontFamily', value);
			this.values.fontFamily = value;
		}
	}
			
	get roleSid(): string | null | undefined {
		return this.values.roleSid
	}
			
	set roleSid(value: string | null | undefined) {
		if(this.values.roleSid !== value) {
			this.propertyChanged('roleSid', value);
			this.values.roleSid = value;
		}
	}
			
	get userRole(): string | null | undefined {
		return this.values.userRole
	}
			
	set userRole(value: string | null | undefined) {
		if(this.values.userRole !== value) {
			this.propertyChanged('userRole', value);
			this.values.userRole = value;
		}
	}
			
	get userPurpose(): string | null | undefined {
		return this.values.userPurpose
	}
			
	set userPurpose(value: string | null | undefined) {
		if(this.values.userPurpose !== value) {
			this.propertyChanged('userPurpose', value);
			this.values.userPurpose = value;
		}
	}
			
	get onboardingFinished(): boolean | null | undefined {
		return this.values.onboardingFinished
	}
			
	set onboardingFinished(value: boolean | null | undefined) {
		if(this.values.onboardingFinished !== value) {
			this.propertyChanged('onboardingFinished', value);
			this.values.onboardingFinished = value;
		}
	}
			
	get department(): string | null | undefined {
		return this.values.department
	}
			
	set department(value: string | null | undefined) {
		if(this.values.department !== value) {
			this.propertyChanged('department', value);
			this.values.department = value;
		}
	}
			
	get signupCampaign(): string | null | undefined {
		return this.values.signupCampaign
	}
			
	set signupCampaign(value: string | null | undefined) {
		if(this.values.signupCampaign !== value) {
			this.propertyChanged('signupCampaign', value);
			this.values.signupCampaign = value;
		}
	}
			
	get languageCode(): string | null | undefined {
		return this.values.languageCode
	}
			
	set languageCode(value: string | null | undefined) {
		if(this.values.languageCode !== value) {
			this.propertyChanged('languageCode', value);
			this.values.languageCode = value;
		}
	}
			
	get resetUrl(): string | null | undefined {
		return this.values.resetUrl
	}
			
	get signupCompletionUrl(): string | null | undefined {
		return this.values.signupCompletionUrl
	}
			
	get cacheVersion(): number | null | undefined {
		return this.values.cacheVersion
	}
			
	get defaultPlanToAssign(): number | null | undefined {
		return this.values.defaultPlanToAssign
	}
			
	
	static get<IType extends IUser = IUser>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IUserFilter = IUserFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IUserFilter = IUserFilter, IType extends IUser = IUser>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
	
	async impersonate(): Promise<string | null> {
		var action = `impersonate`;
		const query = this.wrap('query', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.impersonate !== undefined && data.impersonate !== null) {
			return data.impersonate;
		}
		return null;
	}
	
	static async verifyLogin(token: string, provider: string, signupCampaign: string): Promise<string | null> {
		const query = `query{
			user {
				verifyLogin(token: ${token !== undefined ? `${JSON.stringify(token)}` : 'null'}, provider: ${provider !== undefined ? `${JSON.stringify(provider)}` : 'null'}, signupCampaign: ${signupCampaign !== undefined ? `${JSON.stringify(signupCampaign)}` : 'null'})
			}
		}`;
		var data = await Base.request(query);
		data = data.user || null;
		if(data && data.verifyLogin !== undefined && data.verifyLogin !== null) {
			return data.verifyLogin;
		}
		return null;
	}
	
	static async login(email: string, password: string): Promise<string | null> {
		const query = `query{
			user {
				login(email: ${email !== undefined ? `${JSON.stringify(email)}` : 'null'}, password: ${password !== undefined ? `${JSON.stringify(password)}` : 'null'})
			}
		}`;
		var data = await Base.request(query);
		data = data.user || null;
		if(data && data.login !== undefined && data.login !== null) {
			return data.login;
		}
		return null;
	}
	
	static async setPassword(email: string, password: string, token: string): Promise<string | null> {
		const query = `query{
			user {
				setPassword(email: ${email !== undefined ? `${JSON.stringify(email)}` : 'null'}, password: ${password !== undefined ? `${JSON.stringify(password)}` : 'null'}, token: ${token !== undefined ? `${JSON.stringify(token)}` : 'null'})
			}
		}`;
		var data = await Base.request(query);
		data = data.user || null;
		if(data && data.setPassword !== undefined && data.setPassword !== null) {
			return data.setPassword;
		}
		return null;
	}
	
	async changePassword(oldPassword: string, password: string): Promise<string | null> {
		var action = `changePassword(oldPassword: ${oldPassword !== undefined ? `${JSON.stringify(oldPassword)}` : 'null'}, password: ${password !== undefined ? `${JSON.stringify(password)}` : 'null'})`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.changePassword !== undefined && data.changePassword !== null) {
			return data.changePassword;
		}
		return null;
	}
	
	static async requestResetPassword(email: string): Promise<boolean | null> {
		const query = `mutation{
			user {
				requestResetPassword(email: ${email !== undefined ? `${JSON.stringify(email)}` : 'null'})
			}
		}`;
		var data = await Base.request(query);
		data = data.user || null;
		if(data && data.requestResetPassword !== undefined && data.requestResetPassword !== null) {
			return data.requestResetPassword;
		}
		return null;
	}
	
	async resetPassword(): Promise<boolean | null> {
		var action = `resetPassword`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.resetPassword !== undefined && data.resetPassword !== null) {
			return data.resetPassword;
		}
		return null;
	}
	
	async uiFlagOn(bit: number): Promise<number | null> {
		var action = `uiFlagOn(bit: ${bit !== undefined ? `${JSON.stringify(bit)}` : 'null'})`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.uiFlagOn !== undefined && data.uiFlagOn !== null) {
			return data.uiFlagOn;
		}
		return null;
	}
}
