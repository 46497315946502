import React, { useMemo } from 'react';
import { FeatureFeatureName } from '../../client/base/feature';
import Sequence from '../../client/sequence';
import { openPeechProUpgradeModal, openTranslateModal } from '../../components/layout';
import { AppContext } from '../../lib/app-context';
import { DropdownFeatureOption } from '../DropdownFeatureOptions/DropDownFeatureOption';

interface Params {
  sequence: Sequence;
  trigger: string;
}

export default function useSequenceTranslateOptions({ sequence, trigger }: Params) {
  const { user, plan, reachProjectLimit } = React.useContext(AppContext);
  const shouldShowTranslateFeature = user?.UIFLAGS.TRANSLATE_SEQUENCE;

  return useMemo(
    () =>
      ['translate'].map((opt) => {
        const planFeature = FeatureFeatureName.TRANSLATE_SEQUENCE;
        const featureAllowed = !!plan?.planAllowedTierFeatures?.includes(planFeature);
        const text = 'Translate';

        return {
          key: `translate`,
          icon: `translate`,
          toolTip: `translate`,
          text: <DropdownFeatureOption allowed={featureAllowed} text={text} />,
          hide: !shouldShowTranslateFeature,
          onClick: () => {
            if (reachProjectLimit) {
              openPeechProUpgradeModal(user, 'Translate react project limit', "PROJECTS_LIMIT_REACHED");
              return;
            }
            if (featureAllowed) {
              openTranslateModal({ sequence, trigger });
              return;
            }
            openPeechProUpgradeModal(user, 'Translate', FeatureFeatureName.TRANSLATE_SEQUENCE);
          },
        };
      }),
    [sequence, user, plan, reachProjectLimit]
  );
}
