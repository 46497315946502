import classNames from 'classnames';
import Compressor from 'compressorjs';
import React, { useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useDropzone } from 'react-dropzone';
import { entity } from 'simpler-state';
import Asset from '../../client/asset';
import Color from '../../client/color';
import CuePoint from '../../client/cuePoint';
import Preset from '../../client/preset';
import { Logo } from '../../client/sequence';
import { fileFromCanvas, findLastIndex, imgToCanvas, mostFrequent, numbersToColor } from '../../client/sequence/logo';
import TitleCuePoint from '../../client/titleCuePoint';
import { useAnimation } from '../../hooks/useAnimation';
import { AppContext } from '../../lib/app-context';
import FileUploader from '../../lib/fileUploader';
import SliderWithInput from "./subtitles/SliderWithInput";
import {mapValueWithinRange} from "../../utils/mapValueWithinRange.utils";

import {
  cuePointsState,
  editCuePointState,
  isActive,
  setTextCuePointsColor,
} from '../../state/cuePoints';
import { handleError } from '../../state/error';
import { fileUploaderState } from '../../state/fileUploader';
import { scenesState, sequenceSave, sequenceState } from '../../state/sequence';
import Loader from '../../ui-components/Loader';
import Menu from '../../ui-components/Menu/Menu';
import MenuItem from '../../ui-components/Menu/MenuItem';
import PresetLogo from '../../ui-components/PresetLogo/PresetLogo';
import { trackEvent } from '../../utils/analityics.utils';
import { TimeFormat } from '../common/time-code/time-code.component';
import { applyAnimationColorsNames, getAnimationProps } from '../cue-points/graphic';
import { TextCuePointElement } from '../cue-points/title';
import { resetMainPlayerStyle } from '../main-player';
import CuePointPropertiesItem from './cue-point';
import styled from 'styled-components';
import useUserPs from '../../hooks/useUserPs';
import VisualsMenuColorsComponent from './VisualsMenuColorsComponent';
import useVisualsMenuColorsChange from '../../hooks/useVisualsMenuColorsChange';


const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  flex: 1;
  font-family: 'Open Sans',sans-serif;
`;

const SwitchContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 4px;
  padding-right: 4px;
  label {
    flex: 1;
  }
`;

const COLOR_HEIGHT = 65;

enum FILE_TYPE {
  LOGO = 0,
  SCENE = 1,
}

const mediaUploadingState = entity(false);

const frameDefaultWidth = 0.75;
const frameMaxWidth = 1;
const frameMinWidth = 0.5;

export default function FrameProperties({ type }) {
  const sequence = sequenceState.use();
  const sequenceStyleFrameWidth = sequenceState.use((sequence) => sequence?.style?.frameWidth);
  const isEnabled = sequenceState.use((sequence) => sequence?.editRules?.useFrame);
  const assetSid = sequenceState.use((sequence) => sequence?.style?.frameAssetSid);
  const { config, user, setUser } = React.useContext(AppContext);
  const presetRef = React.useRef(user?.preset || new Preset());
  const cuePoints = cuePointsState.use();
  const newFrameCuePoint = () => {
    const scenes = scenesState.get().filter.animated;
    const intro = scenes.find((scene) => scene.cuePointType === TitleCuePoint.TYPE.INTRO);
    const outro = scenes.find((scene) => scene.cuePointType === TitleCuePoint.TYPE.OUTRO);

    const cp = new TitleCuePoint(sequence.sid);
    cp.width = 100;
    cp.height = 100;
    cp.top = 0;
    cp.left = 0;
    cp.type = TitleCuePoint.TYPE.FRAME;
    cp.startTime = intro ? intro.duration * 1000 : 0;
    cp.endTime = outro ? outro.duration * -1000 : -1;
    return cp;
  };

  const cuePoint: TitleCuePoint =
    Object.values(cuePoints).find((cp) => cp?.type === CuePoint.TYPE.FRAME) || newFrameCuePoint();
  const withUserPs = useUserPs();
  const getCuePointUrl = () =>
    cuePointThumbnail ||
    withUserPs(
      `${config.CHUNKS_URL}/cc/${sequence.sid}/TitleCuePoint/${cuePoint.sid}/90/${cuePoint.thumbnailVersion}.png`
    );

  const fileUploaderRef = React.useRef<FileUploader>({});

  const activeTime = cuePoint?.activeTimes?.length && cuePoint.activeTimes[0];
  const mediaUploading = mediaUploadingState.use((state) => state[type]);
  const isLoading = mediaUploading;

  const [isUpdatingFrameProperties, setIsUpdatingFrameProperties] = useState(false);

  const [cuePointThumbnail, setCuePointThumbnail] = React.useState();
  const [logoThumbnail, setLogoThumbnail] = React.useState(
    sequence &&
      sequence.logo &&
      Number.isInteger(sequence.logo.thumbnailVersion) &&
      withUserPs(`${config.CHUNKS_URL}/t/${sequence.sid}/${sequence.logo.sid}/${sequence.logo.thumbnailVersion}.png`)
  );

  const texts = {
    format: 'Formatting',
    upload: 'Uploading',
  };

  async function onDisable(shouldDelete: boolean = true, shouldResetOutroAssetSid: boolean = true) {
    if (!sequence.editRules?.useFrame) {
      return;
    }
    try {
      sequence.editRules!.useFrame = false;
      await sequenceSave(sequence);
      resetMainPlayerStyle();
    } catch (err) {
      handleError({
        title: `Hide frame`,
        message: err.message,
        responseError: err,
      });
    }
  }

  async function onEnable() {
    try {
      if (!cuePoint.sid) {
        await cuePoint.save();
      }
      if (!sequence.editRules?.useFrame) {
        sequence.editRules!.useFrame = true;
        await sequenceSave(sequence);
      }
    } catch (err) {
      handleError({
        title: `Show frame`,
        message: err.message,
        responseError: err,
      });
    }
  }

  async function onEnabledChange() {
    trackEvent(`visuals-enable`, { enable: !isEnabled });
    isEnabled ? onDisable(false, false) : onEnable();
  }

  /**
   *
   * @param {HTMLImageElement} img
   * @returns {Promise<Sequence>}
   */
  async function onLogoChange(img) {
    if (!img || !img.naturalWidth) {
      return false;
    }
    var colors = window.getPalette(img, 5);
    var colorsHash = colors.map((color) => numbersToColor(color.rgb)).join(':');
    var savedColors = sequence.colors ? sequence.colors.map((color) => color.color).join(':') : '';
    if (savedColors !== colorsHash) {
      sequence.colors = colors.map((color, index) => {
        var obj = new Color();
        obj.dominancy = index + 1;
        obj.color = numbersToColor(color.rgb);
        obj.percent = color.percent;
        return obj;
      });
      setTextCuePointsColor(sequence);
    }
    return sequence;
  }

  async function uploadLogo(img, filename) {
    var canvas = imgToCanvas(img);
    var context = canvas.getContext('2d');

    var data = [
      context.getImageData(2, 2, 1, 1),
      context.getImageData(2, canvas.height - 2, 1, 1),
      context.getImageData(canvas.width - 2, canvas.height - 2, 1, 1),
      context.getImageData(canvas.width - 2, 2, 1, 1),
    ].map((imgData) => imgData.data);

    var removeWhiteBackground = false;
    var transparent = data.findIndex((corner) => corner[3] < 255) >= 0;
    if (!transparent) {
      var bg = Array.from(mostFrequent(data));
      if (bg[0] > 250 && bg[1] > 250 && bg[2] > 250) {
        removeWhiteBackground = true;
      }
    }

    var imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    var logoImageData = imageData.data;

    if (removeWhiteBackground) {
      for (let i = 0, n = logoImageData.length; i < n; i += 4) {
        if (logoImageData[i] > 250 && logoImageData[i + 1] > 250 && logoImageData[i + 2] > 250) {
          logoImageData[i + 3] = 0;
        }
      }

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.putImageData(imageData, 0, 0);
    }

    // crop
    if (true) {
      // build array of opacity cells
      var opacity = logoImageData.filter((_, i) => (i + 1) % 4 === 0);
      // start with worse data
      var w = canvas.width,
        h = canvas.height,
        l = w,
        t = h,
        r = 0,
        b = 0;
      for (let i = 0; i < h; i++) {
        var chunk = opacity.slice(i * w, (i + 1) * w);
        var firstNonTransparentIndex = chunk.findIndex((d) => d);
        if (firstNonTransparentIndex < 0) {
          continue; // all line is transparent
        }
        var lastNonTransparentIndex = findLastIndex(chunk, (d) => d);
        if (lastNonTransparentIndex < 0) {
          lastNonTransparentIndex = w;
        }
        l = Math.min(firstNonTransparentIndex, l);
        r = Math.max(lastNonTransparentIndex + 1, r);
        t = Math.min(i, t);
        b = Math.max(i + 1, b);
      }
      if (l > 0 || t > 0 || r < w || b < h) {
        imageData = context.getImageData(l, t, r - l, b - t);
        logoImageData = imageData.data;
        context.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = r - l;
        canvas.height = b - t;
        context.putImageData(imageData, 0, 0);
      }
    }
    const src = canvas.toDataURL();
    setLogoThumbnail(src);

    var asset = new Asset();
    asset.type = Asset.TYPE.LOGO;
    asset.name = filename;
    await asset.save();

    const fileUploader = new FileUploader({
      sequence,
      file: fileFromCanvas(canvas),
      content: asset,
    });
    await fileUploader.uploadAsset();

    if (!sequence.logo) {
      sequence.logo = new Logo();
    }

    user.defaultLogoSid = asset.sid;
    user.logoAssets.unshift(asset);

    sequence.logo.assetSid = asset.sid;
    sequence.logo.name = filename;
    sequence.logo.srcWidth = canvas.width;
    sequence.logo.srcHeight = canvas.height;
    await onLogoChange(img);
    await sequenceSave(sequence);
    presetRef.current.addLogoAsset(asset);
    await presetRef.current.save();
    user.preset = presetRef.current;
    setUser(user);
    // onChange && onChange()
  }

  function onLogoFileSelected(file) {
    new Compressor(file, {
      strict: false,
      maxWidth: sequence.width ? sequence.width * 0.4 : 768,
      maxHeight: sequence.height ? sequence.height * 0.4 : 432,
      checkOrientation: true,
      convertSize: Infinity,
      success: (compressed) => {
        const img = new Image();
        img.onload = () => uploadLogo(img, file.name);
        img.src = window.URL.createObjectURL(compressed);
      },
      error: (err) => {
        // setUploaderError(err)
        handleError({
          title: 'Logo Upload',
          message: err.message,
          responseError: err,
        });
      },
    });
  }

  async function onFileSelected(file) {
    // if (!sequence.sid) {
    // 	await saveNewSequence();
    // }
    mediaUploadingState.set(true);
    const asset = new Asset();
    asset.name = file.name;
    asset.type = Asset.TYPE.CUE_POINT_IMAGE; // Or Asset.TYPE.CUE_POINT_VIDEO
    await asset.save();

    const maxDuration = 30;
    const uploader = new FileUploader({
      file,
      maxDuration,
      content: asset,
    });
    fileUploaderRef.current = { emitter: uploader };
    fileUploaderState.set((uploaders) => ({ ...uploaders, [asset.sid]: uploader }));

    uploader
      .on('done', () => fileUploaderState.set(({ [asset.sid]: done, ...uploaders }) => uploaders))
      .on('canceled', () => fileUploaderState.set(({ [asset.sid]: canceled, ...uploaders }) => uploaders))
      .on('error', (title, message) => handleError({ title, message }))
      .on('thumbnail', async ({ src, thumbnail }) => {
        if (!asset.contentIdentifier) {
          return;
        }
        try {
          uploader.uploadThumbnail(asset.contentIdentifier.key, thumbnail, asset.sid);
          asset.thumbnail = src;
          setCuePointThumbnail(src);
        } catch (err) {
          console.error(err);
          handleError({
            title: 'Content thumbnail upload failed',
            message: err.message,
            responseError: err,
          });
        }
      })
      .on('parsed', (metadata) => {
        console.log(metadata);
      })
      .uploadAsset(null, asset.sid);

    mediaUploadingState.set(false);
  }

  const displayVideo = useMemo(() => {
    if (sequence.style!.frameWidth === 0) {
      return false;
    }
    return true;
  }, [sequenceStyleFrameWidth]);

  async function onResetFrameParams() {
    sequence.style!.framePositionX = null;
    sequence.style!.framePositionY = null;
    sequence.style!.frameWidth = null;
    await sequenceSave(sequence);
  }

  async function onVideoDisplayToggle(): Promise<void> {
    setIsUpdatingFrameProperties(true);
    if (sequence.style!.frameWidth === 0) {
      await onResetFrameParams();
    } else {
      sequence.style!.framePositionX = 0;
      sequence.style!.framePositionY = 0;
      sequence.style!.frameWidth = 0;
      await sequenceSave(sequence);
    }
    setIsUpdatingFrameProperties(false);
  }

  async function onFrameWidthChange(value: number) {
    const percent = mapValueWithinRange(value, 0, 100, frameMinWidth, frameMaxWidth);
    sequence.style!.frameWidth = percent;
    const xyPosition = (1 - percent) / 2;
    sequence.style!.framePositionX = xyPosition;
    sequence.style!.framePositionY = xyPosition;
    await sequenceSave(sequence);
  }

  const onFileDrop = React.useCallback((type, files) => {
    if (!files.length) {
      return;
    }
    const file = files[0];

    trackEvent(`${type === FILE_TYPE.LOGO ? 'logo' : 'frame'}-change`, {
      size: file.size,
      type: file.type,
    });

    type === FILE_TYPE.LOGO && onLogoFileSelected(file);
    type === FILE_TYPE.SCENE && onFileSelected(file);
  }, []);

  const {
    getRootProps: getLogoRootProps,
    getInputProps: getLogoInputProps,
    open: openLogoPrompt,
  } = useDropzone({ onDrop: (files) => onFileDrop(FILE_TYPE.LOGO, files) });
  const {
    getRootProps: getSceneRootProps,
    getInputProps: getSceneInputProps,
    open: openScenePrompt,
  } = useDropzone({ onDrop: (files) => onFileDrop(FILE_TYPE.SCENE, files) });

  const animation = useAnimation(assetSid, sequence.aspectRatio);
  const logoRequired = animation?.assets.find((asset) => asset.hasClass('logo'));
  const extractedColors = (animation && getAnimationProps(animation)) || [];
  const cuePointColors = applyAnimationColorsNames(extractedColors, cuePoint.colors);
  const frameParamsEnabled = user.UIFLAGS?.FRAME_PARAMS;

  const colorPickerMaxHeight = cuePointColors?.length * COLOR_HEIGHT;

  const replaceState = editCuePointState.use((state) => state.cuePoint);
  const logoActions = [
    {
      svg: 'replace',
      className: classNames('menu-control--custom-icon', { active: replaceState }),
      onClick: openLogoPrompt,
    },
  ];

  const menuClassNames = classNames({ disabled: isLoading });

  const onColorsChange = useVisualsMenuColorsChange();

  return (
    <Menu
      type="frame"
      className={menuClassNames}
      header="Background"
      enabled={!!isEnabled}
      onChange={onEnabledChange}
      customIcon={{ show: 'show', hide: 'hide' }}
    >
      {isLoading && (
        <Loader size="md" style={{ position: 'absolute', top: 145, left: '50%', transform: 'translateX(-50%)' }} />
      )}
      <MenuItem id="frame-dropzone" bordered={false}>
        <input {...getSceneInputProps()} className="frame-file" data-cy="scene-input" onClick={openScenePrompt} />
        {cuePoint.titleType ? (
          <div className="frame-thumbnail-preview">
            <img src={getCuePointUrl()} />
          </div>
        ) : assetSid && Number.isInteger(cuePoint?.status) ? (
          <CuePointPropertiesItem
            type="visual"
            cuePoint={cuePoint}
            seekCalculator={() => activeTime.start + 1000}
            demoMode={true}
            replaceToggle={true}
            timerToggle={true}
            // onTimerToggle={onTimerToggle}
            timeEditDisabled={true}
            disableTimeChange={true}
            timeFormat={TimeFormat.MMSS}
          >
            <div className={`scene-animation-preview ratio-${sequence?.aspectRatio?.replace(':', '-')}`}>
              <TextCuePointElement
                inMenu={true}
                autoPlay={false}
                loop={true}
                animationSid={assetSid}
                cuePoint={cuePoint}
                moveable={false}
              />
            </div>
          </CuePointPropertiesItem>
        ) : (
          <div {...getSceneRootProps()} className="scene-upload">
            {/* <div>
							<CuePointUploader />
						</div> */}
          </div>
        )}
      </MenuItem>

      {logoRequired ? (
        <MenuItem
          className={classNames({ disabled: !isEnabled })}
          label="Logo"
          id={`${type}-logo`}
          bordered={true}
          actions={[]}
        >
          <PresetLogo />
        </MenuItem>
      ) : null}

            {/* COLORS */}
            {assetSid && isActive(cuePoint) && !!cuePointColors.length && (
        <MenuItem id="frame-colors" bordered={false}>
          <div className="menu-item--color-picker open" style={{ maxHeight: colorPickerMaxHeight }}>
            <VisualsMenuColorsComponent
              colorsObjects={cuePointColors}
              onColorChange={(value, colorObj, type) =>
                onColorsChange(value, colorObj, type, cuePoint, extractedColors, 'frame-edit')
              }
              type="frame"
            />
          </div>
        </MenuItem>
      )}


      {isEnabled ? (
        <>
          
          <MenuItem id="size-frame" bordered={true}>
            <Title>Video size</Title>
            <SliderWithInput
              onValueChange={onFrameWidthChange}
              value={Math.round(mapValueWithinRange(sequence.style?.frameWidth || frameDefaultWidth, frameMinWidth, frameMaxWidth, 0, 100))}
              min={1}
              max={100}
              step={1}
              disabled={isUpdatingFrameProperties || sequence.style?.frameWidth === 0}
            />
            {frameParamsEnabled ? (
              <SwitchContainer>
                <Title>Display video</Title>
                <Form.Check
                  id="enabled"
                  type="switch"
                  checked={displayVideo}
                  onChange={onVideoDisplayToggle}
                  disabled={isUpdatingFrameProperties}
                />
                </SwitchContainer>
            ) : null}
          </MenuItem>
        </>
      ) : null}

    </Menu>
  );
}
