
import React from 'react';
import Preset from '../../client/preset';
import BrandColors from '../Colors';
import Logos from '../Logos/Logos';
import './logosAndColors.scss';


interface LogosAndColorsProps {
    preset: Preset
    setPreset: Function;
}

export default function LogosAndColors({ preset, setPreset }: LogosAndColorsProps) {

    if (!preset) {
        return null
    }

    return <div className='logos-and-colors-inner'>
        <div className='inner-block'>
            <Logos preset={preset} setPreset={setPreset} />
        </div>
        <div className='inner-block'>
            <BrandColors preset={preset} setPreset={setPreset} />
        </div>


    </div>
}