import React from 'react'
import classNames from 'classnames'

import {activeEditCuePointState, cuePointsOnTimeState, isActive } from '../../state/cuePoints'
import { seekPlayback, playbackState, currentTimeState, signalPlaybackShouldPause } from '../../state/playback'


import CuePoint from '../../client/cuePoint'

import { AppContext } from '../../lib/app-context'
import { formatTime } from '../time-code'
import { scenesState, sequenceState } from '../../state/sequence'
import SVG from '../svg'
import { TimeCodeComponent } from '../common/time-code/time-code.component'
import { TimeContainer } from '../common/time-code/time-code.style'
import { trackEvent } from '../../utils/analityics.utils'


/**
 * @param {{
 *  cuePoint: CuePoint,
 *  type: string,
 * 	onStartChange: Function,
 * 	onEndChange: Function,
 * 	onDoubleClick: Function,
 * 	onDuplicate: Function,
 *  onReplace: Function,
 * 	seekCalculator: Function,
 *  minTime: number,
 *  maxTime: number,
 * 	timeEditDisabled: boolean,
 * 	index: string,
 * 	scrollToIndex: Function,
 *  hideTimeCode: boolean, // temp
 *  showDuplicateButton: boolean
 * }} props
 */
export default function CuePointPropertiesItem({ cuePoint, className, type, onStartChange, onEndChange, onDoubleClick, onDuplicate, onReplace, minTime, maxTime, seekCalculator, children, chapterSid, index, scrollToIndex, bordered, visibilityToggle, onVisibilityToggle, timerToggle, onTimerToggle, disableTimeChange, deleteToggle, onDelete, editToggle, onEdit, editMode, replaceToggle, demoMode, enabled, inMenu, hideTimeCode, timeFormat, showDuplicateButton, ...rest }) {
	const [clicking, setClicking] = React.useState(false)
	const sequence = sequenceState.use()
	const activeEditCuePoint = activeEditCuePointState.use()
	const scenes = scenesState.use(scenes => scenes.map)
	const seekVersion = React.useRef()
	const currentSeekVersion = playbackState.use(playback => playback.version)
	const myRef = React.createRef()
	const onTime = cuePointsOnTimeState.useCuePoint(cuePoint)

	const activeTime = cuePoint?.activeTimes?.length && cuePoint.activeTimes[0];
	// chapterSid for the time when CC timecode will be included in CuePointPropertiesItem
	const offset = scenesState.use(scenes => scenes.map[activeTime?.sceneSid || chapterSid]?.offset || 0);
	const duration = scenesState.use(scenes => scenes.map[activeTime?.sceneSid || chapterSid]?.duration || sequence.duration);

	const currentTime = currentTimeState.use()
	const playingChapterSid = () => scenes.array.filter(scene => scene.offset <= currentTime).sort((a, b) => b.offset - a.offset)[0].sid

	const [timeEditDisabled, setTimeEditDisabled] = React.useState(true)

	const { user } = React.useContext(AppContext)



	const onTimerClick = () => {
		if (disableTimeChange) {
			return
		}
		const value = !timeEditDisabled
		onTimerToggle && onTimerToggle(value)
		setTimeEditDisabled(value)

	}

	React.useEffect(() => {
		if (onTime) {
			if (!clicking && (!seekVersion.current && myRef.current && !inMenu)) {
				onTime === chapterSid && scrollToIndex(index)
			}
			setClicking(false)
			seekVersion.current = null;
		}
	}, [onTime])

	React.useEffect(() => {
		if (!timeEditDisabled) {
			onTimerClick()
		}
	}, [enabled])

	React.useEffect(() => {
		if (seekVersion.current && currentSeekVersion > seekVersion.current) {
			setClicking(false);
			seekVersion.current = null;
		}
	}, [currentSeekVersion])

	async function onClick() {
		setClicking(true);
		signalPlaybackShouldPause();
		const seekTime = seekCalculator ? seekCalculator(cuePoint) : (activeTime.start + 800);
		seekVersion.current = await seekPlayback(Math.ceil(seekTime) / 1000);
		activeEditCuePointState.set(cuePoint.values.sid);
		trackEvent(`${type}-click`);
	}

	function calcMinTime() {
		if (isNaN(minTime)) {
			return offset * 1000;
		}
		return Math.round(minTime / 1000) * 1000;
	}

	function calcMaxTime() {
		if (isNaN(maxTime)) {
			return (offset + duration) * 1000;
		}
		return Math.round(maxTime / 1000) * 1000;
	}

	const cuePointClassNames = classNames('properties-item cue-point ' + chapterSid, { [type]: type, [className]: className, 'selected': onTime && chapterSid === playingChapterSid(), 'cuePointActive': type === 'sentence' ? onTime && chapterSid === playingChapterSid() : activeEditCuePoint === cuePoint.values.sid, 'disabled': !isActive(cuePoint), 'bordered': bordered })

	if (!demoMode && (isNaN(activeTime?.start) || isNaN(activeTime?.end))) {
		return null;
	}

	if (demoMode && (!isActive(cuePoint))) {
		return (
			<div
				{...rest}
				ref={myRef}
				id={`cuePoint-wrapper-${cuePoint.sid}`}
				className={cuePointClassNames}
				onClick={() => onClick()}
				onDoubleClick={onDoubleClick}
			>
				{children}
			</div>
		)
	}

	return (
		<div
			{...rest}
			ref={myRef}
			id={`cuePoint-wrapper-${cuePoint.sid}`}
			className={cuePointClassNames}
			onClick={() => onClick()}
			onDoubleClick={onDoubleClick}
			data-cy-starttime={activeTime?.start && formatTime(activeTime.start, cuePoint)}
			data-cy-endtime={activeTime?.end && formatTime(activeTime.end, cuePoint)}
		>
			<div className={classNames("time", { 'editable': true || (!timeEditDisabled && !disableTimeChange) })}>
				{!hideTimeCode && <div className='time-container'>
					<TimeContainer data-cy="time-container">
						<TimeCodeComponent
							time={Math.round(activeTime?.start)}
							minTime={minTime || calcMinTime()}
							maxTime={activeTime.end - 500 || 0} // max start time
							onTimeChange={time => {
								onStartChange(time, minTime || calcMinTime(), activeTime.end - 500 || 0)
							}}
							format={timeFormat}
							disabled={disableTimeChange}
						/>
						<span>|</span>
						<TimeCodeComponent
							time={Math.round(activeTime?.end)}
							minTime={activeTime.start + 500 || 0} // min end time
							maxTime={maxTime || calcMaxTime()}
							onTimeChange={time => onEndChange(time, activeTime.start + 500 || 0, maxTime || calcMaxTime())}
							format={timeFormat}
							disabled={disableTimeChange}
						/>
					</TimeContainer>
				</div>}
				<div className='toggles'>
					{/* {editToggle && replaceToggle && editToggle && <div className="toggles-separation" />} */}
					{onReplace ? <a className="replace-toggle" onClick={onReplace}><SVG name='pallet' /></a> : null}
					{editToggle && <a className="edit-toggle" onClick={onEdit}><SVG className={classNames({ 'active': editMode })} name='edit' /></a>}

					{visibilityToggle && <a className="visibility-toggle" onClick={onVisibilityToggle}><SVG name={isActive(cuePoint) ? 'show' : 'hide'} /></a>}
					{/* {deleteToggle && (replaceToggle || editToggle) && <div className="toggles-separation" />} */}
					{showDuplicateButton && <a className="visibility-toggle" onClick={onDuplicate}><SVG name="duplicate" /></a>}
					{deleteToggle && <a className="visibility-toggle" onClick={onDelete}><SVG name="delete" /></a>}
				</div>
			</div>
			{children}
		</div>
	)
}
