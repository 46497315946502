
import * as Base from '../base/sequence/backgroundJob';


export interface IBackgroundJob extends Base.IBackgroundJob {

}

export interface IBackgroundJobProperties extends Base.IBackgroundJobProperties {

}

export interface IBackgroundJobFilter extends Base.IBackgroundJobFilter {

}

export default class BackgroundJob<IType extends IBackgroundJob = IBackgroundJob> extends Base.BackgroundJob<IType> {
}