import React, { useCallback, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import { Modal } from 'react-bootstrap';
import styled from '@emotion/styled/macro';
import User from '../../client/user';
import { AppContext } from '../../lib/app-context';

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 600px;
  }

  .modal-content {
    display: flex;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
`;

const ModalBody = styled.div`
  display: flex;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 12%) 8px 4px 8px 0;
`;

const LeftPanel = styled.div`
  flex: 0 0 55%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  font-family: 'Open Sans', sans-serif;
  color: #323338;
  background-color: #fff;
`;

const RightPanel = styled.div`
  display: flex;
  flex: 0 0 45%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
  background-color: #fff;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
`;

const Description = styled.div`
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 25px;
`;

const Note = styled.span`
  font-size: 12px;
  line-height: 30px;
  font-weight: 400;
  color: #1f9ffb;
`;

export const Link = styled(Note)`
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s;
  &:hover {
    color: #1f9ffb;
    text-decoration-color: #1f9ffb;
  }
`;

const Button = styled.button`
  height: 30px;
  margin-top: 12px;
  border: none;
  border-radius: 6px;
  background: #1f9ffb;
  color: #fff;
  transition: background 0.3s;
  &:hover {
    background: #7ec7fb;
  }
`;

export default function MobileUploadModal({ isOpen, onClose }: { isOpen: boolean; onClose(): void }) {
  const { config } = React.useContext(AppContext);
  const [linkedCopied, setLinkedCopied] = useState(false);

  const mobileQRCodeUrl = useMemo(() => {
    if (User.ps) {
      return `${config.NEW_UI_URL}/mobile-upload?authToken=${encodeURIComponent(User.ps)}`;
    }

    return null;
  }, [config, User.ps]);

  const onClosing = useCallback(() => {
    onClose();
    setLinkedCopied(false);
  }, [onClose]);

  const onLinkCopy = useCallback(() => {
    if (mobileQRCodeUrl) {
      navigator.clipboard.writeText(mobileQRCodeUrl);
      setLinkedCopied(true);
    }
  }, [mobileQRCodeUrl]);

  return (
    <StyledModal show={isOpen} onHide={onClosing} centered>
      <ModalBody>
        <LeftPanel>
          <Title>
            <div>Upload videos from mobile</div>
          </Title>

          <Description>To upload videos directly from your mobile device, scan the QR with your phone.</Description>

          <Button onClick={onClosing}>Go it</Button>
        </LeftPanel>

        <RightPanel>
          {mobileQRCodeUrl && (
            <>
              <QRCode value={mobileQRCodeUrl} level="H" size={200} />
              {linkedCopied ? (
                <Note>Copied</Note>
              ) : (
                <Link onClick={onLinkCopy}>{linkedCopied ? 'Copied' : 'Copy to clip board'}</Link>
              )}
            </>
          )}
        </RightPanel>
      </ModalBody>
    </StyledModal>
  );
}
