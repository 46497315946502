import React from 'react';
import { AppContext } from '../lib/app-context';
import { animationAssets } from '../state/sequence';
import * as Lottie from '../../../data-layer/shared/lottie';
import { entity } from 'simpler-state';
import Asset from '../client/asset';
import useUserPs from './useUserPs';

export const lottiesAsset = entity({});

export function getLottieJsonUrl(
  appUrl: string | undefined,
  assetSid: string,
  variant: string = '16:9',
  version: number = 0
) {
  return `${appUrl}/c/t/asset/f/${assetSid}/${variant}/${version}.json`;
}

export function useAnimation(assetSid: string, variant: string = '16:9'): Lottie.Animation {
  const animations = animationAssets.use();
  const lottiesAssetData = lottiesAsset.use();
  const animationId = `${assetSid}-${variant}`;
  const [animation, setAnimation] = React.useState(animations[animationId]);
  const { config } = React.useContext(AppContext);
  const withUserPs = useUserPs();
  React.useEffect(async () => {
    if (assetSid === '') {
      return;
    }
    if (animations[animationId]) {
      setAnimation(animations[animationId]);
    } else {
      let asset = lottiesAssetData[assetSid];
      if (!asset) {
        try {
          asset = await Asset.get(assetSid);
          lottiesAsset.set((assets: Asset[]) => ({ ...assets, [assetSid]: asset }));
        } catch (error) {}
      }
      const url = withUserPs(getLottieJsonUrl(config.CONTENT_URL, assetSid, variant, asset?.version || Date.now()));
     
      const response = await fetch(url);
      if (response.ok) {
        const json = await response.json();
        const animation = new Lottie.Animation(json);
        animationAssets.set((animations) => ({ ...animations, [animationId]: animation }));
        setAnimation(animation);
      }
    }
  }, [assetSid, variant]);
  return animation;
}
