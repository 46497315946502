import React from 'react';
import ClosedCaption, { Word } from '../../../client/closedCaption';
import CuePoint from '../../../client/cuePoint';
import { Chapter } from '../../../client/sequence';
import { ChapterOrder } from '../../../client/sequence/chapter';
import { AppContext } from '../../../lib/app-context';
import { cuePointsState, cuePointsVersionState, uniqueSid } from '../../../state/cuePoints';
import { fileUploaderState } from '../../../state/fileUploader';
import { incrementPlaybackVersion, seekPlayback, signalPlaybackShouldPause } from '../../../state/playback';
import { getNextTempChapter, getPrevTempChapter, sequenceState, tempScenesState } from '../../../state/sequence';
import DraggableDetails from '../Draggable/DraggableDetails';
import ChapterClosedCaptions from '../Generics/ChapterClosedCaptions';
import WordElement from './WordElement';
import useUserPs from '../../../hooks/useUserPs';




interface ChapterElementProps {
    chapterSid: string;
    chapters: Chapter[] | ChapterOrder[];
    className: string;
    style?: any;
    draggable?: boolean;
    hideDeletedWords?: boolean;
    shouldUpdate?: boolean;
    contentWrapperRef: React.RefObject<HTMLDivElement>;
    experimentalKeepBlankWhenCutWord? : boolean;
}

export default function ChapterElement({ 
    chapters, 
    chapterSid, 
    draggable, 
    hideDeletedWords, 
    shouldUpdate, 
    contentWrapperRef, 
    scrollVersion,
    experimentalKeepBlankWhenCutWord,
     ...rest }: ChapterElementProps & {}) {
    const sequence = sequenceState.use()
    const scenes = tempScenesState.use()
    const chapter = chapters?.find(c => c.sid === chapterSid || c.cid === chapterSid)
    const linkedChapter = tempScenesState.use(scenes => scenes.map[chapter?.linkSid])
    const [selectedWords, setSelectedWords] = React.useState(null)
    const cuePointsData = cuePointsState.use()
    const cuePointsVersion = cuePointsVersionState.use()
    const closedCaptionCuePoints = Object.values(cuePointsData).filter(cuePoint =>
        cuePoint?.type === CuePoint.TYPE.CLOSED_CAPTION &&
        cuePoint?.words.length
    )
    const firstWordTime = React.useRef(null)
    const lastWordTime = React.useRef(null)

    const { config } = React.useContext(AppContext);
    const fileUploader = fileUploaderState.use(uploaders => uploaders[chapter?.sid])
    const thumbnailVersion = linkedChapter?.thumbnailVersion || chapter?.thumbnailVersion
    const withUserPs = useUserPs();
    const thumbnailUrl = () => (fileUploader?.thumb || withUserPs(`${config.CHUNKS_URL}/t/${sequence.sid}/${(linkedChapter || chapter)?.sid}/${thumbnailVersion}.png`));
    const [thumbnail, setThumbnail] = React.useState(fileUploader?.thumb || thumbnailUrl());
    const [highlightedWord, setHighlightedWord] = React.useState<Word>(null);
    const isBlurable = React.useRef(false);

    const prevChapter = getPrevTempChapter(chapter)
    const nextChapter = getNextTempChapter(chapter)
    const classNameEditor = sequence.languageCode === 'he' ? 'scenes-editor rtl' : 'scenes-editor'

    const getChapterSeekOffset = (chapterSid: string, scenes: any) => (scenes.array || []).filter(c => c instanceof Chapter && c.duration && c.index < scenes.map[chapterSid].index)
        .reduce((total, curr) => total += curr.duration, 0) - (scenes.map[chapterSid].clipFrom || 0)

    React.useEffect(() => setThumbnail(thumbnailUrl), [thumbnailVersion]);

    React.useEffect(() => {
        if (selectedWords?.length) {
            isBlurable.current = true
            const isDeleted = [...prevDeletedWords, ...nextDeletedWords].includes(selectedWords[0])
            if (chapter.sid && !shouldUpdate && !isDeleted) {
                const offset = getChapterSeekOffset(chapterSid, scenes)
                signalPlaybackShouldPause()
                seekPlayback(offset + selectedWords[0].startTime / 1000)
            }
        }
    }, [selectedWords]);

    const captions = React.useMemo(() =>
        closedCaptionCuePoints
            .sort((a, b) => a.order - b.order)
        , [cuePointsVersion])


    const chapterWords = React.useMemo(() => captions.reduce((chapterWords, closedCaption) => {
        (closedCaption.chapterSid === (chapter.linkSid || chapter.sid)) &&
            closedCaption.status !== CuePoint.STATUS.DELETED &&
            chapterWords.push(closedCaption.words)
        return chapterWords
    }, []), [chapter, cuePointsVersion])


    const chapterActiveWords = React.useMemo(() => chapterWords.flat().filter(w => chapter
        && w.chapterSid === (chapter.linkSid || chapter.sid)
        && (!chapter.clipFrom || w.startTime / 1000 >= chapter.clipFrom)
        && w.startTime / 1000 <= (chapter.clipTo ? chapter.clipTo : chapter.clipFrom ? chapter.clipFrom + chapter.duration : chapter.duration)
    ).sort((a, b) => a.startTime - b.startTime)
        , [chapter, chapter?.duration, chapter?.clipFrom, chapterWords, cuePointsVersion])

    const getSeletctedWords = () => captions
        .filter((closedCaption: ClosedCaption) => {
            const { startTime, endTime, chapterSid } = closedCaption
            return chapterSid === (chapter.linkSid || chapter.sid) &&
                ((startTime >= firstWordTime.current && startTime <= lastWordTime.current) ||
                    (endTime >= firstWordTime.current && endTime <= lastWordTime.current) ||
                    (startTime < lastWordTime.current && endTime > lastWordTime.current))
        }).reduce(activeWordsReducer, []).sort((a, b) => a.startTime - b.startTime)

    const activeWordsReducer = (wordsArray: Word[], closedCaption: ClosedCaption) => {
        const { words } = closedCaption
        words.forEach((word: Word) => word.startTime >= firstWordTime.current && word.startTime <= lastWordTime.current && wordsArray.push(word))
        return wordsArray;
    };

    const event = React.useRef(null)
    const menuPosition = React.useRef(null)

    function getTextSelection(e) {
        e.stopPropagation();
        if (selectedWords?.length) {
            return
        }

        const { target } = e
        var select: Selection;
        if (window.getSelection) {
            select = window.getSelection();
        }
        else if (document.getSelection) {
            select = document.getSelection();
        }
        else if (document.selection) {
            select = document.selection;
        }
        else {
            return null;
        }

        if (!select || !select.focusNode || !select.focusNode.parentNode) {
            return select?.removeAllRanges();
        }

        const { anchorNode, focusNode, baseNode, extentNode, baseOffset, extentOffset } = select

        if (!baseNode.parentNode.parentNode.parentNode || !extentNode.parentNode.parentNode.parentNode || baseNode.parentNode.parentNode.parentNode.parentNode !== extentNode.parentNode.parentNode.parentNode.parentNode) {
            return select.removeAllRanges();
        }

        if (anchorNode === focusNode && baseOffset === extentOffset && baseNode.parentNode === extentNode.parentNode) {
            select.removeAllRanges();
            return selectHighlightedWord()
        }

        const firstTime = parseInt(baseNode.parentNode.parentNode.attributes.starttime?.nodeValue) // || anchorNode.attributes.starttime?.nodeValue
        const secondTime = parseInt(extentNode.parentNode.parentNode.attributes.starttime?.nodeValue) // || focusNode.attributes.starttime?.nodeValue
        event.current = e
        const { left, top } = (firstTime < secondTime ? baseNode : extentNode).parentNode.getBoundingClientRect()
        menuPosition.current = { x: left, y: top - 30 }

        if (!Number.isFinite(firstTime) || !Number.isFinite(secondTime)) {
            return select.removeAllRanges();
        }

        const middleValue = (numArray) => numArray.sort((a, b) => a - b)[1]

        const times = [firstTime, secondTime]
        if (
            (prevDeletedWords.find(w => w.startTime === firstTime) &&
                prevDeletedWords.find(w => w.startTime === secondTime))
            || (nextDeletedWords.find(w => w.startTime === firstTime) &&
                nextDeletedWords.find(w => w.startTime === secondTime))
        ) {
            firstWordTime.current = Math.min(...times)
            lastWordTime.current = Math.max(...times)
        } else {
            firstWordTime.current = middleValue([...times, chapterActiveWords[0].startTime])
            lastWordTime.current = middleValue([...times, chapterActiveWords.at(-1).startTime])
        }

        const wordsFound = getSeletctedWords()

        console.log('words found', { wordsFound })
        wordsFound?.length && setSelectedWords(wordsFound)
        setHighlightedWord(null)
        try {
            select.removeAllRanges();
            return select;
        }
        catch (err) {
            console.error(err)
            return null;
        }
    }

    function resetSelection() {
        setSelectedWords(null)
        firstWordTime.current = null
        lastWordTime.current = null
    }

    const prevDeletedWords = React.useMemo(() => chapterWords.flat().filter(w => {
        const startTime = w.startTime / 1000
        const isPrev = startTime < (chapter?.clipFrom || 0)
        const prevChapterClipTo = (prevChapter?.clipFrom || 0) + prevChapter?.duration
        return prevChapter ?
            isPrev && startTime > prevChapterClipTo :
            isPrev
    }).sort((a, b) => a.startTime - b.startTime), [chapter, chapter?.clipFrom, chapterActiveWords, prevChapter?.clipFrom, prevChapter?.duration])


    const nextDeletedWords = React.useMemo(() => chapterWords.flat().filter(w => {
        const startTime = w.startTime / 1000
        const clipTo = (chapter?.clipFrom || 0) + chapter?.duration
        const isNext = startTime > clipTo
        return nextChapter ?
            isNext && startTime < nextChapter.clipFrom :
            isNext
    }).sort((a, b) => a.startTime - b.startTime), [chapter, chapter?.duration, chapter?.clipFrom, chapterActiveWords, nextChapter?.clipFrom])

    function revertDeletedWords() {
        const tempChapterSid = `temp-${uniqueSid()}`
        let seekOffset;
        if (prevDeletedWords.includes(selectedWords[0])) {
            const indexOfFirst = prevDeletedWords.indexOf(selectedWords[0])
            const indexOfLast = prevDeletedWords.indexOf(selectedWords.at(-1))

            const prevChapterSid = prevChapter && (prevChapter.sid || prevChapter.cid)

            if (selectedWords.length === prevDeletedWords.length) {
                // Case : all the prev deleted words are selected
                if (prevChapter) {
                    console.log('Revert all prev deleted words, prev chapter found')
                    const clipFrom = prevChapter.clipFrom
                    tempScenesState.set(scenes => {
                        Object.keys(scenes.scenes).map(chapterSid => {
                            if (scenes.map[chapterSid]?.index > chapter.index) {
                                scenes.map[chapterSid].index -= 1
                            }
                        })

                        if (chapter.index < scenes.map[prevChapterSid].index) {
                            scenes.map[chapter.sid].index = prevChapterSid
                        }
                        scenes.map[prevChapterSid].duration = (((chapter.clipFrom || 0) + chapter.duration) - (prevChapter.clipFrom || 0))
                        scenes.map[prevChapterSid].clipFrom = clipFrom

                        scenes.map[chapterSid].duration = null
                        scenes.map[chapterSid].index = null

                        seekOffset = getChapterSeekOffset(prevChapterSid, scenes)
                        scenes._clearCache()
                        return scenes
                    })
                } else {
                    console.log('Revert all prev deleted words, prev chapter not found')
                    tempScenesState.set(scenes => {
                        scenes.map[chapterSid].duration = chapter.duration + chapter.clipFrom
                        scenes.map[chapterSid].clipFrom = NaN

                        seekOffset = getChapterSeekOffset(chapterSid, scenes)
                        return scenes
                    })
                }
            } else if (selectedWords.at(-1) === prevDeletedWords.at(-1)) {
                // Case : prev deleted words are selected from mid till end 
                console.log('Revert prev deleted words from end')
                const clipFrom = (prevDeletedWords[indexOfFirst - 1].endTime + selectedWords[0].startTime) / 2000
                const duration = chapter.duration + (chapter.clipFrom - clipFrom)
                tempScenesState.set(scenes => {
                    scenes.map[chapterSid].clipFrom = clipFrom
                    scenes.map[chapterSid].duration = duration

                    seekOffset = getChapterSeekOffset(chapterSid, scenes)
                    return scenes
                })
            } else if (selectedWords[0] === prevDeletedWords[0]) {
                // Case : prev deleted words are selected from start till mid
                if (prevChapter) {
                    console.log('Revert prev deleted words from start, prev chapter found')
                    const clipTo = (selectedWords.at(-1).endTime + chapterActiveWords[0].startTime) / 2000
                    tempScenesState.set(scenes => {
                        scenes.map[prevChapterSid].clipTo = clipTo
                        scenes.map[prevChapterSid].duration = (clipTo - (prevChapter.clipFrom || 0))

                        seekOffset = getChapterSeekOffset(prevChapterSid, scenes)
                        return scenes
                    })
                } else {
                    console.log('Revert prev deleted words from start, prev chapter not found')
                    const clipTo = (selectedWords.at(-1).endTime + prevDeletedWords[indexOfLast + 1].startTime - 2) / 2000
                    const duration = clipTo
                    tempScenesState.set(scenes => {
                        Object.keys(scenes.scenes).map(chapterSid => {
                            if (scenes.map[chapterSid]?.index > chapter.index) {
                                scenes.map[chapterSid].index += 1
                            }
                        })

                        scenes.map[tempChapterSid] = new ChapterOrder()
                        scenes.map[tempChapterSid].clipTo = chapter.clipTo
                        scenes.map[tempChapterSid].clipFrom = chapter.clipFrom
                        scenes.map[tempChapterSid].duration = chapter.duration
                        scenes.map[tempChapterSid].linkSid = chapter.linkSid || chapterSid
                        scenes.map[tempChapterSid].index = chapter.index + 1
                        scenes.map[tempChapterSid].sourceDuration = (chapter.srcDuration || chapter.sourceDuration)
                        scenes.map[tempChapterSid].cid = tempChapterSid

                        scenes.map[chapterSid].clipFrom = NaN
                        scenes.map[chapterSid].clipTo = clipTo
                        scenes.map[chapterSid].duration = duration

                        seekOffset = getChapterSeekOffset(chapterSid, scenes)
                        return scenes
                    })
                }

            } else {
                // Case : selected words in the mid of deleted words
                console.log('Revert prev deleted words in between')
                const clipFrom = (selectedWords[0].startTime + prevDeletedWords[indexOfFirst - 1].endTime) / 2000
                const clipTo = (selectedWords.at(-1).endTime + prevDeletedWords[indexOfLast + 1].startTime - 2) / 2000
                const duration = clipTo - clipFrom
                tempScenesState.set(scenes => {
                    Object.keys(scenes.scenes).map(chapterSid => {
                        if (scenes.map[chapterSid]?.index > chapter.index) {
                            scenes.map[chapterSid].index += 1
                        }
                    })

                    scenes.map[tempChapterSid] = new ChapterOrder()
                    scenes.map[tempChapterSid].clipFrom = chapter.clipFrom
                    scenes.map[tempChapterSid].clipTo = chapter.clipTo
                    scenes.map[tempChapterSid].linkSid = chapter.linkSid || chapterSid
                    scenes.map[tempChapterSid].index = chapter.index + 1
                    scenes.map[tempChapterSid].duration = chapter.duration
                    scenes.map[tempChapterSid].sourceDuration = (chapter.srcDuration || chapter.sourceDuration)
                    scenes.map[tempChapterSid].cid = tempChapterSid

                    scenes.map[chapterSid].clipTo = clipTo
                    scenes.map[chapterSid].clipFrom = clipFrom
                    scenes.map[chapterSid].duration = duration

                    seekOffset = getChapterSeekOffset(chapterSid, scenes)

                    return scenes
                })
            }
        } else if (nextDeletedWords.includes(selectedWords[0])) {
            const indexOfFirst = nextDeletedWords.indexOf(selectedWords[0])
            const indexOfLast = nextDeletedWords.indexOf(selectedWords.at(-1))
            const nextChapterSid = nextChapter && (nextChapter.sid || nextChapter.cid)

            if (selectedWords.length === nextDeletedWords.length) {
                // Case : all the next deleted words are selected
                seekOffset = getChapterSeekOffset(chapterSid, scenes)

                if (nextChapter) {
                    console.log('Revert all next deleted words, next chapter found')
                    const clipTo = (nextChapter.clipFrom || 0) + nextChapter.duration
                    tempScenesState.set(scenes => {
                        Object.keys(scenes.scenes).map(chapterSid => {
                            if (scenes.map[chapterSid].index > scenes.map[nextChapterSid].index) {
                                scenes.map[chapterSid].index -= 1
                            }
                        })

                        scenes.map[chapterSid].duration = (clipTo - (chapter.clipFrom || 0))
                        scenes.map[chapterSid].clipTo = clipTo

                        scenes.map[nextChapterSid].duration = null
                        scenes.map[nextChapterSid].index = null
                        scenes._clearCache()
                        return scenes
                    })
                } else {
                    console.log('Revert all next deleted words, next chapter not found')
                    const clipTo = chapter.sourceDuration || selectedWords.at(-1).endTime / 1000
                    tempScenesState.set(scenes => {
                        scenes.map[chapterSid].duration = (clipTo - (chapter.clipFrom || 0))
                        scenes.map[chapterSid].clipTo = clipTo
                        return scenes
                    })
                }
            } else if (selectedWords.at(-1) === nextDeletedWords.at(-1)) {
                // Case : next deleted words are selected from mid till end 
                const clipFrom = (selectedWords[0].startTime + nextDeletedWords[indexOfFirst - 1].endTime) / 2000

                if (nextChapter) {
                    console.log('Revert next deleted words from end, next chapter found')
                    const duration = nextChapter.duration + (nextChapter.clipFrom - clipFrom)
                    tempScenesState.set(scenes => {
                        scenes.map[nextChapterSid].clipFrom = clipFrom
                        scenes.map[nextChapterSid].duration = duration
                        scenes._clearCache()
                        seekOffset = getChapterSeekOffset(nextChapterSid, scenes)
                        return scenes
                    })
                } else {
                    console.log('Revert next deleted words from end, next chapter not found')
                    const clipTo = chapter.sourceDuration || selectedWords.at(-1).endTime / 1000
                    const duration = clipTo - clipFrom
                    tempScenesState.set(scenes => {
                        Object.keys(scenes.scenes).map(chapterSid => {
                            if (scenes.map[chapterSid]?.index > chapter.index) {
                                scenes.map[chapterSid].index += 1
                            }
                        })
                        scenes.map[tempChapterSid] = new ChapterOrder()
                        scenes.map[tempChapterSid].clipFrom = clipFrom
                        scenes.map[tempChapterSid].clipTo = clipTo
                        scenes.map[tempChapterSid].duration = duration
                        scenes.map[tempChapterSid].linkSid = chapter.linkSid || chapterSid
                        scenes.map[tempChapterSid].index = chapter.index + 1
                        scenes.map[tempChapterSid].sourceDuration = (chapter.srcDuration || chapter.sourceDuration)
                        scenes.map[tempChapterSid].cid = tempChapterSid
                        seekOffset = getChapterSeekOffset(tempChapterSid, scenes)
                        return scenes
                    })
                }
            } else if (selectedWords[0] === nextDeletedWords[0]) {
                // Case : next deleted words are selected from start till mid
                console.log('Revert next deleted words from start')
                seekOffset = getChapterSeekOffset(chapterSid, scenes)

                const clipTo = (selectedWords.at(-1).endTime + nextDeletedWords[indexOfLast + 1].startTime - 2) / 2000
                const duration = (clipTo - (chapter.clipFrom || 0))
                tempScenesState.set(scenes => {
                    scenes.map[chapterSid].duration = duration
                    scenes.map[chapterSid].clipTo = clipTo
                    return scenes
                })
            } else {
                // Case : selected words in the mid of next deleted word
                console.log('Revert next deleted words in between')
                const clipFrom = (selectedWords[0].startTime + nextDeletedWords[indexOfFirst - 1].endTime) / 2000
                const clipTo = (selectedWords.at(-1).endTime + nextDeletedWords[indexOfLast + 1].startTime - 2) / 2000
                const duration = clipTo - clipFrom
                tempScenesState.set(scenes => {
                    Object.keys(scenes.scenes).map(chapterSid => {
                        if (scenes.map[chapterSid]?.index > chapter.index) {
                            scenes.map[chapterSid].index += 1
                        }
                    })
                    scenes.map[tempChapterSid] = new ChapterOrder()
                    scenes.map[tempChapterSid].clipFrom = clipFrom
                    scenes.map[tempChapterSid].clipTo = clipTo
                    scenes.map[tempChapterSid].duration = duration
                    scenes.map[tempChapterSid].linkSid = chapter.linkSid || chapterSid
                    scenes.map[tempChapterSid].index = chapter.index + 1
                    scenes.map[tempChapterSid].sourceDuration = (chapter.srcDuration || chapter.sourceDuration)
                    scenes.map[tempChapterSid].cid = tempChapterSid
                    scenes._clearCache()
                    seekOffset = getChapterSeekOffset(tempChapterSid, scenes)
                    return scenes
                })
            }
        }
        if (!shouldUpdate) { // ATM will always be false
            seekPlayback(seekOffset + selectedWords[0].startTime)
        } else {
            incrementPlaybackVersion()
        }
        return resetSelection()
    }

    function getChapterName(chapter) {
        return getLinkedChapterName(linkedChapter) || chapter.name?.substring(0, chapter?.name.lastIndexOf('.'))
    }

    function getLinkedChapterName(linkedChapter) {
        if (!linkedChapter) {
            return null
        }
        const linkedChapters = (scenes.array || []).filter(c => c.linkSid === chapter.linkSid).sort((a, b) => (a.clipFrom - b.clipFrom))
        const index = linkedChapters?.indexOf(chapter) + 2
        return linkedChapter.name?.substring(0, linkedChapter.name.lastIndexOf('.')) + ` - ${index}`
    }

    // const reversedWordsList = React.useMemo(() =>
    //     new Array(...chapterActiveWords).reverse()
    //     , [wordsObject.words.length, chapter?.duration]) // needed with sentence highlight instead of word

    function HighlightSentence(word) {
        if (word === null || draggable || selectedWords?.length) {
            return setHighlightedWord(null)
        }

        if (highlightedWord === word) {
            return
        }

        // sentence highlight instead of word
        // console.log('HighlightSentence', { word, chapterActiveWords, reversedWordsList })

        // const reversedWordIndex = reversedWordsList.indexOf(word)
        // const wordIndex = chapterActiveWords.indexOf(word)
        // const before = reversedWordsList.slice(reversedWordIndex)
        // const after = chapterActiveWords.slice(wordIndex)

        // const firstWordIndex = before.findIndex((el, i) => el.word.match(/[.?]/) && i > 0) - 1
        // const lastWordIndex = after.findIndex(el => el.word.match(/[.?]/))
        // const firstWord = before[firstWordIndex] || chapterActiveWords[0]
        // const lastWord = after[lastWordIndex] || chapterActiveWords.at(-1)

        setHighlightedWord(word)
    }

    function selectHighlightedWord() {
        if (!highlightedWord || selectedWords?.length) {
            return
        }

        firstWordTime.current = highlightedWord.startTime
        lastWordTime.current = highlightedWord.startTime

        const wordsFound = getSeletctedWords()

        console.log('highlighted words found', { wordsFound, highlightedWord })
        wordsFound?.length && setSelectedWords(wordsFound)
        setHighlightedWord(null)
    }

    function onClickOutSide() {
        window.getSelection()?.removeAllRanges();
    }

    React.useEffect(() => {
        window.addEventListener('mouseup', onClickOutSide);
        return () => {
            window.removeEventListener('mouseup', onClickOutSide);
        }
    }, []);

    if (!chapter?.index) {
        return null
    }

    return (<div {...rest} data-cy="chapter-element" onMouseUp={getTextSelection}>
        {!draggable && !chapter.linkSid && !hideDeletedWords && !!prevDeletedWords.length && <div className="deleted" data-cy="prev-deleted-words">
            <span>
                {!chapter.linkSid && prevDeletedWords.map((word, i) => <WordElement key={i} isBlurable={false} deleted={true} onClick={() => setSelectedWords(prevDeletedWords)} selectedWords={selectedWords} word={word} handleDeletion={revertDeletedWords} resetSelection={resetSelection} index={i} contentWrapperRef={contentWrapperRef}
                    scrollVersion={scrollVersion} />)}
            </span>
        </div>}

        <div key={chapterSid} className={`scene-wrapper chapter chapter-${chapter.index}`}>
            <div className={classNameEditor}>
                <span className="main-content" data-cy="chapter-words" onMouseLeave={() => HighlightSentence(null)}>
                    <React.Fragment key={chapterSid}>
                        {!!(chapterActiveWords.length || chapter.transcripted || chapter.linkSid) &&
                            <ChapterClosedCaptions
                                isBlurable={isBlurable}
                                closedCaption={{ words: chapterActiveWords }}
                                selectedWords={selectedWords}
                                resetSelection={resetSelection}
                                HighlightSentence={HighlightSentence}
                                highlightedWord={highlightedWord}
                                className="closed-captions"
                                chapterSid={chapterSid}
                                chapters={chapters}
                                contentWrapperRef={contentWrapperRef}
                                scrollVersion={scrollVersion} 
                                experimentalKeepBlankWhenCutWord={experimentalKeepBlankWhenCutWord}
                                />
                        }
                    </React.Fragment>
                </span>

                {draggable && <DraggableDetails thumbnail={thumbnail} />}

            </div>
        </div>
        {
            !draggable && !hideDeletedWords && <div className="deleted" data-cy="next-deleted-words" onMouseUp={getTextSelection}>
                {nextDeletedWords.map((word, i) => <WordElement key={i} isBlurable={isBlurable} deleted={true} onClick={() => setSelectedWords(nextDeletedWords)} selectedWords={selectedWords} word={word} handleDeletion={revertDeletedWords} resetSelection={resetSelection} index={i} contentWrapperRef={contentWrapperRef}
                    scrollVersion={scrollVersion} />)}
            </div>
        }
    </div >)
}