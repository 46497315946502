import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

interface DeleteConfirmationProps {
  onHide: () => void;
  onApproval: () => void;
  title?: string;
  deleteText?: string;
  question?: string;
}

export default function DeleteConfirmation({
  onHide,
  onApproval,
  title,
  deleteText,
  question,
}: DeleteConfirmationProps) {
  const modalBodyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalBodyRef.current && !modalBodyRef.current.contains(event.target as Node)) {
        onHide();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onHide]);

  return (
    <Modal
      show={true}
      id="delete-confirmation-dialog"
      className="project-delete"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onHide}
      centered
    >
      <Modal.Body ref={modalBodyRef}>
        <Modal.Title>{title ? title : 'Delete Confirmation'}</Modal.Title>
        <p style={{ whiteSpace: 'pre-line' }}>
          {deleteText
            ? deleteText
            : 'Once deleting an asset, you will no longer have access\n to use that asset anymore.'}
        </p>
        <p>
          <b>{question ? question : 'Are you sure you want to delete this asset?'}</b>
        </p>
        <Button variant="secondary" onClick={(e) => {
          e.stopPropagation();
          onHide();
        }
          }>
          Close
        </Button>
        <Button id="asset-delete-button" variant="danger" onClick={(e) => {
          e.stopPropagation();
          onApproval();
        }
          }>
          Delete
        </Button>
      </Modal.Body>
    </Modal>
  );
}
