
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'

export enum LogoStatus {
	PENDING = 0,
	PROCESSING = 1,
	READY = 2,
	DELETED = 3,
	ERRONEOUS = 5,
};

export interface ILogo extends IBase {
	status?: number | null;
	key?: string | null;
	thumbnailVersion?: number | null;
	assetSid?: string | null;
	linkSid?: string | null;
	thumbnail?: string | null;
	name?: string | null;
	srcWidth?: number | null;
	srcHeight?: number | null;
	size?: number | null;
	top?: number | null;
	bottom?: number | null;
	left?: number | null;
	right?: number | null;
	removeWhiteBackground?: boolean | null;
	crop?: boolean | null;
	opacity?: number | null;
}

export interface ILogoProperties extends IBaseProperties {
	status?: boolean;
	key?: boolean;
	thumbnailVersion?: boolean;
	assetSid?: boolean;
	linkSid?: boolean;
	thumbnail?: boolean;
	name?: boolean;
	srcWidth?: boolean;
	srcHeight?: boolean;
	size?: boolean;
	top?: boolean;
	bottom?: boolean;
	left?: boolean;
	right?: boolean;
	removeWhiteBackground?: boolean;
	crop?: boolean;
	opacity?: boolean;
}

export interface ILogoFilter extends IBaseFilter {
}

export class Logo<IType extends ILogo = ILogo, ITypeProperties extends ILogoProperties = ILogoProperties> extends Base<IType, ITypeProperties> implements ILogo {
	
	static className = 'Logo';
	static NAME = 'logo';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		key: true,
		thumbnailVersion: true,
		assetSid: true,
		linkSid: true,
		name: true,
		srcWidth: true,
		srcHeight: true,
		size: true,
		top: true,
		bottom: true,
		left: true,
		right: true,
		removeWhiteBackground: true,
		crop: true,
		opacity: true,
	};
	
	/**
	 * @deprecated use LogoStatus instead
	 */
	static STATUS: any = {
		PENDING: LogoStatus.PENDING,
		PROCESSING: LogoStatus.PROCESSING,
		READY: LogoStatus.READY,
		DELETED: LogoStatus.DELETED,
		ERRONEOUS: LogoStatus.ERRONEOUS,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get key(): string | null | undefined {
		return this.values.key
	}
			
	get thumbnailVersion(): number | null | undefined {
		return this.values.thumbnailVersion
	}
			
	get assetSid(): string | null | undefined {
		return this.values.assetSid
	}
			
	set assetSid(value: string | null | undefined) {
		if(this.values.assetSid !== value) {
			this.propertyChanged('assetSid', value);
			this.values.assetSid = value;
		}
	}
			
	get linkSid(): string | null | undefined {
		return this.values.linkSid
	}
			
	set thumbnail(value: string | null | undefined) {
		if(this.values.thumbnail !== value) {
			this.propertyChanged('thumbnail', value);
			this.values.thumbnail = value;
		}
	}
			
	get name(): string | null | undefined {
		return this.values.name
	}
			
	set name(value: string | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	get srcWidth(): number | null | undefined {
		return this.values.srcWidth
	}
			
	set srcWidth(value: number | null | undefined) {
		if(this.values.srcWidth !== value) {
			this.propertyChanged('srcWidth', value);
			this.values.srcWidth = value;
		}
	}
			
	get srcHeight(): number | null | undefined {
		return this.values.srcHeight
	}
			
	set srcHeight(value: number | null | undefined) {
		if(this.values.srcHeight !== value) {
			this.propertyChanged('srcHeight', value);
			this.values.srcHeight = value;
		}
	}
			
	get size(): number | null | undefined {
		return this.values.size
	}
			
	set size(value: number | null | undefined) {
		if(this.values.size !== value) {
			this.propertyChanged('size', value);
			this.values.size = value;
		}
	}
			
	get top(): number | null | undefined {
		return this.values.top
	}
			
	set top(value: number | null | undefined) {
		if(this.values.top !== value) {
			this.propertyChanged('top', value);
			this.values.top = value;
		}
	}
			
	get bottom(): number | null | undefined {
		return this.values.bottom
	}
			
	set bottom(value: number | null | undefined) {
		if(this.values.bottom !== value) {
			this.propertyChanged('bottom', value);
			this.values.bottom = value;
		}
	}
			
	get left(): number | null | undefined {
		return this.values.left
	}
			
	set left(value: number | null | undefined) {
		if(this.values.left !== value) {
			this.propertyChanged('left', value);
			this.values.left = value;
		}
	}
			
	get right(): number | null | undefined {
		return this.values.right
	}
			
	set right(value: number | null | undefined) {
		if(this.values.right !== value) {
			this.propertyChanged('right', value);
			this.values.right = value;
		}
	}
			
	get removeWhiteBackground(): boolean | null | undefined {
		return this.values.removeWhiteBackground
	}
			
	set removeWhiteBackground(value: boolean | null | undefined) {
		if(this.values.removeWhiteBackground !== value) {
			this.propertyChanged('removeWhiteBackground', value);
			this.values.removeWhiteBackground = value;
		}
	}
			
	get crop(): boolean | null | undefined {
		return this.values.crop
	}
			
	set crop(value: boolean | null | undefined) {
		if(this.values.crop !== value) {
			this.propertyChanged('crop', value);
			this.values.crop = value;
		}
	}
			
	get opacity(): number | null | undefined {
		return this.values.opacity
	}
			
	set opacity(value: number | null | undefined) {
		if(this.values.opacity !== value) {
			this.propertyChanged('opacity', value);
			this.values.opacity = value;
		}
	}
			
	
	static get<IType extends ILogo = ILogo>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends ILogoFilter = ILogoFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends ILogoFilter = ILogoFilter, IType extends ILogo = ILogo>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
