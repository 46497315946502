import classNames from 'classnames';
import { pick } from 'lodash/fp';
import React from 'react';
import CuePoint from '../../client/cuePoint';
import TitleCuePoint from '../../client/titleCuePoint';
import { useAnimation } from '../../hooks/useAnimation';
import {
  activeEditCuePointState,
  cuePointsState,
  cuePointsVersionState,
  editCuePointState,
  onCuePointChange,
  removeCuePoint,
  saveCuePoint,
  setCuePoint,
} from '../../state/cuePoints';
import { handleError } from '../../state/error';
import { onMenuTextFieldBlur, onMenuTextFieldChange} from '../../state/menu';
import { scenesState, sequenceSave, sequenceState } from '../../state/sequence';
import Menu from '../../ui-components/Menu/Menu';
import { trackEvent } from '../../utils/analityics.utils';
import { calculateDuplicateCuePointPlacement } from '../../utils/cuePoint.utils';
import { validateTimeValue } from '../../utils/time-utils';
import { TimeFormat } from '../common/time-code/time-code.component';
import { applyAnimationColorsNames, color2string, getAnimationProps } from '../cue-points/graphic';
import { TextCuePointElement } from '../cue-points/title';
import SVG from '../svg';
import VisualsMenuColorsComponent from './VisualsMenuColorsComponent';
import CuePointPropertiesItem from './cue-point';
import { MenuItemColorPicker } from './cuePoint-palette';
import MonitorMenu from './monitor-menu';
import useVisualsMenuColorsChange from '../../hooks/useVisualsMenuColorsChange';
import { visualPackageReplaceState } from '../../dialogs/assets';
import { AssetType } from '../../client/asset';

const TITLE_TYPE = TitleCuePoint.TITLE_TYPE;
const COLOR_HEIGHT = 65;
const MAX_TITLE_CHARACTERS = 14;
const MAX_GRAPHIC_TEXT_CHARACTERS = 50;
const MAX_TEXT_CHARACTERS = 110;

const END_PADDING = 2000;

const TextCounter = ({ count, maxCount, textDirection }) => (
  <div className={`character-limit ${textDirection}`}>
    {count || 0}/{maxCount}
  </div>
);

/**
 * @param {object} props
 * @param {TitleCuePoint} props.cuePoint
 */
function Visual({ cuePoint }) {
  const scenes = scenesState.use((scenes) => scenes.map);
  const lastActiveScene = scenesState.use((scenes) => scenes.filter.contentChapters?.at(-1));
  const sequence = sequenceState.use();
  const useTitleCuePoints = sequenceState.use((sequence) => sequence.useTitleCuePoints);
  const [bold, setBold] = React.useState(
    cuePoint?.fontWeight === undefined ? sequence?.style?.fontWeight === 'Bold' : cuePoint?.fontWeight === 'Bold'
  );
  const textAnimation = useAnimation(cuePoint?.assetSid || sequence?.style?.textAssetSid, sequence?.aspectRatio);
  const slideAnimation = useAnimation(cuePoint?.assetSid || sequence?.style?.slideAssetSid, sequence?.aspectRatio);
  const willRenderLottieCuePointElement = cuePoint?.titleType !== TitleCuePoint.TITLE_TYPE.SLIDE && textAnimation;
  const activeTime = cuePoint?.activeTimes?.length && cuePoint.activeTimes[0];

  const [propertiesMode, setPropertiesMode] = React.useState(false);
  const [showColorPicker, setShowColorPicker] = React.useState(false);

  const titleInputRef = React.useRef();
  const textInputRef = React.useRef();
  const [charactersCount, setCharactersCount] = React.useState({
    title: cuePoint.title?.length || 0,
    text: cuePoint.text?.length || 0,
  });
  const activeEditCuePoint = activeEditCuePointState.use();
  const visualPackageReplaceStateValue = visualPackageReplaceState.use();

  const onEditToggle = (e) => {
    setPropertiesMode(!propertiesMode);
  };

  React.useEffect(() => {
    if (titleInputRef.current) {
      titleInputRef.current.textContent = cuePoint?.title || '';
    }
  }, [titleInputRef.current]);

  React.useEffect(() => {
    if (textInputRef.current) {
      textInputRef.current.textContent = cuePoint?.text || '';
    }
  }, [textInputRef.current]);

  async function onDelete() {
    try {
      await cuePoint.remove();
      removeCuePoint(cuePoint.sid);
      trackEvent('visuals-delete', { type: isSlide ? 'text-slide' : 'graphic-text' });
    } catch (err) {
      console.error('Delete cue-point failed', err);
      handleError({
        statusCode: 10,
        responseError: err,
      });
    }
  }

  async function onDuplicate() {
    try {
      let newCuePoint = new TitleCuePoint(sequence.sid);
      let propsToDuplicate = [
        'left',
        'chapterSid',
        'type',
        'srcStartTime',
        'srcEndTime',
        'startTime',
        'endTime',
        'height',
        'text',
        'color',
        'backgroundColorIndex',
        'colors',
        'fontWeight',
      ];

      const cuePointJson = pick(propsToDuplicate, cuePoint.toJSON());
      newCuePoint.set(cuePointJson);
      newCuePoint.changedProperties = propsToDuplicate;
      newCuePoint.colors?.forEach((color) => {
        color.changedProperties = ['name', 'className', 'type', 'colorIndex', 'color'];
      });
      newCuePoint.top = calculateDuplicateCuePointPlacement(cuePoint.top, cuePoint.height);

      await newCuePoint.save();

      trackEvent('visuals-duplicate', { type: 'graphic-text' });
    } catch (err) {
      console.error('duplicate graphic-text failed', err);
    }
  }


  const isReplaceable = React.useMemo(() => {
    const isText = cuePoint.titleType === TitleCuePoint.TITLE_TYPE.TEXT || !cuePoint.titleType;
    const isSlide = cuePoint.titleType === TitleCuePoint.TITLE_TYPE.SLIDE;
    const lottieType = isText ? AssetType.LOTTIE_TEXT : AssetType.LOTTIE_SLIDE;
    return visualPackageReplaceStateValue[lottieType];
  }, [visualPackageReplaceStateValue, cuePoint.titleType]);
  

  function onReplace() {
    const { cuePoint: activeCuePoint } = editCuePointState.get();
    if (cuePoint.type === CuePoint.TYPE.LOGO || cuePoint.type === CuePoint.TYPE.WATERMARK) {
      return;
    }

    if (activeCuePoint?.sid === cuePoint?.sid) {
      editCuePointState.set({});
      return;
    }

    const isText = cuePoint.titleType === TitleCuePoint.TITLE_TYPE.TEXT || !cuePoint.titleType;
    const isSlide = cuePoint.titleType === TitleCuePoint.TITLE_TYPE.SLIDE;

    let defaultType = 'text';
    if (isSlide){
      defaultType = 'slide';
    } 

    trackEvent('visuals-edit', { type: isSlide ? 'text-slide' : 'graphic-text', action: 'design-library-open' });

    if (activeCuePoint?.titleType !== cuePoint?.titleType) { // if different type close to reload dialog assets
      editCuePointState.set({});
    }

    setTimeout(() => {
      editCuePointState.set({ cuePoint, defaultType});
    }, 1);
  }

  // time utils start
  // return cue-point time in chapter and chapter
  const getCuePointTimeInChapter = (time) => {
    const chapter = scenes.array
      .filter((scene) => scene.offset <= time)
      .sort((a, b) => b.offset - a.offset)
      .shift();
    return {
      chapterSid: chapter.sid,
      timeInChapter: Math.floor((time - (chapter.offset - (chapter.clipFrom || 0))) * 1000),
    };
  };

  // time utils end

  async function onStartChange(time, minTime, maxTime) {
    trackEvent('visuals-edit', { action: 'time' });
    const activeTimeDuration = activeTime.end - time;
    const validateTime = validateTimeValue(time, minTime, maxTime) / 1000;
    const { timeInChapter, chapterSid } = getCuePointTimeInChapter(validateTime);
    cuePoint.startTime = Math.ceil(timeInChapter);
    // update end time - if chapter change it will be changed
    cuePoint.endTime = Math.floor(timeInChapter + activeTimeDuration);
    cuePoint.chapterSid = chapterSid;
    onCuePointChange(cuePoint, true);
  }

  async function onEndChange(time, minTime, maxTime) {
    trackEvent('visuals-edit', { action: 'time' });
    cuePoint.endTime = Math.floor(validateTimeValue(time, minTime, maxTime) - cuePoint.offset);
    onCuePointChange(cuePoint, true);
  }

  async function onChangeFontWeight() {
    trackEvent('visuals-edit', { type: isSlide ? 'text-slide' : 'graphic-text', action: 'font-weight' });
    const isBold = !bold;
    setBold(isBold);
    cuePoint.fontWeight = isBold ? 'Bold' : 'Regular';
    setCuePoint(cuePoint, sequence, scenes);
    onCuePointChange(cuePoint, true);
  }

  function onKeyDown(event, maxCharacters) {
    const { key, keyCode, ctrlKey, target } = event;
    if (keyCode == 13 || key === 'Enter') {
      return event.preventDefault();
    }

    // check if user selected some text and wants to overwrite
    const sel = window.getSelection && window.getSelection();
    let range = null;
    if (sel && sel.rangeCount > 0) {
      range = window.getSelection()?.getRangeAt(0)?.toString()?.length;
    }
    // detecting ctrl+a only work for windows @TODO configure for mac
    const isSelectAll = (keyCode == 65 || keyCode == 97) && ctrlKey === true;

    if (
      keyCode != 8 &&
      key !== 'Backspace' &&
      maxCharacters &&
      target.textContent.length >= maxCharacters &&
      !range &&
      !isSelectAll
    ) {
      return event.preventDefault();
    }
  }

  const isGraphicText = cuePoint instanceof TitleCuePoint && !cuePoint.titleType;
  function onPaste(e, field = 'text') {
    e.preventDefault();
    const selectedCount = window.getSelection()?.getRangeAt(0)?.toString()?.length;
    const clipboardData = (e.clipboardData || window.clipboardData).getData('text');
    const charLeft =
      (isGraphicText ? MAX_GRAPHIC_TEXT_CHARACTERS : field === 'text' ? MAX_TEXT_CHARACTERS : MAX_TITLE_CHARACTERS) -
      (e.target.textContent.length || 0) +
      selectedCount;
    const trimmmedData = clipboardData?.slice(0, charLeft);

    const selection = window.getSelection();
    if (!selection.rangeCount) {
      return;
    }

    const newTextNode = document.createTextNode(trimmmedData);
    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(newTextNode);

    const range = selection.getRangeAt(0);
    range.setStartAfter(newTextNode);
    range.setEndAfter(newTextNode);
    selection.removeAllRanges();
    selection.addRange(range);

    setCharactersCount((count) => ({ ...count, [field]: e.target.textContent?.length || 0 }));
  }

  async function onTextChange(event, field = 'text') {
    onMenuTextFieldChange(event, cuePoint, field);
    setCharactersCount((count) => ({ ...count, [field]: event.target.textContent?.length || 0 }));
    // await cuePoint.calcSizeByLottie(textAnimation);
  }

  async function onBlur(event, field) {
    trackEvent('visuals-edit', {
      type: isSlide ? 'text-slide' : 'graphic-text',
      action: !isSlide ? 'text' : field ? 'title' : 'subtitle',
    });
    onMenuTextFieldBlur(event, cuePoint, field);
    cuePoint.clientVersion++;
  }

  const isSlide = cuePoint.titleType === TitleCuePoint.TITLE_TYPE.SLIDE,
    isImage = cuePoint.titleType === TitleCuePoint.TITLE_TYPE.IMAGE,
    isFootage = cuePoint.titleType === TitleCuePoint.TITLE_TYPE.VIDEO,
    isIcon = cuePoint.titleType === TitleCuePoint.TITLE_TYPE.SVG,
    isCredit = cuePoint.titleType === TitleCuePoint.TITLE_TYPE.CREDIT,
    isText = cuePoint.titleType === TitleCuePoint.TITLE_TYPE.TEXT || !cuePoint.titleType;

  const isMedia = isImage || isFootage || isIcon;
  const isEditable = isMedia || isText || isSlide;

  const activeAnimation = isSlide ? slideAnimation : isText && textAnimation;
  const activeAnimationMidTime = activeAnimation?.data && (activeAnimation.data.op / activeAnimation.data.fr) * 500;

  const extractedColors = (activeAnimation && getAnimationProps(activeAnimation)) || [];
  const cuePointColors = applyAnimationColorsNames(extractedColors, cuePoint.colors);

  const colorPickerMaxHeight = showColorPicker || isSlide ? cuePointColors?.length * COLOR_HEIGHT : 0;

  // TODO include intro time if exist
  const minTime = 0;
  const maxTime = sequence.duration * 1000;

  if (!sequence) return;

  const cuePointClassName = classNames({
    edit: propertiesMode,
    text: isText,
    slide: isSlide,
    image: isImage,
    footage: isFootage,
    icon: isIcon,
    credit: isCredit,
  });

  const onColorsChange = useVisualsMenuColorsChange();
  
  React.useEffect(() => {
    // sets edit mode true/false on activeEditCuePoint change
    activeEditCuePoint !== cuePoint.sid ? setPropertiesMode(false) : setPropertiesMode(true);
  }, [activeEditCuePoint, cuePoint.sid, setPropertiesMode]);

  const GraphicText = (
    <>
      <div className={`visual-item-wrapper ${sequence.textDirectionByLanguage}`}>
        <div className={`visual-item ${sequence.textDirectionByLanguage}`}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className="field title-input-wrapper" style={{ marginRight: '6px' }}>
              <div
                contentEditable={true}
                className="title-input"
                data-cy="visuals-title-input"
                placeholder="Add text here"
                onKeyUp={onTextChange}
                onKeyDown={(e) => onKeyDown(e, MAX_GRAPHIC_TEXT_CHARACTERS)}
                onBlur={onBlur}
                onPaste={onPaste}
                ref={textInputRef}
              />
              <TextCounter
                count={charactersCount.text}
                maxCount={MAX_GRAPHIC_TEXT_CHARACTERS}
                textDirection={sequence.textDirectionByLanguage}
              />
            </div>
            <div className="actions-left">
              <SVG className={classNames({ active: bold })} name="bold" title="bold" onClick={onChangeFontWeight} />
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '10px' }}>
        {!!cuePointColors?.length && (
          <VisualsMenuColorsComponent
            colorsObjects={cuePointColors}
            onColorChange={(value, colorObj, type) =>
              onColorsChange(value, colorObj, type, cuePoint, extractedColors, 'graphic-text-edit')
            }
            type='graphic-text'
          />
        )}
      </div>
    </>
  );

  const TextSlide = (
    <>
      <div className={`visual-item-wrapper`}>
        <div className={`visual-item slide-item ${sequence.textDirectionByLanguage}`}>
          <div
            className={`slide-preview 
			ratio-${sequence?.aspectRatio?.replace(':', '-')}`}
          >
            <TextCuePointElement
              animation={slideAnimation}
              inMenu={true}
              autoPlay={false}
              loop={true}
              cuePoint={cuePoint}
              moveable={false}
            />
          </div>
          <div className={`field title-input-wrapper ${sequence.textDirectionByLanguage} `}>
            <div
              contentEditable={true}
              className="title-input"
              placeholder="Add Title"
              onKeyUp={(e) => onTextChange(e, 'title')}
              onKeyDown={(e) => onKeyDown(e, MAX_TITLE_CHARACTERS)}
              onBlur={(e) => onBlur(e, 'title')}
              ref={titleInputRef}
              onPaste={(e) => onPaste(e, 'title')}
            />
            <TextCounter
              count={charactersCount.title}
              maxCount={MAX_TITLE_CHARACTERS}
              textDirection={sequence.textDirectionByLanguage}
            />
          </div>
          <div className="field paragraph-input-wrapper">
            <div
              contentEditable={true}
              className="paragraph-input"
              placeholder="Enter Subtext"
              onKeyUp={onTextChange}
              onKeyDown={(e) => onKeyDown(e, MAX_TEXT_CHARACTERS)}
              onBlur={onBlur}
              ref={textInputRef}
              onPaste={onPaste}
            />
            <TextCounter
              count={charactersCount.text}
              maxCount={MAX_TEXT_CHARACTERS}
              textDirection={sequence.textDirectionByLanguage}
            />
          </div>
        </div>
        {/* COLORS */}
      </div>
      <div
        className="menu-item--color-picker open"
        style={{
          maxHeight: colorPickerMaxHeight,
          display: isSlide && !propertiesMode ? 'none' : 'block',
          marginTop: '10px',
        }}
      >
        {!!cuePointColors?.length && (
          <VisualsMenuColorsComponent
            colorsObjects={cuePointColors}
            onColorChange={(value, colorObj, type) =>
              onColorsChange(value, colorObj, type, cuePoint, extractedColors, 'text-slide-edit')
            }
            type='text-slide'
          />
        )}
      </div>
    </>
  );

  // const seekTime = React.useMemo()

  if (!cuePoint || !activeTime) {
    return null;
  }

  if (!cuePoint || !activeTime) {
    return null;
  }

  return (
    <CuePointPropertiesItem
      type="visual"
      cuePoint={cuePoint}
      onStartChange={onStartChange}
      onEndChange={onEndChange}
      seekCalculator={(animation) => {
          let seekTime = activeTime.start + (activeAnimationMidTime || 1000);
          if (seekTime > activeTime.end) {
            seekTime = activeTime.end - (activeTime.end - activeTime.start) / 2;
          }
          return seekTime;
        }
      }
      // onDoubleClick={isEditable ? onReplace : undefined}
      animation={isText && activeAnimation}
      enabled={useTitleCuePoints}
      bordered={true}
      deleteToggle={true}
      onDelete={onDelete}
      editToggle={isSlide}
      showDuplicateButton={isText}
      editMode={propertiesMode}
      onEdit={isSlide && onEditToggle}
      onDuplicate={isText && onDuplicate}
      onReplace={isReplaceable && onReplace}
      replaceToggle={isEditable}
      timerToggle={true}
      timeFormat={TimeFormat.MMSSmmm}
      minTime={minTime}
      maxTime={maxTime}
      className={cuePointClassName}
      data-cy="visuals-menu-item"
    >
      {isSlide ? TextSlide : GraphicText}
    </CuePointPropertiesItem>
  );
}

const filterCuePoints = (cuePointsData) => {
  return Object.values(cuePointsData)
    .filter(
      (cuePoint) =>
        cuePoint &&
        cuePoint instanceof TitleCuePoint &&
        cuePoint.type !== TitleCuePoint.TYPE.LOGO &&
        cuePoint.type !== TitleCuePoint.TYPE.INTRO &&
        cuePoint.type !== TitleCuePoint.TYPE.OUTRO &&
        cuePoint.type !== TitleCuePoint.TYPE.FRAME &&
        cuePoint.activeTimes.length &&
        cuePoint.status !== CuePoint.STATUS.DELETED &&
        cuePoint.status !== CuePoint.STATUS.INACTIVE &&
        cuePoint.sid !== 'tmp'
    )
    .sort((a, b) => a.order - b.order);
};

export default function VisualsProperties() {
  const sequence = sequenceState.use();
  const cuePointsVersion = cuePointsVersionState.use();
  const [enabled, setEnabled] = React.useState(!!sequence.useTitleCuePoints);
  const [filter, setFilter] = React.useState(!!sequence.useTitleCuePoints);
  const cuePointsData = cuePointsState.use();
  const cuePointsCount = Object.values(cuePointsData)?.length;
  const cuePoints = React.useMemo(() => filterCuePoints(cuePointsData), [cuePointsCount, cuePointsVersion]);

  async function onEnabledChange() {
    trackEvent('visuals-show-hide-all', { action: enabled ? 'hide-all-visuals' : 'show-all-visuals' });
    sequence.useTitleCuePoints = !enabled;
    setEnabled(sequence.useTitleCuePoints);
    await sequenceSave(sequence);
  }

  React.useEffect(() => {
    return () => editCuePointState.set({});
  }, []);

  const menuClassNames = classNames({ [`filter-${filter}`]: filter });

  return (
    <Menu
      type="visuals"
      header="Visuals"
      className={menuClassNames}
      enabled={enabled}
      onChange={onEnabledChange}
      customIcon={{ show: 'show', hide: 'hide' }}
    >
      <div style={{ position: 'relative', width: '100%' }}>
        <MonitorMenu trigger="visuals-menu" />
      </div>

      {cuePoints.map((cp) => (
        <Visual key={cp.sid} cuePoint={cp} />
      ))}
    </Menu>
  );
}
