
import _ from "lodash";
import React from "react";
import { searchIcon } from "../../../static/svg/musicMenuIcons";
import { lastSearchedMusicTagState } from "../../state/menu";
import SVG from "../svg";
import { SearchingContainer, SearchMusicDiv, Tag, TagsContainer } from "./MusicMenu.styled";
import { MUSIC_SEARCH_TAGS } from "./projectAudio";





interface SearchingControllerProperties {
    onSearchMusicByFilter: Function;
    setLibraryLoading: Function;
}


export default function SearchingController({ onSearchMusicByFilter, setLibraryLoading }: SearchingControllerProperties) {

    const lastSearchedMusicTag = lastSearchedMusicTagState.use();

    const debounceGetMusicTrack = React.useCallback(_.debounce(onSearchMusicByFilter, 700), []);

    const onMusicSearch = (value: string, withDebounce: boolean) => {        
        if (value === lastSearchedMusicTag) {
            return;
        }
        lastSearchedMusicTagState.set(value);
        if(value.length < 1 || value.length > 2){
            setLibraryLoading(true);
            if (withDebounce) {
                debounceGetMusicTrack(value)
            } else {
                debounceGetMusicTrack.cancel();
                onSearchMusicByFilter(value);
            }
        }
    }

    
    return (
        <SearchMusicDiv>
            <SearchingContainer>
                {searchIcon}
                <input type='text' placeholder="Search music" value={lastSearchedMusicTag} onChange={(e) => {
                    onMusicSearch(e.target.value, true);
                }} />
                <span onClick={() => {
                    onMusicSearch('', true);
                }}>
                    <SVG name='close' viewBox="0 0 32 32" />
                </span>

            </SearchingContainer>
            <TagsContainer>
                {MUSIC_SEARCH_TAGS.map((item, index) => {
                    return <Tag key={index} onClick={() => {
                        onMusicSearch(item, true);
                    }}>{item}</Tag>
                })}

            </TagsContainer>
        </SearchMusicDiv>
    );

}