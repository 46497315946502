import styled from '@emotion/styled/macro';
import React from 'react';
import Col from 'react-bootstrap/Col';
import { COLORS } from '../../../constants/colors';
import SVG from '../../components/svg';
import { AppContext } from '../../lib/app-context';
import Asset from '../../client/asset';
import LottiePlayer from '../../components/lottie-player';
import { FontPrest, getImageToAnimation } from '../../utils/lottie-animation.utils';
import { IColor } from '../../client/base/color';
import { color2string, getAnimationProps } from '../../components/cue-points/graphic';
import * as Lottie from '../../../../data-layer/shared/lottie';
import { AnimationItem } from 'lottie-web';
import { DEFAULT_FONT } from '../../utils/font.utils';
import { useAnimation } from '../../hooks/useAnimation';

const VPItem = styled.div`
  position: relative;
  height: fit-content;
  width: 92%;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: ${COLORS.peech_gray_100};
  border-radius: 8px;
  min-width: 180px;
  margin: 10px;
`;

const PackagePreview = styled.div`
  width: 100%;
  min-width: 180px;
  aspect-ratio: 16 / 9;
  background-color: lightblue;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
  overflow: hidden;
`;

const PackageNameWrapper = styled.div<{ isSelected: boolean }>`
  width: 100%;
  height: 30px;
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 8px 8px;

  span {
    width: 85%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: ${COLORS.peech_gray_500};
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
  }

  label {
    height: 19px;
    width: 19px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: solid 1px;
    border-color: ${COLORS.peech_gray_400};
    border-radius: 4px;
    position: relative;
    input {
      height: 10px;
      width: 10px;
      margin: 0;
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .checkmark {
      position: absolute;
      top: -1px;
      right: -1px;
      display: ${({ isSelected }): string => (isSelected ? 'flex' : 'none')};
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      height: 19px;
      width: 19px;
      border-radius: 4px;
      background-color: #ea429d;
      fill: ${COLORS.peech_neutral_white};
    }
  }
`;
const IsCustomIcon = styled.div`
  height: 28px;
  width: 28px;
  position: absolute;
  margin-top: 8px;
  margin-left: 10px;
  background: linear-gradient(90deg, #ea429d 0%, #ea429d 9.11%, #ea75b4 24.25%, #ae8bfa 54.15%, #1f9ffb 99.42%);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    position: absolute;
    top: 6px;
    left: 6px;
  }
`;

interface VisualPackageItemProps {
  visPack: Object;
  isSelected: boolean;
  onItemClick: Function;
  isCustom: boolean;
  lottiePreview: Asset;
  logoAssetUrl: string | null;
  thumbnailUrl: string | null;
  colors: IColor[];
  fonts: FontPrest[];
  isModal: boolean;
}

export default function VisualPackageItem({
  visPack,
  isSelected = false,
  onItemClick,
  isCustom,
  lottiePreview,
  logoAssetUrl,
  thumbnailUrl,
  colors,
  fonts,
}: VisualPackageItemProps) {
  const [animationData, setAnimationData] = React.useState<null | object>(null);
  const animationSrc = useAnimation(lottiePreview?.sid || '', '16:9');

  const onSelect = () => {
    if (!isSelected) {
      onItemClick(visPack.sid);
    }
  };

  const fallbackBg = '/images/visual-pack-bg.jpg';
  const logoUrl = React.useMemo(() => logoAssetUrl || '/images/logo.png', [logoAssetUrl]);
  const bgPhoto = React.useMemo(() => thumbnailUrl || fallbackBg, [thumbnailUrl]);

  React.useEffect(async () => {
    if (animationSrc) {
      const json = animationSrc?.data;
      if (json) {
        const lottieAssetLogo = json?.assets.find((a: any) => a.id === 'logo');
        if (logoUrl) {
          try {
            const imageBase64 = await getImageToAnimation(logoUrl, lottieAssetLogo.w, lottieAssetLogo.h);
            lottieAssetLogo.p = imageBase64;
            lottieAssetLogo.u = '';
          } catch (error) {
            // TODO: if user logo failed we can try add the peech logo to animation
            console.log('failed to load logo in package preview');
          }
        }
        const bgAsset = json?.assets.find((a: any) => a.id === 'bg-photo');
        if (bgAsset) {
          try {
            const bgBase64 = await getImageToAnimation(bgPhoto, bgAsset.w, bgAsset.h);
            bgAsset.p = bgBase64;
            bgAsset.u = '';
          } catch (error) {
            bgAsset.p = fallbackBg;
            bgAsset.u = '';
          }
        }

        if (json?.fonts?.list?.length && fonts?.length) {
          json.fonts.list.forEach((font: { fFamily: string; fWeight: string }, index: number) => {
            font.fFamily = fonts[index]?.fontFamily || DEFAULT_FONT;
            font.fWeight = fonts[index]?.variant || '400';
          });
        }
        setAnimationData(json);
      }
    }
  }, [animationSrc, logoUrl, bgPhoto]);
  const animationRef = React.useRef<AnimationItem>();

  function onMouseEnter() {
    animationRef?.current?.goToAndPlay(0);
  }

  function onMouseLeave() {
    animationRef?.current?.goToAndStop(2000);
  }

  return (
    <Col xs="12" sm="6" lg="4" xl="3">
      <VPItem>
        <PackagePreview onClick={onSelect} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {lottiePreview?.sid && animationData && (
            <LottiePlayer
              animationRef={animationRef}
              inAssetsDialog
              demoWidth={'100%'}
              autoplay={false}
              pauseAt={2500}
              loop
              shouldMeasure={false}
              animationData={animationData}
              onAnimationComplete={() => console.log('anim ended')}
              subClasses={getAnimationProps(new Lottie.Animation(animationData), colors)
                ?.map((color) => {
                  console.log('subclass', color);

                  const colorValue = color2string(color, false, colors);
                  console.log('subclass', colorValue);
                  if (!colorValue) {
                    return null;
                  }
                  return [
                    {
                      className: `${color.className}.color`,
                      style: {
                        color: colorValue,
                      },
                    },
                    {
                      className: color.className?.replace('element-sid', 'color-fill-dominant-'),
                      style: {
                        color: colorValue,
                      },
                    },
                  ];
                })
                .filter((i) => i)
                .flat()}
            />
          )}
        </PackagePreview>
        <PackageNameWrapper isSelected={isSelected}>
          <span>{visPack.name}</span>
          <label onChange={onSelect}>
            <input type="checkbox" defaultChecked={isSelected} />
            <span className="checkmark">
              <SVG name="check" viewBox="0 0 36 36" />
            </span>
          </label>
        </PackageNameWrapper>
        {isCustom && (
          <IsCustomIcon>
            <SVG name="visual-package" viewBox="0 0 22 22" />
          </IsCustomIcon>
        )}
      </VPItem>
    </Col>
  );
}
