
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export enum VideoTypeStatus {
	ACTIVE = 1,
	INACTIVE = 2,
	DELETED = 3,
	IS_DEFAULT = 4,
};

export interface IVideoType extends IBase {
	status?: number | null;
	name?: string | null;
	description?: string | null;
	editRulesSid?: string | null;
	order?: number | null;
	icon?: string | null;
}

export interface IVideoTypeProperties extends IBaseProperties {
	status?: boolean;
	name?: boolean;
	description?: boolean;
	editRulesSid?: boolean;
	order?: boolean;
	icon?: boolean;
}

export interface IVideoTypeFilter extends IBaseFilter {
}

export class VideoType<IType extends IVideoType = IVideoType, ITypeProperties extends IVideoTypeProperties = IVideoTypeProperties> extends Base<IType, ITypeProperties> implements IVideoType {
	
	static className = 'VideoType';
	static NAME = 'videoType';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		name: true,
		description: true,
		editRulesSid: true,
		order: true,
		icon: true,
	};
	
	/**
	 * @deprecated use VideoTypeStatus instead
	 */
	static STATUS: any = {
		ACTIVE: VideoTypeStatus.ACTIVE,
		INACTIVE: VideoTypeStatus.INACTIVE,
		DELETED: VideoTypeStatus.DELETED,
		IS_DEFAULT: VideoTypeStatus.IS_DEFAULT,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get name(): string | null | undefined {
		return this.values.name
	}
			
	set name(value: string | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	get description(): string | null | undefined {
		return this.values.description
	}
			
	set description(value: string | null | undefined) {
		if(this.values.description !== value) {
			this.propertyChanged('description', value);
			this.values.description = value;
		}
	}
			
	get editRulesSid(): string | null | undefined {
		return this.values.editRulesSid
	}
			
	set editRulesSid(value: string | null | undefined) {
		if(this.values.editRulesSid !== value) {
			this.propertyChanged('editRulesSid', value);
			this.values.editRulesSid = value;
		}
	}
			
	get order(): number | null | undefined {
		return this.values.order
	}
			
	set order(value: number | null | undefined) {
		if(this.values.order !== value) {
			this.propertyChanged('order', value);
			this.values.order = value;
		}
	}
			
	get icon(): string | null | undefined {
		return this.values.icon
	}
			
	set icon(value: string | null | undefined) {
		if(this.values.icon !== value) {
			this.propertyChanged('icon', value);
			this.values.icon = value;
		}
	}
			
	
	static get<IType extends IVideoType = IVideoType>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IVideoTypeFilter = IVideoTypeFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IVideoTypeFilter = IVideoTypeFilter, IType extends IVideoType = IVideoType>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
	
	async enable(): Promise<boolean | null> {
		var action = `enable`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.enable !== undefined && data.enable !== null) {
			return data.enable;
		}
		return null;
	}
	
	async disable(): Promise<boolean | null> {
		var action = `disable`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.disable !== undefined && data.disable !== null) {
			return data.disable;
		}
		return null;
	}
	
	async setDefault(properties: ITypeProperties | null = null): Promise<VideoType | null> {
		properties = (properties || VideoType.PROPERTIES) as ITypeProperties;
		var action = `setDefault{${Base.toProperties(properties)}}`;
		const query = this.wrap('mutation', action);
		var data = await Base.request(query);
		data = this.unwrap(data);
		if(data && data.setDefault !== undefined && data.setDefault !== null) {
			this.set(data.setDefault);
			return this;
		}
		return null;
	}
}
