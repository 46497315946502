import { isNil } from 'lodash/fp';
import React, { Fragment, useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { revertVolumeIcon } from '../../../static/svg/musicMenuIcons';
import { signalPlaybackShouldPause } from '../../state/playback';
import { musicBackgroundInProcess, sequenceSave, sequenceState } from '../../state/sequence';
import { ButtonVariant } from '../../ui-components/Button';
import { trackEvent } from '../../utils/analityics.utils';
import { DEFAULT_VOLUME } from '../../utils/music.utils';
import {
  ApplyButton,
  BluredPlug,
  GeneratingMusicLoader,
  GeneratingMusicWrapper,
  MusicVolumeDiv,
  RevertVolumeButton,
  SliderWrapper,
} from './MusicMenu.styled';
import Loader from '../../ui-components/Loader';
import SliderWithInput from "../menu/subtitles/SliderWithInput";
import styled from "@emotion/styled";
import {Title} from "./projectAudio";

interface VolumeControllerProperties {
  disabled: boolean;
  volumeLoading: boolean;
  setVolumeLoading: Function;
}

const MusicWrapper = styled.div`
  margin-bottom: 20px;
`;

export default function VolumeController({ disabled, volumeLoading, setVolumeLoading }: VolumeControllerProperties) {
  const sequence = sequenceState.use();
  const processingMusic = musicBackgroundInProcess.use();
  const musicVolume = useMemo(() => {
    if (!isNil(sequence?.music?.volume)) {
      return Math.min(sequence?.music?.volume, 1);
    } else {
      return DEFAULT_VOLUME.volume;
    }
  }, [sequence?.music, DEFAULT_VOLUME.volume]);

  const noContentVolume = useMemo(() => {
    if (!isNil(sequence?.music?.noContentVolume)) {
      return Math.min(sequence?.music?.noContentVolume, 1);
    } else {
      return DEFAULT_VOLUME.noContentVolume;
    }
  }, [sequence?.music, DEFAULT_VOLUME.noContentVolume]);

  const [volumeChangedValue, setVolumeChangedValue] = React.useState(0);
  const [noContentvolumeChangedValue, setNoContentVolumeChangedValue] = React.useState(0);

  React.useEffect(() => {
    setVolumeChangedValue(musicVolume);
  }, [musicVolume]);

  React.useEffect(() => {
    setNoContentVolumeChangedValue(noContentVolume);
  }, [noContentVolume]);

  const shouldEnableApplyButton = useMemo(
    () =>
      !volumeLoading &&
      !processingMusic &&
      (musicVolume !== volumeChangedValue || noContentVolume !== noContentvolumeChangedValue),
    [volumeLoading, musicVolume, volumeChangedValue, noContentVolume, noContentvolumeChangedValue]
  );

  async function onApplyVolumeChanges() {
    setVolumeLoading(true);
    sequence.music.volume = volumeChangedValue;
    sequence.music.noContentVolume = noContentvolumeChangedValue;
    sequence.music.overrideVolume = true;
    signalPlaybackShouldPause();
    await sequenceSave(sequence);
    setVolumeLoading(false);
  }

  async function onNoContentVolumeChange(value: number) {
    value /= 100;
    setNoContentVolumeChangedValue(value);
    trackEvent('volumeChange', { type: 'nonSpeaker', value: value });
  }

  async function onVolumeChange(value: number) {
    value /= 100
    setVolumeChangedValue(value);
    trackEvent('volumeChange', { type: 'whenSpeaker', value: value });
  }

  const revertSlidersToDefault = () => {
    setVolumeChangedValue(musicVolume);
    setNoContentVolumeChangedValue(noContentVolume);
  };

  async function revertVolumeToDefault() {
    if (
      !sequence.music.overrideVolume &&
      (musicVolume !== volumeChangedValue || noContentVolume !== noContentvolumeChangedValue)
    ) {
      revertSlidersToDefault();
    }

    setVolumeLoading(true);
    sequence.music.overrideVolume = false;
    signalPlaybackShouldPause();
    await sequenceSave(sequence);
    trackEvent('volumeChange', { type: 'revert' });
    setVolumeLoading(false);
  }

  return (
    <>
      <MusicVolumeDiv>
        <MusicWrapper>
          <Title>Music while talking</Title>
        <SliderWrapper>
            <SliderWithInput
              onValueChange={onVolumeChange}
              value={Math.round(volumeChangedValue * 100)}
              min={0}
              max={100}
              step={1}
              disabled={disabled}
            />
          </SliderWrapper>
        </MusicWrapper>

        <MusicWrapper>
          <Title>Music while no talking</Title>
          <SliderWrapper>
            <SliderWithInput
              onValueChange={onNoContentVolumeChange}
              value={Math.round(noContentvolumeChangedValue * 100)}
              min={0}
              max={100}
              step={1}
              disabled={disabled}
            />
          </SliderWrapper>
        </MusicWrapper>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="reset-volume-tooltip" className="tooltip-peech-light">
              Reset to default
            </Tooltip>
          }
        >
          <RevertVolumeButton disabled={disabled || volumeLoading || processingMusic} onClick={revertVolumeToDefault}>
            {revertVolumeIcon}
          </RevertVolumeButton>
        </OverlayTrigger>
        {(volumeLoading || processingMusic) && (
          <Fragment>
            <BluredPlug />
            <GeneratingMusicWrapper>
              <GeneratingMusicLoader>
                <Loader size="md" />
                <span>Generating music</span>
              </GeneratingMusicLoader>
            </GeneratingMusicWrapper>
          </Fragment>
        )}
      </MusicVolumeDiv>
      <ApplyButton
        onClick={onApplyVolumeChanges}
        size="lg"
        variant={ButtonVariant.Primary}
        disabled={!shouldEnableApplyButton}
      >
        Apply
      </ApplyButton>
    </>
  );
}
