import classNames from 'classnames';
import React from 'react';
import { ThreeDots } from 'react-loading-icons';
import '../../css/purchase.scss';
import '../../css/styles.scss';
import { CardOther, CardPro, CardTeams } from '../../static/svg/plan-icons';
import Shopper from '../client/shopper';
import Subscription from '../client/subscription';
import { AppContext } from '../lib/app-context';
import { CARD_TYPE } from '../pages/billing';
import { handleError } from '../state/error';
import { shopperState } from '../state/local';
import Button from '../ui-components/Button';
import { trackEvent } from '../utils/analityics.utils';
import SVG from './svg';






const PlanIcon = ({ cardPlanIcon }) => {
    if (cardPlanIcon === 'card-pro') return CardPro()
    if (cardPlanIcon === 'card-teams') return CardTeams()
    else return CardOther()

}
/**
 * 
 * @param {{
 *  title: string,
 *  shopper: Shopper,
 *  onSuccess: Function,
 * }} props
 * @returns 
 */
export default function PaymentForm({ selectedPlan, onSuccess, title, buttonText, shopper, onHide }) {
    const { user, plan, config } = React.useContext(AppContext);
    const [token, setToken] = React.useState();
    const [warnings, setWarnings] = React.useState({});
    const [processing, setProcessing] = React.useState(false);
    const [valid, setValid] = React.useState(false);
    const [loading, setLoading] = React.useState(true)
    const name = user.orgName || `${user.firstName} ${user.lastName}`;
    const values = React.useRef({ name })
    const [ccType, setCcType] = React.useState(null)
    const [validccn, setValidccn] = React.useState(false)
    const [validexp, setValidexp] = React.useState(false)
    const [validcvv, setValidcvv] = React.useState(false)

    /**
     * @type {Subscription[]}
     */
    const [subscription, setSubscription] = React.useState();
    const showCasedPlan = selectedPlan || plan

    function validateForm() {
        if (Object.keys(warnings).length) {
            console.error('Form warning', warnings);
            return setValid(false)
        }

        const { name, billingEmail } = values.current;
        if (!name) {
            console.error('Form name is missing');
            return setValid(false)
        }
        if (!billingEmail) {
            console.error('Form billing e-mail is missing');
            return setValid(false)
        }
        if (!validateEmail(billingEmail)) {
            console.error('Form invalid billing email');
            return setValid(false)
        }

        setValid(true)
    }

    function validateEmail(userBillingEmail) {
        var re = /\S+@\S+\.\S+/;
        return re.test(userBillingEmail);
    }

    function onValueChange(e) {
        const { name, value } = e.target;
        values.current[name] = value;
        validateForm();
    }

    function onSubscriptionPropertyChange(e) {
        const { name, value } = e.target;
        subscription[name] = value;
        onValueChange(e);
    }

    function onEmailChange(e) {
        if (!validateEmail(e.target.value)) {
            console.error('Form invalid email');
            return setValid(false)
        }
        onSubscriptionPropertyChange(e)
    }

    React.useEffect(validateForm, [warnings]);

    React.useEffect(() => {
        if (!token) {
            return;
        }

        // documentation: https://developers.bluesnap.com/v8976-Tools/docs/hosted-payment-fields#section-implementing-hosted-payment-fields-in-your-checkout-form
        var bsObj = {
            token,
            onFieldEventHandler: {
                setupComplete: function () {
                    if (subscription?.billingEmail) {
                        values.current.billingEmail = subscription?.billingEmail
                    }
                    setLoading(false)
                },
                // /*OPTIONAL*/ threeDsChallengeExecuted: function () {
                //     console.warn("threeDsChallengeExecuted");
                // },
                // tagId returns: "ccn", "cvv", "exp"
                onFocus: function (tagId) { }, // Handle focus
                onBlur: function (tagId) { }, // Handle blur
                onError: function (tagId, errorCode, errorDescription) {
                    switch (parseInt(errorCode)) {
                        case 10:
                            return setWarnings(w => ({ ...w, [tagId]: 'Invalid value' }));

                        case 22013:
                            return setWarnings(w => ({ ...w, ccn: 'Card type is not supported' }));

                        case 14040:
                        case 14041:
                        case 14042:
                        case 400:
                            return handleError({
                                title: 'Payment details',
                                message: 'Session expired, please reload the page'
                            });

                        default:
                            return handleError({
                                title: 'Payment details',
                                message: 'Server error, please try again later'
                            });
                    }

                }, // Handle a change in validation
                /*errorCode returns:
                        "10" --> invalidCcNumber, invalidExpDate, invalidCvv Dependent on the tagId;
                        "22013" --> "CC type is not supported by the merchant"; 
                        "14040" --> " Token is expired";
                        "14041" --> " Could not find token";
                        "14042" --> " Token is not associated with a payment method, please verify your client integration or contact BlueSnap support";
                        "400" --> "Session expired please refresh page to continue";
                        "403", "404", "500" --> "Internal server error please try again later"; 
                    */

                /* errorDescription is optional. Returns BlueSnap's standard error description */

                onType: function (tagId, cardType, cardData) {
                    if (CARD_TYPE[cardType]) {
                        return setCcType(cardType)
                    }

                    setCcType(null)
                    /* cardType will give card type, and only applies to ccn: AMEX, VISA, MASTERCARD, AMEX, DISCOVER, DINERS, JCB */
                    /* cardData is an optional parameter which will provide ccType, last4Digits, issuingCountry, isRegulatedCard, cardSubType, binCategory and ccBin details (only applies to ccn) in a JsonObject */
                },
                onEnter: function (tagId) {
                },
                onValid: function (tagId) {
                    switch (tagId) {
                        case "ccn":
                            setValidccn(true)
                            break;
                        case "exp":
                            setValidexp(true)
                            break;
                        case "cvv":
                            setValidcvv(true)
                            break;

                    }
                    setWarnings(({ [tagId]: _, ...rest }) => ({ ...rest }));
                },
            },

            // styling docs: https://developers.bluesnap.com/v8976-Tools/docs/hosted-payment-fields#section-styling-the-hosted-payment-fields
            style: {
                ":focus": {
                    //style for all input elements on focus event
                    "border-color": "#999"
                },
                "::placeholder": {
                    "color": "#999"
                },
                input: {
                    //style for all input elements
                    color: "black",
                    "bottom": "0",
                    "border-width": "0.5px",
                    "border-color": "#999999",
                    "border-style": "solid",
                    "border-radius": "3px",
                    "height": "28px",
                    "width": "calc(100% - 1px) !important",
                    "font-family": '"Poppins", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";',
                    "font-size": "14px",
                    "padding": "0 10px",
                    "font-weight": "300",



                },
                ".invalid": {
                    //style for all input elements when invalid
                    "border-color": "#FF3D3D",

                }
            },
            ccnPlaceHolder: "Card Number", //for example
            // cvvPlaceHolder: "123", //for example
            expPlaceHolder: "Exp. (MM/YY)",
        };

        //Run the following command after Document Object Model (DOM) is fully loaded
        window.bluesnap.hostedPaymentFieldsCreate(bsObj);
    }, [token]);

    function onPurchase() {
        setProcessing(true);



        window.bluesnap.hostedPaymentFieldsSubmitData(async callback => {
            if (callback.cardData) {
                shopper.ccType = callback.cardData.ccType
                shopper.exp = callback.cardData.exp
                shopper.lastDigits = callback.cardData.last4Digits
                shopper.fraudSessionId = callback.transactionFraudInfo.fraudSessionId
                shopper.name = values.current.name
                shopper.zip = values.current.zip
                shopper.token = token;
                try {
                    let shopperSaved = await shopper.save();
                    shopperState.set(shopperSaved);
                }
                catch (err) {
                    handleError({
                        title: 'Purchase',
                        message: err.message,
                        responseError: err
                    });

                    trackEvent('err-shopper-create', { message: err.message, });
                    return setProcessing(false)
                }

                trackEvent('shopper-create', { shopperSid: shopper.sid, });
                onSuccess && onSuccess();
            } else {
                if (callback.error && callback.error.length) {
                    handleError({
                        title: 'Credit Card Error',
                        message: callback.error.map(err => `${err.tagId}: ${err.errorDescription}`).join(', '),
                    })
                }
            }
        });
    }
    const ccImage = require(`../../static/images/creditcards/${CARD_TYPE[ccType || 'AMEX']}.png`)
    const billingPage = location.pathname.replaceAll('/', '') === 'billing'

    if (!subscription) {
        return null;
    }



    return <div className="purchase" data-form-load={!loading}>
        <div className="panel panel-primary">
            <div className="panel-heading">
                <h2 style={{ margin: "0" }}>{title}</h2>
                <div className='close-wrapper'>
                    {!billingPage && <a onClick={onHide}><SVG name="close" className="close" /></a>}
                </div>
            </div>
            {loading && <div id="blue-snap-loading" className="panel-loading">
                <span>Connecting to payment service...</span>
                <div className="loading-indicator-wrap">
                    <ThreeDots className="loading-indicator" speed={.75}></ThreeDots>
                </div>
            </div>}
            <form style={{ opacity: loading ? 0 : 1 }} className="panel-body" id="checkout-form" action="#">
                <div className='flex-wrap' style={{ display: 'flex' }}>
                    <div style={{ marginTop: '2px', marginLeft: "  30px" }}>
                        <div className="panel-item">
                            <label htmlFor="cardholder-name">Cardholder Name <span className=" required-field">*</span></label>
                            <input type="text" required className={`form-control ${warnings.name && "error"}`} id="cardholder-name" name="name" onChange={onValueChange} defaultValue={name} placeholder="Cardholder Name" />
                        </div>
                        {/* <!--Hosted Payment Field for CC number--> */}
                        <div className="panel-item">
                            {/* <img src={ccType ? ccImage.default : "https://files.readme.io/d1a25b4-generic-card.png"} height="20px" /> */}
                            <label htmlFor="card-number">Card Number <span className=" required-field">*</span></label>
                            <div className={`form-control ${warnings.ccn && "error"}`} name="card" id="card-number" data-bluesnap="ccn">
                            </div>
                        </div>
                        <div className="panel-item" style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex" }} >
                                <div className='date-wrapper'>
                                    <label htmlFor="exp-date" >Exp. (MM/YY) <span className="required-field">*</span></label>
                                </div>
                                <div className='cvv-wrappr' style={{ margin: "auto" }}>
                                    <label htmlFor="cvv">CVC/CVV <span className=" required-field">*</span></label>
                                </div>

                            </div>

                            <div className="wrapper" style={{ display: "flex", flexDirection: "row" }}>
                                {/* <!-- Hosted Payment Field for CC EXP --> */}
                                <div className={`form-control ${warnings.exp && "error"}`} name="exp" id="exp-date" data-bluesnap="exp"></div>
                                {/* <!-- Hosted Payment Field for CC CVV --> */}
                                <div className={`form-control ${warnings.cvv && "error"}`} name="cvv" id="cvv" data-bluesnap="cvv" style={{ marginLeft: "5%" }}></div>
                            </div>
                        </div>
                        <div className="panel-item">
                            <label htmlFor="billing-name">Billing Company Name</label>
                            <input type="text" required className={`form-control ${warnings.name && "error"}`} id="billing-name" onChange={onSubscriptionPropertyChange} defaultValue={subscription?.billingName} placeholder="Billing Company Name " />
                        </div>
                        <div className="panel-item">
                            <label htmlFor="billing-address">Billing Address</label>
                            <input type="text" className="form-control" id="billing-address" name="billingAddress" onChange={onSubscriptionPropertyChange} defaultValue={subscription?.billingAddress} placeholder="Billing Address" />
                        </div>
                        <div className="panel-item">
                            <label htmlFor="card-zipcode">Postal Code</label>
                            <input type="number" className="form-control" id="card-zipcode" name="zip" onChange={onValueChange} pattern="[0-9]" placeholder='Postal Code' />
                        </div>
                        <div className="panel-item">
                            <label htmlFor="billing-email">Billing E-Mail <span className=" required-field">*</span></label>
                            <input type="text" className="form-control" id="billing-email" name="billingEmail" onChange={onEmailChange} defaultValue={subscription?.billingEmail} placeholder="Billing E-Mail" />
                        </div>
                        <div className="panel-item">
                            <label htmlFor="tax-id">Tax ID</label>
                            <input type="text" className="form-control" id="tax-id" name="taxId" onChange={onSubscriptionPropertyChange} defaultValue={subscription?.taxId} placeholder="Tax ID " />
                        </div>
                    </div>
                    {/* <div className="panel-actions">


                        <div className='my-plan-card' >
                            
                            {showCasedPlan?.stockFootage && <div className='Shutterstock-wrapper'>
                                <div className='Shutterstock-logo'><ShutterstockLogo /></div>
                            </div>}
                            <div className='plan-icon' style={{ marginBottom: "unset" }}>
                                <PlanIcon cardPlanIcon={showCasedPlan?.icon} />
                            </div>
                            <h3 className='plan-type'>{showCasedPlan?.name}</h3>
                            <span className='border' />
                            <div className='plan-price'>

                                <h2 className='price'>{`$${showCasedPlan?.overrideRecurringPrice || showCasedPlan?.recurringPrice}`} <span className='per-subscription'>per month</span></h2>
                            </div>

                            <ul className='package-list'>
                                <li className='package-item'>
                                    <SVG name='mark-v' className='item-icon' width="12" viewBox="0 0 12 9" />
                                    <div>
                                        <span className='item-bold'>{showCasedPlan?.maxUsers} </span>
                                        Users
                                    </div>
                                </li>
                                <li className='package-item'>
                                    <SVG name='mark-v' className='item-icon' width="12" viewBox="0 0 12 9" />
                                    <div>
                                        <span className='item-bold'>1080p </span>
                                        Quality
                                    </div>
                                </li>
                                <li className='package-item'>
                                    <SVG name='mark-v' className='item-icon' width="12" viewBox="0 0 12 9" />
                                    <div>
                                        <span className='item-bold'>{showCasedPlan?.maxVideos} </span>
                                        Monthly videos
                                    </div>
                                </li>
                                <li className='package-item'>
                                    <SVG name='mark-v' className='item-icon' width="12" viewBox="0 0 12 9" />
                                    <div>
                                        Email support
                                    </div>
                                </li >
                                <li className='package-item'>
                                    <SVG name='mark-v' className='item-icon' width="12" viewBox="0 0 12 9" />
                                    <div>
                                        Subtitles
                                    </div>
                                </li>
                            </ul >
                        </div >
                    </div> */}
                </div>
                <div className='button-wrapper' >
                    {processing ? <div className="spinner"></div> : <Button className={classNames({ 'modal-button': buttonText != "Save" })} variant="primary" id="submit-purchase" disabled={!valid || !validccn || !validcvv || !validexp} onClick={onPurchase}>{buttonText}</Button>}
                </div>

            </form>
        </div>
    </div>
}
