import { entity } from 'simpler-state';
import * as Lottie from '../../../data-layer/shared/lottie';
import Sequence, { Chapter, Scene } from '../client/sequence';
import { ChapterOrder } from '../client/sequence/chapter';
import { resetMainPlayerStyle } from '../components/main-player';
import { trackEvent } from '../utils/analityics.utils';
import { loadCuePoints } from './cuePoints';
import { handleError } from './error';
import { reloadPlayer } from './playback';

export const DOWNLOAD_STATUS = {
	NOT_STARTED: 1,
	GENERATE: 2,
	READY: 3,
}

class ScenesState {
	constructor(scenes = {}) {
		this.set(scenes);
		this.cacheVersion = 0;
	}

	set(scenes) {
		this.scenes = scenes;
		return this._clearCache();
	}

	reset() {
		this.scenes = {};
		return this._clearCache();
	}

	_clearCache() {
		this._cache = {}
		this.cacheVersion++;
		return this;
	}

	get array() {
		return Object.values(this.scenes).filter(scene => scene.status !== Scene.STATUS.DELETED).sort((a, b) => a.index - b.index)
	}

	get totalArray() {
		return Object.values(this.scenes).sort((a, b) => a.index - b.index)
	}

	get durableArray() {
		return this.array.filter(c => c.index !== null && c.duration !== null)
	}

	get trimmableArray() {
		return this.array.filter(c => c.index !== null && c.keyFramed !== null)
	}

	get formatedScenes() {
		return this.array.filter(scene => scene.resized)
	}

	get formatedChapters() {
		return this.array.filter(scene => scene.resized)
	}

	get previewReadyChapters() {
		return this.array.filter(scene => scene.resized && scene.transcripted)
	}

	source(chapter) {
		return this.totalArray.find(scene => scene.sid === chapter.linkSid) || chapter
	}

	get playable() {
		return this.array
			.filter(scene => scene.status === Scene.STATUS.READY)
	}

	get notDeleted() {
		return this.array
	}

	get notDeletedChapters() {
		return this.array
	}

	get contentChapters() {
		return this.array.filter(scene => !scene.cuePointType && !scene.disabled)
	}

	get notReady() {
		return this.array.filter(scene => scene.status !== Scene.STATUS.READY)
	}

	get notReadyChapters() {
		return this.array.filter(scene => scene.status !== Scene.STATUS.READY)
	}

	get chapters() {
		return this.array
	}

	get readyChapters() {
		return this.chapters.filter(scene => scene.status === Chapter.STATUS.READY)
	}

	/**
	 * @returns {Chapter[]}
	 */
	get animated() {
		return this.array.filter(scene => scene.cuePointType)
	}

	get intro() {
		return this.array.find(scene => scene.type === 'intro')
	}

	get outro() {
		return this.array.find(scene => scene.type === 'outro')
	}

	get filter() {
		if (!this._filter) {
			this._filter = new Proxy(this, {
				get(target, name) {
					if (!target._cache[name]) {
						target._cache[name] = target[name]
					}
					return target._cache[name]
				}
			})
		}
		return this._filter
	}

	get map() {
		if (!this._map) {
			this._map = new Proxy(this, {
				get(target, name) {
					if (name === 'array') {
						return Object.values(target.scenes).filter(scene => scene.status !== Scene.STATUS.DELETED);
					}
					if (name === 'totalArray') {
						return Object.values(target.scenes)
					}
					return target.scenes[name];
				},
				set(target, name, value) {
					target.scenes[name] = value;
					target._clearCache();
					return true;
				},
				deleteProperty(target, name) {
					delete target.scenes[name]
					target._clearCache();
					return true;
				}
			})
		}
		return this._map
	}
}

export async function getSequence(sequenceSid, cacheVersion) {
	trackEvent('project-open');

	/**
	 * @type {Sequence}
	 */
	const sequence = await Sequence.get(sequenceSid)
	if (sequence) {
		reloadPlayer(sequence.version)
		sequenceState.set(sequence)
		const scenesObj = setScenes(sequence, true)
		const animations = animationAssets.get()
		await loadCuePoints(sequence, scenesObj, animations, cacheVersion);
	}
}

const scenesContainer = new ScenesState()
const downloadPendingDefaultState = { MODE: DOWNLOAD_STATUS.NOT_STARTED }

export const scenesState = entity(scenesContainer)
export const sequenceState = entity(new Sequence())
export const downloadNotificationStatus = entity(Sequence.DOWNLOAD_NOTIFICATION_TYPE.DISABLED)
export const downloadStatus = entity(downloadPendingDefaultState)
export const downloadPendingURL = entity(null);
export const musicBackgroundInProcess = entity(false);

const sequenceLiveStateDefault = { watchers: [] }
export const sequenceLiveState = entity(sequenceLiveStateDefault)

export const resetDownloadPendingState = () => {
	downloadStatus.set(downloadPendingDefaultState);
}

/**
 * @param {Sequence} sequence
 */
export const sequenceSave = async sequence => {
	console.log('Saving sequence', sequence)
	try {
		await sequence.save()
	}
	catch (err) {
		console.error(err)
		handleError({
			title: 'Project Error',
			message: err.message,
			responseError: err
		});
		return sequence
	}

	sequenceState.set(sequence)
	setScenes(sequence)
	return sequence
}

/**
 * @type {{
 * 	text: Lottie.Animation,
 * 	speaker: Lottie.Animation,
 *  slide: Lottie.Animation,
 * }}
 */
const defaultAnimation = {
	text: null,
	speaker: null,
};

export const resetSequenceLiveState = () => {
	scenesState.set(scenesContainer.reset())

	sequenceState.set(new Sequence())
	sequenceLiveState.set(sequenceLiveStateDefault)
	resetMainPlayerStyle()
}


/**
 * @param {String} watcher //userSid
 */
export const addWatcher = watcher => {
	sequenceLiveState.set(state => {
		if (state.watchers.includes(watcher)) {
			return state
		}
		const watchers = [...state.watchers, watcher]
		state.watchers = watchers
		return state
	})
}

/**
 * @param {String} watcher //userSid
 */
export const removeWatcher = watcher => {
	sequenceLiveState.set(state => {
		const watchers = state.watchers.filter(w => w !== watcher)
		state.watchers = watchers
		return state
	})
}

export const animationAssets = entity(defaultAnimation)

export async function deactivateScene(scene, sequence) {
	if (!scene || !scene.sid) {
		return
	}

	scene.disabled = true
	await sequence.updateChapter(scene.sid, scene);
	setScene(scene, true)
}

export async function activateScene(scene, sequence) {
	if (!scene || !scene.sid) {
		return
	}

	scene.disabled = false
	await sequence.updateChapter(scene.sid, scene);
	setScene(scene, true)
}

export function setScenes(sequence, shouldSetTemp, shouldSetSequence) {
	shouldSetSequence && sequenceState.set(sequence)
	sequence?.scenes?.forEach(scene => scenesContainer.map[scene.sid] = scene);
	scenesContainer._clearCache()
	shouldSetTemp && setTempScenes(sequence)
	return scenesContainer.map;
}

export function setScene(scene, shouldSetTemp) {
	scenesContainer.map[scene.sid] = scene
	scenesState.set(scenesContainer)

	shouldSetTemp && setTempScene(scene)
}

export function removeScene(sid, shouldRemoveTemp, permDelete) {
	if (scenesContainer.map[sid]) {
		scenesContainer.map[sid].status = Scene.STATUS.DELETED
		if (permDelete) {
			delete scenesContainer.map[sid]
		}
	}
	scenesContainer._clearCache()
	scenesState.set(scenesContainer)
	shouldRemoveTemp && removeTempScene(sid)
}

export const animatedScenes = (scenes) => Object.values(scenes).filter(scene => scene instanceof AnimatedScene)


// Temp scenes state

const tempScenesContainer = new ScenesState()

export const tempScenesState = entity(tempScenesContainer)

export const resetTempScenes = () => tempScenesState.set(scenesContainer => scenesContainer.reset())

export const clearTempCache = () => tempScenesState.set(scenesContainer => scenesContainer._clearCache())

export function setTempScenes(sequence) {
	resetTempScenes()
	sequence?.scenes?.forEach(scene => setTempScene(scene));
	clearTempCache()
	return tempScenesContainer.map;
}

export function setTempScene(scene) {
	if (scene.status === Chapter.STATUS.DELETED || scene.cuePointType || scene.disabled) {
		return
	}

	const properties = ['sid', 'linkSid', 'title', 'clipFrom']
	const chapter = (tempScenesContainer.map[scene.cid] || tempScenesContainer.map[scene.sid] || new ChapterOrder())
	const modifiedKeys = chapter?.changedProperties || []

	console.log('modifiedKeys',
		modifiedKeys,
		Object.keys(scene.values).filter(key => !modifiedKeys.includes(key) && properties.includes(key)),
		Object.keys(scene.values))

	chapter.set({ name: scene.name })
	if (chapter.sid) {
		properties.pop()
	}
	properties.filter(key => chapter[key] !== scene.values[key])
		.forEach(key => chapter[key] = scene.values[key])


	const shouldUpdateClipFrom = (chapter.clipFrom && scene.clipFrom) || !scene.sid

	chapter.sourceDuration = scene.srcDuration || scene.sourceDuration
	chapter.linkSid = scene.linkSid
	scene.transcripted && !scene.linkSid && (chapter.transcripted = true)
	scene.resized && !scene.linkSid && (chapter.resized = true)
	scene.keyFramed && !scene.linkSid && (chapter.keyFramed = true)
	!chapter.index && (chapter.index = scene.index)
	shouldUpdateClipFrom && (chapter.clipFrom = scene.clipFrom)
	if (Math.abs(chapter.duration - scene.duration) < 0.2 || !chapter.duration) {
		chapter.duration = scene.duration
	}
	chapter.sid = scene.sid
	chapter.cid = scene.cid
	scene.sid && scene.cid && delete tempScenesContainer.map[scene.cid]

	tempScenesContainer.map[scene.sid || scene.cid] = chapter
}

export function removeTempScene(sid) {
	console.log('temp removeTempScene', sid)
	if (tempScenesContainer.map[sid]) {
		Object.keys(tempScenesContainer.scenes).map(chapterSid => {
			if (tempScenesContainer.map[chapterSid].index > tempScenesContainer.map[sid].index) {
				tempScenesContainer.map[chapterSid].index -= 1
			}
		})
		delete tempScenesContainer.map[sid]
	}
	tempScenesContainer._clearCache()
}

export function setTempSceneFormated(sid) {
	if (tempScenesContainer.map[sid]) {
		tempScenesContainer.map[sid].resized = true
	}
	tempScenesContainer._clearCache()
}

export function setTempSceneTranscripted(sid) {
	if (tempScenesContainer.map[sid]) {
		tempScenesContainer.map[sid].transcripted = true
	}
	tempScenesContainer._clearCache()
}

export function setTempSceneKeyFramed(sid) {
	if (tempScenesContainer.map[sid]) {
		tempScenesContainer.map[sid].keyFramed = true
	}
	tempScenesContainer._clearCache()
}

// @TODO prevTempChapter
export const getPrevTempChapter = (chapter) => chapter && tempScenesContainer.durableArray.filter(c =>
	(c.linkSid || c.sid) === (chapter.linkSid || chapter.sid) &&
	(c.sid || c.cid) !== (chapter.sid || chapter.cid) &&
	((c.clipFrom || 0) + c.duration) <= (chapter.clipFrom || 0))
	.sort((a, b) => (a.clipFrom || 0) - (b.clipFrom || 0))[0]

// @TODO nextTempChapter
export const getNextTempChapter = (chapter) => chapter && tempScenesContainer.durableArray.filter(c =>
	(c.linkSid || c.sid) === (chapter.linkSid || chapter.sid) &&
	(c.sid || c.cid) !== (chapter.sid || chapter.cid) &&
	c.clipFrom >= (chapter.clipTo || (chapter.clipFrom || 0) + chapter.duration))
	.sort((a, b) => a.clipFrom - b.clipFrom)[0]
