
import * as Base from './base/titleCuePoint';
import { TitleCuePointStatus, TitleCuePointTitleType } from './base/titleCuePoint';
import lottie, { AnimationItem } from 'lottie-web';
import { CSSProperties } from 'react';


export { TitleCuePointStatus, TitleCuePointTitleType } from './base/titleCuePoint';

export interface ITitleCuePoint extends Base.ITitleCuePoint {

}

export interface ITitleCuePointProperties extends Base.ITitleCuePointProperties {

}

export interface ITitleCuePointFilter extends Base.ITitleCuePointFilter {

}

interface IFitSize {
	width: number;
	height?: number;
	fontSize: number;
}

/**
 * @param {Lottie.Animation} textAnimation
 */
function fitSizeToLottieText(height: number, style: CSSProperties, textAnimation: any, text: string, layerClass: string): Promise<IFitSize> {
	const { width: animationWidth, height: animationHeight, textLayers } = textAnimation;
	const sizeRatio = height / animationHeight;
	const textLayer = textLayers.find((l: any) => l.hasClass(layerClass)) || textLayers[0]
	const textFrame = textLayer.textFrames[0]
	const layerFontSize = textFrame.fontSize;

	textLayer.text = text + 'W'.repeat(text.length / 5);
	// textLayer.text = text;
	const container = document.createElement('div');
	container.id = "ranrrrr";
	// container.style.height = `${height * 10}px`
	// container.style.width = `${animationWidth * sizeRatio * 10}px`
	container.style.visibility = 'hidden';
	container.style.position = 'absolute';
	container.style.top = '0px';
	container.style.left = '0px';
	container.style.zIndex = '800000';
	for (let key in style) {
		(container.style as any)[key] = (style as any)[key]
	}
	document.querySelector('body')?.appendChild(container)

	let pauseTime = 0;
	if (textAnimation.animationTimes && textAnimation.animationTimes.length) {
		pauseTime = textAnimation.animationTimes[0].end;
	}

	const animation = lottie.loadAnimation({
		loop: false,
		autoplay: false,
		animationData: textAnimation.data,
		container,
		renderer: 'svg',
		rendererSettings: {
			preserveAspectRatio: 'xMaxYMax'
		}
	});

	return new Promise(resolve => {
		(animation as any).addEventListener('DOMLoaded', () => {
			animation.goToAndStop(pauseTime);
			
			const textElement = container.querySelector(`.${layerClass}`) as HTMLElement
			for (let key in style) {
				(textElement.style as any)[key] = (style as any)[key]
			}
			let fontSize = layerFontSize;
			const width = textElement.scrollWidth / 10;
			while (textElement.scrollWidth > container.scrollWidth) {
				(textElement.style as any).fontSize = --fontSize;
			}
			resolve({ fontSize, width })
			animation.destroy();
			container.remove()
		})
	})
}

export default class TitleCuePoint<IType extends ITitleCuePoint = ITitleCuePoint, ITypeProperties extends ITitleCuePointProperties = ITitleCuePointProperties> extends Base.TitleCuePoint<IType, ITypeProperties> implements ITitleCuePoint {
	async calcSizeByLottie(textAnimation: any) {
		// return this.calcSize();
		if (this.slide) {
			return;
		}
		const fontWeight = this.fontWeight === 'Bold' ? 'bold' : 'normal'
		const { width, fontSize } = await fitSizeToLottieText(this.height as number, { fontWeight: fontWeight }, textAnimation, `${this.text}`, 'text-text')
		this.fontSize = fontSize
		this.width = width
	}

	calcSize() {
		const container = document.createElement('div');
		container.style.visibility = 'hidden';
		container.style.width = 'fit-content';
		// container.style.blockSize = 'fit-content';
		container.style.paddingLeft = '5%';
		container.style.paddingRight = '5%';
		container.style.fontSize = `${this.fontSize}`;
		container.style.fontWeight = this.fontWeight === 'Bold' ? 'bold' : 'normal';
		// container.innerHTML = this.text.replace('\n', '<br/>')
		container.textContent = `${this.text}`
		document.documentElement.appendChild(container)

		this.width = container.offsetWidth
		// this.height = container.offsetHeight
	}
}