import styled from "@emotion/styled/macro"
import React from 'react'
import { ReactNode } from "react"
import { COLORS } from "../../constants/colors";
import SVG from "../components/svg";


const NumberedRemoveButtonWrapper = styled.div`
  height: 100%;
  width: 100%;

  div {
    padding: 0 1px 0 0;
    width: 20px;
    height: 20px;
    margin-top: 6px;
    margin-left: 6px;
    border-radius: 4px;
    color: ${COLORS.peech_neutral_white};
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    position: relative;
    cursor: pointer;
}

  .remove {
    display: none;
    background-color: ${COLORS.peech_neutral_white};
    svg {
        position: absolute;
        top: 5px;
        left: 4px;
        fill: ${COLORS.peech_gray_500};
        stroke: ${COLORS.peech_gray_500};
        stroke-width: 0;
        width: 13px;
    }
  }
  .number {
    background-color: ${COLORS.peech_primary_1_500};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
   
  }
  &:hover {
    .number {
      display: none;

    }
    .remove {
        display: flex;
        justify-content: center;
        align-items: center;
    }
  }
`;

interface NumberedRemoveButtonProps {
    number: Number,
    onClick: Function
}

export default function NumberedRemoveButton({ number, onClick }: NumberedRemoveButtonProps) {

    return (
        <NumberedRemoveButtonWrapper>
            <div className="number">
                {number}
            </div>
            <div className="remove" onClick={onClick}>
                <SVG name="close" />
            </div>
        </NumberedRemoveButtonWrapper>
    )


} 