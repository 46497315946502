
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export interface IDetection extends IBase {
	start?: number | null;
	end?: number | null;
	xCenterPercentage?: number | null;
	yCenterPercentage?: number | null;
}

export interface IDetectionProperties extends IBaseProperties {
	start?: boolean;
	end?: boolean;
	xCenterPercentage?: boolean;
	yCenterPercentage?: boolean;
}

export interface IDetectionFilter extends IBaseFilter {
}

export class Detection<IType extends IDetection = IDetection, ITypeProperties extends IDetectionProperties = IDetectionProperties> extends Base<IType, ITypeProperties> implements IDetection {
	
	static className = 'Detection';
	static NAME = 'detection';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		start: true,
		end: true,
		xCenterPercentage: true,
		yCenterPercentage: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get start(): number | null | undefined {
		return this.values.start
	}
			
	set start(value: number | null | undefined) {
		if(this.values.start !== value) {
			this.propertyChanged('start', value);
			this.values.start = value;
		}
	}
			
	get end(): number | null | undefined {
		return this.values.end
	}
			
	set end(value: number | null | undefined) {
		if(this.values.end !== value) {
			this.propertyChanged('end', value);
			this.values.end = value;
		}
	}
			
	get xCenterPercentage(): number | null | undefined {
		return this.values.xCenterPercentage
	}
			
	set xCenterPercentage(value: number | null | undefined) {
		if(this.values.xCenterPercentage !== value) {
			this.propertyChanged('xCenterPercentage', value);
			this.values.xCenterPercentage = value;
		}
	}
			
	get yCenterPercentage(): number | null | undefined {
		return this.values.yCenterPercentage
	}
			
	set yCenterPercentage(value: number | null | undefined) {
		if(this.values.yCenterPercentage !== value) {
			this.propertyChanged('yCenterPercentage', value);
			this.values.yCenterPercentage = value;
		}
	}
			
	
	static get<IType extends IDetection = IDetection>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IDetectionFilter = IDetectionFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IDetectionFilter = IDetectionFilter, IType extends IDetection = IDetection>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
