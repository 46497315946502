
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export interface IGraphicAnimation extends IBase {
	type?: string | null;
	kind?: string | null;
	start?: number | null;
	end?: number | null;
	startValue?: number | null;
	endValue?: number | null;
	startX?: number | null;
	startY?: number | null;
	endX?: number | null;
	endY?: number | null;
	duration?: number | null;
	easing?: string | null;
	offset?: number | null;
}

export interface IGraphicAnimationProperties extends IBaseProperties {
	type?: boolean;
	kind?: boolean;
	start?: boolean;
	end?: boolean;
	startValue?: boolean;
	endValue?: boolean;
	startX?: boolean;
	startY?: boolean;
	endX?: boolean;
	endY?: boolean;
	duration?: boolean;
	easing?: boolean;
	offset?: boolean;
}

export interface IGraphicAnimationFilter extends IBaseFilter {
}

export class GraphicAnimation<IType extends IGraphicAnimation = IGraphicAnimation, ITypeProperties extends IGraphicAnimationProperties = IGraphicAnimationProperties> extends Base<IType, ITypeProperties> implements IGraphicAnimation {
	
	static className = 'GraphicAnimation';
	static NAME = 'graphicAnimation';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		type: true,
		kind: true,
		start: true,
		end: true,
		startValue: true,
		endValue: true,
		startX: true,
		startY: true,
		endX: true,
		endY: true,
		duration: true,
		easing: true,
		offset: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get type(): string | null | undefined {
		return this.values.type
	}
			
	set type(value: string | null | undefined) {
		if(this.values.type !== value) {
			this.propertyChanged('type', value);
			this.values.type = value;
		}
	}
			
	get kind(): string | null | undefined {
		return this.values.kind
	}
			
	set kind(value: string | null | undefined) {
		if(this.values.kind !== value) {
			this.propertyChanged('kind', value);
			this.values.kind = value;
		}
	}
			
	get start(): number | null | undefined {
		return this.values.start
	}
			
	set start(value: number | null | undefined) {
		if(this.values.start !== value) {
			this.propertyChanged('start', value);
			this.values.start = value;
		}
	}
			
	get end(): number | null | undefined {
		return this.values.end
	}
			
	set end(value: number | null | undefined) {
		if(this.values.end !== value) {
			this.propertyChanged('end', value);
			this.values.end = value;
		}
	}
			
	get startValue(): number | null | undefined {
		return this.values.startValue
	}
			
	set startValue(value: number | null | undefined) {
		if(this.values.startValue !== value) {
			this.propertyChanged('startValue', value);
			this.values.startValue = value;
		}
	}
			
	get endValue(): number | null | undefined {
		return this.values.endValue
	}
			
	set endValue(value: number | null | undefined) {
		if(this.values.endValue !== value) {
			this.propertyChanged('endValue', value);
			this.values.endValue = value;
		}
	}
			
	get startX(): number | null | undefined {
		return this.values.startX
	}
			
	set startX(value: number | null | undefined) {
		if(this.values.startX !== value) {
			this.propertyChanged('startX', value);
			this.values.startX = value;
		}
	}
			
	get startY(): number | null | undefined {
		return this.values.startY
	}
			
	set startY(value: number | null | undefined) {
		if(this.values.startY !== value) {
			this.propertyChanged('startY', value);
			this.values.startY = value;
		}
	}
			
	get endX(): number | null | undefined {
		return this.values.endX
	}
			
	set endX(value: number | null | undefined) {
		if(this.values.endX !== value) {
			this.propertyChanged('endX', value);
			this.values.endX = value;
		}
	}
			
	get endY(): number | null | undefined {
		return this.values.endY
	}
			
	set endY(value: number | null | undefined) {
		if(this.values.endY !== value) {
			this.propertyChanged('endY', value);
			this.values.endY = value;
		}
	}
			
	get duration(): number | null | undefined {
		return this.values.duration
	}
			
	set duration(value: number | null | undefined) {
		if(this.values.duration !== value) {
			this.propertyChanged('duration', value);
			this.values.duration = value;
		}
	}
			
	get easing(): string | null | undefined {
		return this.values.easing
	}
			
	set easing(value: string | null | undefined) {
		if(this.values.easing !== value) {
			this.propertyChanged('easing', value);
			this.values.easing = value;
		}
	}
			
	get offset(): number | null | undefined {
		return this.values.offset
	}
			
	set offset(value: number | null | undefined) {
		if(this.values.offset !== value) {
			this.propertyChanged('offset', value);
			this.values.offset = value;
		}
	}
			
	
	static get<IType extends IGraphicAnimation = IGraphicAnimation>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IGraphicAnimationFilter = IGraphicAnimationFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IGraphicAnimationFilter = IGraphicAnimationFilter, IType extends IGraphicAnimation = IGraphicAnimation>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
