
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'

export interface IBackgroundJob extends IBase {
	startTime?: number | null;
	type?: string | null;
	subType?: string | null;
	audioSid?: string | null;
}

export interface IBackgroundJobProperties extends IBaseProperties {
	startTime?: boolean;
	type?: boolean;
	subType?: boolean;
	audioSid?: boolean;
}

export interface IBackgroundJobFilter extends IBaseFilter {
}

export class BackgroundJob<IType extends IBackgroundJob = IBackgroundJob, ITypeProperties extends IBackgroundJobProperties = IBackgroundJobProperties> extends Base<IType, ITypeProperties> implements IBackgroundJob {
	
	static className = 'BackgroundJob';
	static NAME = 'backgroundJob';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		startTime: true,
		type: true,
		subType: true,
		audioSid: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get startTime(): number | null | undefined {
		return this.values.startTime
	}
			
	get type(): string | null | undefined {
		return this.values.type
	}
			
	get subType(): string | null | undefined {
		return this.values.subType
	}
			
	get audioSid(): string | null | undefined {
		return this.values.audioSid
	}
			
	
	static get<IType extends IBackgroundJob = IBackgroundJob>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IBackgroundJobFilter = IBackgroundJobFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IBackgroundJobFilter = IBackgroundJobFilter, IType extends IBackgroundJob = IBackgroundJob>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
