import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Global } from '@emotion/react';
import styled from '@emotion/styled/macro';

import Sequence from '../../../client/sequence';
import { AppContext } from '../../../lib/app-context';

import { trackEvent } from '../../../utils/analityics.utils';

import { FeatureFeatureName } from '../../../client/base/feature';
import { StyleCaptionsCaseType, StyleStyleType, StyleVerticalAlign } from '../../../client/base/sequence/style';
import {
  cuePointsState,
  randomizeClosedCaptionPosition,
  resetCloseCaptionManualPosition,
} from '../../../state/cuePoints';
import { signalPlaybackShouldPause } from '../../../state/playback';
import { sequenceSave, sequenceState } from '../../../state/sequence';
import {
  OutlinedStack,
  Section,
  SectionTitle,
  Stack,
  disabledStyle,
} from '../../../ui-components/Menu/MenuComponents.styled';
import { AspectRatio } from '../../../ui-components/SequenceCloneOptions/useSequenceCloneOptions';
import {
  SubtitleFormatName,
  CaptionsFormatType,
  getCloseCaptionDefaultPosition,
  getSubtitleFormatPreset,
  saveClosedCaptionsChanges,
  getCloseCaptionVerticalAlignmentByPosition,
  DEFAULT_FONT_SIZE,
} from '../../../utils/closeCpation.utils';
import { openPeechProUpgradeModal } from '../../layout';
import SVG from '../../svg';
import AdvancedCaptionsFormatter from './AdvancedCaptionsFormatter';
import { CaptionsCasePicker } from './CaptionsCasePicker';
import CaptionsFormatter from './CaptionsFormatter';
import HighlightAllComponent from './HighlightAllComponent';
import { SequenceColorPicker, SequenceColorPickerValue } from './SequenceColorPicker';
import { STYLE_TYPES, StyleTypePicker } from './StyleTypePicker';
import './Subtitles.scss';
import Select, { SelectGlobalStyle } from './select.styled';
import { FontOption, useFontOptions } from './useFontOptions';
import { RandomSubtitlePositionButton } from './components/RandomSubtitlePositionButton';
import SliderWithInput from './SliderWithInput';
import VisualsMenuColorsComponent from '../VisualsMenuColorsComponent';
import { use } from 'chai';

const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 10px 14px 0 16px;
  /* border-bottom: 1px solid #e6e9ef; */
  ${(props) => props.disabled && disabledStyle}
  height: fit-content;
  max-height: 100%;
  overflow: auto;
`;

const ArrowDownIcon = styled(SVG)<{ rotate: boolean }>`
  fill: #999999;
  width: 20px;
  height: 20px;
  rotate: ${(props) => (props.rotate ? -180 : 0)}deg;
  transition: rotate 250ms ease-out;
`;

const Divider = styled.div<{ marginTop?: string; marginBottom?: string }>`
  margin: ${(props) => props.marginTop || '0px'} 0 ${(props) => props.marginBottom || '0px'} 0;
  border-bottom: 0.5px #cccccc solid;
`;

ArrowDownIcon.defaultProps = {
  // @ts-ignore
  name: 'arrow-down',
};

export type SubtitleColorsObjectType = {
  values: {
    name: string;
    color?: string | null | undefined;
    colorIndex?: string | number | undefined | null;
  };
  onChangeCallback: (value: SequenceColorPickerValue | null) => void;
  backgroundColor?: string | number | null;
  withStatic: boolean;
  withNoneColor: boolean;
  disabled?: boolean;
};

interface CaptionsStyleFormState {
  font: FontOption;
  fontSize: number;
  verticalAlignment: StyleVerticalAlign;
  captionsPosition: number;
  isCustomCaptionsPosition: boolean;
  fontColor: SequenceColorPickerValue;
  styleType: StyleStyleType;
  styleTypeColor: SequenceColorPickerValue;
  highlightColorIndex: SequenceColorPickerValue;
  captionsCaseType: StyleCaptionsCaseType;
  captionFormattingData: CaptionsFormatType | undefined;
}

interface Props {
  disabled?: boolean;
  featureNotAllowed?: boolean;
  isCustomPositionCloseCaption?: boolean;
}

const isSelectedFont = (font: FontOption, style: Sequence['style']) =>
  ((style?.captionsFontFamily === font.fontFamily || style?.captionsFontAssetSid === font.assetSid) &&
    style?.captionsRegularFileName === font.value) ||
  (style?.captionsFontFamily === font.value && font.isDefault);

const getSequenceColorPickerValue = (value?: string | number | null): SequenceColorPickerValue | undefined => {
  return typeof value === 'number'
    ? { kind: 'palette', value }
    : typeof value === 'string'
    ? { kind: 'static', value }
    : undefined;
};

const getHighlightColorPickerInitialValue = (sequence: Sequence, highlightColorIndex: number | null | undefined) => {
  if (highlightColorIndex) {
    return { kind: 'palette', value: highlightColorIndex };
  }
  if(sequence?.colors && sequence?.style) {
    const res = sequence.colors.map((x) => x.values.color).findIndex((x) => x !== '#FFFFFF' && x !== '#000000');
    if(res !== -1) {
      sequence.style.highlightColorIndex = res + 1;
      sequenceSave(sequence);
      return { kind: 'palette', value: res + 1 };
    }
  }
  return undefined;
};

const getCaptionsFormattingValue = (sequence: Sequence) =>
  ({
    formatName: sequence?.style?.captionFormatting ?? SubtitleFormatName.OneLine,
    formatPreset: getSubtitleFormatPreset(
      sequence?.aspectRatio as AspectRatio,
      (sequence?.style?.captionFormatting ?? SubtitleFormatName.OneLine) as SubtitleFormatName
    ),
  } as CaptionsFormatType);

const getInitialFormState = (sequence: Sequence, fontOptions: FontOption[]): Partial<CaptionsStyleFormState> => {
  const { style } = sequence;
  const captionsFontColorValue = style?.captionsFontColor || style?.captionsFontColorIndex;
  const captionsStyleColorValue = style?.captionsStyleColor || style?.captionsStyleColorIndex;
  const highlightColorIndex = style?.highlightColorIndex || 2; // set default highlight color to index 2 

  return {
    font: fontOptions.find((font) => isSelectedFont(font, sequence.style)),
    fontSize: style?.captionsFontSize ?? DEFAULT_FONT_SIZE * 10,
    verticalAlignment: style?.captionsVerticalAlign ?? undefined,
    captionsPosition: style?.captionsPosition!,
    fontColor: getSequenceColorPickerValue(captionsFontColorValue),
    styleType: style?.captionsStyleType ?? undefined,
    styleTypeColor: getSequenceColorPickerValue(captionsStyleColorValue),
    highlightColorIndex: getHighlightColorPickerInitialValue(sequence, highlightColorIndex),
    captionsCaseType: style?.captionsCaseType ?? StyleCaptionsCaseType.DEFAULT,
    captionFormattingData: getCaptionsFormattingValue(sequence),
  };
};

export default function CaptionsStyleForm({
  disabled,
  featureNotAllowed,
  isCustomPositionCloseCaption = false,
}: Props) {
  const sequence = sequenceState.use();
  const cuePointsData = cuePointsState.use();
  const fontOptions = useFontOptions(sequence);
  const { user } = React.useContext(AppContext);
  const [, setIsLoading] = useState(false);
  const [captionsStyleForm, setCaptionsStyleForm] = useState<Partial<CaptionsStyleFormState>>(() => getInitialFormState(sequence, fontOptions)); // prettier-ignore
  const styleTypeColorDisabled = captionsStyleForm.styleType === StyleStyleType.TRANSPARENT;
  const allowCustomPosition = React.useMemo(() => !!user?.UIFLAGS.CC_MANUAL_POSITION, []);
  const [isAdvancedOpen, setIsAdvancedOpen] = React.useState(false);
  const [isCaptionsFormatEditing, setIsCaptionsFormatEditing] = React.useState(false);
  const [isCaptionsReformatting, setIsCaptionsReformatting] = useState(false);
  const [disabledHighlightPalette, setDisabledHighlightPalette] = useState(false);

  const onFormClick = useCallback(() => {
    if (featureNotAllowed) {
      openPeechProUpgradeModal(user, 'Subtitles design and position', FeatureFeatureName.CUSTOMIZE_SUBTITLE);
    } else {
      signalPlaybackShouldPause();
    }
  }, [featureNotAllowed]);

  const getFieldChangeHandler =
    <Field extends keyof CaptionsStyleFormState>(field: Field) =>
    (value: CaptionsStyleFormState[Field] | null) => {
      let fieldChangeTrackEventValue: any;
      let captionsPosition: number = captionsStyleForm.captionsPosition!;
      let verticalAlignment: number = captionsStyleForm.verticalAlignment!;
      let captionsCaseType: number = captionsStyleForm.captionsCaseType!;
      let captionFormattingData: CaptionsFormatType | undefined = captionsStyleForm.captionFormattingData;
      let additionalFieldChangeTrackEventProps = {};

      switch (field) {
        case 'font':
          fieldChangeTrackEventValue = true;
          break;

        case 'verticalAlignment':
          // when change call to reset ManualPosition
          // TODO: add confirm modal
          isCustomPositionCloseCaption && resetCloseCaptionManualPosition(sequence.sid);
          if (value === StyleVerticalAlign.RANDOM) {
            // if random set random position in CC
            randomizeClosedCaptionPosition(sequence.sid, sequence.chapters);
          }
          captionsPosition = getCloseCaptionDefaultPosition(sequence.aspectRatio, value); // prettier-ignore
          verticalAlignment = Number(value);
          fieldChangeTrackEventValue = StyleVerticalAlign[value as StyleVerticalAlign];
          break;

        case 'captionsPosition':
          onFormClick();
          isCustomPositionCloseCaption && resetCloseCaptionManualPosition(sequence.sid);
          verticalAlignment = getCloseCaptionVerticalAlignmentByPosition(Number(value));
          captionsPosition = Number(value);
          fieldChangeTrackEventValue = value;
          additionalFieldChangeTrackEventProps = {
            verticalAlignment: StyleVerticalAlign[verticalAlignment as StyleVerticalAlign],
          };
          break;

        case 'fontColor':
          fieldChangeTrackEventValue = true;
          break;
        
        case 'fontSize':
          onFormClick();
          fieldChangeTrackEventValue = true;
          break;

        case 'styleType':
          fieldChangeTrackEventValue = StyleStyleType[value as StyleStyleType];
          break;

        case 'styleTypeColor':
          fieldChangeTrackEventValue = true;
          break;

        case 'highlightColorIndex':
          fieldChangeTrackEventValue = true;
          break;
        case 'captionsCaseType':
          captionsCaseType = Number(value);
          fieldChangeTrackEventValue = StyleCaptionsCaseType[value as StyleCaptionsCaseType];
          break;
        case 'captionFormattingData':
          captionFormattingData = value as CaptionsFormatType | undefined;
          fieldChangeTrackEventValue = value as SubtitleFormatName | undefined;
          break;
      }

      setCaptionsStyleForm((form) => {
        const nextFormValue = {
          ...form,
          captionsPosition,
          verticalAlignment,
          captionsCaseType,
          captionFormattingData,
          [field]: value,
        };
        save(nextFormValue);
        return nextFormValue;
      });

      trackEvent('Manual Subtitle design', {
        [field + ' change']: fieldChangeTrackEventValue,
        ...additionalFieldChangeTrackEventProps,
      });
    };

  const save = useCallback(
    async (captionsStyleForm: Partial<CaptionsStyleFormState>) => {
      if (!sequence.style) return;

      if (captionsStyleForm.font) {
        if (captionsStyleForm.font.isDefault) {
          sequence.style.captionsFontFamily = captionsStyleForm.font.value;
          sequence.style.captionsFontAssetSid = null;
          sequence.style.captionsRegularFileName = null;
        } else {
          sequence.style.captionsFontFamily = captionsStyleForm.font.fontFamily ?? null;
          sequence.style.captionsFontAssetSid = captionsStyleForm.font.fontFamily ? null : captionsStyleForm.font.assetSid; // prettier-ignore
          sequence.style.captionsRegularFileName = captionsStyleForm.font.value;
        }
      }

      if (!isNaN(Number(captionsStyleForm?.fontSize))) {
        sequence.style.captionsFontSize = captionsStyleForm.fontSize;
      }

      if (captionsStyleForm.verticalAlignment && !isNaN(Number(captionsStyleForm?.captionsPosition))) {
        sequence.style.captionsVerticalAlign = captionsStyleForm.verticalAlignment;
        sequence.style.captionsPosition = captionsStyleForm.captionsPosition // prettier-ignore
      }

      if (captionsStyleForm.fontColor) {
        sequence.style.captionsFontColorIndex = captionsStyleForm.fontColor.kind === 'palette' ? captionsStyleForm.fontColor.value : null; // prettier-ignore
        sequence.style.captionsFontColor = captionsStyleForm.fontColor.kind === 'static' ? captionsStyleForm.fontColor.value : null; // prettier-ignore
      }

      sequence.style.captionsStyleType = captionsStyleForm.styleType;

      sequence.style.captionsCaseType = captionsStyleForm.captionsCaseType;

      sequence.style.captionFormatting = captionsStyleForm.captionFormattingData?.formatName;

      if (captionsStyleForm.styleTypeColor) {
        sequence.style.captionsStyleColorIndex = captionsStyleForm.styleTypeColor.kind === 'palette' ? captionsStyleForm.styleTypeColor.value : null; // prettier-ignore
        sequence.style.captionsStyleColor = captionsStyleForm.styleTypeColor.kind === 'static' ? captionsStyleForm.styleTypeColor.value : null; // prettier-ignore
      }

      if (captionsStyleForm.highlightColorIndex) {
        sequence.style.highlightColorIndex = captionsStyleForm.highlightColorIndex.kind === 'palette' ? captionsStyleForm.highlightColorIndex.value : null; // prettier-ignore
      }

      setIsLoading(true);
      await sequenceSave(sequence);
      setIsLoading(false);
    },
    [captionsStyleForm]
  );

  const rearrangeCaptions = async (captionsFormatValues: CaptionsFormatType) => {
    if (!sequence.style) return;
    setIsCaptionsReformatting(true);

    // save sequence changes
    getFieldChangeHandler('captionFormattingData')(captionsFormatValues);

    // save words changes
    saveClosedCaptionsChanges(
      sequence,
      captionsFormatValues.formatName,
      user.cacheVersion,
      captionsFormatValues.formatPreset
    );

    setIsAdvancedOpen(false);
    setIsCaptionsFormatEditing(false);
    setIsCaptionsReformatting(false);
  };

  useEffect(() => {
    const savedFont = fontOptions.find((font) => isSelectedFont(font, sequence.style));

    if (savedFont !== captionsStyleForm.font) {
      setCaptionsStyleForm((form) => ({ ...form, font: savedFont }));
    }
  }, [sequence.style?.captionsFontFamily, sequence.style?.captionsFontAssetSid, sequence.style?.captionsRegularFileName]); // prettier-ignore

  const subtitlesColorsObjects: SubtitleColorsObjectType[] = useMemo(
    () => [
      {
        values: {
          name: 'Font Color',
          colorIndex: captionsStyleForm.fontColor?.kind === 'palette' ? captionsStyleForm.fontColor?.value : null,
          color: captionsStyleForm.fontColor?.kind === 'static' ? captionsStyleForm.fontColor?.value : null,
        },
        onChangeCallback: getFieldChangeHandler('fontColor'),
        withStatic: true,
        withNoneColor: false,
      },
      {
        values: {
          name: [StyleStyleType.BOX, StyleStyleType.TRANSPARENT_BOX].includes(captionsStyleForm.styleType!)
            ? 'Background Color'
            : [StyleStyleType.STROKE].includes(captionsStyleForm.styleType!)
            ? 'Stroke Color'
            : [StyleStyleType.SHADOW, StyleStyleType.SHADOW_STROKE].includes(captionsStyleForm.styleType!)
            ? 'Shadow Color'
            : 'Background / Stroke Color',
          colorIndex: styleTypeColorDisabled
            ? undefined
            : captionsStyleForm.styleTypeColor?.kind === 'palette'
            ? captionsStyleForm.styleTypeColor?.value
            : null,
          color: styleTypeColorDisabled
            ? undefined
            : captionsStyleForm.styleTypeColor?.kind === 'static'
            ? captionsStyleForm.styleTypeColor?.value
            : null,
        },
        onChangeCallback: getFieldChangeHandler('styleTypeColor'),
        withStatic: true,
        withNoneColor: false,
        disabled: styleTypeColorDisabled,
      },
      {
        values: {
          name: 'Highlight Color',
          colorIndex:
            captionsStyleForm.highlightColorIndex?.kind === 'palette'
              ? captionsStyleForm.highlightColorIndex?.value
              : null,
          color:
            captionsStyleForm.highlightColorIndex?.kind === 'static'
              ? captionsStyleForm.highlightColorIndex?.value
              : null,
        },
        onChangeCallback: getFieldChangeHandler('highlightColorIndex'),
        withStatic: false,
        withNoneColor: false,
        disabled: disabledHighlightPalette,
      },
    ],
    [captionsStyleForm, disabledHighlightPalette, styleTypeColorDisabled]
  );

  const stylesTypes = useMemo(() => {
    if(user?.UIFLAGS.SHOW_ADDITIONAL_CC_STYLE) {
      return [...STYLE_TYPES, StyleStyleType.SHADOW_STROKE];
    }
    return STYLE_TYPES;
  },[user?.UIFLAGS.SHOW_ADDITIONAL_CC_STYLE]); 

  return (
    <Container disabled={disabled || featureNotAllowed} onClick={onFormClick}>
      <Global styles={SelectGlobalStyle} />

      <Section>
        <SectionTitle>Font</SectionTitle>

        <Select
          value={captionsStyleForm.font}
          onChange={getFieldChangeHandler('font')}
          options={fontOptions}
          getOptionValue={(x) => x.key}
          getOptionLabel={(x) => x.text}
          size="medium"
        />
          <SectionTitle style={{marginTop: '10px'}}>font size</SectionTitle>
          <Stack spacing={10}>
          <SliderWithInput
            onValueChange={getFieldChangeHandler('fontSize')}
            value={captionsStyleForm.fontSize}
            step={1}
            min={25}
            max={200}
          />
        </Stack>
      </Section>

      <Section>
        {isAdvancedOpen ? (
          <>
            <SectionTitle
              style={{ marginBottom: '10px', cursor: 'pointer', justifyContent: 'flex-start' }}
              onClick={() => setIsAdvancedOpen(false)}
            >
              <SVG name="arrow-left" viewBox="0 0 24 24" style={{ marginInline: '-4px 8px' }} />
              Advanced Formatting
            </SectionTitle>
            <OutlinedStack spacing={10}>
              <AdvancedCaptionsFormatter
                aspectRatio={sequence.aspectRatio as AspectRatio}
                rearrangeCaptions={rearrangeCaptions}
                isCaptionsReformatting={isCaptionsReformatting}
              />
            </OutlinedStack>
          </>
        ) : (
          <>
            <SectionTitle
              style={{ marginBottom: '10px', cursor: 'pointer', justifyContent: 'flex-start' }}
              onClick={() => setIsCaptionsFormatEditing(false)}
            >
              {isCaptionsFormatEditing && (
                <SVG name="arrow-left" viewBox="0 0 24 24" style={{ marginInline: '-4px 8px' }} />
              )}
              Subtitles Formatting
            </SectionTitle>
            <OutlinedStack spacing={10}>
              <CaptionsFormatter
                aspectRatio={sequence.aspectRatio as AspectRatio}
                currentCaptionsFormatName={captionsStyleForm.captionFormattingData?.formatName}
                setIsAdvancedOpen={setIsAdvancedOpen}
                rearrangeCaptions={rearrangeCaptions}
                isCaptionsReformatting={isCaptionsReformatting}
                isCaptionsFormatEditing={isCaptionsFormatEditing}
                setIsCaptionsFormatEditing={setIsCaptionsFormatEditing}
              />
            </OutlinedStack>
          </>
        )}
      </Section>

      <Section>
        <SectionTitle>Change all subtitles case</SectionTitle>
        <Stack spacing={10}>
          <CaptionsCasePicker
            value={captionsStyleForm.captionsCaseType}
            onChange={getFieldChangeHandler('captionsCaseType')}
          />
        </Stack>
      </Section>

      <Divider marginBottom={'24px'} />

      <Section>
        <SectionTitle>Subtitles Position</SectionTitle>
        <Stack spacing={10}>
          <SliderWithInput
            onValueChange={getFieldChangeHandler('captionsPosition')}
            value={captionsStyleForm.captionsPosition!}
            reverse
            step={1}
          />
        </Stack>
      </Section>

      <Divider marginBottom={'24px'} />

      <Section>
        <SectionTitle>Randomize subtitles position</SectionTitle>
        <Stack spacing={10}>
          <RandomSubtitlePositionButton onChange={getFieldChangeHandler('verticalAlignment')} />
        </Stack>
      </Section>

      <Divider marginBottom={'24px'} />

      <Section>
        <SectionTitle>Subtitle Style</SectionTitle>
        <Stack spacing={4} style={{flexWrap: "wrap"}}>
          <StyleTypePicker 
          stylesType={stylesTypes as []}
          value={captionsStyleForm.styleType} 
          onChange={getFieldChangeHandler('styleType')

          } />
        </Stack>
      </Section>

      <Divider marginBottom={'24px'} />

      {/* COLORS */}

      <VisualsMenuColorsComponent
        colorsObjects={subtitlesColorsObjects}
        type="subtitles"
        key={(
          captionsStyleForm?.styleType?.toString() ?? 'styleTypeNone' + captionsStyleForm?.styleTypeColor?.value
        ).toString()}
      />

      <HighlightAllComponent
        onColorChange={getFieldChangeHandler('highlightColorIndex')}
        colorValue={captionsStyleForm.highlightColorIndex?.value}
        setDisabledHighlightPalette={setDisabledHighlightPalette}
        showPalette={false}
      />
    </Container>
  );
}
