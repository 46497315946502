import React from 'react'
import { useGoogleLogout } from 'react-google-login';
import User from '../client/user';
import { AppContext } from '../lib/app-context';
import { googleLoginState } from '../state/menu';
import { trackEvent } from '../utils/analityics.utils';

export default function Logout() {
	const { config } = React.useContext(AppContext)
	const googleLogin = googleLoginState.use();

	function onLogout() {
       
        User.ps = null;
        googleLogin && googleLoginState.set(false)
		window.location.assign(`${config.NEW_UI_URL}/logout?redirected`);
    }

    const { signOut } = config.GOOGLE_CLIENT_ID ? useGoogleLogout({
        clientId: config.GOOGLE_CLIENT_ID,
        onLogoutSuccess: onLogout,
    }) : {}

	React.useEffect(() => {
		if(googleLogin) {
			signOut()
		} else{
			onLogout()
		}
		trackEvent('logout');
	}, [config])

	return (
		<div/>
	)
}
