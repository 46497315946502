import User from '../client/user';
import { navigate } from 'gatsby';
import { firstDraftState } from '../components/draftModal/DraftModal';
import { errorState } from '../state/error';
import { DownloadDialogState, shouldLogoutState } from '../state/local';
import { googleLoginState } from '../state/menu';
import { signalPlaybackShouldPause } from '../state/playback';
import { sequenceState } from '../state/sequence';
import { isInIframe } from '../utils/window.utils';

export class UIError {
  constructor({ title, message, actionText, action }) {
    this.title = title; // Error title
    this.message = message; // Error subtext
    this.actionText = actionText; // Error action text
    this.action = action; // Error action callback function
  }
}

function logoutUser() {
  if (isInIframe()) {
    window.top.postMessage('OLD_APP_IFRAME_COOKIE_EXPIRED', '*');
  } else {
    navigate('/');

    setTimeout(() => {
      User.ps = null;
      googleLoginState.get() && googleLoginState.set(false);
      shouldLogoutState.set(true);
      errorState.set({});
    }, 10);
  }
}

function retryDownload() {
  sequenceState.get().generate();
  signalPlaybackShouldPause();
  firstDraftState.set(false);
  DownloadDialogState.set(true);
}

export function reloadPage() {
  document.location.reload();
}

// Example error
// new UIError({ title: 'We’re sorry to see you’re going...', message: 'You\'ll lose access to the \'x\' number of projects you have.', actionText: 'Keep Peeching!', action: () => console.log('example action') }),

export const INTERNAL_SERVER_ERROR = new UIError({
  title: 'Uh-oh, we have an internal server error',
  message: 'A ticket was submitted to our team to solve this issue',
});

export const LOGIN_ERROR = new UIError({
  title: 'Uh-oh your session has expired',
  message: 'Please login',
  actionText: 'Login',
  action: logoutUser,
});

export const AUTO_EDITING_FAILED = new UIError({
  title: 'Uh-oh,it seems likes auto-editing failed',
  message: 'A ticket was submitted to our team to solve this issue',
});

export const FORMAT_NOT_SUPPORTED = new UIError({
  title: 'This file is not supported please try again with a different file',
});

export const ADDING_SCENE_FAILED = new UIError({
  title: 'Sorry, adding a new scene failed',
  message: 'Please start a new project',
  actionText: 'Start a new project',
  action: reloadPage,
});

export const ASPECT_RATIO_NOT_SUPPORTED = new UIError({
  title: "This video's aspect ratio is not currently supported, please try again with a different video",
  actionText: 'Start a new project',
  action: reloadPage,
});

export const FAILED_TO_LOAD_FILES = new UIError({
  title: 'Sorry, we failed to load your files',
  message: 'Please refresh the page to start over',
  actionText: 'Refresh',
  action: reloadPage,
});

export const DOWNLOAD_FAILED = new UIError({
  title: "The download failed, but don't worry!",
  actionText: 'Retry download',
  action: retryDownload,
});

// TODO implement action or callback
export const NO_AUDIO_FOUND = new UIError({
  title:
    "Oops! Your video has no audio, if you choose to continue, there are certain important features that won't be available to you",
});

export const NO_VERBAL_CONTENT = new UIError({
  title: "Oops! We couldn't detect any verbal content, please try again with a different video",
  message: 'Please start a new project',
  actionText: 'Start a new project',
  action: reloadPage,
});

export const SAVING_VISUAL_FAILED = new UIError({
  title: 'Unfortunately, your visual changes were not saved, please retry',
});

export const DELETING_VISUAL_FAILED = new UIError({
  title: 'We failed to delete this visual',
  message: 'Please refresh the page and try again',
  actionText: 'Refresh',
  action: reloadPage,
});

export const CC_REGENERATE_FAILED = new UIError({
  title: 'Sorry, could`t regenerate subtitles',
  message: 'Please refresh the page',
  actionText: 'Refresh',
  action: reloadPage,
});

