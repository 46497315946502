
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export interface IEditRules extends IBase {
	status?: number | null;
	name?: string | null;
	useSpeakers?: boolean | null;
	useIntro?: boolean | null;
	useOutro?: boolean | null;
	useVideos?: boolean | null;
	useIcons?: boolean | null;
	useImages?: boolean | null;
	useTexts?: boolean | null;
	useSlides?: boolean | null;
	useLogo?: boolean | null;
	useMusic?: boolean | null;
	useHighlight?: boolean | null;
	useFrame?: boolean | null;
	volume?: number | null;
	noContentVolume?: number | null;
}

export interface IEditRulesProperties extends IBaseProperties {
	status?: boolean;
	name?: boolean;
	useSpeakers?: boolean;
	useIntro?: boolean;
	useOutro?: boolean;
	useVideos?: boolean;
	useIcons?: boolean;
	useImages?: boolean;
	useTexts?: boolean;
	useSlides?: boolean;
	useLogo?: boolean;
	useMusic?: boolean;
	useHighlight?: boolean;
	useFrame?: boolean;
	volume?: boolean;
	noContentVolume?: boolean;
}

export interface IEditRulesFilter extends IBaseFilter {
}

export class EditRules<IType extends IEditRules = IEditRules, ITypeProperties extends IEditRulesProperties = IEditRulesProperties> extends Base<IType, ITypeProperties> implements IEditRules {
	
	static className = 'EditRules';
	static NAME = 'editRules';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		name: true,
		useSpeakers: true,
		useIntro: true,
		useOutro: true,
		useVideos: true,
		useIcons: true,
		useImages: true,
		useTexts: true,
		useSlides: true,
		useLogo: true,
		useMusic: true,
		useHighlight: true,
		useFrame: true,
		volume: true,
		noContentVolume: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get name(): string | null | undefined {
		return this.values.name
	}
			
	set name(value: string | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	get useSpeakers(): boolean | null | undefined {
		return this.values.useSpeakers
	}
			
	set useSpeakers(value: boolean | null | undefined) {
		if(this.values.useSpeakers !== value) {
			this.propertyChanged('useSpeakers', value);
			this.values.useSpeakers = value;
		}
	}
			
	get useIntro(): boolean | null | undefined {
		return this.values.useIntro
	}
			
	set useIntro(value: boolean | null | undefined) {
		if(this.values.useIntro !== value) {
			this.propertyChanged('useIntro', value);
			this.values.useIntro = value;
		}
	}
			
	get useOutro(): boolean | null | undefined {
		return this.values.useOutro
	}
			
	set useOutro(value: boolean | null | undefined) {
		if(this.values.useOutro !== value) {
			this.propertyChanged('useOutro', value);
			this.values.useOutro = value;
		}
	}
			
	get useVideos(): boolean | null | undefined {
		return this.values.useVideos
	}
			
	set useVideos(value: boolean | null | undefined) {
		if(this.values.useVideos !== value) {
			this.propertyChanged('useVideos', value);
			this.values.useVideos = value;
		}
	}
			
	get useIcons(): boolean | null | undefined {
		return this.values.useIcons
	}
			
	set useIcons(value: boolean | null | undefined) {
		if(this.values.useIcons !== value) {
			this.propertyChanged('useIcons', value);
			this.values.useIcons = value;
		}
	}
			
	get useImages(): boolean | null | undefined {
		return this.values.useImages
	}
			
	set useImages(value: boolean | null | undefined) {
		if(this.values.useImages !== value) {
			this.propertyChanged('useImages', value);
			this.values.useImages = value;
		}
	}
			
	get useTexts(): boolean | null | undefined {
		return this.values.useTexts
	}
			
	set useTexts(value: boolean | null | undefined) {
		if(this.values.useTexts !== value) {
			this.propertyChanged('useTexts', value);
			this.values.useTexts = value;
		}
	}
			
	get useSlides(): boolean | null | undefined {
		return this.values.useSlides
	}
			
	set useSlides(value: boolean | null | undefined) {
		if(this.values.useSlides !== value) {
			this.propertyChanged('useSlides', value);
			this.values.useSlides = value;
		}
	}
			
	get useLogo(): boolean | null | undefined {
		return this.values.useLogo
	}
			
	set useLogo(value: boolean | null | undefined) {
		if(this.values.useLogo !== value) {
			this.propertyChanged('useLogo', value);
			this.values.useLogo = value;
		}
	}
			
	get useMusic(): boolean | null | undefined {
		return this.values.useMusic
	}
			
	set useMusic(value: boolean | null | undefined) {
		if(this.values.useMusic !== value) {
			this.propertyChanged('useMusic', value);
			this.values.useMusic = value;
		}
	}
			
	get useHighlight(): boolean | null | undefined {
		return this.values.useHighlight
	}
			
	set useHighlight(value: boolean | null | undefined) {
		if(this.values.useHighlight !== value) {
			this.propertyChanged('useHighlight', value);
			this.values.useHighlight = value;
		}
	}
			
	get useFrame(): boolean | null | undefined {
		return this.values.useFrame
	}
			
	set useFrame(value: boolean | null | undefined) {
		if(this.values.useFrame !== value) {
			this.propertyChanged('useFrame', value);
			this.values.useFrame = value;
		}
	}
			
	get volume(): number | null | undefined {
		return this.values.volume
	}
			
	set volume(value: number | null | undefined) {
		if(this.values.volume !== value) {
			this.propertyChanged('volume', value);
			this.values.volume = value;
		}
	}
			
	get noContentVolume(): number | null | undefined {
		return this.values.noContentVolume
	}
			
	set noContentVolume(value: number | null | undefined) {
		if(this.values.noContentVolume !== value) {
			this.propertyChanged('noContentVolume', value);
			this.values.noContentVolume = value;
		}
	}
			
	
	static get<IType extends IEditRules = IEditRules>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IEditRulesFilter = IEditRulesFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IEditRulesFilter = IEditRulesFilter, IType extends IEditRules = IEditRules>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
