import { ReactElement, RefAttributes } from 'react';
import ReactSelect from 'react-select';
import SelectClass from 'react-select/dist/declarations/src/Select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { GroupBase } from 'react-select/dist/declarations/src/types';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';

const sizeStyles = {
  medium: css`
    padding: 0 8px;

    .select__input-container {
      padding: 1px;
    }
  `,

  large: css`
    padding: 11px 8px;
  `,
};

export const inputStyle = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #666666;

  border: 1px solid #999999;
  border-radius: 4px;
  background-color: #fff;

  transition: color, background-color, border-color;
  transition-duration: 200ms;
  transition-timing-function: ease-out;

  &:hover {
    border-color: #666666;
  }

  &:focus,
  &:focus-within {
    color: #000;
    border-color: #1f9ffb;
  }

  &:hover,
  &:focus,
  &:focus-within {
    background-color: #f2f2f2;
  }
`;

interface StyledProps {
  size: keyof typeof sizeStyles;
}

const Select = styled(ReactSelect)<StyledProps>`
  .select__control {
    min-height: unset;
    box-shadow: none;
    ${inputStyle}

    .select__placeholder {
      color: #999999;
    }

    .select__value-container {
      ${({ size }) => sizeStyles[size]}
    }

    .select__dropdown-indicator {
      padding-block: 0;
      padding-inline: 8px;

      > svg {
        width: 12px;
        height: 12px;
        fill: #666666;
        transition: fill, rotate;
        transition-duration: 200ms;
        transition-timing-function: ease-out;
      }
    }

    .select__indicator-separator {
      display: none;
    }
  }

  .select__control--menu-is-open {
    .select__dropdown-indicator > svg {
      fill: #1f9ffb;
      rotate: 180deg;
    }
  }
`;

export const SelectGlobalStyle = css`
  .select__menu-portal {
    z-index: 9999 !important;
  }

  .select__menu {
    margin-block: 2px;
    border: 1px solid #999999;
    border-radius: 4px;
    box-shadow: none !important;
    overflow: hidden !important;

    .select__menu-list {
      max-height: 200px;
      padding: 0;

      @media (max-height: 950px) {
        max-height: 150px;
      }

      @media (max-height: 850px) {
        max-height: 120px;
      }

      .select__option {
        padding: 6px 12px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        transition: background-color 200ms ease-out;

        &.select__option--is-focused {
          background-color: #f2f2f2;
        }

        &.select__option--is-selected {
          background-color: #1f9ffb;
        }
      }
    }
  }
`;

Select.defaultProps = {
  classNamePrefix: 'select',
  menuPlacement: 'auto',
  menuPortalTarget: typeof window !== 'undefined' ? document.body : null,
};

export default Select as <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: StateManagerProps<Option, IsMulti, Group> & RefAttributes<SelectClass<Option, IsMulti, Group>> & StyledProps
) => ReactElement;
