
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'

export enum PaymentCardType {
	ISRACARD = 1,
	VISA = 2,
	MASTERCARD = 3,
	AMERICAN_EXPRESS = 4,
	DINERS = 5,
};

export interface IPayment extends IBase {
	id?: string | null;
	amount?: number | null;
	cardType?: number | null;
	cardNum?: number | null;
}

export interface IPaymentProperties extends IBaseProperties {
	id?: boolean;
	amount?: boolean;
	cardType?: boolean;
	cardNum?: boolean;
}

export interface IPaymentFilter extends IBaseFilter {
}

export class Payment<IType extends IPayment = IPayment, ITypeProperties extends IPaymentProperties = IPaymentProperties> extends Base<IType, ITypeProperties> implements IPayment {
	
	static className = 'Payment';
	static NAME = 'payment';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		id: true,
		amount: true,
		cardType: true,
		cardNum: true,
	};
	
	/**
	 * @deprecated use PaymentCardType instead
	 */
	static CARD_TYPE: any = {
		ISRACARD: PaymentCardType.ISRACARD,
		VISA: PaymentCardType.VISA,
		MASTERCARD: PaymentCardType.MASTERCARD,
		AMERICAN_EXPRESS: PaymentCardType.AMERICAN_EXPRESS,
		DINERS: PaymentCardType.DINERS,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get id(): string | null | undefined {
		return this.values.id
	}
			
	set id(value: string | null | undefined) {
		if(this.values.id !== value) {
			this.propertyChanged('id', value);
			this.values.id = value;
		}
	}
			
	get amount(): number | null | undefined {
		return this.values.amount
	}
			
	set amount(value: number | null | undefined) {
		if(this.values.amount !== value) {
			this.propertyChanged('amount', value);
			this.values.amount = value;
		}
	}
			
	get cardType(): number | null | undefined {
		return this.values.cardType
	}
			
	set cardType(value: number | null | undefined) {
		if(this.values.cardType !== value) {
			this.propertyChanged('cardType', value);
			this.values.cardType = value;
		}
	}
			
	get cardNum(): number | null | undefined {
		return this.values.cardNum
	}
			
	set cardNum(value: number | null | undefined) {
		if(this.values.cardNum !== value) {
			this.propertyChanged('cardNum', value);
			this.values.cardNum = value;
		}
	}
			
	
	static get<IType extends IPayment = IPayment>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IPaymentFilter = IPaymentFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IPaymentFilter = IPaymentFilter, IType extends IPayment = IPayment>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
