
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export interface IAccess extends IBase {
	status?: number | null;
	userSid?: string | null;
	type?: number | null;
	role?: number | null;
	email?: string | null;
	name?: string | null;
	thumbUrl?: string | null;
}

export interface IAccessProperties extends IBaseProperties {
	status?: boolean;
	userSid?: boolean;
	type?: boolean;
	role?: boolean;
	email?: boolean;
	name?: boolean;
	thumbUrl?: boolean;
}

export interface IAccessFilter extends IBaseFilter {
}

export class Access<IType extends IAccess = IAccess, ITypeProperties extends IAccessProperties = IAccessProperties> extends Base<IType, ITypeProperties> implements IAccess {
	
	static className = 'Access';
	static NAME = 'access';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		userSid: true,
		type: true,
		role: true,
		email: true,
		name: true,
		thumbUrl: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get userSid(): string | null | undefined {
		return this.values.userSid
	}
			
	set userSid(value: string | null | undefined) {
		if(this.values.userSid !== value) {
			this.propertyChanged('userSid', value);
			this.values.userSid = value;
		}
	}
			
	get type(): number | null | undefined {
		return this.values.type
	}
			
	get role(): number | null | undefined {
		return this.values.role
	}
			
	set role(value: number | null | undefined) {
		if(this.values.role !== value) {
			this.propertyChanged('role', value);
			this.values.role = value;
		}
	}
			
	get email(): string | null | undefined {
		return this.values.email
	}
			
	set email(value: string | null | undefined) {
		if(this.values.email !== value) {
			this.propertyChanged('email', value);
			this.values.email = value;
		}
	}
			
	get name(): string | null | undefined {
		return this.values.name
	}
			
	get thumbUrl(): string | null | undefined {
		return this.values.thumbUrl
	}
			
	
	static get<IType extends IAccess = IAccess>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IAccessFilter = IAccessFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IAccessFilter = IAccessFilter, IType extends IAccess = IAccess>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
