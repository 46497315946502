import React from 'react'

import VideoTypeItem from '../../ui-components/VideoType';

import VideoType from '../../client/videoType';

interface WizardSORProps {
    videoTypes: VideoType[];
    activeSor: string | null;
    onEditRulesChange(videoType: VideoType): void;
}

export default function WizardSOR({ videoTypes, activeSor, onEditRulesChange }: WizardSORProps) {

    return (
        <ul className="wizard-sor">
                {videoTypes?.map(videoType => <li key={videoType.sid} >
                    <VideoTypeItem
                        key={`${videoType.sid}-videoType`}
                        onClick={onEditRulesChange}
                        videoType={videoType}
                        isSelected={activeSor == videoType.sid} />
                </li>
                )}
        </ul>
    )
}
