import React from "react";

export const oneLineSvg =  <svg width="105" height="64" viewBox="0 0 105 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g style={{mixBlendMode: 'luminosity'}}>
<g clip-path="url(#clip0_194_195)">
<rect y="3.05176e-05" width="105" height="64" rx="6" fill="url(#paint0_linear_194_195)"/>
<path d="M60.0749 23.6976C60.0749 25.9125 59.9159 27.7075 60.8682 27.7075C61.8206 27.7075 63.5216 25.9125 63.5216 23.6976C63.5216 21.4827 62.1919 19.9761 61.2404 19.9761C60.2889 19.9761 60.0758 21.4827 60.0758 23.6976H60.0749Z" fill="#EEBBA5"/>
<path d="M44.5331 23.6976C44.5331 25.9125 44.6921 27.7075 43.7397 27.7075C42.7874 27.7075 41.0864 25.9125 41.0864 23.6976C41.0864 21.4827 42.4161 19.9761 43.3676 19.9761C44.3191 19.9761 44.5322 21.4827 44.5322 23.6976H44.5331Z" fill="#EEBBA5"/>
<path d="M25.4883 50.2888C27.7746 45.9981 37.8945 43.7481 44.2618 42.6218C49.5805 41.6809 55.0272 41.6809 60.3459 42.6218C66.7132 43.7481 76.8331 45.9981 79.1194 50.2888C83.894 59.2487 83.6043 75.0008 83.6043 75.0008H21.0034C21.0034 75.0008 20.7137 59.2487 25.4883 50.2888Z" fill="#787777"/>
<path d="M45.793 36.5592V29.0442H58.8146V36.5592C58.8146 40.0919 61.2677 42.2569 65.3796 43.6296C65.3796 43.6296 62.3352 47.3494 52.3038 47.3494C42.2725 47.3494 39.2212 43.6313 39.2212 43.6313C43.3331 42.2586 45.793 40.0919 45.793 36.5592Z" fill="#FDCCB6"/>
<path d="M52.2885 40.17C50.0607 40.17 47.7331 39.2849 45.7769 37.0194C45.7863 36.8683 45.7932 36.7155 45.7932 36.5592V29.0442H58.8148V36.5592C58.8148 36.7043 58.82 36.846 58.8286 36.9868C56.8672 39.272 54.5276 40.17 52.2885 40.17Z" fill="#EEBBA5"/>
<path d="M42.6812 23.7122C42.6812 31.623 46.7011 38.3183 52.2896 38.3183C57.8782 38.3183 61.8981 31.623 61.8981 23.7122C61.8981 14.7738 57.5963 10.9201 52.2896 10.9201C46.983 10.9201 42.6812 14.7738 42.6812 23.7122Z" fill="#FDCCB6"/>
<g style={{mixBlendMode: 'lighten', opacity: "0.4"}}>
<path d="M60.0748 23.6976C60.0748 25.9124 59.9158 27.7075 60.8682 27.7075C61.8205 27.7075 63.5215 25.9124 63.5215 23.6976C63.5215 21.4827 62.1918 19.9761 61.2403 19.9761C60.2889 19.9761 60.0757 21.4827 60.0757 23.6976H60.0748Z" fill="#D3EEFF"/>
<path d="M44.5333 23.6976C44.5333 25.9124 44.6923 27.7075 43.7399 27.7075C42.7876 27.7075 41.0866 25.9124 41.0866 23.6976C41.0866 21.4827 42.4163 19.9761 43.3678 19.9761C44.3192 19.9761 44.5324 21.4827 44.5324 23.6976H44.5333Z" fill="#D3EEFF"/>
<path d="M45.7937 36.5592V29.0441H58.8154V36.5592C58.8154 40.0918 61.2684 42.2569 65.3803 43.6296C65.3803 43.6296 62.3359 47.3494 52.3045 47.3494C42.2732 47.3494 39.2219 43.6313 39.2219 43.6313C43.3338 42.2586 45.7937 40.0918 45.7937 36.5592Z" fill="#C2E7FF"/>
<path d="M42.6812 23.7122C42.6812 31.623 46.7011 38.3182 52.2897 38.3182C57.8782 38.3182 61.8982 31.623 61.8982 23.7122C61.8982 14.7737 57.5963 10.9201 52.2897 10.9201C46.983 10.9201 42.6812 14.7737 42.6812 23.7122Z" fill="#C2E7FF"/>
</g>
<path d="M61.897 23.7122C61.897 23.7122 61.8833 14.0999 57.1328 13.7625C54.4201 13.5702 52.8266 14.6527 52.8266 14.6527C52.8266 14.6527 48.8135 12.595 46.535 14.1119C44.2564 15.6288 42.7015 22.6623 42.7015 22.6623C42.7015 22.6623 38.2063 15.3163 41.6246 10.4806C45.042 5.64392 51.3259 4.2489 55.2951 5.35976C59.1586 6.44058 58.8286 9.18598 58.8286 9.18598C58.8286 9.18598 63.3642 9.91139 63.4596 13.7041C63.555 17.4969 61.897 23.7122 61.897 23.7122Z" fill="#988787"/>
<rect x="8" y="51" width="87" height="7" rx="3.5" fill="white" fill-opacity="0.35"/>
<rect x="10" y="53" width="13" height="3" rx="1.5" fill="white"/>
<rect x="26" y="53" width="25" height="3" rx="1.5" fill="white"/>
<rect x="55" y="53" width="7" height="3" rx="1.5" fill="white"/>
<rect x="65" y="53" width="28" height="3" rx="1.5" fill="white"/>
<g style={{mixBlendMode: 'soft-light', clipPath: "url(#clip1_194_195)"}}>
<path d="M98.6875 5.00003H87.3125C86.5879 5.00003 86 5.58792 86 6.31253V14.1875C86 14.9121 86.5879 15.5 87.3125 15.5H98.6875C99.4121 15.5 100 14.9121 100 14.1875V6.31253C100 5.58792 99.4121 5.00003 98.6875 5.00003ZM91.9637 11.1168C92.0402 11.0485 92.1578 11.0594 92.2152 11.1414L92.7484 11.8989C92.7949 11.9645 92.7895 12.052 92.7348 12.1094C91.2691 13.6625 88.0098 12.9871 88.0098 10.2528C88.0098 7.51839 91.3375 6.98519 92.7266 8.33597C92.784 8.39066 92.7949 8.42347 92.7539 8.49183L92.2754 9.32581C92.2234 9.41058 92.1059 9.43519 92.0266 9.3723C90.9109 8.4973 89.4398 8.96487 89.4398 10.2254C89.4426 11.5379 90.8371 12.1532 91.9637 11.1168ZM97.1699 11.1168C97.2465 11.0485 97.3641 11.0594 97.4215 11.1414L97.9547 11.8989C98.0012 11.9645 97.9957 12.052 97.941 12.1094C96.4781 13.6653 93.2188 12.9871 93.2188 10.2528C93.2188 7.51839 96.5465 6.98519 97.9355 8.33597C97.993 8.39066 98.0039 8.42347 97.9629 8.49183L97.4844 9.32581C97.4324 9.41058 97.3148 9.43519 97.2355 9.3723C96.1199 8.4973 94.6488 8.96487 94.6488 10.2254C94.6488 11.486 96.0434 12.1532 97.1699 11.1168Z" fill="black"/>
</g>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_194_195" x1="5.36496" y1="-15.1579" x2="67.633" y2="58.3202" gradientUnits="userSpaceOnUse">
<stop offset="0.351057" stop-color="#EFECEC"/>
<stop offset="1" stop-color="#B0ADAD"/>
</linearGradient>
<clipPath id="clip0_194_195">
<rect y="3.05176e-05" width="105" height="64" rx="6" fill="white"/>
</clipPath>
<clipPath id="clip1_194_195">
<rect width="14" height="10.5" fill="white" transform="translate(86 5.00003)"/>
</clipPath>
</defs>
</svg>

export const twoLineSvg = <svg width="107" height="64" viewBox="0 0 107 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g style={{mixBlendMode: 'luminosity'}}>
<g clip-path="url(#clip0_194_107)">
<rect x="0.179199" y="3.05176e-05" width="106.821" height="64" rx="6" fill="url(#paint0_linear_194_107)"/>
<path d="M61.2959 23.6976C61.2959 25.9125 61.1341 27.7075 62.103 27.7075C63.0718 27.7075 64.8023 25.9125 64.8023 23.6976C64.8023 21.4827 63.4496 19.9761 62.4816 19.9761C61.5136 19.9761 61.2967 21.4827 61.2967 23.6976H61.2959Z" fill="#EEBBA5"/>
<path d="M45.4845 23.6976C45.4845 25.9125 45.6463 27.7075 44.6774 27.7075C43.7086 27.7075 41.9781 25.9125 41.9781 23.6976C41.9781 21.4827 43.3308 19.9761 44.2988 19.9761C45.2668 19.9761 45.4837 21.4827 45.4837 23.6976H45.4845Z" fill="#EEBBA5"/>
<path d="M26.1095 50.2888C28.4355 45.9981 38.7309 43.7481 45.2086 42.6218C50.6195 41.6809 56.1606 41.6809 61.5715 42.6218C68.0492 43.7481 78.3446 45.9981 80.6706 50.2888C85.528 59.2487 85.2333 75.0008 85.2333 75.0008H21.5468C21.5468 75.0008 21.2521 59.2487 26.1095 50.2888Z" fill="#787777"/>
<path d="M46.7663 36.5592V29.0442H60.0138V36.5592C60.0138 40.0919 62.5094 42.2569 66.6926 43.6296C66.6926 43.6296 63.5954 47.3494 53.39 47.3494C43.1847 47.3494 40.0805 43.6313 40.0805 43.6313C44.2637 42.2586 46.7663 40.0919 46.7663 36.5592Z" fill="#FDCCB6"/>
<path d="M53.3745 40.17C51.108 40.17 48.7401 39.2849 46.7499 37.0194C46.7595 36.8683 46.7665 36.7155 46.7665 36.5592V29.0442H60.0139V36.5592C60.0139 36.7043 60.0192 36.846 60.0279 36.9868C58.0325 39.272 55.6523 40.17 53.3745 40.17Z" fill="#EEBBA5"/>
<path d="M43.6005 23.7122C43.6005 31.623 47.6901 38.3183 53.3756 38.3183C59.0611 38.3183 63.1507 31.623 63.1507 23.7122C63.1507 14.7738 58.7743 10.9201 53.3756 10.9201C47.977 10.9201 43.6005 14.7738 43.6005 23.7122Z" fill="#FDCCB6"/>
<g style={{mixBlendMode: 'lighten', opacity: "0.4"}}>
<path d="M61.2958 23.6976C61.2958 25.9124 61.134 27.7075 62.1029 27.7075C63.0717 27.7075 64.8022 25.9124 64.8022 23.6976C64.8022 21.4827 63.4495 19.9761 62.4815 19.9761C61.5135 19.9761 61.2967 21.4827 61.2967 23.6976H61.2958Z" fill="#D3EEFF"/>
<path d="M45.4847 23.6976C45.4847 25.9124 45.6465 27.7075 44.6776 27.7075C43.7088 27.7075 41.9783 25.9124 41.9783 23.6976C41.9783 21.4827 43.331 19.9761 44.299 19.9761C45.267 19.9761 45.4838 21.4827 45.4838 23.6976H45.4847Z" fill="#D3EEFF"/>
<path d="M46.7671 36.5592V29.0441H60.0145V36.5592C60.0145 40.0918 62.5101 42.2569 66.6933 43.6296C66.6933 43.6296 63.5961 47.3494 53.3908 47.3494C43.1854 47.3494 40.0813 43.6313 40.0813 43.6313C44.2645 42.2586 46.7671 40.0918 46.7671 36.5592Z" fill="#C2E7FF"/>
<path d="M43.6005 23.7122C43.6005 31.623 47.6902 38.3182 53.3756 38.3182C59.0611 38.3182 63.1507 31.623 63.1507 23.7122C63.1507 14.7737 58.7743 10.9201 53.3756 10.9201C47.977 10.9201 43.6005 14.7737 43.6005 23.7122Z" fill="#C2E7FF"/>
</g>
<path d="M63.1496 23.7122C63.1496 23.7122 63.1356 14.0999 58.3027 13.7625C55.543 13.5702 53.9219 14.6527 53.9219 14.6527C53.9219 14.6527 49.8392 12.595 47.5211 14.1119C45.203 15.6288 43.6212 22.6623 43.6212 22.6623C43.6212 22.6623 39.048 15.3163 42.5256 10.4806C46.0023 5.64392 52.3951 4.2489 56.4332 5.35976C60.3637 6.44058 60.0279 9.18598 60.0279 9.18598C60.0279 9.18598 64.6422 9.91139 64.7393 13.7041C64.8363 17.4969 63.1496 23.7122 63.1496 23.7122Z" fill="#988787"/>
<rect x="10.1792" y="45" width="86" height="7" rx="3.5" fill="white" fill-opacity="0.35"/>
<rect x="12.1792" y="47" width="13" height="3" rx="1.5" fill="white"/>
<rect x="28.1792" y="47" width="25" height="3" rx="1.5" fill="white"/>
<rect x="56.1792" y="47" width="7" height="3" rx="1.5" fill="white"/>
<rect x="66.1792" y="47" width="28" height="3" rx="1.5" fill="white"/>
<rect x="18.1792" y="54" width="70" height="7" rx="3.5" fill="white" fill-opacity="0.35"/>
<rect x="20.1792" y="56" width="13" height="3" rx="1.5" fill="white"/>
<rect x="36.1792" y="56" width="25" height="3" rx="1.5" fill="white"/>
<rect x="64.1792" y="56" width="22" height="3" rx="1.5" fill="white"/>
<g style={{mixBlendMode: 'soft-light', clipPath: "url(#clip1_194_195)"}}>
<path d="M98.8667 5.00003H87.4917C86.7671 5.00003 86.1792 5.58792 86.1792 6.31253V14.1875C86.1792 14.9121 86.7671 15.5 87.4917 15.5H98.8667C99.5913 15.5 100.179 14.9121 100.179 14.1875V6.31253C100.179 5.58792 99.5913 5.00003 98.8667 5.00003ZM92.1429 11.1168C92.2194 11.0485 92.337 11.0594 92.3944 11.1414L92.9276 11.8989C92.9741 11.9645 92.9687 12.052 92.914 12.1094C91.4483 13.6625 88.189 12.9871 88.189 10.2528C88.189 7.51839 91.5167 6.98519 92.9058 8.33597C92.9632 8.39066 92.9741 8.42347 92.9331 8.49183L92.4546 9.32581C92.4026 9.41058 92.2851 9.43519 92.2058 9.3723C91.0901 8.4973 89.619 8.96487 89.619 10.2254C89.6218 11.5379 91.0163 12.1532 92.1429 11.1168ZM97.3491 11.1168C97.4257 11.0485 97.5433 11.0594 97.6007 11.1414L98.1339 11.8989C98.1804 11.9645 98.1749 12.052 98.1202 12.1094C96.6573 13.6653 93.3979 12.9871 93.3979 10.2528C93.3979 7.51839 96.7257 6.98519 98.1147 8.33597C98.1722 8.39066 98.1831 8.42347 98.1421 8.49183L97.6636 9.32581C97.6116 9.41058 97.494 9.43519 97.4147 9.3723C96.2991 8.4973 94.828 8.96487 94.828 10.2254C94.828 11.486 96.2226 12.1532 97.3491 11.1168Z" fill="black"/>
</g>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_194_107" x1="5.63719" y1="-15.1579" x2="67.721" y2="59.3731" gradientUnits="userSpaceOnUse">
<stop offset="0.351057" stop-color="#EFECEC"/>
<stop offset="1" stop-color="#B0ADAD"/>
</linearGradient>
<clipPath id="clip0_194_107">
<rect x="0.179199" y="3.05176e-05" width="106.821" height="64" rx="6" fill="white"/>
</clipPath>
<clipPath id="clip1_194_107">
<rect width="14" height="10.5" fill="white" transform="translate(86.1792 5.00003)"/>
</clipPath>
</defs>
</svg>
;


export const singleWordSvg = <svg width="105" height="64" viewBox="0 0 105 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g style={{mixBlendMode: 'luminosity'}}>
<g clip-path="url(#clip0_194_247)">
<rect y="3.05176e-05" width="105" height="64" rx="6" fill="url(#paint0_linear_194_247)"/>
<path d="M60.0749 23.6976C60.0749 25.9125 59.9159 27.7075 60.8682 27.7075C61.8206 27.7075 63.5216 25.9125 63.5216 23.6976C63.5216 21.4827 62.1919 19.9761 61.2404 19.9761C60.2889 19.9761 60.0758 21.4827 60.0758 23.6976H60.0749Z" fill="#EEBBA5"/>
<path d="M44.5331 23.6976C44.5331 25.9125 44.6921 27.7075 43.7397 27.7075C42.7874 27.7075 41.0864 25.9125 41.0864 23.6976C41.0864 21.4827 42.4161 19.9761 43.3676 19.9761C44.3191 19.9761 44.5322 21.4827 44.5322 23.6976H44.5331Z" fill="#EEBBA5"/>
<path d="M25.4883 50.2888C27.7746 45.9981 37.8945 43.7481 44.2618 42.6218C49.5805 41.6809 55.0272 41.6809 60.3459 42.6218C66.7132 43.7481 76.8331 45.9981 79.1194 50.2888C83.894 59.2487 83.6043 75.0008 83.6043 75.0008H21.0034C21.0034 75.0008 20.7137 59.2487 25.4883 50.2888Z" fill="#787777"/>
<path d="M45.793 36.5592V29.0442H58.8146V36.5592C58.8146 40.0919 61.2677 42.2569 65.3796 43.6296C65.3796 43.6296 62.3352 47.3494 52.3038 47.3494C42.2725 47.3494 39.2212 43.6313 39.2212 43.6313C43.3331 42.2586 45.793 40.0919 45.793 36.5592Z" fill="#FDCCB6"/>
<path d="M52.2885 40.17C50.0607 40.17 47.7331 39.2849 45.7769 37.0194C45.7863 36.8683 45.7932 36.7155 45.7932 36.5592V29.0442H58.8148V36.5592C58.8148 36.7043 58.82 36.846 58.8286 36.9868C56.8672 39.272 54.5276 40.17 52.2885 40.17Z" fill="#EEBBA5"/>
<path d="M42.6812 23.7122C42.6812 31.623 46.7011 38.3183 52.2896 38.3183C57.8782 38.3183 61.8981 31.623 61.8981 23.7122C61.8981 14.7738 57.5963 10.9201 52.2896 10.9201C46.983 10.9201 42.6812 14.7738 42.6812 23.7122Z" fill="#FDCCB6"/>
<g style={{mixBlendMode: 'lighten', opacity: "0.4"}}>
<path d="M60.0748 23.6976C60.0748 25.9124 59.9158 27.7075 60.8682 27.7075C61.8205 27.7075 63.5215 25.9124 63.5215 23.6976C63.5215 21.4827 62.1918 19.9761 61.2403 19.9761C60.2889 19.9761 60.0757 21.4827 60.0757 23.6976H60.0748Z" fill="#D3EEFF"/>
<path d="M44.5333 23.6976C44.5333 25.9124 44.6923 27.7075 43.7399 27.7075C42.7876 27.7075 41.0866 25.9124 41.0866 23.6976C41.0866 21.4827 42.4163 19.9761 43.3678 19.9761C44.3192 19.9761 44.5324 21.4827 44.5324 23.6976H44.5333Z" fill="#D3EEFF"/>
<path d="M45.7937 36.5592V29.0441H58.8154V36.5592C58.8154 40.0918 61.2684 42.2569 65.3803 43.6296C65.3803 43.6296 62.3359 47.3494 52.3045 47.3494C42.2732 47.3494 39.2219 43.6313 39.2219 43.6313C43.3338 42.2586 45.7937 40.0918 45.7937 36.5592Z" fill="#C2E7FF"/>
<path d="M42.6812 23.7122C42.6812 31.623 46.7011 38.3182 52.2897 38.3182C57.8782 38.3182 61.8982 31.623 61.8982 23.7122C61.8982 14.7737 57.5963 10.9201 52.2897 10.9201C46.983 10.9201 42.6812 14.7737 42.6812 23.7122Z" fill="#C2E7FF"/>
</g>
<path d="M61.897 23.7122C61.897 23.7122 61.8833 14.0999 57.1328 13.7625C54.4201 13.5702 52.8266 14.6527 52.8266 14.6527C52.8266 14.6527 48.8135 12.595 46.535 14.1119C44.2564 15.6288 42.7015 22.6623 42.7015 22.6623C42.7015 22.6623 38.2063 15.3163 41.6246 10.4806C45.042 5.64392 51.3259 4.2489 55.2951 5.35976C59.1586 6.44058 58.8286 9.18598 58.8286 9.18598C58.8286 9.18598 63.3642 9.91139 63.4596 13.7041C63.555 17.4969 61.897 23.7122 61.897 23.7122Z" fill="#988787"/>
<rect x="36" y="51" width="33" height="7" rx="3.5" fill="white" fill-opacity="0.35"/>
<rect x="38" y="53" width="29" height="3" rx="1.5" fill="white"/>
<g style={{mixBlendMode: 'soft-light', clipPath: "url(#clip1_194_195)"}}>
<path d="M98.6875 5.00003H87.3125C86.5879 5.00003 86 5.58792 86 6.31253V14.1875C86 14.9121 86.5879 15.5 87.3125 15.5H98.6875C99.4121 15.5 100 14.9121 100 14.1875V6.31253C100 5.58792 99.4121 5.00003 98.6875 5.00003ZM91.9637 11.1168C92.0402 11.0485 92.1578 11.0594 92.2152 11.1414L92.7484 11.8989C92.7949 11.9645 92.7895 12.052 92.7348 12.1094C91.2691 13.6625 88.0098 12.9871 88.0098 10.2528C88.0098 7.51839 91.3375 6.98519 92.7266 8.33597C92.784 8.39066 92.7949 8.42347 92.7539 8.49183L92.2754 9.32581C92.2234 9.41058 92.1059 9.43519 92.0266 9.3723C90.9109 8.4973 89.4398 8.96487 89.4398 10.2254C89.4426 11.5379 90.8371 12.1532 91.9637 11.1168ZM97.1699 11.1168C97.2465 11.0485 97.3641 11.0594 97.4215 11.1414L97.9547 11.8989C98.0012 11.9645 97.9957 12.052 97.941 12.1094C96.4781 13.6653 93.2188 12.9871 93.2188 10.2528C93.2188 7.51839 96.5465 6.98519 97.9355 8.33597C97.993 8.39066 98.0039 8.42347 97.9629 8.49183L97.4844 9.32581C97.4324 9.41058 97.3148 9.43519 97.2355 9.3723C96.1199 8.4973 94.6488 8.96487 94.6488 10.2254C94.6488 11.486 96.0434 12.1532 97.1699 11.1168Z" fill="black"/>
</g>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_194_247" x1="5.36496" y1="-15.1579" x2="67.633" y2="58.3202" gradientUnits="userSpaceOnUse">
<stop offset="0.351057" stop-color="#EFECEC"/>
<stop offset="1" stop-color="#B0ADAD"/>
</linearGradient>
<clipPath id="clip0_194_247">
<rect y="3.05176e-05" width="105" height="64" rx="6" fill="white"/>
</clipPath>
<clipPath id="clip1_194_247">
<rect width="14" height="10.5" fill="white" transform="translate(86 5.00003)"/>
</clipPath>
</defs>
</svg>
;


export const advancedSvg = <svg width="105" height="64" viewBox="0 0 105 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g style={{mixBlendMode: 'luminosity'}}>
<g clip-path="url(#clip0_194_165)">
<rect y="3.05176e-05" width="105" height="64" rx="6" fill="url(#paint0_linear_194_165)"/>
<path d="M60.0749 23.6976C60.0749 25.9125 59.9159 27.7075 60.8682 27.7075C61.8206 27.7075 63.5216 25.9125 63.5216 23.6976C63.5216 21.4827 62.1919 19.9761 61.2404 19.9761C60.2889 19.9761 60.0758 21.4827 60.0758 23.6976H60.0749Z" fill="#EEBBA5"/>
<path d="M44.5331 23.6976C44.5331 25.9125 44.6921 27.7075 43.7397 27.7075C42.7874 27.7075 41.0864 25.9125 41.0864 23.6976C41.0864 21.4827 42.4161 19.9761 43.3676 19.9761C44.3191 19.9761 44.5322 21.4827 44.5322 23.6976H44.5331Z" fill="#EEBBA5"/>
<path d="M25.4883 50.2888C27.7746 45.9981 37.8945 43.7481 44.2618 42.6218C49.5805 41.6809 55.0272 41.6809 60.3459 42.6218C66.7132 43.7481 76.8331 45.9981 79.1194 50.2888C83.894 59.2487 83.6043 75.0008 83.6043 75.0008H21.0034C21.0034 75.0008 20.7137 59.2487 25.4883 50.2888Z" fill="#787777"/>
<path d="M45.793 36.5592V29.0442H58.8146V36.5592C58.8146 40.0919 61.2677 42.2569 65.3796 43.6296C65.3796 43.6296 62.3352 47.3494 52.3038 47.3494C42.2725 47.3494 39.2212 43.6313 39.2212 43.6313C43.3331 42.2586 45.793 40.0919 45.793 36.5592Z" fill="#FDCCB6"/>
<path d="M52.2885 40.17C50.0607 40.17 47.7331 39.2849 45.7769 37.0194C45.7863 36.8683 45.7932 36.7155 45.7932 36.5592V29.0442H58.8148V36.5592C58.8148 36.7043 58.82 36.846 58.8286 36.9868C56.8672 39.272 54.5276 40.17 52.2885 40.17Z" fill="#EEBBA5"/>
<path d="M42.6812 23.7122C42.6812 31.623 46.7011 38.3183 52.2896 38.3183C57.8782 38.3183 61.8981 31.623 61.8981 23.7122C61.8981 14.7738 57.5963 10.9201 52.2896 10.9201C46.983 10.9201 42.6812 14.7738 42.6812 23.7122Z" fill="#FDCCB6"/>
<g style={{mixBlendMode: 'lighten', opacity: "0.4"}}>
<path d="M60.0748 23.6976C60.0748 25.9124 59.9158 27.7075 60.8682 27.7075C61.8205 27.7075 63.5215 25.9124 63.5215 23.6976C63.5215 21.4827 62.1918 19.9761 61.2403 19.9761C60.2889 19.9761 60.0757 21.4827 60.0757 23.6976H60.0748Z" fill="#D3EEFF"/>
<path d="M44.5332 23.6976C44.5332 25.9124 44.6923 27.7075 43.7399 27.7075C42.7876 27.7075 41.0866 25.9124 41.0866 23.6976C41.0866 21.4827 42.4163 19.9761 43.3677 19.9761C44.3192 19.9761 44.5324 21.4827 44.5324 23.6976H44.5332Z" fill="#D3EEFF"/>
<path d="M45.7937 36.5592V29.0441H58.8154V36.5592C58.8154 40.0918 61.2684 42.2569 65.3803 43.6296C65.3803 43.6296 62.3359 47.3494 52.3046 47.3494C42.2732 47.3494 39.2219 43.6313 39.2219 43.6313C43.3338 42.2586 45.7937 40.0918 45.7937 36.5592Z" fill="#C2E7FF"/>
<path d="M42.6812 23.7122C42.6812 31.623 46.7011 38.3182 52.2897 38.3182C57.8782 38.3182 61.8982 31.623 61.8982 23.7122C61.8982 14.7737 57.5963 10.9201 52.2897 10.9201C46.983 10.9201 42.6812 14.7737 42.6812 23.7122Z" fill="#C2E7FF"/>
</g>
<path d="M61.897 23.7122C61.897 23.7122 61.8833 14.0999 57.1328 13.7625C54.4201 13.5702 52.8266 14.6527 52.8266 14.6527C52.8266 14.6527 48.8135 12.595 46.535 14.1119C44.2564 15.6288 42.7015 22.6623 42.7015 22.6623C42.7015 22.6623 38.2063 15.3163 41.6246 10.4806C45.042 5.64392 51.3259 4.2489 55.2951 5.35976C59.1586 6.44058 58.8286 9.18598 58.8286 9.18598C58.8286 9.18598 63.3642 9.91139 63.4596 13.7041C63.555 17.4969 61.897 23.7122 61.897 23.7122Z" fill="#988787"/>
<rect x="9" y="51" width="87" height="7" rx="3.5" fill="white" fill-opacity="0.35"/>
<rect x="11" y="53" width="13" height="3" rx="1.5" fill="white"/>
<rect x="27" y="53" width="25" height="3" rx="1.5" fill="white"/>
<rect x="56" y="53" width="7" height="3" rx="1.5" fill="white"/>
<rect x="66" y="53" width="28" height="3" rx="1.5" fill="white"/>
<rect x="25" y="40" width="56" height="7" rx="3.5" fill="white" fill-opacity="0.35"/>
<rect x="27" y="42" width="13" height="3" rx="1.5" fill="white"/>
<rect x="42" y="42" width="7" height="3" rx="1.5" fill="white"/>
<rect x="51" y="42" width="28" height="3" rx="1.5" fill="white"/>
<path d="M93 5.00003L95.0572 9.16847L99.6574 9.83691L96.3287 13.0816L97.1145 17.6631L93 15.5L88.8855 17.6631L89.6713 13.0816L86.3426 9.83691L90.9428 9.16847L93 5.00003Z" fill="#A0A0A0"/>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_194_165" x1="5.36496" y1="-15.1579" x2="67.633" y2="58.3202" gradientUnits="userSpaceOnUse">
<stop offset="0.351057" stop-color="#EFECEC"/>
<stop offset="1" stop-color="#B0ADAD"/>
</linearGradient>
<clipPath id="clip0_194_165">
<rect y="3.05176e-05" width="105" height="64" rx="6" fill="white"/>
</clipPath>
</defs>
</svg>
;

