import React from 'react';

import * as Base from './base/preset';
import { PresetStatus } from './base/preset';
import User from './user';
import Asset, { AssetType } from './asset';
import AssetUsage, { AssetUsageUsageType } from './preset/assetUsage';

import { appConfig } from '../lib/app-context';
import { FontAssetUsage } from './base/preset/fontAssetUsage';
import { loadedPresetsState, modifyPreset } from '../state/local';

	
export {default as AssetUsage} from './preset/assetUsage';
export {default as FontAssetUsage} from './preset/fontAssetUsage';
export {default as Color} from './color';

export { PresetStatus } from './base/preset';

export interface IPreset extends Base.IPreset {

}

export interface IPresetProperties extends Base.IPresetProperties {

}

export interface IPresetFilter extends Base.IPresetFilter {

}

interface IDefaultChange {
  user: User;
  setUser: (user: User) => {};
}

export default class Preset<IType extends IPreset = IPreset, ITypeProperties extends IPresetProperties = IPresetProperties> extends Base.Preset<IType, ITypeProperties> implements IPreset {

    /**
     * @returns {AssetUsage[]}
     */
    get assets() {
      return (this.values.assets || []).filter(a => a.assetSid)
    }
  
    /**
     * @param {AssetUsage[]} value
     */
    set assets(value) {
      if (this.values.assets !== value) {
        this.propertyChanged('assets', value);
        this.values.assets = value;
      }
    }
  
    addLogoAsset(logoAsset: Asset, usageType?: AssetUsageUsageType) {
      this.assets = this.assets || [];
      let { sid, type } = logoAsset;
      let assetUsage = new AssetUsage();
      let existingAsset
      assetUsage.assetSid = sid;
      assetUsage.assetType = type;
      if (usageType) {
        assetUsage.usageType = usageType;
        existingAsset = this.assets
          .find((a) => (a.type === Asset.TYPE.LOGO || a.assetType === Asset.TYPE.LOGO) && a.usageType === usageType)
      } else {
        existingAsset = this.assets
          .find((a) => (a.type === Asset.TYPE.LOGO || a.assetType === Asset.TYPE.LOGO) && !a.usageType)
      }
      existingAsset && (existingAsset.assetSid = sid)
      !this.logos()?.length && (assetUsage.isDefault = true)
      this.assets = existingAsset ? [...this.assets] : [...this.assets, assetUsage];
      return assetUsage
    }
  
    hasLogoAsset() {
      return this.assets?.find((a) => a.assetType === Asset.TYPE.LOGO || a.type === Asset.TYPE.LOGO);
    }
  
    addFontAsset(fontAsset: Asset, usageType: AssetUsageUsageType) {
      this.assets = this.assets || [];
      this.assets.filter((a) => (!Asset.isFont(a.assetType) && !Asset.isFont(a.type as AssetType)) || a.usageType !== usageType);
      let { sid, type, fileNames } = fontAsset;
      let fontAssetUsage = new FontAssetUsage();
      fontAssetUsage.assetType = type;
      fontAssetUsage.assetSid = sid;
      fontAssetUsage.usageType = usageType;
      fontAssetUsage.fileNames = [...(fileNames || [])];
      this.assets = [...this.assets, fontAssetUsage];
      return fontAssetUsage
    }
  
    hasFontAsset() {
      return this.assets?.find((a) => Asset.isFont(a.assetType) || Asset.isFont(a.type as AssetType));
    }
  
    logos() {
      return this.assets?.filter((asset) => asset.assetType === Asset.TYPE.LOGO || asset.type === Asset.TYPE.LOGO);
    }
  
    getLogoOfType(usageType?: AssetUsageUsageType) {
      return this.logos()?.find(
        asset => asset.usageType === usageType);
    }
  
    getLogoUrl(usageType?: AssetUsageUsageType, cacheVersion = 0) {
      const assetUsage = this.getLogoOfType(usageType);
      console.log('getLogoOfType assetUsagsse', assetUsage)
      return assetUsage && `${appConfig.CHUNKS_URL}/asset/${assetUsage.assetSid}/logo/${cacheVersion}.png`;
    }
  
    getDefaultLogo() {
      return (
        this.logos()?.find((asset) => asset.isDefault) ||
        this.logos()?.find((asset) => !asset.usageType) ||
        this.logos()?.find((asset) => asset.assetType === Asset.TYPE.LOGO)?.[0]
      );
    }
  
    fonts(): AssetUsage[] {
      return this.assets?.filter(asset => Asset.isFont(asset.assetType) || Asset.isFont(asset.type as AssetType)) || [];
    }
  
    getFontOfType(usageType?: AssetUsageUsageType | null): AssetUsage {
      return this.fonts().find(asset => usageType === asset.usageType) as AssetUsage;
    }
  
    getHeaderFontAsset(): AssetUsage {
      return this.fonts().find(font => font.usageType === AssetUsageUsageType.HEADER_FONT_FAMILY) as AssetUsage
    }
  
    getBodyFontAsset(): AssetUsage {
      return this.fonts().find(font => font.usageType === AssetUsageUsageType.BODY_FONT_FAMILY) as AssetUsage
    }
  
    getHeaderFont(): AssetUsage | string {
      return this.values.headerFontFamily ? this.values.headerFontFamily as string : this.fonts().find(font => font.usageType === AssetUsageUsageType.HEADER_FONT_FAMILY) as AssetUsage
    }
  
    getBodyFont(): string | AssetUsage | null | undefined {
      return this.values.fontFamily || this.fonts().find(font => font.usageType === AssetUsageUsageType.BODY_FONT_FAMILY)
    }
  
    removeAsset(sid: string) {
      if (sid) {
        const asset = this.assets.find(asset => asset.sid === sid);
        if(asset) {
          asset.assetSid = null;
        }
      }
      return this
    }
  
    /**
     * @param {Preset[]} Preset
     * @return {Preset} value
     */
    static getDefaultPreset(presets: Preset[] = []) {
      return presets?.find((preset) => preset.status === PresetStatus.IS_DEFAULT) || presets?.[0];
    }
  
    /**
     * @return {number} value
     */
    get status() {
      return this.values.status;
    }
  
    /**
     * @param {number} value
     */
    set status(value) {
      this.values.status = value;
    }
  
    /**
     * @returns {string}
     */
    get fontFamily() {
      return this.values.fontFamily;
    }
  
    /**
     * @param {string} value
     */
    set fontFamily(value) {
      if (this.values.fontFamily !== value) {
        if (value && this.assets) {
          let assetUsage = this.assets.find((a) => (Asset.isFont(a.assetType) || Asset.isFont(a.type as AssetType)) && a.usageType === AssetUsageUsageType.BODY_FONT_FAMILY);
          if (assetUsage) {
            assetUsage.assetSid = null;
          }
        }
        this.propertyChanged('fontFamily', value);
        this.values.fontFamily = value;
      }
    }
  
    /**
    * @returns {string}
    */
    get headerFontFamily() {
      return this.values.headerFontFamily;
    }
  
    /**
     * @param {string} value
     */
    set headerFontFamily(value) {
      if (this.values.headerFontFamily !== value) {
        if (value && this.assets) {
          let assetUsage = this.assets.find((a) => (Asset.isFont(a.assetType) || Asset.isFont(a.type as AssetType)) && a.usageType === AssetUsageUsageType.HEADER_FONT_FAMILY);
          if (assetUsage) {
            assetUsage.assetSid = null;
          }
        }
        this.propertyChanged('headerFontFamily', value);
        this.values.headerFontFamily = value;
      }
    }
  
    onDefaultChange(e: React.FormEvent, { user, setUser}: IDefaultChange) {
      loadedPresetsState.set((presets: null | Preset[]) => {
        presets = presets || [];
        const defaultPreset = presets.find(preset => preset.status === Preset.STATUS.IS_DEFAULT)
        const newDefault = presets.find(p => p.sid === this.sid) as Preset
        if (defaultPreset) {
          defaultPreset.status = Preset.STATUS.ACTIVE
        }
        if (newDefault) {
          newDefault.setDefault()
          newDefault.status = Preset.STATUS.IS_DEFAULT
        }
        defaultPreset && modifyPreset(defaultPreset)
        modifyPreset(newDefault)
        user.preset = newDefault
        setUser(user)
        return presets
      })
    }
  }
  