import styled from "@emotion/styled/macro";

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -4px; // temp time-code position 
  margin-bottom: 8px;
  span {
    font-size: 10px;
    margin: 0 4px;
  }
  input {
    background-color: transparent;
  }
  .icon {
    width: 19px;
    height: 19px;
    fill: #666666;
  }
`;