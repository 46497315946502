
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'

export enum MusicSourceStatus {
	PENDING = 1,
	READY = 2,
	INIT = 3,
};

export interface IMusic extends IBase {
	status?: number | null;
	key?: string | null;
	thumbnailVersion?: number | null;
	assetSid?: string | null;
	linkSid?: string | null;
	thumbnail?: string | null;
	size?: number | null;
	contentVersion?: number | null;
	duration?: number | null;
	srcDuration?: number | null;
	configurationTime?: number | null;
	generatingTime?: number | null;
	aspectRatio?: string | null;
	name?: string | null;
	description?: string | null;
	externalId?: string | null;
	credit?: string | null;
	creditRequired?: boolean | null;
	enabled?: boolean | null;
	downloadDate?: number | null;
	volume?: number | null;
	noContentVolume?: number | null;
	overrideVolume?: boolean | null;
}

export interface IMusicProperties extends IBaseProperties {
	status?: boolean;
	key?: boolean;
	thumbnailVersion?: boolean;
	assetSid?: boolean;
	linkSid?: boolean;
	thumbnail?: boolean;
	size?: boolean;
	contentVersion?: boolean;
	duration?: boolean;
	srcDuration?: boolean;
	configurationTime?: boolean;
	generatingTime?: boolean;
	aspectRatio?: boolean;
	name?: boolean;
	description?: boolean;
	externalId?: boolean;
	credit?: boolean;
	creditRequired?: boolean;
	enabled?: boolean;
	downloadDate?: boolean;
	volume?: boolean;
	noContentVolume?: boolean;
	overrideVolume?: boolean;
}

export interface IMusicFilter extends IBaseFilter {
}

export class Music<IType extends IMusic = IMusic, ITypeProperties extends IMusicProperties = IMusicProperties> extends Base<IType, ITypeProperties> implements IMusic {
	
	static className = 'Music';
	static NAME = 'music';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		status: true,
		key: true,
		thumbnailVersion: true,
		assetSid: true,
		linkSid: true,
		size: true,
		contentVersion: true,
		duration: true,
		srcDuration: true,
		configurationTime: true,
		generatingTime: true,
		aspectRatio: true,
		name: true,
		description: true,
		externalId: true,
		credit: true,
		creditRequired: true,
		enabled: true,
		downloadDate: true,
		volume: true,
		noContentVolume: true,
		overrideVolume: true,
	};
	
	/**
	 * @deprecated use MusicSourceStatus instead
	 */
	static SOURCE_STATUS: any = {
		PENDING: MusicSourceStatus.PENDING,
		READY: MusicSourceStatus.READY,
		INIT: MusicSourceStatus.INIT,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get status(): number | null | undefined {
		return this.values.status
	}
			
	get key(): string | null | undefined {
		return this.values.key
	}
			
	get thumbnailVersion(): number | null | undefined {
		return this.values.thumbnailVersion
	}
			
	get assetSid(): string | null | undefined {
		return this.values.assetSid
	}
			
	set assetSid(value: string | null | undefined) {
		if(this.values.assetSid !== value) {
			this.propertyChanged('assetSid', value);
			this.values.assetSid = value;
		}
	}
			
	get linkSid(): string | null | undefined {
		return this.values.linkSid
	}
			
	set thumbnail(value: string | null | undefined) {
		if(this.values.thumbnail !== value) {
			this.propertyChanged('thumbnail', value);
			this.values.thumbnail = value;
		}
	}
			
	get size(): number | null | undefined {
		return this.values.size
	}
			
	get contentVersion(): number | null | undefined {
		return this.values.contentVersion
	}
			
	get duration(): number | null | undefined {
		return this.values.duration
	}
			
	set duration(value: number | null | undefined) {
		if(this.values.duration !== value) {
			this.propertyChanged('duration', value);
			this.values.duration = value;
		}
	}
			
	get srcDuration(): number | null | undefined {
		return this.values.srcDuration
	}
			
	get configurationTime(): number | null | undefined {
		return this.values.configurationTime
	}
			
	get generatingTime(): number | null | undefined {
		return this.values.generatingTime
	}
			
	get aspectRatio(): string | null | undefined {
		return this.values.aspectRatio
	}
			
	get name(): string | null | undefined {
		return this.values.name
	}
			
	set name(value: string | null | undefined) {
		if(this.values.name !== value) {
			this.propertyChanged('name', value);
			this.values.name = value;
		}
	}
			
	get description(): string | null | undefined {
		return this.values.description
	}
			
	set description(value: string | null | undefined) {
		if(this.values.description !== value) {
			this.propertyChanged('description', value);
			this.values.description = value;
		}
	}
			
	get externalId(): string | null | undefined {
		return this.values.externalId
	}
			
	set externalId(value: string | null | undefined) {
		if(this.values.externalId !== value) {
			this.propertyChanged('externalId', value);
			this.values.externalId = value;
		}
	}
			
	get credit(): string | null | undefined {
		return this.values.credit
	}
			
	set credit(value: string | null | undefined) {
		if(this.values.credit !== value) {
			this.propertyChanged('credit', value);
			this.values.credit = value;
		}
	}
			
	get creditRequired(): boolean | null | undefined {
		return this.values.creditRequired
	}
			
	set creditRequired(value: boolean | null | undefined) {
		if(this.values.creditRequired !== value) {
			this.propertyChanged('creditRequired', value);
			this.values.creditRequired = value;
		}
	}
			
	get enabled(): boolean | null | undefined {
		return this.values.enabled
	}
			
	set enabled(value: boolean | null | undefined) {
		if(this.values.enabled !== value) {
			this.propertyChanged('enabled', value);
			this.values.enabled = value;
		}
	}
			
	get downloadDate(): number | null | undefined {
		return this.values.downloadDate
	}
			
	set downloadDate(value: number | null | undefined) {
		if(this.values.downloadDate !== value) {
			this.propertyChanged('downloadDate', value);
			this.values.downloadDate = value;
		}
	}
			
	get volume(): number | null | undefined {
		return this.values.volume
	}
			
	set volume(value: number | null | undefined) {
		if(this.values.volume !== value) {
			this.propertyChanged('volume', value);
			this.values.volume = value;
		}
	}
			
	get noContentVolume(): number | null | undefined {
		return this.values.noContentVolume
	}
			
	set noContentVolume(value: number | null | undefined) {
		if(this.values.noContentVolume !== value) {
			this.propertyChanged('noContentVolume', value);
			this.values.noContentVolume = value;
		}
	}
			
	get overrideVolume(): boolean | null | undefined {
		return this.values.overrideVolume
	}
			
	set overrideVolume(value: boolean | null | undefined) {
		if(this.values.overrideVolume !== value) {
			this.propertyChanged('overrideVolume', value);
			this.values.overrideVolume = value;
		}
	}
			
	
	static get<IType extends IMusic = IMusic>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IMusicFilter = IMusicFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IMusicFilter = IMusicFilter, IType extends IMusic = IMusic>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
