import { navigate, useLocation } from '@reach/router';
import React from 'react';

import { sequenceState } from '../../state/sequence';

import Button, { ButtonVariant } from '../../ui-components/Button';

import { entity } from 'simpler-state';
import { trackEvent } from '../../utils/analityics.utils';
import DownloadButton from '../download-button';
import PlayerModal from '../playerModal/PlayerModal';
import './draftModal.scss';
import { AppContext } from '../../lib/app-context';
import { isInIframe } from '../../utils/window.utils';
import { isInPreviewState } from '../../state/local';

interface DraftModalProps {
  playbackUrl: string;
  onDownload(): void;
}

export const firstDraftState = entity(false);

function DraftModal({ playbackUrl, onDownload }: DraftModalProps) {
  const { config } = React.useContext(AppContext);
  const location = useLocation();
  const sequence = sequenceState.use();
  const showFirstDraft = firstDraftState.use();
  const [callbackAndHide, setCallbackAndHide] = React.useState<boolean | Function>();
  const { isMobileDevice } = React.useContext(AppContext);
  const [headerTag, setHeaderTag] = React.useState<string>('');
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isNew = queryParams.get('new') === 'true';
    const tag = queryParams.get('tag');
    const triggerDownload = queryParams.get('download') === 'true';
    if (tag) {
      setHeaderTag(tag);
    }
    firstDraftState.set(isNew || isMobileDevice);

    // If the query param is set to download, trigger the download
    if (triggerDownload) {
      onDownload();
    }
    return () => firstDraftState.set(false);
  }, []);

  const onDownloadButtonClick = () => {
    trackEvent('project-download', { trigger: 'download-first-draft' });
    setCallbackAndHide(onDownload);
  };

  return (
    <PlayerModal
      headerText={isInPreviewState.get() ? sequence.title || '' : ''}
      headerTag={isInPreviewState.get() ? headerTag : ''}
      show={showFirstDraft}
      playbackUrl={playbackUrl}
      aspectRatio={sequence.aspectRatio}
      hide={callbackAndHide}
      onHide={() => {
        if (!callbackAndHide && isMobileDevice) {
          const path = `${config.NEW_UI_URL}/platform/p/${sequence.footageSid}`;
          window.location.assign(path);
        }
      }}
    >
      <div className="draft-controls" data-cy="create-project-step">
        {!isMobileDevice && (
          <Button
            variant={ButtonVariant.Tertiary}
            data-cy="edit-button"
            id="edit-button"
            label="Edit"
            style={{ width: '150px' }}
            onClick={() => {
              trackEvent('project-monitor', { trigger: 'first-draft' });
              setCallbackAndHide(true);
            
              isInPreviewState.get() && isInIframe() && window.top?.postMessage('DRAFT_MODAL_EDIT_CLICK', '*');
            }}
          />
        )}
        <DownloadButton id="download-button" loveIt={true} onClick={onDownloadButtonClick} />
      </div>
    </PlayerModal>
  );
}

export default DraftModal;
