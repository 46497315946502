export const calculateLogoData = (logoImageUrl: string | null | undefined, animationObject: any, logoScaleFactor: number) =>
    new Promise((resolve) => {

        try {
            // Find the asset in the animation object that is classified as the logo
            const logoAsset = animationObject.assets.find((asset: any) => asset.hasClass("logo"));

            if (logoImageUrl && logoAsset) {
                // If the logoAsset does not already have original width or height stored, store these values
                if (!logoAsset.data.originHeight || !logoAsset.data.originWidth) {
                    logoAsset.data.originHeight = logoAsset.height;
                    logoAsset.data.originWidth = logoAsset.width;
                }

                // Create an <img> element
                const imageElement = document.createElement("img");

                const processImage = () => {
                    // Calculate and store some data related to the original dimensions of the image and asset
                    const originalImageData = {
                        width: imageElement.width,
                        height: imageElement.height,
                        ratio: imageElement.width / imageElement.height,
                        assetOriginalHeight: logoAsset.data.originHeight || logoAsset.height,
                        assetOriginalWidth: logoAsset.data.originWidth || logoAsset.width
                    }

                    const resizedImageData = {
                        w: 0,
                        h: 0,
                        maxScale: 1
                    }

                    // Set logo max height - if ratio > 2 limit to 70% off the placeholder height
                    const maxResizedHeight = originalImageData.ratio > 2 ? originalImageData.assetOriginalHeight * 0.7 : originalImageData.assetOriginalHeight;
                    let heightScaleFactor = maxResizedHeight / originalImageData.height;
                    resizedImageData.h = maxResizedHeight;
                    resizedImageData.w = originalImageData.width * heightScaleFactor;

                    const maxLogoWidth = animationObject.width * 0.7; // 70% of animation width
                    let scaledDown = false;

                    // If the width of the logo (after scaling to max height) exceeds maximum allowed width, further scale down
                    if (resizedImageData.w > maxLogoWidth) {
                        const widthScaleDownFactor = maxLogoWidth / resizedImageData.w;
                        resizedImageData.h *= widthScaleDownFactor;
                        resizedImageData.w *= widthScaleDownFactor;
                        scaledDown = true;
                    }

                    // Calculate maximum scaling factor allowed for both height and width and select the minimum
                    const maxScaleHeight: number =  parseFloat((animationObject.height * 0.5 / resizedImageData.h).toFixed(1));
                    const maxScaleWidth: number = parseFloat((animationObject.width * 0.9 / resizedImageData.w).toFixed(1));
                    resizedImageData.maxScale = Math.min(maxScaleHeight, maxScaleWidth);

                    // Scale the resized logo according to user setting
                    const userDefinedScale = logoScaleFactor || 1;
                    if (userDefinedScale) {
                        resizedImageData.h *= userDefinedScale;
                        resizedImageData.w *= userDefinedScale;
                    }

                    // Update image size
                    imageElement.height = resizedImageData.h;
                    imageElement.width = resizedImageData.w;

                    // Create a canvas and draw the image on it
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
                    canvas.width = imageElement.width;
                    canvas.height = imageElement.height;

                    // Draw the logo image onto canvas
                    ctx?.drawImage(
                        imageElement,
                        0,
                        0,
                        imageElement.width,
                        imageElement.height
                    );

                    // Cleanup: detach the onload function and clear image src
                    imageElement.onload = null;
                    imageElement.src = "";
                    
                    resolve({
                        origin: originalImageData,
                        data: {
                            // Convert canvas image to data URL (base64 format)
                            src: canvas.toDataURL("image/png"),
                            ...resizedImageData,
                        }
                    })
                };

                // Function 'processImage' is called as soon as the image has been completely loaded
                imageElement.onload = processImage;
                imageElement.crossOrigin = "anonymous";
                // Load the image
                imageElement.src = logoImageUrl;
            }
        }
        catch (error) {
            resolve(error);
        }
    })