
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'
import CuePoint, { ICuePoint, ICuePointFilter, ICuePointProperties } from '../cuePoint'
import Word, { IWord, IWordProperties } from '../closedCaption/word'

export enum ClosedCaptionStatus {
	PENDING = 0,
	ACTIVE = 1,
	INACTIVE = 2,
	DELETED = 3,
	ERRONEOUS = 5,
};

export enum ClosedCaptionType {
	LOGO = 1,
	CLOSED_CAPTION = 2,
	LOCATION = 4,
	PERSON = 5,
	ORG = 6,
	ORGANIZATION = 6,
	WORK_OF_ART = 7,
	CONSUMER_GOOD = 8,
	PRODUCT = 8,
	PHONE_NUMBER = 9,
	ADDRESS = 10,
	DATE = 11,
	NUMBER = 12,
	CARDINAL = 12,
	PRICE = 13,
	MONEY = 13,
	TITLE = 14,
	CREDIT = 15,
	SPEAKER = 16,
	NAME = 17,
	BACKGROUND = 18,
	FRAME = 19,
	WATERMARK = 20,
	ORDINAL = 21,
	NORP = 22,
	FAC = 23,
	GPE = 24,
	LOC = 25,
	EVENT = 26,
	LAW = 27,
	LANGUAGE = 28,
	TIME = 29,
	PERCENT = 30,
	QUANTITY = 31,
	INTRO = 32,
	OUTRO = 33,
	TRANSITION = 34,
};

export interface IClosedCaption extends ICuePoint {
	words?: Word[] | null;
	text?: string | null;
	channelTag?: string | null;
}

export interface IClosedCaptionProperties extends ICuePointProperties {
	words?: IWordProperties;
	text?: boolean;
	channelTag?: boolean;
}

export interface IClosedCaptionFilter extends ICuePointFilter {
}

export class ClosedCaption<IType extends IClosedCaption = IClosedCaption, ITypeProperties extends IClosedCaptionProperties = IClosedCaptionProperties> extends CuePoint<IType, ITypeProperties> implements IClosedCaption {
	
	static className = 'ClosedCaption';
	static NAME = 'closedCaption';
	static PKS = [
		'sequenceSid',
		'sid',
	];
	static PROPERTIES = {...CuePoint.PROPERTIES, 
		words: Word.PROPERTIES,
		text: true,
		channelTag: true,
	};
	
	/**
	 * @deprecated use ClosedCaptionStatus instead
	 */
	static STATUS: any = {
		PENDING: ClosedCaptionStatus.PENDING,
		ACTIVE: ClosedCaptionStatus.ACTIVE,
		INACTIVE: ClosedCaptionStatus.INACTIVE,
		DELETED: ClosedCaptionStatus.DELETED,
		ERRONEOUS: ClosedCaptionStatus.ERRONEOUS,
	};
	
	/**
	 * @deprecated use ClosedCaptionType instead
	 */
	static TYPE: any = {
		LOGO: ClosedCaptionType.LOGO,
		CLOSED_CAPTION: ClosedCaptionType.CLOSED_CAPTION,
		LOCATION: ClosedCaptionType.LOCATION,
		PERSON: ClosedCaptionType.PERSON,
		ORG: ClosedCaptionType.ORG,
		ORGANIZATION: ClosedCaptionType.ORGANIZATION,
		WORK_OF_ART: ClosedCaptionType.WORK_OF_ART,
		CONSUMER_GOOD: ClosedCaptionType.CONSUMER_GOOD,
		PRODUCT: ClosedCaptionType.PRODUCT,
		PHONE_NUMBER: ClosedCaptionType.PHONE_NUMBER,
		ADDRESS: ClosedCaptionType.ADDRESS,
		DATE: ClosedCaptionType.DATE,
		NUMBER: ClosedCaptionType.NUMBER,
		CARDINAL: ClosedCaptionType.CARDINAL,
		PRICE: ClosedCaptionType.PRICE,
		MONEY: ClosedCaptionType.MONEY,
		TITLE: ClosedCaptionType.TITLE,
		CREDIT: ClosedCaptionType.CREDIT,
		SPEAKER: ClosedCaptionType.SPEAKER,
		NAME: ClosedCaptionType.NAME,
		BACKGROUND: ClosedCaptionType.BACKGROUND,
		FRAME: ClosedCaptionType.FRAME,
		WATERMARK: ClosedCaptionType.WATERMARK,
		ORDINAL: ClosedCaptionType.ORDINAL,
		NORP: ClosedCaptionType.NORP,
		FAC: ClosedCaptionType.FAC,
		GPE: ClosedCaptionType.GPE,
		LOC: ClosedCaptionType.LOC,
		EVENT: ClosedCaptionType.EVENT,
		LAW: ClosedCaptionType.LAW,
		LANGUAGE: ClosedCaptionType.LANGUAGE,
		TIME: ClosedCaptionType.TIME,
		PERCENT: ClosedCaptionType.PERCENT,
		QUANTITY: ClosedCaptionType.QUANTITY,
		INTRO: ClosedCaptionType.INTRO,
		OUTRO: ClosedCaptionType.OUTRO,
		TRANSITION: ClosedCaptionType.TRANSITION,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		if(values.words && values.words.length) {
			values.words = values.words.map(item => Base.asType<IWord>(item, Word, this, 'words') as Word);
		}
		return super.set(values, parent, name, ignoreModified);
	}
	
	get words(): Word[] | null | undefined {
		return this.values.words
	}
			
	set words(value: Word[] | null | undefined) {
		if(this.values.words !== value) {
			this.propertyChanged('words', value);
			this.values.words = value;
		}
	}
			
	get text(): string | null | undefined {
		return this.values.text
	}
			
	set text(value: string | null | undefined) {
		if(this.values.text !== value) {
			this.propertyChanged('text', value);
			this.values.text = value;
		}
	}
			
	get channelTag(): string | null | undefined {
		return this.values.channelTag
	}
			
	set channelTag(value: string | null | undefined) {
		if(this.values.channelTag !== value) {
			this.propertyChanged('channelTag', value);
			this.values.channelTag = value;
		}
	}
			
	
	static get<IType extends IClosedCaption = IClosedCaption>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IClosedCaptionFilter = IClosedCaptionFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IClosedCaptionFilter = IClosedCaptionFilter, IType extends IClosedCaption = IClosedCaption>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
	
	static async listWords(sequenceSid: string, chapterSid: string, properties: IBaseProperties | null = null): Promise<Array<Word> | null> {
		properties = (properties || Word.PROPERTIES) as IBaseProperties;
		const query = `query{
			closedCaption {
				listWords(sequenceSid: ${sequenceSid !== undefined ? `${JSON.stringify(sequenceSid)}` : 'null'}, chapterSid: ${chapterSid !== undefined ? `${JSON.stringify(chapterSid)}` : 'null'}){${Base.toProperties(properties)}}
			}
		}`;
		var data = await Base.request(query);
		data = data.closedCaption || null;
		if(data && data.listWords !== undefined && data.listWords !== null) {
			return data.listWords.map((item: IWord) => new Word(item.sid as string).set(item));
		}
		return null;
	}
	
	static async updateWords(sequenceSid: string, words: Array<Word>): Promise<boolean | null> {
		const query = `mutation{
			closedCaption {
				updateWords(sequenceSid: ${sequenceSid !== undefined ? `${JSON.stringify(sequenceSid)}` : 'null'}, words: ${words !== undefined ? `[${words.map(v => `{${v.changedValues()}}`).join(', ')}]` : 'null'})
			}
		}`;
		var data = await Base.request(query);
		data = data.closedCaption || null;
		if(data && data.updateWords !== undefined && data.updateWords !== null) {
			return data.updateWords;
		}
		return null;
	}
	
	static async deleteWords(sequenceSid: string, words: Array<Word>): Promise<boolean | null> {
		const query = `mutation{
			closedCaption {
				deleteWords(sequenceSid: ${sequenceSid !== undefined ? `${JSON.stringify(sequenceSid)}` : 'null'}, words: ${words !== undefined ? `[${words.map(v => `{${v.changedValues()}}`).join(', ')}]` : 'null'})
			}
		}`;
		var data = await Base.request(query);
		data = data.closedCaption || null;
		if(data && data.deleteWords !== undefined && data.deleteWords !== null) {
			return data.deleteWords;
		}
		return null;
	}
}
