
import * as Base from './base/graphicAnimation';


export interface IGraphicAnimation extends Base.IGraphicAnimation {

}

export interface IGraphicAnimationProperties extends Base.IGraphicAnimationProperties {

}

export interface IGraphicAnimationFilter extends Base.IGraphicAnimationFilter {

}

export default class GraphicAnimation<IType extends IGraphicAnimation = IGraphicAnimation, ITypeProperties extends IGraphicAnimationProperties = IGraphicAnimationProperties> extends Base.GraphicAnimation<IType, ITypeProperties> implements IGraphicAnimation {
}