
import * as Base from './base/picture';


export interface IPicture extends Base.IPicture {

}

export interface IPictureProperties extends Base.IPictureProperties {

}

export interface IPictureFilter extends Base.IPictureFilter {

}

export default class Picture<IType extends IPicture = IPicture, ITypeProperties extends IPictureProperties = IPictureProperties> extends Base.Picture<IType, ITypeProperties> implements IPicture {
}