
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'

export enum StyleVerticalAlign {
	TOP = 1,
	MIDDLE = 2,
	BOTTOM = 3,
};

export enum StyleStyleType {
	ROUND_BOX = 1,
	STROKE = 2,
	TRANSPARENT_BOX = 3,
	TRANSPARENT = 4,
	SHADOW = 5,
	SHADOW_STROKE = 6,
	BOX = 7,
	FULL_BOX = 8,
};

export enum StyleCaptionsCaseType {
	DEFAULT = 1,
	UPPERCASE = 2,
	LOWERCASE = 3,
};

export interface IStyle extends IBase {
	fontAssetSid?: string | null;
	captionsFontAssetSid?: string | null;
	introLogoAssetSid?: string | null;
	outroLogoAssetSid?: string | null;
	captionsRegularFileName?: string | null;
	captionsFontFamily?: string | null;
	textAssetSid?: string | null;
	slideAssetSid?: string | null;
	imageAssetSid?: string | null;
	videoAssetSid?: string | null;
	iconAssetSid?: string | null;
	speakerAssetSid?: string | null;
	introAssetSid?: string | null;
	outroAssetSid?: string | null;
	transitionAssetSid?: string | null;
	frameAssetSid?: string | null;
	visualPackageSid?: string | null;
	backgroundOpacity?: number | null;
	fontSize?: number | null;
	fontWeight?: string | null;
	fontItalic?: boolean | null;
	fontFamily?: string | null;
	padding?: number[] | null;
	outlineWidth?: number | null;
	outlineStyle?: string | null;
	borderWidth?: number | null;
	borderStyle?: string | null;
	backgroundColor?: string | null;
	outlineColor?: string | null;
	borderColor?: string | null;
	foregroundColor?: string | null;
	imagesStyleName?: string | null;
	headerFontFamily?: string | null;
	headerFontAssetSid?: string | null;
	headerBoldFileName?: string | null;
	headerRegularFileName?: string | null;
	bodyBoldFileName?: string | null;
	bodyRegularFileName?: string | null;
	captionsVerticalAlign?: number | null;
	captionsStyleType?: number | null;
	captionsCaseType?: number | null;
	captionFormatting?: string | null;
	captionsFontColor?: string | null;
	captionsFontColorIndex?: number | null;
	captionsStyleColor?: string | null;
	captionsStyleColorIndex?: number | null;
	captionsPosition?: number | null;
	depth?: number | null;
	layers?: number | null;
	perspective?: number | null;
	fade?: boolean | null;
	direction?: string | null;
	depthColor?: string | null;
	highlightColorIndex?: number | null;
	framePositionX?: number | null;
	framePositionY?: number | null;
	frameWidth?: number | null;
	captionsFontSize?: number | null;
}

export interface IStyleProperties extends IBaseProperties {
	fontAssetSid?: boolean;
	captionsFontAssetSid?: boolean;
	introLogoAssetSid?: boolean;
	outroLogoAssetSid?: boolean;
	captionsRegularFileName?: boolean;
	captionsFontFamily?: boolean;
	textAssetSid?: boolean;
	slideAssetSid?: boolean;
	imageAssetSid?: boolean;
	videoAssetSid?: boolean;
	iconAssetSid?: boolean;
	speakerAssetSid?: boolean;
	introAssetSid?: boolean;
	outroAssetSid?: boolean;
	transitionAssetSid?: boolean;
	frameAssetSid?: boolean;
	visualPackageSid?: boolean;
	backgroundOpacity?: boolean;
	fontSize?: boolean;
	fontWeight?: boolean;
	fontItalic?: boolean;
	fontFamily?: boolean;
	padding?: boolean;
	outlineWidth?: boolean;
	outlineStyle?: boolean;
	borderWidth?: boolean;
	borderStyle?: boolean;
	backgroundColor?: boolean;
	outlineColor?: boolean;
	borderColor?: boolean;
	foregroundColor?: boolean;
	imagesStyleName?: boolean;
	headerFontFamily?: boolean;
	headerFontAssetSid?: boolean;
	headerBoldFileName?: boolean;
	headerRegularFileName?: boolean;
	bodyBoldFileName?: boolean;
	bodyRegularFileName?: boolean;
	captionsVerticalAlign?: boolean;
	captionsStyleType?: boolean;
	captionsCaseType?: boolean;
	captionFormatting?: boolean;
	captionsFontColor?: boolean;
	captionsFontColorIndex?: boolean;
	captionsStyleColor?: boolean;
	captionsStyleColorIndex?: boolean;
	captionsPosition?: boolean;
	depth?: boolean;
	layers?: boolean;
	perspective?: boolean;
	fade?: boolean;
	direction?: boolean;
	depthColor?: boolean;
	highlightColorIndex?: boolean;
	framePositionX?: boolean;
	framePositionY?: boolean;
	frameWidth?: boolean;
	captionsFontSize?: boolean;
}

export interface IStyleFilter extends IBaseFilter {
}

export class Style<IType extends IStyle = IStyle, ITypeProperties extends IStyleProperties = IStyleProperties> extends Base<IType, ITypeProperties> implements IStyle {
	
	static className = 'Style';
	static NAME = 'style';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		fontAssetSid: true,
		captionsFontAssetSid: true,
		introLogoAssetSid: true,
		outroLogoAssetSid: true,
		captionsRegularFileName: true,
		captionsFontFamily: true,
		textAssetSid: true,
		slideAssetSid: true,
		imageAssetSid: true,
		videoAssetSid: true,
		iconAssetSid: true,
		speakerAssetSid: true,
		introAssetSid: true,
		outroAssetSid: true,
		transitionAssetSid: true,
		frameAssetSid: true,
		visualPackageSid: true,
		backgroundOpacity: true,
		fontSize: true,
		fontWeight: true,
		fontItalic: true,
		fontFamily: true,
		padding: true,
		outlineWidth: true,
		outlineStyle: true,
		borderWidth: true,
		borderStyle: true,
		backgroundColor: true,
		outlineColor: true,
		borderColor: true,
		foregroundColor: true,
		imagesStyleName: true,
		headerFontFamily: true,
		headerFontAssetSid: true,
		headerBoldFileName: true,
		headerRegularFileName: true,
		bodyBoldFileName: true,
		bodyRegularFileName: true,
		captionsVerticalAlign: true,
		captionsStyleType: true,
		captionsCaseType: true,
		captionFormatting: true,
		captionsFontColor: true,
		captionsFontColorIndex: true,
		captionsStyleColor: true,
		captionsStyleColorIndex: true,
		captionsPosition: true,
		depth: true,
		layers: true,
		perspective: true,
		fade: true,
		direction: true,
		depthColor: true,
		highlightColorIndex: true,
		framePositionX: true,
		framePositionY: true,
		frameWidth: true,
		captionsFontSize: true,
	};
	
	/**
	 * @deprecated use StyleVerticalAlign instead
	 */
	static VERTICAL_ALIGN: any = {
		TOP: StyleVerticalAlign.TOP,
		MIDDLE: StyleVerticalAlign.MIDDLE,
		BOTTOM: StyleVerticalAlign.BOTTOM,
	};
	
	/**
	 * @deprecated use StyleStyleType instead
	 */
	static STYLE_TYPE: any = {
		ROUND_BOX: StyleStyleType.ROUND_BOX,
		STROKE: StyleStyleType.STROKE,
		TRANSPARENT_BOX: StyleStyleType.TRANSPARENT_BOX,
		TRANSPARENT: StyleStyleType.TRANSPARENT,
		SHADOW: StyleStyleType.SHADOW,
		SHADOW_STROKE: StyleStyleType.SHADOW_STROKE,
		BOX: StyleStyleType.BOX,
	};
	
	/**
	 * @deprecated use StyleCaptionsCaseType instead
	 */
	static CAPTIONS_CASE_TYPE: any = {
		DEFAULT: StyleCaptionsCaseType.DEFAULT,
		UPPERCASE: StyleCaptionsCaseType.UPPERCASE,
		LOWERCASE: StyleCaptionsCaseType.LOWERCASE,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get fontAssetSid(): string | null | undefined {
		return this.values.fontAssetSid
	}
			
	set fontAssetSid(value: string | null | undefined) {
		if(this.values.fontAssetSid !== value) {
			this.propertyChanged('fontAssetSid', value);
			this.values.fontAssetSid = value;
		}
	}
			
	get captionsFontAssetSid(): string | null | undefined {
		return this.values.captionsFontAssetSid
	}
			
	set captionsFontAssetSid(value: string | null | undefined) {
		if(this.values.captionsFontAssetSid !== value) {
			this.propertyChanged('captionsFontAssetSid', value);
			this.values.captionsFontAssetSid = value;
		}
	}
			
	get introLogoAssetSid(): string | null | undefined {
		return this.values.introLogoAssetSid
	}
			
	set introLogoAssetSid(value: string | null | undefined) {
		if(this.values.introLogoAssetSid !== value) {
			this.propertyChanged('introLogoAssetSid', value);
			this.values.introLogoAssetSid = value;
		}
	}
			
	get outroLogoAssetSid(): string | null | undefined {
		return this.values.outroLogoAssetSid
	}
			
	set outroLogoAssetSid(value: string | null | undefined) {
		if(this.values.outroLogoAssetSid !== value) {
			this.propertyChanged('outroLogoAssetSid', value);
			this.values.outroLogoAssetSid = value;
		}
	}
			
	get captionsRegularFileName(): string | null | undefined {
		return this.values.captionsRegularFileName
	}
			
	set captionsRegularFileName(value: string | null | undefined) {
		if(this.values.captionsRegularFileName !== value) {
			this.propertyChanged('captionsRegularFileName', value);
			this.values.captionsRegularFileName = value;
		}
	}
			
	get captionsFontFamily(): string | null | undefined {
		return this.values.captionsFontFamily
	}
			
	set captionsFontFamily(value: string | null | undefined) {
		if(this.values.captionsFontFamily !== value) {
			this.propertyChanged('captionsFontFamily', value);
			this.values.captionsFontFamily = value;
		}
	}
			
	get textAssetSid(): string | null | undefined {
		return this.values.textAssetSid
	}
			
	set textAssetSid(value: string | null | undefined) {
		if(this.values.textAssetSid !== value) {
			this.propertyChanged('textAssetSid', value);
			this.values.textAssetSid = value;
		}
	}
			
	get slideAssetSid(): string | null | undefined {
		return this.values.slideAssetSid
	}
			
	set slideAssetSid(value: string | null | undefined) {
		if(this.values.slideAssetSid !== value) {
			this.propertyChanged('slideAssetSid', value);
			this.values.slideAssetSid = value;
		}
	}
			
	get imageAssetSid(): string | null | undefined {
		return this.values.imageAssetSid
	}
			
	set imageAssetSid(value: string | null | undefined) {
		if(this.values.imageAssetSid !== value) {
			this.propertyChanged('imageAssetSid', value);
			this.values.imageAssetSid = value;
		}
	}
			
	get videoAssetSid(): string | null | undefined {
		return this.values.videoAssetSid
	}
			
	set videoAssetSid(value: string | null | undefined) {
		if(this.values.videoAssetSid !== value) {
			this.propertyChanged('videoAssetSid', value);
			this.values.videoAssetSid = value;
		}
	}
			
	get iconAssetSid(): string | null | undefined {
		return this.values.iconAssetSid
	}
			
	set iconAssetSid(value: string | null | undefined) {
		if(this.values.iconAssetSid !== value) {
			this.propertyChanged('iconAssetSid', value);
			this.values.iconAssetSid = value;
		}
	}
			
	get speakerAssetSid(): string | null | undefined {
		return this.values.speakerAssetSid
	}
			
	set speakerAssetSid(value: string | null | undefined) {
		if(this.values.speakerAssetSid !== value) {
			this.propertyChanged('speakerAssetSid', value);
			this.values.speakerAssetSid = value;
		}
	}
			
	get introAssetSid(): string | null | undefined {
		return this.values.introAssetSid
	}
			
	set introAssetSid(value: string | null | undefined) {
		if(this.values.introAssetSid !== value) {
			this.propertyChanged('introAssetSid', value);
			this.values.introAssetSid = value;
		}
	}
			
	get outroAssetSid(): string | null | undefined {
		return this.values.outroAssetSid
	}
			
	set outroAssetSid(value: string | null | undefined) {
		if(this.values.outroAssetSid !== value) {
			this.propertyChanged('outroAssetSid', value);
			this.values.outroAssetSid = value;
		}
	}
			
	get transitionAssetSid(): string | null | undefined {
		return this.values.transitionAssetSid
	}
			
	set transitionAssetSid(value: string | null | undefined) {
		if(this.values.transitionAssetSid !== value) {
			this.propertyChanged('transitionAssetSid', value);
			this.values.transitionAssetSid = value;
		}
	}
			
	get frameAssetSid(): string | null | undefined {
		return this.values.frameAssetSid
	}
			
	set frameAssetSid(value: string | null | undefined) {
		if(this.values.frameAssetSid !== value) {
			this.propertyChanged('frameAssetSid', value);
			this.values.frameAssetSid = value;
		}
	}
			
	get visualPackageSid(): string | null | undefined {
		return this.values.visualPackageSid
	}
			
	set visualPackageSid(value: string | null | undefined) {
		if(this.values.visualPackageSid !== value) {
			this.propertyChanged('visualPackageSid', value);
			this.values.visualPackageSid = value;
		}
	}
			
	get backgroundOpacity(): number | null | undefined {
		return this.values.backgroundOpacity
	}
			
	set backgroundOpacity(value: number | null | undefined) {
		if(this.values.backgroundOpacity !== value) {
			this.propertyChanged('backgroundOpacity', value);
			this.values.backgroundOpacity = value;
		}
	}
			
	get fontSize(): number | null | undefined {
		return this.values.fontSize
	}
			
	set fontSize(value: number | null | undefined) {
		if(this.values.fontSize !== value) {
			this.propertyChanged('fontSize', value);
			this.values.fontSize = value;
		}
	}
			
	get fontWeight(): string | null | undefined {
		return this.values.fontWeight
	}
			
	set fontWeight(value: string | null | undefined) {
		if(this.values.fontWeight !== value) {
			this.propertyChanged('fontWeight', value);
			this.values.fontWeight = value;
		}
	}
			
	get fontItalic(): boolean | null | undefined {
		return this.values.fontItalic
	}
			
	set fontItalic(value: boolean | null | undefined) {
		if(this.values.fontItalic !== value) {
			this.propertyChanged('fontItalic', value);
			this.values.fontItalic = value;
		}
	}
			
	get fontFamily(): string | null | undefined {
		return this.values.fontFamily
	}
			
	set fontFamily(value: string | null | undefined) {
		if(this.values.fontFamily !== value) {
			this.propertyChanged('fontFamily', value);
			this.values.fontFamily = value;
		}
	}
			
	get padding(): number[] | null | undefined {
		return this.values.padding
	}
			
	set padding(value: number[] | null | undefined) {
		if(this.values.padding !== value) {
			this.propertyChanged('padding', value);
			this.values.padding = value;
		}
	}
			
	get outlineWidth(): number | null | undefined {
		return this.values.outlineWidth
	}
			
	set outlineWidth(value: number | null | undefined) {
		if(this.values.outlineWidth !== value) {
			this.propertyChanged('outlineWidth', value);
			this.values.outlineWidth = value;
		}
	}
			
	get outlineStyle(): string | null | undefined {
		return this.values.outlineStyle
	}
			
	set outlineStyle(value: string | null | undefined) {
		if(this.values.outlineStyle !== value) {
			this.propertyChanged('outlineStyle', value);
			this.values.outlineStyle = value;
		}
	}
			
	get borderWidth(): number | null | undefined {
		return this.values.borderWidth
	}
			
	set borderWidth(value: number | null | undefined) {
		if(this.values.borderWidth !== value) {
			this.propertyChanged('borderWidth', value);
			this.values.borderWidth = value;
		}
	}
			
	get borderStyle(): string | null | undefined {
		return this.values.borderStyle
	}
			
	set borderStyle(value: string | null | undefined) {
		if(this.values.borderStyle !== value) {
			this.propertyChanged('borderStyle', value);
			this.values.borderStyle = value;
		}
	}
			
	get backgroundColor(): string | null | undefined {
		return this.values.backgroundColor
	}
			
	set backgroundColor(value: string | null | undefined) {
		if(this.values.backgroundColor !== value) {
			this.propertyChanged('backgroundColor', value);
			this.values.backgroundColor = value;
		}
	}
			
	get outlineColor(): string | null | undefined {
		return this.values.outlineColor
	}
			
	set outlineColor(value: string | null | undefined) {
		if(this.values.outlineColor !== value) {
			this.propertyChanged('outlineColor', value);
			this.values.outlineColor = value;
		}
	}
			
	get borderColor(): string | null | undefined {
		return this.values.borderColor
	}
			
	set borderColor(value: string | null | undefined) {
		if(this.values.borderColor !== value) {
			this.propertyChanged('borderColor', value);
			this.values.borderColor = value;
		}
	}
			
	get foregroundColor(): string | null | undefined {
		return this.values.foregroundColor
	}
			
	set foregroundColor(value: string | null | undefined) {
		if(this.values.foregroundColor !== value) {
			this.propertyChanged('foregroundColor', value);
			this.values.foregroundColor = value;
		}
	}
			
	get imagesStyleName(): string | null | undefined {
		return this.values.imagesStyleName
	}
			
	set imagesStyleName(value: string | null | undefined) {
		if(this.values.imagesStyleName !== value) {
			this.propertyChanged('imagesStyleName', value);
			this.values.imagesStyleName = value;
		}
	}
			
	get headerFontFamily(): string | null | undefined {
		return this.values.headerFontFamily
	}
			
	set headerFontFamily(value: string | null | undefined) {
		if(this.values.headerFontFamily !== value) {
			this.propertyChanged('headerFontFamily', value);
			this.values.headerFontFamily = value;
		}
	}
			
	get headerFontAssetSid(): string | null | undefined {
		return this.values.headerFontAssetSid
	}
			
	set headerFontAssetSid(value: string | null | undefined) {
		if(this.values.headerFontAssetSid !== value) {
			this.propertyChanged('headerFontAssetSid', value);
			this.values.headerFontAssetSid = value;
		}
	}
			
	get headerBoldFileName(): string | null | undefined {
		return this.values.headerBoldFileName
	}
			
	set headerBoldFileName(value: string | null | undefined) {
		if(this.values.headerBoldFileName !== value) {
			this.propertyChanged('headerBoldFileName', value);
			this.values.headerBoldFileName = value;
		}
	}
			
	get headerRegularFileName(): string | null | undefined {
		return this.values.headerRegularFileName
	}
			
	set headerRegularFileName(value: string | null | undefined) {
		if(this.values.headerRegularFileName !== value) {
			this.propertyChanged('headerRegularFileName', value);
			this.values.headerRegularFileName = value;
		}
	}
			
	get bodyBoldFileName(): string | null | undefined {
		return this.values.bodyBoldFileName
	}
			
	set bodyBoldFileName(value: string | null | undefined) {
		if(this.values.bodyBoldFileName !== value) {
			this.propertyChanged('bodyBoldFileName', value);
			this.values.bodyBoldFileName = value;
		}
	}
			
	get bodyRegularFileName(): string | null | undefined {
		return this.values.bodyRegularFileName
	}
			
	set bodyRegularFileName(value: string | null | undefined) {
		if(this.values.bodyRegularFileName !== value) {
			this.propertyChanged('bodyRegularFileName', value);
			this.values.bodyRegularFileName = value;
		}
	}
			
	get captionsVerticalAlign(): number | null | undefined {
		return this.values.captionsVerticalAlign
	}
			
	set captionsVerticalAlign(value: number | null | undefined) {
		if(this.values.captionsVerticalAlign !== value) {
			this.propertyChanged('captionsVerticalAlign', value);
			this.values.captionsVerticalAlign = value;
		}
	}
			
	get captionsStyleType(): number | null | undefined {
		return this.values.captionsStyleType
	}
			
	set captionsStyleType(value: number | null | undefined) {
		if(this.values.captionsStyleType !== value) {
			this.propertyChanged('captionsStyleType', value);
			this.values.captionsStyleType = value;
		}
	}
			
	get captionsCaseType(): number | null | undefined {
		return this.values.captionsCaseType
	}
			
	set captionsCaseType(value: number | null | undefined) {
		if(this.values.captionsCaseType !== value) {
			this.propertyChanged('captionsCaseType', value);
			this.values.captionsCaseType = value;
		}
	}
			
	get captionFormatting(): string | null | undefined {
		return this.values.captionFormatting
	}
			
	set captionFormatting(value: string | null | undefined) {
		if(this.values.captionFormatting !== value) {
			this.propertyChanged('captionFormatting', value);
			this.values.captionFormatting = value;
		}
	}
			
	get captionsFontColor(): string | null | undefined {
		return this.values.captionsFontColor
	}
			
	set captionsFontColor(value: string | null | undefined) {
		if(this.values.captionsFontColor !== value) {
			this.propertyChanged('captionsFontColor', value);
			this.values.captionsFontColor = value;
		}
	}
			
	get captionsFontColorIndex(): number | null | undefined {
		return this.values.captionsFontColorIndex
	}
			
	set captionsFontColorIndex(value: number | null | undefined) {
		if(this.values.captionsFontColorIndex !== value) {
			this.propertyChanged('captionsFontColorIndex', value);
			this.values.captionsFontColorIndex = value;
		}
	}
			
	get captionsStyleColor(): string | null | undefined {
		return this.values.captionsStyleColor
	}
			
	set captionsStyleColor(value: string | null | undefined) {
		if(this.values.captionsStyleColor !== value) {
			this.propertyChanged('captionsStyleColor', value);
			this.values.captionsStyleColor = value;
		}
	}
			
	get captionsStyleColorIndex(): number | null | undefined {
		return this.values.captionsStyleColorIndex
	}
			
	set captionsStyleColorIndex(value: number | null | undefined) {
		if(this.values.captionsStyleColorIndex !== value) {
			this.propertyChanged('captionsStyleColorIndex', value);
			this.values.captionsStyleColorIndex = value;
		}
	}
			
	get captionsPosition(): number | null | undefined {
		return this.values.captionsPosition
	}
			
	set captionsPosition(value: number | null | undefined) {
		if(this.values.captionsPosition !== value) {
			this.propertyChanged('captionsPosition', value);
			this.values.captionsPosition = value;
		}
	}
			
	get depth(): number | null | undefined {
		return this.values.depth
	}
			
	set depth(value: number | null | undefined) {
		if(this.values.depth !== value) {
			this.propertyChanged('depth', value);
			this.values.depth = value;
		}
	}
			
	get layers(): number | null | undefined {
		return this.values.layers
	}
			
	set layers(value: number | null | undefined) {
		if(this.values.layers !== value) {
			this.propertyChanged('layers', value);
			this.values.layers = value;
		}
	}
			
	get perspective(): number | null | undefined {
		return this.values.perspective
	}
			
	set perspective(value: number | null | undefined) {
		if(this.values.perspective !== value) {
			this.propertyChanged('perspective', value);
			this.values.perspective = value;
		}
	}
			
	get fade(): boolean | null | undefined {
		return this.values.fade
	}
			
	set fade(value: boolean | null | undefined) {
		if(this.values.fade !== value) {
			this.propertyChanged('fade', value);
			this.values.fade = value;
		}
	}
			
	get direction(): string | null | undefined {
		return this.values.direction
	}
			
	set direction(value: string | null | undefined) {
		if(this.values.direction !== value) {
			this.propertyChanged('direction', value);
			this.values.direction = value;
		}
	}
			
	get depthColor(): string | null | undefined {
		return this.values.depthColor
	}
			
	set depthColor(value: string | null | undefined) {
		if(this.values.depthColor !== value) {
			this.propertyChanged('depthColor', value);
			this.values.depthColor = value;
		}
	}
			
	get highlightColorIndex(): number | null | undefined {
		return this.values.highlightColorIndex
	}
			
	set highlightColorIndex(value: number | null | undefined) {
		if(this.values.highlightColorIndex !== value) {
			this.propertyChanged('highlightColorIndex', value);
			this.values.highlightColorIndex = value;
		}
	}
			
	get framePositionX(): number | null | undefined {
		return this.values.framePositionX
	}
			
	set framePositionX(value: number | null | undefined) {
		if(this.values.framePositionX !== value) {
			this.propertyChanged('framePositionX', value);
			this.values.framePositionX = value;
		}
	}
			
	get framePositionY(): number | null | undefined {
		return this.values.framePositionY
	}
			
	set framePositionY(value: number | null | undefined) {
		if(this.values.framePositionY !== value) {
			this.propertyChanged('framePositionY', value);
			this.values.framePositionY = value;
		}
	}
			
	get frameWidth(): number | null | undefined {
		return this.values.frameWidth
	}
			
	set frameWidth(value: number | null | undefined) {
		if(this.values.frameWidth !== value) {
			this.propertyChanged('frameWidth', value);
			this.values.frameWidth = value;
		}
	}
			
	get captionsFontSize(): number | null | undefined {
		return this.values.captionsFontSize
	}
			
	set captionsFontSize(value: number | null | undefined) {
		if(this.values.captionsFontSize !== value) {
			this.propertyChanged('captionsFontSize', value);
			this.values.captionsFontSize = value;
		}
	}
			
	
	static get<IType extends IStyle = IStyle>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IStyleFilter = IStyleFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IStyleFilter = IStyleFilter, IType extends IStyle = IStyle>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
