import React from "react";
import '../../../css/styles.scss'
import SVG from '../../components/svg'
import {downloadPendingURL} from '../../state/sequence'
import styled from "styled-components";
import {Fader} from "../../ui-components/Fader/Fader";
import { AppContext } from "../../lib/app-context";

const HighlightLink = styled.a`
  text-decoration-color: #EA429D;
  text-decoration-thickness: from-font;
  color: ${props => props.color};
  font-weight: 700;
`;

const Title = styled.div`
  margin: 16px 0px 16px 0px;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  text-wrap: balance;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 18px;
  }
`;

const Paragraph = styled.div`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  text-wrap: balance;
`;

export const DownloadReady = () => {
    const downloadUrl = downloadPendingURL.use();
    const { isMobileDevice } = React.useContext(AppContext);
    return (
        <>
                <SVG name="handes-clap" className="handes-clap" width={isMobileDevice? "90" : "100"} height={isMobileDevice? "90" : "100"} viewBox="0 0 115 120"></SVG>

                <Title>Your Video Is Ready!</Title>

                <Paragraph>
                    Your download should have started automatically.
                    If it hasn't, please <HighlightLink color={'#EA429D'} href={downloadUrl} download>
                            Click Here
                        </HighlightLink> to begin downloading.
                </Paragraph>

        </>
    )
}
