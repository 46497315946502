import { entity } from 'simpler-state';
import Asset, { AssetType } from '../client/asset';
import FolderStructure, { FolderStructureFolderType } from '../client/folderStructure';


export const visualPackagesListState = entity({});
export const lottiesPreviewListState = entity({});
export const sortedPackagesSidsState = entity([]);
export const packagesLoadingState = entity(false);
export const lottiesLoadingState = entity(false);

export async function getVisPackagesList(user) {

    try {
        packagesLoadingState.set(true);
        FolderStructure.list({
            typeEqual: FolderStructureFolderType.LOTTIE_PACKAGE
        }).then(res => {
            res.sort((a, b) => {
                if(a.userSid === user.orgSid && b.userSid !== user.orgSid) {
                    return -1;
                }  else {
                    return b.insertTime - a.insertTime;
                }
            });

            let arrSids = [];
            let obj = {};
            res.forEach(el => {
                arrSids.push(el.sid);
                obj[el.sid] = el;
            })
            visualPackagesListState.set(obj);
            sortedPackagesSidsState.set(arrSids);
            packagesLoadingState.set(false);
        });
    } catch (error) {
        console.log("getVisPackagesList ", error);
        packagesLoadingState.set(false);
    }



}

export async function getLottiesPreviewList() {
    try {
        lottiesLoadingState.set(true);
        Asset.list({
            typeEqual: AssetType.LOTTIE_PACKAGE_PREVIEW,
        }).then(res => {
            let obj = {};
            res.forEach(el => {
                obj[el.folderSid] = el;
            })
            lottiesPreviewListState.set(obj);
            lottiesLoadingState.set(false);
        })
    } catch (error) {
        console.log("getLottiesPreviewList ", error);
        lottiesLoadingState.set(false);
    }

   
}