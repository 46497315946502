import _ from 'lodash';
import React, { Fragment } from 'react';
import Form from 'react-bootstrap/Form';
import { goBackIcon } from '../../../static/svg/musicMenuIcons';
import { FeatureFeatureName } from '../../client/base/feature';
import { openPeechProUpgradeModal } from '../../components/layout';
import Upgrade from '../../dialogs/upgrade';
import { AppContext } from '../../lib/app-context';
import {
  lastSearchedMusicTagState,
  menuHeaderTooltipTextState,
  shouldDisplayDenoiseState,
  shouldEnableDenoiseState,
  shouldEnableOverrideVolumeState,
  shouldShowMusicLibraryState,
} from '../../state/menu';
import { musicBackgroundInProcess, sequenceSave, sequenceState } from '../../state/sequence';
import {
  FakeHighLightToolTip,
  FakeTooltip,
  IconWrapper,
  MenuHeaderWrapper,
  MenuHeaderWrapperWithSwitch, StyledLabel,
  TabsWrapper,
} from '../../ui-components/Menu/MenuComponents.styled';
import { trackEvent } from '../../utils/analityics.utils';
import applySelectedMusic from '../../utils/music.utils';
import SVG from '../svg';
import AudioTrackController from './AudioTrackController';
import ChangeTrackController from './ChangeTrackController';
import MusicLibraryList from './MusicLibraryList';
import {
  ActionsDiv,
  AudioContainer,
  DenoiseDiv,
  DenoiseWrapper,
  DiamondContiner,
  MusicLibraryFooter,
  MusicLibraryHeader,
  MusicMenuButton,
  MusicMenuContainer,
  MusicMenuWrapper,
  StockLibraryContainer,
} from './MusicMenu.styled';
import SearchingController from './SearchingController';
import VolumeController from './VolumeController';
import Sequence from '../../client/sequence';
import Tabs from '../../ui-components/Tabs/Tabs';
import styled from "@emotion/styled";
import {InfoPopup} from "../infoPopup";

export const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 6px;
  font-family: 'Open Sans',sans-serif;
`;

const FakeHighLightToolTipContainer = styled.div`
margin-bottom: 10px;
`;

export const MUSIC_SEARCH_TAGS = ['Corporate', 'Inspirational', 'Uplifting', 'Technology', 'Upbeat'];

interface ProjectAudioProperties {}

export default function ProjectAudio({}: ProjectAudioProperties) {
  const { user, plan } = React.useContext(AppContext);
  const menuHeaderTooltipText = menuHeaderTooltipTextState.use();
  const shouldShowMusicLibrary = shouldShowMusicLibraryState.use();
  const shouldDisplayDenoise = shouldDisplayDenoiseState.use();
  const shouldEnableDenoise = shouldEnableDenoiseState.use();
  const shouldEnableOverrideVolume = shouldEnableOverrideVolumeState.use();
  const lastSearchedMusicTag = lastSearchedMusicTagState.use();
  const processingMusic = musicBackgroundInProcess.use();
  const sequence = sequenceState.use();
  const [openStockLibrary, setOpenStockLibrary] = React.useState(false);
  const [useDenoised, setUseDenoised] = React.useState(sequence.useDenoised);
  const [useMusic, setUseMusic] = React.useState(sequence.music.enabled);
  const [disableChanges, setDisableChanges] = React.useState(false);
  const [volumeLoading, setVolumeLoading] = React.useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = React.useState(false);
  const [uploading, setUploading] = React.useState(null);
  const [useProgress, setUseProgress] = React.useState(0);
  const [library, setLibrary] = React.useState([]);
  const [currentPlayingTrack, setCurrentPlayingTrack] = React.useState();
  const [disableChangeVolume, setDisableChangeVolume] = React.useState(false);
  const [libraryLoading, setLibraryLoading] = React.useState(false);

  const musicTabRef = React.useRef();
  const audioTabRef = React.useRef();

  const tabsItems = [
    { name: 'Music', value: 'Music', ref: musicTabRef },
    { name: 'Audio', value: 'Audio', ref: audioTabRef },
  ];

  const [activeTab, setActiveTab] = React.useState('Music');

  const playingTrackUrl = React.useMemo(() => {
    return currentPlayingTrack?.url || '';
  }, [currentPlayingTrack]);

  const defaultMusicTag = React.useMemo(() => {
    return MUSIC_SEARCH_TAGS[0];
  }, [MUSIC_SEARCH_TAGS]);

  const allowedMusicLibrary = React.useMemo(
    () => !!plan?.planAllowedTierFeatures?.includes(FeatureFeatureName.MUSIC_LIBRARY),
    [plan]
  );

  React.useEffect(() => {
    setDisableChanges(!useMusic);
  }, [useMusic]);

  React.useEffect(() => {
    if (disableChanges || !shouldEnableOverrideVolume || uploading || volumeLoading) {
      setDisableChangeVolume(true);
    } else {
      setDisableChangeVolume(false);
    }
  }, [disableChanges, shouldEnableOverrideVolume, uploading, volumeLoading]);

  React.useEffect(() => {
    if (!openStockLibrary) {
      setCurrentPlayingTrack(null);
    }
  }, [openStockLibrary]);

  function onUseDenoisedChange() {
    sequence.useDenoised = !useDenoised;
    setUseDenoised(sequence.useDenoised);
    sequenceSave(sequence);

    trackEvent('denoiser', { value: sequence.useDenoised ? 'turnedOn' : 'turnedOff' });
  }

  function onMusicEnableChange() {
    sequence.music.enabled = !useMusic;
    setUseMusic(sequence.music.enabled);
    sequenceSave(sequence);
    trackEvent('music-enable', { music: sequence.music.enabled });
  }

  const onSearchMusicByFilter = React.useCallback(
    async (value) => {
      setCurrentPlayingTrack(null);
      if (!value) {
        value = defaultMusicTag;
      }
      const musicTracks = await Sequence.musics(sequence.sid, value, 100);
      setLibrary(musicTracks);
      setLibraryLoading(false);
    },
    [sequence.sid]
  );

  async function onUsersTrackUpload(track) {
    user.defaultAudioSid = track.sid;
    user.audioAssets.unshift(track);
    const seqCopy = _.cloneDeep(sequence);
    await applySelectedMusic(track, seqCopy);
  }

  async function onLibraryTrackSelected(track) {
    if (!allowedMusicLibrary) {
      openPeechProUpgradeModal(user, 'external music Library', FeatureFeatureName.MUSIC_LIBRARY);
      return;
    }
    const seqCopy = _.cloneDeep(sequence);
    await applySelectedMusic(track, seqCopy);
    trackEvent('track-change', { source: 'external music Library', trackName: track.name });
    setOpenStockLibrary(false);
  }

  React.useEffect(() => {
    onSearchMusicByFilter(lastSearchedMusicTag || defaultMusicTag);
  }, []);

  return (
    <AudioContainer openStockLibrary={openStockLibrary}>
      {/* MAIN MUSIC MENU */}

      {
        <MusicMenuContainer>
          <MenuHeaderWrapperWithSwitch marginBottom={shouldDisplayDenoise ? 26 : 5}>
            <StyledLabel>
              {shouldDisplayDenoise ? 'Music & Audio' : 'Music'}
            </StyledLabel>
            <InfoPopup text={menuHeaderTooltipText} />

          </MenuHeaderWrapperWithSwitch>

          {shouldDisplayDenoise && (
            <TabsWrapper>
              <Tabs tabsItems={tabsItems} onChange={(selectedTab) => setActiveTab(selectedTab)} />
            </TabsWrapper>
          )}
          {activeTab === 'Music' ? (
            <Fragment>
                {processingMusic &&
                  <MenuHeaderWrapper>
                  <FakeHighLightToolTipContainer>
                    <FakeHighLightToolTip>
                      <IconWrapper>
                        <SVG name="info-tooltip" viewBox="0 0 14 14" />
                      </IconWrapper>
                      Good things come to those who wait, music will be added automatically once ready!
                    </FakeHighLightToolTip>
                  </FakeHighLightToolTipContainer>
                </MenuHeaderWrapper>
              }

              <ActionsDiv>
                <Title>Track</Title>
                <Form.Check id="enabled-music" type="switch" checked={!!useMusic} onChange={onMusicEnableChange} />
              </ActionsDiv>

              <MusicMenuWrapper blured={disableChanges}>
                <AudioTrackController uploading={uploading} volumeLoading={volumeLoading} useProgress={useProgress} />

                <ChangeTrackController
                  disabled={false}
                  setOpenStockLibrary={setOpenStockLibrary}
                  setShowUpgradeModal={setShowUpgradeModal}
                  setUploading={setUploading}
                  setUseProgress={setUseProgress}
                  onUsersTrackUpload={onUsersTrackUpload}
                  disableChanges={disableChanges}
                  shouldShowMusicLibrary={shouldShowMusicLibrary}
                />

                {shouldEnableOverrideVolume && (
                  <VolumeController
                    disabled={disableChangeVolume || disableChanges}
                    volumeLoading={volumeLoading}
                    setVolumeLoading={setVolumeLoading}
                  />
                )}
              </MusicMenuWrapper>
            </Fragment>
          ) : (
            <DenoiseWrapper>
              <DenoiseDiv disabled={!shouldEnableDenoise}>
                <Fragment>
                  <span>
                    <SVG name="microphone" />
                    <label>Studio Sound Enhancer</label>
                  </span>

                  <Form.Check
                    id="enabled"
                    htmlFor="use-denoise"
                    type="switch"
                    checked={!!useDenoised}
                    onChange={onUseDenoisedChange}
                    disabled={!shouldEnableDenoise}
                  />
                </Fragment>
              </DenoiseDiv>
              {shouldEnableDenoise && (
                <FakeTooltip>
                  <IconWrapper>
                    <SVG name="info-tooltip" viewBox="0 0 14 14" />
                  </IconWrapper>
                  <span>{'Enhances your audio.\nTurn it on to enjoy richer and clearer sound for your videos.'}</span>
                </FakeTooltip>
              )}
            </DenoiseWrapper>
          )}
        </MusicMenuContainer>
      }

      {/* MUSIC LIBRARY */}

      {
        <StockLibraryContainer openStockLibrary={openStockLibrary}>
          <MusicLibraryHeader>
            <MusicMenuButton disabled={false} onClick={() => setOpenStockLibrary(false)}>
              {goBackIcon}
            </MusicMenuButton>
            Music library
            <DiamondContiner
              onClick={(e) =>
                openPeechProUpgradeModal(user, 'external music Library', FeatureFeatureName.MUSIC_LIBRARY)
              }
            >
              {!allowedMusicLibrary && <SVG name="diamond-gradient" />}
            </DiamondContiner>
          </MusicLibraryHeader>

          <SearchingController onSearchMusicByFilter={onSearchMusicByFilter} setLibraryLoading={setLibraryLoading} />

          <MusicLibraryList
            libraryLoading={libraryLoading}
            library={library}
            currentPlayingTrack={currentPlayingTrack}
            playingTrackUrl={playingTrackUrl}
            setCurrentPlayingTrack={setCurrentPlayingTrack}
            onLibraryTrackSelected={onLibraryTrackSelected}
          />

          <MusicLibraryFooter>
            <FakeTooltip>
              <IconWrapper>
                <SVG name="info-tooltip" viewBox="0 0 14 14" />
              </IconWrapper>
              Music watermark will be removed <br />
              in the downloaded version
            </FakeTooltip>
            POWERED BY SHUTTERSTOCK
          </MusicLibraryFooter>
        </StockLibraryContainer>
      }

      {showUpgradeModal && (
        <Upgrade
          onHide={() => setShowUpgradeModal(false)}
          title="Music Track Upload"
          text="Your account is limited to existing music tracks only. To upload your own music upgrade your account.
				"
        />
      )}
    </AudioContainer>
  );
}
