import { diffChars } from 'diff'
import { entity } from 'simpler-state'
import Base from '../client/base/base'
import ClosedCaption, { Word } from '../client/closedCaption'
import CuePoint, { CuePointTime } from '../client/cuePoint'
import FrameCuePoint from '../client/frameCuePoint'
import GraphicCuePoint from '../client/graphicCuePoint'
import { Chapter } from '../client/sequence'
import SpeakerCuePoint from '../client/speakerCuePoint'
import TitleCuePoint from '../client/titleCuePoint'
import { decodeHtml } from '../components/cue-points/editableTextItem'
import { trackEvent } from '../utils/analityics.utils'
import { handleError } from './error'
import { MENUS, menuState } from './menu'
import { scenesState, sequenceState } from './sequence'



const BRIGHTNESS_THRESHOLD = 200;
const CUEPOINT_INACTIVE_STATUSES = [CuePoint.STATUS.INACTIVE, CuePoint.STATUS.DELETED]

const inRange = (num, max) => num >= 0 && num <= max;

/**
 * @type {Map<string, CuePoint>}
 */
const cuePointsDefault = {}
export const cuePointsState = entity(cuePointsDefault)

export const lockInteractionState = entity(false)

export const isHighlightedState = entity(false)

export const highlightWordListState = entity([])
export const keywordListState = entity([])
export const activeEditCuePointState = entity(null);
/**
 * @type {{
* 	cuePoint: TitleCuePoint,
* 	defaultType: 'icon'|'image'|'video'|'asset'
* 	enableTypes: string[]
* }}
*/
const editCuePointDefault = {}
export const editCuePointState = entity(editCuePointDefault)




export function setHighlightedWords(wordsList, reset) {
	const sequence = sequenceState.get()
	const closedCaptionArr = {}
	let words = []

	if (wordsList.find(w => !w.enabled)) {
		wordsList.forEach(w => closedCaptionArr[w.chapterSid] ? closedCaptionArr[w.chapterSid].push(w) : closedCaptionArr[w.chapterSid] = [w])
		Object.values(closedCaptionArr).forEach(wordsList => {
			wordsList = wordsList.sort((a, b) => a.startTime - b.startTime)
			let csid = wordsList[0].chapterSid
			let cst = wordsList[0].startTime
			let cet = wordsList[wordsList.length - 1].endTime
			let ccp = new ClosedCaption(sequence.sid, uniqueSid()).set({
				status: ClosedCaption.STATUS.ACTIVE,
				type: ClosedCaption.TYPE.CLOSED_CAPTION,
				words: JSON.parse(JSON.stringify(wordsList)),
				sequenceSid: sequence.sid,
				chapterSid: csid,
				startTime: cst,
				endTime: cet,
			})

			const calced = calcCuePointTimes(ccp, sequence, scenesState.get().map, true, true)
			words.push(calced.words)
		})
		words = words.flat()
	} else {
		words = wordsList
	}

	const keywordsToAdd = [],
		highlightedToAdd = [],
		highlightedToRemove = [];

	words.forEach(word => {
		if (word.flags) {
			keywordsToAdd.push(word)
		} else if (word.highlight) {
			highlightedToAdd.push(word)
		} else {
			highlightedToRemove.push(word)
		}
	})

	keywordListState.set(keywords => {
		if(reset) {
			return keywordsToAdd;
		} 
		keywordsToAdd.forEach(word => {
			const existingWord = keywords.find(w => w.word === word.word && w.startTime === word.startTime && w.chapterSid === word.chapterSid)
			if (!existingWord) {
				keywords.push(word)
			} else {
				existingWord.highlight !== word.highlight && (existingWord.highlight = word.highlight)
			}
		})
		return keywords
	})

	highlightWordListState.set(highlightedWords => {
		if(reset) {
			return highlightedToAdd;
		} 
		highlightedToAdd.forEach(word => {
			const existingWord = highlightedWords.find(w => w.word === word.word && w.startTime === word.startTime && w.chapterSid === word.chapterSid)
			if (!existingWord) {
				highlightedWords.push(word)
			} else {
				existingWord.highlight !== word.highlight && (existingWord.highlight = word.highlight)
			}
		})
		return highlightedWords
	})
}


/**
 * @type {Map<string, boolean>}
 */
const onTimeDefault = {}
const closedCaptionOnTimeState = entity({})
export const cuePointsOnTimeState = entity(onTimeDefault)

cuePointsOnTimeState.useCuePoint = cuePoint => {
	if (cuePoint?.type === CuePoint.TYPE.CLOSED_CAPTION) {
		return closedCaptionOnTimeState.use(state => state[cuePoint?.sid])
	}
	else {
		return cuePointsOnTimeState.use(state => state[cuePoint?.sid])
	}
}
/**
* @type {Map<string, string>}
*/
const mediaDefaults = {}
export const mediaState = entity(mediaDefaults)

/**
* @type {Map<string, string>}
*/
export const tempMediaState = entity(mediaDefaults)

export async function setLoadedMedia(url, mediaKey, isTemp) {
	if (url) {
		const res = await fetch(url);
		const blob = await res.blob()
		url = URL.createObjectURL(blob)
	}
	isTemp ? tempMediaState.set(state => ({ ...state, [mediaKey]: url })) : mediaState.set(state => ({ ...state, [mediaKey]: url }))
}

export function resetTempMedia() {
	tempMediaState.set(mediaDefaults)
}

export function tempToLoadedMedia(sid, tempLoadedSrc) {
	tempLoadedSrc && mediaState.set(state => ({ ...state, [sid]: tempLoadedSrc }))
	resetTempMedia()
}



export const cuePointsVersionState = entity(0)

export function incrementCuePointsVersion() {
	cuePointsVersionState.set(version => version + 1)
}
export const CUE_POINT_CLASSES = {
	[GraphicCuePoint.className]: GraphicCuePoint,
	[TitleCuePoint.className]: TitleCuePoint,
	[SpeakerCuePoint.className]: SpeakerCuePoint,
	[FrameCuePoint.className]: FrameCuePoint,
}

export function newInstanceHandler({ sid, sequenceSid, objectType }) {
	var clazz = CUE_POINT_CLASSES[objectType]
	if (!clazz) {
		return console.error('Cue-Point type not found', objectType, 'available types: ', Object.keys(CUE_POINT_CLASSES))
	}
	return new clazz(sequenceSid, sid)
}

export function uniqueSid() {
	return Math.random().toString(36).substring(2)
}

export function colorToRGBA(color, opacity) {
	if (!color) {
		return `rgba(0,0,0,0)`
	}
	var rgb = {
		r: parseInt(color.substr(1, 2), 16),
		g: parseInt(color.substr(3, 2), 16),
		b: parseInt(color.substr(5, 2), 16),
	}
	return `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`
}

function aspectRatioToFloat(aspectRatio) {
	if (aspectRatio.indexOf(':') > 0) {
		const [w, h] = aspectRatio.split(':');
		return w / h;
	}
	return parseFloat(aspectRatio)
}

/**
 * @param {CuePoint} cuePoint
 * @param {boolean} value
 */
export async function setCuePointOnTime(cuePoint, value, clear = false) {
	if (cuePoint.type === CuePoint.TYPE.CLOSED_CAPTION) {
		const timeState = {};
		timeState[cuePoint.sid] = value;

		closedCaptionOnTimeState.set(prevState => {
			if (!clear) {
				return timeState;
			}
			// check if need to clean only if the same value
			if (prevState[cuePoint.sid] && prevState[cuePoint.sid] === value) {
				return {};
			}

			return prevState;
		})
	} else {
		cuePointsOnTimeState.set(state => ({ ...state, [cuePoint.sid]: clear ? null : value }))
	}

}

/**
 * @param {string} sid
 * @param {boolean} value
 */
export async function resetCuePointOnTime() {
	cuePointsOnTimeState.set({})
	closedCaptionOnTimeState.set({})
}

/**
 * @param {CuePoint} cuePoint
 */
export function resetCuePointTempValues(cuePoint) {
	cuePoint.hide = false;
	cuePoint.tmpWidth = null;
	cuePoint.tmpHeight = null;
	cuePoint.tmpSvg = null;
	cuePoint.tmpVideoUrl = null;
	cuePoint.tmpImageUrl = null;
}

/**
 * @param {CuePoint} cuePoint
 */
export function resetCuePoint(cuePoint) {
	resetCuePointTempValues(cuePoint);
	cuePoint.videoUrl = null;
	cuePoint.svg = null;
	cuePoint.assetSid = null;
	cuePoint.credit = null;
}

/**
 * @param {Sequence} sequence
 * @param {Map<string, Scene>} scenes
 */
export async function loadCuePoints(sequence, scenes, animations, cacheVersion) {
	const sequenceSid = sequence.sid
	await Promise.all([
		GraphicCuePoint.list({ sequenceSidEqual: sequenceSid }).then(
			cp => cp && cp.forEach(cuePoint => setCuePoint(cuePoint, sequence, scenes, false))
		),
		TitleCuePoint.list({ sequenceSidEqual: sequenceSid }).then(
			cp => cp && cp.forEach(cuePoint => {
				setCuePoint(cuePoint, sequence, scenes, false)
			})
		),
		SpeakerCuePoint.list({ sequenceSidEqual: sequenceSid }).then(
			cp => cp && cp.forEach(cuePoint => {
				setCuePoint(cuePoint, sequence, scenes, false)
			})
		),
		ClosedCaption.listWords(sequenceSid).then(words =>
			setClosedCaption(words, sequence, scenes, true)
		),
	])
}

// fetch close caption 
export async function getCloseCaption(sequence, scenes) {
	const sequenceSid = sequence.sid;
	await ClosedCaption.listWords(sequenceSid).then(words =>
		setClosedCaption(words, sequence, scenes, true)
	)
}

/**
 * @param {Word[]} wordsList
 * @param {Sequence} sequence
 * @param {Map<string, Scene>} scenes
 */
export function setClosedCaption(wordsList, sequence, scenes, incrementVersion = false) {

	if (!wordsList.length) {
		return
	}


	cuePointsState.set(
		(cuePoints) => {
			let closedCaptions = Object.values(cuePoints)
				.filter(cuePoint => cuePoint && cuePoint instanceof ClosedCaption)

			let existingWords = closedCaptions
				.map(closedCaption => closedCaption.words)
				.flat();

			const firstWordStartTime = wordsList[0].startTime
			const lastWordEndTime = wordsList[wordsList.length - 1].endTime
			let lastChapterSid, lastClosedCaption, prevClosedCaption, nextClosedCaption

			// TODO: need to fixed or remove this fro closedCaption pre / next currently it not accurate 
			if (closedCaptions.length) {
				prevClosedCaption = closedCaptions.filter(cc => inRange(firstWordStartTime - cc.endTime, 5000)).sort((a, b) => b.endTime - a.endTime).shift()
				nextClosedCaption = closedCaptions.filter(cc => inRange(cc.startTime - lastWordEndTime, 5000)).sort((a, b) => a.startTime - b.startTime).shift()
			}
			lastClosedCaption = prevClosedCaption

			let newWords = [];

			wordsList.forEach(word => {
				const existingWord = existingWords.find(w => (
					w.startTime === word.startTime &&
					w.word === word.word &&
					w.chapterSid === word.chapterSid
				));

				if (word.new || !existingWord) {
					newWords.push(word);
				} else {
					existingWord && existingWord.set(word.values);
				}

			});

			newWords
				.filter(w => !w.alt)
				.reduce(
					(chunks, word) => {
						// if new chapterSid create new chunk
						if ((lastChapterSid && lastChapterSid !== word.chapterSid)) {
							chunks.push([]);
						}
						lastChapterSid = word.chapterSid
						// push word to the last chunk
						chunks[chunks.length - 1].push(word);
						// if current word newline it will be last in the chunk 
						// push new array for the next word
						if (word.newLine) {
							chunks.push([]);
						}
						return chunks;
					},
					[[]]
				)
				.filter(words => words.length)
				.forEach(words => {
					let chapterSid = words[0].chapterSid
					let startTime = words[0].startTime
					let endTime = words[words.length - 1].endTime
					let closedCaption = new ClosedCaption(sequence.sid, uniqueSid()).set({
						status: ClosedCaption.STATUS.ACTIVE,
						type: ClosedCaption.TYPE.CLOSED_CAPTION,
						words: JSON.parse(JSON.stringify(words)),
						sequenceSid: sequence.sid,
						chapterSid,
						startTime,
						endTime,
					})

					lastClosedCaption && (closedCaption.prev = lastClosedCaption)
					closedCaptions.push(closedCaption);
					cuePoints[closedCaption.sid] = calcCuePointTimes(closedCaption, sequence, scenes);
					lastClosedCaption && (cuePoints[lastClosedCaption.sid].next = cuePoints[closedCaption.sid])
					lastClosedCaption = closedCaption;
				})

			lastClosedCaption && nextClosedCaption && (cuePoints[lastClosedCaption.sid].next = cuePoints[nextClosedCaption.sid])
			nextClosedCaption && lastClosedCaption && (cuePoints[nextClosedCaption.sid].prev = cuePoints[lastClosedCaption.sid])

			//TODO: not in used
			// newWords
			// 	.filter(w => w.alt)
			// 	.forEach(alt => {
			// 		console.log('alt', alt);
			// 		let closedCaption = closedCaptions.find(closedCaption => (
			// 			closedCaption.chapterSid === alt.chapterSid &&
			// 			closedCaption.startTime < alt.endTime &&
			// 			closedCaption.endTime > alt.startTime
			// 		))
			// 		if (!closedCaption) {
			// 			return;
			// 		}

			// 		let word = closedCaption.words.find(w => w.startTime === alt.startTime || w.endTime === alt.endTime)
			// 		if (!word || word.word === alt.word) {
			// 			return;
			// 		}

			// 		word.values.sid = word.sid || uniqueSid();
			// 		word.alts = word.alts || [word.word];

			// 		word.alts.push(alt.word)
			// 		if (!closedCaption.alts) {
			// 			closedCaption.alts = [];
			// 		}
			// 		closedCaption.alts.push(alt)

			// 	})

			setHighlightedWords(wordsList)


			return cuePoints;
		})
	incrementVersion && incrementCuePointsVersion()
}


export function setNonSequelWords(words, sequence, scenes, incrementVersion = false) {

	const closedCaptionArr = {}
	let wordsList = []
	words
		.sort((a, b) => a.startTime - b.startTime)
		.forEach(w => closedCaptionArr[w.chapterSid] ? closedCaptionArr[w.chapterSid].push(w) : closedCaptionArr[w.chapterSid] = [w])

	Object.values(closedCaptionArr).forEach(words => {
		let chapterSid = words[0].chapterSid
		let startTime = words[0].startTime
		let endTime = words[words.length - 1].endTime
		let closedCaption = new ClosedCaption(sequence.sid, uniqueSid()).set({
			status: ClosedCaption.STATUS.ACTIVE,
			type: ClosedCaption.TYPE.CLOSED_CAPTION,
			words: JSON.parse(JSON.stringify(words)),
			sequenceSid: sequence.sid,
			chapterSid,
			startTime,
			endTime,
		})
		const calced = calcCuePointTimes(closedCaption, sequence, scenesState.get().map, true, true)
		wordsList = wordsList.concat(calced.words)
	})

	const updateWord = (word, newWord) => { word.set(newWord.values) }
	const addWord = word => { setClosedCaption([word], sequence, scenes, incrementVersion) }
	const deleteWord = word => {
		const filterMethod = words => {
			const ret = words.filter(w =>
				word.startTime !== w.startTime &&
				word.chapterSid !== w.chapterSid
			)
			return ret
		}
		// highlightWordListState.set(filterMethod)
		// keywordListState.set(filterMethod)
	}
	const updateWordFromList = (word, newWord, wordsList) => {
		const update = wordsList.find(w =>
			w.startTime === word.startTime &&
			w.chapterSid === word.chapterSid)
		if (update) {
			updateWord(update, newWord)
		} else {
			wordsList = [...(wordsList || []), newWord]
		}
		return wordsList
	}
	const updateKeyword = (w, word) => {
		keywordListState.set(wordsList => {
			return updateWordFromList(w, word, wordsList)
		})
	}
	const updateHighlighted = (w, word) => {
		keywordListState.set(wordsList => {
			return updateWordFromList(w, word, wordsList)
		})
	}

	cuePointsState.set(cuePoints => {
		let closedCaptions = Object.values(cuePoints)
			.filter(cuePoint => cuePoint && cuePoint instanceof ClosedCaption)

		closedCaptions.forEach(cc => {
			for (let i = 0; i < cc.words.length; i++) {
				const w = cc.words[i]
				wordsList.flat().forEach((word, index) => {
					if (w.startTime === word.startTime &&
						w.chapterSid === word.chapterSid) {
						if (word.word) {
							updateWord(w, word)
							if (w.flags) {
								updateKeyword(w, word)
							} else if (w.highlight) {
								updateHighlighted(w, word)
							}
						} else {
							deleteWord(w)
							cc.words.splice(i, 1)
						}
						wordsList.splice(index, 1)
					}
				})
				if (!wordsList.length) {
					break;
				}
			}
		})

		if (wordsList.length) {
			wordsList.forEach(addWord)
		}

		return cuePoints;
	})
}


/**
 * @param {string} sid
 */
export function removeCuePoint(sid) {
	cuePointsState.set(cuePoints => {
		delete cuePoints[sid]
		return cuePoints
	});
	incrementCuePointsVersion()
}

/**
 * @param {string} sid
 */
export function removeClosedCaption(cuePoint) {
	cuePointsState.set(cuePoints => {
		cuePoints[cuePoint.sid].disableCuePoint()
		return cuePoints
	})
	incrementCuePointsVersion()
}

/**
 * @param {string} sid Chapter sid
 */
export function removeChapterCuePoints(sid) {
	const linkedChapter = scenesState.get().array.find(ch => ch.linkSid === sid)
	removeCuePoints(c =>
		c.chapterSid === sid
		&&
		(c.type !== ClosedCaption.TYPE.CLOSED_CAPTION || !linkedChapter)
	);
}

/**
 * @param {Function} condition
 */
export function removeCuePoints(condition) {
	cuePointsState.set(cuePoints => {
		return Object
			.values(cuePoints)
			.filter(cuePoint => cuePoint && !condition(cuePoint))
			.reduce((obj, cuePoint) => ({ ...obj, [cuePoint.sid]: cuePoint }), {});
	})
	incrementCuePointsVersion()
}

/**
 * @param {CuePoint} cuePoint
 * @param {Sequence} sequence
 * @param {Map<string, Scene>} scenes
 */
function onTimeCalculation(cuePoints, sequence, scenes) {
	const ret = {};
	const cuePointsArray = Object.values(cuePoints).filter(c => c);
	for (const cuePoint of cuePointsArray) {
		ret[cuePoint.sid] = calcCuePointTimes(cuePoint, sequence, scenes);
	}
	return ret;
}

/**
 * @param {CuePoint[]} cuePoints
 * @param {Sequence} sequence
 * @param {Map<string, Scene>} scenes
 */
function onTimeCalculationTemp(cuePoints, sequence, scenes) {
	const ret = [];
	for (const cuePoint of cuePoints) {
		ret.push(calcCuePointTimes(cuePoint, sequence, scenes, true))
	}
	return ret;
}

/**
 * @param {Sequence} sequence
 * @param {Scene} scene
 */
export function recalcSceneCuePointsTimes(sequence, scene) {
	const scenes = { [scene.sid]: scene };
	cuePointsState.set(cuePoints => {
		const ret = {};
		const cuePointsArray = Object.values(cuePoints);
		for (const cuePoint of cuePointsArray) {
			if (cuePoint?.chapterSid === scene.sid || cuePoint?.chapterSid === scene.linkSid) {
				ret[cuePoint.sid] = calcCuePointTimes(cuePoint, sequence, scenes);
			}
			else {
				ret[cuePoint.sid] = cuePoint;
			}
		}
		return ret;
	})
	incrementCuePointsVersion()
}

/**
 * @param {Sequence} sequence
 * @param {Map<string, Scene>} scenes
 */
export function recalcCuePointsTimes(sequence, scenes) {


	resetCuePointOnTime()

	cuePointsState.set(cuePoints => onTimeCalculation(cuePoints, sequence, scenes))

	incrementCuePointsVersion()
}

export function wordOffset(word) {
	const scenes = scenesState.get().map;
	// get active chapterId that is not temp chapter
	// TODO: need to remove the temp chapter
	let activeWordChapterId = null;
	try {
		if(word.enabled){
			Object.entries(word.enabled).forEach(e => {
				if(e[0].indexOf('temp-') === -1 && e[1] === true) {
					activeWordChapterId = e[0];
					return
				}
			});
		}
		
		const chapter = scenes?.[activeWordChapterId]
		if (!chapter) {
			return null
		}
		return (chapter.offset - (chapter.clipFrom || 0)) * 1000 + word.startTime;

	} catch (error) {
		console.trace(error, word);
		return null;
	}
}



/**
 * @param {CuePoint} cuePoint
 * @param {Sequence} sequence
 * @param {Map<string, Scene>} scenes
 */
export function calcCuePointTimes(cuePoint, { duration }, scenes, isPreview, enableNonReadyChapter) {
	let { startTime, endTime, chapterSid, times } = cuePoint;
	const isClosedCaption = cuePoint instanceof ClosedCaption
	const sequenceDurationMS = duration * 1000;

	if (chapterSid) {
		const isRelatedToCuePoint = (c) => c && c.index && ((c.sid && c.resized && c.transcripted) || c.status === Chapter.STATUS.READY || enableNonReadyChapter || isPreview) && (c.sid === chapterSid || c.linkSid === chapterSid)
		const isOverlapping = (c) => (c.clipFrom || 0) * 1000 < (endTime > 0 ? endTime : c.duration * 1000 + endTime) && ((c.clipFrom || 0) + c.duration) * 1000 > startTime
		const isSequenceLevel = cuePoint instanceof TitleCuePoint

		/**
		 * @type {Scene[]}
		 */

		const activeScenes = (scenes.array || [])
			.filter(isRelatedToCuePoint)
			.filter(isOverlapping)

		const sequenceLevelScene = [activeScenes[0] || scenes[chapterSid]].filter(c => c)

		const offset = (scene) => (scenes.array || []).filter(c => c.duration && c.index < scene.index).reduce((total, curr) => total + curr.duration, 0) * 1000
		cuePoint.times = (isSequenceLevel ? sequenceLevelScene : activeScenes).reduce((all, scene) => ({ ...all, [scene.sid || scene.cid]: CuePointTime.fromScene(cuePoint, scene, offset(scene), sequenceDurationMS) }),
			({}));

		if (isClosedCaption && cuePoint.words?.length) {
			menuState.set(menu => menu === false ? MENUS.CAPTIONS : menu)
		}
	} else {
		duration *= 1000
		let start =
			startTime >= 0
				? startTime
				: duration + startTime
		let end =
			endTime > 0
				? endTime
				: duration
		cuePoint.times = { _: CuePointTime.once(start, Math.min(end, sequenceDurationMS)) };
	}

	return cuePoint;
}

/**
 * @param {CuePoint} cuePoint
 * @param {Sequence} sequence
 * @param {Map<string, Scene>} scenes
 * @param {boolean} incrementVersion
 */
export function setCuePoint(cuePoint, sequence, scenes, incrementVersion = true, enableNonReadyChapter) {
	let calced
	cuePointsState.set(cuePoints => {
		const { tmp, ...rest } = cuePoints;
		calced = calcCuePointTimes(cuePoint, sequence, scenes, false, enableNonReadyChapter)
		calced.prev = cuePoints[cuePoint.prev?.sid]
		calced.next = cuePoints[cuePoint.next?.sid]
		return { ...rest, [cuePoint.sid]: calced }
	})
	if (cuePoint.type === CuePoint.TYPE.CLOSED_CAPTION) {
		// setHighlightedWords(calced.words || [])
	}
	if (incrementVersion) {
		incrementCuePointsVersion()
	}
	return calced
}

/**
 * @param {Sequence} sequence
 */
export async function setTextCuePointsColor(sequence, dominancy) {
	const colors = sequence.colors?.filter(c => c?.color);

	if (!colors || !colors.length) {
		return null
	}

	cuePointsState.set(cuePoints => {
		let cuePointsArray = Object.values(cuePoints).filter(c => c?.text)

		if (dominancy) {
			cuePointsArray = cuePointsArray.filter(c => c.backgroundColorIndex === dominancy)
		}

		cuePointsArray.forEach(async cuePoint => {
			const cuePointBgColor = colors.find(c => c.dominancy === dominancy || c.dominancy === cuePoint.backgroundColorIndex)
			if (!cuePointBgColor) {
				return
			}

			const cuePointColor = cuePoint.color
			cuePoint.color = (cuePointBgColor.brightness) > BRIGHTNESS_THRESHOLD ? '#000000' : '#ffffff';
			if (cuePointColor !== cuePoint.color) {
				await cuePoint.save()
				cuePoints[cuePoint.sid] = cuePoint
			}
		})

		return cuePoints
	})
}

export function onCuePointChange(cuePoint, incrementCuePointsVersion = false, changedProperties) {
	setCuePoint(cuePoint, sequenceState.get(), scenesState.get().map, incrementCuePointsVersion)
	if (!isTemp(cuePoint)) {
		Base.trackChanges(cuePoint, changedProperties, saveCuePoint);
	}
}

/**
 * @param {CuePoint} cuePoint
 * @param {Sequence} sequence
 * @param {Map<string, Scene>} scenes
 * @param {boolean} incrementVersion
 * @param {object} properties
 * @param {boolean} shouldUpdateLocally
 * @returns {Promise<CuePoint>}
 */
export async function saveCuePoint(cuePoint, sequence = sequenceState.get(), scenes = scenesState.get().map, incrementVersion = true, properties = null, shouldUpdateLocally = true) {
	if (!cuePoint.valuesCahnged()) {
		return
	}
	if (cuePoint.sid === 'tmp') {
		delete cuePoint.values.sid;
	} else {
		shouldUpdateLocally && setCuePoint(cuePoint, sequence, scenes, false)
	}

	console.log('Save Cue-Point', cuePoint)
	try {
		lockInteractionState.set(true)
		const res = await cuePoint.save(properties)
		shouldUpdateLocally && setCuePoint(cuePoint, sequence, scenes, incrementVersion)
		lockInteractionState.set(false)
		return res
	}
	catch (err) {
		lockInteractionState.set(false)
		handleError({
			statusCode: 3,
			responseError: err
		})
		if (cuePoint.sid) {
			setCuePoint(cuePoint, sequence, scenes, incrementVersion)
		}
		throw err;
	}

}

/**
 * @param {CuePoint} cuePoint
 * @returns {boolean}
 */
export function isTemp(cuePoint) {
	return cuePoint?.sid === "tmp"
}

/**
 * @param {CuePoint} cuePoint
 * @returns {boolean}
 */
export function isActive(cuePoint) {
	return cuePoint?.status && !CUEPOINT_INACTIVE_STATUSES.includes(cuePoint.status)
}


/**
 * @param {CuePoint} cuePoint
 * @returns {boolean}
 */
export function isFullFrame(cuePoint) {
	return cuePoint.titleType === TitleCuePoint.TITLE_TYPE.SLIDE ||
		cuePoint.type === TitleCuePoint.TYPE.INTRO ||
		cuePoint.type === TitleCuePoint.TYPE.OUTRO ||
		cuePoint.type === TitleCuePoint.TYPE.FRAME ||
		cuePoint.type === TitleCuePoint.TYPE.TRANSITION;
}

/**
 * @type {CuePoint[]}
 */
const previewCuePointsDefault = []
export const previewActiveCuePointsState = entity(previewCuePointsDefault)

export const tempCuePointsVersionState = entity(0)

function incrementTempCuePointsVersion() {
	tempCuePointsVersionState.set(version => version + 1)
}

export function calcPreviewCuePointsTimes(sequence, scenes) {
	const closedCaptions = Object.values(cuePointsState.get()).filter(cp => cp?.type === CuePoint.TYPE.CLOSED_CAPTION).sort((a, b) => a.startTime - b.startTime)
	previewActiveCuePointsState.set(Object.values(onTimeCalculationTemp(closedCaptions, sequence, scenes)))
	incrementTempCuePointsVersion()
}


// ClosedCaption utils and actions
/**
 * @param {string} newText
 * @param {ClosedCaption} closedCaption
 * @param {string} chapterSid
 * @param {number} splitIndex
 * @param {boolean} merge
 * @returns {Word[]}
 */
export function diffChanges(newText, closedCaption, chapterSid, splitIndex, merge) {

	const activeWords = closedCaption.words.filter(w => w.enabled && w.enabled[chapterSid] && !w.excluded).sort((a, b) => a.startTime - b.startTime);

	if (!activeWords.length) {
		const word = new Word();
		word.origin = 'none';
		word.word = newText;
		word.startTime = Math.round(closedCaption.startTime);
		word.endTime = Math.round(closedCaption.endTime);
		word.chapterSid = closedCaption.chapterSid;
		word.newLine = true;
		word.manualTiming = true;
		return [word]
	}

	const text = activeWords.map(w => w.word).join(' ');
	const diffs = diffChars(text, newText);
	// const saveWords = JSON.parse(JSON.stringify(activeWords));
	const saveWords = activeWords;
	saveWords.forEach((w, i) => {
		delete w.added;
		delete w.removed;
		w.offset = (i ? (saveWords[i - 1].offset + saveWords[i - 1].word.length + 1) : 0)
	});
	
	const wordsToRemove = [],
		wordsToUpdate = []

	var offset = 0;
	for (var d of diffs) {
		if (d.removed) {
			var removedWords = d.value.split(/\s/);
			let index = saveWords.findIndex(w => w.offset > offset) - 1
			if (index === -2) {
				index = saveWords.length - 1;
			}
			let localOffset = offset;
			for (var i = 0; i < removedWords.length; i++) {
				var removedWord = removedWords[i];
				let word = saveWords[index]
				if (i && index) {
					word.mergePrev = saveWords[index - 1];
				}
				index++;
				if (!removedWord) {
					continue;
				}
				let charIndex = localOffset + i - word.offset + (word.added || 0) - (word.removed || 0);
				wordsToRemove.push({ word: word.word, startTime: word.startTime, chapterSid: word.chapterSid })
				console.log("cuepoint - wordsToRemove", wordsToRemove)
				word.origin = word.values.origin || word.word
				word.word = word.word.substr(0, charIndex) + word.word.substr(charIndex + removedWord.length)
				localOffset += removedWord.length;
				word.removed = (word.removed || 0) + removedWord.length;
				word.highlight = false
			}
			offset += d.count;
		}
		else if (d.added) {
			let index = saveWords.findIndex(w => w.offset > offset) - 1
			if (index === -2) {
				index = saveWords.length - 1;
			}
			let word = saveWords[index]
			let charIndex = offset - word.offset + (word.added || 0) - (word.removed || 0);
			let prevWord = word.word
			word.origin = word.values.origin || word.word
			word.word = word.word.substr(0, charIndex) + d.value + word.word.substr(charIndex)
			word.added = (word.added || 0) + d.count;
			word.highlight && wordsToUpdate.push({ word: word.word, origin: prevWord, chapterSid, chapterSid: word.chapterSid, startTime: word.startTime })

		}
		else {
			offset += d.count;
		}
	}

	
	saveWords
		.filter(w => w.mergePrev)
		.reverse()
		.forEach(word => {
			word.mergePrev.origin = word.mergePrev.values.origin || word.mergePrev.word;
			word.mergePrev.word += word.word;
			word.mergePrev.endTime = Math.round(word.endTime);
			word.mergePrev.newLine = word.newLine;
			word.origin = word.values.origin || word.word;
			word.word = '';
			delete word.mergePrev;
		})

	console.log('Words diff', { text, newText, saveWords })
	return saveWords;
}

/**
 * @param {HTMLElement} target
 * @param {React.MutableRefObject<boolean>} editing
 * @param {ClosedCaption} closedCaption
 * @param {string} chapterSid
 * @param {boolean} inMenu
 * @param {number} splitIndex
 * @param {boolean} merge
 * @returns {Word[]}
 */
export async function evalClosedCaption(target, editing, closedCaption, chapterSid, inMenu, splitIndex, merge) {
	if (!editing.current) {
		return;
	}
	const activeWords = closedCaption.words.filter(w => w.enabled && w.enabled[chapterSid] && !w.excluded).sort((a, b) => a.startTime - b.startTime);

	const newText = decodeHtml(target.textContent).replace(/[\s\r\n]+/g, ' ').trim()
	var empty = false;
	var saveWords;
	if (newText) {
		saveWords = diffChanges(newText, closedCaption, chapterSid, splitIndex, merge);
	}
	else {
		empty = true;
		activeWords.forEach(w => {
			w.origin = w.word;
			w.word = '';
		});
		saveWords = activeWords;
	}

	var wordsToSplit = saveWords.filter(w => w.word.match(/\s/))
	if (wordsToSplit.length) {
		wordsToSplit.forEach(w => {
			var newLine = w.newLine
			var startTime = w.startTime
			var words = w.word.split(/\s+/)
			var splitTime = Math.floor(
				(w.endTime - startTime) / words.length
			)
			w.endTime = Math.round(startTime + splitTime)
			w.word = words.shift().trim()
			if (words.length) {
				w.newLine = false
			} else if (newLine) {
				w.newLine = true
			}
			while (words.length) {
				var word = new Word().set(JSON.parse(JSON.stringify(w)))
				startTime += splitTime
				word.origin = null
				word.manualTiming = true
				word.startTime = Math.round(startTime)
				word.endTime = Math.round(startTime + splitTime)
				word.word = words.shift().trim()
				word.positionX = null;
				word.positionY = null;
				if (words.length) {
					w.newLine = false
				} else if (newLine) {
					word.newLine = true
				}
				saveWords.push(word)
			}
		})
	} else {
		const lastWord = [...saveWords].reverse().find(w => w.word?.trim().length && newText.includes(w.word));
		saveWords
			.filter(w => w !== saveWords[splitIndex] && w !== lastWord && w.newLine)
			.forEach(w => w.newLine = false)
	}
	
	saveWords.sort((a, b) => a.startTime - b.startTime);
	// get word position
	const wordsWithPosition = saveWords.filter(w => w.positionX || w.positionY);
	if (saveWords.length && wordsWithPosition.length) {
		const position = {
			x: wordsWithPosition.at(-1).positionX,
			y: wordsWithPosition.at(-1).positionY,
		}
		// rest wordsWithPosition
		wordsWithPosition.forEach(w => {
			w.positionX = null;
			w.positionY = null;
		});
		// TODO: check for newLine? 
		const nonDeletedWords = saveWords.filter(w => w.word.length > 1);
		const newLastWord = nonDeletedWords.at(-1);
		// set last word with the position
		newLastWord.positionX = position.x;
		newLastWord.positionY = position.y;
	}
	
	

	const changedWords = saveWords.filter(w => w.changedProperties.length);
	trackEvent('closed-caption-change', { inMenu, changedWords: changedWords.length });

	await saveChanges(closedCaption, changedWords, editing, chapterSid, splitIndex, true);
	if (empty) {
		removeClosedCaption(closedCaption);
	}
}

export function previousClosedCaption(closedCaption) {
	const closedCaptions = Object.values(cuePointsState.get())
		.filter(cuePoint => cuePoint && cuePoint instanceof ClosedCaption && cuePoint.chapterSid === closedCaption.chapterSid && isActive(cuePoint))
		.sort((a, b) => a.startTime - b.startTime);
	const ccIndex = closedCaptions.findIndex(cc => cc.sid === closedCaption.sid);
	return closedCaptions[ccIndex - 1];
}

export function nextClosedCaption(closedCaption, chapterSid) {
	const chapterId = chapterSid || closedCaption.chapterSid;
	const closedCaptions = Object.values(cuePointsState.get())
		.filter(cuePoint => cuePoint && cuePoint instanceof ClosedCaption && cuePoint.chapterSid === chapterId && isActive(cuePoint))
		.sort((a, b) => a.startTime - b.startTime)
	const ccIndex = closedCaptions.findIndex(cc => cc.sid === closedCaption.sid);
	return closedCaptions[ccIndex + 1];
}

/**
 * @param {ClosedCaption} closedCaption
 * @param {{Word[]} words
 * @param {React.MutableRefObject<boolean>} editing
 * @param {string} chapterSid
 * @param {number} splitIndex
 * @param {boolean} removeAlts
 * @returns {Word[]}
 */
export async function saveChanges(closedCaption, words, editing, chapterSid, splitIndex, removeAlts) {
	const sequence = sequenceState.get(), scenes = scenesState.get(scenes => scenes.map)

	let activeWords = [];
	let alts = [];

	if (closedCaption != null) {

		activeWords = closedCaption.words.filter(w => w.enabled && w.enabled[chapterSid] && !w.excluded).sort((a, b) => a.startTime - b.startTime);
		alts = (closedCaption.alts || []).filter(alt => removeAlts || words.find(w => w.startTime === alt.startTime || w.endTime === alt.endTime))
		alts.forEach(alt => {
			alt.origin = alt.word
			alt.word = ''
		})



	}

	words.forEach(w => {
		delete w.alts;
	})

	/**
	 * @type {Word[]}
	 */
	const update = [...words, ...alts].filter(w => w.word !== undefined)
	if (!update.length && isNaN(splitIndex)) {
		return;
	}
	update.forEach(w => {
		w.chapterSid = closedCaption.chapterSid; // store the chapter sid for a word
		w.changedProperties = [
			...w.changedProperties,
			'startTime',
			'endTime',
			'chapterSid',
			'score',
			'word',
			'excluded',
			'newLine',
			'highlight',
			'flags',
			'origin',
			'positionX',
			'positionY',
		]
	})
	console.log('Save words', update)

	await ClosedCaption.updateWords(closedCaption.sequenceSid, update)
	closedCaption.alts = (closedCaption.alts || []).filter(alt => !alts.find(a => a === alt));
	for (const word of words) {
		closedCaption.words.find(w => w === word) || closedCaption.words.push(word)
	}
	const wordsArray = []
	closedCaption.words.forEach(w => {
		delete w.values.origin;
		w.changedProperties = [];
		if (w.word.length) {
			wordsArray.push(w);
		}
	});
	closedCaption.words = wordsArray;
	setCuePointOnTime(closedCaption, false)
	closedCaption.words.sort((a, b) => a.startTime - b.startTime);
	editing.current = false;
	closedCaption.tmpText = null;
	// split
	if (splitIndex !== undefined && splitIndex >= 0 && activeWords.length-1 > splitIndex) {
		// const activeWords = closedCaption.words.filter(w => w.enabled && w.enabled[chapterSid] && !w.excluded).sort((a, b) => a.startTime - b.startTime);
		console.log('Save words', {splitIndex, activeWords});
		const nextWords = activeWords.slice(splitIndex + 1).filter(word => word.word);
		if(nextWords && nextWords.length) {
			const lastNextWord = nextWords.at(-1);
			const wordPosition = {
				x: lastNextWord?.positionX || null,
				y: lastNextWord?.positionY || null,
			}
			var nextClosedCaption = new ClosedCaption(closedCaption.sequenceSid, Math.random().toString(36).substring(2))
			nextClosedCaption.set({
				status: ClosedCaption.STATUS.ACTIVE,
				type: ClosedCaption.TYPE.CLOSED_CAPTION,
				words: JSON.parse(JSON.stringify(nextWords)),
				sequenceSid: closedCaption.sequenceSid,
				chapterSid: closedCaption.chapterSid,
				startTime: Math.round(nextWords[0].startTime),
				endTime: Math.round(nextWords[nextWords.length - 1].endTime),
			})
			// first word time in the new closedCaption
			const nextClosedCaptionFirstWordStartTime = nextClosedCaption.words[0].startTime;
			// the words that need to keep from the current split CC, filter to avoid bug when split 
			// CC with virtual chapter
			const leftWords = closedCaption.words.filter(w => w.startTime < nextClosedCaptionFirstWordStartTime);
			// set last word new line start
			const lastWordInLeftWords = leftWords[leftWords.length-1];
				lastWordInLeftWords.newLine = true;
				// set word position like origin cc position
				lastWordInLeftWords.positionX = wordPosition.x;
				lastWordInLeftWords.positionY = wordPosition.y;
				lastWordInLeftWords.changedProperties = [
					...lastWordInLeftWords.changedProperties,
					'startTime',
					'endTime',
					'chapterSid',
					'score',
					'word',
					'excluded',
					'newLine',
					'highlight',
					'positionX',
					'positionY',
					'flags',
					'origin'
				]
			await ClosedCaption.updateWords(closedCaption.sequenceSid, [lastWordInLeftWords]);
			delete lastWordInLeftWords.values.origin;
			lastWordInLeftWords.changedProperties = [];
			// set last word new line end 

			closedCaption.set({
				endTime: Math.round(leftWords[leftWords.length - 1].endTime),
				words: JSON.parse(JSON.stringify(leftWords)),
			});

			nextClosedCaption.prev = closedCaption;
			if (closedCaption.next) {
				nextClosedCaption.next = closedCaption.next;
				nextClosedCaption.next.prev = nextClosedCaption;
			}
			closedCaption.next = nextClosedCaption;
			setCuePoint(nextClosedCaption, sequence, scenes)
			nextClosedCaption.next && setCuePoint(nextClosedCaption.next, sequence, scenes)
			setCuePointOnTime(closedCaption, false)
		}
	}
	setCuePoint(closedCaption, sequence, scenes)
}


export async function resetCuePointToDefault() {
	const cuePoints = cuePointsState.get();
    const awaitList = [];
	let count = 0;
    Object.values(cuePoints).forEach((cuePoint) => {
	  if(cuePoint instanceof TitleCuePoint ||
		 cuePoint instanceof SpeakerCuePoint || 
		 cuePoint instanceof FrameCuePoint 
		 ) {
		count++;
		cuePoint.colors = null;
		cuePoint.assetSid = null;
		awaitList.push(cuePoint.save());
	  }
   
    });
    await Promise.all(awaitList);
}

export async function resetLogoScaleToDefault(...cuePointTypes) {
	if (cuePointTypes.length) {
		const cuePoints = cuePointsState.get();
		const awaitList = [];
		cuePointTypes.forEach((type) => {
			Object.values(cuePoints).forEach((cuePoint) => {
				if(cuePoint?.type === type && cuePoint?.logoScale) {
					cuePoint.logoScale = 1;
					awaitList.push(cuePoint.save());
				}
			});
		})
		await Promise.all(awaitList);
	}
}

export async function randomizeClosedCaptionPosition(sequenceSid, chapters) {
	let wordsWithNewLine = [];
	const OPTIONAL_YPOSITION = [25,35,45,55,65,70,80]
	cuePointsState.set(
		(cuePoints) => {
			let closedCaptions = Object.values(cuePoints)
				.filter(cuePoint => cuePoint && cuePoint instanceof ClosedCaption)

				wordsWithNewLine = closedCaptions
				.map(closedCaption => closedCaption.words.filter(w => w.newLine))
				.flat();
				wordsWithNewLine.forEach((w) => {
					//Randomize position choose one of  [25,48,70]
					let minYPosition = 10;
					let chapter = chapters.find(chapter => chapter.sid === w.chapterSid);
					if (chapter.detections && chapter.detections.length > 0) {
						//TODO add loop that check the position of the word and find it in the detections
						let detectionInTime = chapter.detections.find(detection => detection.start <= w.startTime/1000 && detection.end >= w.endTime/1000);
						console.log('detectionInTime', detectionInTime);
						if (detectionInTime) {
							let yCenterFace = Math.round(detectionInTime.yCenterPercentage * 100);
							minYPosition = yCenterFace + 15;
						}
					}
				w.positionX = null ;
				w.positionY = OPTIONAL_YPOSITION[Math.floor(Math.random() * OPTIONAL_YPOSITION.length)];
				if (w.positionY < minYPosition) {
					w.positionY = minYPosition;
				}
				w.changedProperties = [
					...w.changedProperties,
					'chapterSid',
          'word',
          'startTime',
          'endTime',
          'newLine',
          'origin',
				]

			})
			return cuePoints;
		})
		if(wordsWithNewLine.length > 0) {
			incrementCuePointsVersion();
			await ClosedCaption.updateWords(sequenceSid, wordsWithNewLine);
			
		}
}

// reset words position
export async function resetCloseCaptionManualPosition(sequenceSid) {
	let wordsWithPosition = [];
	cuePointsState.set(
		(cuePoints) => {
			let closedCaptions = Object.values(cuePoints)
				.filter(cuePoint => cuePoint && cuePoint instanceof ClosedCaption)

			wordsWithPosition = closedCaptions
				.map(closedCaption => closedCaption.words.filter(w => w.positionX || w.positionY))
				.flat();
			wordsWithPosition.forEach((w) => {
				w.positionX = null;
				w.positionY = null;
				w.changedProperties = [
					...w.changedProperties,
					'startTime',
					'endTime',
					'chapterSid',
					'word',
				]

			})
			return cuePoints;
		})
		if(wordsWithPosition.length > 0) {
			incrementCuePointsVersion();
			await ClosedCaption.updateWords(sequenceSid, wordsWithPosition);
			
		}
}
