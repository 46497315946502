
import * as Base from './base/invoiceList';
	
export {default as Payment} from './invoiceList/payment';
	
export {default as Income} from './invoiceList/income';
	
export {default as Invoice} from './invoiceList/invoice';


export interface IInvoiceList extends Base.IInvoiceList {

}

export interface IInvoiceListProperties extends Base.IInvoiceListProperties {

}

export interface IInvoiceListFilter extends Base.IInvoiceListFilter {

}

export default class InvoiceList<IType extends IInvoiceList = IInvoiceList, ITypeProperties extends IInvoiceListProperties = IInvoiceListProperties> extends Base.InvoiceList<IType, ITypeProperties> implements IInvoiceList {
}