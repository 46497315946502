import React from 'react';
import { components, GroupHeadingProps } from 'react-select';

import styled from 'styled-components';
import { COLORS } from '../../../../constants/colors';

const DivStyled = styled.div`
  position: relative;
  height: 30px;
  width: 100%;
`;

const SpanStyled = styled.span`
  position: absolute;
  z-index: 2;
  height: 20px;
  width: fit-content;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 900;
  color: ${COLORS.peech_gray_400};
  background-color: #ffffff;
  padding: 0 6px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  text-transform: capitalize;
`;

const LineStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: ${COLORS.peech_gray_300};
  top: 50%;
  transform: translateY(-50%);
`;

export default function StyledGroupHeading({ ...props }: GroupHeadingProps) {
  return (
    <components.GroupHeading {...props}>
      <DivStyled>
        <LineStyled />
        <SpanStyled>{props.children}</SpanStyled>
      </DivStyled>
    </components.GroupHeading>
  );
}
