import React from 'react'
import SVG from '../../components/svg'
import classNames from 'classnames'
import './Uploader.scss'
import { sequenceState} from '../../state/sequence';



interface UploaderProps {
    preview?: string;
    error?: any;
    onImageLoad?(img: EventTarget /*HTMLImageElement*/): void;
    file?: any;
    fontAsset?: any;
    onCancel?(): void;
    preset?:any;
}

export default function Uploader({ file, preset,preview, error, onImageLoad, fontAsset, onCancel }: UploaderProps) {
    const sequence = sequenceState.use();

    const uploaderClassNames = () => classNames('peech-scene-uploader', { 'failed': error, 'font': fontAsset, 'image': preview })

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
        );
    }

    return <div className={`file-uploader ${uploaderClassNames()}`}>

        {!preview && !error && !fontAsset && <>
            <SVG name="upload-illustration" className="file-uploader-icon" viewBox="0 0 156 125" />
           <span className="placeholder">Drag your files here <br /> or <span>browse</span> to upload</span>
        </>}
        {fontAsset && <div className="file-uploader-font">
            <SVG width="46" height="58" viewBox="0 0 46 58" name="file" />
            <div className="text">
                <span className="file-uploader-file-name">{file.name}</span>
            </div>
            <SVG viewBox="0 0 25 25" name="success" />
            <button onClick={onCancel} className="close-btn-font-uploader">
                <SVG name='close' width='12px' height='12px' />
            </button>
        </div>}
        {error && <div className="file-uploader-info">
            <SVG name="warning" />
            <div className="text">
                <span className="file-uploader-description">Upload failed</span>
                <a><span className="file-uploader-action">Retry</span></a>
            </div>
        </div>}
        { !!preview && <div>
            <img
                className="file-uploader-preview"
                src={preview}
                onLoad={e => onImageLoad && onImageLoad(e.target)} />
        </div>
        }
        { !!preview  && sequence.logo && <div className="file-uploader-logo">
            <button onClick={onCancel} className="change-btn-logo-uploader">
                <SVG name='change' width="36" height="36" viewBox="0 0 36 36" />
            </button>
        </div>}

        
        { !!preview  && preset?.assets && <div className="file-uploader-logo">
            <button onClick={onCancel} className="change-btn-logo-uploader">
                <SVG name='change' width="36" height="36" viewBox="0 0 36 36" />
            </button>
        </div>}

    </div>
}
