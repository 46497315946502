import { entity } from 'simpler-state'

/**
 * @type {{
 *  seek: float,
 *  playUntil: float,
 *  version: number,
 *  manifestVersion: number,
 *  startTime: number,
 *  start: boolean,
 *  shouldPause: boolean,
 * }}
 */
const defaultValue = {
	seek: null,
	playUntil: null,
	version: 0,
	manifestVersion: 0,
	startTime: 0.001,
	start: false,
	shouldPause: false,
	isPlayerPlaying: false
}

export const playbackState = entity(defaultValue)

export const currentTimeState = entity(0)

export const buffersState = entity([])

export const activeClosedCaptionState = entity(null)

export const playbackIsLockedState = entity(false);

/**
 * @param {number} seek in seconds
 * @returns {Promise<number>} version
 */
export function seekPlayback(seek, playUntil = null, start = false) {
	//check that seek is a number
	if (typeof seek !== 'number') {
		throw new Error('seek must be a number')
	}
	return new Promise(resolve => {
		playbackState.set(playback => {
			const version = playback.version + 1;
			resolve(version)
			return {
				...playback,
				seek,
				playUntil,
				version,
				start
			}
		})
		currentTimeState.set(seek)
	})
}

/**
 * @param {number} seek in seconds
 */
export function reloadPlayer(version, startTime) {
	playbackState.set(playback => ({
		...playback,
		manifestVersion: version,
		startTime: startTime || defaultValue.startTime
	}))
}

export function resetPlayUntil() {
	playbackState.set(playback => ({
		...playback,
		playUntil: null
	}))
}

export function resetSeekAndPlayUntil() {
	playbackState.set(playback => ({
		...playback,
		seek: null,
		playUntil: null,
		version: playback.version + 1
	}))
}

const updateShouldPause = (playback, shouldPause) => {
	return { ...playback, shouldPause }
}

export function signalPlaybackShouldPause() {
	playbackState.set(updateShouldPause, true)
}

export function clearPlaybackShouldPause() {
	playbackState.set(updateShouldPause, false)
}

export function resetCurrentTime() {
	currentTimeState.set(0)
}

export function incrementPlaybackVersion() {
	playbackState.set(playback => ({
		...playback,
		manifestVersion: playback.manifestVersion + 1,
	}))
}

export function setActiveClosedCaption(value) {
	activeClosedCaptionState.set(value)
}

export function resetActiveClosedCaption() {
	activeClosedCaptionState.set(null)
}

export function setIsPlayerPlaying(value) {
	playbackState.set(playback => ({
		...playback,
		isPlayerPlaying: value
	}));
}