import Compressor from 'compressorjs'
import React from 'react'
import Dropzone from 'react-dropzone'
import '../../../data-layer/shared/mmcq'
import Asset from '../client/asset'
import Color from '../client/color'
import Preset from '../client/preset'
import Sequence, { Logo } from '../client/sequence'
import { fileFromCanvas, findLastIndex, imgToCanvas, mostFrequent, numbersToColor } from '../client/sequence/logo'
import { AppContext } from '../lib/app-context'
import FileUploader from '../lib/fileUploader'
import { setTextCuePointsColor } from '../state/cuePoints'
import { handleError } from '../state/error'
import { sequenceSave, sequenceState } from '../state/sequence'
import Uploader from '../ui-components/Uploader/Uploader'
import { trackEvent } from '../utils/analityics.utils'
import useUserPs from '../hooks/useUserPs'





/**
 * @param {{
 * 	onChange: Function
 * 	openUploader: boolean
 * 	onAutoOpen: Function
 * }} props
 */
export default function LogoDropArea({ source, preset, onChange, openUploader, onAutoOpen, customUploadFunction }) {

	const sequence = sequenceState.use();
	const thumbnailVersion = sequenceState.use(sequence => sequence.logo?.thumbnailVersion);
	const { user, setUser, config } = React.useContext(AppContext)
	const withUserPs = useUserPs();
	const [logoThumbnail, setLogoThumbnail] = React.useState(source ||
		sequence &&
		sequence.logo &&
		Number.isInteger(sequence.logo.thumbnailVersion) &&
		withUserPs(`${config.CHUNKS_URL}/t/${sequence.sid}/${sequence.logo.sid}/${sequence.logo.thumbnailVersion}.png`)
	);
	const dropzoneRef = React.useRef();
	const [uploaderError, setUploaderError] = React.useState(false);
	const presetRef = React.useRef(user?.preset || new Preset())
	const [logoFile, setLogoFile] = React.useState(null)

	React.useEffect(() => {
		if (sequence && sequence.logo && thumbnailVersion && !source) {
			setLogoThumbnail(withUserPs(`${config.CHUNKS_URL}/t/${sequence.sid}/${sequence.logo.sid}/${thumbnailVersion}.png`));
		} else if (source) {
			setLogoThumbnail(source)
		}

	}, [sequence?.sid, thumbnailVersion, source]);

	React.useEffect(() => {
		if (openUploader) {
			dropzoneRef.current.open()
			onAutoOpen()
		}
	}, [openUploader]);



	/**
	 *
	 * @param {HTMLImageElement} img
	 * @returns {Promise<Sequence>}
	 */
	async function onLogoChange(img) {
		if (!img || !img.naturalWidth) {
			return false
		}
		var colors = window.getPalette(img, 5)
		var colorsHash = colors
			.map(color => numbersToColor(color.rgb))
			.join(':')
		var savedColors = sequence.colors
			? sequence.colors.map(color => color.color).join(':')
			: ''
		if (savedColors !== colorsHash) {
			sequence.colors = colors.map((color, index) => {
				var obj = new Color()
				obj.dominancy = index + 1
				obj.color = numbersToColor(color.rgb)
				obj.percent = color.percent
				return obj
			})
			setTextCuePointsColor(sequence)
		}
		return sequence;
	}

	async function uploadLogo(img, filename) {
		var canvas = imgToCanvas(img);
		var context = canvas.getContext("2d");

		var data = [
			context.getImageData(2, 2, 1, 1),
			context.getImageData(2, canvas.height - 2, 1, 1),
			context.getImageData(canvas.width - 2, canvas.height - 2, 1, 1),
			context.getImageData(canvas.width - 2, 2, 1, 1),
		]
			.map(imgData => imgData.data);

		var removeWhiteBackground = false;
		var transparent = data.findIndex(corner => corner[3] < 255) >= 0;
		if (!transparent) {
			var bg = Array.from(mostFrequent(data));
			if (bg[0] > 250 && bg[1] > 250 && bg[2] > 250) {
				removeWhiteBackground = true;
			}
		}

		var imageData = context.getImageData(0, 0, canvas.width, canvas.height);
		var logoImageData = imageData.data;

		if (removeWhiteBackground) {
			for (let i = 0, n = logoImageData.length; i < n; i += 4) {
				if (logoImageData[i] > 250 && logoImageData[i + 1] > 250 && logoImageData[i + 2] > 250) {
					logoImageData[i + 3] = 0;
				}
			}

			context.clearRect(0, 0, canvas.width, canvas.height);
			context.putImageData(imageData, 0, 0);
		}

		// crop
		if (true) {
			// build array of opacity cells
			var opacity = logoImageData.filter((_, i) => (i + 1) % 4 === 0);
			// start with worse data
			var w = canvas.width, h = canvas.height, l = w, t = h, r = 0, b = 0;
			for (let i = 0; i < h; i++) {
				var chunk = opacity.slice(i * w, (i + 1) * w);
				var firstNonTransparentIndex = chunk.findIndex(d => d);
				if (firstNonTransparentIndex < 0) {
					continue; // all line is transparent
				}
				var lastNonTransparentIndex = findLastIndex(chunk, d => d);
				if (lastNonTransparentIndex < 0) {
					lastNonTransparentIndex = w;
				}
				l = Math.min(firstNonTransparentIndex, l);
				r = Math.max(lastNonTransparentIndex + 1, r);
				t = Math.min(i, t);
				b = Math.max(i + 1, b);
			}
			if (l > 0 || t > 0 || r < w || b < h) {
				imageData = context.getImageData(l, t, r - l, b - t);
				logoImageData = imageData.data;
				context.clearRect(0, 0, canvas.width, canvas.height);
				canvas.width = r - l;
				canvas.height = b - t;
				context.putImageData(imageData, 0, 0);
			}
		}
		const src = canvas.toDataURL();
		setLogoThumbnail(src)

		var asset = new Asset();
		asset.type = Asset.TYPE.LOGO;
		asset.name = filename;
		await asset.save();


		const fileUploader = new FileUploader({
			sequence,
			file: fileFromCanvas(canvas),
			content: asset,
		})
		await fileUploader.uploadAsset();

		if (!sequence.logo) {
			sequence.logo = new Logo();
		}

		user.defaultLogoSid = asset.sid
		user?.logoAssets?.unshift(asset)

		sequence.logo.assetSid = asset.sid;
		sequence.logo.name = filename;
		sequence.logo.srcWidth = canvas.width;
		sequence.logo.srcHeight = canvas.height;
		await onLogoChange(img);
		await sequenceSave(sequence)
		presetRef.current.addLogoAsset(asset)
		await presetRef.current.save()
		user.preset = presetRef.current
		setUser(user)
		onChange && onChange()
	}

	function onLogoFileSelected(file) {
		new Compressor(file, {
			strict: false,
			maxWidth: sequence.width ? (sequence.width * 0.4) : 768,
			maxHeight: sequence.height ? (sequence.height * 0.4) : 432,
			checkOrientation: true,
			convertSize: Infinity,
			success: compressed => {
				const img = new Image()
				img.onload = () => customUploadFunction ?
					customUploadFunction(img, file.name) :
					uploadLogo(img, file.name);
				img.src = window.URL.createObjectURL(compressed);
			},
			error: err => {
				setUploaderError(err)
				handleError({
					title: 'Logo Upload',
					message: err.message,
					responseError: err
				})
			},
		})
	}

	function onLogoFileInputChange(files) {
		if (!files.length) {
			return
		}
		const file = files[0];

		trackEvent('logo-change', {
			size: file.size,
			type: file.type,
		});
		onLogoFileSelected(file);
		setLogoFile(file)
	}

	function logoLoaded(logoImage) {
		if (sequence &&
			sequence.logo &&
			sequence.logo.assetSid &&
			logoImage.naturalWidth &&
			!sequence.colors
		) {
			onLogoChange(logoImage);
		}
	}

	return <Dropzone
		onDrop={onLogoFileInputChange}
		accept="image/*"
		maxFiles={1}
		className="logo-dropzone"
		style={{ cursor: "pointer" }}
		ref={dropzoneRef}
	>
		{({ getRootProps, getInputProps }) => (
			<div
				{...getRootProps()}
				className="drop-area drop-area-logo logo"
				style={logoThumbnail ? { border: 'unset' } : {}}
			>
				<input {...getInputProps()} className="logo-file" data-cy="logo-drop-area" />

				<div className='uploader-wrapper'>
					< Uploader preview={logoThumbnail} file={logoFile} error={uploaderError} preset={preset} />
					<br />
				</div>
			</div>
		)}
	</Dropzone>
}