import styled from '@emotion/styled/macro';
import React from 'react';
import { COLORS } from '../../../constants/colors';
import FolderStructure from '../../client/folderStructure';
import { sequenceFontDirectoryState } from '../../components/player-style';
import SVG from '../../components/svg';
import { AppContext } from '../../lib/app-context';
import { resetCuePointToDefault, resetLogoScaleToDefault } from '../../state/cuePoints';
import { sequenceSave, sequenceState } from '../../state/sequence';
import { lottiesLoadingState, lottiesPreviewListState, packagesLoadingState } from '../../state/visualPackage';
import { trackEvent } from '../../utils/analityics.utils';
import { getFontFormSequenceFontToPackagePreview } from '../../utils/lottie-animation.utils';
import Button from '../Button';
import Loader from '../Loader';
import VisualPackageItem from '../VisualPackages/VisualPackageItem';
import Base from '../../client/base/base';
import {CuePointType} from "../../client/cuePoint";
import useUserPs from "../../hooks/useUserPs";

const ModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalBody = styled.div`
  position: absolute;
  min-width: 800px;
  width: 70%;
  min-height: 500px;
  height: 80%;
  z-index: 38;
  background: #ffffff;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px 20px 30px;
`;
const CloseDiv = styled.div`
  position: absolute;
  top: 17px;
  right: 18px;
  height: 18px;
  width: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: ${COLORS.peech_gray_600};
  }
`;

const PackagesContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  height: fit-content;
  overflow-y: scroll;
`;

const LoaderContainer = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  width: 100%;
  min-height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 22px;
  margin-top: 4px;
`;

const LoaderWrapper = styled.div`
  width: 134px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.peech_primary_2_500};
  border-radius: 6px;
  text-align: center;
`;

interface VisualPackagesModalProps {
  onHide: Function;
  sequenceSaveLoading: boolean;
  setSequenceSaveLoading: Function;
  visualPackagesListToShow: FolderStructure[];
}

export default function VisualPackagesModal({
  onHide,
  sequenceSaveLoading,
  setSequenceSaveLoading,
  visualPackagesListToShow,
}: VisualPackagesModalProps) {
  const { config } = React.useContext(AppContext);
  const sequence = sequenceState.use();
  const lottiesPreviewList = lottiesPreviewListState.use();
  const packagesLoading = packagesLoadingState.use();
  const lottiesLoading = lottiesLoadingState.use();
  const [selectedPackageSid, setSelectedPackageSid] = React.useState();

  React.useEffect(() => {
    setSelectedPackageSid(sequence?.style?.visualPackageSid);
  }, [sequence?.style?.visualPackageSid]);

  async function onVisualPackageApply() {
    if (sequence?.style?.visualPackageSid !== selectedPackageSid) {
      setSequenceSaveLoading(true);
      await Base.executeAPIQueue();
      const selectedPackageName = visualPackagesListToShow.find(x => x.values.sid === selectedPackageSid)?.values.name;
      trackEvent(`visualStyle-changed`, { visualStyleSid: selectedPackageSid, visualStyleName: selectedPackageName });
      const newSequence = await sequence.applyPresetGraphics(selectedPackageSid!);
      await sequenceSave(newSequence);
      await resetCuePointToDefault();
      await resetLogoScaleToDefault(CuePointType.INTRO, CuePointType.OUTRO);
      setSequenceSaveLoading(false);
    }
    onHide();
  }

  const withUserPs = useUserPs();

  const logoAssetUrl = React.useMemo(() => {
    if (sequence?.logo) {
      const thumbnailVersion = sequence.logo?.thumbnailVersion;
      const logoSid = sequence.logo?.sid;
      return withUserPs(`${config.CHUNKS_URL}/t/${sequence.sid}/${logoSid}/${thumbnailVersion}.png`);
    }
    return null;
  }, [sequence?.logo]);

  const thumbnailUrl = React.useMemo(() => {
    if(sequence?.chapters && config.CHUNKS_URL) {
      const chapter = sequence.chapters.find((c) => c.assetSid);
      if(chapter?.thumbnailUrl) {
        return withUserPs(chapter.thumbnailUrl(sequence, 400, config.CHUNKS_URL, "16:9"));
      }
    }
    return null;
  }, [sequence?.chapters]);

 

  const fonts = React.useMemo(
    () => getFontFormSequenceFontToPackagePreview(sequenceFontDirectoryState.get()),
    [sequenceFontDirectoryState.get()]
  );

  return (
    <ModalWrapper onClick={onHide}>
      <ModalBody onClick={(e) => e.stopPropagation()}>
        <CloseDiv onClick={onHide}>
          <SVG name="close" viewBox="0 0 22 22" />
        </CloseDiv>
        <PackagesContainer>
          {!(packagesLoading || lottiesLoading) ? (
            visualPackagesListToShow.map((item, index) => {
              return (
                <VisualPackageItem
                  key={item.sid}
                  visPack={item}
                  isSelected={item.sid === selectedPackageSid}
                  onItemClick={() => setSelectedPackageSid(item.sid)}
                  isCustom={item.isCustom}
                  lottiePreview={lottiesPreviewList[item.sid]}
                  colors={sequence?.colors || []}
                  logoAssetUrl={logoAssetUrl}
                  thumbnailUrl={thumbnailUrl}
                  fonts={fonts}
                  isModal={true}
                />
              );
            })
          ) : (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
        </PackagesContainer>
        <ButtonContainer>
          {!(packagesLoading || lottiesLoading) ? (
            sequenceSaveLoading ? (
              <LoaderWrapper>
                <Loader size="xs" />
              </LoaderWrapper>
            ) : (
              <Button variant="primary" label="Apply" onClick={onVisualPackageApply} />
            )
          ) : null}
        </ButtonContainer>
      </ModalBody>
    </ModalWrapper>
  );
}
