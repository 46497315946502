
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from '../base'

export interface IAssetIngestFlow extends IBase {
	rootJobId?: string | null;
	rootJobQueueName?: string | null;
	denoiseTimeoutJobId?: string | null;
	denoiseTimeoutJobQueueName?: string | null;
}

export interface IAssetIngestFlowProperties extends IBaseProperties {
	rootJobId?: boolean;
	rootJobQueueName?: boolean;
	denoiseTimeoutJobId?: boolean;
	denoiseTimeoutJobQueueName?: boolean;
}

export interface IAssetIngestFlowFilter extends IBaseFilter {
}

export class AssetIngestFlow<IType extends IAssetIngestFlow = IAssetIngestFlow, ITypeProperties extends IAssetIngestFlowProperties = IAssetIngestFlowProperties> extends Base<IType, ITypeProperties> implements IAssetIngestFlow {
	
	static className = 'AssetIngestFlow';
	static NAME = 'assetIngestFlow';
	static PKS = [
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		rootJobId: true,
		rootJobQueueName: true,
		denoiseTimeoutJobId: true,
		denoiseTimeoutJobQueueName: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get rootJobId(): string | null | undefined {
		return this.values.rootJobId
	}
			
	get rootJobQueueName(): string | null | undefined {
		return this.values.rootJobQueueName
	}
			
	get denoiseTimeoutJobId(): string | null | undefined {
		return this.values.denoiseTimeoutJobId
	}
			
	get denoiseTimeoutJobQueueName(): string | null | undefined {
		return this.values.denoiseTimeoutJobQueueName
	}
			
	
	static get<IType extends IAssetIngestFlow = IAssetIngestFlow>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IAssetIngestFlowFilter = IAssetIngestFlowFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IAssetIngestFlowFilter = IAssetIngestFlowFilter, IType extends IAssetIngestFlow = IAssetIngestFlow>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
