import classNames from 'classnames';
import React from 'react';
import FormCheck from 'react-bootstrap/FormCheck';
import SVG from '../../components/svg';
import { menuHeaderTooltipTextState } from '../../state/menu';
import { MenuHeaderWrapper } from './MenuComponents.styled';
import {InfoPopup} from "../../components/infoPopup";


/**
 * @param {object} props 
 * @param {string} props.header
 * @param {boolean} props.enabled
 * @param {function} props.onChange
 * @param {string} props.className
 * @param {string} props.type
 */
export default function Menu({ header, enabled, onChange, className, children, type, customIcon, actions }) {

  const handling = React.useRef(false);
  const menuHeaderTooltipText = menuHeaderTooltipTextState.use();

  async function onClick() {
    if (!onChange || handling.current) {
      return;
    }

    handling.current = true;
    await onChange();
    handling.current = false;
  }

  var enabledClassName = `menu-${enabled ? 'enabled' : 'disabled'}`

  const attrs = {}
  if (actions?.length) {
    attrs.style = { marginLeft: 'unset' }
  }

  return (
    <div className={classNames('menu-wrapper parent', { [className]: className, [enabledClassName]: enabled !== undefined })}>
      <MenuHeaderWrapper>
        <div className="menu-header" >

          <label className="menu-title" htmlFor={header}>{header}</label>
          <div style={{display: "flex"}}>
            <InfoPopup text={menuHeaderTooltipText}/>
          </div>
          {(actions || []).map((action, i) => <a key={i} className={action.className} data-cy={`action-${action.svg}`}
                                                 style={{marginLeft: 'auto'}} onClick={action.onClick}><SVG
            name={action.svg}/></a>)}
          {(enabled !== undefined) ? (
            <div className="menu-control" {...attrs} onClick={onClick}>
              {customIcon ?
                <a className='menu-control--custom-icon'><SVG name={enabled ? customIcon.show : customIcon.hide}/></a> :
                <FormCheck
                  type="switch"
                  id={`switch-${type}`}
                  checked={enabled}
                  readOnly
                />
              }
            </div>
          ) : null}

        </div>
      </MenuHeaderWrapper>
        <div className="menu-items flexible" data-cy="sidebar-menu">{children}</div>
    </div >
  )
}
