
import * as Base from './base/folderStructure';

export const FolderStructureFolderType = Base.FolderStructureFolderType;

export interface IFolderStructure extends Base.IFolderStructure {

}

export interface IFolderStructureProperties extends Base.IFolderStructureProperties {

}

export interface IFolderStructureFilter extends Base.IFolderStructureFilter {

}

export default class FolderStructure<IType extends IFolderStructure = IFolderStructure, ITypeProperties extends IFolderStructureProperties = IFolderStructureProperties> extends Base.FolderStructure<IType, ITypeProperties> implements IFolderStructure {
}