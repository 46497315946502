import { entity } from 'simpler-state'
import * as ERRORS from '../lib/ui-errors';

const noError = {};

/**
 * @type ERRORS.UIError | {}
 */
export const errorState = entity(noError);

const ERROR_CODE = {
    default: ERRORS.INTERNAL_SERVER_ERROR,
    1: ERRORS.INTERNAL_SERVER_ERROR,
    2: ERRORS.LOGIN_ERROR,
    3: ERRORS.SAVING_VISUAL_FAILED,
    4: ERRORS.CC_REGENERATE_FAILED,
    5: ERRORS.DOWNLOAD_FAILED,
    8: ERRORS.NO_AUDIO_FOUND, // Handle should be automatically according to data
    9: ERRORS.FAILED_TO_LOAD_FILES,
    10: ERRORS.DELETING_VISUAL_FAILED,
    11: ERRORS.ADDING_SCENE_FAILED,
    101: ERRORS.AUTO_EDITING_FAILED,
    102: ERRORS.AUTO_EDITING_FAILED,
    103: ERRORS.AUTO_EDITING_FAILED,
    111: ERRORS.FORMAT_NOT_SUPPORTED,
    113: ERRORS.ASPECT_RATIO_NOT_SUPPORTED,
    115: ERRORS.FORMAT_NOT_SUPPORTED,
    120: ERRORS.ASPECT_RATIO_NOT_SUPPORTED,
    121: ERRORS.NO_VERBAL_CONTENT,
    401: ERRORS.LOGIN_ERROR,
    
}

const errorExist = (statusCode) => !!ERROR_CODE[statusCode]

export function handleError(err) {
    const { title, message, actionText, statusCode, action, responseError } = err || {}
    const { code } = responseError || {}
    // TODO move log here --- console.error(err)

    // Prevent familiar error code from being overriden
    if (Object.values(ERRORS).includes(errorState.get())) {
        return
    }

    if (errorExist(code?.toString())) {
        errorState.set(ERROR_CODE[code])
    } else if (errorExist(statusCode?.toString())) {
        errorState.set(ERROR_CODE[statusCode])
    } else if (message || title) {
        errorState.set(new ERRORS.UIError({ title, message, actionText, action }))
    } else {
        errorState.set(ERROR_CODE.default)
    }
}
