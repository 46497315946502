
import * as Base from '../base/asset/assetIngestFlow';


export interface IAssetIngestFlow extends Base.IAssetIngestFlow {

}

export interface IAssetIngestFlowProperties extends Base.IAssetIngestFlowProperties {

}

export interface IAssetIngestFlowFilter extends Base.IAssetIngestFlowFilter {

}

export default class AssetIngestFlow<IType extends IAssetIngestFlow = IAssetIngestFlow> extends Base.AssetIngestFlow<IType> {
}