import React from 'react';
import styled from '@emotion/styled/macro';
import ClosedCaption, { Word } from '../../../../client/closedCaption';
import {
  setCuePoint,
  previousClosedCaption,
  nextClosedCaption,
  cuePointsState,
  incrementCuePointsVersion,
  calcCuePointTimes,
  evalClosedCaption,
  saveChanges,
} from '../../../../state/cuePoints';

import { scenesState, sequenceState } from '../../../../state/sequence';
import { EditableCloseCaptionComponent } from './editable-cc.component';
import { TimeCodeComponent, TimeFormat } from '../../../common/time-code/time-code.component';
import CuePointPropertiesItem from '../../cue-point';
import { validateTimeValue } from '../../../../utils/time-utils';
import { TimeContainer } from '../../../common/time-code/time-code.style';
import { trackEvent } from '../../../../utils/analityics.utils';
import { deleteActiveWords } from '../../../../utils/closeCpation.utils';
// import SVG from '../../../svg';

const Container = styled.div`
  padding: 1px 8px;
`;
interface Props {
  closedCaption: ClosedCaption;
  chapterSid: string;
  index: any;
  scrollToIndex: () => void;
}

export const SentenceComponent: React.FC<Props> = ({ closedCaption, chapterSid, index, scrollToIndex }) => {
  const scenes = scenesState.use((scenes) => scenes.map);
  const sequence = sequenceState.use();
  const scene = React.useMemo(() => scenes[chapterSid], [chapterSid, scenes]);

  const activeTime = React.useMemo(() => closedCaption.times[chapterSid], [chapterSid, closedCaption.times]);
  const activeWords = closedCaption.words
    .filter((w) => w.enabled && w.enabled[chapterSid] && !w.excluded)
    .sort((a, b) => a?.startTime - b?.startTime);

  const wordsString = React.useMemo(
    () =>
      activeWords.reduce((value: string, word: Word, index: number) => value + `${index ? ' ' : ''}${word.word}`, ''),
    [activeWords]
  );

  const startMinTime = React.useMemo(() => {
    const firstEnabledWord = activeWords[0];
    // get the first inactive word time next to the first active word (virtual chapter)
    const prevInactiveWord = closedCaption.words
      .filter((w) => !w?.enabled[chapterSid] && w?.startTime < firstEnabledWord?.startTime)
      .sort((a, b) => a?.startTime - b?.startTime)
      ?.pop();
    const prevInactiveTime = prevInactiveWord?.endTime;
    const prevCC = previousClosedCaption(closedCaption);
    let prevCCEndTime;
    if (prevCC && prevCC.times[chapterSid]) {
      const preCCActiveTimes = prevCC.times[chapterSid];
      prevCCEndTime = preCCActiveTimes.end - preCCActiveTimes.offset;
    }

    // get the max value
    const value = Math.max(prevCCEndTime || 0, (scene.clipFrom || 0) * 1000, prevInactiveTime || 0);

    return Math.round(Math.min(value, activeTime?.start - activeTime?.offset) + activeTime?.offset); // add active time offset;
  }, [activeWords, closedCaption]);

  const endMaxTime = React.useMemo(() => {
    const lastEnabledWord = activeWords[activeWords.length - 1];
    // get the first inactive word start time next to the last active word (virtual chapter)
    const nextInactiveTime = closedCaption.words
      .filter((w) => !w?.enabled[chapterSid] && w?.endTime > lastEnabledWord?.startTime)
      .sort((a, b) => a?.startTime - b?.startTime)?.[0]?.startTime;

    const nextCC = nextClosedCaption(closedCaption);
    let nextCCStartTime;
    if (nextCC && nextCC.times[chapterSid]) {
      const nextCCActiveTimes = nextCC.times[chapterSid];
      nextCCStartTime = nextCCActiveTimes.start - nextCCActiveTimes.offset;
    }
    const sceneEndTime = ((scene.clipFrom || 0) + scene.duration) * 1000;
    // get the min value
    const value = Math.min(nextCCStartTime || sceneEndTime, sceneEndTime, nextInactiveTime || sceneEndTime);

    return Math.round(value + activeTime.offset); // add active time offset
  }, [activeWords, closedCaption]);

  const startMaxTime = React.useMemo(() => {
    const currentEndTime = activeTime?.end || 0;
    const minDuration = Math.max(10, activeWords.length);
    return Math.round(currentEndTime - minDuration);
  }, [activeTime]);

  const endMinTime = React.useMemo(() => {
    const currentStartTime = activeTime?.start || 0;
    const minDuration = Math.max(10, activeWords.length);
    return Math.round(currentStartTime + minDuration);
  }, [startMinTime]);

  const onEditDone = React.useCallback(
    async (target: HTMLDivElement, splitIndex: number = NaN, merge: boolean = false) => {
      // update the current words that changed
      await evalClosedCaption(target, { current: true }, closedCaption, chapterSid, true, splitIndex, merge);
      // TODO: Take this to the cuePoint state - or handle it in evalClosedCaption
      // after the current CC word updated if there is change
      // we should updated the previous CC with the current CC words
      if (merge && allowMergeClosedCaption) {
        let prevClosedCaption = previousClosedCaption(closedCaption);
        if (prevClosedCaption) {
          let prevClosedCaptionLastWord = prevClosedCaption.words.at(-1); // get the last word
          prevClosedCaptionLastWord.newLine = false; // change the word new line to false
          // manual position get position
          const wordPositionX = prevClosedCaptionLastWord.positionX;
          const wordPositionY = prevClosedCaptionLastWord.positionY;
          // manual position reset word position
          prevClosedCaptionLastWord.positionX = null;
          prevClosedCaptionLastWord.positionY = null;
          prevClosedCaption.words = prevClosedCaption.words.concat(closedCaption.words); // add the current CC words to previous CC
          let closedCaptionLastWord = prevClosedCaption.words.at(-1); // get the last word after merge
          // manual position update word position
          closedCaptionLastWord.positionX = wordPositionX;
          closedCaptionLastWord.positionY = wordPositionY;
          prevClosedCaption.endTime = closedCaption.endTime; // set times
          prevClosedCaption = calcCuePointTimes(prevClosedCaption, sequence, scenes, false, false); // ?

          // update cuePoints state
          cuePointsState.set((cuePoints) => {
            // delete the current CC
            delete cuePoints[closedCaption.sid];
            return cuePoints;
          });
          // save the word with newLine false
          saveChanges(
            prevClosedCaption,
            [prevClosedCaptionLastWord, closedCaptionLastWord],
            { current: true },
            chapterSid,
            NaN,
            false
          );
          incrementCuePointsVersion(); // TODO: check to remove
        }
      }
    },
    [closedCaption, chapterSid]
  );

  // check if has previousClosedCaption and enabled on the same chapterSid
  const allowMergeClosedCaption = React.useMemo(() => {
    const prevClosedCaption = previousClosedCaption(closedCaption);
    return !!prevClosedCaption && !!prevClosedCaption?.words?.find((w) => w.enabled && w.enabled[chapterSid]);
  }, [closedCaption]);

  const onStartTimeChanged = React.useCallback(
    async (time: number, minTime: number, maxTime: number) => {
      const startTime = validateTimeValue(time, minTime, maxTime) - Math.ceil(activeTime?.offset || 0);
      const endTime = (activeTime.end || 0) - (activeTime?.offset || 0);
      const timeDuration = endTime - startTime;
      if (timeDuration / activeWords.length < 1) {
        console.error('The time duration is too short to for the amount of words. Please increase the time duration.');
        return;
      }
      const singleWordDuration = Math.round(timeDuration / activeWords.length);

      console.log('onStartTimeChanged', { startTime, endTime, timeDuration, singleWordDuration });

      // delete all active words before the new time
      await deleteActiveWords(activeWords, sequence.sid!);

      //   word.origin = word.word;
      //   word.word = '';
      //   word.changedProperties = [
      //     ...word.changedProperties,
      //     'startTime',
      //     'endTime',
      //     'chapterSid',
      //     'score',
      //     'word',
      //     'flag',
      //     'excluded',
      //     'newLine',
      //     'origin',
      //   ];
      // });

      // try {
      //  await  ClosedCaption.updateWords(closedCaption.sequenceSid || sequence.sid, activeWords);
      // } catch (error) {
      //   console.error(error);
      // }

      // update all active words with the new time
      activeWords.forEach((word, index) => {
        const newStartTime = startTime + index * singleWordDuration;
        const newEndTime =
          index === activeWords.length - 1 ? endTime : Math.min(newStartTime + singleWordDuration, endTime);
        word.word = word.values.origin;
        word.changedProperties = [
          ...word.changedProperties,
          'startTime',
          'endTime',
          'chapterSid',
          'score',
          'word',
          'flag',
          'excluded',
          'newLine',
          'origin',
        ];
        word.startTime = Math.min(newStartTime, newEndTime);
        word.endTime = Math.min(newEndTime, endTime);
      });

      console.log('onStartTimeChanged', { activeWords });
      try {
        // delete all words with old times
        // create new words word new time
        ClosedCaption.updateWords(closedCaption.sequenceSid || sequence.sid, activeWords);
        const firstWord = activeWords[0];
        // update cc start time only if we change the first word timing
        // it cannot take the new time if it virtual chapter
        if (firstWord === closedCaption.words[0]) {
          closedCaption.origin.startTime = isNaN(closedCaption.origin.startTime)
            ? closedCaption.startTime
            : closedCaption.origin.startTime;
          closedCaption.startTime = startTime;
        }

        const calced = calcCuePointTimes(closedCaption, sequence, scenes, false, false);
        setCuePoint(calced, sequence, scenes, true, false);
        // delete all active words origin start and end time
        activeWords.forEach((word) => {
          delete word.values.originStartTime; // TODO: there is better why to do that ?
          delete word.values.originEndTime; // TODO: there is better why to do that ?
        });
      } catch (err) {
        console.error(err);
      }
    },
    [activeWords]
  );

  const onEndTimeChanged = React.useCallback(
    async (time: number, minTime: number, maxTime: number) => {
      const startTime = (activeTime.start || 0) - (activeTime?.offset || 0);
      const endTime = validateTimeValue(time, minTime, maxTime) - Math.floor(activeTime.offset || 0);
      const timeDuration = endTime - startTime;
      if (timeDuration / activeWords.length < 1) {
        console.error('The time duration is too short to for the amount of words. Please increase the time duration.');
        return;
      }
      const singleWordDuration = Math.round(timeDuration / activeWords.length);
      console.log('onEndTimeChanged', { startTime, endTime, timeDuration, singleWordDuration });

      // delete all active words before the new time
      await deleteActiveWords(activeWords, sequence.sid!);

      // update all active words with the new time
      activeWords.forEach((word, index) => {
        const newStartTime = startTime + index * singleWordDuration;
        const newEndTime =
          index === activeWords.length - 1 ? endTime : Math.min(newStartTime + singleWordDuration, endTime);
        word.word = word.values.origin;
        word.changedProperties = [
          ...word.changedProperties,
          'startTime',
          'endTime',
          'chapterSid',
          'score',
          'word',
          'flag',
          'excluded',
          'newLine',
          'origin',
        ];
        word.originStartTime = word.startTime;
        word.originEndTime = word.endTime;
        word.startTime = Math.min(newStartTime, newEndTime);
        word.endTime = Math.min(newEndTime, endTime);
      });

      console.log('onEndTimeChanged', { activeWords });

      try {
        ClosedCaption.updateWords(closedCaption.sequenceSid || sequence.sid, activeWords);
        const lastWord = activeWords[activeWords.length - 1];
        // update cc end time only if we change the last word timing
        // it cannot take the new time if it virtual chapter and not last word
        if (lastWord === closedCaption.words[closedCaption.words.length - 1]) {
          closedCaption.origin.endTime = isNaN(closedCaption.origin.endTime)
            ? closedCaption.endTime
            : closedCaption.origin.endTime;
          closedCaption.endTime = endTime;
        }

        const calced = calcCuePointTimes(closedCaption, sequence, scenes, false, false);
        setCuePoint(calced, sequence, scenes, true, false);
        // delete all active words origin start and end time
        activeWords.forEach((word) => {
          delete word.values.originStartTime; // TODO: there is better why to do that ?
          delete word.values.originEndTime; // TODO: there is better why to do that ?
        });
      } catch (err) {
        console.error(err);
      }
    },
    [activeWords]
  );

  return (
    <Container key={closedCaption.sid}>
      <CuePointPropertiesItem
        type="sentence"
        cuePoint={closedCaption}
        onStartChange={() => {}}
        onEndChange={() => {}}
        seekCalculator={() => (activeTime?.start || 0) + 1}
        minTime={startMinTime}
        maxTime={endMaxTime}
        timerToggle={false}
        onTimerToggle={() => {}}
        chapterSid={chapterSid}
        index={index}
        scrollToIndex={scrollToIndex}
        data-captions-index={index}
        inMenu
        hideTimeCode
      >
        <TimeContainer data-cy="time-container">
          {/* <SVG className="icon" name="timer" /> */} {/* temp remove clock */}
          <TimeCodeComponent
            time={Math.round(activeTime?.start)}
            minTime={startMinTime}
            maxTime={startMaxTime}
            onTimeChange={(time: number) => {
              trackEvent('subtitle-edit', { action: 'time' });
              onStartTimeChanged(time, startMinTime, startMaxTime);
            }}
          />
          <span>|</span>
          <TimeCodeComponent
            time={Math.round(activeTime?.end)}
            minTime={endMinTime}
            maxTime={endMaxTime}
            onTimeChange={(time: number) => {
              trackEvent('subtitle-edit', { action: 'time' });
              onEndTimeChanged(time, endMinTime, endMaxTime);
            }}
          />
        </TimeContainer>
        <EditableCloseCaptionComponent
          onDone={onEditDone}
          allowMerge={allowMergeClosedCaption}
          allowSplit
          dir={sequence.textDirectionByLanguage}
        >
          {wordsString}
        </EditableCloseCaptionComponent>
      </CuePointPropertiesItem>
    </Container>
  );
};
