
import * as Base from '../base/invoiceList/invoice';
	
export {default as Income} from '../invoiceList/income';
	
export {default as Payment} from '../invoiceList/payment';


export interface IInvoice extends Base.IInvoice {

}

export interface IInvoiceProperties extends Base.IInvoiceProperties {

}

export interface IInvoiceFilter extends Base.IInvoiceFilter {

}

export default class Invoice<IType extends IInvoice = IInvoice, ITypeProperties extends IInvoiceProperties = IInvoiceProperties> extends Base.Invoice<IType, ITypeProperties> implements IInvoice {
}