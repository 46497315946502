
import * as Base from './base/closedCaption';
	
export {default as Word} from './closedCaption/word';

export const ClosedCaptionStatus = Base.ClosedCaptionStatus;
export const ClosedCaptionType = Base.ClosedCaptionType;

export interface IClosedCaption extends Base.IClosedCaption {
    next?: ClosedCaption
    prev?: ClosedCaption
}

export interface IClosedCaptionProperties extends Base.IClosedCaptionProperties {

}

export interface IClosedCaptionFilter extends Base.IClosedCaptionFilter {

}

export default class ClosedCaption<IType extends IClosedCaption = IClosedCaption, ITypeProperties extends IClosedCaptionProperties = IClosedCaptionProperties> extends Base.ClosedCaption<IType, ITypeProperties> implements IClosedCaption {

    /**
     * Chapter SID
     */
    menuChapter? : string;

    get next() {
        return this.values.next;
    }

    set next(value) {
        if (this.values.next !== value) {
            this.values.next = value;
        }
    }

    get prev() {
        return this.values.prev;
    }

    set prev(value) {
        if (this.values.prev !== value) {
            this.values.prev = value;
        }
    }
}