import React, {useRef} from 'react';
import { Rnd } from 'react-rnd';

import { animationAssets, scenesState, sequenceSave, sequenceState } from '../../state/sequence';
import {
  editCuePointState,
  colorToRGBA,
  mediaState,
  tempMediaState,
  setCuePoint,
  isTemp,
  lockInteractionState,
  onCuePointChange,
  isFullFrame as isFullFrameCP,
  saveCuePoint, activeEditCuePointState,
} from '../../state/cuePoints';

import TitleCuePoint from '../../client/titleCuePoint';
import { calcTransform, getAnimationProps, LottieCuePointElement } from './graphic';
import EditableTextItem, { decodeHtml, fitTextToElement } from './editableTextItem';
import {MENUS, menuState} from '../../state/menu';
import { currentTimeState, playbackState } from '../../state/playback';

import { AppContext } from '../../lib/app-context';
import { useAnimation } from '../../hooks/useAnimation';
import { mainPlayerStyleState } from '../main-player';
import { trackEvent } from '../../utils/analityics.utils';
import { sequenceFontDirectoryState } from '../player-style';
import { ZINDEX } from '../../../constants/zIndex.const';
import useUserPs from '../../hooks/useUserPs';

const VIDEO_READY_STATE = 4;
const CUE_POINT_TYPE = TitleCuePoint.TYPE;
const TITLE_TYPE = TitleCuePoint.TITLE_TYPE;

const NON_TITLE_TYPES = [CUE_POINT_TYPE.LOGO, CUE_POINT_TYPE.INTRO, CUE_POINT_TYPE.OUTRO];

/**
 * @param {{
 *  width: number,
 *  height: number,
 *  cuePoint: TitleCuePoint,
 *  time: number,
 * 	animate: boolean,
 *  duration: number,
 *  style: CSSProperties
 * }} props
 */
export default function TitleCuePointElement(props) {
  const {
    style,
    cuePoint,
    animate,
    onTime,
    cuePointTime,
    time,
    duration,
    innerSize,
    outerSize,
    outerPos,
    shouldMeasure,
    onFit,
    reFit,
    inMenu,
    measured,
    inAssetsDialog,
    isDraft,
    isPreview,
  } = props;
  const sequence = sequenceState.use();
  const framePositionX = sequenceState.use((sequence) => sequence?.style?.framePositionX);
  const framePositionY = sequenceState.use((sequence) => sequence?.style?.framePositionY);
  const frameWidth = sequenceState.use((sequence) => sequence?.style?.frameWidth);

  const useFrame = sequenceState.use((sequence) => sequence.editRules?.useFrame);
  const fontSize = React.useRef();

  const isLogo = cuePoint.type === CUE_POINT_TYPE.LOGO;
  const isFrame = cuePoint.type === CUE_POINT_TYPE.FRAME;
  const isOutroOrIntro = cuePoint.type === CUE_POINT_TYPE.OUTRO || cuePoint.type === CUE_POINT_TYPE.INTRO;
  const isSlide = cuePoint.titleType === TITLE_TYPE.SLIDE;
  const enabled = isFrame
    ? sequenceState.use((sequence) => sequence.editRules?.useFrame)
    : NON_TITLE_TYPES.includes(cuePoint.type) || sequenceState.use((sequence) => sequence.useTitleCuePoints);
  const isCuePointActive = activeEditCuePointState.use() === cuePoint.sid;

  const textAnimation = animationAssets.use((animations) => animations.text);
  const isMedia =
    cuePoint.type === CUE_POINT_TYPE.LOGO ||
    cuePoint.titleType === TITLE_TYPE.IMAGE ||
    cuePoint.titleType === TITLE_TYPE.VIDEO ||
    cuePoint.titleType === TITLE_TYPE.SVG

  if (cuePoint.thumbUrl && isMedia) {
    throw new Error('Unexpected case');
  }

  const isResizableLottie = !isMedia && !cuePoint.titleType && textAnimation;

  const lockInteraction = lockInteractionState.use();
  const isFullFrame = isFullFrameCP(cuePoint);

  React.useEffect(() => {
    if (!isFrame) {
      return;
    }
    if (isFrame && onTime && useFrame && !isPreview) {
      mainPlayerStyleState.set((style) => ({
        ...style,
        top: sequence?.style?.framePositionX != undefined ? sequence.style.framePositionX * 100 + '%' : '12.5%',
        left: sequence?.style?.framePositionY != undefined ? sequence.style.framePositionY * 100 + '%' : '12.5%',
        width: sequence?.style?.frameWidth != undefined ? sequence.style.frameWidth * 100 + '%' : '75%',
        height: sequence?.style?.frameWidth != undefined ? sequence.style.frameWidth * 100 + '%' : '75%',
      }));
    } else {
      const mainPlayerStyle = mainPlayerStyleState.get();
      const currentTime = currentTimeState.get() * 1000;
      if (
        (mainPlayerStyle?.top || mainPlayerStyle?.left) &&
        (currentTime < cuePoint.times._.start || currentTime > cuePoint.times._.end)
      ) {
        mainPlayerStyleState.set((style) => ({
          ...style,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }));
      }
    }
  }, [onTime, useFrame, framePositionX, framePositionY, frameWidth]);

  if (!enabled || (!cuePoint.activeTimes.length && onTime !== true && !inMenu) || cuePoint.hide) {
    return null;
  }

  const resizeableStyle = { zIndex: 4, ...(outerPos || {}) };

  if (isFrame) {
    resizeableStyle.zIndex = ZINDEX.FRAME;
  } else if (isLogo) {
    resizeableStyle.zIndex = ZINDEX.LOGO;
  } else if (isOutroOrIntro) {
    resizeableStyle.zIndex = ZINDEX.OUTRO;
  } else if (isSlide) {
    resizeableStyle.zIndex = ZINDEX.SLIDE;
  } else {
    resizeableStyle.zIndex = ZINDEX.GRAPHIC;
  }

  if (shouldMeasure && !measured) {
    resizeableStyle.opacity = 0;
  }

  function percentToX(outerPos) {
    if ((isMedia && !cuePoint.left && !cuePoint.right) || isFullFrame) {
      return 0;
    }
    if (cuePoint.left === undefined || cuePoint.left === null) {
      return (props.width * (100 - (cuePoint.right || 0) - (outerPos || 0) - cuePoint.width)) / 100;
    }
    const center = cuePoint.alignment === 'center' || cuePoint.alignment === 'both';
    return (
      (props.width * (cuePoint.left + (outerPos || 0))) / 100 +
      (center ? ((cuePoint.width || 0) * -0.5 * props.width) / 100 : 0)
    );
    // return props.width * cuePoint.left / 100 + (cuePoint.width || 0) * (cuePoint.translateX || 0) / 100 * props.width / 100
  }

  function percentToY() {
    if (isMedia && !cuePoint.top && !cuePoint.bottom) {
      return 0;
    }
    if (cuePoint.top === undefined || cuePoint.top === null) {
      return (props.height * (100 - (cuePoint.bottom || 0) - cuePoint.height)) / 100;
    }
    const middle = cuePoint.alignment === 'middle' || cuePoint.alignment === 'both';
    return (props.height * cuePoint.top) / 100 + (middle ? ((cuePoint.height || 0) * -0.5 * props.height) / 100 : 0);
    // return props.height * cuePoint.top / 100 + (cuePoint.height || 0) * (cuePoint.translateY || 0) / 100 * props.height / 100
  }

  function xToPercent(x) {
    return parseFloat(((x / props.width) * 100).toFixed(2));
  }

  function yToPercent(y) {
    return parseFloat(((y / props.height) * 100).toFixed(2));
  }

  let ratio = 1;
  let { width, height } = cuePoint;
  if (cuePoint.tmpWidth) {
    width = cuePoint.tmpWidth;
  }
  if (cuePoint.tmpHeight) {
    height = cuePoint.tmpHeight;
  }
  if (isMedia && !width && !height) {
    width = 100;
    height = 100;
  } else if (isResizableLottie) {
    const [w, h] = sequence.aspectRatio.split(':');
    ratio = w / h;
    width /= ratio;
  }

  const textElement = React.createRef();

  const resizeableProps = {
    className: onTime ? `moveable-element ${isCuePointActive ? 'cuePointActive' : ""}` : '',
    style: resizeableStyle,
    lockAspectRatio: true,
    enableResizing: true,
    disableDragging: false,
    position: {
      x: (sequence.visualPackageVersion &&  sequence.visualPackageVersion > 1) ? percentToX() : percentToX(outerPos), // ? new way : legacy
      y: percentToY(),
    },
    size: {
      width: width && !isResizableLottie ? `${width}%` : 'auto',
      height: height ? `${height}%` : 'auto',
    },
    onDragStop: (e, { x, y }) => {
      if (sequence.visualPackageVersion &&  sequence.visualPackageVersion > 1) {
        // new way
        cuePoint.left = xToPercent(x); 
      } else {
        // legacy support 
        cuePoint.left = xToPercent(x) - (outerPos || 0);
      }
      cuePoint.top = yToPercent(y);
      const hasChanges = cuePoint.changedProperties.some((i) => i === 'left' || i === 'top');
      if (hasChanges) {
        onCuePointChange(cuePoint);
        trackEvent('visuals-edit', { type: 'graphic-text', action: 'position' });
      }
    },
    /**
     * @param {HTMLElement} ref
     */
    onResizeStop: (e, direction, ref, delta, { x, y }) => {
      const heightRatio = yToPercent(ref.clientHeight) / (cuePoint?.height || yToPercent(ref.clientHeight));
      cuePoint.width = cuePoint.width || isTemp(cuePoint) ? xToPercent(ref.clientWidth) * ratio : null;
      cuePoint.height = cuePoint.height || isTemp(cuePoint) ? yToPercent(ref.clientHeight) : null;
      if (sequence.visualPackageVersion &&  sequence.visualPackageVersion > 1) {
        // new way
        cuePoint.left = xToPercent(x); 
      } else {
        // legacy support
        cuePoint.left = xToPercent(x) - (outerPos || 0) * heightRatio;
      }
      cuePoint.top = yToPercent(y);
      cuePoint.tmpWidth = null;
      cuePoint.tmpHeight = null;
     
      if (fontSize.current) {
        cuePoint.fontSize = yToPercent(fontSize.current);
      }
      const hasChanges = cuePoint.changedProperties.some((i) => i === 'width' || i === 'height');
      if (hasChanges) {
        onCuePointChange(cuePoint);
        trackEvent('visuals-edit', { type: 'graphic-text', action: 'size' });
      }

      // update logo size on sequence.logo
      if (isLogo) {
        // saveCuePoint to avoid missing updates
        saveCuePoint(cuePoint);
        sequence.logo.size =  cuePoint.width;
        sequenceSave(sequence);
      }
      reFit && reFit();
    },
  };

  if (cuePoint.alignment === 'both') {
    resizeableProps.disableDragging = true;
  } else if (cuePoint.alignment === 'center') {
    resizeableProps.dragAxis = 'y';
  } else if (cuePoint.alignment === 'middle') {
    resizeableProps.dragAxis = 'x';
  }

  const localStyle = {};
  if (cuePoint.alignment) {
    const { slideY, slideX, scale, scaleY, scaleX } = style;
    localStyle.transform = calcTransform({ slideY, slideX, scale, scaleY, scaleX });
  }

  function onEdit() {
    if (cuePoint.type === CUE_POINT_TYPE.LOGO || cuePoint.type === CUE_POINT_TYPE.WATERMARK) {
      return;
    }
    const defaultType =
      cuePoint.titleType === TITLE_TYPE.VIDEO
        ? 'video'
        : cuePoint.titleType === TITLE_TYPE.IMAGE
        ? 'image'
        : cuePoint.assetSid
        ? 'asset'
        : 'icon';
    editCuePointState.set({ cuePoint, defaultType });
  }

  if (isMedia) {
    localStyle.height = '100%';
    localStyle.width = '100%';

    let className = 'title-element';
    if (cuePoint.type === CUE_POINT_TYPE.LOGO) {
      resizeableProps.bounds = '.logo-bounds';
      resizeableProps.minWidth="5.9%"
      localStyle.zIndex = 15;
      localStyle.top = '0';
      localStyle.left = '0';
      className = 'logo-element';
    }
    return (
      <Rnd
        onMouseDown={()=> {
            activeEditCuePointState.set(cuePoint.sid);
            menuState.set(MENUS.BRAND);
          }
        }
        className={`moveable-element ${isCuePointActive ? 'cuePointActive' : ""}`}
        {...resizeableProps}
        enableResizing={!lockInteraction && !isDraft}
        disableDragging={lockInteraction || isDraft}
        resizeHandleClasses={{
          bottom: 'rnd-handle bottom-handle',
          bottomLeft: 'rnd-handle bottom-left-handle',
          bottomRight: 'rnd-handle bottom-right-handle',
          left: 'rnd-handle left-handle',
          right: 'rnd-handle right-handle',
          top: 'rnd-handle top-handle',
          topLeft: 'rnd-handle top-left-handle',
          topRight: 'rnd-handle top-right-handle',
        }}
        onDoubleClick={onEdit}
      >
        <MediaCuePointElement
          style={{ ...style, ...localStyle }}
          cuePoint={cuePoint}
          time={time}
          onTime={onTime}
          animate={animate}
          className={className}
        />
      </Rnd>
    );
  }

  if ((!cuePoint.width || cuePoint.width < 99) && (!cuePoint.height || cuePoint.height < 99)) {
    resizeableProps.bounds = cuePoint.parentSid ? `#cue-point-${cuePoint.parentSid}` : 'parent';
  }

  function fitText() {
    if (!textElement.current || !cuePoint.text) {
      return;
    }

    fontSize.current = fitTextToElement(textElement.current, cuePoint.text);
    textElement.current.style.fontSize = fontSize.current + 'px';
  }

  function onFontSizeChange(size) {
    // fontSize.current = size;
    cuePoint.fontSize = yToPercent(size);
  }

  function onSizeChange(width, height) {
    cuePoint.width = xToPercent(width);
    cuePoint.height = yToPercent(height);
  }

  localStyle.textAlign = 'center';
  localStyle.fontSize = (props.height * (cuePoint.fontSize || sequence?.style?.fontSize)) / 100;

  const size = {
    height: resizeableProps.size.height,
    width: outerSize?.width || resizeableProps.size.width,
  };

  return (
    <Rnd
      onMouseDown={()=> {
        activeEditCuePointState.set(cuePoint.sid);
        switch(cuePoint.type) {
          case CUE_POINT_TYPE.INTRO:
            menuState.set(MENUS.INTRO_OUTRO);
            break;
          case CUE_POINT_TYPE.OUTRO:
            menuState.set(MENUS.INTRO_OUTRO);
            break;
          case CUE_POINT_TYPE.FRAME:
            menuState.set(MENUS.FRAME);
            break;
          case CUE_POINT_TYPE.TITLE:
            menuState.set(MENUS.VISUALS);
            break;
          }
        }
      }
      className={`
      moveable-element 
          ${onTime && "draggable-element"}
          ${isCuePointActive ? 'cuePointActive' : ''}
      `}
      data-sid={cuePoint.sid}
      {...resizeableProps}
      style={{ ...(resizeableProps.style || {}), visibility: !onTime && cuePointTime === null ? 'hidden' : 'visible' }}
      size={size}
      onResize={fitText}
      enableResizing={!isFullFrame && !lockInteraction && !isDraft}
      disableDragging={isFullFrame || lockInteraction || isDraft}
      resizeHandleClasses={{
        bottom: 'rnd-handle bottom-handle',
        bottomLeft: 'rnd-handle bottom-left-handle',
        bottomRight: 'rnd-handle bottom-right-handle',
        left: 'rnd-handle left-handle',
        right: 'rnd-handle right-handle',
        top: 'rnd-handle top-handle',
        topLeft: 'rnd-handle top-left-handle',
        topRight: 'rnd-handle top-right-handle',
      }}
      // onResize={(e, direction, ref) => fitText(ref)}
    >
      <TextCuePointElement
        style={{ ...style, ...localStyle, ...resizeableProps.size }}
        innerRef={textElement}
        cuePoint={cuePoint}
        time={time}
        animate={animate}
        duration={duration}
        // onChange={fitText}
        measured={innerSize && outerSize}
        onFit={onFit}
        className="title-element"
        onFontSizeChange={isFullFrame && onFontSizeChange}
        onSizeChange={!isFullFrame && onSizeChange}
        onTime={onTime}
        innerSize={innerSize}
        outerSize={outerSize}
        outerPos={outerPos}
        isDraft={isDraft}
        inAssetsDialog={inAssetsDialog}
        {...props}
      />
    </Rnd>
  );
}

/**
 * @param {{
 *  cuePoint: TitleCuePoint,
 *  disabled: boolean,
 *  onFontSizeChange: Function,
 *  className: string,
 *  innerRef: React.RefObject<HTMLElement> | Function,
 *  onClick: Function,
 *  style: CSSProperties,
 * 	inMenu: boolean,
 *  time: number,
 * 	animate: boolean,
 * }} props
 */
export function TextCuePointElement(props) {
  const {
    src,
    cuePoint,
    disabled,
    className,
    innerRef,
    style,
    onClick,
    onMouseOver,
    onMouseOut,
    time,
    animate,
    duration,
    onFontSizeChange,
    onSizeChange,
    inMenu,
    inAssetsDialog,
    onTime,
    animationSid,
    onFit,
    reFit,
    measured,
    innerSize,
    outerSize,
    outerPos,
  } = props;

  const { config, user } = React.useContext(AppContext);
  const sequenceFontDirectory = sequenceFontDirectoryState.use();
  const scenes = scenesState.use((scenes) => scenes.map);
  const sequence = sequenceState.use();
  const sequenceLogoAssetSid = sequenceState.use((sequence) => {
    if (sequence?.style?.introLogoAssetSid && cuePoint.type === CUE_POINT_TYPE.INTRO) {
      return sequence.style.introLogoAssetSid;
    } else if (sequence?.style?.outroLogoAssetSid && cuePoint.type === CUE_POINT_TYPE.OUTRO) {
      return sequence.style.outroLogoAssetSid;
    } else {
      return sequence.logo?.assetSid;
    }
  });
  const thumbnailVersion = sequenceState.use((sequence) => sequence.logo?.thumbnailVersion);
  const [spellCheck, setSpellCheck] = React.useState(false);

  const isFrame = cuePoint.type === CUE_POINT_TYPE.FRAME;
  const animationAssetSid = inAssetsDialog
    ? animationSid
    : cuePoint.assetSid
    ? cuePoint.assetSid
    : cuePoint.type === CUE_POINT_TYPE.INTRO
    ? sequence?.style?.introAssetSid
    : cuePoint.type === CUE_POINT_TYPE.OUTRO
    ? sequence?.style?.outroAssetSid
    : isFrame
    ? sequence?.style?.frameAssetSid
    : cuePoint.titleType === TITLE_TYPE.SLIDE
    ? sequence?.style?.slideAssetSid
    : sequence?.style?.textAssetSid;

  const initAnimation = React.useRef(0);

  React.useEffect(() => (initAnimation.current ? reFit && reFit() : initAnimation.current++), [animationAssetSid]);

  const animationAsset = useAnimation(animationAssetSid, sequence.aspectRatio);

  const withUserPs = useUserPs();

  const getLogoSrc = () =>
    sequence && sequence.logo && withUserPs(`${config.CHUNKS_URL}/asset/${sequenceLogoAssetSid}/logo/${thumbnailVersion}.png`);
  const [logoSrc, setLogoSrc] = React.useState(getLogoSrc());
  React.useEffect(() => setLogoSrc(getLogoSrc), [sequenceLogoAssetSid]);

  function onKeyUp(e) {
    // escape
    if (e.which === 27) {
      e.preventDefault();
      e.target.blur();
    }
  }

  async function onTextChange({ target, animation }) {
    const text = target.textContent;
    cuePoint.text = decodeHtml(text).replace(/[\n\r]+/g, '\n');
    await cuePoint.calcSizeByLottie(animation);
    setCuePoint(cuePoint, sequence, scenes, false);
  }

  async function onTextChangeDone() {
    onCuePointChange(cuePoint);
    setSpellCheck(false);
  }

  async function onBlur({ target }) {
    var text = target?.innerText;
    if (text?.length && text !== cuePoint.text) {
      onTextChangeDone(text);
    } else {
      target.innerText = cuePoint.text;
    }
  }

  let classes = ['sequence-font'];
  if (cuePoint.titleType === TitleCuePoint.TITLE_TYPE.SLIDE) {
    classes.push('slide-element');
  }
  if (className) {
    classes.push(className);
  }
  if (cuePoint.fontItalic) {
    classes.push('font-italic');
  }
  if (cuePoint.fontWeight === undefined) {
    classes.push('font-' + sequence.style.fontWeight.toLowerCase());
  } else {
    classes.push('font-' + cuePoint.fontWeight.toLowerCase());
  }

  if (cuePoint.backgroundColorIndex) {
    classes.push('color-bg-dominant-' + cuePoint.backgroundColorIndex);
  }
  if (cuePoint.colorIndex) {
    classes.push('color-text-dominant-' + cuePoint.colorIndex);
  }

  function calcColor(color, colorIndex) {
    return color ? color : colorIndex ? undefined : 'transparent';
  }

  // return <div>test 4</div>
  if (animationAssetSid) {
    // const source = src || `${config.CONTENT_URL}/c/t/asset/f/${animationAssetSid}/${sequence.aspectRatio}/${user?.cacheVersion || 0}.json`;
    const animationTextFields =
      cuePoint.type === CUE_POINT_TYPE.INTRO ||
      cuePoint.type === CUE_POINT_TYPE.OUTRO ||
      cuePoint.titleType === TITLE_TYPE.SLIDE
        ? ['title', 'text']
        : ['text'];

    return (
      <LottieCuePointElement
        // src={source}
        animationSid={animationAssetSid}
        fontSize={cuePoint.fontSize}
        demoColors={inAssetsDialog && animationAsset && getAnimationProps(animationAsset)}
        inMenu={inMenu}
        style={style}
        inAssetsDialog={inAssetsDialog}
        time={time}
        animate={animate}
        className={classes.join(' ')}
        animation={animationAsset}
        cuePoint={cuePoint}
        textFields={animationTextFields}
        logo={logoSrc}
        // onKeyUp={onTextChange}
        // onBlur={onBlur}
        onFit={onFit}
        newVisualVersion={!!(sequence.visualPackageVersion && sequence.visualPackageVersion > 1)}
        measured={measured}
        innerSize={innerSize}
        outerSize={outerSize}
        outerPos={outerPos}
        editable={false}
        onTime={onTime}
        onClick={onClick}
        loop={isFrame || inAssetsDialog || inMenu}
        sequenceFontDirectory={sequenceFontDirectory}
        textDirection={sequence.textDirectionByLanguage}
        {...props}
      />
    );
  }

  const localStyle = { ...style };
  if (cuePoint.backgroundColor) {
    localStyle.backgroundColor = colorToRGBA(cuePoint.backgroundColor, sequence.style.backgroundOpacity);
  }
  if (cuePoint.color) {
    localStyle.color = cuePoint.color;
  }

  classes.push('text-element');

  var text = cuePoint.tmpText || cuePoint.text || '';
  if (time !== undefined && cuePoint.animations) {
    cuePoint.animations
      .filter((a) => a.type === 'typewriter')
      .forEach((animation) => {
        var startTime = animation.start >= 0 ? animation.start : duration + animation.start;
        if (startTime <= time && time <= startTime + animation.duration) {
          var progress = (time - startTime) / animation.duration;
          text = text.substr(0, text.length * progress);
        }
      });
  }
  const html = text.replace(/\n/g, '<br/>');

  return (
    <EditableTextItem
      cuePoint={cuePoint}
      spellCheck={spellCheck}
      html={html}
      style={localStyle}
      innerRef={innerRef}
      className={classes.join(' ')}
      disabled={disabled}
      onSizeChange={onSizeChange}
      onFontSizeChange={onFontSizeChange}
      onClick={(event) => {
        menuState.set(MENUS.VISUALS);
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        if (onClick) {
          onClick(event);
        }
      }}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      onFocus={() => setSpellCheck(true)}
      onMouseOver={onMouseOver && onMouseOver}
      onMouseOut={onMouseOut && onMouseOut}
    />
  );
}
// TextCuePointElement.whyDidYouRender = true;

export function LogoCuePointElement(props) {
  const { config } = React.useContext(AppContext);
  const sequence = sequenceState.use();
  const thumbnailVersion = sequenceState.use((sequence) => sequence.logo?.thumbnailVersion);
  const withUserPs = useUserPs();
  const getLogoSrc = () =>
    sequence && sequence.logo && withUserPs(`${config.CHUNKS_URL}/t/${sequence.sid}/${sequence.logo.sid}/${thumbnailVersion}.png`);
  const [logoSrc, setLogoSrc] = React.useState(getLogoSrc());

  React.useEffect(() => {
    if (thumbnailVersion && sequence?.logo?.sid) {
      setLogoSrc(getLogoSrc());
    }
  }, [thumbnailVersion, sequence?.logo?.sid]);

  return <img src={logoSrc} {...props} />;
}

/**
 * @param {{
 *  cuePoint: TitleCuePoint,
 * 	inMenu: boolean,
 *  time: number,
 * 	animate: boolean,
 *  className: string,
 *  innerRef: React.Ref,
 *  style: CSSProperties
 * }} props
 */
export function MediaCuePointElement({ inMenu, onTime, time, animate, className, cuePoint, innerRef, style, ...rest }) {
  const { config } = React.useContext(AppContext);
  /**
   * @type {React.RefObject<HTMLImageElement>}
   */
  const ref = innerRef || React.createRef();
  const media = mediaState.use();
  const tmpMedia = tempMediaState.use();
  const { version } = playbackState.use();
  const withUserPs = useUserPs();

  React.useEffect(() => {
    if ((cuePoint.titleType === TITLE_TYPE.VIDEO) && ref.current && onTime && !cuePoint.thumb) {
      if (animate && ref.current.paused) {
        ref.current.play();
      } else if ((!animate || ref.current.paused) && ref.current.pause) {
        ref.current.pause();
      }
    }
  }, [animate]);

  React.useEffect(() => {
    if (
      (cuePoint.titleType === TITLE_TYPE.VIDEO) &&
      !animate &&
      time &&
      ref.current &&
      !cuePoint.thumb &&
      ref.current.paused &&
      ref.current.readyState === VIDEO_READY_STATE
    ) {
      ref.current.currentTime = time / 1000;
    }
  }, [version]);

  const {
    titleType,
    sequenceSid,
    sid,
    thumbnailVersion,
    fullSize,
    height,
    type,
    tmpImageUrl,
    assetSid,
    tmpSvg,
    svg,
    tmpVideoUrl,
    thumb,
    contentVersion,
    url,
   } = cuePoint;

  if (assetSid) {
    const src = withUserPs(`${config.CHUNKS_URL}/t/asset/b/${assetSid}/${sid}.png`);
    return (
      <img
        ref={ref}
        id={`cue-point-${sid}`}
        className={`${className} image-element asset-src`}
        src={src}
        style={style}
        {...rest}
      />
    );
  }

  let src;
  switch (type) {
    case CUE_POINT_TYPE.LOGO:
      return (
        <LogoCuePointElement
          id={`cue-point-${sid}`}
          className={`${className} logo-element`}
          style={style}
          {...rest}
        />
      );
    case CUE_POINT_TYPE.TITLE:
      switch (titleType) {
        case TITLE_TYPE.IMAGE:
          if (url || tmpImageUrl) {
            return (
              <img
                ref={ref}
                data-cy="image-element"
                id={`cue-point-${sid}`}
                className={`${className} image-element tmp-img-url`}
                src={url || tmpImageUrl}
                style={style}
                {...rest}
              />
            );
          }
          src = fullSize
              ? withUserPs(`${config.CHUNKS_URL}/c/${sequenceSid}/${sid}/${thumbnailVersion}.png`)
              : withUserPs(`${config.CHUNKS_URL}/cc/${sequenceSid}/TitleCuePoint/${sid}/${inMenu ? 90 : 800}/${thumbnailVersion}.png`);
          return (
            <img
              ref={ref}
              id={`cue-point-${sid}`}
              data-cy="monitor-image"
              className={`${className} image-element image-src`}
              src={src}
              style={style}
              {...rest}
            />
          );
        case TITLE_TYPE.SVG:
          if (tmpSvg) {
            return (
              <div
                ref={ref}
                dangerouslySetInnerHTML={{ __html: tmpSvg }}
                id={`cue-point-${sid}`}
                className={`${className} icon-element tmp-svg`}
                style={style}
                {...rest}
              />
            );
          }
          return (
            <div
              ref={ref}
              dangerouslySetInnerHTML={{ __html: svg }}
              id={`cue-point-${sid}`}
              className={`${className} icon-element svg-src`}
              style={style}
              {...rest}
            />
          );
        case TITLE_TYPE.VIDEO:
          if (tmpVideoUrl) {
            const loadedVideo = tmpMedia[tmpVideoUrl] || tmpVideoUrl;
            return (
              <video
                ref={ref}
                data-cy="video-element"
                id={`cue-point-${sid}`}
                className={`${className} video-element tmp-video-url`}
                src={loadedVideo}
                autoPlay
                style={style}
                {...rest}
              />
            );
          }
          if (thumb) {
            return (
              <img
                ref={ref}
                id={`cue-point-${sid}`}
                className={`${className} image-element video-thumb`}
                src={thumb}
                style={style}
                {...rest}
              />
            );
          }
          if (onTime) {
            return null;
            src = withUserPs(`${config.CHUNKS_URL}/c/${sequenceSid}/${sid}/${contentVersion}.mp4`)
            // const poster = `${config.CHUNKS_URL}/c/${sequenceSid}/${sid}/${thumbnailVersion}.png`
            return (
              <video
                ref={ref}
                id={`cue-point-${sid}`}
                className={`${className} video-element video-src`}
                src={src}
                style={height ? { ...style, width: 'auto' } : { ...style, height: 'auto' }}
                {...rest}
              />
            );
          }
          src = fullSize
              ? withUserPs(`${config.CHUNKS_URL}/c/${sequenceSid}/${sid}/${thumbnailVersion}.png`)
              : withUserPs(`${config.CHUNKS_URL}/cc/${sequenceSid}/TitleCuePoint/${sid}/${inMenu ? 90 : 800}/${thumbnailVersion}.png`);
          return (
            <img
              ref={ref}
              id={`cue-point-${sid}`}
              className={`${className} video-element video-poster`}
              src={src}
              style={height ? { ...style, width: 'auto' } : { ...style, height: 'auto' }}
              {...rest}
            />
          );
        default:
          throw new Error('Unexpected title type');
      }
  }

  return <div>{sid}</div>;
}
