
import * as Base from '../base/sequence/style';


export interface IStyle extends Base.IStyle {

}

export interface IStyleProperties extends Base.IStyleProperties {

}

export interface IStyleFilter extends Base.IStyleFilter {

}

export default class Style<IType extends IStyle = IStyle, ITypeProperties extends IStyleProperties = IStyleProperties> extends Base.Style<IType, ITypeProperties> implements IStyle {
}