

export function onProjectTitleKeyPress(e) {
    let pattern = (/[A-Za-z0-9\ \-\_\/]/gi)
    var key = e.key;
    
    if (!pattern.test(key)) {
      e.preventDefault();
    }
    if (e.which === 13) {
      e.preventDefault()
      e.target.blur()
    }
  }