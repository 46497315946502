import React from 'react'
import TimePicker from 'react-time-picker';
import { setCuePoint } from '../state/cuePoints';
import { scenesState, sequenceState } from '../state/sequence';


function timeToObject(time, cuePoint) {
    const obj = {}
    time = Math.round(time / 1000)
    obj.seconds = time % 60
    time = Math.floor(time / 60)
    obj.minutes = time % 60
    time = Math.floor(time / 60)
    obj.hours = time % 60
    if (typeof obj.seconds !== 'number' || typeof obj.minutes !== 'number' || typeof obj.hours !== 'number') {
        console.error({ time, cuePoint, obj })
    }
    return obj
}

export function formatTime(time, cuePoint) {
    if (time === undefined || time === null || time === NaN) {
        return '00:00:00'
    }
    if (typeof time === 'number') {
        time = timeToObject(time, cuePoint)
    } else (
        console.error({ time, cuePoint })
    )
    return (
        time &&
        `${(time.hours)
            .toString()
            .padStart(2, '0')}:${(time.minutes)
                .toString()
                .padStart(2, '0')}:${(time.seconds)
                    .toString()
                    .padStart(2, '0')}`
    )
}

/**
 * 
 * @param {{
 *  value: number,
 *  minTime: number,
 *  maxTime: number,
 *  onChange: Function
 * }} props
 * @returns 
 */
export default function TimeCode({ value, minTime, maxTime, onChange, cuePoint, ...rest }) {
    const sequenceDuration = sequenceState.use(sequence => sequence?.duration || 0)
    const [time, setTime] = React.useState(value);
    const formattedMinTime = React.useMemo(() => formatTime(minTime, cuePoint), [minTime])
    const formattedMaxTime = React.useMemo(() => formatTime(maxTime, cuePoint), [maxTime])
    const [formattedTime, setFotmattedTime] = React.useState(formatTime(time, cuePoint))
    const timePickerRef = React.useRef()
    React.useEffect(() => setTime(value), [value])

    /**
     * 
     * @param {string} str '00:00:00'
     * @returns {number} time in millis
     */
    function toTime(str) {
        const t = str?.split(':').reverse().reduce((time, part, i) => (time + part * (60 ** i)), 0) * 1000;
        return t
    }

    function resetTime() {
        setFotmattedTime(undefined)
        setTimeout(() => {
            setFotmattedTime(formatTime(time, cuePoint))
        }, 20);
    }

    function onTimeChange(change, apply) {
        const t = toTime(change);
        const invalidTime = t < minTime || t > maxTime || !Number.isInteger(t)

        if (apply) {
            onChange && onChange(t, apply)
        }
        if (invalidTime || apply) {
            return resetTime()
        }
        if (t !== value) {
            setTime(t);
            onChange && onChange(t, apply)
        }
    }

    function applyTime(e) {
        onTimeChange(timePickerRef.current.props.value, true)
        // old value, ignored
    }

    const shouldShowHours = sequenceDuration / 60 > 60

    return <TimePicker
        ref={timePickerRef}
        format={shouldShowHours ? "HH:mm:ss" : "mm:ss"}
        className="non-hour"
        clearIcon={null}
        disableClock={true}
        maxDetail="second"
        minTime={formattedMinTime}
        maxTime={formattedMaxTime}
        value={formattedTime}
        onChange={onTimeChange}
        onBlur={applyTime}
        {...rest}
    />
}