
import Base, {IBase, IBaseType, IBaseFilter, IBaseProperties, Pager} from './base'

export interface IVideo extends IBase {
	externalId?: string | null;
	description?: string | null;
	thumbUrl?: string | null;
	imageUrl?: string | null;
	url?: string | null;
	credit?: string | null;
	creditRequired?: boolean | null;
	width?: number | null;
	height?: number | null;
	isSource?: boolean | null;
}

export interface IVideoProperties extends IBaseProperties {
	externalId?: boolean;
	description?: boolean;
	thumbUrl?: boolean;
	imageUrl?: boolean;
	url?: boolean;
	credit?: boolean;
	creditRequired?: boolean;
	width?: boolean;
	height?: boolean;
	isSource?: boolean;
}

export interface IVideoFilter extends IBaseFilter {
}

export class Video<IType extends IVideo = IVideo, ITypeProperties extends IVideoProperties = IVideoProperties> extends Base<IType, ITypeProperties> implements IVideo {
	
	static className = 'Video';
	static NAME = 'video';
	static PKS = [
		'sid',
	];
	static PROPERTIES = {...Base.PROPERTIES, 
		externalId: true,
		description: true,
		thumbUrl: true,
		imageUrl: true,
		url: true,
		credit: true,
		creditRequired: true,
		width: true,
		height: true,
		isSource: true,
	};
	
	set(values: IType, parent?: IBase, name?: string, ignoreModified = false): IType {
		return super.set(values, parent, name, ignoreModified);
	}
	
	get externalId(): string | null | undefined {
		return this.values.externalId
	}
			
	set externalId(value: string | null | undefined) {
		if(this.values.externalId !== value) {
			this.propertyChanged('externalId', value);
			this.values.externalId = value;
		}
	}
			
	get description(): string | null | undefined {
		return this.values.description
	}
			
	set description(value: string | null | undefined) {
		if(this.values.description !== value) {
			this.propertyChanged('description', value);
			this.values.description = value;
		}
	}
			
	get thumbUrl(): string | null | undefined {
		return this.values.thumbUrl
	}
			
	set thumbUrl(value: string | null | undefined) {
		if(this.values.thumbUrl !== value) {
			this.propertyChanged('thumbUrl', value);
			this.values.thumbUrl = value;
		}
	}
			
	get imageUrl(): string | null | undefined {
		return this.values.imageUrl
	}
			
	set imageUrl(value: string | null | undefined) {
		if(this.values.imageUrl !== value) {
			this.propertyChanged('imageUrl', value);
			this.values.imageUrl = value;
		}
	}
			
	get url(): string | null | undefined {
		return this.values.url
	}
			
	set url(value: string | null | undefined) {
		if(this.values.url !== value) {
			this.propertyChanged('url', value);
			this.values.url = value;
		}
	}
			
	get credit(): string | null | undefined {
		return this.values.credit
	}
			
	set credit(value: string | null | undefined) {
		if(this.values.credit !== value) {
			this.propertyChanged('credit', value);
			this.values.credit = value;
		}
	}
			
	get creditRequired(): boolean | null | undefined {
		return this.values.creditRequired
	}
			
	set creditRequired(value: boolean | null | undefined) {
		if(this.values.creditRequired !== value) {
			this.propertyChanged('creditRequired', value);
			this.values.creditRequired = value;
		}
	}
			
	get width(): number | null | undefined {
		return this.values.width
	}
			
	set width(value: number | null | undefined) {
		if(this.values.width !== value) {
			this.propertyChanged('width', value);
			this.values.width = value;
		}
	}
			
	get height(): number | null | undefined {
		return this.values.height
	}
			
	set height(value: number | null | undefined) {
		if(this.values.height !== value) {
			this.propertyChanged('height', value);
			this.values.height = value;
		}
	}
			
	get isSource(): boolean | null | undefined {
		return this.values.isSource
	}
			
	set isSource(value: boolean | null | undefined) {
		if(this.values.isSource !== value) {
			this.propertyChanged('isSource', value);
			this.values.isSource = value;
		}
	}
			
	
	static get<IType extends IVideo = IVideo>(sid: string | null = null, properties: IBaseProperties | null = null): Promise<IType | null> {
		return super.get<IType>(sid, properties);
	}

	static count<IFilter extends IVideoFilter = IVideoFilter>(filter: IFilter | null = null) : Promise<number> {
		return super.count<IFilter>(filter);
	}

	static list<IFilter extends IVideoFilter = IVideoFilter, IType extends IVideo = IVideo>(filter: IFilter | null = null, pager: Pager | null = null, properties: IBaseProperties | null = null) : Promise<IType[]> {
		return super.list<IFilter, IType>(filter, pager, properties);
	}
}
