import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import Select from 'react-select';

import styled from 'styled-components';

import StyledFontsSelectMenu from './StyledFontsSelectMenu';
import StyledDropdownIndicator from './StyledDropdownIndicator';
import StyledGroupHeading from './StyledGroupHeading';
import StyledFontsSelectControl from './StyledFontSelectControl';
import { COLORS } from '../../../../constants/colors';

const SelectWrapper = styled.div`
  width: 220px;
  background-color: ${COLORS.peech_neutral_white};
`;

const StyledSelect = styled(Select).attrs((props) => ({
  classNamePrefix: props.classNamePrefix ?? 'select',
  menuPlacement: 'auto',
}))`
  .select__control {
    height: 36px;
    border: solid 1px;
    border-color: ${COLORS.peech_gray_400};
    min-width: 90%;
    width: unset !important;
    width: fit-content;

    transition: opacity 250ms ease-out;
    .select__value-container {
      max-height: 100%;
    }
  }

  .select__control--menu-is-open,
  .select__control--is-focused,
  .select__control--is-focused:hover {
    border-color: ${COLORS.peech_primary_2_500} !important;
    box-shadow: none !important;
  }

  .select__indicators {
    max-height: 100%;
  }

  .select__loading-indicator {
    color: ${COLORS.peech_primary_1_500} !important;
  }

  .select__dropdown-indicator {
    padding: 5px;
  }

  .select__value-container {
    font-size: 14px;
    font-weight: 400;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__placeholder {
    color: ${COLORS.peech_gray_400};
    font-weight: 300;
  }
`;

export type FontOption = {
  label: string;
  value: string;
  disabled?: boolean;
  isCustom?: boolean;
};

interface GroupedFontsSelectProps {
  fontsOptions: any;
  selectedOption: FontOption;
  onGoogleFontSelect: (value: string) => void;
  onCustomFontSelect: (option: FontOption) => void;
  onFontAdd: (files: any) => void;
  isLoading?: boolean;
}

export default function GroupedFontsSelect({
  fontsOptions,
  selectedOption,
  onGoogleFontSelect,
  onCustomFontSelect,
  onFontAdd,
  isLoading
}: GroupedFontsSelectProps) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleSelectChange = (option: FontOption) => {
    if (option.isCustom) {
      onCustomFontSelect(option);
    } else {
      onGoogleFontSelect(option.value);
    }
    setMenuIsOpen(false);
  };

  const handleClickOutside = (event: SyntheticEvent<HTMLDivElement>) => {
    if (selectRef.current && !selectRef.current?.contains(event.target)) {
      setMenuIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [selectRef?.current]);

  return (
    <SelectWrapper ref={selectRef}>
      <StyledSelect
        isLoading={isLoading}
        menuIsOpen={menuIsOpen}
        options={fontsOptions}
        value={selectedOption}
        onChange={handleSelectChange}
        closeMenuOnSelect={false}
        onFontAdd={onFontAdd}
        setMenuIsOpen={setMenuIsOpen}
        placeholder="Choose a font..."
        components={{
          Menu: StyledFontsSelectMenu,
          GroupHeading: StyledGroupHeading,
          DropdownIndicator: StyledDropdownIndicator,
          Control: StyledFontsSelectControl,
        }}
      />
    </SelectWrapper>
  );
}
