import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import React from 'react';
import ClosedCaption from '../client/closedCaption';
import CuePoint from '../client/cuePoint';
import Sequence, { Chapter, SequenceAccess } from '../client/sequence';
import Assets from '../dialogs/assets';
import DownloadModal from '../dialogs/download/DownloadModal';
import Trim from '../dialogs/trim';
import useOpenDownloadDialog from '../hooks/useOpenDownloadDialog';
import { AppContext } from '../lib/app-context';
import FileUploader from '../lib/fileUploader';
import ContentEditor from '../pages/content-editor';
import {
  cuePointsState,
  cuePointsVersionState,
  lockInteractionState,
  recalcSceneCuePointsTimes,
} from '../state/cuePoints';
import { handleError } from '../state/error';
import { fileUploaderState } from '../state/fileUploader';
import { DownloadDialogState, isInPreviewState } from '../state/local';
import { menuState } from '../state/menu';
import { playbackIsLockedState, playbackState, reloadPlayer } from '../state/playback';
import {
  downloadPendingURL,
  resetDownloadPendingState,
  scenesState,
  sequenceSave,
  sequenceState,
  setScene,
} from '../state/sequence';
import { trackEvent } from '../utils/analityics.utils';
import Preloader from './cue-points/preloader';
import DraftModal, { firstDraftState } from './draftModal/DraftModal';
import MainPlayer from './main-player';
import Menu from './menu/menu';
import PlayerStyle from './player-style';
import PlugMobile from '../ui-components/PlugMobile';


let duration;

function calcPercent(time) {
  return time && duration ? (time / duration) * 100 : 0;
}

export const EDITORS = {
  VISUALS: 0,
  CONTENT: 1,
};

export default function Dashboard() {
  const sequence = sequenceState.use();
  const sequenceDuration = sequenceState.use((sequence) => sequence.duration);
  const scenes = scenesState.use((scenes) => scenes.map);
  const chapters = scenesState.use((scenes) => scenes.filter.chapters);
  const nonDeletedScenes = scenesState.use((scenes) => scenes.filter.notDeleted);
  const { user, plan, status, config, isMobileDevice, isIOS } = React.useContext(AppContext);
  const statusRef = React.useRef();
  const location = useLocation();
  const [sequenceCreator, setSequenceCreator] = React.useState();
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const downloadURL = downloadPendingURL.use();

  const readyChapters = scenesState.use((scenes) => scenes.filter.readyChapters);
  const cuePointsData = cuePointsState.use();
  const cuePointsVersion = cuePointsVersionState.use();
  const lockPlayer = playbackIsLockedState.use();
  const openDownloadDialog = useOpenDownloadDialog();
  const shouldShowEditor = !isInPreviewState.use();

  const closedCaptionCuePoints = Object.values(cuePointsData).filter(
    (cuePoint) =>
      cuePoint?.type === CuePoint.TYPE.CLOSED_CAPTION &&
      cuePoint.activeTimes.length &&
      cuePoint.words?.find((w) => w.word)
  );

  const captions = React.useMemo(() => closedCaptionCuePoints.sort((a, b) => a.order - b.order), [cuePointsVersion]);

  const subtitleElements = React.useMemo(
    () =>
      captions.reduce((obj, closedCaption) => {
        readyChapters.forEach((chapter) => {
          const clipFrom = chapter.clipFrom || 0;
          if (
            closedCaption.status !== CuePoint.STATUS.DELETED &&
            closedCaption.chapterSid === (chapter.linkSid || chapter.sid) &&
            closedCaption.words.find(
              (w) => w.startTime / 1000 >= clipFrom && w.startTime / 1000 <= clipFrom + chapter.duration
            )
          ) {
            const cc = Object.assign(new ClosedCaption(), closedCaption);
            cc.menuChapter = chapter.sid;
            obj[chapter.sid] = obj[chapter.sid] || [];
            obj[chapter.sid].push(cc);
          }
        });
        return obj;
      }, {}),
    [readyChapters, captions]
  );

  async function saveNewSequence() {
    await sequenceSave(sequence);
    trackEvent('new-project');
    navigate(`/project#${sequence.sid}`);
  }

  async function onChapterFileSelected(file, callback) {
    if (!sequence.sid) {
      await saveNewSequence();
    }
    const asset = new Asset();
    asset.name = file.name;
    asset.type = Asset.TYPE.SOURCE;
    await asset.save();

    const maxDuration = user?.UIFLAGS.NO_UPLOAD_LIMIT ? null : plan?.maxVideoDuration * 60;
    const uploader = new FileUploader({
      file,
      maxDuration,
      content: asset,
    });
    fileUploaderState.set((uploaders) => ({ ...uploaders, [asset.sid]: uploader }));

    uploader
      .on('done', () => fileUploaderState.set(({ [asset.sid]: done, ...uploaders }) => uploaders))
      .on('canceled', () => fileUploaderState.set(({ [asset.sid]: canceled, ...uploaders }) => uploaders))
      .on('error', (title, message) => handleError({ title, message }))
      .on('thumbnail', async ({ src, thumbnail }) => {
        if (!asset.contentIdentifier) {
          return;
        }
        try {
          uploader.uploadThumbnail(asset.contentIdentifier.key, thumbnail, asset.sid);
          asset.thumbnail = src;
        } catch (err) {
          console.error(err);
          handleError({
            title: 'Content thumbnail upload failed',
            message: err.message,
            responseError: err,
          });
        }
      })
      .on('parsed', (metadata) => {
        console.log(metadata);
      })
      .uploadAsset(null, asset.sid);
    let chapter = new Chapter();
    chapter.assetSid = asset.sid;
    try {
      chapter = await sequence.addChapter(chapter);
    } catch (err) {
      return handleError({
        statusCode: 11,
        responseError: err,
      });
    }
    chapter.values.size = file.size;

    setScene(chapter);
  }

  // React.useEffect(() => {
  // 	if (plan && plan.maxVideoDuration && sequence.duration / 60 > plan.maxVideoDuration) {
  // 		showSnackbar !== null && setShowSnackbar(true)
  // 	} else if (showSnackbar) {
  // 		setShowSnackbar(false)
  // 	}
  // }, [sequenceDuration, plan])

  const manifestVersion = playbackState.use((playback) => playback.manifestVersion);
  const getPlaybackUrl = () => {
    const baseUrl = config.PLAYBACK_URL || location.protocol + '//' + location.host;
    const streamType = sequence?.useFootageForCrop ? 'preview-original' : 'preview';

    const osType = isIOS ? 'hls' : 'dash';
    const manifestType = isIOS ? 'master.m3u8' : 'manifest.mpd';

    return `${baseUrl}/${osType}/${sequence.sid}/${manifestVersion}/${
      streamType
    }/${manifestType}`;
  };

  const [playbackUrl, setPlaybackUrl] = React.useState();
  const [trim, setTrim] = React.useState();
  const downloadDialog = DownloadDialogState.use();
  const monitorHidePlay = config?.MONITOR_HIDE_PLAY === 'true';
  const showFirstDraft = firstDraftState.use();

  React.useEffect(() => {
    if (!sequence && !status) {
      return;
    }

    if (status) {
      if (status.sid !== sequence.sid) {
        status.sid = sequence.sid;
      }
      statusRef.current = status;
    } else {
      return;
    }

    status.sequence
      .on('playback', ({ version, restart, duration, ack }) => {
        console.log('Status playback', { version, restart, duration });
        if (duration) {
          sequenceState.set((sequence) => {
            sequence.duration = duration;
            return sequence;
          });
        }
        reloadPlayer(version, restart && 0.001);
        ack && ack();
      })
      .on('audio', ({ progress, progressType }) => {
        if (config?.PROCESS_MUSIC_IN_BACKGROUND === 'true') {
          // when new music bg job don't run old flow
          return;
        }
        if (progress < 100) {
          playbackIsLockedState.set(true);
        } else {
          playbackIsLockedState.set(false);
        }
        console.log('Status audio', { progress });
      })
      .on('output', ({ ack }) => {
        ack && ack();
      })
      .on('duration', ({ version, duration }) => {
        if (duration !== sequence.duration) {
          reloadPlayer(version);
        }
      })
      .on('scene', ({ scene: update }) => {
        sequenceState.set((sequence) => {
          let scene = scenes[update.sid];
          if (update.status !== Chapter.STATUS.DELETED) {
            let recalc = false;
            if (
              (!scene ||
                scene.index !== update.index ||
                scene.offset !== update.offset ||
                scene.status !== update.status) &&
              update.status === Chapter.STATUS.READY
            ) {
              if (scene) {
                recalc = true;
              }
            }
            if (!scene) {
              recalc = true;
              scene = new Chapter(update.sid);
            }
            recalc && recalcSceneCuePointsTimes(sequence, scene);
          }
          return sequence;
        });
      });
  }, [status, sequence, config]);

  React.useEffect(() => {
    sequence && sequence.sid && !sequenceCreator && loadSequenceCreator();
  }, [sequence]);

  React.useEffect(() => {
    return () => {
      resetDownloadPendingState();
      lockInteractionState.set(false);
      location.state?.editContent &&
        navigate(undefined, {
          state: {},
        });
    };
  }, []);

  React.useEffect(() => {
    if (!nonDeletedScenes.filter((c) => c.status === Chapter.STATUS.READY).length) {
      return console.log('No ready chapter');
    }

    setPlaybackUrl(getPlaybackUrl);
  }, [manifestVersion, nonDeletedScenes]);

  React.useEffect(() => {
    if (window.location.hash.includes('editContent')) {
      const [seqSid] = window.location.hash.slice(1).split('&');
      navigate(`/project/#${seqSid}`, { state: { editContent: true } });
    }
  }, []);

  function onTrim(chapter) {
    menuState.set(null);
    setTrim(chapter);
  }

  async function loadSequenceCreator() {
    let seqCreator;
    if (sequence.role === Sequence.ROLE.CREATOR) {
      seqCreator = new SequenceAccess().set({
        userSid: user.sid,
        email: user.email,
        name: (user.firstName + ' ' + user.lastName).trim(),
      });
    } else {
      seqCreator = await sequence.creator();
    }
    setSequenceCreator(seqCreator);
  }

  const viewerDashboardClassNames = classNames('screen dashboard preview viewer-view', {
    'one-on-one': sequence?.aspectRatio === '1:1',
  });

  if (!nonDeletedScenes.length) {
    return null;
  }

  if (location.state?.editContent) return <ContentEditor />;

  if (sequence.role === Sequence.ROLE.VIEWER) {
    return (
      <div className={viewerDashboardClassNames}>
        <Preloader />
        <PlayerStyle />
        <div className={`viewer preview ${sequence?.aspectRatio === '1:1' ? 'one-on-one' : ''}`}>
          <MainPlayer
            playbackUrl={playbackUrl}
            onError={() => setPlaybackUrl(null)}
            onRetry={() => setPlaybackUrl(getPlaybackUrl)}
            disabled={trim}
            sequenceCreator={sequenceCreator}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="screen dashboard " data-cy="dashboard">
      {isInPreviewState.get() && 
      <style>{`
        body, .page-view-wrapper, .screen.dashboard, .modal-backdrop {
          background: transparent !important;
        }
				`}</style>
        }
      <PlayerStyle />
      <Preloader />
      {isIOS ? <PlugMobile /> : <DraftModal onDownload={openDownloadDialog} playbackUrl={playbackUrl} />}
      {downloadDialog && (
        <DownloadModal
          aspectRatio={sequence.aspectRatio}
          onHide={() => {
            DownloadDialogState.set(false);
            if (isMobileDevice) {
              const path = `${config.NEW_UI_URL}/platform/p/${sequence.footageSid}`;
              window.location.assign(path);
            }
          }}
        />
      )}
      {shouldShowEditor && (
        <>
          {!isMobileDevice && <Menu subtitleElements={subtitleElements} />}
          {!isMobileDevice && !showFirstDraft && (
            <div className="preview player">
              <MainPlayer
                playbackUrl={playbackUrl}
                onError={() => setPlaybackUrl(null)}
                onRetry={() => setPlaybackUrl(getPlaybackUrl)}
                disabled={trim}
                onDownload={sequence.role === Sequence.ROLE.CREATOR && openDownloadDialog}
                showPlayButton={!monitorHidePlay}
              />
              {/* <div className="player-tools">
					<div style={{ height: 0, position: 'relative' }}>
					</div>
				</div> */}
            </div>
          )}
          {/* <!-- Timeline Menu--> */}
          {/* <Timeline className="timeline" scenes={nonDeletedScenes} calcPercent={calcPercent} onChapterFileSelected={onChapterFileSelected} showProgress={playbackUrl !== undefined} onTrim={onTrim} /> */}
          {trim && <Trim key={trim} sid={trim} onClose={() => setTrim(null)} onTrim={onTrim} />}
          <Assets />
          <div className={`snackbar ${showSnackbar ? 'active' : ''}`}>
            <span>
              {/* TODO instead of looking for the first Chapter, add support to uploaded intro/outro if they're long enough */}
              Your video seems to be longer than your plan
              {chapters.length && <a onClick={() => onTrim(chapters[0].sid)}>Trim</a>}
            </span>
            <button className="btn btn-link" onClick={() => setShowSnackbar(null)}>
              ×
            </button>
          </div>
        </>
      )}
      {downloadURL && (
        <iframe
          id="download-target"
          title="download"
          style={{ maxHeight: 0, visibility: 'hidden', display: 'none' }}
          src={downloadURL}
          onLoad={() => {
            trackEvent('download-done');
          }}
        ></iframe>
      )}
    </div>
  );
}
