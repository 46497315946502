import React from 'react';
import styled from '@emotion/styled/macro';
import { Modal } from 'react-bootstrap';

import { AppContext } from '../../lib/app-context';

const StyledModal = styled(Modal)`
  overflow: auto;
  .modal-dialog {
  }

  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: transparent;
    box-shadow: none;

    iframe {
      height: 75vh;
      width: 80vw;
      min-height: 580px;
      min-width: 734px;
      max-width: 1100px;
      max-height: 700px;
      border-radius: 30px;
      overflow: hidden;
    }
  }
`;

export default function PeechUpgradeFeatureModal({
  isOpen,
  onClose,
  featureId,
}: {
  isOpen: boolean;
  onClose(): void;
  featureId?: string;
}) {
  const { config } = React.useContext(AppContext);
  return (
    <StyledModal show={isOpen} onHide={onClose} centered>
      <iframe src={`${config.NEW_UI_URL}/upgrade-feature?featureId=${featureId ?? ''}`}> </iframe>
    </StyledModal>
  );
}
